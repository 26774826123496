import axios from 'axios'
import firebase from 'firebase/app';

import {
      FETCH_ALL_METHODS, LOAD_ALL_METHODS, ERROR_ALL_METHODS,
} from '../Actions/Types'


export const getAllPaymentPayingMethods = () => dispatch => {

    dispatch({
        type: LOAD_ALL_METHODS,
        payload: 'loading'
    })

    axios.get('/PaymentMethods/getAllPaymentPayingMethods')
        .then((res) => {
            dispatch({
                type: FETCH_ALL_METHODS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_ALL_METHODS,
                payload: Error.message
            })
        })
}
