import axios,{post,put} from "axios";
import {LOAD_DISCOVER_US_SERIES,
    FETCH_ALL_DISCOVER_US_SERIES,
    ERROR_LOADING_DISCOVER_US_SERIES,
    EMPTY_DISCOVER_US_SERIES,
    SAVE_DISCOVER_US_SERIES,
    ERROR_SAVING_DISCOVER_US_SERIES,
    DELETE_DISCOVER_US_SERIES,
    ERROR_DELETING_DISCOVER_US_SERIES,
    UPDATE_DISCOVE_US_SERIES,
    ERROR_UPDATING_DISCOVER_US_SERIES,
    GET_DISCOVER_US_SERIES_DATA,
    SETTING_DISCOVER_US_SERIES_STATUS,
    ERROR_SETTING_DISCOVER_US_SERIES_STATUS,
    EMPTY_DISCOVER_US_SERIES_ITEM,
    ADD_DISCOVER_WITH_SENTFROM} from './Types'


    export const  addSerieItemWithSentFrom = (seriesItem, sentFrom) => dispatch => {

        const series = {
            seriesItem: seriesItem,
            sentFrom: sentFrom
        }
        dispatch({
            type: ADD_DISCOVER_WITH_SENTFROM,
            payload: series
        })
    }




export const fetchDiscoverUsSeries =()=> dispatch =>
{
    dispatch({
        type: LOAD_DISCOVER_US_SERIES,
        payload: 'loading'
    })

    axios.get('/DiscoverUs/getAllDiscoverUsSeriesList')
    .then((res)=>
    {
        dispatch({
            type:FETCH_ALL_DISCOVER_US_SERIES,
            payload:res.data
        })
    }).catch((Error)=>
    {
        dispatch({
            type:ERROR_LOADING_DISCOVER_US_SERIES,
            payload:Error.message
        })
    })

}
export const emptyDiscoverUsSeries =()=>dispatch=>
{
    dispatch({
        type:EMPTY_DISCOVER_US_SERIES,
        payload:''
    })
}
export const emptyDiscoverSeriesItem =()=>dispatch=>
{
    dispatch({
        type:EMPTY_DISCOVER_US_SERIES_ITEM,
        payload:''
    })
}



export const saveDiscoverUsSeries =(name, description, image,publishDate, audio, promo_with_music,
     promo_without_music,
     intro_with_music,intro_without_music,
     name_in_english,
     description_in_english,
     videoKeyIntroWithMusic, videoKeyIntroWithoutMusic, videoKeyPromoWithMusic, videoKeyPromoWithoutMusic, featured)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type: LOAD_DISCOVER_US_SERIES,
                payload: 'loading'
            })
            const url = "/DiscoverUs/addDiscoverUsSeries"
            const formData = new FormData();
            formData.append("Name",name);
            formData.append("Description",description);
            formData.append("Image",image);
            formData.append("Status","SUSPENDED")
            formData.append("Publish_Date",publishDate);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promo_with_music);
            formData.append('Promo_Video_Without_Music',promo_without_music);
            formData.append('Intro_Video_With_Music',intro_with_music);
            formData.append('Intro_Video_Without_Music',intro_without_music);
            formData.append('Name_In_English', name_in_english);
            formData.append('Description_In_English', description_in_english);
            formData.append("videoKeyIntroWithMusic",videoKeyIntroWithMusic);
            formData.append("videoKeyIntroWithoutMusic",videoKeyIntroWithoutMusic);
            formData.append("videoKeyPromoWithMusic", videoKeyPromoWithMusic);
            formData.append("videoKeyPromoWithoutMusic", videoKeyPromoWithoutMusic);
            formData.append('featured', featured);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:SAVE_DISCOVER_US_SERIES,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_SAVING_DISCOVER_US_SERIES,
                    payload:Error.message
                })
            })
        })
    }
}

export const deleteDiscoverUsSeries =(id)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type: LOAD_DISCOVER_US_SERIES,
                payload: 'loading'
            })

            const url = "/DiscoverUs/deleteDiscoverUsSeries"
            const formData = new FormData();
            formData.append("ID",id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post (url,formData,config).then((res)=>
            {
                dispatch({
                    type:DELETE_DISCOVER_US_SERIES,
                    payload:res.data
                })

            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_DELETING_DISCOVER_US_SERIES,
                    payload:Error.message
                })
            })

        })
    }
}

export const updateDiscoverUsSeries =(id,name, description, status,image,publishDate, audio,
    promo_with_music, promo_without_music, intro_with_music,
           intro_without_music, name_in_english, description_in_english,
            video_key_promo_with_music, video_key_promo_without_music, video_key_intro_with_music,
            video_key_intro_without_music,featured)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type: LOAD_DISCOVER_US_SERIES,
                payload: 'loading'
            })

            const url = "/DiscoverUs/updateDiscoverUsSeries"
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', image);
            formData.append('Status', status);
            formData.append('Publish_Date', publishDate);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promo_with_music);
            formData.append('Promo_Video_Without_Music',promo_without_music);
            formData.append('Intro_Video_With_Music',intro_with_music);
            formData.append('Intro_Video_Without_Music',intro_without_music);
            formData.append('Name_In_English', name_in_english);
            formData.append('Description_In_English', description_in_english);
            formData.append('Video_Key_Promo_Video_With_Music',video_key_promo_with_music);
            formData.append('Video_Key_Promo_Video_Without_Music',video_key_promo_without_music);
            formData.append('Video_Key_Intro_Video_With_Music', video_key_intro_with_music);
            formData.append('Video_Key_Intro_Video_Without_Music', video_key_intro_without_music);
            formData.append('featured',featured);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url,formData,config).then((res)=>
            {
                dispatch({
                    type:UPDATE_DISCOVE_US_SERIES,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_UPDATING_DISCOVER_US_SERIES,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const getDiscoverUsSeriesData =(id)=>
{

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_DISCOVER_US_SERIES,
                payload: 'loading'
            })
            const url = "/DiscoverUs/getDiscoverUsSeriesDetails/admin"
            const formData = new FormData();
            formData.append('Discover_us_Series ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_DISCOVER_US_SERIES_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_DISCOVER_US_SERIES,
                    payload: Error.message
                })
            })


        })

    }

}

export const setDiscoverUsSeriesState =(id,status)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
             dispatch({
                type: LOAD_DISCOVER_US_SERIES,
                payload: 'loading'
            })

            const url = "/DiscoverUs/setDiscoverUsSeriesStatus"
            const formData = new FormData();
            formData.append("ID",id)
            formData.append("Status",status)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:SETTING_DISCOVER_US_SERIES_STATUS,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_SETTING_DISCOVER_US_SERIES_STATUS,
                    payload: Error.response.data.message//Error.message
                })
            })
        })

    }
}
