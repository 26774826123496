import {
    LOAD_FIT_VERIFYING_CODE,
    FIT_VERIFYING_CODE_SUCCESS,
    FIT_VERIFYING_CODE_ERROR,

    LOAD_FIT_RESEND_CODE,
    FIT_RESEND_CODE_SUCCESS,
    FIT_RESEND_CODE_ERROR,

    EMPTY_FIT

} from "./Types";

import { post } from 'axios'


export const verifyingCode = (pinCode, telecomCompany, phone) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_FIT_VERIFYING_CODE,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
          
            const url = "/Fit/submitPinCode"
            
            const formData = new FormData();
            formData.append('telecomCompany', telecomCompany)
            formData.append('pinCode', pinCode)
            formData.append('msisdn', phone)
            
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: FIT_VERIFYING_CODE_SUCCESS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: FIT_VERIFYING_CODE_ERROR,
                    payload: Error.response.data.message
                })

            })
        })
    }

}


export const resendCode = (phone, telecomCompany) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_FIT_RESEND_CODE,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
          
            const url = "/Fit/resendPinCode"
            
            const formData = new FormData();
            formData.append('msisdn', phone)
            formData.append('telecomCompany', telecomCompany)
            
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: FIT_RESEND_CODE_SUCCESS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: FIT_RESEND_CODE_ERROR,
                    payload: Error.response.data.message
                })

            })
        })
    }

}


export const emptyFit = () => dispatch => {
    dispatch({
        type: EMPTY_FIT,
        payload: ''
    })
}