import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Button } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    emptyDurationResponse,
    deleteDuration,
    fetchAllDuration
} from '../../Actions/DurationOfUseActions';

class DeleteDurationOfUse extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteDuration(id) {
        this.props.deleteDuration(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;


        console.log("id = " + id)

        if (id === 0) {
            alert(" No Duration to delete");
        }
        else {
            this.deleteDuration(id);
        }
        this.close();

    }

    render() {
        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert(error)
            this.props.emptyDurationResponse();
        }
        else if (success === true) {
            alert("Duration Deleted Successfully")
            this.props.emptyDurationResponse();
            this.props.fetchAllDuration();
            this.props.deleteDurationOfUse("deleted");

        }

        return (

            <div className="acor-btn-group">

                <Button color="black" className="acor-btn2" onClick={this.open}>
                    <i className="far fa-trash-alt" >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </Button>
                <Confirm id="modal"
                    content='Are you sure you want to delete this Duration Of Use ?? '
                    open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>
        )
    }
}

DeleteDurationOfUse.propTypes = {
    fetchAllDuration: propTypes.func.isRequired,
    deleteDuration: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    durationOfUse: state.durationOfUse.items,
    loading: state.durationOfUse.loading,
    error: state.durationOfUse.deleting_Duration_error,
    success: state.durationOfUse.deleting_Duration_sucessfully
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyDurationResponse,
                deleteDuration,
                fetchAllDuration
            })
        (DeleteDurationOfUse)
    );
