import { Promise } from 'q';
import store from '../Store';
import firebase from 'firebase/app';
import { post } from 'axios'
import 'firebase/firestore';
import 'firebase/auth';


export function saveEpisode(activityId) {
    return new Promise(function (resolve, reject) {
        let episode = store.getState().episodes.item;

        let isFree = '', subValueId = '',
            durationWithMusic = '', durationWithoutMusic = '',
            videoWithMusicUrl = '', videoWithoutMusicUrl = '',
            videoKeyVideoWithMusic = '', videoKeyVideoWithoutMusic = '';
        if (episode.isFree === 'paid') {
            isFree = 0
        }
        else if (episode.isFree === 'free') {
            isFree = 1
        }

        if (episode.subValueId === '' ||
            episode.subValueId === undefined ||
            episode.subValueId === null) {
            subValueId = 0
        } else {
            subValueId = episode.subValueId
        }


        if (episode.episode_videoUrlwithMusic_duration === '' ||
            episode.episode_videoUrlwithMusic_duration === undefined ||
            episode.episode_videoUrlwithMusic_duration === null) {
            durationWithMusic = 0
        } else {
            durationWithMusic = episode.episode_videoUrlwithMusic_duration
        }

        if (episode.episode_videoUrlwithoutMusic_duration === '' ||
            episode.episode_videoUrlwithoutMusic_duration === undefined ||
            episode.episode_videoUrlwithoutMusic_duration === null) {
            durationWithoutMusic = 0
        } else {
            durationWithoutMusic = episode.episode_videoUrlwithoutMusic_duration
        }

        if (episode.videoKeyVideoWithMusic === '' ||
            episode.videoKeyVideoWithMusic === undefined ||
            episode.videoKeyVideoWithMusic === null) {
            videoKeyVideoWithMusic = ''
        } else {
            videoKeyVideoWithMusic = episode.videoKeyVideoWithMusic
        }
        if (episode.videoKeyVideoWithoutMusic === '' ||
            episode.videoKeyVideoWithoutMusic === undefined ||
            episode.videoKeyVideoWithoutMusic === null) {
            videoKeyVideoWithoutMusic = ''
        } else {
            videoKeyVideoWithoutMusic = episode.videoKeyVideoWithoutMusic
        }


        if (episode.episode_videoUrlwithMusic === '' ||
            episode.episode_videoUrlwithMusic === undefined ||
            episode.episode_videoUrlwithMusic === null) {
            videoWithMusicUrl = ''
        } else {
            videoWithMusicUrl = episode.episode_videoUrlwithMusic
        }


        if (episode.episode_videoUrlwithoutMusic === '' ||
            episode.episode_videoUrlwithoutMusic === undefined ||
            episode.episode_videoUrlwithoutMusic === null) {
            videoWithoutMusicUrl = ''
        } else {
            videoWithoutMusicUrl = episode.episode_videoUrlwithoutMusic
        }
        let description = episode.episode_description;
        if (episode.episode_description == null ||
            episode.episode_description == undefined ||
            episode.episode_description == '') {
            description = ''
        }

        const url = '/Episode/addEpisode';

        const formData = new FormData();
        formData.append('Name', episode.episode_name);
        formData.append('Status', "SUSPENDED");
        formData.append('Description', description);
        formData.append('Episode_Number', episode.episode_number);
        formData.append('Publish_Date', episode.episode_publishDate);
        formData.append('Image', episode.episode_imageUrl);
        formData.append('Series_Id', episode.selected_series_id);
        formData.append('Activity_Id', activityId);
        formData.append('Is_free', isFree);
        formData.append('Voice', episode.voice);
        formData.append('Content_Sub_Value_Id', subValueId);

        formData.append('Video_With_Music_URL', videoWithMusicUrl);
        formData.append('Video_With_Music_Duration', durationWithMusic);
        formData.append('Video_Without_Music_URL', videoWithoutMusicUrl);
        formData.append('Video_Without_Music_Duration', durationWithoutMusic);
        formData.append('Episode_Name_In_English', episode.episode_name_in_english);
        formData.append('Episode_Description_In_English', episode.episode_description_in_english);
        formData.append("videoKeyVideoWithMusic", videoKeyVideoWithMusic);
        formData.append("videoKeyVideoWithoutMusic", videoKeyVideoWithoutMusic);
        formData.append('featured', episode.featured);
        formData.append('contentVendorSelectedId', episode.contentVendorSelectedId);

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res) => {
            resolve(res);
            return res;
        }).catch((Error) => {
            reject(Error)
            return Error.message
        })
    })
}