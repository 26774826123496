import {
    FETCH_All_SERIES, GET_SERIES_DATA, LOAD_SERIESES, ERROR_LOADING_SERIESES,
    ERROR_LOADING_SERIES, LOAD_SERIES, SAVE_SERIES, ERROR_ADDING_SERIES,
    ERROR_SETTING_STATE_SERIES, SETTING_STATE_SERIES,
    ERROR_DELETING_SERIES, DELETING_SERIES,
    ERROR_UPDATE_SERIES, UPDATE_SERIES, EMPTY_SERIRS,
    ADD_SERIES_WITH_SENTFROM, 
    GET_SERIES_TYPE,
    CLEAR_SERIES_TYPE} from './Types'
import axios, { post, put } from 'axios'

export const addSerieItemWithSentFrom = (seriesItem, sentFrom) => dispatch => {

    const series = {
        seriesItem: seriesItem,
        sentFrom: sentFrom
    }
    dispatch({
        type: ADD_SERIES_WITH_SENTFROM,
        payload: series
    })
}



export const fetchAllSeries = () => dispatch => {

    dispatch({
        type: LOAD_SERIESES,
        payload: 'loading'
    })

    axios.get('/Series/getAllSeriesList')
        .then((res) => {
            dispatch({
                type: FETCH_All_SERIES,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_SERIESES,
                payload: Error.message
            })
        })
}

export const getSeriesData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SERIES,
                payload: 'loading'
            })
            const url = "/Series/getNormalSeriesDetails/admin"
            const formData = new FormData();
            formData.append('Normal_Series_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_SERIES_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_SERIES,
                    payload: Error.message
                })
            })


        })

    }
}

export const saveSeries = (name, description, imgURL, publish_date, audio, promo_with_music, promo_without_music,
    intro_with_music, intro_without_music, nameInEnglish, descriptionInEnglish,
    videoKeyIntroWithMusic, videoKeyIntroWithoutMusic, videoKeyPromoWithMusic, videoKeyPromoWithoutMusic, featured) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_SERIES,
                payload: 'loading'
            })

            const url = '/Series/addNormalseries';
            const formData = new FormData();
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Status', "SUSPENDED");
            formData.append('Image', imgURL);
            formData.append('Publish_Date', publish_date);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promo_with_music);
            formData.append('Promo_Video_Without_Music', promo_without_music);
            formData.append('Intro_Video_With_Music', intro_with_music);
            formData.append('Intro_Video_Without_Music', intro_without_music);
            formData.append('name_in_english', nameInEnglish);
            formData.append('description_in_english', descriptionInEnglish);
            formData.append("videoKeyIntroWithMusic", videoKeyIntroWithMusic);
            formData.append("videoKeyIntroWithoutMusic", videoKeyIntroWithoutMusic);
            formData.append("videoKeyPromoWithMusic", videoKeyPromoWithMusic);
            formData.append("videoKeyPromoWithoutMusic", videoKeyPromoWithoutMusic);
            formData.append("featured", featured);
            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SAVE_SERIES,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_SERIES,
                    payload: Error.message
                })
            })
        })


    }
}

export const setStateNormalSeries = (id, state) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SERIES,
                payload: 'loading'
            })

            const url = "/Series/setStateNormalSeries";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('State', state);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SETTING_STATE_SERIES,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_STATE_SERIES,
                    payload: Error.response.data.message//Error.message
                })
            })
        })

    }
}


export const deleteSeries = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SERIES,
                payload: 'loading'
            })

            const url = "/Series/deleteNormalSeries";
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_SERIES,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_SERIES,
                    payload: Error.message
                })
            })
        })

    }
}


export const updateSeries = (id, name, description, status,
    imageUrl, publishDate, audio,
    promo_with_music, promo_without_music, intro_with_music,
    intro_without_music,
    nameInEnglish, descriptionInEnglish,
    video_key_promo_with_music, video_key_promo_without_music, video_key_intro_with_music,
    video_key_intro_without_music, featured) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SERIES,
                payload: 'loading'
            })

            const url = "/Series/updateNormalSeries";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', imageUrl);
            formData.append('Status', "");
            formData.append('Publish_Date', publishDate);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promo_with_music);
            formData.append('Promo_Video_Without_Music', promo_without_music);
            formData.append('Intro_Video_With_Music', intro_with_music);
            formData.append('Intro_Video_Without_Music', intro_without_music);
            formData.append('name_in_english', nameInEnglish);
            formData.append('description_in_english', descriptionInEnglish);
            formData.append('Video_Key_Promo_Video_With_Music', video_key_promo_with_music);
            formData.append('Video_Key_Promo_Video_Without_Music', video_key_promo_without_music);
            formData.append('Video_Key_Intro_Video_With_Music', video_key_intro_with_music);
            formData.append('Video_Key_Intro_Video_Without_Music', video_key_intro_without_music);
            formData.append('featured', featured);


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_SERIES,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_SERIES,
                    payload: Error.response.data.message
                })
            })
        })

    }
}

export const emptySeriesWithEpisode = () => dispatch => {
    console.log("Empty_Seris")
    dispatch({
        type: EMPTY_SERIRS,
        payload: ""
    })
}

export const getSeriesType = (series_id) => dispatch => {

    axios.get(`/general-series/series-type?id=${series_id}`)
        .then((res) => {
            dispatch({
                type: GET_SERIES_TYPE,
                payload: res.data
            })
        }
        )
}
export const clearSeriesType = (series_id) => dispatch => {

   
            dispatch({
                type: CLEAR_SERIES_TYPE,
                payload: ""
            })
        
}

