import {EMPTY_ADMIN,
    FETCH_ALL_ADMINS, LOAD_ALL_ADMINS, ERROR_ALL_ADMINS,
    LOAD_ADMIN, ERROR_DELETING_ADMIN, DELETING_ADMIN,
    ERROR_SETTING_STATE_ADMIN, SETTING_STATE_ADMIN,
    ERRROR_ADMIN_RESET_PASSWORD, ADMIN_RESET_PASSWORD,
    ERROR_CHANGE_ADMIN_ROLE, CHANGE_ADMIN_ROLE, FETCH_ADMIN, ERROR_FETCH_ADMIN
} from '../Actions/Types';

const initialState = {
    items: [],
    item: {},
    showModal: '',

    loading: '',
    getting_allAdmins_error: '',
    getting_allAdmin_sucessfully: '',

    getting_admin_error: '',
    getting_admin_sucessfully: '',

    deleting_admin_sucessfully: '',
    deleting_admin_error: '',

    blocking_admin_sucessfully: '',
    blocking_admin_error: '',

    admin_reset_password_sucessfully: '',
    admin_reset_password_error: '',

    change_admin_role_error: '',
    change_admin_role_sucessfully: '',

}


export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ADMIN:
          return {
            ...state,
            item: action.payload,
            getting_admin_sucessfully: true,
            getting_admin_error: '',
            loading: false,
          }

        case ERROR_FETCH_ADMIN:
            return{
              ...state,
              item: '',
              getting_admin_sucessfully: false,
              getting_admin_error: action.payload,
              loading: false,
            }
        case FETCH_ALL_ADMINS:
            return {
                ...state,
                items: action.payload,
                getting_allAdmins_error: '',
                getting_allAdmin_sucessfully: true,
                loading: false
            }


        case LOAD_ALL_ADMINS, LOAD_ADMIN:
            return {
                ...state,
                loading: true
            }

        case ERROR_ALL_ADMINS:
            return {

                ...state,
                getting_allAdmins_error: action.payload,
                loading: false
            }

        case DELETING_ADMIN:
            return {

                ...state,
                item: action.payload,
                deleting_admin_sucessfully: true,
                deleting_admin_error: '',
                loading: false
            }
        case ERROR_DELETING_ADMIN:
            return {

                ...state,
                deleting_admin_error: action.payload,
                loading: false
            }

        case SETTING_STATE_ADMIN:
            return {
                ...state,
                item: action.payload,
                blocking_admin_sucessfully: true,
                loading: false
            }

        case ERROR_SETTING_STATE_ADMIN:
            return {
                ...state,
                blocking_admin_error: action.payload,
                loading: false
            }

        case ADMIN_RESET_PASSWORD:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                admin_reset_password_sucessfully: true,
                admin_reset_password_error: '',
                loading: false
            }

        case ERRROR_ADMIN_RESET_PASSWORD:
            return {
                ...state,
                admin_reset_password_error: action.payload,
                admin_reset_password_sucessfully: false,
                loading: false
            }



        case CHANGE_ADMIN_ROLE:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                change_admin_role_sucessfully: true,
                change_admin_role_error: '',
                loading: false
            }

        case ERROR_CHANGE_ADMIN_ROLE:
            return {
                ...state,
                change_admin_role_error: action.payload,
                change_admin_role_sucessfully: false,
                loading: false
            }
        case EMPTY_ADMIN:
            return {
                ...state,
                getting_allAdmins_error: action.payload,
                getting_allAdmin_sucessfully: action.payload,

                deleting_admin_sucessfully: action.payload,
                deleting_admin_error: action.payload,

                blocking_admin_sucessfully: action.payload,
                blocking_admin_error: action.payload,

                admin_reset_password_sucessfully: action.payload,
                admin_reset_password_error: action.payload,

                change_admin_role_error: action.payload,
                change_admin_role_sucessfully: action.payload,

                loading: action.payload,
                item: {},
            }
        default:
            return state
    }

}
