import React, { Component } from "react";
import Player from "video-react/lib/components/Player";
import { Button } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import { withFirebase } from "../../config";
/*Component */
import QuestionPreview from "../Layout/QuestionPreview";
import GamePreview from "../Layout/GamePreview";
import PaintingPreview from "../Layout/PaintingPreview";
import AudioPlayer from "react-h5-audio-player";
import UploadImage from "../S3Uploading/UploadImage";
import EvaluationPreview from "../Evaluation/EvaluationPreview";
import DragDropPreview from "../Layout/DragDropPreview";
/*Redux*/
import { connect } from "react-redux";
import propTypes from "prop-types";
import store from "../../Store";
import {
  getEpisodeData,
  emptyEpisodes,
  saveEpisodePuzzle,
  saveEpisodePaintingGame,
} from "../../Actions/EpisodeActions";
import { addDragDropParentIDtoRedirectionPage } from "../../Actions/RedirectionPagesActions.js";

/* components */
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
import Loader from "../Layout/Loader";

/* Same CSS style as PublishEpisode*/
import "../../css/AppCss/Episode/PublicViewPublishEpisode.css";
import "../../css/AppCss/Episode/ViewEpisode.css";
import "../../css/AppCss/Episode/EpisodeButtons.css";
import "../../../node_modules/video-react/dist/video-react.css";

/* Routes */
import {
  SIGN_IN,
  UPDATE_DISCOVER_SERIES,
  ADD_EPISODE,
  GET_EPISODE_TO_EDIT,
  GET_EPISODE_ACTIVITY_TO_EDIT,
  GET_EPISODE_TO_VIEW,
} from "../../routes";
import RenderVideoWithoutMusic from "../VideoPreview/RenderVideoWithoutMusic.js";
import RenderVideoWithMusic from "../VideoPreview/RenderVideoWithMusic.js";
import { EPISODE_FOLDER_NAME } from "../../portal/src/constants.js";

const INITIAL_STATE = {
  seriesName: "",
  seriesId: "",
  Icon_Image: "",
  Painting_Image: "",
};

function DateFormate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  //January is 0!
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  date =
    mm +
    "/" +
    dd +
    "/" +
    yyyy +
    ", " +
    date.getHours() +
    ":" +
    date.getMinutes();
  return date;
}

function GMTtolocalTime(GMTDate) {
  var date = new Date(GMTDate + " UTC");
  var d = new Date(date.toString());
  var d = DateFormate(d);

  return d;
}
class ViewEpisode extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.props.emptyEpisodes();
  }

  componentDidMount() {
    const { auth } = this.props;
    if (!auth.uid) {
      return <Redirect to={SIGN_IN}></Redirect>;
    } else {
      let seriesName = this.props.match.params.seriesName;
      let seriesId = this.props.match.params.seriesId;
      let episodeId = this.props.match.params.episodeId;

      this.setState({ seriesName: seriesName, seriesId: seriesId });
      this.props.getEpisodeData(episodeId);

      var url =
        GET_EPISODE_TO_VIEW +
        "/" +
        seriesName +
        "/" +
        seriesId +
        "/" +
        episodeId;
      this.props.addDragDropParentIDtoRedirectionPage(url, episodeId);
    }
  }


  handleChangeVideoWithMusic = (uploadInfo, videoKey) => {
    const { episodes } = this.props;


    // if (
    //   uploadInfo &&
    //   uploadInfo !== undefined &&
    //   uploadInfo !== null &&
    //   Object.keys(uploadInfo).length > 0
    // ) {

    // } 
  };
  handleChangeVideoWithoutMusic = (uploadInfo, videoKey) => {
    const { episodes } = this.props;


    // if (
    //   uploadInfo &&
    //   uploadInfo !== undefined &&
    //   uploadInfo !== null &&
    //   Object.keys(uploadInfo).length > 0
    // ) {

    // } 
  };
  getIconImageUrl(imageUrl) {

    if (imageUrl) {
      this.setState({ Icon_Image: imageUrl });
      let episodeId = this.props.match.params.episodeId;
      saveEpisodePuzzle(episodeId, imageUrl)
        .then((res) => {
          alert("Puzzle Game Item Added Successfully");
          this.props.getEpisodeData(episodeId);
          //this.props.history.push("/ViewSeries");
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    }

    setTimeout(() => {
      this.setState({ Icon_Image: "" });
    }, 10);
  }

  getPaintingImageUrl(imageUrl) {
    if (imageUrl) {
      this.setState({ Painting_Image: imageUrl });
      let episodeId = this.props.match.params.episodeId;
      saveEpisodePaintingGame(episodeId, imageUrl)
        .then((res) => {
          alert("Painting Game Item Added Successfully");
          this.props.getEpisodeData(episodeId);
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    }

    setTimeout(() => {
      this.setState({ Painting_Image: "" });
    }, 10);
  }

  render() {
    let seriesName = this.props.match.params.seriesName;
    let seriesId = this.props.match.params.seriesId;
    let episodeId = this.props.match.params.episodeId;



    const { episodes } = this.props;
    const { puzzles } = this.props.episodes;
    const { paintingGames } = this.props.episodes;
    const { dragDrops } = this.props.episodes;

    let $episode_Question = null;
    let editActivityLink = null;
    let EpisodeData = null;
    let evaluationPreview = null;


    if (episodes.activityId !== undefined) {
      evaluationPreview = (
        <EvaluationPreview
          evaluationCategory={episodes.activityId.evaluationCategory}
        />
      );
      $episode_Question = (
        <QuestionPreview
          questions={episodes.activityId.questions}
          state="view"
        />
      );

      if (episodes.activityId.id !== undefined) {
        editActivityLink = (
          <Link
            to={{
              pathname:
                GET_EPISODE_ACTIVITY_TO_EDIT +
                "/" +
                episodes.activityId.id +
                "/" +
                seriesName +
                "/" +
                seriesId +
                "/" +
                episodeId,
            }}
          >
            <Button id="episode_view"> Edit </Button>
          </Link>
        );
      }
    }

    let subvalue = "No Sub Value";
    if (
      episodes.contentSubValue !== null &&
      episodes.contentSubValue !== undefined
    ) {
      if (
        episodes.contentSubValue.contentValue !== null &&
        episodes.contentSubValue.contentValue !== undefined
      ) {
        subvalue = episodes.contentSubValue.contentValue;
      }
    }

    let episode_Game = null;
    if (puzzles !== undefined) {
      episode_Game = <GamePreview games={puzzles} state="viewEpisode" />;
    }

    let episode_Painting_Game = null;
    if (paintingGames !== undefined) {
      episode_Painting_Game = (
        <PaintingPreview games={paintingGames} state="viewEpisode" />
      );
    }

    let episode_drag_Drop = null;
    if (dragDrops !== undefined) {
      episode_drag_Drop = (
        <DragDropPreview
          games={dragDrops}
          state="viewEpisode"
          parentId={episodeId}
        />
      );
    }

    let episodeDescription = "";
    if (
      episodes.description !== null &&
      episodes.description !== undefined &&
      episodes.description !== ""
    ) {
      episodeDescription = episodes.description;
    } else {
      episodeDescription = "No description";
    }

    let episodeDescriptionInEnglish = "";
    if (
      episodes.descriptionInEnglish !== null &&
      episodes.descriptionInEnglish !== undefined &&
      episodes.descriptionInEnglish !== ""
    ) {
      episodeDescriptionInEnglish = episodes.descriptionInEnglish;
    } else {
      episodeDescriptionInEnglish = "No description";
    }

    let $episode_VideoPreviewWithMusic = null;
    if (
      episodes.videoWithMusicId !== undefined &&
      episodes.videoWithMusicId !== null
    ) {
      if (
        episodes.videoWithMusicId.url !== undefined &&
        episodes.videoWithMusicId.url !== null
      ) {
        $episode_VideoPreviewWithMusic = (
          <Player playsInline fluid src={episodes.videoWithMusicId.url} />
        );
      } else {
        $episode_VideoPreviewWithMusic = (
          <div className="previewText">No Video for Preview</div>
        );
      }
    }

    let $episode_VideoPreviewWithoutMusic = null;

    if (
      episodes.videoWithoutMusicId !== undefined &&
      episodes.videoWithoutMusicId !== null
    ) {
      if (
        episodes.videoWithoutMusicId.url !== undefined &&
        episodes.videoWithoutMusicId.url !== null
      ) {
        $episode_VideoPreviewWithoutMusic = (
          <Player playsInline fluid src={episodes.videoWithoutMusicId.url} />
        );
      } else {
        $episode_VideoPreviewWithoutMusic = (
          <div className="previewText">No Video for Preview</div>
        );
      }
    }

    let $imagePreview = null;
    if (episodes.image) {
      $imagePreview = (
        <img
          className="image_Preview"
          src={episodes.image}
          alt="Episode Image"
        />
      );
    } else {
      $imagePreview = <div className="image_Preview">No Image for Preview</div>;
    }

    let isFree = "";
    if (episodes.isFree == 0) {
      isFree = "paid";
    } else if (episodes.isFree == 1) {
      isFree = "free";
    }

    let audio = "";

    if (
      episodes.voice !== undefined &&
      episodes.voice !== null &&
      episodes.voice !== "undefined" &&
      episodes.voice !== "null" &&
      episodes.voice !== "null"
    ) {
      audio = (
        <AudioPlayer
          src={episodes.voice}
          onPlay={(e) => console.log("onPlay")}
        />
      );
    } else {
      audio = (
        <td fluid className="episode_label">
          {" "}
          No Audio
        </td>
      );
    }

    let featured = false;
    if (episodes.featured !== null && episodes.featured !== undefined) {
      featured = episodes.featured;
    }

    if (this.props.loading === true) {

      EpisodeData = <Loader />;
    } else {
      EpisodeData = (
        <div className="container">
          {episodes.videoWithMusicId && (
            <div>

              <label className="label">Video with music</label>
              <section className="video_section">
                <RenderVideoWithMusic
                  videoKey={episodes.videoWithMusicId.vdocipherId}
                  id="VideoWithMusic"
                  foldername={EPISODE_FOLDER_NAME}
                  changedid={this.handleChangeVideoWithMusic}
                  enableEdit={false}
                ></RenderVideoWithMusic>
              </section>

              <br />
            </div>
          )}
          {episodes.videoWithoutMusicId && (
            <div>

              <label className="label">Video with music</label>
              <section className="video_section">
                <RenderVideoWithoutMusic
                  videoKey={episodes.videoWithoutMusicId.vdocipherId}
                  id="VideoWithoutMusic"
                  foldername={EPISODE_FOLDER_NAME}
                  changedid={this.handleChangeVideoWithoutMusic}
                  enableEdit={false}
                ></RenderVideoWithoutMusic>
              </section>

              <br />
            </div>
          )}
          {/* {episodes.videoWithMusicId ? (
            episodes.videoWithMusicId.url !== null && episodes.videoWithMusicId.url !== ""? (
              <div>
            
                <label className="label">Video with music</label>
                <section className="video_section">
                  <div>{$episode_VideoPreviewWithMusic}</div>
                </section>
                <br />
              </div>
            ) : null
          ) : null}
          {episodes.videoWithoutMusicId ? (
            episodes.videoWithoutMusicId.url !== null  && episodes.videoWithoutMusicId.url !== ""? (
              <div>
                <label className="label">Video without music</label>
                <section className="video_section">
                  <div>{$episode_VideoPreviewWithoutMusic}</div>
                </section>
                <br />
              </div>
            ) : null
          ) : null} */}
          <br />
          <div className="row">
            <div className="col-4">
              <section>{$imagePreview}</section>
            </div>

            <div className="col">
              <table>
                <tr>
                  <th className="episode_label"> Episode Name </th>
                  <td fluid className="episode_label">
                    {" "}
                    {episodes.name}{" "}
                  </td>
                </tr>
                <tr>
                  <th className="episode_label"> Episode Name In English </th>
                  <td fluid className="episode_label">
                    {" "}
                    {episodes.nameInEnglish}{" "}
                  </td>
                </tr>
                <tr>
                  <th className="episode_label"> Episode Number </th>
                  <td fluid className="episode_label">
                    {" "}
                    {episodes.episodeNumber}{" "}
                  </td>
                </tr>
                <tr>
                  <th className="episode_label"> Select the Series </th>
                  <td fluid className="episode_label">
                    {" "}
                    {this.state.seriesName}{" "}
                  </td>
                </tr>

                <tr>
                  <th className="episode_label"> Schedule </th>
                  <td fluid className="episode_label">
                    {GMTtolocalTime(episodes.publishDate)}
                  </td>
                </tr>
                <tr>
                  <th className="episode_label"> payment availability </th>
                  <td fluid className="episode_label">
                    {" "}
                    {isFree}{" "}
                  </td>
                </tr>

                <tr>
                  <th className="episode_label"> Sub Value </th>
                  <td fluid className="episode_label">
                    <p>{subvalue}</p>
                  </td>
                </tr>

                <tr>
                  <th className="episode_label"> Description </th>
                  <td fluid className="episode_label">
                    <p>{episodeDescription}</p>
                  </td>
                </tr>

                <tr>
                  <th className="episode_label"> Description In English </th>
                  <td fluid className="episode_label">
                    <p>{episodeDescriptionInEnglish}</p>
                  </td>
                </tr>

                <tr>
                  <th className="episode_label"> Audio </th>
                  {audio}
                </tr>

                <tr>
                  <th className="episode_label"> Featured </th>
                  {featured ? "True" : "False"}
                </tr>
              </table>
              <div className="buttons_container">
                <Link
                  to={{
                    pathname:
                      GET_EPISODE_TO_EDIT +
                      "/" +
                      this.state.seriesId +
                      "/" +
                      episodes.id,
                  }}
                >
                  <Button id="episode_view"> Edit </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row" id="episode_Question_Row">
            <label className="question_label">Question</label>
            <hr id="questionHR" />
            <br />
            {$episode_Question}
          </div>
          <br />
          <div className="row" id="episode_Question_Row">
            <br />
            <label className="question_label">Evaluation</label>
            <hr id="shorthr" />
            <br />

            {evaluationPreview}

            <div className="buttons_container">{editActivityLink}</div>
          </div>
          <div className="row" id="episode_Question_Row">
            <label className="question_label">Puzzle Game</label>
            <hr id="questionHR" />
            <br />
            {episode_Game}
            <div className={"col-3"}>
              <UploadImage
                getImageURLCallBack={this.getIconImageUrl.bind(this)}
                imageUrl={this.state.Icon_Image}
                imagepreview={this.state.Icon_Image}
                s3DirectryrName="episode/puzzlegame"
              />
            </div>
          </div>
          <div className="row" id="episode_Question_Row">
            <label className="question_label">Painting Game</label>
            <hr id="questionHR" />
            <br />
            {episode_Painting_Game}
            <div className={"col-3"}>
              <UploadImage
                getImageURLCallBack={this.getPaintingImageUrl.bind(this)}
                imageUrl={this.state.Painting_Image}
                imagepreview={this.state.Painting_Image}
                s3DirectryrName="episode/paintinggame"
              />
            </div>
          </div>
          <div className="row" id="episode_Question_Row">
            <label className="question_label">Drag Drop Game</label>
            <hr id="questionHR" />
            <br />
            {episode_drag_Drop}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="container">
          <OuterHeader />
          <div className="container">
            <div className="container-body">
              <div className="row">
                <Dashboard />
                <div className="col-9">
                  <InnerHeader />
                  <div className="main-content">
                    <div className="row">
                      <div
                        className="col-12 left-main-body"
                        id="left-main-body"
                      >
                        <NavBar />
                        <br />
                        {EpisodeData}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ViewEpisode.propTypes = {
  getEpisodeData: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  episodes: state.episodes.item,
  auth: state.firebase.auth,
  loading: state.episodes.loading,
});

export default withFirebase(
  connect(mapStateToProps, {
    getEpisodeData,
    emptyEpisodes,
    addDragDropParentIDtoRedirectionPage,
  })(ViewEpisode)
);
