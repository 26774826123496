import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class EvaluationPreview extends Component {

    render() {
        const { evaluationCategory } = this.props;
        console.log(evaluationCategory)

        let evaluationList = 'No Evaluation', excellent = '', good = '', bad = '';

        if (evaluationCategory !== null
            && evaluationCategory !== undefined
            && evaluationCategory[0] !== null
            && evaluationCategory[0] !== undefined

        ) {

            if (evaluationCategory[0].excellent !== null
                && evaluationCategory[0].excellent !== undefined) {

                excellent = (
                    <tr>
                        <th id="evaluationtd"> Excellent</th>
                        <td id="evaluationtd"> {evaluationCategory[0].excellent.startNumber}    </td>
                        <td id="evaluationtd"> {evaluationCategory[0].excellent.endNumber}      </td>
                        <td id="evaluationtd"> {evaluationCategory[0].excellent.recommendation} </td>
                        <td id="evaluationtd">
                            <Link to={evaluationCategory[0].excellent.uploadedFile} >
                                {evaluationCategory[0].excellent.uploadedFile}
                            </Link>
                        </td>
                        <td id="evaluationtd">
                            <Link to={evaluationCategory[0].excellent.articleUrl} >
                                {evaluationCategory[0].excellent.articleUrl}
                            </Link>
                        </td>
                    </tr>
                )
            }

            if (evaluationCategory[0].good !== null
                && evaluationCategory[0].good !== undefined) {

                good = (
                    <tr>
                        <th id="evaluationtd"> Good</th>
                        <td id="evaluationtd"> {evaluationCategory[0].good.startNumber}    </td>
                        <td id="evaluationtd"> {evaluationCategory[0].good.endNumber}      </td>
                        <td id="evaluationtd"> {evaluationCategory[0].good.recommendation} </td>
                        <td id="evaluationtd">
                            <Link to={evaluationCategory[0].good.uploadedFile} >
                                {evaluationCategory[0].good.uploadedFile}
                            </Link>
                        </td>
                        <td id="evaluationtd">
                            <Link to={evaluationCategory[0].good.articleUrl} >
                                {evaluationCategory[0].good.articleUrl}
                            </Link>
                        </td>
                    </tr>
                )
            }

            if (evaluationCategory[0].bad !== null
                && evaluationCategory[0].bad !== undefined) {

                bad = (
                    <tr>
                        <th id="evaluationtd"> Bad</th>
                        <td id="evaluationtd"> {evaluationCategory[0].bad.startNumber}    </td>
                        <td id="evaluationtd"> {evaluationCategory[0].bad.endNumber}      </td>
                        <td id="evaluationtd"> {evaluationCategory[0].bad.recommendation} </td>
                        <td id="evaluationtd">
                            <Link to={evaluationCategory[0].bad.uploadedFile} >
                                {evaluationCategory[0].bad.uploadedFile}
                            </Link>
                        </td>
                        <td id="evaluationtd">
                            <Link to={evaluationCategory[0].bad.articleUrl} >
                                {evaluationCategory[0].bad.articleUrl}
                            </Link>
                        </td>
                    </tr>
                )
            }


            if ((evaluationCategory[0].excellent !== null
                && evaluationCategory[0].excellent !== undefined)
                || (evaluationCategory[0].good !== null
                    && evaluationCategory[0].good !== undefined)
                ||
                (evaluationCategory[0].bad !== null
                    && evaluationCategory[0].bad !== undefined)
            ) {

                evaluationList = (

                    <div className="table-responsive">

                        <table className="table ">
                            <tr>
                                <th>Category</th>
                                <th>Start Number</th>
                                <th>End Number</th>
                                <th>recommendation</th>
                                <th>uploadedFile</th>
                                <th>articleUrl</th>
                            </tr>

                            {excellent}
                            {good}
                            {bad}
                        </table>

                    </div>

                )
            }

        }
        else {
            return (<div>No Evaluation</div>)
        }

        return (
            <div>
                {evaluationList}
            </div>
        )
    }


}

export default EvaluationPreview
