import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withFirebase } from '../../config';
/*Redux*/
import { connect } from 'react-redux';
import {
    deleteEpisodeDragAndDrop,
    getEpisodeData
} from '../../Actions/EpisodeActions';
import {
    deleteSongDragAndDrop,
    getSongData
} from '../../Actions/SongActions';
import {
    deleteStoryDragAndDrop,
    getStoryData
} from '../../Actions/StoryActions';
import {
    deleteChannelDragAndDrop,
    getChannelData
} from '../../Actions/RadioChannelAction';

import {
    deleteBookDragAndDrop,
    getBookData
} from '../../Actions/ReadWithJeelAction';

import {
    deletePdfBookDragAndDrop,
    getPdfBookData
} from '../../Actions/PdfBookAction';

/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import '../../css/AppCss/gamePreview.css'

import '../../css/AppCss/gamePreview.css';
import { SIGN_IN , ADD_DRAG_DROP, DRAG_DROP_TO_EDIT} from '../../routes';


class DragDropPreview extends Component {

    constructor(props) {
        super(props);

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
    }

    handleDeleteImage(e, id) {
        const { state, parentId } = this.props;

        if (state === "viewStory") {
            deleteStoryDragAndDrop(id)
                .then(res => {
                    alert("Drag & Drop Item Deleted Successfully");
                    this.props.getStoryData(parentId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        if (state === "viewSong") {
            deleteSongDragAndDrop(id)
                .then(res => {
                    alert("Drag & Drop Item Deleted Successfully");
                    this.props.getSongData(parentId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        if (state === "viewEpisode") {
            deleteEpisodeDragAndDrop(id)
                .then(res => {
                    alert("Drag & Drop Item Deleted Successfully");
                    this.props.getEpisodeData(parentId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        if (state === "viewChannel") {
            deleteChannelDragAndDrop(id)
                .then(res => {
                    alert("Drag & Drop Item Deleted Successfully");
                    this.props.getChannelData(parentId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (state === "viewBook") {
            deleteBookDragAndDrop(id)
                .then(res => {
                    alert("Drag & Drop Item Deleted Successfully");
                    this.props.getBookData(parentId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (state === "viewPdfBook") {
            deletePdfBookDragAndDrop(id)
                .then(res => {
                    alert("Drag & Drop Item Deleted Successfully");
                    this.props.getPdfBookData(parentId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
    }


    render() {
        const { games } = this.props;

        return (
            <div className="container">

                <div className="row" >
                    <div className="col-12">
                        <div className="row">

                            <div className="col-10">

                                <span className="font-italic small-font">
                                    <div className="row">
                                        {games !== null && games.map((game, index) => {
                                            return (
                                                <div className="col-4 gamePreview dragDrop">
                                                    <span onClick={e => this.handleDeleteImage(e, game.dragDropId)}>
                                                        <FontAwesomeIcon icon="times"
                                                            color="#dc6d6d"
                                                            className="true" />
                                                    </span>

                                                    <Link to={DRAG_DROP_TO_EDIT +"/" + game.dragDropId}><span>
                                                        <FontAwesomeIcon
                                                            icon="edit"
                                                            color="#888"
                                                            className="true" />
                                                    </span>
                                                    </Link>

                                                    <img className="image_Preview"
                                                        alt={game.url}
                                                        src={game.url} />
                                                </div>
                                            )
                                        })}
                                        {games !== null
                                            && games.length < 1
                                            &&
                                            <div className="col-12">No games</div>}

                                        <div className="col-4 gamePreview ">

                                            <Link to={ADD_DRAG_DROP}>
                                                <label id="" className="new-items">
                                                    <div id="" className="add-box">
                                                        <p className="small-font  mb-0 upload-icon-p">
                                                            Click to Upload icon</p>
                                                    </div>

                                                </label>

                                            </Link>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.item
});

export default withFirebase(
    connect(mapStateToProps,
        {
            getEpisodeData,
            getSongData,
            getStoryData,
            getChannelData,
            getBookData,
            getPdfBookData
        })
        (DragDropPreview));
