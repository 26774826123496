import {
    LOAD_All_DURATION_OF_USE, FETCH_All_DURATION_OF_USE, ERROR_ALL_DURATION_OF_USE,
    LOAD_DURATION_OF_USE, 
    ERROR_ADDING_DURATION_OF_USE, ADD_DURATION_OF_USE ,
     DELETING_DURATION_OF_USE, ERROR_DELETING_DURATION_OF_USE,
     EMPTY_DURATION
} from './Types'
import axios, { post  } from 'axios'

export const fetchAllDuration = () => dispatch => {

    dispatch({
        type: LOAD_All_DURATION_OF_USE,
        payload: 'loading'
    })

    axios.get('/DurationOfUse/getAllDurationOfUseList')
        .then((res) => {
            dispatch({
                type: FETCH_All_DURATION_OF_USE,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_ALL_DURATION_OF_USE,
                payload: Error.message
            })
        })
}


export const addDurationOfUse = (text, articleUrl , programUrl, duration) => {
    console.log(text+" " +  articleUrl
        +" " + programUrl + " "+ duration)

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_DURATION_OF_USE,
                payload: 'loading'
            })

            const url = "/DurationOfUse/addDurationOfUse";

            const formData = new FormData();
            formData.append('Text', text);
            formData.append('File_Article_Url', articleUrl);
            formData.append('File_Program_url', programUrl);
            formData.append('Duration', duration);


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_DURATION_OF_USE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_DURATION_OF_USE,
                    payload: Error.response.data.message
                })
            })
        })

    }
}

export const deleteDuration = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_DURATION_OF_USE,
                payload: 'loading'
            })

            const url = "/DurationOfUse/deleteDurationOfUse";
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_DURATION_OF_USE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_DURATION_OF_USE,
                    payload: Error.response.data.message
                })
            })
        })

    }
}

export const emptyDurationResponse = () => dispatch => {
    dispatch({
        type: EMPTY_DURATION,
        payload: ''
    })
}