import React, { Component } from 'react'
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import "../../css/AppCss/PublishDateComponent.css";

const INITIAL_STATE = {
  publishDate: "",
  publishTime: "",
  publishDateTime: "",
  radioButton: "schedule_Time",
}

var scheduleChoice = true;
var tempDate = null;
var today = new Date();

function publishDateFormate(date) {
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  //January is 0!
  var yyyy = date.getFullYear();
  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  date = mm + '/' + dd + '/' + yyyy + " " + date.getHours() + ":" + date.getMinutes();
  return date;
}


export class PublishDate extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }


  handleRadioChange = (e) => {

    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === "now") {

      var date = new Date(today);
      var utcString = date.toUTCString();
      var gmtDate = new Date(utcString.split('GMT')[0]);

      this.setState({ publishDateTime: publishDateFormate(gmtDate) });
      scheduleChoice = false;
      console.log("Now: publishDateTime= " + publishDateFormate(gmtDate));
      this.props.getPublishDateCallBack(publishDateFormate(gmtDate), "now");
    }
  }

  componentDidMount() {
    if (this.props.date !== undefined) {

      var date = new Date(this.props.date + " UTC")
      var d = new Date(date.toString())
      var d = publishDateFormate(d)

      let dates = d.split(' ');
      this.setState({ publishDate: dates[0], publishTime: dates[1] })

      this.setState({ publishDateTime: d })
    }
  }



  componentWillReceiveProps(props) {
    if (props.date !== undefined && props.date !== null) {

      var date = new Date(props.date + " UTC")
      var d = new Date(date.toString())
      var d = publishDateFormate(d)

      let dates = d.split(' ');

      this.setState({ publishDate: dates[0], publishTime: dates[1] })

      this.setState({ publishDateTime: d })
    }
  }

  handleDateTimeChange = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      let attrName = name;
      let attrValue = value;
      //this.setState({ [name]: value });
      if (name === "publishDate") {
        tempDate = value + " " + this.state.publishTime;
        var date = new Date(tempDate);
        if (date != "Invalid Date") {
          var utcString = date.toUTCString();
          var gmtDate = new Date(utcString.split('GMT')[0]);
          this.setState({ [attrName]: attrValue, publishDateTime: publishDateFormate(gmtDate) });
          console.log("handleDateTimeChange: publishDateTime= " + publishDateFormate(gmtDate));
          this.props.getPublishDateCallBack(publishDateFormate(gmtDate), "schedule");
        }
      }
      else if (name === "publishTime") {
        tempDate = this.state.publishDate + " " + value;
        var date = new Date(tempDate);
        if (date != "Invalid Date") {
          var utcString = date.toUTCString();
          var gmtDate = new Date(utcString.split('GMT')[0]);
          this.setState({ [attrName]: attrValue, publishDateTime: publishDateFormate(gmtDate) });
          console.log("handleDateTimeChange: publishDateTime= " + publishDateFormate(gmtDate));
          this.props.getPublishDateCallBack(publishDateFormate(gmtDate), "schedule");
        }
      }
    }
  }

  render() {
    const { showNow } = this.props;

    let nowComponent = (<label className=" label col">
      <input type="radio" name="radioButton" value="now" onChange={this.handleRadioChange} required />
      Now
    </label>
    )
    if (showNow === false) {
      nowComponent = ''
    }

    const radioButton = this.state.radioButton;

    // console.log("radioButton= " + radioButton);

    let radiOption = null;
    if (radioButton === "schedule_Time") {

      scheduleChoice = true;

      radiOption = (<div className="form-inline" >

        <DateInput className="col"
          name="publishDate"
          closeOnMouseLeave

          value={this.state.publishDate}
          placeholder="Add Date"
          onChange={this.handleDateTimeChange}
          iconPosition="right"
          dateFormat="MM/DD/YYYY"
          required
        />
        <TimeInput className="col"
          name="publishTime"
          value={this.state.publishTime}
          onChange={this.handleDateTimeChange}
          placeholder="Add Time"
          iconPosition="right"
          required
        />
      </div>);
    }


    return (
      <div>

        <label htmlFor="date" className="label">
        <span className="required_input">*</span>
          Schedule</label> <br />

        <div className="form-inline">

          <label className=" label " id="tab">
            <input type="radio" name="radioButton" value="schedule_Time" checked={scheduleChoice} onChange={this.handleRadioChange} required />
            Schedule Time
          </label>

          {nowComponent}

        </div>

        <br />
        {radiOption}

      </div>
    )
  }
}

export default PublishDate
