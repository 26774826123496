import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Components */
import PromoCodes from './PromoCodes';
import PromoCodeDetails from './PromoCodeDetails';
import AddPromoCode from './AddPromoCode';
import EditPromoCode from './EditPromoCode';

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';

/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, PROMO_CODES,
         VIEW_PROMO_CODE, ADD_PROMO_CODE,
         EDIT_PROMO_CODE} from '../../routes';

class PromoCodeAuth extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { userPaymentProfile } = this.props;
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    const { userPaymentProfile } = this.props;
    if(userPaymentProfile !== undefined){
      if(userPaymentProfile.user_role.role !== "SuperAdmin" &&
         userPaymentProfile.user_role.role !== "ADMIN" &&
         userPaymentProfile.user_role.role !== "AppManagement"){
        return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
      }
    }
    return (
      <span>
          {/* adding PromoCodes Routers */}
          <Route path={PROMO_CODES} component={PromoCodes} />
          <Route path={VIEW_PROMO_CODE} component={PromoCodeDetails} />
          <Route path={ADD_PROMO_CODE} component={AddPromoCode} />
          <Route path={EDIT_PROMO_CODE} component={EditPromoCode} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(PromoCodeAuth)
);
