import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Input, Button, Modal } from 'semantic-ui-react'

/*components*/
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    updateMainValue,
    emptyMainValue,
    fetchAllMainValues
} from '../../Actions/ContentMainValuesAction'

const INITIAL_STATE = {

    showModal: '',
    /////////// FAQ
    id: 0,
    text: '',
    text_in_english: '',
    number: 0,
    imageUrl: '',
    description: '',
    description_in_english: '',

};

export class EditMainValue extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }


    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    updateMainValue(id, text, description, imageUrl, number, text_in_english, description_in_english) {
        this.props.updateMainValue(id, text,
            description, imageUrl, number, text_in_english, description_in_english);
    }

    getImageUrl(imageUrl) {

        if (imageUrl) {
            this.setState({ imageUrl: imageUrl });
            console.log("imgURL in edit Main Value = " + this.state.imageUrl);
        }
    }

    handleChange = (e) => {

        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleSubmit = (e) => {

        const { id, text, number,
            imageUrl,
            description, text_in_english, description_in_english } = this.state;

        this.updateMainValue(id, text,
            description, imageUrl,
            number, text_in_english, description_in_english)

        e.preventDefault();
        this.setState({
            text: '',
            id: 0,
            number: 0,
            imageUrl: '',
            description: '',
            text_in_english: '',
            description_in_english: '',
        })

    }

    closeModal = (e) => {
        this.setState({ showModal: false })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
        const { mainValueItem } = this.props;
        console.log(mainValueItem,"data");
        this.setState({
            id: mainValueItem.id,
            text: mainValueItem.contentValue,
            number: mainValueItem.number,
            imageUrl: mainValueItem.imageUrl,
            description: mainValueItem.description,
            text_in_english: mainValueItem.contentValueInEnglish,
            description_in_english: mainValueItem.descriptionInEnglish,
        })
    }

    render() {

        const { error } = this.props;
        const { success } = this.props;


        const { loading } = this.props;
        const { text, number, imageUrl, text_in_english } = this.state;
        const isInvalidText =
            text === '' ||
            number === 0 ||
            imageUrl === ''||
            text_in_english === ''||
            text_in_english === null; 

        let $buttonSubmitLoader = (
            <Button onClick={this.handleSubmit}
                disabled={isInvalidText}
                id="saveButton" >
                Save
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="saveButton"
                    class="ui loading button">
                    Loading</button>);
        }

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyMainValue();

        }
        else if (success === true) {
            alert("Main Value Updated Successfully")
            this.props.emptyMainValue();

            this.closeModal();
            this.props.fetchAllMainValues();
        }

        return (
            <Modal id="modal"
                trigger={
                    <button className="acor-btn1" type="submit"
                        onClick={this.openModal}>
                        Edit
                    </button>
                }

                onClose={this.closeModal}
                open={this.state.showModal}>
                <Modal.Header>Edit Main Value</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">

                                    {/* image Uploader */}
                                    <div className="col-md-4 col-12">
                                        <div className="">
                                            <span className="required_input">*</span>

                                            <UploadImageWithoutDropZone
                                                getImageURLCallBack={this.getImageUrl.bind(this)}
                                                imageUrl={this.state.imageUrl}
                                                imagepreview={this.state.imageUrl}
                                                s3DirectryrName='contentvalue' />


                                        </div>

                                    </div>


                                    <div className="col-md-8 col-12">


                                        <label htmlFor="text"
                                            className="label">
                                            <span className="required_input">*</span>
                                            Text</label>
                                        <input fluid name="text"
                                            dir="auto"
                                            className="form-control"

                                            id="episode_description"

                                            onChange={this.handleChange}
                                            value={this.state.text} />
                                        <br />

                                        <label htmlFor="text_in_english"
                                            className="label">
                                            <span className="required_input">*</span>
                                            Text In English</label>
                                        <input fluid name="text_in_english"
                                            dir="auto"
                                            className="form-control"

                                            id="text_in_english"

                                            onChange={this.handleChange}
                                            value={this.state.text_in_english} />
                                        <br />

                                        <label htmlFor="number"
                                            className="label">
                                            <span className="required_input">*</span>
                                            Number</label>
                                        <br />
                                        <Input fluid type="number" min="0"
                                            name="number" id="number"
                                            onChange={this.handleChange}
                                            value={this.state.number} />
                                        <br />

                                        <label htmlFor="description"
                                            className="label">Description</label><br />
                                        <textarea fluid rows="6"
                                            dir="auto"
                                            className="form-control"

                                            name="description"
                                            id="episode_description"
                                            onChange={this.handleChange}
                                            value={this.state.description} />
                                        <br />

                                        <label htmlFor="description_in_english"
                                            className="label">Description In English</label><br />
                                        <textarea fluid rows="6"
                                            dir="auto"
                                            className="form-control"

                                            name="description_in_english"
                                            id="description_in_english"
                                            onChange={this.handleChange}
                                            value={this.state.description_in_english} />
                                        <br />

                                    </div>

                                </div>

                                <div className="input-field">

                                    {$buttonSubmitLoader}

                                </div>
                                {error && <p>{error.message}</p>}
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

EditMainValue.propTypes = {
    updateMainValue: propTypes.func.isRequired,
    fetchAllMainValues: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    mainValues: state.contentMainValue.item,
    showModal: state.contentMainValue.showModal,
    error: state.contentMainValue.updating_main_values_error,
    success: state.contentMainValue.updating_main_values_sucessfully,
    loading: state.contentMainValue.loading

});


export default
    withFirebase
        (connect
            (mapStateToProps,
                {
                    updateMainValue,
                    emptyMainValue,
                    fetchAllMainValues
                }
            )
            (EditMainValue)
        );

