import React, { Component } from 'react'
import testimonial1 from '../../../images/portal/testimonial1.png'
import testimonial2 from '../../../images/portal/testimonial2.png'
import testimonial3 from '../../../images/portal/testimonial3.png'
import testimonial4 from '../../../images/portal/testimonial4.png'
import testimonial5 from '../../../images/portal/testimonial5.png'
import testimonialImage from '../../../images/portal/image.png';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import "../../../css/portal-style.css";


 class Testimonials extends Component {

    state={
        responsive:{
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    }
    render() {
        return (
            <span>
            {/** Start Testimonials */}
          <section className="testimonials">
            <div className="container">
                <div className="testimonial-title h2">
                    آراء من عائلة جيل
                </div>
                <div className="owl-container">
                    <div className="testimonial-bg d-md-block">
                        <img src={testimonialImage} alt=""/>
                    </div>
                    <OwlCarousel className={'owl-theme owl-rtl'}
                      loop={true}
                      margin={8}
                      nav={true}
                      dots={false}
                      autoplay={true}
                      autoplayTimeout={3000}
                      items={3}
                      responsive={this.state.responsive} >
                      <div className={'item'}>
                        <img src= {testimonial1} alt=""/>
                      </div>
                      <div className={'item'}>
                        <img src= {testimonial2} alt=""/>
                      </div>
                      <div className={'item'}>
                        <img src= {testimonial3} alt=""/>
                      </div>
                      <div className={'item'}>
                        <img src= {testimonial4} alt=""/>
                      </div>
                      <div className={'item'}>
                        <img src= {testimonial5} alt=""/>
                      </div>
                      </OwlCarousel>
                </div>
            </div>
          </section>
          {/** End Testimonials **/}
          </span>
        )
    }
}

export default Testimonials
