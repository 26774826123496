import {
    CONTENT_VENDOR_FETCH_ALL, 
    CONTENT_VENDOR_LOAD_START, 
    CONTENT_VENDOR_LOAD_ERROR,
    CONTENT_VENDOR_UPDATE_SUCCESSFULLY,
    CONTENT_VENDOR_UPDATE_ERROR,
    CONTENT_VENDOR_GET_DATA_SUCCESSFULLY,
    CONTENT_VENDOR_GET_DATA_ERROR,
    CONTENT_VENDOR_EMPTY,
    CONTENT_VENDOR_ADD_SUCCESSFULLY,
    CONTENT_VENDOR_ADD_ERROR
    
} from './Types'
import axios from 'axios'
import { post } from 'axios';

    
export const fetchAllContentVendors = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: CONTENT_VENDOR_LOAD_START,
                payload: 'loading'
            })
            const url = "/ContentVendor/getAllContentVendorList"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
	
	//console.log('Vecdors : '+JSON.stringify(res.data,null,2));
	
                dispatch({
                    type: CONTENT_VENDOR_FETCH_ALL,
                    payload: res.data
                })

            }).catch((Error) => {
                 dispatch({
                type: CONTENT_VENDOR_LOAD_ERROR,
                payload: Error.message
            })
            })

        })

    }
}

export const getContentVendorData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: CONTENT_VENDOR_LOAD_START,
                payload: 'loading'
            })

            const url = "/ContentVendor/getContentVendorDetails";
            const formData = new FormData();
            formData.append('contentVendorId', id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: CONTENT_VENDOR_GET_DATA_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: CONTENT_VENDOR_GET_DATA_ERROR,
                    payload: Error.message
                })
            })
        })

    }
}



export const editContentVendor = (id, userName, country, phone, note,city, addess) => {
	
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: CONTENT_VENDOR_LOAD_START,
                payload: 'loading'
            })
            console.log(note)
            console.log(city)
            console.log(addess)
    
            const url = "/ContentVendor/updateContentVendor";
            const formData = new FormData();
            formData.append('Id', id);
            formData.append('Name', userName);
            formData.append('Country', country);
            formData.append('Phone', phone);
            formData.append('Note', note);
            formData.append('City', city);
            formData.append('Address', addess);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: CONTENT_VENDOR_UPDATE_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                
                // console.log(Error.respose.data.message)
                dispatch({
                    type: CONTENT_VENDOR_UPDATE_ERROR,
                    payload: Error.message
                    
                })

            })
        })

    }
}


export const emptyContentVendor = () => dispatch => {    
    dispatch({
        type: CONTENT_VENDOR_EMPTY,
        payload: ""
    })
}

