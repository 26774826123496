import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { deleteAdmin, fetchAllAdmins, emptyAdmin }
from '../../Actions/UserManagementActions'

class DeleteAdmin extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteAdmin(uid) {
        this.props.deleteAdmin(uid);
    }


    handleSubmit = (e) => {

        const { uid } = this.props;

        console.log("uid delete = " + uid)

        this.deleteAdmin(uid);
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        // console.log(error)
        // console.log(success)

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyAdmin();
        }
        else if (success === true) {
            alert("Admin Deleted Successfully")
            this.props.emptyAdmin();
            this.props.fetchAllAdmins();
        }

        return (
            <span >
            <Popup trigger=
            {
                <a class="trash" onClick={this.open}>
                    <i><FontAwesomeIcon icon="trash" /></i>
                </a>
            }
            content='Delete'
            position='top center'
            />

                <Confirm id="modal"
                    content='Are you sure you want
                    to delete this admin ??'
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </span>

        )
    }
}


DeleteAdmin.propTypes = {
    deleteAdmin: propTypes.func.isRequired,
    fetchAllAdmins: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    loading: state.admins.loading,
    error: state.admins.deleting_admin_error,
    success: state.admins.deleting_admin_sucessfully
});


export default withFirebase
    (connect
        (mapStateToProps,
            { deleteAdmin, emptyAdmin, fetchAllAdmins })
        (DeleteAdmin)
    );
