import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import { Popup } from 'semantic-ui-react'

/*Components */
import Loader from '../Layout/Loader';
/*CSS */
import 'react-table/react-table.css';
/*Routes*/
//import { CONTENT_GET_VENDOR_TO_EDIT } from '../../routes';

import { Axios } from 'axios';

/**Actions */
import { getAllContentVendorsProfits , emptyContentVendorProfits } from '../../Actions/ProfitShareActions';





const INITIAL_STATE = {

    suggestedProfit: '',

    ////////////////// validation form
    error: '',
    errorMessage: "",
};


class ProfitShareTabel extends Component {



    constructor(props) {
        super(props);
        
        this.state = { ...INITIAL_STATE };
        //this.state={suggestedProfit:0}
    }

    componentDidMount() {
	    //this.props.getAllContentVendorsProfits();
	    this.props.emptyContentVendorProfits();
    }    
    
    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });

        
        setTimeout(() => {
            if ((this.state.suggestedProfit === '' || this.state.suggestedProfit === null || this.state.suggestedProfit === undefined)) 
            {
                this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
            }
        }, 0);
    }
        
    
    handleSubmit = (e) => {

        const { suggestedProfit } = this.state;
        //this.props.saveVendor(email, password, name, phone, country, note, contractType, city, influencerInfo, addess);
        
        //console.log('suggestedProfit: '+suggestedProfit);
		
        
        this.props.getAllContentVendorsProfits(suggestedProfit);
        
        e.preventDefault();

    }
      
    clearProfits = () =>{
		this.props.emptyContentVendorProfits();
	}    

    render() 
    {

		console.log('All props in render , Json is: '+JSON.stringify(this.props,null,2));
		
		const { profitShareList,fetchProfitShareListError, loading, fetchProfitShareListSuccessfully} = this.props;
		const { State_Profit_Share_ByVendors_Fetch_sucessfully,State_Items_ByVendors,State_Profit_Share_ByVendors_Fetch_Error} = this.props;
		
		//console.log('Final profitShareList : '+JSON.stringify(profitShareList,null,2));
		
		// ==============================================
        
        const columns = [
            
            {
                Header: "Content Vendor Name",
                accessor: "contentVendorName",
                width: 109,
                MinWidth: 140,
                Maxwidth: 140,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (

                        <div style={{ "textAlign": "center" }}>
                            <Link className="link" to={{ pathname: props.original.id }}>
                                {props.original.contentVendorName}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: "profit",
                accessor: "gainedProfit",
                width: 100,
                filterable: false,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <Link className="link" to={{ pathname: props.original.id }}>
                                {props.original.gainedProfit}
                            </Link>
                        </div>
                    )
                }
            }
        ]
// ==============================================
        let tableForProfitByVendors =  '';
        let currArr;

		if(State_Profit_Share_ByVendors_Fetch_sucessfully===true)
		{						
			
			tableForProfitByVendors =  <div className="card mt-3 p-0">
										        <div className="card-body p-4">
    												<div className="row">       	
    													<div className="col-8">										    
													            {/* <!-- start form --> */}
													            <form className="alluserMng" onSubmit={(e)=> e.preventDefault()}>
													                <div className="mt-4 responsive-table">
													                            {/* Admins Table */}                    
																				<ReactTable
																	
																	                columns={columns}
																	                data={State_Items_ByVendors}
																	                defaultPageSize={5}
																	                className="-striped -highlight"
																	                showPagination={false}
																	            >
																	
																	            </ReactTable>				                                
													                </div>
													            </form>										        
									    				</div>
									    				<div className="col-4"></div>
	   
    												</div>
												</div>
										    </div>  
		}
		
		let profitByCurrency = <div className="container">
                                                    <span className="general-setting-form" >
                                                        <fieldset className='fieldset'>
                                                            <legend className="legend">Revenue Per Currency</legend>

																	<div className="card-body p-4">
																		<table border="1">
																	      <thead>
																	        <tr>
																	          <th>Profit  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   </th>
																	          <th>Currency &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </th>
																	          <th>Convert Profit To USD &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </th>
																	          <th>Convert Profit To EGP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </th>
																	          <th>ToDay Rate to USD &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </th>
																	        </tr>
																	      </thead>
																	      <tbody>
																	        {profitShareList.map((p) => (
																	          <tr key={p.profit}>
																	            <td>{parseFloat(p.profit).toFixed(2)}</td>
																	            <td>{p.currency}</td>
																	            <td>{Number(p.profitWithUSD).toFixed(2)}</td>
																	            <td>{Number(p.profitWithEGP).toFixed(2)}</td>
																	            <td>{Number(p.rateToUSD).toFixed(2)}</td>
																	          </tr>
																	        ))}
																	      </tbody>
																	    </table>
																    </div>
																    
																    <div className="card-body p-4"> Total : &nbsp;&nbsp;
																    
																    {parseFloat(profitShareList.reduce((prev,curr,ind)=>{return prev+curr.profitWithEGP},0)).toFixed(2)}&nbsp;&nbsp;&nbsp; EGP																         
																       
																    </div>                                                            

                                                        </fieldset>

                                                        <fieldset className='fieldset'>
                                                            <legend className="legend">Share The Profit</legend>

                                                            <div className="ui form">

					                                                <div className="row">
					                                                    <form className="col-12" onSubmit={(e)=> e.preventDefault()}>
					                                                       
					                                                        <div className="col-4">
								                                                        
								                                                 <span style={{ color: "red" }}>* </span>  Revenue To Be Shared <br/><br/>
								                                                        	    	
								                                            </div>             	
								                                                        
					                                                        <div className="col-4">
						                                                        
						                                                            
						                                                            <input
							                                                            type="number"
							                                                            dir="auto"
							                                                            className="form-control"
							                                                            fluid name="suggestedProfit" 
							                                                            id="suggestedProfit"
							                                                            onChange={this.handleChange}
							                                                            value={this.state.suggestedProfit}
							                                                            required />
							                                                        <br />
                                                        
					                                                        </div>
					                                                        <div class="col-4"> </div>
					                                                        
					                                                        <br />
					                                                        
					                                                        
					                                                     </form>
					                                                </div>       

                                                            </div>
                                                            
															<div className="box-container">                                                
															
																		<button onClick={this.handleSubmit} id="nextButton" disabled={false} className="seriesButtons">
															                Distribute Profits
															            </button>  
															            
																		<button onClick={this.clearProfits} id="clearButton" disabled={false} className="seriesButtons">
															                Clear Profit
															            </button>  
															            															            
															            
															            
															    <h4 className="main-title mt-2 font-weight-bold"> The Profits </h4>
															</div>


															{tableForProfitByVendors}

    

                                                        </fieldset>

                                                    </span>


                                                </div>

        //if (loading === true) 
        //{
            //profitShares = (<Loader />)
        //}                
		//else 
		if(fetchProfitShareListSuccessfully === true && fetchProfitShareListError === '' && profitShareList.length > 0 )
		{                                                
			
					return ( profitByCurrency );			
		}  
		
			return (
                        <div className="ui form">
                           No Content
                        </div>				
			       );
		
		     
    }
}


ProfitShareTabel.propTypes = {
    //getAllCurrenyProfits: propTypes.func.isRequired,
    //getAllContentVendorsProfits: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    profitShareList: state.profitShareReducer.State_items,
    loading: state.profitShareReducer.State_loading,
    vendorsReport: state.profitShareReducer.items,
    
    //error: state.profitShareReducer.State_Content_Vendor_Load_Error,
    //success: state.profitShareReducer.State_Content_Vendor_Load_sucessfully,
    
    State_Items_ByVendors: state.profitShareReducer.State_Items_ByVendors,
	State_Profit_Share_ByVendors_Fetch_sucessfully:state.profitShareReducer.State_Profit_Share_ByVendors_Fetch_sucessfully,
	State_Profit_Share_ByVendors_Fetch_Error:state.profitShareReducer.State_Profit_Share_ByVendors_Fetch_Error,


});

export default withRouter
    (connect
        (mapStateToProps,
            { getAllContentVendorsProfits , emptyContentVendorProfits })
        (ProfitShareTabel)
    );
