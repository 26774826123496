import { Promise } from 'q';
import store from '../Store';
import firebase from 'firebase/app';
import {post} from 'axios'
import 'firebase/firestore';
import 'firebase/auth';


export function saveFawryOrder (packageType,token, cash)
{
    return new Promise (function(resolve,reject)
    {

        const url = '/FawryOrder/addDefaultFawryOrder';

        const formData = new FormData();
        formData.append('Package_type', packageType);
        formData.append('Paid_price' , cash);

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': token
            }
        }
        post(url, formData, config).then((res)=>
        {
            resolve(res);
            return res;
        }).catch((Error)=>
        {
            reject(Error)
            return Error.message
        })
    })
}


export function saveFawryOrderWithCode (packageType, codeUid, token, cash)
{
    return new Promise (function(resolve,reject)
    {

        const url = '/FawryOrder/addFawryOrderWithCode';
        if(codeUid === undefined || codeUid === null || codeUid === ''){
          codeUid = null;
        }
        const formData = new FormData();
        formData.append('Package_type', packageType);
        formData.append('Code_UID', codeUid);
        formData.append('Paid_price' , cash);

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': token
            }
        }
        post(url, formData, config).then((res)=>
        {
            resolve(res);
            return res;
        }).catch((Error)=>
        {
            reject(Error)
            return Error.response.data.message
        })
    })
}
