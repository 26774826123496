import {
    ADD_EVALUATION,
    EMPTY_EVALUATION, EMPTY_EVALUATIONAll
} from './Types'
import axios, { post, put } from 'axios'

export const addEvaluationToRedux = (start_number, end_number, recommendation, uploaded_file, blogsIds, evaluation_category, articleUrl) => dispatch => {
    const evaluation = {
        startNumber: start_number,
        endNumber: end_number,
        recommendation: recommendation,
        uploadedFile: uploaded_file,
        blogs: blogsIds,
        evaluation_category: evaluation_category,
        articleUrl: articleUrl

    }

    dispatch({
        type: ADD_EVALUATION,
        payload: evaluation
    })
}


export const emptyEvaluationSucessAndfailure = () => dispatch => {
    dispatch({
        type: EMPTY_EVALUATION,
        payload: ''
    })
}

export const emptyEvaluationAll = () => dispatch => {
    dispatch({
        type: EMPTY_EVALUATIONAll,
        payload: ''
    })
}

