import React, { Component } from 'react'
import { TextArea, Input, Button, Icon, Label, Modal } from 'semantic-ui-react'
import { Link, Redirect } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react'
import Switch from "react-switch"
import placeHolder from '../../images/placeholder.png'

/**Other Component */
import Loader from '../Layout/Loader'
import Viewchildren from '../Children/Viewchildren'
import ResetPasswordCustomer from './ResetPasswordCustomer';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader'
import Dashboard from '../Dashboard'
import InnerHeader from '../Layout/InnerHeader'
import Footer from '../Layout/Footer'
/*Redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
/* Actions*/
import { customerResetPassword } from '../../Actions/CustomerActions'
import { getCustomerProfileData } from '../../Actions/CustomerActions'
import { getUserData } from '../../Actions/MobilePaymentProfileAction';

import { withFirebase } from '../../config';

/* Routes */
import { CUSTOMERS } from '../../routes';

const INITIAL_STATE = {
    back: false,
    showModel: false,
    password: '',
    confirmPassword: '',
    confirmed: false,
    errorMessage: ''
}

class ViewCustomer extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    componentDidMount() {

        let customerUid = this.props.match.params.customerUid;
        this.props.getCustomerProfileData(customerUid);
    }

    customerResetPassword(uid, password) {
        this.props.customerResetPassword(uid, password);
    }

    handleBack = (e) => {
        this.setState({ back: true });
    }

    openModel = (e) => {
        this.setState({ showModel: true });
    }

    closeModel = (e) => {
        this.setState({ showModel: false, password: '', confirmPassword: '' })
    }

    handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            if (this.state.password === null || this.state.confirmPassword === null || this.state.password === "" || this.state.password === " "
                || this.state.confirmPassword === "" || this.state.confirmPassword === " ") {
                this.setState({ confirmed: false, errorMessage: "Empty Data is not allowed" });
            }
            else if (this.state.password !== this.state.confirmPassword) {
                this.setState({ confirmed: false, errorMessage: "Passwords do not match" });
            }
            else {
                this.setState({ [name]: value });
                this.setState({ confirmed: true, errorMessage: "" });
            }
        }, 0);
    }

    handleSubmit = (e) => {
        let uid = this.props.match.params.customerUid;
        let password = this.state.password;
        this.customerResetPassword(uid, password);
    }

    render() {
        let resetPasswordButton = '';
        const { customers } = this.props;
        const { userPaymentProfile } = this.props;
        if (userPaymentProfile.user_role.role !== "Analyst") {
            resetPasswordButton = (
                <Button onClick={this.openModel} id="Reset_password">Reset Password</Button>
            )
        }
        //  console.log(customers);
        const { error, loading, success } = this.props;
        let childrenData = null
        if (customers.user === undefined || customers.user === null) {
            return (<Loader />)
        }
        else {
            if (loading === true) {
                childrenData = (<Loader />)
            }

            let paymentHistory = null;
            let readonly = 'readOnly';
            paymentHistory = customers.user.paymentHistory.map(historyItem => {
                return (
                    <div class="children-box mb-3">
                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="customer_payment" className="label input-group">Payment Date</label>
                                <Input readOnly fluid type="text" name="customer_payment" id="customer_payment" value={historyItem.userPaymentPackageId.paymentDatetime} />
                                <br />
                            </div>
                            <div className="col-6">
                                <label htmlFor="customer_payment" className="label input-group">Expiration Date</label>
                                <Input readOnly fluid type="text" name="customer_payment" id="customer_payment" value={historyItem.userPaymentPackageId.expirationDatetime} />
                                <br />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="customer_payment" className="label input-group">Payment plan</label>
                            <Input readOnly fluid type="text" name="customer_payment" id="customer_payment" value={historyItem.userPaymentPackageId.paymentPackageId.packageType} />
                            <br />
                        </div>
                    </div>
                )
            });
            childrenData = customers.user.children.length >= 1 ? (
                customers.user.children.map(childItem => {
                    if (!childItem.profilePicture) {
                        childItem.profilePicture = placeHolder;
                    }

                    return (
                        <div class="children-box mb-3">
                            <div class="first-row">
                                <div>
                                    <div class="h6 chaild-title font-weight-bold mb-0">{childItem.name}</div>
                                    <input type="checkbox" id="switch" /><label for="switch">Toggle</label>
                                </div>
                                <img class="Chaild-pic" alt="user-pic" src={childItem.profilePicture} />
                            </div>
                            <div class="second-row">
                                <div class="chaild-btns text-right">
                                    <button type="button" class="btn btn-outline-secondary">View active</button>
                                    <button type="button" class="btn btn-outline-secondary">Edit</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            ) :
                (
                    childrenData =
                    <div className="children_container">
                        <div className="customer-card-container">
                            <div id="child-data">

                                {/* <hr className="customer-hr" /> */}
                            </div>
                            <div id="child-data">

                                <div className="child-toogle">

                                    <label style={{ paddingLeft: "20px" }}>No Children</label>

                                    {/* <div class="ui fitted toggle checkbox">
                                //     <input type="checkbox" class="hidden" readonly="" tabindex="0" />
                                //     <label></label>
                                // </div>
                                */}
                                </div>

                            </div>
                        </div>
                    </div>
                )
            if (this.state.back === true) {
                // redirect to customer page or issue details
                const { redirectionPage } = this.props;
                var pageName = redirectionPage.pageName

                return (<Redirect to={pageName} ></Redirect>)
            }

            if (this.state.showModel === true) {
                return (
                    <Modal id="modal" onClose={this.closeModel} open={this.state.showModel}>
                        <Modal.Header>Customer Reset Password </Modal.Header>
                        <Modal.Content >
                            <Modal.Actions>
                                <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                                            <br />
                                            <label htmlFor="password" className="label">
                                                Password </label>
                                            <Input fluid name="password" id="password" type="password"
                                                onChange={this.handleChange}
                                                value={this.state.password} />
                                            <br />

                                            <label htmlFor="confirm-password" className="label">
                                                Confirm Password </label>
                                            <Input fluid name="confirmPassword" id="confirmPassword" type="password"
                                                onChange={this.handleChange}
                                                value={this.state.confirmPassword} />
                                            <br />

                                            <div className="input-field">
                                                <Button disabled={!this.state.confirmed} onClick={this.handleSubmit}
                                                    id="saveButton" >
                                                    Save
                                        </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </Modal.Actions>
                        </Modal.Content>
                    </Modal>
                )
            }
            else {
                return (
                    <div className="container">
                        <OuterHeader />
                        <div className="container">
                            <div className="container-body">
                                <div className="row">
                                    <Dashboard />
                                    <div className="col">
                                        <InnerHeader />
                                        <div className="main-content">
                                            <div className="row">

                                                <div className="col-12 left-main-body" id="left-main-body">
                                                    <div className="box-container">

                                                        {/* <!--  start status bar --> */}
                                                        <div className="statusbar small-font">
                                                            <span className="status-link">
                                                                <Link to="/">Home</Link><span> > </span>
                                                            </span>
                                                            <span className="status-link">
                                                                <Link to={CUSTOMERS}>Customers</Link><span> > </span>
                                                            </span>

                                                            <span>View Customer</span>
                                                        </div>

                                                        <h5 className="main-title mt-2 font-weight-bold">Customer Data</h5>

                                                        <div className="card mt-3 p-0">
                                                            <div className="card-body p-4">

                                                                <div className="row">


                                                                    <div className="col-6">
                                                                        <form >
                                                                            <label htmlFor="customer_name" className="label">Customer Name</label><br />
                                                                            <Input readOnly fluid name="customer_name" id="customer_name" value={customers.user.name} />
                                                                            <br />

                                                                            <label htmlFor="customer_number" className="label">Phone Number</label><br />
                                                                            <Input readOnly fluid type="number" min="0" name="customer_number" id="customer_number" value={customers.user.phone} />
                                                                            <br />

                                                                            <label htmlFor="customer_email" className="label">Email</label><br />
                                                                            <Input readOnly fluid type="email" name="customer_email" id="customer_email" value={customers.user.email} />
                                                                            <br />

                                                                            <label htmlFor="customer_birthday" className="label">Birthday</label><br />
                                                                            <Input readOnly fluid type="text" name="customer_birthday" id="customer_birthday" value={customers.user.dateOfBirth} />
                                                                            <br />

                                                                            <label htmlFor="customer_Gender" className="label">Gender</label><br />
                                                                            <Input readOnly fluid type="text" name="customer_Gender" id="customer_Gender" value={customers.user.gender} />
                                                                            <br />

                                                                            <div className="row">
                                                                                <div className="col-6">
                                                                                    <label htmlFor="customer_payment" className="label input-group">Current Payment Date</label>
                                                                                    <Input readOnly fluid type="text" name="customer_payment" id="customer_payment" value={customers.user.currentPackagePaymentId && customers.user.currentPackagePaymentId.paymentDatetime} />
                                                                                    <br />
                                                                                </div>
                                                                                <div className="col-6">
                                                                                    <label htmlFor="customer_payment" className="label input-group"> Expiration Date</label>
                                                                                    <Input readOnly fluid type="text" name="customer_payment" id="customer_payment" value={customers.user.currentPackagePaymentId && customers.user.currentPackagePaymentId.expirationDatetime} />
                                                                                    <br />
                                                                                </div>
                                                                            </div>
                                                                            <label htmlFor="customer_payment" className="label input-group">Payment plan</label>
                                                                            <Input readOnly fluid type="text" name="customer_payment" id="customer_payment" value={customers.user.currentPackagePaymentId && customers.user.currentPackagePaymentId.paymentPackageId.packageType} />
                                                                            <br />
                                                                            <label htmlFor="customer_payment" className="label input-group">Total Time Of Use </label>
                                                                            <Input readOnly fluid type="text" name="customer_payment" id="customer_payment" value={customers.totalTimeOfUse} />
                                                                            <br />
                                                                            <label htmlFor="customer_language" className="label">Language Of Application</label><br />
                                                                            <Input readOnly fluid type="text" name="customer_language" id="customer_language" value="English" />
                                                                            <br />
                                                                             {/* <!--  NGMA-2887 of removing reset password --> */}
                                                                        </form>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <label class="small-label">Children</label>
                                                                        {childrenData}
                                                                        <br />
                                                                        <label class="small-label">History</label>
                                                                        {paymentHistory}
                                                                        <br />
                                                                    </div>

                                                                </div>
                                                                <br /><br />

                                                                <div className="buttons_container">
                                                                    <Button icon labelPosition='right' id="episode_cancel" style={{ width: "120px", float: "right" }} onClick={this.handleBack} >
                                                                        Back
                                                                  <Icon name='right arrow' />
                                                                    </Button>
                                                                    {/* <Link to="/">
                                                                <Button id="View_customer_save" onClick={this.handleSubmit} >
                                                                    Next

                                                                </Button>
                                                            </Link> */}

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                )
            }
        }
    }
}

// ViewCustomer.propTypes = {
//     getCustomerProfileData: propTypes.func.isRequired,
// }
const mapStateToProps = state => ({
    customers: state.customers.item,
    loading: state.customers.loading,
    error: state.customers.get_customer_error,
    success: state.customers.get_customer_sucessfully,
    userPaymentProfile: state.userPaymentProfile.item,
    redirectionPage: state.redirectionPage.item,

});

export default withRouter
    (connect
        (
            mapStateToProps,
            { getCustomerProfileData, customerResetPassword, getUserData }
        )
        (ViewCustomer)
    );
