export default
 [{
    key: 'Andorra',
    text: 'Andorra',
    value: 'Andorra'
},
{
    key: 'United Arab Emirates',
    text: 'United Arab Emirates',
    value: 'United Arab Emirates'
}
    ,
{
    key: 'Afghanistan',
    text: 'Afghanistan',
    value: 'Afghanistan'
}
    ,
{
    key: 'Antigua and Barbuda',
    text: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda'
}
    ,
{
    key: 'Anguilla',
    text: 'Anguilla',
    value: 'Anguilla'
}
    ,
{
    key: 'Albania',
    text: 'Albania',
    value: 'Albania'
}
    ,
{
    key: 'Armenia',
    text: 'Armenia',
    value: 'Armenia'
}
    ,
{
    key: 'Netherlands Antilles',
    text: 'Netherlands Antilles',
    value: 'Netherlands Antilles'
}
    ,
{
    key: 'Angola',
    text: 'Angola',
    value: 'Angola'
}
    ,
{
    key: 'Antarctica',
    text: 'Antarctica',
    value: 'Antarctica'
}
    ,
{
    key: 'Argentina',
    text: 'Argentina',
    value: 'Argentina'
}
    ,
{
    key: 'American Samoa',
    text: 'American Samoa',
    value: 'American Samoa'
}
    ,
{
    key: 'Austria',
    text: 'Austria',
    value: 'Austria'
}
    ,
{
    key: 'Australia',
    text: 'Australia',
    value: 'Australia'
}
    ,
{
    key: 'Aruba',
    text: 'Aruba',
    value: 'Aruba'
}
    ,
{
    key: 'Åland Islands',
    text: 'Åland Islands',
    value: 'Åland Islands'
}
    ,
{
    key: 'Azerbaijan',
    text: 'Azerbaijan',
    value: 'Azerbaijan'
}
    ,
{
    key: 'Bosnia and Herzegovina',
    text: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina'
}
    ,
{
    key: 'Barbados',
    text: 'Barbados',
    value: 'Barbados'
}
    ,
{
    key: 'Bangladesh',
    text: 'Bangladesh',
    value: 'Bangladesh'
}
    ,
{
    key: 'Belgium',
    text: 'Belgium',
    value: 'Belgium'
}
    ,
{
    key: 'Burkina Faso',
    text: 'Burkina Faso',
    value: 'Burkina Faso'
}
    ,
{
    key: 'Bulgaria',
    text: 'Bulgaria',
    value: 'Bulgaria'
}
    ,
{
    key: 'Bahrain',
    text: 'Bahrain',
    value: 'Bahrain'
}
    ,
{
    key: 'Burundi',
    text: 'Burundi',
    value: 'Burundi'
}
    ,
{
    key: 'Benin',
    text: 'Benin',
    value: 'Benin'
}
    ,
{
    key: "Saint Barthélemy",
    text: "Saint Barthélemy",
    value: "Saint Barthélemy"
}
    ,
{
    key: 'Bermuda',
    text: 'Bermuda',
    value: 'Bermuda'
}
    ,
{
    key: 'Brunei Darussalam',
    text: 'Brunei Darussalam',
    value: 'Brunei Darussalam'
}
    ,
{
    key: 'Bolivia, Plurinational State of',
    text: 'Bolivia, Plurinational State of',
    value: 'Bolivia, Plurinational State of'
}
    ,
{
    key: 'Caribbean Netherlands',
    text: 'Caribbean Netherlands',
    value: 'Caribbean Netherlands'
}
    ,
{
    key: 'Brazil',
    text: 'Brazil',
    value: 'Brazil'
}
    ,
{
    key: 'Bahamas',
    text: 'Bahamas',
    value: 'Bahamas'
}
    ,
{
    key: 'Bhutan',
    text: 'Bhutan',
    value: 'Bhutan'
}
    ,
{
    key: 'Bouvet Island',
    text: 'Bouvet Island',
    value: 'Bouvet Island'
}
    ,
{
    key: 'Botswana',
    text: 'Botswana',
    value: 'Botswana'
}
    ,
{
    key: 'Belarus',
    text: 'Belarus',
    value: 'Belarus'
}
    ,
{
    key: 'Belize',
    text: 'Belize',
    value: 'Belize'
}
    ,
{
    key: 'Canada',
    text: 'Canada',
    value: 'Canada'
}
    ,
{
    key: 'Cocos (Keeling) Islands',
    text: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands'
}
    ,
{
    key: 'Congo, the Democratic Republic of the',
    text: 'Congo, the Democratic Republic of the',
    value: 'Congo, the Democratic Republic of the'
}
    ,
{
    key: 'Central African Republic',
    text: 'Central African Republic',
    value: 'Central African Republic'
}
    ,
{
    key: 'Congo',
    text: 'Congo',
    value: 'Congo'
}
    ,
{
    key: 'Switzerland',
    text: 'Switzerland',
    value: 'Switzerland'
}
    ,
{
    key: "Côte d'Ivoire",
    text: "Côte d'Ivoire",
    value: "Côte d'Ivoire"
}
    ,
{
    key: 'Cook Islands',
    text: 'Cook Islands',
    value: 'Cook Islands'
}
    ,
{
    key: 'Chile',
    text: 'Chile',
    value: 'Chile'
}
    ,
{
    key: 'Cameroon',
    text: 'Cameroon',
    value: 'Cameroon'
}
    ,
{
    key: 'China',
    text: 'China',
    value: 'China'
}
    ,
{
    key: 'Colombia',
    text: 'Colombia',
    value: 'Colombia'
}
    ,
{
    key: 'Costa Rica',
    text: 'Costa Rica',
    value: 'Costa Rica'
}
    ,
{
    key: 'Cuba',
    text: 'Cuba',
    value: 'Cuba'
}
    ,
{
    key: 'Cape Verde',
    text: 'Cape Verde',
    value: 'Cape Verde'
}
    ,
{
    key: 'Curaçao',
    text: 'Curaçao',
    value: 'Curaçao'
}
    ,
{
    key: 'Christmas Island',
    text: 'Christmas Island',
    value: 'Christmas Island'
}
    ,
{
    key: 'Cyprus',
    text: 'Cyprus',
    value: 'Cyprus'
}
    ,
{
    key: 'Czech Republic',
    text: 'Czech Republic',
    value: 'Czech Republic'
}
    ,
{
    key: 'Germany',
    text: 'Germany',
    value: 'Germany'
},
{
    key: 'Djibouti',
    text: 'Djibouti',
    value: 'Djibouti'
},
{
    key: 'Denmark',
    text: 'Denmark',
    value: 'Denmark'
},
{
    key: 'Dominica',
    text: 'Dominica',
    value: 'Dominica'
},
{
    key: 'Dominican Republic',
    text: 'Dominican Republic',
    value: 'Dominican Republic'
},
{
    key: 'Algeria',
    text: 'Algeria',
    value: 'Algeria'
},
{
    key: 'Ecuador',
    text: 'Ecuador',
    value: 'Ecuador'
},
{
    key: 'Estonia',
    text: 'Estonia',
    value: 'Estonia'
},
{
    key: 'Egypt',
    text: 'Egypt',
    value: 'Egypt'
},
{
    key: 'Western Sahara',
    text: 'Western Sahara',
    value: 'Western Sahara'
},
{
    key: 'Eritrea',
    text: 'Eritrea',
    value: 'Eritrea'
},
{
    key: 'Spain',
    text: 'Spain',
    value: 'Spain'
},
{
    key: 'Ethiopia',
    text: 'Ethiopia',
    value: 'Ethiopia'
},
{
    key: 'Finland',
    text: 'Finland',
    value: 'Finland'
},
{
    key: 'Fiji',
    text: 'Fiji',
    value: 'Fiji'
},
{
    key: 'Falkland Islands (Malvinas)',
    text: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)'
},
{
    key: 'Micronesia, Federated States of',
    text: 'Micronesia, Federated States of',
    value: 'Micronesia, Federated States of'
},
{
    key: 'Faroe Islands',
    text: 'Faroe Islands',
    value: 'Faroe Islands'
},
{
    key: 'France',
    text: 'France',
    value: 'France'
},
{
    key: 'Gabon',
    text: 'Gabon',
    value: 'Gabon'
},
{
    key: 'United Kingdom',
    text: 'United Kingdom',
    value: 'United Kingdom'
},
{
    key: 'England',
    text: 'England',
    value: 'England'
},
{
    key: 'Northern Ireland',
    text: 'Northern Ireland',
    value: 'Northern Ireland'
},
{
    key: 'Scotland',
    text: 'Scotland',
    value: 'Scotland'
},
{
    key: 'Wales',
    text: 'Wales',
    value: 'Wales'
}
    ,
{
    key: 'Grenada',
    text: 'Grenada',
    value: 'Grenada'
},
{
    key: 'Georgia',
    text: 'Georgia',
    value: 'Georgia'
},
{
    key: 'French Guiana',
    text: 'French Guiana',
    value: 'French Guiana'
},
{
    key: 'Guernsey',
    text: 'Guernsey',
    value: 'Guernsey'
},
{
    key: 'Ghana',
    text: 'Ghana',
    value: 'Ghana'
},
{
    key: 'Gibraltar',
    text: 'Gibraltar',
    value: 'Gibraltar'
},
{
    key: 'Greenland',
    text: 'Greenland',
    value: 'Greenland'
},
{
    key: 'Gambia',
    text: 'Gambia',
    value: 'Gambia'
},
{
    key: 'Guinea',
    text: 'Guinea',
    value: 'Guinea'
},
{
    key: 'Guadeloupe',
    text: 'Guadeloupe',
    value: 'Guadeloupe'
},
{
    key: 'Equatorial Guinea',
    text: 'Equatorial Guinea',
    value: 'Equatorial Guinea'
},
{
    key: 'Greece',
    text: 'Greece',
    value: 'Greece'
},
{
    key: 'South Georgia and the South Sandwich Islands',
    text: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands'
},
{
    key: 'Guatemala',
    text: 'Guatemala',
    value: 'Guatemala'
},
{
    key: 'Guam',
    text: 'Guam',
    value: 'Guam'
},
{
    key: 'Guinea-Bissau',
    text: 'Guinea-Bissau',
    value: 'Guinea-Bissau'
},
{
    key: 'Guyana',
    text: 'Guyana',
    value: 'Guyana'
},
{
    key: 'Hong Kong',
    text: 'Hong Kong',
    value: 'Hong Kong'
},
{
    key: 'Heard Island and McDonald Islands',
    text: 'Heard Island and McDonald Islands',
    value: 'Heard Island and McDonald Islands'
}
    ,
{
    key: 'Honduras',
    text: 'Honduras',
    value: 'Honduras'
},
{
    key: 'Croatia',
    text: 'Croatia',
    value: 'Croatia'
},
{
    key: 'Haiti',
    text: 'Haiti',
    value: 'Haiti'
},
{
    key: 'Hungary',
    text: 'Hungary',
    value: 'Hungary'
},
{
    key: 'Indonesia',
    text: 'Indonesia',
    value: 'Indonesia'
},
{
    key: 'Ireland',
    text: 'Ireland',
    value: 'Ireland'
},
{
    key: 'Israel',
    text: 'Israel',
    value: 'Israel'
},
{
    key: 'Isle of Man',
    text: 'Isle of Man',
    value: 'Isle of Man'
},
{
    key: 'India',
    text: 'India',
    value: 'India'
},
{
    key: 'British Indian Ocean Territory',
    text: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory'
},
{
    key: 'Iraq',
    text: 'Iraq',
    value: 'Iraq'
},
{
    key: 'Iran, Islamic Republic of',
    text: 'Iran, Islamic Republic of',
    value: 'Iran, Islamic Republic of'
},
{
    key: 'Iceland',
    text: 'Iceland',
    value: 'Iceland'
},
{
    key: 'Italy',
    text: 'Italy',
    value: 'Italy'
},
{
    key: 'Jersey',
    text: 'Jersey',
    value: 'Jersey'
},
{
    key: 'Jamaica',
    text: 'Jamaica',
    value: 'Jamaica'
},
{
    key: 'Jordan',
    text: 'Jordan',
    value: 'Jordan'
},
{
    key: 'Japan',
    text: 'Japan',
    value: 'Japan'
},
{
    key: 'Kenya',
    text: 'Kenya',
    value: 'Kenya'
},
{
    key: 'Kyrgyzstan',
    text: 'Kyrgyzstan',
    value: 'Kyrgyzstan'
},
{
    key: 'Cambodia',
    text: 'Cambodia',
    value: 'Cambodia'
},
{
    key: 'Kiribati',
    text: 'Kiribati',
    value: 'Kiribati'
},
{
    key: 'Comoros',
    text: 'Comoros',
    value: 'Comoros'
},
{
    key: 'Saint Kitts and Nevis',
    text: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis'
},
{
    key: "Korea, Democratic People's Republic of",
    text: "Korea, Democratic People's Republic of",
    value: "Korea, Democratic People's Republic of"
},
{
    key: 'Korea, Republic of',
    text: 'Korea, Republic of',
    value: 'Korea, Republic of'
},
{
    key: 'Kuwait',
    text: 'Kuwait',
    value: 'Kuwait'
},
{
    key: 'Cayman Islands',
    text: 'Cayman Islands',
    value: 'Cayman Islands'
},
{
    key: 'Kazakhstan',
    text: 'Kazakhstan',
    value: 'Kazakhstan'
},
{
    key: "Lao People's Democratic Republic",
    text: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic"
},
{
    key: 'Lebanon',
    text: 'Lebanon',
    value: 'Lebanon'
},
{
    key: 'Saint Lucia',
    text: 'Saint Lucia',
    value: 'Saint Lucia'
},
{
    key: 'Liechtenstein',
    text: 'Liechtenstein',
    value: 'Liechtenstein'
},
{
    key: 'Sri Lanka',
    text: 'Sri Lanka',
    value: 'Sri Lanka'
},
{
    key: 'Liberia',
    text: 'Liberia',
    value: 'Liberia'
},
{
    key: 'Lesotho',
    text: 'Lesotho',
    value: 'Lesotho'
},
{
    key: 'Lithuania',
    text: 'Lithuania',
    value: 'Lithuania'
},
{
    key: 'Luxembourg',
    text: 'Luxembourg',
    value: 'Luxembourg'
},
{
    key: 'Latvia',
    text: 'Latvia',
    value: 'Latvia'
},
{
    key: 'Libya',
    text: 'Libya',
    value: 'Libya'
},
{
    key: 'Morocco',
    text: 'Morocco',
    value: 'Morocco'
},
{
    key: 'Monaco',
    text: 'Monaco',
    value: 'Monaco'
},
{
    key: 'Moldova, Republic of',
    text: 'Moldova, Republic of',
    value: 'Moldova, Republic of'
},
{
    key: 'Montenegro',
    text: 'Montenegro',
    value: 'Montenegro'
},
{
    key: 'Saint Martin',
    text: 'Saint Martin',
    value: 'Saint Martin'
},
{
    key: 'Madagascar',
    text: 'Madagascar',
    value: 'Madagascar'
},
{
    key: 'Marshall Islands',
    text: 'Marshall Islands',
    value: 'Marshall Islands'
},
{
    key: 'Macedonia, the former Yugoslav Republic of',
    text: 'Macedonia, the former Yugoslav Republic of',
    value: 'Macedonia, the former Yugoslav Republic of'
},
{
    key: 'Mali',
    text: 'Mali',
    value: 'Mali'
},
{
    key: 'Myanmar',
    text: 'Myanmar',
    value: 'Myanmar'
},
{
    key: 'Mongolia',
    text: 'Mongolia',
    value: 'Mongolia'
},
{
    key: 'Macao',
    text: 'Macao',
    value: 'Macao'
},
{
    key: 'Northern Mariana Islands',
    text: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands'
},
{
    key: 'Martinique',
    text: 'Martinique',
    value: 'Martinique'
},
{
    key: 'Mauritania',
    text: 'Mauritania',
    value: 'Mauritania'
},
{
    key: 'Montserrat',
    text: 'Montserrat',
    value: 'Montserrat'
},
{
    key: 'Malta',
    text: 'Malta',
    value: 'Malta'
},
{
    key: 'Mauritius',
    text: 'Mauritius',
    value: 'Mauritius'
},
{
    key: 'Maldives',
    text: 'Maldives',
    value: 'Maldives'
},
{
    key: 'Malawi',
    text: 'Malawi',
    value: 'Malawi'
},
{
    key: 'Mexico',
    text: 'Mexico',
    value: 'Mexico'
},
{
    key: 'Malaysia',
    text: 'Malaysia',
    value: 'Malaysia'
},
{
    key: 'Mozambique',
    text: 'Mozambique',
    value: 'Mozambique'
},
{
    key: 'Namibia',
    text: 'Namibia',
    value: 'Namibia'
},
{
    key: 'New Caledonia',
    text: 'New Caledonia',
    value: 'New Caledonia'
},
{
    key: 'Niger',
    text: 'Niger',
    value: 'Niger'
},
{
    key: 'Norfolk Island',
    text: 'Norfolk Island',
    value: 'Norfolk Island'
},
{
    key: 'Nigeria',
    text: 'Nigeria',
    value: 'Nigeria'
},
{
    key: 'Nicaragua',
    text: 'Nicaragua',
    value: 'Nicaragua'
},
{
    key: 'Netherlands',
    text: 'Netherlands',
    value: 'Netherlands'
},
{
    key: 'Norway',
    text: 'Norway',
    value: 'Norway'
},
{
    key: 'Nepal',
    text: 'Nepal',
    value: 'Nepal'
},
{
    key: 'Nauru',
    text: 'Nauru',
    value: 'Nauru'
},
{
    key: 'Niue',
    text: 'Niue',
    value: 'Niue'
},
{
    key: 'New Zealand',
    text: 'New Zealand',
    value: 'New Zealand'
},
{
    key: 'Oman',
    text: 'Oman',
    value: 'Oman'
},
{
    key: 'Panama',
    text: 'Panama',
    value: 'Panama'
},
{
    key: 'Peru',
    text: 'Peru',
    value: 'Peru'
},
{
    key: 'French Polynesia',
    text: 'French Polynesia',
    value: 'French Polynesia'
},
{
    key: 'Papua New Guinea',
    text: 'Papua New Guinea',
    value: 'Papua New Guinea'
},
{
    key: 'Philippines',
    text: 'Philippines',
    value: 'Philippines'
},
{
    key: 'Pakistan',
    text: 'Pakistan',
    value: 'Pakistan'
},
{
    key: 'Poland',
    text: 'Poland',
    value: 'Poland'
},
{
    key: 'Saint Pierre and Miquelon',
    text: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon'
},
{
    key: 'Pitcairn',
    text: 'Pitcairn',
    value: 'Pitcairn'
},
{
    key: 'Puerto Rico',
    text: 'Puerto Rico',
    value: 'Puerto Rico'
},
{
    key: 'Palestine',
    text: 'Palestine',
    value: 'Palestine'
},
{
    key: 'Portugal',
    text: 'Portugal',
    value: 'Portugal'
},
{
    key: 'Palau',
    text: 'Palau',
    value: 'Palau'
},
{
    key: 'Paraguay',
    text: 'Paraguay',
    value: 'Paraguay'
},
{
    key: 'Qatar',
    text: 'Qatar',
    value: 'Qatar'
},
{
    key: 'Réunion',
    text: 'Réunion',
    value: 'Réunion'
},
{
    key: 'Romania',
    text: 'Romania',
    value: 'Romania'
},
{
    key: 'Serbia',
    text: 'Serbia',
    value: 'Serbia'
},
{
    key: 'Russian Federation',
    text: 'Russian Federation',
    value: 'Russian Federation'
},
{
    key: 'Rwanda',
    text: 'Rwanda',
    value: 'Rwanda'
},
{
    key: 'Saudi Arabia',
    text: 'Saudi Arabia',
    value: 'Saudi Arabia'
},
{
    key: 'Solomon Islands',
    text: 'Solomon Islands',
    value: 'Solomon Islands'
},
{
    key: 'Seychelles',
    text: 'Seychelles',
    value: 'Seychelles'
},
{
    key: 'Sudan',
    text: 'Sudan',
    value: 'Sudan'
},
{
    key: 'Sweden',
    text: 'Sweden',
    value: 'Sweden'
},
{
    key: 'Singapore',
    text: 'Singapore',
    value: 'Singapore'
},
{
    key: 'Saint Helena, Ascension and Tristan da Cunha',
    text: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'Saint Helena, Ascension and Tristan da Cunha'
},
{
    key: 'Slovenia',
    text: 'Slovenia',
    value: 'Slovenia'
},
{
    key: 'Svalbard and Jan Mayen Islands',
    text: 'Svalbard and Jan Mayen Islands',
    value: 'Svalbard and Jan Mayen Islands'
},
{
    key: 'Slovakia',
    text: 'Slovakia',
    value: 'Slovakia'
},
{
    key: 'Sierra Leone',
    text: 'Sierra Leone',
    value: 'Sierra Leone'
},
{
    key: 'San Marino',
    text: 'San Marino',
    value: 'San Marino'
},
{
    key: 'Senegal',
    text: 'Senegal',
    value: 'Senegal'
},
{
    key: 'Somalia',
    text: 'Somalia',
    value: 'Somalia'
},
{
    key: 'Suriname',
    text: 'Suriname',
    value: 'Suriname'
},
{
    key: 'South Sudan',
    text: 'South Sudan',
    value: 'South Sudan'
},
{
    key: 'Sao Tome and Principe',
    text: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe'
},
{
    key: 'El Salvador',
    text: 'El Salvador',
    value: 'El Salvador'
},
{
    key: 'Sint Maarten (Dutch part)',
    text: 'Sint Maarten (Dutch part)',
    value: 'Sint Maarten (Dutch part)'
},
{
    key: 'Syrian Arab Republic',
    text: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic'
},
{
    key: 'Swaziland',
    text: 'Swaziland',
    value: 'Swaziland'
},
{
    key: 'Turks and Caicos Islands',
    text: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands'
},
{
    key: 'Chad',
    text: 'Chad',
    value: 'Chad'
},
{
    key: 'French Southern Territories',
    text: 'French Southern Territories',
    value: 'French Southern Territories'
},
{
    key: 'Togo',
    text: 'Togo',
    value: 'Togo'
},
{
    key: 'Thailand',
    text: 'Thailand',
    value: 'Thailand'
},
{
    key: 'Tajikistan',
    text: 'Tajikistan',
    value: 'Tajikistan'
},
{
    key: 'Tokelau',
    text: 'Tokelau',
    value: 'Tokelau'
},
{
    key: 'Timor-Leste',
    text: 'Timor-Leste',
    value: 'Timor-Leste'
},
{
    key: 'Turkmenistan',
    text: 'Turkmenistan',
    value: 'Turkmenistan'
},
{
    key: 'Tunisia',
    text: 'Tunisia',
    value: 'Tunisia'
},
{
    key: 'Tonga',
    text: 'Tonga',
    value: 'Tonga'
},
{
    key: 'Turkey',
    text: 'Turkey',
    value: 'Turkey'
},
{
    key: 'Trinidad and Tobago',
    text: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago'
},
{
    key: 'Tuvalu',
    text: 'Tuvalu',
    value: 'Tuvalu'
},
{
    key: 'Taiwan',
    text: 'Taiwan',
    value: 'Taiwan'
},
{
    key: 'Tanzania, United Republic of',
    text: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of'
},
{
    key: 'Ukraine',
    text: 'Ukraine',
    value: 'Ukraine'
},
{
    key: 'Uganda',
    text: 'Uganda',
    value: 'Uganda'
},
{
    key: 'US Minor Outlying Islands',
    text: 'US Minor Outlying Islands',
    value: 'US Minor Outlying Islands'
},
{
    key: 'United States',
    text: 'United States',
    value: 'United States'
},
{
    key: 'Uruguay',
    text: 'Uruguay',
    value: 'Uruguay'
},
{
    key: 'Uzbekistan',
    text: 'Uzbekistan',
    value: 'Uzbekistan'
},
{
    key: 'Holy See (Vatican City State)',
    text: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)'
},
{
    key: 'Saint Vincent and the Grenadines',
    text: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines'
},
{
    key: 'Venezuela, Bolivarian Republic of',
    text: 'Venezuela, Bolivarian Republic of',
    value: 'Venezuela, Bolivarian Republic of'
},
{
    key: 'Virgin Islands, British',
    text: 'Virgin Islands, British',
    value: 'Virgin Islands, British'
},
{
    key: 'Virgin Islands, U.S.',
    text: 'Virgin Islands, U.S.',
    value: 'Virgin Islands, U.S.'
},
{
    key: 'Vietnam',
    text: 'Vietnam',
    value: 'Vietnam'
},
{
    key: 'Vanuatu',
    text: 'Vanuatu',
    value: 'Vanuatu'
},
{
    key: 'Wallis and Futuna Islands',
    text: 'Wallis and Futuna Islands',
    value: 'Wallis and Futuna Islands'
},
{
    key: 'Kosovo',
    text: 'Kosovo',
    value: 'Kosovo'
},
{
    key: 'Samoa',
    text: 'Samoa',
    value: 'Samoa'
},
{
    key: 'Yemen',
    text: 'Yemen',
    value: 'Yemen'
},
{
    key: 'Mayotte',
    text: 'Mayotte',
    value: 'Mayotte'
},
{
    key: 'South Africa',
    text: 'South Africa',
    value: 'South Africa'
},
{
    key: 'Zambia',
    text: 'Zambia',
    value: 'Zambia'
},
{
    key: 'Zimbabwe',
    text: 'Zimbabwe',
    value: 'Zimbabwe'
}
]
