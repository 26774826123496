import {
    LOAD_All_GAME_DATA, FETCH_All_GAME_DATA,
    ERROR_ALL_GAME_DATA,
    LOAD_GAME_DATA, ERROR_UPDATE_GAME_DATA,
    UPDATE_GAME_DATA,
    ERROR_ADDING_GAME_DATA, ADD_GAME_DATA,
    DELETING_GAME_DATA,
    ERROR_DELETING_GAME_DATA, EMPTY_GAME_DATA,
    GET_GAME_DATA, ERROR_LOADING_GAME_DATA

} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    loading_data: '',

    getting_all_game_data_error: '',
    getting_all_game_data_sucessfully: '',

    getting_game_data_data_error: '',
    getting_game_data_data_sucessfully: '',

    updating_game_data_error: '',
    updating_game_data_sucessfully: '',

    adding_game_data_error: '',
    adding_game_data_sucessfully: "",

    deleting_game_data_error: '',
    deleting_game_data_sucessfully: "",
}

export default function (state = initialState, action) {
    switch (action.type) {


        case FETCH_All_GAME_DATA:
            return {
                ...state,
                items: action.payload,
                getting_all_game_data_error: '',
                getting_all_game_data_sucessfully: true,
                loading_data: false
            }

        case ERROR_ALL_GAME_DATA:
            return {
                ...state,
                getting_all_game_data_error: action.payload,
                loading_data: false
            }
        case GET_GAME_DATA:

            return {
                ...state,
                item: action.payload,
                loading_data: false,
                getting_game_data_data_sucessfully: true,
                getting_game_data_data_error: ''
            }

        case ERROR_LOADING_GAME_DATA:
            return {
                ...state,
                getting_game_data_data_error: action.payload,
                loading_data: false
            }

        case LOAD_GAME_DATA:
            return {
                ...state,
                loading_data: true
            }

        case LOAD_All_GAME_DATA:
            return {
                ...state,
                loading_data: true
            }

        case UPDATE_GAME_DATA:
            return {
                ...state,
                item: action.payload,
                updating_game_data_error: '',
                updating_game_data_sucessfully: true,
                loading_data: false
            }

        case ERROR_UPDATE_GAME_DATA:
            return {

                ...state,
                updating_game_data_error: action.payload,
                updating_game_data_sucessfully: false,
                loading_data: false
            }

        case ADD_GAME_DATA:
            return {
                ...state,
                item: action.payload,
                adding_game_data_error: '',
                adding_game_data_sucessfully: true,
                loading_data: false
            }

        case ERROR_ADDING_GAME_DATA:
            return {

                ...state,
                adding_game_data_error: action.payload,
                adding_game_data_sucessfully: false,
                loading_data: false
            }

        case DELETING_GAME_DATA:
            return {

                ...state,
                item: action.payload,
                deleting_game_data_sucessfully: true,
                loading_data: false
            }
        case ERROR_DELETING_GAME_DATA:
            return {

                ...state,
                deleting_game_data_error: action.payload,
                loading_data: false
            }

        case EMPTY_GAME_DATA:
            return {
                ...state,
                updating_game_data_error: action.payload,
                updating_game_data_sucessfully: action.payload,

                adding_game_data_error: action.payload,
                adding_game_data_sucessfully: action.payload,

                deleting_game_data_error: action.payload,
                deleting_game_data_sucessfully: action.payload,

                getting_game_data_data_error: action.payload,
                getting_game_data_data_sucessfully: action.payload,

                getting_all_game_data_error: '',
                getting_all_game_data_sucessfully: '',
                loading_data: false,

                item: {},
            }

        default:
            return state

    }

}