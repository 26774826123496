import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
/* Components */
/*Stripe MobilePayment */
import MobilePayment from './MobilePayment';
import MobilePaymentProfile from './MobilePaymentProfile';
import MobilePaymentSupport from './MobilePaymentSupport';
import MobilePaymentHistory from './MobilePaymentHistory';
import MobilePaymentStripeCheckoutParent from './MobilePaymentStripeCheckoutParent';

import MobilePaymentOffer from './MobilePaymentOffer';

import FawryMobilePayment from '../FawryPayment/FawryMobilePayment'
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
import { addTokenToredux } from '../../Actions/loginActions.js'
/*Actions */
import { getUserInfoByToken } from '../../Actions/CustomerActions';

const INITIAL_STATE = {
  redirectPage: '',
  token: ''
}

class PaymentAuth extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    let token = this.props.match.params.token;
    //console.log("TOKEN");
    //console.log(token);
    if (token !== null && token !== undefined) {
      //console.log("################################")
      //console.log(token)
      this.props.addTokenToredux(token);
      this.props.getUserData(token);
      //     this.setState({token: token});
      //      this.getUserByToken(token);
    } else {
      if (firebase.auth().currentUser) {
        firebase.auth().currentUser.getIdToken().then((token) => {
          this.props.addTokenToredux(token);
          this.props.getUserData(token);
          //this.setState({token: token});
        })
      }
      else {
        //console.log("Login Page")
        this.setState({ redirectPage: "/MobilePaymentLogin" })
      }
    }
  }

  componentDidMount() {
    const { userPaymentProfile } = this.props;
    const { auth } = this.props;
    if (this.state.redirectPage !== '') {
      return <Redirect to={this.state.redirectionPage}></Redirect>
    }
    let pathname = window.location.pathname.split('/');

    if (!auth.uid && pathname[1] !== "/MobilePayment") {
      return <Redirect to="/MobilePaymentLogin"></Redirect>
    }
    if (userPaymentProfile.user_role == undefined || userPaymentProfile.user_role.role !== "USER") {
      return <Redirect to="/MobilePaymentLogin"></Redirect>
    }
  }

  // getUserByToken(token) {
  //     getUserInfoByToken(token)
  //         .then(res => {
  //         //  firebase.auth().signInWithCustomToken(res.data).then((user) => {
  //             console.log(res.data);
  //           }).catch(function(error){
  //             console.log("FFFFFFFFFFFFFFFFFFFFFFFFFF")
  //             console.log(error)
  //           });
  //             // console.log(res.data);
  //             // this.setState({ questionGame: res.data });
  //         //});
  //         // .catch(err => {
  //         //     alert(err.response.data.message);
  //         // });
  // }

  render() {
    const { userPaymentProfile } = this.props;
    // //  console.log(userPaymentProfile)
    //   // if(userPaymentProfile.user_role === undefined){
    //   //   return <Redirect to="/MobilePaymentLogin"></Redirect>
    //   // }
    //   /*if (userPaymentProfile.user_role !== undefined) {
    //     if(userPaymentProfile.user_role.role !== "USER"){
    //       return <Redirect to="/MobilePaymentLogin"></Redirect>
    //     }
    //   }*/
    return (
      <span>
        {/* stripe MobilePayment   */}
        <Route path="/MobilePayment/:token" component={MobilePayment} />
        <Route path="/MobilePaymentOffer" component={MobilePaymentOffer} />

        <Route path="/MobilePaymentProfile/:token" component={MobilePaymentProfile} />
        <Route path="/MobilePaymentSupport" component={MobilePaymentSupport} />
        <Route path="/MobilePaymentHistory" component={MobilePaymentHistory} />
        <Route path="/MobilePaymentStripeCheckoutParent" component={MobilePaymentStripeCheckoutParent} />

        <Route path="/FawryMobilePayment/:token" component={FawryMobilePayment} />
      </span>

    )
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  userPaymentProfile: state.userPaymentProfile.item,
  token: state.user.token,
})

export default withFirebase
  (connect
    (mapStateToProps,
      { getUserData, addTokenToredux }
    )(PaymentAuth)
  );
