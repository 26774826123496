import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Components */
// import AddBook from './AddBook'
// import EditBook from './EditBook'
import GetAllBook from './GetAllBook'
// import ViewBook from './ViewBook'

import AddBook from '../ReadWithJeel/AddBook'
import EditBook from '../ReadWithJeel/EditBook'
import BookDetails from '../ReadWithJeel/BookDetails'
import PublishBook from '../ReadWithJeel/PublishBook'
import BookEvaluation from '../ReadWithJeel/BookEvaluation'
import EditActivitBook from '../ReadWithJeel/EditActivitBook'
import EditEvaluationBook from '../ReadWithJeel/EditEvaluationBook'
import AddBookActivity from '../ReadWithJeel/AddBookActivity'
/*Book Parent */
import GetAllBookParents from '../BookParent/GetAllBookParents'
import AddBookParent from '../BookParent/AddBookParent'
import ViewBookParent from '../BookParent/ViewBookParent';
import EditBookParent from '../BookParent/EditBookParent'

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
// import { ADD_BOOK, ADD_RADIO_CHANNEL, ADD_RADIO_CHAN_ACTIVITY, ADMIN_LAND_PAGE, ALL_BOOK, EDIT_ACTIVITY_RADIO_CHAN, EDIT_BOOK, EDIT_RADIO_CHANNEL, EDIT_RADIO_CHAN_EVAL, GET_RADIO_CHAN_EVAL, PUBLISH_RADIO_CHANNEL, SIGN_IN, VIEW_BOOK, VIEW_RADIO_CHANNEL } from '../../routes';
import {
    ADD_BOOK,
    ADD_BOOK_ACTIVITY,
    ADMIN_LAND_PAGE,
    ALL_BOOK,
    BOOK_TO_EDIT,
    EDIT_ACTIVITY_BOOK,
    EDIT_BOOK,
    EDIT_BOOK_EVAL,
    GET_BOOK_EVAL,
    PUBLISH_BOOK,
    SIGN_IN, VIEW_BOOK, ADD_BOOK_PARENT, VIEW_STORY_PARENT, EDIT_BOOK_PARENT, ALL_BOOK_PARENTS, VIEW_BOOK_PARENT
} from '../../routes';

export class BookAuth extends Component {
    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    componentDidMount() {
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to={SIGN_IN}></Redirect>
        }
    }

    render() {
        const { userPaymentProfile } = this.props;

        if (userPaymentProfile.user_role.role === "Analyst") {
            return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
        }

        return (
            <span>
                <Route path={ALL_BOOK_PARENTS} component={GetAllBookParents} />
                <Route path={ADD_BOOK_PARENT} component={AddBookParent} />
                <Route path={VIEW_BOOK_PARENT} component={ViewBookParent} />
                <Route path={EDIT_BOOK_PARENT} component={EditBookParent} />
                {/* adding Book Routers */}
                <Route path={ALL_BOOK} component={GetAllBook} />
                <Route path={ADD_BOOK} component={AddBook} />
                <Route path={BOOK_TO_EDIT} component={EditBook} />
                <Route path={VIEW_BOOK} component={BookDetails} />
                <Route path={PUBLISH_BOOK} component={PublishBook} />
                <Route path={GET_BOOK_EVAL} component={BookEvaluation} />
                <Route path={EDIT_ACTIVITY_BOOK} component={EditActivitBook} />
                <Route path={EDIT_BOOK_EVAL} component={EditEvaluationBook} />
                <Route path={ADD_BOOK_ACTIVITY} component={AddBookActivity} />

            </span>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
    (connect
        (mapStateToProps,
            { getUserData }
        )(BookAuth)
    );
