import React, { Component } from "react";
import { withFirebase } from "../../config";
import { Button } from "semantic-ui-react";
import { Redirect } from "react-router-dom";

/*Navbar*/
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";

/*Redux */
import "firebase/firestore";
import "firebase/auth";
import { connect } from "react-redux";
import propTypes from "prop-types";
/*Actions */
import {
  saveSongParent,
  fetchAllSongParent,
  emptyError,
} from "../../Actions/SongParentAction";
/*Component */
import PublishDate from "../Layout/PublishDate";
import UploadImage from "../S3Uploading/UploadImage";
import UploadAudio from "../S3Uploading/UploadAudio";
import UploadVideo from "../S3Uploading/UploadVideo";

import { ALL_SONG_PARENTS } from "../../routes";
import { clearPreSignData, clearAll } from "../../Actions/VideoCipherActions";
import PromoWithMusicUploader from "../FilePondUploading/PromoWithMusicUploader";
import PromoWithoutMusicUploader from "../FilePondUploading/PromoWithoutMusicUploader";
import { SONG_FOLDER_NAME_WITH_MUSIC, SONG_FOLDER_NAME_WITH_OUT_MUSIC } from "../../portal/src/constants";
const INITIAL_STATE = {
  name: "",
  description: "",
  publish_date: "",
  imgURL: "",
  audio: "",
  promo_with_music: "",
  promo_without_music: "",
  videoKeyPromoWithMusic: "",
  videoKeyPromoWithoutMusic: "",
  error: true,
  errorMessage: "",
  nameInEnglish: "",
  descriptionInEnglish: "",
  featured: false
};

class AddSongParent extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleUploadPromoWithMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyPromoWithMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyPromoWithMusic: "" });
    }
  };
  handleUploadPromoWithoutMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({
        videoKeyPromoWithoutMusic: uploadInfo.videoId,
      });
    } else {
      this.setState({ videoKeyPromoWithoutMusic: "" });
    }
  };

  saveSongParent(
    name,
    description,
    imgURL,
    publish_date,
    audio,
    promo_with_music,
    promo_without_music,
    nameInEnglish,
    descriptionInEnglish,
    videoKeyPromoWithMusic,
    videoKeyPromoWithoutMusic,
    featured
  ) {
    // let promoWithMusicVideoId = this.props.vdocipherUploadInfo.PromoWithMusic
    //   ? this.props.vdocipherUploadInfo.PromoWithMusic.videoId
    //   : "";
    // let promoWithoutMusicVideoId = this.props.vdocipherUploadInfo
    //   .PromoWithoutMusic
    //   ? this.props.vdocipherUploadInfo.PromoWithoutMusic.videoId
    //   : "";
    this.props.saveSongParent(
      name,
      description,
      imgURL,
      publish_date,
      audio,
      promo_with_music,
      promo_without_music,
      nameInEnglish,
      descriptionInEnglish,
      videoKeyPromoWithMusic,
      videoKeyPromoWithoutMusic,
      featured
    );
  }

  getPublishDate(publishDateTime) {
    if (publishDateTime) {
      this.setState({ publish_date: publishDateTime });
    }
  }

  getImageUrl(imageUrl) {


    if (imageUrl === "delete") {
      this.setState({
        imgURL: "",
        error: true,
        errorMessage: "Empty Data is not accepted",
      });

    } else if (imageUrl !== undefined || imageUrl !== "") {
      this.setState({ imgURL: imageUrl });

      if (
        this.state.name !== "" &&
        this.state.name !== " " &&
        this.state.name !== null
      ) {
        this.setState({ error: false, errorMessage: "" });
      }
    } else {
      this.setState({
        imgURL: "",
        error: true,
        errorMessage: "Empty Data is not accepted",
      });

    }
  }

  getPromoWithMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ promo_with_music: videoUrl });


    } else {
      this.setState({ promo_with_music: "" });
    }
  }

  getPromoWithoutMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ promo_without_music: videoUrl });


    } else {
      this.setState({ promo_without_music: "" });
    }
  }

  handleChange = (e) => {
    //
    let checkSpaces = e.target.value.replace(/\s\s+/g, " ");
    const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
    this.setState({ [e.target.name]: checkSpaces });
    let nameOfState = e.target.name;
    setTimeout(() => {
      if (specialCharacters.test(this.state.name) || specialCharacters.test(this.state.nameInEnglish)){
        this.setState({
          error: true,
          errorMessage: "Song name can't contain spaecial characters",
        });
      }
      else if (
        this.state.imgURL === "" ||
        this.state.imgURL === null ||
        this.state.imgURL === undefined ||
        this.state.name === "" ||
        this.state.name === null ||
        this.state.name === " " ||
        this.state.descriptionInEnglish.length > 255
      ) {
        this.setState({
          error: true,
          errorMessage:
            nameOfState === "descriptionInEnglish"
              ? "Description in English is too long (greater than 255 characters)."
              : "Empty Data is not accepted",
        });
      }
      else if (
        (this.state.imgURL !== "" || this.state.imgURL !== undefined) &&
        (this.state.name !== null ||
          this.state.name !== "" ||
          this.state.name !== " ") &&
        this.state.descriptionInEnglish.length <= 255
      ) {
        this.setState({ error: false, errorMessage: "" });
      } else {
        this.setState({
          error: true,
          errorMessage: "Empty Data is not accepted",
        });
      }
    }, 0);
  };
  handleCheckboxChange = () => {
    this.setState({ featured: !this.state.featured })
  };

  handleSubmit = (e) => {
    const {
      name,
      description,
      publish_date,
      imgURL,
      audio,
      promo_with_music,
      promo_without_music,
      nameInEnglish,
      descriptionInEnglish,
      videoKeyPromoWithMusic,
      videoKeyPromoWithoutMusic,
      featured
    } = this.state;

    if (e.nativeEvent.submitter.id === "saveButton") {
      this.saveSongParent(
        name,
        description,
        imgURL,
        publish_date,
        audio,
        promo_with_music,
        promo_without_music,
        nameInEnglish,
        descriptionInEnglish,
        videoKeyPromoWithMusic,
        videoKeyPromoWithoutMusic,
        featured
      );
    }
    // this.closeModal();
    e.preventDefault();
  };

  getAudio(audioUrl) {
    // get  Audio
    if (audioUrl) {
      // check if url =  delete send empty to DB
      if (audioUrl === "delete") {
        this.setState({ audio: "" });
      }
      // else send the url to DB
      else {
        this.setState({ audio: audioUrl });
      }
    }
  }

  render() {
    const { error } = this.state;

    const { addingError, success, loading } = this.props;

    let $buttonSubmitLoader = (
      <Button disabled={this.state.error} id="saveButton">
        Add
      </Button>
    );

    if (loading === true) {
      $buttonSubmitLoader = (
        <button id="saveButton" class="ui loading button">
          Loading
        </button>
      );
    }

    if (success === false) {
      alert("error missing data or network " + addingError);
      this.props.emptyError();
      this.props.clearAll();
    } else if (success === true) {
      alert("Song Parent added Successfully");
      this.props.emptyError();
      this.props.fetchAllSongParent();
      this.props.clearAll();

      this.setState({
        name: "",
        description: "",
        publish_date: "",
        imgURL: "",
        audio: "",
        promo_with_music: "",
        promo_without_music: "",
        nameInEnglish: "",
        descriptionInEnglish: "",
        videoKeyPromoWithMusic: "",
        videoKeyPromoWithoutMusic: "",
      });

      return <Redirect to={ALL_SONG_PARENTS}></Redirect>;
    }

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <NavBar />
                      <br />
                      <div className="container">
                        <h3>Add New Song Parent</h3>
                        <hr />

                        <div className="">
                          <form onSubmit={this.handleSubmit.bind(this)}>
                            <div className="row">
                              {/* image Uploader */}
                              <div className="col-4">
                                <label className="label">
                                  <span className="required_input">*</span>
                                  Image
                                </label>
                                <UploadImage
                                  getImageURLCallBack={this.getImageUrl.bind(
                                    this
                                  )}
                                  imageUrl={this.state.imgURL}
                                  imagepreview={this.state.imgURL}
                                  s3DirectryrName="song"
                                />
                              </div>

                              <div className="col">
                                <p style={{ color: "red" }}>
                                  {this.state.errorMessage}{" "}
                                </p>
                                <label htmlFor="name" className="label">
                                  <span className="required_input">*</span>
                                  Name
                                </label>
                                <input
                                  dir="auto"
                                  className="form-control"
                                  fluid
                                  name="name"
                                  id="name"
                                  onChange={this.handleChange}
                                  value={this.state.name}
                                />
                                <br />

                                <label
                                  htmlFor="nameInEnglish"
                                  className="label"
                                >
                                  {/* <span className="required_input">*</span> */}
                                  Name In English
                                </label>
                                <input
                                  dir="auto"
                                  className="form-control"
                                  fluid
                                  name="nameInEnglish"
                                  id="nameInEnglish"
                                  onChange={this.handleChange}
                                  value={this.state.nameInEnglish}
                                />
                                <br />

                                <div className="form-group">
                                  <label
                                    htmlFor="description"
                                    className="label"
                                  >
                                    Description
                                  </label>

                                  <textarea
                                    dir="auto"
                                    fluid
                                    rows="3"
                                    className="form-control"
                                    name="description"
                                    id="description"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                  />
                                </div>
                                <br />

                                <div className="form-group">
                                  <label
                                    htmlFor="descriptionInEnglish"
                                    className="label"
                                  >
                                    Description In English
                                  </label>

                                  <textarea
                                    dir="auto"
                                    fluid
                                    rows="3"
                                    className="form-control"
                                    name="descriptionInEnglish"
                                    id="descriptionInEnglish"
                                    onChange={this.handleChange}
                                    value={this.state.descriptionInEnglish}
                                  />
                                </div>
                                <br />

                                <label htmlFor="name" className="label">
                                  Audio
                                </label>

                                <UploadAudio
                                  getAudioUrlCallBack={this.getAudio.bind(this)}
                                  audioPreview={this.state.audio}
                                  audioUrl={this.state.audio}
                                  s3DirectryrName="song/audio"
                                />
                                <br />

                                {/* DateComponent */}
                                <PublishDate
                                  getPublishDateCallBack={this.getPublishDate.bind(
                                    this
                                  )}
                                  value={this.state.publish_date}
                                />
                                <br />
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={this.state.featured}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  {' Featured'}
                                </label>
                                <br />
                              </div>
                            </div>

                            {error && <p>{error.message}</p>}
                            <label className="label">Promo With Music</label>
                            <div>
                              <PromoWithMusicUploader
                                id="PromoWithMusic"
                                onUploadInfo={this.handleUploadPromoWithMusic}
                                folderName={SONG_FOLDER_NAME_WITH_MUSIC}
                              />
                            </div>
                            <br />

                            <label className="label">Promo Without Music</label>
                            <div>
                              <PromoWithoutMusicUploader
                                id="PromoWithoutMusic"
                                onUploadInfo={
                                  this.handleUploadPromoWithoutMusic
                                }
                                folderName={SONG_FOLDER_NAME_WITH_OUT_MUSIC}
                              />
                            </div>
                            <br />

                            {/* <label className="label">Promo with music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getPromoWithMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.promo_with_music}
                                videoUrl={this.state.promo_with_music}
                                s3DirectryrName="song"
                              />
                            </div>
                            <br />

                            <label className="label">Promo without music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getPromoWithoutMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.promo_without_music}
                                videoUrl={this.state.promo_without_music}
                                s3DirectryrName="song"
                              />
                            </div>
                            <br /> */}

                            <div className="input-field">
                              {$buttonSubmitLoader}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

AddSongParent.propTypes = {
  saveSongParent: propTypes.func.isRequired,
  fetchAllSongParent: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  loading: state.songParent.loading,

  songParent: state.songParent.items,
  addingError: state.songParent.error_adding_song_parent,
  success: state.songParent.song_parent_added_sucessfully,
  // vdocipherUploadInfo: state.vdoCipher.entity,
});

export default withFirebase(
  connect(mapStateToProps, {
    saveSongParent,
    emptyError,
    fetchAllSongParent,
    clearPreSignData,
    clearAll,
  })(AddSongParent)
);
