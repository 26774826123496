import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Components */
import UserManagement from './UserManagement';
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import {SIGN_IN, ADMIN_LAND_PAGE, USER_MANAGEMENT} from '../../routes';

class UserManagementAuth extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { userPaymentProfile } = this.props;
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    const { userPaymentProfile } = this.props;

    if(userPaymentProfile.user_role.role !== "ADMIN" && userPaymentProfile.user_role.role !== "SuperAdmin"){
      return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
    }
    return (
      <span>
        {/* UserManagement */}
        <Route path={USER_MANAGEMENT} component={UserManagement} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(UserManagementAuth)
);
