import React from 'react';

const Footer =() =>
{
    return(<div className="container">
                    <footer className="footer-style mt-3 mb-3">
                        <p className="text-center">© 2019 All rights reserved </p>
                    </footer>
     </div>)
}
export default Footer;
