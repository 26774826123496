import React, { Component } from 'react'
import queryString from 'query-string';
import axios from 'axios';

import { getPaymentstatus } from '../../Promises/HyperPay';

class ResultPage extends Component {
    state = {
        responseData: null,
        loading: true
    }
    componentDidMount() {
        const id = this.props.match.params.id;
        // const parsed = queryString.parse(this.props.location.search)
        // const resourcePath = parsed.resourcePath;
       
        getPaymentstatus(id).then(res => {
            console.log(res.data.id)
            this.setState({
                responseData: res.data,
                loading: false
            })
        })
        // axios.post("http://tstname.xyz/phpapi/api/v1/results", {
        //     resourcePath
        // }).then(res => {
        //     this.setState({
        //         responseData: res.data,
        //         loading: false
        //     })
        // })
    }

    checkResult = () => {
        const successPattern =/^(000\.000\.|000\.100\.1|000\.[36])/;
        const manuallPattern =/^(000\.400\.0[^3]|000\.400\.100)/;
        const match1 =
            successPattern.test(this.state.responseData.result.code);
        const match2 =
            manuallPattern.test(this.state.responseData.result.code);
        console.log(match1, match2)
        if (match1 || match2) {
            return (
                <div>
                    <h1>العملیة ناجحة</h1>
                    <h3> {this.state.responseData.result.description} </h3>
                </div>)
        } else {
            return (
                <div>
                    <h1>
                        خطأ في العملیة
                    </h1>
                    <h3> {this.state.responseData.result.description} </h3>
                </div>
            )
        }
    }
    render() {
        if (!this.state.loading) {
            return (
                <div>
                    {this.checkResult()}
                </div>
            );
        } else {
            return (
                <div>
                    <h1> Loading </h1>
                </div>
            );
        }
    }
}


export default ResultPage
