import React, { Component } from 'react';
import Loader from '../Layout/Loader';
import { ProgressBar } from 'react-bootstrap';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader'
import '../../css/AppCss/dropzone.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../node_modules/video-react/dist/video-react.css';

import ReactPlayer from 'react-player'

const INITIAL_STATE = {
    videoWithMusic: "",
    videoWithMusicPreviewUrl: "",
    videoWithMusicUrl: "",
    videoError: "",
    completed: 0,
    s3DirectryrName: '',
    duration: '',
    uploadingDone: false


}

const _validFileExtensions = [".mp4", ".3gp", ".wmv", ".flv"];
// const bucketName = "newgeneprivate"


export class UploadVideo extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE

    }

    componentWillMount() {

        if (this.props.videoPreview !== undefined
            || this.props.videoUrl !== undefined
            || this.props.s3DirectryrName !== undefined) {
            this.setState({
                videoWithMusicPreviewUrl: this.props.videoPreview,
                videoWithMusicUrl: this.props.videoUrl,
                s3DirectryrName: this.props.s3DirectryrName

            })
        }
    }

    componentWillReceiveProps(nextProp) {
        if (nextProp.videoPreview !== undefined
            || nextProp.videoUrl !== undefined
            || nextProp.s3DirectryrName !== undefined) {
            this.setState({
                videoWithMusicPreviewUrl: nextProp.videoPreview,
                videoWithMusicUrl: nextProp.videoUrl,
                s3DirectryrName: nextProp.s3DirectryrName

            })
        }
    }
    // Validate(file) {
    //     var blnValid = false;
    //     var sFileName = file.name;

    //     console.log("Validate type" + file.type);
    //     console.log("Validate name" + file.name);

    //     for (var j = 0; j < _validFileExtensions.length; j++) {
    //         var sCurExtension = _validFileExtensions[j];
    //         if (sFileName.substr(sFileName.length - sCurExtension.length,
    //             sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
    //             blnValid = true;
    //             break;
    //         }
    //     }

    //     if (!blnValid) {
    //         alert("Sorry, " + sFileName +
    //             " is invalid , allowed extensions are: " +
    //             _validFileExtensions.join(", "));
    //         return false;
    //     }

    //     return true;
    // }

    /********************** start testing with java backend ************************** */
    onUploadProgress = (percent) => {
        this.setState({ completed: percent });
    }

    onError = (errror) => {
        alert(errror)
    }

    onUploadFinish = (e) => {
        this.setState({
            videoWithMusicPreviewUrl: e.publicUrl,
            videoWithMusicUrl: e.publicUrl,
            uploadingDone: true
        })
        console.log("videoWithMusicUrl url", e.publicUrl)
        // this.props.getVideoURLCallBack(e.publicUrl);
        // console.log(e.publicUrl);
    }


    handleDeleteVideo(e) {
        this.setState({
            videoError: '',
            videoWithMusicPreviewUrl: '',
            completed: 0,
            duration: '',
            uploadingDone: false
        })

        this.props.getVideoURLCallBack('delete', 0);

    }


    handleDuration = (duration) => {
        console.log('onDuration', duration)
        this.setState({ duration: duration })
    }

    callbackdata = () => {

        this.props.getVideoURLCallBack(
            this.state.videoWithMusicPreviewUrl,
            Math.ceil(this.state.duration));

        this.setState({
            uploadingDone: false,
            duration: ''
        })
    }


    render() {

        const { s3DirectryrName } = this.state;

        let uploadinglink = 'newgeneprivate';
        if (s3DirectryrName !== '') {
            uploadinglink = 'newgeneprivate/' + s3DirectryrName;
        }
        // console.log(uploadinglink)

        const uploadOptions = {

            signingUrl: "/W3S/getPresignedURL",
            signingUrlMethod: 'POST',
            signingUrlHeaders: {
                'content-type': 'application/json'
                // ,
                // 'X-Authorization-Firebase' : token
            },
            signingUrlQueryParams: { 'bucketName': uploadinglink },
            uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
            contentDisposition: "auto",
            scrubFilename: (filename) => new Date().getTime()

        }

        let { videoWithMusicUrl, videoError } = this.state
        let loadVideoWithMusic = null;

        let { videoWithMusicPreviewUrl } = this.state;
        let $videoWithMusicPreview = null;

        let $dropzone = (
            <div className=''>

                <label id="" className="new-items">
                    <div id="" className="add-box">
                        <div id="d-hide">


                            <DropzoneS3Uploader accept="video/*"
                                onFinish={this.onUploadFinish}
                                s3Url='https://newgenepublic.s3.amazonaws.com/newgenepublic/'
                                onProgress={this.onUploadProgress}
                                upload={uploadOptions}

                            />

                        </div>
                        <p className="small-font mt-2 mb-0 upload-icon-p">Click to Upload icon</p>
                    </div>  </label>
                <div>
                    <br />
                    <ProgressBar animated striped bsstyle="success" now={this.state.completed} label={`${this.state.completed}%`} />
                </div>
            </div>
        );

        if (videoWithMusicPreviewUrl) {

            if (videoWithMusicUrl === '') {
                if (videoError === '') { loadVideoWithMusic = (<Loader />) }

                else if (videoError) {
                    $videoWithMusicPreview = $dropzone;
                    this.setState({ videoError: '', videoWithMusicPreviewUrl: '', duration: '' })
                }
            }
            else {
                if (this.state.uploadingDone === true &&
                    this.state.duration !== '') {

                    this.callbackdata();
                }
                $videoWithMusicPreview = (
                    <span>
                        <small className="removeVideo"
                            onClick={e => this.handleDeleteVideo(e)}>
                            <FontAwesomeIcon icon="times" color="#dc6d6d" className="true" /></small>
                        <section className="video_section" >
                            <ReactPlayer
                                playsinline
                                fluid
                                url={videoWithMusicPreviewUrl}
                                onDuration={this.handleDuration}
                                playing={true}
                                controls={true}
                                width="100%"
                                className='react-player'

                            />
                        </section>
                    </span>
                );
            }
        }
        else {
            $videoWithMusicPreview = $dropzone;
        }

        return (
            <div>
                {/* <section className="video_section" >
                        <Player playsInline fluid src="https://newgeneprivate.s3.amazonaws.com/%C3%99%C2%81%C3%99%C2%8A%C3%98%C2%AF%C3%99%C2%8A%C3%99%C2%88+%C3%98%C2%A8%C3%98%C2%A7%C3%99%C2%84%C3%98%C2%B9%C3%98%C2%B1%C3%98%C2%A8%C3%99%C2%89+%C3%98%C2%A7%C3%99%C2%84%C3%99%C2%82%C3%98%C2%B1%C3%98%C2%AF+%C3%98%C2%A7%C3%99%C2%84%C3%98%C2%B5%C3%98%C2%A7%C3%98%C2%AD%C3%99%C2%8905_09_2019_16_5.mp4" />
                    </section>); */}
                {$videoWithMusicPreview}
                {loadVideoWithMusic}
            </div>
        )
    }
}

export default UploadVideo
