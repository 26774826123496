import {
    EMPTY_CUSTOMER, EMPTY_PARENT,
    FETCH_ALL_CUSTOMERS, LOAD_ALL_CUSTOMERS, ERROR_ALL_CUSTOMERS,
    ADDING_CUSTOMERS, ERROR_ADDING_CUSTOMERS,
    LOAD_CUSTOMER, GET_CUSTOMER_DATA, ERROR_LOADING_CUSTOMER,
    LOAD_SENDING_MAILS, SEND_EMAIL, ERROR_SEND_EMAIL,
    LOAD_SENDING_NOTIFICATION, SEND_NOTIFICATION, ERROR_SENDING_NOTIFICATION,
    ERROR_SENDING_NOTIFICATION_TO_ALLCUSTOMERS, SEND_NOTIFICATION_TO_ALLCUSTOMERS,
    SETTING_STATE_CUSTOMER, ERROR_SETTING_STATE_CUSTOMER,
    CUSTOMER_RESET_PASSWORD, ERRROR_CUSTOMER_RESET_PASSWORD,
    ACTIVE_CUSTOMER_PACKAGE, ERROR_ACTIVE_CUSTOMER_PACKAGE,
    EMPTY_PACKAGE_ACTIVATION, ERROR_ADDING_CUSTOMER, ADD_CUSTOMER, EDIT_CUSTOMER,
    ERROR_EDITING_CUSTOMER, ADD_CHILD, REMOVE_CHILD, CUSTOMER_FIRST_SIGN_UP, CUSTOMER_SIGNNED_IN


} from '../Actions/Types';

const initialState = {
    items: [],
    item: {},
    showModal: '',
    showModal_1:'',

    notificationShowModal: '',

    loading: '',

    getting_allCustomers_error: '',
    getting_allCustomers_sucessfully: '',


    adding_customer_sucessfully: '',
    adding_customer_error: '',

    editing_customer_sucessfully: '',
    editing_customer_error: '',

    get_customer_sucessfully: '',
    get_customer_error: '',

    send_email_sucessfully: '',
    send_email_error: '',

    send_notification_sucessfully: '',
    send_notification_error: '',

    send_notification_to_AllCustomers_sucessfully: '',

    blocking_customer_error: '',
    blocking_customer_sucessfully: '',

    customer_reset_password_sucessfully: '',
    customer_reset_password_error: '',

    active_customer_package_sucessfully: '',
    active_customer_package_error: '',

    recaptcha: '',

    isChild: '',

    firstSignUp: ''
}


export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_CUSTOMERS:
            return {
                ...state,
                items: action.payload,
                getting_allCustomers_error: '',
                getting_allCustomers_sucessfully: true,
                loading: false
            }

        case LOAD_ALL_CUSTOMERS, LOAD_CUSTOMER,
            LOAD_SENDING_MAILS, LOAD_SENDING_NOTIFICATION:
            return {
                ...state,
                loading: true
            }

        case ERROR_ALL_CUSTOMERS:
            return {
                ...state,
                getting_allCustomers_error: action.payload,
                loading: false
            }

        case ADDING_CUSTOMERS:
            return {
                ...state,
                item: action.payload

            }

        case ERROR_ADDING_CUSTOMERS:
            return {
                ...state,
                adding_customer_error: action.payload,
                adding_customer_sucessfully: false,
                loading: false

            }

        case GET_CUSTOMER_DATA:
            return {
                ...state,
                item: action.payload,
                loading: false,
                get_customer_sucessfully: true,
            }

        case ERROR_LOADING_CUSTOMER:
            return {
                ...state,
                get_customer_error: action.payload,
                loading: false
            }

        case SEND_EMAIL:
            return {
                ...state,
                item: action.payload,
                send_email_error: '',
                send_email_sucessfully: true,
                loading: false,
                showModal: false,
            }

        case ERROR_SEND_EMAIL:
            return {
                ...state,
                send_email_error: action.payload,
                send_email_sucessfully: false,
                loading: false,
            }

        case SEND_NOTIFICATION:
            return {
                ...state,
                item: action.payload,
                send_notification_error: '',
                send_notification_sucessfully: true,
                loading: false,
                notificationShowModal: false,
            }

        case ERROR_SENDING_NOTIFICATION:
            return {
                ...state,
                send_notification_error: action.payload,
                send_notification_sucessfully: false,
                loading: false
            }

        case SEND_NOTIFICATION_TO_ALLCUSTOMERS:
            return {
                ...state,
                item: action.payload,
                send_notification_error: '',
                send_notification_to_AllCustomers_sucessfully: true,
                loading: false,
                notificationShowModal: false,
            }

        case ERROR_SENDING_NOTIFICATION_TO_ALLCUSTOMERS:
            return {
                ...state,
                send_notification_error: action.payload,
                send_notification_to_AllCustomers_sucessfully: false,
                loading: false
            }

        case SETTING_STATE_CUSTOMER:
            return {
                ...state,
                item: action.payload,
                blocking_customer_sucessfully: true,
                blocking_customer_error: '',
                loading: false
            }

        case ERROR_SETTING_STATE_CUSTOMER:
            return {
                ...state,
                blocking_customer_error: action.payload,
                blocking_customer_sucessfully: false,
                loading: false
            }

        case CUSTOMER_RESET_PASSWORD:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                customer_reset_password_sucessfully: true,
                customer_reset_password_error: '',
                loading: false
            }

        case ERRROR_CUSTOMER_RESET_PASSWORD:
            return {
                ...state,

                customer_reset_password_error: action.payload,
                customer_reset_password_sucessfully: false,
                loading: false
            }

        case ACTIVE_CUSTOMER_PACKAGE:
            return {
                ...state,
                item: action.payload,
                showModal_1: false,
                active_customer_package_sucessfully: true,
                active_customer_package_error: '',
                loading: false
            }

        case ERROR_ACTIVE_CUSTOMER_PACKAGE:
            return {
                ...state,
                active_customer_package_error: action.payload,
                active_customer_package_sucessfully: false,
                loading: false
            }

        case EMPTY_PACKAGE_ACTIVATION :
            return {
                ...state,
                active_customer_package_sucessfully: '',
                active_customer_package_error: '',

            }

        case EMPTY_CUSTOMER:
            console.log("Enter the reducer = ")
            return {
                ...state,

                adding_customer_error: action.payload,
                adding_customer_sucessfully: action.payload,

                get_customer_sucessfully: action.payload,
                get_customer_error: action.payload,

                send_email_sucessfully: action.payload,
                send_email_error: action.payload,

                send_notification_error: action.payload,
                send_notification_sucessfully: action.payload,

                send_notification_to_AllCustomers_sucessfully: action.payload,
                send_notification_to_AllCustomers_error: action.payload,


                blocking_customer_error: action.payload,
                blocking_customer_sucessfully: action.payload,

                customer_reset_password_sucessfully: action.payload,
                customer_reset_password_error: action.payload,


                loading: action.payload,
                item: {},

                editing_customer_sucessfully: action.payload,
                editing_customer_error: action.payload,
            }

            case EMPTY_PARENT:
                return {
                    ...state,

                    adding_customer_error: '',
                    adding_customer_sucessfully: '',

                    get_customer_sucessfully: '',
                    get_customer_error: '',

                    editing_customer_sucessfully: '',
                    editing_customer_error: '',

                    send_email_sucessfully: '',
                    send_email_error: '',

                    send_notification_error: '',
                    send_notification_sucessfully: '',

                    send_notification_to_AllCustomers_sucessfully: '',
                    send_notification_to_AllCustomers_error: '',


                    blocking_customer_error: '',
                    blocking_customer_sucessfully: '',

                    customer_reset_password_sucessfully: '',
                    customer_reset_password_error: '',


                    // loading: action.payload,
                    item: {},
                }

        case ERROR_ADDING_CUSTOMER:
          return {
            ...state,
            item: {},
            adding_customer_sucessfully: false,
            adding_customer_error:action.payload,
            loading: ''
          }

        case ADD_CUSTOMER:
          return {
            ...state,
            item: action.payload,
            adding_customer_sucessfully: true,
            adding_customer_error: '',
            loading: ''
        }


        case EDIT_CUSTOMER:
          return {
            ...state,
            item: action.payload,
            editing_customer_sucessfully: true,
            editing_customer_error: '',
            loading: ''
        }

        case ERROR_EDITING_CUSTOMER:
          return {
            ...state,
            item: {},
            editing_customer_sucessfully: false,
            editing_customer_error: action.payload,
            loading: ''
        }

        case ADD_CHILD:
          return {
            ...state,
            isChild: action.payload,
            loading: ''
          }

        case REMOVE_CHILD:
          return {
            ...state,
            isChild: '',
          }

        case CUSTOMER_FIRST_SIGN_UP:
          return {
            ...state,
            firstSignUp: action.payload,
          }

        case CUSTOMER_SIGNNED_IN:
          return {
            ...state,
            firstSignUp: '',
          }

        default:
            return state
    }

}
