import {
    LOAD_All_TERMSANDCONDITIONS, FETCH_All_TERMSANDCONDITIONS, ERROR_ALL_TERMSANDCONDITIONS,
    ERROR_UPDATE_TERMSANDCONDITIONS, UPDATE_TERMSANDCONDITIONS, LOAD_TERMSANDCONDITIONS,
    ADD_TERMSANDCONDITIONS, ERROR_ADDING_TERMSANDCONDITIONS,
    DELETING_TERMSANDCONDITIONS, ERROR_DELETING_TERMSANDCONDITIONS
} from './Types'

import axios, { post, put } from 'axios'

export const fetchAllTermsAndConditions = () => dispatch => {

    dispatch({
        type: LOAD_All_TERMSANDCONDITIONS,
        payload: 'loading'
    })

    axios.get('/TermsAndConditions/getAllTermsAndconditionsList')
        .then((res) => {
            dispatch({
                type: FETCH_All_TERMSANDCONDITIONS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_ALL_TERMSANDCONDITIONS,
                payload: Error.message
            })
        })
}

export const updateTermsAndConditions = (termsAndConditions_ID, title, text) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_TERMSANDCONDITIONS,
                payload: 'loading'
            })

            const url = "/TermsAndConditions/updateTermsAndConditions";
            const formData = new FormData();
            formData.append('TermsAndConditions_ID', termsAndConditions_ID);
            formData.append('Title', title);
            formData.append('Text', text);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_TERMSANDCONDITIONS,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_TERMSANDCONDITIONS,
                    payload: Error.message
                })
            })
        })

    }
}

export const addTermsAndConditions = (title, text) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_TERMSANDCONDITIONS,
                payload: 'loading'
            })

            const url = "/TermsAndConditions/addTermsAndConditions";
            const formData = new FormData();
            formData.append('Title', title);
            formData.append('Text', text);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_TERMSANDCONDITIONS,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_TERMSANDCONDITIONS,
                    payload: Error.message
                })
            })
        })


    }
}

export const deleteFAQTermsAndConditions = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_TERMSANDCONDITIONS,
                payload: 'loading'
            })

            const url = "/TermsAndConditions/deleteTermsAndConditions";
            const formData = new FormData();
            formData.append('TermsAndConditions_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_TERMSANDCONDITIONS,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_TERMSANDCONDITIONS,
                    payload: Error.message
                })
            })
        })

    }
}