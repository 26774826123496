import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Accordion, Icon } from 'semantic-ui-react';

/*Components*/
import ContentMainValueCard from './ContentMainValueCard'
import OuterHeader from '../Layout/OuterHeader'
import Dashboard from '../Dashboard'
import InnerHeader from '../Layout/InnerHeader'
import Footer from '../Layout/Footer'
import AddMainValue from './AddMainValue'

import NAME_CONDITIONS from '../ConstData/NameFilterConditions';
import DESCRIPTION_CONDITIONS from '../ConstData/NameFilterConditions';
/* Routes */
import { ADMIN_LAND_PAGE } from '../../routes';
var filterOnMainValue = true;

export class ContentValues extends Component {

    state = {

        activeIndex: 0,

        filterName: '',
        nameFilterCondition: 'contains',

        descriptionFilterCondition: 'contains',
        description: '',

    }

    handleChange = (e) => {

        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
    }

    handleNameFilterConditions = (e, { value }) => {
        this.setState({
            nameFilterCondition: value,
        })
    }

    handleDescriptionFilterConditions = (e, { value }) => {
        this.setState({
            descriptionFilterCondition: value,
        })
    }

    handleRadioChange = (e) => {

        this.setState({
            [e.target.name]: e.target.value,
            filterName: '',
            nameFilterCondition: 'contains',

            descriptionFilterCondition: 'contains',
            description: '',
        });

        if (e.target.value === "MainValue") {

            filterOnMainValue = true
        }
        else {
            filterOnMainValue = false
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {

        var description,
            name = (<div className="row" >


                <div className="col-md-5 col-5">
                    <label className="label">Sub Value Name Conditions</label>

                    <Dropdown
                        className="form-control"
                        placeholder='Select Condition'
                        selection
                        options={NAME_CONDITIONS}
                        onChange={this.handleNameFilterConditions}
                        value={this.state.nameFilterCondition}
                    />
                </div>
                <div className="col-md-6 col-6">

                    <label className="label">Sub Value Name</label>

                    <input
                        dir="auto"
                        className="form-control"
                        name="filterName"
                        placeholder='..'
                        id="filterName"
                        onChange={this.handleChange}
                        value={this.state.filterName} />

                </div>
            </div>);

        if (filterOnMainValue === true) {
            description = (<span>
                <div className="row" >

                    <div className="col-md-5 col-5">
                        <label className="label">Main Value Description Conditions</label>

                        <Dropdown
                            className="form-control"
                            placeholder='Select Condition'
                            selection
                            options={DESCRIPTION_CONDITIONS}
                            onChange={this.handleDescriptionFilterConditions}
                            value={this.state.descriptionFilterCondition}
                        />
                    </div>
                    <div className="col-md-6 col-6">

                        <label className="label">Main Value Description</label>

                        <input
                            dir="auto"
                            className="form-control"
                            name="description"
                            placeholder='..'
                            id="description"
                            onChange={this.handleChange}
                            value={this.state.description} />

                    </div>
                </div>
                <br />
            </span>)
            name = (<div className="row" >


                <div className="col-md-5 col-5">
                    <label className="label">Main Value Name Conditions</label>

                    <Dropdown
                        className="form-control"
                        placeholder='Select Condition'
                        selection
                        options={NAME_CONDITIONS}
                        onChange={this.handleNameFilterConditions}
                        value={this.state.nameFilterCondition}
                    />
                </div>
                <div className="col-md-6 col-6">

                    <label className="label">Main Value Name</label>

                    <input
                        dir="auto"
                        className="form-control"
                        name="filterName"
                        placeholder='..'
                        id="filterName"
                        onChange={this.handleChange}
                        value={this.state.filterName} />

                </div>
            </div>)
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body"
                                            id="left-main-body">

                                            <div className="box-container">

                                                {/* <!--  start status bar --> */}

                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    </span>
                                                    <span>Content Values</span>
                                                </div>
                                                {/* <!--  end status bar --> */}


                                                <div className="main-title-container">
                                                    <h5 className="main-title mt-2 font-weight-bold">
                                                        Content Values
                                                    </h5>
                                                    {/* Add MainValue  Component */}

                                                    <AddMainValue />

                                                </div>

                                                <br />
                                                <Accordion fluid styled>
                                                    <Accordion.Title
                                                        active={this.state.activeIndex === 0}
                                                        index={0}
                                                        onClick={this.handleClick}>

                                                        <Icon name='dropdown' />
                                                    Filter
                                                </Accordion.Title>
                                                    <Accordion.Content active={this.state.activeIndex === 0}>

                                                        <div class="row justify-content-md-center">

                                                            {/* <div className="col-md-12 col-12 "> */}
                                                            <div className="col-md-3 col-3 ">

                                                                <label className="label">Main Value</label>
                                                                <input type="radio" name="filterON" value="MainValue"
                                                                    onChange={this.handleRadioChange}
                                                                    checked={filterOnMainValue} />
                                                            </div>
                                                            <div className="col-md-3 col-3 ">

                                                                <label className="label">Sub Value</label>
                                                                <input type="radio" name="filterON" value="SubValue"
                                                                    onChange={this.handleRadioChange} />
                                                            </div>

                                                        </div>
                                                        <br />
                                                        {name}
                                                        <br />
                                                        {description}

                                                    </Accordion.Content>

                                                </Accordion>



                                                {/* Get All MainValue Cards */}
                                                <ContentMainValueCard
                                                    filterName={this.state.filterName}
                                                    nameFilterCondition={this.state.nameFilterCondition}
                                                    descriptionFilterCondition={this.state.descriptionFilterCondition}
                                                    description={this.state.description}
                                                    filterOnMainValue={filterOnMainValue}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default ContentValues
