import {
  ADD_CODE, ERROR_ADDING_CODE, LOAD_CODE,
  FETCH_ALL_CODES, LOAD_ALL_CODES, ERROR_ALL_CODES,
  EMPTY_CODE, ERROR_DELETING_CODE, DELETING_CODE,
  FETCH_CODE_DATA, ERROR_LOADING_CODE, EDIT_CODE,
  ERROR_EDITING_CODE, ADD_CODE_TO_REDUX , EMPTY_CODE_FROM_REDUX,
  ERROR_FETCH_SAVED_CODE, LOAD_SAVED_CODE, FETCH_SAVED_CODE,
  SAVE_CODE, ERROR_SAVING_CODE, LOAD_SAVING_CODE, CLEAR_PROMO_CODE_ALL
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    loading: '',
    getting_allCodes_sucessfully: '',
    getting_allCodes_error: '',
    error_adding_code:'',
    code_added_successfully:'',
    code_updated_successfully:'',
    error_updating_code:'',
    code_deleted_successfully:'',
    error_deleting_code:'',
    error_loading_code: '',
    get_code_sucessfully: '',
    // code saved in redux
    code_name: '',
    code_note: '',
    // end
    // for fetching saved code
    saved: {},
    get_saved_code_sucessfully: '',
    error_loading_saved_code: '',
    loading_saved: '',
    //// end
    /// for saving a new code by user
    new_code_saved: {},
    loading_for_saving: '',
    new_code_saved_successfully: '',
    error_saving_new_code: '',
    /// end
    promoCodeWork: false,


}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOAD_CODE:
            return {
                ...state,
                loading: true
            }

        case FETCH_CODE_DATA:
            return{
                ...state,
                item: action.payload,
                error_loading_code: '',
                get_code_sucessfully: true,
                loading: false,
                promoCodeWork: true,
               
            }

        case ERROR_LOADING_CODE:
            return{
                ...state,
                error_loading_code: action.payload,
                get_code_sucessfully: false,
                loading: '',
                promoCodeWork:false,
                code_name: '',
            }

        case ADD_CODE:
            return {
                ...state,
                item: action.payload,
                code_added_successfully: true,
                error_adding_code: ''
            }

        case ERROR_ADDING_CODE:
            return {
                ...state,
                error_adding_code: action.payload,
                code_added_successfully: false
            }

        case FETCH_ALL_CODES:
            return {
                ...state,
                items: action.payload,
                getting_allCodes_error: '',
                getting_allCodes_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_CODES:
            return {
                ...state,
                getting_allCodes_error: action.payload,
                getting_allCodes_sucessfully: '',
                loading: false
            }

        case LOAD_ALL_CODES:
            return {
                ...state,
                loading: true
            }

        case ERROR_DELETING_CODE:
            return {
                ...state,
                error_deleting_code: action.payload,
                code_deleted_successfully: false,
                loading: false
            }

        case DELETING_CODE:
          return {
              ...state,
              item: action.payload,
              error_deleting_code: '',
              code_deleted_successfully: true,
              loading: false
          }

        case EDIT_CODE:
            return {
              ...state,
              item: action.payload,
              code_updated_successfully:true,
              error_updating_code: ''
            }

        case ERROR_EDITING_CODE:
            return {
                ...state,
                error_updating_code: action.payload,
                code_updated_successfully: false,
                loading: ''
            }

        case EMPTY_CODE:
            return {
                ...state,
                code_added_successfully:'',
                code_updated_successfully:'',
                code_deleted_successfully:'',
                getting_allCodes_sucessfully: '',
                get_code_sucessfully: '',
                loading: '',

                getting_allCodes_error: '',
                error_adding_code: '',
                error_deleting_code: '',
                error_loading_code: '',
                error_updating_code: '',
                //code_name: '',
                new_code_saved_successfully: '',
                item: '',
            }
            case ADD_CODE_TO_REDUX:
                return {
                    ...state,
                    code_name: action.payload.code_name,
                    code_note: action.payload.code_note,
                }

            case EMPTY_CODE_FROM_REDUX:
                return {
                    ...state,
                    code_name: '',
                    code_note: '',
                }
            case FETCH_SAVED_CODE:
                return {
                    ...state,
                    saved: action.payload,
                    get_saved_code_sucessfully: true,
                    error_loading_saved_code: '',
                    loading_saved: false,
                }
            case LOAD_SAVED_CODE:
                return {
                    ...state,
                    loading_saved: true,
                }
            case ERROR_FETCH_SAVED_CODE:
                  return{
                    ...state,
                    loading_saved: '',
                    get_saved_code_sucessfully: false,
                    error_getting_saved_code: action.payload,
                    saved: {},
                  }
            case SAVE_CODE:
                return {
                    ...state,
                    new_code_saved: action.payload,
                    loading_for_saving: '',
                    new_code_saved_successfully: true,
                    error_saving_new_code: '',
                }
            case LOAD_SAVING_CODE:
                return {
                    ...state,
                    loading_for_saving: true,
                }
            case ERROR_SAVING_CODE:
                  return{
                    ...state,
                    new_code_saved: '',
                    loading_for_saving: '',
                    new_code_saved_successfully: false,
                    error_saving_new_code: action.payload,
                  }
            case CLEAR_PROMO_CODE_ALL:
                  return {
                    ...state,
                    items: [],
                    item: {},
                    loading: '',
                    getting_allCodes_sucessfully: '',
                    getting_allCodes_error: '',
                    error_adding_code:'',
                    code_added_successfully:'',
                    code_updated_successfully:'',
                    error_updating_code:'',
                    code_deleted_successfully:'',
                    error_deleting_code:'',
                    error_loading_code: '',
                    get_code_sucessfully: '',
                    // code saved in redux
                    // end
                    // for fetching saved code
                    saved: {},
                    get_saved_code_sucessfully: '',
                    error_loading_saved_code: '',
                    loading_saved: '',
                    //// end
                    /// for saving a new code by user
                    new_code_saved: {},
                    loading_for_saving: '',
                    new_code_saved_successfully: '',
                    error_saving_new_code: '',
                  }
        default:
            return state
    }

}
