import React,{Component} from "react";
import {connect} from 'react-redux';
import { setEducationalVideoStatus,deleteEducationalVideo,getAllEducationalVideos,emptyEducationalVideo } from "../../Actions/ParentLibraryAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Redirect} from 'react-router-dom'
import { Confirm } from 'semantic-ui-react'
/* Routes */
import { ALL_EDUCATIONAL_VIDEOS } from '../../routes';

class DeleteParentLibrary extends Component
{
    state={open:false}
    open=()=>this.setState({open:true})
    close =()=>this.setState({open:false})

    removeParentLibrary(id)
    {
        this.props.deleteEducationalVideo(id);
    }


    handleSubmit =(e)=>
    {
        const {id} = this.props;
        this.removeParentLibrary(id);
        this.close();
    }

    render ()
    {
        const {parent_library_deleted_successfully,error_deleting_parent_library} = this.props;
        if (parent_library_deleted_successfully===false)
        {
            alert ("error deleting Educational Video"+ error_deleting_parent_library);
            this.props.emptyEducationalVideo();
        }
        else if (parent_library_deleted_successfully===true)
        {
            alert("Educational Video deleted successfully");
            this.props.emptyEducationalVideo();
            this.props.getAllEducationalVideos();
            return (<Redirect to={ALL_EDUCATIONAL_VIDEOS}></Redirect>)
        }
        return (<div >
            <button className="seriesCardButtons"  onClick={this.open}>
                 
                        <FontAwesomeIcon icon="trash-alt" />
                  
                </button>
                <Confirm id="modal"
                open={this.state.open}
                onCancel={this.close}
                content='Are You Sure To Delete This Educational Video ? '
                onConfirm={this.handleSubmit} />
            </div>)

    }

}
const mapStateToProps = state =>
({
    parent_library_deleted_successfully:state.parentLibrary.educationalVideo_deleted_successfully,
    error_deleting_parent_library:state.parentLibrary.error_deleting_educationalVideo,

})
export default connect (mapStateToProps,{setEducationalVideoStatus,deleteEducationalVideo,getAllEducationalVideos,emptyEducationalVideo}) (DeleteParentLibrary)
