import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    deleteIssue,
    emptyError,
    getIssuesByType
} from '../../Actions/IssueAction'

class DeleteIssue extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteIssue(id) {
        this.props.deleteIssue(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        this.deleteIssue(id);
        this.close();

    }

    render() {

        const { type } = this.props;

        console.log(type)
        let confirm = ''
        

        if (type === "Suggestion") {
            confirm = (
                <Confirm id="modal"
                    content='Are you sure you want to delete this Suggestion ?? '
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />)
        }else{
            confirm = (
                <Confirm id="modal"
                    content='Are you sure you want to delete this Complaint ?? '
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />)
        }

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Deleted Successfully")
            // get all suggesstion
            if(type === "Suggestion"){
                this.props.getIssuesByType(1);

            }
            // get all complains
            else{
                this.props.getIssuesByType(2);
            }

            this.props.emptyError();
        }

        return (
            <div className="acor-btn-group">

                <button className="acor-btn2" onClick={this.open}>
                        <FontAwesomeIcon icon="trash-alt" />
                </button>
            {confirm}
            </div>

        )
    }
}


DeleteIssue.propTypes = {
    deleteIssue: propTypes.func.isRequired,
    getIssuesByType: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    error: state.issues.delete_issue_error,
    success: state.issues.delete_issue_successfully
});


export default withFirebase
    (connect(
        mapStateToProps,
        {
            deleteIssue,
            emptyError,
            getIssuesByType

        })
        (DeleteIssue));

