import axios, { post, put } from 'axios'
import { LOAD_BLOG_CATEGORIES, GET_BLOG_CATEGPORIES, ERROR_LOADING_BLOG_CATEGORIES, BLOG_CATEGORY_DELETED, ERROR_DELETING_BLOG_CATEGORY, SAVE_BLOG_CATEGORY, ERROR_ADDING_BLOG_CATEGORY, UPDATE_BLOG_CATEGORY, ERROR_UPDATE_BLOG_CATEGORY, EMPTY_BLOG_CATEGORY } from './Types'

export const getBlogCategories = () => dispatch => {
    dispatch({
        type: LOAD_BLOG_CATEGORIES,
        payload: 'loading'
    })
    axios.get('/BlogCategory/getAllBlogCategory').then((res) => {
        dispatch({
            type: GET_BLOG_CATEGPORIES,
            payload: res.data.results

        })
    }).catch((Error) => {
        dispatch({
            type: ERROR_LOADING_BLOG_CATEGORIES,
            payload: Error.message
        })
    })
}
export const deleteBlogCategory = (id) => {
    console.log("deleted blog cat in action = " + id)
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = '/BlogCategory/deleteBlogCategory';
            const formData = new FormData();
            formData.append('Blog_Category_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: BLOG_CATEGORY_DELETED,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_BLOG_CATEGORY,
                    payload: Error.message
                })
            })
        })
    }
}



export const updateBlogCategory = (id, categoryText) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/BlogCategory/updateBlogCategory"
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Category_Text', categoryText);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch({
                    type: UPDATE_BLOG_CATEGORY,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_BLOG_CATEGORY,
                    payload: Error.message
                })
            })
        })
    }
}

export const emptyBlogCategory = () => dispatch => {
    dispatch({
        type: EMPTY_BLOG_CATEGORY
    })
}

export const addBlogCategory = (categoryText) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/BlogCategory/saveBlogCategory"
            const formData = new FormData();
            formData.append('Category_Text', categoryText);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SAVE_BLOG_CATEGORY,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_BLOG_CATEGORY,
                    payload: Error.message
                })
            })
        })
    }
}
