import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
/**Actions */
import {
    fetchAllDynamicSections,
    deleteDynamicSection
} from '../../Actions/DynamicSectionActions'


class DeleteDynamicSection extends Component {
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteDynamicSection(id) {
        this.props.deleteDynamicSection(id);
    }

    handleSubmit = (e) => {
        const { id } = this.props;
        this.deleteDynamicSection(id);
        this.close();
    }
    render() {
        const { id } = this.props;
        let message = 'Are you sure you want to delete this Dynamic Section ' + id + ' ??'
        return (
            <span >
                <Popup trigger=
                    {
                        <a class="trash" onClick={this.open}>
                            <i><FontAwesomeIcon icon="trash" /></i>
                        </a>
                    }
                    content='Delete'
                    position='top center'
                />

                <Confirm id="modal"
                    content={message}
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </span>
        )
    }
}



DeleteDynamicSection.propTypes = {
    fetchAllDynamicSections: propTypes.func.isRequired,
    deleteDynamicSection: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    loading: state.dynamicSection.loading,
    error: state.dynamicSection.delete_section_error,
    success: state.dynamicSection.delete_section_successfully,
});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                fetchAllDynamicSections,
                deleteDynamicSection
            })
        (DeleteDynamicSection)
    );

