import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import '../../../node_modules/video-react/dist/video-react.css';
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../css/Songs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import puzzleIcon from '../../images/Puzzle-Shape.png';
import dragAndDropIcon from '../../images/Drag-Shape.png';
import paintingIcon from '../../images/paintingShape.png';
/* Routes */
import {
    PUZZLE_DETAIL, DRAG_DROP_DETAIL, PAINTING_DETAIL,
    JUMPING_DETAIL,
    GAME_DATA_DETAIL
} from '../../routes';

class getAllGames extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container allGames">


                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-2 borderRight">
                                                                    <Link className="link" to={PUZZLE_DETAIL}>
                                                                        <img className="rounded" src={puzzleIcon} alt="" height="65" width="65" />
                                                                    </Link>
                                                                </div>
                                                                <div className="col-10">

                                                                    <div>
                                                                        <span className="card-title" color="gray">
                                                                            <Link className="link" to={PUZZLE_DETAIL}>Puzzle</Link>
                                                                        </span>
                                                                    </div>
                                                                    <span className="font-italic small-font">
                                                                        <p>Publish date: 1/11/2019 , 05:00 pm</p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-2 borderRight">
                                                                    <Link className="link" to={DRAG_DROP_DETAIL}>
                                                                        <img className="rounded" src={dragAndDropIcon} alt="" height="65" width="65" />
                                                                    </Link>
                                                                </div>
                                                                <div className="col-10">

                                                                    <div>
                                                                        <span className="card-title" color="gray">
                                                                            <Link className="link" to={DRAG_DROP_DETAIL}>Drag & Drop</Link>
                                                                        </span>
                                                                    </div>
                                                                    <span className="font-italic small-font">
                                                                        <p>Publish date: 1/11/2019 , 05:00 pm</p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-2 borderRight">
                                                                    <Link className="link" to={PAINTING_DETAIL}>
                                                                        <img className="rounded" src={paintingIcon} alt="" height="65" width="65" />
                                                                    </Link>
                                                                </div>
                                                                <div className="col-10">

                                                                    <div>
                                                                        <span className="card-title" color="gray">
                                                                            <Link className="link" to={PAINTING_DETAIL}>Painting</Link>
                                                                        </span>
                                                                    </div>
                                                                    <span className="font-italic small-font">
                                                                        <p>Publish date: 25/11/2019 , 05:00 pm</p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-2 borderRight">
                                                                    <Link className="link" to={JUMPING_DETAIL}>
                                                                        <img className="rounded" src={puzzleIcon} alt="" height="65" width="65" />
                                                                    </Link>
                                                                </div>
                                                                <div className="col-10">

                                                                    <div>
                                                                        <span className="card-title" color="gray">
                                                                            <Link className="link" to={JUMPING_DETAIL}>Jumping</Link>
                                                                        </span>
                                                                    </div>
                                                                    <span className="font-italic small-font">
                                                                        <p>Publish date: 1/11/2019 , 05:00 pm</p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col-2 borderRight">
                                                                    <Link className="link" to={GAME_DATA_DETAIL}>
                                                                        <img className="rounded" src={dragAndDropIcon} alt="" height="65" width="65" />
                                                                    </Link>
                                                                </div>
                                                                <div className="col-10">

                                                                    <div>
                                                                        <span className="card-title" color="gray">
                                                                            <Link className="link" to={GAME_DATA_DETAIL}>Game Data</Link>
                                                                        </span>
                                                                    </div>
                                                                    <span className="font-italic small-font">
                                                                        <p>Publish date: 1/11/2019 , 05:00 pm</p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )

    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth
});
export default withRouter(connect(mapStateToProps, {})(getAllGames));
