import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/*Redux*/
import firebase from 'firebase/app'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';

import logo from "../../images/logo.png";


const INITIAL_STATE = {
    redirection: false
}

class MobilePaymentSupport extends Component {

    constructor(props) {

        super(props);
        this.state = { ...INITIAL_STATE };

    }

    handleLink = (e) => {
        this.setState({ redirection: true })

    }

    render() {

        
        // back to previous page if browser back button clicked
        window.onhashchange = function() {
            this.props.history.goBack();
        }
        
          // redirect to login if not user
          const { userPaymentProfile } = this.props;

          //console.log(userPaymentProfile)

          if (userPaymentProfile.user_role !== undefined) {
              if (userPaymentProfile.user_role.role !== "USER") {

                  firebase.auth().signOut()
                  return (<Redirect to="/MobilePaymentLogin"></Redirect>)
              }
          }



        const { redirection } = this.state;
        if (redirection == true) {
            const { redirectionPage } = this.props;
            var pageName =  "/MobilePayment";

            return (<Redirect to={pageName} ></Redirect>)
        }

        return (
            <div className="main-payment">

                <div className="shape-container">
                    <div className="bg-img">
                    </div>
                </div>

                <div className="container">
                    <div className="pay-box">
                        <div className="main-logo text-center h4">
                            <img className="  logo" alt="logo" src={logo} />
                            <p className="font small-font main-color">بوابة الدفع لجيل</p>
                        </div>
                        <div className="card card-container Current-Package">
                            <div className="card-header profile-name-card text-right">
                                <span className="h4 font">الدعم الفني</span>

                                <Link>
                                    <a className="back-icon float-left" onClick={this.handleLink.bind(this)}>
                                        <i className="fas fa-chevron-left">
                                            <FontAwesomeIcon icon="chevron-left" />
                                        </i>
                                    </a>
                                </Link>

                            </div>
                            <div className="card-body">
                                <fieldset>
                                    <legend className="font">ما نوع المشكلة التى تواجهها</legend>
                                    <a href="#" className="font div-box text-center d-block m-2">
                                        مشكلة في التطبيق
                                        </a>
                                    <a href="#" className="font div-box text-center d-block m-2">
                                        مشكلة في الدفع
                                        </a>
                                    <a href="#" className="font div-box text-center d-block m-2">
                                        تعديل باقتك الحالية
                                    </a>
                                </fieldset>

                            </div>
                        </div>

                        <MobilePaymentFooter />
                    </div>
                </div>

            </div>
        )
    }
}


MobilePaymentSupport.propTypes = {

}
const mapStateToProps = state => ({
    redirectionPage: state.redirectionPage.item,
    userPaymentProfile: state.userPaymentProfile.item,


});

export default
    connect(
        mapStateToProps,
        {
        }
    )
        (MobilePaymentSupport);
