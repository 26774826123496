import {
    LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_LOADING, REGISTER_SUCCESS, REGISTER_ERROR, REGISTER_LOADING, SIGNOUT_SUCCESS, FIREBASE_ERROR,

    ADD_VENDOR_SUCCESSFULLY, ADD_VENDOR_ERROR, LOAD_VENDOR,
    ADD_CUSTOMER, ERROR_ADDING_CUSTOMER, LOAD_CUSTOMER, EMPTY
    ,
    // Fit Data
    FIT_USER_LOGIN_SUCCESSFULLY, FIT_USER_LOGIN_ERROR,
    LOADING_FIT_USER_LOGIN,

    FIT_USER_REGISTER_SUCCESSFULLY, FIT_USER_REGISTER_ERROR,
    LOADING_FIT_USER_REGISTER,

    // Tpay Data
    TPAY_USER_LOGIN_SUCCESSFULLY, TPAY_USER_LOGIN_ERROR,
    LOADING_TPAY_USER_LOGIN,

    TPAY_USER_REGISTER_SUCCESSFULLY, TPAY_USER_REGISTER_ERROR,
    LOADING_TPAY_USER_REGISTER ,

	CONTENT_VENDOR_LOAD_START,
	CONTENT_VENDOR_ADD_SUCCESSFULLY,
	CONTENT_VENDOR_ADD_ERROR,

} from './Types'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import axios, { post } from 'axios';

const config = {
    apiKey: "AIzaSyAQdp8uKo8vQstE-9wPb6NJ-Bc2tgbYVHk",
    authDomain: "mobidevlabsgen.firebaseapp.com",
    databaseURL: "https://mobidevlabsgen.firebaseio.com",
    projectId: "mobidevlabsgen",
    storageBucket: "mobidevlabsgen.appspot.com",
    messagingSenderId: "207655697394"
};

var secondaryApp = firebase.initializeApp(config, "Secondary");

// for Admin Only
export const signIn = (email, password) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOGIN_LOADING,
            payload: 'Loading'
        })

        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            email,
            password
        ).then((res) => {
            firebase.auth().currentUser.getIdToken().then((token) => {
                const url = "/User/login";
                const config = {
                    headers: {
                        'X-Authorization-Firebase': token
                    }
                }
                axios.get(url, config).then((res) => {
                    console.log(res)

                    if (res.data !== '' && res.data !== ' ') {
                        dispatch({
                            type: LOGIN_SUCCESS,
                            payload: res.data
                        })
                    }
                    else {
                        dispatch({
                            type: LOGIN_ERROR,
                            payload: "Either email or password is not correct"
                        })
                    }
                }).catch((Error) => {
                    if (Error.response.data.status == 400) {
                        dispatch({
                            type: LOGIN_ERROR,
                            payload: "User has been blocked"
                        })
                    } else {
                        dispatch({
                            type: LOGIN_ERROR,
                            payload: Error.response.data.message
                        })
                    }
                })

            })

        }).catch((Error) => {
            dispatch({
                type: FIREBASE_ERROR,
                payload: "Either email or password is not correct"
            })
        })
    }
}

// Registration For Admin Only
export const register = (email, password, userName, selected_userRole_name) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            let adminToken = token;
            dispatch({
                type: LOAD_VENDOR,
                payload: 'loading'
            })

            dispatch({
                type: REGISTER_LOADING,
                payload: 'Loading'
            })

            secondaryApp.auth().createUserWithEmailAndPassword(email, password).then(authUser => {

                console.log(authUser.user);
                const url = '/User/registration';
                const formData = new FormData();

                formData.append('Name', userName);
                formData.append('Password', '');
                formData.append('Country', '');
                formData.append('Phone', '');
                formData.append('Role', selected_userRole_name);
                formData.append('Gender', '');
                formData.append('Date_Of_Birth', '');
                const config = {
                    headers: {
                        'Authorization-Firebase': authUser.user.h.b,
                        'X-Authorization-Firebase': adminToken
                    }
                }
                secondaryApp.auth().signOut();
                return post(url, formData, config);


                // console.log(firebaseUser.uid);
                // console.log("User " + firebaseUser.uid + " created successfully!");
                //I don't know if the next statement is necessary
            }).then(() => {
                dispatch({
                    type: REGISTER_SUCCESS
                })
            }).catch((error) => {
                dispatch({
                    type: REGISTER_ERROR,
                    payload: error.message
                })
            })
        })
    }
}

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().signOut().then(() => {
            dispatch({
                type: SIGNOUT_SUCCESS

            })
        })
    }
}

// Registration For vendors Only
export const saveVendor = (email, password, userName, phone, country, note, contractType, city, influencerInfo, addess) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            let adminToken = token;
            dispatch({
                type: LOAD_VENDOR,
                payload: 'loading'
            })

            secondaryApp.auth().createUserWithEmailAndPassword(email, password).then(authUser => {


                const url = '/Vendor/addVendor';
                const formData = new FormData();

                formData.append('Name', userName);
                formData.append('Email', email);
                formData.append('Password', password);
                formData.append('Country', country);
                formData.append('Phone', phone);
                formData.append('Note', note);
                formData.append('Contract_Type', contractType);
                formData.append('City', city);
                formData.append('Influencer_Info', influencerInfo);
                formData.append('Address', addess);


                const config = {
                    headers: {
                        'Authorization-Firebase': authUser.user.h.b,
                        'X-Authorization-Firebase': adminToken

                    }
                }
                secondaryApp.auth().signOut();
                return post(url, formData, config);



            }).then(() => {
                dispatch({
                    type: ADD_VENDOR_SUCCESSFULLY

                })
            }).catch((error) => {
                dispatch({
                    type: ADD_VENDOR_ERROR,
                    payload: error.message
                })
            })
        })
    }
}

/* For customer registration from web-portal */
export const customerRegister = (obj) => {

    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_CUSTOMER,
            payload: 'Loading'
        })

        secondaryApp.auth().createUserWithEmailAndPassword(obj.email, obj.password).then(authUser => {

            const url = '/User/userRegister';
            const formData = new FormData();
            formData.append('Email', (obj.email).trim());
            formData.append('Password', (obj.password).trim());
            formData.append('Code_Name', (obj.code).trim());
            formData.append('Country', (obj.country).trim());
            formData.append('Phone', (obj.phoneNumber).trim());
                        

            const config = {
                headers: {
                    'Authorization-Firebase': authUser.user.h.b,
                }
            }
            secondaryApp.auth().signOut();
            post(url, formData, config).then((res) => {
                console.log(res)
                dispatch({
                    type: ADD_CUSTOMER,
                    payload: res.data
                })

            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_CUSTOMER,
                    payload: Error.response.data.message

                })
            })
        }).catch((error) => {
            dispatch({
                type: ERROR_ADDING_CUSTOMER,
                payload: error
            })
        })
    }
}


export const empty = () => dispatch => {
    dispatch({
        type: EMPTY,
        payload: ''
    })
}

export const customerSignIn = (obj) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOGIN_LOADING,
            payload: 'Loading'
        })

        const firebase = getFirebase();
        firebase.auth().signInWithEmailAndPassword(
            obj.email,
            obj.password
        ).then((res) => {
            firebase.auth().currentUser.getIdToken().then((token) => {
                const url = "/User/userLogin";
                const formData = new FormData();
                formData.append('Code_Name', obj.code);

                const config = {
                    headers: {
                        'X-Authorization-Firebase': token
                    }
                }
                post(url, formData, config).then((res) => {

                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data
                    })


                }).catch((Error) => {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: Error.response.data.message

                    })
                })

            })

        }).catch((Error) => {
            dispatch({
                type: FIREBASE_ERROR,
                payload: Error
            })
        })
    }
}
// for Users via portal
export const signInWithPhone = (phone) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOGIN_LOADING,
            payload: 'Loading'
        })

        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/User/userLogin";
            const formData = new FormData();
            formData.append('Code_Name', '');

            const config = {
                headers: {
                    'Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                console.log(res)
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const customerSignInWithGoogle = () => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOGIN_LOADING,
            payload: 'Loading'
        })
        var provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth().signInWithPopup(provider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            //var token = result.user.token;
            // The signed-in user info.
            firebase.auth().currentUser.getIdToken().then((token) => {
                const url = "/User/userLogin";
                const formData = new FormData();
                formData.append('Code_Name', '');

                const config = {
                    headers: {
                        'Authorization-Firebase': token
                    }
                }
                post(url, formData, config).then((res) => {
                    console.log(res)
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data
                    })

                }).catch((Error) => {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: Error.response.data.message

                    })
                })
            })
            // ...
        }).catch((Error) => {
            // Handle Errors here.
            dispatch({
                type: LOGIN_ERROR,
                payload: Error.message

            })
        });
    }
}

export const customerSignInWithTwitter = () => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOGIN_LOADING,
            payload: 'Loading'
        })
        var provider = new firebase.auth.TwitterAuthProvider();

        firebase.auth().signInWithPopup(provider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            //var token = result.user.token;
            // The signed-in user info.
            firebase.auth().currentUser.getIdToken().then((token) => {
                const url = "/User/userLogin";
                const formData = new FormData();
                formData.append('Code_Name', '');

                const config = {
                    headers: {
                        'Authorization-Firebase': token
                    }
                }
                post(url, formData, config).then((res) => {
                    console.log(res)
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data
                    })

                }).catch((Error) => {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: Error.response.data.message

                    })
                })
            })
            // ...
        }).catch((Error) => {
            // Handle Errors here.
            dispatch({
                type: LOGIN_ERROR,
                payload: Error.message

            })
        });
    }
}

export const customerSignInWithFacebook = () => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOGIN_LOADING,
            payload: 'Loading'
        })
        var provider = new firebase.auth.FacebookAuthProvider();

        firebase.auth().signInWithPopup(provider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            //var token = result.user.token;
            // The signed-in user info.
            firebase.auth().currentUser.getIdToken().then((token) => {
                const url = "/User/userLogin";
                const formData = new FormData();
                formData.append('Code_Name', '');

                const config = {
                    headers: {
                        'Authorization-Firebase': token
                    }
                }
                post(url, formData, config).then((res) => {
                    console.log(res)
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data
                    })

                }).catch((Error) => {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: Error.response.data.message

                    })
                })
            })
            // ...
        }).catch((Error) => {
            // Handle Errors here.
            dispatch({
                type: LOGIN_ERROR,
                payload: Error.message

            })
        });
    }
}

export const changeCustomerSignInMethod = (email, password) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOGIN_LOADING,
            payload: 'Loading'
        })

        var credential = firebase.auth.EmailAuthProvider.credential(email, password);

        firebase.auth().currentUser.linkWithCredential(credential)
            .then((usercred) => {
                var user = usercred.user;
                firebase.auth().currentUser.getIdToken().then((token) => {
                    const url = "/User/updateUserLogin";
                    const formData = new FormData();
                    formData.append('Email', email);
                    formData.append('Password', password);
                    const config = {
                        headers: {
                            'X-Authorization-Firebase': token
                        }
                    }
                    post(url, formData, config).then((res) => {
                        console.log(res)
                        dispatch({
                            type: LOGIN_SUCCESS,
                            payload: res.data
                        })

                    }).catch((Error) => {
                        dispatch({
                            type: LOGIN_ERROR,
                            payload: Error.response.data.message

                        })
                    })
                })
            }).catch((error) => {
                dispatch({
                    type: LOGIN_ERROR,
                    payload: "لقد تم تحديث طريقة التسجيل من قبل"
                })
            });
    }
}


//////////////////////////////// FIT ////////////////////
export const fitLogin = (email, password, phone, fitId, type) => {
    return (dispatch, getState, { getFirebase }) => {


        dispatch({
            type: LOADING_FIT_USER_LOGIN,
            payload: 'loading'
        })

        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            email,
            password
        ).then((res) => {
            firebase.auth().currentUser.getIdToken().then((token) => {

                const url = "/Fit/FitUserLogin";

                const formData = new FormData();
                formData.append('Phone', phone);
                formData.append('Fit_Id', fitId);
                formData.append('Type', type);
                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                        'X-Authorization-Firebase': token
                    }
                }
                post(url, formData, config).then((res) => {
                    dispatch(
                        {
                            type: FIT_USER_LOGIN_SUCCESSFULLY,
                            payload: res.data
                        }
                    )
                }).catch((Error) => {
                    dispatch({
                        type: FIT_USER_LOGIN_ERROR,
                        payload: Error.response.data.message
                    })
                })
            })

        }).catch((error) => {
            let errorMsg = error
            if (error.code === 'auth/email-already-in-use') {
                errorMsg = 'البريد الإلكتروني المُدخل مسجل مسبقاً'
            }
            else if (error.code === 'auth/network-request-failed') {
                errorMsg = 'فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً'
            } else if (error.code === 'auth/user-not-found') {
                errorMsg = 'البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال'
            } else if (error.code === 'auth/wrong-password') {
                errorMsg = 'كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور'
            } else if (error.code === undefined) {
                errorMsg = error
            }
            dispatch({
                type: FIREBASE_ERROR,
                payload: errorMsg
            })
        })
    }
}


export const fitRegister = (email, password, phone, fitId, type, country) => {
    return (dispatch, getState, { getFirebase }) => {


        dispatch({
            type: LOADING_FIT_USER_REGISTER,
            payload: 'loading'
        })

        const firebase = getFirebase();

        firebase.auth().createUserWithEmailAndPassword(
            email,
            password
        ).then(authUser => {
            firebase.auth().currentUser.getIdToken().then((token) => {


                const url = "/Fit/FitUserRegister";

                const formData = new FormData();
                formData.append('Phone', phone);
                formData.append('Email', email);
                formData.append('Fit_Id', fitId);
                formData.append('Type', type);
                formData.append('Country', country);

                const config = {
                    headers: {
                        'Authorization-Firebase': token,
                    }
                }
                secondaryApp.auth().signOut();
                return post(url, formData, config);

            }).then((res) => {
                dispatch(
                    {
                        type: FIT_USER_REGISTER_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {

                // in case of registration error , delete user from firebase
                firebase.auth().currentUser.delete().then(function () {
                    // User deleted.
                }).catch(function (error) {
                    // An error happened.
                });

                dispatch({
                    type: FIT_USER_REGISTER_ERROR,
                    payload: Error.response.data.message
                })

            })
        }).catch((error) => {
            let errorMsg = error
            if (error.code === 'auth/email-already-in-use') {
                errorMsg = 'البريد الإلكتروني المُدخل مسجل مسبقاً'
            }
            else if (error.code === 'auth/network-request-failed') {
                errorMsg = 'فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً'
            } else if (error.code === 'auth/user-not-found') {
                errorMsg = 'البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال'
            } else if (error.code === 'auth/wrong-password') {
                errorMsg = 'كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور'
            } else if (error.code === undefined) {
                errorMsg = error
            }

            dispatch({
                type: FIREBASE_ERROR,
                payload: errorMsg
            })
        })
    }
}

//////////////////////////////// Tpay ////////////////////
export const tpayLogin = (email, password, phone, type, country, sessionToken,dailyWeeklyListSelectedValue) => {
    return (dispatch, getState, { getFirebase }) => {


        dispatch({
            type: LOADING_TPAY_USER_LOGIN,
            payload: 'loading'
        })

        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            email,
            password
        ).then((res) => {
            firebase.auth().currentUser.getIdToken().then((token) => {

                const url = "/Telecom/userLogin";

                const formData = new FormData();
                formData.append('MSISDN', phone);
                formData.append('TelecomCopmany', type);
                formData.append('Country', country);
                formData.append('SessionToken', sessionToken);
                formData.append('dailyWeeklyListSelectedValue', dailyWeeklyListSelectedValue);
                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                        'X-Authorization-Firebase': token
                    }
                }
                post(url, formData, config).then((res) => {
                    dispatch(
                        {
                            type: TPAY_USER_LOGIN_SUCCESSFULLY,
                            payload: res.data
                        }
                    )
                }).catch((Error) => {
                    let errorMsg = '';
                    if (Error.response.status === 504) {
                        errorMsg = 'تعذر الاتصال بمقدم الخدمة. برجاء المحاولة لاحقا';
                    }
                    else if (Error.response.status === 301) {
                        errorMsg = '301';
                    } else {
                        errorMsg = Error.response.data.message
                    }
                    dispatch({
                        type: TPAY_USER_LOGIN_ERROR,
                        payload: errorMsg
                    })
                })
            })

        }).catch((error) => {
            let errorMsg = error
            if (error.code === 'auth/email-already-in-use') {
                errorMsg = 'البريد الإلكتروني المُدخل مسجل مسبقاً'
            }
            else if (error.code === 'auth/network-request-failed') {
                errorMsg = 'فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً'
            } else if (error.code === 'auth/user-not-found') {
                errorMsg = 'البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال'
            } else if (error.code === 'auth/wrong-password') {
                errorMsg = 'كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور'
            } else if (error.code === 'auth/invalid-email') {                 
                errorMsg = ' الايميل المُدخل غير صحيح، برجاء التحقق من الايميل'
            } else if (error.code === undefined) {
                errorMsg = error
            }
            dispatch({
                type: FIREBASE_ERROR,
                payload: errorMsg
            })
        })
    }
}

export const tpayRegister = (email, password, phone, type, country, sessionToken,dailyWeeklyListSelectedValue) => {
	
    return (dispatch, getState, { getFirebase }) => {


        dispatch({
            type: LOADING_TPAY_USER_REGISTER,
            payload: 'loading'
        })
//console.log('passwordd: '+password);
if(password!=='' && password.length<8)
{
	dispatch({
                type: FIREBASE_ERROR,
                payload: ' كلمة المرور يجب الا تقل عن 8 احرف'
            }) ;
            
    return ;        
            
}


        const firebase = getFirebase();

        firebase.auth().createUserWithEmailAndPassword(
            email,
            password
        ).then(authUser => {
            firebase.auth().currentUser.getIdToken().then((token) => {


                const url = "/Telecom/userRegister";

                const formData = new FormData();
                formData.append('MSISDN', phone);
                formData.append('Email', email);
                formData.append('Country', country);
                formData.append('TelecomCopmany', type);
                formData.append('SessionToken', sessionToken);
                formData.append('dailyWeeklyListSelectedValue', dailyWeeklyListSelectedValue);

                const config = {
                    headers: {
                        'Authorization-Firebase': token,
                    }
                }
                
                
                secondaryApp.auth().signOut();
                return post(url, formData, config);

            }).then((res) => {
//alert(" before return success :"+JSON.stringify(res.data,null,3));
                dispatch(
                    {
                        type: TPAY_USER_REGISTER_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                let errorMsg = '';
                if (Error.response.status === 504) {
                    errorMsg = 'تعذر الاتصال بمقدم الخدمة. برجاء المحاولة لاحقا';
                } else {
                    errorMsg = Error.response.data.message
                }
                // in case of registration error , delete user from firebase
                firebase.auth().currentUser.delete().then(function () {
                    // User deleted.
                }).catch(function (error) {
                    // An error happened.
                    //console.log('Error: '+error);
                });

                dispatch({
                    type: TPAY_USER_REGISTER_ERROR,
                    payload: errorMsg
                })

            })
        }).catch((error) => {
	//console.log('Error20: '+JSON.stringify(error,null,3));
            let errorMsg = error
            if (error.code === 'auth/email-already-in-use') {
                errorMsg = 'البريد الإلكتروني المُدخل مسجل مسبقاً'
            }
            else if (error.code === 'auth/network-request-failed') {
                errorMsg = 'فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً'
            } else if (error.code === 'auth/user-not-found') {
                errorMsg = 'البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال'
            } else if (error.code === 'auth/wrong-password') {
                errorMsg = 'كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور'
            } else if (error.code === 'auth/invalid-email') { 
                errorMsg = ' الايميل المُدخل غير صحيح، برجاء التحقق من الايميل'
            } else if(error.code ==='auth/weak-password'){
				errorMsg = ' كلمة المرور يجب الا تقل عن 8 احرف'
            } else if (error.code === undefined) {
                errorMsg = error
            }

            dispatch({
                type: FIREBASE_ERROR,
                payload: errorMsg
            })
        })
    }
}


// ============================================================================================================================================================================
// ============================================================================================================================================================================
// ============================================================================================================================================================================

export const mondiaRegister = (subscriptionId,email, password, phone,country,sessionToken) => {
	
	
    return (dispatch, getState, { getFirebase }) => {

//console.log('start hand 1');

        dispatch({
            type: LOADING_TPAY_USER_REGISTER,
            payload: 'loading'
        })


if(password!=='' && password.length<8)
{
	dispatch({
                type: FIREBASE_ERROR,
                payload: ' كلمة المرور يجب الا تقل عن 8 احرف'
            }) ;
            
    return ;        
            
}

//console.log('start hand 2');

        const firebase = getFirebase();

        firebase.auth().createUserWithEmailAndPassword(
            email,
            password
        ).then(authUser => {
            firebase.auth().currentUser.getIdToken().then((token) => {

//console.log('start hand 3');

                const url = "/mondia/register";

                const formData = new FormData();
                formData.append('MSISDN', phone);
                formData.append('Email', email);
                formData.append('Country', country);           
                formData.append('subscriptionId', subscriptionId);                     
                formData.append('SessionToken', sessionToken);

                const config = {
                    headers: {
                        'Authorization-Firebase': token,
                    }
                }
                
                
                secondaryApp.auth().signOut();
                
//console.log('start hand 4: '+url);                
                
                return post(url, formData, config);

            }).then((res) => {
				
				res = {
					data:{
						phone:'87666778',						
					}
				};
				console.log(" before return success :"+JSON.stringify(res.data,null,3));
				
				
                dispatch(
                    {
                        type: TPAY_USER_REGISTER_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                let errorMsg = '';
//console.log('start hand error 1');                
                if (Error.response.status === 504) {
                    errorMsg = 'تعذر الاتصال بمقدم الخدمة. برجاء المحاولة لاحقا';
                } else {
                    errorMsg = Error.response.data.message
                }
                // in case of registration error , delete user from firebase
                firebase.auth().currentUser.delete().then(function () {
                    // User deleted.
                }).catch(function (error) {
                    // An error happened.
                    //console.log('Error: '+error);
                });

                dispatch({
                    type: TPAY_USER_REGISTER_ERROR,
                    payload: errorMsg
                })

            })
        }).catch((error) => {
	//console.log('Error20: '+JSON.stringify(error,null,3));
            let errorMsg = error
            if (error.code === 'auth/email-already-in-use') {
                errorMsg = 'البريد الإلكتروني المُدخل مسجل مسبقاً'
            }
            else if (error.code === 'auth/network-request-failed') {
                errorMsg = 'فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً'
            } else if (error.code === 'auth/user-not-found') {
                errorMsg = 'البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال'
            } else if (error.code === 'auth/wrong-password') {
                errorMsg = 'كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور'
            } else if (error.code === 'auth/invalid-email') { 
                errorMsg = ' الايميل المُدخل غير صحيح، برجاء التحقق من الايميل'
            } else if(error.code ==='auth/weak-password'){
				errorMsg = ' كلمة المرور يجب الا تقل عن 8 احرف'
            } else if (error.code === undefined) {
                errorMsg = error
            }

            dispatch({
                type: FIREBASE_ERROR,
                payload: errorMsg
            })
        })
    }
}

export const mondiaLogin = (email, password, phone, type, country, sessionToken) => {
	
//	alert("2");
	
    return (dispatch, getState, { getFirebase }) => {


        dispatch({
            type: LOADING_TPAY_USER_LOGIN,
            payload: 'loading'
        })

        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            email,
            password
        ).then((res) => {
	
//	alert("After firebase for Login:"+JSON.stringify(res,null,2));
	
            firebase.auth().currentUser.getIdToken().then((token) => {

//alert("Token: "+token);

                const url = "/mondia/login";

                const formData = new FormData();
                formData.append('MSISDN', phone);
                formData.append('Email', email);
                formData.append('Country', country);
                formData.append('SessionToken', sessionToken);
                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                        'X-Authorization-Firebase': token,
                        'Authorization-Firebase': token
                    }
                }
                post(url, formData, config).then((res) => {
	
	//alert("res: "+res);
                    dispatch(
                        {
                            type: TPAY_USER_LOGIN_SUCCESSFULLY,
                            payload: res.data
                        }
                    )
                }).catch((Error) => {
                    let errorMsg = '';
                    if (Error.response.status === 504) {
                        errorMsg = 'تعذر الاتصال بمقدم الخدمة. برجاء المحاولة لاحقا';
                    }
                    else if (Error.response.status === 301) {
                        errorMsg = '301';
                    } else {
                        errorMsg = Error.response.data.message
                    }
                    dispatch({
                        type: TPAY_USER_LOGIN_ERROR,
                        payload: errorMsg
                    })
                })
            })

        }).catch((error) => {
            let errorMsg = error
            if (error.code === 'auth/email-already-in-use') {
                errorMsg = 'البريد الإلكتروني المُدخل مسجل مسبقاً'
            }
            else if (error.code === 'auth/network-request-failed') {
                errorMsg = 'فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً'
            } else if (error.code === 'auth/user-not-found') {
                errorMsg = 'البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال'
            } else if (error.code === 'auth/wrong-password') {
                errorMsg = 'كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور'
            } else if (error.code === 'auth/invalid-email') {                 
                errorMsg = ' الايميل المُدخل غير صحيح، برجاء التحقق من الايميل'
            } else if (error.code === undefined) {
                errorMsg = error
            }
            dispatch({
                type: FIREBASE_ERROR,
                payload: errorMsg
            })
        })
    }
}


export const saveContentVendor = (email, password, userName, phone, country, note, city, addess) => {
	
	console.log("save 2");
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            let adminToken = token;
            
            console.log("save 2 : "+adminToken);
            
            dispatch({
                type: CONTENT_VENDOR_LOAD_START,
                payload: 'loading'
            })

            secondaryApp.auth().createUserWithEmailAndPassword(email, password).then(authUser => {


                const url = '/ContentVendor/addContentVendor'; 
                const formData = new FormData();

                formData.append('Name', userName);
                formData.append('Email', email);
                formData.append('Password', password);
                formData.append('Country', country);
                formData.append('Phone', phone);
                formData.append('Note', note);
                formData.append('City', city);
                formData.append('Address', addess);


                const config = {
                    headers: {
                        'Authorization-Firebase': authUser.user.h.b,
                        'X-Authorization-Firebase': adminToken

                    }
                }
                secondaryApp.auth().signOut();
                return post(url, formData, config);



            }).then(() => {
                dispatch({
                    type: CONTENT_VENDOR_ADD_SUCCESSFULLY

                })
            }).catch((error) => {
                dispatch({
                    type: CONTENT_VENDOR_ADD_ERROR,
                    payload: error.message
                })
            })
        })
    }
}

