import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

/* Components */
import AddInAppNotification from './AddInAppNotification';
import EditInAppNotification from './EditInAppNotification';
import InAppNotification from './InAppNotification';

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import {SIGN_IN, ADD_IN_APP_NOTIFICATION,
    EDIT_IN_APP_NOTIFICATION, IN_APP_NOTIFICATION
} from '../../routes';

class InAppNotificationAuth extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { userPaymentProfile } = this.props;
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    const { userPaymentProfile } = this.props;

    return (
      <span>
          {/* adding InAppNotification Routers */}
          <Route path={ADD_IN_APP_NOTIFICATION} component={AddInAppNotification} />
          <Route path={EDIT_IN_APP_NOTIFICATION} component={EditInAppNotification} />
          <Route path={IN_APP_NOTIFICATION} component={InAppNotification} />
         
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(InAppNotificationAuth)
);
