import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withFirebase } from '../../config';
import placeHolder from '../../images/placeholder.png';
/*Redux*/
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import {
    getDragAndDrop,
    deleteDraggableElement,
    updateDragAndDrop,
    updateOneDraggableElement,
    addDraggableElement
} from '../../Actions/GameActions';

import {
    getDragAndDropEpisode,
    deleteDraggableElementEpisode,
    updateDragAndDropEpisode,
    updateOneDraggableElementEpisode,
    addDraggableElementEpisode
} from '../../Actions/EpisodeActions';

import {
    getDragAndDropSong,
    deleteDraggableElementSong,
    updateDragAndDropSong,
    updateOneDraggableElementSong,
    addDraggableElementSong
} from '../../Actions/SongActions';

import {
    getDragAndDropStory,
    deleteDraggableElementStory,
    updateDragAndDropStory,
    updateOneDraggableElementStory,
    addDraggableElementStory
} from '../../Actions/StoryActions';

import {
    getDragAndDropChannel,
    deleteDraggableElementChannel,
    updateDragAndDropChannel,
    updateOneDraggableElementChannel,
    addDraggableElementChannel
} from '../../Actions/RadioChannelAction';

import {
    getDragAndDropBook,
    deleteDraggableElementBook,
    updateDragAndDropBook,
    updateOneDraggableElementBook,
    addDraggableElementBook
} from '../../Actions/ReadWithJeelAction';

import {
    getDragAndDropPdfBook,
    deleteDraggableElementPdfBook,
    updateDragAndDropPdfBook,
    updateOneDraggableElementPdfBook,
    addDraggableElementPdfBook
} from '../../Actions/PdfBookAction';
/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import UploadImage from '../S3Uploading/UploadImage';
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone';

import '../../css/AppCss/gamePreview.css';


/* Routes */
import {
    SIGN_IN, ADMIN_LAND_PAGE, DRAG_DROP_DETAIL
} from '../../routes';

const FIX_FACTOR = (1024.0/1920);
class editDragDrop extends Component {

    


    constructor(props) {
        super(props);
        const { redirectionPage } = this.props;

        this.state = {
            game_Image: "",
            croppedImageUrl: "",
            dragDropWidth: "",
            dragDropHeight: "",
            listElements: [],
            dragDropID: "",

            newImage: "",
            newDraggableWidth: "",
            newDraggableHeight: "",
            newXCordinate: "",
            newYCordinate: ""
        }

        let DDID = parseInt(this.props.match.params.id);
        if (!DDID) this.props.history.push(redirectionPage.dragDropPageName);

        this.getDragAndDropByID(DDID);

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }

    }

    getDragAndDropByID = (DDID) => {

        const { redirectionPage } = this.props;

        if (redirectionPage.dragDropPageName == DRAG_DROP_DETAIL) {
            getDragAndDrop(DDID)
                .then(res => {
                    this.setState({
                        dragDropID: res.data.dragDropId,
                        dragDropWidth: res.data.dragDropWidth,
                        dragDropHeight: res.data.dragDropHeight,
                        game_Image: res.data.url,
                        croppedImageUrl: res.data.croppedImageUrl
                    });

                    let tempArr = [];
                    for (let index = 0; index < res.data.draggableElements.length; index++) {
                        const temp = res.data.draggableElements[index];
                        var element = {
                            imageUrl: temp.url.imageUrl,
                            dropElemId: temp.dropElemId,
                            draggableWidth: Number((temp.draggableWidth * FIX_FACTOR).toFixed(0)),
                            dragDropHeight: Number((temp.draggableHeight* FIX_FACTOR).toFixed(0)),
                            xCoordinate: Number((temp.xCoordinate * FIX_FACTOR).toFixed(0)),
                            yCoordinate: Number((temp.yCoordinate * FIX_FACTOR).toFixed(0))
                        };
                        tempArr.push(element);
                        this.setState({ listElements: tempArr });
                    }
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err.response);
                    alert(err.response.data.message);
                });
        }

        let arrayOfurl = redirectionPage.dragDropPageName.split('/');

        if (arrayOfurl.includes("ViewEpisode")) {
            getDragAndDropEpisode(DDID)
                .then(res => {
                    this.setState({
                        dragDropID: res.data.dragDropId,
                        dragDropWidth: res.data.dragDropWidth,
                        dragDropHeight: res.data.dragDropHeight,
                        game_Image: res.data.url,
                        croppedImageUrl: res.data.croppedImageUrl
                    });

                    let tempArr = [];
                    for (let index = 0; index < res.data.draggableElements.length; index++) {
                        const temp = res.data.draggableElements[index];
                        var element = {
                            imageUrl: temp.url.imageUrl,
                            dropElemId: temp.dropElemId,
                            draggableWidth: Number((temp.draggableWidth * FIX_FACTOR).toFixed(0)),
                            dragDropHeight: Number((temp.draggableHeight* FIX_FACTOR).toFixed(0)),
                            xCoordinate: Number((temp.xCoordinate * FIX_FACTOR).toFixed(0)),
                            yCoordinate: Number((temp.yCoordinate * FIX_FACTOR).toFixed(0))
                        };
                        tempArr.push(element);
                        this.setState({ listElements: tempArr });
                    }
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err.response);
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("songDetails")) {
            getDragAndDropSong(DDID)
                .then(res => {
                    this.setState({
                        dragDropID: res.data.dragDropId,
                        dragDropWidth: res.data.dragDropWidth,
                        dragDropHeight: res.data.dragDropHeight,
                        game_Image: res.data.url,
                        croppedImageUrl: res.data.croppedImageUrl
                    });

                    let tempArr = [];
                    for (let index = 0; index < res.data.draggableElements.length; index++) {
                        const temp = res.data.draggableElements[index];
                        var element = {
                            imageUrl: temp.url.imageUrl,
                            dropElemId: temp.dropElemId,
                            draggableWidth: Number((temp.draggableWidth * FIX_FACTOR).toFixed(0)),
                            dragDropHeight: Number((temp.draggableHeight* FIX_FACTOR).toFixed(0)),
                            xCoordinate: Number((temp.xCoordinate * FIX_FACTOR).toFixed(0)),
                            yCoordinate: Number((temp.yCoordinate * FIX_FACTOR).toFixed(0))
                        };
                        tempArr.push(element);
                        this.setState({ listElements: tempArr });
                    }
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err.response);
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("storyDetails")) {
            getDragAndDropStory(DDID)
                .then(res => {
                    this.setState({
                        dragDropID: res.data.dragDropId,
                        dragDropWidth: res.data.dragDropWidth,
                        dragDropHeight: res.data.dragDropHeight,
                        game_Image: res.data.url,
                        croppedImageUrl: res.data.croppedImageUrl
                    });

                    let tempArr = [];
                    for (let index = 0; index < res.data.draggableElements.length; index++) {
                        const temp = res.data.draggableElements[index];
                        var element = {
                            imageUrl: temp.url.imageUrl,
                            dropElemId: temp.dropElemId,
                            draggableWidth: Number((temp.draggableWidth * FIX_FACTOR).toFixed(0)),
                            dragDropHeight: Number((temp.draggableHeight* FIX_FACTOR).toFixed(0)),
                            xCoordinate: Number((temp.xCoordinate * FIX_FACTOR).toFixed(0)),
                            yCoordinate: Number((temp.yCoordinate * FIX_FACTOR).toFixed(0))
                        };
                        tempArr.push(element);
                        this.setState({ listElements: tempArr });
                    }
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err.response);
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("RadioChannelDetails")) {
            getDragAndDropChannel(DDID)
                .then(res => {
                    this.setState({
                        dragDropID: res.data.dragDropId,
                        dragDropWidth: res.data.dragDropWidth,
                        dragDropHeight: res.data.dragDropHeight,
                        game_Image: res.data.url,
                        croppedImageUrl: res.data.croppedImageUrl
                    });

                    let tempArr = [];
                    for (let index = 0; index < res.data.draggableElements.length; index++) {
                        const temp = res.data.draggableElements[index];
                        var element = {
                            imageUrl: temp.url.imageUrl,
                            dropElemId: temp.dropElemId,
                            draggableWidth: Number((temp.draggableWidth * FIX_FACTOR).toFixed(0)),
                            dragDropHeight: Number((temp.draggableHeight* FIX_FACTOR).toFixed(0)),
                            xCoordinate: Number((temp.xCoordinate * FIX_FACTOR).toFixed(0)),
                            yCoordinate: Number((temp.yCoordinate * FIX_FACTOR).toFixed(0))
                        };
                        tempArr.push(element);
                        this.setState({ listElements: tempArr });
                    }
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err.response);
                    alert(err.response.data.message);
                });
        }

        // Book
        if (arrayOfurl.includes("BookDetails")) {
            getDragAndDropBook(DDID)
                .then(res => {
                    this.setState({
                        dragDropID: res.data.dragDropId,
                        dragDropWidth: res.data.dragDropWidth,
                        dragDropHeight: res.data.dragDropHeight,
                        game_Image: res.data.url,
                        croppedImageUrl: res.data.croppedImageUrl
                    });

                    let tempArr = [];
                    for (let index = 0; index < res.data.draggableElements.length; index++) {
                        const temp = res.data.draggableElements[index];
                        var element = {
                            imageUrl: temp.url.imageUrl,
                            dropElemId: temp.dropElemId,
                            draggableWidth: Number((temp.draggableWidth * FIX_FACTOR).toFixed(0)),
                            dragDropHeight: Number((temp.draggableHeight* FIX_FACTOR).toFixed(0)),
                            xCoordinate: Number((temp.xCoordinate * FIX_FACTOR).toFixed(0)),
                            yCoordinate: Number((temp.yCoordinate * FIX_FACTOR).toFixed(0))
                        };
                        tempArr.push(element);
                        this.setState({ listElements: tempArr });
                    }
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err.response);
                    alert(err.response.data.message);
                });
        }

        // PDF Book
        if (arrayOfurl.includes("PdfBookDetails")) {
            getDragAndDropPdfBook(DDID)
                .then(res => {
                    this.setState({
                        dragDropID: res.data.dragDropId,
                        dragDropWidth: res.data.dragDropWidth,
                        dragDropHeight: res.data.dragDropHeight,
                        game_Image: res.data.url,
                        croppedImageUrl: res.data.croppedImageUrl
                    });

                    let tempArr = [];
                    for (let index = 0; index < res.data.draggableElements.length; index++) {
                        const temp = res.data.draggableElements[index];
                        var element = {
                            imageUrl: temp.url.imageUrl,
                            dropElemId: temp.dropElemId,
                            draggableWidth: Number((temp.draggableWidth * FIX_FACTOR).toFixed(0)),
                            dragDropHeight: Number((temp.draggableHeight* FIX_FACTOR).toFixed(0)),
                            xCoordinate: Number((temp.xCoordinate * FIX_FACTOR).toFixed(0)),
                            yCoordinate: Number((temp.yCoordinate * FIX_FACTOR).toFixed(0))
                        };
                        tempArr.push(element);
                        this.setState({ listElements: tempArr });
                    }
                    console.log(res.data);
                })
                .catch(err => {
                    console.log(err.response);
                    alert(err.response.data.message);
                });
        }
    }


    emptyModal() {
        this.setState({
            newDraggableWidth: "",
            newDraggableHeight: "",
            newXCordinate: "",
            newYCordinate: ""
        })
    }

    deleteElement(index) {
        const { redirectionPage } = this.props;

        if (redirectionPage.dragDropPageName == DRAG_DROP_DETAIL) {

            deleteDraggableElement(index)
                .then(res => {
                    alert("Element Deleted Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        let arrayOfurl = redirectionPage.dragDropPageName.split('/');

        if (arrayOfurl.includes("ViewEpisode")) {
            deleteDraggableElementEpisode(index)
                .then(res => {
                    alert("Element Deleted Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("songDetails")) {
            deleteDraggableElementSong(index)
                .then(res => {
                    alert("Element Deleted Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("storyDetails")) {
            deleteDraggableElementStory(index)
                .then(res => {
                    alert("Element Deleted Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("RadioChannelDetails")) {
            deleteDraggableElementChannel(index)
                .then(res => {
                    alert("Element Deleted Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        // Book
        if (arrayOfurl.includes("BookDetails")) {
            deleteDraggableElementBook(index)
                .then(res => {
                    alert("Element Deleted Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        // PDF Book
        if (arrayOfurl.includes("PdfBookDetails")) {
            deleteDraggableElementPdfBook(index)
                .then(res => {
                    alert("Element Deleted Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
    }

    onChangeElement(e, index) {
        let tempArr = this.state.listElements;
        let obj = tempArr[index];
        var element = {
            imageUrl: obj.imageUrl,
            dropElemId: obj.dropElemId,
            draggableWidth: obj.draggableWidth,
            dragDropHeight: obj.dragDropHeight,
            xCoordinate: obj.xCoordinate,
            yCoordinate: obj.yCoordinate
        };

        if (e.target.name.includes("imageUrl")) {
            element.imageUrl = e.target.value;
        }
        if (e.target.name.includes("draggableWidth")) {
            element.draggableWidth = e.target.value;
        }
        if (e.target.name.includes("dragDropHeight")) {
            element.dragDropHeight = e.target.value;
        }
        if (e.target.name.includes("xCoordinate")) {
            element.xCoordinate = e.target.value;
        }
        if (e.target.name.includes("yCoordinate")) {
            element.yCoordinate = e.target.value;
        }

        tempArr[index] = element;
        this.setState({ listElements: tempArr });
    }

    onChangeBasic(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    getGameImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({ game_Image: '' });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ game_Image: imageUrl });
        }
    }

    getCropedGameImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({ croppedImageUrl: '' });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ croppedImageUrl: imageUrl });
        }
    }

    getNewDragElementImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({ newImage: '' });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ newImage: imageUrl });
        }
    }

    getDragElementImageUrl(index, imageUrl) {
        let tempArr = this.state.listElements;
        let obj = tempArr[index];
        var element = {
            imageUrl: imageUrl,
            dropElemId: obj.dropElemId,
            draggableWidth: obj.draggableWidth,
            dragDropHeight: obj.dragDropHeight,
            xCoordinate: obj.xCoordinate,
            yCoordinate: obj.yCoordinate
        };
        tempArr[index] = element;
        this.setState({ listElements: tempArr });
    }

    editParentDragDrop() {
        const { redirectionPage } = this.props;

        if (redirectionPage.dragDropPageName == DRAG_DROP_DETAIL) {

            updateDragAndDrop(this.state.dragDropID, this.state.dragDropWidth, this.state.dragDropHeight, this.state.game_Image, this.state.croppedImageUrl)
                .then(res => {
                    alert("Drag & Drop Item Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        let arrayOfurl = redirectionPage.dragDropPageName.split('/');

        if (arrayOfurl.includes("ViewEpisode")) {

            updateDragAndDropEpisode(this.state.dragDropID, this.state.dragDropWidth, this.state.dragDropHeight, this.state.game_Image, this.state.croppedImageUrl)
                .then(res => {
                    alert("Drag & Drop Item Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("songDetails")) {

            updateDragAndDropSong(this.state.dragDropID, this.state.dragDropWidth, this.state.dragDropHeight, this.state.game_Image, this.state.croppedImageUrl)
                .then(res => {
                    alert("Drag & Drop Item Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("storyDetails")) {

            updateDragAndDropStory(this.state.dragDropID, this.state.dragDropWidth, this.state.dragDropHeight, this.state.game_Image, this.state.croppedImageUrl)
                .then(res => {
                    alert("Drag & Drop Item Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("RadioChannelDetails")) {

            updateDragAndDropChannel(this.state.dragDropID, this.state.dragDropWidth, this.state.dragDropHeight, this.state.game_Image, this.state.croppedImageUrl)
                .then(res => {
                    alert("Drag & Drop Item Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("BookDetails")) {

            updateDragAndDropBook(this.state.dragDropID, this.state.dragDropWidth, this.state.dragDropHeight, this.state.game_Image, this.state.croppedImageUrl)
                .then(res => {
                    alert("Drag & Drop Item Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("PdfBookDetails")) {
            
            updateDragAndDropPdfBook(this.state.dragDropID, this.state.dragDropWidth, this.state.dragDropHeight, this.state.game_Image, this.state.croppedImageUrl)
                .then(res => {
                    alert("Drag & Drop Item Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

    }

    editDraggableElement(index) {
        let tempArr = this.state.listElements;
        let obj = tempArr[index];

        const { redirectionPage } = this.props;

        if (redirectionPage.dragDropPageName == DRAG_DROP_DETAIL) {

            updateOneDraggableElement(obj.dropElemId, obj.draggableWidth, obj.dragDropHeight, obj.xCoordinate, obj.yCoordinate, obj.imageUrl)
                .then(res => {
                    alert("Draggable Element Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        let arrayOfurl = redirectionPage.dragDropPageName.split('/');

        if (arrayOfurl.includes("ViewEpisode")) {

            updateOneDraggableElementEpisode(obj.dropElemId, obj.draggableWidth, obj.dragDropHeight, obj.xCoordinate, obj.yCoordinate, obj.imageUrl)
                .then(res => {
                    alert("Draggable Element Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("songDetails")) {

            updateOneDraggableElementSong(obj.dropElemId, obj.draggableWidth, obj.dragDropHeight, obj.xCoordinate, obj.yCoordinate, obj.imageUrl)
                .then(res => {
                    alert("Draggable Element Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("storyDetails")) {

            updateOneDraggableElementStory(obj.dropElemId, obj.draggableWidth, obj.dragDropHeight, obj.xCoordinate, obj.yCoordinate, obj.imageUrl)
                .then(res => {
                    alert("Draggable Element Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("RadioChannelDetails")) {

            updateOneDraggableElementChannel(obj.dropElemId, obj.draggableWidth, obj.dragDropHeight, obj.xCoordinate, obj.yCoordinate, obj.imageUrl)
                .then(res => {
                    alert("Draggable Element Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        // Book
        if (arrayOfurl.includes("BookDetails")) {

            updateOneDraggableElementBook(obj.dropElemId, obj.draggableWidth, obj.dragDropHeight, obj.xCoordinate, obj.yCoordinate, obj.imageUrl)
                .then(res => {
                    alert("Draggable Element Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        // Pdf Book
        if (arrayOfurl.includes("PdfBookDetails")) {

            updateOneDraggableElementPdfBook(obj.dropElemId, obj.draggableWidth, obj.dragDropHeight, obj.xCoordinate, obj.yCoordinate, obj.imageUrl)
                .then(res => {
                    alert("Draggable Element Updated Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

    }

    addNewElement(e) {

        const { redirectionPage } = this.props;

        if (redirectionPage.dragDropPageName == DRAG_DROP_DETAIL) {

            addDraggableElement(this.props.match.params.id, this.state.newDraggableWidth, this.state.newDraggableHeight, this.state.newXCordinate, this.state.newYCordinate, this.state.newImage)
                .then(res => {
                    //$('.close').click();
                    document.getElementById("close").click();
                    alert("Draggable Element Added Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        let arrayOfurl = redirectionPage.dragDropPageName.split('/');

        if (arrayOfurl.includes("ViewEpisode")) {

            addDraggableElementEpisode(this.props.match.params.id, this.state.newDraggableWidth, this.state.newDraggableHeight, this.state.newXCordinate, this.state.newYCordinate, this.state.newImage)
                .then(res => {
                    //$('.close').click();
                    document.getElementById("close").click();
                    alert("Draggable Element Added Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("songDetails")) {

            addDraggableElementSong(this.props.match.params.id, this.state.newDraggableWidth, this.state.newDraggableHeight, this.state.newXCordinate, this.state.newYCordinate, this.state.newImage)
                .then(res => {
                    //$('.close').click();
                    document.getElementById("close").click();
                    alert("Draggable Element Added Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("storyDetails")) {

            addDraggableElementStory(this.props.match.params.id, this.state.newDraggableWidth, this.state.newDraggableHeight, this.state.newXCordinate, this.state.newYCordinate, this.state.newImage)
                .then(res => {
                    //$('.close').click();
                    document.getElementById("close").click();
                    alert("Draggable Element Added Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("RadioChannelDetails")) {

            addDraggableElementChannel(this.props.match.params.id, this.state.newDraggableWidth, this.state.newDraggableHeight, this.state.newXCordinate, this.state.newYCordinate, this.state.newImage)
                .then(res => {
                    //$('.close').click();
                    document.getElementById("close").click();
                    alert("Draggable Element Added Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        // Book
        if (arrayOfurl.includes("BookDetails")) {

            addDraggableElementBook(this.props.match.params.id, this.state.newDraggableWidth, this.state.newDraggableHeight, this.state.newXCordinate, this.state.newYCordinate, this.state.newImage)
                .then(res => {
                    //$('.close').click();
                    document.getElementById("close").click();
                    alert("Draggable Element Added Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        // PDF Book
        if (arrayOfurl.includes("PdfBookDetails")) {

            addDraggableElementPdfBook(this.props.match.params.id, this.state.newDraggableWidth, this.state.newDraggableHeight, this.state.newXCordinate, this.state.newYCordinate, this.state.newImage)
                .then(res => {
                    //$('.close').click();
                    document.getElementById("close").click();
                    alert("Draggable Element Added Successfully.");
                    this.getDragAndDropByID(this.props.match.params.id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
    }


    redirect = (e) => {

        this.setState({ redirection: true })
    }

    render() {

        const InValid = this.state.dragDropWidth === "" || this.state.dragDropHeight === "" || this.state.game_Image === "" || this.state.croppedImageUrl === "";
        const InValidModal = this.state.newImage === "" ||
            this.state.newDraggableWidth === "" ||
            this.state.newDraggableHeight === "" ||
            this.state.newXCordinate === "" ||
            this.state.newYCordinate === "";

        if (this.state.redirection == true) {
            const { redirectionPage } = this.props;
            var pageName = redirectionPage.dragDropPageName
            console.log(redirectionPage.dragDropPageName)

            return (<Redirect to={pageName} ></Redirect>)
        }

        return (
            <div className="container">

                <div class="modal fade"
                    id="exampleModal"
                    tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add New Drag & Drop Element</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="add-items icon-upload-itm">
                                            <span className="required_input">*</span>
                                            <UploadImage
                                                getImageURLCallBack={this.getNewDragElementImageUrl.bind(this)}
                                                imageUrl={this.state.newImage}
                                                imagepreview={this.state.newImage}
                                                s3DirectryrName='dragdrop' />
                                            <div className="upload-hint x-small-font">This image size should be 200px * 200px</div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="small-label"><span className="required_input">*</span>Element Width</label>
                                            <input className="form-control" min="1" type="number" name="newDraggableWidth" value={this.state.newDraggableWidth} onChange={e => this.onChangeBasic(e)} />
                                        </div>

                                        <div className="form-group">
                                            <label className="small-label"><span className="required_input">*</span>Element Height</label>
                                            <input className="form-control" min="1" type="number" name="newDraggableHeight" value={this.state.newDraggableHeight} onChange={e => this.onChangeBasic(e)} />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="small-label"><span className="required_input">*</span>Element X Cordinate</label>
                                            <input className="form-control" min="1" type="number" name="newXCordinate" value={this.state.newXCordinate} onChange={e => this.onChangeBasic(e)} />
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="small-label"><span className="required_input">*</span>Element Y Cordinate</label>
                                            <input className="form-control" min="1" type="number" name="newYCordinate" value={this.state.newYCordinate} onChange={e => this.onChangeBasic(e)} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="modal-footer editBTNs">
                                <button type="button"
                                    id="close"
                                    className="btn btn-dark"
                                    data-dismiss="modal">
                                    Close
                                 </button>
                                <button type="button"
                                    class="btn btn-primary colorBTN"
                                    disabled={InValidModal}
                                    onClick={e => this.addNewElement(e)}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>




                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />

                                            {/* <div className="col-12 left-main-body" id="left-main-body">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link to={ALL_GAMES}>Get All Games</Link><span> > </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link to={DRAG_DROP_DETAIL}>Drag & Drop Details</Link><span> > </span>
                                                    </span>

                                                    <span>Edit Drag & Drop</span>
                                                </div>
                                            </div> */}

                                            <div className="container">
                                                <h3 className="main-title mt-2 font-weight-bold">Edit New Drag & Drop</h3>

                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">

                                                                <div className="col-4">

                                                                    <div>
                                                                        <span className="card-title" color="gray">Drag & Drop</span>
                                                                    </div>
                                                                    <div className="add-items icon-upload-itm mt-4">
                                                                        <label className="small-label"><span className="required_input">*</span> Image</label>

                                                                        <UploadImage
                                                                            getImageURLCallBack={this.getGameImageUrl.bind(this)}
                                                                            imageUrl={this.state.game_Image}
                                                                            imagepreview={this.state.game_Image}
                                                                            s3DirectryrName='dragdrop' />

                                                                        <div className="upload-hint x-small-font">This image size should be 200px * 200px</div>
                                                                    </div>

                                                                    <div className="add-items icon-upload-itm mt-4">
                                                                        <label className="small-label">Croped Image</label>

                                                                        <UploadImage
                                                                            getImageURLCallBack={this.getCropedGameImageUrl.bind(this)}
                                                                            imageUrl={this.state.croppedImageUrl}
                                                                            imagepreview={this.state.croppedImageUrl}
                                                                            s3DirectryrName='dragdrop' />

                                                                        <div className="upload-hint x-small-font">This image size should be 200px * 200px</div>
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label className="small-label"><span className="required_input">*</span> Drag & Drop Width</label>
                                                                        <input className="form-control" value={this.state.dragDropWidth} name="dragDropWidth" onChange={e => this.onChangeBasic(e)} min="1" type="number" />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label className="small-label"><span className="required_input">*</span> Drag & Drop Height</label>
                                                                        <input className="form-control" value={this.state.dragDropHeight} name="dragDropHeight" onChange={e => this.onChangeBasic(e)} min="1" type="number" />
                                                                    </div>


                                                                    <div className="groups-btn mt-5 editBTNs">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <button className="btn btn-dark colorBTN" disabled={InValid} onClick={e => this.editParentDragDrop(e)}>Save</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>



                                                                <div className="col-8">

                                                                    {this.state.listElements !== null && this.state.listElements.map((dragElement, index) => {
                                                                        return (
                                                                            <div className="element mt-3" key={index}>
                                                                                <div><span className="card-title" color="gray">Drag & Drop Element</span></div>

                                                                                <br />

                                                                                <div className="col-6">
                                                                                    <div className="add-items icon-upload-itm">
                                                                                        <span className="required_input">*</span>
                                                                                        {console.log(dragElement.imageUrl)}

                                                                                        <UploadImageWithoutDropZone
                                                                                            getImageURLCallBack={this.getDragElementImageUrl.bind(this, index)}
                                                                                            imageUrl={dragElement.imageUrl}
                                                                                            imagepreview={dragElement.imageUrl}
                                                                                            s3DirectryrName='dragdrop' />

                                                                                        <div className="upload-hint x-small-font">This image size should be 200px * 200px</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="small-label"><span className="required_input">*</span>Element Width</label>
                                                                                        <input className="form-control" min="1" type="number" value={dragElement.draggableWidth} name={"draggableWidth" + index} onChange={e => this.onChangeElement(e, index)} />
                                                                                    </div>

                                                                                    <div className="form-group">
                                                                                        <label className="small-label"><span className="required_input">*</span>Element Height</label>
                                                                                        <input className="form-control" min="1" type="number" value={dragElement.dragDropHeight} name={"dragDropHeight" + index} onChange={e => this.onChangeElement(e, index)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="small-label"><span className="required_input">*</span>Element X Cordinate</label>
                                                                                        <input className="form-control" min="1" type="number" value={dragElement.xCoordinate} name={"xCoordinate" + index} onChange={e => this.onChangeElement(e, index)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="small-label"><span className="required_input">*</span>Element Y Cordinate</label>
                                                                                        <input className="form-control" min="1" type="number" value={dragElement.yCoordinate} name={"yCoordinate" + index} onChange={e => this.onChangeElement(e, index)} />
                                                                                    </div>
                                                                                </div>


                                                                                <span>
                                                                                    <div className="col-12 mt-4 editBTNs">
                                                                                        {
                                                                                            index !== 0 &&
                                                                                            <span>
                                                                                                <button className="btn btn-outline-danger" onClick={e => this.deleteElement(dragElement.dropElemId)}>Delete element</button>
                                                                                            </span>
                                                                                        }

                                                                                        <button className="btn btn-dark colorBTN" disabled={dragElement.imageUrl === "" || dragElement.draggableWidth === "" || dragElement.dragDropHeight === "" || dragElement.xCoordinate === "" || dragElement.yCoordinate === ""} onClick={e => this.editDraggableElement(index)}>Save element</button>
                                                                                    </div>
                                                                                </span>

                                                                            </div>

                                                                        )
                                                                    })}



                                                                    <div className="groups-btn mt-5 editBTNs">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="float-right rest-floating">


                                                                                    <button className="btn btn-dark" onClick={this.redirect}>
                                                                                        Back
                                                                                    </button>


                                                                                    <button
                                                                                        className="btn btn-dark colorBTN"
                                                                                        data-toggle="modal"
                                                                                        data-target="#exampleModal"
                                                                                        onClick={e => this.emptyModal()}>
                                                                                        + Add New Element
                                                                                     </button>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.dragDropitem
});
export default withFirebase(connect(mapStateToProps, {})(editDragDrop));
