import React, { Component } from 'react'
import Loader from '../Layout/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import AudioPlayer from "react-h5-audio-player";
import { ProgressBar } from 'react-bootstrap';
import AudioPlayer from './AudioPlayerComponent';
var ReactS3Uploader = require('react-s3-uploader');


const INITIAL_STATE = {
    audio: "",
    audioPreview: "",
    audioUrl: "",
    audioError: "",
    completed: 0
}

class UploadAudio extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE
    }

    componentDidMount() {
        // console.log("componentDidMount")
        if (this.props.audioUrl !== undefined && this.props.audioPreview !== undefined) {

            // if the returned data from DB = null || empty 
            // then empty the audio data to enable uploading another one
            if (this.props.audioUrl == null
                || this.props.audioUrl == ''
                || this.props.audioUrl === " "
                || this.props.audioUrl == undefined
                || this.props.audioPreview == null
                || this.props.audioPreview == ''
                || this.props.audioPreview === " "
                || this.props.audioPreview == undefined
            ) {

                // console.log("componentDidMount if ( null)")
                this.setState({
                    audioPreview: "",
                    audioUrl: ""
                })

            }
            else {
                // console.log("componentDidMount elsss (not null)")
                this.setState({
                    audioPreview: this.props.audioPreview,
                    audioUrl: this.props.audioUrl
                })
            }
        }
    }

    async componentWillReceiveProps(nextProp) {
        // console.log("Start componentWillReceiveProps")
        await nextProp.audioUrl;
        await nextProp.audioPreview;
        // console.log("***************")
        // console.log(nextProp.audioUrl)
        // console.log(nextProp.audioPreview)
        // console.log("***************")
        if (nextProp.audioUrl !== undefined && nextProp.audioPreview !== undefined) {

            // if the returned data from DB = null || empty 
            // then empty the audio data to enable uploading another one
            if (nextProp.audioUrl == null
                || nextProp.audioUrl == ''
                || nextProp.audioPreview == null
                || nextProp.audioPreview == ''
                || nextProp.audioUrl == "null"
                || nextProp.audioPreview == "null"

            ) {

                // console.log("componentWillReceiveProps if ( null)")
                this.setState({
                    audioPreview: "",
                    audioUrl: ""
                })

            }
            else {
                // console.log("componentWillReceiveProps elsss (not null)")
                this.setState({
                    audioPreview: nextProp.audioPreview,
                    audioUrl: nextProp.audioUrl
                })
            }
        }
        // console.log("End componentWillReceiveProps")

    }


    deleteVoice = () => {
        // console.log("deleteVoice")
        // sending delete to the outer header to send null to DB
        this.props.getAudioUrlCallBack('delete');
        this.setState({
            audio: "",
            audioPreview: "",
            audioUrl: "",
            audioError: ""
        })
    }

    onUploadProgress = (percent) => {
        // console.log("onUploadProgress")
        this.setState({ no_visible: false, completed: percent });
    }
    onError = (errror) => {
        // console.log("onError")
        alert(errror)
    }
    onUploadFinish = (e) => {
    //  console.log("onUploadFinish")
        this.setState({
            audioPreview: e.publicUrl,
            audioUrl: e.publicUrl,
            no_visible: true
        })
        // console.log("audioUrl", e.publicUrl)
        this.props.getAudioUrlCallBack(e.publicUrl);
        // console.log("onUploadFinish = " + e.publicUrl);
    }

    render() {

        let { audioPreview, audioError } = this.state;
        let { audioUrl } = this.state;
        let $audioPreview = null;
        let loadAudio = null;


        const { s3DirectryrName } = this.props;

        let uploadinglink = 'newgenepublic';
        if (s3DirectryrName !== '') {
            uploadinglink = 'newgenepublic/' + s3DirectryrName;
        }
        let $dropzone = (
            <div>
                <div class="custom-file">
                    <div className=''>
                        <label>
                            <FontAwesomeIcon icon="microphone" size="2x" />
                            <span className="d-none">
                                <ReactS3Uploader accept="audio/*"
                                    signingUrl="/W3S/getPresignedURL"
                                    signingUrlMethod="POST"
                                    onProgress={this.onUploadProgress}
                                    onError={this.handleError}
                                    onFinish={this.onUploadFinish}
                                    signingUrlHeaders={{
                                        'content-type': 'application/json'
                                    }}
                                    signingUrlQueryParams={{ 'bucketName': uploadinglink }}
                                    uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
                                    contentDisposition="auto"
                                    scrubFilename={(filename) => new Date().getTime()}
                                />
                            </span>
                            <span className="col-2">
                                <ProgressBar striped bsstyle="success"
                                    hidden={this.state.no_visible}
                                    now={this.state.completed}
                                    label={`${this.state.completed}%`} />
                            </span>
                        </label>

                    </div>
                </div>
                <br /> <br />

            </div>);


        if (audioPreview) {
            // console.log("audioPreview = " + audioPreview)
            if (audioUrl === '') {
                if (audioError === '') {
                    loadAudio = (
                        <span className="audio">
                            <Loader />
                        </span>)

                }
                else if (audioError) {
                    // console.log("audioError = " + audioError)
                    $audioPreview = $dropzone;
                    this.setState({ audioError: '', audioPreview: '' })
                }
            }
            else {

                $audioPreview = (
                    <span>
                        <br /> <br /> <br />
                        <AudioPlayer audioPreview={audioPreview} />
                        {/* <AudioPlayer
                            src={audioPreview}
                            onPlay={e => console.log("onPlay")}
                            onEnded={e => {
                                console.log("onEnded")
                                console.log(e)
                            }}
                            onError={e => {
                                console.log("onError")
                                console.log(e)
                            }}
                        // other props here
                        /> */}

                        <FontAwesomeIcon
                            className="far fa-trash-alt"
                            icon='trash-alt'
                            onClick={this.deleteVoice.bind(this)}>
                        </FontAwesomeIcon>
                        <br />
                        <br />

                    </span>

                )
            };

        }
        else {
            $audioPreview = $dropzone;

        }

        return (
            <div>
                {$audioPreview}
                {loadAudio}
            </div>
        )
    }
}

export default UploadAudio
