import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import placeHolder from '../../images/placeholder.png';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/**Actions */
import {
    fetchAllStoryParent,
    emptyStoryParentWithStories,
    addStoryParentItemWithSentFrom
} from '../../Actions/StoryParentAction';
import { emptyEvaluationAll } from '../../Actions/EvaluationActions'
import { emptyAllStories } from '../../Actions/StoryActions'
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'
import { clearQuestions } from '../../Actions/QuestionActions';

/*Components */
import Loader from '../Layout/Loader';
import DeleteStoryParent from './DeleteStoryParent'
import SetStateStoryParent from './SetStateStoryParent'
import { ALL_STORIES_PARENTS, EDIT_STORY_PARENT, VIEW_STORY_PARENT } from '../../routes';

const INITIAL_STATE = {
    redirection: false,
    addingDone: false
}

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0! 
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}


class GetAllStoryParentsList extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {
        this.props.emptyEvaluationAll();
        this.props.emptyAllStories();

        this.props.fetchAllStoryParent();
        this.props.addSeriesIDtoRedirectionPage(ALL_STORIES_PARENTS, 0);

        this.props.clearQuestions();
    }

    redirectToView = (itemId, e) => {

        this.props.addSeriesIDtoRedirectionPage(VIEW_STORY_PARENT, itemId);
        this.setState({ redirection: true })
    }

    addingItemToRedux = (item, e) => {

        this.props.addStoryParentItemWithSentFrom(item, "AllStoryParents");
        this.setState({ addingDone: true })
    }


    render() {
        const { storyParent } = this.props;
        const { error, loading } = this.props;
        const { redirection, addingDone } = this.state;


        const { filterName, nameFilterCondition,
            description, descriptionFilterCondition,
            publishDate,
            dateFilterCondition,
            secondPublishDate,
            isFree,
            status,
            featured
        } = this.props;

        let choosenNameFilter = '',
            choosenPublishDate = '',
            choosenStatus = '',
            choosenDescription = ''

            let storyParentToFilter = ''
            if(storyParent) {
                if (featured){
                    storyParentToFilter = storyParent.filter(item => (item.featured === true))
                }
                else {
                    storyParentToFilter =storyParent
                }
            }

        const FilteredList = storyParentToFilter.filter(item => {

            if (nameFilterCondition == 'is') {
                choosenNameFilter = item.name == filterName
            } else if (nameFilterCondition == 'does not contain') {
                choosenNameFilter = !item.name.includes(filterName)
            } else if (nameFilterCondition == 'is not') {
                choosenNameFilter = item.name != filterName
            } else {
                choosenNameFilter = item.name.includes(filterName)
            }

            console.log("description loop" + item.description)
            if (item.description !== null &&
                item.description !== undefined &&
                item.description !== '') {

                console.log("description not null" + item.description)

                if (descriptionFilterCondition == 'is') {
                    choosenDescription = item.description == description
                } else if (descriptionFilterCondition == 'does not contain') {
                    choosenDescription = !item.description.includes(description)
                } else if (descriptionFilterCondition == 'is not') {
                    choosenDescription = item.description != description
                } else {
                    if (description !== '' && description !== null && description !== undefined) {
                        choosenDescription = item.description.includes(description)
                        console.log("contains = " + choosenDescription)
                    } else {
                        console.log("description true")
                        choosenDescription = true
                    }

                }
            }

            if (status == 'DEFAULT') {
                choosenStatus = item.status == 'Default' || item.status == 'DEFAULT'
            } else if (status == 'SUSPENDED') {
                choosenStatus = item.status == 'SUSPENDED'
            }

            if (item.publishDate != null) {


                let arrayOfDate = item.publishDate.split(" ")
                let date = arrayOfDate[0];

                if (dateFilterCondition == 'on') {
                    choosenPublishDate = publishDate == date
                }

                else if (dateFilterCondition == 'after') {

                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);

                    choosenPublishDate = filterDate < seriesDate
                }
                else if (dateFilterCondition == 'before') {
                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);

                    choosenPublishDate = filterDate > seriesDate;
                }
                else if (dateFilterCondition == 'between') {

                    if (secondPublishDate !== '' &&
                        secondPublishDate !== null &&
                        secondPublishDate !== undefined) {
                        var seriesDate = new Date(date);
                        var filterDate = new Date(publishDate);
                        var filterSecondDate = new Date(secondPublishDate);

                        choosenPublishDate = filterDate < seriesDate && seriesDate < filterSecondDate
                    }
                }
                else if (dateFilterCondition == 'not between') {
                    if (secondPublishDate !== '' &&
                        secondPublishDate !== null &&
                        secondPublishDate !== undefined) {
                        console.log("////////////////" + item.name + "////////////////////")
                        var seriesDate = new Date(date);
                        var filterDate = new Date(publishDate);
                        var filterSecondDate = new Date(secondPublishDate);

                        console.log("seriesDate")
                        console.log(date)
                        console.log(seriesDate)

                        console.log("filterDate")
                        console.log(publishDate)
                        console.log(filterDate)

                        console.log("filterDate")
                        console.log(secondPublishDate)
                        console.log(filterSecondDate)

                        let notbetween = filterDate < seriesDate && seriesDate < filterSecondDate

                        choosenPublishDate = !notbetween
                    }

                }
            }

            ///* Name and Date and status and description
            if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("Name and Date and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter && choosenStatus && choosenDescription
                }
            }
            ///* Name and Date and status
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("Name and Date and status")
                return choosenPublishDate && choosenNameFilter && choosenStatus
            }

            ///* Name and Date and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("Name and Date and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter && choosenDescription
                }
            }
            ///* Name and Date
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined
            ) {
                console.log("Name and Date")
                return choosenPublishDate && choosenNameFilter
            }

            //date and status and description
            else if (publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenStatus && choosenDescription
                }
            }
            //date and status
            else if (publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("date and status")
                return choosenPublishDate && choosenStatus
            }
            //date and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null &&
                dateFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenDescription
                }
            }
            //date
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined) {
                console.log("date")
                return choosenPublishDate
            }
            //name and status and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("name and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenNameFilter && choosenStatus && choosenDescription
                }
            }
            // status and description
            else if (
                status !== "" && status !== null && status !== undefined && status !== 'none' &&
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenStatus && choosenDescription
                }
            }

            //name and status
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("name and status")
                return choosenNameFilter && choosenStatus
            }
            // status
            else if (
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("status")
                return choosenStatus
            }

            //name and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null &&
                nameFilterCondition !== undefined &&
                descriptionFilterCondition !== "" &&
                descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("///name and description")

                // base case both are null return all values
                if ((filterName == '' || filterName == null || filterName == undefined) &&
                    (description == '' || description == null || description == undefined)) {
                    console.log("both empty")
                    return true;
                }
                else if ((filterName !== '' && filterName !== null && filterName !== undefined) &&
                    (description !== '' && description !== null && description !== undefined)) {
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        console.log("both not empty")
                        return choosenNameFilter && choosenDescription
                    }
                }
                else if (filterName !== ''
                    && filterName !== null
                    && filterName !== undefined) {
                    console.log("name exist")
                    return choosenNameFilter
                }
                else if (description !== '' && description !== null && description !== undefined) {
                    console.log("description exist")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {

                        return choosenDescription;
                    }
                }
            }
            //name
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined) {
                console.log("name")
                return choosenNameFilter
            }
            // description
            else if (
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("description")

                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenDescription
                }
            }
        })


        let storyParentList = null

        if (redirection == true) {
            return (
                <Redirect to={VIEW_STORY_PARENT} >
                </Redirect>
            )
        }

        if (addingDone == true) {
            return (
                <Redirect to={EDIT_STORY_PARENT} >
                </Redirect>
            )
        }

        if (loading === true) {
            storyParentList = (<Loader />);
        }


        else if (loading === false &&
            error === '') {

            let storyItems = storyParent

            if (
                filterName ||
                publishDate ||
                description ||
                status
            ) {
                storyItems = FilteredList
            }
            storyParentList = storyItems ? (
                storyItems.map(item => {

                    let parentDescription = "";
                    let tempImg = item.image
                    if (!item.image) {
                        tempImg = placeHolder;
                    }

                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== '') {
                        parentDescription = item.description
                    }
                    else {
                        parentDescription = "No description"
                    }

                    return (
                        <div className="card" key={item.id}>
                            <div className="row" >
                                <div className="col ">
                                    <img className="img-thumbnail" src={tempImg}
                                        alt="Story Parent" />
                                </div>
                                <div className="col-10">

                                    <div >
                                        {/* link and on click call fuction 
                                        that settes the parent and it's id in redux 
                                        then redirect to view Story */}
                                        <Link className="link"
                                            onClick={this.redirectToView.bind(this, item.id)}
                                        >

                                            <span className="card-title">
                                                {item.name}
                                            </span>
                                        </Link>

                                        <DeleteStoryParent id={item.id} />

                                        <button type="submit"
                                            className="seriesCardButtons"
                                            onClick={this.addingItemToRedux.bind(this, item)}>
                                            Edit
                                        </button>


                                        <SetStateStoryParent
                                            status={item.status}
                                            id={item.id}
                                            sentFrom="AllStoryParents" />

                                    </div>
                                    <span className="font-italic small-font ">
                                        <span className="date">{GMTtolocalTime(item.createdDate)}</span>

                                        <hr />
                                        {parentDescription}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })
            )
                : (
                    <div className="text-center">No Stories </div>
                )

        }

        else {
            storyParentList = (<div><br /><center><div>{error}</div></center><br /></div>)
        }
        return (
            <div className="container">
                {storyParentList}
            </div>

        )
    }
}


GetAllStoryParentsList.propTypes = {
    fetchAllStoryParent: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    storyParent: state.storyParent.items,
    loading: state.storyParent.loading,
    error: state.storyParent.error_loading_story_parents
});

export default withRouter
    (connect
        (mapStateToProps,
            {

                fetchAllStoryParent,
                emptyStoryParentWithStories,
                addStoryParentItemWithSentFrom,
                emptyEvaluationAll,
                emptyAllStories,
                clearQuestions,
                addSeriesIDtoRedirectionPage
            }
        )
        (GetAllStoryParentsList)
    );
