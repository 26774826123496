import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
/*header */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';

import { connect } from 'react-redux';
import AudioPlayer from "react-h5-audio-player";
/*css */
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../node_modules/video-react/dist/video-react.css';
/*Action */
import {
    getChannelData,
    emptyChannel,
    saveChannelPuzzle,
    saveChannelPaintingGame
} from '../../Actions/RadioChannelAction';
import { addDragDropParentIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*component */
import QuestionPreview from '../Layout/QuestionPreview';
import GamePreview from '../Layout/GamePreview';
import PaintingPreview from '../Layout/PaintingPreview';
import UploadImage from '../S3Uploading/UploadImage';
import EvaluationPreview from '../Evaluation/EvaluationPreview';
import DragDropPreview from '../Layout/DragDropPreview'
import { EDIT_ACTIVITY_RADIO_CHAN, EDIT_RADIO_CHANNEL, RADIO_CAHNNEL_TO_GET, RADIO_CHANNEL_TO_EDIT, RADIO_CHAN_ACTIVITY_TO_EDIT, SIGN_IN, VIEW_RADIO_CHANNEL } from '../../routes';


const INITIAL_STATE = {
    radioId: '',
    radioName: '',

    Icon_Image: "",
    Painting_Image: ""
}

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

class RadioChannelDetails extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.props.emptyChannel();
    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {

            let radioName = this.props.match.params.radioName;
            let radioId = this.props.match.params.radioId;
            let channelId = this.props.match.params.channelId;

            this.setState({
                radioName: radioName,
                radioId: radioId
            });

            this.props.getChannelData(channelId);

            var url = RADIO_CAHNNEL_TO_GET + "/"
                + radioName + "/"
                + radioId + "/"
                + channelId;

            this.props.addDragDropParentIDtoRedirectionPage(url, channelId);

        }
    }

    getIconImageUrl(imageUrl) {

        if (imageUrl) {
            this.setState({ Icon_Image: imageUrl });

            saveChannelPuzzle(this.props.match.params.channelId, imageUrl)
                .then(res => {
                    alert("Puzzle Game Item Added Successfully");
                    let id = this.props.match.params.channelId;
                    this.props.getChannelData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        setTimeout(() => {
            this.setState({ Icon_Image: "" });
        }, 10);
    }

    getPaintingImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ Painting_Image: imageUrl });

            saveChannelPaintingGame(this.props.match.params.channelId, imageUrl)
                .then(res => {
                    alert("Painting Game Item Added Successfully");
                    let id = this.props.match.params.channelId;
                    this.props.getChannelData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        setTimeout(() => {
            this.setState({ Painting_Image: "" });
        }, 10);
    }



    render() {

        let radioName = this.props.match.params.radioName;
        let radioId = this.props.match.params.radioId;
        let channelId = this.props.match.params.channelId;


        const { radioChannel } = this.props
        const { activity } = this.props
        const { puzzles } = this.props.radioChannel
        const { paintingGames } = this.props.radioChannel
        const { dragDrops } = this.props.radioChannel

        let evaluationPreview = null;

        let questionsList = null,
            editActivityLink = null,
            gamesList = null,
            gamesPaintingList = null;

        if (activity !== undefined) {

            questionsList = (
                <QuestionPreview state='view'
                    questions={activity.questions} />)

            evaluationPreview = (
                <EvaluationPreview
                    evaluationCategory={activity.evaluationCategory} />);


            if (activity.id !== undefined) {
                editActivityLink = (
                    <Link to={{
                        pathname: RADIO_CHAN_ACTIVITY_TO_EDIT
                            + "/" + activity.id
                            + "/" + radioName
                            + "/" + radioId
                            + "/" + channelId
                    }}>

                        <Button id="episode_view"> Edit </Button>

                    </Link>);
            }
        }

        let subvalue = "No Sub Value"
        if (radioChannel.contentSubValue !== null
            && radioChannel.contentSubValue !== undefined) {

            if (radioChannel.contentSubValue.contentValue !== null
                &&
                radioChannel.contentSubValue.contentValue !== undefined) {
                subvalue = radioChannel.contentSubValue.contentValue;
            }
        }

        if (puzzles !== undefined) {
            gamesList = (
                <GamePreview
                    state='viewChannel'
                    games={puzzles}
                />)
        }

        if (paintingGames !== undefined) {
            gamesPaintingList = (
                <PaintingPreview
                    state='viewChannel'
                    games={paintingGames} />)
        }

        let channel_drag_Drop = null;
        if (dragDrops !== undefined) {
            channel_drag_Drop = (<DragDropPreview
                games={dragDrops}
                state="viewChannel"
                parentId={channelId} />);
        }
        let isFree = ''
        if (radioChannel.isFree == 0) {
            isFree = 'paid'
        }
        else if (radioChannel.isFree == 1) {
            isFree = 'free'
        }

        let audio = ''
        console.log(radioChannel.voice)
        if (radioChannel.voice !== undefined &&
            radioChannel.voice !== null &&
            radioChannel.voice !== "undefined"
            && radioChannel.voice !== "null"
            && radioChannel.voice !== 'null') {
            audio = (<AudioPlayer
                src={radioChannel.voice}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            audio = (
                <td fluid className="episode_label"> No Audio</td>
            )
        }

        let trackWithMusic = ''
        console.log(radioChannel.trackWithMusic)
        if (radioChannel.trackWithMusic !== undefined &&
            radioChannel.trackWithMusic !== null &&
            radioChannel.trackWithMusic !== "undefined"
            && radioChannel.trackWithMusic !== "null"
            && radioChannel.trackWithMusic !== 'null') {
            trackWithMusic = (<AudioPlayer
                src={radioChannel.trackWithMusic}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            trackWithMusic = (
                <td fluid className="episode_label"> No Track With Music</td>
            )
        }

        let trackWithoutMusic = ''
        console.log(radioChannel.trackWithoutMusic)
        if (radioChannel.trackWithoutMusic !== undefined &&
            radioChannel.trackWithoutMusic !== null &&
            radioChannel.trackWithoutMusic !== "undefined"
            && radioChannel.trackWithoutMusic !== "null"
            && radioChannel.trackWithoutMusic !== 'null') {
            trackWithoutMusic = (<AudioPlayer
                src={radioChannel.trackWithoutMusic}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            trackWithoutMusic = (
                <td fluid className="episode_label"> No Track Without Music</td>
            )
        }


        let channelDescription = '';
        if (radioChannel.description !== null &&
            radioChannel.description !== undefined &&
            radioChannel.description !== '') {
            channelDescription = radioChannel.description
        }
        else {
            channelDescription = "No description"
        }

        let featured = false;
        if (radioChannel.featured !== null && radioChannel.featured !== undefined) {
            featured = radioChannel.featured;
        }


        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <label htmlFor="" >{radioChannel.name}</label>

                                                <hr />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <img className="rounded" src={radioChannel.image}
                                                            alt="" className="image_Preview" />
                                                    </div>

                                                    <div className="col">
                                                        <table >
                                                            <tr>
                                                                <th className="episode_label"> Channel Name </th>
                                                                <td fluid className="episode_label"> {radioChannel.name} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Channel Name In English</th>
                                                                <td fluid className="episode_label"> {radioChannel.nameInEnglish} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Selected Radio </th>
                                                                <td fluid className="episode_label"> {this.state.radioName} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> payment availability </th>
                                                                <td fluid className="episode_label"> {isFree} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Sub Value </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {subvalue}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Schedule </th>
                                                                <td fluid className="episode_label">{GMTtolocalTime(radioChannel.publishDate)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Track With Music </th>
                                                                {trackWithMusic}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Track Without Music </th>
                                                                {trackWithoutMusic}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Audio </th>
                                                                {audio}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Description </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {channelDescription}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Description In English </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {radioChannel.descriptionInEnglish}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Featured </th>
                                                                {featured ? "True" : "False"}
                                                            </tr>

                                                        </table>
                                                    </div>


                                                </div>

                                                <Link className="link" to={RADIO_CHANNEL_TO_EDIT
                                                    + "/" + this.state.radioName
                                                    + "/" + this.state.radioId
                                                    + "/" + radioChannel.id}>
                                                    <Button color='blue'
                                                        id="editButton">
                                                        Edit
                                                    </Button>
                                                </Link>
                                                <br /> <br /> <br />


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Question</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {questionsList}


                                                </div>
                                                <br />

                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Evaluation</label>
                                                    <hr id="shorthr" />
                                                    <br />
                                                    {evaluationPreview}

                                                    <div className="buttons_container">
                                                        {editActivityLink}

                                                    </div>

                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Puzzle Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {gamesList}

                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getIconImageUrl.bind(this)}
                                                            imageUrl={this.state.Icon_Image}
                                                            imagepreview={this.state.Icon_Image}
                                                            s3DirectryrName='radiochannel/puzzlegame' />
                                                    </div>
                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Painting Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {gamesPaintingList}

                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getPaintingImageUrl.bind(this)}
                                                            imageUrl={this.state.Painting_Image}
                                                            imagepreview={this.state.Painting_Image}
                                                            s3DirectryrName='radiochannel/paintinggame' />
                                                    </div>
                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Drag Drop Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {channel_drag_Drop}

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

const mapStateToProps = state => ({
    radioChannel: state.radioChannel.item,
    activity: state.radioChannel.item.activityId,
    auth: state.firebase.auth

});

export default connect
    (mapStateToProps,
        {
            getChannelData,
            emptyChannel,
            addDragDropParentIDtoRedirectionPage
        })(RadioChannelDetails)
