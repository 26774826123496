import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/* Same CSS style as PublishEpisode*/
import '../../css/AppCss/Episode/PublicViewPublishEpisode.css';
import '../../css/AppCss/Episode/ViewEpisode.css';
import '../../css/AppCss/Episode/EpisodeButtons.css';
import '../../../node_modules/video-react/dist/video-react.css';
import '../../css/AppCss/questionPreview.css'
import '../../css/AppCss/gamePreview.css'

import { connect } from 'react-redux';
import propTypes from 'prop-types';
import store from '../../Store'

import { withFirebase } from '../../config';
import { Link, Redirect, withRouter } from 'react-router-dom'

import { deleteStoryPuzzle, getStoryData } from '../../Actions/StoryActions';
import { deleteSongPuzzle, getSongData } from '../../Actions/SongActions';
import { deleteEpisodePuzzle, getEpisodeData } from '../../Actions/EpisodeActions';
import { deleteChannelPuzzle, getChannelData } from '../../Actions/RadioChannelAction'
import { deleteBookPuzzle, getBookData } from '../../Actions/ReadWithJeelAction'
import { deletePdfBookPuzzle, getPdfBookData } from '../../Actions/PdfBookAction'
import { SIGN_IN } from '../../routes';

export class GamePreview extends Component {

    constructor(props) {
        super(props);

        console.log(this.props);

    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }

    }


    handleDeleteImage(e, id) {

        if (this.props.state === "viewStory") {
            deleteStoryPuzzle(id)
                .then(res => {
                    alert("Puzzle Game Item Deleted Successfully");
                    let storyId = this.props.match.params.storyId;
                    this.props.getStoryData(storyId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (this.props.state === "viewSong") {
            deleteSongPuzzle(id)
                .then(res => {
                    alert("Puzzle Game Item Deleted Successfully");
                    let songId = this.props.match.params.songId;
                    this.props.getSongData(songId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (this.props.state === "viewEpisode") {
            deleteEpisodePuzzle(id)
                .then(res => {
                    alert("Puzzle Game Item Deleted Successfully");
                    let episodeId = this.props.match.params.episodeId;
                    this.props.getEpisodeData(episodeId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }


        if (this.props.state === "viewChannel") {
            deleteChannelPuzzle(id)
                .then(res => {
                    alert("Puzzle Game Item Deleted Successfully");
                    let channelId = this.props.match.params.channelId;
                    this.props.getChannelData(channelId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (this.props.state === "viewBook") {
            alert(this.props.bookId)
            deleteBookPuzzle(id)
                .then(res => {
                    alert("Puzzle Game Item Deleted Successfully");
                    let bookId = this.props.bookId;
                    this.props.getBookData(bookId);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        if (this.props.state === "viewPdfBook") {
            let pdfID = this.props.pdfId
            deletePdfBookPuzzle(id)
                .then(res => {
                    alert("Puzzle Game Item Deleted Successfully");
                    this.props.getPdfBookData(pdfID);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

    }



    render() {
        let gamesList = '';
        const { games } = this.props;
        const { state } = this.props;
        if (games !== undefined && games !== null) {

            gamesList = games.length >= 1 ? (games.map(game => {
                return (
                    <div className="col-3 gamePreview">
                        <span onClick={e => this.handleDeleteImage(e, game.id)}><FontAwesomeIcon icon="times" color="#dc6d6d" className="true" /></span>

                        <img class="image_Preview" alt={game.imageUrl} src={game.imageUrl} />
                    </div>
                )
            })
            ) : (<div className="col-12">No games</div>)
        }

        return (
            gamesList
        )
    }
}


const mapStateToProps = state =>
({
    auth: state.firebase.auth
})
export default
    withRouter(
        connect(
            mapStateToProps, {
            getStoryData,
            getSongData,
            getEpisodeData,
            getChannelData,
            getBookData,
            getPdfBookData
        })(GamePreview));
