import React, { Component } from 'react'
import { setStoryStatus, emptyStory, fetchStories } from '../../Actions/StoryActions'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { getStoryParentData} from '../../Actions/StoryParentAction'

class SetStoryStatus extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setStoryStatus(id, staus) {
        this.props.setStoryStatus(id, staus)
    }

    handleSubmit = (e) => {

        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setStoryStatus(id, "DEFAULT");

        } else {

            this.setStoryStatus(id, "SUSPENDED");
        }
        this.close();
    }
    render() {
        const { story_status_updated_successfully,
            error_updating_status } = this.props
        const { StoryParentId, status } = this.props

        let contentPreview = null;
        
        if (story_status_updated_successfully === false) {
            alert("Error: " + error_updating_status);
            this.props.emptyStory();
            this.props.getStoryParentData(StoryParentId);

        }
        else if (story_status_updated_successfully === true) {
            alert("Story status updated successfully");
            this.props.emptyStory();
            this.props.getStoryParentData(StoryParentId);
        }

        if (status === "SUSPENDED") {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons" onClick={this.open}>
                        <i  >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )
        }
        else {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                     onClick={this.open}>||</button>
                }
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }

        return (<span >
            {contentPreview}
            <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
        </span>)
    }
}

const mapStateToProps = state =>
    ({
        story_status_updated_successfully: state.stories.story_status_updated_successfully,
        error_updating_status: state.stories.error_updating_status
    })
export default connect
    (mapStateToProps,
        {
            setStoryStatus,
            emptyStory,
            getStoryParentData
        })
    (SetStoryStatus)
