import React, { Component } from 'react';
/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

/** Assets*/
import mainLogoM from '../../../images/portal/logo-big.png';

const INITIAL_STATE = {
  sideNav: false,
}

class NavBar extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

  }

  toggleSideNav = () => {
    let { sideNav } = this.state;
    sideNav = !sideNav;
    this.setState({ sideNav })
  }

  render() {
    return (
      <span className="parent-navebar">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={mainLogoM} alt="" />
            </a>
            {/**
            <button className={`navbar-toggler${this.state.sideNav ? 'show' : ''}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleSideNav}>
              <span className="navbar-toggler-icon"></span>
            </button>
            */}
            {/**
            <div id="navbarNav"
              className={`collapse navbar-collapse ${this.state.sideNav ? 'show' : ''}`}>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a className="nav-link" href="#">عن جيل
                      <span className="sr-only">(current)
                        </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">المميزات</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">التحميل</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">المحتوي</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">الاسئلة الشائعة</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">المدونة</a>
                  </li>
                </ul>
            </div>
            */}
          </div>
        </nav>
      </span>
    )
  }
}

const mapStateToProps = state => ({
});

export default withRouter
  (connect(mapStateToProps,
    {})
    (NavBar));
