import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withFirebase } from '../../config'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// /*Components */
import InAppNotificationTable from './InAppNotificationTable'
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import { ADMIN_LAND_PAGE , ADD_IN_APP_NOTIFICATION } from '../../routes';

class InAppNotification extends Component {

    


    render() {
        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body"
                                            id="left-main-body">
                                            <div className="box-container">


                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    </span>
                                                    <span>In App Notification</span>

                                                </div>
                                                {/* <!--  end status bar --> */}

                                                <Link to={{ pathname: ADD_IN_APP_NOTIFICATION }}>
                                                    <button type="submit"
                                                        className="seriesButtons">
                                                        + Add New In App Notification
                                                    </button>
                                                </Link>

                                    
                                                <h5 className="main-title mt-2 font-weight-bold">In App Notification</h5>

                                                <div className="mt-4 responsive-table">

                                                    <br />
                                                    <div className="card mt-3 p-0">
                                                        <div className="card-body p-4">

                                                           <InAppNotificationTable/>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(InAppNotification)
);

