import {
    FETCH_All_ADMINS_ROLE, LOAD_ADMINS_ROLE, ERROR_All_ADMINS_ROLE,
    EMPTY
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},

    loading: '',

    fetching_user_role_error: "",
    fetching_user_role_sucessfully: "",

}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_All_ADMINS_ROLE:

            return {
                ...state,
                items: action.payload,
                fetching_user_role_error: '',
                fetching_user_role_sucessfully:true,
                loading: false
            }


        case LOAD_ADMINS_ROLE:
            return {
                ...state,
                loading: true
            }

        case ERROR_All_ADMINS_ROLE:
            return {

                ...state,
                fetching_user_role_error: action.payload,
                loading: false
            }
        case EMPTY:
            return {
                ...state,
                fetching_user_role_error: action.payload,
                fetching_user_role_sucessfully: action.payload,
            }

        default:
            return state

    }

}
