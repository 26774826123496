import {
      FETCH_ALL_METHODS, LOAD_ALL_METHODS, ERROR_ALL_METHODS,
} from '../Actions/Types'


const initialState = {
    items: [],
    loading: '',
    getting_allMethods_sucessfully: '',
    getting_allMethods_error: '',
}

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_ALL_METHODS:
            return {
                ...state,
                items: action.payload,
                getting_allMethods_error: '',
                getting_allMethods_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_METHODS:
            return {
                ...state,
                getting_allMethods_error: action.payload,
                loading: false
            }


            case LOAD_ALL_METHODS:
                return {
                    ...state,
                    loading: true
                }
        default:
            return state
    }
}
