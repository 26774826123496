import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import propTypes from 'prop-types';

import { Accordion, Icon } from 'semantic-ui-react'

/**Actions */
import { fetchAllFAQ } from '../../Actions/FAQActions';
/*Components */
import Loader from '../Layout/Loader';
import EditFAQ from './EditFAQ';
import DeleteFAQ from './DeleteFAQ';

/*CSS*/
import '../../css/main-style.css';

class FAQCard extends Component {

    state = { activeIndex: 0 }

    componentDidMount() {
        this.props.fetchAllFAQ();
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    render() {

        const { activeIndex } = this.state

        const { faq } = this.props;
        const { loading, error, success } = this.props;

        var counter = 0;
        let FAQList = null

        if (loading === true) {
            FAQList = (<Loader />)
        }
        else if (success === true && error === '') {
            FAQList = faq.length ? (
                faq.map(faqItem => {

                    counter = counter + 1
                    return (
                        <div key={faqItem.id}>

                            <Accordion fluid styled>
                                <Accordion.Title active={activeIndex === counter} index={counter} onClick={this.handleClick}>
                                    <div className="card-header" id="card-header" >
                                        <h4 className="mb-0">

                                            <Icon name='dropdown' />
                                            {faqItem.question}
                                            <div className="acor-btn-group">

                                                <EditFAQ faqItem={faqItem} />

                                                <DeleteFAQ id ={faqItem.id}/>
                                                
                                            </div>

                                        </h4>
                                    </div>
                                </Accordion.Title>

                                <Accordion.Content active={activeIndex === counter}>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            {faqItem.answer}
                                        </div>
                                    </div>
                                </Accordion.Content>

                            </Accordion>
                        </div>
                    )
                })

            ) : (
                    <div className="center">No FAQ </div>
                )

        }

        return (
            <div className=" mt-3 p-0">
                <div className="card-body p-0">
                    {FAQList}
                </div>
            </div>
        )
    }
}

FAQCard.propTypes = {
    fetchAllFAQ: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    faq: state.faq.items,
    loading: state.faq.loading,
    error: state.faq.getting_all_FAQ_error,
    success: state.faq.getting_all_FAQ_sucessfully
});

export default withRouter(connect(mapStateToProps, { fetchAllFAQ })(FAQCard));

