import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import mainLogo from "../../../images/portal/icons/logo.svg";
import { Modal, Transition } from "semantic-ui-react";
import JeelAuthForm from "./JeelAuthForm";

const INITIAL_STATE = {};

class SubAuthModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render() {
    return (
      <div className="auth-modal-container">
        <Transition duration={500} visible={this.props.showSubAuth}>
          <Modal
            className="auth-modal login-modal show"
            closeOnDimmerClick={false}
            onClose={() => this.props.closeSubAuthModal()}
            open={this.props.showSubAuth}
          >
            <Modal.Header>
              <div className="d-flex align-items-center justify-content-between">
                <div className="modal-logo">
                  <img src={mainLogo} alt="" />
                </div>
                <button
                  type="button"
                  className="close m-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.props.closeSubAuthModal()}
                >
                  <FontAwesomeIcon icon={faTimes} className="faTimes" />
                </button>
              </div>
            </Modal.Header>
            <Modal.Content>
              <Modal.Actions>
                <form className="jeel-form">
                  <div className="mb-2">انشاء حساب جديد في جيل</div>
                  <div className="orange-title mb-3">
                    .قيمة الاشتراك الشهري هي 47 حنيهاُ مصريا ,
                    <span className="gray-text">تجدد تلقائياً كل شهر</span>
                  </div>
                  <JeelAuthForm />
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="btn portal-main-btn jeel-form-submit mx-auto my-2"
                      onClick={() => {
                        this.props.closeSubAuthModal();
                        this.props.openOTPModal();
                      }}
                    >
                      تأكيد الاشتراك
                    </button>
                  </div>
                </form>
                <div className="jeel-form-link d-flex align-items-center justify-content-center">
                  لديك حساب؟
                  <button
                    type="button"
                    onClick={() => {
                      this.props.closeSubAuthModal();
                      this.props.openLoginModal();
                    }}
                  >
                    سجل الدخول الان
                  </button>
                </div>
                <div className="form-messages">
                  <div className="form-message mb-2">
                    سوف تقوم بالاشتراك في خدمة جيل مقابل ٤٧ جنيه مصري شهريا تجدد
                    تلقائياً. لإلغاء الاشتراك لعملاء اورنج أرسل استهلاك الأنترنت
                    سوف يخصم من الباقة الخاصة بك
                  </div>
                </div>
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        </Transition>
      </div>
    );
  }
}
export default SubAuthModal;
