import { ADD_FAWRY_REDIRECTION_PAGE,
         REMOVE_FAWRY_REDIRECTION_PAGE }
 from '../Actions/Types';


 const initialState = {
     item: {},
 }

 export default function (state = initialState, action) {
     switch (action.type) {

         case ADD_FAWRY_REDIRECTION_PAGE:
             return {
                 ...state,
                 item: action.payload,
             }

         case REMOVE_FAWRY_REDIRECTION_PAGE:
             return {
                 ...state,
                 item: action.payload,
             }

         default:
             return state
     }

 }
