import {
    EMPTY_CUSTOMER, EMPTY_PARENT,
    FETCH_ALL_CUSTOMERS, LOAD_ALL_CUSTOMERS, ERROR_ALL_CUSTOMERS,
    ADDING_CUSTOMERS, ADD_CUSTOMER,
    LOAD_CUSTOMER, GET_CUSTOMER_DATA, ERROR_LOADING_CUSTOMER,
    LOAD_SENDING_MAILS, SEND_EMAIL, ERROR_SEND_EMAIL,
    LOAD_SENDING_NOTIFICATION, SEND_NOTIFICATION, ERROR_SENDING_NOTIFICATION,
    ERROR_SENDING_NOTIFICATION_TO_ALLCUSTOMERS, SEND_NOTIFICATION_TO_ALLCUSTOMERS,
    SETTING_STATE_CUSTOMER, ERROR_SETTING_STATE_CUSTOMER,
    CUSTOMER_RESET_PASSWORD, ERRROR_CUSTOMER_RESET_PASSWORD,
    ACTIVE_CUSTOMER_PACKAGE, ERROR_ACTIVE_CUSTOMER_PACKAGE,
    EMPTY_PACKAGE_ACTIVATION,
    LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_LOADING, FIREBASE_ERROR,
    EDIT_CUSTOMER, ERROR_EDITING_CUSTOMER, ADD_CHILD, REMOVE_CHILD,
    CUSTOMER_FIRST_SIGN_UP, CUSTOMER_SIGNNED_IN
} from './Types';

import axios, { post } from 'axios';
import firebase from 'firebase/app';


export const addCustomer = (customers_data) => dispatch => {
    const customer = { customer: customers_data };
    dispatch({
        type: ADDING_CUSTOMERS,
        payload: customer
    })
}

export const getCustomerData = (uid) => { 

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CUSTOMER,
                payload: 'loading'
            })

            const url = "/User/getUserWithUID"
            const formData = new FormData();
            formData.append('UID', uid);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_CUSTOMER_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_CUSTOMER,
                    payload: Error.message
                })
            })

        })

    }
}

export const getCustomerProfileData = (uid) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CUSTOMER,
                payload: 'loading'
            })

            const url = "/User/getUserProfileWithUID?UID="+uid

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            axios.get(url, config).then((res) => {
                dispatch(
                    {
                        type: GET_CUSTOMER_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_CUSTOMER,
                    payload: Error.message
                })
            })

        })

    }
}

// page numbber , size , filtername
export const fetchAllCustomers = (role_id) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ALL_CUSTOMERS,
                payload: 'loading'
            })

            const url = "/User/getAllUsersByRoleID"
            const formData = new FormData();
            formData.append('Role_ID', 1);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: FETCH_ALL_CUSTOMERS,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ALL_CUSTOMERS,
                    payload: Error.message
                })
            })

        })

    }
}

export const SendEmailsToSingle = (selectedCustomerUIds, email_subject, email_text) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SENDING_MAILS,
                payload: 'loading'
            })

            console.log("************")
            console.log(email_subject);
            console.log(email_text);
            console.log(selectedCustomerUIds);


            for (let i = 0; i < selectedCustomerUIds.length; i++) {
                console.log(selectedCustomerUIds[i]);
            }
            console.log("************")

            const url = "/Email/sendMail"
            const formData = new FormData();
            formData.append('Send_Mail_To', selectedCustomerUIds);
            formData.append('Email_Subject', email_subject);
            formData.append('Email_Text', email_text);


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SEND_EMAIL,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SEND_EMAIL,
                    payload: Error.message
                })
            })

        })

    }
}

export const SendNotificationToSingle = (selectedCustomerUIds, notification_text) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SENDING_NOTIFICATION,
                payload: 'loading'
            })

            console.log("************")
            console.log(notification_text);
            console.log(selectedCustomerUIds);


            for (let i = 0; i < selectedCustomerUIds.length; i++) {
                console.log(selectedCustomerUIds[i]);
            }
            console.log("************")


            const url = "/Notification/pushNotificationToList"
            const formData = new FormData();
            formData.append('Send_Notification_To', selectedCustomerUIds);
            formData.append('Notification_Text', notification_text);


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SEND_NOTIFICATION,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SENDING_NOTIFICATION,
                    payload: Error.message
                })
            })

        })

    }
}

export const SendNotificationToAllCustomers = (notification_text) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SENDING_NOTIFICATION,
                payload: 'loading'
            })


            const url = "/Notification/pushNotificationToAllCustomers"
            const formData = new FormData();
            formData.append('Notification_Text', notification_text);


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SEND_NOTIFICATION_TO_ALLCUSTOMERS,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SENDING_NOTIFICATION_TO_ALLCUSTOMERS,
                    payload: Error.message
                })
            })

        })

    }
}

export const setStateCustomer = (uid, Status) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CUSTOMER,
                payload: 'loading'
            })

            console.log(uid, Status)

            const url = "/User/UserSetState";
            const formData = new FormData();
            formData.append('User_UID', uid);
            formData.append('Status', Status);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SETTING_STATE_CUSTOMER,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_STATE_CUSTOMER,
                    payload: Error.message
                })
            })
        })

    }
}

// changing customer password in local database
export const customerResetPassword = (uid, password) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CUSTOMER,
                payload: 'loading'
            })

            console.log(uid, password)

            const url = "/User/userAndAdminResetPassword";
            const formData = new FormData();
            formData.append('UID', uid);
            formData.append('Password', password);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: CUSTOMER_RESET_PASSWORD,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERRROR_CUSTOMER_RESET_PASSWORD,
                    payload: Error.message
                })
            })
        })

    }
}

export const emptyCustomer = () => dispatch => {
    dispatch({
        type: EMPTY_CUSTOMER,
        payload: ''
    })
}

export const emptyPackageActivation = () => dispatch => {
    dispatch({
        type: EMPTY_PACKAGE_ACTIVATION,
        payload: ''
    })
}

export const activePaymentPlan = (user_uid, chargeId, payment_date, expiration_date, payment_package_type,paying_method,price, paying_currency) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_CUSTOMER,
                payload: 'loading'
            })

            const url = '/UserPaymentPlan/activePaymentPlan';
            const formData = new FormData();
            formData.append('User_UID', user_uid);
            formData.append('Charge_Id', chargeId);
            formData.append('Payment_Date', payment_date);
            formData.append('Expiration_Date', expiration_date);
            formData.append('Payment_Package_type', payment_package_type);
            formData.append('Auto_Renewel', 0);

            formData.append('Paying_Method', paying_method);
            formData.append('Paid_price', price);
            formData.append('Paid_price_currency', paying_currency);

            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ACTIVE_CUSTOMER_PACKAGE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ACTIVE_CUSTOMER_PACKAGE,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const getUserInfoByToken = (token) => {
  const url = "/User/getUserInfoByToken?ID_Token="+token
  return axios({
      method: "get",
      headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
      url: url
  });
}


export const addParentData = (name, gender, year, kid) => dispatch => {
    const parentData = {
        name: name,
        gender: gender,
        year: year,
        kidName: kid.kidName,
        kidDayUse: kid.kidDayUse,
        kidAge: kid.kidAge,
        kidPass:kid.kidPass,
        kidGender: kid.kidGender,
        kidImage: kid.kidImage,

    }
    dispatch({
        type: ADD_CUSTOMER,
        payload: parentData
    })
}

export const emptyParentData = () => dispatch => {
    dispatch({
        type: EMPTY_PARENT,
        payload: ''
    })
}

export const emptychildData =  (name, gender, year) => dispatch => {
  const parentData = {
      name: name,
      gender: gender,
      year: year,
  }
  dispatch({
      type: ADD_CUSTOMER,
      payload: parentData
  })
}

export const editCustomer = (obj) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_CUSTOMER,
            payload: 'Loading'
        })

        const firebase = getFirebase();

            firebase.auth().currentUser.getIdToken().then((token) => {
                const url = "/User/editCustomer"
                const data = {
                      "parentDTO": obj.parent,
                      "parentChildDTO":obj.children,
                }
                const config = {
                    headers: {
                        'content-type': 'application/json',
                        'X-Authorization-Firebase': token
                    }
                }
                post(url, data, config).then((res) => {
                        dispatch({
                            type: EDIT_CUSTOMER,
                            payload: res.data
                        })
            }).catch((Error) => {
              dispatch({
                  type: ERROR_EDITING_CUSTOMER,
                  payload: Error.response.data.message
              })
            })
        })
    }
}

export const customerForgetPassword = (email) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_CUSTOMER,
            payload: ''
        })

        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail(email).then( () => {
          //alert('An e-mail has been sent to you');
          dispatch({
              type: CUSTOMER_RESET_PASSWORD,
              payload: 'تم إرسال رسالة لبريدك الالكتروني'
          })
        }).catch((Error) => {
          dispatch({
              type: ERRROR_CUSTOMER_RESET_PASSWORD,
              payload: Error.message
          })
        })
    }
}

export const addCustomerPhoneToRedux = (phone) => dispatch => {
    const customerPhone = {
        phone: phone,
    }
    dispatch({
        type: GET_CUSTOMER_DATA,
        payload: customerPhone
    })
}

export const removeCustomerPhoneFromRedux = (phone) => dispatch => {

    dispatch({
        type: EMPTY_CUSTOMER,
        payload: ''
    })
}


export const addEditingChildToRedux = () => dispatch => {
  const isChild = {
      isChild: true,
      isParent: false
  }
  dispatch({
      type: ADD_CHILD,
      payload: isChild
  })
}

export const removEeditingChildFromRedux = () => dispatch => {
  const isChild = {
      isChild: '',
      isParent: ''
  }
  dispatch({
      type: REMOVE_CHILD,
      payload: ''
  })
}

export const signedUpCustomer = () => dispatch => {
  const signedUp = {
      firstSignUp: true,
  }
  dispatch({
      type: CUSTOMER_FIRST_SIGN_UP,
      payload: signedUp
  })
}

export const signedInCustomer = () => dispatch => {
  const signedUp = {
      firstSignUp: '',
  }
  dispatch({
      type: CUSTOMER_SIGNNED_IN,
      payload: ''
  })
}
