import {
    FETCH_All_PACKAGE_PAYMENT,
    ERROR_FETCH_All_PACKAGE_PAYMENT,
    LOAD_PACKAGE_PAYMENT, ADD_PAYMENT_DATA,
    DELETE_PAYMENT_DATA, ADD_AUTO_RENEWEL,
    UPDATE_All_PACKAGE_PAYMENT,
    ERROR_UPDATE_All_PACKAGE_PAYMENT,
    EMPTY_PACKAGE_PAYMENT
} from './Types'

import axios, { post } from 'axios'




// adding payment Package data to redux
export const addPaymentData = (cash, paymentType, expirationDate, isWithOffer) => dispatch => {
    const mobilePaymentPackage = {
        cash: cash,
        paymentType: paymentType,
        expirationDate: expirationDate,
        isWithOffer: isWithOffer
    }
    dispatch({
        type: ADD_PAYMENT_DATA,
        payload: mobilePaymentPackage
    })
}

// adding payment Package data to redux
export const isItAutoRenewel = (isAutoRenewel, expirationDate) => dispatch => {
    const autoRenewel = {
        isAutoRenewel: isAutoRenewel,
        expirationDate: expirationDate
    }
    dispatch({
        type: ADD_AUTO_RENEWEL,
        payload: autoRenewel
    })
}

export const clearPaymentData = () => dispatch => {

    dispatch({
        type: DELETE_PAYMENT_DATA,
        payload: ''
    })
}

export const fetchAllPaymentPackage = () => dispatch => {

    dispatch({
        type: LOAD_PACKAGE_PAYMENT,
        payload: 'loading'
    })

    axios.get('/PaymentPackage/getAllPaymentPackage')
        .then((res) => {
            dispatch({
                type: FETCH_All_PACKAGE_PAYMENT,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_FETCH_All_PACKAGE_PAYMENT,
                payload: Error.message
            })
        })
}

export const updateAllPaymentPackage = (weeklyDiscountPrice, weeklyOrinalPrice, monthlyDiscountPrice, monthlyOrinalPrice,
    yearlyDiscountPrice, yearlyOrinalPrice)  => {
        console.log("updateAllPaymentPackage")

        return (dispatch, getState, { getFirebase }) => {

            const firebase = getFirebase();
            firebase.auth().currentUser.getIdToken().then((token) => {
                dispatch({
                    type: LOAD_PACKAGE_PAYMENT,
                    payload: 'loading'
                })
                console.log(token)
                console.log("updateAllPaymentPackage 22222222")

                const url = "/PaymentPackage/updateAllPaymentPackageDetails";
                const formData = new FormData();
                formData.append('Weekly_Original_Price', weeklyOrinalPrice);
                formData.append('Weekly_Discount_Price', weeklyDiscountPrice);
                formData.append('Monthly_Original_Price', monthlyOrinalPrice);
                formData.append('Monthly_Discount_Price', monthlyDiscountPrice);
                formData.append('Yearly_Original_Price', yearlyOrinalPrice);
                formData.append('Yearly_Discount_Price', yearlyDiscountPrice);
                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                        'X-Authorization-Firebase': token
                    }
                }
                post(url, formData, config).then((res) => {
                    dispatch(
                        {
                            type: UPDATE_All_PACKAGE_PAYMENT,
                            payload: res.data
                        }
                    )
                }).catch((Error) => {
                    dispatch({
                        type: ERROR_UPDATE_All_PACKAGE_PAYMENT,
                        payload: Error.message
                    })
                })
            })

        }
    }


export const emptyError = () => dispatch => {
    dispatch({
        type: EMPTY_PACKAGE_PAYMENT,
        payload: ''
    })
}


export const addPaymentDataWithCode = (cash, paymentType, expirationDate, isWithOffer, codeName, paymentCurrencyPackage) => dispatch => {
    const mobilePaymentPackage = {
        cash: cash,
        paymentType: paymentType,
        expirationDate: expirationDate,
        isWithOffer: isWithOffer,
        codeName: codeName,
        paymentCurrencyPackage: paymentCurrencyPackage,
    }
    dispatch({
        type: ADD_PAYMENT_DATA,
        payload: mobilePaymentPackage
    })
}
