import React, { Component } from 'react'
import { Link , Redirect} from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react';
/**Other Component */
import Evaluation from '../Evaluation/Evaluation';
/*Navbar*/
import Circles2 from '../Layout/Circles2'
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withFirebase } from '../../config';
import { ADD_RADIO_CHAN_ACTIVITY, PUBLISH_RADIO_CHANNEL } from '../../routes';

const INITIAL_STATE = {
    redirection: false,
}

class RadioChannelEvaluation extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE

    }

    handleCancel = (e) => {
        this.setState({ redirection: true })
    }

    render() {
        if (this.state.redirection === true) {
            const { redirectionPage } = this.props;
            var pageName = redirectionPage.pageName
            return (<Redirect to={pageName} ></Redirect>)
        }
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="main-progress-bar">
                                                <ol className="progress-tracker">
                                                    <li className="step active s2-active"><span className="step-name small-font">ِAdd Channel</span></li>
                                                    <li className="step active s2-active"><span className="step-name small-font">Add activity</span></li>
                                                    <li className="step active"><span class="step-name small-font">Add Evaluation</span></li>
                                                    <li className="step"><span className="step-name small-font">Puplish Channel</span></li>
                                                </ol>
                                            </div>
                                            <br />

                                            {/* Adding component */}
                                            <Evaluation />

                                            <div>
                                                <div className="buttons_container">

                                                    <Link to={PUBLISH_RADIO_CHANNEL}>
                                                        <Button icon labelPosition='right' id="nextButton" >
                                                            Next
                                                         <Icon name='right arrow' />
                                                        </Button>
                                                    </Link>

                                                    <Link to={ADD_RADIO_CHAN_ACTIVITY}>
                                                        <Button icon labelPosition='left'
                                                            id="episode_backButton" >
                                                            back
                                                            <Icon name='left arrow' />
                                                        </Button>
                                                    </Link>

                                                    <Button
                                                    onClick={this.handleCancel}
                                                    id="episode_cancel">Cancel</Button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    redirectionPage: state.redirectionPage.item
});

export default withFirebase(
    connect(
        mapStateToProps,
        {
        })(RadioChannelEvaluation));
