import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
/*Headers */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from "../Layout/Footer";
/*Redux*/
import { withFirebase } from '../../config';
import { connect } from 'react-redux';
import porpTypes from 'prop-types';
/*Actions*/
import { fetchQuestionsCategories } from '../../Actions/QuestionCategoryActions';
import { addActivity } from '../../Actions/ActivityActions'
import { addQuestions, getActitvityQuestions } from '../../Actions/QuestionActions';
/*Components*/
import AddActivityComponent from '../Activity/AddActivityComponent'

import '../../css/Activity.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { ADD_RADIO_CHANNEL, GET_RADIO_CHAN_EVAL } from '../../routes';

const INITIAL_STATE = {
    questions: []
}
class AddRadioChannelActivity extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }


    componentDidMount() {

        this.props.addActivity(this.props.radioChannel.name);
    }

    getValidatinData(questions) {
        console.log(questions)
        this.setState({ questions: questions })

    }

    render() {

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="main-progress-bar">
                                                    <ol className="progress-tracker">
                                                        <li className="step active s2-active">
                                                            <span className="step-name small-font">ِ
                                                            Add Channel
                                                                </span>
                                                        </li>
                                                        <li className="step active">
                                                            <span className="step-name small-font">
                                                                Add activity
                                                                </span>
                                                        </li>
                                                        <li className="step ">
                                                            <span className="step-name small-font">
                                                                Add Evaluation
                                                                </span>
                                                        </li>
                                                        <li className="step">
                                                            <span className="step-name small-font">
                                                                Puplish Channel
                                                                </span>
                                                        </li>
                                                    </ol>
                                                </div>
                                                <br />

                                                <AddActivityComponent
                                                    validateDataCallBack={this.getValidatinData.bind(this)} />
                                                <br />
                                                <Link to={GET_RADIO_CHAN_EVAL}>
                                                    <Button icon labelPosition='right'
                                                        id="nextButton" >
                                                        Next
                                                            <Icon name='right arrow' />
                                                    </Button>
                                                </Link>

                                                <Link to={ADD_RADIO_CHANNEL}>
                                                    <Button icon
                                                        labelPosition='left'
                                                        id="episode_backButton" >
                                                        back
                                                            <Icon name='left arrow' />
                                                    </Button>
                                                </Link>

                                                <br /> <br /> <br />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

AddRadioChannelActivity.porpTypes = {
    fetchQuestionsCategories: porpTypes.func.isRequired,
    questionCategories: porpTypes.func.isRequired,
    addQuestion: porpTypes.func.isRequired,
    getActitvityQuestions: porpTypes.func.isRequired,
    Activityquestions: porpTypes.func.isRequired
}
const mapStateToProps = state => ({
    questionCategories: state.questionCategories.items,
    activity: state.activities.item,
    question: state.questions.item,
    radioChannel: state.radioChannel.item,
    activityQuestions: state.questions.items

});

export default withFirebase(
    connect(
        mapStateToProps,
        {
            fetchQuestionsCategories,
            addQuestions,
            getActitvityQuestions,
            addActivity
        })(AddRadioChannelActivity));
