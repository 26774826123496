import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

/*Actions*/
import {
    getUserData, emptyMobileUserProfile,
    emptyMobileUserProfileWithItem
} from '../../Actions/MobilePaymentProfileAction';
import { emptyToken } from '../../Actions/loginActions.js'
import { getUserInfo } from '../../Promises/UserData.js'

/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';

/* images */
import logo from "../../images/logo.png";

/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';


function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

class MobilePaymentFeedback extends Component {

    state = {
        userPaymentProfile: {}
    }
    constructor(props) {
        super(props);

        let token = this.props.match.params.token;

        getUserInfo(token).then((res) => {

            //console.log(res.data)

            this.setState({ userPaymentProfile: res.data })
            // firebase.auth().signOut()
            // this.props.emptyToken();
            // this.props.emptyMobileUserProfile();
            // this.props.emptyMobileUserProfileWithItem();

        })
        console.log(constructor)
    }


    render() {
        
        // back to previous page if browser back button clicked
        window.onhashchange = function() {
            this.props.history.goBack();
        }
        
        console.log("render")

        const { userPaymentProfile } = this.state;

        let feedback_messege1 = "تمت العمليه بنجاح";

        if ((!isEmpty(userPaymentProfile)) &&
            userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType !== null
            && userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType !== undefined) {

            //console.log(userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType);

            if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType === "Monthly") {
                feedback_messege1 = "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة شهر"
            } else if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType === "Yearly"){
                feedback_messege1 = "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة عام كامل"
            } else if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType === "Weekly"){
                feedback_messege1 = "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة أسبوع"
            } else {
                feedback_messege1 = "تهانينا! تم الدفع بنجاح"
            }
        }



        return (
            <div className="main-payment" >

                <div className="shape-container">
                    <div className=" bg-img">
                    </div>
                </div>

                <div className="container leftToRight">
                    <div className="pay-box">

                        <div className="  main-logo text-center h4">
                            <img className="  logo" alt="logo" src={logo} />
                            <p className=" font  small-font main-color ">
                                بوابة الدفع لجيل
                        </p>
                        </div>
                        <div >

                            <div className=" card card-container Current-Package mb-4 ">
                                <div className="card-header profile-name-card text-right">


                                    <span className="h4 font">مرحبا</span>
                                    <span className="h4 font">   &nbsp;
                                        {userPaymentProfile.name}
                                        &nbsp; </span>


                                </div>
                                <div className="tab-button-outer">

                                    <div id="tab02" className="tab-contents">

                                        <div className="  card-body">

                                            <div className="  h6 text-center mb-4  font">
                                                {feedback_messege1}
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                    <MobilePaymentFooter />
                </div>


            </div>
        )
    }
}




MobilePaymentFeedback.propTypes = {
}
const mapStateToProps = state => ({
});

export default withRouter
    (connect
        (mapStateToProps,
            {
        
                emptyToken, emptyMobileUserProfile,
                emptyMobileUserProfileWithItem
            }
        )
        (MobilePaymentFeedback)
    );


