import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

/*Actions */
import { getQuesrionDetails } from '../../Promises/Question.js';
import { emptyQuestions } from '../../Actions/QuestionActions'
import { getActivityData } from '../../Actions/ActivityActions';

class QuestionDetails extends Component {


    handleSubmit = (e) => {

        const { questionId } = this.props;
        const { activityId } = this.props;

        console.log("id data = " + questionId)

        getQuesrionDetails(questionId).then((res) => {
            //alert("Question Data Successfully")
            //console.log(res.data);
            this.props.questionDataCallBack(res.data);

            // this.props.getActivityData(activityId);

        }).catch((Error) => {
            // it enters the catch when the correctAnsId = null
            alert("error missing data or network " + Error)
            // this.props.questionIsDeleted(false);
        })

    }

    render() {
        return (
            <span >
            <Popup trigger=
            {
                <FontAwesomeIcon
                    icon='edit' className="far fa-edit" onClick={this.handleSubmit}>
                </FontAwesomeIcon>
            }
            content='Edit'
            position='top center'
            />
            </span>

        )
    }
}


QuestionDetails.propTypes = {
    getActivityData: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
});


export default withFirebase
    (connect
        (mapStateToProps,
            { emptyQuestions, getActivityData })
        (QuestionDetails)
    );
