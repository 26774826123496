import { Promise } from 'q';
import firebase from 'firebase/app';
import { post } from 'axios'
import 'firebase/firestore';
import 'firebase/auth';


export function getInAppNotificationDataPromise(id) {
    return new Promise(function (resolve, reject) {
        

        const url = "/InAppNotification/getInAppNotificationDetails";
        const formData = new FormData();
        formData.append('Id', id);

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res) => {
            resolve(res);
            return res;
        }).catch((Error) => {
            reject(Error)
            return Error.response.data.message
        })
    })
}