import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withFirebase } from '../../config';
import placeHolder from '../../images/placeholder.png';
/*Redux*/
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { saveDragAndDrop } from '../../Actions/GameActions';
import {
    saveEpisodeDragAndDrop
} from '../../Actions/EpisodeActions';
import {
    saveSongDragAndDrop
} from '../../Actions/SongActions';
import {
    saveStoryDragAndDrop
} from '../../Actions/StoryActions';
import {
    saveChannelDragAndDrop
} from '../../Actions/RadioChannelAction';
import {saveBookDragAndDrop} from '../../Actions/ReadWithJeelAction';
import {savePdfBookDragAndDrop} from '../../Actions/PdfBookAction';
/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import UploadImage from '../S3Uploading/UploadImage';
import '../../css/AppCss/gamePreview.css'
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_GAMES, DRAG_DROP_DETAIL } from '../../routes';

class addDragDrop extends Component {


    constructor(props) {
        super(props);

        const { redirectionPage } = this.props;
        var parentId = redirectionPage.dragDropId

        this.state = {
            parentId: parentId,
            game_Image: "",
            cropedImageUrl: "",
            dragDropWidth: "",
            dragDropHeight: "",
            listElements: [],

            redirection: false,
            errorMessage: '',
            errorMessage2: ''


        }

        this.duplicateElement();

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }

    }


    duplicateElement() {
        let tempArr = this.state.listElements;
        let lastElement = tempArr[tempArr.length - 1];
        if (lastElement !== undefined && (lastElement.url.imageUrl === "" || lastElement.draggableWidth === "" || lastElement.dragDropHeight === "" || lastElement.xCoordinate === "" || lastElement.yCoordinate === "")) {
            alert("Please fill out all fields");
        }
        else {
            var element = {
                draggableWidth: "",
                draggableHeight: "",
                xCoordinate: "",
                yCoordinate: "",
                url: {
                    imageUrl: ""
                }
            };
            tempArr.push(element);
            this.setState({ listElements: tempArr });
        }

    }



    deleteElement(index) {
        console.log(index);
        let tempArr = this.state.listElements;
        console.log(tempArr);
        tempArr.splice(index, 1);
        this.setState({ listElements: tempArr });
        console.log(tempArr);
        alert('Element has been deleted successfully');
    }

    onChangeElement(e, index) {
        const isInteger = value => parseInt(value) == value

        let tempArr = this.state.listElements;
        let obj = tempArr[index];
        var element = {
            url: {
                imageUrl: obj.url.imageUrl
            },
            draggableWidth: obj.draggableWidth,
            draggableHeight: obj.draggableHeight,
            xCoordinate: obj.xCoordinate,
            yCoordinate: obj.yCoordinate
        };

        if (e.target.name.includes("imageUrl")) {
            element.imageUrl = e.target.value;
        }
        if (e.target.name.includes("draggableWidth")) {

            if (e.target.value == "" || isInteger(e.target.value)) {
                element.draggableWidth = e.target.value;
                this.setState({
                    errorMessage2: ""
                });
            } else {
                this.setState({ errorMessage2: "Fraction data not allowed" });
            }

        }
        if (e.target.name.includes("draggableHeight")) {
            if (e.target.value == "" || isInteger(e.target.value)) {
                element.draggableHeight = e.target.value;
                this.setState({
                    errorMessage2: ""
                });
            } else {
                this.setState({ errorMessage2: "Fraction data not allowed" });
            }
        }
        if (e.target.name.includes("xCoordinate")) {
            if (e.target.value == "" || isInteger(e.target.value)) {
                element.xCoordinate = e.target.value;
                this.setState({
                    errorMessage2: ""
                });
            } else {
                this.setState({ errorMessage2: "Fraction data not allowed" });
            }

        }
        if (e.target.name.includes("yCoordinate")) {
            if (e.target.value == "" || isInteger(e.target.value)) {
                element.yCoordinate = e.target.value;
                this.setState({
                    errorMessage2: ""
                });
            } else {
                this.setState({ errorMessage2: "Fraction data not allowed" });
            }

        }
        tempArr[index] = element;
        this.setState({ listElements: tempArr });
    }


    onChangeBasic(e) {
        const isInteger = value => parseInt(value) == value

        console.log(e.target.value)
        console.log(isInteger(e.target.value))

        if (e.target.value == "" || isInteger(e.target.value)) {
            this.setState({
                [e.target.name]: e.target.value,
                errorMessage: ""
            });
        } else {
            this.setState({ errorMessage: "Fraction data not allowed" });
        }
    }

    getGameImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ game_Image: imageUrl });
        }
    }

    getCropedGameImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ cropedImageUrl: imageUrl });
        }
    }



    getDragElementImageUrl(index, imageUrl) {
        let tempArr = this.state.listElements;
        let obj = tempArr[index];
        var element = {
            url: {
                imageUrl: imageUrl
            },
            draggableWidth: obj.draggableWidth,
            draggableHeight: obj.draggableHeight,
            xCoordinate: obj.xCoordinate,
            yCoordinate: obj.yCoordinate
        };
        tempArr[index] = element;
        this.setState({ listElements: tempArr });
    }

    addDragDrop(e) {
        e.preventDefault();
        const { redirectionPage } = this.props;
        console.log(redirectionPage.dragDropPageName)

        if (redirectionPage.dragDropPageName == DRAG_DROP_DETAIL) {
            saveDragAndDrop(this.state)
                .then(res => {
                    alert("Item added successfully.");
                    this.props.history.push(redirectionPage.dragDropPageName);
                })
                .catch(err => {
                    console.log(err.response.data);
                    alert(err.response.data.message);
                });
        }


        let arrayOfurl = redirectionPage.dragDropPageName.split('/');

        // content dragDdrop
        if (arrayOfurl.includes("ViewEpisode")) {
            saveEpisodeDragAndDrop(this.state)
                .then(res => {
                    alert("Item added successfully.");
                    this.props.history.push(redirectionPage.dragDropPageName);
                })
                .catch(err => {
                    console.log(err.response.data);
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("songDetails")) {
            saveSongDragAndDrop(this.state)
                .then(res => {
                    alert("Item added successfully.");
                    this.props.history.push(redirectionPage.dragDropPageName);
                })
                .catch(err => {
                    console.log(err.response.data);
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("storyDetails")) {
            saveStoryDragAndDrop(this.state)
                .then(res => {
                    alert("Item added successfully.");
                    this.props.history.push(redirectionPage.dragDropPageName);
                })
                .catch(err => {
                    console.log(err.response.data);
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("RadioChannelDetails")) {
            saveChannelDragAndDrop(this.state)
                .then(res => {
                    alert("Item added successfully.");
                    this.props.history.push(redirectionPage.dragDropPageName);
                })
                .catch(err => {
                    console.log(err.response.data);
                    alert(err.response.data.message);
                });
        }

        if (arrayOfurl.includes("BookDetails")) {
            saveBookDragAndDrop(this.state)
                .then(res => {
                    alert("Item added successfully.");
                    this.props.history.push(redirectionPage.dragDropPageName);
                })
                .catch(err => {
                    console.log(err.response.data);
                    alert(err.response.data.message);
                });
        }
        if (arrayOfurl.includes("PdfBookDetails")) {
            savePdfBookDragAndDrop(this.state)
                .then(res => {
                    alert("Item added successfully.");
                    this.props.history.push(redirectionPage.dragDropPageName);
                })
                .catch(err => {
                    console.log(err.response.data);
                    alert(err.response.data.message);
                });
        }
    }

    redirect = (e) => {

        this.setState({ redirection: true })
    }


    render() {

        let lastElement = this.state.listElements[this.state.listElements.length - 1];
        const InValid = this.state.dragDropWidth === "" || this.state.dragDropHeight === "" ||
            this.state.game_Image === "" || lastElement.draggableWidth === "" ||
            lastElement.draggableHeight === "" || lastElement.xCoordinate === "" ||
            lastElement.yCoordinate === "" || lastElement.url.imageUrl === "";


        if (this.state.redirection == true) {
            const { redirectionPage } = this.props;
            var pageName = redirectionPage.dragDropPageName
            console.log(redirectionPage.dragDropPageName)
            return (<Redirect to={pageName} ></Redirect>)
        }

        return (
            <div className="container">

                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />

                                            {/* <div className="col-12 left-main-body" id="left-main-body">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link to={ALL_GAMES}>Get All Games</Link><span> > </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link onClick={this.redirect} >
                                                            Drag & Drop Details
                                                             </Link>
                                                        <span> > </span>
                                                    </span>

                                                    <span>Add Drag & Drop</span>
                                                </div>
                                            </div> */}

                                            <div className="container">
                                                <h3 className="main-title mt-2 font-weight-bold">Add New Drag & Drop</h3>

                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">

                                                                <div className="col-4">
                                                                    <div>
                                                                        <span className="card-title" color="gray">Drag & Drop</span>
                                                                    </div>
                                                                    <div className="add-items icon-upload-itm mt-4">
                                                                        <label className="small-label"><span className="required_input">*</span>Image</label>
                                                                        <UploadImage
                                                                            getImageURLCallBack={this.getGameImageUrl.bind(this)}
                                                                            imageUrl={this.state.game_Image}
                                                                            imagepreview={this.state.game_Image}
                                                                            s3DirectryrName='dragdrop' />
                                                                        <div className="upload-hint x-small-font">This image size should be 200px * 200px</div>
                                                                    </div>

                                                                    <div className="add-items icon-upload-itm mt-4">
                                                                        <label className="small-label">Croped Image</label>
                                                                        <UploadImage
                                                                            getImageURLCallBack={this.getCropedGameImageUrl.bind(this)}
                                                                            imageUrl={this.state.cropedImageUrl}
                                                                            imagepreview={this.state.cropedImageUrl}
                                                                            s3DirectryrName='dragdrop' />
                                                                        <div className="upload-hint x-small-font">This image size should be 200px * 200px</div>
                                                                    </div>


                                                                    <p style={{ color: "red" }}>
                                                                        {this.state.errorMessage} </p>

                                                                    <div className="form-group">
                                                                        <label className="small-label"><span className="required_input">*</span> Drag & Drop Width</label>
                                                                        <input className="form-control" value={this.state.dragDropWidth} name="dragDropWidth" onChange={e => this.onChangeBasic(e)} min="1" type="number" />
                                                                    </div>

                                                                    <div className="form-group">
                                                                        <label className="small-label"><span className="required_input">*</span> Drag & Drop Height</label>
                                                                        <input className="form-control" value={this.state.dragDropHeight} name="dragDropHeight" onChange={e => this.onChangeBasic(e)} min="1" type="number" />
                                                                    </div>
                                                                </div>



                                                                <div className="col-8">

                                                                    {this.state.listElements !== null && this.state.listElements.map((dragElement, index) => {
                                                                        return (
                                                                            <div className="element mt-3" key={index}>
                                                                                <div><span className="card-title" color="gray">Drag & Drop Element</span></div>

                                                                                <br />

                                                                                <div className="col-6">
                                                                                    <div className="add-items icon-upload-itm">
                                                                                        <span className="required_input">*</span>
                                                                                        <UploadImage
                                                                                            getImageURLCallBack={this.getDragElementImageUrl.bind(this, index)}
                                                                                            imageUrl={dragElement.url.imageUrl}
                                                                                            imagepreview={dragElement.url.imageUrl}
                                                                                            s3DirectryrName='dragdrop' />
                                                                                        <div className="upload-hint x-small-font">This image size should be 200px * 200px</div>
                                                                                    </div>
                                                                                </div>

                                                                                <p style={{ color: "red" }}>
                                                                                    {this.state.errorMessage2} </p>

                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="small-label"><span className="required_input">*</span>Element Width</label>
                                                                                        <input className="form-control" min="1" type="number" value={dragElement.draggableWidth} name={"draggableWidth" + index} onChange={e => this.onChangeElement(e, index)} />
                                                                                    </div>

                                                                                    <div className="form-group">
                                                                                        <label className="small-label"><span className="required_input">*</span>Element Height</label>
                                                                                        <input className="form-control" min="1" type="number" value={dragElement.draggableHeight} name={"draggableHeight" + index} onChange={e => this.onChangeElement(e, index)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="small-label"><span className="required_input">*</span>Element X Cordinate</label>
                                                                                        <input className="form-control" min="1" type="number" value={dragElement.xCoordinate} name={"xCoordinate" + index} onChange={e => this.onChangeElement(e, index)} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-12">
                                                                                    <div className="form-group">
                                                                                        <label className="small-label"><span className="required_input">*</span>Element Y Cordinate</label>
                                                                                        <input className="form-control" min="1" type="number" value={dragElement.yCoordinate} name={"yCoordinate" + index} onChange={e => this.onChangeElement(e, index)} />
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    (this.state.listElements.length > 1 && index !== 0) &&
                                                                                    <span>
                                                                                        <div className="col-12 mt-4">
                                                                                            <button className="btn btn-outline-danger" onClick={e => this.deleteElement(index)}>Delete element</button>
                                                                                        </div>
                                                                                    </span>
                                                                                }

                                                                            </div>

                                                                        )
                                                                    })}


                                                                    <div className="col-12 mt-4">
                                                                        <button className="btn btn-dark btn-block" onClick={e => this.duplicateElement()}>Add another element</button>
                                                                    </div>

                                                                    <div className="groups-btn mt-5 editBTNs">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="float-right rest-floating">
                                                                                    <button className="btn btn-dark" onClick={this.redirect}>
                                                                                        Cancel
                                                                                        </button>
                                                                                    <button className="btn btn-dark colorBTN" disabled={InValid} onClick={e => this.addDragDrop(e)}>Save</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.dragDropitem
});
export default withFirebase(
    connect(
        mapStateToProps,
        {

        }
    )(addDragDrop));
