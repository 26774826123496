import { EMPTY_COUNTRY, SET_COUNTRY } from "./Types";

export const setCountry = (country) =>{
    return {
        type: SET_COUNTRY,
        payload: country,
    };
};

export const emptyCountry = () => dispatch => {
    dispatch({
        type: EMPTY_COUNTRY,
        payload: ''
    })
}