import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom';

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    deleteGameParent,
    fetchAllGameParent,
    emptyError
} from '../../Actions/GameParentAction'
import { ALL_GAME_PARENTS } from '../../routes';


class DeleteGameParent extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteGameParent(id) {
        this.props.deleteGameParent(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        this.deleteGameParent(id);
        this.close();

    }


    render() {
        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();

        }
        else if (success === true) {
            alert("Parent Game Deleted Successfully")
            this.props.emptyError();
            this.props.fetchAllGameParent();
            return (<Redirect to={ALL_GAME_PARENTS}></Redirect>)
        }

        return (
            <div >
                <button className="seriesCardButtons" onClick={this.open}>
                    <i>
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal"
                    content='Are you sure you want to delete this Parent Game ??'
                    open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>

        )
    }
}


DeleteGameParent.propTypes = {
    deleteGameParent: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    error: state.gameParent.deleting_game_parent_error,
    success: state.gameParent.deleting_game_parent_sucessfully
});

export default withFirebase(
    connect
        (mapStateToProps,
            {
                fetchAllGameParent,
                deleteGameParent,
                emptyError
            })
        (DeleteGameParent));
