import React from "react";
import Content_1 from "../../../images/portal/icons/content-1.svg";
import Content_2 from "../../../images/portal/icons/content-2.svg";
import Content_3 from "../../../images/portal/icons/content-3.svg";
import Content_4 from "../../../images/portal/icons/content-4.svg";
import Content_5 from "../../../images/portal/icons/content-5.svg";
import Content_6 from "../../../images/portal/icons/content-6.svg";
import Content_8 from "../../../images/portal/icons/content-8.svg";
import Content_9 from "../../../images/portal/icons/content-9.svg";
import Content_10 from "../../../images/portal/icons/content-10.svg";
import Content_11 from "../../../images/portal/icons/content-11.svg";
import Content_12 from "../../../images/portal/icons/content-12.svg";

export const Content = () => {
  const contentList = [
    {
      logo: Content_1,
    },
    {
      logo: Content_2,
    },
    {
      logo: Content_3,
    },
    {
      logo: Content_4,
    },
    {
      logo: Content_5,
    },
    {
      logo: Content_6,
    },

    {
      logo: Content_8,
    },
    {
      logo: Content_9,
    },
    {
      logo: Content_10,
    },
    {
      logo: Content_11,
    },
    {
      logo: Content_12,
    },
  ];
  return (
    <div className="portal-section jeel-content">


      <div className="jeel-content-wrapper">
        <div className="container">
          <div class="content-items d-flex">
            {contentList && contentList.length
              ? contentList.map((item, index) => {
                  return (
                    <div key={index} class="item">
                      <img src={item.logo} alt="logo" />
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
