import {
    CONTENT_VENDOR_FETCH_ALL, 
    CONTENT_VENDOR_LOAD_START,
    CONTENT_VENDOR_LOAD_ERROR,
    CONTENT_VENDOR_ADD_SUCCESSFULLY, 
    CONTENT_VENDOR_ADD_ERROR,
    CONTENT_VENDOR_UPDATE_SUCCESSFULLY, 
    CONTENT_VENDOR_UPDATE_ERROR,
    CONTENT_VENDOR_GET_DATA_SUCCESSFULLY,
    CONTENT_VENDOR_GET_DATA_ERROR,
    CONTENT_VENDOR_EMPTY

} from '../Actions/Types'


const initialState = {
    State_items: [],
    State_item: {},
    State_loading: '',

    State_Content_Vendor_Load_sucessfully: '',
    State_Content_Vendor_Load_Error: '',
    
    State_Content_Vendor_Get_sucessfully: '',
    State_Content_Vendor_Get_Error: '',
	
    State_Content_Vendor_Add_sucessfully: '',
    State_Content_Vendor_Add_Error: '',

    State_Content_Vendor_Update_sucessfully: '',
    State_Content_Vendor_Update_Error: '',

}

export default function (state = initialState, action) {
    switch (action.type) {

        case CONTENT_VENDOR_FETCH_ALL:
            return {
                ...state,
                State_items: action.payload,
                State_Content_Vendor_Load_Error: '',
                State_Content_Vendor_Load_sucessfully: true,
                State_loading: false,
                State_item: {}
            }
        case CONTENT_VENDOR_LOAD_START:
            return {
                ...state,
                State_loading: true
            }            
        case CONTENT_VENDOR_LOAD_ERROR:
            return {
                ...state,
                State_Content_Vendor_Load_Error: action.payload,
                State_loading: false
            }

            
        case CONTENT_VENDOR_GET_DATA_SUCCESSFULLY:
            return {
                ...state,
                State_item: action.payload,
                State_Content_Vendor_Get_sucessfully: true,
                State_Content_Vendor_Get_Error: '',
                State_loading: false
            }
            
        case CONTENT_VENDOR_GET_DATA_ERROR:
            return {
                ...state,
                State_Content_Vendor_Get_Error: action.payload,
                State_Content_Vendor_Get_sucessfully: false,
                State_loading: false
            }

        case CONTENT_VENDOR_ADD_SUCCESSFULLY:
            return {
                ...state,
                State_Content_Vendor_Add_sucessfully: true,
                State_loading: false
            }

        case CONTENT_VENDOR_ADD_ERROR:
            return {
                ...state,
                State_Content_Vendor_Add_sucessfully: false,
                State_Content_Vendor_Add_Error: action.payload,
                State_loading: false
            }

        case CONTENT_VENDOR_UPDATE_SUCCESSFULLY:
            return {
                ...state,
                State_Content_Vendor_Update_sucessfully: true,
                State_Content_Vendor_Update_Error:'',
                State_loading: false
            }

        case CONTENT_VENDOR_UPDATE_ERROR:
            return {
                ...state,
                State_Content_Vendor_Update_sucessfully: false,
                State_Content_Vendor_Update_Error: action.payload,
                State_loading: false
            }

        case CONTENT_VENDOR_EMPTY:
            return {
                ...state,
			
			    CONTENT_VENDOR_LOAD_START: '',
			    State_Content_Vendor_Load_Error: '',
			    
			    State_Content_Vendor_Get_sucessfully: '',
			    State_Content_Vendor_Get_Error: '',
				
			    State_Content_Vendor_Add_sucessfully: '',
			    State_Content_Vendor_Add_Error: '',
			
			    State_Content_Vendor_Update_sucessfully: '',
			    State_Content_Vendor_Update_Error: '',
			    
                State_item:{}
            }
        default:
            return state

    }
}