import {FETCH_QUESTIONS_CATEGORIES, QUESTION_CATEGORY_DELETED, ERROR_DELETING_QUESTION_CATEGORY, UPDATE_QUESTION_CATEGORY, ERROR_UPDATE_QUESTION_CATEGORY, SAVE_Question_CATEGORY, ERROR_ADDING_Question_CATEGORY, EMPTY_QUESTION_CATEGORY} from '../Actions/Types'

const initial_state={
    items:[],
    item:{},
    blog_category_updated_successfully:'',
    question_category_saved_successfully:'',
    error_updating_question_category:'',
    question_category_deleted_successfully:'',
    error_deleteing_question_category:'',
    error_adding_question_category:''
}
export default function (state=initial_state,action) {
    switch (action.type)
    {
        case FETCH_QUESTIONS_CATEGORIES:

            return {
                ...state,
                items:action.payload
            }
        
            case QUESTION_CATEGORY_DELETED:
                    return {
                        ...state,
                        question_category_deleted_successfully:true,
                        error_deleteing_question_category:'',
                        item:action.payload
                    }
                case ERROR_DELETING_QUESTION_CATEGORY:
                    return {
                        ...state,
                        question_category_deleted_successfully:false,
                        error_deleteing_question_category:action.payload
                    }

                    case UPDATE_QUESTION_CATEGORY:
                            return {
                                ...state,
                                item:action.payload,
                                question_category_updated_successfully:true
                            }
                        case ERROR_UPDATE_QUESTION_CATEGORY:
                            return {
                                ...state,
                                error_updating_question_category:action.payload,
                                question_category_updated_successfully:false
                            }
                            case SAVE_Question_CATEGORY:
                                    return{
                                        ...state,
                                        item:action.payload,
                                       question_category_saved_successfully:true
                                    }
                                case ERROR_ADDING_Question_CATEGORY:
                                    return{
                                        ...state,
                                        error_adding_question_category:action.payload,
                                        question_category_saved_successfully:false
                        
                                    }

                                    case EMPTY_QUESTION_CATEGORY:
                                            return {
                                                ...state,
                                                question_category_updated_successfully:'',
                                                error_loading_question_category:'',
                                                error_adding_question_category:'',
                                               question_category_saved_successfully:'',
                                                error_updating_question_category:'',
                                                error_deleteing_question_category:'',
                                                question_category_deleted_successfully:''
                                                
                                            }
        default:
            return state     
            
        

    }
    
}