import {
    FETCH_All_RADIO, GET_RADIO_DATA, LOAD_ALL_RADIO,
    ERROR_LOADING_RADIOES,
    ERROR_LOADING_RADIO, LOAD_RADIO,
    SAVE_RADIO, ERROR_ADDING_RADIO,
    ERROR_SETTING_STATE_RADIO,
    SETTING_STATE_RADIO,
    ERROR_DELETING_RADIO, DELETING_RADIO,
    ERROR_UPDATE_RADIO,
    UPDATE_RADIO, EMPTY_RADIO,
    EMPTY_RADIO_WITH_DATA,
    ADD_RADIO_WITH_SENTFROM
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},

    loading: '',
    error_loading_radio_parents: '',
    error_loading_radio_parent: '',

    error_adding_radio_parent: '',
    radio_parent_added_sucessfully: '',

    setting_state_radio_parent_error: "",
    setting_state_radio_parent_sucessfully: "",

    deleting_radio_parent_error: "",
    deleting_radio_parent_sucessfully: "",

    updating_radio_parent_sucessfully: "",
    updating_radio_parent_error: "",
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_RADIO_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }

        case FETCH_All_RADIO:

            return {
                ...state,
                items: action.payload,
                error_loading_radio_parents: '',
                loading: false
            }
        case GET_RADIO_DATA:

            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_radio_parent: ''
            }
        case SAVE_RADIO:
            return {
                ...state,
                item: action.payload,
                items: state.items.concat(action.payload),
                radio_parent_added_sucessfully: true
            }
        case EMPTY_RADIO:
            return {
                ...state,
                error_adding_radio_parent: action.payload,
                radio_parent_added_sucessfully: action.payload,

                setting_state_radio_parent_error: action.payload,
                setting_state_radio_parent_sucessfully: action.payload,

                deleting_radio_parent_sucessfully: action.payload,
                deleting_radio_parent_error: action.payload,

                updating_radio_parent_sucessfully: action.payload,
                updating_radio_parent_error: action.payload,

                loading: action.payload,

            }
        case EMPTY_RADIO_WITH_DATA:
            return {
                ...state,
                item: {}
            }

        case LOAD_ALL_RADIO:
            return {
                ...state,
                loading: true
            }
        case LOAD_RADIO:
            return {
                ...state,
                loading: true
            }

        case ERROR_LOADING_RADIOES:
            return {

                ...state,
                error_loading_radio_parents: action.payload,
                loading: false
            }

        case ERROR_LOADING_RADIO:
            return {
                ...state,
                error_loading_radio_parent: action.payload,
                loading: false
            }

        case ERROR_ADDING_RADIO:
            return {
                ...state,
                error_adding_radio_parent: action.payload,
                radio_parent_added_sucessfully: false
            }

        case SETTING_STATE_RADIO:
            return {
                ...state,
                item: action.payload,
                setting_state_radio_parent_sucessfully: true,
                loading: false
            }

        case ERROR_SETTING_STATE_RADIO:
            return {
                ...state,
                setting_state_radio_parent_error: action.payload,
                loading: false
            }

        case DELETING_RADIO:
            return {

                ...state,
                item: action.payload,
                deleting_radio_parent_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_RADIO:
            return {

                ...state,
                deleting_radio_parent_error: action.payload,
                loading: false
            }

        case UPDATE_RADIO:
            return {
                ...state,
                item: action.payload,
                updating_radio_parent_sucessfully: true,
                updating_radio_parent_error: '',
                loading: false
            }

        case ERROR_UPDATE_RADIO:
            return {
                ...state,
                updating_radio_parent_error: action.payload,
                loading: false,
                updating_radio_parent_sucessfully: false
            }


        default:
            return state

    }

}
