import React, { Component } from 'react'
import Loader from '../Layout/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom'
import DeleteIssue from './DeleteIssue'
/*Routes*/
import { ISSUE_TO_GET } from '../../routes';

class IssuesList extends Component {

    render() {
        console.log(this.props)
        const { issues, status, createdDate, issueState, user, error } = this.props
        let issueList = null
        let commentsNumber = 0;
        let userName = "";
        let userEmail = "";
        let image = "";
        let imageItems;

        var issuesFiltered = issues.filter((issue) => {


            if (user == '') {
                if (issue.createdByUid !== undefined
                    && issue.createdByUid !== null
                    && issue.createdByUid !== '') {
                    if (issue.createdByUid.name !== undefined
                        && issue.createdByUid.name !== null
                        && issue.createdByUid.name !== '') {
                        return issue.createdByUid.name.toLowerCase().startsWith(user.toLowerCase());
                    }
                    else {
                        return true;
                    }
                }
            } else if (user !== '' && user !== undefined) {
                if (issue.createdByUid !== undefined
                    && issue.createdByUid !== null
                    && issue.createdByUid !== '') {
                    if (issue.createdByUid.name !== undefined
                        && issue.createdByUid.name !== null
                        && issue.createdByUid.name !== '') {
                        return issue.createdByUid.name.toLowerCase().startsWith(user.toLowerCase());
                    }
                }
            }
        })

        var issuesFilteredStatus = issuesFiltered.filter((issue) => {

            if (status === '' || status === undefined || status === null) {
                return true;
            }

            if (status !== '' || status !== undefined) {
                return issue.status === status;
            }

        })
        var issueDateFilter = issuesFilteredStatus.filter((issue) => {
            if (createdDate === '' || createdDate === undefined || createdDate === null) {
                return true;
            }

            if (createdDate !== '' || createdDate !== undefined) {
                return issue.createdDate.toString().split(' ')[0] === createdDate.toString();
            }
        })


        if (issueState === true) {
            issueList = <Loader />;
        }
        else if (issueState === false && error === '') {
            issueList =
                issueDateFilter.map(issue => {
                    var issueCard = ""
                    if (issue.comments !== undefined) {
                        commentsNumber = issue.comments.length;
                    }
                    if (issue.createdByUid !== undefined) {
                        if (issue.createdByUid.name !== undefined
                            && issue.createdByUid.name !== null
                            && issue.createdByUid.name !== '') {

                            userName = issue.createdByUid.name
                        } else {
                            userName = "No Name"
                        }

                        if (issue.createdByUid.email !== undefined
                            && issue.createdByUid.email !== null
                            && issue.createdByUid.email !== '') {
                            userEmail = issue.createdByUid.email;
                        }
                        else {
                            userEmail = "No Email"
                        }
                    }
                    if (issue.image === null) {
                        image = <div></div>
                    }
                    else if (image !== null) {

                        image = (
                            <Zoom>
                                <img src={issue.image} alt="" height="90" width="90" style={{ margin: '0px 5px 0px 5px' }} />
                            </Zoom>)
                    }

                    if (issue.issueImages === null || issue.issueImages.length == 0) {
                        imageItems = <div></div>
                    }
                    else if (issue.issueImages !== null && issue.issueImages.length !== 0) {

                        imageItems = issue.issueImages.map(img => {
                            return (<Zoom>
                                <img src={img.imageUrl} alt="" height="90" width="90" style={{ margin: '0px 5px 0px 5px' }} />
                            </Zoom>)
                        })
                    }

                    issueCard = (<div className="card-body" key={issue.id}>
                        <div className="row">
                            <div className="col-6">
                                <div className="float-left">
                                    <Link to={ISSUE_TO_GET + '/' + issue.id}>
                                        <span className="h6 font-weight-bold">{issue.title}</span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6">
                                <DeleteIssue id={issue.id} type={issue.typeId.issueTypeText} />
                                <div className="float-right">
                                    <Link to={ISSUE_TO_GET + '/' + issue.id}>{commentsNumber}
                                        <FontAwesomeIcon icon='comments' className="fas fa-comments ml-1">
                                        </FontAwesomeIcon>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="float-left small-label">
                                    <span className="">{userName}</span>
                                    &nbsp;
                                    <span className="">,  &nbsp; {userEmail}</span>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="float-right small-label">
                                    <span>{issue.createdDate.split(' ')[0]}</span> <span>{issue.createdDate.split(' ')[1]}</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col">
                                <div className="cop-content">
                                    <div class="small-font font-weight-bold">
                                        {issue.descreption}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            {image}
                            {imageItems}
                        </div>
                    </div>)

                    if (issue.status == "waiting for replay") {
                        return (
                            <div className="card chat-box-highlighted">
                                {issueCard}
                            </div>
                        )
                    } else {

                        return (
                            <div className="card chat-box">
                                {issueCard}
                            </div>
                        )
                    }
                })
        }

        return (
            <div>
                {issueList}
            </div>
        )
    }
}
export default IssuesList;
