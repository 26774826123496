import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';

/* Assets */
import mainLogoM from '../../../images/portal/logo-big.png';
import succIcon from '../../../images/portal/succ-sign.svg';
import Loader from '../../../components/Layout/Loader';
import * as CONSTANTS from '../../../constants';

/* Actions */
import {
  verifyingCode,
  resendCode,
  emptyTpay
} from '../../../Actions/TpayActions';

/* Routes */
import { TPAY_SUCCESS } from '../../../routes';

const INITIAL_STATE = {
  code: '',
  code1: '',
  code2: '',
  code3: '',
  code4: '',
  code5: '',
  redirect: false,
  confirmResult: '',
  errorMsg: '',
  tpaySubContractId: '',
  tpayTransactionId: '',
  country: '',
  serviceName: CONSTANTS.JEEL_SERVICE_NAME,
  supportMail: CONSTANTS.SUPPORT_MAIL,
  telecomCompanyText: '',
  keyword: '',
  shortCode: '',
  price: 47,
  frequency: CONSTANTS.PORTAL_SUBSCRIPTION_MONTHLY,
  phone: ''
}

class TpayPhoneCode extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    let tpaySubContractId = this.props.match.params.tpaySubContractId;
    let tpayTransactionId = this.props.match.params.tpayTransactionId;
    let telecomCompany = this.props.match.params.telecomCompany;
    let phone = this.props.match.params.phone;

    this.setState({
      tpaySubContractId: tpaySubContractId,
      tpayTransactionId: tpayTransactionId,
      phone: phone,
      telecomCompanyText: CONSTANTS[telecomCompany],
      shortCode: CONSTANTS.TPAY_SHORT_CODES[telecomCompany],
      keyword: CONSTANTS.TPAY_KEYWORDS[telecomCompany],
    })
  }

  handleNext = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    let name = e.target.name;
    this.setState({ [e.target.name]: checkSpaces });
    setTimeout(() => {
      this.setState({
        code: this.state.code1
          + this.state.code2
          + this.state.code3
          + this.state.code4
          + this.state.code5
          + this.state.code6
      })
    }, 1000)
    if (name === 'code1')
      this.secondTextInput.focus();
    if (name === 'code2')
      this.thirdTextInput.focus();
    if (name === 'code3')
      this.forthTextInput.focus();
    if (name === 'code4')
      this.fifthTextInput.focus();
    if (name === 'code5')
      this.sixthTextInput.focus();
  }

  submit = (e) => {
    const {
      tpaySubContractId,
      tpayTransactionId,
      code } = this.state;

    if (this.state.tpaySubContractId !== '' && this.state.tpaySubContractId !== null
      && this.state.tpaySubContractId !== undefined && this.state.tpaySubContractId !== ' ' &&

      this.state.tpayTransactionId !== '' && this.state.tpayTransactionId !== null
      && this.state.tpayTransactionId !== undefined && this.state.tpayTransactionId !== ' ' &&

      this.state.code !== '' && this.state.code !== null
      && this.state.code !== undefined && this.state.code !== ' ') {

      this.props.verifyingCode(tpaySubContractId, code, tpayTransactionId);
    }
    else {
      this.setState({ errorMsg: "لقد حدث خطأ من فضلك حاول مره اخرى" })

    }

    e.preventDefault();

  }

  resend = (e) => {
    console.log("resend")
    const { tpaySubContractId } = this.state;

    if (this.state.tpaySubContractId !== '' && this.state.tpaySubContractId !== null
      && this.state.tpaySubContractId !== undefined && this.state.tpaySubContractId !== ' '
    ) {

      this.props.resendCode(tpaySubContractId);
    }
    else {
      this.setState({ errorMsg: "لم نتمكن من إرسال كود التفعيل، يرجى المحاولة مرة اخرى" })
    }

  }

  render() {

    const { tpay, loading,
      verifyingError, verifyingSuccess,
      resendCodeError, resendCodeSuccess, resendLoading } = this.props;

    let loader = null;

    if (loading === true) {
      loader = (<Loader />)
    }

    //let disclaimer = 'سوف تقوم بالاشتراك في خدمة ' + this.state.serviceName + ' مقابل ' + this.state.price + ' جنيه مصري ' + this.state.frequency + ' تجدد تلقائيا. ' + ' لالغاء الاشتراك، لعملاء ' + this.state.telecomCompanyText + ' أرسل ' + this.state.keyword + ' الى ' + this.state.shortCode + ' مجانا. ' + ' لأيّة استفسارات يرجي التواصل معنا على ' + this.state.supportMail + '. استهلاك الانترنت سوف يخصم من الباقة الخاصة بك.';
    let beforePriceDisclaimer = 'سوف تقوم بالاشتراك في خدمة ' + this.state.serviceName + ' مقابل ';
    let afterPriceDisclaimer = this.state.frequency + ' تجدد تلقائيا. ' + ' لالغاء الاشتراك، لعملاء ' + this.state.telecomCompanyText + ' أرسل ' + this.state.keyword + ' الى ' + this.state.shortCode + ' مجانا. ' + ' لأيّة استفسارات يرجي التواصل معنا على ' + this.state.supportMail + '. استهلاك الانترنت سوف يخصم من الباقة الخاصة بك.';

    let disclaimerSection = (
      <div style={{ textAlign: 'right' }}>
        <div className="text">
          <p>
            {beforePriceDisclaimer}
            <span style={{ color: 'red' }}>{this.state.price + ' جنيه مصري '}</span>
            {afterPriceDisclaimer}
          </p>

        </div>
      </div>
    );

    // verifying send code
    if (verifyingSuccess === true
      && tpay !== undefined
      && tpay !== null) {



      // verifying Api Returns success in json when operationStatusCode ==0 
      if (
        tpay.operationStatusCode == 0) {
        let redirect = "/TpaySuccess/" + this.state.phone;

        this.props.emptyTpay();
        return (<Redirect to={redirect}> </Redirect>);
      }
      // verifying Api Returns error in json when operationStatusCode !=0 
      else {
        let errorMsg = " لقد حدث خطأ  " + tpay.errorMessage

        if (tpay.responseCode === 302
          || tpay.paymentStatus === 11
          || tpay.errorMessage.includes('Pin Code is invalid.')) {
          errorMsg = 'عذرًا ، رمز التحقق غير صالح.'
        } else if (tpay.resendCode === 305
          || tpay.errorMessage.includes('have exceeded the number of attempts')
          || tpay.errorMessage.includes('have exceeded maximum invalid PIN trials attempt')) {
          errorMsg = 'عفوًا ، لقد تجاوزت عدد المحاولات ، يرجى المحاولة مرة أخرى في بضع دقائق.'
        }
        else if (tpay.errorMessage.includes('Subscription Contract Is Already Verified')) {
          errorMsg = 'عفوًا ، تم تفعيل هذه الباقه من قبل.'
        }
        else if (tpay.errorMessage.includes('is duplicate, wait for 2 minutes before issuing same request')) {
          errorMsg = 'عذرًا ، لم نتمكن من إكمال تسجيلك ، انتظر بضع دقائق وحاول مرة أخرى.'
        }

        // payment errors
        else if (tpay.paymentStatus === 4
          || tpay.errorMessage.includes('you don’t have enough Credit')) {
          errorMsg = 'عذرًا ، ليس لديك رصيد كافٍ.'
        } else if (tpay.paymentStatus === 16
          || tpay.errorMessage.includes('Pin Code duration has expired')) {
          errorMsg = 'عذرًا ، انتهت مدة رمز التحقق.'
        } else if (tpay.paymentStatus === 19
          || tpay.errorMessage.includes('connection has timed out please try again in few minutes')) {
          errorMsg = 'عذرًا ، انتهت مهلة الاتصال ، يرجى المحاولة مرة أخرى خلال بضع دقائق.'
        } else if (tpay.paymentStatus === 8
          || tpay.errorMessage.includes('mobile number is Inactive')) {
          errorMsg = 'عذرًا ، رقم الموبايل هذا غير نشط.'
        } else if (tpay.paymentStatus === 14
          || tpay.errorMessage.includes('cannot use comp line')) {
          errorMsg = 'عذرًا ،  لا يمكن استخدام خط شركات'
        }

        this.setState({ errorMsg: errorMsg });

        this.props.emptyTpay();

      }
    }
    // error in calling API
    else if (verifyingSuccess === false &&
      verifyingError !== '' &&
      verifyingError !== undefined
    ) {
      this.setState({ errorMsg: " لقد حدث خطأ " + verifyingError })
      this.props.emptyTpay();

    }

    // resend code
    if (resendCodeSuccess === true
      && tpay !== undefined
      && tpay !== null) {

      // verifying Api Returns success in json when operationStatusCode ==0 
      if (tpay.operationStatusCode == 0) {

        this.setState({ errorMsg: "تم ارسال الرمز بنجاح" })
        this.props.emptyTpay();

      }
      // verifying Api Returns error in json when operationStatusCode !=0 
      else {

        let errorMsg = " لقد حدث خطأ  " + tpay.errorMessage

        if (tpay.errorMessage.includes('Failed To Send Verfication SMS')) {
          errorMsg = ' عفوًا ، حدث خطأ اثناء ارسال الرمز.'
        }

        this.setState({ errorMsg: errorMsg });
        this.props.emptyTpay();
      }
    }
    // error in calling API
    else if (resendCodeSuccess === false &&
      resendCodeError !== '' &&
      resendCodeError !== undefined
    ) {
      this.setState({ errorMsg: " لقد حدث خطأ " + resendCodeError })
      this.props.emptyTpay();

    }

    let phoneSection = (
      <div className="card-content text-center">
        <p style={{ color: 'red', textAlign: 'center' }}> {this.state.errorMsg}</p>
        <div className="card-title h4" id="recaptcha-container">تم ارسال الرمز</div>
        <div className="code-icon">
          <img src={succIcon} alt="" />
        </div>
        <div className="description-text">
          <span>فضلًا، أدخل الرمز المكون من (6) أرقام، والمرسل في رسالة نصية إلى رقم </span>
          <span className="phone-number">{this.state.phone}</span>
        </div>
        <form className="code-form">
          <div className="form-group">
            <input type="text" className="form-control" maxlength="1" autoFocus
              name="code1" value={this.state.code1}
              onChange={this.handleNext}
              blurOnSubmit={false}
            />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" maxlength="1"
              name="code2" value={this.state.code2}
              ref={(input) => { this.secondTextInput = input; }}
              onChange={this.handleNext} />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" maxlength="1"
              name="code3" value={this.state.code3}
              ref={(input) => { this.thirdTextInput = input; }}
              onChange={this.handleNext} />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" maxlength="1"
              name="code4" value={this.state.code4}
              ref={(input) => { this.forthTextInput = input; }}
              onChange={this.handleNext} />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" maxlength="1"
              name="code5" value={this.state.code5}
              ref={(input) => { this.fifthTextInput = input; }}
              onChange={this.handleNext} />
          </div>
          <div className="form-group">
            <input type="text" className="form-control" maxlength="1"
              name="code6" value={this.state.code6}
              ref={(input) => { this.sixthTextInput = input; }}
              onChange={this.handleNext} />
          </div>
        </form>
        <div className="form-group custom-btn">
          <button type="submit" className="btn phone-btn" onClick={this.submit}>تأكيد الاشتراك</button>
        </div>
        {disclaimerSection}
      </div>
    )

    if (resendLoading === true) {
      phoneSection = (<Loader />)
    }

    return (
      <body>
        <div className="other-methods-container">

          <section className="jeel-fit">
            <div className="logo-container">
              <div className="container">
                <div className="logo">
                  <img src={mainLogoM} alt="logo" />
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-12 col-md-6">
                  <div className="center fit-about-text">
                    <div className="title h2 white ">
                      <center>
                        بعد الاشتراك ، كل ما عليك الآن هو
                      </center>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">

                <div className="col-12 col-md-6">
                  <div className="  main-progress-bar">
                    <ol className="progress-tracker">
                      <li className="step fitbg">
                        <span className="step-name small-font">
                          <span className="bold">
                            الاشتراك
                          </span>
                        </span>
                      </li>
                      <li className="step fit">
                        <span className="step-name small-font">
                          <span className="bold">
                            رمز التحقق
                          </span>
                        </span>
                      </li>
                      <li className="step fit">
                        <span className="step-name small-font">
                          <span className="notbold">
                            تحميل التطبيق
                          </span>
                        </span>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </section>

          {loader}
          <section className="dont-worry">
            <div className="container">
              <div className="dont-wory-card">
                {phoneSection}
              </div>
              <div className="resend-code">
                <span>لم تصلك رسالة التأكيد؟ </span>
                <span>
                  <button className="resend"
                    type="submit"
                    onClick={this.resend}>أعد إرسال الرمز</button>
                </span>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </body>
    )
  }
}

TpayPhoneCode.propTypes = {
  verifyingCode: propTypes.func.isRequired,
  resendCode: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
  tpay: state.tpay.item,
  loading: state.tpay.loading,

  verifyingSuccess: state.tpay.verifying_successfully,
  verifyingError: state.tpay.verifying_error,

  resendCodeSuccess: state.tpay.resend_code_successfully,
  resendCodeError: state.tpay.resend_code_error,
  resendLoading: state.tpay.resendLoading

});

export default withRouter
  (connect

    (mapStateToProps,
      {
        verifyingCode,
        resendCode,
        emptyTpay
      })
    (TpayPhoneCode)
  );
