import { LOAD_PREPARE_CHECKOUT, FETCH_PREPARE_CHECKOUT, ERROE_PREPARE_CHECKOUT,
     GET_PAYMENT_STATUS, LOAD_PAYMENT_STATUS, ERROR_PAYMENT_STATUS, EMPTY_ID, EMPTY_STATUS } from "../Actions/Types";

const initialState = {
    id: '',
    error:'',
    loading:'',
    result:'',
    errorPaymentStatus:'',
    loadingPaymentStatus:'',
    idSuccess:false,
}

export default function (state = initialState, action){
    switch(action.type){
        case FETCH_PREPARE_CHECKOUT:
            return{
                ...state,
                id: action.payload,
                loading:false,
                idSuccess:true,
            }
        case ERROE_PREPARE_CHECKOUT:
            return{
                ...state,
                error: action.payload,
                loading: false,
                idSuccess:false,
            }
        case LOAD_PREPARE_CHECKOUT:
            return{
                ...state,
                loading: true,
                idSuccess: false,
            }
        case GET_PAYMENT_STATUS:
            return{
                ...state,
                result: action.payload,
                loadingPaymentStatus: false,
            } 
        case LOAD_PAYMENT_STATUS:
            return{
                ...state,
                loadingPaymentStatus:true,
            }
        case ERROR_PAYMENT_STATUS:
            return{
                ...state,
                errorPaymentStatus: action.payload,
            }
        case EMPTY_ID:
            return{
                ...state,
                idSuccess: '',
            }
        case EMPTY_STATUS:
            return{
                ...state,
                result:'',
            }                   
        default:
            return state            
    }
}