import React, { Component } from 'react';
import { Confirm, Popup } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteSetting ,getAllSettingsWithoutVdocipher,emptySetting} from '../../Actions/SettingAction';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { SETTINGS } from '../../routes';

class DeleteSetting extends Component {
    state = { open: false, redirectToSettings: false };

    open = () => this.setState({ open: true });
    close = () => this.setState({ open: false });

    handleDelete = () => {
        const { id } = this.props;
        this.props.deleteSetting(id);
        this.close();
    };
    
    render() {
        const { id } = this.props;

        const message = `Are you sure you want to delete setting ${id}?`;
        const {setting_deleted_successfully,error_deleting_setting} = this.props;
        if (setting_deleted_successfully===false)
        {
            alert ("error deleting setting"+ error_deleting_setting);
            this.props.emptySetting();
        }
        else if (setting_deleted_successfully===true)
        {
            alert("setting deleted successfully");
            this.props.emptySetting();
            this.props.getAllSettingsWithoutVdocipher();
            return (<Redirect to={SETTINGS}></Redirect>)
        }
        return (
            <span>
                <Popup
                    trigger={
                        <a className="trash" onClick={this.open}>
                            <i><FontAwesomeIcon icon="trash" /></i>
                        </a>
                    }
                    content="Delete"
                    position="top center"
                />
                <Confirm
                    id="modal"
                    content={message}
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleDelete}
                />
            </span>
        );
    }
}

DeleteSetting.propTypes = {
    id: PropTypes.number.isRequired,
    deleteSetting: PropTypes.func.isRequired,
    getAllSettings: PropTypes.func.isRequired,
};

const mapStateToProps = state =>
({
    setting_deleted_successfully:state.settings.setting_deleted_successfully,
    error_deleting_setting:state.settings.error_deleting_setting,

})

export default connect(mapStateToProps, { deleteSetting , getAllSettingsWithoutVdocipher,emptySetting})(DeleteSetting);
