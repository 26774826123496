import {
    ADD_SUCCESS_MESSAGE, REMOVE_SUCCESS_MESSAGE
} from './Types';

export const addSuccessMessage = (message) => dispatch => {
  const successMessage = {
      message: message,
      show: true
  }
  dispatch({
      type: ADD_SUCCESS_MESSAGE,
      payload: successMessage
  })
}

export const removeSuccessMessage = () => dispatch => {
  const successMessage = {
      message: '',
      show: false,
  }
  dispatch({
      type: REMOVE_SUCCESS_MESSAGE,
      payload: successMessage
  })
}
