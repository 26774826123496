import {    
    MW_Bahrain_LOAD_VERIFYING_CODE,
    MW_Bahrain_VERIFYING_CODE_SUCCESS,
    MW_Bahrain_VERIFYING_CODE_ERROR,

    MW_Bahrain_LOAD_RESEND_CODE,
    MW_Bahrain_RESEND_CODE_SUCCESS,
    MW_Bahrain_RESEND_CODE_ERROR,

    MW_Bahrain_EMPTY    

} from '../../../src/Actions/Types'


const initialState = {
    item: {},
    loading: '',

    verifying_successfully: '',
    verifying_error: '',

    resend_code_error: '',
    resend_code_successfully: '',
    resendLoading:''

}

export default function (state = initialState, action) {
    switch (action.type) {


        case MW_Bahrain_LOAD_VERIFYING_CODE:
            return {
                ...state,
                loading: true
            }

        case MW_Bahrain_VERIFYING_CODE_SUCCESS:
//console.log('in Rducers: ');
//console.log('-(5): in reducer:actionPayload: '+JSON.stringify(action.payload,null,3));
//console.log('oldState: '+JSON.stringify(state,null,3));
        
            return {
                ...state,
                item: action.payload,
                verifying_successfully: true,
                verifying_error: '',
                loading: false

            }
        case MW_Bahrain_VERIFYING_CODE_ERROR:
            return {
                ...state,
                verifying_successfully: false,
                verifying_error: action.payload,
                loading: false
            }

        case MW_Bahrain_LOAD_RESEND_CODE:
            return {
                ...state,
                resendLoading: true
            }

        case MW_Bahrain_RESEND_CODE_SUCCESS:
            return {
                ...state,
                item: action.payload,
                resend_code_successfully: true,
                resend_code_error: '',
                resendLoading: false

            }
        case MW_Bahrain_RESEND_CODE_ERROR:
            return {
                ...state,
                resend_code_successfully: false,
                resend_code_error: action.payload,
                resendLoading: false
            }

        case MW_Bahrain_EMPTY:
            return {
                ...state,
                verifying_error: '',
                verifying_successfully: '',
                resend_code_error: '',
                resend_code_successfully: '',
                item: '',
                loading: false,
                resendLoading:false

            }

        default:
            return state
    }
}