import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

/* Redux */
import { connect } from 'react-redux';
import firebase from "firebase/app";
import { withFirebase } from '../../../config';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */

/* Assets */
import avatar from '../../../images/portal/avatars/dad-avatar.png';
import dadImage from "../../../images/portal/avatars/dad.png";
import momImage from "../../../images/portal/avatars/mom.png";

/** Actions */
import { customerRegister, empty, signOut } from '../../../Actions/AuthActions';
import { emptyCustomer, customerSignIn } from '../../../Actions/CustomerActions';
import { getUserData, emptyMobileUserProfileWithItem } from "../../../Actions/MobilePaymentProfileAction";
import { emptyPromoCode, emptyCodeFromRedux } from '../../../Actions/PromoCodesActions';
import { emptyPaymentPrice } from '../../../Actions/MobilePaymentPriceAction';
import { emptyToken } from '../../../Actions/loginActions';

/* Routers*/
import { PORTAL_LAND_PAGE, PORTAL_ACCOUNT_PROFILE, PORTAL_SUBSCRIPTION_HISTORY } from '../../../routes';

const INITIAL_STATE = {
  sideNav: false,
  logout: false,
  height: '100px',
}

class SideMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(token => {
          this.props.getUserData(token);
        });
    }
  }

  componentDidMount() {
    // import("../../../css/portal-style.css");
    const { auth } = this.props;
    if (!auth.uid) {
      return <Redirect to={PORTAL_LAND_PAGE}></Redirect>;
    }
    // console.log(window.document.getElementsByClassName("main-container")[0].clientHeight);
    // var h = window.document.getElementsByClassName("main-container")[0].clientHeight - 85;
    // h += "px";
    // this.setState({height: h});
    // console.log("sn " + h);
  }

  toggleSideNav = () => {
    let {sideNav} = this.state;
    sideNav = !sideNav;
    this.setState({sideNav})
    console.log(window.document.getElementsByClassName("main-container")[0].clientHeight);
    var h = window.document.getElementsByClassName("main-container")[0].clientHeight - 50;
    h += "px";
    this.setState({height: h});
    console.log("sn " + h);
  }

  handleLogout = () => {
    this.props.emptyMobileUserProfileWithItem();
    this.props.emptyPaymentPrice();
    this.props.emptyCustomer();
    this.props.empty();
    this.props.emptyToken();
    this.props.signOut();
  }

  render(){
    const { userPaymentProfile } = this.props;
    const { auth } = this.props;
    if (!auth.uid){
      return  (<Redirect to={PORTAL_LAND_PAGE}></Redirect>)
    }
    return (
      <div className="col-12 col-md-4">
          <button className={`toggle-side ${this.state.sideNav? 'active': ''}`}
                  onClick={this.toggleSideNav}
                  style={{marginBottom: '15px'}}>
              <div className="open-side" >
                  <Icon name="chevron left"/>
              </div>
              <div className="close-side">
                    <Icon name="chevron right"/>
              </div>
          </button>
          <section className={`side-nav ${this.state.sideNav? 'active': ''}`} style={{height: `${this.state.height}`}}>
              <div className="user-info">
                  <div className="user-image">
                      <img src={(userPaymentProfile.image) ? userPaymentProfile.image : (userPaymentProfile.gender === 'male') ? dadImage : momImage} alt=""/>
                  </div>
                  <div className="info">
                      <div className="user-name h5">
                          {userPaymentProfile.name}
                      </div>
                      <div className="user-email">
                          {userPaymentProfile.email}
                      </div>
                  </div>
              </div>
              <ul className="nav-content list-unstyled">
                  <li className="col-12 side-link" data-toggle-active = '.detail-content'>
                    <NavLink to={PORTAL_ACCOUNT_PROFILE} activeClassName="activeNav">
                      <span> بيانات الوالدين </span>
                    </NavLink>
                  </li>

                  <li className="col-12 side-link" data-toggle-active = '.plan-content'>
                    <NavLink to="/subscription" activeClassName="activeNav">
                      <span> الاشتراكات </span>
                    </NavLink>
                  </li>

                  <li className="col-12 side-link" data-toggle-active = '.history-content'>
                    <NavLink to={PORTAL_SUBSCRIPTION_HISTORY} activeClassName="activeNav">
                      <span> سِجل الباقات السابقة </span>
                    </NavLink>
                  </li>

                  <li className="col-12 side-link" data-toggle-active = '.history-content'>
                    <a onClick={this.handleLogout} activeClassName="activeNav">
                      <span> تسجيل خروج </span>
                    </a>
                  </li>
              </ul>
          </section>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  userPaymentProfile: state.userPaymentProfile.item
});

export default withRouter
              (connect(mapStateToProps,
                      { getUserData, signOut, emptyPaymentPrice, emptyToken,
                        emptyMobileUserProfileWithItem, emptyCustomer, empty,
                        emptyPromoCode, emptyCodeFromRedux})
              (SideMenu));
