import React, { Component } from 'react';
import logout from '../../images/logout.png';
import { signOut } from '../../Actions/AuthActions';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
/** Routes */
import { SIGN_IN } from '../../routes';
class OuterHeader extends Component {

    signOut = ()=>
    {
        this.props.signOut();
    }

    render() {
        const {auth} =this.props;
       // console.log(auth)
        if (!auth.uid)
        {
            return  (<Redirect to={SIGN_IN}></Redirect>)
        }
        return (<div className="container mb-2 mt-2">
            <div className="row">
                <div className="col-6">
                    <a className="language-btn logout-btn" onClick={this.signOut} >
                        <img className="logout" src={logout} alt="logout" />
                    </a>
                    <a className="language-btn small-font" href="#">ar</a>
                </div>
                <div className="col-6">
                    <div className="small-font last-edit text-right">
                        <span className="font-weight-bold">Last edits:</span>
                        <span className="font-italic">
                            <span className="date">4/April/2019</span> ,<span className="time"> 05:00 pm</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const mapStateToProps =(state) =>
({

    auth:state.firebase.auth

})

export default connect(mapStateToProps,{signOut})(OuterHeader);
