import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Loader from '../Layout/Loader';
import { getAllSettings , getAllSettingsWithoutVdocipher } from '../../Actions/SettingAction';
import { emptyError } from '../../Actions/Empty';
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import { Link } from 'react-router-dom';
import { ADD_SETTING, ADMIN_LAND_PAGE, EDIT_SETTING } from '../../routes';
import Footer from '../Layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from 'semantic-ui-react';
import DeleteSetting from './DeleteSetting';

class GetAllSettings extends Component {
    componentDidMount() {
        //this.props.getAllSettings();
        this.props.getAllSettingsWithoutVdocipher();
        this.props.emptyError();
    }

    render() {
        const { settings, loading } = this.props;

        const columns = [
            { Header: 'ID', accessor: 'id', width: 150, headerClassName: 'table-header' },
            { Header: 'Name', accessor: 'name', width: 150, headerClassName: 'table-header' },
            { Header: 'Value', accessor: 'value', width: 150, headerClassName: 'table-header' },
            {
                Header: 'Actions',
                width: 180,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Link className="link" to={{ pathname: EDIT_SETTING + "/" + props.original.id }}>
                                <Popup trigger={
                                    <a className="user-lock" style={{ marginRight: '8px' }}>
                                        <FontAwesomeIcon icon="edit" />
                                    </a>
                                }
                                content='Edit Setting'
                                position='top center'
                                />
                            </Link>
                            <DeleteSetting id={props.original.id} />
                        </div>
                    );
                }
            }
        ];

        if (loading) {
            return <Loader />;
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: '50px' }}>

                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link>
                                                    <span>  </span>
                                                </span>
                                                <span>Settings</span>
                                            </div>
                                            <br />
                                            <h5 className="main-title mt-2 font-weight-bold"><span>Settings</span></h5>
                                            <Link to={ADD_SETTING}>
                                                <button type="button" className="SongsButtons" >+ Add New Setting</button>
                                            </Link>
                                            <div className="container" style={{ width: '100%', display: 'flex', justifyContent: 'center' , marginTop: '50px'}}>
                                                <ReactTable
                                                    data={settings}
                                                    columns={columns}
                                                    defaultPageSize={10}
                                                    className="-striped -highlight"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

GetAllSettings.propTypes = {
    settings: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    settings: state.settings.items,
    loading: state.settings.loading
});

export default connect(mapStateToProps, { getAllSettingsWithoutVdocipher, emptyError })(GetAllSettings);
