import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Issues*/
import GetAllIssues from './GetAllIssues';
import ComplaintIssues from './ComplaintIssues';
import SuggestionIssues from './SuggestionIssues';
import IssueDetails from './IssueDetails';

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
import { emptyAllIssues } from '../../Actions/IssueAction';

/* Routes */
import { SIGN_IN, ALL_SERIES, ALL_ISSUES,
         GET_COMPLAINTS_ISSUES, GET_SUGGESTIONS_ISSUES,
         ISSUE_DETAIL } from '../../routes';

class IssueAuth extends Component {

  constructor(props) {
      super(props);

      this.props.emptyAllIssues();
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { userPaymentProfile } = this.props;
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    const { userPaymentProfile } = this.props;

    if(userPaymentProfile.user_role.role === "ContentPublisher"){
      return <Redirect to={ALL_SERIES}></Redirect>
    }
    return (
      <span>
      {/* Issues and Comments */}
        <Route path={ALL_ISSUES} component={GetAllIssues} />
        <Route path={GET_COMPLAINTS_ISSUES} component={ComplaintIssues} />
        <Route path={GET_SUGGESTIONS_ISSUES} component={SuggestionIssues} />
        <Route path={ISSUE_DETAIL} component={IssueDetails} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData,emptyAllIssues}
    )(IssueAuth)
);
