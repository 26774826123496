import axios from "axios";
import firebase from 'firebase/app'
import { FETCH_PREPARE_CHECKOUT, LOAD_PREPARE_CHECKOUT, ERROE_PREPARE_CHECKOUT, 
    GET_PAYMENT_STATUS, LOAD_PAYMENT_STATUS, ERROR_PAYMENT_STATUS, EMPTY_ID, EMPTY_STATUS } from "./Types";

export const prepareCheckout = (amount, hyperPayPaymentType)=>{
    return(dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_PREPARE_CHECKOUT,
                payload: true,
            })

            const url = "/HyperPay/prepareCheckout";
            const formData = new FormData();
            formData.append('Amount', amount);
            formData.append('HyperPay_payment_type',hyperPayPaymentType);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            
            axios.post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: FETCH_PREPARE_CHECKOUT,
                        payload: res.data.id
                    },
                )
            }).catch((Error) => {
                
                dispatch({
                    type: ERROE_PREPARE_CHECKOUT,
                    payload: Error.message
                })
            })



        })
    }
}

export const getPaymentStatus = (checkOutId, cashInRiyal, packageType, hyperPayType,uid, isPromoCodeWork)=>{
    return(dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_PAYMENT_STATUS,
                payload: true,
            })

            const url = "/HyperPay/getPaymentStatus";
            const formData = new FormData();
            formData.append('CheckOutId', checkOutId);
            formData.append('Cash_in_Riyal', cashInRiyal);
            formData.append('Payment_Package_type', packageType);
            formData.append('HyperPay_payment_type', hyperPayType);
            formData.append('promocodeUid',uid);
            formData.append('promocodeStatus',isPromoCodeWork);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            
            axios.post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_PAYMENT_STATUS,
                        payload: res.data.result.description,
                    },
                )
            }).catch((Error) => {
                
                dispatch({
                    type: ERROR_PAYMENT_STATUS,
                    payload: Error.message
                })
            })



        })
    }
}

export const emptyStatusMadaId = () => dispatch => {
    dispatch({
        type: EMPTY_ID,
        payload: ''
    })
}

export const emptyPaymentStatus = () => dispatch => {
    dispatch({
        type: EMPTY_STATUS,
        payload: ''
    })
}