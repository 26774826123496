import {
    LOAD_All_FAQ, FETCH_All_FAQ, ERROR_ALL_FAQ,
    LOAD_FAQ, ERROR_UPDATE_FAQ, UPDATE_FAQ,
    ERROR_ADDING_FAQ, ADD_FAQ , DELETING_FAQ, ERROR_DELETING_FAQ
} from './Types'
import axios, { post, put  } from 'axios'

export const fetchAllFAQ = () => dispatch => {

    dispatch({
        type: LOAD_All_FAQ,
        payload: 'loading'
    })

    axios.get('/FAQ/getAllFAQList')
        .then((res) => {
            dispatch({
                type: FETCH_All_FAQ,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_ALL_FAQ,
                payload: Error.message
            })
        })
}

export const updateFAQ = (FAQ_ID, question, answer) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_FAQ,
                payload: 'loading'
            })

            const url = "/FAQ/updateFAQ";
            const formData = new FormData();
            formData.append('FAQ_ID', FAQ_ID);
            formData.append('Question', question);
            formData.append('Answer', answer);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_FAQ,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_FAQ,
                    payload: Error.message
                })
            })
        })

    }
}

export const addFAQ = (question, answer, language) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_FAQ,
                payload: 'loading'
            })

            const url = "/FAQ/addFAQ";

            const formData = new FormData();
            formData.append('Question', question);
            formData.append('Answer', answer);
            formData.append('Language', language);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_FAQ,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_FAQ,
                    payload: Error.message
                })
            })
        })

    }
}

export const deleteFAQ = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_FAQ,
                payload: 'loading'
            })

            const url = "/FAQ/deleteFAQ";
            const formData = new FormData();
            formData.append('FAQ_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_FAQ,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_FAQ,
                    payload: Error.message
                })
            })
        })

    }
}