import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react';
import { withFirebase } from '../../config';
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from "../Layout/Footer";
import EditActivityComponent from '../Activity/EditActivityComponent'
/*CSS*/
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/AppCss/Episode/EpisodeButtons.css';
import { EDIT_RADIO_CHAN_EVAL, RADIO_CHAN_EVAL_TO_EDIT } from '../../routes';

export class EditActivitRadioChannel extends Component {
    
    
    render() {
        // sending activity ID to EditActivityComponent  
        let activityId = this.props.match.params.activityId;

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="main-progress-bar">
                                                <ol className="progress-tracker">
                                                    <li className="step active s2-active"><span className="step-name small-font">ِEdit Activity</span></li>
                                                    <li className="step "><span className="step-name small-font">Edit Evaluation</span></li>
                                                </ol>
                                            </div>
                                            <br />

                                            <EditActivityComponent activityId={activityId} />

                                            <div>
                                                <div className="buttons_container">
                                                    <Link to={{ pathname: RADIO_CHAN_EVAL_TO_EDIT
                                                    + "/" + activityId
                                                    + "/" +this.props.match.params.radioName
                                                    + "/" +this.props.match.params.radioId
                                                    + "/" +this.props.match.params.channelId
                                                     }}>


                                                        <Button icon labelPosition='right' id="nextButton" >
                                                            Next
                                                         <Icon name='right arrow' />
                                                        </Button>

                                                    </Link>

                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}


export default EditActivitRadioChannel
