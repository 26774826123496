import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Input, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';
import { phoneCodes } from '../../../components/ConstData/CountryListTranslated';

/* Assets */
import mainLogoM from '../../../images/portal/logo-big.png';

/* Actions */
import { addCustomerPhoneToRedux } from '../../../Actions/CustomerActions';
import { signInWithPhone } from '../../../Actions/AuthActions'

/* Routes */
import { PORTAL_LAND_PAGE, PORTAL_PHONE_CODE } from '../../../routes';

var parentPage ;

const INITIAL_STATE = {
  next: false,
  phone: '20',
  disabled: true,
  code: '',
}

class LoggedInByPhone extends Component {

  constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
  }

  componentDidMount(){
    // import("../../../css/portal-style.css").then(()=>{});
  }

  submit = (e) => {
      this.props.addCustomerPhoneToRedux("+" + this.state.phone);
      this.setState({next: true});
  }

  handleChange = (e) => {
    this.setState({ phone: e });
    setTimeout(() => {
      //this.setState({disabled: true})
      if(this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined && this.state.phone !== ' '){
        this.setState({disabled: false})
      }else{
        this.setState({disabled: true})
      }
      // if(!this.state.phone.startsWith('+')){
      //   this.setState({disabled: true, errorMsg: 'برجاء إضافة كود الدولة قبل الرقم'})
      // }else{
      //   this.setState({errorMsg: ''});
      // }
    }, 0);

  }

  render(){
   const { redirectionPage} = this.props;

      if(redirectionPage
        && redirectionPage.pageName
        && redirectionPage.pageName !== null
        && redirectionPage.pageName !==undefined){

        console.log("if")

        parentPage = redirectionPage.pageName;
      }else{
        console.log("else")
        parentPage = PORTAL_LAND_PAGE;
      }

    if(this.state.next === true){
      // verify that phone number
      return (<Redirect to={PORTAL_PHONE_CODE}> </Redirect>)
    }
    return(
      <body>
       <div className="other-methods-container">
        <div className="logo-container">
          <div className="container">
             <div className="logo">
               <img src={mainLogoM} alt="logo" />
             </div>
          </div>
       </div>

       <section className="redirect-content">
         <div className="container">
             <div className="redirect-btn">
                 <Link to={parentPage}>
                   <Icon name='right arrow' />
                   <span className="text"> العودة للصفحة الرئيسية</span>
                 </Link>
             </div>
         </div>
       </section>

       <section className="dont-worry">
        <div className="container">
             <div className="dont-wory-card">
                 <div className="card-content col-lg-12">
                     <div className="row">
                      <p style={{color: 'red', textAlign: 'right', marginRight: '15px'}}> {this.state.errorMsg} </p>
                     </div>
                     <div className="card-title h4 bold" style={{fontSize: '20px', fontWeight: 'bold'}}>رقم الهاتف</div>
                     <div className="description-text">من فضلك ادخل رقم الهاتف الذي قمت بتسجيل الدخول به مسبقاََ</div>
                     <form className="phone-form">
                        <PhoneInput
                          placeholder="رقم الهاتف"
                          name="phone"
                          value={this.state.phone}
                          onChange={this.handleChange} />
                     </form>
                     <div className="form-group custom-btn col-6 col-lg-6">
                         <button type="submit" className="btn phone-btn" disabled={this.state.disabled}
                                   onClick={this.submit}>التالي</button>
                     </div>
                 </div>
             </div>
         </div>
       </section>

       </div>
        <Footer />
      </body>
    )
  }
}

LoggedInByPhone.propTypes = {

}
const mapStateToProps = state => ({
  redirectionPage: state.redirectionPage.item,

});

export default withRouter
    (connect
        (mapStateToProps,{addCustomerPhoneToRedux, signInWithPhone})
        (LoggedInByPhone)
    );
