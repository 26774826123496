import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import mainLogo from "../../../images/portal/icons/logo.svg";
import { Modal, Transition } from "semantic-ui-react";

const INITIAL_STATE = {
  code: "",
  code1: "",
  code2: "",
  code3: "",
};

class OTPModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleNext = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, " ");
    let name = e.target.name;
    this.setState({ [e.target.name]: checkSpaces });
    setTimeout(() => {
      this.setState({
        code:
          this.state.code1 +
          this.state.code2 +
          this.state.code3 +
          this.state.code4,
      });
    }, 1000);
    if (name === "code1") this.secondTextInput.focus();
    if (name === "code2") this.thirdTextInput.focus();
    if (name === "code3") this.forthTextInput.focus();
  };

  render() {
    return (
      <div className="auth-modal-container">
        <Transition duration={500} visible={this.props.showOTP}>
          <Modal
            className="auth-modal login-modal show"
            closeOnDimmerClick={false}
            onClose={() => this.props.closeOTPModal()}
            open={this.props.showOTP}
          >
            <Modal.Header>
              <div className="d-flex align-items-center justify-content-between">
                <div className="modal-logo">
                  <img src={mainLogo} alt="" />
                </div>
                <button
                  type="button"
                  className="close m-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.props.closeOTPModal()}
                >
                  <FontAwesomeIcon icon={faTimes} className="faTimes" />
                </button>
              </div>
            </Modal.Header>
            <Modal.Content>
              <Modal.Actions>
                <form className="jeel-form otp-form">
                  <div className="d-flex align-items-center justify-content-center gap-5 mb-3">
                    <div className="otp-text">تم إرسال كود لرقم هاتفك </div>
                    <div className="otp-phone-number">010000001010 </div>
                  </div>

                  <div className="otp-container">
                    <div className="otp-label">ادخل الكود</div>
                    <div className="otp-group">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          maxlength="1"
                          autoFocus
                          name="code1"
                          value={this.state.code1}
                          onChange={this.handleNext}
                          blurOnSubmit={false}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          maxlength="1"
                          name="code2"
                          value={this.state.code2}
                          ref={(input) => {
                            this.secondTextInput = input;
                          }}
                          onChange={this.handleNext}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          maxlength="1"
                          name="code3"
                          value={this.state.code3}
                          ref={(input) => {
                            this.thirdTextInput = input;
                          }}
                          onChange={this.handleNext}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          maxlength="1"
                          name="code4"
                          value={this.state.code4}
                          ref={(input) => {
                            this.forthTextInput = input;
                          }}
                          onChange={this.handleNext}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="btn portal-main-btn jeel-form-submit mx-auto my-2"
                    >
                      الدخول
                    </button>
                  </div>
                </form>

                <div className="jeel-form-link d-flex align-items-center justify-content-center">
                  ليس لديك حساب؟
                  <button
                    type="button"
                    onClick={() => {
                      this.props.closeOTPModal();
                      this.props.openMainAuthModal();
                      // this.props.openSubAuthModal();
                    }}
                  >
                    انشاء حساب جديد
                  </button>
                </div>
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        </Transition>
      </div>
    );
  }
}
export default OTPModal;
