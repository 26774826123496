import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Label } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom'

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';

/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*component */
import Loader from '../Layout/Loader';
import AddSpecialCountryPrice from './AddSpecialCountryPrice';
import DeleteSpecialCountryPrice from './DeleteSpecialCountryPrice'
import EditSpecialCountryPrice from './EditSpecialCountryPrice'
/*Actions */
import {
    fetchAllPaymentPackage, emptyError,
    updateAllPaymentPackage
} from '../../Actions/MobilePaymentActions'
import { fetchAllSpecialCountriesPrice }
    from '../../Actions/MobileSpecialCountriesAction'
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE } from '../../routes';


const INITIAL_STATE = {
    weeklyOrinalPrice: '',
    weeklyDiscountPrice: '',

    monthlyOrinalPrice: '',
    monthlyDiscountPrice: '',

    yearlyOrinalPrice: '',
    yearlyDiscountPrice: ''
}


class PaymentPackages extends Component {

    constructor(props) {
        super(props)
        this.state = INITIAL_STATE
    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {

            this.props.fetchAllPaymentPackage();
            this.props.fetchAllSpecialCountriesPrice();
        }
    }

    componentWillReceiveProps(nextprop) {
        let mobilePaymentPackage = nextprop.mobilePaymentPackage;
        let paymentPackageList = '';

        console.log(mobilePaymentPackage)

        if (mobilePaymentPackage !== null && mobilePaymentPackage !== undefined) {
            console.log(mobilePaymentPackage)

            paymentPackageList = mobilePaymentPackage.length ? (
                mobilePaymentPackage.map(mobilePaymentItem => {

                    if (mobilePaymentItem.packageType
                        == 'Monthly') {
                        this.setState({

                            monthlyOrinalPrice: mobilePaymentItem.regularPrice,
                            monthlyDiscountPrice: mobilePaymentItem.normalPrice
                        })
                    }
                    else if (mobilePaymentItem.packageType
                        == 'Yearly') {
                        this.setState({

                            yearlyOrinalPrice: mobilePaymentItem.regularPrice,
                            yearlyDiscountPrice: mobilePaymentItem.normalPrice
                        })
                    }
                    else if (mobilePaymentItem.packageType
                        == 'Weekly') {
                        this.setState({

                            weeklyOrinalPrice: mobilePaymentItem.regularPrice,
                            weeklyDiscountPrice: mobilePaymentItem.normalPrice
                        })
                    }

                })) : (
                    <div className="center">No Payment Package </div>
                )
        }

    }

    handleChange = e => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    };

    handleSaveOriginalPrices = e => {

        const {weeklyDiscountPrice, weeklyOrinalPrice, monthlyDiscountPrice, monthlyOrinalPrice,
            yearlyDiscountPrice, yearlyOrinalPrice } = this.state;

        this.props.updateAllPaymentPackage(weeklyDiscountPrice, weeklyOrinalPrice, monthlyDiscountPrice, monthlyOrinalPrice, yearlyDiscountPrice, yearlyOrinalPrice);

        console.log("tttttt")
        e.preventDefault();
    }

    render() {


        const { updateError, updateSucess, loading } = this.props;


        if (updateSucess === true) {
            alert("Original Payment Package Prices Updated Successfully")
            this.props.emptyError();
        }

        if (updateError !== '' && updateError !== ""
            && updateError !== null && updateError !== undefined) {
            alert(updateError)
            this.props.emptyError();
        }

        let specialCountriesPriceList = ''
        const { specialCountriesPrice, allSpecialError,
            allSpecialSuccess, allSpecialState } = this.props;

        if (allSpecialState === true) {
            specialCountriesPriceList = (<Loader />)
        }
        if (allSpecialState === false &&
            allSpecialError === '') {
            specialCountriesPriceList = specialCountriesPrice.length ? (
                specialCountriesPrice.map(specialCountryPrice => {
                    var description = specialCountryPrice.description;
                    if (specialCountryPrice.description === 'null' ||
                        specialCountryPrice.description === "null" ||
                        specialCountryPrice.description === null ||
                        specialCountryPrice.description == undefined) {
                        description = 'Empty';
                    }
                    return (<div className="fields">

                        <div className="wide-field">
                            <Label> Country</Label>
                            <input dir="auto"
                                className="form-control"
                                type="text"
                                placeholder="Country"
                                value={specialCountryPrice.country}
                            />
                        </div>
                        <div className="wide-field">
                            <Label> Description</Label>
                            <input type="text"
                                dir="auto"
                                className="form-control"
                                placeholder="Description"
                                value={description}
                            />
                        </div>
                        <div className="package-price-field d-flex flex-column">
                        <div className='discount-price d-flex flex-row' style={{columnGap:'0.5em'}}>
                        <div >
                            <Label> Weekly Discount Price</Label>
                            <input type="number"
                                step="0.01"
                                min="0"
                                placeholder="Weekly Price"
                                value={specialCountryPrice.weekDiscountPrice}
                            />
                        </div>
                        <div >
                            <Label> Monthly Discount Price</Label>
                            <input type="number"
                                step="0.01"
                                min="0"
                                placeholder="Monthly Price"
                                value={specialCountryPrice.monthDiscountPrice}
                            />
                        </div>
                        <div >
                            <Label> Yearly Discount Price</Label>
                            <input type="number"
                                step="0.01"
                                min="0"
                                placeholder="Yearly Price"
                                value={specialCountryPrice.yearDiscountPrice}
                            />
                        </div>
</div>
                        <div className='basic-price d-flex flex-row' style={{columnGap:'0.5em'}}>
                        <div >
                            <Label>Weekly Basic Price</Label>
                            <input type="number"
                                step="0.01"
                                min="0"
                                placeholder="Weekly Price"
                                value={specialCountryPrice.weekBasicPrice}
                            />
                        </div>
                        <div >
                            <Label> Monthly Basic Price</Label>
                            <input type="number"
                                step="0.01"
                                min="0"
                                placeholder="Monthly Price"
                                value={specialCountryPrice.monthBasicPrice}
                            />
                        </div>
                        <div >
                            <Label> Yearly Basic Price</Label>
                            <input type="number"
                                step="0.01"
                                min="0"
                                placeholder="Yearly Price"
                                value={specialCountryPrice.yearBasicPrice}
                            />
                        </div>
                        </div>
                        </div>
                        <div style={{width:'15%', paddingLeft:'0.5em', paddingRight:'0.5em'}}>
                            <Label> icons</Label>
                            <div className="acor-btn-group w-100 d-flex">

                                <EditSpecialCountryPrice specialCountriesPriceItem={specialCountryPrice} />

                                <DeleteSpecialCountryPrice id={specialCountryPrice.id} />

                            </div>
                        </div>
                    </div>
                    )
                })
            )
                : (
                    <div className="center">No Series </div>
                )

        }

        let loadingOriginal = ''
        if (loading === true) {
            loadingOriginal = (<Loader />)
        }
        else {
            loadingOriginal = (
                <span>
                    <div className="ui form">
                    <h4 className="ui dividing header">Weekly</h4>
                        <div className="two fields">
                            <div className="field">
                                <Label> Original Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Week Original Price"
                                    name="weeklyOrinalPrice"
                                    onChange={this.handleChange}
                                    value={this.state.weeklyOrinalPrice} />
                            </div>
                            <div className="field">
                                <Label> Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="weeklyOrinalPrice Discount Price"
                                    name="weeklyDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.weeklyDiscountPrice} />
                            </div>
                        </div>

                        <h4 className="ui dividing header">Monthly</h4>
                        <div className="two fields">
                            <div className="field">
                                <Label> Original Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Month Original Price"
                                    name="monthlyOrinalPrice"
                                    onChange={this.handleChange}
                                    value={this.state.monthlyOrinalPrice} />
                            </div>
                            <div className="field">
                                <Label> Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Monthly Discount Price"
                                    name="monthlyDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.monthlyDiscountPrice} />
                            </div>
                        </div>

                        <h4 className="ui dividing header">Yearly</h4>

                        <div className="two fields">

                            <div className="field">
                                <Label> Original Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Yearly Original Price"
                                    name="yearlyOrinalPrice"
                                    onChange={this.handleChange}
                                    value={this.state.yearlyOrinalPrice} />
                            </div>
                            <div className="field">
                                <Label> Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Yearly Discount Price"
                                    name="yearlyDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.yearlyDiscountPrice} />
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={this.handleSaveOriginalPrices}
                        id='contentSettingbutton'>Save</Button>

                </span>
            )
        }


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">


                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    </span>
                                                    <span>PaymentPackages</span>
                                                </div>
                                                {/* <!--  end status bar --> */}


                                                <div className="container">
                                                    <span className="general-setting-form"
                                                    >
                                                        <fieldset className='fieldset'>
                                                            <legend className="legend">Original Payment Package Price</legend>

                                                            {loadingOriginal}

                                                        </fieldset>

                                                        <fieldset className='fieldset'>
                                                            <legend className="legend">Special Countries Payment Package Price</legend>

                                                            <div className="ui form">
                                                                {specialCountriesPriceList}
                                                            </div>


                                                            <AddSpecialCountryPrice />


                                                        </fieldset>

                                                    </span>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


PaymentPackages.propTypes = {
    fetchAllPaymentPackage: propTypes.func.isRequired,
    updateAllPaymentPackage: propTypes.func.isRequired,
    emptyError: propTypes.func.isRequired,
    fetchAllSpecialCountriesPrice: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    mobilePaymentPackage: state.mobilePaymentPackage.items,
    loading: state.mobilePaymentPackage.loading,
    error: state.mobilePaymentPackage.getting_all_payment_package_data_error,
    sucess: state.mobilePaymentPackage.getting_all_payment_package_data_sucessfully,

    updateError: state.mobilePaymentPackage.update_all_payment_package_data_error,
    updateSucess: state.mobilePaymentPackage.update_all_payment_package_data_sucessfully,

    specialCountriesPrice: state.specialCountriesPrice.items,
    allSpecialError: state.specialCountriesPrice.getting_all_special_countries_price_error,
    allSpecialSuccess: state.specialCountriesPrice.getting_all_special_countries_price_sucessfully,
    allSpecialState: state.specialCountriesPrice.loading,

    auth: state.firebase.auth,

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                fetchAllPaymentPackage,
                updateAllPaymentPackage,
                emptyError,
                fetchAllSpecialCountriesPrice
            }
        )
        (PaymentPackages)
    );