import React, { Component } from 'react'
import { Snackbar } from '@material-ui/core';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import mainLogoM from '../../../images/portal/logo-big@2x.png';

import succ from '../../../images/fit/succ-sign.png';

import { removeSuccessMessage } from '../../../Actions/SuccessMessagesActions';

/* Components */
import Footer from '../Layout/Footer';
import FitDownloadApps from '../Layout/FitDownloadApps'

import "../../../css/Fit.css";

import * as CONSTANTS from '../../../constants';

class MondiaSuccessComponent extends Component {

    state = {
        fitCompanyContent: "",
        phoneNumber: "",
        openSnakBar: false,

        vertical: 'bottom',
        horizontal: 'right',
        phone: '',
        type: '',
        service: '',
        companyContent: '',
        packageType:'الشهرية'
    }

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let phone = this.props.match.params.phone;
        
        this.setState({
            phone: phone,
            type: 'ty',
            service: 'ooo',
            companyContent:'',
        })
      
    }

    handleSuccessMessages = () => {
        this.props.removeSuccessMessage();
    }

    render() {
	
	//alert('MondiaSuccessssssssssssssss');
	
        const { vertical, horizontal, packageType } = this.state;
        const { successMessage } = this.props;

        return (
            <div>

                <Snackbar
                    autoHideDuration={6000} 
                    anchorOrigin={{ vertical, horizontal }}
                    open={successMessage.show}
                    onClose={this.handleSuccessMessages}
                    message={successMessage.message}
                    key={vertical + horizontal}
                />

                <section className="jeel-fit">
                    <div className="logo-container">
                        <div className="container">
                            <div className="logo">
                                <img src={mainLogoM} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-6">
                                <div className="center fit-about-text">
                                    <div className="title h2 white">
                                        <center>
                                            خطوة واحدة تفصلك عن عالم جيل الممتع!
                                            <br />
                                            <span className=" h5">
                                                حمِّل التطبيق على جهازك واستمتع بمزاياه الرائعة.
                                            </span>
                                        </center>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-6">
                                <div className="  main-progress-bar">
                                    <ol className="progress-tracker">
                                        <li className="step fitbg">
                                            <span className="step-name small-font">
                                                <span className="bold">
                                                    الاشتراك
                                                </span>
                                            </span>
                                        </li>
                                        <li className="step fit">
                                            <span className="step-name small-font">
                                                <span className="bold">
                                                    تحميل التطبيق
                                                </span>
                                            </span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


                <div className="container" >

                    <div className=" col-12 col-md-8" id="border">

                        <div className=" center img">
                            <img src={succ} alt="logo" />
                            <br /><br />

                            <strong>
                                شكرًا لك!
                            </strong>

                        </div>

                        <div className="row justify-content-md-center">
                            <div className=" col-12 col-md-8">

                                <p className="h5 text-rtl">
                                    أتممت كافة خطوات الاشتراك عبر رقم هاتفك في الباقة {packageType} لتطبيق جيل،
                                    وستصلك رسالة بتأكيد الاشتراك على هاتفك،
                                    وستُجدد الباقة تلقائيًا بعد انتهائها.
                                </p>
                            </div>
                        </div>

                        <br />

                    </div>
                </div>

                <div className="container" >
                    <div className=" col-12 col-md-8" id="border">

                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-8 ">

                                <p className="h5 text-rtl margin">
                                    رقم الهاتف الذي اشتركت به
                                </p>
                                <br />
                                <div className="phoneNum" id="phone">
                                    <PhoneInput
                                        name="phone"
                                        value={this.state.phone}
                                        disabled />
                                </div>
                                <br />

                                <p className="cancel small-font font-weight-bold text-rtl">
                                    {this.state.companyContent}
                                </p>
                                <br />
                                <br />
                            </div>

                        </div>
                    </div>


                </div>

                <div className="container" >
                    <div className=" col-12 col-md-8" id="border"
                    >

                        {/** download section */}
                        <FitDownloadApps />
                        {/** End of download section*/}
                    </div>
                </div>





                {/** Start Footer */}
                <Footer />
                {/** End Footer */}

            </div>
        )
    }
}

const mapStateToProps = state => ({

    successMessage: state.successMessages.item,
});


export default withRouter
    (connect
        (mapStateToProps,
            {
                removeSuccessMessage,
            })
        (MondiaSuccessComponent)
    );

