import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
// Import the plugin code
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css";

// Import the plugin code
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
// Import the plugin code
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
// Import the plugin code
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
// Import the plugin code
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPreSignData,
  getPreSingUrlvdoCipher,
  getvdoCipherStatus,
  resetVdocipherData,
} from "../../Actions/VideoCipherActions";
import "../../css/main-style.css"

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview,
  FilePondPluginFileValidateSize,
  FilePondPluginImageTransform,
  FilePondPluginImageResize
);

function IntroWithoutMusicUploader({ id, onUploadInfo,folderName }) {
  const introWithoutMusic = useSelector(
    (state) => state.vdoCipher.introWithoutMusic
  );
  const [uploadInfo, setUploadInfo] = useState({});
  const [videoStatus, setVideoStatus] = useState("");
  const [isLoaded, setIsLoaded] = useState({});
  const [isLoading , setIsLoading] = useState("");
  const [loadingBar , setLoadingBar] = useState(false);

  const dispatch = useDispatch();
  const reset = () => {
    dispatch(resetVdocipherData(id));
    setVideoStatus("");
    setUploadInfo({});
    setIsLoaded({});
    setIsLoading("");
    setLoadingBar(false);
  }
  useEffect(() => {
    reset();
  }, []);
  useEffect(() => {
    if (introWithoutMusic) {
      setUploadInfo(introWithoutMusic.uploadInfo);
      setVideoStatus(introWithoutMusic.status);
      setIsLoaded(introWithoutMusic.isLoaded);
    }
  }, [introWithoutMusic]);

  
  
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (uploadInfo) {
        if (uploadInfo.videoId && uploadInfo.videoId !== null && uploadInfo.videoId !== undefined && uploadInfo.videoId !== "") {
          dispatch(getvdoCipherStatus(uploadInfo.videoId, id));
        }
      }
    }, 1000);
    if(videoStatus !== "Queued" && videoStatus !== "ready"){
      setIsLoading("")
      setLoadingBar(false);
    }
if (videoStatus === "Queued" || videoStatus === "Processing"){
setIsLoading("Video has been uploaded and is being encoded and encrypted")
setLoadingBar(true);
}
    if (videoStatus === "ready") {
      onUploadInfo(uploadInfo);
      setIsLoading("Video is ready for playback")
      setLoadingBar(false);
      clearInterval(intervalId);
    }
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [videoStatus,uploadInfo]);
  
  const handleDelete = () => {
    const isConfirmed = window.confirm('The video will no longer be available, and all associated data will be permanently removed.\nAre you sure you want to delete this video?');
    if (isConfirmed) {
      
          dispatch(clearPreSignData(uploadInfo.videoId,id));
        reset();
    } else {
      
      return;
    }
  };
  return (
    <div>
      {isLoading && isLoading !== "" && 
      <div>
      <div className={isLoading === "Video is ready for playback" ? "font-weight-bold my-2 text-success": "font-weight-bold my-2"}>{isLoading}</div>
      {loadingBar &&   <div className="loader-line"></div>}
      </div>
      }  
      <FilePond
        className="filepond"
        allowMultiple={false}
        imageResizeTargetWidth={100}
        imageResizeTargetHeight={100}
        imageResizeMode={"cover"}
        imageTransformOutputQuality={94}
        imageTransformOutputQualityMode="optional"
        onaddfile={(error, file) => {
          dispatch(getPreSingUrlvdoCipher(file.filename, id,folderName));
        }}

        beforeRemoveFile={handleDelete}

        oninit={() => {
        reset();
        }}
        
        maxFiles={1}
        instantUpload={false}
        allowImagePreview={true}
        allowVideoPreview={true}
        allowAudioPreview={true}
        stylePanelLayout={"compact"}
        server={{
          url:
            isLoaded &&
            uploadInfo &&
            uploadInfo.clientPayload &&
            uploadInfo.clientPayload.uploadLink,
          process(
            fieldName,
            file,
            metadata,
            load,
            error,
            progress,
            abort,
            transfer,
            options
          ) {
            const xhr = new XMLHttpRequest();
            xhr.open(
              "POST",
              isLoaded &&
                uploadInfo &&
                uploadInfo.clientPayload &&
                uploadInfo.clientPayload.uploadLink
            );
            const formData = new FormData();
            if (isLoaded) {
              formData.append(
                "x-amz-credential",
                uploadInfo.clientPayload["x-amz-credential"]
              );
              formData.append(
                "x-amz-algorithm",
                uploadInfo.clientPayload["x-amz-algorithm"]
              );
              formData.append(
                "x-amz-date",
                uploadInfo.clientPayload["x-amz-date"]
              );
              formData.append(
                "x-amz-signature",
                uploadInfo.clientPayload["x-amz-signature"]
              );
              formData.append("key", uploadInfo.clientPayload.key);
              formData.append("policy", uploadInfo.clientPayload.policy);
              formData.append("success_action_status", "201");
              formData.append("success_action_redirect", "");
              formData.append("file", file);
            }

            xhr.upload.onprogress = (event) => {
              progress(event.lengthComputable, event.loaded, event.total);
            };
            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 201) {
                  load(xhr.responseText);
                } else {
                  console.error(xhr.responseText, xhr.response, file);
                }
              }
            };
            xhr.send(formData);
            // Should expose an abort method so the request can be cancelled
            return {
              abort() {
                // This function is entered if the user has tapped the cancel button
                xhr.abort();

                // Let FilePond know the request has been cancelled
                abort();
              },
            };
          },
        }}
        acceptedFileTypes={["video/*"]}
        name="files"
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
}
export default IntroWithoutMusicUploader;
