import { FETCH_All_ADMINS_ROLE, LOAD_ADMINS_ROLE, ERROR_All_ADMINS_ROLE} from './Types'
import axios from 'axios'

export const fetchAllAdminsRole = () => dispatch => {

    dispatch({
        type: LOAD_ADMINS_ROLE,
        payload: 'loading'
    })

    axios.get('/UserRole/findAllAdminsRole')
        .then((res) => {
            dispatch({
                type: FETCH_All_ADMINS_ROLE,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_All_ADMINS_ROLE,
                payload: Error.message
            })
        })
}

/* Used in admin panel when admin can register anyone but not with the same role as he is */
export const fetchAdminRoles = (token) => dispatch => {
        dispatch({
            type: LOAD_ADMINS_ROLE,
            payload: 'loading'
        })

          const url = "/UserRole/findAdminsRoles"
          const config = {
              headers: {
                  'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                  'X-Authorization-Firebase': token
              }
          }
          axios.get(url, config).then((res) => {
              dispatch(
                  {
                      type: FETCH_All_ADMINS_ROLE,
                      payload: res.data
                  }
              )
          }).catch((Error) => {
              dispatch({
                  type: ERROR_All_ADMINS_ROLE,
                  payload: Error.message
              })
          })
}
