import {ADD_STORY_VIDEOS,PUBLISH_VIDEO,ERROR_ADDING_VIDEO,EMPTY_STORY_VIDEOS} from '../Actions/Types'

const initialState = {
    items:[],
    item:{},
    error_adding_video:''
}

export default function (state=initialState,action)
{
    switch(action.type)
    {
        case ADD_STORY_VIDEOS:
            return {
                ...state,
                items:action.payload
            }
        case PUBLISH_VIDEO:
            return{
                ...state,
                item:action.payload
            }
        case EMPTY_STORY_VIDEOS:
            return{
                ...state,
                items:{}
            }
        case ERROR_ADDING_VIDEO:
            return{
                ...state,
                error_adding_video:action.payload
            }
        default:
            return state
    }
}