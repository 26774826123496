import React, { Component } from "react";
import { Link } from "react-router-dom";
import mainLogo from "../../../images/portal/icons/logo.svg";
import Subscribe from "./Subscribe";

class JeelSubscription extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
            <div className="d-flex align-items-center">
              <Link className="navbar-brand d-flex align-items-center" to="/">
                <img src={mainLogo} alt="" />
              </Link>
              <div className="already-subscribed d-none d-sm-block">
                انت مشترك بالفعل جيل
              </div>
            </div>
            <Link className="btn back-btn" type="button" to="/">
              العوده
            </Link>
          </div>
          <div className="subscribed-card d-flex justify-content-between align-items-center">
            <div className="text-start">
              <div className="subscribed-label">انت مشترك في</div>
              <div className="subscribed-title">الباقة السنوية</div>
            </div>
            <div className="text-start">
              <div className="subscribed-label">تنتهي في</div>
              <div className="subscribed-date">2-5-2024</div>
            </div>
          </div>
          <Subscribe showRenewal={true} />
        </div>
      </>
    );
  }
}

export default JeelSubscription;
