import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withFirebase } from '../../config';
import placeHolder from '../../images/placeholder.png';
/*Redux*/
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { getAllPaintings, savePainting, deletePainting } from '../../Actions/GameActions';
/*Headers*/
import Loader from '../Layout/Loader';
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import UploadImage from '../S3Uploading/UploadImage';
import '../../css/AppCss/gamePreview.css'

import paintingIcon from '../../images/paintingShape.png';
import '../../css/AppCss/gamePreview.css';

/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_GAMES } from '../../routes';

const INITIAL_STATE = {
    game_Image: ""
}


class paintingDetails extends Component {


    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        this.state = {
            gamePainiting: null
        }

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {


        }

    }

    componentDidMount() {
        this.getPaintingData();
    }

    getPaintingData() {
        getAllPaintings()
            .then(res => {
                this.setState({ gamePainiting: res.data });
            })
            .catch(err => {
                alert(err.response.data.message);
            });
    }

    handleDeleteImage(e, id) {
        deletePainting(id)
            .then(res => {
                alert("Painting Item Deleted Successfully");
                this.getPaintingData();
            })
            .catch(err => {
                alert(err.response.data.message);
            });
    }


    getGameImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ game_Image: imageUrl });

            savePainting(imageUrl)
                .then(res => {
                    alert("Painting Item Added Successfully");
                    this.getPaintingData();
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }
        setTimeout(() => {
            this.setState({ game_Image: "" });
        }, 10);
    }



    render() {


        if (this.state.gamePainiting === null) {
            return (
                <div className="container">
                    <OuterHeader />
                    <div className="container">
                        <div className="container-body">
                            <div className="row">

                                <Dashboard />
                                <div className="col">
                                    <InnerHeader />
                                    <div className="main-content">
                                        <div className="row">
                                            <div className="col-12 left-main-body" id="left-main-body">
                                                <NavBar />
                                                <br />
                                                <Loader />


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            );
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />


                                            <div className="col-12 left-main-body" id="left-main-body">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link to={ALL_GAMES}>Get All Games</Link><span> {'>'} </span>
                                                    </span>
                                                    <span>Painting</span>
                                                </div>

                                            </div>

                                            <div className="container">

                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col ">
                                                                    <img className="rounded" src={paintingIcon} alt="" height="65" width="65" />
                                                                </div>
                                                                <div className="col-10">

                                                                    <div>
                                                                        <span className="card-title" color="gray">Painting</span>
                                                                    </div>
                                                                    <span className="font-italic small-font">
                                                                        <hr />
                                                                        <div className="row">
                                                                            {this.state.gamePainiting !== null && this.state.gamePainiting.map((game, index) => {
                                                                                return (
                                                                                    <div className="col-4 gamePreview">
                                                                                        <span onClick={e => this.handleDeleteImage(e, game.id)}>
                                                                                            <FontAwesomeIcon icon="times"
                                                                                                color="#dc6d6d"
                                                                                                className="true" />
                                                                                        </span>
                                                                                        <img class="image_Preview" alt={game.imageUrl} src={game.imageUrl} />
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            {this.state.gamePainiting !== null && this.state.gamePainiting.length < 1 && <div className="col-12">No games</div>}
                                                                            <div className={"col-4"}>
                                                                                <UploadImage
                                                                                    getImageURLCallBack={this.getGameImageUrl.bind(this)}
                                                                                    imageUrl={this.state.game_Image}
                                                                                    imagepreview={this.state.game_Image}
                                                                                    s3DirectryrName='paintinggame' />
                                                                            </div>

                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.item
});
export default withFirebase(connect(mapStateToProps, {})(paintingDetails));
