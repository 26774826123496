import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react';
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from "../Layout/Footer";
import EditEvaluation from '../Evaluation/EditEvaluation'
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import porpTypes from 'prop-types';
import { withFirebase } from '../../config';
/*CSS*/
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/AppCss/Episode/EpisodeButtons.css';
/*Actions */
import { emptyEvaluationCategory } from '../../Actions/EvaluationCategoryAction';
/* Routes */
import { GET_EPISODE_TO_VIEW } from '../../routes';

const INITIAL_STATE = {
    redirection: false,
}

class EditEvaluationEpisode extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    handleFinish = (e) => {
        this.props.emptyEvaluationCategory();
    }

    render() {
        // sending activity ID to EditEvaluationComponent
        let activityId = this.props.match.params.activityId;

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="main-progress-bar">
                                                <ol className="progress-tracker">
                                                    <li className="step active s2-active"><span className="step-name small-font">ِEdit Activity</span></li>
                                                    <li className="step active "><span className="step-name small-font">Edit Evaluation</span></li>
                                                </ol>
                                            </div>
                                            <br />

                                            <EditEvaluation activityId={activityId} />

                                            <div>
                                                <div className="buttons_container">

                                                    {/* <Link to="/EpisodeEvaluation"> */}

                                                    {/* <Button icon labelPosition='right' id="nextButton" >
                                                        Next
                                                         <Icon name='right arrow' />
                                                    </Button> */}

                                                    {/* </Link> */}

                                                    <Link className="link"
                                                        to={{
                                                            pathname: GET_EPISODE_TO_VIEW
                                                                + "/" + this.props.match.params.seriesName
                                                                + "/" + this.props.match.params.seriesId
                                                                + "/" + this.props.match.params.episodeId

                                                        }} >
                                                        <Button id="episode_cancel"
                                                            className="float-right"
                                                            onClick={this.handleFinish.bind(this)}>
                                                            Finish
                                                            </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

EditEvaluationEpisode.porpTypes = {
    emptyEvaluationCategory: porpTypes.func.isRequired,

}
const mapStateToProps = state => ({
    redirectionPage: state.redirectionPage.item

});
export default withFirebase
    (connect
        (mapStateToProps,
            {
                emptyEvaluationCategory
            }
        )
        (EditEvaluationEpisode)
    );
