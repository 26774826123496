import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import { Popup } from 'semantic-ui-react'


/**Actions */
import {
    fetchAllInAppNotification,
    emptyError
} from '../../Actions/InAppNotificationAction'
/*Components */
import Loader from '../Layout/Loader';
import DeleteInAppNotification from './DeleteInAppNotification'
/*CSS */
import 'react-table/react-table.css';
/*Routes*/
import { EDIT_IN_APP_NOTIFICATION, GET_IN_APP_NOTIFICATION_TO_EDIT } from '../../routes';

const FoldableTable = FoldableTableHOC(ReactTable);

class InAppNotificationTable extends Component {

    constructor(props) {
        super(props);

        this.props.fetchAllInAppNotification();
    }

    componentDidMount() {
        this.props.fetchAllInAppNotification();
    }

    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        const content = row[id];
        if (typeof content !== 'undefined') {
            // filter by text in the table or if it's a object, filter by key
            if (typeof content === 'object' && content !== null && content.key) {
                return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
            } else {
                return String(content).toLowerCase().includes(filter.value.toLowerCase());
            }
        }
    
        return true;
    };

    render() {

        const { inAppNotification } = this.props;


        const columns = [

            {
                Header: "ID",
                accessor: "id",
                width: 50,
                MinWidth: 50,
                Maxwidth: 130,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (

                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_IN_APP_NOTIFICATION_TO_EDIT + "/" + props.original.id }}>

                                {props.original.id}
                            </Link>
                        </div>
                    )
                }

            },
            {
                Header: "Text",
                accessor: "text",
                width: 180,
                MinWidth: 180,
                Maxwidth: 180,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    let text = props.original.text;
                    if (props.original.text == "null") {
                        text = ''
                    }

                    return (
                        <div style={{ "textAlign": "center" }}>
                            {text}
                        </div>
                    )
                }

            },
            {
                Header: "Start Date",
                id: "startDate",
                accessor: c=> new Date(c.startDate),
                width: 150,
                filterable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                          { props.original.startDate}
                        </div>
                    )
                }

            },
            {
                Header: "End Date",
                id: "endDate",
                accessor: c=> new Date(c.endDate),
                width: 180,
                filterable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>

                            {props.original.endDate}
                        </div>
                    )
                }

            },
            {
                Header: "Actions",
                width: 180,
                filterable: false,
                headerClassName: 'table-header',
                resizable: false,
                //foldable: true,
                Cell: props => {
                    return (
                        <div style={{ textAlign: "center" }}>

                            <Link className="link"
                                to={{ pathname: GET_IN_APP_NOTIFICATION_TO_EDIT + "/" + props.original.id }}>

                                <Popup trigger={
                                    <a className="user-lock" onClick={this.open}>
                                        <i>  <FontAwesomeIcon icon="edit" />
                                        </i>
                                    </a>}
                                    content='Edit In App Notification'
                                    position='top center'
                                />
                            </Link>

                            <DeleteInAppNotification
                                id={props.original.id} />


                        </div>
                    )
                }
            }
        ]


        const { error, success, loadingAll,
            deleteSuccess, deleteError, loading } = this.props;

        let inAppNotificationList = null


        if (deleteSuccess === false) {
            alert("error missing data or network " + deleteError)
            this.props.emptyError();
        }
        else if (deleteSuccess === true) {
            this.props.emptyError();
            alert("In App Notification Deleted Successfully")
            this.props.fetchAllInAppNotification();
        }



        if (loadingAll === true) {
            inAppNotificationList = (<Loader />)
        }
        else if (success === true && error === '') {

            return (
                <div>
                    <ReactTable

                        className=" table-striped custm-table"
                        columns={columns}
                        data={inAppNotification}
                        filterable
                        defaultPageSize={10}
                        defaultFilterMethod={this.filterCaseInsensitive}

                    >

                    </ReactTable>
                </div>)
        }


        return (
            <div>

                <div className="mt-4 responsive-table">
                    {inAppNotificationList}
                </div>
            </div>
        )
    }
}


InAppNotificationTable.propTypes = {
    fetchAllInAppNotification: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    inAppNotification: state.inAppNotification.items,
    loadingAll: state.inAppNotification.loadingAllInAppNotification,
    error: state.inAppNotification.error_all_loading_inAppNotification,
    success: state.inAppNotification.getting_all_inAppNotification_sucessfully,
    userPaymentProfile: state.userPaymentProfile.item,


    loading: state.inAppNotification.loading,
    deleteError: state.inAppNotification.delete_inAppNotification_error,
    deleteSuccess: state.inAppNotification.delete_inAppNotification_successfully,

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                fetchAllInAppNotification,
                emptyError
            })
        (InAppNotificationTable)
    );