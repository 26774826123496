import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Input, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { withFirebase } from '../../../config';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';

/* Assets */
import mainLogoM from '../../../images/portal/logo-big.png';

/* Routes */
import { PORTAL_LAND_PAGE, PORTAL_PAYMENT_METHODS } from '../../../routes';

/* Actions */
import { changeCustomerSignInMethod, empty } from '../../../Actions/AuthActions';
import { exchangeData } from '../../../Actions/RedirectionPagesActions'

var parentPage;
const INITIAL_STATE = {
  disabled: true,
  email: '',
  password: ''
}

class ChangeRegistration extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // import("../../../css/portal-style.css").then(()=>{});

  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces });
    setTimeout(() => {
      this.setState({ error: false, errorMsg: '' })
      if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
        this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ') {
        this.setState({ disabled: false })
      } else {
        this.setState({ disabled: true })
      }
    }, 0);
  }

  submit = () => {
    this.props.changeCustomerSignInMethod(this.state.email, this.state.password);
  }

  render() {
    const { authUser, loginsuccess,
      loginerror, loginloading,
      redirectionPage } = this.props;

    if (redirectionPage
      && redirectionPage.pageName
      && redirectionPage.pageName !== null
      && redirectionPage.pageName !== undefined) {

      console.log("if")

      parentPage = redirectionPage.pageName;
    } else {
      console.log("else")
      parentPage = PORTAL_LAND_PAGE;
    }

    if (loginsuccess === true) {

      // redirect to FitLogin to acrive package
      if (redirectionPage
        && redirectionPage.pageName
        && redirectionPage.pageName !== null
        && redirectionPage.pageName !== undefined) {

        parentPage = redirectionPage.pageName;

        this.props.exchangeData(this.state.email, this.state.password, redirectionPage.series_Id);

        this.props.empty();

        return (<Redirect to={parentPage}> </Redirect>)
      } else {
        // in case of success redirect to the new form to update register with the new method
        this.props.empty();
        return (<Redirect to={PORTAL_PAYMENT_METHODS}> </Redirect>)
      }

    }
    if (loginsuccess === false && (loginerror !== '' && loginerror !== false && loginerror !== undefined)) {
      this.setState({ errorMsg: loginerror })
      this.props.empty();
    }
    return (
      <body>
        <div className="other-methods-container">
          <div className="logo-container">
            <div className="container">
              <div className="logo">
                <img src={mainLogoM} alt="logo" />
              </div>
            </div>
          </div>

          <section className="redirect-content">
            <div className="container">
              <div className="redirect-btn">
                <Link to={parentPage}>
                  <Icon name='right arrow' />
                  <span className="text"> العودة للصفحة الرئيسية</span>
                </Link>
              </div>
            </div>
          </section>

          <section className="dont-worry">
            <div className="container">
              <div className="dont-wory-card">
                <div className="card-content">
                  <p style={{ color: 'red', textAlign: 'center' }}> {this.state.errorMsg}</p>

                  <div className="card-title h4" style={{ fontWeight: 'bold' }}>تغيير طريقة التسجيل</div>
                  <div className="description-text">لتحسين تجربة المستخدم للتطبيق، قمنا بتحديث طرق التسجيل. لذا يرجي تغيير طريقة التسجيل من رقم الهاتف إلي واحدة من الطرق الأخري</div>
                  <form className="change-registeration">
                    <div className="form-group">
                      <input type="text" className="form-control" name="email" value={this.state.email}
                        onChange={this.handleChange} placeholder="البريد الإلكتروني" />
                    </div>
                    <div className="form-group">
                      <div className="input-container">
                        <input type="password" className="form-control" name="password" value={this.state.password}
                          onChange={this.handleChange} placeholder="كلمة المرور" />
                      </div>
                    </div>
                  </form>
                  <div className="form-group custom-btn">
                    <button type="submit" className="btn phone-btn" disabled={this.state.disabled}
                      onClick={this.submit}>تاكيد
                         </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </body>
    )
  }
}

ChangeRegistration.propTypes = {

}
const mapStateToProps = state => ({
  authUser: state.authUser.item,
  loginsuccess: state.authUser.success,
  loginerror: state.authUser.errorMsg,
  loginloading: state.authUser.loading,
  redirectionPage: state.redirectionPage.item,

});

export default withRouter
  (connect
    (mapStateToProps, {
      changeCustomerSignInMethod, empty ,
      exchangeData})
    (ChangeRegistration)
  );
