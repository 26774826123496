import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { DateInput } from 'semantic-ui-calendar-react';
import { Dropdown, TextArea, Input, Button, Icon, Label, Modal } from 'semantic-ui-react';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*component */
import Loader from '../Layout/Loader';
import country_list from '../ConstData/PaymentAndVendorCountriesList'
/*Actions */
import { getAllCouponTypes } from '../../Actions/CouponTypesActions';
import { getAllDiscountTypes } from '../../Actions/DiscountTypesActions';
import { fetchAllPaymentPackage } from '../../Actions/MobilePaymentActions';
import { getAllPaymentPayingMethods } from '../../Actions/PaymentMethodsActions';
import { editPromoCode, emptyPromoCode, getPromoCodeItem} from '../../Actions/PromoCodesActions'
/* Routes */
import { ADMIN_LAND_PAGE, PROMO_CODES } from '../../routes';

const INITIAL_STATE = {
  uid: '',
  back: false,
  status: 'ACTIVE',
  name: '',
  text: '',
  max_users: 1,
  max_uses: 1,
  discount_amount: 1,
  coupon_type_text: "NEW_USERS",
  discount_type_text: "PERCENTAGE",
  start_date: '',
  end_date: '',
  payment_packages_ids: [],
  payment_methods_ids: [],
  country_names: [],
  country: '',
  registered_on: '',
  registered_months: 0,
  subscribed_months: 0,
  disableSubscribtion: true,

  error: false,
  errorMessage: '',
}

function FormatDate(date) {
  if(date == null){
    return '';
  }
  date = new Date(date);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  //January is 0!
  var yyyy = date.getFullYear();
  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  date = yyyy + '/' + mm + '/' + dd;
  return date;
}

// The reason to build this function is the names of countries at database are UpperCase
// and we need the first letter only to be capitalize 
// example UNITED ARAB EMIRATES -> United Arab Emirates
function capitalizeFirstLetter(country) {
  const words = country.split(' ');
  const capitalizedWords = words.map((word) =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return capitalizedWords.join(' ');
}

class EditPromoCode extends Component {
  constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
  }

  handleBack = (e) => {
      this.setState({ back: true });
  }

  componentDidMount() {
    this.props.emptyPromoCode();
    let codeUid = this.props.match.params.codeUid;
    this.setState({uid: codeUid});
    this.props.getPromoCodeItem(codeUid);
    const {promocode} = this.props;
    const packages = promocode.paymentPackages;
    let packagesId = [];
    for(var i = 0 ; i < packages.length; i++){
      packagesId.push(packages[i].id);
    }
    const methods = promocode.paymentMethods;
    let methodsId = [];
    for(var i = 0 ; i < methods.length; i++){
      methodsId.push(methods[i].id);
    }
    const countries = promocode.countryCurrencies;
    let countryNames = [];
    for(var i = 0; i < countries.length; i++){
      countryNames.push(capitalizeFirstLetter(countries[i].country));
    }
    if(promocode.country  ===  null){
      this.setState({country: ''});
    }else{
      this.setState({country: promocode.country})
    }
    if(promocode.couponType.type === "NEW_USERS"){
      this.setState({disableSubscribtion: true});
    }else{
      this.setState({disableSubscribtion: false});
    }
    this.setState({
      name: promocode.name,
      text: promocode.text,
      status: promocode.status,
      discount_amount: promocode.discountAmount,
      discount_type_text: promocode.discountType.type,
      coupon_type_text: promocode.couponType.type,
      max_users: promocode.maxUsage,
      max_uses: promocode.maxUsagePerUser,
    //  country: promocode.country,
      start_date: FormatDate(promocode.startDate),
      end_date: FormatDate(promocode.endDate),
      payment_packages_ids: packagesId,
      payment_methods_ids: methodsId,
      country_names: countryNames,
      registered_on: FormatDate(promocode.registeredOn),
    })
    this.props.getAllCouponTypes();
    this.props.getAllDiscountTypes();
    this.props.fetchAllPaymentPackage();
    this.props.getAllPaymentPayingMethods();
  }

  handleChange = (e) => {
    console.log([e.target.name])
    this.setState({ [e.target.name]: e.target.value });
    setTimeout(() => {
      if(this.state.name !== '' && this.state.name !== null && this.state.name !== undefined && this.state.name !== ' ' &&
         this.state.text !== '' && this.state.text !== null && this.state.text !== undefined && this.state.text !== ' ' &&
         this.state.start_date !== '' && this.state.start_date !== undefined && this.state.start_date !== null){
        this.setState({error: false, errorMessage: ''})
      }else{
        this.setState({error: true, errorMessage: 'Please Fill the Required Data'})
      }
    }, 0);
  }

  handleStatusChange = (e, {value} ) => {
    this.setState({ status: value })
  }

  handleCouponTypeChange = (e,{value}) => {
    const type = value;
    this.setState({ coupon_type_text: type})
    if(type === 'NEW_USERS'){
      this.setState({disableSubscribtion: true, subscribed_months: 0});
    }else{
      this.setState({disableSubscribtion: false});
    }
  }

  handleDiscountTypeChange = (e,{value}) => {
    this.setState({ discount_type_text: value})
  }

  handlePaymentPackageChange = (e, {value}) => {
    const id = value;
    var ids = this.state.payment_packages_ids;
    ids.push(id);
    this.setState({ payment_packages_ids: id});
  }

  handlePaymentMethodChange = (e, {value}) => {
    const id = value;
    var ids = this.state.payment_methods_ids;
    ids.push(id);
    this.setState({ payment_methods_ids: id});
  }

  handleDateTimeChange = (e, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      let attrName = name;
      let attrValue = value;
      var date = new Date(attrValue);
      if (date != "Invalid Date") {
        var utcString = date.toUTCString();
        var gmtDate = new Date(attrValue.split('GMT')[0]);
        this.setState({ [attrName]: FormatDate(gmtDate) });
      }
    }
    setTimeout(() => {
      if(this.state.name !== '' && this.state.name !== null && this.state.name !== undefined && this.state.name !== ' ' &&
         this.state.text !== '' && this.state.text !== null && this.state.text !== undefined && this.state.text !== ' ' &&
         this.state.start_date !== '' && this.state.start_date !== undefined && this.state.start_date !== null){
        this.setState({error: false, errorMessage: ''})
      }else{
        this.setState({error: true, errorMessage: 'Please Fill the Required Data'})
      }
    }, 0);
  }

  handleSubmit = () => {
    let obj = this.state;
    // NGMA-2317
    if(this.state.coupon_type_text === "NEW_USERS" && this.state.max_uses > 1){
      this.setState({error: true, errorMessage: 'New Users can\'t use promo codes more than once'});
    }else{
      this.props.editPromoCode(obj);
    }
  }

  handleCountryChange = (e, {value}) => {
    const id = value;
    var ids = this.state.country_names;
    ids.push(id);
    this.setState({ country_names: id});
  }

  render() {
    const {success, couponTypes,
           discountSuccess, discountTypes,
          paymentPackagesSuccess, paymentPackages,
          paymentMethodsSuccess, paymentMethods,
          promocodesuccess, promocoderror, promocodeload,
          promocode, codeItemerror, codeItemsuccess} = this.props;

    let couponTypesList = [];
    let discountTypesList = [];
    let paymentPackagesList = [];
    let paymentMethodsList = [];
    if(success === true){
      for(var i = 0 ; i < couponTypes.length; i++){
        couponTypesList.push({
              key: couponTypes[i].type,
              text: couponTypes[i].type,
              value: couponTypes[i].type,
        });
      }
    }
    if(discountSuccess === true){
      for(var i = 0 ; i < discountTypes.length; i++){
        discountTypesList.push({
              key: discountTypes[i].type,
              text: discountTypes[i].type,
              value: discountTypes[i].type,
        });
      }
    }
    if(paymentPackagesSuccess === true){
      for(var i = 0 ; i < paymentPackages.length; i++){
        paymentPackagesList.push({
              key: paymentPackages[i].id,
              text: paymentPackages[i].packageType,
              value: paymentPackages[i].id,
        });
      }
    }
    if(paymentMethodsSuccess === true){
      for(var i = 0 ; i < paymentMethods.length; i++){
        paymentMethodsList.push({
              key: paymentMethods[i].id,
              text: paymentMethods[i].name,
              value: paymentMethods[i].id,
        });
      }
    }
    if(this.state.back === true){
      return (<Redirect to={PROMO_CODES} ></Redirect>)
    }
    if(promocodesuccess === true){
      alert("Code has been updated Successfully")
      this.props.emptyPromoCode();
      return (<Redirect to={PROMO_CODES}></Redirect>)
    }else if(promocoderror !== '' && promocodesuccess !== true){
      alert(promocoderror)
      this.props.emptyPromoCode();
    }

    const statusList = [];
    statusList.push({
          text: 'ACTIVE',
          value: 'ACTIVE'
        },{
          text: 'INACTIVATE',
          value: 'INACTIVE'
        }
    )

    let $buttonUpdateLoader = (<Button disabled={this.state.error} icon style={{ width: "120px" }}
                                      onClick={this.handleSubmit}
                                      id="nextButton"  >
                                Update
                               </Button>);
    if(promocodeload === true){
      $buttonUpdateLoader = (<button id="nextButton"
                                     class="ui loading button">
                                Loading
                             </button>)
    }

    return (
        <div className="container">
            <OuterHeader />
            <div className="container">
                <div className="container-body">
                    <div className="row">
                        <Dashboard />
                        <div className="col">
                            <InnerHeader />
                            <div className="main-content">
                                <div className="row">

                                    <div className="col-12 left-main-body" id="left-main-body">
                                        <div className="box-container">

                                            {/* <!--  start status bar --> */}
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                </span>
                                                <span className="status-link">
                                                    <Link to={PROMO_CODES}>Promo Codes</Link><span> > </span>
                                                </span>

                                                <span>Edit Promo Code</span>
                                            </div>

                                            <h5 className="main-title mt-2 font-weight-bold">Edit New Promo Code</h5>
                                            <span style = {{fontSize: '12px', color: 'red'}}>{this.state.errorMessage}</span>
                                            <div className="card mt-3 p-0">
                                                <div className="card-body p-4">

                                                    <div className="row">


                                                        <div className="col-6">
                                                            <form >
                                                                <label className="label"> <span style={{color: 'red', fontSize: '20px'}}>*</span>Code Name</label><br />
                                                                <Input  fluid type = "text" name="name" id="code_name"
                                                                        value = {this.state.name}
                                                                        onChange= {this.handleChange}/>
                                                                <br />

                                                                <label htmlFor="code_number" className="label"> <span style={{color: 'red', fontSize: '20px'}}>*</span>Code Description</label><br />
                                                                <Input  fluid type="text" name="text" id="code_text"
                                                                        value = {this.state.text}
                                                                        onChange= {this.handleChange}/>
                                                                <br />

                                                                <label htmlFor="code_status" className="label"> <span style={{color: 'red', fontSize: '20px'}}>*</span>Code Status</label><br />
                                                                <Dropdown fluid selection placeholder='Select Status' name="status" id="code_status"
                                                                          value= {this.state.status} onChange={this.handleStatusChange}
                                                                          options={statusList} />
                                                                <br />

                                                                <label htmlFor="code_coupon_type" className="label"> <span style={{color: 'red', fontSize: '20px'}}>*</span>Code Coupon Type</label><br />
                                                                <Dropdown fluid selection placeholder='Select Coupon Type' name="coupon_type" id="coupon_type"
                                                                          value= {this.state.coupon_type_text} onChange={this.handleCouponTypeChange}
                                                                          options={couponTypesList} />
                                                                <br />

                                                                 <label class="label input-group">Code Country</label>
                                                                 <Dropdown multiple fluid selection placeholder='Select Country' name="country_names" id="country_names"
                                                                      value= {this.state.country_names} onChange={this.handleCountryChange}
                                                                      options={country_list} />
                                                                 <br />

                                                                <label htmlFor="code_max_usage" className="label"> <span style={{color: 'red', fontSize: '20px'}}>*</span>Code Max Users</label><br />
                                                                <Input  fluid type="number"  min="1" name="max_users" id="code_max_usage"
                                                                        value = {this.state.max_users}
                                                                        onChange= {this.handleChange}/>
                                                                <br />

                                                                <label htmlFor="code_max_usage_per_user" className="label"> <span style={{color: 'red', fontSize: '20px'}}>*</span>Code Max Usage Per User</label><br />
                                                                <Input  fluid type="number" min="1" name="max_uses" id="code_max_usage_per_user"
                                                                        value = {this.state.max_uses}
                                                                        onChange= {this.handleChange}/>
                                                                <br />

                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label htmlFor="code_discount_amount" className="label input-group"> <span style={{color: 'red', fontSize: '20px'}}>*</span> Code Discount Amount</label>
                                                                        <Input  fluid type="number" min= "1" name="discount_amount" id="code_discount_amount"
                                                                                value = {this.state.discount_amount}
                                                                                onChange= {this.handleChange}/>
                                                                        <br />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <label htmlFor="code_discount_type" className="label input-group"> <span style={{color: 'red', fontSize: '20px'}}>*</span> Code Discount Type</label>
                                                                        <Dropdown fluid selection placeholder='Select Coupon Type' name="coupon_type" id="coupon_type"
                                                                                  value= {this.state.discount_type_text} onChange={this.handleDiscountTypeChange}
                                                                                  options={discountTypesList} />
                                                                        <br />
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <label className="label input-group"> <span style={{color: 'red', fontSize: '20px'}}>*</span> Code Start Date</label>
                                                                        <DateInput className="col-6"
                                                                          name="start_date"
                                                                          closeOnMouseLeave

                                                                          value={this.state.start_date}
                                                                          placeholder="Add Date"
                                                                          onChange={this.handleDateTimeChange}
                                                                          iconPosition="right"
                                                                          dateFormat="YYYY/MM/DD"
                                                                          required
                                                                        />
                                                                        <br />
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <label className="label input-group">Code End Date</label>
                                                                        <DateInput className="col-6"
                                                                          name="end_date"
                                                                          closeOnMouseLeave

                                                                          value={this.state.end_date}
                                                                          placeholder="Add Date"
                                                                          onChange={this.handleDateTimeChange}
                                                                          iconPosition="right"
                                                                          dateFormat="YYYY/MM/DD"
                                                                        />
                                                                        <br />
                                                                    </div>
                                                                </div>

                                                            </form>
                                                        </div>

                                                        <div className="col-6">
                                                            <label class="label input-group">Applied Payment Packages</label>
                                                            <Dropdown multiple fluid selection placeholder='Select Payment Package' name="payment_package_text" id="payment_package_text"
                                                                      value= {this.state.payment_packages_ids} onChange={this.handlePaymentPackageChange}
                                                                      options={paymentPackagesList} />
                                                            <br />
                                                            <label class="label input-group">Applied Payment Methods</label>
                                                            <Dropdown multiple fluid selection placeholder='Select Payment Method' name="payment_method_text" id="payment_method_text"
                                                                      value= {this.state.payment_methods_ids} onChange={this.handlePaymentMethodChange}
                                                                      options={paymentMethodsList} />
                                                            <br />

                                                            <div>
                                                            <label className="label input-group">Options</label>
                                                             <div class="children-box mb-3">
                                                                <div className="row">

                                                                  <div className="col-12">
                                                                    <label className="label">Registered Months</label><br />
                                                                    <Input  fluid type="number"  min="1" name="registered_months" id="registered_months"
                                                                            value = {this.state.registered_months}
                                                                            onChange= {this.handleChange}/>
                                                                    <br /><br />
                                                                    </div>

                                                                    <div className= "col-12">
                                                                    <label className="label">Subscribed Months</label><br />
                                                                    <Input  fluid type="number"  min="1" name="subscribed_months" id="subscribed_months"
                                                                            value = {this.state.subscribed_months}
                                                                            onChange= {this.handleChange}
                                                                            disabled= {this.state.disableSubscribtion}/>
                                                                    <br /><br />
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <label htmlFor="payment_package" className="label input-group"> Registered On</label>
                                                                        <DateInput className="col-6"
                                                                          name="registered_on"
                                                                          closeOnMouseLeave

                                                                          value={this.state.registered_on}
                                                                          placeholder="Add Date"
                                                                          onChange={this.handleDateTimeChange}
                                                                          iconPosition="right"
                                                                          dateFormat="YYYY/MM/DD"
                                                                          required
                                                                        />
                                                                        <br />
                                                                    </div>
                                                                </div>
                                                             </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <br /><br />

                                                    <div className="buttons_container">
                                                      {$buttonUpdateLoader}
                                                        <Button icon labelPosition='left' id="episode_cancel" style={{ width: "120px" }} onClick={this.handleBack} >
                                                            <Icon name='left arrow' />
                                                            Back
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
  }
}

EditPromoCode.propTypes = {
    getAllCouponTypes: propTypes.func.isRequired,
    getAllDiscountTypes: propTypes.func.isRequired,
    fetchAllPaymentPackage: propTypes.func.isRequired,
    getAllPaymentPayingMethods: propTypes.func.isRequired,
    editPromoCode: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  couponTypes: state.couponTypes.items,
  loading: state.couponTypes.loading,
  error: state.couponTypes.getting_allCoupons_error,
  success: state.couponTypes.getting_allCoupons_sucessfully,

  discountTypes: state.discountTypes.items,
  discountLoading: state.discountTypes.loading,
  discountError: state.discountTypes.getting_allDiscounts_error,
  discountSuccess: state.discountTypes.getting_allDiscounts_sucessfully,

  paymentPackages: state.mobilePaymentPackage.items,
  paymentMethods: state.paymentMethods.items,
  paymentPackagesLoading: state.mobilePaymentPackage.loading,
  paymentPackagesError: state.mobilePaymentPackage.getting_all_payment_package_data_error,
  paymentPackagesSuccess: state.mobilePaymentPackage.getting_all_payment_package_data_sucessfully,

  paymentMethodsLoading: state.paymentMethods.loading,
  paymentMethodsError: state.paymentMethods.getting_allMethods_error,
  paymentMethodsSuccess: state.paymentMethods.getting_allMethods_sucessfully,

  promocode: state.promoCodes.item,
  promocodeload: state.promoCodes.loading,
  codeItemerror: state.promoCodes.error_loading_code,
  codeItemsuccess: state.promoCodes.get_code_sucessfully,

  promocoderror: state.promoCodes.error_updating_code,
  promocodesuccess: state.promoCodes.code_updated_successfully,

});

export default withRouter
    (connect
        (mapStateToProps,{ getAllCouponTypes,
                           getAllDiscountTypes,
                           fetchAllPaymentPackage,
                           getAllPaymentPayingMethods,
                           editPromoCode,
                           emptyPromoCode,
                           getPromoCodeItem})
        (EditPromoCode)
    );
