import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';
/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/* images */
import logo from "../../images/logo.png";
import logout from '../../images/logout.png';


const INITIAL_STATE = {
    signedOut: false,
    creditCardRedirection: false,
    fawryRedirection: false,
}

class MobilePaymentSwitch extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

        let token = this.props.match.params.token;

        // adding page to browser history
        let red= "/MobilePaymentSwitch/"+ token;
        this.props.history.push(red);
    }

    signOut = (e) => {
        firebase.auth().signOut()
        this.setState({ signedOut: true })
        console.log("SignOut")
        e.preventDefault();
    }

    handleCreditCardButton = (e) => {
        this.setState({ creditCardRedirection: true })
    }

    handleFawryButton = (e) => {

            this.setState({ fawryRedirection: true })

    }

    render() {


        // back to previous page if browser back button clicked
        window.onhashchange = function() {
            this.props.history.goBack();
        }

        const { userPaymentProfile } = this.props;



        if (this.state.signedOut === true) {
            return (<Redirect to="/MobilePaymentLogin" ></Redirect>)
        }


        if (this.state.creditCardRedirection == true) {
            let token = this.props.match.params.token;
            let redirectPaymentPage = "/MobilePayment/"+token;

            return (<Redirect to={redirectPaymentPage} ></Redirect>)
        }



        if (this.state.fawryRedirection == true) {
            let token = this.props.match.params.token;
            let redirectPaymentPage = "/FawryMobilePayment/"+token;

            return (<Redirect to={redirectPaymentPage} ></Redirect>)

        }

        return (
            <div className="main-payment" >

                <div className="shape-container">
                    <div className=" bg-img">
                    </div>
                </div>

                <div className="container leftToRight">
                    <div className="pay-box">

                        <div className="  main-logo text-center h4">
                            <img className="  logo" alt="logo" src={logo} />
                            <p className=" font  small-font main-color ">
                                بوابة الدفع لجيل
                        </p>
                        </div>
                        <div >

                            <div className=" card card-container Current-Package mb-4 ">
                                <div className="card-header profile-name-card text-right">


                                    <span className="h4 font">مرحبا</span>
                                    <span className="h4 font">   &nbsp; {userPaymentProfile.name}   &nbsp; </span>

                                    <Link
                                        to={{ pathname: "/MobilePaymentProfile" }}>
                                        <a className=" float-left language-btn logout-btn"
                                            onClick={this.signOut}>
                                            <i className="fas fa-chevron-left">
                                                <img className="logout" src={logout} alt="logout" />
                                            </i>
                                        </a>
                                    </Link>

                                </div>
                                <div className="tab-button-outer">

                                    <div id="tab02" className="tab-contents">

                                        <div className="  card-body">

                                            <div className="  h6 text-center mb-4  font">
                                                من فضلك اختر احدى وسائل الدفع التالية
                                            </div>

                                            <div className=" h5 text-center mb-4 mt-4 font"></div>
                                            <fieldset>
                                                <legend className="font">الدفع بواسطه</legend>

                                                <button
                                                    className=" font btn btn-lg btn-primary btn-block btn-signin mt-2"
                                                    type="submit" onClick={this.handleCreditCardButton} >
                                                    البطاقه الائتمانيه
                                                </button>

                                                <br />
                                                <button
                                                    className=" font btn btn-lg btn-primary btn-block btn-signin mt-2"
                                                    type="submit" onClick={this.handleFawryButton}>
                                                    شركه فورى
                                                </button>


                                            </fieldset>


                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                    <MobilePaymentFooter />
                </div>


            </div>
        )
    }
}



MobilePaymentSwitch.propTypes = {
}
const mapStateToProps = state => ({
    userPaymentProfile: state.userPaymentProfile.item

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                
            }
        )
        (MobilePaymentSwitch)
    );

