import React, { Component } from 'react'
import AudioPlayer from "react-h5-audio-player";

class AudioPlayerComponent extends Component {

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("shouldComponentUpdate")
    }


    render() {
        const { audioPreview } = this.props;

        // console.log("**********")
        // console.log("AudioPlayerComponent")
        // console.log("**********")
        // console.log(audioPreview)

        return (
            <div>
                <AudioPlayer
                    src={audioPreview}
                    onPlay={e => console.log("onPlay")}
                    onEnded={e => {
                        console.log("onEnded")
                        console.log(e)
                    }}
                    onError={e => {
                        console.log("onError")
                        console.log(e)
                    }}
                // other props here
                />
            </div>
        )
    }
}

export default AudioPlayerComponent
