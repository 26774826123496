import { ERROR_GET_USER_PAYMENT_HISTORY, GET_USER_PAYMENT_HISTORY,
    LOAD_GET_USER_PAYMENT_HISTORY } from './Types';
import { post } from 'axios';
import store from '../Store';
import firebase from 'firebase/app'

export const getUserHistory = () => {

    return (dispatch, getState, { getFirebase }) => {
        // const firebase = getFirebase();
         firebase.auth().currentUser.getIdToken().then((token) => {

        //    let token = store.getState().user.token;

        //    console.log(token)

            dispatch({
                type:LOAD_GET_USER_PAYMENT_HISTORY,
            })

            const url = "/PaymentHistory/getAllUserPaymentHistory"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_USER_PAYMENT_HISTORY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_GET_USER_PAYMENT_HISTORY,
                    payload: Error.message
                })
            })

         })

    }
}
