import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import userpic from '../images/user-pic.jpg'
import OuterHeader from './Layout/OuterHeader';
import '../css/animate.css'
import '../css/main-style.css'
import '../css/main-mQuery.css'
import Dashboard from './Dashboard';
import InnerHeader from './Layout/InnerHeader';
import Footer from "./Layout/Footer";
import NavBar from './Layout/NavBar';
import { Redirect } from 'react-router-dom'

/*Redux */
import firebase from 'firebase/app'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */

import { getUserData } from '../Actions/MobilePaymentProfileAction';

import * as ROUTES from '../routes';

class Home extends Component {

    state = { redirect: false }

    componentDidMount() {

        console.log(firebase.auth().currentUser)

        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {

                this.props.getUserData(token);
                this.setState({ redirect: true })
            })
        }
    }

    render() {

        const { userPaymentProfile } = this.props;

        // console.log(userPaymentProfile)
        const { redirect } = this.state;

        if (redirect == true) {
            if (userPaymentProfile.user_role !== undefined) {
                if (userPaymentProfile.user_role.role === "USER") {
                    firebase.auth().signOut()
                    return (<Redirect to={ROUTES.SIGN_IN}></Redirect>)
                }
            }
        }

        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9 right-content">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-9 left-main-body" id="left-main-body">
                                            <NavBar />

                                        </div>
                                        <div className="col-3 right-activity" id="activity-box">
                                            <div className="bg-activity">
                                                <div className="admins">
                                                    <p className="h7 main-color font-weight-bold">Admins Activity</p>
                                                    <div className="view-admins">
                                                        <a href="#" className="view-user-act">
                                                            <img className="user-act float-left" alt="user-act" src={userpic}></img>
                                                            <div className="secondary x-small-font user-log">
                                                                <p className="font-weight-bold">Name last name</p>
                                                                <span>add new video</span>
                                                            </div>
                                                        </a>
                                                        <a href="#" className="view-user-act">
                                                            <img className="user-act float-left" alt="user-act" src={userpic} />
                                                            <div className="secondary x-small-font user-log">
                                                                <p className="font-weight-bold">Admin Name</p>
                                                                <span>add new video</span>
                                                            </div>
                                                        </a>
                                                        <a href="#" className="view-user-act">
                                                            <img className="user-act float-left" alt="user-act" src={userpic} />
                                                            <div className="secondary x-small-font user-log">
                                                                <p className="font-weight-bold">Admin Name last name</p>
                                                                <span>add new video</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="team mt-3">
                                                    <p className="h7 main-color font-weight-bold">Team Activity</p>
                                                    <div className="view-admins">
                                                        <a href="#" className="view-user-act">
                                                            <img className="user-act float-left" alt="user-act" src={userpic} />
                                                            <div className="secondary x-small-font user-log">
                                                                <p className="font-weight-bold">Admin Name</p>
                                                                <span>add new video</span>
                                                            </div>
                                                        </a>
                                                        <a href="#" className="view-user-act">
                                                            <img className="user-act float-left" alt="user-act" src={userpic} />
                                                            <div className="secondary x-small-font user-log">
                                                                <p className="font-weight-bold">Name last name</p>
                                                                <span>add new video</span>
                                                            </div>
                                                        </a>
                                                        <a href="#" className="view-user-act">
                                                            <img className="user-act float-left" alt="user-act" src={userpic} />
                                                            <div className="secondary x-small-font user-log">
                                                                <p className="font-weight-bold">Admin Name last name</p>
                                                                <span>add new video</span>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-block btn-light view-btn x-small-font">VIEW MORE</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


Home.propTypes = {
    getUserData: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    userPaymentProfile: state.userPaymentProfile.item,

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                getUserData
            }
        )
        (Home)
    );
