import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';

/* Assets */
import mainLogoM from '../../../images/portal/logo-big.png';
import faceBookIcon from '../../../images/portal/facebook@3x.png';
import googleIcon from '../../../images/portal/google@3x.jpg';
import twitterIcon from '../../../images/portal/twitter@3x.png';

/* Actions */
import {
  customerSignInWithGoogle,
  customerSignInWithTwitter,
  customerSignInWithFacebook,
  empty
} from '../../../Actions/AuthActions';

/* Routes */
import { PORTAL_LAND_PAGE, PORTAL_LOGGED_BY_PHONE, PORTAL_CHANGE_LOGIN_METHODS } from '../../../routes';

var parentPage ;

const INITIAL_STATE = {
  errorMsg: ''
}

class OtherLoginMethods extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // import("../../../css/portal-style.css").then(()=>{});
  }

  handleGoogle = () => {
    this.props.customerSignInWithGoogle();
  }

  handleTwitter = () => {
    this.props.customerSignInWithTwitter();
  }

  handleFacebook = () => {
    this.props.customerSignInWithFacebook();
  }

  render() {

    const { authUser, loginsuccess,
      loginerror, loginloading ,
      redirectionPage} = this.props;

      if(redirectionPage
        && redirectionPage.pageName
        && redirectionPage.pageName !== null
        && redirectionPage.pageName !==undefined){

        console.log("if")

        parentPage = redirectionPage.pageName;
      }else{
        console.log("else")
        parentPage = PORTAL_LAND_PAGE;
      }


    if (loginsuccess === true) {
      // in case of success redirect to the new form to update register with the new method

      this.props.empty();
      return (<Redirect to={PORTAL_CHANGE_LOGIN_METHODS}> </Redirect>)
    }
    if (loginsuccess === false && (loginerror !== '' && loginerror !== false && loginerror !== undefined)) {
      this.setState({ errorMsg: "لم تُسجِّل باستخدام هذه الطريقة من قبل" })
      this.props.empty();
    }
    return (
      <body>
        <div className="other-methods-container">
          <div className="logo-container">
            <div className="container">
              <div className="logo">
                <img src={mainLogoM} alt="logo" />
              </div>
            </div>
          </div>

          <section className="redirect-content">
            <div className="container">
              <div className="redirect-btn">
                <Link to={parentPage}>
                  <Icon name='right arrow' />
                  <span className="text"> العودة للصفحة الرئيسية</span>
                </Link>
              </div>
            </div>
          </section>

          <section className="dont-worry">
            <div className="container">
              <div className="dont-wory-card">
                <div className="card-content col-lg-12">
                  <p style={{ color: 'red', textAlign: 'right' }}> {this.state.errorMsg}</p>

                  <div className="card-title h4 bold" style={{ fontSize: '20px', fontWeight: 'bold' }}>لا تقلق. سوف نساعدك لدخول حسابك مجدداََ</div>
                  <div className="description-text">اختر الطريقة التي قمت بالتسجيل بها مسبقاََ</div>
                  <Link to={PORTAL_LOGGED_BY_PHONE} className="btn phone-btn">عن طريق رقم الهاتف</Link>
                  <div className="or-other">او من خلال</div>
                  <div className="other-ways">
                    <div className="way-btn">
                      <a onClick={this.handleFacebook}>
                        <img src={faceBookIcon} alt="" />
                      </a>
                    </div>
                    <div className="way-btn">
                      <a onClick={this.handleGoogle}>
                        <img src={googleIcon} alt="" />
                      </a>
                    </div>
                    <div className="way-btn">
                      <a onClick={this.handleTwitter}>
                        <img src={twitterIcon} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </body>
    )
  }
}

OtherLoginMethods.propTypes = {

}
const mapStateToProps = state => ({
  authUser: state.authUser.item,
  loginsuccess: state.authUser.success,
  loginerror: state.authUser.errorMsg,
  loginloading: state.authUser.loading,
  redirectionPage: state.redirectionPage.item,

});

export default withRouter
  (connect
    (mapStateToProps,
      {
        customerSignInWithGoogle,
        customerSignInWithTwitter,
        customerSignInWithFacebook,
        empty
      })
    (OtherLoginMethods)
  );
