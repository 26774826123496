import {post} from 'axios'
import { LOAD_KIDS_REPORT, GET_KIDS_REPORT, ERROR_LOADING_KIDS_REPORT ,EMPTY_REPORT, LOAD_KID_USAGE_REPORT, GET_KID_USAGE_REPORT, ERROR_LOADING_KID_USAGE_REPORT, LOAD_USAGE_COUNTRY_FILTER, GET_USAGE_COUNTRY_FILTER, ERROR_LOADING_COUNTRY_FILTER, ERROR_LOADING_GENDER_FILTER, GET_USAGE_GENDER_FILTER, LOAD_USAGE_GENDER_FILTER, LOAD_USAGE_AGE_FILTER, GET_USAGE_AGE_FILTER, ERROR_LOADING_AGE_FILTER} from './Types';

export const getAllKidsReport =(startDate,endDate)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_KIDS_REPORT,
            })
            const url = "/KidReport/getAllKidsReportDetails"
            const formData = new FormData();
            formData.append("Start_Date",startDate)
            formData.append("End_Date",endDate)
            const config = {
                headers:{
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            
            }
            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:GET_KIDS_REPORT,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_KIDS_REPORT,
                    payload:Error.message
                })
            })
        })
    }
}

export const emptyReport =()=> dispatch=>
{
    dispatch({
        type:EMPTY_REPORT,
        payload:[]
    })
}

export const getkidsUsageReport =(startDate ,endDate)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_KID_USAGE_REPORT
            })
            const url = "/KidReport/getKidsUsageReport";
            const formData = new FormData();
            formData.append("Start_Date",startDate);
            formData.append("End_Date",endDate);
            const config = {
                headers:{
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            
            }
            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:GET_KID_USAGE_REPORT,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_KID_USAGE_REPORT,
                    payload:Error.message
                })
            })

        })
    }
}



export const getKidsUsageReportFilteredByCountry = (startDate,endDate,countries)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_USAGE_COUNTRY_FILTER
            })
            const url = "/KidReport/getKidsUsageReportFilterByCountry"
            const formData = new FormData();
            formData.append("Start_Date",startDate)
            formData.append("End_Date",endDate)
            for (let i=0;i<countries.length;i++)
            {
                formData.append("Countries",countries[i])
            }
            if (countries.length===0)
            {
                formData.append('Countries',[])
            }
            const config = {
                headers:{
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            
            }

            post (url,formData,config).then((res)=>
            {
                dispatch({
                    type :GET_USAGE_COUNTRY_FILTER,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_COUNTRY_FILTER,
                    payload:Error.message

                })
            })

        })
    }
}

export const getKidsUsageReportByGender =(startDate,endDate)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_USAGE_GENDER_FILTER
            })
            const url = '/KidReport/getKidReportByGender'
            const formData  = new FormData();
            formData.append('Start_Date',startDate)
            formData.append('End_Date',endDate)

            const config = {
                headers:{
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            
            }

            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:GET_USAGE_GENDER_FILTER,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_GENDER_FILTER,
                    payload:Error.message
                })
            })

        })
    }
}

export const getKidsUsageByAgeRange = (startDate,endDate)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_USAGE_AGE_FILTER
            })

            const url = "/KidReport/getKidsUsageByAgeRange"
            const formData = new FormData();
            formData.append('Start_Date',startDate);
            formData.append('End_Date',endDate);

            
            const config = {
                headers:{
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            
            }

            post (url,formData,config).then((res)=>
            {
                dispatch({
                    type:GET_USAGE_AGE_FILTER,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_AGE_FILTER,
                    payload:Error.message
                })
            })
            
        })
    }
}