import React,{Component} from "react";
import {connect} from 'react-redux';
import {setBlogStatus,deleteBlog,getAllBlogs,emptyBlog} from '../../Actions/BlogActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Redirect} from 'react-router-dom'
import { Confirm } from 'semantic-ui-react'
/* Routes */
import { ALL_BLOGS } from '../../routes';

class DeleteBlog extends Component
{
    state={open:false}
    open=()=>this.setState({open:true})
    close =()=>this.setState({open:false})

    removeBlog(id)
    {
        this.props.deleteBlog(id);
    }


    handleSubmit =(e)=>
    {
        const {id} = this.props;
        this.removeBlog(id);
        this.close();
    }

    render ()
    {
        const {blog_deleted_successfully,error_deleting_blog} = this.props;
        if (blog_deleted_successfully===false)
        {
            alert ("error deleting blog"+ error_deleting_blog);
            this.props.emptyBlog();
        }
        else if (blog_deleted_successfully===true)
        {
            alert("blog deleted successfully");
            this.props.emptyBlog();
            this.props.getAllBlogs();
            return (<Redirect to={ALL_BLOGS}></Redirect>)
        }
        return (<div >
            <button className="seriesCardButtons"  onClick={this.open}>
                    <i className="far fa-trash-alt" >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal"
                open={this.state.open}
                onCancel={this.close}
                content='Are You Sure To Delete This Blog?? '
                onConfirm={this.handleSubmit} />
            </div>)

    }

}
const mapStateToProps = state =>
({
    blog_deleted_successfully:state.blogs.blog_deleted_successfully,
    error_deleting_blog:state.blogs.error_deleting_blog,

})
export default connect (mapStateToProps,{setBlogStatus,deleteBlog,getAllBlogs,emptyBlog}) (DeleteBlog)
