import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm , Popup} from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

/*Actions */
import {deleteQuestion} from '../../Promises/Question.js';
import {  emptyQuestions } from '../../Actions/QuestionActions'
import { getActivityData } from '../../Actions/ActivityActions';

class DeleteQuestion extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })



    handleSubmit = (e) => {

        const { questionId } = this.props;
        const {activityId} = this.props;
        
        console.log("id delete = " + questionId)

        deleteQuestion(questionId).then((res) => {
            alert("Question Deleted Successfully")
            this.props.getActivityData(activityId);
            this.props.questionIsDeleted(true);

        }).catch((Error) => {
            alert("error messing data or network " + Error)
            this.props.questionIsDeleted(false);
        })

        this.close();

    }

    render() {

        return (
            <span >
            <Popup trigger=
            {
                <FontAwesomeIcon className="far fa-trash-alt"
                 icon='trash-alt' onClick={this.open}>
                </FontAwesomeIcon>
            }
            content='Delete'
            position='top center'
            />
                <Confirm id="modal"
                    content='Are you sure you want 
                    to delete this Question ??'
                    open={this.state.open} 
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </span>

        )
    }
}


DeleteQuestion.propTypes = {
    getActivityData: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
});


export default withFirebase
    (connect
        (mapStateToProps,
            {  emptyQuestions, getActivityData })
        (DeleteQuestion)
    );
