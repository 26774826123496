import React, { Component } from "react";
import CardInputs from "./CardInputs";
import PaymentInputs from "./PaymentInputs";
import Promocode from "./Promocode";

class CompleteProcessForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="CompleteProcessFormContainer d-flex flex-column justify-content-between">
          <form className="CompleteProcessForm">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-5">
                  <CardInputs />
                </div>
              </div>
            </div>
          </form>
          <div className="CompleteProcessFormFooter">
            <div className="container ">
              <div className="d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row gap-10">
                <Promocode />
                <button className="portal-main-btn mb-10">الدفع الان</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompleteProcessForm;
