import React from "react";
import mainLogo from "../../../images/portal/icons/logo.svg";
import googlePlay from "../../../images/portal/icons/googlePlay.svg";
import appGallery from "../../../images/portal/icons/appGallery.svg";
import appStore from "../../../images/portal/icons/appStore.svg";
import ealamy from "../../../images/portal/icons/ealamy.svg";
import roots from "../../../images/portal/icons/roots.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  googlePlayLink,
  appGalleryLink,
  appleAppLink,
  webAppLink,
  jeel_youtube,
  jeel_twitter,
  jeel_facebook,
  jeel_intsagram,
  jeel_linkedin,
  faq,
  about,
  contactUs,
} from "../constants";
import {
  faTwitter,
  faFacebookF,
  faTiktok,
  faYoutube,
  faSnapchatGhost,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="portal-footer">
      <div className="container">
        <div className="form-row">
          <div className="col-12 col-md-6 col-lg-3 order-2 order-lg-1">
            <div className="footer-section mt-lg-5">
              <div className="footer-links d-flex align-items-center justify-content-center justify-content-md-start">
                <div className="footer-link">
                  <a
                    className=""
                    href={about}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    من نحن
                  </a>
                </div>
                <div className="footer-link">
                  <a
                    className=""
                    href={faq}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    الأسئلة الشائعة
                  </a>
                </div>
                <div className="footer-link">
                  <a
                    className=""
                    href={contactUs}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    اتصل بنا
                  </a>
                </div>
              </div>
              <div className="social-links mt-4 d-flex align-items-center  justify-content-center justify-content-md-start">
                <a
                  href={jeel_youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faYoutube} className="faYoutube" />
                </a>
                <a
                  href={jeel_twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} className="faTwitter" />
                </a>
                <a
                  href={jeel_facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="faFacebookF" />
                </a>
                <a
                  href={jeel_linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    className="faLinkedinIn"
                  />
                </a>
                <a
                  href={jeel_intsagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} className="faInstagram" />
                </a>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 order-1 order-lg-2">
            <div className="footer-section mx-auto">
              <div className="footer-logo text-center mb-4">
                <img src={mainLogo} alt="" />
              </div>
              <div className="footer-download mb-4 flex-column flex-sm-row d-flex align-items-center justify-content-center">
                <div className="download-link mb-3 mb-sm-0">
                  <a
                    className="download-icon"
                    href={googlePlayLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googlePlay} alt="" />
                  </a>
                </div>
                <div className="download-link mb-3 mb-sm-0">
                  <a
                    className="download-icon"
                    href={appGalleryLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appGallery} alt="" />
                  </a>
                </div>
                <div className="download-link mb-3 mb-sm-0">
                  <a
                    className="download-icon"
                    href={appleAppLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appStore} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-3 order-3">
            <div className="footer-section mt-3 mt-md-0 mt-lg-5 d-block d-sm-flex align-items-center justify-content-center justify-content-md-between order-3">
              <div className="link-with-logo mb-2 mb-sm-0">
                <div className="link mb-4">المنتج المنفذ</div>
                <div className="logo">
                  <img src={ealamy} alt="" />
                </div>
              </div>
              <div className="link-with-logo">
                <div className="link mb-4">الشركة المالكة: روتس انتجريتد</div>
                <div className="logo">
                  <img src={roots} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-text mt-3 mt-lg-0">
          جميع الحقوق محفوظة &copy; 2020
        </div>
      </div>
    </footer>
  );
};
export default Footer;
