import {
  LOAD_CURRENCY_RATE, GET_CURRENCY_RATE, ERROR_GETTING_CURRENCY_RATE, EMPTY_CURRENCY_RATE
} from '../Actions/Types'

const initial_state = {
    item: {},
    loading: '',
    getting_rate_successfully: '',
    error_getting_rate: '',
    error_loading_rate: '',
}

export default function (state=initial_state,action)
{
    switch(action.type)
    {
        case GET_CURRENCY_RATE:
            return{
                ...state,
                item:action.payload,
                getting_rate_successfully: true,
                error_getting_rate: '',
                error_loading_rate: '',
                loading: ''
            }
        case LOAD_CURRENCY_RATE:
            return {
                ...state,
                loading:true
            }
        case ERROR_GETTING_CURRENCY_RATE:
            return{
              ...state,
              getting_rate_successfully: false,
              error_getting_rate: action.payload,
              error_loading_rate: true,
              loading: ''
            }
        case EMPTY_CURRENCY_RATE:
            return{
                ...state,
                item: {},
                loading: '',
                getting_rate_successfully: '',
                error_getting_rate: '',
                error_loading_rate: '',
            }

         default:
             return state

    }
}
