import { Promise } from 'q';
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import store from '../Store'
import { saveQuestion, assignCorrectAnswerToQuestion } from './Question.js'
import { saveAllEvaluationsWithCategoryIDs } from './EvaluationPromise.js'

export function saveActivity(name) {
    return new Promise(function (resolve, reject) {
        let questions = store.getState().questions.items;
        fetch('/Activity/addActivity?Name=' + name + '', {
            method: 'POST',
            headers: {
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }).then((res) => res.json()).then((res) => {
            let activityId = res.id;

            saveAllEvaluationsWithCategoryIDs(activityId).then((res) => {
                resolve(res)
                return res;
            }).catch((Error) => {
                reject(Error);
                return "error"
            })

            for (let i = 0; i < questions.length; i++) {
                let correctAnswerId = questions[i].correctAnswerId;
                saveQuestion(questions[i], res.id).then((res) => {

                    assignCorrectAnswerToQuestion(res.data, correctAnswerId).then((res) => {

                    })
                });


            }
            resolve(res)
            return res;
        }).catch((Error) => {
            reject(Error)
            return Error.message

        })
    })

}
