import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Components */
import GetAllGames from './getAllGames';
import PuzzleDetails from './puzzleDetails';
import DragDropDetails from './dragDropDetails';
import PaintingDetails from './paintingDetails';
import AddDragDrop from './addDragDrop';
import EditDragDrop from './editDragDrop';
import JumpDetails from './jumpDetails';
import EditJump from './editJump';

import GetAllGameData from '../GameData/GetAllGameData'
import AddGameData from '../GameData/AddGameData'
import EditGameData from '../GameData/EditGameData'
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/*Game Parent */
import GetAllGameParents from '../GameParent/GetAllGameParents'
import AddGameParent from '../GameParent/AddGameParent'
import ViewGameParent from '../GameParent/ViewGameParent';
import EditGameParent from '../GameParent/EditGameParent'
/* Routes */
import {
    SIGN_IN, ADMIN_LAND_PAGE, ALL_GAMES,
    PUZZLE_DETAIL, DRAG_DROP_DETAIL, PAINTING_DETAIL,
    ADD_DRAG_DROP, EDIT_DRAG_DROP, JUMPING_DETAIL,
    EDIT_JUMPING,


    GAME_DATA_DETAIL, ADD_GAME_DATA,
    EDIT_GAME_DATA,
    ADD_GAME_PARENT, VIEW_GAME_PARENT, EDIT_GAME_PARENT, ALL_GAME_PARENTS

} from '../../routes';

class GameAuth extends Component {

    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    componentDidMount() {
        const { userPaymentProfile } = this.props;
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to={SIGN_IN}></Redirect>
        }
    }

    render() {
        const { userPaymentProfile } = this.props;

        if (userPaymentProfile.user_role.role === "Analyst") {
            return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
        }
        return (
            <span>
                <Route path={ALL_GAME_PARENTS} component={GetAllGameParents} />
                <Route path={ADD_GAME_PARENT} component={AddGameParent} />
                <Route path={VIEW_GAME_PARENT} component={ViewGameParent} />
                <Route path={EDIT_GAME_PARENT} component={EditGameParent} />
                {/* adding Game Routers */}
                <Route path={ALL_GAMES} component={GetAllGames} />
                <Route path={PUZZLE_DETAIL} component={PuzzleDetails} />
                <Route path={DRAG_DROP_DETAIL} component={DragDropDetails} />
                <Route path={PAINTING_DETAIL} component={PaintingDetails} />
                <Route path={ADD_DRAG_DROP} component={AddDragDrop} />
                <Route path={EDIT_DRAG_DROP} component={EditDragDrop} />
                <Route path={JUMPING_DETAIL} component={JumpDetails} />
                <Route path={EDIT_JUMPING} component={EditJump} />

                {/* Game Data */}
                <Route path={GAME_DATA_DETAIL} component={GetAllGameData} />
                <Route path={ADD_GAME_DATA} component={AddGameData} />
                <Route path={EDIT_GAME_DATA} component={EditGameData} />


            </span>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
    (connect
        (mapStateToProps,
            { getUserData }
        )(GameAuth)
    );
