import React, { Component } from 'react'
import { Redirect, NavLink } from 'react-router-dom'
import { Icon } from 'semantic-ui-react';
import { sha256 } from 'js-sha256';
import { Link } from "react-router-dom";

/*CSS */
// import '../../../css/AppCss/MobilePayment/payment.css';
// import '../../../css/AppCss/MobilePayment/main-mQuery.css';

/* images */
import mainLogoM from '../../../images/portal/logo-big.png';

/*Actions */
import { emptyMobileUserProfileWithItem, emptyMobileUserProfile } from '../../../Actions/MobilePaymentProfileAction';
import { getUserPaymentInfo } from '../../../Actions/MobilePaymentProfileAction';
import { addTokenToredux } from '../../../Actions/loginActions.js'
import { addSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { emptyPrePaidPayment, paymentWithPrePaidCard } from '../../../Actions/PaymentCardActions';
import {
  checkIfSavedPromoCode,
} from '../../../Actions/PromoCodesActions';

/*Components */
import Footer from '../Layout/Footer';
import Loader from '../Layout/Loader';

/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/* Routes */
import { PORTAL_LAND_PAGE, PORTAL_PAYMENT_METHODS, PORTAL_SUBSCRIPTION, PORTAL_SUCCESSFUL } from '../../../routes';

/* Icons */
import PrePaidIcon from "../../../images/portal/prepaid.png";
const INITIAL_STATE = {
  token: '',
  prepaidCode: '',
  errorMsg: '',
  loggedOut: false,
  disabled: true,
}

class PrePaidCardPayment extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    if (firebase.auth().currentUser) {
      firebase.auth().currentUser.getIdToken().then((token) => {
        setTimeout(() => {
          this.setState({ token: token });
          this.props.addTokenToredux(token);
        }, 10)
      })
    } else {
      this.setState({ loggedOut: true });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.emptyMobileUserProfileWithItem();
    if (firebase.auth().currentUser) {
      this.props.getUserPaymentInfo();
      this.props.checkIfSavedPromoCode();

      const { token } = this.props;
      if (token === '') {
        this.setState({ loggedOut: true });
      }
    } else {
      this.setState({ loggedOut: true })
    }
  }


  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces, errorMsg: '' });
    setTimeout(() => {
      if (this.state.prepaidCode !== '' && this.state.prepaidCode !== ' ') {
        this.setState({ disabled: false })
      } else {
        this.setState({ disabled: true })
      }
    }, 0);
  }

  handlePay = (e) => {
    this.props.paymentWithPrePaidCard(this.state.prepaidCode);
  }

  render() {
    const { promocode } = this.props;

    let codeDescription = "";

    if 
      ((promocode !== null
        && promocode !== undefined
        && promocode !== '') &&
      promocode.uid !== undefined) {
      codeDescription = (
        <div class="form-card-content">
          <div class="card-discount">
            عذرا رمز الخصم غير صالح للبطاقات مسبقه الدفع
          </div>
        </div>
      )
    }

    const { item, success, error, loading, feedback_message } = this.props;
    const { token } = this.props;
    const { userloading, userPaymentProfile } = this.props;
    if (this.state.loggedOut === true) {
      return (<Redirect to={PORTAL_LAND_PAGE}> </Redirect>)
    }
    //// payment
    let feedback_messege1 = '';
    if (success === true) {
      if (item !== undefined &&
        item.paymentPackageId !== undefined &&
        item.paymentPackageId.packageType !== undefined) {
        if (item.paymentPackageId.packageType === 'Monthly') {
          feedback_messege1 = "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة شهر";
        } else if (item.paymentPackageId.packageType === 'Yearly') {
          feedback_messege1 = "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة عام كامل";
        } else {
          feedback_messege1 = "تهانينا! تمت عمليه الدفع بنجاح";
        }
        this.props.emptyMobileUserProfile();
        this.props.addSuccessMessage(feedback_messege1);
        this.props.emptyPrePaidPayment();
        // return (<Redirect to={PORTAL_SUBSCRIPTION}> </Redirect>)
        return (<Redirect to={PORTAL_SUCCESSFUL}> </Redirect>)
      }
    } else if (error === true) {
      feedback_messege1 = "مع الأسف، لم تتم عملية الدفع بنجاح، " + "(" + feedback_message + ")";
      this.setState({ errorMsg: feedback_messege1 });
      this.props.emptyPrePaidPayment();
    }

    let fieldsetContent = (
      <fieldset>
        <div className="card-content">
          <div className="card-logo">
          </div>
          <form className="main-form" action="">
            <div className="form-group">
              <input type="text" className="form-control main-input" name="prepaidCode"
                value={this.state.prepaidCode} onChange={this.handleChange} placeholder="كود الاشتراك" />
            </div>
          </form>
        </div>
      </fieldset>
    )

    let buttonSubmitLoader = (
      <button className="pay-input btn form-control" type="submit"
        disabled={this.state.disabled}
        onClick={this.handlePay} >
        تأكيد الدفع
      </button>
    );
    if (loading === true) {
      buttonSubmitLoader = (
        <button
          className="btn btn-block ui loading secondary button">
        </button>
      );
    }

    let content = (
      <span>
        <span>

          <div className="row">
            <div className="col-12">
              <p style={{ color: 'red', float: 'right' }}> {this.state.errorMsg}</p>
              {codeDescription}
              <br />
            </div>
          </div>
          {fieldsetContent}
          <br />
          {buttonSubmitLoader}
        </span>
      </span>
    )

    if (userloading === true) {
      content = (<Loader />)
    }
    return (
      <body>
        <section className="main-payment custom-main-payment">

          <div className="container">
              <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
                <div className="d-flex align-items-center">
                  <Link className="navbar-brand d-flex align-items-center" to="/">
                    <img src={PrePaidIcon} alt="" />
                  </Link>
                  <div className="complete-process d-none d-sm-block">
                    اكمال عملية الدفع
                  </div>
                </div>
                <Link className="btn back-btn" type="button" to="/">
                  العوده
                </Link>
              </div>
         </div>


          <div className="container checkout-card-container">

            <div className="pay-box checkout-card">
              <div className="card card-container Current-Package">
                <div className="card-header profile-name-card text-center d-flex align-items-center justify-content-between">
                  <div className="title">تفاصيل الدفع</div>
                  <div className="icons d-flex align-items-center">
                    <div className="icon">
                      <img src={PrePaidIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div className="card-body">{content}</div>
              </div>
            </div>
          </div>    
        </section>
      </body>
    )
  }
}


PrePaidCardPayment.propTypes = {
  getUserPaymentInfo: propTypes.func.isRequired,
  addTokenToredux: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  userPaymentProfile: state.userPaymentProfile.item,
  userloading: state.userPaymentProfile.loading,

  promocode: state.promoCodes.item,

  token: state.user.token,

  item: state.prepaidCard.item,
  loading: state.prepaidCard.loading,
  success: state.prepaidCard.payment_added_successfully,
  error: state.prepaidCard.error_adding_payment,
  feedback_message: state.prepaidCard.payment_feedback_messege,
});

export default withRouter
  (connect
    (mapStateToProps,
      {
        emptyMobileUserProfile,
        emptyMobileUserProfileWithItem,
        getUserPaymentInfo,
        addTokenToredux,
        addSuccessMessage,
        emptyPrePaidPayment,
        paymentWithPrePaidCard,
        checkIfSavedPromoCode,
      }
    )
    (PrePaidCardPayment)
  );
