import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import { Popup } from 'semantic-ui-react'

/**Actions */
import { fetchAllVendors } from '../../Actions/VendorActions'
/*Components */
import Loader from '../Layout/Loader';
/*CSS */
import 'react-table/react-table.css';
/*Routes*/
import { GET_VENDOR_TO_EDIT } from '../../routes';

class VendorTabel extends Component {

    constructor(props) {
        super(props);

        this.props.fetchAllVendors();
    }

    componentDidMount() {
        this.props.fetchAllVendors();
    }

    render() {

        const { vendor } = this.props;


        const columns = [

            {
                Header: "ID",
                accessor: "id",
                width: 50,
                MinWidth: 50,
                Maxwidth: 130,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (

                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_VENDOR_TO_EDIT + "/" + props.original.id }}>

                                {props.original.id}
                            </Link>
                        </div>
                    )
                }

            },
            {
                Header: "User Name",
                accessor: "name",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (

                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_VENDOR_TO_EDIT + "/" + props.original.id }}>

                                {props.original.name}
                            </Link>
                        </div>
                    )
                }

            },
            {
                Header: "Email",
                accessor: "vendorMasterUid.email",
                width: 180,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>

                            {props.original.vendorMasterUid.email}
                        </div>
                    )
                }

            },
            {
                Header: "Phone",
                accessor: "vendorMasterUid.phone",
                width: 130,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>

                            {props.original.vendorMasterUid.phone}
                        </div>
                    )
                }

            },
            {
                Header: "Contract Type",
                accessor: "contractType",
                width: 180,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>

                            {props.original.contractType}
                        </div>
                    )
                }

            },
            {
                Header: "Country",
                accessor: "vendorMasterUid.country",
                width: 140,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>

                            {props.original.vendorMasterUid.country}
                        </div>
                    )
                }

            },
            {
                Header: "City",
                accessor: "city",
                width: 140,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>

                            {props.original.city}
                        </div>
                    )
                }

            },

            {
                Header: "Actions",
                width: 100,
                filterable: false,
                headerClassName: 'table-header',
                resizable: false,
                //foldable: true,
                Cell: props => {
                    return (
                        <div style={{ textAlign: "center" }}>

                            <Link className="link"
                                to={{ pathname: GET_VENDOR_TO_EDIT + "/" + props.original.id }}>

                                <Popup trigger={
                                    <a className="user-lock" onClick={this.open}>
                                        <i>  <FontAwesomeIcon icon="id-card" />
                                        </i>
                                    </a>}
                                    content='View Partner'
                                    position='top center'
                                />
                            </Link>

                            {/* {this.blockAdminIcon(props)} */}
                            {/* <a class="user-lock" href="#">
                                <i>  <FontAwesomeIcon icon="user-lock" />
                                </i>
                            </a> */}


                        </div>
                    )
                }
            }
        ]


        const { error, loading, success } = this.props;

        let vendorList = null

        if (loading === true) {
            vendorList = (<Loader />)
        }
        else if (success === true && error === '') {

            return (
                <div>
                    <ReactTable

                       className=" table-striped custm-table"
                        columns={columns}
                        data={vendor}
                        filterable
                        defaultPageSize={10}
                    //    className="-striped -highlight"
                    //defaultPageSize={5}
                    >

                    </ReactTable>
                </div>)
        }


        return (
            <div>

                <div className="mt-4 responsive-table">
                    {vendorList}
                </div>
            </div>
        )
    }
}


VendorTabel.propTypes = {
    fetchAllVendors: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    vendor: state.vendor.items,
    loading: state.vendor.loading,
    error: state.vendor.error_all_loading_vendors,
    success: state.vendor.getting_allVendors_sucessfully,
    userPaymentProfile: state.userPaymentProfile.item

});

export default withRouter
    (connect
        (mapStateToProps,
            { fetchAllVendors })
        (VendorTabel)
    );
