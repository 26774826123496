import { Promise } from 'q';
import axios, { put, post } from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'


export function saveQuestion(question, activityId) {
    return new Promise(function (resolve, reject) {
        axios({
            method: 'post',
            url: '/Questions/addQuestion',
            headers: {
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b,
                'Content-Type': 'application/json'
            },
            data: {

                "text": question.text,
                "questionType": question.questionType,
                "voice": question.questionAudio,
                "activityId": {
                    "id": activityId

                },
                "choices": question.choices,
                "questionCategoryId": question.questionCategoryId
            }
        }).then((res) => {
            resolve(res)
            return res.data
        }).catch((Error) => {

            reject(Error)
            return Error.message
        })
    })
}


export function assignCorrectAnswerToQuestion(question, correctAnswerId) {

    console.log("***********")
    console.log("assignCorrectAnswerToQuestion")
    console.log(question.id)
    console.log(correctAnswerId)
    console.log(question.choices[correctAnswerId].id)
    console.log("***********")

    return new Promise(function (resolve, reject) {
        const url = "/Questions/assignCorrectChoiceToQuestion"
        const formdata = new FormData();
        formdata.append('Question_ID', question.id);
        formdata.append('CorrectChoice_ID', question.choices[correctAnswerId].id);
        const config = {
            headers: {

                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }

        }
        put(url, formdata, config).then((res) => {
            resolve(res)
            return res.data
        }
        ).catch((Error) => {
            console.log("error assignCorrectAnswerToQuestion")
            console.log(Error)
            reject(Error)
        })
    })
}


export function saveQuestionwithCorrectAnswer(question, activityId) {
    return new Promise(function (resolve, reject) {
        // console.log("***********")
        // console.log("saveQuestionwithCorrectAnswer")
        // console.log(question)
        // console.log(activityId)
        // console.log("***********")

        let correctAnswerId = question.correctAnswerId;
        saveQuestion(question, activityId).then((res) => {

            assignCorrectAnswerToQuestion(res.data, correctAnswerId).then((res) => {
                resolve(res);
                return "sucess";

            }).catch((Error) => {
                console.log("error assignCorrectAnswerToQuestion" +
                    " in saveQuestionwithCorrectAnswer")
                reject(Error);
                return "error"
            })

        }).catch((Error) => {
            console.log("error saveQuestion" +
                " in saveQuestionwithCorrectAnswer")
            reject(Error);
            return "error"
        })

    })
}

export function deleteQuestion(id) {
    return new Promise(function (resolve, reject) {

        const url = "/Questions/deleteQuestion";
        const formdata = new FormData();
        formdata.append('Question_ID', id);
        const config = {
            headers: {
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formdata, config).then((res) => {
            resolve(res)
            return res.data
        }
        ).catch((Error) => {
            console.log(Error)
            reject(Error)
        })
    })
}


export function getQuesrionDetails(questionId) {
    return new Promise(function (resolve, reject) {
        const url = "/Questions/getQuestionDetails"
        const formdata = new FormData();
        formdata.append('Question_ID', questionId);
        const config = {
            headers: {

                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }

        }
        post(url, formdata, config).then((res) => {
            resolve(res)
            return res.data
        }
        ).catch((Error) => {
            console.log("getQuesrionDetails")
            console.log(Error)
            reject(Error)
        })
    })
}


export function updateQuestion(question, activityId) {
    // console.log("***********")
    // console.log("updateQuestion")
    // // console.log(question)
    // // console.log(activityId)
    // console.log(question.correctAnswerId)
    // console.log("***********")

    // console.log(question.choices)

    return new Promise(function (resolve, reject) {
        axios({
            method: 'put',
            url: '/Questions/updateQuestion',
            headers: {
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b,
                'Content-Type': 'application/json'
            },
            data: {
                "id": question.id,
                "text": question.text,
                "questionType": question.questionType,
                "voice": question.questionAudio,
                "publishDate": "",
                "activityId": {
                    "id": activityId
                },
                "choices": question.choices,
                "questionCategoryId": question.questionCategoryId,
                "CorrectAnsId": {
                    "id": question.choices[question.correctAnswerId].id
                }
            }
        }).then((res) => {
            resolve(res)
            return res.data
        }).catch((Error) => {

            console.log("Error updateQuestion")
            reject(Error)
            return Error.message
        })
    })
}


export function updateQuestionnwithCorrectAnswer(question, activityId) {
    console.log("***********")
    console.log("updateQuestionnwithCorrectAnswer")
    console.log(question.correctAnswerId)
    console.log(question.id)
    console.log(question)
    console.log(question.choices)
    console.log("***********")

    return new Promise(function (resolve, reject) {

        let correctAnswerId = question.correctAnswerId;

        updateQuestion(question, activityId).then((res) => {

            console.log("Correct Ans in Update" + correctAnswerId)

            assignCorrectAnswerToQuestion(question, correctAnswerId).then((res) => {
                resolve(res);
                return "sucess";

            }).catch((Error) => {
                console.log("error in assignCorrectAnswerToQuestion"
                    + " in updateQuestionnwithCorrectAnswer")
                reject(Error);
                return "error"
            })

        }).catch((Error) => {
            console.log("error in updateQuestion" +
                "updateQuestionnwithCorrectAnswer")
            reject(Error);
            return "error"
        })

    })
}
