import React from 'react';

const Footer =() =>
{
    return(
      <footer>
          <div className="footer-text">
              روتس انتجريتد 2020 &copy; جميع الحقوق محفوظة.
          </div>
      </footer>
    )
}
export default Footer;
