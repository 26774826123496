import React, { Component } from 'react'
import { withFirebase } from '../../config';
import { Button, Modal, Popup, Dropdown } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/* firebase */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'

/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import {
    fetchAllAdminsRole,
    fetchAdminRoles
} from '../../Actions/UserRolesActions'

import {
    fetchAllAdmins,
    changeAdminRole,
    emptyAdmin
}
    from '../../Actions/UserManagementActions'


const INITIAL_STATE = {

    showModal: '',
    /////////// 
    selected_userRole_id: '',
    selected_userRole_name: '',

};

class ChangingAdminRole extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {

        // if auth user - Super Admin or Admin changing another admin role
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.fetchAdminRoles(token);
            })

        } else {
            // else 'our normal case'
            this.props.fetchAllAdminsRole()
        }
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }



    handleAdminChange = (e, { value }) => {
        console.log("selected_userRole_id 4 = " + value[0].id);
        console.log("selected_userRole_id 5 = " + value[1].name);

        this.setState({ selected_userRole_id: value[0].id })
        this.setState({ selected_userRole_name: value[1].name })
    }

    handleSubmit = (e) => {

        const { selected_userRole_name } = this.state;
        const { uid } = this.props;
        this.props.changeAdminRole(uid, selected_userRole_name);

        e.preventDefault();

    }

    closeModal = (e) => {
        this.setState({
            showModal: false,
            selected_userRole_id: '',
            selected_userRole_name: ''
        })
    }

    openModal = (e) => {
        const { role, roleId } = this.props;
        console.log("cons")
        console.log(role)
        console.log(roleId)

        this.setState({
            selected_userRole_name: role,
            selected_userRole_id: roleId,
            showModal: true
        })
        
    }

    render() {

        const { userRole } = this.props;
        const userRoleList = [];
        for (let i = 0; i < userRole.length; i++) {
            userRoleList.push({
                key: userRole[i].role_id,
                text: userRole[i].role,
                value: [{ id: userRole[i].role_id }, { name: userRole[i].role }]
            })

        }

        // for dropdown Admin selection
        let selectedRole = null;
        selectedRole = (
            <Dropdown  //className="ui simple dropdown item"
                fluid selection
                placeholder='Select Role'
                name="selected_userRole_name"
                onChange={this.handleAdminChange}
                options={userRoleList}
                value={[{ id: this.state.selected_userRole_id },
                { name: this.state.selected_userRole_name }]} />
        );

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyAdmin();

        }
        else if (success === true) {
            alert("Changing role done Successfully")
            this.props.emptyAdmin();
            this.closeModal();
            this.props.fetchAllAdmins();
        }

        const isInvalid =
            this.state.selected_userRole_name === '';


        return (
            <Modal id="modal"
                trigger={
                    <Popup trigger=
                        {
                            <a className="key" onClick={this.openModal}>
                                <i >
                                    <FontAwesomeIcon icon="user-tag" />
                                </i>
                            </a>}
                        content='Change Role'
                        position='top center'
                    />
                }
                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Change Role</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">


                                    <div className="col">

                                        <div className="form-group ">
                                            <label className="small-label"> Select Role</label>
                                            {selectedRole}

                                            {/* <select id="cars">
  <option value="volvo">Volvo</option>
  <option value="saab">Saab</option>
  <option value="opel">Opel</option>
  <option value="audi">Audi</option>
</select> */}
                                        </div>

                                    </div>


                                </div>
                                <br /><br /><br />
                                <br /><br /><br />
                                <br /><br /><br />
                                <br /><br /><br />

                                <div className="input-field">

                                    <Button
                                        onClick={this.handleSubmit}
                                        disabled={isInvalid}
                                        id="saveButton" >
                                        Save
                                    </Button>

                                </div>
                                <br />
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

ChangingAdminRole.propTypes = {
    fetchAllAdmins: propTypes.func.isRequired,
    fetchAllAdminsRole: propTypes.func.isRequired,
    fetchAdminRoles: propTypes.func.isRequired,
    changeAdminRole: propTypes.func.isRequired,
}
const mapStateToProps = state => ({

    userRole: state.userRole.items,

    showModal: state.admins.showModal,

    loading: state.admins.loading,
    error: state.admins.change_admin_role_error,
    success: state.admins.change_admin_role_sucessfully
});


export default withFirebase(
    connect(
        mapStateToProps,
        {
            fetchAllAdminsRole,
            fetchAdminRoles,
            fetchAllAdmins,
            changeAdminRole,
            emptyAdmin
        }
    )
        (ChangingAdminRole)
);