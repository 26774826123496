
import React, { Component } from "react";
import { deleteSong, emptySong } from "../../Actions/SongActions";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { getSongParentData } from '../../Actions/SongParentAction';
/* Routes */
import { ALL_SONGS } from '../../routes';

class DeleteSong extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    removeSong(id) {
        this.props.deleteSong(id)
    }

    handleSubmit = (e) => {
        const { id } = this.props;
        this.removeSong(id);
        this.close();
    }

    render() {
        const { error_deleteing_song,
            song_deleted_successfully, SongParentId } = this.props

        if (song_deleted_successfully === false) {
            alert("error missing data or network error" + error_deleteing_song);
            this.props.emptySong();

        }
        else if (song_deleted_successfully === true) {
            alert("song deleted successfully");
            this.props.emptySong();
            this.props.getSongParentData(SongParentId);
        }

        return (<div >
            <button className="seriesCardButtons"  onClick={this.open}>
                    <i className="" >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal"
                content='Are You Sure To Delete This Song?? '
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </div>)
    }
}
const mapStateToProps = state =>
({
    error_deleteing_song: state.songs.error_deleteing_song,
    song_deleted_successfully: state.songs.song_deleted_successfully
})
export default connect(
    mapStateToProps,
    {
        deleteSong, emptySong,
        getSongParentData
    })
    (DeleteSong)
