import {ADD_TOKEN, EMPTY_TOKEN } from './Types';

export const addTokenToredux =(token) =>dispatch=>{

        dispatch({
            type: ADD_TOKEN,
            payload: token
        })
    }

    export const emptyToken =() =>dispatch=>{

        dispatch({
            type: EMPTY_TOKEN,
            payload: ''
        })
    }