import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

/*Action */
import { addSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { fitRegister, fitLogin, empty, signOut } from '../../../Actions/AuthActions'
import {
    addSeriesIDtoRedirectionPage,
    emptyExchangeData, redirectionPage
} from '../../../Actions/RedirectionPagesActions'

/*component */
import mainLogoM from '../../../images/portal/logo-big@2x.png';
import Footer from '../Layout/Footer';
import Testimonials from '../Layout/Testimonials';
import Loader from '../../../components/Layout/Loader'
import "../../../css/Fit.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Routes */
import { PORTAL_LAND_PAGE, PORTAL_LOGIN_METHODS, PORTAL_PAYMENT_METHODS } from '../../../routes';

class FitLogin extends Component {

    state =
        {
            type: "",
            fitId: 0,
            register: true,
            email: "",

            phone: "",
            register: true,
            activeRegisterBtn: 'active',
            activeSignInBtn: '',
            activeItemIndex: 0,
            disabled: true,
            loginDisabled: true,
            errorMsg: '',
            error: false,

            openSnakBar: false,
            vertical: 'bottom',
            horizontal: 'right',

            isRevealPassword: false,
            password: "",

            redirect: false,
            loginSuccess: false
        }

    constructor(props) {
        super(props);

        setTimeout(() => {
            // sign out from Firebase
            console.log("Signout fit")

            this.props.signOut();
            this.props.empty();


            // empty redirection page
            this.props.redirectionPage();


            this.passwordOneRef = React.createRef();
            this.iconRevealPassword = React.createRef();
        }, 100);
    }


    componentDidMount() {
        this.props.empty();

        let fitId = this.props.match.params.fitId;
        let type = this.props.match.params.type
        this.setState(
            {
                fitId: fitId,
                type: type
            })

        const { exchangeData } = this.props;
        if (exchangeData
            && exchangeData.parameter1
            && exchangeData.parameter1 !== null
            && exchangeData.parameter1 !== undefined) {
            this.setState({
                email: exchangeData.parameter1,
                password: exchangeData.parameter2,
                phone: exchangeData.parameter3
            })

        }

    }

    handleRegisterForm = () => {
        this.setState({ register: true, activeRegisterBtn: 'active', activeSignInBtn: '' });
    }

    handleSignInForm = () => {
        this.setState({ register: false, activeRegisterBtn: '', activeSignInBtn: 'active' });
    }

    handlePhoneLoginChange = (e) => {
        console.log(e)
        this.setState({ phone: e });

        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '966' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' '
            ) {


                this.setState({ loginDisabled: false })
            } else {
                this.setState({ loginDisabled: true })
            }
        }, 0);
    }

    handlePhoneRegisterChange = (e, country) => {

        this.setState({
            phone: e,
            county: country.name
        });

        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '966' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' '

            ) {
                this.setState({ disabled: false })
            } else {
                this.setState({ disabled: true })
            }
        }, 0);

    }

    handleRegisterChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '966' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' '

            ) {
                this.setState({ disabled: false })
            } else {
                this.setState({ disabled: true })
            }
        }, 0);
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '966' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' '
            ) {


                this.setState({ loginDisabled: false })
            } else {
                this.setState({ loginDisabled: true })
            }
        }, 0);
    }

    handleRegisterSubmit = (e) => {
        const { email, password,
            phone, fitId,
            type, county } = this.state;

        // call Register API
        this.props.fitRegister(email, password, phone, fitId, type, county);

        this.setState({ loginSuccess: true })
        e.preventDefault();
    }

    handleLoginSubmit = (e) => {
        const { email, password, phone, fitId, type } = this.state;

        // call Login API
        this.props.fitLogin(email, password, phone, fitId, type);

        this.setState({ loginSuccess: true })

        e.preventDefault();
    }

    handleSuccessMessages = () => {
        this.props.removeSuccessMessage();
    }

    togglePassword = event => {
        this.setState({ isRevealPassword: !this.state.isRevealPassword })
    }

    redirect = event => {

        if (this.state.phone !== '966' &&
            this.state.phone !== '' && this.state.phone !== null
            && this.state.phone !== undefined && this.state.phone !== ' ') {

            var path = "FitLogin/" + this.state.type + "/" + this.state.fitId
            this.props.addSeriesIDtoRedirectionPage(path, this.state.phone);
            this.setState({ redirect: true });

        } else {
            this.setState({ errorMsg: "يرجى ادخال رقم الهاتف اولا" });

        }
    }


    render() {

        const { isRevealPassword,
            valuePasswordOne,
            valuePasswordTwo, loginSuccess } = this.state;

        let loader = null;
        const { success, errorResponse, loading } = this.props;
        const { vertical, horizontal, redirect } = this.state;
        const { successMessage } = this.props;

        if (redirect === true) {
            return (<Redirect to={PORTAL_LOGIN_METHODS}></Redirect>)

        }

        if (loading === true) {
            loader = (<Loader />)
        }

        if (success === true &&
            loginSuccess === true
        ) {
            let message = 'تم تسجيل البيانات بنجاح'
            this.props.addSuccessMessage(message);
            this.props.empty();
            this.props.emptyExchangeData();

            let redirect = "/FitSuccess/" + this.state.type + "/" + this.state.fitId + "/" + this.state.phone;
            return (<Redirect path to={redirect}> </Redirect>);

        } else if (success === false &&
            (errorResponse !== '' && errorResponse !== undefined
                && loginSuccess === true)
        ) {

            if (errorResponse == "تم تفعيل هذه الباقه من قبل") {
                this.props.addSuccessMessage(errorResponse);

                this.props.emptyExchangeData();
                this.props.empty();
                let redirect = PORTAL_PAYMENT_METHODS;
                return (<Redirect path to={redirect}> </Redirect>);
            } else {

                this.setState({ errorMsg: errorResponse });

                // alert(errorResponse);
                this.props.empty();
                this.props.emptyExchangeData();

            }
        }

        let registerForm = '';
        let signinForm = '';
        let phoneSection = "";
        let loginactive = this.state.loginDisabled

        if (this.state.email !== '' && this.state.email !== null
            && this.state.email !== undefined && this.state.email !== ' ' &&

            this.state.password !== '' && this.state.password !== null
            && this.state.password !== undefined && this.state.password !== ' ' &&

            this.state.phone !== '966' &&
            this.state.phone !== '' && this.state.phone !== null
            && this.state.phone !== undefined && this.state.phone !== ' '
        ) {
            loginactive = false
        }
        let buttonLoginLoader = (
            <div className="custom-form-group w-100 mb-3">
                <button disabled={loginactive}
                    className="btn form-control submit-btn"
                    type="submit" onClick={this.handleLoginSubmit} >
                    تفعيل الأشتراك
                </button>
            </div>
        );

        let buttonSubmitLoader = (
            <div className="custom-form-group">
                <button disabled={this.state.disabled}
                    className="btn form-control submit-btn"
                    type="submit" onClick={this.handleRegisterSubmit} >
                    تفعيل الأشتراك
          </button>
            </div>
        );

        if (this.state.register === true) {

            phoneSection = (
                <div className=" phone-card " >
                    <div className="" >
                        <strong>  رقم الهاتف الذى تم الاشتراك من خلاله
                         </strong>
                        <br /><br />
                        <div className="phoneNum" id="right-lift">
                            <PhoneInput
                                country={'sa'}

                                placeholder="رقم الهاتف"
                                name="phone"
                                value={this.state.phone}
                                onChange={this.handlePhoneRegisterChange.bind(this)}
                            />

                        </div>
                    </div>
                </div>)

            registerForm = (
                <form className="signup-form">

                    <div className="form-group">
                        <input id="font" dir="auto"
                            type="email"
                            className="filed form-control"
                            name="email"
                            placeholder="بريدك الإلكتروني"
                            value={this.state.email}
                            onChange={this.handleRegisterChange} />
                    </div>

                    <div className="form-group">

                        <div className="input-container" style={{ 'position': 'relative' }}>
                            <input id="font"
                                type={isRevealPassword ? "text" : "password"}
                                dir="auto"
                                className="filed form-control"
                                name="password"
                                placeholder="كلمة المرور"
                                value={this.state.password}
                                onChange={this.handleRegisterChange}
                                ref={this.passwordOneRef} />

                            <span onClick={this.togglePassword} ref={this.iconRevealPassword}>
                                <span>
                                    {isRevealPassword ?
                                        <FontAwesomeIcon icon="eye" className="password-eye" /> :
                                        <FontAwesomeIcon icon="eye-slash" className="password-eye" />
                                    }
                                </span>

                            </span>
                        </div>

                    </div>



                    <div className="policies">
                        <div className="text">
                            <p>بتسجيلك فانت توافق على <span className="color-font">
                                شروط الاستخدام و سياسه الخصوصيه

                                </span> </p>

                        </div>
                    </div>
                    {buttonSubmitLoader}

                </form>
            )
        } else {
            phoneSection = (<div className=" phone-card">
                <div className="">
                    <strong>  رقم الهاتف الذى تم الاشتراك من خلاله
              </strong>
                    <br /><br />
                    <div className="phoneNum" id="right-lift">
                        <PhoneInput
                            country={'sa'}

                            id="font"
                            placeholder="رقم الهاتف"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handlePhoneLoginChange} />
                    </div>
                </div>
            </div>)

            signinForm = (
                <form className="signin-form">


                    <div className="form-group">
                        <input id="font"
                            type="email"
                            className=" filed form-control"
                            name="email"
                            placeholder="بريدك الإلكتروني"
                            dir="auto"
                            value={this.state.email}
                            onChange={this.handleChange} />
                    </div>

                    <div className="form-group">

                        <div className="input-container" style={{ 'position': 'relative' }}>
                            <input id="font"
                                type={isRevealPassword ? "text" : "password"}
                                dir="auto"
                                className="filed form-control"
                                name="password"
                                placeholder="كلمة المرور"
                                value={this.state.password}
                                onChange={this.handleChange}
                                ref={this.passwordOneRef} />

                            <span onClick={this.togglePassword} ref={this.iconRevealPassword}>
                                <span>
                                    {isRevealPassword ?
                                        <FontAwesomeIcon icon="eye" className="password-eye" /> :
                                        <FontAwesomeIcon icon="eye-slash" className="password-eye" />
                                    }
                                </span>

                            </span>
                        </div>

                    </div>


                    <div className="custom-form-group w-100 mb-3">
                        <div className="forgot-pw">
                            {/* <NavLink style={{ float: 'right', paddingRight: '5px' }} to="/forgetPassword">نَسِيتُ كلمة المرور!</NavLink> */}
                        </div>
                    </div>

                    {buttonLoginLoader}
                    {/* <div className=" another-ways">
                        <div className=" text">
                            <p>هل قمت بالتسجيل مسبقا عن طريق وسيله اخرى؟</p>

                        </div>
                    </div> */}

                    <div className="another-ways">
                        <div className="text">
                            <p>هل سجَّلت عبر وسيلة أخرى؟</p>
                            <div className="sign-way">
                                <a onClick={this.redirect}>تسجيل الدخول عبر وسيلة أخرى.</a>
                            </div>
                        </div>
                    </div>
                </form>
            )
        }
        return (

            <body>


                <section className="jeel-fit">
                    <div className="logo-container">
                        <div className="container">
                            <div className="logo">
                                <img src={mainLogoM} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-6">
                                <div className="center fit-about-text">
                                    <div className="title h2 white">

                                        بعد اشتراكك عبر الرسائل النصية،
                                        كل ما عليك الآن هو
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-6">
                                <div className="  main-progress-bar">
                                    <ol className="progress-tracker">
                                        <li className="step fit">
                                            <span className=" step-name small-font">
                                                <span className="bold">
                                                    تفعيل الحساب
                                                </span>
                                            </span>
                                        </li>
                                        <li className="step fit">
                                            <span className="step-name small-font">
                                                <span className="notbold">
                                                     تحميل التطبيق
                                                </span>
                                            </span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </section>


                <section className="phone-section" >
                    <div className="row justify-content-md-center ">
                        <div className=" col-12 col-md-6 ">
                            <div className="form-container">

                                {phoneSection}
                            </div>
                        </div>
                    </div>
                </section>

                <br /><br />
                { loader}
                <section className="buttons">
                    <div className="container">
                        <div className="row justify-content-md-center">

                            <div className=" col-12 col-md-6 " id="fit_space" >
                                <p className="h5 text-rtl">
                                    لتفعيل اشتراكك في باقة شركات الاتصالات ،


                                     <strong>
                                        كل ما عليك هو تفعيل حسابك
                                     </strong>
                                </p>
                                <br />
                                <div className="mx-auto text-rtl row">
                                    <p style={{ color: 'red', textAlign: 'right' }}> {this.state.errorMsg}</p>
                                </div>
                            </div>

                            <br /><br />
                        </div>

                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-6">
                                <div className="form-container">
                                    <div className="form-header btn-container">

                                        <button data-toggle-form=".signup-form" className={"btn toggle-btn signin-btn " + this.state.activeRegisterBtn} onClick={this.handleRegisterForm}>انشاء حساب جديد</button>

                                        <button data-toggle-form=".signin-form" className={"btn toggle-btn signup-btn " + this.state.activeSignInBtn} onClick={this.handleSignInForm}>تسجيل دخول</button>


                                    </div>
                                    {registerForm}
                                    {signinForm}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Testimonials />
                {/** Start Footer **/}
                <Footer />
                {/** End Footer */}
            </body >

        )
    }
}

const mapStateToProps = state => ({
    success: state.authUser.success,
    errorResponse: state.authUser.errorMsg,
    loading: state.authUser.loading,
    successMessage: state.successMessages.item,
    exchangeData: state.redirectionPage.item,

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                fitRegister,
                empty,
                fitLogin,
                addSuccessMessage,
                addSeriesIDtoRedirectionPage,
                emptyExchangeData,
                redirectionPage,
                signOut
            })
        (FitLogin)
    );
