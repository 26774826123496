import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Components */
import FAQ from './FAQ'
import AddFAQ from './AddFAQ'
import EditFAQ from './EditFAQ'
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_FAQ, ADD_FAQ, EDIT_FAQ } from '../../routes';

class FAQAuth extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { userPaymentProfile } = this.props;
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    const { userPaymentProfile } = this.props;

    if(userPaymentProfile.user_role.role === "Analyst"){
      return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
    }
    return (
      <span>
        {/* FAQ */}
        <Route path={ALL_FAQ} component={FAQ} />
        <Route path={ADD_FAQ} component={AddFAQ} />
        <Route path={EDIT_FAQ} component={EditFAQ} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(FAQAuth)
);
