import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { Accordion, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

/*Component*/
import DeleteSubValue from './DeleteSubValue'
import AddSubValue from './AddSubValue'
import EditSubValue from './EditSubValue'

class ContentsubValues extends Component {

    render() {

        const { subvalues, mainValueId } = this.props;

        let SubValuesList = (
            subvalues.map((el, i) => {

                return (
                    <div class="col-sm-6 col-md-4"
                        key={el.id}
                        style={{ paddingTop: '10px' }}>
                        <div class="card unitbox">
                            <div class="card-body">
                                <img className="image_Preview"
                                    alt="img-subValue" src={el.imageUrl} />

                            </div>
                            <div className="card-footer">
                                <div className="row">
                                    <div className="col-8">
                                        <label
                                            class="p-0 title font-weight-bold">Value</label>
                                    </div>
                                    <div className="btn-group col-4">
                                        <EditSubValue subValue={el} />
                                        <span> | </span>
                                        <DeleteSubValue id={el.id} />
                                    </div>
                                    <div className="averageAge col-12">{el.contentValue}
                                    </div>

                                </div>

                                <div>

                                </div>
                            </div>
                        </div>
                    </div>

                )
            })

        )



        return (
            <div className="container">
                <div className="general-setting-form" >

                    <fieldset className='fieldset'>
                        <legend className="legend">
                            Sub Values
                            </legend>
                        <div className="row">

                            {SubValuesList}
                            <div className="col-sm-6 col-md-4" style={{ paddingTop: '10px' }}>
                                <AddSubValue mainContentId={mainValueId} />

                            </div>
                        </div>

                    </fieldset>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
})
export default
    connect(
        mapStateToProps,
        {
        }
    )
        (ContentsubValues);
