import {
    FETCH_ALL_SECTION, LOAD_SECTION, ERROR_LOADING_ALL_SECTION,
    UPDATE_SECTION_SUCCESSFULLY, UPDATE_SECTION_ERROR,
    GET_SECTION_DATA_SUCCESSFULLY, GET_SECTION_DATA_ERROR,
    EMPTY_SECTION, ADD_SECTION_SUCCESSFULLY, ADD_SECTION_ERROR,
    DELETE_SECTION_SUCCESSFULLY, DELETE_SECTION_ERROR,
} from './Types'
import { get, post, put, axios } from 'axios';

export const fetchAllDynamicSections = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SECTION,
                payload: 'loading'
            })
            const url = "/DynamicSection/getAllDynamicSectionList"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            get(url, formData, config).then((res) => {
                dispatch({
                    type: FETCH_ALL_SECTION,
                    payload: res.data
                })

            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_ALL_SECTION,
                    payload: Error.message
                })
            })

        })

    }
}

export const saveDynamicSection = (active, category, title, type, titleInEnglish) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SECTION,
                payload: 'loading'
            })
            const url = '/DynamicSection/addDynamicSection';
            const formData = new FormData();
            formData.append('active', active);
            formData.append('category', category);
            formData.append('title', title);
            formData.append('type', type);
            formData.append('titleInEnglish', titleInEnglish);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then(() => {
                dispatch({
                    type: ADD_SECTION_SUCCESSFULLY
                })
            }).catch((error) => {
                dispatch({
                    type: ADD_SECTION_ERROR,
                    payload: error.message
                })
            })
        })
    }
}

export const editDynamicSection = (id, active, category, title, type, titleInEnglish) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SECTION,
                payload: 'loading'
            })
            const url = '/DynamicSection/editDynamicSection';
            const formData = new FormData();
            formData.append('id', id);
            formData.append('active', active);
            formData.append('category', category);
            formData.append('title', title);
            formData.append('type', type);
            formData.append('titleInEnglish', titleInEnglish);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_SECTION_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: UPDATE_SECTION_ERROR,
                    payload: Error.message
                })
            })
        })

    }
}

export const getDynamicSectionDetails = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SECTION,
                payload: 'loading'
            })
            const url = "/DynamicSection/getDynamicSectionDetails";
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                },
                params: {
                    id: id
                }
            }
            get(url, config).then((res) => {
                dispatch(
                    {
                        type: GET_SECTION_DATA_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: GET_SECTION_DATA_ERROR,
                    payload: Error.message
                })
            })
        })
    }
}

export const deleteDynamicSection = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SECTION,
                payload: 'loading'
            })
            const url = "/DynamicSection/deleteDynamicSection";
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                },
                params: {
                    id: id
                }
            }
            get(url, config).then((res) => {
                dispatch(
                    {
                        type: DELETE_SECTION_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: DELETE_SECTION_ERROR,
                    payload: Error.message
                })
            })
        })

    }
}

export const emptyDynamicSection = () => dispatch => {
    dispatch({
        type: EMPTY_SECTION,
        payload: ""
    })
}