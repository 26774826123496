import {EMPTY} from './Types'


export const emptyError = () => dispatch =>
{
    // console.log("Empty Actions")
    dispatch({
        type:EMPTY,
        payload:''
    })
}

