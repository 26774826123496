import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Button, Modal } from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { updateTermsAndConditions } from '../../Actions/TermsAndConditionsActions'
import { emptyError } from '../../Actions/Empty'
import { fetchAllTermsAndConditions } from '../../Actions/TermsAndConditionsActions'

const INITIAL_STATE = {

    showModal: '',
    /////////// FAQ
    id: 0,
    title: '',
    text: '',
    /////////// validation
    formErrors: { question: '', answer: '' }
};

class EditTermsAndconditions extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }


    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    updateTermsAndConditions(termsAndConditions_ID, title, text) {
        this.props.updateTermsAndConditions(termsAndConditions_ID, title, text);
    }

    handleChange = (e) => {
        let validation = this.state.formErrors;
        validation.title = ''; validation.text = '';
        this.setState({ formErrors: validation });
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            if (this.state.title === '' || this.state.title === ' ') {
                validation.title = "Question can not be empty";
                this.setState({ formErrors: validation });
            }

            if (this.state.text === '' || this.state.text === ' ') {
                validation.text = "Answer can not be empty";
                this.setState({ formErrors: validation });
            }
        }, 0);
    }

    handleSubmit = (e) => {

        const { id, title, text } = this.state;

        this.updateTermsAndConditions(id, title, text);

        e.preventDefault();
        this.setState({
            id: 0,
            title: '',
            text: '',
        })

    }

    closeModal = (e) => {
        this.setState({ showModal: false })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
        const { termsandconditionsItem } = this.props;
        this.setState({
            id: termsandconditionsItem.id,
            title: termsandconditionsItem.title,
            text: termsandconditionsItem.text,
        })
    }

    render() {
        const { title, text } = this.state;

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Terms And Conditions Updated Successfully")
            this.props.emptyError();

            this.closeModal();
            this.props.fetchAllTermsAndConditions();
        }

        return (
            <Modal id="modal"
                trigger={
                    <button className="acor-btn1" type="submit" onClick={this.openModal}>
                        Edit
                    </button>
                }

                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Edit Terms And Conditions</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">


                                    <div className="col">

                                        <label htmlFor="title" className="label">Title</label>
                                        <input
                                            dir="auto"
                                            className="form-control"
                                            name="title"
                                            id="title"
                                            onChange={this.handleChange}
                                            value={this.state.title} />
                                        <p style={{ color: "red" }}>{this.state.formErrors.title} </p>
                                        <br />

                                        <label htmlFor="text" className="label">Text</label>
                                        <textarea
                                            dir="auto"
                                            className="form-control"
                                            rows="6"
                                            name="text"
                                            id="episode_description"
                                            onChange={this.handleChange}
                                            value={this.state.text} />
                                        <p style={{ color: "red" }}>{this.state.formErrors.text} </p>
                                        <br />
                                    </div>

                                </div>

                                <div className="input-field">

                                    <Button disabled={this.state.formErrors.title || this.state.formErrors.text} onClick={this.handleSubmit} id="saveButton" >
                                        Save
                                    </Button>


                                </div>
                                {error && <p>{error.message}</p>}
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

EditTermsAndconditions.propTypes = {
    updateTermsAndConditions: propTypes.func.isRequired,
    fetchAllTermsAndConditions: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    termsandconditions: state.termsandconditions.item,
    showModal: state.termsandconditions.showModal,
    error: state.termsandconditions.updating_all_terms_Conditions_error,
    success: state.termsandconditions.updating_all_terms_Conditions__sucessfully
});


export default withFirebase(connect(mapStateToProps, { updateTermsAndConditions, emptyError, fetchAllTermsAndConditions })(EditTermsAndconditions));
