import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { Redirect } from "react-router-dom";

/*Navbar*/
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
/* component */
import PublishDate from "../Layout/PublishDate";
import UploadImage from "../S3Uploading/UploadImage";
import UploadAudio from "../S3Uploading/UploadAudio";
import UploadVideo from "../S3Uploading/UploadVideo";

import {
  saveDiscoverUsSeries,
  emptyDiscoverUsSeries,
} from "../../Actions/DiscoverUsSeriesActions";
import { fetchDiscoverUsSeries } from "../../Actions/DiscoverUsSeriesActions";

/* Routes */
import { ALL_DISCOVER_SERIES } from "../../routes";
import { clearPreSignData, clearAll } from "../../Actions/VideoCipherActions";
import IntroWithMusicUploader from "../FilePondUploading/IntroWithMusicUploader";
import IntroWithoutMusicUploader from "../FilePondUploading/IntroWithoutMusicUploader";
import PromoWithMusicUploader from "../FilePondUploading/PromoWithMusicUploader";
import PromoWithoutMusicUploader from "../FilePondUploading/PromoWithoutMusicUploader";
import {DISCOVER_FOLDER_NAME_WITH_MUSIC, DISCOVER_FOLDER_NAME_WITH_OUT_MUSIC } from "../../portal/src/constants";
const INITIAL_STATE = {
  showModel: "",
  name: "",
  description: "",
  name_in_english: "",
  description_in_english: "",
  PublishDate: "",
  image: "",
  error: "",
  audio: "",
  promo_with_music: "",
  promo_without_music: "",
  intro_with_music: "",
  intro_without_music: "",
  videoKeyIntroWithMusic: "",
  videoKeyIntroWithoutMusic: "",
  videoKeyPromoWithMusic: "",
  videoKeyPromoWithoutMusic: "",
  featured: false,
  /////////////////// validation form
  error: true,
  errorMessage: "",
  ////////////////// cancel Button
  canceled: false,
};

class AddDiscoverUsSeries extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(nextprops, state) {
    if (nextprops.showModal) {
      return {
        showModal: nextprops.showModal,
      };
    }
    return state;
  }

  saveDiscoverUsSeries(
    name,
    description,
    image,
    PublishDate,
    audio,
    promo_with_music,
    promo_without_music,
    intro_with_music,
    intro_without_music,
    name_in_english,
    description_in_english,
    videoKeyIntroWithMusic,
    videoKeyIntroWithoutMusic,
    videoKeyPromoWithMusic,
    videoKeyPromoWithoutMusic,
    featured
  ) {
    // let introWithMusicVideoId = this.props.vdocipherUploadInfo.IntroWithMusic
    //   ? this.props.vdocipherUploadInfo.IntroWithMusic.videoId
    //   : "";
    // let introWithoutMusicVideoId = this.props.vdocipherUploadInfo
    //   .IntroWithoutMusic
    //   ? this.props.vdocipherUploadInfo.IntroWithoutMusic.videoId
    //   : "";
    // let promoWithMusicVideoId = this.props.vdocipherUploadInfo.PromoWithMusic
    //   ? this.props.vdocipherUploadInfo.PromoWithMusic.videoId
    //   : "";
    // let promoWithoutMusicVideoId = this.props.vdocipherUploadInfo
    //   .PromoWithoutMusic
    //   ? this.props.vdocipherUploadInfo.PromoWithoutMusic.videoId
    //   : "";
    this.props.saveDiscoverUsSeries(
      name,
      description,
      image,
      PublishDate,
      audio,
      promo_with_music,
      promo_without_music,
      intro_with_music,
      intro_without_music,
      name_in_english,
      description_in_english,
      videoKeyIntroWithMusic,
      videoKeyIntroWithoutMusic,
      videoKeyPromoWithMusic,
      videoKeyPromoWithoutMusic,
      featured
    );
  }

  getPublishDate(publishDateTime) {
    if (publishDateTime) {
      this.setState({ publish_date: publishDateTime });

    }
  }

  getImageUrl(imageUrl) {
    if (imageUrl === "delete") {
      this.setState({
        image: "",
        error: true,
        errorMessage: "Empty Data is not accepted",
      });
    } else if (imageUrl !== undefined || imageUrl !== "") {
      this.setState({ image: imageUrl });
      if (
        this.state.name !== "" &&
        this.state.name !== " " &&
        this.state.name !== null
        //    && this.state.name_in_english !== '' && this.state.name_in_english !== ' ' && this.state.name_in_english !== null
      ) {
        this.setState({ error: false, errorMessage: "" });
      }

    } else {
      this.setState({
        img: "",
        error: true,
        errorMessage: "Empty Data is not accepted",
      });
    }
  }

  getPromoWithMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {

    } else {
      this.setState({ promo_with_music: "" });
    }
  }

  getPromoWithoutMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ promo_without_music: videoUrl });

    } else {
      this.setState({ promo_without_music: "" });
    }
  }

  getIntroWithoutMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ intro_without_music: videoUrl });

    } else {
      this.setState({ intro_without_music: "" });
    }
  }

  getIntroWithMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ intro_with_music: videoUrl });

    } else {
      this.setState({ intro_with_music: "" });
    }
  }

  handleUploadIntroWithMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyIntroWithMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyIntroWithMusic: "" });
    }
  };
  handleUploadIntroWithoutMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyIntroWithoutMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyIntroWithoutMusic: "" });
    }
  };
  handleUploadPromoWithMusic = (uploadInfo) => {
   
    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ videoKeyPromoWithMusic: uploadInfo.videoId });
    } else {
      this.setState({ videoKeyPromoWithoutMusic: "" });
    }
  };
  handleUploadPromoWithoutMusic = (uploadInfo) => {
  
    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({
        videoKeyPromoWithoutMusic: uploadInfo.videoId,
      });
    } else {
      this.setState({ videoKeyPromoWithoutMusic: "" });
    }
  };

  handleChange = (e) => {
    //
    let checkSpaces = e.target.value.replace(/\s\s+/g, " ");
    const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
    this.setState({ [e.target.name]: checkSpaces });
    let nameOfState = e.target.name;
    setTimeout(() => {
      if (specialCharacters.test(this.state.name) || specialCharacters.test(this.state.name_in_english)){
        this.setState({
          error: true,
          errorMessage: "Series name can't contain spaecial characters",
        });
      }
      else if (  
        this.state.image === "" ||
        this.state.image === null ||
        this.state.image === undefined ||
        this.state.name === "" ||
        this.state.name === null ||
        this.state.name === " " ||
        this.state.description_in_english.length > 255
        //  || (this.state.name_in_english === '' || this.state.name_in_english === null || this.state.name_in_english === ' ')
      ) {
        this.setState({
          error: true,
          errorMessage:
            nameOfState === "description_in_english"
              ? "Description in English is too long (greater than 255 characters)."
              : "Empty Data is not accepted",
        });
      }
      else if (
        (this.state.image !== "" || this.state.image !== undefined) &&
        (this.state.name !== null ||
          this.state.name !== "" ||
          this.state.name !== " ") &&
        this.state.description_in_english.length <= 255
        // && (this.state.name_in_english !== null || this.state.name_in_english !== '' || this.state.name_in_english !== ' ')
      ) {
        this.setState({ error: false, errorMessage: "" });
      } else {
        this.setState({
          error: true,
          errorMessage: "Empty Data is not accepted",
        });
      }
    }, 0);
  };

  handleSubmit = (e) => {
    const {
      name,
      description,
      PublishDate,
      image,
      audio,
      promo_with_music,
      promo_without_music,
      intro_with_music,
      intro_without_music,
      name_in_english,
      description_in_english,
      videoKeyIntroWithMusic,
      videoKeyIntroWithoutMusic,
      videoKeyPromoWithMusic,
      videoKeyPromoWithoutMusic,
      featured
    } = this.state;

    if (e.nativeEvent.submitter.id === "saveButton") {
      this.saveDiscoverUsSeries(
        name,
        description,
        image,
        PublishDate,
        audio,
        promo_with_music,
        promo_without_music,
        intro_with_music,
        intro_without_music,
        name_in_english,
        description_in_english,
        videoKeyIntroWithMusic,
        videoKeyIntroWithoutMusic,
        videoKeyPromoWithMusic,
        videoKeyPromoWithoutMusic,
        featured
      );
    }
    e.preventDefault();
  };

  handleCancel = (e) => {
    this.setState({ canceled: true });
  };

  handleCheckboxChange = () => {
    this.setState({ featured: !this.state.featured })
  };

  getAudio(audioUrl) {
    // get  Audio
    if (audioUrl) {
      // check if url =  delete send empty to DB
      if (audioUrl === "delete") {
        this.setState({ audio: "" });
  
      }
      // else send the url to DB
      else {
        this.setState({ audio: audioUrl });
   
      }
    }
  }

  render() {
    const { name, image, error } = this.state;
    const {
      error_adding_discover_us_series,
      discover_us_series_added_successfully,
    } = this.props;

    const isInvalidSeries = name === "" || image === "";

    const { loading } = this.props;

    let $buttonSubmitLoader = (
      <div>
        <Button disabled={this.state.error} id="saveButton">
          Add
        </Button>
        <Button
          style={{ float: "left" }}
          id="episode_cancel"
          onClick={this.handleCancel}
        >
          Cancel
        </Button>
      </div>
    );

    if (loading === true) {
      $buttonSubmitLoader = (
        <button id="saveButton" class="ui loading button">
          Loading
        </button>
      );
    }

    if (discover_us_series_added_successfully === false) {
      alert("error" + error_adding_discover_us_series);
      this.props.emptyDiscoverUsSeries();
      this.props.clearAll();
    } else if (discover_us_series_added_successfully === true) {
      alert("Discover With Us series added successfully");
      this.props.emptyDiscoverUsSeries();
      this.props.fetchDiscoverUsSeries();
      this.props.clearAll();

      this.setState({
        name: "",
        description: "",
        PublishDate: "",
        image: "",
        audio: "",
        promo_with_music: "",
        promo_without_music: "",
        intro_with_music: "",
        intro_without_music: "",
        videoKeyIntroWithMusic: "",
        videoKeyIntroWithoutMusic: "",
        videoKeyPromoWithMusic: "",
        videoKeyPromoWithoutMusic: "",
        name_in_english: "",
        description_in_english: "",
        featured: false,
      });

      return <Redirect to={ALL_DISCOVER_SERIES}></Redirect>;
    }
    if (this.state.canceled === true) {
      return <Redirect to={ALL_DISCOVER_SERIES}></Redirect>;
    }
    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <NavBar />
                      <br />
                      <div className="container">
                        <h3>Add New Learning & Skills</h3>
                        <hr />

                        <div className="">
                          <form onSubmit={this.handleSubmit.bind(this)}>
                            <div className="row">
                              {/* image Uploader */}
                              <div className="col-md-4 col-12">
                                <div className="">
                                  <span className="required_input">*</span>
                                  <UploadImage
                                    getImageURLCallBack={this.getImageUrl.bind(
                                      this
                                    )}
                                    imageUrl={this.state.image}
                                    imagepreview={this.state.image}
                                    s3DirectryrName="discoverusseries"
                                  />
                                </div>
                              </div>

                              <div className="col-md-8 col-12">
                                <p style={{ color: "red" }}>
                                  {this.state.errorMessage}{" "}
                                </p>
                                <label htmlFor="name" className="label">
                                  <span className="required_input">*</span>
                                  Name
                                </label>
                                <input
                                  dir="auto"
                                  className="form-control"
                                  fluid
                                  name="name"
                                  id="name"
                                  onChange={this.handleChange}
                                  value={this.state.name}
                                />
                                <br />

                                <label htmlFor="name" className="label">
                                  {/* <span className="required_input">*</span> */}
                                  Name In English
                                </label>
                                <input
                                  dir="auto"
                                  className="form-control"
                                  fluid
                                  name="name_in_english"
                                  id="name_in_english"
                                  onChange={this.handleChange}
                                  value={this.state.name_in_english}
                                />
                                <br />

                                <div className="form-group">
                                  <label
                                    htmlFor="description"
                                    className="label"
                                  >
                                    Description
                                  </label>

                                  <textarea
                                    dir="auto"
                                    fluid
                                    rows="3"
                                    className="form-control"
                                    name="description"
                                    id="description"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                  />
                                </div>
                                <br />

                                <div className="form-group">
                                  <label
                                    htmlFor="description_in_english"
                                    className="label"
                                  >
                                    Description In English
                                  </label>

                                  <textarea
                                    dir="auto"
                                    fluid
                                    rows="3"
                                    className="form-control"
                                    name="description_in_english"
                                    id="description_in_english"
                                    onChange={this.handleChange}
                                    value={this.state.description_in_english}
                                  />
                                </div>
                                <br />

                                <label htmlFor="name" className="label">
                                  Audio
                                </label>

                                <UploadAudio
                                  getAudioUrlCallBack={this.getAudio.bind(this)}
                                  audioPreview={this.state.audio}
                                  audioUrl={this.state.audio}
                                  s3DirectryrName="discoverusseries/audio"
                                />

                                {/* DateComponent */}
                                <PublishDate
                                  getPublishDateCallBack={this.getPublishDate.bind(
                                    this
                                  )}
                                  value={this.state.publish_date}
                                />
                                <br />
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={this.state.featured}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  {' Featured'}
                                </label>
                                <br />
                              </div>
                            </div>

                            {error && <p>{error.message}</p>}
                            <label className="label">Intro With Music</label>
                            <div>
                              <IntroWithMusicUploader
                                id="IntroWithMusic"
                                onUploadInfo={this.handleUploadIntroWithMusic}
                                folderName={DISCOVER_FOLDER_NAME_WITH_MUSIC}
                              />
                            </div>
                            <br />
                            <label className="label">Intro Without Music</label>
                            <div>
                              <IntroWithoutMusicUploader
                                id="IntroWithoutMusic"
                                onUploadInfo={
                                  this.handleUploadIntroWithoutMusic
                                }
                                folderName={DISCOVER_FOLDER_NAME_WITH_OUT_MUSIC}
                              />
                            </div>
                            <br />
                            <label className="label">Promo With Music</label>
                            <div>
                              <PromoWithMusicUploader
                                id="PromoWithMusic"
                                onUploadInfo={this.handleUploadPromoWithMusic}
                                folderName={DISCOVER_FOLDER_NAME_WITH_MUSIC}
                              />
                            </div>
                            <br />
                            <label className="label">Promo Without Music</label>
                            <div>
                              <PromoWithoutMusicUploader
                                id="PromoWithoutMusic"
                                onUploadInfo={
                                  this.handleUploadPromoWithoutMusic
                                }
                                folderName={DISCOVER_FOLDER_NAME_WITH_OUT_MUSIC}
                              />
                            </div>
                            <br />

                            {/* <label className="label">Intro with music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getIntroWithMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.intro_with_music}
                                videoUrl={this.state.intro_with_music}
                                s3DirectryrName="discoverwithus"
                              />
                            </div>
                            <br />

                            <label className="label">Intro without music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getIntroWithoutMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.intro_without_music}
                                videoUrl={this.state.intro_without_music}
                                s3DirectryrName="discoverwithus"
                              />
                            </div>
                            <br />

                            <label className="label">Promo with music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getPromoWithMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.promo_with_music}
                                videoUrl={this.state.promo_with_music}
                                s3DirectryrName="discoverwithus"
                              />
                            </div>
                            <br />

                            <label className="label">Promo without music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getPromoWithoutMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.promo_without_music}
                                videoUrl={this.state.promo_without_music}
                                s3DirectryrName="discoverwithus"
                              />
                            </div>
                            <br /> */}

                            <div className="input-field">
                              {$buttonSubmitLoader}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

AddDiscoverUsSeries.propTypes = {
  saveDiscoverUsSeries: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.discoverUsSeries.loading,
  discoverUsSeries: state.discoverUsSeries.items,
  showModal: state.discoverUsSeries.showModal,
  error_adding_discover_us_series:
    state.discoverUsSeries.error_adding_discover_us_series,
  discover_us_series_added_successfully:
    state.discoverUsSeries.discover_us_series_added_successfully,
  // vdocipherUploadInfo: state.vdoCipher.entity,
});
export default connect(mapStateToProps, {
  saveDiscoverUsSeries,
  emptyDiscoverUsSeries,
  fetchDiscoverUsSeries,
  clearPreSignData,
  clearAll,
})(AddDiscoverUsSeries);
