import React, { Component } from "react";
import { withFirebase } from "../../config";
import { Button } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
/*Redux */
import "firebase/firestore";
import "firebase/auth";
import { connect } from "react-redux";
import propTypes from "prop-types";
/*Actions */
import {
  updateSeries,
  fetchAllSeries,
  getSeriesData,
} from "../../Actions/SeriesActions";
import { emptyError } from "../../Actions/Empty";

/*Navbar*/
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";

/*Component */
import PublishDate from "../Layout/PublishDate";
import Loader from "../Layout/Loader";
import UploadAudio from "../S3Uploading/UploadAudio";
import UploadVideo from "../S3Uploading/UploadVideo";
import UploadImage from "../S3Uploading/UploadImage";

/*CSS */
import "../../css/AppCss/Series/AddSeries.css";

/* Routes */
import { SIGN_IN, ALL_SERIES, VIEW_SERIES } from "../../routes";
import RenderIntroWithMusic from "../VideoPreview/RenderIntroWithMusic";
import RenderIntroWithoutMusic from "../VideoPreview/RenderIntroWithoutMusic";
import RenderPromoWithMusic from "../VideoPreview/RenderPromoWithMusic";
import RenderPromoWithoutMusic from "../VideoPreview/RenderPromoWithoutMusic";
import { SERIES_FOLDER_NAME_WITH_MUSIC, SERIES_FOLDER_NAME_WITH_OUT_MUSIC } from "../../portal/src/constants";

const INITIAL_STATE = {
  sentFrom: "",
  seriesItem: "",
  /////////// Series
  id: "",
  name: "",
  desscription: "",
  publish_date: "",
  status: "",
  error: "",

  imageUrl: "",
  audio: "",

  promo_with_music: "",
  promo_without_music: "",
  intro_with_music: "",
  intro_without_music: "",

  video_key_promo_with_music: "",
  video_key_promo_without_music: "",
  video_key_intro_with_music: "",
  video_key_intro_without_music: "",
  featured: false,
  cancel: false,
  /////////////// validation
  errorMessage: "",
  nameInEnglish: "",
  descriptionInEnglish: "",
};

class UpdateSeries extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    const { series } = this.props;
    var seriesItem = series.seriesItem;
    this.setState({
      sentFrom: series.sentFrom,
      seriesItem: series.seriesItem,
    });

    this.setState({
      id: seriesItem.id,
      name: seriesItem.name,
      description: seriesItem.description,
      imageUrl: seriesItem.image,
      status: seriesItem.status,
      audio: seriesItem.voice,
      publish_date: seriesItem.publishDate,
      nameInEnglish: seriesItem.nameInEnglish,
      descriptionInEnglish: seriesItem.descriptionInEnglish,
      featured: seriesItem.featured
    });


    if (
      seriesItem.introWithMusicId !== null &&
      seriesItem.introWithMusicId !== undefined
    ) {
      this.setState({
        intro_with_music: seriesItem.introWithMusicId.url,
        video_key_intro_with_music: seriesItem.introWithMusicId.vdocipherId,
      });
    }

    if (
      seriesItem.introWithoutMusicId !== null &&
      seriesItem.introWithoutMusicId !== undefined
    ) {
      this.setState({
        intro_without_music: seriesItem.introWithoutMusicId.url,
        video_key_intro_without_music: seriesItem.introWithoutMusicId.vdocipherId,
      });
    }

    if (
      seriesItem.promoWithMusicId !== null &&
      seriesItem.promoWithMusicId !== undefined
    ) {
      this.setState({
        promo_with_music: seriesItem.promoWithMusicId.url,
        video_key_promo_with_music: seriesItem.promoWithMusicId.vdocipherId,
      });
    }

    if (
      seriesItem.promoWithoutMusicId !== null &&
      seriesItem.promoWithoutMusicId !== undefined
    ) {
      this.setState({
        promo_without_music: seriesItem.promoWithoutMusicId.url,
        video_key_promo_without_music: seriesItem.promoWithoutMusicId.vdocipherId,
      });
    }

    const { auth } = this.props;
    if (!auth.uid) {
      return <Redirect to={SIGN_IN}></Redirect>;
    }

    // if (seriesItem.nameInEnglish !== null && seriesItem.nameInEnglish !== undefined) {
    //     this.setState({ errorMessage: "" });
    // }else {
    // this.setState({ errorMessage: "Empty Data is not accepted" });
    // }
  }

  updateSeries(
    id,
    name,
    description,
    status,
    imageUrl,
    publishDate,
    audio,
    promo_with_music,
    promo_without_music,
    intro_with_music,
    intro_without_music,
    nameInEnglish,
    descriptionInEnglish,
    video_key_promo_with_music,
    video_key_promo_without_music,
    video_key_intro_with_music,
    video_key_intro_without_music,
    featured
  ) {
    this.props.updateSeries(
      id,
      name,
      description,
      status,
      imageUrl,
      publishDate,
      audio,
      promo_with_music,
      promo_without_music,
      intro_with_music,
      intro_without_music,
      nameInEnglish,
      descriptionInEnglish,
      video_key_promo_with_music,
      video_key_promo_without_music,
      video_key_intro_with_music,
      video_key_intro_without_music,
      featured
    );
  }

  getPublishDate(publishDateTime) {
    if (publishDateTime) {
      this.setState({ publish_date: publishDateTime });
    }
  }

  getPromoWithMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ promo_with_music: videoUrl });

    } else {
      this.setState({ promo_with_music: "" });
    }
  }

  getPromoWithoutMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ promo_without_music: videoUrl });


    } else {
      this.setState({ promo_without_music: "" });
    }
  }

  getIntroWithoutMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ intro_without_music: videoUrl });


    } else {
      this.setState({ intro_without_music: "" });
    }
  }

  getIntroWithMusicVideoUrl(videoUrl) {
    if (videoUrl !== "delete") {
      this.setState({ intro_with_music: videoUrl });


    } else {
      this.setState({ intro_with_music: "" });
    }
  }

  handleChangeIntroWithMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ video_key_intro_with_music: uploadInfo.videoId });
    }

    else {
      this.setState({ video_key_intro_with_music: this.state.video_key_intro_with_music});
    }
  };
  handleChangeIntroWithoutMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ video_key_intro_without_music: uploadInfo.videoId });
    }
    else {
      this.setState({ video_key_intro_without_music: this.state.video_key_intro_without_music});
    }
  };
  handleChangePromoWithMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ video_key_promo_with_music: uploadInfo.videoId });
    }
    else {
      this.setState({ video_key_promo_with_music: this.state.video_key_promo_with_music});
    }
  };
  handleChangePromoWithoutMusic = (uploadInfo) => {

    if (
      uploadInfo &&
      uploadInfo !== undefined &&
      uploadInfo !== null &&
      Object.keys(uploadInfo).length > 0
    ) {
      this.setState({ video_key_promo_without_music: uploadInfo.videoId });
    }

    else {
      this.setState({ video_key_promo_without_music: this.state.video_key_promo_without_music});
    }
  };

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, " ");
    const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
    this.setState({ [e.target.name]: checkSpaces });
    let nameOfState = e.target.name;
    setTimeout(() => {
      if (specialCharacters.test(this.state.name) || specialCharacters.test(this.state.nameInEnglish)){
        this.setState({
          error: true,
          errorMessage: "Series name can't contain spaecial characters",
        });
      }
      else if (
        this.state.imageUrl === "" ||
        this.state.imageUrl === null ||
        this.state.imageUrl === undefined ||
        this.state.name === "" ||
        this.state.name === null ||
        this.state.name === " " ||
        this.state.descriptionInEnglish.length > 255
      ) {
        this.setState({
          error: true,
          errorMessage:
            nameOfState === "descriptionInEnglish"
              ? "Description in English is too long (greater than 255 characters)."
              : "Empty Data is not accepted",
        });
      }
      else if (
        (this.state.imageUrl !== "" || this.state.imageUrl !== undefined) &&
        (this.state.name !== null ||
          this.state.name !== "" ||
          this.state.name !== " ") &&
        this.state.descriptionInEnglish.length <= 255
      ) {
        this.setState({ errorMessage: "" });
      } else {
        this.setState({ errorMessage: "Empty Data is not accepted" });
      }
    }, 0);
  };

  handelCancel = (e) => {
    this.setState({ cancel: true });
  };

  handleCheckboxChange = () => {
    this.setState({ featured: !this.state.featured })
  };

  handleSubmit = (e) => {
    const {
      id,
      name,
      description,
      status,
      imageUrl,
      publish_date,
      audio,
      promo_with_music,
      promo_without_music,
      intro_with_music,
      intro_without_music,
      nameInEnglish,
      descriptionInEnglish,
      video_key_promo_with_music,
      video_key_promo_without_music,
      video_key_intro_with_music,
      video_key_intro_without_music,
      featured
    } = this.state;

    if (e.nativeEvent.submitter.id === "saveButton") {
      this.updateSeries(
        id,
        name,
        description,
        status,
        imageUrl,
        publish_date,
        audio,
        promo_with_music,
        promo_without_music,
        intro_with_music,
        intro_without_music,
        nameInEnglish,
        descriptionInEnglish,
        video_key_promo_with_music,
        video_key_promo_without_music,
        video_key_intro_with_music,
        video_key_intro_without_music,
        featured
      );
    }
    e.preventDefault();
  };

  getAudio(audioUrl) {
    if (audioUrl) {
      // check if url =  delete send empty to DB
      if (audioUrl === "delete") {
        this.setState({ audio: "" });

      }

      // else send the url to DB
      else {
        this.setState({ audio: audioUrl });

      }
    }
  }

  getImageUrl(imageUrl) {
    if (imageUrl === "delete") {
      this.setState({
        imageUrl: "",
        error: true,
        errorMessage: "Empty Data is not accepted",
      });
    } else if (imageUrl !== undefined || imageUrl !== "") {
      this.setState({ imageUrl: imageUrl });
      if (
        this.state.name !== "" &&
        this.state.name !== " " &&
        this.state.name !== null
      ) {
        this.setState({ error: false, errorMessage: "" });
      }

    } else {
      this.setState({
        imageUrl: "",
        error: true,
        errorMessage: "Empty Data is not accepted",
      });
    }
  }

  render() {
    const { seriesError, seriesSuccess } = this.props;
    const { sentFrom, seriesItem } = this.state;

    if (seriesSuccess === false) {
      alert(seriesError);
      this.props.emptyError();
    } else if (seriesSuccess === true || this.state.cancel === true) {
      if (seriesSuccess === true) {
        alert("Series updated Successfully");
        this.props.emptyError();
      }
      // if the requesst come from All Series then
      //  fetch all Series to update the suspended Series

      if (sentFrom === "AllSeries") {
        this.props.fetchAllSeries();
        return <Redirect to={ALL_SERIES}></Redirect>;
      }

      // if the requesst come from ViewSeries then
      //  get series data to update the suspended Series\
      if (sentFrom === "ViewSeries") {
        this.props.getSeriesData(seriesItem.id);
        return <Redirect to={VIEW_SERIES}></Redirect>;
      }
    }

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <NavBar />
                      <br />
                      <div className="container">
                        <h3>updating Series</h3>
                        <hr />

                        <div className="">
                          <form onSubmit={this.handleSubmit}>
                            <div className="row">
                              {/* image Uploader */}
                              <div className="col-md-4 col-12">
                                <span className="required_input">*</span>
                                <UploadImage
                                  getImageURLCallBack={this.getImageUrl.bind(
                                    this
                                  )}
                                  imageUrl={this.state.imageUrl}
                                  imagepreview={this.state.imageUrl}
                                  s3DirectryrName="series"
                                />
                              </div>

                              <div className="col-md-8 col-12">
                                <p style={{ color: "red" }}>
                                  {this.state.errorMessage}{" "}
                                </p>
                                <label htmlFor="name" className="label">
                                  <span className="required_input">*</span>
                                  Name
                                </label>
                                <input
                                  dir="auto"
                                  className="form-control"
                                  fluid
                                  name="name"
                                  id="name"
                                  onChange={this.handleChange}
                                  value={this.state.name}
                                />
                                <br />

                                <label
                                  htmlFor="nameInEnglish"
                                  className="label"
                                >
                                  {/* <span className="required_input">*</span> */}
                                  Name In English
                                </label>
                                <input
                                  dir="auto"
                                  className="form-control"
                                  fluid
                                  name="nameInEnglish"
                                  id="nameInEnglish"
                                  onChange={this.handleChange}
                                  value={this.state.nameInEnglish}
                                />
                                <br />

                                <div className="form-group">
                                  <label
                                    htmlFor="description"
                                    className="label"
                                  >
                                    Description
                                  </label>

                                  <textarea
                                    dir="auto"
                                    fluid
                                    rows="3"
                                    className="form-control"
                                    name="description"
                                    id="description"
                                    onChange={this.handleChange}
                                    value={this.state.description}
                                  />
                                </div>
                                <br />

                                <div className="form-group">
                                  <label
                                    htmlFor="descriptionInEnglish"
                                    className="label"
                                  >
                                    Description In English
                                  </label>

                                  <textarea
                                    dir="auto"
                                    fluid
                                    rows="3"
                                    className="form-control"
                                    name="descriptionInEnglish"
                                    id="descriptionInEnglish"
                                    onChange={this.handleChange}
                                    value={this.state.descriptionInEnglish}
                                  />
                                </div>
                                <br />

                                <label htmlFor="name" className="label">
                                  Audio
                                </label>

                                <UploadAudio
                                  getAudioUrlCallBack={this.getAudio.bind(this)}
                                  audioPreview={this.state.audio}
                                  audioUrl={this.state.audio}
                                  s3DirectryrName="series/audio"
                                />

                                {/* DateComponent */}
                                <PublishDate
                                  getPublishDateCallBack={this.getPublishDate.bind(
                                    this
                                  )}
                                  date={this.state.publish_date}
                                />
                                <br />
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={this.state.featured}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  {' Featured'}
                                </label>
                                <br />
                              </div>
                            </div>
                            <div>
                              <label className="label">Intro with music</label>
                              <div>

                                <RenderIntroWithMusic
                                  videoKey={this.state.video_key_intro_with_music}
                                  id="IntroWithMusic"
                                  foldername={SERIES_FOLDER_NAME_WITH_MUSIC}
                                  changedid={this.handleChangeIntroWithMusic}
                                ></RenderIntroWithMusic>
                              </div>
                              <br />
                            </div>
                            <div>
                              <label className="label">Intro without music</label>
                              <div>
                                <RenderIntroWithoutMusic
                                  videoKey={this.state.video_key_intro_without_music}
                                  id="IntroWithoutMusic"
                                  foldername={SERIES_FOLDER_NAME_WITH_OUT_MUSIC}
                                  changedid={this.handleChangeIntroWithoutMusic}
                                ></RenderIntroWithoutMusic>
                              </div>
                              <br />
                            </div>


                            <div>
                              <label className="label">Promo without music</label>
                              <div>
                                <RenderPromoWithMusic
                                  videoKey={this.state.video_key_promo_with_music}
                                  id="PromoWithMusic"
                                  foldername={SERIES_FOLDER_NAME_WITH_MUSIC}
                                  changedid={this.handleChangePromoWithMusic}
                                ></RenderPromoWithMusic>
                              </div>
                              <br />
                            </div>


                            <div>
                              <label className="label">Promo without music</label>
                              <div>
                                <RenderPromoWithoutMusic
                                  videoKey={this.state.video_key_promo_without_music}
                                  id="PromoWithoutMusic"
                                  foldername={SERIES_FOLDER_NAME_WITH_OUT_MUSIC}
                                  changedid={this.handleChangePromoWithoutMusic}
                                ></RenderPromoWithoutMusic>
                              </div>
                              <br />
                            </div>


                            {/* <label className="label">Intro with music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getIntroWithMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.intro_with_music}
                                videoUrl={this.state.intro_with_music}
                                s3DirectryrName="series"
                              />
                            </div>
                            <br />

                            <label className="label">Intro without music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getIntroWithoutMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.intro_without_music}
                                videoUrl={this.state.intro_without_music}
                                s3DirectryrName="series"
                              />
                            </div>
                            <br />

                            <label className="label">Promo with music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getPromoWithMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.promo_with_music}
                                videoUrl={this.state.promo_with_music}
                                s3DirectryrName="series"
                              />
                            </div>
                            <br />

                            <label className="label">Promo without music</label>
                            <div>
                              <UploadVideo
                                getVideoURLCallBack={this.getPromoWithoutMusicVideoUrl.bind(
                                  this
                                )}
                                videoPreview={this.state.promo_without_music}
                                videoUrl={this.state.promo_without_music}
                                s3DirectryrName="series"
                              />
                            </div>
                            <br /> */}

                            <div className="buttons_container">
                              <Button
                                disabled={this.state.errorMessage}
                                id="saveButton"
                              >
                                Save
                              </Button>

                              <Button
                                id="episode_cancel"
                                onClick={this.handelCancel}
                              >
                                Cancel
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

UpdateSeries.propTypes = {
  updateSeries: propTypes.func.isRequired,
  fetchAllSeries: propTypes.func.isRequired,
  getSeriesData: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  series: state.series.item,
  seriesError: state.series.updating_series_error,
  seriesSuccess: state.series.updating_series_sucessfully,
  auth: state.firebase.auth,
});

export default withFirebase(
  connect(mapStateToProps, {
    updateSeries,
    emptyError,
    fetchAllSeries,
    getSeriesData,
  })(UpdateSeries)
);
