import {
    FETCH_ALL_VENDOR, LOAD_VENDOR, ERROR_LOADING_ALL_VENDOR,
    UPDATE_VENDOR_SUCCESSFULLY,UPDATE_VENDOR_ERROR,
    GET_VENDOR_DATA_SUCCESSFULLY,GET_VENDOR_DATA_ERROR,
    EMPTY_VENDOR
    
} from './Types'
import axios from 'axios'
import { post } from 'axios';

export const fetchAllVendors = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_VENDOR,
                payload: 'loading'
            })
            const url = "/Vendor/getAllVendorList"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: FETCH_ALL_VENDOR,
                    payload: res.data
                })

            }).catch((Error) => {
                 dispatch({
                type: ERROR_LOADING_ALL_VENDOR,
                payload: Error.message
            })
            })

        })

    }
}

export const getVendorData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_VENDOR,
                payload: 'loading'
            })

            const url = "/Vendor/getVendorDetails";
            const formData = new FormData();
            formData.append('Vendor_ID', id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_VENDOR_DATA_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: GET_VENDOR_DATA_ERROR,
                    payload: Error.message
                })
            })
        })

    }
}


export const editVendor = (id, userName, country, phone, note, contractType, city, influencerInfo, addess) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_VENDOR,
                payload: 'loading'
            })
            console.log(note)
            console.log(contractType)
            console.log(city)
            console.log(influencerInfo)
            console.log(addess)
    
            const url = "/Vendor/updateVendor";
            const formData = new FormData();
            formData.append('Id', id);
            formData.append('Name', userName);
            formData.append('Country', country);
            formData.append('Phone', phone);
            formData.append('Note', note);
            formData.append('Contract_Type', contractType);
            formData.append('City', city);
            formData.append('Influencer_Info', influencerInfo);
            formData.append('Address', addess);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_VENDOR_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                
                // console.log(Error.respose.data.message)
                dispatch({
                    type: UPDATE_VENDOR_ERROR,
                    payload: Error.message
                    
                })

            })
        })

    }
}


export const emptyVendor = () => dispatch => {
    console.log("emptyVendor")
    dispatch({
        type: EMPTY_VENDOR,
        payload: ""
    })
}

