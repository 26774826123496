import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Link } from 'react-router-dom'
import { Button, Dropdown, Checkbox } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import Footer from '../Layout/Footer';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/*Actions */
import { editDynamicSection, emptyDynamicSection, getDynamicSectionDetails } from '../../Actions/DynamicSectionActions'
/*CSS */
import '../../css/AppCss/Series/AddSeries.css';
import Section_Categories from '../ConstData/DynamicSectionCategories';
import Section_Types from '../ConstData/DynamicSectionTypes';

/* Routes */
import { ADMIN_LAND_PAGE, SECTIONS } from '../../routes';

const INITIAL_STATE = {
    id: 0,
    active: false,
    category: "",
    title: "",
    type: "",
    titleInEnglish: "",
    ////////////////// validation form
    error: false,
    errorMessage: "",
};

export class EditDynamicSection extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }
    componentDidMount() {
        let id = this.props.match.params.id;
        console.log(this.props.match.params.id);
        this.props.getDynamicSectionDetails(id);
    }

    componentWillReceiveProps(nextprop) {
        let dynamicSection = nextprop.dynamicSection;

        if (dynamicSection.id !== undefined && dynamicSection.id) {
            this.setState(
                {
                    id: dynamicSection.id,
                    active: dynamicSection.active,
                    category: dynamicSection.category,
                    title: dynamicSection.title,
                    type: dynamicSection.type,
                    titleInEnglish: dynamicSection.titleInEnglish,
                }
            )
        }
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            if ((this.state.title === '' || this.state.title === null || this.state.title === undefined || this.state.title.trim() === ''
                || this.state.titleInEnglish === '' || this.state.titleInEnglish === null || this.state.titleInEnglish === undefined || this.state.titleInEnglish.trim() === '')) {
                this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
            }
            else if ((this.state.title !== '' && this.state.title !== null && this.state.title !== undefined &&
                this.state.titleInEnglish !== '' && this.state.titleInEnglish !== null && this.state.titleInEnglish !== undefined)) {
                this.setState({ error: false, errorMessage: "" });
            }
        }, 0);
    }

    handleCategoryChange = (e, { value }) => {
        this.setState({ category: value });
    };

    handleTypeChange = (e, { value }) => {
        this.setState({ type: value });
    };

    handleActiveChange = (e, { checked }) => {
        this.setState({ active: checked });
    }

    handleSubmit = (e) => {
        const { id, active, category, title, type, titleInEnglish } = this.state;
        this.props.editDynamicSection(id, active, category, title, type, titleInEnglish);
        e.preventDefault();
    }

    render() {
        const { success, error, loading } = this.props;
        let buttonSubmitLoader = (
            <Button onClick={this.handleSubmit}
                id="nextButton" disabled={this.state.error}>
                Save
            </Button>);

        if (loading === true) {
            buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">
                    Loading
                </button>
            )
        }
        if (success === true) {
            this.props.emptyDynamicSection();
            alert("Updating Dynamic Section Done Successfully");
            return (<Redirect to={SECTIONS}></Redirect>)

        }
        else if (success === false) {
            alert(error);
            this.props.emptyDynamicSection();
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">

                                            {/* <!--  start status bar --> */}
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    <Link to={SECTIONS}>Section</Link><span> {'>'} </span>

                                                </span>
                                                <span>Edit Dynamic Section</span>

                                            </div>
                                            {/* <!--  end status bar --> */}

                                            <br />
                                            <div className="container">
                                                <h3>Edit Dynamic Section</h3>
                                                <hr />
                                                <div className="">
                                                    <form className="white" onSubmit={(e) => e.preventDefault()}>
                                                        <p style={{ color: "red" }}>{this.state.errorMessage} </p>

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Title</label>
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="title" id="title" onChange={this.handleChange}
                                                            value={this.state.title} />
                                                        <br />

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Title In English</label>
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="titleInEnglish" id="titleInEnglish" onChange={this.handleChange}
                                                            value={this.state.titleInEnglish} />
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select Category </label><br />
                                                        <Dropdown fluid search selection
                                                            placeholder='Select Category'
                                                            id="episode_series_id"
                                                            onChange={this.handleCategoryChange}
                                                            options={Section_Categories}
                                                            value={this.state.category}
                                                        />
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select Type </label><br />
                                                        <Dropdown fluid search selection
                                                            placeholder='Select Type'
                                                            id="episode_series_id"
                                                            onChange={this.handleTypeChange}
                                                            options={Section_Types}
                                                            value={this.state.type}
                                                        />
                                                        <br />

                                                        <label htmlFor="active" className="label">
                                                            <span className="required_input">*</span> Active
                                                        </label>
                                                        <br />
                                                        <Checkbox
                                                            id="active"
                                                            toggle
                                                            checked={this.state.active}
                                                            onChange={this.handleActiveChange}
                                                        />
                                                        <br />
                                                    </form>
                                                </div>
                                                <div className="input-field">
                                                    {buttonSubmitLoader}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}


EditDynamicSection.propTypes = {
    editDynamicSection: propTypes.func.isRequired,
    getDynamicSectionDetails: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    dynamicSection: state.dynamicSection.item,
    loading: state.dynamicSection.loading,
    success: state.dynamicSection.update_section_successfully,
    error: state.dynamicSection.update_section_error
});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                getDynamicSectionDetails,
                editDynamicSection,
                emptyDynamicSection
            }
        )
        (EditDynamicSection)
    );
