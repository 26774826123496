import axios from 'axios'
import firebase from 'firebase/app';

import {
    FETCH_ALL_COUPONS, LOAD_ALL_COUPONS, ERROR_ALL_COUPONS
} from './Types';


export const getAllCouponTypes = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ALL_COUPONS,
                payload: 'loading'
            })

            const url = "/CouponType/getAllCouponTypes"
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            axios.get(url, config).then((res) => {
                dispatch(
                    {
                        type: FETCH_ALL_COUPONS,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ALL_COUPONS,
                    payload: Error.message
                })
            })
        })
    }
}
