import {post} from "axios";
import { ERROR_LOADING_CUSTOME_COUNTRY, GET_CUSTOME_COUNTRY, LOAD_CUSTOME_COUNTRY, EMPTY_CUSTOME_REPORT, LOAD_CUSTOME_GENDER, GET_CUSTOME_GENDER, ERROR_LOADING_CUSTOME_GENDER, LOAD_CUSTOME_AGE_RANGE, GET_CUSTOME_AGE_RANGE, ERROR_LOADING_CUSTOME_AGE_RANGE } from "./Types";

export const getCustomeCountryReport = (startDate,endDate,countries,gender,ageRanges)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
                dispatch({
                    type:LOAD_CUSTOME_COUNTRY
                })
                const url = "/CustomeReport/getCountryReport"
                const formData = new FormData();

                formData.append('Start_Date',startDate)
                formData.append('End_Date',endDate);
                formData.append('gender',gender);
                for (let i=0;i<countries.length;i++)
                {
                    formData.append('Countries',countries[i])

                }
                for (let i=0;i<ageRanges.length;i++)
                {
                    formData.append('Age_Ranges',ageRanges[i])
                }
                if (ageRanges.length==0)
                {
                    formData.append('Age_Ranges',[])
                }
                
                const config = {
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                        'X-Authorization-Firebase': token
                    }
                }

                post(url,formData,config).then((res)=>
                {
                    dispatch({
                        type:GET_CUSTOME_COUNTRY,
                        payload:res.data
                    })
                }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_CUSTOME_COUNTRY,
                    payload:Error.message
                })
            })

        })
    }
}

export const emptyCustomeReport = ()=> dispatch=>
{
    dispatch({
        type:EMPTY_CUSTOME_REPORT
    })
}

export const getCustomeGenderReport = (startDate,endDate,countries,ageRanges)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch ({
                type :LOAD_CUSTOME_GENDER
            })
            const url = "/CustomeReport/getGenderReport"
            const formData = new FormData();
            formData.append('Start_Date',startDate);
            formData.append('End_Date',endDate)
            for (let i=0;i<countries.length;i++)
            {
                formData.append('Countries',countries[i])
            }
            for (let i =0 ;i<ageRanges.length ; i++)
            {
                formData.append('Age_Ranges',ageRanges[i])
            }
            if (countries.length==0)
            {
                formData.append('Countries',[])
            }
            if (ageRanges.length==0)
            {
                formData.append('Age_Ranges',[])
            }

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:GET_CUSTOME_GENDER,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_CUSTOME_GENDER,
                    payload:Error.message
                })
            })
        })
    }
}


export const getCustomeAgeRangeReport =(startDate,endDate,countries,gender)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_CUSTOME_AGE_RANGE
            })
            const url = "/CustomeReport/getAgeRangeReport"
            const formData = new FormData();
            formData.append('Start_Date',startDate)
            formData.append('End_Date',endDate)
            for (let i=0; i<countries.length;i++)
            {
                formData.append('Countries',countries[i]);
            }
            if (countries.length===0)
            {
                formData.append('Countries',[]);
            }
            formData.append('gender',gender);

            
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:GET_CUSTOME_AGE_RANGE,
                    payload:res.data
                })

            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_CUSTOME_AGE_RANGE,
                    payload:Error.message
                })
            })

        })
    }
}