import {
    LOAD_FIT_VERIFYING_CODE,
    FIT_VERIFYING_CODE_SUCCESS,
    FIT_VERIFYING_CODE_ERROR,

    LOAD_FIT_RESEND_CODE,
    FIT_RESEND_CODE_SUCCESS,
    FIT_RESEND_CODE_ERROR,

    EMPTY_FIT

} from '../Actions/Types'

const initialState = {
    item: {},
    loading: '',

    verifying_successfully: '',
    verifying_error: '',

    resend_code_error: '',
    resend_code_successfully: '',
    resendLoading:''

}

export default function (state = initialState, action) {
    switch (action.type) {


        case LOAD_FIT_VERIFYING_CODE:
            return {
                ...state,
                loading: true
            }

        case FIT_VERIFYING_CODE_SUCCESS:
            return {
                ...state,
                item: action.payload,
                verifying_successfully: true,
                verifying_error: '',
                loading: false

            }
        case FIT_VERIFYING_CODE_ERROR:
            return {
                ...state,
                verifying_successfully: false,
                verifying_error: action.payload,
                loading: false
            }

        case LOAD_FIT_RESEND_CODE:
            return {
                ...state,
                resendLoading: true
            }

        case FIT_RESEND_CODE_SUCCESS:
            return {
                ...state,
                item: action.payload,
                resend_code_successfully: true,
                resend_code_error: '',
                resendLoading: false

            }
        case FIT_RESEND_CODE_ERROR:
            return {
                ...state,
                resend_code_successfully: false,
                resend_code_error: action.payload,
                resendLoading: false
            }

        case EMPTY_FIT:
            return {
                ...state,
                verifying_error: '',
                verifying_successfully: '',
                resend_code_error: '',
                resend_code_successfully: '',
                item: '',
                loading: false,
                resendLoading:false

            }

        default:
            return state
    }
}