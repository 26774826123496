import React, { Component } from 'react';
import '../../../node_modules/video-react/dist/video-react.css';
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from '../Layout/Loader'
import '../../css/Songs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter,Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { emptyError } from '../../Actions/Empty'
import { getAllBlogs } from '../../Actions/BlogActions'
import DeleteBlog from './DeleteBlog';
import SetBlogStatus from './SetBlogStatus';
/* Routes */
import { ADMIN_LAND_PAGE, ADD_BLOG, BLOG_TO_EDIT } from '../../routes';

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

class GetAllBlogs extends Component {

    componentDidMount() {
        this.props.getAllBlogs();
        this.props.emptyError();
    }

    render() {
        const { blogs } = this.props;
        const {error} = this.props;
        let blogsList = null
        if (this.props.blogsStatue === true) {
            blogsList = (<Loader/>)
        }
        else if(this.props.blogsStatue===false && this.props.error==='') {
            blogsList =
                blogs.map(blog => {


                    return (
                        <div className="card" key={blog.id}>
                            <div className="row" >
                                <div className="col ">
                                    <img className="rounded" src={blog.image} alt="" height="90" width="90" />
                                </div>
                                <div className="col-10">

                                    <div>

                                        <span className="card-title" color="gray">
                                            {blog.title}
                                        </span>
                                        <DeleteBlog id={blog.id}/>
                                        <Link to={BLOG_TO_EDIT + '/' + blog.id}><button className="songCardButtons"  >Edit</button>
                                        </Link>
                                        <SetBlogStatus id={blog.id} status={blog.status}/>
                                    </div>
                                    <span className="font-italic small-font">
                                        <span className="date">{GMTtolocalTime(blog.createdDate)}</span>
                                        <hr />
                                        <span><p>{blog.description}</p></span>

                                    </span>


                                </div>
                            </div>
                        </div>
                    )
                })
        }
        else
        {
            blogsList=( <div><br/><center><div>{error}</div></center><br/></div>)
        }
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body" id="left-main-body">
                                        <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                    </span>
                                                    <span>Blogs</span>

                                                </div>
                                            <br />
                                            <h5 className="main-title mt-2 font-weight-bold"><span>  Blogs</span></h5>

                                           <Link to={ADD_BLOG}><button type="submit" className="SongsButtons">+ Add New Blog</button></Link>
                                            <div className="container">
                                                {blogsList}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )

    }
}
const mapStateToProps = state => ({
    blogs: state.blogs.items,
    blogsStatue: state.blogs.loading,
    error:state.blogs.error_loading_blogs
});

export default withRouter(connect(mapStateToProps, { getAllBlogs,emptyError })(GetAllBlogs));
