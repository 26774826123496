import React, { Component } from "react";
import { deleteStory, emptyStory } from '../../Actions/StoryActions'
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { getStoryParentData } from '../../Actions/StoryParentAction';
/* Routes */
import { ALL_STORIES } from '../../routes';

class DeleteStory extends Component {
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteStory(id) {
        this.props.deleteStory(id)
    }

    handleSubmit = (e) => {
        const { id } = this.props;
        this.deleteStory(id);
        this.close();
    }

    render() {
        const {
            error_deleting_story,
            story_deleted_successfully,
            StoryParentId
        } = this.props;

        if (story_deleted_successfully === false) {
            alert("error missing data or network error" + error_deleting_story);
            this.props.emptyStory();
        }
        else if (story_deleted_successfully === true) {
            alert("story deleted successfully");
            this.props.emptyStory();
            this.props.getStoryParentData(StoryParentId)
        }

        return (<div >
            <button className="seriesCardButtons" onClick={this.open}>
                <i  >
                    <FontAwesomeIcon icon="trash-alt" />
                </i>
            </button>
            <Confirm id="modal"
                content='Are You Sure To Delete This Story?? '
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </div>)

    }

}
const mapStateToProps = state => ({
    stories: state.stories.items,
    error_deleting_story: state.stories.error_deleting_story,
    story_deleted_successfully: state.stories.story_deleted_successfully
})
export default connect(
    mapStateToProps,
    {
        deleteStory,
        emptyStory,
        getStoryParentData
    })
    (DeleteStory)
