import {
      FETCH_ALL_DISCOUNTS, LOAD_ALL_DISCOUNTS, ERROR_ALL_DISCOUNTS,
} from '../Actions/Types'

const initialState = {
    items: [],
    loading: '',
    getting_allDiscounts_sucessfully: '',
    getting_allDiscounts_error: '',
}

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_ALL_DISCOUNTS:
            return {
                ...state,
                items: action.payload,
                getting_allDiscounts_error: '',
                getting_allDiscounts_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_DISCOUNTS:
            return {
                ...state,
                getting_allDiscounts_error: action.payload,
                loading: false
            }


            case LOAD_ALL_DISCOUNTS:
                return {
                    ...state,
                    loading: true
                }
        default:
            return state
    }

}
