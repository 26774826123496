import {
    ERROR_GET_FAWRY_PAYMENT_PACKAGE,
    GET_FAWRY_PAYMENT_PACKAGE,
    LOAD_GET_FAWRY_PAYMENT_PACKAGE,
    EMPTY_FAWRY_PAYMENT_PACKAGE
} from './Types'
import axios, { post } from 'axios'


export const getFawryPrices = (country, payingMethodName,token) => {
    return (dispatch, getState, { getFirebase }) => {
           dispatch({
                type: LOAD_GET_FAWRY_PAYMENT_PACKAGE,
                payload: 'loading'
            })

            const url = "/PaymentHandlingPrices/getPaymentMethodByCountryAndMethod";

            const formData = new FormData();
            formData.append('Country_Name', country);
            formData.append('Paying_Method_Name', payingMethodName);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_FAWRY_PAYMENT_PACKAGE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_GET_FAWRY_PAYMENT_PACKAGE,
                    payload: Error.message
                })
            })
    

    }
}

export const getTelecomPrices = (country, payingMethodName) => {
    return (dispatch, getState, { getFirebase }) => {
           dispatch({
                type: LOAD_GET_FAWRY_PAYMENT_PACKAGE,
                payload: 'loading'
            })

            const url = "/PaymentHandlingPrices/getPaymentMethodByCountryAndMethodWithoutToken";

            const formData = new FormData();
            formData.append('Country_Name', country);
            formData.append('Paying_Method_Name', payingMethodName);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_FAWRY_PAYMENT_PACKAGE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_GET_FAWRY_PAYMENT_PACKAGE,
                    payload: Error.message
                })
            })
    

    }
}

export const emptyFawryPrices = () => dispatch => {
    dispatch({
        type: EMPTY_FAWRY_PAYMENT_PACKAGE,
        payload: ""
    })
}
