import {
    LOAD_All_TIME_OF_USE,
    FETCH_All_TIME_OF_USE,
    ERROR_ALL_TIME_OF_USE,
    LOAD_TIME_OF_USE,
    ERROR_ADDING_TIME_OF_USE,
    ADD_TIME_OF_USE,
    DELETING_TIME_OF_USE,
    ERROR_DELETING_TIME_OF_USE,
    EMPTY_TIME
} from './Types'
import axios, { post } from 'axios'

export const fetchAllTimeOfUse = () => dispatch => {

    dispatch({
        type: LOAD_All_TIME_OF_USE,
        payload: 'loading'
    })

    axios.get('/TimeOfUse/getAllTimeOfUseList')
        .then((res) => {
            dispatch({
                type: FETCH_All_TIME_OF_USE,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_ALL_TIME_OF_USE,
                payload: Error.message
            })
        })
}


export const addTimeOfUse = (text, articleUrl, programUrl) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_TIME_OF_USE,
                payload: 'loading'
            })

            const url = "/TimeOfUse/addTimeOfUse";

            const formData = new FormData();
            formData.append('Text', text);
            formData.append('File_Article_Url', articleUrl);
            formData.append('File_Program_url', programUrl);


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_TIME_OF_USE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_TIME_OF_USE,
                    payload: Error.message
                })
            })
        })

    }
}

export const deleteTimeOfUse = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_TIME_OF_USE,
                payload: 'loading'
            })

            const url = "/TimeOfUse/deleteTimeOfUse";
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_TIME_OF_USE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_TIME_OF_USE,
                    payload:  Error.response.data.message
                })
            })
        })

    }
}

export const emptyTimeResponse = () => dispatch => {
    dispatch({
        type: EMPTY_TIME,
        payload: ''
    })
}
