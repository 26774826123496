import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Popup } from 'semantic-ui-react'
/**Actions */
import { fetchVendorsReport } from '../../Actions/VendorsReport'
/*Components */
import Loader from '../Layout/Loader';
import menLogo from '../../images/men.png';
import menCheckLogo from '../../images/men_correct.png';
import cardCheckLogo from '../../images/card_correct.png';
import cardLogo from '../../images/card.png';
import VendorReportTable from './VendorReportTable'
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import VendorManagement from '../Layout/VendorManagement';


class VendorReport extends Component {
    constructor(props) {
        super(props);
        this.props.fetchVendorsReport();
    }

    componentDidMount() {
        this.props.fetchVendorsReport();
    }


    render() {

        const { vendorsReport } = this.props;

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                        <VendorManagement/>
                                            <div className="container">

                                                {/* <!--  start status bar --> */}
                                                {/* <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to="/">Home</Link><span> > </span>
                                                    </span>
                                                    <span>Vendors Report</span>

                                                </div> */}
                                                {/* <!--  end status bar --> */}

                                                {/* <h5 className="main-title mt-2 font-weight-bold">
                                                    Vendors Report</h5> */}


                                                {/* Start div with icons  */}
                                                <div className="row ">
                                                    <div className="col-3 ">

                                                        <div className="vendorbox col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="circle-tile ">
                                                                        <img
                                                                            className="main-logo float-left"
                                                                            alt="main logo"
                                                                            src={menLogo}
                                                                        />
                                                                    </div>
                                                                    <p className="col ayhaga">
                                                                        Total Number Of Vendors</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="numbers">{vendorsReport.numberOfVendor}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className="vendorbox col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="circle-tile ">
                                                                        <img
                                                                            className="main-logo float-left"
                                                                            alt="main logo"
                                                                            src={menCheckLogo}
                                                                        />
                                                                    </div>
                                                                    <p className="col ayhaga">
                                                                        Total Number Of Active Users</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="numbers">{vendorsReport.numberOfActiveUsers}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                    <div className="col-3">

                                                        <div className="vendorbox col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="circle-tile ">
                                                                        <img
                                                                            className="main-logo float-left"
                                                                            alt="main logo"
                                                                            src={cardCheckLogo}
                                                                        />
                                                                    </div>
                                                                    <p className="col ayhaga">
                                                                        Total Number Of Active Cards</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="numbers">{vendorsReport.numberOfActiveCards}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className="vendorbox col">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="circle-tile ">
                                                                        <img
                                                                            className="main-logo float-left"
                                                                            alt="main logo"
                                                                            src={cardLogo}
                                                                        />
                                                                    </div>
                                                                    <p className="col ayhaga">
                                                                        Total Number Of Cards</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="numbers">{vendorsReport.numberOfCards}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End div with icons  */}


                                                <br />
                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Active Users</label>
                                                    <hr id="questionHR" />
                                                    <br />

                                                    <div className=" col-6 my-2">
                                                        <div>Most vendors has number of Active Users</div>
                                                        <VendorReportTable vendors={vendorsReport.mostVendorsWithActiveUsers} />
                                                    </div>

                                                    <div className=" col-6 my-2">
                                                        <div>Least vendors has number of Active Users</div>

                                                        <VendorReportTable vendors={vendorsReport.leastVendorsWithActiveUsers} />
                                                    </div>
                                                </div>

                                                <br />
                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Active Cards</label>
                                                    <hr id="questionHR" />
                                                    <br />

                                                    <div className=" col-6 my-2">
                                                        <div>Most vendors has number of Active cards</div>
                                                        <VendorReportTable vendors={vendorsReport.mostVendorsWithActiveCards} />
                                                    </div>

                                                    <div className=" col-6 my-2">
                                                        <div>Least vendors has number of Active cards</div>

                                                        <VendorReportTable vendors={vendorsReport.leastVendorsWithActiveCards} />
                                                    </div>
                                                </div>


                                                <br />
                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Cards</label>
                                                    <hr id="questionHR" />
                                                    <br />


                                                    <div className=" col-6 my-2">
                                                        <div>Most vendors has number of cards</div>
                                                        <VendorReportTable vendors={vendorsReport.mostVendorsWithCards} />
                                                    </div>

                                                    <div className=" col-6 my-2">
                                                        <div>Least vendors has number of cards</div>

                                                        <VendorReportTable vendors={vendorsReport.leastVendorsWithCards} />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )

    }
}
VendorReport.propTypes = {
    fetchVendorsReport: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    vendorsReport: state.vendorsReport.items,
    loading: state.vendorsReport.loading,
    error: state.vendorsReport.get_all_vendors_report_error,
    success: state.vendorsReport.get_all_vendors_report_successfully,

});

export default withRouter
    (connect
        (mapStateToProps,
            { fetchVendorsReport })
        (VendorReport)
    );