import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    deleteSpecialCountriesPrice,
    emptySpeciialCountryOffer,
    fetchAllSpecialCountriesPrice
} from '../../Actions/MobileSpecialCountriesAction'

class DeleteSpecialCountryPrice extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteSpecialCountriesPrice(id) {
        this.props.deleteSpecialCountriesPrice(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        this.deleteSpecialCountriesPrice(id);
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("Error : " + error)
            this.props.emptySpeciialCountryOffer();
        }
        else if (success === true) {
            alert("Country With Special Offer has Deleted Successfully")
            this.props.emptySpeciialCountryOffer();
            this.props.fetchAllSpecialCountriesPrice();
        }

        return (
            <div className="acor-btn-group">

                <button className="acor-btn2" onClick={this.open}>
                 
                        <FontAwesomeIcon icon="trash-alt" />
                   
                </button>
                <Confirm id="modal"
                    content='Are you sure you want to delete this Special Country with offer ?? '
                    open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>

        )
    }
}


DeleteSpecialCountryPrice.propTypes = {
    deleteSpecialCountriesPrice: propTypes.func.isRequired,
    fetchAllSpecialCountriesPrice: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    error: state.specialCountriesPrice.delete_special_countries_price_error,
    success: state.specialCountriesPrice.delete_special_countries_price_sucessfully
});


export default withFirebase(connect(
    mapStateToProps, {
    deleteSpecialCountriesPrice,
    emptySpeciialCountryOffer,
    fetchAllSpecialCountriesPrice
})
    (DeleteSpecialCountryPrice));
