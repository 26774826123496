import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { setStateNormalSeries, fetchAllSeries, getSeriesData } from '../../Actions/SeriesActions'
import { emptyError } from '../../Actions/Empty'

class SetStateSeries extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    setStateNormalSeries(id, state) {
        this.props.setStateNormalSeries(id, state);
    }


    handleSubmit = (e) => {

        const { id, state } = this.props;

        if (state === "SUSPENDED") {
            this.setStateNormalSeries(id, "DEFAULT");

        } else {

            this.setStateNormalSeries(id, "SUSPENDED");
        }
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        const { state, id, sentFrom } = this.props;

        let contentPreview = null;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Series State Changed Successfully")
            this.props.emptyError();

            // if the requesst come from All Series then
            //  fetch all Series to update the suspended Series
            if (sentFrom === "AllSeries") {
                this.props.fetchAllSeries()
            }

            // if the requesst come from ViewSeries then
            //  get series data to update the suspended Series\
            if (sentFrom === "ViewSeries") {
                this.props.getSeriesData(id);
            }
        }

        if (state === "SUSPENDED") {

            contentPreview = (

                <Popup trigger={
                    <button className="seriesCardButtons" onClick={this.open}>
                        <i  >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>}
                    content='Tap to Active'
                    position='top center'
                />


            )

        } else {

            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>||</button>}
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }

        return (

            <span >
                {contentPreview}
                <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </span>

        )

    }
}


SetStateSeries.propTypes = {
    setStateNormalSeries: propTypes.func.isRequired,
    fetchAllSeries: propTypes.func.isRequired,
    getSeriesData: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    series: state.series.item,
    error: state.series.setting_state_series_error,
    success: state.series.setting_state_series_sucessfully
});

export default withFirebase(connect(mapStateToProps, { setStateNormalSeries, emptyError, getSeriesData, fetchAllSeries })(SetStateSeries));
