import React, { Component } from 'react'
/*Redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import propTypes from 'prop-types';
/*Component */
import UploadFile from '../S3Uploading/UploadFile';
import DeleteTimeOfUse from './DeleteTimeOfUse'
/*Actions */
import {
    emptyTimeResponse,
    addTimeOfUse,
    fetchAllTimeOfUse
} from '../../Actions/TimeOfUseActions';


const INITIAL_STATE = {

    id: 0,
    text: '',
    articleUrl: '',
    programUrl: ''
};


class TimeOfUse extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.props.fetchAllTimeOfUse();
        if (this.props.timeOfUse) {
            let timeOfUse = this.props.timeOfUse[0];
            if (timeOfUse !== null && timeOfUse !== undefined) {
                this.setState({
                    id: timeOfUse.id,
                    text: timeOfUse.text,
                    articleUrl: timeOfUse.articleUrl,
                    programUrl: timeOfUse.programUrl
                })
            }
        }
    }

    componentDidMount() {
        this.props.fetchAllTimeOfUse();
        if (this.props.timeOfUse) {
            let timeOfUse = this.props.timeOfUse[0];
            if (timeOfUse !== null && timeOfUse !== undefined) {
                this.setState({
                    id: timeOfUse.id,
                    text: timeOfUse.text,
                    articleUrl: timeOfUse.articleUrl,
                    programUrl: timeOfUse.programUrl
                })
            }
        }
    }

    getFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ programUrl: fileURL });
            console.log("TimeOfUse programUrl")
            console.log(fileURL);
        }

    }

    getArticleUrl(articleUrl) {
        if (articleUrl) {
            this.setState({ articleUrl: articleUrl });
            console.log("TimeOfUse articleUrl")
            console.log(articleUrl);
        }
    }

    deleteTime(deleted) {
        if (deleted) {
            this.setState({
                id: 0,
                text: '',
                articleUrl: '',
                programUrl: ''
            });
            console.log("Time deleted")
        }
    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleSubmit = (e) => {

        const { text, articleUrl, programUrl } = this.state;

        console.log(text + " " + articleUrl
            + " " + programUrl)
        this.props.addTimeOfUse(text, articleUrl,
            programUrl);

        e.preventDefault();
    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyTimeResponse();
        }
        else if (success === true) {
            alert("Time Of Use saved Successfully")
            this.props.emptyTimeResponse();
            this.props.fetchAllTimeOfUse();

        }

        const { text, programUrl, articleUrl } = this.state;

        const isInvalidTime =
            text === '' ||
            programUrl === '' ||
            articleUrl === '';

        let fileLink;
        let articleLink;

        if (programUrl !== undefined &&
            programUrl !== null &&
            programUrl !== '') {
            fileLink = <span > Program Link : {programUrl}</span>;
        }


        if (articleUrl !== undefined &&
            articleUrl !== null &&
            articleUrl !== '') {
            articleLink = <span >Article Link : {articleUrl}</span>;
        }


        let timeOfUse = this.props.timeOfUse[0];
        let button;
        if (timeOfUse) {

            button =
                <button
                    className="btn btn-lg btn-success btn-suc btn-block-sm"
                    onClick={this.handleSubmit.bind(this)}
                    disabled={isInvalidTime}>
                    update
                </button>
        }
        else {

            button = <button
                className="btn btn-lg btn-success btn-suc btn-block-sm"
                onClick={this.handleSubmit.bind(this)}
                disabled={isInvalidTime}>

                Save
            </button>
        }


        return (
            <div className="card-body">

                <div className="row">
                    <div className="form-group col-md-12">
                        <label className="small-label"
                            for="exampleFormControlTextarea1">
                            <span className="required_input">*</span>
                            Recommendation:
            </label>
                        <textarea
                            dir="auto"
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="text" id="text"
                            onChange={this.handleChange}
                            value={this.state.text} >
                        </textarea>

                        <br />


                        <label className="small-label">
                            <span className="required_input">*</span>
                            Aticle File </label>
                        <br />
                        {articleLink}
                        <UploadFile
                            getFileURLCallBack={this.getArticleUrl.bind(this)}
                            fileURL={this.state.articleUrl}
                            s3DirectryrName='timeofuse' />
                        <br />


                        <label className="small-label">
                            <span className="required_input">*</span>
                            Upload File </label>
                        <br />
                        {fileLink}
                        <UploadFile
                            getFileURLCallBack={this.getFileUrl.bind(this)}
                            fileURL={this.state.programUrl}
                            s3DirectryrName='timeofuse' />

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="float-right">

                            {button}
                        </div>

                        <div className="float-left">


                            <DeleteTimeOfUse id={this.state.id}
                                deletingTimeOfUse={this.deleteTime.bind(this)} />

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

TimeOfUse.propTypes = {
    fetchAllTimeOfUse: propTypes.func.isRequired,
    addTimeOfUse: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    timeOfUse: state.timeOfUse.items,
    loading: state.timeOfUse.loading,
    error: state.timeOfUse.adding_Time_error,
    success: state.timeOfUse.adding_Time_sucessfully
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyTimeResponse,
                addTimeOfUse,
                fetchAllTimeOfUse
            })
        (TimeOfUse)
    );
