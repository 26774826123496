import React, { Component } from 'react';
import Loader from '../Layout/Loader';
import { ProgressBar } from 'react-bootstrap';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader'
import '../../css/AppCss/dropzone.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const INITIAL_STATE = {
    song_Image: "",
    imagePreviewUrl: "",
    imageUrl: "",
    completed: 0,
    imageError: "",
    s3DirectryrName: '',
    itemIndex: ''

}


const _validFileExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".tif"];
// const _validFileExtensions = ["image/jpg", "image/jpeg", "image/bmp", "image/gif", "image/png", "image/tif"];

export class UploadPageImage extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE
    }

    componentWillMount() {

        if (this.props.imageUrl !== undefined ||
            this.props.imagepreview !== undefined ||
            this.props.s3DirectryrName !== undefined ||
            this.props.itemIndex !== undefined) {
            this.setState({
                imagePreviewUrl: this.props.imagepreview,
                imageUrl: this.props.imageUrl,
                s3DirectryrName: this.props.s3DirectryrName,
                itemIndex: this.props.itemIndex
            })
            // console.log(this.props.s3DirectryrName)
        }
    }


    componentWillReceiveProps(nextProp) {

        // console.log(nextProp)

        if (nextProp.file) {
            this.setState({
                imagePreviewUrl: nextProp.file,
                imageUrl: nextProp.file,
            })

        }

        if (nextProp.imageUrl !== undefined ||
            nextProp.imagepreview !== undefined ||
            nextProp.s3DirectryrName !== undefined ||
            nextProp.itemIndex !== undefined) {
            this.setState({
                imagePreviewUrl: nextProp.imagepreview,
                imageUrl: nextProp.imageUrl,
                s3DirectryrName: nextProp.s3DirectryrName,
                itemIndex: nextProp.itemIndex
            })
            // console.log(nextProp.s3DirectryrName)
        }
    }

    onUploadProgress = (percent) => {
        this.setState({ completed: percent });
    }

    onError = (errror) => {
        alert(errror)
    }
    onUploadFinish = (e) => {
        this.setState({
            imagePreviewUrl: e.publicUrl,
            imageUrl: e.publicUrl
        })
        console.log("image url", e.publicUrl)
        this.props.getImageURLCallBack(e.publicUrl, this.state.itemIndex);

        console.log("onUploadFinish = " + e.publicUrl);
    }

    handleDeleteImage(e) {
      this.props.getImageURLCallBack('delete', this.state.itemIndex);
        this.setState({
          song_Image: "",
          imagePreviewUrl: "",
          imageUrl: "",
          completed: 0,
          imageError: "",
          s3DirectryrName: '',
          itemIndex: ""
        });
    }


    render() {

        const { s3DirectryrName } = this.state;

        let uploadinglink = 'newgenepublic';
        if (s3DirectryrName !== '') {
            uploadinglink = 'newgenepublic/' + s3DirectryrName;
        }
        // console.log(uploadinglink)

        const uploadOptions = {

            signingUrl: "/W3S/getPresignedURL",
            signingUrlMethod: 'POST',
            signingUrlHeaders: {
                'content-type': 'application/json'
                // ,
                // 'X-Authorization-Firebase' : token
            },
            signingUrlQueryParams: { 'bucketName': uploadinglink },
            uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
            contentDisposition: "auto",
            scrubFilename: (filename) => new Date().getTime()

        }
        let { imagePreviewUrl, imageError } = this.state;
        let { imageUrl } = this.state;
        let $imagePreview = null;
        let loadImage = null;

        let $dropzone = (

            <div className=''>

                <label id="" className="new-items">
                    <div id="" className="add-box">
                        <div id="d-hide">


                            <DropzoneS3Uploader accept="image/*"
                                onFinish={this.onUploadFinish}
                                s3Url='https://newgenepublic.s3.amazonaws.com/newgenepublic/'
                                onProgress={this.onUploadProgress}
                                upload={uploadOptions}

                            />

                        </div>
                        <p className="small-font mt-2 mb-0 upload-icon-p">Click to Upload Image</p>
                    </div>  </label>
                <div>
                    <br />
                    <ProgressBar animated striped bsstyle="success" now={this.state.completed} label={`${this.state.completed}%`} />
                </div>
            </div>
        );

        if (imagePreviewUrl) {

            if (imageUrl === '') {
                if (imageError === '') {
                    loadImage = (<Loader />)

                }
                else if (imageError) {
                    $imagePreview = $dropzone;
                    this.setState({ imageError: '', imagePreviewUrl: '' })
                }
            }
            else {
                $imagePreview = (
                    <div className="img-edit">
                        <span>
                            <small className="removeImage" onClick={e => this.handleDeleteImage(e)}><FontAwesomeIcon icon="times" color="#dc6d6d" className="true" /></small>
                            <img className="image_Preview" src={imagePreviewUrl} alt="img" />
                        </span>
                    </div>)
            };

        }
        else {
            $imagePreview = $dropzone;

        }

        return (
            <div /*className="img-edit" */>
                {$imagePreview}
                {loadImage}
            </div>
        )
    }
}

export default (UploadPageImage)
