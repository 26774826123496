import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ZenDeskChat from '@goranefbl/react-zendesk-chat';
/*image */
import logout from '../../images/logout.png';
import logo from "../../images/logo.png";
/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';
/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'
import { isItAutoRenewel } from '../../Actions/MobilePaymentActions.js'
import { renewPayment, cancelRenew } from '../../Actions/StripePaymentAction.js'
import {
    getUserData, emptyMobileUserProfile,
    cancelSubscription, emptyMobileUserProfileWithItem
} from '../../Actions/MobilePaymentProfileAction';

import { signIn } from '../../Actions/AuthActions'
import { emptyError } from '../../Actions/Empty'
import { addTokenToredux, emptyToken } from '../../Actions/loginActions.js'
/*Promise */
import { getUserInfo } from '../../Promises/UserData.js'

const INITIAL_STATE = {
    redirection: false,
    isChecked: '',
    isSignOut: false,
    checkedMessage: '',
    redirectPaymentPage: '',
    renew: false,
    subscriptionCanceled: true,
    token: ''

}

// const countriesWithOffer = ["Egypt"];
// ["Egypt", "Tunisia", "Algeria",
//     "Morocco", "Libya", "Mauritania"
// ];

function CheckexpiryDate(exDate) {

    var newExpDate;
    var today = new Date();

    let arrayOfDate = exDate.split('-');
    var expdd = arrayOfDate[0]
    var expmm = arrayOfDate[1];
    var expyyyy = arrayOfDate[2];
    var formatedEpxDate = expmm + "/" + expdd + "/" + expyyyy;
    var oldDate = new Date(formatedEpxDate);

    if (today > oldDate) {
        newExpDate = null;
    }
    else {
        newExpDate = exDate;
    }
    //console.log("newExpDate = " + newExpDate)
    return newExpDate;
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


class MobilePaymentProfile extends Component {


    constructor(props) {

        super(props);
        this.state = { ...INITIAL_STATE };

        this.props.emptyToken();
        this.props.emptyMobileUserProfile();
        this.props.emptyMobileUserProfileWithItem();
        let token = this.props.match.params.token;
        //console.log("token profile = " + token)
        this.props.addTokenToredux(token);
        getUserInfo(token).then((res) => {

            console.log(res.data)

            if (res.data.paymentAutoRenewal == 0) {
                console.log(0)
                this.setState({ isChecked: false })
            }
            else {
                console.log(1)
                this.setState({ isChecked: true })
            }

            this.setState({ token: token });
            this.props.getUserData(token);
        })



    }

    viewOfferPages(userCountry) {
        let token = this.props.match.params.token;

        // const { userPaymentProfile } = this.props;
        // console.log(userPaymentProfile.country)
        let redirectPaymentPage = '';

        if (userCountry !== null &&
            (userCountry === "Egypt" || userCountry === "egypt")) {
            redirectPaymentPage = "/MobilePaymentSwitch/" + token;
        } else {
            redirectPaymentPage = "/MobilePayment/" + token;
        }


        // adding page to browser history
        let red = "/MobilePaymentProfile/" + token;
        this.props.history.push(red);
        // for (var i = 0; i < countriesWithOffer.length; i++) {
        //     if (userCountry === countriesWithOffer[i]) {
        //         redirectPaymentPage = "/MobilePaymentOffer"
        //     }
        // }
        this.setState({ redirectPaymentPage: redirectPaymentPage })
    }

    // componentWillReceiveProps(nextProps){
    /*if(nextProps.currentPackagePaymentId !== null){
      //Perform some operation
      this.viewOfferPages(nextProps.country);
    }*/
    // }
    componentDidMount() {
        //    const {token} = this.props;
        //    console.log("componentDidMount props token = "+token)
        //console.log("componentDidMount token = " + this.state.token)
        let token = this.props.match.params.token;
        getUserInfo(token).then((res) => {

            this.props.getUserData(this.state.token);

            if (res.data.currentPackagePaymentId === null) {
                this.viewOfferPages(res.data.country)
            }
            else {
                if (res.data != undefined ||
                    res.data != null) {
                    if (res.data.paymentAutoRenewal == 0) {
                        console.log("componentDidMount 0")
                        this.setState({ isChecked: false, checkedMessage: '' })
                    }
                    else {
                        console.log("componentDidMount 1")
                        this.setState({ isChecked: true, checkedMessage: "أنت مشترك فى خدمة التجديد التلقائى " })
                    }
                }
            }

        })



    }

    /*handleLink = (e) => {
        firebase.auth().currentUser.getIdToken().then((token) => {

            this.props.addSeriesIDtoRedirectionPage("MobilePaymentProfile", token);
            this.setState({ redirection: true })
        })
    }*/

    signOut = (e) => {

        firebase.auth().signOut()

        this.setState({ isSignOut: true })
        console.log("SignOut")
        e.preventDefault();
    }

    handleSubmit = (PageLink, ExpirationDate, e) => {
        let { isChecked } = this.state;

        //console.log(" handelSubmit ExpirationDate " + ExpirationDate)

        this.props.isItAutoRenewel(isChecked, ExpirationDate);
        this.componentDidMount();
        //   this.setState({ PageLink: PageLink })

        e.preventDefault();
    }

    handleRenew = (PageLink, ExpirationDate, e) => {
        let { isChecked } = this.state;

        this.props.isItAutoRenewel(isChecked, ExpirationDate);
        //    this.componentDidMount();

        this.props.renewPayment();
        this.viewOfferPages();
    }

    handleCheckbuttonChange = (customerId, e) => {

        const isChecked = e.target.checked;
        // cancel subcription
        if (isChecked === false) {
            //console.log(isChecked)
            this.props.cancelSubscription();
            this.setState({ isChecked: isChecked })
        }
        ///// case of /////
        else if (isChecked === true) {
            //subscriptionId = null , customerId = data
            // this.setState({ visible: true })
            // alert("You need to complete the payment to save Auto Renewel");

            alert("يرجى اتمام عمليه الدفع لتفعيل خدمه التجديد التلقائى");
            //error you can't subscripe now, try later while charging your package
        }
        ///////////
    }

    render() {

        // back to previous page if browser back button clicked
        window.onhashchange = function () {
            this.props.history.goBack();
        }

        let urlToken = this.props.match.params.token;

        //    this.props.getUserData(this.props.match.params.token);
        const { userPaymentProfile } = this.props;
        //      this.props.emptyMobileUserProfile();
        if ((!isEmpty(userPaymentProfile)) &&
            userPaymentProfile.currentPackagePaymentId == null) {
            let { isChecked } = this.state;
            //console.log(" handelSubmit ExpirationDate " + null)
            this.props.isItAutoRenewel(isChecked, null);


            let redirect = '';

            if (userPaymentProfile.country !== null &&
                (userPaymentProfile.country === "Egypt" ||
                    userPaymentProfile.country === "egypt")) {
                redirect = "/MobilePaymentSwitch/" + urlToken;
            } else {
                redirect = "/MobilePayment/" + urlToken;

            }

            // adding page to browser history
            let red = "/MobilePaymentProfile/" + urlToken;
            this.props.history.push(red);

            //   for (var i = 0; i < countriesWithOffer.length; i++) {
            //       if (userPaymentProfile.country === countriesWithOffer[i]) {

            //         return (<Redirect to = "/MobilePaymentOffer"> </Redirect>)
            //       }
            //   }
            return (<Redirect to={redirect} ></Redirect>)
        }
        // if(this.state.redirectPaymentPage !== ''){
        //   return (<Redirect to = {this.state.redirectPaymentPage}> </Redirect>)
        // }

        if (!isEmpty(userPaymentProfile)) {
            if (this.props.renew === true) {
                let redirect = '';


                if (userPaymentProfile.country !== null &&
                    (userPaymentProfile.country === "Egypt" ||
                        userPaymentProfile.country === "egypt")) {
                    redirect = "/MobilePaymentSwitch/" + urlToken;
                } else {
                    redirect = "/MobilePayment/" + urlToken;
                }


                // adding page to browser history
                let red = "/MobilePaymentProfile/" + urlToken;
                this.props.history.push(red);

                this.props.cancelRenew();

                return (<Redirect to={redirect} ></Redirect>)
            }
        }
        // mapping to MobilePayment && MobilePaymentOffer
        const { PageLink } = this.state;


        if (PageLink) {
            return (<Redirect to={PageLink} ></Redirect>)

        }

        const { successLogin, errorMsgLogin } = this.props;

        if (successLogin === true) {
            this.props.emptyError();
        }
        else if (successLogin === false) {
            alert("error missing data or network " + errorMsgLogin);
            this.props.emptyError();
        }

        const { redirection, redirectPaymentPage, isSignOut } = this.state;

        let { isChecked } = this.state;


        const { sucess, error } = this.props;
        const { cancelSubscribtionSuccess } = this.props;
        const { cancelSubscribtionError } = this.props;
        const { loading } = this.props;

        if (userPaymentProfile.user_role !== undefined) {
            if (userPaymentProfile.user_role.role !== "USER") {
                alert("Not A User")

                //console.log(" not user")
                firebase.auth().signOut()
                return (<Redirect to="/MobilePaymentLogin"></Redirect>)
            }
        }


        // case of error of token come form backend
        /*if (sucess === false && error !== '') {
            console.log("success === F " + sucess)
            alert("error " + error)
            firebase.auth().signOut()
            this.props.emptyMobileUserProfile();

            return (<Redirect to="/MobilePaymentLogin" ></Redirect>)
        }*/
        if (sucess === true &&
            userPaymentProfile.email !== undefined &&
            userPaymentProfile.password !== undefined) {


            //console.log("success === T " + sucess)
            //console.log(userPaymentProfile.email + " == " +
            //    userPaymentProfile.password)

            //  this.props.emptyMobileUserProfile();

        }


        if (isSignOut === true) {
            console.log("signOut in render")
            return (<Redirect to="/MobilePaymentLogin" ></Redirect>)
        }

        if (redirection == true) {
            return (<Redirect to="/MobilePaymentSupport" ></Redirect>)
        }



        if (cancelSubscribtionSuccess === true) {

            // alert("Success, you cancel the subscription successfully");
            alert("تم الغاء التجديد التلقائى بنجاح");
            this.props.emptyMobileUserProfile();

        }

        else if (cancelSubscribtionSuccess === false) {
            alert("Error, " + cancelSubscribtionError)
            this.props.emptyMobileUserProfile();

        }

        let packageType = '';
        let currentPackage = '';

        let button = (
            <button className="btn btn-lg btn-primary btn-block btn-signin mt-2 font"
                type="submit" data-toggle="modal"
                data-target="#exampleModalCenter"
                onClick={this.handleSubmit.bind(this,
                    redirectPaymentPage,
                    null)}>
                اشترك الآن
            </button>
        );

        if (userPaymentProfile.currentPackagePaymentId != undefined ||
            userPaymentProfile.currentPackagePaymentId != null) {
            if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType
                == 'Monthly') {
                packageType = (
                    <span className=" h5 m-0 mb-1 font " style={{ color: "black" }}>
                        الشهرية
                    </span>
                )
            }

            else if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType
                == 'Yearly') {
                packageType = (
                    <span className=" h5 m-0 mb-1 font " style={{ color: "black" }}>
                        السنويه
                    </span>
                )
            }

            else if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType
                == 'Weekly') {
                packageType = (
                    <span className=" h5 m-0 mb-1 font " style={{ color: "black" }}>
                        الأسبوعية
                    </span>
                )
            }

            button = (

                <button className="btn btn-lg btn-primary btn-block btn-signin mt-2 font"
                    type="submit" data-toggle="modal"
                    data-target="#exampleModalCenter"
                    onClick={this.handleRenew.bind(this,
                        redirectPaymentPage,
                        CheckexpiryDate(userPaymentProfile.currentPackagePaymentId.expirationDatetime))}
                >
                    تجديد الباقة
                </button>
            )

            currentPackage = (
                <div>
                    <div className=" h5 text-center mb-4 mt-4 font">
                        {this.state.isChecked && this.state.checkedMessage}
                    </div>
                    <fieldset>
                        <legend className="font">الباقة الحالية</legend>
                        <div className="div-box">
                            <div className="row">
                                <div className="col-md-8 col-12">
                                    <div className="mo-package">
                                        <div className="h5 m-0 mb-1 font">الباقة {packageType} لجيل</div>
                                        <span className="small-font font">يمكنك إضافة ثلاث اطفال للحساب الواحد</span>
                                    </div>
                                </div>
                                <div className="col-md-4 col-12">
                                    <div className="d-flex align-items-center justify-content-center h-100 ">
                                        <div className="Pkg-price">
                                            <div className="small-font text-right font">بسعر</div>
                                            <div className="price-value h2 m-0 eng-lang font">
                                                ${userPaymentProfile.currentPackagePaymentId.paymentPackageId.normalPrice}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="p-3 small-font ltr font">
                                    : الباقة صالحة لتاريخ
                                    </div>
                            </div>
                            <div className="col-6">
                                <div className="pl-4 pt-3">
                                    <div className="small-font eng-lang text-left font">
                                        {userPaymentProfile.currentPackagePaymentId.expirationDatetime}</div>
                                </div>
                            </div>
                        </div>

                        <hr className="" />
                        <label className="checkbox mt-3 small-font font">التجديد التلقائي للباقة
                            <input type="checkbox" checked={isChecked}
                                onClick={this.handleCheckbuttonChange.bind(this,
                                    userPaymentProfile.stripeCustomerId)} />
                            <span className="checkmark"></span>
                        </label>
                    </fieldset>
                    <div className="col-12">
                        <Link
                            to={{ pathname: "/MobilePaymentHistory" }}
                            className="small-font mt-3 mb-3 float-left font">
                            سجل الباقات السابقة
                        </Link>
                    </div>
                </div>
            )

        }


        if (loading === true) {
            button = (
                <button
                    className="btn btn-block ui loading secondary button">
                </button>
            );
            console.log("loading");
        }

        return (
            <div className="main-payment">

                <div className="shape-container">
                    <div className="bg-img">
                    </div>
                </div>

                <div className="container">
                    <div className="pay-box">
                        <div className="main-logo text-center h4">
                            <img className="  logo" alt="logo" src={logo} />
                            <p className="small-font main-color">بوابة الدفع لجيل</p>
                        </div>
                        <div className="card card-container Current-Package">
                            <div className="card-header profile-name-card text-right">
                                {/* <div className="small-font font">مرحبا</div>
                                <div className="h4 font">{userPaymentProfile.name}</div> */}


                                <span className="h4 font">مرحبا</span>
                                <span className="h4 font">   &nbsp; {userPaymentProfile.name}   &nbsp; </span>

                                <Link
                                    to={{ pathname: "/MobilePaymentProfile" }}>
                                    <a className=" float-left language-btn logout-btn"
                                        onClick={this.signOut}>
                                        <i className="fas fa-chevron-left">
                                            <img className="logout" src={logout} alt="logout" />
                                        </i>
                                    </a>
                                </Link>

                            </div>
                            <div className="card-body">
                                {currentPackage}


                                {button}

                                <div >
                                    <Link
                                        className="h6 mt-3 mb-0 float-right font" id="livechat-open">
                                        لديك مشكلة؟
                                   </Link>
                                </div>
                                <ZenDeskChat onlineMsg="Live Chat Support" offlineMsg="Leave us a message" appID="rVsdP33FK31Q8eZxdFwegHbpDOk2rzMd" buttonID="livechat-open" />
                            </div>
                        </div>
                        <MobilePaymentFooter />
                    </div>
                </div>

            </div>
        )
    }
}


MobilePaymentProfile.propTypes = {
    getUserData: propTypes.func.isRequired,
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    isItAutoRenewel: propTypes.func.isRequired,
    emptyMobileUserProfile: propTypes.func.isRequired,
    cancelSubscription: propTypes.func.isRequired,
    addTokenToredux: propTypes.func.isRequired,
    cancelRenew: propTypes.func.isRequired
}
const mapStateToProps = state => ({
    userPaymentProfile: state.userPaymentProfile.item,
    loading: state.userPaymentProfile.loading,
    error: state.userPaymentProfile.getting_user_payment_data_error,
    sucess: state.userPaymentProfile.getting_user_payment_data_sucessfully,
    renew: state.stripePayment.renew,
    cancelSubscribtionSuccess: state.userPaymentProfile.user_payment_cancel_subscription_sucessfully,
    cancelSubscribtionError: state.userPaymentProfile.user_payment_cancel_subscription_error,

    successLogin: state.authUser.success,
    errorMsgLogin: state.authUser.errorMsg,
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                getUserData,
                renewPayment,
                addSeriesIDtoRedirectionPage,
                isItAutoRenewel,
                emptyMobileUserProfile,
                cancelSubscription,
                emptyMobileUserProfileWithItem,
                signIn,
                emptyError,
                emptyToken,
                addTokenToredux,
                cancelRenew
            }
        )
        (MobilePaymentProfile)
    );
