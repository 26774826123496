import React, { Component } from 'react'
import { getIssuesByType } from '../../Actions/IssueAction';
import { connect } from 'react-redux';
import IssuesList from './IssuesList'


class SuggestionIssues extends Component {
    state = {
        user: '',
        createdDate: '',
        status: '',
        filterdIssues: ''
    }

    componentDidMount() {
        this.props.getIssuesByType(1);
    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    render() {
        const { issues, issueState, error } = this.props;
        const { createdDate, status, user } = this.state;


        return (
            <div>
                <div class="filter-box p-3 mb-3">
                    <form onSubmit={(e)=> e.preventDefault()}>
                        <div class="form-row">
                            <div class="col-md-3 mb-3">
                                <label class="small-label">User</label>
                                <input
                                    dir="auto"
                                    class="form-control"
                                    type="text"
                                    aria-describedby=""
                                    value={this.state.user}
                                    onChange={this.handleChange}
                                    name='user' />
                            </div>
                            <div class="col-md-3 mb-3">
                                <label class="small-label" for="">created date</label>
                                <input type="date" class="form-control" id="" aria-describedby="" value={this.state.createdDate} onChange={this.handleChange} name='createdDate' />
                            </div>
                            <div class="col-md-3 mb-3">
                                <label class="small-label" for="">status</label>
                                <select className="form-control" defaultValue="all" onChange={this.handleChange} name="status" id="exampleFormControlSelect1">
                                    <option value="">all</option>
                                    <option value="open">open</option>
                                    <option value="close">close</option>
                                    <option value="waiting for replay">waiting for replay</option>

                                </select>
                            </div>
                            <div class="col-12">
                                <div class="float-right">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <IssuesList issues={issues} status={status} createdDate={createdDate} issueState={issueState} user={user} error={error} />
            </div>
        )
    }
}
const mapStateToProps = state => (
    {
        issues: state.issues.items,
        issueState: state.issues.loading,
        error: state.issues.error_loading_issues

    })
export default connect(mapStateToProps, { getIssuesByType })(SuggestionIssues);