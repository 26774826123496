import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AudioPlayer from "react-h5-audio-player";

/* Same CSS style as PublishEpisode*/
import '../../css/AppCss/Episode/PublicViewPublishEpisode.css';
import '../../css/AppCss/Episode/ViewEpisode.css';
import '../../css/AppCss/Episode/EpisodeButtons.css';
import '../../../node_modules/video-react/dist/video-react.css';
import '../../css/AppCss/questionPreview.css'
export class QuestionPreview extends Component {


    render() {

        let questionsList = '';
        const { questions } = this.props;
        const { state } = this.props;

        if (questions !== undefined) {
            console.log(questions);

            questionsList = questions.length ? (questions.map(question => {

                if (question.questionType === "text") {

                    let qAudioWithText = ''
                    if (question.voice !== null &&
                        question.voice !== undefined &&
                        question.voice !== "null" &&
                        question.voice !== "") {
                            qAudioWithText = (
                            <div className="row grey ">

                                <div className="col-4">
                                    <p >{question.text}  </p>
                                </div>

                                <div className="col">
                                    <div className="col-1 hideProgress">
                                        <br />
                                        <AudioPlayer
                                            src={question.voice}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        qAudioWithText = (
                            <div className="row grey ">

                                <div className="col-4">
                                    <p className="p">{question.text}  </p>
                                </div>

                            </div>
                        )

                    }
                    return (
                        <div className="col-6">
                            <div className="preview" >
                                {qAudioWithText}
                                <br />
                                <div className="">
                                    {
                                        question.choices.map(choice => {

                                            if (state === "publish") {

                                                if (question.correctAnswerId == choice.id) {
                                                    return (
                                                        <div >
                                                            <FontAwesomeIcon icon="check-circle" color="#39b54a" /> <span className="ansr episode_label" >{choice.text}</span>
                                                        </div>)
                                                }
                                                else {
                                                    return (
                                                        <div >
                                                            <FontAwesomeIcon icon="check-circle" color="#999999" /> <span className="ansr episode_label" >{choice.text}</span>
                                                        </div>)
                                                }
                                            }

                                            else if (state === "view") {

                                                if (question.correctAnsId !== null) {
                                                    let audio = ''
                                                    if (choice.voice !== null &&
                                                        choice.voice !== undefined &&
                                                        choice.voice !== "null" &&
                                                        choice.voice !== "") {
                                                        audio = (<div className="col-1 hideProgress">
                                                            <AudioPlayer
                                                                src={choice.voice}
                                                            />


                                                        </div>)
                                                    }

                                                    if (question.correctAnsId.id === choice.id) {
                                                        return (
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <FontAwesomeIcon icon="check-circle" color="#39b54a" />
                                                                    <span className="ansr episode_label" >{choice.text}
                                                                    </span>
                                                                </div>
                                                                <div className="col-2">
                                                                    {audio}
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <FontAwesomeIcon icon="check-circle" color="#999999" />
                                                                    <span className="ansr episode_label" >{choice.text}
                                                                    </span>
                                                                </div>
                                                                <div className="col-2">
                                                                    {audio}
                                                                </div>
                                                            </div>)
                                                    }
                                                }
                                            }


                                        })

                                    }
                                </div>
                            </div>
                        </div>

                    )
                }
                else if (question.questionType === "image") {

                    let qAudio = ''
                    if (question.voice !== null &&
                        question.voice !== undefined &&
                        question.voice !== "null" &&
                        question.voice !== "") {
                        qAudio = (<div className="col-1 hideProgress">
                            <br />
                            <AudioPlayer
                                src={question.voice}
                            />
                        </div>)
                    }
                    return (
                        <div className="col-6">
                            <div className="preview">

                                <div className="row grey ">
                                    <div className="col-4">
                                        <p >{question.text} </p>
                                    </div>

                                    <div className="col-3">
                                        {qAudio}
                                    </div>
                                </div>
                                <br />
                                <div className="pl-0">

                                    {
                                        question.choices.map(choice => {

                                            if (state === "publish") {

                                                if (question.correctAnswerId == choice.id) {
                                                    return (
                                                        <div className="ansr">
                                                            <FontAwesomeIcon icon="check-circle" color="#39b54a" classNameName="true" />
                                                            <img className="episode_question_img" src={choice.text} alt="" />
                                                        </div>)
                                                }
                                                else {
                                                    return (
                                                        <div className="ansr">
                                                            <FontAwesomeIcon icon="check-circle" color="#999999" className="true" />
                                                            <img className="episode_question_img" src={choice.text} alt="" />
                                                        </div>)
                                                }
                                            }

                                            else if (state === "view") {
                                                if (question.correctAnsId !== null) {


                                                    if (question.correctAnsId.id === choice.id) {

                                                        if (choice.voice !== null &&
                                                            choice.voice !== undefined &&
                                                            choice.voice !== "null" &&
                                                            choice.voice !== "") {

                                                            return (

                                                                <div className="row">
                                                                    <div className="col-5">
                                                                        <div className="ansr">

                                                                            <FontAwesomeIcon icon="check-circle" color="#39b54a" className="true" />
                                                                            <img className="episode_question_img" src={choice.text} alt="" />

                                                                        </div>
                                                                    </div>
                                                                    <div className="hideBorder hideProgress">
                                                                        <AudioPlayer
                                                                            src={choice.voice}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            )
                                                        }
                                                        else {

                                                            return (
                                                                <div className="ansr">

                                                                    <FontAwesomeIcon icon="check-circle" color="#39b54a" className="true" />
                                                                    <img className="episode_question_img" src={choice.text} alt="" />

                                                                </div>
                                                            )
                                                        }
                                                    }
                                                    else {
                                                        if (choice.voice !== null &&
                                                            choice.voice !== undefined &&
                                                            choice.voice !== "null" &&
                                                            choice.voice !== "") {

                                                            return (

                                                                <div className="row">
                                                                    <div className="col-5">
                                                                        <div className="ansr">

                                                                            <FontAwesomeIcon icon="check-circle" color="#999999" className="true" />
                                                                            <img className="episode_question_img" src={choice.text} alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="hideBorder hideProgress">
                                                                        <AudioPlayer
                                                                            src={choice.voice}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            )
                                                        } else {
                                                            return (
                                                                <div className="ansr">
                                                                    <FontAwesomeIcon icon="check-circle" color="#999999" className="true" />
                                                                    <img className="episode_question_img" src={choice.text} alt="" />
                                                                </div>)
                                                        }
                                                    }
                                                }

                                            }
                                        })

                                    }

                                </div>
                            </div>
                        </div>


                    )
                }

            }
            )

            ) : (<div>No questions</div>)
        }

        return (
            <div>
                <div className="row" id="episode_Question_Row">


                    {questionsList}
                </div>
            </div>
        )
    }
}

export default QuestionPreview
