import {
    ADD_EPISODE, PUBLISH_EPISODE, GET_EPISODE_DATA,
    LOAD_EPISODE, EMPTY_EPISODE_RESPONSE,
    ERROR_ADDING_EPISODE, ERROR_LOADING_EPISODE,
    UPDATE_EPISODE, ERROR_UPDATE_EPISODE,
    DELETING_EPISODE, ERROR_DELETING_EPISODE,
    SETTING_STATE_EPISODE, ERROR_SETTING_STATE_EPISODE,
    EMPTY_EPISODE
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    loading: '',

    error_adding_episode: '',
    episode_added_sucessfully: '',
    error_loading_episode: '',

    error_updating_episode: '',
    episode_updated_sucessfully: '',

    deleting_episode_error: '',
    deleting_episode_sucessfully: "",

    setting_state_episode_error: "",
    setting_state_episode_sucessfully: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_EPISODE:
            return {
                ...state,
                item: action.payload

            }
        case UPDATE_EPISODE:
            return {
                ...state,
                item: action.payload,
                episode_updated_sucessfully: true
            }

        case ERROR_UPDATE_EPISODE:
            return {
                ...state,
                error_updating_episode: action.payload,
                episode_updated_sucessfully: false
            }

        case PUBLISH_EPISODE:
            return {
                ...state,
                item: action.payload,
                episode_added_sucessfully: true,

            }
        case GET_EPISODE_DATA:
            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_episode: ''
            }
        case LOAD_EPISODE:
            return {
                ...state,
                loading: true
            }
        case EMPTY_EPISODE_RESPONSE:
            // console.log("Empty Episode Reducer");
            return {
                ...state,
                error_adding_episode: '',
                episode_added_sucessfully: '',
                episode_updated_sucessfully: '',
                error_updating_episode: '',
                deleting_episode_sucessfully: '',
                deleting_episode_error: '',
                setting_state_episode_error: '',
                setting_state_episode_sucessfully:'',
                loading: false
            }
        case EMPTY_EPISODE:
            return {
                ...state,
                items: [],
                item: {},
            }

        case ERROR_ADDING_EPISODE:
            return {
                ...state,
                error_adding_episode: action.payload,
                episode_added_sucessfully: false

            }

        case ERROR_LOADING_EPISODE:
            return {
                ...state,
                error_loading_episode: action.payload,
                loading: false
            }

        case DELETING_EPISODE:
            return {

                ...state,
                item: action.payload,
                deleting_episode_sucessfully: true
            }
        case ERROR_DELETING_EPISODE:
            return {

                ...state,
                deleting_episode_sucessfully: false,
                deleting_episode_error: action.payload     
            }


        case SETTING_STATE_EPISODE:
            return {
                ...state,
                item: action.payload,
                setting_state_episode_sucessfully: true
            }

        case ERROR_SETTING_STATE_EPISODE:
            return {
                ...state,
                setting_state_episode_error: action.payload,
                setting_state_episode_sucessfully: false
            }

        default:
            return state
    }

}
