import { FETCH_SETTINGS, LOAD_SETTINGS, ERROR_LOADING_SETTINGS, ADD_SETTING, ERROR_ADDING_SETTING, UPDATE_SETTING, ERROR_UPDATING_SETTING, DELETE_SETTING, ERROR_DELETING_SETTING,EMPTY_SETTING, GET_SETTING_DATA }  from "../Actions/Types";

const initialState = {
    items: [],
    item: {},
    error_loading_settings: '',
    loading: '',
    error_adding_setting: '',
    setting_added_successfully: '',
    setting_updated_successfully: '',
    error_updating_setting: '',
    setting_deleted_successfully: '',
    error_deleting_setting: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_SETTINGS:
            return {
                ...state,
                items: action.payload,
                error_loading_settings: '',
                loading: false
            }
        case LOAD_SETTINGS:
            return {
                ...state,
                loading: true
            }
        case ERROR_LOADING_SETTINGS:
            return {
                ...state,
                error_loading_settings: action.payload,
                loading: false
            }
        case ADD_SETTING:
            return {
                ...state,
                item: action.payload,
                setting_added_successfully: true
            }
        case ERROR_ADDING_SETTING:
            return {
                ...state,
                error_adding_setting: action.payload,
                setting_added_successfully: false
            }
        case UPDATE_SETTING:
            return {
                ...state,
                item: action.payload,
                setting_updated_successfully: true
            }
        case ERROR_UPDATING_SETTING:
            return {
                ...state,
                error_updating_setting: action.payload,
                setting_updated_successfully: false
            }
        case DELETE_SETTING:
            return {
                ...state,
                item: action.payload,
                setting_deleted_successfully: true
            }
        case ERROR_DELETING_SETTING:
            return {
                ...state,
                error_deleting_setting: action.payload,
                setting_deleted_successfully: false
            }
            case GET_SETTING_DATA:
                return{
                    ...state,
                    item:action.payload,
                    error_loading_settings:'',
                    loading:false
                }
        case EMPTY_SETTING:
                return{
                    ...state,
                    error_adding_setting:action.payload,
                    setting_added_successfully:action.payload,
                    error_loading_settings:action.payload,
                    setting_updated_successfully:action.payload,
                    setting_deleted_successfully:'',
                    error_deleting_setting:'',
                    setting_updated_successfully:'',
                    error_updating_setting:''
                }
        default:
            return state
    }
}
