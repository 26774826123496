import React, { Component } from "react";

class CardInputs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="CardInputs text-start">
        <div className="title orange">بيانات البطاقة</div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label>اسم حامل البطاقة</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>رقم البطاقة</label>
              <input type="number" min={0} className="form-control" />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>الانتهاء في شهر</label>
              <input type="number" min={0} className="form-control" />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>الانتهاء في سنة</label>
              <input type="number" min={0} className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>رقم الحماية</label>
              <input type="number" min={0} className="form-control" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardInputs;
