import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom';

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
// import {
//     deleteRadio,
//     fetchAllRadio,
//     emptyError
// } from '../../Actions/RadioActions'
import { updateBook, emptyBook, getBookData, fetchBooks, emptyError, updateBookPage, addBookPage,
    deleteBookPage, addBookItemWithSentFrom } from '../../Actions/ReadWithJeelAction'
import { ALL_BOOK, VIEW_BOOK, BOOK_TO_EDIT, BOOK_TO_GET } from '../../routes';


class DeletePage extends Component {
  
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deletePage(id) {
        this.props.deleteBookPage(id);
    }


    handleSubmit = async (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        await this.deletePage(id);
        await this.props.getDataCallBack();
        await this.props.setPageIDToDelete(id)
        this.close();

    }


    render() {
        const { error } = this.props;
        const { success, bookPageID } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();

        }
        else if (success === true) {
            alert("Page Deleted Successfully")
            this.props.emptyError();
            this.props.getDataCallBack();
            return (<Redirect to={BOOK_TO_EDIT}></Redirect>)
        }

        return (
            <>
                {/* <button className="seriesCardButtons" onClick={this.open}>
                    <i>
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button> */}
                <button onClick={this.open} style={{ marginInlineStart: "auto" }} type="button" className="ui button">
                            <FontAwesomeIcon icon="trash-alt" />
                        </button>
                <Confirm id="modal"
                    content='Are you sure 
                    you want to delete this Page ??'
                    open={this.state.open} 
                    onCancel={this.close} 
                    onConfirm={this.handleSubmit} />
            </>

        )
    }
}


// DeletePage.propTypes = {
//     deleteRadio: propTypes.func.isRequired,

// }
const mapStateToProps = state => ({
    error: state.readWithJeel.deleting_book_page_error,
    success: state.readWithJeel.deleting_book_page_sucessfully
    

});

export default withFirebase(
    connect
        (mapStateToProps,
            {
                getBookData,
                updateBookPage,
                addBookPage,
                deleteBookPage,
                addBookItemWithSentFrom,
                emptyError

            })
        (DeletePage));
