import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom';

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { deleteSeries , fetchAllSeries } from '../../Actions/SeriesActions'
import { emptyError } from '../../Actions/Empty'
/* Routes */
import { ALL_SERIES } from '../../routes';

 class DeleteSeries extends Component {
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteSeries(id) {
        this.props.deleteSeries(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        this.deleteSeries(id);
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();

        }
        else if (success === true) {
            alert("Series Deleted Successfully")
            this.props.emptyError();
            this.props.fetchAllSeries();
            return (<Redirect to={ALL_SERIES}></Redirect>)
        }

        return (
            <div >
            <button className="seriesCardButtons"  onClick={this.open}>
                    <i  >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal"
                content='Are you sure you want to delete this series ??'
                open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>

        )
    }
}


DeleteSeries.propTypes = {
    deleteSeries: propTypes.func.isRequired
}
const mapStateToProps = state => ({
    series: state.series.items,
    error: state.series.deleting_series_error,
    success: state.series.deleting_series_sucessfully
});

export default withFirebase(connect(mapStateToProps, { deleteSeries, emptyError, fetchAllSeries })(DeleteSeries));
