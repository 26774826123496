import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import Switch from "react-switch"
import { Dropdown } from 'semantic-ui-react';

/*Redux*/
import { connect } from 'react-redux';
import { withFirebase } from '../../config';
import propTypes from 'prop-types';

/*Actions*/
import { addGameData, emptyGameData } from '../../Actions/GameDataAction';
import {
    fetchAllGameParent
} from '../../Actions/GameParentAction';
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import UploadImage from '../S3Uploading/UploadImage';
import UploadAudio from '../S3Uploading/UploadAudio';

import '../../css/AppCss/gamePreview.css'
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_GAMES, GAME_DATA_DETAIL, VIEW_GAME_PARENT, ALL_GAME_PARENTS } from '../../routes';


import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'


function validURL(str) {
    var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)

}

const INITIAL_STATE = {
    name: "",
    name_in_english: "",
    key: "",
    game_Image: "",
    girl_image: "",
    redirection: false,
    is_free: false,
    status: 'ACTIVE',
    type: "Unity",
    order: 0,
    url: "",
    voice: "",
    selected_gameParent_id: '',
    selected_gameParent_name: '',
    featured: false,
    contentVendorSelectedId: '',
    
    returned_gameParent_id_viewGameParentPage: '',
    returned_gameParent_name_viewGameParentPage: '',

}

const STATUESES = [
    {
        text: 'Active',
        value: 'ACTIVE'
    },
    {
        text: 'Suspended',
        value: 'SUSPENDED'
    }
]

const TYPES = [
    {
        text: 'Unity',
        value: 'Unity'
    },
    {
        text: 'Html5',
        value: 'Html5'
    }
]
class AddGameData extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.handleIsFreeChange = this.handleIsFreeChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }  
    }

    onChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
    }

    getGameImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({ game_Image: '' });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ game_Image: imageUrl });
        }
    }

    getGirlImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({ girl_image: '' });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ girl_image: imageUrl });
        }
    }

    redirect = (e) => {
        this.setState({ redirection: true })
    }

    addGameData(e) {
        console.log(addGameData)
        e.preventDefault();
        const { name, key, game_Image, girl_image, is_free, status,
            url, type, order, voice , selected_gameParent_id, name_in_english, featured,contentVendorSelectedId} = this.state


        if (name.length > 255) {
            alert("Name lenght can't be more than 255 character")
        }
        else if (key.length > 255) {
            alert("Key lenght can't be more than 255 character")
        } else if (selected_gameParent_id == '') {
            alert("Empty Parent")
        }
        else if
            (type == "Html5" && url != null
            && url != undefined
            && url != ''
            && url != ""
            && !(validURL(url))) {
            alert("Please enter a vaild url ")

        }

        else {
            // if(this.props.match.params.id) {
            //     this.props.addSeriesIDtoRedirectionPage(VIEW_GAME_PARENT, this.props.match.params.id);
            // }
            this.props.addSeriesIDtoRedirectionPage(VIEW_GAME_PARENT, this.state.selected_gameParent_id)
            this.props.addGameData(name, game_Image, girl_image, key, is_free, status, url, type, order, voice, selected_gameParent_id, name_in_english, featured,contentVendorSelectedId)
        }
    }

    handleIsFreeChange = (is_free) => {
        this.setState({ is_free })
    }

    handleStatusChange = (e, { value }) => {
        this.setState({ status: value })
    }

    handleTypeChange = (e, { value }) => {
        this.setState({ type: value })
    }

    getAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ voice: '' });
                console.log("audio URL in gameDate  if = "
                    + this.state.voice);
            }
            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
                console.log("audio URL in add gameDate else = "
                    + this.state.voice);

            }

        }
    }

    handleGameParentChange = (e, { value }) => {
        console.log("selected_gameParent_id 4 = " + value[0].id);
        console.log("selected_gameParent_name 5 = " + value[1].name);

        this.setState({ selected_gameParent_id: value[0].id })
        this.setState({ selected_gameParent_name: value[1].name })
        this.setState({ returned_gameParent_name_viewGameParentPage: '' })
    }
    componentDidMount = () => {
        let { redirectionParentPage } = this.props;
        let parentID = redirectionParentPage.series_Id
        this.props.fetchAllContentVendors();

        // console.log('redirectionPage', redirectionParentPage)
        // let parentID = this.props.match.params.id
        if(parentID) {
            const {gameParent} = this.props
            const selectedParent = []
            let filterParent = gameParent.filter(item => item.id == parentID) // parent id key => gameParent_id
            selectedParent.push(...filterParent)
            let selectedGameParentName = selectedParent[0] ? selectedParent[0].name  : ''
            if(selectedParent.length) {
                this.setState({ selected_gameParent_id: parentID,
                    selected_gameParent_name: selectedGameParentName,
                    returned_gameParent_name_viewGameParentPage: selectedGameParentName })
            }
        }
    }
    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };
      


    handleContentVendorSelection = (e, { value }) => {
        //console.log("contentVendor selected: " + value);
        this.setState({ contentVendorSelectedId: value })
    }
          
      
    render() {
        const { success, error } = this.props;

        const InValid = this.state.name === " "
            || this.state.name === ""
            || this.state.name === ''
            || this.state.name === ' '
            // || this.state.name_in_english === " "
            // || this.state.name_in_english === ""
            // || this.state.name_in_english === ''
            // || this.state.name_in_english === ' '
            || this.state.key === ""
            || this.state.key === ""
            || this.state.key === ' '
            || this.state.key === ''
            || this.state.game_Image === ""
            || this.state.game_Image === ""
            || this.state.game_Image === ''
            || this.state.game_Image === ' '
            || this.state.girl_image === ""
            || this.state.girl_image === ""
            || this.state.girl_image === ''
            || this.state.girl_image === ' '
            || this.state.selected_gameParent_id === '';

        let buttonSubmitLoader = (
            <button
                className="btn btn-dark colorBTN"
                disabled={InValid}
                onClick={e => this.addGameData(e)}>
                Save
            </button>
        )
        let { redirectionParentPage } = this.props;
        let parentID = redirectionParentPage.series_Id
        if (success === false) {
            alert(error)
            this.props.emptyGameData();
        }
        else if (success === true) {
            alert("Item added successfully.");

            this.props.emptyGameData();
         
            if(parentID) {
                return (<Redirect to={VIEW_GAME_PARENT} ></Redirect>)
            } else {
                return (<Redirect to={ALL_GAME_PARENTS} ></Redirect>)
                // this.props.history.push(GAME_DATA_DETAIL);

            }
        }

        if (this.state.redirection == true) {
            if(parentID) {
                return (<Redirect to={VIEW_GAME_PARENT} ></Redirect>)
            } else {
                return (<Redirect to={ALL_GAME_PARENTS} ></Redirect>)
                // this.props.history.push(GAME_DATA_DETAIL);

            }
        }

        const{gameParent} = this.props;
        const gameParentList = [];
        for (let i = 0; i < gameParent.length; i++) {
            gameParentList.push({
                key: gameParent[i].id,
                text: gameParent[i].name,
                value: [{ id: gameParent[i].id }, { name: gameParent[i].name }]
            })
        }

// --------------------------------
    const { ContentVendorsList } = this.props;
    const ContentVendorsArr = [];
    
    //console.log('ContentVendorsList : '+JSON.stringify(ContentVendorsList,null,2));
    
    for (let i = 0; i < ContentVendorsList.length; i++) 
    {
      ContentVendorsArr.push({
        key: ContentVendorsList[i].id,
        text: ContentVendorsList[i].user.name,
        value: ContentVendorsList[i].id,
      });
    }
// --------------------------------

         let selectedGameParent = null;
         if (this.state.returned_gameParent_name_viewGameParentPage) {
            selectedGameParent = (<input
                 dir="auto"
                 className="form-control"
                 fluid id="episode_description"
                 value={this.state.returned_gameParent_name_viewGameParentPage} />);
         }
         else {
            selectedGameParent = (
            <Dropdown fluid 
            selection 
            placeholder='Select Game Parent'
            name="gameParent_id" 
            // id="episode_series_id"  
            onChange={this.handleGameParentChange} 
            options={gameParentList} />
             );
         }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <div className="col-12 left-main-body" id="left-main-body">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link>
                                                        <span> {'>'} </span>
                                                    </span>
                                                    {/* <span className="status-link">
                                                        <Link to={ALL_GAMES}>Get All Games</Link>
                                                        <span> {'>'} </span>
                                                    </span> */}
                                                    <span className="status-link">
                                                        <Link onClick={this.redirect} >
                                                            Games
                                                        </Link>
                                                        <span> {'>'} </span>
                                                    </span>
                                                    <span>Add Game Data</span>
                                                </div>
                                            </div>


                                            <div className="container">
                                                <h3 className="main-title mt-2 font-weight-bold">Add Game Data</h3>
                                                <div className="card">
                                                    <div className="row justify-content-md-center" >
                                                        <div className="col-12">

                                                            <div className="add-items icon-upload-itm mt-4">
                                                                <label className="label">
                                                                    Boy Image
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>
                                                                <UploadImage
                                                                    getImageURLCallBack={this.getGameImageUrl.bind(this)}
                                                                    imageUrl={this.state.game_Image}
                                                                    imagepreview={this.state.game_Image}
                                                                    s3DirectryrName='gamedata' />
                                                            </div>

                                                            <div className="add-items icon-upload-itm mt-4">
                                                                <label className="label">
                                                                    Girl Image
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>
                                                                <UploadImage
                                                                    getImageURLCallBack={this.getGirlImageUrl.bind(this)}
                                                                    imageUrl={this.state.girl_image}
                                                                    imagepreview={this.state.girl_image}
                                                                    s3DirectryrName='gamedata' />
                                                            </div>

                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Name
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    value={this.state.name}
                                                                    name="name"
                                                                    onChange={e => this.onChange(e)}
                                                                    type="text" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Name In English
                                                                    {/* <span style={{ color: 'red' }}>*
                                                                    </span> */}
                                                                </label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    value={this.state.name_in_english}
                                                                    name="name_in_english"
                                                                    onChange={e => this.onChange(e)}
                                                                    type="text" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Game Parent
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>
                                                                {selectedGameParent}
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Key
                                                                    <span style={{ color: 'red' }}>*
                                                                    </span>
                                                                </label>
                                                                <input dir="auto"
                                                                    className="form-control"
                                                                    value={this.state.key}
                                                                    name="key"
                                                                    onChange={e => this.onChange(e)}
                                                                    type="text" />
                                                            </div>
                                                            <label className="label">
                                                                <span>Status</span>
                                                            </label>
                                                            <Dropdown required fluid selection
                                                                id="game_status"
                                                                onChange={this.handleStatusChange}
                                                                options={STATUESES}
                                                                value={this.state.status}
                                                            />
                                                            <br />

                                                            <label className="label">
                                                                <span>Type</span>
                                                            </label>
                                                            <Dropdown required fluid selection
                                                                id="type"
                                                                onChange={this.handleTypeChange}
                                                                options={TYPES}
                                                                value={this.state.type}
                                                            />
                                                            <br />

                                                            <label className="label">
                                                                <span>Order</span>
                                                            </label>
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="number"
                                                                min="0"
                                                                fluid
                                                                name="order"
                                                                onChange={this.onChange}
                                                                value={this.state.order}
                                                            />
                                                            <br />

                                                            <div className="form-group">
                                                                <label className="label">
                                                                    Url
                                                                </label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    value={this.state.url}
                                                                    name="url"
                                                                    onChange={e => this.onChange(e)}
                                                                    type="text" />
                                                            </div>

                                                            <label className="label">
                                                                <span>Is Free</span>
                                                                <br />
                                                                <Switch onColor="#6f42c1" onChange={this.handleIsFreeChange} checked={this.state.is_free} />
                                                            </label>


                                                            <div className="form-group">

                                                                <label className="label">
                                                                    Audio
                                                                </label>
                                                                <UploadAudio
                                                                    getAudioUrlCallBack={this.getAudio.bind(this)}
                                                                    audioPreview={this.state.voice}
                                                                    audioUrl={this.state.voice}
                                                                    s3DirectryrName="episode/audio"
                                                                />
                                                            </div>
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.featured}
                                                                    onChange={this.handleCheckboxChange}
                                                                />
                                                                {' Featured'}
                                                            </label>
                                                            <br/>

<label
                                htmlFor="episode_ContentVendor_id"
                                className="label"
                              >
                                
                                Select Content Vendor
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Select Content Vendor"
                                id="episode_ContentVendor_id"
                                onChange={this.handleContentVendorSelection}
                                options={ContentVendorsArr}
                              />
                              <br />
                                                            
                              
                              
                              


                                                            <div className="groups-btn mt-5 editBTNs">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="float-right rest-floating">
                                                                            <button className="btn btn-dark" onClick={this.redirect}>
                                                                                Cancel
                                                                            </button>
                                                                            {buttonSubmitLoader}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </div>
        )
    }
}
AddGameData.propTypes = {
    addGameData: propTypes.func.isRequired,
    fetchAllGameParent: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.dragDropitem,
    redirectionParentPage: state.redirectionPage.item,

    error: state.gameData.adding_game_data_error,
    success: state.gameData.adding_game_data_sucessfully,
    gameParent: state.gameParent.items,
    
    ContentVendorsList : state.contentVendor.State_items,


});
export default withFirebase(
    connect(
        mapStateToProps,
        {
            addGameData,
            emptyGameData,
            fetchAllGameParent,
            addSeriesIDtoRedirectionPage,
            fetchAllContentVendors,
        }
    )(AddGameData));