import {
    ERROR_GET_USER_PAYMENT_HISTORY, GET_USER_PAYMENT_HISTORY,
    LOAD_GET_USER_PAYMENT_HISTORY
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},

    loading: '',
    getting_user_payment_history_error: '',
    getting_user_payment_history_sucessfully: '',
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_USER_PAYMENT_HISTORY:
            return {
                ...state,
                items: action.payload,
                getting_user_payment_history_error: '',
                getting_user_payment_history_sucessfully: true,
                loading: false
            }

        case ERROR_GET_USER_PAYMENT_HISTORY:
            return {
                ...state,
                getting_user_payment_history_error: action.payload,
                loading: false
            }

        case LOAD_GET_USER_PAYMENT_HISTORY:
            return {
                ...state,
                loading: true
            }

        default:
            return state

    }

}