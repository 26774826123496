import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Redirect } from 'react-router-dom';

import { Button, Icon } from 'semantic-ui-react';
import { withFirebase } from '../../config';
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from "../Layout/Footer";
import { addActivity } from '../../Actions/ActivityActions'
import AddActivityComponent from '../Activity/AddActivityComponent';
import { connect } from 'react-redux';
/*CSS*/
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/AppCss/Episode/EpisodeButtons.css';
/* Routes */
import { ADD_EPISODE, EPISODE_EVALUATION } from '../../routes';

const INITIAL_STATE = {
    questions: []
}
export class AddActivityEpisode extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        this.props.addActivity(this.props.episode.episode_name);

    }

    getValidatinData(questions) {
        console.log(questions)
        this.setState({ questions: questions })

    }

    handelSubmit = (e) =>{

        // console.log(this.state.questions === true)

        //  if (this.state.questions.length == 0) {
        //     alert("Empty Questions, Please add at least one Question")

        // }

    }

    render() {
        const {questions} = this.state;

        console.log(questions)
        // const isInvalid =
        // questions.length === 0 || questions == undefined ;

        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="main-progress-bar">
                                                <ol className="progress-tracker">
                                                    <li className="step active s2-active"><span className="step-name small-font">ِAdd Episode</span></li>
                                                    <li className="step active"><span className="step-name small-font">Add activity</span></li>
                                                    <li className="step "><span className="step-name small-font">Add Evaluation</span></li>
                                                    <li className="step"><span className="step-name small-font">Puplish Episode</span></li>
                                                </ol>
                                            </div>
                                            <br />
                                            <AddActivityComponent validateDataCallBack={this.getValidatinData.bind(this)} />
                                            <div>
                                                <div className="buttons_container">

                                                    <Link to={EPISODE_EVALUATION}>

                                                        <Button icon labelPosition='right'
                                                            id="nextButton"
                                                            //disabled={isInvalid}
                                                            onClick={this.handelSubmit}
                                                            >
                                                            Next
                                                         <Icon name='right arrow' />
                                                        </Button>
                                                    </Link>

                                                    <Link to={ADD_EPISODE}>
                                                        <Button icon labelPosition='left' id="episode_backButton" >
                                                            back
                                                            <Icon name='left arrow' />
                                                        </Button>
                                                    </Link>

                                                    {/* <Button id="episode_cancel">Cancel</Button> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    episode: state.episodes.item
})


export default withFirebase(connect(mapStateToProps, { addActivity })(AddActivityEpisode));
