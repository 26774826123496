import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import OuterHeader from '../Layout/OuterHeader'
import Dashboard from '../Dashboard'
import InnerHeader from '../Layout/InnerHeader'
import Footer from '../Layout/Footer'
import { Button} from 'semantic-ui-react';
import { fetchQuestionsCategories, addQuestionCategory, updateQuestionCategory } from '../../Actions/QuestionCategoryActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getBlogCategories, updateBlogCategory, addBlogCategory } from '../../Actions/BlogCategoryAction'
import DeleteBlogCategory from './DeleteBlogCategory';
import DeleteQuestionCategory from './DeleteQuestionCategory';
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE } from '../../routes';

const INITIAL_STATE = {
    questionCategories: [],
    blogCategories: []
}
class ContentSetting extends Component {
    constructor(props) {
        super(props)
        this.state = INITIAL_STATE
    }
    componentDidMount() {
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to={SIGN_IN}></Redirect>
        }
        else {
            this.props.fetchQuestionsCategories();
            this.props.getBlogCategories();
        }
    }

    componentWillReceiveProps(next) {
        this.setState({
            questionCategories: next.questionCategories,
            blogCategories: next.blogCategories
        })
    }

    addQuestionCategoryField = (e) => {
        this.setState(prevState => ({ questionCategories: [...prevState.questionCategories, { categoryText: '', categoryID: '' }] }))
        e.preventDefault();

    }

    handleQCChange = (i, event) => {
        let q = [...this.state.questionCategories];

        q[i].categoryText = event.target.value;
        this.setState({
            questionCategories: q
        })

    }


    createQCField() {
        let c = null;

        c = this.state.questionCategories.map((el, i) =>

            <div className="card col-5" key={i}>
                <div className='row' >
                    {/* <div className='col-1' id='blabla'><Checkbox name={el.categoryText} value={el.categoryText}/> </div>  */}
                    {el.categoryID !== '' ? (<div className='col-1' id='blabla'><DeleteQuestionCategory id={el.categoryID} /> </div>)
                        : (<div>
                            <button onClick={this.deleteQuestionCat.bind(this, el)} >
                                <i className="far fa-trash-alt" >
                                    <FontAwesomeIcon icon="trash-alt" />
                                </i>
                            </button>
                        </div>)}
                    <div className='col-6'> <input
                        dir="auto"
                        className="form-control"
                        type='text'
                        value={el.categoryText}
                        onChange={this.handleQCChange.bind(this, i)}
                    /> </div>

                </div>
            </div>
        )
        return c;
    }
    deleteQuestionCat = (i, e) => {
        console.log(i)
        let qc = [...this.state.questionCategories];
        const filteredItems = qc.filter(function (item) {
            return item !== i
        })
        this.setState({ questionCategories: filteredItems })
        console.log(filteredItems)
        e.preventDefault();
    }

    deleteBlogCat = (i, e) => {
        let bc = [...this.state.blogCategories];
        const filteredItems = bc.filter(function (item) {
            return item !== i
        })
        this.setState({ blogCategories: filteredItems })
        e.preventDefault();
    }

    createBlogCatField() {
        let c = null;

        c = this.state.blogCategories.map((el, i) =>

            <div className="card col-5" key={i}>
                <div className='row' >
                    {/* <div className='col-1' id='blabla'><Checkbox  name={el.categoryText} value={el.categoryText}/> </div>  */}
                    {el.id !== '' ? (<div className='col-1' id='blabla'><DeleteBlogCategory id={el.id} /> </div>)
                        : (<div>
                            <button onClick={this.deleteBlogCat.bind(this, el)} >
                                <i className="far fa-trash-alt" >
                                    <FontAwesomeIcon icon="trash-alt" />
                                </i>
                            </button>
                        </div>)}
                    <div className='col-6'>
                        <input type='text'
                            dir="auto"
                            className="form-control"
                            value={el.categoryText}
                            onChange={this.handleBlogCatChange.bind(this, i)} /> </div>

                </div>
            </div>
        )
        return c;
    }
    addBlogTypeField = (e) => {
        this.setState(prevState => ({
            blogCategories: [...prevState.blogCategories, { categoryText: '', id: '' }]
        }))
        e.preventDefault()
    }

    handleBlogCatChange = (i, event) => {
        let c = [...this.state.blogCategories];
        c[i].categoryText = event.target.value;
        this.setState({ blogCategories: c })
    }
    handleSubmit = (e) => {
        const { blogCategories, questionCategories } = this.state
        for (let i = 0; i < blogCategories.length; i++) {
            if (blogCategories[i].id !== '') {
                this.props.updateBlogCategory(blogCategories[i].id, blogCategories[i].categoryText)
            }
            else if (blogCategories[i].id === '') {
                this.props.addBlogCategory(blogCategories[i].categoryText)
            }
        }

        for (let i = 0; i < questionCategories.length; i++) {
            if (questionCategories[i].categoryID !== '') {
                this.props.updateBlogCategory(questionCategories[i].categoryID, questionCategories[i].categoryText)
            }
            else if (questionCategories[i].categoryID === '') {
                this.props.addQuestionCategory(questionCategories[i].categoryText)
            }
        }
        e.preventDefault();
    }
    render() {
        const { questionCategories, blogCategories } = this.state
        console.log(questionCategories, blogCategories)

        return (<div>
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                </span>
                                                <span>Content Setting</span>

                                            </div>
                                            <br />
                                            <div className="container">
                                                <h3>Content Setting</h3>
                                                <hr />
                                                <form className="general-setting-form" onSubmit={this.handleSubmit}>
                                                    <div className="general-setting-header"><strong>Activity Setting</strong></div>
                                                    <fieldset className='fieldset'>
                                                        <legend className="legend">Question Category</legend>
                                                        <div className="row">
                                                            {/* {questionCategorydata} */}
                                                            {this.createQCField()}

                                                        </div>
                                                        <Button onClick={this.addQuestionCategoryField} id='contentSettingbutton' >+</Button>

                                                    </fieldset>
                                                    <br />
                                                    <hr />
                                                    <div className="general-setting-header"><strong>Blog Setting</strong></div>

                                                    <fieldset className='fieldset'>
                                                        <legend className="legend">Category Type</legend>
                                                        <div className="row">
                                                            {/* {blogCategoriesData} */}
                                                            {this.createBlogCatField()}
                                                        </div>

                                                        <Button onClick={this.addBlogTypeField} id='contentSettingbutton' >+</Button>

                                                    </fieldset>
                                                    <Button onClick={this.handleSubmit} color="green" className="nextButton"  >Save</Button>
                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </div>)
    }
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    questionCategories: state.questionCategories.items,
    blogCategories: state.blogCategories.items
})
export default connect(mapStateToProps, { fetchQuestionsCategories, getBlogCategories, updateBlogCategory, addBlogCategory, addQuestionCategory, updateQuestionCategory })(ContentSetting);
