import {
    SUB_VALUE_DELETED,
    ERROR_DELETING_SUB_VALUE,
    SAVE_SUB_VALUE,
    ERROR_ADDING_SUB_VALUE,
    UPDATE_SUB_VALUE,
    ERROR_UPDATE_SUB_VALUE,
    EMPTY_SUB_VALUE,

    LOAD_All_SUB_VALUE,
    FETCH_All_SUB_VALUE,
    ERROR_ALL_SUB_VALUE
} from "../Actions/Types";

const Initial_State = {
    item: {},
    items: [],
    showModal: '',
    loading: '',

    error_updating_sub_value: '',
    sub_value_updated_successfully: '',

    sub_value_saved_successfully: '',
    error_adding_sub_value: '',

    sub_value_deleted_successfully: '',
    error_deleteing_sub_value: '',

    getting_all_sub_values_error: '',
    getting_all_sub_values_sucessfully: ''

}

export default function (state = Initial_State, action) {
    switch (action.type) {


        case FETCH_All_SUB_VALUE:
            return {
                ...state,
                items: action.payload,
                getting_all_sub_values_error: '',
                getting_all_sub_values_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_SUB_VALUE:
            return {
                ...state,
                getting_all_sub_values_error: action.payload,
                loading: false
            }

        case LOAD_All_SUB_VALUE:
            return {
                ...state,
                loading: true
            }

        case SUB_VALUE_DELETED:
            return {
                ...state,
                sub_value_deleted_successfully: true,
                error_deleteing_sub_value: '',
                item: action.payload,
                loading: false
            }
        case ERROR_DELETING_SUB_VALUE:
            return {
                ...state,
                sub_value_deleted_successfully: false,
                error_deleteing_sub_value: action.payload,
                loading: false
            }
        case UPDATE_SUB_VALUE:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                sub_value_updated_successfully: true,
                loading: false
            }
        case ERROR_UPDATE_SUB_VALUE:
            return {
                ...state,
                error_updating_sub_value: action.payload,
                sub_value_updated_successfully: false,
                loading: false
            }
        case SAVE_SUB_VALUE:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                sub_value_saved_successfully: true,
                loading: false
            
            }
        case ERROR_ADDING_SUB_VALUE:
            return {
                ...state,
                error_adding_sub_value: action.payload,
                sub_value_saved_successfully: false,
                loading: false

            }
        case EMPTY_SUB_VALUE:
            return {
                ...state,
                sub_value_updated_successfully: '',
                error_loading_sub_value: '',
                error_adding_sub_value: '',
                sub_value_saved_successfully: '',
                error_updating_sub_value: '',
                error_deleteing_sub_value: '',
                sub_value_deleted_successfully: ''

            }
        default:
            return state
    }
}