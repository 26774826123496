import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import userpic from '../../images/user-pic.jpg';
import { Input } from 'semantic-ui-react'


const InnerHeader = () => {
    return (<div className="nav-items">
        {/* <div className="search-div">
            <form action="" className="form-inline" >

            <Input size='small' icon='search' placeholder='Search...' />

            </form>
        </div> */}
        <div className="main-icons">
            <a href="#">
                <FontAwesomeIcon icon="bell" color="gray" />
            </a>
            <a href="#">
                <FontAwesomeIcon icon="cog" color="gray" />
            </a>
            <a href="#" id="eye-activity">
                <FontAwesomeIcon icon="eye" color="gray" />
            </a>
        </div>
        <div className="user-login">
            <img className="user-pic float-left" alt="user-pic" src={userpic} />
            <div className="main-color x-small-font admin-name font-weight-bold">Super Admin Name</div>
        </div>
    </div>
    )
}
export default InnerHeader;
