import axios, { post, put } from 'axios'
import firebase from 'firebase/app';

export const getAllPuzzles = () => {
    const url = "/puzzle/getAllPuzzles"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "get",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        url: url
    });
}

export const savePuzzle = (Image_URL) => {
    const url = "/puzzle/savePuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("url", Image_URL);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const deletePuzzle = (id) => {
    const url = "/puzzle/deletePuzzle"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Puzzle-ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}



/*Start of Drag Drop*/ 

export const getAllDragAndDrops = () => {
    const url = "/dragAndDrop/getAllDragAndDrops"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "get",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        url: url
    });
}

export const getDragAndDrop = (DDID) => {
    const url = "/dragAndDrop/getDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DragDrop-ID", DDID);
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const saveDragAndDrop = (attributes) => {
    const url = "/dragAndDrop/saveDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/json',
            'X-Authorization-Firebase': token
        },
        data: {
            imageUrl: attributes.game_Image,
            cropedImageUrl: attributes.cropedImageUrl,
            dragDropWidth: attributes.dragDropWidth,
            dragDropHeight: attributes.dragDropHeight,
            draggableElements: attributes.listElements
        },
        url: url
    });
}

export const deleteDragAndDrop = (id) => {
    const url = "/dragAndDrop/deleteDragAndDrop"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DragDrop-ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const addDraggableElement = (DDID, draggableWidth, draggableHeight, xCoordinate, yCoordinate, draggableImage) => {
    const url = "/dragAndDrop/addDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: {
            dragDropId: DDID,
            draggableWidth: draggableWidth,
            draggableHeight: draggableHeight,
            xCoordinate: xCoordinate,
            yCoordinate: yCoordinate,
            draggableImage: draggableImage,
        },
        url: url
    });
}

export const deleteDraggableElement = (id) => {
    const url = "/dragAndDrop/deleteDraggableElement"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableElement-ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateOneDraggableElement = (id, width, height, XCoordinate, YCoordinate, imageURL) => {
    const url = "/dragAndDrop/updateOneDraggableElement";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("DraggableId", id);
    formData.append("DraggbleWidth", width);
    formData.append("DraggbleHeight", height);
    formData.append("XCoordinate", XCoordinate);
    formData.append("YCoordinate", YCoordinate);
    formData.append("url", imageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const updateDragAndDrop = (id, width, height, imageURL, croppedImageURL) => {
    const url = "/dragAndDrop/updateDragAndDrop";
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Id", id);
    formData.append("DragDropWidth", width);
    formData.append("DragDropHeight", height);
    formData.append("url", imageURL);
    formData.append("Cropped_Image_Url", croppedImageURL);

    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

/*End of Drag Drop*/ 




export const getAllPaintings = () => {
    const url = "/painting/getAllPaintings"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "get",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        url: url
    });
}


export const savePainting = (Image_URL) => {
    const url = "/painting/savePainting"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("url", Image_URL);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const deletePainting = (id) => {
    const url = "/painting/deletePainting"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("Painting-ID", id);
    return axios({
        method: "post",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}

export const getGeneralActivity = () => {
    const url = "/Activity/getGeneralActivity"
    let token = firebase.auth().currentUser.h.b;
    return axios({
        method: "get",
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
        url: url
    });
}


/*Start of Game Data*/ 


export const getGameData = (ID) => {
    const url = "/gameData/getGameData"
    let token = firebase.auth().currentUser.h.b;
    const formData = new FormData();
    formData.append("ID", ID);
    return axios({
        method: "post",
        headers: {
            'X-Authorization-Firebase': token
        },
        data: formData,
        url: url
    });
}




/*End of GameData*/ 
