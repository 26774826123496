import React, { Component } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { withFirebase } from '../../config';
import { Link } from 'react-router-dom'
import { Button, Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import countryList from 'react-select-country-list'


/**Other Component */
import UploadVideo from '../S3Uploading/UploadVideo';
import UploadImage from '../S3Uploading/UploadImage';
import UploadAudio from '../S3Uploading/UploadAudio';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import Footer from '../Layout/Footer';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/*Actions */
import {
    getInAppNotificationData,
    editInAppNotification,
    emptyError
} from '../../Actions/InAppNotificationAction';

import { getInAppNotificationDataPromise } from '../../Promises/InAppNotification'
/* Routes */
import {
    SIGN_IN,
    ADMIN_LAND_PAGE,
    IN_APP_NOTIFICATION
} from '../../routes';
import RenderInAppNotification from '../VideoPreview/RenderInAppNotification';
import { NOTIFICATION_FOLDER_NAME } from '../../portal/src/constants';

function validURL(str) {
    var res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)

}

var isVideoSelected, isImageSelected;


const is_every_time = [{
    key: 0,
    text: 'Everytime',
    value: true
},
{
    key: 1,
    text: 'Once',
    value: false
}]

const INITIAL_STATE = {

    id: '',
    text: '',
    content_type_video: '',
    content_type_iamge: '',
    content_type_radioButton: 'image',
    audio: '',
    action_link: '',
    is_everyTime: '',
    start_date: '',
    end_date: '',
    selectedCountry: 'all',
    validateInput: false,
    redirection: false,
};

class EditInAppNotification extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {

            let id = this.props.match.params.id;

            this.setState({ id: id })

            getInAppNotificationDataPromise(id).then(res => {

                let countryToUpdate = res.data.country ;
if (countryToUpdate !== "" && countryToUpdate !== null && countryToUpdate !== 'undefined'){
    this.setState({
        selectedCountry : countryToUpdate
    })
}
                
                let audio = res.data.audio;
                if (audio === 'undefined') {
                    audio = null
                }

                if (res.data.isVideo == true) {
                    isVideoSelected = true
                    isImageSelected = false
                    this.setState({
                        content_type_radioButton: 'video',
                        content_type_video: res.data.contentType
                    })
                } else {

                    isVideoSelected = false
                    isImageSelected = true
                    this.setState({
                        content_type_radioButton: 'image',
                        content_type_iamge: res.data.contentType
                    })

                }

                let text = res.data.text;
                if (res.data.text == "null" ||
                    res.data.text == null) {
                    text = ''
                }

                let actionLink = res.data.actionLink;
               
                if (res.data.actionLink == "null"
                    || res.data.actionLink == null) {
                    actionLink = ''
                }
                this.setState({
                    text: text,
                    audio: audio,
                    action_link: actionLink,
                    is_everyTime: res.data.isViewsEveryTime,
                    start_date: res.data.startDate,
                    end_date: res.data.endDate,

                })
            }).catch(error => {
                alert(error)
            })

        }
        let countries = countryList().getLabels();
        let countryOptions = [
            {
                key: 'all',
                value: 'all',
                text: 'All'
            },
            ...countries.map((country) => ({
                key: country,
                value: country,
                text: country
            }))
        ];
        this.setState({ countryOptions: countryOptions });
         

      
    }


    editInAppNotification(id, Content_Type, Text, Audio, Action_Link, Is_NumberOfViews_EveryTime, Start_Date, End_Date, isVideoSelected ,selectedCountry) {

        // this.props.editInAppNotification(id, Content_Type, Text, Audio, Action_Link, Is_NumberOfViews_EveryTime, Start_Date, End_Date, isVideoSelected)
        const notificationData = {
            id :id,
            contentType:Content_Type,
            text: Text,
            audio: Audio,
            actionLink: Action_Link,
            isViewsEveryTime: Is_NumberOfViews_EveryTime,
            isVideo: isVideoSelected,
            startDate: Start_Date,
            endDate: End_Date,
            country: selectedCountry
        }

        this.props.editInAppNotification(notificationData)
    }


    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleRadioChange = (e) => {

        this.setState({ [e.target.name]: e.target.value });
        if (e.target.value === "image") {
            isVideoSelected = false;
            isImageSelected = true
        }
        else if (e.target.value === "video") {
            isVideoSelected = true;
            isImageSelected = false
        }
    }


    handleIsEveryTime = (e, { value }) => {

        this.setState({
            is_everyTime: value
        })

    }


    getImageUrl(imageUrl) {

        if (imageUrl !== '' && imageUrl !== "delete") {
            this.setState({ content_type_iamge: imageUrl });
        }
        else {
            this.setState({ content_type_iamge: '' });
        }
    }

    getVideoUrl(videoUrl) {


        if (videoUrl !== '' && videoUrl !== "delete") {

            this.setState({
                content_type_video: videoUrl
            });

        }
        else {

            this.setState({
                content_type_video: '',
            });
        }
    }

    getAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ audio: '' });
            }
            // else send the url to DB
            else {
                this.setState({ audio: audioUrl });
            }
        }
    }


    handleDateChange = (e, { name, value }) => {

        if (this.state.hasOwnProperty(name)) {
            this.setState({
                [name]: value
            })
        }
    }


    handleCancel = (e) => {
        this.setState({ redirection: true })
    }
    handleCountryDropDown = (e, { value }) => {
        this.setState(prevState => ({
            selectedCountry: value === "all" ? "all" : value
        }));
    };
    
    handleChangeInAppNotification = (uploadInfo,videoKey) => {

        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ content_type_video: uploadInfo.videoId });
        } 

        else {
            this.setState({ content_type_video: this.state.content_type_video});
          }
      };

    handleSubmit = (e) => {

        e.preventDefault();

        if (e.nativeEvent.submitter.id === "nextButton") {
            const { id, text,
                content_type_iamge,
                content_type_video,
                audio,
                action_link,
                is_everyTime,
                start_date,
                end_date } = this.state;

            var now = new Date();


            if (action_link != null
                && action_link != undefined
                && action_link != ''
                && action_link != ""
                && !(validURL(action_link))) {
                alert("Please enter vaild url in action link")
                this.setState({ validateInput: false })

            }

            else if (isVideoSelected == true &&
                (content_type_video == '' ||
                    content_type_video == undefined ||
                    content_type_video == null ||
                    content_type_video == "")) {

                alert("Empty Video ")
                this.setState({ validateInput: false })

            }
            else if (isVideoSelected == false &&
                (content_type_iamge == '' ||
                    content_type_iamge == undefined ||
                    content_type_iamge == null ||
                    content_type_iamge == "")) {

                alert("Empty Image ")
                this.setState({ validateInput: false })

            }

            else if (is_everyTime === ''
                || is_everyTime == undefined
                || end_date == null
                || end_date == "") {
                alert("Empty NumberOf Views ")
                this.setState({ validateInput: false })

            }

            else if (start_date === ''
                || start_date == undefined
                || start_date == null
                || start_date == "") {
                alert("Empty Start Date ")
                this.setState({ validateInput: false })
            }

            else if (!(moment(start_date, 'MM/DD/YYYY', true).isValid())) //true
            {
                alert("Please enter Start_Date in this formate MM/DD/YYYY")
                this.setState({ validateInput: false })
            }

            else if ((!(now.getFullYear() == new Date(start_date).getFullYear()
                && now.getMonth() == new Date(start_date).getMonth()
                && now.getDate() == new Date(start_date).getDate()))
                && new Date(start_date) < now) {

                alert("Start Date can't be before now ")
                this.setState({ validateInput: false })


            }


            else if (end_date === ''
                || end_date == undefined
                || end_date == null
                || end_date == "") {
                alert("Empty End Date ")
                this.setState({ validateInput: false })
            }


            else if (!(moment(end_date, 'MM/DD/YYYY', true).isValid())) //true
            {
                alert("Please enter End_Date in this formate MM/DD/YYYY")
                this.setState({ validateInput: false })
            }
            else {
                this.setState({ validateInput: true })

                let content_type;
                if (isVideoSelected == true) {
                    content_type = content_type_video
                } else {
                    content_type = content_type_iamge
                }


                this.editInAppNotification(id,
                    content_type, text, audio, action_link,
                    is_everyTime, start_date, end_date, isVideoSelected, this.state.selectedCountry)
            }
        }
    }

    render() {

        const {
            content_type_radioButton
        } = this.state;

        const { success, error, loading } = this.props;

        if (this.state.redirection === true) {
            return (<Redirect to={IN_APP_NOTIFICATION} ></Redirect>)
        }

        let buttonSubmitLoader = (
            <Button
                className="ui button"
                id="nextButton">
                Save
            </Button>
        );

        if (loading === true) {
            buttonSubmitLoader = (
                <Button id="nextButton"
                    className="ui loading button">
                    Loading
                </Button>
            )
        }
        if (success === true) {
            this.props.emptyError();
            alert("Edit In App Notification Done Successfully");
            return (<Redirect to={IN_APP_NOTIFICATION} ></Redirect>)

        }
        else if (success === false) {
            alert(error);
            this.props.emptyError();
            let id = this.props.match.params.id;
            this.props.getInAppNotificationData(id);

        }


        let content_type_object = null

        if (content_type_radioButton === "image") {
            content_type_object = (<span>
                {/*  image dropzone*/}
                <label htmlFor="date" className="label">
                    Image <span style={{ color: 'red' }}>*</span>
                </label>
                <div className="">
                    <UploadImage
                        getImageURLCallBack={this.getImageUrl.bind(this)}
                        imageUrl={this.state.content_type_iamge}
                        imagepreview={this.state.content_type_iamge}
                        s3DirectryrName='inappnotification' />

                </div>
            </span>)
        } else if (content_type_radioButton === "video") {

            content_type_object = (
                <span>
                    <label className="label">
                        Video
                    </label>
                    <div>
                       
                            <RenderInAppNotification
                                  videoKey={this.state.content_type_video}
                                  id="inAppNotification"
                                  foldername={NOTIFICATION_FOLDER_NAME}
                                  changedid={this.handleChangeInAppNotification}
                                  ></RenderInAppNotification>
                            </div>
                </span>
            )
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">

                                            <div className="container">
                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                        <Link to={IN_APP_NOTIFICATION}>In App Notification</Link><span> {'>'} </span>

                                                    </span>
                                                    <span>Edit In App Notification</span>

                                                </div>
                                                {/* <!--  end status bar --> */}

                                                <h3>Edit In App Notification</h3>
                                                <hr />

                                                <br />

                                                <form onSubmit={this.handleSubmit.bind(this)} >

                                                    <div className="">

                                                        {/* <label htmlFor="date" className="label">
                                                            Type
                                                            <span style={{ color: 'red' }}>*</span>
                                                        </label> <br />

                                                        <div className="form-inline">

                                                            <label className=" label " id="tab">
                                                                <input type="radio"
                                                                    name="content_type_radioButton"
                                                                    value="video"
                                                                    checked={isVideoSelected}
                                                                    onChange={this.handleRadioChange}
                                                                    required />
                                                                Video
                                                            </label>

                                                            <label className=" label col">
                                                                <input type="radio"
                                                                    name="content_type_radioButton"
                                                                    value="image"
                                                                    checked={isImageSelected}
                                                                    onChange={this.handleRadioChange}
                                                                    required />
                                                                Image
                                                            </label>
                                                        </div>
                                                        <br /> */}
                                                        {content_type_object}


                                                        <br />
                                                        <div className="">
                                                            <label htmlFor="action_link"
                                                                className="label">
                                                                Action Link </label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                fluid name="action_link"
                                                                id="action_link" onChange={this.handleChange}
                                                                value={this.state.action_link} />
                                                            <br />

                                                            <label htmlFor="name" className="label">
                                                                Audio
                                                            </label>

                                                            <UploadAudio
                                                                getAudioUrlCallBack={this.getAudio.bind(this)}

                                                                audioPreview={this.state.audio}
                                                                audioUrl={this.state.audio}
                                                                s3DirectryrName="inappnotification/audio"
                                                            />

                                                            <br />

                                                            <label htmlFor="episode_series_id"
                                                                className="label">

                                                                Select Number Of Views
                                                                <span style={{ color: 'red' }}>*
                                                                </span>
                                                            </label><br />

                                                            <Dropdown fluid
                                                                selection
                                                                placeholder='Number Of Views'
                                                                name=""
                                                                id="episode_series_id"
                                                                onChange={this.handleIsEveryTime}
                                                                options={is_every_time}
                                                                value={this.state.is_everyTime} />                                                            <br />

                                                            <br />


                                                            <label htmlFor="text"
                                                                className="label">Text</label><br />
                                                            <textarea
                                                                dir="auto"
                                                                className="form-control"
                                                                fluid rows="6"
                                                                name="text"
                                                                id="text"
                                                                onChange={this.handleChange}
                                                                value={this.state.text} />
                                                            <br />

                                                            {/* DataComponent */}
                                                            <label htmlFor="start_date" className="label">
                                                                Start Date
                                                                <span style={{ color: 'red' }}>*
                                                                </span>

                                                            </label>

                                                            <DateInput
                                                                className="col"
                                                                name="start_date"
                                                                closeOnMouseLeave
                                                                iconPosition="right"
                                                                dateFormat="MM/DD/YYYY"
                                                                required
                                                                fluid
                                                                value={this.state.start_date}
                                                                placeholder="Start Date"
                                                                onChange={this.handleDateChange}
                                                            />
                                                           
                                                            <br />

                                                            {/* DataComponent */}
                                                            <label htmlFor="endDate" className="label">
                                                                End Date
                                                                <span style={{ color: 'red' }}>*
                                                                </span>

                                                            </label>

                                                            <DateInput
                                                                className="col"
                                                                name="end_date"
                                                                closeOnMouseLeave
                                                                iconPosition="right"
                                                                dateFormat="MM/DD/YYYY"
                                                                required
                                                                fluid
                                                                value={this.state.end_date}
                                                                placeholder="End Date"
                                                                onChange={this.handleDateChange}
                                                            />
                                                             <label htmlFor="country" className="label">
                                                                Country
                                                            </label> <br/>
                                                            <div className="col" style={{width:"100%"}}>
                                                            <Dropdown
                                                            style={{width:"100%"}}
                                                            clearable
                                                            selection
                                                            options={this.state.countryOptions}
                                                            placeholder="Select Country"
                                                            onChange={this.handleCountryDropDown}
                                                            value={this.state.selectedCountry}
                                                        />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <div className="buttons_container">

                                                            {buttonSubmitLoader}
                                                            <Button id="episode_cancel"
                                                                onClick={this.handleCancel}>
                                                                Cancel</Button>

                                                        </div>

                                                    </div>

                                                </form>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}


EditInAppNotification.propTypes = {
    editInAppNotification: propTypes.func.isRequired,
    getInAppNotificationData: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    inAppNotification: state.inAppNotification.item,
    loading: state.inAppNotification.loading,
    error: state.inAppNotification.update_inAppNotification_error,
    success: state.inAppNotification.update_inAppNotification_successfully,

});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                getInAppNotificationData,
                editInAppNotification,
                emptyError
            }
        )
        (EditInAppNotification)
    );
