import React, { useRef, Component } from "react";
import OuterHeader from "../Layout/OuterHeader";
import InnerHeader from "../Layout/InnerHeader";
import Dashboard from "../Dashboard";
import Footer from "../Layout/Footer";
import UploadImage from "../S3Uploading/UploadImage";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../css/AppCss/addBlog.css";
import PublishDate from "../Layout/PublishDate";
import { Button, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { saveBanner, emptyBanner } from "../../Actions/BannerAction";
import { Redirect, Link } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';

import porpTypes from "prop-types";
import JoditEditor from "jodit-react";
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_BANNERS } from '../../routes';
import BannerCategories from "../ConstData/BannerCategories";

const INITIAL_STATE = {
  image: "",
  title: "",
  titleInEnglish :"",
  description: "",
  link: "",
  cancel: false,
  editorImage: '',
  error: true,
  errorMessage: "",
  selectedCategory:"",
};

class AddBanner extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {

  }

  getImage(imageUrl) {
    if (imageUrl) {

      if (imageUrl === "delete") {
        this.setState({ image: '' });

      } else {
        this.setState({ image: imageUrl });
      }
    }
  }


  getEditorImage(imageUrl) {
    if (imageUrl) {

      if (imageUrl === "delete") {
        this.setState({ editorImage: '' });

      } else {
        this.setState({ editorImage: imageUrl });
      }
    }
  }


  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces });

        if ((this.state.title === '' || this.state.title === null || this.state.title === undefined || this.state.title.trim() === ''
        || this.state.titleInEnglish === '' || this.state.titleInEnglish === null || this.state.titleInEnglish === undefined || this.state.titleInEnglish.trim() === '')) {
            this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
        }
        else {
            this.setState({ error: false, errorMessage: "" });
        }
  
}

handleCategoryDropDown = (e, { value }) => {
  this.setState(prevState => ({
      selectedCategory: value 
  }));
};

handleSubmit=e=>{
    const {
      title,
      titleInEnglish,
      description,
      image,
      link,
      selectedCategory
    } = this.state;



    if (image === '' || image === undefined || image === null) {
      alert("please insert image");
      this.setState({
        validateInput: false
      })
    }
    else if (title === '' || title === undefined || title === null || title.trim() === '') {
      alert("please insert title in arabic");
      this.setState({
        validateInput: false
      })
    }
    else if (titleInEnglish === '' || titleInEnglish === undefined || titleInEnglish === null  || titleInEnglish.trim() === '') {
      alert("please insert title in english");
      this.setState({
        validateInput: false
      })
    }
    else if (selectedCategory === '' || selectedCategory === undefined || selectedCategory === null || selectedCategory.trim() === '') {
      alert("please select category");
      this.setState({
        validateInput: false
      })
    }



    else {
      this.setState({ validateInput: true })
      this.props. saveBanner(
        title,
        titleInEnglish,
        description,
        image,
       link,
       selectedCategory
      );
    }
    e.preventDefault();
  };

  handelCancel = e => {
    this.setState({ cancel: true });
  }


  render() {
    const
      config = {
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        enableDragAndDropFileToEditor: true,
        useImageResizer: false,
        direction: 'rtl',
      }


    const {bannererror,  bannerSuccess} = this.props;


    if ( bannerSuccess === false) {
      alert( bannererror);
      this.props. emptyBanner();
    }
    else if ( bannerSuccess === true) {
      alert("banner added successfully");
      this.props. emptyBanner();
      return <Redirect to={ALL_BANNERS} />;
    }

    if (this.state.cancel === true) {
      return (<Redirect to={ALL_BANNERS}></Redirect>)

    }
    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col-9">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">

                    <div className="col-12 left-main-body" id="left-main-body">
                      <div className="statusbar small-font">
                        <span className="status-link">
                          <Link to={ADMIN_LAND_PAGE}>Home</Link><span>  </span>
                        </span>
                        <span className="status-link">
                          <Link to={ALL_BANNERS}>Banners</Link>
                        </span>
                      </div>
                      <br />
                      <h5 className="main-title mt-2 font-weight-bold">
                        <span>Add Banner</span>
                      </h5>
                      <br />
                      <div className="container">
                        <form onSubmit={this.handleSubmit}>
                          <span className="required_input">*</span>
                          <label className="small-label">Image</label>
                          <UploadImage
                            getImageURLCallBack={this.getImage.bind(this)}
                            imageUrl={this.state.image}
                            imagepreview={this.state.image}
                            s3DirectryrName='banner'
                          />
                          <br />
                          <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Title In Arabic</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title"
                            name="title"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.title}
                          />
                          <br />
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Title In English</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title_in_english"
                            name="titleInEnglish"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.titleInEnglish}
                          />
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              Description
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="description"
                              onChange={this.handleChange}
                              onKeyUp={this.handleChange}
                              value={this.state.description}
                            />
                          </div>
                          <br />

                         
                          <label className="small-label">
                           
                            Link</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="link"
                            name="link"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.link}
                          />
                          <br />

                          <label className="small-label">
                            <span className="required_input">*</span>
                            Category</label>

                            <Dropdown
                            className="form-control mb-5"
                            id="category"
                            name="category"
                            clearable
                            selection
                            options={BannerCategories}
                            placeholder="Select Category"
                            onChange={this.handleCategoryDropDown}
                            />
                            <br />

                          <Button
                            icon
                            labelPosition="right"
                            id="nextButton"
                            onClick={this.handleSubmit}
                            disabled={this.state.error}
                          >
                            Publish
                          </Button>

                          <Button id="episode_cancel"
                            onClick={this.handelCancel}>
                            Cancel
                          </Button>

                          <br />
                          <br /> <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

AddBanner.porpTypes = {

};

const mapStateToProps = state => ({
  banner: state.banners.item,
   bannererror: state.banners.error_adding_blog,
   bannerSuccess: state.banners.banner_added_successfully
});

export default connect(
  mapStateToProps,
  {  saveBanner,  emptyBanner}
)(AddBanner);
