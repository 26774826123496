export default [{
    key: 'on',
    text: 'on',
    value: 'on'
},
{
    key: 'after',
    text: 'after',
    value: 'after'
},
{
    key: 'before',
    text: 'before',
    value: 'before'
},
{
    key: 'between',
    text: 'between',
    value: 'between'
},
{
    key: 'not between',
    text: 'not between',
    value: 'not between'
}]
