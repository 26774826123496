import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import placeHolder from '../../images/placeholder.png';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/**Actions */
import {
    fetchAllRadio,
    emptyRadioWithChannels,
    addRadioItemWithSentFrom
} from '../../Actions/RadioActions';
import {
    fetchPdfBooks,
    addPdfBookItemWithSentFrom
} from '../../Actions/PdfBookAction';

import { emptyEvaluationAll } from '../../Actions/EvaluationActions'
// import { emptyAllChannels } from '../../Actions/RadioChannelAction'
import { emptyAllPdfBooks } from '../../Actions/PdfBookAction'
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'
import { clearQuestions } from '../../Actions/QuestionActions';


/*Components */
import Loader from '../Layout/Loader';
import DeletePdfBook from '../PdfBook/DeletePdfBook'
// import SetStateBook from './SetStateBook'
import SetPdfBookState from '../PdfBook/SetPdfBookState'
import { ALL_PDF_BOOK_PAGE_NAME, ALL_PDF_BOOK, EDIT_PDF_BOOK, VIEW_PDF_BOOK, PDF_BOOK_TO_GET, PDF_BOOK_TO_EDIT } from '../../routes';

const INITIAL_STATE = {
    redirection: false,
    addingDone: false,
    itemId: '',
    itemName: ''
}

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0! 
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}
function ConvertDate(date) {
    var date = new Date(date)
    var convertedDate = date.toLocaleString()
    return convertedDate
}

class GetAllPdfBookList extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {
        this.props.emptyEvaluationAll();
        this.props.emptyAllPdfBooks();

        this.props.fetchPdfBooks();
        // this.props.addSeriesIDtoRedirectionPage(ALL_BOOK, 0);

        this.props.clearQuestions();
    }

    redirectToView = (itemId, e) => {

        this.props.addSeriesIDtoRedirectionPage(VIEW_PDF_BOOK, itemId);
        this.setState({ redirection: true,
            itemId: itemId })
    }

    addingItemToRedux = (item, e) => {

        this.props.addPdfBookItemWithSentFrom(item, ALL_PDF_BOOK_PAGE_NAME);
        this.setState({ addingDone: true,
            itemId: item.id
         })
    }

    render() {
        const {pdfBook} = this.props
        const { error, loading } = this.props;
        const { redirection, addingDone } = this.state;


        const { filterName, nameFilterCondition,
            description, descriptionFilterCondition,
            publishDate,
            dateFilterCondition,
            secondPublishDate,
            isFree,
            status,
            featured,
        } = this.props;

        let choosenNameFilter = '',
            choosenPublishDate = '',
            choosenStatus = '',
            choosenDescription = ''

            let booksToFilter = ''
            if(pdfBook) {
                if (featured){
                    booksToFilter = pdfBook.filter(item => (item.featured === true))
                }
                else {
                    booksToFilter =pdfBook
                }
            }

        const FilteredList = booksToFilter.filter(item => {

            if (nameFilterCondition == 'is') {
                choosenNameFilter = item.pdfName.toLowerCase() == filterName.toLowerCase()
            } else if (nameFilterCondition == 'does not contain') {
                choosenNameFilter = !item.pdfName.toLowerCase().includes(filterName.toLowerCase())
            } else if (nameFilterCondition == 'is not') {
                choosenNameFilter = item.pdfName.toLowerCase() != filterName.toLowerCase()
            } else {
                choosenNameFilter = item.pdfName.toLowerCase().includes(filterName.toLowerCase())
            }

            console.log("description loop" + item.description)
            if (item.description !== null &&
                item.description !== undefined &&
                item.description !== '') {

                console.log("description not null" + item.description)

                if (descriptionFilterCondition == 'is') {
                    choosenDescription = item.description.toLowerCase() == description.toLowerCase()
                } else if (descriptionFilterCondition == 'does not contain') {
                    choosenDescription = !item.description.toLowerCase().includes(description.toLowerCase())
                } else if (descriptionFilterCondition == 'is not') {
                    choosenDescription = item.description.toLowerCase() != description.toLowerCase()
                } else {
                    if (description !== '' && description !== null && description !== undefined) {
                        choosenDescription = item.description.toLowerCase().includes(description.toLowerCase())
                        console.log("contains = " + choosenDescription)
                    } else {
                        console.log("description true")
                        choosenDescription = true
                    }

                }
            }

            if (status == 'DEFAULT') {
                choosenStatus = item.status == 'Default' || item.status == 'DEFAULT'
            } else if (status == 'SUSPENDED') {
                choosenStatus = item.status == 'SUSPENDED'
            }

            if (item.publishDate != null) {


                let arrayOfDate = item.publishDate.split(" ")
                let date = arrayOfDate[0];

                if (dateFilterCondition == 'on') {
                    choosenPublishDate = publishDate == date
                }

                else if (dateFilterCondition == 'after') {

                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);

                    choosenPublishDate = filterDate < seriesDate
                }
                else if (dateFilterCondition == 'before') {
                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);

                    choosenPublishDate = filterDate > seriesDate;
                }
                else if (dateFilterCondition == 'between') {

                    if (secondPublishDate !== '' &&
                        secondPublishDate !== null &&
                        secondPublishDate !== undefined) {
                        var seriesDate = new Date(date);
                        var filterDate = new Date(publishDate);
                        var filterSecondDate = new Date(secondPublishDate);

                        choosenPublishDate = filterDate < seriesDate && seriesDate < filterSecondDate
                    }
                }
                else if (dateFilterCondition == 'not between') {
                    if (secondPublishDate !== '' &&
                        secondPublishDate !== null &&
                        secondPublishDate !== undefined) {
                        console.log("////////////////" + item.pdfName + "////////////////////")
                        var seriesDate = new Date(date);
                        var filterDate = new Date(publishDate);
                        var filterSecondDate = new Date(secondPublishDate);

                        console.log("seriesDate")
                        console.log(date)
                        console.log(seriesDate)

                        console.log("filterDate")
                        console.log(publishDate)
                        console.log(filterDate)

                        console.log("filterDate")
                        console.log(secondPublishDate)
                        console.log(filterSecondDate)

                        let notbetween = filterDate < seriesDate && seriesDate < filterSecondDate

                        choosenPublishDate = !notbetween
                    }

                }
            }

            ///* Name and Date and status and description
            if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("Name and Date and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter && choosenStatus && choosenDescription
                }
            }
            ///* Name and Date and status
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("Name and Date and status")
                return choosenPublishDate && choosenNameFilter && choosenStatus
            }

            ///* Name and Date and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("Name and Date and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter && choosenDescription
                }
            }
            ///* Name and Date
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined
            ) {
                console.log("Name and Date")
                return choosenPublishDate && choosenNameFilter
            }

            //date and status and description
            else if (publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenStatus && choosenDescription
                }
            }
            //date and status
            else if (publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("date and status")
                return choosenPublishDate && choosenStatus
            }
            //date and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null &&
                dateFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenDescription
                }
            }
            //date
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined) {
                console.log("date")
                return choosenPublishDate
            }
            //name and status and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("name and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenNameFilter && choosenStatus && choosenDescription
                }
            }
            // status and description
            else if (
                status !== "" && status !== null && status !== undefined && status !== 'none' &&
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenStatus && choosenDescription
                }
            }
            //name and status
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("name and status")
                return choosenNameFilter && choosenStatus
            }

            // status
            else if (
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("status")
                return choosenStatus
            }

            //name and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null &&
                nameFilterCondition !== undefined &&
                descriptionFilterCondition !== "" &&
                descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("///name and description")

                // base case both are null return all values
                if ((filterName == '' || filterName == null || filterName == undefined) &&
                    (description == '' || description == null || description == undefined)) {
                    console.log("both empty")
                    return true;
                }
                else if ((filterName !== '' && filterName !== null && filterName !== undefined) &&
                    (description !== '' && description !== null && description !== undefined)) {
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        console.log("both not empty")
                        return choosenNameFilter && choosenDescription
                    }
                }
                else if (filterName !== ''
                    && filterName !== null
                    && filterName !== undefined) {
                    console.log("name exist")
                    return choosenNameFilter
                }
                else if (description !== '' && description !== null && description !== undefined) {
                    console.log("description exist")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {

                        return choosenDescription;
                    }
                }
            }
            //name
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined) {
                console.log("name")
                return choosenNameFilter
            }
            // description
            else if (
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("description")

                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenDescription
                }
            }
        })
        console.log('FilteredList', FilteredList)
        let bookList = null

        if (redirection == true) {
            return (<Redirect to={VIEW_PDF_BOOK} ></Redirect>)
        }

        if (addingDone == true) {
            // let {itemName, itemId} = this.state
            // let EditURL = BOOK_TO_GET + "/" + itemId
            
            return (<Redirect to={PDF_BOOK_TO_EDIT} ></Redirect>)
        }

        if (loading === true) {
            bookList = (<Loader />);
        }
        else if (loading === false &&
            error === '') {
            

            let bookItems = pdfBook
            if (
                filterName ||
                publishDate ||
                description ||
                status
            ) {
                bookItems = FilteredList
            }
            bookList = bookItems ? (
                bookItems.map(item => {

                    let parentDescription = "";
                    if (!item.pdfImage) {
                        item.pdfImage = placeHolder;
                    }

                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== '') {
                        parentDescription = item.description
                    }
                    else {
                        parentDescription = "No description"
                    }

                    return (
                        <div className="card" id="editEpisodeCard"
                        key={item.id}>
                        <div className="row" id="episode-header">
                            <div className="col">
                                <img className="img-thumbnail"
                                    src={item.pdfImage}
                                    alt="radio " />
                            </div>
                            <div className="col-10" >

                                <div className="title_buttons_container">

                                    <span className="card-title"
                                        id="editSeriesCard-title">
                                        <Link className="link"
                                        onClick={this.redirectToView.bind(this, item.id)}
                                            >
                                            {item.pdfName}</Link>
                                    </span>

                                    <DeletePdfBook id={item.id}
                                        BookId={item.id} />

                                        <button
                                            className="songCardButtons"
                                            onClick={this.addingItemToRedux.bind(this, item)} >
                                            Edit
                                         </button>
                                    <SetPdfBookState id={item.id}
                                        status={item.status}
                                        bookId={item.id} />

                                </div>
                                <span className="font-italic small-font ">
                                    <span className="date">
                                        {GMTtolocalTime(item.publishDate)}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <hr />
                        <span><p>{parentDescription}</p></span>

                    </div>
                    )
                })
            ) : (
                    <div className="text-center">No Books </div>
                )

        }

        else {
            bookList = (<div><br /><center><div>{error}</div></center><br /></div>)
        }
        return (
            <div className="container">
                {bookList}
            </div>

        )
    }
}

GetAllPdfBookList.propTypes = {
    fetchPdfBooks: propTypes.func.isRequired,
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    // addRadioItemWithSentFrom: propTypes.func.isRequired
    addPdfBookItemWithSentFrom: propTypes.func.isRequired

}
const mapStateToProps = state => ({
    pdfBook: state.pdfBook.items,
    loading: state.pdfBook.loading,
    error: state.pdfBook.error_loading_all_books
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                fetchPdfBooks,
                emptyAllPdfBooks,
                emptyEvaluationAll,
                addSeriesIDtoRedirectionPage,
                // addRadioItemWithSentFrom,
                addPdfBookItemWithSentFrom,
                clearQuestions,
                emptyRadioWithChannels

            }
        )
        (GetAllPdfBookList)
    );