import React, { Component } from 'react'
import ReactTable from 'react-table';
import { Link } from 'react-router-dom'
/*CSS */
import 'react-table/react-table.css';
/*Routes*/
import { GET_VENDOR_TO_EDIT } from '../../routes';

class VendorReportTable extends Component {
    render() {

        const { vendors } = this.props;

        const columns = [

            {
                Header: "ID",
                accessor: "id",
                width: 40,
                MinWidth: 50,
                Maxwidth: 130,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_VENDOR_TO_EDIT + "/" + props.original.id }}>

                                {props.original.id}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: "User Name",
                accessor: "name",
                width: 109,
                MinWidth: 140,
                Maxwidth: 140,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (

                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_VENDOR_TO_EDIT + "/" + props.original.id }}>

                                {props.original.name}
                            </Link>

                        </div>
                    )
                }

            },
            {
                Header: "Country",
                accessor: "vendorMasterUid.country",
                width: 100,
                filterable: false,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_VENDOR_TO_EDIT + "/" + props.original.id }}>

                                {props.original.vendorMasterUid.country}
                            </Link>

                        </div>
                    )
                }

            },
            {
                Header: "Phone",
                accessor: "vendorMasterUid.phone",
                width: 100,
                filterable: false,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_VENDOR_TO_EDIT + "/" + props.original.id }}>

                                {props.original.vendorMasterUid.phone}
                            </Link>

                        </div>
                    )
                }

            }
        ]

        return (
            <ReactTable

                columns={columns}
                data={vendors}
                defaultPageSize={5}
                className="-striped -highlight"
                showPagination={false}
            >

            </ReactTable>

        )
    }
}

export default VendorReportTable
