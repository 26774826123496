import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom'

/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import Footer from '../Layout/Footer';
/*Actions */
import { register } from '../../Actions/AuthActions'
import { emptyError } from '../../Actions/Empty'
import { fetchAllAdminsRole, fetchAdminRoles } from '../../Actions/UserRolesActions'

/* firebase */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'

/*Redux */
import { connect } from 'react-redux';
import { withFirebase } from '../../config';
import propTypes from 'prop-types';

/*Component */
import Loader from '../Layout/Loader';
/* CSS */
import '../../css/AppCss/Authentication/Register.css';
/* Routes */
import {ADMIN_LAND_PAGE, USER_MANAGEMENT} from '../../routes';

const INITIAL_STATE = {
  userName: '',
  password: '',
  email: '',
  phone: '',
  error: '',
  authUser: {},

  selected_userRole_id: '',
  selected_userRole_name: ''
};

class Register extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    // if auth user - Super Admin or Admin adding another admin -
    if (firebase.auth().currentUser) {
      firebase.auth().currentUser.getIdToken().then((token) => {
        this.props.fetchAdminRoles(token);
      })

    } else {
      // else 'our normal case'
      this.props.fetchAllAdminsRole()
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = (e) => {
    const { userName, password, email, selected_userRole_name } = this.state;

    this.props.register(email, password, userName, selected_userRole_name);
    e.preventDefault();
  }

  handleAdminChange = (e, { value }) => {
    console.log("selected_userRole_id 4 = " + value[0].id);
    console.log("selected_userRole_id 5 = " + value[1].name);

    this.setState({ selected_userRole_id: value[0].id })
    this.setState({ selected_userRole_name: value[1].name })
  }

  render() {

    let $Loader = null;

    const { userName, password, email, error, selected_userRole_name } = this.state;

    const { success, loading, errorMsg } = this.props;

    const { userRole } = this.props;
    const userRoleList = [];
    for (let i = 0; i < userRole.length; i++) {
      userRoleList.push({
        key: userRole[i].role_id,
        text: userRole[i].role,
        value: [{ id: userRole[i].role_id }, { name: userRole[i].role }]
      })

    }

    // for dropdown Admin selection
    let selectedRole = null;
    selectedRole = (<Dropdown fluid selection placeholder='Select Admin' name="selected_userRole_name" id="episode_series_id" onChange={this.handleAdminChange} options={userRoleList} />
    );



    if (loading === true) {
      $Loader = (<Loader />)
    }

    if (success === true) {
      this.props.emptyError();
      alert("Registeration Done Successfully");
      return (<Redirect to={USER_MANAGEMENT}></Redirect>)
      this.setState({
        userName: '',
        password: '',
        email: '',
        selected_userRole_id: '',
        selected_userRole_name: ''
      })


    }
    else if (success === false) {
      alert("error missing data or network " + errorMsg);
      this.props.emptyError();
    }

    const isInvalid =
      userName === '' ||
      password === '' ||
      selected_userRole_name === '' ||
      email === '';

    return (

      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">

              <Dashboard />
              <div className="col">
                <InnerHeader />

                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <br />
                      <div className="container">

                        {/* <!--  start status bar --> */}
                        <div className="statusbar small-font">
                          <span className="status-link">
                            <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                          </span>
                          <span className="status-link">
                            <Link to={USER_MANAGEMENT}>User Management</Link><span> {'>'} </span>
                          </span>
                          <span>Add New Admin</span>
                        </div>
                        {/* <!--  end status bar --> */}

                        {$Loader}

                        <h5 className="main-title mt-2 font-weight-bold">Add User</h5>
                        <div className="card mt-3 p-0">
                          <div className="card-body p-4">

                            <form onSubmit={this.handleSubmit} className="white">

                              <div className="row">
                                <div className="col-md-9 col-sm-12">

                                  <div className="form-group">
                                    <label className="small-label">User Name</label>
                                    <input
                                      dir="auto"
                                      type="text"
                                      className="form-control"
                                      name="userName"
                                      id="userName"
                                      onChange={this.handleChange}
                                      value={this.state.userName} />
                                  </div>

                                  <div className="form-group">
                                    <label className="small-label" htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control" name="email" id="email" aria-describedby="emailHelp"
                                      onChange={this.handleChange}
                                      value={this.state.email} />
                                  </div>

                                  <div className="form-group">
                                    <label className="small-label" htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control" name="password" id="password" onChange={this.handleChange}
                                      value={this.state.password} />
                                  </div>

                                  <div className="form-group">
                                    <label className="small-label">Role</label>
                                    {selectedRole}

                                  </div>
{/* 
                                  <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label small-font" htmlFor="exampleCheck1">Send notification by email</label>
                                  </div> */}

                                </div>
                              </div>

                              {/* Success Message */}
                              {/* <div className="alert-box">
                                <div className="alert alert-success" role="alert">
                                  <span className="font-weight-bold"><i className="fas fa-check"></i> success </span>Your Request was finished successfully.
                                 </div>
                              </div> */}


                              <div className="groups-btn mt-5">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="float-right rest-floating">
                                      <button type="submit" disabled={isInvalid} className="btn btn-lg btn-primary btn-nxt btn-block-sm" id="btn-add" >Add</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />

      </div>
    )

  }

}
Register.propTypes = {
  fetchAllAdminsRole: propTypes.func.isRequired,
  fetchAdminRoles: propTypes.func.isRequired
}
const mapStateToProps = state => (
  {
    success: state.authUser.success,
    loading: state.authUser.loading,
    errorMsg: state.authUser.errorMsg,

    userRole: state.userRole.items,
    userRoleLoading: state.userRole.loading,
    userRoleError: state.userRole.fetching_user_role_error,
    userRoleSuccess: state.userRole.fetching_user_role_sucessfully,

    auth: state.firebase.auth,
  })

export default withFirebase(connect(mapStateToProps, { register, emptyError, fetchAllAdminsRole, fetchAdminRoles })(Register));

// export default withFirebase(Register);
