import { LOAD_SETTINGS, FETCH_SETTINGS, ERROR_LOADING_SETTINGS, ADD_SETTING, ERROR_ADDING_SETTING, UPDATE_SETTING, ERROR_UPDATING_SETTING, DELETE_SETTING, ERROR_DELETING_SETTING ,GET_SETTING_DATA ,ERROR_LOADING_SETTING , EMPTY_SETTING} from "./Types";

import axios, { post, put  } from 'axios'

export const getAllSettings = () => dispatch => {
    dispatch({
        type: LOAD_SETTINGS,
        payload: 'Loading'
    });

    axios.get('/settings/getSettings')
        .then((res) => {
            dispatch({
                type: FETCH_SETTINGS,
                payload: res.data
            });
        }).catch((error) => {
            dispatch({
                type: ERROR_LOADING_SETTINGS,
                payload: error.message
            });
        });
};
export const getAllSettingsWithoutVdocipher = () => dispatch => {
    dispatch({
        type: LOAD_SETTINGS,
        payload: 'Loading'
    });

    axios.get('/settings/getSettingsWithoutVdocipher')
        .then((res) => {
            dispatch({
                type: FETCH_SETTINGS,
                payload: res.data
            });
        }).catch((error) => {
            dispatch({
                type: ERROR_LOADING_SETTINGS,
                payload: error.message
            });
        });
};

export const saveSetting = (name, value) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/settings/addSettings"
            const formData = new FormData();
            formData.append('name', name)
            formData.append('value', value)
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: ADD_SETTING,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_SETTING,
                    payload: Error.message
                })

            })
        })
    }

}

export const updateSetting = (id, name, value) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SETTINGS,
                payload: 'loading'
            })
            const url = '/settings/updateSettings'
            const formData = new FormData();
            formData.append('id', id)
            formData.append('name', name)
            formData.append('value', value)
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            put(url, formData, config).then((res) => {
                dispatch({
                    type: UPDATE_SETTING,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATING_SETTING,
                    payload: Error.message
                })
            })

        })
    }

}

export const emptySetting = () => dispatch => {
    dispatch({
        type: EMPTY_SETTING,
        payload: ''
    })
}
export const deleteSetting = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/settings/deleteSettings";
            const formData = new FormData();
            formData.append('setting_id', id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            };

            axios.post(url, formData, config)
                .then(() => {
                    dispatch({ type: DELETE_SETTING });
                })
                .catch((error) => {
                    dispatch({ type: ERROR_DELETING_SETTING, payload: error.message });
                });
        });
    };
};


export const getSettingData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
           

            const url = "/settings/getSetting";
            const formData = new FormData();
            formData.append('setting_id', id);
            

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            };

            // Using 'post' function from 'axios'
            post(url, formData, config)
            .then((res) => {
                dispatch({ type: GET_SETTING_DATA, payload: res.data });
            })
            .catch((error) => {
                dispatch({ type: ERROR_LOADING_SETTING, payload: error.message });
            });
        });
    };
};