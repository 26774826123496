import { Promise } from 'q';
import axios, { put, post } from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

export function getUserInfo(token ) {
    return new Promise(function (resolve, reject) {
// console.log("getUserInfo = " + token)
        const url = "/User/getUserData";
        const formdata = new FormData();
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': token
            }
        }
        post(url, formdata, config).then((res) => {
            console.log("success ")
            console.log(res.data)
            resolve(res)
            return res.data
        }
        ).catch((Error) => {
            console.log(Error)
            reject(Error)
        })
    })
}
