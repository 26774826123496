import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { deleteFAQTermsAndConditions } from '../../Actions/TermsAndConditionsActions'
import { emptyError } from '../../Actions/Empty'
import { fetchAllTermsAndConditions } from '../../Actions/TermsAndConditionsActions'

export class DeletTermsAndConditions extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    deleteFAQTermsAndConditions(id) {
        this.props.deleteFAQTermsAndConditions(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        this.deleteFAQTermsAndConditions(id);
        this.close();
    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Terms And Conditions Deleted Successfully")
            this.props.emptyError();
            this.props.fetchAllTermsAndConditions();
        }

        return (
            <div className="acor-btn-group">

                    <button className="acor-btn2" onClick={this.open}>
                        <i className="far fa-trash-alt" >
                            <FontAwesomeIcon icon="trash-alt" />
                        </i>
                    </button>
                    <Confirm id="modal"
                    content='Are you sure you want to delete this terms and conditions ??'
                     open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>

        )
    }
}

DeletTermsAndConditions.propTypes = {
    deleteFAQTermsAndConditions: propTypes.func.isRequired,
    fetchAllTermsAndConditions: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    termsandconditions: state.termsandconditions.item,
    error: state.termsandconditions.deleting_all_terms_Conditions_error,
    success: state.termsandconditions.deleting_all_terms_Conditions_sucessfully
});


export default withFirebase(connect(mapStateToProps, { deleteFAQTermsAndConditions, emptyError, fetchAllTermsAndConditions })(DeletTermsAndConditions));
