import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import propTypes from 'prop-types';
/*Components */
import UserManagementTabel from './UserManagementTabel';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
/* Action */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import {REGISTER, ADMIN_LAND_PAGE} from '../../routes';

export class UserManagement extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
    }

    render() {
      const {userPaymentProfile} = this.props;
      let addNewAdminButton = '';
      if(userPaymentProfile.user_role.role === "SuperAdmin" || userPaymentProfile.user_role.role === "ADMIN"){
        addNewAdminButton = (
          <div className="col-12 left-main-body" id="left-main-body">
              <Link to={REGISTER}><button type="submit"
                  className="seriesButtons">
                  + Add New Admin
                  </button></Link>
          </div>
        )
      }
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">

                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    </span>
                                                    <span>User Management</span>

                                                </div>
                                                {/* <!--  end status bar --> */}

                                                {addNewAdminButton}

                                                <h5 className="main-title mt-2 font-weight-bold">
                                                    User Management</h5>
                                                <div className="card mt-3 p-0">
                                                    <div className="card-body p-4">
                                                        {/* <!-- start form --> */}
                                                        <form className="alluserMng" onSubmit={(e)=> e.preventDefault()}>
                                                            <div className="mt-4 responsive-table">
                                                                {/* Admins Table */}
                                                                <UserManagementTabel />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    userPaymentProfile: state.userPaymentProfile.item
});

export default withRouter
    (connect
        (mapStateToProps,
            { getUserData })
        (UserManagement)
    );
