import {
  SAVE_PAYMENT_METHODS,
  SAVE_PRICE_SUBSCRIBE_CARD,
  SAVE_DISCOUNT_SUBSCRIBE_CARD,
  SAVE_DISCRIPTION_SUBSCRIBE_CARD,
  SAVE_EXPIRATIONDATE_SUBSCRIBE_CARD,
  SAVE_SLUG_SUBSCRIBE_CARD,
  CLEAR_PAYMENT_DATA,
  ERORR_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
  GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
  LOAD_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
  CHANGE_PAYMENT_METHOD_TYPE,
} from "./Types"; 

import axios from 'axios' 

export const fetchPackagesAndMethods = (countryCode) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOAD_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
        payload: "Loading",
      });
      const response = await axios.get(
        `/api/getPackagePriceAndPaymentMethodByCountry?Country_Code=${countryCode}`
      );
      dispatch({
        type: GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERORR_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
        payload: error.message,
      });
    }
  };
};
export const savePriceSubscribeCard = (value) => {
  return {
    type: SAVE_PRICE_SUBSCRIBE_CARD,
    payload: value,
  };
};
export const savePaymentMethod = (value) => {
  return {
    type: SAVE_PAYMENT_METHODS,
    payload: value,
  };
};
export const saveDiscountSubscribeCard = (value) => {
  return {
    type: SAVE_DISCOUNT_SUBSCRIBE_CARD,
    payload: value,
  };
};
export const saveExpirationDateSubscribeCard = (value) => {
  return {
    type: SAVE_EXPIRATIONDATE_SUBSCRIBE_CARD,
    payload: value,
  };
};
export const saveDescriptionSubscribeCard = (value) => {
  return {
    type: SAVE_DISCRIPTION_SUBSCRIBE_CARD,
    payload: value,
  };
};

export const saveSlugDateSubscribeCard = (value) => {
  return {
    type: SAVE_SLUG_SUBSCRIBE_CARD,
    payload: value,
  };
};

export const clearPaymentData = () => {
  return {
    type: CLEAR_PAYMENT_DATA,
    payload: "",
  };
};

export const changePaymentMethodType =(value)=>{
  return {
    type: CHANGE_PAYMENT_METHOD_TYPE,
    payload: value,
  }
}
