import React, { Component, useEffect, useState } from "react";
import Promocode from "./Promocode";
import { useDispatch, useSelector } from "react-redux";
import { PORTAL_FAWRY, PORTAL_CREDIT_CARD, PORTAL_MADA, PORTAL_FAWRY_E_Wallet } from "../../../routes";
import { useHistory } from "react-router-dom";
import { saveExpirationDateSubscribeCard } from "../../../Actions/PaymentDataAction";
import { addPaymentDataWithCode } from "../../../Actions/MobilePaymentActions";
import { SUBSCRIPTION_MONTHLY, SUBSCRIPTION_WEEKLY, SUBSCRIPTION_YEARLY } from "../../../constants";
import { emptyPromoCode } from "../../../Actions/PromoCodesActions";
import { getUserPaymentInfo } from "../../../Actions/MobilePaymentProfileAction";
import { E_Wallet_Payment_Method, Fawry_Payment_Method, JeelMADA, JeelMasterCard } from "../constants";
import { prepareCheckout } from "../../../Actions/HyperPayAction";

function UpgradeCard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const paymentName = useSelector((state) => state.paymentData.paymentMethod);
  // const price = useSelector((state) => state.paymentData.price);
  const price = useSelector((state) => state.paymentData.discount);
  const expirationDate = useSelector(
    (state) => state.paymentData.expirationDate
  );
  const discription = useSelector((state) => state.paymentData.discription);
  const slug = useSelector((state) => state.paymentData.slug);
  const errorLoadingCode = useSelector(
    (state) => state.promoCodes.error_loading_code
  );
  const [discount, setDiscount] = useState(0);
  const [newDate, setNewDate] = useState("");
  var today = new Date();
  const [date] = expirationDate.split(" ");
  const data = useSelector(
    (state) => state.paymentData.package_and_methods
  );
  const promocodeItem = useSelector((state) => state.promoCodes.item);
  const userPaymentProfile = useSelector(
    (state) => state.userPaymentProfile.item
  );
  const idSuccess = useSelector(state => state.hyperPay.idSuccess);
  const paymentPackage = data.paymentSpecialCountriesOffer;
  // const addCodeToredux = useSelector(state => state.promoCodes.)

  const calcExpirationDate = (today, type, expirationDate) => {
    let calcExpirationDate = expirationDateFormat(today, type, expirationDate);
    return calcExpirationDate;
  };
  const addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  };
  const addWeek = (date) => {
    date.setDate(date.getDate() + 7);
    return date;
  }
  const expirationDateFormat = (today, type, expirationDate) => {
    //console.log("expirationDate = " + expirationDate)
    var expiDate;
    var dd;
    var mm;
    var yyyy;

    if (
      expirationDate == null ||
      expirationDate == undefined ||
      expirationDate == "null"
    ) {
      expiDate = new Date();
      //console.log("today = " + expiDate)
    } else {
      let arrayOfDate = expirationDate.split("-");

      dd = arrayOfDate[0];

      mm = arrayOfDate[1];

      yyyy = arrayOfDate[2];
      console.log(dd);
      console.log(mm);
      console.log(yyyy);

      var formatedEpxDate = mm + "/" + dd + "/" + yyyy;
      var oldDate = new Date(formatedEpxDate);
      console.log("old " + oldDate);

      if (today > oldDate) {
        expiDate = new Date();
      } else {
        // Subtract 1 from month to convert it to system ( January is 0!)
        // console.log("expirationDate = " + expirationDate)
        expiDate = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0);
        // console.log("new expiDate = " + expiDate)
      }
    }

    if (type == "Monthly") {
      expiDate = addMonths(expiDate, 1);
      // console.log("monthExpiDate = " + expiDate)
    } else if (type == "Yearly") {
      expiDate = addMonths(expiDate, 12);
      // console.log("yearExpiDate = " + expiDate)
    } else if (type == "Weekly"){
      expiDate = addWeek(expiDate);
    }
    
    // add 1 to month to convert it to general ( January is 1!)
    var tempMonth = expiDate.getMonth();
    var tempMonth = tempMonth + 1;

    var date =
      expiDate.getDate() + "-" + tempMonth + "-" + expiDate.getFullYear();
    return date;
  };

  const handleClick = () => {
    if (paymentName === JeelMADA){
      const amount =  discount === 0 ? price : (price - discount).toFixed(2);
      dispatch(prepareCheckout(amount, JeelMADA));
    }
    let endDate;
    if (
      userPaymentProfile !== undefined &&
      userPaymentProfile.currentPackagePaymentId !== undefined &&
      userPaymentProfile.currentPackagePaymentId !== null
    ) {
      endDate = expirationDateFormat(
        today,
        slug,
        userPaymentProfile.currentPackagePaymentId.expirationDatetime
      );
    } else {
      endDate = expirationDateFormat(today, slug);
    }
    dispatch(saveExpirationDateSubscribeCard(endDate));
    dispatch(
      addPaymentDataWithCode(
        price,
        slug,
        endDate,
        true,
        promocodeItem,
        paymentPackage
      )
    );
    dispatch(emptyPromoCode());
    console.log(paymentName,"paymentMethod");
    if (paymentName === JeelMasterCard) {
      history.push(PORTAL_CREDIT_CARD);
    } else if (paymentName === Fawry_Payment_Method || paymentName === E_Wallet_Payment_Method) {
      history.push(PORTAL_FAWRY);
    }
  };

  const getPackageType = (type) => {
    switch (type) {
      case SUBSCRIPTION_MONTHLY:
        return "الباقة الشهرية ";
      case SUBSCRIPTION_YEARLY:
        return "الباقة السنوية  ";
      case SUBSCRIPTION_WEEKLY:
        return " الباقة الأسبوعية";
      case "Monthly":
        return " الباقة الشهرية  ";
      case "Yearly":
        return "الباقة السنوية  ";
      case "Weekly":
        return " الباقة الأسبوعية";
      default:
        return "";
    }
  };

  const convertDiscountPercentageToAmount = () => {};

  useEffect(() => {
    if (promocodeItem) {
      setDiscount((promocodeItem.discountAmount / 100) * price);
    }

  }, [promocodeItem]);

  useEffect(() => {
    dispatch(emptyPromoCode());
    dispatch(getUserPaymentInfo());

  }, []);

  useEffect(() => {
    if (idSuccess) {
      history.push(PORTAL_MADA);
    }
  }, [idSuccess]);


  return (
    <>
      <div className="UpgradeCard text-start">
        {userPaymentProfile !== undefined &&
          userPaymentProfile.currentPackagePaymentId !== undefined &&
          userPaymentProfile.currentPackagePaymentId !== null ? (
          <div>
            <div className="top-bar text-center mb-3 p-3">
              انت الان مشترك فى{" "}
              <span className="orange-color">
                {getPackageType(
                  userPaymentProfile.currentPackagePaymentId.paymentPackageId
                    .packageType
                )}
              </span>{" "}
              و التي سوف تنتهى في{" "}
              <span className="orange-color">
                {userPaymentProfile.currentPackagePaymentId.expirationDatetime}
              </span>
            </div>
            <div className="upgrade-to-title text-center mb-3">
              سوف تقوم بترقية الباقة إلى
            </div>
            <div className="upgrade-to mb-3 p-3">
              <div className="d-flex align-items-center justify-content-between gap-10">
                <div className="name">اسم الباقة</div>
                <div className="price">{getPackageType(slug)}</div>
              </div>
              <div className="end-date d-flex align-items-center  gap-5">
                تنتهي في{" "}
                <span className="date">
                  {expirationDateFormat(
                    today,
                    slug,
                    userPaymentProfile.currentPackagePaymentId
                      .expirationDatetime
                  )}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="top-bar text-center mb-3 p-3">
              سوف تقوم الاشتراك في{" "}
              <span className="orange-color">{getPackageType(slug)}</span>
            </div>
            <div className="upgrade-to mb-3 p-3">
              <div className="d-flex align-items-center justify-content-between gap-10">
                <div className="name">اسم الباقة</div>
                <div className="price">{getPackageType(slug)}</div>
              </div>
              <div className="end-date d-flex align-items-center  gap-5">
                تنتهي في{" "}
                <span className="date">
                  {expirationDateFormat(today, slug)}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="discount-container d-md-flex align-items-center justify-content-between gap-10">
          <Promocode callMethod={convertDiscountPercentageToAmount} />
          <div className="discount d-flex align-items-center gap-5">
            {errorLoadingCode ? (
              <div>
                {" "}
                <span className="orange-color">{errorLoadingCode}</span>{" "}
              </div>
            ) : (
              " "
            )}

            {promocodeItem.discountAmount ? (
              <div className="discount d-flex align-items-center gap-5">
                {promocodeItem.discountAmount}
                خصم <span>%</span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="total-container mb-3">
          <div className="item py-2 d-flex align-items-center justify-content-between gap-10">
            <div className="label">الحساب</div>
            <div className="amount">{price.toFixed(2)}</div>
          </div>
          <div className="item py-2 d-flex align-items-center justify-content-between gap-10">
            <div className="label">الخصم</div>
            <div className="amount">{discount.toFixed(2)}</div>
          </div>
          <div className="item py-2 d-flex align-items-center justify-content-between gap-10">
            <div className="label">المجموع</div>
            <div className="amount">
              {(price - discount).toFixed(2)}
            </div>
          </div>
        </div>
        <button
          className="btn continue portal-main-btn w-100"
          onClick={handleClick}
        >
          استمر
        </button>
      </div>
    </>
  );
  // }
}

export default UpgradeCard;
