import React, { Component } from "react";
import { Dropdown, Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/*Redux  */
import "firebase/firestore";
import "firebase/auth";
import { withFirebase } from "../../config";
import { connect } from "react-redux";
import porpTypes from "prop-types";
/*Components */
import UploadAudio from "../S3Uploading/UploadAudio";

import UploadImage from "../S3Uploading/UploadImage";
import DeleteQuestion from "../Questions/DeleteQuestion";
import QuestionDetails from "../Questions/QuestionDetails";
import UploadImageWithoutDropZone from "../S3Uploading/UploadImageWithoutDropZone";
import DeleteChoice from "../Choices/DeleteChoice";
/*Actions */
import { fetchQuestionsCategories } from "../../Actions/QuestionCategoryActions";
import { getActivityData, emptyActivity } from "../../Actions/ActivityActions";
import {
  saveQuestionwithCorrectAnswer,
  updateQuestionnwithCorrectAnswer
} from "../../Promises/Question.js";
/*Css */
import "bootstrap/dist/css/bootstrap.min.css";
import "../../css/Activity.css";

const questions_type = [
  {
    key: "text",
    text: "text",
    value: "text"
  },
  {
    key: "image",
    text: "image",
    value: "image"
  }
];

let returnedCorrectAnswerId = true;
let memoryCategoryId;

class EditActivityComponent extends Component {
  constructor(props) {
    console.log("constructor")

    super(props);

    // getting QuestionCategory
    this.props.fetchQuestionsCategories();

    // getting the activity id from EditActivityEpisode
    const { activityId } = this.props;
    // console.log("activityId in EditActivityComponent = " + activityId);

    // get activitydata
    this.props.getActivityData(activityId);
  }

  state = {
    questionCategoryBoolean: "",
    answersWithIds: [{ text: null, voice: null }],
    answers: [{ text: null, voice: null }],
    questionType: "",
    questionCategoryID: "",
    questionText: "",
    questions: [],
    correctAnswerId: -1,
    updaingQuestion: false,
    questionId: 0,
    questionAudio: "",

    // validation
    error: false,
    errorMessage: ''
  };

  componentDidMount() {
    console.log("componentDidMount")

    // getting QuestionCategory
    this.props.fetchQuestionsCategories();

    // getting the activity id from EditActivityEpisode
    const { activityId } = this.props;
    // console.log("activityId in EditActivityComponent = " + activityId);

    // get activitydata
    this.props.getActivityData(activityId);

    const { questionCategories } = this.props;
    console.log("componentDidMount")
    console.log(questionCategories)
    for (let i = 0; i < questionCategories.length; i++) {
      if (questionCategories[i].categoryText == "null") {
        memoryCategoryId = questionCategories[i].categoryID;
        this.setState({ questionCategoryID: questionCategories[i].categoryID })
      }
    }

  }

  componentWillReceiveProps() {
    console.log("will recive props");
    // console.log(this.props.activityId, this.props)

    // if the props has activity and it equals to activityID get from EpisodeID && questions !== undefined
    if (
      this.props.activitydata.id &&
      this.props.activityId == this.props.activitydata.id
    ) {
      let activitydata = this.props.activitydata;
      // console.log(activitydata.questions);
      if (this.props.activitydata.questions !== undefined) {
        // console.log("will recive props 3 ");

        this.setState({ questions: activitydata.questions });
      }
      // console.log(activitydata, this.props.activitydata)
    }
    // if the props has the old activity go to componenentDidMout
    // to get the get the new activity
    else if (
      this.props.activitydata.id &&
      this.props.activityId != this.props.activitydata.id
    )
      this.componentDidMount();
  }

  checkDeletedQuestion(isDeletd) {
    console.log("IsDeleted");
    console.log(isDeletd);

    if (isDeletd) {
      this.componentDidMount();
      this.setState({ questionCategoryID: memoryCategoryId })

      this.setState({
        answers: [],
        questionText: "",
        // questionCategoryID: "",
        questionType: "",
        updaingQuestion: false,
        questionAudio: ""
      });
    }
  }

  // question audio
  getAudio(questionAudioUrl) {
    console.log("getAudio")
    if (questionAudioUrl) {

      if (questionAudioUrl !== "delete") {
        this.setState({ questionAudio: questionAudioUrl });
      }
      else {
        this.setState({ questionAudio: "" });
      }
    }
  }

  getQuestionData(question) {
    console.log("getQuestionData");
    console.log(question);

    let bool = false; //image
    if (question.questionType === "text") {
      bool = true;
      this.setState({ previewQuestionImages: false });
    } else {
      // for images to preview dropzone or preview images
      this.setState({ previewQuestionImages: true });
    }

    let ans = [];
    for (let i = 0; i < question.choices.length; i++) {
      ans.push({
        text: question.choices[i].text,
        voice: question.choices[i].voice
      });
    }

    console.log("answer");
    console.log(ans);

    this.setState({
      questionCategoryBoolean: bool,
      answers: ans,
      answersWithIds: question.choices,
      questionType: question.questionType,
      questionCategoryID: question.questionCategory.categoryID,
      questionText: question.text,
      correctAnswerId: question.correctAnsId.id,
      updaingQuestion: true,
      questionId: question.id,
      questionAudio: question.voice,
      // this variable to check if the correctanswer id changed or not
      //as if it didn't change the update function gets error as the sent paramter
      // is the actual id not the index of this question
      correctAnsIdTemp: question.correctAnsId.id
    });
  }

  handleQuestionType = (e, { value }) => {
    console.log("handleQuestionType")
    let bool = false; //image
    if (value === "text") {
      bool = true;
    }
    this.setState({
      questionCategoryBoolean: bool,
      questionType: value,
      answers: []
    });
    setTimeout(() => {
      if (this.state.questionText == null || this.state.questionText == ' ' || this.state.questionText == "" ||
        this.state.questionType == null || this.state.questionType == "" ||
        this.state.questionCategoryID == "" || this.state.questionCategoryID == null) {
        this.setState({ error: true, errorMessage: 'Empty data is not accepted' });
      }
      else {
        this.setState({ error: false, errorMessage: '' });
      }
    }, 0);
  };

  deleteAnswerFromRedux(i, event) {
    console.log("deleteAnswerFromRedux");
    console.log(i);

    let answers = [...this.state.answers];
    const filteredItems = answers.filter(function (item) {
      return item.text !== answers[i].text;
    });

    console.log(filteredItems);
    this.setState({ answers: filteredItems });
  }

  handleCorrectAnswersChange(i, event) {
    console.log("handleCorrectAnswersChange")
    let correctAnswerId = event.target.value;
    this.setState({ correctAnswerId });
  }

  createInputField() {
    console.log("createInputField")

    let showRadioButton = null;
    let $textcontent = null;

    if (
      this.state.previewQuestionImages === false &&
      this.state.updaingQuestion == true
    ) {
      $textcontent = this.state.answers.map((el, i) => {
        // console.log(this.state.answersWithIds[i].text)
        // console.log(this.state.answersWithIds[i].id)
        // console.log(this.state.answersWithIds[i].id == this.state.correctAnswerId)
        if (
          this.state.answersWithIds[i].id !== undefined &&
          this.state.answersWithIds[i].id == this.state.correctAnswerId
        ) {
          showRadioButton = (
            <input
              type="radio"
              id={i}
              name="radio"
              value={i}
              checked={returnedCorrectAnswerId}
              onChange={this.handleCorrectAnswersChange.bind(this, i)}
            ></input>
          );
        } else {
          showRadioButton = (
            <input
              type="radio"
              id={i}
              name="radio"
              value={i}
              onChange={this.handleCorrectAnswersChange.bind(this, i)}
            ></input>
          );
        }

        // getting the id of the question text ,
        // if the text in answer_withIds_array == the text in answer_text_array_then retutn it's id
        if (this.state.answersWithIds[i].text == el.text) {
          // console.log("object in map")
          // console.log(el.voice)
          return (
            <div className="row">
              <div className="col-1">
                <UploadAudio
                  getAudioUrlCallBack={this.getChoiceAudio.bind(this, i)}
                  audioPreview={el.voice}
                  audioUrl={el.voice}
                  s3DirectryrName="activity/audio"
                />
              </div>

              <div className="col-9">
                <input
                  type="text"
                  dir="auto"
                  className="form-control"
                  onChange={this.handleAnswersChange.bind(this, i)}
                  value={el.text}
                />
              </div>
              <div className="answers-box">
                <span className="ansr-control">
                  {showRadioButton}
                  <span> | </span>
                  <DeleteChoice
                    questionId={this.state.questionId}
                    choiceId={this.state.answersWithIds[i].id}
                    questionDataCallBack={this.getQuestionData.bind(this)}
                  />
                </span>
              </div>
            </div>
          );
        }
      });
    } else {
      $textcontent = this.state.answers.map((el, i) => (
        <div className="row">
          <div className="col-1">
            <UploadAudio
              getAudioUrlCallBack={this.getChoiceAudio.bind(this, i)}
              audioPreview={el.voice}
              audioUrl={el.voice}
              s3DirectryrName="activity/audio"
            />
          </div>

          <div className="col-9">
            <input
              type="text"
              dir="auto"
              className="form-control"
              onChange={this.handleAnswersChange.bind(this, i)}
              value={el.text}
            />
          </div>

          <div className="answers-box">
            <span className="ansr-control">
              <input
                type="radio"
                id={i}
                name="radio"
                value={i}
                onChange={this.handleCorrectAnswersChange.bind(this, i)}
              ></input>
              <span> | </span>
              <Popup trigger=
                {
                  <FontAwesomeIcon
                    className="far fa-trash-alt"
                    icon="trash-alt"
                    onClick={this.deleteAnswerFromRedux.bind(this, i)}
                  ></FontAwesomeIcon>
                }
                content='Delete'
                position='top center'
              />
            </span>
          </div>
        </div>
      ));
    }
    return $textcontent;
  }

  createDropZoneFields() {

    console.log("createDropZoneFields")
    console.log("answers")
    console.log(this.state.answers)

    console.log("this.state.answersWithIds")
    console.log(this.state.answersWithIds)

    let $imagesContent = null;
    let showRadioButton = null;
    if (
      this.state.previewQuestionImages === true &&
      this.state.updaingQuestion == true
    ) {
      $imagesContent = this.state.answers.map((el, i) => {
        // console.log(this.state.answersWithIds[i].text)
        // console.log(this.state.answersWithIds[i].id)
        // console.log(this.state.answersWithIds[i].id == this.state.correctAnswerId)
        if (
          this.state.answersWithIds[i].id !== undefined &&
          this.state.answersWithIds[i].id == this.state.correctAnswerId
        ) {
          showRadioButton = (
            <input
              type="radio"
              id={i}
              name="radio"
              value={i}
              checked={returnedCorrectAnswerId}
              onChange={this.handleCorrectAnswersChange.bind(this, i)}
            ></input>
          );
        } else {
          showRadioButton = (
            <input
              type="radio"
              id={i}
              name="radio"
              value={i}
              onChange={this.handleCorrectAnswersChange.bind(this, i)}
            ></input>
          );
        }

        if (this.state.answersWithIds[i].text == el.text) {
          return (
            <div className="row">
              <div className="col-1">
                <UploadAudio
                  getAudioUrlCallBack={this.getChoiceAudio.bind(this, i)}
                  audioPreview={el.voice}
                  audioUrl={el.voice}
                  s3DirectryrName="activity/audio"
                />
              </div>

              <div className="col-9">
                <UploadImageWithoutDropZone
                  getImageURLCallBack={this.handleImagesChange.bind(this, i)}
                  imageUrl={el.text}
                  imagepreview={el.text}
                  s3DirectryrName="activity"
                />
              </div>

              <div className="answers-box">
                <span className="ansr-control">
                  {showRadioButton}
                  <span> | </span>
                  <DeleteChoice
                    questionId={this.state.questionId}
                    choiceId={this.state.answersWithIds[i].id}
                    questionDataCallBack={this.getQuestionData.bind(this)}
                  />
                </span>
              </div>
            </div>
          );
        }
      });
    } else {
      $imagesContent = this.state.answers.map((el, i) => (
        <div className="row">
          <div className="col-1">
            <UploadAudio
              getAudioUrlCallBack={this.getChoiceAudio.bind(this, i)}
              audioPreview={el.voice}
              audioUrl={el.voice}
              s3DirectryrName="activity/audio"
            />
          </div>

          <div className="col-9">
            <UploadImage
              getImageURLCallBack={this.handleImagesChange.bind(this, i)}
              imageUrl={el.text}
              imagepreview={el.text}
              s3DirectryrName="activity"
            />
          </div>

          <div className="answers-box">
            <span className="ansr-control">
              <input
                type="radio"
                id={i}
                name="radio"
                value={i}
                onChange={this.handleCorrectAnswersChange.bind(this, i)}
              ></input>
            </span>
          </div>
        </div>
      ));
    }
    return $imagesContent;
  }


  handleImagesChange(i, imageUrl) {
    console.log("handleImagesChange")
    let answers = [...this.state.answers];

    let answersWithIds = [...this.state.answersWithIds]; // from updates
    console.log(answersWithIds);


    if (imageUrl === 'delete') {
      answers[i] = {
        text: null,
        voice: null
      };
      this.setState({ answers });
    }
    else if (imageUrl !== undefined && imageUrl !== null) {
      if (answers[i].voice === "" || answers[i].voice === undefined) {
        answers[i] = {
          text: imageUrl,
          voice: null
        };

        if (answersWithIds[i] !== undefined) {
          answersWithIds[i] = {
            id: answersWithIds[i].id,
            text: imageUrl,
            voice: null
          };
        }

      } else {
        answers[i] = {
          text: imageUrl,
          voice: answers[i].voice
        };


        if (answersWithIds[i] !== undefined) {
          answersWithIds[i] = {
            id: answersWithIds[i].id,
            text: imageUrl,
            voice: answersWithIds[i].voice
          };
        }
      }
      this.setState({ answers, answersWithIds });
    }
  }


  handleAnswersChange(i, event) {
    console.log("handleAnswersChange");
    let answers = [...this.state.answers]; // the saved ones

    let answersWithIds = [...this.state.answersWithIds]; // from updates
    console.log(answersWithIds);

    if (answers[i].voice == "" || answers[i].voice == undefined) {
      console.log("if null");

      answers[i] = {
        text: event.target.value,
        voice: null
      };

      if (answersWithIds[i] !== undefined) {
        answersWithIds[i] = {
          id: answersWithIds[i].id,
          text: event.target.value,
          voice: null
        };
      }
    } else {
      console.log("else");
      answers[i] = {
        text: event.target.value,
        voice: answers[i].voice
      };

      if (answersWithIds[i] !== undefined) {
        answersWithIds[i] = {
          id: answersWithIds[i].id,
          text: event.target.value,
          voice: answersWithIds[i].voice
        };
      }
    }

    console.log("anwsers");
    console.log(answers);
    if ((answers[i].text == null || answers[i].text == "" || answers[i].text == undefined) || (answers[i].text == null || answers[i].text == "" || answers[i].text == undefined)) {
      console.log("LOOL");
      this.setState({ error: true, errorMessage: "Empty answers are not accepted" });
    }
    else {
      this.setState({ error: false, errorMessage: "" });
    }
    this.setState({ answers, answersWithIds });
  }

  getChoiceAudio(i, audioUrl, event) {
    console.log("i in getChoiceAudio = " + i);
    let choiceAudio = null;
    if (audioUrl) {
      console.log("Audio Url in getChoiceAudio");
      // check if url !==  delete send url to DB
      if (audioUrl !== "delete") {
        choiceAudio = audioUrl;
        console.log("choice audio URL in addActivity else = " + choiceAudio);
      }
    }

    let answers = [...this.state.answers];
    let answersWithIds = [...this.state.answersWithIds];

    if (answersWithIds[i] !== undefined) {
      answersWithIds[i] = {
        id: answersWithIds[i].id,
        text: answersWithIds[i].text,
        voice: choiceAudio
      };
    }

    answers[i] = {
      text: answers[i].text,
      voice: choiceAudio
    };

    console.log(answersWithIds);

    this.setState({ answers, answersWithIds });
  }

  addClick = e => {
    console.log("addClick")
    this.setState(prevState => ({ answers: [...prevState.answers, ""] }));
    e.preventDefault();
  };

  handleQuestionCategory = (e, { value }) => {
    console.log("handleQuestionCategory")

    this.setState({
      questionCategoryID: value
    });
    setTimeout(() => {
      if (this.state.questionText == null || this.state.questionText == ' ' || this.state.questionText == "" ||
        this.state.questionType == null || this.state.questionType == "" ||
        this.state.questionCategoryID == "" || this.state.questionCategoryID == null) {
        this.setState({ error: true, errorMessage: 'Empty data is not accepted' });
      }
      else {
        this.setState({ error: false, errorMessage: '' });
      }
    }, 0);
  };

  handleChange = e => {
    console.log("handleChange")
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces });
    console.log(this.state);
    setTimeout(() => {
      if (this.state.questionText == null || this.state.questionText == ' ' || this.state.questionText == "" ||
        this.state.questionType == null || this.state.questionType == "" ||
        this.state.questionCategoryID == "" || this.state.questionCategoryID == null) {
        this.setState({ error: true, errorMessage: 'Empty data is not accepted' });
      }
      else {
        this.setState({ error: false, errorMessage: '' });
      }
    }, 0);
  };

  cancelSubmit = e => {
    console.log("cancelSubmit")

    this.setState({ questionCategoryID: memoryCategoryId })

    this.setState({
      answers: [],
      questionText: "",
      //questionCategoryID: "",
      questionType: "",
      updaingQuestion: false,
      questionAudio: ""
    });

  };

  updateQuestion = event => {
    console.log("updateQuestion")
    const { activityId } = this.props;
    let answers = this.state.answers;
    let questionType = this.state.questionType;
    let questionCategoryID = this.state.questionCategoryID;
    let questionText = this.state.questionText;
    let answersWithIds = this.state.answersWithIds;
    let correctAnswerId = this.state.correctAnswerId;
    let questionId = this.state.questionId;
    let questionAudio = this.state.questionAudio;

    for (let i = 0; i < answersWithIds.length; i++) {
      answersWithIds[i].text = answers[i].text;
      answersWithIds[i].voice = answers[i].voice;
    }
    // gettign the index of correct answer in the choices array
    //by checking if the correctanswer id changed or not
    //as if it didn't change the update function gets error as the sent paramter
    // is the actual id not the index of this question
    if (this.state.correctAnsIdTemp === correctAnswerId) {
      for (let i = 0; i < answersWithIds.length; i++) {
        if (answersWithIds[i].id === correctAnswerId) {
          correctAnswerId = i;
        }
      }
    }

    let theUpdatedQuestion = {
      id: questionId,
      text: questionText,
      questionType: questionType,
      choices: answersWithIds,
      questionCategoryId: questionCategoryID,

      correctAnswerId: correctAnswerId,
      questionAudio: questionAudio
    };

    updateQuestionnwithCorrectAnswer(theUpdatedQuestion, activityId)
      .then(res => {
        alert("Question updated successfully");

        // changing the text of the question
        //in the array of questions
        let questions = [...this.state.questions];
        let updatedQuestionIndex = null;

        for (let i = 0; i < questions.length; i++) {
          if (questions[i].id == questionId) {
            updatedQuestionIndex = i;
          }
        }

        questions[updatedQuestionIndex] = theUpdatedQuestion;
        this.setState({ questions: questions });
        this.setState({ questionCategoryID: memoryCategoryId })

        this.setState({
          answers: [],
          questionText: "",
          questionAudio: "",
          // questionCategoryID: "",
          questionType: "",
          updaingQuestion: false
        });
      })
      .catch(Error => {
        alert("Error missing data or network " + Error);
      });

    event.preventDefault();
  };

  handleSubmit = event => {
    console.log("handleSubmit")
    const { activityId } = this.props;

    let questionType = this.state.questionType;
    let questionCategoryID = this.state.questionCategoryID;
    let questionText = this.state.questionText;
    let questionAudio = this.state.questionAudio;

    let ans = [];
    for (let i = 0; i < this.state.answers.length; i++) {
      if (
        this.state.answers[i].text === "" ||
        this.state.answers[i].text == undefined
      ) {
        alert("Empty Answer, Please Add it");
        continue;
      } else {
        ans.push({
          text: this.state.answers[i].text,
          id: i,
          voice: this.state.answers[i].voice
        });
      }
    }
    let correctAnswerId = this.state.correctAnswerId;
    if (correctAnswerId == -1) {
      alert("Empty Correct Answer, Please Add one");
    } else {
      if (
        ans[correctAnswerId] == undefined ||
        ans[correctAnswerId].text == "" ||
        ans[correctAnswerId].text == undefined
      ) {
        alert("Empty Answer, Please Add one");
      } else {
        let question = {
          questionType: questionType,
          questionCategoryId: questionCategoryID,
          text: questionText,
          choices: ans,
          correctAnswerId: correctAnswerId,
          questionAudio: questionAudio
        };

        console.log(activityId);
        console.log(question);

        saveQuestionwithCorrectAnswer(question, activityId)
          .then(res => {
            alert("Question added successfully");

            //Adding the question with id to all Questions
            let questions = [...this.state.questions, res.data];

            this.setState({ questions: questions });
            this.setState({ questionCategoryID: memoryCategoryId })

            this.setState({
              answers: [],
              questionText: "",
              questionAudio: "",
              //  questionCategoryID: "",
              questionType: "",
              correctAnsId: -1
            });
          })
          .catch(Error => {
            alert("Error missing data or network " + Error);
          });
      }
    }
    event.preventDefault();
  };

  render() {
    // activity id to send it to delete Question , and edit
    const { activityId } = this.props;

    let buttonsState = null,
      addingNewAnswerButton = null;
    if (this.state.updaingQuestion == true) {
      buttonsState = (

        <div>
          <span>
            <button
              className="btn btn-lg btn-dark float-left small-font pl-4 pr-4"
              type="submit" id="cancelSubmit"
              onClick={this.cancelSubmit.bind(this)}
            >
              cancel
            </button>
            <button
              className="btn btn-lg btn-primary add-ansrs-btn small-font pl-4 pr-4"
              disabled={this.state.error}
              id="updateQuestion"
              onClick={this.updateQuestion.bind(this)}
            >
              update
            </button>
          </span>
          <p style={{ color: "red" }}> {this.state.errorMessage} </p>
        </div>

      );
    } else if (this.state.updaingQuestion == false) {
      buttonsState = (
        <div>
          <p style={{ float: "left", color: "red" }}> {this.state.errorMessage} </p>
          <button
            className="btn btn-lg btn-primary add-ansrs-btn small-font pl-4 pr-4"
            type="submit"
            id="Submit"
            onClick={this.handleSubmit.bind(this)}
            disabled={this.state.error}
          >
            add
          </button>
        </div>
      );

      addingNewAnswerButton = (
        <button
          className="btn btn-lg btn-primary btn-add btn-block mb-4"
          id="addClick"
          onClick={this.addClick.bind(this)}
        >
          add New Answer
        </button>
      );
    }
    const questions = this.state.questions;

    const { questionCategories } = this.props;
    const questionCategoriesList = [];
    for (let i = 0; i < questionCategories.length; i++) {
      questionCategoriesList.push({
        key: questionCategories[i].categoryID,
        text: questionCategories[i].categoryText,
        value: questionCategories[i].categoryID
      });
    }

    const questionsAdded = questions.length ? (
      questions.map(question => {
        return (
          <div className="answers-box">
            <span className="ansr-control">
              <DeleteQuestion
                activityId={activityId}
                questionId={question.id}
                questionIsDeleted={this.checkDeletedQuestion.bind(this)}
              />

              <span> | </span>

              <QuestionDetails
                activityId={activityId}
                questionId={question.id}
                questionDataCallBack={this.getQuestionData.bind(this)}
              />
            </span>
            <span className="text-ansr">{question.text}</span>
          </div>
        );
      })
    ) : (
        <p className="small-font">No questions added</p>
      );

    return (
      <div className="container">
        <div className="container">
          <div className="row">
            <h3 className="col-3">Add Question</h3>
            <hr className="col-8" />
          </div>
          <div className="row questionOuterDiv">
            <div className="col-6 question">
              <br />
              <form onSubmit={(e) => e.preventDefault()}>
                <label>Question Type</label>
                <Dropdown
                  placeholder="Select Type"
                  fluid
                  selection
                  options={questions_type}
                  onChange={this.handleQuestionType}
                  value={this.state.questionType}
                />
                <br />
                <label>Question Category</label>
                <Dropdown
                  placeholder="Select Category"
                  fluid
                  selection
                  options={questionCategoriesList}
                  onChange={this.handleQuestionCategory}
                  value={this.state.questionCategoryID}
                />
                <hr />
                <label htmlFor="Question-text">Question</label>
                <div className="row">
                  <div className="col-1">
                    <UploadAudio
                      getAudioUrlCallBack={this.getAudio.bind(this)}
                      audioPreview={this.state.questionAudio}
                      audioUrl={this.state.questionAudio}
                      s3DirectryrName="activity/audio"
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      dir="auto"
                      className="form-control"
                      onChange={this.handleChange.bind(this)}
                      name="questionText"
                      value={this.state.questionText}
                    ></input>
                  </div>
                </div>
                <br />
                <label>Add Answer</label>
                {this.state.questionCategoryBoolean
                  ? this.createInputField()
                  : this.createDropZoneFields()}
                <br />
                {addingNewAnswerButton}
                {/* <button className="btn btn-lg btn-primary btn-add btn-block mb-4"
                                    onClick={this.addClick}>add New Answer</button> */}
                <br />
                <div className="text-right">{buttonsState}</div>
                <br />
                <br /> <br />
              </form>
            </div>
            <div className="col-5 addedQuestions">
              <label className="mb-2 font-weight-bold small-font">
                Added questions
              </label>

              {questionsAdded}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
EditActivityComponent.porpTypes = {
  fetchQuestionsCategories: porpTypes.func.isRequired,
  questionCategories: porpTypes.func.isRequired,
  getActivityData: porpTypes.func.isRequired
};
const mapStateToProps = state => ({
  questionCategories: state.questionCategories.items,
  activity: state.activities.item,
  activitydata: state.activities.itemforUpdate
});

export default withFirebase(
  connect(
    mapStateToProps,
    {
      fetchQuestionsCategories,
      getActivityData,
      emptyActivity
    }
  )(EditActivityComponent)
);
