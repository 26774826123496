import React, { Component } from "react";
import { getEducationalVideoData,emptyEducationalVideo , emptyEducationalVideoItems } from "../../Actions/ParentLibraryAction";
import { connect } from "react-redux";
import OuterHeader from "../Layout/OuterHeader";
import InnerHeader from "../Layout/InnerHeader";
import Dashboard from "../Dashboard";
import Footer from "../Layout/Footer";
import { Redirect } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import UploadImageWithoutDropZone from "../S3Uploading/UploadImageWithoutDropZone";


/* Routes */
import { SIGN_IN, ALL_EDUCATIONAL_VIDEOS } from '../../routes';
import UploadImage from "../S3Uploading/UploadImage";

class ViewParentLibrary extends Component {
  state = {
    image: "",
    title: "",
    titleInEnglish :"",
    description: "",
    descriptionInEnglish:"",
    link: "",
    cancel: false,
    parentLibrary_id: 0,
  };


  componentDidMount() {
this.props.emptyEducationalVideoItems();
    if (!this.props.auth.uid) {
      return <Redirect to={SIGN_IN} />;
    } else {
      let id = this.props.match.params.id;
     
      this.props.getEducationalVideoData(id);
      this.setState({ parentLibrary_id: id });
    }
  }

  componentWillReceiveProps(nextprop) {
    let parentLibrary = nextprop.parentLibrary;
 
    this.setState({
      title: parentLibrary.itemName,
      titleInEnglish : parentLibrary.itemNameInEnglish,
      image: parentLibrary.itemImage,
      description: parentLibrary.itemDescription,
      descriptionInEnglish: parentLibrary.itemDescriptionInEnglish,
      link: parentLibrary.itemUrl,
    });
  }

handelCancel = e => {
    this.setState({ cancel: true });
  }

  render() {


    const
      config = {
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        enableDragAndDropFileToEditor: true,
        useImageResizer: false,
        direction: 'rtl',
      }

    if (this.state.cancel === true) {
        return (<Redirect to={ALL_EDUCATIONAL_VIDEOS}></Redirect>)
  
      }
    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col-9">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <h5 className="main-title mt-2 font-weight-bold">
                        <span> View Parent Educational Videos</span>
                      </h5>
                      <br />
                      <div className="container">
                    
                        
                          <label className="small-label">Image</label>
                          <br />
                          <img className="image_Preview" src={this.state.image} alt=""/>
                          <br />
                          <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                          <label className="small-label">
                           
                            Title In Arabic</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title"
                            name="title"
                            value={this.state.title}
                            disabled
                          />
                          <br />
                          <label className="small-label">
                            
                            Title In English</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title_in_english"
                            name="titleInEnglish"
                            value={this.state.titleInEnglish}
                            disabled
                          />
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              Description
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="description"
                              value={this.state.description}
                              disabled
                            />
                          </div>
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              Description In English
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="descriptionInEnglish"
                              value={this.state.descriptionInEnglish}
                              disabled
                            />
                          </div>
                          <br />

                         
                          <label className="small-label">
                           
                            Youtube Url</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="link"
                            name="link"
                            value={this.state.link}
                            disabled
                          />
                          <br />
                          <Button id="episode_cancel"
                            onClick={this.handelCancel}>
                            Back
                          </Button>
                          <br />
                          <br /> <br />
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  parentLibrary: state.parentLibrary.item,
  parentLibraryerror: state.parentLibrary.error_updating_educationalVideo,
  parentLibrarysucess: state.parentLibrary.educationalVideo_updated_successfully,
  auth: state.firebase.auth
});
export default connect(
  mapStateToProps,
  { getEducationalVideoData, emptyEducationalVideo,emptyEducationalVideoItems}
)(ViewParentLibrary);
