import {
    LOAD_PAYMENT, ADD_PAYMENT, EMPTY_PAYMENT, CLEAR_MESSAGE, RENEW_PAYMENT, CANCEL_RENEW_PAYMENT
} from './Types'

import { post } from 'axios'
import store from '../Store';


var today = new Date();

function publishDateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy;
    return date;
}

function ExpireDateFormate(date) {
    let arrayOfDate = date.split('-');

    var dd = arrayOfDate[0]

    var mm = arrayOfDate[1];

    var yyyy = arrayOfDate[2];

    date = mm + '/' + dd + '/' + yyyy;
    return date;
}

export const clearMessage = ()=>{
    return (dispatch, getState, { getFirebase }) => {
        dispatch(
            {
                type: CLEAR_MESSAGE,
                payload: ''
            }
        )
    };
}

export const addStripePayment = (userToken , tokenId , cash , expiratinDate, packageType , autoRenewel,isWithOffer) => {
    return (dispatch, getState, { getFirebase }) => {
        // const firebase = getFirebase();
        // firebase.auth().currentUser.getIdToken().then((token) => {

            let token = userToken;
            dispatch({
                type: LOAD_PAYMENT,
                payload: 'loading'
            })

            let isChecked = ''
            if(autoRenewel == true){
                isChecked = 1
                console.log(autoRenewel + " = 1 ")
            }else{
                isChecked = 0
                console.log(autoRenewel + " = 0 ")
            }


            let planWithOffer = ''
            if(isWithOffer == true){
                planWithOffer = 1
                console.log(isWithOffer + " = 1 ")
            }else{
                planWithOffer = 0
                console.log(isWithOffer + " = 0 ")
            }



            const url = "/UserPaymentPlan/addUserPaymentPlan";
            const formData = new FormData();
            formData.append('Token', tokenId);
            formData.append('Cash_in_Dollar', cash);
            formData.append('Payment_Date', publishDateFormate(today));
            formData.append('Expiration_Date', ExpireDateFormate(expiratinDate));
            formData.append('Payment_Package_type', packageType);
            formData.append('Auto_Renewel', isChecked);
            formData.append('Is_with_Offer',planWithOffer);


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_PAYMENT,
                        payload: res.data
                    }
                )
            })
                .catch((Error) => {
                    dispatch({
                        type: ADD_PAYMENT,
                        payload: Error.response.data.message
                    })
                })
        // })

    }
}

// export const addStripePayment = (tokenId) => {
//     return (dispatch, getState, { getFirebase }) => {
//         const firebase = getFirebase();
//         firebase.auth().currentUser.getIdToken().then((token) => {
//             dispatch({
//                 type: LOAD_PAYMENT,
//                 payload: 'loading'
//             })

//             const url = "/Stripe/create-charge";
//             const formData = new FormData();
//             formData.append('Email', "test@mobidev.com");
//             formData.append('Token', tokenId);

//             const config = {
//                 headers: {
//                     'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
//                     'X-Authorization-Firebase': token
//                 }
//             }

//             post(url, formData, config).then((res) => {
//                 dispatch(
//                     {
//                         type: ADD_PAYMENT,
//                         payload: res.data
//                     }
//                 )
//             })
//             .catch((Error) => {
//                 dispatch({
//                     type: ADD_PAYMENT,
//                     payload: Error.message
//                 })
//             })
//         })

//     }
// }

export const emptyPayment = () => dispatch => {
    dispatch({
        type: EMPTY_PAYMENT,
        payload: ''
    })
}

export const renewPayment = () => dispatch => {
    dispatch({
        type: RENEW_PAYMENT,
        payload: ''
    })
}

export const cancelRenew = () => dispatch => {
    dispatch({
        type: CANCEL_RENEW_PAYMENT,
        payload: ''
    })
}

export const addStripePaymentWithCode = (userToken , tokenId , cash , expiratinDate, packageType , autoRenewel,isWithOffer, codeUid) => {
    return (dispatch, getState, { getFirebase }) => {
        // const firebase = getFirebase();
        // firebase.auth().currentUser.getIdToken().then((token) => {

            let token = userToken;
            dispatch({
                type: LOAD_PAYMENT,
                payload: 'loading'
            })

            let isChecked = ''
            if(autoRenewel == true){
                isChecked = 1
                console.log(autoRenewel + " = 1 ")
            }else{
                isChecked = 0
                console.log(autoRenewel + " = 0 ")
            }


            let planWithOffer = ''
            if(isWithOffer == true){
                planWithOffer = 1
                console.log(isWithOffer + " = 1 ")
            }else{
                planWithOffer = 0
                console.log(isWithOffer + " = 0 ")
            }

            if(codeUid === undefined || codeUid === null || codeUid === ''){
              codeUid = null;
            }


            const url = "/UserPaymentPlan/addUserPaymentPlanWithCode";
            const formData = new FormData();
            formData.append('Token', tokenId);
            formData.append('Cash_in_Dollar', cash);
            formData.append('Payment_Date', publishDateFormate(today));
            formData.append('Expiration_Date', ExpireDateFormate(expiratinDate));
            formData.append('Payment_Package_type', packageType);
            formData.append('Auto_Renewel', isChecked);
            formData.append('Is_with_Offer',planWithOffer);
            formData.append('Code_UID', codeUid)


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_PAYMENT,
                        payload: res.data
                    }
                )
            })
                .catch((Error) => {
                    dispatch({
                        type: ADD_PAYMENT,
                        payload: Error.response.data.message
                    })
                })
        // })

    }
}
