import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { deleteFAQ } from '../../Actions/FAQActions'
import { emptyError } from '../../Actions/Empty'
import { fetchAllFAQ } from '../../Actions/FAQActions'

class DeleteFAQ extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteFAQ(id) {
        this.props.deleteFAQ(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;
        
        console.log("id = " + id)
        
        this.deleteFAQ(id);
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true) {
            alert("FAQ Deleted Successfully")
            this.props.emptyError();
            this.props.fetchAllFAQ();
        }

        return (
            <div className="acor-btn-group">

                 <button className="acor-btn2"  onClick={this.open}> 
                    <i className="far fa-trash-alt" >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal" 
                content='Are you sure you want to delete this FAQ ?? '
                open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>
            
        )
    }
}


DeleteFAQ.propTypes = {
    deleteFAQ: propTypes.func.isRequired,
    fetchAllFAQ: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    faq: state.faq.item,
    error: state.faq.deleting_FAQ_error,
    success: state.faq.deleting_FAQ__sucessfully
});


export default withFirebase(connect(mapStateToProps, { deleteFAQ, emptyError, fetchAllFAQ })(DeleteFAQ));