/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Input, Button, Icon, Label } from 'semantic-ui-react';
import { Snackbar } from '@material-ui/core';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';
import firebase from 'firebase/app'

/* Components */
import Footer from '../Layout/Footer';
import NavBar from '../Layout/NavBar';
import SideMenu from '../Layout/SideMenu';
import Loader from '../Layout/Loader';

/* Assets */
import codeIcon from '../../../images/portal/promo-code-icon.svg';
import Stripe from '../../../images/portal/current-visa.png';
import Fawry from '../../../images/portal/fawry-logo.png';
import Pre_Paid_Card from '../../../images/portal/prepaid.png';
import MADA from '../../../images/portal/mada.png';
import STC_PAY from '../../../images/portal/stc.png';
import FIT_STC from '../../../images/portal/stc.png';
import JEEL_VODAFONE from '../../../images/tpay/vodafone.png'
import JEEL_WE from '../../../images/tpay/we.jpg'
import JEEL_ORANGE from '../../../images/tpay/orange.png'
import JEEL_ETISALAT from '../../../images/tpay/etisalat.png'
import FIT_MOBILY from '../../../images/fit/mobily.jpg'
import FIT_ZAIN from '../../../images/fit/zain.jpg'

/** Actions */
//import { getUserDataWithCodes } from '../../../Actions/MobilePaymentProfileAction';
import { getUserPaymentInfo, emptyMobileUserProfile, cancelSubscription, cancelMobileSubscription, emptyMobileUserProfileWithItem } from '../../../Actions/MobilePaymentProfileAction';
import { emptyPromoCode, getSavedPromoCode, emptyCodeFromRedux, clearPromoCodeAll } from '../../../Actions/PromoCodesActions';
import { addSuccessMessage, removeSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { clearPaymentData } from '../../../Actions/MobilePaymentActions';
/** Routes */
import { PORTAL_PAYMENT_METHODS, PORTAL_SUBSCRIPTION } from '../../../routes';

import * as CONSTANTS from '../../../constants';

const INITIAL_STATE = {
  paymentAutoRenewal: 1,
  redirect: false,
  errorMsg: '',
  openSnakBar: false,
  vertical: 'bottom',
  horizontal: 'right',
}

const TELECOM_COMPANIES = [
  CONSTANTS.DB_FIT_STC, CONSTANTS.DB_FIT_MOBILY, CONSTANTS.DB_FIT_ZAIN,
  CONSTANTS.DB_TPAY_VODAFONE, CONSTANTS.DB_TPAY_ETISALAT, CONSTANTS.DB_TPAY_ORANGE,
  CONSTANTS.DB_TPAY_WE
];

class CustomerSubscriptionProfile extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.props.clearPaymentData();
    this.props.clearPromoCodeAll();
    this.props.emptyCodeFromRedux();
    //this.props.emptyMobileUserProfileWithItem();
    this.props.history.push(PORTAL_SUBSCRIPTION);

  }

  componentDidMount() {
    if (firebase.auth().currentUser) {
      //this.props.getUserPaymentInfo();
      this.props.getSavedPromoCode();
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    if (prevState.paymentAutoRenewal !== props.userPaymentProfile.paymentAutoRenewal) {
      return {
        paymentAutoRenewal: props.userPaymentProfile.paymentAutoRenewal,
      };
    }
  }

  typeOf(key) {
    var listOfTypes = [];
    listOfTypes[CONSTANTS.DB_APPLE] = CONSTANTS.APPLE;
    listOfTypes[CONSTANTS.DB_STRIPE] = CONSTANTS.STRIPE;
    listOfTypes[CONSTANTS.DB_FAWRY] = CONSTANTS.FAWRY;
    listOfTypes[CONSTANTS.DB_PRE_PAID_CARD] = CONSTANTS.PRE_PAID_CARD;
    listOfTypes[CONSTANTS.DB_MADA] = CONSTANTS.MADA;
    listOfTypes[CONSTANTS.DB_STC_PAY] = CONSTANTS.PORTAL_STC_PAY;
    listOfTypes[CONSTANTS.DB_FIT_MOBILY] = CONSTANTS.PORTAL_FIT_MOBILY;
    listOfTypes[CONSTANTS.DB_FIT_ZAIN] = CONSTANTS.PORTAL_FIT_ZAIN
    listOfTypes[CONSTANTS.DB_FIT_STC] = CONSTANTS.PORTAL_FIT_STC;
    listOfTypes[CONSTANTS.DB_TPAY_WE] = CONSTANTS.PORTAL_TPAY_WE
    listOfTypes[CONSTANTS.DB_TPAY_ORANGE] = CONSTANTS.PORTAL_TPAY_ORANGE;
    listOfTypes[CONSTANTS.DB_TPAY_VODAFONE] = CONSTANTS.PORTAL_TPAY_VODAFONE;
    listOfTypes[CONSTANTS.DB_TPAY_ETISALAT] = CONSTANTS.PORTAL_TPAY_ETISALAT;

    if (key === null) {
      return listOfTypes[CONSTANTS.DB_STRIPE];
    }
    return listOfTypes[key];
  }

  handleRenew = (e) => {
    let value = e.target.checked;
    const [{ userPaymentProfile }] = [this.props];
    let type = userPaymentProfile.currentPackagePaymentId.type;
    if (value === true) {
      this.setState({ errorMsg: "يرجى اتمام عمليه الدفع لتفعيل خدمه التجديد التلقائى", paymentAutoRenewal: false })
    }
    else if (value === false && [CONSTANTS.TPAY_VODAFONE, CONSTANTS.TPAY_ORANGE, CONSTANTS.TPAY_ETISALAT, CONSTANTS.TPAY_WE].includes(type)) {


      if (confirm("هل أنت متأكد من إلغاء التأكيد التلقائى لإشتراكك") === true) {
        this.setState({ paymentAutoRenewal: false });
        this.props.cancelMobileSubscription(userPaymentProfile.tpaySubscriptionContractId);
      }

    }
    else if (value === false) {
      this.setState({ paymentAutoRenewal: false });
      this.props.cancelSubscription();
    } else {
      this.setState({ paymentAutoRenewal: false });
    }
  }

  handleSubmit = () => {
    this.setState({ redirect: true });
  }

  handleClose = (e) => {
    this.setState({ openSnakBar: false })
  }

  handleSuccessMessages = () => {
    this.props.removeSuccessMessage();
  }

  render() {
    const { success, error, loading } = this.props;
    const { cancelSubscribtionSuccess, cancelSubscribtionError } = this.props;
    const { promocodesuccess, promocode, promocodeload } = this.props;
    const { vertical, horizontal } = this.state;
    const { successMessage } = this.props;
    const [{ userPaymentProfile }, { paymentAutoRenewal }] = [this.props, this.state];
    let currency = (userPaymentProfile.currentPackagePaymentId !== undefined && userPaymentProfile.currentPackagePaymentId !== null && userPaymentProfile.currentPackagePaymentId.type === 'Fawry') ? 'EGP' : '$';
    let packageType = '';
    let paymentIcon = '';
    let savedCodes = '';
    let currentPackage = '';
    let codesNames = '';
    let type = ''

    //alert(this.state.renewal)
    if (this.state.redirect === true) {
      this.props.emptyPromoCode();
      return (<Redirect to={PORTAL_PAYMENT_METHODS} ></Redirect>)
    }

    if (cancelSubscribtionSuccess === true) {

      this.setState({ openSnakBar: true, paymentAutoRenewal: 0 });
      this.props.userPaymentProfile.paymentAutoRenewal = 0;
      this.props.emptyMobileUserProfile();




    }

    else if (cancelSubscribtionSuccess === false) {
      this.setState({ errorMsg: cancelSubscribtionError })
      this.props.emptyMobileUserProfile();
    }
    if (loading === true) {
      currentPackage = (<Loader />)
    }

    if (userPaymentProfile !== undefined && userPaymentProfile !== null && userPaymentProfile.currentPackagePaymentId !== null &&
      userPaymentProfile.currentPackagePaymentId !== undefined) {
      if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType === CONSTANTS.SUBSCRIPTION_MONTHLY) {
        packageType = (
          <span className="type">
            شهرياً &nbsp;
          </span>
        )
      }

      else if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType === CONSTANTS.SUBSCRIPTION_YEARLY) {
        packageType = (
          <span className="type">
            سنوياً &nbsp;
          </span>
        )
      }

      else if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType === CONSTANTS.SUBSCRIPTION_WEEKLY) {
        packageType = (
          <span className="type">
            أسبوعياً &nbsp;
          </span>
        )
      }

      if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_FAWRY) {
        paymentIcon = (
          <div className="history-logo">
            <img src={Fawry} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_STRIPE) {
        paymentIcon = (
          <div className="history-logo">
            <img src={Stripe} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_PRE_PAID_CARD) {
        paymentIcon = (
          <div className="history-logo">
            <img src={Pre_Paid_Card} alt="" />
          </div>
        )
      }
      else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_MADA) {
        paymentIcon = (
          <div className="history-logo">
            <img src={MADA} alt="" />
          </div>
        )
      }
      else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_STC_PAY) {
        paymentIcon = (
          <div className="history-logo">
            <img src={STC_PAY} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_FIT_MOBILY) {
        paymentIcon = (
          <div className="history-logo">
            <img src={FIT_MOBILY} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_FIT_STC) {
        paymentIcon = (
          <div className="history-logo">
            <img src={FIT_STC} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_FIT_ZAIN) {
        paymentIcon = (
          <div className="history-logo">
            <img src={FIT_ZAIN} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_TPAY_VODAFONE) {
        paymentIcon = (
          <div className="history-logo">
            <img src={JEEL_VODAFONE} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_TPAY_WE) {
        paymentIcon = (
          <div className="history-logo">
            <img src={JEEL_WE} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_TPAY_ETISALAT) {
        paymentIcon = (
          <div className="history-logo">
            <img src={JEEL_ETISALAT} alt="" />
          </div>
        )
      } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_TPAY_ORANGE) {
        paymentIcon = (
          <div className="history-logo">
            <img src={JEEL_ORANGE} alt="" />
          </div>
        )
      } else {
        paymentIcon = '';
      }

      let price = userPaymentProfile.currentPackagePaymentId.paymentPackageId.regularPrice;
      type = this.typeOf(userPaymentProfile.currentPackagePaymentId.type);
      let packagePriceInfo = '';

      if (!TELECOM_COMPANIES.includes(userPaymentProfile.currentPackagePaymentId.type)) {
        packagePriceInfo = (
          <div className="current-content">
            <div className="cost d-flex">
              {/** sit dynamic currency not constant ($), and switch to EGP in case of FAWRY **/}
              <div className="currency" style={{ fontSize: '12px' }}>{currency}</div>
              <div className="price" style={{ fontSize: '17px' }}>{price}</div>
              <div className="per" style={{ fontSize: '17px' }}>{packageType}</div>
            </div>

            <div className="current-footer" style={{ float: 'right', textAligin: 'right' }}>
              تاريخ التجديد {userPaymentProfile.currentPackagePaymentId.expirationDatetime}
            </div>
            <br />
          </div>
        )
      }

      currentPackage = (
        <div>
          <div className="current-card">
            <div className="card-title" style={{ float: 'right', textAligin: 'right' }}>
              الباقة الحالية
            </div>
            <br /><br />
            <div className="current-body">
              <div className="current-title" style={{ paddingBottom: '10px' }}>
                <div className="title" style={{ textAligin: 'right' }}>عن طريق {type}</div>
                <div className="current-logo">
                  {paymentIcon}
                </div>
              </div>
              {packagePriceInfo}
            </div>
          </div>
          <form>
            <div className="row current-content">
              <p style={{ color: 'red', float: 'right', marginRight: '15px' }}> {this.state.errorMsg} </p>
            </div>
            <div className="form-group checkbox-container">
              <input type="checkbox" id="automatic" className="main-checkbox"
                checked={paymentAutoRenewal}
                onChange={this.handleRenew} />
              <label for="automatic" className="checkbox-label">تجديد الباقة تلقائيا</label>
            </div>
            <div className="form-group">
              <button type="submit" className="full-w-btn auto-btn mt-40px" onClick={this.handleSubmit}>تجديد الباقة</button>
            </div>
          </form>
        </div>
      )
    } else {
      currentPackage = (
        <div>
          <div className="current-card">
            <div className="card-title" style={{ float: 'right', textAligin: 'right' }}>
              أنت غير مشترك فى باقة حالياً
            </div>
            <br /><br />

          </div>
          <form>
            <div className="form-group">
              <button type="submit" className="full-w-btn auto-btn mt-40px" onClick={this.handleSubmit}>اشترك الآن</button>
            </div>
          </form>
        </div>
      )
    }

    if (promocodesuccess === true && promocode !== undefined && promocode !== null && promocode !== '') {
      var symbol = '%';
      if (promocode.discountType !== undefined && promocode.discountType.type === 'FIXED') {
        symbol = '$';
      }
      savedCodes = (
        <div className="plan-hint">
          <div className="hint-icon">
            <img src={codeIcon} alt="" />
          </div>
          <div className="hint-text">
            لديك كود تخفيض مفعّل للتجديد القادم (قيمة التخفيض <span className="bold-title">{promocode.discountAmount}{symbol}</span>)
          </div>
        </div>
      )
    } else {
      savedCodes = '';
    }

    if (promocodeload === true) {
      savedCodes = (<Loader />);
    }
    return (
      <body>
        <Snackbar
          autoHideDuration={3000} onClose={this.handleClose}
          anchorOrigin={{ vertical, horizontal }}
          open={this.state.openSnakBar}
          onClose={this.handleClose}
          message="تم الغاء التجديد التلقائى بنجاح"
          key={vertical + horizontal}
        />

        <Snackbar
          autoHideDuration={3000} onClose={this.handleSuccessMessages}
          anchorOrigin={{ vertical, horizontal }}
          open={successMessage.show}
          onClose={this.handleSuccessMessages}
          message={successMessage.message}
          key={vertical + horizontal}
        />
        {/** Start NavBar **/}
        <NavBar />
        {/** End NavBar */}
        <div className="main-container">
          <div className="container">
            <div className="form-row">
              {/** Side Menu **/}
              <SideMenu />
              {/** End Side Menu */}
              <div className="col-12 col-md-8">
                <div className="content-container">
                  {/** Start of subsciption section */}

                  <div className="plan-content">
                    <div className="row">
                      <div className="plan-title h2 bold-title">
                        الإشتراكات
                      </div>
                    </div>
                    {/** saved promo codes */}
                    {savedCodes}
                    {/** End of saved promo codes */}
                    {/** current payment package */}
                    {currentPackage}
                    {/** End current payment package */}
                  </div>
                  {/** End of subsciption section **/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** Start Footer */}
        <Footer />
        {/** End Footer */}

      </body>
    )
  }
}

CustomerSubscriptionProfile.propTypes = {
  // getUserDataWithCodes: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  userPaymentProfile: state.userPaymentProfile.item,
  loading: state.userPaymentProfile.loading,
  error: state.userPaymentProfile.getting_user_payment_data_error,
  success: state.userPaymentProfile.getting_user_payment_data_sucessfully,

  cancelSubscribtionSuccess: state.userPaymentProfile.user_payment_cancel_subscription_sucessfully,
  cancelSubscribtionError: state.userPaymentProfile.user_payment_cancel_subscription_error,

  promocodesuccess: state.promoCodes.get_code_sucessfully,
  promocode: state.promoCodes.item,
  promocodeload: state.promoCodes.loading,

  successMessage: state.successMessages.item,
});

export default withRouter
  (connect(mapStateToProps,
    { //getUserDataWithCodes,
      cancelSubscription,
      cancelMobileSubscription,
      getUserPaymentInfo, emptyMobileUserProfileWithItem, emptyMobileUserProfile,
      getSavedPromoCode, emptyPromoCode, clearPromoCodeAll,
      addSuccessMessage, removeSuccessMessage,
      emptyCodeFromRedux,
      clearPaymentData,

    })
    (CustomerSubscriptionProfile));
