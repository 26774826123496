import {af} from '../../images/portal/assets/af.png';

const countryListEN = [
  "Andorra",
  "United Arab Emirates",
  "Afghanistan",
  "Antigua and Barbuda",
  "Anguilla",
  "Albania",
  "Armenia",
  "Netherlands Antilles",
  "Angola",
  "Antarctica",
  "Argentina",
  "American Samoa",
  "Austria",
  "Australia",
  "Aruba",
  "Åland Islands",
  "Azerbaijan",
  "Bosnia and Herzegovina",
  "Barbados",
  "Bangladesh",
  "Belgium",
  "Burkina Faso",
  "Bulgaria",
  "Bahrain",
  "Burundi",
  "Benin",
  "Saint Barthélemy",
  "Bermuda",
  "Brunei Darussalam",
  "Bolivia, Plurinational State of",
  "Caribbean Netherlands",
  "Brazil",
  "Bahamas",
  "Bhutan",
  "Bouvet Island",
  "Botswana",
  "Belarus",
  "Belize",
  "Canada",
  "Cocos (Keeling) Islands",
  "Congo, the Democratic Republic of the",
  "Central African Republic",
  "Congo",
  "Switzerland",
  "Côte d'Ivoire",
  "Cook Islands",
  "Chile",
  "Cameroon",
  "China",
  "Colombia",
  "Costa Rica",
  "Cuba",
  "Cape Verde",
  "Curaçao",
  "Christmas Island",
  "Cyprus",
  "Czech Republic",
  "Germany",
  "Djibouti",
  "Denmark",
  "Dominica",
  "Dominican Republic",
  "Algeria",
  "Ecuador",
  "Estonia",
  "Egypt",
  "Western Sahara",
  "Eritrea",
  "Spain",
  "Ethiopia",
  "Finland",
  "Fiji",
  "Falkland Islands (Malvinas)",
  "Micronesia, Federated States of",
  "Faroe Islands",
  "France",
  "Gabon",
  "United Kingdom",
  "England",
  "Northern Ireland",
  "Scotland",
  "Wales",
  "Grenada",
  "Georgia",
  "French Guiana",
  "Guernsey",
  "Ghana",
  "Gibraltar",
  "Greenland",
  "Gambia",
  "Guinea",
  "Guadeloupe",
  "Equatorial Guinea",
  "Greece",
  "South Georgia and the South Sandwich Islands",
  "Guatemala",
  "Guam",
  "Guinea-Bissau",
  "Guyana",
  "Hong Kong",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Croatia",
  "Haiti",
  "Hungary",
  "Indonesia",
  "Ireland",
  "Israel",
  "Isle of Man",
  "India",
  "British Indian Ocean Territory",
  "Iraq",
  "Iran, Islamic Republic of",
  "Iceland",
  "Italy",
  "Jersey",
  "Jamaica",
  "Jordan",
  "Japan",
  "Kenya",
  "Kyrgyzstan",
  "Cambodia",
  "Kiribati",
  "Comoros",
  "Saint Kitts and Nevis",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Cayman Islands",
  "Kazakhstan",
  "Lao People's Democratic Republic",
  "Lebanon",
  "Saint Lucia",
  "Liechtenstein",
  "Sri Lanka",
  "Liberia",
  "Lesotho",
  "Lithuania",
  "Luxembourg",
  "Latvia",
  "Libya",
  "Morocco",
  "Monaco",
  "Moldova, Republic of",
  "Montenegro",
  "Saint Martin",
  "Madagascar",
  "Marshall Islands",
  "Macedonia, the former Yugoslav Republic of",
  "Mali",
  "Myanmar",
  "Mongolia",
  "Macao",
  "Northern Mariana Islands",
  "Martinique",
  "Mauritania",
  "Montserrat",
  "Malta",
  "Mauritius",
  "Maldives",
  "Malawi",
  "Mexico",
  "Malaysia",
  "Mozambique",
  "Namibia",
  "New Caledonia",
  "Niger",
  "Norfolk Island",
  "Nigeria",
  "Nicaragua",
  "Netherlands",
  "Norway",
  "Nepal",
  "Nauru",
  "Niue",
  "New Zealand",
  "Oman",
  "Panama",
  "Peru",
  "French Polynesia",
  "Papua New Guinea",
  "Philippines",
  "Pakistan",
  "Poland",
  "Saint Pierre and Miquelon",
  "Pitcairn",
  "Puerto Rico",
  "Palestine",
  "Portugal",
  "Palau",
  "Paraguay",
  "Qatar",
  "Réunion",
  "Romania",
  "Serbia",
  "Russian Federation",
  "Rwanda",
  "Saudi Arabia",
  "Solomon Islands",
  "Seychelles",
  "Sudan",
  "Sweden",
  "Singapore",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Slovenia",
  "Svalbard and Jan Mayen Islands",
  "Slovakia",
  "Sierra Leone",
  "San Marino",
  "Senegal",
  "Somalia",
  "Suriname",
  "South Sudan",
  "Sao Tome and Principe",
  "El Salvador",
  "Sint Maarten (Dutch part)",
  "Syrian Arab Republic",
  "Swaziland",
  "Turks and Caicos Islands",
  "Chad",
  "French Southern Territories",
  "Togo",
  "Thailand",
  "Tajikistan",
  "Tokelau",
  "Timor-Leste",
  "Turkmenistan",
  "Tunisia",
  "Tonga",
  "Turkey",
  "Trinidad and Tobago",
  "Tuvalu",
  "Taiwan",
  "Tanzania, United Republic of",
  "Ukraine",
  "Uganda",
  "US Minor Outlying Islands",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Holy See (Vatican City State)",
  "Saint Vincent and the Grenadines",
  "Venezuela, Bolivarian Republic of",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Vietnam",
  "Vanuatu",
  "Wallis and Futuna Islands",
  "Kosovo",
  "Samoa",
  "Yemen",
  "Mayotte",
  "South Africa",
  "Zambia",
  "Zimbabwe"
];

const countryListAR = [
  "أندورا",
  "الإمارات العربية المتحدة",
  "أفغانستان",
  "أنتيغوا وبربودا",
  "أنغويلا",
  "ألبانيا",
  "أرمينيا",
  "الأنتيل الهولندية",
  "أنجولا",
  "القارة القطبية الجنوبية",
  "الأرجنتين",
  "ساموا الأمريكية",
  "النمسا",
  "أستراليا",
  "أروبا",
  "آلاند",
  "أذربيجان",
  "البوسنة والهرسك",
  "بربادوس",
  "بنغلاديش",
  "بلجيكا",
  "بوركينا فاسو",
  "بلغاريا",
  "البحرين",
  "بوروندي",
  "بنين",
  "سانت بارتيليمي",
  "برمودا",
  "بروناي دار السلام",
  "بوليفيا",
  "الكاريبي هولندا",
  "البرازيل",
  "الباهاما",
  "بوتان",
  "بوفيه",
  "بوتسوانا",
  "روسيا البيضاء",
  "بليز",
  "كندا",
  "كوكوس (كيلينغ)",
  "الكونغو الديمقراطية",
  "إفريقيا الوسطى",
  "الكونغو",
  "سويسرا",
  "كوت ديفوار",
  "كوك",
  "تشيلي",
  "الكاميرون",
  "الصين",
  "كولومبيا",
  "كوستا ريكا",
  "كوبا",
  "الرأس الأخضر",
  "كوراساو",
  "جزيرة الكريسماس",
  "قبرص",
  "التشيك",
  "المانيا",
  "جيبوتي",
  "الدنمارك",
  "دومينيكا",
  "جمهورية الدومنيكان",
  "الجزائر",
  "الإكوادور",
  "إستونيا",
  "مصر",
  "الصحراء الغربية",
  "إرتريا",
  "إسبانيا",
  "إثيوبيا",
  "فنلندا",
  "فيجي",
  "فوكلاند (مالفيناس)",
  "ميكرونيزيا الموحدة",
  "فارو",
  "فرنسا",
  "الغابون",
  "المملكة المتحدة",
  "انجلترا",
  "ايرلندا الشمالية",
  "إسكتلندا",
  "ويلز",
  "غرينادا",
  "جورجيا",
  "غيانا الفرنسية",
  "غيرنسي",
  "غانا",
  "جبل طارق",
  "جرين لاند",
  "غامبيا",
  "غينيا",
  "جوادلوب",
  "غينيا الإستوائية",
  "اليونان",
  "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  "غواتيمالا",
  "غوام",
  "غينيا بيساو",
  "غيانا",
  "هونج كونج",
  "هيرد و ماكدونالد",
  "هندوراس",
  "كرواتيا",
  "هايتي",
  "هنغاريا",
  "إندونيسيا",
  "أيرلندا",
  "فلسطين1",
  "ايل أوف مان",
  "الهند",
  "إقليم المحيط البريطاني الهندي",
  "العراق",
  "ايران",
  "ايسلندا",
  "ايطاليا",
  "جيرسي",
  "جامايكا",
  "الأردن",
  "اليابان",
  "كينيا",
  "قرغيزستان",
  "كمبوديا",
  "كيريباس",
  "جزر القمر",
  "سانت كيتس ونيفيس",
  "كوريا الشمالية",
  "كوريا الجنوبية",
  "الكويت",
  "كايمان",
  "كازاخستان",
  "لاوس",
  "لبنان",
  "سانت لوسيا",
  "ليختنشتاين",
  "سيريلانكا",
  "ليبيريا",
  "ليسوتو",
  "ليتوانيا",
  "لوكسمبورغ",
  "لاتفيا",
  "ليبيا",
  "المغرب",
  "موناكو",
  "مولدوفا",
  "الجبل الأسود",
  "سانت مارتن",
  "مدغشقر",
  "المارشال",
  "مقدونيا",
  "مالي",
  "ميانمار",
  "منغوليا",
  "ماكاو",
  "مارينا الشمالية",
  "المارتينيك",
  "موريتانيا",
  "مونتسيرات",
  "مالطا",
  "موريشيوس",
  "المالديف",
  "مالاوي",
  "المكسيك",
  "ماليزيا",
  "موزمبيق",
  "ناميبيا",
  "كاليدونيا الجديدة",
  "النيجر",
  "نورفولك",
  "نيجيريا",
  "نيكاراغوا",
  "هولندا",
  "النرويج",
  "نيبال",
  "ناورو",
  "نيوي",
  "نيوزيلاندا",
  "عمان",
  "بنما",
  "بيرو",
  "بولينيزيا الفرنسية",
  "بابوا غينيا الجديدة",
  "الفلبين",
  "باكستان",
  "بولندا",
  "سانت بيير وميكلون",
  "بيتكيرن",
  "بورتوريكو",
  "فلسطين",
  "البرتغال",
  "بالاو",
  "باراغواي",
  "قطر",
  "ريونيون",
  "رومانيا",
  "صربيا",
  "روسيا",
  "رواندا",
  "المملكة العربية السعودية",
  "جزر سليمان",
  "سيشيل",
  "السودان",
  "السويد",
  "سنغافورة",
  "سانت هيلانة ، أسنشن وتريستان دا كونها",
  "سلوفينيا",
  "جزر سفالبارد وجان ماين",
  "سلوفاكيا",
  "سيرا ليون",
  "سان مارينو",
  "السنغال",
  "الصومال",
  "سورينام",
  "جنوب السودان",
  "ساو تومي وبرينسيبي",
  "السلفادور",
  "سانت مارتن (الجزء الهولندي)",
  "سوريا",
  "سوازيلاند",
  "جزر تركس وكايكوس",
  "تشاد",
  "المناطق الجنوبية لفرنسا",
  "توجو",
  "تايلاند",
  "طاجيكستان",
  "توكيلاو",
  "تيمور الشرقية",
  "تركمانستان",
  "تونس",
  "تونغا",
  "تركيا",
  "ترينداد وتوباغو",
  "توفالو",
  "تايوان",
  "تنزانيا",
  "أوكرانيا",
  "أوغندا",
  "جزر الولايات المتحدة البعيدة الصغيرة",
  "الولايات المتحدة الأمريكية",
  "أوروغواي",
  "أوزبكستان",
  "الفاتيكان",
  "سانت فنسنت وجزر غرينادين",
  "فنزويلا",
  "جزر العذراء البريطانية",
  "جزر فيرجن ، الولايات المتحدة",
  "فيتنام",
  "فانواتو",
  "واليس وفوتونا",
  "كوسوفو",
  "ساموا",
  "اليمن",
  "مايوت",
  "جنوب أفريقيا",
  "زامبيا",
  "زيمبابوي"
];

const isoCodeArr = [
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AN",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GB-ENG",
  "GB-NIR",
  "GB-SCT",
  "GB-WLS",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "XK",
  "WS",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW"
];

const iso3CodeArr = [
  "AND",
  "ARE",
  "AFG",
  "ATG",
  "AIA",
  "ALB",
  "ARM",
  "AN",
  "AGO",
  "ATA",
  "ARG",
  "ASM",
  "AUT",
  "AUS",
  "ABW",
  "ALA",
  "AZE",
  "BIH",
  "BRB",
  "BGD",
  "BEL",
  "BFA",
  "BGR",
  "BHR",
  "BDI",
  "BEN",
  "BLM",
  "BMU",
  "BRN",
  "BOL",
  "BES",
  "BRA",
  "BHS",
  "BTN",
  "BVT",
  "BWA",
  "BLR",
  "BLZ",
  "CAN",
  "CCK",
  "COD",
  "CAF",
  "COG",
  "CHE",
  "CIV",
  "COK",
  "CHL",
  "CMR",
  "CHN",
  "COL",
  "CRI",
  "CUB",
  "CPV",
  "CUW",
  "CXR",
  "CYP",
  "CZE",
  "DEU",
  "DJI",
  "DNK",
  "DMA",
  "DOM",
  "DZA",
  "ECU",
  "EST",
  "EGY",
  "ESH",
  "ERI",
  "ESP",
  "ETH",
  "FIN",
  "FJI",
  "FLK",
  "FSM",
  "FRO",
  "FRA",
  "GAB",
  "GBR",
  "GBR",
  "GBR",
  "GBR",
  "GBR",
  "GRD",
  "GEO",
  "GUF",
  "GGY",
  "GHA",
  "GIB",
  "GRL",
  "GMB",
  "GIN",
  "GLP",
  "GNQ",
  "GRC",
  "SGS",
  "GTM",
  "GUM",
  "GNB",
  "GUY",
  "HKG",
  "HMD",
  "HND",
  "HRV",
  "HTI",
  "HUN",
  "IDN",
  "IRL",
  "ISR",
  "IMN",
  "IND",
  "IOT",
  "IRQ",
  "IRN",
  "ISL",
  "ITA",
  "JEY",
  "JAM",
  "JOR",
  "JPN",
  "KEN",
  "KGZ",
  "KHM",
  "KIR",
  "COM",
  "KNA",
  "PRK",
  "KOR",
  "KWT",
  "CYM",
  "KAZ",
  "LAO",
  "LBN",
  "LCA",
  "LIE",
  "LKA",
  "LBR",
  "LSO",
  "LTU",
  "LUX",
  "LVA",
  "LBY",
  "MAR",
  "MCO",
  "MDA",
  "MNE",
  "MAF",
  "MDG",
  "MHL",
  "MKD",
  "MLI",
  "MMR",
  "MNG",
  "MAC",
  "MNP",
  "MTQ",
  "MRT",
  "MSR",
  "MLT",
  "MUS",
  "MDV",
  "MWI",
  "MEX",
  "MYS",
  "MOZ",
  "NAM",
  "NCL",
  "NER",
  "NFK",
  "NGA",
  "NIC",
  "NLD",
  "NOR",
  "NPL",
  "NRU",
  "NIU",
  "NZL",
  "OMN",
  "PAN",
  "PER",
  "PYF",
  "PNG",
  "PHL",
  "PAK",
  "POL",
  "SPM",
  "PCN",
  "PRI",
  "PSE",
  "PRT",
  "PLW",
  "PRY",
  "QAT",
  "REU",
  "ROU",
  "SRB",
  "RUS",
  "RWA",
  "SAU",
  "SLB",
  "SYC",
  "SDN",
  "SWE",
  "SGP",
  "SHN",
  "SVN",
  "SJM",
  "SVK",
  "SLE",
  "SMR",
  "SEN",
  "SOM",
  "SUR",
  "SSD",
  "STP",
  "SLV",
  "SXM",
  "SYR",
  "SWZ",
  "TCA",
  "TCD",
  "ATF",
  "TGO",
  "THA",
  "TJK",
  "TKL",
  "TLS",
  "TKM",
  "TUN",
  "TON",
  "TUR",
  "TTO",
  "TUV",
  "TWN",
  "TZA",
  "UKR",
  "UGA",
  "UMI",
  "USA",
  "URY",
  "UZB",
  "VAT",
  "VCT",
  "VEN",
  "VGB",
  "VIR",
  "VNM",
  "VUT",
  "WLF",
  "KOS",
  "WSM",
  "YEM",
  "MYT",
  "ZAF",
  "ZMB",
  "ZWE"
];

const phoneCodeArr = [
  "376",
  "971",
  "93",
  "1-268",
  "1-264",
  "355",
  "374",
  "599",
  "244",
  "672",
  "54",
  "1-684",
  "43",
  "61",
  "297",
  "358",
  "994",
  "387",
  "1-246",
  "880",
  "32",
  "226",
  "359",
  "973",
  "257",
  "229",
  "590",
  "1-441",
  "673",
  "591",
  "599",
  "55",
  "1-242",
  "975",
  "47",
  "267",
  "375",
  "501",
  "1",
  "61",
  "243",
  "236",
  "242",
  "41",
  "225",
  "682",
  "56",
  "237",
  "86",
  "57",
  "506",
  "53",
  "238",
  "599",
  "61",
  "357",
  "420",
  "49",
  "253",
  "45",
  "1-767",
  "1-849",
  "213",
  "593",
  "372",
  "20",
  "212",
  "291",
  "34",
  "251",
  "358",
  "679",
  "500",
  "691",
  "298",
  "33",
  "241",
  "44",
  "44",
  "44",
  "44",
  "44",
  "1-473",
  "995",
  "594",
  "44-1481",
  "233",
  "350",
  "299",
  "220",
  "224",
  "590",
  "240",
  "30",
  "500",
  "502",
  "1-671",
  "245",
  "592",
  "852",
  "672",
  "504",
  "385",
  "509",
  "36",
  "62",
  "353",
  "972",
  "44-1624",
  "91",
  "246",
  "964",
  "98",
  "354",
  "39",
  "44-1534",
  "1-876",
  "962",
  "81",
  "254",
  "996",
  "855",
  "686",
  "269",
  "1-869",
  "850",
  "82",
  "965",
  "1-345",
  "7",
  "856",
  "961",
  "1-758",
  "423",
  "94",
  "231",
  "266",
  "370",
  "352",
  "371",
  "218",
  "212",
  "377",
  "373",
  "382",
  "590",
  "261",
  "692",
  "389",
  "223",
  "95",
  "976",
  "853",
  "1-670",
  "596",
  "222",
  "1-664",
  "356",
  "230",
  "960",
  "265",
  "52",
  "60",
  "258",
  "264",
  "687",
  "227",
  "672",
  "234",
  "505",
  "31",
  "47",
  "977",
  "674",
  "683",
  "64",
  "968",
  "507",
  "51",
  "689",
  "675",
  "63",
  "92",
  "48",
  "508",
  "64",
  "1-787",
  "970",
  "351",
  "680",
  "595",
  "974",
  "262",
  "40",
  "381",
  "7",
  "250",
  "966",
  "677",
  "248",
  "249",
  "46",
  "65",
  "290",
  "386",
  "47",
  "421",
  "232",
  "378",
  "221",
  "252",
  "597",
  "211",
  "239",
  "503",
  "1-721",
  "963",
  "268",
  "1-649",
  "235",
  "262",
  "228",
  "66",
  "992",
  "690",
  "670",
  "993",
  "216",
  "676",
  "90",
  "1-868",
  "688",
  "886",
  "255",
  "380",
  "256",
  "1",
  "1",
  "598",
  "998",
  "379",
  "1-784",
  "58",
  "1-284",
  "1-340",
  "84",
  "678",
  "681",
  "383",
  "685",
  "967",
  "262",
  "27",
  "260",
  "263"
];

var countries = [];

var phoneCodes = [];

for (var i = 0 ; i < countryListEN.length; i++){
  var country = {};
  country.key = countryListAR[i];
  country.text = countryListAR[i];
  country.value = countryListAR[i];
  country.name = countryListEN[i];
  country.flag = (isoCodeArr[i]).toString().toLowerCase();
  countries.push(country);
}

for (var i = 0 ; i < countryListEN.length; i++){
  var phoneCode = {};
  phoneCode.key = countryListEN[i];
  phoneCode.text = phoneCodeArr[i];
  phoneCode.value = phoneCodeArr[i];
  phoneCode.name = phoneCodeArr[i];
  phoneCode.flag = (isoCodeArr[i]).toString().toLowerCase();
  phoneCodes.push(phoneCode);
}

export var countries


export var phoneCodes
