import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Popup } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import porpTypes from 'prop-types';
import { withFirebase } from '../../config';
/*Ccomponents */
import EvaluationBlogs from './EvaluationBlogs';
import UploadFile from '../S3Uploading/UploadFile';
/*Actions */
import { addEvaluationToRedux }
    from '../../Actions/EvaluationActions';
import { emptyEvaluationSucessAndfailure }
    from '../../Actions/EvaluationActions'
import {
    saveOneEvaluationsWithCategoryIDs,
    saveOneEvaluationsWithoutCategoryIDs,
    updateEvaluation
} from '../../Promises/EvaluationPromise.js'
import { addEvaluationCategory } from '../../Actions/EvaluationCategoryAction';
/* Routes */
import { SIGN_IN } from '../../routes';


class EditEvaluationForm extends Component {

    state = {
        id: 0,
        checkedBlogs: new Map(),

        start_number: 0,
        end_number: 0,
        recommendation: "",
        fileURL: "",
        articleURL: "",

        // evaluationCategoryId: 0,
        category_type: "",
        dataExist: "",
        // adding evaluation
        evaluationAdded: false,

        showfileUploader: true,
        showArticleUploader: true

    };

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {

            const { category_type } = this.props

            // const { evaluationCategoryitem } = this.props;
            // const { evaluationCategoryId } = this.props

            const { formData } = this.props

            // console.log("/////// didMount ///////////")
            // console.log(category_type)
            // console.log(evaluationCategoryitem)
            // console.log(evaluationCategoryId)
            // console.log(formData)
            // console.log("//////// didMount //////////")


            if (formData !== undefined
                && category_type !== undefined
                // && evaluationCategoryId !== undefined
            ) {

                if (formData == null) {

                    console.log("formData == null ")
                    // console.log(evaluationCategoryitem)
                    // this.props.addEvaluationCategory(evaluationCategoryitem);

                    this.setState({
                        dataExist: false
                        // ,
                        // evaluationCategoryId: evaluationCategoryId
                    })
                }
                // else if (formData == null && evaluationCategoryitem == null) {
                //     console.log("formData == null && evaluationCategoryitem == null")
                //     // this.props.addEvaluationCategory(evaluationCategoryitem);

                //     this.setState({
                //         dataExist: false,
                //         // evaluationCategoryId: evaluationCategoryId
                //     })
                // }
                else {
                    console.log("else")
                    console.log(formData.blogs)
                    console.log("////////////////////////////")
                    console.log(formData)
                    console.log(formData.articleUrl)
                    this.setState({

                        id: formData.id,
                        start_number: formData.startNumber,
                        end_number: formData.endNumber,
                        recommendation: formData.recommendation,
                        fileURL: formData.uploadedFile,
                        articleURL: formData.articleUrl,
                        checkedBlogs: formData.blogs,

                        // evaluationCategoryId: evaluationCategoryId,
                        category_type: category_type,
                        dataExist: true,
                        showfileUploader: false,
                        showArticleUploader: false

                    });

                }
            }
        }
    }

    componentWillReceiveProps() {

        const { copyFileURL } = this.props;
        const { copyArticleURL } = this.props;

        const { start } = this.props;
        const { end } = this.props;
        const { recommendation } = this.props;


        if (copyFileURL !== ""
            && copyFileURL !== null
            && copyFileURL !== undefined
            &&
            (this.state.fileURL == ""
                || this.state.fileURL == null
                || this.state.fileURL == undefined)
        ) {
            this.setState({
                fileURL: copyFileURL,
                showfileUploader: false

            })
        }

        if (copyArticleURL !== ""
            && copyArticleURL !== null
            && copyArticleURL !== undefined
            &&
            (this.state.articleURL == ""
                || this.state.articleURL == null
                || this.state.articleURL == undefined)
        ) {

            this.setState({
                articleURL: copyArticleURL,
                showArticleUploader: false
            })
        }

        if (start !== ""
            && start !== null
            && start !== undefined
            &&
            (this.state.start_number == 0
                || this.state.start_number == null
                || this.state.start_number == undefined)
        ) {
            this.setState({
                start_number: start,
            })
        }



        if (end !== ""
            && end !== null
            && end !== undefined
            &&
            (this.state.end_number == ""
                || this.state.end_number == null
                || this.state.end_number == undefined)
        ) {
            this.setState({
                end_number: end,
            })
        }

        if (recommendation !== ""
            && recommendation !== null
            && recommendation !== undefined
            &&
            (this.state.recommendation == ""
                || this.state.recommendation == null
                || this.state.recommendation == undefined)
        ) {
            this.setState({
                recommendation: recommendation,
            })
        }

    }


    getCheckedBlogs(checkedItems) {

        if (checkedItems) {
            this.setState({ checkedBlogs: checkedItems });
            console.log("getCheckedBlogs in EditEvaluationForm = ");
            console.log(checkedItems);
        }
    }

    getFileUrl(fileURL) {
        if (fileURL) {
            this.setState({
                fileURL: fileURL,
                showfileUploader: false
            });
            console.log("EditEvaluationForm fileUrl")
            console.log(fileURL);
            this.props.returnCategoryFileURLCallBack(fileURL);

        }
    }

    getArticleUrl(articleURL) {
        if (articleURL) {
            this.setState({
                articleURL: articleURL,
                showArticleUploader: false
            });
            console.log("EvaluationForm articleURL")
            console.log(articleURL);
            this.props.returnCategoryArticleURLCallBack(articleURL);

        }

    }

    handleChange = (e) => {
        if (e.target.name === "start_number") {
            this.props.returnedStart(e.target.value);

        }
        else if (e.target.name === "end_number") {
            this.props.returnedEnd(e.target.value);

        }
        else if (e.target.name === "recommendation") {
            this.props.returnedRecommendation(e.target.value);

        }

        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleUpdate = (e) => {

        const { checkedBlogs } = this.state;
        const { id, start_number, end_number,
            recommendation, fileURL, articleURL } = this.state;

        console.log(checkedBlogs)
        var selectedBlogsIDs = [];
        var get_keys = checkedBlogs.keys();

        for (var ele of get_keys) {
            selectedBlogsIDs.push(ele);
        }

        for (let i = 0; i < selectedBlogsIDs.length; i++) {
            console.log(selectedBlogsIDs[i]);
        }

        if (this.state.evaluationAdded === false) {
            if (id !== undefined) {

                updateEvaluation(id, start_number, end_number,
                    recommendation, fileURL, selectedBlogsIDs, articleURL)
                    .then((res) => {
                        alert("Evaluation updated successfully");
                    })
                    .catch((Error) => {
                        alert("Error messing data or network " + Error)
                    })
            } else {
                alert("Error with evaluation ID");
            }
        }
        else { alert("You can't update the one you just add") }

        e.preventDefault();

    }


    handleSubmit = (e) => {
        const { category_type } = this.props;
        const { activityID } = this.props;

        const { evaluationCategoryitem } = this.props;
        const { evaluationCategoryId } = this.props


        // var  evaluationCategoryId = this.state.evaluationCategoryId;

        console.log("********************************")
        console.log(category_type);
        console.log(" evaluation Items in form ")
        console.log(evaluationCategoryitem)

        console.log(" evaluationCategoryId in form ")
        console.log(evaluationCategoryId)
        console.log("********************************")


        // const { evaluationCategoryId } = this.state.evaluationCategoryId;
        // console.log(evaluationCategoryId)


        /////////mapping the value of customers uid to array
        const { checkedBlogs } = this.state;
        const { start_number, end_number,
            recommendation, fileURL, articleURL } = this.state;

        var selectedBlogsIDs = [];
        var get_keys = checkedBlogs.keys();

        for (var ele of get_keys) {
            selectedBlogsIDs.push(ele);
        }

        for (let i = 0; i < selectedBlogsIDs.length; i++) {
            console.log(selectedBlogsIDs[i]);
        }
        if (start_number === '' || start_number == undefined || start_number < 0) {
            alert("Empty Start Number")
        }
        else if (end_number === '' || end_number == undefined || end_number == 0) {
            alert("Empty End Number")
        }
        else if (recommendation === '' || recommendation == undefined) {
            alert("Empty Recommendation")
        }
        else if (fileURL === '' || fileURL == undefined) {
            alert("Empty Uploaded File")
        }
        else if (articleURL === '' || articleURL == undefined) {
            alert("Empty article File")
        }
        else {

            // console.log(evaluationCategoryitem)
            // console.log(evaluationCategoryitem !== undefined)
            // console.log(evaluationCategoryitem === null)
            console.log(activityID)

            // if the evaluation category has a value then update evaluationCategory table
            // and the data in redux = '' , it filled only of an evaluation category saved for the first time
            // to map data between children
            if (evaluationCategoryId !== undefined &&
                evaluationCategoryId !== null &&
                evaluationCategoryitem == '') {

                console.log("evaluationCategoryid !== undefined or null or evaluationCategoryitem == ''");
                console.log(evaluationCategoryId)
                console.log(evaluationCategoryitem)

                saveOneEvaluationsWithCategoryIDs(start_number, end_number,
                    recommendation, fileURL, selectedBlogsIDs,
                    category_type, evaluationCategoryId, articleURL)
                    .then((res) => {
                        alert("Evaluation added successfully");
                        this.setState({ evaluationAdded: true })
                    })
                    .catch((Error) => {
                        alert("Error messing data or network " + Error)
                    })

            }

            // if the evaluation category has a value then update evaluationCategory table
            else if (evaluationCategoryitem !== undefined &&
                evaluationCategoryitem !== null && evaluationCategoryitem !== '') {

                console.log("evaluationCategoryitem !== undefined or null");
                console.log(evaluationCategoryitem)

                saveOneEvaluationsWithCategoryIDs(start_number, end_number,
                    recommendation, fileURL, selectedBlogsIDs,
                    category_type, evaluationCategoryitem.id, articleURL)
                    .then((res) => {
                        alert("Evaluation added successfully");
                    })
                    .catch((Error) => {
                        alert("Error messing data or network " + Error)
                    })

            }
            // if the evaluation category has a value then save evaluationCategory table
            else if (evaluationCategoryId === null
                && evaluationCategoryitem === '') {

                console.log("evaluationCategoryitem === null");
                console.log(evaluationCategoryitem)
                console.log(evaluationCategoryId)

                console.log(activityID)
                saveOneEvaluationsWithoutCategoryIDs(start_number, end_number,
                    recommendation, fileURL, selectedBlogsIDs,
                    category_type, activityID, articleURL)
                    .then((res) => {

                        console.log(" evaluationCategoryitem = " + res.data.id)
                        console.log(res.data)
                        this.props.addEvaluationCategory(res.data);

                        // this.setState({ evaluationCategoryitem : res.data.id})

                        alert("Evaluation added successfully");
                    })
                    .catch((Error) => {
                        alert("Error messing data or network " + Error)
                    })

            }

            this.setState({ dataExist: true })
        }
        e.preventDefault();
    }


    handleFileUploaderPreview = (e) => {
        const { showfileUploader } = this.state;

        if (showfileUploader === true) {
            this.setState({
                showfileUploader: false
            })
        }
        else {
            this.setState({
                showfileUploader: true
            })
        }
    }


    handleArticleUploaderPreview = (e) => {
        const { showArticleUploader } = this.state;

        if (showArticleUploader === true) {
            this.setState({
                showArticleUploader: false
            })
        }
        else {
            this.setState({
                showArticleUploader: true
            })
        }
    }


    render() {
        const { start_number, end_number,
            recommendation, fileURL,
            dataExist, articleURL,
            showfileUploader,
            showArticleUploader } = this.state;

        let button = null;

        let fileLink;
        let articleLink;

        const isInvalidEvaluation =
            start_number === '' ||
            recommendation === '' ||
            fileURL === '' ||
            articleURL === '' ||
            end_number === '';


        if (fileURL !== undefined &&
            fileURL !== null &&
            fileURL !== '') {
            fileLink = (
                <span>
                    <span > Program Link : {fileURL} </span>
                    <div className="float-right">
                        <Popup trigger=
                            {<FontAwesomeIcon
                                icon='edit'
                                className="far fa-edit" onClick={this.handleFileUploaderPreview}>
                            </FontAwesomeIcon>
                            }
                            content="Upload File"
                            position="top center" />
                    </div>
                </span>
            );
        }


        let uploadFile = (<UploadFile
            getFileURLCallBack={this.getFileUrl.bind(this)}
            fileURL={this.state.fileURL}
            s3DirectryrName='evaluation' />
        )

        if (showfileUploader == false) {
            uploadFile = (
                <span>
                    <div className="d-none">
                        <UploadFile
                            getFileURLCallBack={this.getFileUrl.bind(this)}
                            fileURL={this.state.fileURL}
                            s3DirectryrName='evaluation' />
                    </div>
                    <br />
                    <br />
                </span>
            )

        }


        if (articleURL !== undefined &&
            articleURL !== null &&
            articleURL !== '') {
            articleLink = (
                <span >Article Link : {articleURL}
                    <div className="float-right">
                        <Popup trigger=
                            {<FontAwesomeIcon
                                icon='edit'
                                className="far fa-edit" onClick={this.handleArticleUploaderPreview}>
                            </FontAwesomeIcon>
                            }
                            content="Upload Article"
                            position="top center" />
                    </div>
                </span>
            );
        }

        let uploadArticle = (
            <UploadFile
                getFileURLCallBack={this.getArticleUrl.bind(this)}
                fileURL={this.state.articleURL}
                s3DirectryrName='evaluation' />

        )

        if (showArticleUploader == false) {
            uploadArticle = (
                <span>
                    <div className="d-none">
                        <UploadFile
                            getFileURLCallBack={this.getArticleUrl.bind(this)}
                            fileURL={this.state.articleURL}
                            s3DirectryrName='evaluation' />
                    </div>
                    <br />
                    <br />
                </span>
            )

        }


        if (dataExist === false) { // adding case
            button =
                <button
                    className="btn btn-lg btn-success btn-suc btn-block-sm"
                    onClick={this.handleSubmit.bind(this)}
                    disabled={isInvalidEvaluation} >
                    Save
                    </button>

        }
        else if (dataExist === true) { // updating case
            button =
                <div className="buttons_container">
                    <button
                        className="btn btn-lg btn-success btn-suc btn-block-sm"
                        onClick={this.handleUpdate}
                        disabled={isInvalidEvaluation} >update
                </button>

                    {/* <button className="btn btn-lg btn-secondary btn-suc btn-block-sm"
                        id="btn-delete"
                        onClick={this.handleDelete} >
                        Delete
                    </button> */}

                </div>
        }



        return (
            <div>

                <div className="card-body">

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="small-label">
                                <span className="required_input">*</span>
                                add Start Number</label>
                            <input className="form-control" type="number"
                                name="start_number" id="start_number"
                                onChange={this.handleChange}
                                value={this.state.start_number} />
                        </div>

                        <div className="form-group col-md-6">
                            <label className="small-label">
                                <span className="required_input">*</span>
                                add End Number</label>
                            <input className="form-control" type="number"
                                name="end_number" id="end_number"
                                onChange={this.handleChange}
                                value={this.state.end_number} />
                        </div>

                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label className="small-label"
                                for="exampleFormControlTextarea1">
                                <span className="required_input">*</span>

                                Recommendation:
                            </label>
                            <textarea
                                dir="auto"
                                className="form-control"
                                id="exampleFormControlTextarea1" rows="3"
                                name="recommendation" id="recommendation"
                                onChange={this.handleChange}
                                value={this.state.recommendation} ></textarea>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="input-group col-md-12 d-block">
                            <label className="small-label">Related articles</label>
                            <div>
                                <EvaluationBlogs
                                    getListOfCheckedBlogsCallBack={this.getCheckedBlogs.bind(this)} />
                            </div>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="form-group col-md-12">
                            <label className="small-label">
                                <span className="required_input">*</span>Upload File </label>

                            <br />
                            {fileLink}

                            {uploadFile}
                            <br />

                            <label className="small-label">
                                <span className="required_input">*</span>
                                Aticle File </label>

                            <br />
                            {articleLink}
                            {uploadArticle}


                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="float-right">

                                {button}

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}

EditEvaluationForm.porpTypes = {
    addEvaluationToRedux: porpTypes.func.isRequired,
    addEvaluationCategory: porpTypes.func.isRequired,


}
const mapStateToProps = state => ({
    evaluation: state.evaluation.items,
    auth: state.firebase.auth,

    evaluationCategoryitem: state.evaluationCategory.item


});
export default withFirebase
    (connect
        (mapStateToProps,
            {
                addEvaluationToRedux,
                emptyEvaluationSucessAndfailure,
                addEvaluationCategory
            }
        )
        (EditEvaluationForm)
    );
