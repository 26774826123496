import {FETCH_All_STORY_PARENT, GET_STORY_PARENT_DATA, LOAD_STORY_PARENTES, ERROR_LOADING_STORY_PARENTES,
    ERROR_LOADING_STORY_PARENT, LOAD_STORY_PARENT, SAVE_STORY_PARENT, ERROR_ADDING_STORY_PARENT,
    ERROR_SETTING_STATE_STORY_PARENT ,SETTING_STATE_STORY_PARENT,
    ERROR_DELETING_STORY_PARENT, DELETING_STORY_PARENT,
    ERROR_UPDATE_STORY_PARENT, UPDATE_STORY_PARENT, EMPTY_STORY_PARENT,
    EMPTY_STORY_PARENT_WITH_DATA,
    ADD_STORY_PARENT_WITH_SENTFROM
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},

    loading: '',
    error_loading_story_parents: '',
    error_loading_story_parent: '',

    error_adding_story_parent: '',
    story_parent_added_sucessfully: '',

    setting_state_story_parent_error: "",
    setting_state_story_parent_sucessfully: "",

    deleting_story_parent_error: "",
    deleting_story_parent_sucessfully: "",

    updating_story_parent_sucessfully: "",
    updating_story_parent_error: "",
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_STORY_PARENT_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }

        case FETCH_All_STORY_PARENT:

            return {
                ...state,
                items: action.payload,
                error_loading_story_parents: '',
                loading: false
            }
        case GET_STORY_PARENT_DATA:

            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_story_parent: ''
            }
        case SAVE_STORY_PARENT:
            return {
                ...state,
                item: action.payload,
                items: state.items.concat(action.payload),
                story_parent_added_sucessfully: true
            }
        case EMPTY_STORY_PARENT:
            return {
                ...state,
                error_adding_story_parent: action.payload,
                story_parent_added_sucessfully: action.payload,

                setting_state_story_parent_error: action.payload,
                setting_state_story_parent_sucessfully: action.payload,

                deleting_story_parent_sucessfully: action.payload,
                deleting_story_parent_error: action.payload,

                updating_story_parent_sucessfully: action.payload,
                updating_story_parent_error: action.payload,

                loading: action.payload,

            }
        case EMPTY_STORY_PARENT_WITH_DATA:
            return {
                ...state,
                item: {}
            }

        case LOAD_STORY_PARENTES:
            return {
                ...state,
                loading: true
            }
        case LOAD_STORY_PARENT:
            return {
                ...state,
                loading: true
            }

        case ERROR_LOADING_STORY_PARENTES:
            return {

                ...state,
                error_loading_story_parents: action.payload,
                loading: false
            }

        case ERROR_LOADING_STORY_PARENT:
            return {
                ...state,
                error_loading_story_parent: action.payload,
                loading: false
            }

        case ERROR_ADDING_STORY_PARENT:
            return {
                ...state,
                error_adding_story_parent: action.payload,
                story_parent_added_sucessfully: false
            }

        case SETTING_STATE_STORY_PARENT:
            return {
                ...state,
                item: action.payload,
                setting_state_story_parent_sucessfully: true,
                loading: false
            }

        case ERROR_SETTING_STATE_STORY_PARENT:
            return {
                ...state,
                setting_state_story_parent_error: action.payload,
                loading: false
            }

        case DELETING_STORY_PARENT:
            return {

                ...state,
                item: action.payload,
                deleting_story_parent_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_STORY_PARENT:
            return {

                ...state,
                deleting_story_parent_error: action.payload,
                loading: false
            }

        case UPDATE_STORY_PARENT:
            return {
                ...state,
                item: action.payload,
                updating_story_parent_sucessfully: true,
                updating_story_parent_error: '',
                loading: false
            }

        case ERROR_UPDATE_STORY_PARENT:
            return {
                ...state,
                updating_story_parent_error: action.payload,
                loading: false,
                updating_story_parent_sucessfully: false
            }


        default:
            return state

    }

}
