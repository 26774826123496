import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Button } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    emptyTimeResponse,
    deleteTimeOfUse,
    fetchAllTimeOfUse
} from '../../Actions/TimeOfUseActions';

class DeleteTimeOfUse extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteTime(id) {
        this.props.deleteTimeOfUse(id);
    }

    handleSubmit = (e) => {

        const { id } = this.props;


        console.log("id = " + id)

        if (id === 0) {
            alert(" No Duration to delete");
        }
        else {
            this.deleteTime(id);
        }
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert(error)
            this.props.emptyTimeResponse();
        }
        else if (success === true) {
            alert("TimeOfUse Deleted Successfully")
            this.props.emptyTimeResponse();
            this.props.fetchAllTimeOfUse();
            this.props.deletingTimeOfUse("deleted");

        }
        return (

            <div className="acor-btn-group">


                <Button color="black" className="acor-btn2" onClick={this.open}>
                    {/* Delete */}
                    <i className="far fa-trash-alt" >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </Button>
                <Confirm id="modal"
                    content='Are you sure you want to delete this Time Of Use ?? '
                    open={this.state.open} onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </div>
        )
    }
}


DeleteTimeOfUse.propTypes = {
    fetchAllTimeOfUse: propTypes.func.isRequired,
    deleteTimeOfUse: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    timeOfUse: state.timeOfUse.items,
    loading: state.timeOfUse.loading,
    error: state.timeOfUse.deleting_Time_error,
    success: state.timeOfUse.deleting_Time_sucessfully
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyTimeResponse,
                deleteTimeOfUse,
                fetchAllTimeOfUse
            })
        (DeleteTimeOfUse)
    );

