import React, { Component } from 'react'
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import '../../css/AppCss/IssueStyle.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import { Link } from 'react-router-dom'
import ComplaintIssues from './ComplaintIssues';
import SuggestionIssues from './SuggestionIssues';

/* Routes */
import { ADMIN_LAND_PAGE } from '../../routes';

export class GetAllIssues extends Component {
    render() {
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                </span>
                                                <span>Complaints and suggestions</span>

                                            </div>
                                            <br />
                                            <h5 className="main-title mt-2 font-weight-bold">Complaints and suggestions</h5>
                                            <div className="handleCardWidth card cstm-card mt-3 p-0">
                                                <div className="card-body p-0">

                                                    <div className="tabs">
                                                        <div className="tab-button-outer">
                                                            <Tabs >
                                                                <TabList>
                                                                    <Tab>Complaints</Tab>
                                                                    <Tab>suggestions</Tab>
                                                                </TabList>

                                                                <TabPanel>
                                                                    <ComplaintIssues />
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <SuggestionIssues />
                                                                </TabPanel>
                                                            </Tabs>



                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

export default GetAllIssues
