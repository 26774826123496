import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Link } from 'react-router-dom'
import { Button, Dropdown } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import Footer from '../Layout/Footer';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import cardCheckLogo from '../../images/card_correct.png';
import cardLogo from '../../images/card.png';

/*Actions */
import { emptyVendor, getVendorData, editVendor } from '../../Actions/VendorActions'
/*CSS */
import '../../css/AppCss/Series/AddSeries.css';
import country_list from '../ConstData/PaymentAndVendorCountriesList'
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, VENDORS } from '../../routes';

const INITIAL_STATE = {

    id: '',
    name: '',
    email: '',
    password: '',
    phone: '',
    country: '',
    note: '',
    contractType: '',
    city: '',
    influencerInfo: '',
    address: '',

    ////////////////// validation form
    error: false,
    errorMessage: "",
};

export class EditVendor extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {

            let id = this.props.match.params.id;
            this.props.getVendorData(id);
            this.setState({ id: id })

        }

    }

    componentWillReceiveProps(nextprop) {
        let vendor = nextprop.vendor;


        if (vendor.vendorMasterUid !== undefined && vendor.vendorMasterUid) {
            let note = vendor.note;
            if (note === '' || note === 'null' || note === null || note === undefined) {
                note = ''
            }

            let contactType = vendor.contractType
            if (contactType === '' || contactType === 'null' || contactType === null || contactType === undefined) {
                contactType = ''
            }

            let city = vendor.city
            if (city === '' || city === 'null' || city === null || city === undefined) {
                city = ''
            }

            let influencerInfo = vendor.influencerInfo
            if (influencerInfo === '' || influencerInfo === 'null' || influencerInfo === null || influencerInfo === undefined) {
                influencerInfo = ''
            }
            let address = vendor.address;
            if (address === '' || address === 'null' || address === null || address === undefined) {
                address = ''
            }

            this.setState(
                {
                    id: vendor.id,
                    name: vendor.name,
                    email: vendor.vendorMasterUid.email,
                    password: vendor.vendorMasterUid.password,
                    phone: vendor.vendorMasterUid.phone,
                    country: vendor.vendorMasterUid.country,
                    note: note,
                    contractType: contactType,
                    city: city,
                    influencerInfo: influencerInfo,
                    address: address,
                    numberOfCards: vendor.numberOfCards,
                    numberOfActivatedCards: vendor.numberOfActivatedCards
                })
        }
    }



    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });

        setTimeout(() => {
            if ((this.state.name === '' || this.state.name === null || this.state.name === undefined)
                || (this.state.phone === '' || this.state.phone === null || this.state.phone === undefined)
                || (this.state.country === '' || this.state.country === null || this.state.country === undefined)
            ) {
                this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
            }
            else if ((this.state.name !== '' && this.state.name !== null && this.state.name !== undefined)
                && (this.state.phone !== '' && this.state.phone !== null && this.state.phone !== undefined)
                && (this.state.country !== '' && this.state.country !== null && this.state.country !== undefined)

            ) {
                this.setState({ error: false, errorMessage: "" });
            }
        }, 0);
    }

    handleCountryChange = (e, { value }) => {
        this.setState({ country: value });
    };

    handleSubmit = (e) => {

        const { id, name, country, phone, note, contractType, city, influencerInfo, address } = this.state;

        this.props.editVendor(id, name, country, phone, note, contractType, city, influencerInfo, address);
        // this.closeModal();
        e.preventDefault();

    }

    render() {
        const { success, error, loading } = this.props;


        let buttonSubmitLoader = (
            <Button onClick={this.handleSubmit}
                id="nextButton" disabled={this.state.error}>
                Save
            </Button>);

        if (loading === true) {
            buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">
                    Loading
                </button>
            )
        }
        if (success === true) {
            this.props.emptyVendor();
            alert("Updating Vendor Done Successfully");
            return (<Redirect to={VENDORS}></Redirect>)

        }
        else if (success === false) {
            alert(error);
            this.props.emptyVendor();
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">

                                            {/* <!--  start status bar --> */}
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    <Link to={VENDORS}>Vendor</Link><span> {'>'} </span>

                                                </span>
                                                <span>Edit Vendor</span>

                                            </div>
                                            {/* <!--  end status bar --> */}

                                            <br />
                                            <div className="container">
                                                <h3>Edit Vendor</h3>
                                                <hr />

                                                <div className="row mx-md-n5">
                                                    <div className="col-xs-6 px-md-5 ">

                                                        <div className="vendorbox col">
                                                            <div className="row">
                                                                <div className="col-xs-6">
                                                                    <div className="circle-tile ">
                                                                        <img
                                                                            className="main-logo float-left"
                                                                            alt="main logo"
                                                                            src={cardCheckLogo}
                                                                        />
                                                                    </div>
                                                                    <p className="col-8 vendorEditayhaga">
                                                                        Total Number Of Active Cards</p>
                                                                </div>
                                                                <div className="col-xs-6 my-3 mx-4">
                                                                    <p className="vendorEditnumbers">{this.state.numberOfActivatedCards}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xs-6 px-md-5">
                                                        <div className="vendorbox col">
                                                            <div className="row">
                                                                <div className="col-xs-6">
                                                                    <div className="circle-tile ">
                                                                        <img
                                                                            className="main-logo float-left"
                                                                            alt="main logo"
                                                                            src={cardLogo}
                                                                        />
                                                                    </div>
                                                                    <p className="col-8 vendorEditayhaga">
                                                                        Total Number Of Cards</p>
                                                                </div>
                                                                <div className="col-xs-6 my-3 mx-4">
                                                                    <p className="vendorEditnumbers">{this.state.numberOfCards}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <br />
                                                <div className="">
                                                    <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                                        <p style={{ color: "red" }}>{this.state.errorMessage} </p>

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                                    Name</label>
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="name" id="name" onChange={this.handleChange}
                                                            value={this.state.name} />
                                                        <br />


                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Password</label>
                                                        <input
                                                            type="text"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="password" id="password"
                                                            value={this.state.password}
                                                            disabled />
                                                        <br />

                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Email</label>
                                                        <input
                                                            type="email"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="email" id="email"
                                                            value={this.state.email}
                                                            disabled />
                                                        <br />


                                                        <label htmlFor="name" className="label">
                                                            <span className="required_input">*</span>
                                                            Phone</label>
                                                        <input
                                                            type="number"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="phone" id="email"
                                                            onChange={this.handleChange}
                                                            value={this.state.phone}
                                                            required />
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                                Select Country </label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Country'
                                                            id="episode_series_id"
                                                            onChange={this.handleCountryChange}
                                                            options={country_list}
                                                            value={this.state.country}
                                                        />
                                                        <br />

                                                        <label htmlFor="city" className="label">
                                                            City</label>
                                                        <input
                                                            type="text"
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="city" id="city"
                                                            onChange={this.handleChange}
                                                            value={this.state.city}
                                                        />
                                                        <br />


                                                        <div className="form-group">
                                                            <label htmlFor="address" className="label">
                                                                Address
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="address"
                                                                id="address"
                                                                onChange={this.handleChange}
                                                                value={this.state.address} />
                                                        </div>
                                                        <br />

                                                        <div className="form-group">
                                                            <label htmlFor="contractType" className="label">
                                                                Contract Type
                                                            </label>

                                                            <input
                                                                type="text"
                                                                dir="auto"
                                                                className="form-control"
                                                                name="contractType"
                                                                id="contractType"
                                                                onChange={this.handleChange}
                                                                value={this.state.contractType} />
                                                        </div>
                                                        <br />


                                                        <div className="form-group">
                                                            <label htmlFor="influencerInfo" className="label">
                                                                Influencer Info
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="influencerInfo"
                                                                id="influencerInfo"
                                                                onChange={this.handleChange}
                                                                value={this.state.influencerInfo} />
                                                        </div>
                                                        <br />


                                                        <div className="form-group">
                                                            <label htmlFor="note" className="label">
                                                                Note
                                                            </label>

                                                            <textarea dir="auto"
                                                                fluid rows="3"
                                                                className="form-control"
                                                                name="note"
                                                                id="note"
                                                                onChange={this.handleChange}
                                                                value={this.state.note} />
                                                        </div>

                                                    </form>

                                                </div>


                                                <div className="input-field">

                                                    {buttonSubmitLoader}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}


EditVendor.propTypes = {
    editVendor: propTypes.func.isRequired,
    getVendorData: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,

    vendor: state.vendor.item,

    loading: state.vendor.loading,
    success: state.vendor.update_vendor_successfully,
    error: state.vendor.update_vendor_error
});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                getVendorData,
                editVendor,
                emptyVendor
            }
        )
        (EditVendor)
    );
