import {
    LOAD_All_MAIN_VALUE, FETCH_All_MAIN_VALUE,
    ERROR_ALL_MAIN_VALUE,
    LOAD_MAIN_VALUE, ERROR_UPDATE_MAIN_VALUE,
    UPDATE_MAIN_VALUE,
    ERROR_ADDING_MAIN_VALUE, ADD_MAIN_VALUE,
    DELETING_MAIN_VALUE,
    ERROR_DELETING_MAIN_VALUE, EMPTY_MAIN_VALUE,
    GET_MAIN_VALUE, ERROR_LOADING_MAIN_VALUE

} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    showModal: '',
    loading: '',

    getting_all_main_values_error: '',
    getting_all_main_values_sucessfully: '',

    getting_main_values_data_error: '',
    getting_main_values_data_sucessfully: '',

    updating_main_values_error: '',
    updating_main_values_sucessfully: '',

    adding_main_values_error: '',
    adding_main_values_sucessfully: "",

    deleting_main_values_error: '',
    deleting_main_values_sucessfully: "",
}

export default function (state = initialState, action) {
    switch (action.type) {


        case FETCH_All_MAIN_VALUE:
            return {
                ...state,
                items: action.payload,
                getting_all_main_values_error: '',
                getting_all_main_values_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_MAIN_VALUE:
            return {
                ...state,
                getting_all_main_values_error: action.payload,
                loading: false
            }
        case GET_MAIN_VALUE:

            return {
                ...state,
                item: action.payload,
                loading: false,
                getting_main_values_data_sucessfully:true,
                getting_main_values_data_error: ''
            }

        case ERROR_LOADING_MAIN_VALUE:
            return {
                ...state,
                getting_main_values_data_error: action.payload,
                loading: false
            }

        case LOAD_MAIN_VALUE:
            return {
                ...state,
                loading: true
            }

        case LOAD_All_MAIN_VALUE:
            return {
                ...state,
                loading: true
            }

        case UPDATE_MAIN_VALUE:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                updating_main_values_error: '',
                updating_main_values_sucessfully: true,
                loading: false
            }

        case ERROR_UPDATE_MAIN_VALUE:
            return {

                ...state,
                updating_main_values_error: action.payload,
                updating_main_values_sucessfully: false,
                loading: false
            }

        case ADD_MAIN_VALUE:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                adding_main_values_error: '',
                adding_main_values_sucessfully: true,
                loading: false
            }

        case ERROR_ADDING_MAIN_VALUE:
            return {

                ...state,
                adding_main_values_error: action.payload,
                adding_main_values_sucessfully: false,
                loading: false
            }

        case DELETING_MAIN_VALUE:
            return {

                ...state,
                item: action.payload,
                deleting_main_values_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_MAIN_VALUE:
            return {

                ...state,
                deleting_main_values_error: action.payload,
                loading: false
            }

        case EMPTY_MAIN_VALUE:
            return {
                ...state,
                updating_main_values_error: action.payload,
                updating_main_values_sucessfully: action.payload,

                adding_main_values_error: action.payload,
                adding_main_values_sucessfully: action.payload,

                deleting_main_values_error: action.payload,
                deleting_main_values_sucessfully: action.payload,

                getting_main_values_data_error: action.payload,
                getting_main_values_data_sucessfully: action.payload,

                item: {},
            }

        default:
            return state

    }

}