import React, { Component } from 'react';
import '../../../node_modules/video-react/dist/video-react.css';
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from '../Layout/Loader'
import '../../css/Songs.css';
import { withRouter,Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { emptyError } from '../../Actions/Empty'
import { getAllEducationalVideos } from '../../Actions/ParentLibraryAction';
/* Routes */
import { ADMIN_LAND_PAGE, ADD_EDUCATIONAL_VIDEO, EDUCATIONAL_VIDEO_TO_EDIT ,EDUCATIONAL_VIDEO_TO_VIEW} from '../../routes';
import SetParentLibraryStatus from './SetParentLibraryStatus';
import DeleteParentLibrary from './DeleteParentLibrary';


class GetAllParentLibrary extends Component {

    componentDidMount() {
        this.props.getAllEducationalVideos();
        this.props.emptyError();
    }

    render() {
     
        const { educationalVideos } = this.props;

        const {error} = this.props;
        let educationalVideoList = null
        if (this.props.educationalVideoStatus === true) {
            educationalVideoList = (<Loader/>)
        }
        else if(this.props.educationalVideoStatus===false && this.props.error==='') {
            educationalVideoList =
                educationalVideos.map(educationalVideo => {

                    return (
                    
                        <div className="card" key={educationalVideo.id}>
                      
                            <div className="row" >
                                <div className="col ">
                                    <img className="rounded" src={educationalVideo.itemImage} alt="" height="90" width="90" />
                                </div>
                                <div className="col-10">

                                    <div>
                                    <Link className="link" to={`${EDUCATIONAL_VIDEO_TO_VIEW}/${educationalVideo.id}`}>
                                            <span className="card-title">
                                            {educationalVideo.itemNameInEnglish} - {educationalVideo.itemName}
                                            </span>
                                        </Link>
                                    </div>
                                        <hr />
                                        <DeleteParentLibrary id={educationalVideo.id}/>
                                        <Link to={`${EDUCATIONAL_VIDEO_TO_EDIT}/${educationalVideo.id}`}><button className="songCardButtons"  >Edit</button>
                                        </Link>
                                        <SetParentLibraryStatus id={educationalVideo.id} status={educationalVideo.itemStatus}/>
                                    <span className="font-italic small-font">
                                        <span><p>{educationalVideo.itemUrl}</p></span>

                                    </span>


                                </div>
                            </div>
                        </div>
                    )
                })
        }
        else
        {
            educationalVideoList=( <div><br/><center><div>{error}</div></center><br/></div>)
        }
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body" id="left-main-body">
                                        <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span>  </span>
                                                    </span>
                                                    <span>Educational Videos</span>

                                                </div>
                                            <br />
                                            <h5 className="main-title mt-2 font-weight-bold"><span>Educational Videos</span></h5>

                                           <Link to={ADD_EDUCATIONAL_VIDEO}><button type="submit" className="SongsButtons">+ Add New Educational Video</button></Link>
                                            <div className="container">
                                                {educationalVideoList}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )

    }
}
const mapStateToProps = state => ({
    educationalVideos: state.parentLibrary.items,
    educationalVideoStatus: state.parentLibrary.loading,
    error:state.parentLibrary.error_loading_educationalVideos
});

export default withRouter(connect(mapStateToProps, { getAllEducationalVideos ,emptyError })(GetAllParentLibrary));
