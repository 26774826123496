import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

/*CSS */
import '../../css/AppCss/MobilePayment/payment.css';
import '../../css/AppCss/MobilePayment/main-mQuery.css';

import logo from "../../images/logo.png";
import logout from '../../images/logout.png';

/*Actions */
import { fetchAllPaymentPackage, addPaymentData } from '../../Actions/MobilePaymentActions'
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'
import {
    getUserData, emptyMobileUserProfile,
    cancelSubscription, emptyMobileUserProfileWithItem
} from '../../Actions/MobilePaymentProfileAction';
import { addTokenToredux } from '../../Actions/loginActions.js'
import { getUserInfo } from '../../Promises/UserData.js'

/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';
import Loader from '../Layout/Loader';

/*Redux */
import firebase from 'firebase/app'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';

var today = new Date();

function addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }


function expirationDateFormate(date, type, expirationDate) {

    //console.log("expirationDate = " + expirationDate)
    var expiDate;
    var dd;
    var mm;
    var yyyy;

    if (expirationDate == null || expirationDate == undefined ||
        expirationDate == 'null') {

        expiDate = new Date();
        //console.log("today = " + expiDate)

    }
    else {
        let arrayOfDate = expirationDate.split('-');

        dd = arrayOfDate[0]

        mm = arrayOfDate[1];

        yyyy = arrayOfDate[2];

        
        var formatedEpxDate = mm + "/" + dd + "/" + yyyy;
        var oldDate = new Date(formatedEpxDate);
    
        if (today > oldDate) {
            expiDate = new Date();
        }
        else{
        // Subtract 1 from month to convert it to system ( January is 0!)
        // console.log("expirationDate = " + expirationDate)
        expiDate = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0);
        // console.log("new expiDate = " + expiDate)
        }
    }

    if (type == 'Month') {

        expiDate = addMonths (expiDate , 1);
        //console.log("monthExpiDate = " + expiDate)
    }
    else {
        
        expiDate = addMonths (expiDate , 12);
        //console.log("yearExpiDate = " + expiDate)

    }
    // add 1 to month to convert it to general ( January is 1!)
    var tempMonth = expiDate.getMonth();    
    var tempMonth = tempMonth + 1;
    

    date = expiDate.getDate() + '-' + tempMonth + '-' + expiDate.getFullYear();
    return date;
}

const INITIAL_STATE = {
    redirection: false,
    isYearActive: false,
    signedOut: false,
    token: ''
}

class MobilePaymentOffer extends Component {

    constructor(props) {

        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {
        const { token } = this.props;
        //console.log("LOOOOOOOOOOOOOOOOOOL" + token)
        setTimeout(() => {
            this.setState({ token: token });
        }, 100);

        getUserInfo(token).then((res) => {
            this.props.getUserData(token);
            this.props.addTokenToredux(token);
        })
        console.log(this.state.token)
        //  this.props.emptyMobileUserProfileWithItem();
        //    this.props.addTokenToredux(token);
        //   this.props.getUserData(token);
        const { userPaymentProfile } = this.props;
        //console.log("RRRRRRRRRRRRRRRRRRRRR")
        //console.log(userPaymentProfile)
        this.props.fetchAllPaymentPackage();
    }

    handlePay = (cash, paymentType, expirationDate, e) => {
        const { token } = this.props;

        getUserInfo(token).then((res) => {
            this.props.getUserData(token);
            this.props.addTokenToredux(token);
        })
        //console.log(cash, paymentType, expirationDate)
        this.props.addPaymentData(cash, paymentType, expirationDate, true);

        let oldexpirationDate = this.props.match.params.expirationDate;
        this.props.addSeriesIDtoRedirectionPage("MobilePaymentOffer", oldexpirationDate);

        this.setState({ redirection: true })

    }

    activetheYear = () => this.setState({ isYearActive: true })


    deactivetheYear = () => this.setState({ isYearActive: false })

    signOut = (e) => {
        firebase.auth().signOut()
        this.setState({ signedOut: true })
        console.log("SignOut")
        e.preventDefault();
    }

    render() {


        // redirect to login if not user
        const { userPaymentProfile } = this.props;

        //console.log(userPaymentProfile)

        if (userPaymentProfile.user_role !== undefined) {
            if (userPaymentProfile.user_role.role !== "USER") {
                firebase.auth().signOut()
                return (<Redirect to="/MobilePaymentLogin"></Redirect>)
            }
        }

        if (this.state.signedOut === true) {
            return (<Redirect to="/MobilePaymentLogin" ></Redirect>)
        }
        const { isYearActive } = this.state;
        const { redirection } = this.state;

        if (redirection == true) {

            return (<Redirect to="/MobilePaymentStripeCheckoutParent" ></Redirect>)

        }
        const { autoRenewel } = this.props;

        let expirationDate = autoRenewel.expirationDate;
        //console.log("expirationDate = " + autoRenewel.expirationDate)
        let calcExpirationDateMonth = expirationDateFormate(today, 'Month', expirationDate);
        let calcExpirationDateYear = expirationDateFormate(today, 'Year', expirationDate);


        const { mobilePaymentPackage } = this.props;

        const { error, loading } = this.props;

        let yearContent = ''
        let monthContent = ''
        let monthActive = ''
        let yearActive = ''

        let paymentPackageList = null
        let packageType = ''


        if (loading === false &&
            error === '') {
            paymentPackageList = mobilePaymentPackage.length ? (
                mobilePaymentPackage.map(mobilePaymentItem => {


                    if (mobilePaymentItem.packageType
                        == 'Monthly') {

                        monthContent = (
                            <div id="tab02" className="tab-contents">

                                <div className="  card-body">
                                    <p className="font">
                                        استمتع بمحتوى جيل لمدة شهر كامل لثلاثة أطفال
                                    </p>

                                    <div className="  div-box mb-3">
                                        <div className="  row">
                                            <div className="  col-12 text-center">
                                                <div className=" font special-offer p-2 mb-4">
                                                    خصم لأول 5000 مشترك من مصر
                                                    </div>
                                                <div className="  Pkg-price">
                                                    <div className=" font small-font text-right">
                                                        سعر الباقة
                                                        </div>
                                                    <div className=" font price-value h1 m-0 eng-lang">
                                                        ${mobilePaymentItem.discountPrice}
                                                    </div>
                                                    <p className=" font small-font text-center">
                                                        <span className="font">بدلا من</span>
                                                        <span
                                                            className="font  small-font eng-lang h5">
                                                            ${mobilePaymentItem.regularPrice}
                                                        </span>
                                                    </p>
                                                </div>
                                                <p className=" font Pkg-hint small-font p-2">
                                                    إضافة حتى 3 أطفال<br />
                                                    محتوى تربوي ممتع<br />
                                                    تقارير تفصيلية مستقلة لكل طفل<br />
                                                    أنشطة تربوية بعد كل حلقة<br />
                                                    الاستفادة بالقسم التربوي للآباء (جسور)
                                                    </p>
                                                <p className="  danger-color small-font m-0">
                                                    <span className="font">تنتهي في</span>
                                                    <span className=" font danger-color eng-lang">
                                                        {calcExpirationDateMonth}
                                                    </span>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        className=" font btn btn-lg btn-primary btn-block btn-signin mt-2"
                                        type="submit" onClick={this.handlePay.bind(this,
                                            mobilePaymentItem.discountPrice,
                                            mobilePaymentItem.packageType,
                                            calcExpirationDateMonth)}>
                                        اشترك الآن
                                            </button>


                                </div>
                            </div>

                        )
                    }

                    else if (mobilePaymentItem.packageType
                        == 'Yearly') {

                        yearContent = (
                            <div id="tab01" className="tab-contents">

                                <div className="  card-body">
                                    <p className="font">
                                        استمتع بكل محتوى جيل لمدة عام كامل لثلاثة أطفال
                                    </p>
                                    <div className="  div-box mb-3">
                                        <div className="  row">
                                            <div className="  col-12 text-center">
                                                <div className=" font special-offer p-2 mb-4">
                                                    خصم لأول 5000 مشترك من مصر
                                                    </div>
                                                <div className="  Pkg-price">
                                                    <div className=" font small-font text-right">
                                                        سعر الباقة
                                                        </div>
                                                    <div className=" font price-value h1 m-0 eng-lang">
                                                        ${mobilePaymentItem.discountPrice}
                                                    </div>
                                                    <p className="font  small-font text-center">
                                                        <span className="font">بدلا من</span>
                                                        <span
                                                            className=" font small-font  eng-lang h5">
                                                            ${mobilePaymentItem.regularPrice}
                                                        </span>
                                                    </p>
                                                </div>
                                                <p className=" font Pkg-hint small-font p-2">
                                                    إضافة حتى 3 أطفال<br />
                                                    محتوى تربوي ممتع<br />
                                                    تقارير تفصيلية مستقلة لكل طفل<br />
                                                    أنشطة تربوية بعد كل حلقة<br />
                                                    الاستفادة بالقسم التربوي للآباء (جسور)
                                                </p>
                                                <p className=" font danger-color small-font m-0">
                                                    <span className="font">تنتهي في</span>
                                                    <span className=" font danger-color eng-lang">
                                                        {calcExpirationDateYear}
                                                    </span>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        className=" font btn btn-lg btn-primary btn-block btn-signin mt-2"
                                        type="submit" onClick={this.handlePay.bind(this,
                                            mobilePaymentItem.discountPrice,
                                            mobilePaymentItem.packageType,
                                            calcExpirationDateYear)}>
                                        اشترك الآن
                                            </button>


                                </div>
                            </div>

                        )
                    }

                }
                )
            )
                : (
                    <div className="center">No Payment Package </div>
                )

        }

        yearActive = (
            <div className="tabs card card-container Current-Package mb-4 ">

                <div className="tab-button-outer">
                    <span>
                        <br />
                        <Link
                            to={{ pathname: "/MobilePaymentLogin" }}>
                            <a className=" float-left language-btn logout-btn" style={{ marginLeft: "30px" }}
                                onClick={this.signOut}>
                                <i className="fas fa-chevron-left">
                                    <img className="logout" src={logout} alt="logout" />
                                </i>
                            </a>
                        </Link>
                        <br /> <br />
                    </span>
                    <ul id="tab-button">
                        <li >
                            <a>
                                <div className="  card-header profile-name-card"
                                    onClick={this.deactivetheYear}>
                                    <div className=" font h4">
                                        الباقة السنوية
                            </div>
                                </div>
                            </a>
                        </li>

                        <li className="is-active">
                            <a>
                                <div className="card-header profile-name-card"
                                    onClick={this.activetheYear}>
                                    <div className=" font h4">
                                        الباقة الشهرية
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                {monthContent}
            </div>)

        monthActive = (
            <div className="tabs card card-container Current-Package mb-4 ">

                <div className="tab-button-outer">
                    <span>
                        <br />
                        <Link
                            to={{ pathname: "/MobilePaymentLogin" }}>
                            <a className=" float-left language-btn logout-btn" style={{ marginLeft: "30px" }}
                                onClick={this.signOut}>
                                <i className="fas fa-chevron-left">
                                    <img className="logout" src={logout} alt="logout" />
                                </i>
                            </a>
                        </Link>
                        <br /> <br />
                    </span>
                    <ul id="tab-button">
                        <li className="is-active">
                            <a>
                                <div className="  card-header profile-name-card"
                                    onClick={this.deactivetheYear}>
                                    <div className=" font h4">
                                        الباقة السنوية
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li >
                            <a>
                                <div className="  card-header profile-name-card"
                                    onClick={this.activetheYear}>
                                    <div className=" font h4">
                                        الباقة الشهرية
                            </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                {yearContent}
            </div>)

        let content = '';
        if (loading === true) {
            content = (<Loader />)
        }
        else {
            if (isYearActive == true) {
                content =
                    (<span>{yearActive}</span>)
            }
            else {
                content = (
                    <span>{monthActive}</span>
                )
            }
        }




        return (
            <div className="main-payment" >

                <div className="shape-container">
                    <div className=" bg-img">
                    </div>
                </div>

                <div className="container leftToRight">
                    <div className="pay-box">
                        <div className="  main-logo text-center h4">
                            <img className="  logo" alt="logo" src={logo} />
                            <p className="  small-font main-color ">
                                بوابة الدفع لجيل
                        </p>
                        </div>
                        <div className="  h5 text-center mb-4 mt-4 font">
                            من فضلك قم بالاشتراك فى احدى الخطط التالية
                    </div>

                        <div className="  row justify-content-md-center Plans-box">

                            {content}
                        </div>
                    </div>

                    <MobilePaymentFooter />
                </div>


            </div>
        )
    }
}



MobilePaymentOffer.propTypes = {
    fetchAllPaymentPackage: propTypes.func.isRequired,
    addPaymentData: propTypes.func.isRequired,
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    getUserData: propTypes.func.isRequired,
    emptyMobileUserProfile: propTypes.func.isRequired,
    addTokenToredux: propTypes.func.isRequired


}
const mapStateToProps = state => ({
    mobilePaymentPackage: state.mobilePaymentPackage.items,
    loading: state.mobilePaymentPackage.loading,
    error: state.mobilePaymentPackage.getting_all_payment_package_data_error,
    sucess: state.mobilePaymentPackage.getting_all_payment_package_data_sucessfully,
    autoRenewel: state.mobilePaymentPackage.autoRenewel,
    userPaymentProfile: state.userPaymentProfile.item,
    token: state.user.token


});

export default withRouter
    (connect
        (mapStateToProps,
            {
                fetchAllPaymentPackage,
                addPaymentData,
                addSeriesIDtoRedirectionPage,
                getUserData,
                addTokenToredux,
                emptyMobileUserProfile,
                emptyMobileUserProfileWithItem
            }
        )
        (MobilePaymentOffer)
    );
