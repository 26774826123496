import {
    ADD_EVALUATION_CATEGORY_ID, 
    EMPTY_EVALUATION_CATEGORY_ID
} from '../Actions/Types'

const initialState = {
    item: {},
}

export default function (state = initialState, action) {
    switch (action.type) {

      
        case ADD_EVALUATION_CATEGORY_ID:
            return {
                ...state,
                item: action.payload,                
            }

        case EMPTY_EVALUATION_CATEGORY_ID:
            return {
                ...state,
                item: ''
            }

        default:
            return state

    }

}