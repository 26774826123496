import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { getAllKidsReport,emptyReport } from '../../Actions/ReportingActions'
import { DateInput } from 'semantic-ui-calendar-react';
import Loader from '../Layout/Loader'
import ReportList from "./ReportList";


class DetailsReport extends Component {

    state = {
        startDate: "",
        endDate: ""
    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleDateChange = (e, { name, value }) => {
        let s = "";
        let end = "";
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
            if (name === "startDate")
            {
                if (this.state.endDate)
               
               { this.props.getAllKidsReport(value,this.state.endDate)}
            }
             if (name ==="endDate")
            {
                if (this.state.startDate)
                { this.props.getAllKidsReport(this.state.startDate,value)}

            }
        }
        
    }

    componentDidMount()
    {
        this.props.emptyReport();
    }

    render() {
        console.log(this.state)
        const {loading,kidsReport,error_loading_kids_report} = this.props
      
        return (
            <div>
                <div id="tab03" className="tab-contents details-table">
                    <form onSubmit={(e)=> e.preventDefault()}>
                        <div className="form-row mt-2">
                            {/* <div className="col-md-4 col-12 mb-3">
                                <label className="small-label" for="">Search</label>
                                <div className="search-form">
                                    <div className="form-group w-100">
                                        <input type="text" className="form-control" name="search" id="search" placeholder="search" />
                                        <i className="fas fa-search"><FontAwesomeIcon icon="search" /></i>

                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-4 col-12 mb-3">
                                <label className="small-label" for="">Start Date</label>
                                <DateInput
                                    name="startDate"
                                    closeOnMouseLeave

                                    value={this.state.startDate}
                                    placeholder="start date"
                                    onChange={this.handleDateChange}
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                />
                            </div>
                            <div className="col-md-4 col-12 mb-3">
                                <label className="small-label" for="">End Date</label>
                                <DateInput
                                    name="endDate"
                                    closeOnMouseLeave

                                    value={this.state.endDate}
                                    placeholder="end date"
                                    onChange={this.handleDateChange}
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                />                 
                             </div>
                        </div>
                    </form>
                    
                    <div className="responsive-table ">
                       

                        <ReportList kidsReport={kidsReport} error={error_loading_kids_report} loading={loading}/>
                    </div>
         


                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({

    kidsReport: state.kidsReport.items,
    error_loading_kids_report: state.kidsReport.error_loading_kids_report,
    loading:state.kidsReport.loading


})
export default connect(mapStateToProps, { getAllKidsReport,emptyReport })(DetailsReport);