export default
[
    {
        key: 'HOME',
        text: 'Home Page',
        value: 'HOME'
    },
    {
        key: 'SERIES',
        text: 'Series Page',
        value: 'SERIES'
    },
]