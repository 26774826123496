import React, { Component } from 'react'
import { withFirebase } from '../../config';
import { Input, Button, Modal, TextArea, Dropdown } from 'semantic-ui-react';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import {
    saveSpecialCountriesPrice, emptySpeciialCountryOffer
} from '../../Actions/MobileSpecialCountriesAction';

import payment_package_type_list from '../ConstData/PaymentAndVendorCountriesList'

const INITIAL_STATE = {

    showModal: '',
    /////////// special Country data
    country: '',
    description: '',
    weeklyDiscountPrice: '',
    monthlyDiscountPrice: '',
    yearlyDiscountPrice: '',
    weeklyBasicPrice: '',
    monthlyBasicPrice: '',
    yearlyBasicPrice: '',
    ////////// validation
    // formErrors: { error: true, country: '', description: '', monthlyDiscountPrice: '', yearlyDiscountPrice: '' },

};

class AddSpecialCountryPrice extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    AddSpecialCountryPrice(country, description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice) {
        this.props.saveSpecialCountriesPrice(country, description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice);
    }

    handleCountryChange = (e, { value }) => {
        this.setState({ country: value });
    };

    handleChange = e => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    };
    // handleChange = (e) => {
    //     let validation = this.state.formErrors;
    //     let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    //     this.setState({ [e.target.name]: checkSpaces });
    //     setTimeout(() => {
    //         if (this.state.monthlyDiscountPrice === 0 || this.state.monthlyDiscountPrice ==='' ||
    //         this.state.monthlyDiscountPrice === ' ') {
    //             validation.monthlyDiscountPrice = "MonthlyDiscountPrice can not be empty or = 0";
    //             validation.error = true;
    //             this.setState({ formErrors: validation });
    //         }
    //         else {
    //             validation.monthlyDiscountPrice = '';
    //             this.setState({ formErrors: validation });
    //         }
    //         if (this.state.yearlyDiscountPrice === 0  || this.state.yearlyDiscountPrice ==='' ||
    //         this.state.yearlyDiscountPrice === ' ') {
    //             validation.yearlyDiscountPrice = "Yearly Discount Price can not be empty or = 0";
    //             validation.error = true;
    //             this.setState({ formErrors: validation });
    //         }
    //         else {
    //             validation.yearlyDiscountPrice = '';
    //             this.setState({ formErrors: validation });
    //         }
    //         if (this.state.monthlyDiscountPrice !== 0 &&
    //             this.state.monthlyDiscountPrice !== '' &&
    //             this.state.monthlyDiscountPrice !== ' ' &&

    //             this.state.yearlyDiscountPrice !== 0 &&
    //             this.state.yearlyDiscountPrice !== '' &&
    //             this.state.yearlyDiscountPrice !== ' '
    //             ) {

    //             validation.monthlyDiscountPrice = '';
    //             validation.yearlyDiscountPrice = '';
    //             validation.error = false;
    //             this.setState({ formErrors: validation });
    //         }
    //     }, 0);
    // }

    handleSubmit = (e) => {

        const { country, description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice } = this.state;

        this.AddSpecialCountryPrice(country, description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice);

        e.preventDefault();
        this.setState({
            country: '',
            description: '',
            weeklyDiscountPrice: '',
            monthlyDiscountPrice: '',
            yearlyDiscountPrice: '',
            weeklyBasicPrice: '',
            monthlyBasicPrice: '',
            yearlyBasicPrice: '',
        })

    }

    closeModal = (e) => {
        this.setState({
            showModal: false,
            country: '',
            description: '',
            weeklyDiscountPrice: '',
            monthlyDiscountPrice: '',
            yearlyDiscountPrice: '',
            weeklyBasicPrice: '',
            monthlyBasicPrice: '',
            yearlyBasicPrice: '',
            // formErrors: {
            //     country: '',
            //     description: '',
            //     monthlyDiscountPrice: '',
            //     yearlyDiscountPrice: '',
            //     error: true
            // }
        })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
    }

    render() {

        const { country, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice } = this.state;

        const { error } = this.props;
        const { success } = this.props;
        if (success === false) {
            alert("Error " + error)
            this.props.emptySpeciialCountryOffer();
        }
        else if (success === true) {
            this.props.emptySpeciialCountryOffer();
            alert("Country with special offer added Successfully")
            this.closeModal();
        }


        const isInvalidSeries =
            country === '' || weeklyDiscountPrice === 0 || weeklyDiscountPrice === '' ||
            monthlyDiscountPrice === 0 || monthlyDiscountPrice === '' ||
            yearlyDiscountPrice === 0 || yearlyDiscountPrice === '' || weeklyBasicPrice === 0 || weeklyBasicPrice === '' ||
            monthlyBasicPrice === 0 || monthlyBasicPrice === '' ||
            yearlyBasicPrice === 0 || yearlyBasicPrice === '';

        return (
            <Modal id="modal"
                trigger={

                    <Button id='contentSettingbutton'
                        onClick={this.openModal}> +</Button>}
                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Add New Country With Special Offer</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">

                                    <div className="col">

                                        <div className="ui form">


                                            <label htmlFor="episode_series_id"
                                                className="label">
                                                <span className="required_input">*</span>
                                                                Select Country </label><br />

                                            <Dropdown fluid search selection
                                                placeholder='Select Country'
                                                id="episode_series_id"
                                                onChange={this.handleCountryChange}
                                                options={payment_package_type_list}
                                                value={this.state.country}
                                            />
                                            <br />


                                            <div className="two fields">
                                                <div className="field">
                                                    <label htmlFor="weeklyDiscountPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Weekly Discount Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Weekly Discount Price"
                                                        name="weeklyDiscountPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.weeklyDiscountPrice} /><br />
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="monthlyDiscountPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Monthly Discount Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Monthly Discount Price"
                                                        name="monthlyDiscountPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.monthlyDiscountPrice} /><br />
                                                    {/* <p style={{ color: "red" }}>{this.state.formErrors.monthlyDiscountPrice} </p> */}
                                                </div>
                                                <div className="field">

                                                    <label htmlFor="yearlyDiscountPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Yearly Discount Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Yearly Discount Price"
                                                        name="yearlyDiscountPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.yearlyDiscountPrice} /><br />
                                                    {/* <p style={{ color: "red" }}>{this.state.formErrors.yearlyDiscountPrice} </p> */}

                                                </div>
                                            </div>

                                            <div className="two fields">
                                                <div className="field">
                                                    <label htmlFor="weeklyBasicPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Weekly Basic Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Weekly basic Price"
                                                        name="weeklyBasicPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.weeklyBasicPrice} /><br />
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="monthlyBasicPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Monthly Basic Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Monthly Basic Price"
                                                        name="monthlyBasicPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.monthlyBasicPrice} /><br />
                                                    {/* <p style={{ color: "red" }}>{this.state.formErrors.monthlyBasicPrice} </p> */}
                                                </div>
                                                <div className="field">

                                                    <label htmlFor="yearlyBasicPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Yearly Basic Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Yearly Basic Price"
                                                        name="yearlyBasicPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.yearlyBasicPrice} /><br />
                                                    {/* <p style={{ color: "red" }}>{this.state.formErrors.yearlyBasicPrice} </p> */}

                                                </div>
                                            </div>

                                            <label htmlFor="description" className="label">Dicount Description</label>
                                            <TextArea fluid rows="3" name="description" id="episode_description"
                                                onChange={this.handleChange} value={this.state.description} />
                                            <br /><br />

                                        </div>
                                    </div>

                                </div>

                                <div className="input-field">

                                    <Button onClick={this.handleSubmit} disabled={isInvalidSeries} id="saveButton" >
                                        Add
                                    </Button>


                                </div>
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

AddSpecialCountryPrice.propTypes = {
    saveSpecialCountriesPrice: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    specialCountriesPrice: state.specialCountriesPrice.item,
    showModal: state.specialCountriesPrice.showModal,
    error: state.specialCountriesPrice.add_special_countries_price_error,
    success: state.specialCountriesPrice.add_special_countries_price_sucessfully
});


export default withFirebase(
    connect(
        mapStateToProps,
        { saveSpecialCountriesPrice, emptySpeciialCountryOffer }
    )
        (AddSpecialCountryPrice)
);
