import React, { Component } from 'react'
import {  Modal } from 'semantic-ui-react';
/*Component */
import Loader from '../Layout/Loader'
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

/*Actions*/
import { emptyError } from '../../Actions/Empty'
import { getAllBlogs } from '../../Actions/BlogActions'

const INITIAL_STATE = {

    showModal: '',
    /////////// Blog

    checkedItems: new Map()
};

class EvaluationBlogs extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        this.props.getAllBlogs();
        this.props.emptyError();
    }

    handleCheckbuttonChange = (id, e) => {

        let { checkedItems } = this.state;

        const isChecked = e.target.checked;
        // console.log(" isChecked = " + isChecked);

        let checkeditem = { "id": id, "isChecked": isChecked }

        var temCheckded = new Map([...checkedItems]);
        if (temCheckded.has(id)) {
            if (isChecked == false) {
                temCheckded.delete(id);
            }
        } else {
            temCheckded.set(id, checkeditem);
        }
        this.setState({ checkedItems: temCheckded })

        this.props.getListOfCheckedBlogsCallBack(temCheckded);

        // console.log(this.state.checkedItems);
    }


    getCheckedState(id) {
        //if Msp Contains my uid
        if (this.state.checkedItems.has(id)) {
            // console.log("found here" + uid);
            return true;
        }
        //Cannot find it
        return false;
    }


    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }
    closeModal = (e) => {
        this.setState({ showModal: false })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
    }


    render() {
        const { blogs } = this.props;
        let blogsList = null

        const { blogError } = this.props;


        if (this.props.blogsStatue === true) {
            blogsList = (<Loader />)
        }
        else if (this.props.blogsStatue === false && this.props.blogError === '') {
            blogsList =
                blogs.map(blog => {
                    let checkState = this.getCheckedState(blog.id);
                    return (
                        <div className="mt-3" key={blog.id}>
                            <div className="article-box mb-3">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-2">
                                                <div className="c-checkbox">
                                                    <div className="custom-control custom-checkbox ">
                                                        <input type="checkbox" className="custom-control-input" id="customControlAutosizing-1"
                                                            id={blog.id} checked={checkState}
                                                            onChange={this.handleCheckbuttonChange.bind(this, blog.id)}
                                                        />
                                                        <label className="custom-control-label" for="customControlAutosizing-1"
                                                            for={blog.id}  ></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-10">
                                                <img className="modal-img" src={blog.image} alt="pic" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="row pr-3">
                                            <div className="col-md-12">
                                                <div className="row d-block">
                                                    <div className="h4 mb-0" >{blog.title}</div>
                                                    <p className="small-font mb-0">{blog.createdDate}</p>
                                                    <p className="mt-2">
                                                        {blog.description}
                                                    </p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    )
                })
        }
        else {
            blogsList = (<div><br /><center><div>{blogError}</div></center><br /></div>)
        }



        return (
            <Modal id="modal"
                trigger={
                    <button className="btn btn-lg btn-primary btn-add mb-3"
                        type="submit" onClick={this.openModal} >Related Blogs</button>}
                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Related articles</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <div className="modal-body">

                                {blogsList}

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-lg btn-primary btn-nxt btn-block-sm" onClick={this.closeModal}>Done</button>
                            </div>

                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

const mapStateToProps = state => ({
    blogs: state.blogs.items,
    blogsStatue: state.blogs.loading,
    blogError: state.blogs.error_loading_blogs
});
export default withRouter(connect(mapStateToProps, { getAllBlogs, emptyError })(EvaluationBlogs));
