import {
    LOAD_All_DURATION_OF_USE, FETCH_All_DURATION_OF_USE, ERROR_ALL_DURATION_OF_USE,
    LOAD_DURATION_OF_USE, 
    ERROR_ADDING_DURATION_OF_USE, ADD_DURATION_OF_USE ,
     DELETING_DURATION_OF_USE, ERROR_DELETING_DURATION_OF_USE,
     EMPTY_DURATION
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    loading: '',

    getting_all_Duration_error: '',
    getting_all_Duration_sucessfully: '',

    adding_Duration_error: '',
    adding_Duration_sucessfully: "",

    deleting_Duration_error: '',
    deleting_Duration_sucessfully: "",
}

export default function (state = initialState, action) {
    switch (action.type) {


        case FETCH_All_DURATION_OF_USE:
            return {
                ...state,
                items: action.payload,
                getting_all_Duration_error: '',
                getting_all_Duration_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_DURATION_OF_USE:
            return {

                ...state,
                getting_all_Duration_error: action.payload,
                loading: false
            }

        case LOAD_All_DURATION_OF_USE:
                return {
                    ...state,
                    loading: true
                }
    
        case LOAD_DURATION_OF_USE:
            return {
                ...state,
                loading: true
            }

        case ADD_DURATION_OF_USE:
            return {
                ...state,
                items: action.payload,
                adding_Duration_error: '',
                adding_Duration_sucessfully: true,
                loading: false
            }

        case ERROR_ADDING_DURATION_OF_USE:
            return {

                ...state,
                adding_Duration_error: action.payload,
                adding_Duration_sucessfully: false,
                loading: false
            }

        case DELETING_DURATION_OF_USE:
            return {

                ...state,
                item: action.payload,
                deleting_Duration_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_DURATION_OF_USE:
            return {

                ...state,
                deleting_Duration_error: action.payload,
                deleting_Duration_sucessfully : false,
                loading: false
            }

        case EMPTY_DURATION:
            return {
                ...state,
                adding_Duration_error: action.payload,
                adding_Duration_sucessfully: action.payload,
                deleting_Duration_error: action.payload,
                deleting_Duration_sucessfully: action.payload
            }

        default:
            return state

    }

}