import {
  FETCH_PRE_SIGN_URL,
  LOAD_PRE_SIGN_URL,
  ERROR_PRE_SIGN_URL,
  CLEAR_PRE_SIGN_URL_DATA,
  GENERATE_OTP,
  GET_VIDEO_STATUS,
  CLEAR_ALL,
  RESET_VDOCIPHER_DATA
} from "../Actions/Types";

const initialState = {
  inAppNotification: {
    uploadInfo: {},
    otp: {},
    status: "",
    loading: false,
    isLoaded: false,
  },
  videoWithMusic: {
    uploadInfo: {},
    otp: {},
    status: "",
    loading: false,
    isLoaded: false,
  },
  videoWithoutMusic: {
    uploadInfo: {},
    otp: {},
    status: "",
    loading: false,
    isLoaded: false,
  },
  introWithMusic: {
    uploadInfo: {},
    otp: {},
    status: "",
    loading: false,
    isLoaded: false,
  },
  introWithoutMusic: {
    uploadInfo: {},
    otp: {},
    status: "",
    loading: false,
    isLoaded: false,
  },
  promoWithMusic: {
    uploadInfo: {},
    otp: {},
    status: "",
    loading: false,
    isLoaded: false,
  },
  promoWithoutMusic: {
    uploadInfo: {},
    otp: {},
    status: "",
    loading: false,
    isLoaded: false,
  },
};

export default function (state = initialState, action) {
  const { type, payload, id } = action;
  switch (type) {
    case LOAD_PRE_SIGN_URL:
      switch (id) {
        case "IntroWithMusic":
          return {
            ...state,
            introWithMusic: {
              ...state.introWithMusic,
              loading: true,
              isLoaded: false,
            },
          };
        case "IntroWithoutMusic":
          return {
            ...state,
            introWithoutMusic: {
              ...state.introWithoutMusic,
              loading: true,
              isLoaded: false,
            },
          };
        case "PromoWithMusic":
          return {
            ...state,
            promoWithMusic: {
              ...state.promoWithMusic,
              loading: true,
              isLoaded: false,
            },
          };
        case "PromoWithoutMusic":
          return {
            ...state,
            promoWithoutMusic: {
              ...state.promoWithoutMusic,
              loading: true,
              isLoaded: false,
            },
          };
        case "inAppNotification":
          return {
            ...state,
            inAppNotification: {
              ...state.inAppNotification,
              loading: true,
              isLoaded: false,
            },
          };
        case "VideoWithMusic":
          return {
            ...state,
            videoWithMusic: {
              ...state.videoWithMusic,
              loading: true,
              isLoaded: false,
            },
          };
        case "VideoWithoutMusic":
          return {
            ...state,
            videoWithoutMusic: {
              ...state.videoWithoutMusic,
              loading: true,
              isLoaded: false,
            },
          };
      }

    case FETCH_PRE_SIGN_URL:
      switch (id) {
        case "IntroWithMusic":
          return {
            ...state,
            introWithMusic: {
              ...state.introWithMusic,
              uploadInfo: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "IntroWithoutMusic":
          return {
            ...state,
            introWithoutMusic: {
              ...state.introWithoutMusic,
              uploadInfo: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "PromoWithMusic":
          return {
            ...state,
            promoWithMusic: {
              ...state.promoWithMusic,
              uploadInfo: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "PromoWithoutMusic":
          return {
            ...state,
            promoWithoutMusic: {
              ...state.promoWithoutMusic,
              uploadInfo: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "inAppNotification":
          return {
            ...state,
            inAppNotification: {
              ...state.inAppNotification,
              uploadInfo: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "VideoWithMusic":
          return {
            ...state,
            videoWithMusic: {
              ...state.videoWithMusic,
              uploadInfo: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "VideoWithoutMusic":
          return {
            ...state,
            videoWithoutMusic: {
              ...state.videoWithoutMusic,
              uploadInfo: payload,
              isLoaded: true,
              loading: false,
            },
          };
      }

    case GET_VIDEO_STATUS:
      switch (id) {
        case "IntroWithMusic":
          return {
            ...state,
            introWithMusic: {
              ...state.introWithMusic,
              status: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "IntroWithoutMusic":
          return {
            ...state,
            introWithoutMusic: {
              ...state.introWithoutMusic,
              status: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "PromoWithMusic":
          return {
            ...state,
            promoWithMusic: {
              ...state.promoWithMusic,
              status: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "PromoWithoutMusic":
          return {
            ...state,
            promoWithoutMusic: {
              ...state.promoWithoutMusic,
              status: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "inAppNotification":
          return {
            ...state,
            inAppNotification: {
              ...state.inAppNotification,
              status: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "VideoWithMusic":
          return {
            ...state,
            videoWithMusic: {
              ...state.videoWithMusic,
              status: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "VideoWithoutMusic":
          return {
            ...state,
            videoWithoutMusic: {
              ...state.videoWithoutMusic,
              status: payload,
              isLoaded: true,
              loading: false,
            },
          };
      }

    case GENERATE_OTP:
      switch (id) {
        case "IntroWithMusic":
          return {
            introWithMusic: {
              ...state.introWithMusic,
              otp: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "IntroWithoutMusic":
          return {
            introWithoutMusic: {
              ...state.introWithoutMusic,
              otp: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "PromoWithMusic":
          return {
            promoWithMusic: {
              ...state.promoWithMusic,
              otp: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "PromoWithoutMusic":
          return {
            promoWithoutMusic: {
              ...state.promoWithoutMusic,
              otp: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "inAppNotification":
          return {
            inAppNotification: {
              ...state.inAppNotification,
              otp: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "VideoWithMusic":
          return {
            videoWithMusic: {
              ...state.videoWithMusic,
              otp: payload,
              isLoaded: true,
              loading: false,
            },
          };
        case "VideoWithoutMusic":
          return {
            videoWithoutMusic: {
              ...state.videoWithoutMusic,
              otp: payload,
              isLoaded: true,
              loading: false,
            },
          };
      }
    case ERROR_PRE_SIGN_URL:
      switch (id) {
        case "IntroWithMusic":
          return {
            ...state,
            introWithMusic: {
              ...state.introWithMusic,
              loading: false,
              isLoaded: false,
            },
          };
        case "IntroWithoutMusic":
          return {
            ...state,
            introWithoutMusic: {
              ...state.introWithoutMusic,
              loading: false,
              isLoaded: false,
            },
          };
        case "PromoWithMusic":
          return {
            ...state,
            promoWithMusic: {
              ...state.promoWithMusic,
              loading: false,
              isLoaded: false,
            },
          };
        case "PromoWithoutMusic":
          return {
            ...state,
            promoWithoutMusic: {
              ...state.promoWithoutMusic,
              loading: false,
              isLoaded: false,
            },
          };
        case "inAppNotification":
          return {
            ...state,
            inAppNotification: {
              ...state.inAppNotification,
              loading: false,
              isLoaded: false,
            },
          };
        case "VideoWithMusic":
          return {
            ...state,
            videoWithMusic: {
              ...state.videoWithMusic,
              loading: false,
              isLoaded: false,
            },
          };
        case "VideoWithoutMusic":
          return {
            ...state,
            videoWithoutMusic: {
              ...state.videoWithoutMusic,
              loading: false,
              isLoaded: false,
            },
          };
      }

    case CLEAR_PRE_SIGN_URL_DATA, RESET_VDOCIPHER_DATA:
      switch (id) {
        case "IntroWithMusic":
          return {
            ...state,
            introWithMusic: {
              ...state.introWithMusic,
              uploadInfo: {},
              otp: {},
              status: "",
              loading: false,
              isLoaded: false,
            },
          };
        case "IntroWithoutMusic":
          return {
            ...state,
            introWithoutMusic: {
              ...state.introWithoutMusic,
              uploadInfo: {},
              otp: {},
              status: "",
              loading: false,
              isLoaded: false,
            },
          };
        case "PromoWithMusic":
          return {
            ...state,
            promoWithMusic: {
              ...state.promoWithMusic,
              uploadInfo: {},
              otp: {},
              status: "",
              loading: false,
              isLoaded: false,
            },
          };
        case "PromoWithoutMusic":
          return {
            ...state,
            promoWithoutMusic: {
              ...state.promoWithoutMusic,
              uploadInfo: {},
              otp: {},
              status: "",
              loading: false,
              isLoaded: false,
            },
          };
        case "inAppNotification":
          return {
            ...state,
            inAppNotification: {
              ...state.inAppNotification,
              uploadInfo: {},
              otp: {},
              status: "",
              loading: false,
              isLoaded: false,
            },
          };
        case "VideoWithMusic":
          return {
            ...state,
            videoWithMusic: {
              ...state.videoWithMusic,
              uploadInfo: {},
              otp: {},
              status: "",
              loading: false,
              isLoaded: false,
            },
          };
        case "VideoWithoutMusic":
          return {
            ...state,
            videoWithoutMusic: {
              ...state.videoWithoutMusic,
              uploadInfo: {},
              otp: {},
              status: "",
              loading: false,
              isLoaded: false,
            },
          };
      }

    case CLEAR_ALL:
      return initialState;

    default:
      return state;
  }
}
