import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import propTypes from 'prop-types';
/*Components */
import VendorTabel from './VendorTabel';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import VendorManagement from '../Layout/VendorManagement';
/* Routes */
import { ADD_VENDOR } from '../../routes';

export class Vendor extends Component { 
    render() {
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <VendorManagement />
                                            <div className="box-container">

                                                {/* <!--  start status bar --> */}
                                                {/* <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to="/">Home</Link><span> > </span>
                                                    </span>
                                                    <span>Vendor</span>

                                                </div> */}
                                                {/* <!--  end status bar --> */}

                                                <Link to={{ pathname: ADD_VENDOR }}>
                                                    <button type="submit"
                                                        className="seriesButtons">
                                                        + Add New Partner
                                                    </button>
                                                </Link>

                                                <h5 className="main-title mt-2 font-weight-bold">
                                                    Partner</h5>
                                                <div className="card mt-3 p-0">
                                                    <div className="card-body p-4">
                                                        {/* <!-- start form --> */}
                                                        <form className="alluserMng" onSubmit={(e)=> e.preventDefault()}>
                                                            <div className="mt-4 responsive-table">
                                                                {/* Admins Table */}
                                                                <VendorTabel />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    // userPaymentProfile: state.userPaymentProfile.item
});

export default withRouter
    (connect
        (mapStateToProps,
            {})
        (Vendor)
    );
