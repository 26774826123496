import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { Accordion, Icon } from 'semantic-ui-react'

/**Actions */
import { fetchAllTermsAndConditions } from '../../Actions/TermsAndConditionsActions';
/*Components */
import Loader from '../Layout/Loader';
import EditTermsAndconditions from './EditTermsAndconditions'
import DeletTermsAndConditions from './DeletTermsAndConditions'
/*CSS*/
import '../../css/main-style.css';

class TermsAndConditionsCard extends Component {

    state = { activeIndex: 0 }

    componentDidMount() {
        this.props.fetchAllTermsAndConditions();
    }


    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    render() {
        const { activeIndex } = this.state

        const { termsandconditions } = this.props;
        const { loading, error, success } = this.props;
        var counter = 0;
        let termsAndConditionList = null

        if (loading === true) {
            termsAndConditionList = (<Loader />)
        }
        else if (success === true && error === '') {
            termsAndConditionList = termsandconditions.length ? (
                termsandconditions.map(termsandconditionsItem => {

                    counter = counter + 1
                    return (
                        <div key={termsandconditionsItem.id}>

                            <Accordion fluid styled>
                                <Accordion.Title active={activeIndex === counter} index={counter} onClick={this.handleClick}>
                                    <div className="card-header" id="card-header" >
                                        <h4 className="mb-0">

                                            <Icon name='dropdown' />
                                            {termsandconditionsItem.title}
                                            <div className="acor-btn-group">

                                                <EditTermsAndconditions termsandconditionsItem ={termsandconditionsItem}/>
                                                
                                                <DeletTermsAndConditions id ={termsandconditionsItem.id}/>

                                            </div>

                                        </h4>
                                    </div>
                                </Accordion.Title>

                                <Accordion.Content active={activeIndex === counter}>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">
                                            {termsandconditionsItem.text}
                                        </div>
                                    </div>
                                </Accordion.Content>

                            </Accordion>
                        </div>
                    )
                })

            ) : (
                    <div className="center">No Terms And Conditions </div>
                )

        }

        return (
            <div className=" mt-3 p-0">
                <div className="card-body p-0">
                    {termsAndConditionList}
                </div>
            </div>

        )
    }
}

TermsAndConditionsCard.propTypes = {
    fetchAllTermsAndConditions: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    termsandconditions: state.termsandconditions.items,
    loading: state.termsandconditions.loading,
    error: state.termsandconditions.getting_all_terms_Conditions_error,
    success: state.termsandconditions.getting_all_terms_Conditions_sucessfully
});

export default withRouter(connect(mapStateToProps, { fetchAllTermsAndConditions })(TermsAndConditionsCard));