import React, { Component } from "react";
import { Link } from "react-router-dom";
import MasterCardLogo from "../../../images/portal/icons/mastercard-logo.svg";
import CompleteProcessForm from "./CompleteProcessForm";
import Subscribe from "./Subscribe";

class CompleteProcess extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
            <div className="d-flex align-items-center">
              <Link className="navbar-brand d-flex align-items-center" to="/">
                <img src={MasterCardLogo} alt="" />
              </Link>
              <div className="complete-process d-none d-sm-block">
                اكمال عملية الدفع
              </div>
            </div>
            <Link className="btn back-btn" type="button" to="/">
              العوده
            </Link>
          </div>
        </div>
        <CompleteProcessForm />
      </>
    );
  }
}

export default CompleteProcess;
