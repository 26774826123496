import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Icon, Label } from 'semantic-ui-react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Snackbar } from '@material-ui/core';

/* Redux */
import { connect } from 'react-redux';
import firebase from "firebase/app";
import { withRouter } from 'react-router-dom'

/* Components */
import Footer from '../Layout/Footer';
import NavBar from '../Layout/NavBar';
import SideMenu from '../Layout/SideMenu';
import Loader from '../Layout/Loader';
import DownloadApps from '../Layout/DownloadApps'

/* Assets */
import avatar from "../../../images/portal/avatars/dad-avatar.png";
import avatar1 from "../../../images/portal/avatars/avatar-1.png";
import avatar2 from "../../../images/portal/avatars/avatar-2.png";
import avatar3 from "../../../images/portal/avatars/avatar-3.png";
import avatar4 from "../../../images/portal/avatars/avatar-4.png";
import avatar5 from "../../../images/portal/avatars/avatar-5.png";
import avatar6 from "../../../images/portal/avatars/avatar-6.png";
import dadImage from "../../../images/portal/avatars/dad.png";
import momImage from "../../../images/portal/avatars/mom.png";
import addImage from "../../../images/portal/add.svg";

import {
  addEditingChildToRedux, removEeditingChildFromRedux,
} from '../../../Actions/CustomerActions';
import { getUserData, getUserPaymentInfo, emptyMobileUserProfileWithItem } from "../../../Actions/MobilePaymentProfileAction";
import { removeSuccessMessage } from '../../../Actions/SuccessMessagesActions';
/** Routes */
import { PORTAL_ACCOUNT , PORTAL_ACCOUNT_PROFILE} from '../../../routes';
// import "../../../css/portal-style.css" ;

const INITIAL_STATE = {
  token: '',
  redirectToEditAccount: false,
  redirectToEditChild: false,
  addNewKid: '',
  checkAddKid: true,
  openSnakBar: false,
  vertical: 'bottom',
  horizontal: 'right',
}

function getDifferenceInYears(date1, date2) {
  var dt1 = new Date(date1);
  var dt2 = new Date(date2);
  var days = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24 * 365));
  return days;
}

function getParentYearOfBirth(date1) {
  var dt1 = new Date(date1);
  return dt1.getFullYear();
}

class CustomerAccountProfile extends Component {

  constructor(props) {
    super(props);
    // import("../../../css/portal-style.css");
    this.state = { ...INITIAL_STATE };
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(token => {
          //this.props.getUserData(token);
          this.setState({ token })
        });
      //this.props.getUserPaymentInfo();
    }

    this.props.history.push(PORTAL_ACCOUNT_PROFILE);

  }

  componentDidMount() {
      this.props.getUserPaymentInfo();
  }

  handleAddingNewKid() {
    const { userPaymentProfile } = this.props;
    if (userPaymentProfile !== null && userPaymentProfile !== undefined && userPaymentProfile !== '') {
      if (userPaymentProfile.children !== null && userPaymentProfile.children !== undefined && userPaymentProfile.children.length >= 3) {
        this.setState({ addNewKid: 'none', checkAddKid: false });
      }
    }
  }

  redirectEditParent = () => {
    this.setState({ redirectToEditAccount: true })
  }

  redirectEditChild = () => {
    this.setState({ redirectToEditChild: true })
  }

  handleSuccessMessages = () => {
    this.props.removeSuccessMessage();
  }

  render() {
    const { userPaymentProfile, userloading, usersucess, usererror } = this.props;
    const { vertical, horizontal } = this.state;
    const { successMessage } = this.props;

    let today = new Date();
    let parent = '';
    let childrenList = '';

    if (usersucess === true) {
      parent = (
        <span>
          <div className="account-image">
            <div className="img-btn reset-btn-style">
              <img src={(userPaymentProfile.image) ? userPaymentProfile.image : (userPaymentProfile.gender === 'male') ? dadImage : momImage} alt="" />
              {/*<div className="image-overlay">تعديل</div>*/}
            </div>
          </div>
          <div className="form-row">
            <div className="col-12 col-md-6">
              <div className="account-details">
                <div className="label">الاسم</div>
                <div className="content">{(userPaymentProfile.name !== null) ? userPaymentProfile.name : this.setState({ redirectToEditAccount: true })}</div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="account-details">
                <div className="label">البريد الالكتروني</div>
                <div className="content">{userPaymentProfile.email}</div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="account-details">
                <div className="label">سنة الميلاد</div>
                <div className="content">{(userPaymentProfile.dateOfBirth !== null) ? getParentYearOfBirth(userPaymentProfile.dateOfBirth) : '-'}</div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="account-details">
                <div className="label">البلد</div>
                <div className="content">{userPaymentProfile.country}</div>
              </div>
            </div>
          </div>
        </span>
      )

      childrenList = userPaymentProfile.children.map((child, i) => {
        return (
            <div className="col-12 col-lg-4" style={{ float: 'right' }}>
            <div className="row">
              <div className="col-3 form-group child-profile">
                <div className="child-image">
                  <img src={(child.profilePicture !== null && child.profilePicture !== '') ? (child.profilePicture) : ((child.gender === 'male') ? avatar1 : avatar2)}
                       alt="" />
                </div>
              </div>
              <div className="col-9">
                <span>
                  <span className="child-data" style={{ float: 'right', textAlign: 'right' }}>
                    <span className="name bold-title">{child.name}</span>
                    <br />
                    {getDifferenceInYears(child.dateOfBirth, today)} سنوات
               </span>
                </span>
              </div>
            </div>
            </div>
        )
      })
    }

    if (this.state.checkAddKid === true) {
      this.handleAddingNewKid();
    }

    if (this.state.redirectToEditAccount === true) {
      this.props.removEeditingChildFromRedux();
      return (
        <Redirect to={PORTAL_ACCOUNT}> </Redirect>
      )
    }

    if (this.state.redirectToEditChild === true) {
      this.props.addEditingChildToRedux();
      return (
        <Redirect to={PORTAL_ACCOUNT}> </Redirect>
      )
    }
    if (userloading === true) {
      parent = (<Loader />)
    }
    return (
      <body>
        <Snackbar
          autoHideDuration={3000} onClose={this.handleSuccessMessages}
          anchorOrigin={{ vertical, horizontal }}
          open={successMessage.show}
          onClose={this.handleSuccessMessages}
          message={successMessage.message}
          key={vertical + horizontal}
        />
        {/** Start NavBar **/}
        <NavBar />
        {/** End NavBar */}
        <div className="main-container">
          <div className="container">
            <div className="form-row">
              {/** Side Menu **/}
              <SideMenu />
              {/** End Side Menu */}
              {/** content section **/}
              <div className="col-12 col-md-8">
                {/** parent section **/}
                <div className="completed-container">

                  <div className="account-title d-flex align-items-center justify-content-between" style={{ textAlign: 'right' }}>
                    <div className="title h3 bold-title">
                      بيانات الحساب
                          </div>
                    <div className="edit-account">
                      <button type="button" className="add-btn" onClick={this.redirectEditParent}>
                        تعديل بيانات الحساب
                              </button>
                    </div>
                  </div>
                  {parent}
                </div>
                {/** end of parent section **/}

                {/* Start of Children section */}
                <div className="completed-container">
                  <div className="account-title d-flex align-items-center justify-content-between">
                    <div className="title h3 bold-title">
                      أطفالي
                          </div>
                    <div className="edit-account">
                      <button type="button" className="add-btn" onClick={this.redirectEditChild} style={{ display: `${this.state.addNewKid}` }}>
                        <div className="add-icon">
                          <img src={addImage} alt="" />
                        </div>
                        <span className="bold-title">إضافة طفل اخر</span>
                      </button>
                    </div>
                  </div>
                  <div className="form-row mt-3">
                    {/** children list **/}
                    {childrenList}
                  </div>
                </div>
                {/* End of children section*/}

<DownloadApps/>

              </div>
            </div>
          </div>
        </div>
        {/** Start Footer */}
        <Footer />
        {/** End Footer */}

      </body>
    )
  }
}

const mapStateToProps = state => ({
  userPaymentProfile: state.userPaymentProfile.item,
  userloading: state.userPaymentProfile.loading,
  usererror: state.userPaymentProfile.getting_user_payment_data_error,
  usersucess: state.userPaymentProfile.getting_user_payment_data_sucessfully,

  successMessage: state.successMessages.item,
});


export default withRouter
  (connect
    (mapStateToProps,
      {
        getUserPaymentInfo,
        getUserData, addEditingChildToRedux, removEeditingChildFromRedux,
        removeSuccessMessage, emptyMobileUserProfileWithItem
      })
    (CustomerAccountProfile)
  );
