import {
    LOAD_All_MAIN_VALUE, FETCH_All_MAIN_VALUE, ERROR_ALL_MAIN_VALUE,
    LOAD_MAIN_VALUE, ERROR_UPDATE_MAIN_VALUE, UPDATE_MAIN_VALUE,
    ERROR_ADDING_MAIN_VALUE, ADD_MAIN_VALUE, DELETING_MAIN_VALUE,
    ERROR_DELETING_MAIN_VALUE, EMPTY_MAIN_VALUE,
    GET_MAIN_VALUE, ERROR_LOADING_MAIN_VALUE
} from './Types'
import axios, { post, put } from 'axios'

export const fetchAllMainValues = () => dispatch => {

    dispatch({
        type: LOAD_All_MAIN_VALUE,
        payload: 'loading'
    })

    axios.get('/ContentMainValue/getAllcontnetMainValuesForAdminPanel')
        .then((res) => {
            dispatch({
                type: FETCH_All_MAIN_VALUE,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_ALL_MAIN_VALUE,
                payload: Error.message
            })
        })
}

export const getMainValueData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_MAIN_VALUE,
                payload: 'loading'
            })
            const url = "/ContentMainValue/getContentMainValueDetails"
            const formData = new FormData();
            formData.append('Content_Main_Value_id', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_MAIN_VALUE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_MAIN_VALUE,
                    payload: Error.message
                })
            })


        })

    }
}

export const addMainValue = (text, description, imageUrl, number, text_in_english, description_in_english) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_MAIN_VALUE,
                payload: 'loading'
            })

            console.log(description_in_english, "des");

            const url = "/ContentMainValue/addContentMainValue";

            const formData = new FormData();
            formData.append('Content_Main_Value', text);

            formData.append('Description', description);
            formData.append('Image_URL', imageUrl);
            formData.append('Number', number);
            formData.append('Content_Main_Value_In_English', text_in_english);
            formData.append('Description_In_English', description_in_english);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_MAIN_VALUE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_MAIN_VALUE,
                    payload: Error.message
                })
            })
        })

    }
}


export const updateMainValue = (mainValueId, text,description, imageUrl, number, text_in_english, description_in_english) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_MAIN_VALUE,
                payload: 'loading'
            })

            const url = "/ContentMainValue/updateContentMainValue";
            const formData = new FormData();
            formData.append('Content_Main_Value_id', mainValueId);
            formData.append('Content_Main_Value', text);
            formData.append('Description', description);
            formData.append('Image_URL', imageUrl);
            formData.append('Number', number);
            formData.append('Content_Main_Value_In_English', text_in_english);
            formData.append('Description_In_English', description_in_english);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_MAIN_VALUE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_MAIN_VALUE,
                    payload: Error.message
                })
            })
        })

    }
}

export const deleteMainValue = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_MAIN_VALUE,
                payload: 'loading'
            })

            const url = "/ContentMainValue/deleteContentMainValue";
            const formData = new FormData();
            formData.append('Content_Main_Value_id', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_MAIN_VALUE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_MAIN_VALUE,
                    payload: Error.message
                })
            })
        })

    }
}

export const emptyMainValue = () => dispatch => {
    dispatch({
        type: EMPTY_MAIN_VALUE,
        payload: ''
    })
}
