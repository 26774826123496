import axios, { post, put } from 'axios'
import {
    SUB_VALUE_DELETED,
    ERROR_DELETING_SUB_VALUE,
    SAVE_SUB_VALUE,
    ERROR_ADDING_SUB_VALUE,
    UPDATE_SUB_VALUE,
    ERROR_UPDATE_SUB_VALUE,
    EMPTY_SUB_VALUE,
    LOAD_All_SUB_VALUE,
    FETCH_All_SUB_VALUE,
    ERROR_ALL_SUB_VALUE
} from './Types'

export const deleteSubValue = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = '/ContentSubValue/deleteContentSubValue';
            const formData = new FormData();
            formData.append('Content_Sub_Value_id', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SUB_VALUE_DELETED,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_SUB_VALUE,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const updateSubValue = (id, text,imageUrl, number, text_in_english) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/ContentSubValue/updateContentSubValue"
            const formData = new FormData();
            formData.append('Content_Sub_Value_id', id);
            formData.append('Content_Sub_Value', text);
            formData.append('Image_URL', imageUrl);
            formData.append('Number', number);
            formData.append('Content_Sub_Value_In_English', text_in_english);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch({
                    type: UPDATE_SUB_VALUE,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_SUB_VALUE,
                    payload: Error.message
                })
            })
        })
    }
}

export const emptySubValues = () => dispatch => {
    dispatch({
        type: EMPTY_SUB_VALUE
    })
}

export const addSubValue = (mainValueId, text, imageUrl, number, text_in_english) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/ContentSubValue/addContentSubValue"
            const formData = new FormData();
            formData.append('Content_Main_Value_Id', mainValueId);
            formData.append('Content_Sub_Value', text);
            formData.append('Image_URL', imageUrl);
            formData.append('Number', number);
            formData.append('Content_Sub_Value_In_English', text_in_english);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SAVE_SUB_VALUE,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_SUB_VALUE,
                    payload: Error.message
                })
            })
        })
    }
}

export const fetchAllSubValues = () => dispatch => {

    dispatch({
        type: LOAD_All_SUB_VALUE,
        payload: 'loading'
    })

    axios.get('/ContentSubValue/getAllcontnetSubValues')
        .then((res) => {
            dispatch({
                type: FETCH_All_SUB_VALUE,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_ALL_SUB_VALUE,
                payload: Error.message
            })
        })
}
