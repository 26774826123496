import {
    ERROR_GET_USER_PAYMENT_DATA, GET_USER_PAYMENT_DATA,
    LOAD_GET_USER_PAYMENT_DATA, EMPTY_MOBILE_USER_PROFILE,
    SUCCESS_CANCEL_USER_PAYMENT_SUPSCRIPTION,
    ERROR_CANCEL_USER_PAYMENT_SUPSCRIPTION,
    SUCCESS_CANCEL_TPAY_SUPSCRIPTION,
    EMPTY_MOBILE_USER_PROFILE_WITH_ITEM, LOAD_GET_USER_CODE_DATA,
    ERROR_GET_USER_CODE_DATA, GET_USER_CODE_DATA, EMPTY_USER_PROFILE,
    EMPTY_USER_PROFILE_WITH_ITEM,
    UPDATE_STATE,
} from './Types';
import { post } from 'axios';
import store from '../Store';


export const getUserData = (tokenFromUrl) => {

    return (dispatch, getState, { getFirebase }) => {
        // const firebase = getFirebase();
        // firebase.auth().currentUser.getIdToken().then((token) => {

        dispatch({
            type: LOAD_GET_USER_PAYMENT_DATA,
        })

        const url = "/User/getUserData"
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': tokenFromUrl
            }
        }
        post(url, formData, config).then((res) => {
            dispatch(
                {
                    type: GET_USER_PAYMENT_DATA,
                    payload: res.data
                }
            )
        }).catch((Error) => {
            dispatch({
                type: ERROR_GET_USER_PAYMENT_DATA,
                payload: Error.response.data.message

            })
        })

        // })

    }
}

export const cancelSubscription = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            //let token = store.getState().user.token;
            dispatch({
                type: LOAD_GET_USER_PAYMENT_DATA,
            })

            const url = "/Stripe/cancelSubscription"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SUCCESS_CANCEL_USER_PAYMENT_SUPSCRIPTION,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_CANCEL_USER_PAYMENT_SUPSCRIPTION,
                    payload: Error.message
                })
            })

        })

    }
}
export const cancelMobileSubscription = (tpayId) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            //let token = store.getState().user.token;
            dispatch({
                type: LOAD_GET_USER_PAYMENT_DATA,
            })

            const url = "/Tpay/cancelSubscriptionAPI"
            const formData = new FormData();
            formData.append("subscriptionContractId", tpayId);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SUCCESS_CANCEL_TPAY_SUPSCRIPTION,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_CANCEL_USER_PAYMENT_SUPSCRIPTION,
                    payload: Error.message
                })
            })

        })

    }
}

export const emptyMobileUserProfile = () => dispatch => {
    dispatch({
        type: EMPTY_MOBILE_USER_PROFILE,
        payload: ''
    })
}


export const emptyMobileUserProfileWithItem = () => dispatch => {
    dispatch({
        type: EMPTY_MOBILE_USER_PROFILE_WITH_ITEM,
        payload: ''
    })
}

// export const getUserDataWithCodes = () => {
//
//     return (dispatch, getState, { getFirebase }) => {
//          const firebase = getFirebase();
//          firebase.auth().currentUser.getIdToken().then((token) => {
//
//             dispatch({
//                 type: LOAD_GET_USER_CODE_DATA,
//             })
//
//             const url = "/User/getUserWithCodesData"
//             const formData = new FormData();
//             const config = {
//                 headers: {
//                     'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
//                     'X-Authorization-Firebase': token
//                 }
//             }
//             post(url, formData, config).then((res) => {
//                 dispatch(
//                     {
//                         type: GET_USER_CODE_DATA,
//                         payload: res.data
//                     }
//                 )
//             }).catch((Error) => {
//                 dispatch({
//                     type: ERROR_GET_USER_CODE_DATA,
//                     payload: Error.response.data.message
//
//                 })
//             })
//
//          })
//     }
// }

export const emptyUserProfile = () => dispatch => {
    dispatch({
        type: EMPTY_USER_PROFILE,
        payload: ''
    })
}

export const emptyUserProfileWithItem = () => dispatch => {
    dispatch({
        type: EMPTY_USER_PROFILE_WITH_ITEM,
        payload: ''
    })
}

export const cancelAutoRenewal = () => {

    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_GET_USER_CODE_DATA,
            })

            const url = "/Stripe/cancelSubscription"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SUCCESS_CANCEL_USER_PAYMENT_SUPSCRIPTION,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_CANCEL_USER_PAYMENT_SUPSCRIPTION,
                    payload: Error.message
                })
            })
        })
    }
}

export const getUserPaymentInfo = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_GET_USER_PAYMENT_DATA,
            })

            const url = "/User/getUserData"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_USER_PAYMENT_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_GET_USER_PAYMENT_DATA,
                    payload: Error.response.data.message

                })
            })
        })
    }
}
