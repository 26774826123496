import {
    LOAD_PRE_PAID_PAYMENT, ADD_PRE_PAID_PAYMENT,
    EMPTY_PRE_PAID_PAYMENT, ERROR_ADDING_PRE_PAID_PAYMENT
} from '../Actions/Types'

const initialState = {
  payment_added_successfully: '',
  error_adding_payment: '',
  getting_payment_successfully: '',
  payment_feedback_messege: '',
  loading: '',
  item: '',
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_PRE_PAID_PAYMENT:
            return {
                ...state,
                item: action.payload,
                //payment_feedback_messege: action.payload,
                payment_added_successfully: true,
                error_adding_payment: '',
                loading: false
            }
        case LOAD_PRE_PAID_PAYMENT:
            return {
                ...state,
                loading: true
            }
        case EMPTY_PRE_PAID_PAYMENT:
            return {
                ...initialState,
            }
        case ERROR_ADDING_PRE_PAID_PAYMENT:
            return{
                ...state,
                error_adding_payment: true,
                payment_feedback_messege: action.payload,
                payment_added_successfully: '',
                item: '',
                loading: false,
            }
        default:
            return state
    }
}
