import React, { Component } from 'react'
import EvaluationForm from './EvaluationForm';
import { Button } from 'semantic-ui-react';
import { Accordion, Icon } from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withFirebase } from '../../config';
/*Actions */
import { addEvaluationToRedux } from '../../Actions/EvaluationActions';
import { emptyEvaluationSucessAndfailure } from '../../Actions/EvaluationActions'

class Evaluation extends Component {

    state = {
        activeIndex: 0,

        excellentErrorMessage: "",
        excellentfileURL: "",
        excellentArticlURL: "",
        excellentStart: 4,
        excellentEnd: 5,
        excellentRcommendation: 'عظيم، جاء تقييم طفلك في النشاط ممتازاً، ما يدل على حسن تقبله للمحتوى وارتفاع مستوى تركيزه. احرص على استثمار ذلك بالنقاش والتفاعل حول قيمة الحلقة.  ولإثراء النقاش حول القيم التي لاقت اهتمام الطفل، برجاء تفقد الأوراق التربوية المرفقة، إذ تحوي مزيدًا من المعلومات والأفكار. تنبيه: اختر أوقاتًا مناسبة لتطبيق بعض الأفكار العملية التي يضمها البرنامج التطويري، فستسهم تلك الأفكار في تثبيت القيمة بشكل لا يصدق',

        goodErrorMessage: "",
        goodfileURL: "",
        goodArticlURL: "",
        goodStart: 2,
        goodEnd: 3,
        goodRcommendation: 'جيد، كان تفاعل طفلك متوسطًا، وهو ما يدعو إلى التفاؤل بفضل إرادته ودعمك والمواد المتاحة. شارك طفلك النقاش حول قيمة الحلقة لتساعده على تبنيها، وأكثر ما سيساعدك في ذلك الأوراق التربوية.    وهنا يفضل أن تشارك البرنامج التطويري مع طفلك لتحسين مستواه، كل ذلك بشكل عملي يساعده على تثبيت القيمة. واطلب منه بعدها إعادة مشاهدة الحلقة وتأدية النشاط مرة أخرى للوصول لنتائج أفضل',

        badErrorMessage: "",
        badfileURL: "",
        badArticlURL: "",
        badStart: 0,
        badEnd: 1,
        badRcommendation: 'حسنًا، لم يحالف طفلك التوفيق هذه المرة، لكن محاولته تدل على الاهتمام بقيمة الحلقة. ندعوك إلى إعادة مشاهدة الحلقة مع طفلك بعد الاطلاع على الأوراق التربوية بما سيساعد على إثراء نقاشكما وتيسير تبنيه للقيمة واستفادته من الحلقة.  كما نرجو تنفيذ البرنامج التطويري معه، وتذكر أن البذرة تنمو بالسقاية، وأن تشجيعك له سيساعده دائماً على تخطي أية مصاعب، في طفولته وسائر حياته ',
    }


    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    // Start excellent
    getExcellentStart(start) {
        if (start) {
            console.log("ex start = " + start)
            this.setState({ excellentStart: start, excellentErrorMessage: "" });
        }
    }
    getExcellentEnd(end) {
        if (end) {
            this.setState({ excellentEnd: end, excellentErrorMessage: "" });
        }
    }
    getExcellentRecommendation(recommendation) {
        if (recommendation) {
            this.setState({ excellentRcommendation: recommendation, excellentErrorMessage: "" });
        }
    }
    getExcellentFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ excellentfileURL: fileURL });
            if (fileURL !== ""
                && fileURL !== null
                && fileURL !== undefined) {
                this.setState({
                    badfileURL: fileURL,
                    goodfileURL: fileURL, excellentErrorMessage: ""
                })
            }
        }
    }
    getExcellentArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ excellentArticlURL: articlURL });

            if (articlURL !== ""
                && articlURL !== null
                && articlURL !== undefined) {

                this.setState({
                    goodArticlURL: articlURL,
                    badArticlURL: articlURL, excellentErrorMessage: ""
                })
            }
        }
    }
    // End excellent

    // Start Good
    getGoodStart(start) {
        if (start) {
            this.setState({ goodStart: start, goodErrorMessage: "" });
        }
    }
    getGoodEnd(end) {
        if (end) {
            this.setState({ goodEnd: end, goodErrorMessage: "" });
        }
    }
    getGoodRecommendation(recommendation) {
        if (recommendation) {
            this.setState({ goodRcommendation: recommendation, goodErrorMessage: "" });
        }
    }
    getGoodFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ goodfileURL: fileURL, goodErrorMessage: "" });
        }
    }
    getGoodArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ goodArticlURL: articlURL, goodErrorMessage: "" });
        }
    }
    // End Good

    // Start Bad
    getBadStart(start) {
        if (start) {
            this.setState({ badStart: start, badErrorMessage: "" });
        }
    }
    getBadEnd(end) {
        if (end) {
            this.setState({ badEnd: end, badErrorMessage: "" });
        }
    }
    getBadRecommendation(recommendation) {
        if (recommendation) {
            this.setState({ badRcommendation: recommendation, badErrorMessage: "" });
        }
    }
    getBadFileUrl(fileURL) {
        if (fileURL) {
            console.log("badFile = " + fileURL)
            this.setState({ badfileURL: fileURL, badErrorMessage: "" });
        }
    }
    getBadArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ badArticlURL: articlURL, badErrorMessage: "" });
        }
    }
    // End Good

    handleSubmit = (e) => {

        var selectedBlogsIDs = [];
        const {
            excellentStart, excellentEnd, excellentRcommendation, excellentfileURL, excellentArticlURL,
            goodStart, goodEnd, goodRcommendation, goodfileURL, goodArticlURL,
            badStart, badEnd, badRcommendation, badfileURL, badArticlURL } = this.state;

        if ((excellentStart === '' || excellentStart == undefined || excellentStart < 0)
            || (excellentEnd === '' || excellentEnd == undefined || excellentEnd == 0)
            || (excellentRcommendation === '' || excellentRcommendation == undefined)
            || (excellentfileURL === '' || excellentfileURL == undefined)
            || (excellentArticlURL === '' || excellentArticlURL == undefined)

            || (goodStart === '' || goodStart == undefined || goodStart < 0)
            || (goodEnd === '' || goodEnd == undefined || goodEnd == 0)
            || (goodRcommendation === '' || goodRcommendation == undefined)
            || (goodfileURL === '' || goodfileURL == undefined)
            || (goodArticlURL === '' || goodArticlURL == undefined)

            || (badStart === '' || badStart == undefined || badStart < 0)
            || (badEnd === '' || badEnd == undefined || badEnd == 0)
            || (badRcommendation === '' || badRcommendation == undefined)
            || (badfileURL === '' || badfileURL == undefined)
            || (badArticlURL === '' || badArticlURL == undefined)

        ) {

            if (excellentStart === '' || excellentStart == undefined || excellentStart < 0) {
                this.setState({ excellentErrorMessage: "Empty Excellent Start Number" })
            }
            else if (excellentEnd === '' || excellentEnd == undefined || excellentEnd == 0) {
                this.setState({ excellentErrorMessage: "Empty Excellent End Number" })
            }
            else if (excellentRcommendation === '' || excellentRcommendation == undefined) {
                this.setState({ excellentErrorMessage: "Empty Excellent Recommendation" })
            }
            else if (excellentfileURL === '' || excellentfileURL == undefined) {
                this.setState({ excellentErrorMessage: "Empty Excellent Program File" })
            }
            else if (excellentArticlURL === '' || excellentArticlURL == undefined) {
                this.setState({ excellentErrorMessage: "Empty Excellent article File" })
            }

            /************ good ************ */
            if (goodStart === '' || goodStart == undefined || goodStart < 0) {
                this.setState({ goodErrorMessage: "Empty Good Start Number" })
            }
            else if (goodEnd === '' || goodEnd == undefined || goodEnd == 0) {
                this.setState({ goodErrorMessage: "Empty Good End Number" })
            }
            else if (goodRcommendation === '' || goodRcommendation == undefined) {
                this.setState({ goodErrorMessage: "Empty Good Recommendation" })
            }
            else if (goodfileURL === '' || goodfileURL == undefined) {
                this.setState({ goodErrorMessage: "Empty Good Program File" })
            }
            else if (goodArticlURL === '' || goodArticlURL == undefined) {
                this.setState({ goodErrorMessage: "Empty Good article File" })
            }
            /************ Bad*************/
            if (badStart === '' || badStart == undefined || badStart < 0) {
                this.setState({ badErrorMessage: "Empty Bad Start Number" })
            }
            else if (badEnd === '' || badEnd == undefined || badEnd == 0) {
                this.setState({ badErrorMessage: "Empty Bad End Number" })
            }
            else if (badRcommendation === '' || badRcommendation == undefined) {
                this.setState({ badErrorMessage: "Empty Bad Recommendation" })
            }
            else if (badfileURL === '' || badfileURL == undefined) {
                this.setState({ badErrorMessage: "Empty Bad Program File" })
            }
            else if (badArticlURL === '' || badArticlURL == undefined) {
                this.setState({ badErrorMessage: "Empty Bad article File" })
            }
        }
        else {

            this.props.addEvaluationToRedux(excellentStart, excellentEnd,
                excellentRcommendation, excellentfileURL, selectedBlogsIDs, "excellent", excellentArticlURL);

            this.props.addEvaluationToRedux(goodStart, goodEnd,
                goodRcommendation, goodfileURL, selectedBlogsIDs, "good", goodArticlURL);

            this.props.addEvaluationToRedux(badStart, badEnd,
                badRcommendation, badfileURL, selectedBlogsIDs, "bad", badArticlURL);

        }


        e.preventDefault();
    }

    render() {


        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyEvaluationSucessAndfailure();

        }

        else if (success === true) {
            alert("Evaluation Added Successfully")
            this.props.emptyEvaluationSucessAndfailure();
        }

        const { activeIndex } = this.state

        return (
            <div>

                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 0}
                        index={1} onClick={this.handleClick}>
                        <div className="card-header" id="card-header" >
                            <h4 className="mb-0">
                                <Icon name='dropdown' />
                                Excellent
                            </h4>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <div id="collapseOne" className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <EvaluationForm category_type="excellent"

                                returnCategoryFileURLCallBack={this.getExcellentFileUrl.bind(this)}
                                returnCategoryArticleURLCallBack={this.getExcellentArticleUrl.bind(this)}

                                returnedStart={this.getExcellentStart.bind(this)}
                                returnedEnd={this.getExcellentEnd.bind(this)}
                                returnedRecommendation={this.getExcellentRecommendation.bind(this)}

                                start={this.state.excellentStart}
                                end={this.state.excellentEnd}
                                recommendation={this.state.excellentRcommendation}
                                errorMessage={this.state.excellentErrorMessage}
                            />
                        </div>
                    </Accordion.Content>
                </Accordion>


                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 3}
                        index={3} onClick={this.handleClick}>
                        <div className="card-header" id="card-header" >
                            <h4 className="mb-0">
                                <Icon name='dropdown' />
                                Good
                            </h4>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                        <div id="collapseOne" className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <EvaluationForm category_type="good"
                                returnCategoryFileURLCallBack={this.getGoodFileUrl.bind(this)}
                                returnCategoryArticleURLCallBack={this.getGoodArticleUrl.bind(this)}
                                copyFileURL={this.state.goodfileURL}
                                copyArticleURL={this.state.goodArticlURL}

                                returnedStart={this.getGoodStart.bind(this)}
                                returnedEnd={this.getGoodEnd.bind(this)}
                                returnedRecommendation={this.getGoodRecommendation.bind(this)}

                                start={this.state.goodStart}
                                end={this.state.goodEnd}
                                recommendation={this.state.goodRcommendation}
                                errorMessage={this.state.goodErrorMessage}

                            />
                        </div>
                    </Accordion.Content>
                </Accordion>

                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 5}
                        index={5} onClick={this.handleClick}>
                        <div className="card-header" id="card-header" >
                            <h4 className="mb-0">
                                <Icon name='dropdown' />
                                Bad
                            </h4>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 5}>
                        <div id="collapseOne" className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <EvaluationForm category_type="bad"
                                returnCategoryFileURLCallBack={this.getBadFileUrl.bind(this)}
                                returnCategoryArticleURLCallBack={this.getBadArticleUrl.bind(this)}
                                copyFileURL={this.state.badfileURL}
                                copyArticleURL={this.state.badArticlURL}

                                returnedStart={this.getBadStart.bind(this)}
                                returnedEnd={this.getBadEnd.bind(this)}
                                returnedRecommendation={this.getBadRecommendation.bind(this)}

                                start={this.state.badStart}
                                end={this.state.badEnd}
                                recommendation={this.state.badRcommendation}
                                errorMessage={this.state.badErrorMessage}

                            />

                        </div>
                    </Accordion.Content>

                </Accordion>

                <div className="buttons_container float-right">
                    <button
                        className=" float-right btn btn-lg btn-success btn-suc btn-block-sm"
                        onClick={this.handleSubmit}>
                        Save All
                    </button>

                </div>
            </div>
        )
    }
}

Evaluation.propTypes = {
    addEvaluationToRedux: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    evaluation: state.evaluation.items,
    error: state.evaluation.adding_Evaluation_error,
    success: state.evaluation.adding_Evaluation_sucessfully
});
export default withFirebase
    (connect
        (mapStateToProps,
            { addEvaluationToRedux, emptyEvaluationSucessAndfailure }
        )
        (Evaluation)
    );