import {
    FETCH_ALL_CHANNELS,
    ADD_CHANNEL, PUBLISH_CHANNEL,
    GET_CHANNEL_DATA, LOAD_ALL_CHANNELS,
    ERROR_LOADING_CHANNEL,
    ERROR_LOADING_ALL_CHANNELS,
    ERROR_ADDING_CHANNEL,
    LOAD_CHANNEL, EDIT_CHANNEL_DATA,
    ERROR_EDITING_CHANNEL, EMPTY_CHANNEL,
    ERROR_DELETING_CHANNEL, DELETE_CHANNEL,
    SETTING_CHANNEL_STATUS, ERROR_SETTING_CHANNEL_STATUS,
    EMPTY_ALL_CHANNELS
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},

    loading: '',

    error_adding_channel: '',
    channel_added_successfully: '',

    error_loading_all_channels: '',
    error_loading_channel: '',

    channel_updated_successfully: '',
    error_updated_channel: '',

    channel_deleted_successfully: '',
    error_deleteing_channel: '',

    channel_status_updated_successfully: '',
    error_setting_status: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_CHANNELS:
            return {
                ...state,
                items: action.payload,
                error_loading_all_channels: '',
                loading: false,
                item: {}
            }

        case ADD_CHANNEL:
            return {
                ...state,
                item: action.payload
            }

        case PUBLISH_CHANNEL:
            return {
                ...state,
                item: action.payload,
                channel_added_successfully: true
            }

        case GET_CHANNEL_DATA:
            return {
                ...state,
                item: action.payload,
                error_loading_channel: '',
                loading: false
            }


        case ERROR_LOADING_ALL_CHANNELS:
            return {
                ...state,
                error_loading_all_channels: action.payload,
                loading: false
            }
        case ERROR_LOADING_CHANNEL:
            return {
                ...state,
                error_loading_channel: action.payload,
                loading: false
            }

        case EMPTY_CHANNEL:
            return {
                ...state,
                error_adding_channel: '',
                channel_added_successfully: '',

                error_updated_channel: '',
                channel_updated_successfully: '',

                error_deleteing_channel: '',
                channel_deleted_successfully: '',

                channel_status_updated_successfully: '',
                error_setting_status: '',

                error_loading_channel: '',
                error_loading_all_channels: '',
                loading: false

            }
        case EMPTY_ALL_CHANNELS:
            return {
                ...state,
                items: [],
                item: {},
            }

        case LOAD_CHANNEL:
            return {
                ...state,
                loading: true
            }


        case LOAD_ALL_CHANNELS:
            return {
                ...state,
                loading: true
            }

        case ERROR_ADDING_CHANNEL:
            return {
                ...state,
                error_adding_channel: action.payload,
                channel_added_successfully: false

            }

        case EDIT_CHANNEL_DATA:
            return {
                ...state,
                item: action.payload,
                channel_updated_successfully: true
            }

        case ERROR_EDITING_CHANNEL:
            return {
                ...state,
                error_updated_channel: action.payload,
                channel_updated_successfully: false,
                loading: false
            }

        case DELETE_CHANNEL:
            return {
                ...state,
                item: action.payload,
                channel_deleted_successfully: true
            }
        case ERROR_DELETING_CHANNEL:
            return {
                ...state,
                error_deleteing_channel: action.payload,
                channel_deleted_successfully: false
            }

            case SETTING_CHANNEL_STATUS:
                return {
                    ...state,
                    channel_status_updated_successfully: true,
                    item: action.payload,
                    loading: false
                }
            case ERROR_SETTING_CHANNEL_STATUS:
                return {
                    ...state,
                    channel_status_updated_successfully: false,
                    error_setting_status: action.payload,
                    loading: false
                }
            default:
                return state
    
        }
    
    }
    

