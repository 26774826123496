import {
    ADD_QUESTION, ADD_QUESTIONS, GET_ACTIVITY_QUESTIONS,
    ASSIGN_CORRECT_ANSWER_TO_QUESTION, PUBLISH_QUESTION, CLEAR_QUESTIONS,
    LOAD_QUESTION, EMPTY_QUESTION,DELETE_QUESTION_FROM_REDUX

} from '../Actions/Types'

const initial_state = {
    items: [],
    item: {},

    loading: '',

}

export default function (state = initial_state, action) {
    switch (action.type) {

        case LOAD_QUESTION:
            return {
                ...state,
                loading: true
            }
        /************************************* */
        case ADD_QUESTION:

            return {
                ...state,
                item: action.payload
            }

        case CLEAR_QUESTIONS:
            return {
                ...state,
                items: action.payload
            }
        case ADD_QUESTIONS:
            return {
                ...state,
                items: action.payload
            }
        case PUBLISH_QUESTION:
            return {
                ...state,
                item: action.payload
            }
        case GET_ACTIVITY_QUESTIONS:
            return {
                ...state,
                items: action.payload

            }
        case ASSIGN_CORRECT_ANSWER_TO_QUESTION:
            return {
                ...state,
                item: action.payload
            }
            

        case DELETE_QUESTION_FROM_REDUX:
            return {
                ...state,
                items: action.payload
            }

        default:
            return state



    }

}
