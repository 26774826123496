import {
    EMPTY_RANDOM_NUMBER,
    LOAD_RANDOM_NUMBER,
    GET_RANDOM_NUMBER,
    ERROR_GET_RANDOM_NUMBER,
    EMPTY_RANDOM_NUMBER_ITEMS, GET_RANDOM_NUMBER_LINK_ID,FETCH_ALL_QrTemplates,ERROR_LOADING_ALL_QrTemplates,CARD_FILE_UPLOADED
} from './Types'
import axios, { post, get } from 'axios'


var timerId;
export const generatePaymentCardList = (cardsNumber, paymentPackageType, vendorId) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_RANDOM_NUMBER,
                payload: 'loading'
            })

            const url = '/PaymentCard/generatePaymentCardList';
            const formData = new FormData();
            formData.append('Codes_Number', cardsNumber);
            formData.append('Payment_Package_type', paymentPackageType);
            formData.append('Vendor_Id', vendorId);

            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_RANDOM_NUMBER_LINK_ID,
                        payload: res.data
                    }
                )

            }).catch((Error) => {
                dispatch({
                    type: ERROR_GET_RANDOM_NUMBER,
                    payload: Error.message
                })
            })
        })


    }
}


export const generatePaymentCardListNew = (cardsNumber, paymentPackageType, vendorId , qrTemplateName) => {            
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_RANDOM_NUMBER,
                payload: 'loading'
            })

            const url = '/PaymentCard/generatePaymentCardListNew';
            const formData = new FormData();
            formData.append('Codes_Number', cardsNumber);
            formData.append('Payment_Package_type', paymentPackageType);
            formData.append('Vendor_Id', vendorId);
            formData.append('qrTemplateName', qrTemplateName);

            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                },
                responseType: 'blob'
            }
            post(url, formData, config).then((res) => {		
				return res.data;			
			})
			.then((res) => {
				//alert('res blob recieved is :'+res);
				
				let blob = new Blob([res], {type: 'application/octet-stream'});
        		let url = window.URL.createObjectURL(blob);
        		//triggerDownload(fileUrl, 'img1.png');
        		
        		//alert('URL is :'+url);
        		
		        let a = document.createElement('a');
		        a.setAttribute('href', url);
		        a.setAttribute('download', 'QrCodes.xlsx'); 
		        a.click();        		
				//link.href = URL.createObjectURL(res.data);
      			
      			 
                dispatch(
                    {
                        type: GET_RANDOM_NUMBER_LINK_ID,
                        payload: res.data
                    }
                )
                

            }).catch((Error) => {
                dispatch({
                    type: ERROR_GET_RANDOM_NUMBER,
                    payload: Error.message
                })
            })
        })


    }
}





export const getDownloadLink = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = '/QrGeneration/getQrGenerationById';
            const formData = new FormData();
            formData.append('id', id);
 

            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                }
            }
            timerId = setInterval(() => {
                post(url, formData, config).then((res) => {
                    if (res.data.url !== "" && res.data.url !== undefined && res.data.url !== "undefined" && res.data.url !== null && res.data.url !== "null") {

                        dispatch(
                            {
                                type: GET_RANDOM_NUMBER,
                                payload: res.data.url
                            }
                        )
                    }

                }).catch((Error) => {
                    dispatch({
                        type: ERROR_GET_RANDOM_NUMBER,
                        payload: Error.message
                    })
                })
            }, 10000);




        });
    }
}

export const emptySecureRandomNumber = () => dispatch => {
    clearInterval(timerId);
    dispatch({
        type: EMPTY_RANDOM_NUMBER,
        payload: ""
    })
}


export const emptySecureRandomNumberItems = () => dispatch => {
    clearInterval(timerId);
    dispatch({
        type: EMPTY_RANDOM_NUMBER_ITEMS,
        payload: ''
    })
}



export const fetchAllQrImagesTemplates = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: 'LOAD_QrTemplate',
                payload: 'loading'
            })
            const url = "/PaymentCard/getPaymentCardTemplatesList"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
	
	//alert('Qr Remplates list :'+JSON.stringify(res,null,3));
                dispatch({
                    type: FETCH_ALL_QrTemplates,
                    payload: res.data
                })

            }).catch((Error) => {
	//alert('Qr Remplates error :'+Error.message);
                 dispatch({
                type: ERROR_LOADING_ALL_QrTemplates,
                payload: Error.message
            })
            })

        })

    }
}

export const generatePaymentCardListStampedOnImg = (cardsNumber, paymentPackageType, vendorId,qrTemplateName) => {            
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_RANDOM_NUMBER,
                payload: 'loading'
            })

            const url = '/PaymentCard/generatePaymentCardListNew';
            const formData = new FormData();
            formData.append('Codes_Number', cardsNumber);
            formData.append('Payment_Package_type', paymentPackageType);
            formData.append('Vendor_Id', vendorId);
            formData.append('qrTemplateName', qrTemplateName);

            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                },
                responseType: 'blob'
            }
            post(url, formData, config).then((res) => {		
				return res.data;			
			})
			.then((res) => {
				//alert('res blob recieved is :'+res);
				
				let blob = new Blob([res], {type: 'application/octet-stream'});
        		let url = window.URL.createObjectURL(blob);
        		
        		//alert('URL is :'+url);
        		
		        let a = document.createElement('a');
		        a.setAttribute('href', url);
		        a.setAttribute('download', 'QrCodes.xlsx'); 
		        a.click();        		
				
            }).catch((Error) => {
                dispatch({
                    type: ERROR_GET_RANDOM_NUMBER,
                    payload: Error.message
                })
            })
        })


    }
}
    
export const uploadQrCardTemplate = (cardFile) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: 'LOAD_QrCardImg',
                payload: 'loading'
            })
             
            const url = "/PaymentCard/uploadCardImage"
            const formData = new FormData();
			formData.append('file', cardFile);
    		formData.append('fileName', cardFile.name);            
            
            const config = {
                headers: {
                    //'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'content-type': 'multipart/form-data',
                    'X-Authorization-Firebase': token
                }
            }
            
            post(url, formData, config).then((res) => {
	
				console.log('returned file url to be displayed if it was an img : '+res.data);
	
                dispatch({
                    type: CARD_FILE_UPLOADED,
                    payload: res.data
                })

            }).catch((Error) => {
				//alert('Qr Remplates error :'+Error.message);
				console.error("Error uploading file: ", Error);
        		//setError(Error);
                
                 dispatch({
                type: ERROR_LOADING_ALL_QrTemplates,
                payload: Error.message
            })
            })

        })

    }
}


