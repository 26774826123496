import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Components */
import GetAllDiscoverUsSeries from './GetAllDiscoverUsSeries';
import ViewDiscoverUsSeries from './ViewDiscoverUsSeries';
import AddDiscoverUsSeries from './AddDiscoverUsSeries';
import UpdateDiscoverUsSeries from './UpdateDiscoverUsSeries';
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_DISCOVER_SERIES,
         VIEW_DISCOVER_SERIES, ADD_DISCOVER_SERIES,
          UPDATE_DISCOVER_SERIES } from '../../routes';

class DiscoverUsSeriesAuth extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { userPaymentProfile } = this.props;
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    const { userPaymentProfile } = this.props;

    if(userPaymentProfile.user_role.role === "Analyst"){
      return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
    }
    return (
      <span>
          {/* Discover us series */}
          <Route path={ALL_DISCOVER_SERIES} component={GetAllDiscoverUsSeries} />
          <Route path={VIEW_DISCOVER_SERIES} component={ViewDiscoverUsSeries} />
          <Route path={ADD_DISCOVER_SERIES} component={AddDiscoverUsSeries} />
          <Route path={UPDATE_DISCOVER_SERIES} component={UpdateDiscoverUsSeries} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(DiscoverUsSeriesAuth)
);
