import {
    LOAD_TPAY_VERIFYING_CODE,
    TPAY_VERIFYING_CODE_SUCCESS,
    TPAY_VERIFYING_CODE_ERROR,

    LOAD_TPAY_RESEND_CODE,
    TPAY_RESEND_CODE_SUCCESS,
    TPAY_RESEND_CODE_ERROR,

    LOAD_TPAY_DIGEST_CODE,
    TPAY_DIGEST_CODE_SUCCESS,
    TPAY_DIGEST_CODE_ERROR,

    EMPTY_TPAY

} from "./Types";

import { post } from 'axios'


export const verifyingCode = (subscriptionContractId, pinCode, transactionId) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_TPAY_VERIFYING_CODE,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/Tpay/verifySubscriptionAPI"

            const formData = new FormData();
            formData.append('subscriptionContractId', subscriptionContractId)
            formData.append('pinCode', pinCode)
            formData.append('transactionId', transactionId)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: TPAY_VERIFYING_CODE_SUCCESS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: TPAY_VERIFYING_CODE_ERROR,
                    payload: Error.message
                })

            })
        })
    }

}


export const resendCode = (subscriptionContractId) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_TPAY_RESEND_CODE,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/Tpay/resendVerificationCodeAPI"

            const formData = new FormData();
            formData.append('subscriptionContractId', subscriptionContractId)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: TPAY_RESEND_CODE_SUCCESS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: TPAY_RESEND_CODE_ERROR,
                    payload: Error.message
                })

            })
        })
    }

}

export const getDigest = (message) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_TPAY_DIGEST_CODE,
            payload: 'loading'
        })

        const url = "/Tpay/calculateDigest"

        const formData = new FormData();
        formData.append("message", message);

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            }
        }
        post(url, formData, config).then((res) => {
            dispatch({
                type: TPAY_DIGEST_CODE_SUCCESS,
                payload: res.data
            })
        }).catch((Error) => {
            dispatch({
                type: TPAY_DIGEST_CODE_ERROR,
                payload: Error.message
            })

        })
    }

}

export const emptyTpay = () => dispatch => {
    dispatch({
        type: EMPTY_TPAY,
        payload: ''
    })
}