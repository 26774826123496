import React, { Component } from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Link } from 'react-router-dom'
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withFirebase } from '../../config';
/*Components */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
/*Actions */
import { addStripePayment, emptyPayment } from '../../Actions/StripePaymentAction';

class StripePayment extends Component {
    constructor(props) {
        super(props);

        this.state = { complete: false };
        this.submit = this.submit.bind(this);
    }

    submit(ev) {
        //console.log("Submitted")
        this.props.stripe.createToken({ type: 'card', name: 'test' })
            .then((data) => {
                //console.log("success")
                //console.log(data.token.id)
                //console.log(data.token);
                this.props.addStripePayment(data.token.id);

            })
            .catch((error) => {
                console.log("error")
                console.log(error)
            })

        ev.preventDefault();

    }

    render() {
        const { feedback_messege, success } = this.props;
        if (success == true && feedback_messege != '') {
            //console.log(feedback_messege)
            alert( feedback_messege)
            this.props.emptyPayment();
        }
        
        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">

                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to="/">Home</Link><span> </span>
                                                </span>
                                                <span>Payment</span>

                                            </div>

                                            <br />

                                            <div className="container">
                                                <h3>Stripe payment page</h3>
                                                <hr />
                                                <form className="general-setting-form" onSubmit={(e)=> e.preventDefault()}>
                                                    <fieldset className='fieldset'>
                                                        <legend className="legend">Payment Method</legend>
                                                        <div className="row">
                                                            <div className="card col-5" >

                                                                <div className="checkout">
                                                                    <p> Would you like to complete the purchase? </p>
                                                                    <CardElement />
                                                                    <button onClick={this.submit}>Pay $9.99</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}


StripePayment.propTypes = {
    addStripePayment: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    feedback_messege: state.stripePayment.payment_feedback_messege,
    loading: state.stripePayment.loading,
    success: state.stripePayment.payment_updated_successfully
});

export default injectStripe(withFirebase(connect(mapStateToProps, { addStripePayment, emptyPayment })(StripePayment)));
