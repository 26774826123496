import {
    ADD_EPISODE_REDIRECTION_PAGE,
    EMPTY_EPISODE_REDIRECTION_PAGE,
    EXCHANGE_DATA, CLEAR_EXCHANGE_DATA,
    EMPTY_DRAG_DROP_PARENT_REDIRECTION_PAGE,
    ADD_DRAG_DROP_PARENT_REDIRECTION_PAGE
} from '../Actions/Types'

const initialState = {
    item: {},
    dragDropitem: {},

    redirectionPage_adding_error: '',
    redirectionPage_adding_sucessfully: '',

    redirectionPage_error: '',
    redirectionPage_sucessfully: '',

}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_EPISODE_REDIRECTION_PAGE:
            return {
                ...state,
                item: action.payload
            }


        case EMPTY_EPISODE_REDIRECTION_PAGE:
            return {
                ...state,
                redirectionPage_adding_error: action.payload,
                redirectionPage_adding_sucessfully: action.payload,
                item: action.payload,
            }

        case ADD_DRAG_DROP_PARENT_REDIRECTION_PAGE:
            return {
                ...state,
                dragDropitem: action.payload
            }


        case EMPTY_DRAG_DROP_PARENT_REDIRECTION_PAGE:
            return {
                ...state,
                redirectionPage_error: '',
                redirectionPage_sucessfully: '',
                dragDropitem: '',
            }

        case EXCHANGE_DATA:
            return {
                ...state,
                item: action.payload
            }


        case CLEAR_EXCHANGE_DATA:
            return {
                ...state,
                clear_data_error: action.payload,
                clear_data_sucessfully: action.payload,
                item: action.payload,
            }
        default:
            return state
    }

}
