import React, { Component } from 'react'
import { getIssueComments } from '../../Actions/CommentActions';
import { connect } from 'react-redux'
import Store from '../../Store'
import userpic from '../../images/user-pic.jpg'
import { Redirect } from 'react-router-dom'
/* Routes */
import { SIGN_IN } from '../../routes';
import Loader from '../Layout/Loader';

function publishDateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + " " + date.getHours() + ":" + date.getMinutes();
    return date;
}

class GetCommentIssueList extends Component {

    constructor(props) {
        super(props)

    }
    componentDidMount() {
        let id = this.props.id;
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        console.log("/********didmount comment*////////")
        this.props.getIssueComments(this.props.issue.id)
    }

    // componentWillReceiveProps(props) {
    //     this.props.getIssueComments(props.issueId)
    //
    //  }


    render() {

        const { comments, loading } = this.props;
        let currentUserId = Store.getState().firebase.auth.uid;

        let commentsList = null;
        console.log(loading)

        console.log("getCommentIssueList")
        console.log(comments)

        if (loading == true) {
            commentsList = (<Loader />)
        } else {
            if (comments !== undefined || comments !== null) {
                commentsList = comments.map(comment => {

                    var date = new Date(comment.createdDate + " UTC");
                    var d = new Date(date.toString())
                    var createdDate = publishDateFormate(d)



                    if (comment.createdByUid !== undefined) {


                        let xt = comment.text
                        let txt = xt.split(/\n/g);
                        const text = []
                        for (var i = 0; i < txt.length; i++) {
                            text.push(<span >{txt[i]}<br /></span>)
                        }

                        if (
                            comment.createdByUid.user_role.role === "USER"
                        ) {


                            return (<div class="incoming_msg">
                                <div class="incoming_msg_img"> <img src={userpic} alt="pic" /> </div>
                                <div class="received_msg">
                                    <div class="received_withd_msg">
                                        <p>{text}</p>
                                        <span class="time_date"> {createdDate}        |     {comment.createdByUid.name}   |&nbsp;{comment.createdByUid.email} </span> </div>
                                </div>
                            </div>)
                        }
                        else { // any admin role
                            return (
                                <div class="outgoing_msg">
                                    <div className="sent_msg">

                                        <p>{text}</p>
                                        <span class="time_date"> {createdDate}       |     {comment.createdByUid.name}</span> </div>
                                </div>
                            )

                        }
                    }
                })
            }
        }
        return (
            <div>
                {commentsList}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    issue: state.issues.item,
    comments: state.comments.items,
    loading: state.comments.loading,

    auth: state.firebase.auth
})
export default connect(mapStateToProps, { getIssueComments })(GetCommentIssueList)
