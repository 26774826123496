import {
    ERROR_GET_USER_CODE_DATA,
    GET_USER_CODE_DATA,
    LOAD_GET_USER_CODE_DATA,
    EMPTY_USER_PROFILE,
    EMPTY_USER_PROFILE_WITH_ITEM,
    SUCCESS_CANCEL_USER_RENEWAL_SUPSCRIPTION,
    ERROR_CANCEL_USER_RENEWAL_SUPSCRIPTION,
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},

    loading: '',
    getting_user_payment_data_error: '',
    getting_user_payment_data_sucessfully: '',

    user_payment_cancel_subscription_error: '',
    user_payment_cancel_subscription_sucessfully: ''
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_USER_CODE_DATA:
            return {
                ...state,
                item: action.payload,
                getting_user_payment_data_error: '',
                getting_user_payment_data_sucessfully: true,
                loading: false
            }

        case ERROR_GET_USER_CODE_DATA:
            return {
                ...state,
                getting_user_payment_data_error: action.payload,
                getting_user_payment_data_sucessfully: false,
                loading: false
            }

        case LOAD_GET_USER_CODE_DATA:
            return {
                ...state,
                loading: true
            }

        case EMPTY_USER_PROFILE:
            return {
                ...state,

                getting_user_payment_data_error: '',
                getting_user_payment_data_sucessfully: '',

                user_payment_cancel_subscription_error: '',
                user_payment_cancel_subscription_sucessfully: ''
            }

        case EMPTY_USER_PROFILE_WITH_ITEM:
            return {
                ...state,
                item: {},
                getting_user_payment_data_error: '',
                getting_user_payment_data_sucessfully: '',

                user_payment_cancel_subscription_error: '',
                user_payment_cancel_subscription_sucessfully: ''
            }

        case LOAD_GET_USER_CODE_DATA:
            return {
              ...state,
                loading: true,
                getting_user_payment_data_error: '',
                getting_user_payment_data_sucessfully: '',
            }

        case SUCCESS_CANCEL_USER_RENEWAL_SUPSCRIPTION:
            return {
                ...state,
                //item: action.payload,
                user_payment_cancel_subscription_error: '',
                user_payment_cancel_subscription_sucessfully: true,
                loading: false
            }

        case ERROR_CANCEL_USER_RENEWAL_SUPSCRIPTION:
            return {
                ...state,
                user_payment_cancel_subscription_sucessfully: false,
                user_payment_cancel_subscription_error: action.payload,
                loading: false
            }

        default:
            return state

    }

}
