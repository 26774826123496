import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Redirect, Route } from 'react-router-dom'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
/* Components */
import GetAllBanners from './GetAllBanners';
import AddBanner from './AddBanner';

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';

/* Routes */
import {SIGN_IN, ALL_BANNERS,
        ADD_BANNER,
        EDIT_BANNER} from '../../routes';
import EditBanner from './EditBanner'

class BannerAuth extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    return (
      <span>
          {/* Reporting */}
          <Route path={ALL_BANNERS} component={GetAllBanners} />
          <Route path={ADD_BANNER} component={AddBanner} />
          <Route path={EDIT_BANNER} component={EditBanner} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(BannerAuth)
);
