import {
    EMPTY_ADMIN, ERROR_FETCH_ADMIN, FETCH_ADMIN,
    FETCH_ALL_ADMINS, LOAD_ALL_ADMINS, ERROR_ALL_ADMINS,
    LOAD_ADMIN, ERROR_DELETING_ADMIN, DELETING_ADMIN,
    ERROR_SETTING_STATE_ADMIN, SETTING_STATE_ADMIN,
    ERRROR_ADMIN_RESET_PASSWORD, ADMIN_RESET_PASSWORD,
    ERROR_CHANGE_ADMIN_ROLE, CHANGE_ADMIN_ROLE
} from './Types';

import { post } from 'axios';

export const fetchAllAdmins = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ALL_ADMINS,
                payload: 'loading'
            })

            const url = "/User/findAllNormalAdmins"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: FETCH_ALL_ADMINS,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ALL_ADMINS,
                    payload: Error.message
                })
            })

        })

    }
}

export const setStateAdmin = (uid, Status) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ADMIN,
                payload: 'loading'
            })

            const url = "/User/AdminSetState";
            const formData = new FormData();
            formData.append('User_UID', uid);
            formData.append('Status', Status);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SETTING_STATE_ADMIN,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_STATE_ADMIN,
                    payload: Error.message
                })
            })
        })

    }
}

// changeing admin password in Firebase and in local database
export const adminResetPassword = (uid, email) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: ADMIN_RESET_PASSWORD,
            payload: ''
        })

        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail(email).then( () => {
          alert('An e-mail has been sent to Admin');
        }).catch(function(error) {
          console.log('Error fetching user data:', error);
        });
    }
}

export const emptyAdmin = () => dispatch => {
    dispatch({
        type: EMPTY_ADMIN,
        payload: ''
    })
}

// changeing admin password in Firebase and in local database
export const adminForgetPassword = (email) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: LOAD_ADMIN,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().sendPasswordResetEmail(email).then( () => {
          //  alert('Please check your mail');
        }).catch(function(error) {
          console.log('Error fetching user data:', error);
        });
    }
}

export const deleteAdmin = (uid) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ADMIN,
                payload: 'loading'
            })

            const url = "/User/deleteAdmin";
            const formData = new FormData();
            formData.append('User_UID', uid);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_ADMIN,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_ADMIN,
                    payload: Error.message
                })
            })
        })

    }
}


export const getAdminInfo = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ADMIN,
                payload: 'loading'
            })

            const url = "/User/getUserData";
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: FETCH_ALL_ADMINS,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ALL_ADMINS,
                    payload: Error.message
                })
            })
        })

    }
}

export const changeAdminRole = (uid, role) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ADMIN,
                payload: 'loading'
            })

            const url = "/User/changeUserRole";
            const formData = new FormData();
            formData.append('UID', uid);
            formData.append('Role', role);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: CHANGE_ADMIN_ROLE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_CHANGE_ADMIN_ROLE,
                    payload: Error.message
                })
            })
        })

    }
}
