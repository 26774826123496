import {
    ERROR_GET_FAWRY_PAYMENT_PACKAGE,
    GET_FAWRY_PAYMENT_PACKAGE,
    LOAD_GET_FAWRY_PAYMENT_PACKAGE,
    EMPTY_FAWRY_PAYMENT_PACKAGE
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    loading: '',

    getting_fawry_prices_error: '',
    getting_fawry_prices_sucessfully: '',
    }

export default function (state = initialState, action) {
    switch (action.type) {

        case LOAD_GET_FAWRY_PAYMENT_PACKAGE:
            return {
                ...state,
                loading: true
            }

        case GET_FAWRY_PAYMENT_PACKAGE:
            return {
                ...state,
                item: action.payload,
                getting_fawry_prices_error: '',
                getting_fawry_prices_sucessfully: true,
                loading: false
            }
            case ERROR_GET_FAWRY_PAYMENT_PACKAGE:
            return {

                ...state,
                getting_fawry_prices_error: action.payload,
                loading: false
            }
            case EMPTY_FAWRY_PAYMENT_PACKAGE:
                return {
                    ...state,
                    getting_fawry_prices_error: '',
                    getting_fawry_prices_sucessfully: '',
                    item: {},
                }
    
            default:
                return state
    
        }
    
    }