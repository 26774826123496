import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withFirebase } from '../../config';
/*Ccomponents */
import EvaluationBlogs from './EvaluationBlogs';
import UploadFile from '../S3Uploading/UploadFile';
/*Actions */
import { addEvaluationToRedux } from '../../Actions/EvaluationActions';
import { emptyEvaluationSucessAndfailure } from '../../Actions/EvaluationActions'
import { saveEvaluation } from '../../Promises/EvaluationPromise.js'

class EvaluationForm extends Component {

    state = {
        checkedBlogs: new Map(),

        start_number: 0,
        end_number: 0,
        recommendation: "",
        fileURL: "",
        articleURL: "",

        showfileUploader: true,
        showArticleUploader: true,
        errorMessage: "",
        error: true


    };


    componentWillReceiveProps() {

        const { copyFileURL } = this.props;
        const { copyArticleURL } = this.props;
        const { start } = this.props;
        const { end } = this.props;
        const { recommendation } = this.props;
        const { errorMessage } = this.props;

        if (errorMessage !== ""
            && errorMessage !== null
            && errorMessage !== undefined
            &&
            (this.state.errorMessage == ""
                || this.state.errorMessage == null
                || this.state.errorMessage == undefined)
        ) {
            this.setState({
                errorMessage: errorMessage,
            })

        } else {
            this.setState({
                errorMessage: ""
            })
        }

        if (copyFileURL !== ""
            && copyFileURL !== null
            && copyFileURL !== undefined
            &&
            (this.state.fileURL == ""
                || this.state.fileURL == null
                || this.state.fileURL == undefined)
        ) {

            this.setState({
                fileURL: copyFileURL,
                showfileUploader: false

            })

        }

        if (copyArticleURL !== ""
            && copyArticleURL !== null
            && copyArticleURL !== undefined
            &&
            (this.state.articleURL == ""
                || this.state.articleURL == null
                || this.state.articleURL == undefined)
        ) {

            this.setState({
                articleURL: copyArticleURL,
                showArticleUploader: false

            })
        }


        if (start !== ""
            && start !== null
            && start !== undefined
            &&
            (this.state.start_number == 0
                || this.state.start_number == null
                || this.state.start_number == undefined)
        ) {
            this.setState({
                start_number: start,
            })
        }



        if (end !== ""
            && end !== null
            && end !== undefined
            &&
            (this.state.end_number == ""
                || this.state.end_number == null
                || this.state.end_number == undefined)
        ) {
            this.setState({
                end_number: end,
            })
        }

        if (recommendation !== ""
            && recommendation !== null
            && recommendation !== undefined
            &&
            (this.state.recommendation == ""
                || this.state.recommendation == null
                || this.state.recommendation == undefined)
        ) {
            this.setState({
                recommendation: recommendation,
            })
        }


    }




    getCheckedBlogs(checkedItems) {

        if (checkedItems) {
            this.setState({ checkedBlogs: checkedItems });
            console.log("getCheckedBlogs in EvaluationForm = ");
            console.log(checkedItems);
        }
    }

    getFileUrl(fileURL) {
        if (fileURL) {
            this.setState({
                fileURL: fileURL,
                showfileUploader: false,
                errorMessage: "",

            });
            console.log("EvaluationForm fileUrl")
            console.log(fileURL);
            this.props.returnCategoryFileURLCallBack(fileURL);

        }

    }

    getArticleUrl(articleURL) {
        if (articleURL) {
            this.setState({
                articleURL: articleURL,
                showArticleUploader: false,
                errorMessage: "",

            });
            console.log("EvaluationForm articleURL")
            console.log(articleURL);
            this.props.returnCategoryArticleURLCallBack(articleURL);

        }

    }

    handleChange = (e) => {
        if (e.target.name === "start_number") {
            this.props.returnedStart(e.target.value);

        }
        else if (e.target.name === "end_number") {
            this.props.returnedEnd(e.target.value);

        }
        else if (e.target.name === "recommendation") {
            this.props.returnedRecommendation(e.target.value);

        }

        switch (e.target.name) {
            default:
                this.setState({
                    [e.target.name]: e.target.value,
                    errorMessage: "",
                });
        }
    }

    handleSubmit = (e) => {
        const { category_type } = this.props;
        console.log(category_type);

        /////////mapping the value of customers uid to array
        const { checkedBlogs } = this.state;
        const { start_number, end_number, recommendation, fileURL, articleURL } = this.state;

        var selectedBlogsIDs = [];
        var get_keys = checkedBlogs.keys();

        for (var ele of get_keys) {
            selectedBlogsIDs.push(ele);
        }

        for (let i = 0; i < selectedBlogsIDs.length; i++) {
            console.log(selectedBlogsIDs[i]);
        }

        if (start_number === '' || start_number == undefined || start_number < 0) {
            this.setState({ error: true, errorMessage: "Empty Start Number" });
        }
        else if (end_number === '' || end_number == undefined || end_number == 0) {
            this.setState({ error: true, errorMessage: "Empty End Number" });

        }
        else if (recommendation === '' || recommendation == undefined) {
            this.setState({ error: true, errorMessage: "Empty Recommendation" });

        }
        else if (fileURL === '' || fileURL == undefined) {
            this.setState({ error: true, errorMessage: "Empty Program File" });
        }
        else if (articleURL === '' || articleURL == undefined) {
            this.setState({ error: true, errorMessage: "Empty article File" });
        }
        else {

            this.props.addEvaluationToRedux(start_number, end_number,
                recommendation, fileURL, selectedBlogsIDs, category_type, articleURL);
        }
        e.preventDefault();
        // this.setState({
        //     start_number: '',
        //     end_number: '',
        //     recommendation:'',
        //     fileURL: ''
        // })

    }


    handleFileUploaderPreview = (e) => {
        const { showfileUploader } = this.state;

        if (showfileUploader === true) {
            this.setState({
                showfileUploader: false
            })
        }
        else {
            this.setState({
                showfileUploader: true
            })
        }
    }


    handleArticleUploaderPreview = (e) => {
        const { showArticleUploader } = this.state;

        if (showArticleUploader === true) {
            this.setState({
                showArticleUploader: false
            })
        }
        else {
            this.setState({
                showArticleUploader: true
            })
        }
    }


    render() {
        const { start_number, end_number,
            recommendation,
            fileURL,
            articleURL,
            showArticleUploader,
            showfileUploader } = this.state;

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyEvaluationSucessAndfailure();

        }

        else if (success === true) {
            alert("Evaluation Added Successfully")
            this.props.emptyEvaluationSucessAndfailure();
        }

        let fileLink;
        let articleLink;

        const isInvalidEvaluation =
            start_number === '' ||
            recommendation === '' ||
            fileURL === '' ||
            articleURL === '' ||
            end_number === '';

        if (fileURL !== undefined &&
            fileURL !== null &&
            fileURL !== '') {
            fileLink = (
                <span>
                    <span > Program Link : {fileURL} </span>
                    <div className="float-right">
                        <Popup trigger=
                            {<FontAwesomeIcon
                                icon='edit'
                                className="far fa-edit" onClick={this.handleFileUploaderPreview}>
                            </FontAwesomeIcon>
                            }
                            content="Upload File"
                            position="top center" />
                    </div>
                </span>
            );
        }


        let uploadFile = (<UploadFile
            getFileURLCallBack={this.getFileUrl.bind(this)}
            fileURL={this.state.fileURL}
            s3DirectryrName='evaluation' />
        )

        if (showfileUploader == false) {
            uploadFile = (
                <span>
                    <div className="d-none">
                        <UploadFile
                            getFileURLCallBack={this.getFileUrl.bind(this)}
                            fileURL={this.state.fileURL}
                            s3DirectryrName='evaluation' />
                    </div>
                    <br />
                    <br />
                </span>
            )

        }


        if (articleURL !== undefined &&
            articleURL !== null &&
            articleURL !== '') {
            articleLink = (
                <span >Article Link : {articleURL}
                    <div className="float-right">
                        <Popup trigger=
                            {<FontAwesomeIcon
                                icon='edit'
                                className="far fa-edit" onClick={this.handleArticleUploaderPreview}>
                            </FontAwesomeIcon>
                            }
                            content="Upload Article"
                            position="top center" />
                    </div>
                </span>
            );
        }

        let uploadArticle = (

            <UploadFile
                getFileURLCallBack={this.getArticleUrl.bind(this)}
                fileURL={this.state.articleURL}
                s3DirectryrName='evaluation' />


        )

        if (showArticleUploader == false) {
            uploadArticle = (
                <span>
                    <div className="d-none">
                        <UploadFile
                            getFileURLCallBack={this.getArticleUrl.bind(this)}
                            fileURL={this.state.articleURL}
                            s3DirectryrName='evaluation' />
                    </div>
                    <br />
                    <br />
                </span>
            )

        }

        return (
            <div>

                <div className="card-body">
                    <p style={{ color: "red" }}>{this.state.errorMessage} </p>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="small-label">
                                <span className="required_input">*</span>
                                add Start Number</label>
                            <input className="form-control" type="number"
                                name="start_number" id="start_number"
                                onChange={this.handleChange}
                                value={this.state.start_number} />
                        </div>

                        <div className="form-group col-md-6">
                            <label className="small-label">
                                <span className="required_input">*</span>
                                add End Number</label>
                            <input className="form-control" type="number"
                                name="end_number" id="end_number"
                                onChange={this.handleChange}
                                value={this.state.end_number} />
                        </div>

                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label className="small-label"
                                for="exampleFormControlTextarea1">
                                <span className="required_input">*</span>
                                Recommendation:
                            </label>
                            <textarea
                                dir="auto"
                                className="form-control"
                                id="exampleFormControlTextarea1" rows="3"
                                name="recommendation" id="recommendation"
                                onChange={this.handleChange}
                                value={this.state.recommendation} ></textarea>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="input-group col-md-12 d-block">
                            <label className="small-label">Related articles</label>
                            <div>
                                <EvaluationBlogs
                                    getListOfCheckedBlogsCallBack={this.getCheckedBlogs.bind(this)} />
                            </div>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="form-group col-md-12">

                            <label className="small-label">
                                <span className="required_input">*</span>
                                Program File </label>
                            <br />
                            {fileLink}

                            {uploadFile}

                            <br />

                            <label className="small-label">
                                <span className="required_input">*</span>
                                Article File </label>
                            <br />
                            {articleLink}
                            {uploadArticle}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="float-right">
                                <button className="btn btn-lg btn-success btn-suc btn-block-sm"
                                    onClick={this.handleSubmit}
                                    disabled={isInvalidEvaluation}
                                >Save</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}

EvaluationForm.propTypes = {
    addEvaluationToRedux: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    evaluation: state.evaluation.items,
    error: state.evaluation.adding_Evaluation_error,
    success: state.evaluation.adding_Evaluation_sucessfully
});
export default withFirebase
    (connect
        (mapStateToProps,
            { addEvaluationToRedux, emptyEvaluationSucessAndfailure }
        )
        (EvaluationForm)
    );
