import React, { Component } from 'react'
import { withFirebase } from '../../config';
import { Input, Button, Modal } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  Popup } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/**Other Component */
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone'
import Loader from '../Layout/Loader';

/*Actions */
import {
    updateSubValue,
    emptySubValues
} from '../../Actions/ContentSubValueAction'

import {
    fetchAllMainValues,
} from '../../Actions/ContentMainValuesAction';

class EditSubValue extends Component {

    state = {

        showModal: '',
        /////////// FAQ
        id: '',
        text: '',
        text_in_english: '',
        number: 0,
        imageUrl: ''

    };

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    updateSubValue(id, text, imageUrl, number, text_in_english) {
        this.props.updateSubValue(id, text, imageUrl, number, text_in_english);
    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleSubmit = (e) => {

        const { id, text, imageUrl, number, text_in_english } = this.state;

        this.updateSubValue(id, text, imageUrl, number, text_in_english);
        
        e.preventDefault();
    }

    closeModal = (e) => {
        this.setState({ showModal: false })
    }

    openModal = (e) => {
        this.setState({ showModal: true })

        const { subValue } = this.props;

        this.setState({
            id: subValue.id,
            text: subValue.contentValue,
            imageUrl: subValue.imageUrl,
            number: subValue.number,
            text_in_english: subValue.contentValueInEnglish,
        });
    }

    getImageUrl(image) {
        if (image) {
            this.setState({ imageUrl: image })
        }
    }


    render() {
        const { text, number, imageUrl, text_in_english } = this.state;

        const { error } = this.props;
        const { success } = this.props;
        const { loading } = this.props;

        const isInvalidText =
            text === '' ||
            number === 0 ||
            imageUrl === ''||
            text_in_english === ''||
            text_in_english === null;

        let $buttonSubmitLoader = (
            <Button onClick={this.handleSubmit}
                disabled={isInvalidText}
                id="saveButton" >
                Save
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="saveButton"
                    class="ui loading button">
                    Loading</button>);
        }

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptySubValues();
        }
        else if (success === true) {
            alert("Sub Value updated Successfully")

            this.closeModal();
            this.props.emptySubValues();
            this.props.fetchAllMainValues();

            this.setState({
                text: '',
                number: 0,
                imageUrl: '',
                text_in_english: '',
            })

        }



        return (
            
            <Modal id="modal"
                trigger={
                    <Popup trigger=
                        {<FontAwesomeIcon //className="far fa-trash-alt"
                            icon='edit'
                            className="far fa-edit" onClick={this.openModal}>
                        </FontAwesomeIcon>
                        }
                        content="Edit"
                        position="top center" />

                }
                onClose={this.closeModal} open={this.state.showModal}>


                <Modal.Header>Update Sub Value</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">

                                    {/* image Uploader */}
                                    <div className="col-md-4 col-12">
                                        <div className="">
                                            <span className="required_input">*</span>
                                            <UploadImageWithoutDropZone getImageURLCallBack={this.getImageUrl.bind(this)}
                                                imageUrl={this.state.imageUrl}
                                                imagepreview={this.state.imageUrl}
                                                s3DirectryrName='contentvalue' />
                                        </div>

                                    </div>

                                    <div className="col-md-8 col-12">

                                        <label htmlFor="text" className="label">
                                            <span className="required_input">*</span>
                                            Text</label>
                                        <input fluid name="text" id="text"
                                            dir="auto"
                                            className="form-control"
                                            onChange={this.handleChange}
                                            value={this.state.text} />
                                        <br />

                                        <label htmlFor="text_in_english" className="label">
                                            <span className="required_input">*</span>
                                            Text In English</label>
                                        <input fluid name="text_in_english" id="text_in_english"
                                            dir="auto"
                                            className="form-control"
                                            onChange={this.handleChange}
                                            value={this.state.text_in_english} />
                                        <br />

                                        <label htmlFor="number"
                                            className="label">
                                            <span className="required_input">*</span>
                                            Number</label>
                                        <br />
                                        <Input fluid type="number" min="0"
                                            name="number" id="number"
                                            onChange={this.handleChange}
                                            value={this.state.number} />
                                        <br />

                                    </div>

                                </div>

                                <div className="input-field">

                                    {$buttonSubmitLoader}

                                </div>
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

EditSubValue.propTypes = {
    updateSubValue: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
    showModal: state.ContentSubValue.showModal,
    error: state.ContentSubValue.error_updating_sub_value,
    success: state.ContentSubValue.sub_value_updated_successfully,
    loading: state.ContentSubValue.loading
});


export default withFirebase(
    connect(
        mapStateToProps,
        {
            updateSubValue,
            emptySubValues,
            fetchAllMainValues
        }
    )
        (EditSubValue)
);

