import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import "react-phone-number-input/style.css";
import PhoneInput ,{isValidPhoneNumber }from "react-phone-number-input";
import { useLocation } from "react-router-dom";
import { getName } from "country-list";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
//import ar from "react-phone-number-input/locale/ar";

const INITIAL_STATE = {
  phoneNumber: "",
  showPassword: false,
  showConfirmPassword: false,
  email: "",
  password: "",
  confirmPassword: "",
  passwordError: "",
  strongPasswordError: "",
  emailError:"",
  phoneError:"",
  checkConfirmPassword: false,
  phoneNumberIsValid: false,
  confirmPhoneNumber: false,
  country: "EG",
};

class JeelAuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  componentDidMount(){
    this.getCurrentCountryFromUrl();
  }
  getCurrentCountryFromUrl = () => {
    const { search } = this.props.location;
    const query = new URLSearchParams(search);
    const codetest = query.get("x-test-country-code-x");
    if(codetest != null || codetest != undefined){
      this.setState({ country: codetest });
    }
  }
  handleChangeNumber = (val) => {

    if(!isValidPhoneNumber(val)){
      this.setState({phoneError:'رقم الهاتف غير صحيح'});
      this.setState({confirmPhoneNumber: false});
    }

    if(isValidPhoneNumber(val)){
      this.setState({phoneError:''})
      this.setState({confirmPhoneNumber: true});
    }

    this.setState({ phoneNumber: val }, () => {
      // Pass values to parent component
      this.props.onData(this.state.email,
         this.state.password,
         this.state.phoneNumber,
         this.state.checkConfirmPassword,
         this.state.confirmPhoneNumber);
    })

    if(!val){
      this.setState({phoneError:' رقم الهاتف مطلوب'});
    }

  };

  hideShowPassword = (inputName) => {
    if (inputName == "showPassword") {
      this.setState({ showPassword: !this.state.showPassword });
    }
    if (inputName == "showConfirmPassword") {
      this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
    }
  };



  handleChange = (e) => {
    this.setState({ email: e.target.value}, () => {
      // Pass values to parent component
      this.props.onData(this.state.email, 
                        this.state.password, 
                        this.state.phoneNumber,
                        this.state.checkConfirmPassword,
                        this.state.confirmPhoneNumber);
    });

    this.setState({emailError:''});
    if(e.target.value.length === 0){
      this.setState({emailError:'البريد الإلكتروني مطلوب'});      
    }

  };

  handleConfirmPassword = (e) => {
    // const {name, value} = e.target;
    const {password} = this.state;
    let passwordError = '';

    if(password === e.target.value){
      this.setState({checkConfirmPassword: true}, () => {
        this.props.onData(this.state.email, 
                          this.state.password, 
                          this.state.phoneNumber,
                          this.state.checkConfirmPassword,
                          this.state.confirmPhoneNumber);
      });
    }else{
      passwordError = 'كلمة المرور غير مطابقة'
      this.setState({checkConfirmPassword: false}, ()=>{
        this.props.onData(this.state.email, 
                          this.state.password, 
                          this.state.phoneNumber,
                          this.state.checkConfirmPassword,
                          this.state.confirmPhoneNumber);
      });
    }

    this.setState({
      confirmPassword: e.target.value,
      passwordError,
    });
  };

  handleStrongPassword = (e) => {
  
    const {confirmPassword} = this.state;
    let passwordError = '';
    //const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[#?!@$%^&*\\-_])(?=.{8,})/;
    let strongPasswordError = '';
    if(e.target.value.length < 8){
      strongPasswordError = 'كلمة المرور أقل من 8 أحرف'
    }
    if(confirmPassword === e.target.value){
      this.setState({checkConfirmPassword: true}, ()=>{
        this.props.onData(this.state.email, 
                          this.state.password, 
                          this.state.phoneNumber,
                          this.state.checkConfirmPassword,
                          this.state.confirmPhoneNumber);
      });
    }else{
      passwordError = 'كلمة المرور غير مطابقة'
      this.setState({checkConfirmPassword: false}, ()=> {
        this.props.onData(this.state.email, 
                          this.state.password, 
                          this.state.phoneNumber,
                          this.state.checkConfirmPassword,
                          this.state.confirmPhoneNumber);
      });
    }

    this.setState({
      password: e.target.value,
      strongPasswordError,
      passwordError,

    });

  };

  render() {
    const { email, password, confirmPassword, passwordError, strongPasswordError, error,phoneNumber, emailError, phoneError } = this.state;
    return (
      <>
        {<div className="error" style={{ color: 'red' }}>{emailError}</div>}
        <div className="form-group">
          <label>البريد الالكتروني</label>
          <input className="form-control" type="email" value={email} name="email" 
          onChange={this.handleChange}
          />
        </div>
        {<div className="error" style={{ color: 'red' }}>{phoneError}</div>}
        <div className="form-group">
          <label>رقم الهاتف</label>
          <PhoneInput
            international
            value={this.state.phoneNumber}
            onChange={(val) => this.handleChangeNumber(val)}
            className="phoneNumberInput"
            required
            defaultCountry={this.state.country}
          />
        </div>
        <div className="form-group">
          <label>كلمة المرور</label>
          <div
            className={`input-with-icon ${
              this.state.showPassword ? "text" : "password"
            }`}
          >
            <input
              className="form-control"
              type={`${this.state.showPassword ? "text" : "password"}`}
              name="password"
              value={password}
              onChange={this.handleStrongPassword}
            />
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.hideShowPassword("showPassword");
              }}
              className="toggle-password input-icon bg-none"
            >
              <FontAwesomeIcon icon={faEye} className="faEye" />
              <FontAwesomeIcon icon={faEyeSlash} className="faEyeSlash" />
            </button>
          </div>
          {strongPasswordError && <div className="error" style={{ color: 'red' }}>{strongPasswordError}</div>}
        </div>
        
        <div className="form-group">
          <label>تاكيد كلمة المرور</label>
          <div
            className={`input-with-icon ${
              this.state.showConfirmPassword ? "text" : "password"
            }`}
          >
            <input
              className="form-control"
              type={`${this.state.showConfirmPassword ? "text" : "password"}`}
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.handleConfirmPassword}
            />
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.hideShowPassword("showConfirmPassword");
              }}
              className="toggle-password input-icon bg-none"
            >
              <FontAwesomeIcon icon={faEye} className="faEye" />
              <FontAwesomeIcon icon={faEyeSlash} className="faEyeSlash" />
            </button>
          </div>
          {passwordError && <div className="error" style={{ color: 'red' }}>{passwordError}</div>}
        </div>

      </>
    );
  }
}

export default withRouter((JeelAuthForm));