import { FETCH_BLOGS, LOAD_BLOGS, ERROR_LOADING_BLOGS, PUBLISH_BLOG, ERROR_ADDING_BLOG, GET_BLOG_DATA, ERROR_LOADING_BLOG, LOAD_BLOG, UPDATE_BLOG, ERROR_UPDATING_BLOG, EMPTY_BLOG, DELETE_BLOG, ERROR_DELETING_BLOG, SETTING_BLOG_STATUS, ERROR_SETTING_BLOG_STATUS } from "../Actions/Types";


const initialState = {
    items:[],
    item:{},
    error_loading_blogs:'',
    loading:'',
    error_adding_blog:'',
    blog_added_successfully:'',
    blog_updated_successfully:'',
    error_updating_blog:'',
    blog_deleted_successfully:'',
    error_deleting_blog:'',
    status_updated_successfully:'',
    error_updating_status:''
}

export default function (state=initialState,action)
{
    switch(action.type)
    {
        case FETCH_BLOGS:
            return{
                ...state,
                items:action.payload,
                error_loading_blogs:'',
                loading:false
            }
        case LOAD_BLOGS:
            return {
                ...state,
                loading:true
            }
        case ERROR_LOADING_BLOGS:
            return{
                ...state,
                error_loading_blogs:action.payload,
                loading:false
            }
        case EMPTY_BLOG:
            return{
                ...state,
                error_adding_blog:action.payload,
                blog_added_successfully:action.payload,
                error_loading_blog:action.payload,
                blog_updated_successfully:action.payload,
                blog_deleted_successfully:'',
                error_deleting_blog:'',
                status_updated_successfully:'',
                error_updating_status:'',
                error_updating_blog:''
            }

        case PUBLISH_BLOG:
            return {
                ...state,
                item:action.payload,
                blog_added_successfully:true

            }
            case ERROR_ADDING_BLOG:
            return{
                ...state,
                error_adding_blog:action.payload,
                blog_added_successfully:false
            }

            case GET_BLOG_DATA:
                return{
                    ...state,
                    item:action.payload,
                    error_loading_blog:'',
                    loading:false
                }
            case ERROR_LOADING_BLOG:
                return{
                    ...state,
                    error_loading_blog:action.payload,
                    loading:false
                }
            case LOAD_BLOG:
                return{
                    ...state,
                    loading:true
                }
            case UPDATE_BLOG:
                return{
                    ...state,
                    item:action.payload,
                    blog_updated_successfully:true
                }
            case ERROR_UPDATING_BLOG:
                return{
                    ...state,
                    error_updating_blog: action.payload,
                    loading: false
                }
            case DELETE_BLOG:
                return{
                    ...state,
                    blog_deleted_successfully:true,
                    item:action.payload
                }

            case ERROR_DELETING_BLOG:
                return {
                    ...state,
                    blog_deleted_successfully:false,
                    error_deleting_blog:action.payload
                }
            case SETTING_BLOG_STATUS:
                return {
                    ...state,
                    status_updated_successfully:true,
                    item:action.payload,
                }
            case ERROR_SETTING_BLOG_STATUS:
                return {
                    ...state,
                    status_updated_successfully:false,
                    error_updating_status:action.payload
                }
         default:
             return state

    }
}