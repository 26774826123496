import React, { Component } from 'react'
import { setSongStatus, emptySong } from '../../Actions/SongActions'

import { getSongParentData } from '../../Actions/SongParentAction';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

class SetSongStatus extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setSongStatus(id, staus) {
        this.props.setSongStatus(id, staus)
    }

    handleSubmit = (e) => {

        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setSongStatus(id, "DEFAULT");

        } else {

            this.setSongStatus(id, "SUSPENDED");
        }
        this.close();
    }
    render() {
        const { song_status_updated_successfully,
            error_setting_status } = this.props
        const { id, status, SongParentId } = this.props

        let contentPreview = null;

        if (song_status_updated_successfully === false) {
            alert("Error: " + error_setting_status);
            this.props.emptySong();
            this.props.getSongParentData(SongParentId);
        }
        else if (song_status_updated_successfully === true) {
            alert("Song status updated successfully");
            this.props.emptySong();
            this.props.getSongParentData(SongParentId);

        }

        if (status === "SUSPENDED") {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>
                        <i className="" >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )
        }
        else {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons" 
                    onClick={this.open}>||</button>}
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }

        return (<span >
            {contentPreview}
            <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
        </span>)
    }
}

const mapStateToProps = state =>
({
    song_status_updated_successfully: state.songs.song_status_updated_successfully,
    error_setting_status: state.songs.error_setting_status
})
export default connect
    (mapStateToProps,
        {
            setSongStatus,
            emptySong,
            getSongParentData
        })
    (SetSongStatus)
