import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom';

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    deleteStoryParent,
    fetchAllStoryParent,
    emptyError
} from '../../Actions/StoryParentAction'
import { ALL_STORIES_PARENTS } from '../../routes';


class DeleteStoryParent extends Component {
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteStoryParent(id) {
        this.props.deleteStoryParent(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        this.deleteStoryParent(id);
        this.close();

    }


    render() {
        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();

        }
        else if (success === true) {
            alert("Parent Story Deleted Successfully")
            this.props.emptyError();
            this.props.fetchAllStoryParent();
            return (<Redirect to={ALL_STORIES_PARENTS}></Redirect>)
        }

        return (
            <div >
                <button className="seriesCardButtons" onClick={this.open}>
                    <i>
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal"
                    content='Are you sure you want to delete this Parent Story ??'
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </div>

        )
    }
}


DeleteStoryParent.propTypes = {
    fetchAllStoryParent: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    error: state.storyParent.deleting_story_parent_error,
    success: state.storyParent.deleting_story_parent_sucessfully
});

export default withFirebase(
    connect
        (mapStateToProps,
            {
                fetchAllStoryParent,
                deleteStoryParent,
                emptyError
            })
        (DeleteStoryParent));

