import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom';

/*Redux */
import firebase from 'firebase/app'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import 'firebase/firestore';
import 'firebase/auth';
/*images */
import logo from "../../images/logo.png";
/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';
import { addTokenToredux } from '../../Actions/loginActions.js'
import { emptyMobileUserProfileWithItem } from '../../Actions/MobilePaymentProfileAction';
/*Redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';


class MobilePaymentLogin extends Component {


    constructor(props) {
        super(props);
        this.props.emptyMobileUserProfileWithItem();


        // adding page to browser history
        this.props.history.push("/MobilePaymentLogin");
    }

    state = { isSignedIn: false, name: '', image: '', token: '' }

    uiConfig = {
        signInFlow: "popup",
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            firebase.auth.PhoneAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            signInSuccess: () => false
        }
    }

    componentDidMount = () => {
        firebase.auth().onAuthStateChanged(user => {
            this.setState({ isSignedIn: !!user })
            //   console.log("user", user)
            //   console.log(!user)
            //   console.log(!!user)

            if (!!user) {
                firebase.auth().currentUser.getIdToken()
                    .then((token) => {
                        this.setState({ token: token });
                        //this.props.addTokenToredux(token);

                    })

                this.setState
                    ({
                        name: firebase.auth().currentUser.displayName,
                        image: firebase.auth().currentUser.photoURL
                    })
            }
        })

    }

    render() {

        const { token, isSignedIn } = this.state;

        // console.log(token);
        if (isSignedIn && token !== '') {

            let redirect = "/MobilePaymentProfile" + "/" + token;
            //console.log(redirect)
            return (<Redirect to={redirect} ></Redirect>)

        }
        return (
            <div className="main-payment">

                <div className="shape-container">
                    <div className="bg-img">
                    </div>
                </div>

                <div className="container">
                    <div className="pay-box">
                        <div className="main-logo text-center h4">
                            <img className="logo" alt="logo" src={logo} />
                            <p className="small-font main-color font">بوابة الدفع لجيل</p>
                        </div>
                        <div className="card card-container Current-Package  login">
                            <div className="card-header profile-name-card text-center
                             font-weight-bold font ">
                                تسجيل الدخول
                            </div>
                            <div className="card-body">

                                {/* /////////////////////////////////// */}
                                <StyledFirebaseAuth
                                    uiConfig={this.uiConfig}
                                    firebaseAuth={firebase.auth()}
                                />

                                {/* /////////////////////////////////// */}

                            </div>
                        </div>
                        <MobilePaymentFooter />
                    </div>
                </div>

            </div>
        )
    }
}


const mapStateToProps = state => ({
    token: state.user.token,

});


export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyMobileUserProfileWithItem,
                addTokenToredux,
            }
        )
        (MobilePaymentLogin)
    );
