import React, { Component } from 'react'
import Loader from '../Layout/Loader';
import PieChart from 'react-minimal-pie-chart';

export default class AgeRangeFilter extends Component {
    state = {
        totalSessionsPerDayValues: [],
        totalTimeUsagePerDayValues :[],
        averageSessionTimeValues:[]
    }

    componentWillReceiveProps(nextprop)
    {

        let totalSessionsPerDayValues = [];
        let totalTimeUsagePerDayValues =[];
        let averageSessionTimeValues = []
        if (nextprop.ages.length===0){
           for (let j =0;j<nextprop.ageFilter.length;j++)
            {
                var letters = '0123456789ABCDEF';
                var color = '#';
                for (var x = 0; x < 6; x++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                if (nextprop.ageFilter[j].kidUsageReport!==null)
                {
                    let pie = { title: nextprop.ageFilter[j].ageRange, value:nextprop.ageFilter[j].kidUsageReport.totalSessionsPerDay, color: color }
                    let pieUsage = { title: nextprop.ageFilter[j].ageRange, value: nextprop.ageFilter[j].kidUsageReport.totalTimeUsagePerDay, color: color }
                    let average = {title:nextprop.ageFilter[j].ageRange,value:nextprop.ageFilter[j].kidUsageReport.averageSessionTime,color:color}
                    totalSessionsPerDayValues.push(pie)
                    totalTimeUsagePerDayValues.push(pieUsage)
                    averageSessionTimeValues.push(average)
                }
            }
        }
        else{
            for (let i =0;i<nextprop.ages.length;i++)
            {
                var letters = '0123456789ABCDEF';
                var color = '#';
                for (var j = 0; j < 6; j++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
               for (let j =0;j<nextprop.ageFilter.length;j++)
                {
                  if (nextprop.ageFilter[j].ageRange == nextprop.ages[i])
                  {
                    let pie = { title: nextprop.ageFilter[j].ageRange, value:nextprop.ageFilter[j].kidUsageReport.totalSessionsPerDay, color: color }
                    let pieUsage = { title: nextprop.ageFilter[j].ageRange, value: nextprop.ageFilter[j].kidUsageReport.totalTimeUsagePerDay, color: color }
                    let average = {title:nextprop.ageFilter[j].ageRange,value:nextprop.ageFilter[j].kidUsageReport.averageSessionTime,color:color}
                    totalSessionsPerDayValues.push(pie)
                    totalTimeUsagePerDayValues.push(pieUsage)
                    averageSessionTimeValues.push(average)
                  }
                }
            }
        }
        this.setState({totalSessionsPerDayValues:totalSessionsPerDayValues,totalTimeUsagePerDayValues:totalTimeUsagePerDayValues,averageSessionTimeValues:averageSessionTimeValues})
    }

    render() {
        console.log(this.state)
        const {kidsUsage,error_loading,loading,ageFilter,error_loading_age_filter,loading_age_filter,ages} = this.props
        let data = null;
        if (loading_age_filter===true || loading===true){
            data = <Loader/>
        }
        else if (ages.length > 1 && error_loading==='' && loading===false ){
          const totalSessionsPerDay = this.state.totalSessionsPerDayValues.reduce((totalSessionsPerDay, totalSessionPerDay) => totalSessionsPerDay + totalSessionPerDay.value, 0);
          const totalTimeUsagePerDay = this.state.totalTimeUsagePerDayValues.reduce((totalTimeUsagePerDay, timeUsagePerDay) => totalTimeUsagePerDay + timeUsagePerDay.value, 0);
          const averageSessionTime = this.state.averageSessionTimeValues.reduce((averageSessionTime, avgSessionTime) => averageSessionTime + avgSessionTime.value, 0);

          data =  <div class="row t-row small-font">
          <div class="col-12 head-row">
              Age Range
           <hr />
          </div>
          <div class="col-md-4">
            <div class="h2 font-weight-bold text-center p-3">{totalSessionsPerDay}</div>
          </div>
          <div class="col-md-4">
            <div class="h2 font-weight-bold text-center p-3">{totalTimeUsagePerDay}</div>
          </div>
          <div class="col-md-4">
            <div class="h2 font-weight-bold text-center p-3">{averageSessionTime}</div>
          </div>
         </div>
         }
         else if (ages.length === 1 && error_loading_age_filter==='' && loading_age_filter===false )
         {
           data = <div class="row t-row small-font">
           <div class="col-12 head-row">
              Age Range
              <hr />
           </div>
           <div class="col-md-4">
            <div class="h2 font-weight-bold text-center p-3">{this.state.totalSessionsPerDayValues[0].value}</div>
           </div>
           <div class="col-md-4">
            <div class="h2 font-weight-bold text-center p-3">{this.state.totalTimeUsagePerDayValues[0].value}</div>
           </div>
           <div class="col-md-4">
            <div class="h2 font-weight-bold text-center p-3">{this.state.averageSessionTimeValues[0].value}</div>
           </div>
          </div>
         }

         else if (error_loading_age_filter==='' && loading_age_filter===false)
         {
           console.log("looooooooooooooooooooooooooool")
             data  = <div class="row t-row">
             <div class="col-12 head-row small-font">
                 Age Range
              <hr />
             </div>
             <div class="col-md-4">
                 <div class="h2 font-weight-bold text-center p-3"><PieChart
                     data={this.state.totalSessionsPerDayValues}
                     label={({ data, dataIndex }) =>
                         Math.round(data[dataIndex].percentage) + '%'
                     } labelStyle={{
                         fontSize: '5px',
                         fontFamily: 'sans-serif',
                         fill: '#121212'
                     }}
                 /></div>
             </div>
             <div class="col-md-4">
                 <div class="h2 font-weight-bold text-center p-3"><PieChart
                     data={this.state.totalTimeUsagePerDayValues}
                     label={({ data, dataIndex }) =>
                         Math.round(data[dataIndex].percentage) + '%'
                     } labelStyle={{
                         fontSize: '5px',
                         fontFamily: 'sans-serif',
                         fill: '#121212'
                     }}
                 /></div>
             </div>
             <div class="col-md-4">
                 <div class="h2 font-weight-bold text-center p-3"><PieChart
                     data={this.state.averageSessionTimeValues}
                     label={({ data, dataIndex }) =>
                         Math.round(data[dataIndex].percentage) + '%'
                     } labelStyle={{
                         fontSize: '5px',
                         fontFamily: 'sans-serif',
                         fill: '#121212'
                     }}
                 /></div>
             </div>
         </div>
         }

        return (
          <div>
           {data}
          </div>
        )
    }
}
