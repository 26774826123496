import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import { Confirm } from 'semantic-ui-react'
import { deleteBlogCategory, emptyBlogCategory, getBlogCategories } from '../../Actions/BlogCategoryAction'
/* Routes */
import { CONTENT_SETTING, CONTENT_VALUES } from '../../routes';
class DeleteBlogCategory extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    removeCategoryBlog(id) {
        console.log("deleted blog catergory = " + id)

        this.props.deleteBlogCategory(id);
    }
    handleSubmit = (e) => {
        const { id } = this.props;
        this.removeCategoryBlog(id);
        this.close();
    }
    render() {
        const { deleted, error_deleting } = this.props;
        if (deleted === false) {
            alert("error deleting blog category" + error_deleting);
            this.props.emptyBlogCategory();
        }
        else if (deleted === true) {
            alert("blog category deleted successfully");
            this.props.emptyBlogCategory();
            this.props.getBlogCategories();
            return (<Redirect to={CONTENT_SETTING}></Redirect>)
        }
        return (<div >
            <button onClick={this.open}>
                <i className="far fa-trash-alt" >
                    <FontAwesomeIcon icon="trash-alt" />
                </i>
            </button>
            <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
        </div>)

    }
}
const mapStateToProps = state =>
    ({
        deleted: state.blogCategories.blog_category_deleted_successfully,
        error_deleting: state.blogCategories.error_deleteing_blog_category
    })
export default connect(mapStateToProps, { deleteBlogCategory, emptyBlogCategory, getBlogCategories })(DeleteBlogCategory)
