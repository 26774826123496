import {
    ERROR_GET_USER_PAYMENT_DATA,
    GET_USER_PAYMENT_DATA,
    LOAD_GET_USER_PAYMENT_DATA,
    EMPTY_MOBILE_USER_PROFILE,
    SUCCESS_CANCEL_USER_PAYMENT_SUPSCRIPTION,
    ERROR_CANCEL_USER_PAYMENT_SUPSCRIPTION,
    SUCCESS_CANCEL_TPAY_SUPSCRIPTION,
    EMPTY_MOBILE_USER_PROFILE_WITH_ITEM, UPDATE_STATE,
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},

    loading: '',
    getting_user_payment_data_error: '',
    getting_user_payment_data_sucessfully: '',

    user_payment_cancel_subscription_error: '',
    user_payment_cancel_subscription_sucessfully: ''
}

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_USER_PAYMENT_DATA:
            return {
                ...state,
                item: action.payload,
                getting_user_payment_data_error: '',
                getting_user_payment_data_sucessfully: true,
                loading: false
            }

        case ERROR_GET_USER_PAYMENT_DATA:
            return {
                ...state,
                getting_user_payment_data_error: action.payload,
                getting_user_payment_data_sucessfully: false,
                loading: false
            }

        case LOAD_GET_USER_PAYMENT_DATA:
            return {
                ...state,
                loading: true
            }

        case EMPTY_MOBILE_USER_PROFILE:
            return {
                ...state,

                getting_user_payment_data_error: '',
                getting_user_payment_data_sucessfully: '',

                user_payment_cancel_subscription_error: '',
                user_payment_cancel_subscription_sucessfully: ''
            }

        case EMPTY_MOBILE_USER_PROFILE_WITH_ITEM:
            return {
                ...state,
                item: {},
                getting_user_payment_data_error: '',
                getting_user_payment_data_sucessfully: '',

                user_payment_cancel_subscription_error: '',
                user_payment_cancel_subscription_sucessfully: ''
            }
        ///////////////////////////////
        case SUCCESS_CANCEL_USER_PAYMENT_SUPSCRIPTION:
            return {
                ...state,
                item: action.payload,
                user_payment_cancel_subscription_error: '',
                user_payment_cancel_subscription_sucessfully: true,
                loading: false
            }
        case SUCCESS_CANCEL_TPAY_SUPSCRIPTION:
            return {
                ...state,
                user_payment_cancel_subscription_error: '',
                user_payment_cancel_subscription_sucessfully: true,
                loading: false
            }

        case ERROR_CANCEL_USER_PAYMENT_SUPSCRIPTION:
            return {
                ...state,
                user_payment_cancel_subscription_sucessfully: false,
                user_payment_cancel_subscription_error: action.payload,
                loading: false
            }


        default:
            return state

    }

}
