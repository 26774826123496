import { Promise } from 'q';
import axios, { put, post } from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

export function deleteChoice(choiceId,questionId ) {
    return new Promise(function (resolve, reject) {

        const url = "/Choice/deleteChoice";
        const formdata = new FormData();
        formdata.append('Choice_ID', choiceId);
        formdata.append('Question_ID',questionId);
        const config = {
            headers: {
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formdata, config).then((res) => {
            resolve(res)
            return res.data
        }
        ).catch((Error) => {
            console.log(Error)
            reject(Error)
        })
    })
}
