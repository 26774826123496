import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

/*Actions*/ 
import {
    getUserData, emptyMobileUserProfile,
    emptyMobileUserProfileWithItem
} from '../../Actions/MobilePaymentProfileAction';
import {  emptyToken } from '../../Actions/loginActions.js'

/*Components */
import MobilePaymentFooter from '../StripePayment/MobilePaymentFooter';

/* images */
import logo from "../../images/logo.png";

/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';


class MobilePaymentError extends Component {

    constructor(props) {
        super(props);
        
        firebase.auth().signOut()
        this.props.emptyToken();
        this.props.emptyMobileUserProfile();
        this.props.emptyMobileUserProfileWithItem();
    }


    render() {

        // back to previous page if browser back button clicked
        window.onhashchange = function() {
            this.props.history.goBack();
        }

        return (
            <div className="main-payment" >

                <div className="shape-container">
                    <div className=" bg-img">
                    </div>
                </div>

                <div className="container leftToRight">
                    <div className="pay-box">

                        <div className="  main-logo text-center h4">
                            <img className="  logo" alt="logo" src={logo} />
                            <p className=" font  small-font main-color ">
                                بوابة الدفع لجيل
                        </p>
                        </div>
                        <div >

                            <div className=" card card-container Current-Package mb-4 ">
                                <div className="card-header profile-name-card text-right">


                                    <span className="h4 font">مرحبا</span>
                                    <span className="h4 font">   &nbsp;
                                        
                                        &nbsp; </span>


                                </div>
                                <div className="tab-button-outer">

                                    <div id="tab02" className="tab-contents">

                                        <div className="  card-body">

                                            <div className="  h6 text-center mb-4  font">
                                               لقد حدث خطأ ما من فضلك حاول مره اخرى
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                    <MobilePaymentFooter />
                </div>


            </div>
        )
    }
}




MobilePaymentError.propTypes = {
}
const mapStateToProps = state => ({
    userPaymentProfile: state.userPaymentProfile.item,
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyToken, emptyMobileUserProfile,
                emptyMobileUserProfileWithItem
            }
        )
        (MobilePaymentError)
    );


