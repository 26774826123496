import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Promise */
import {deleteChoice} from '../../Promises/Choice.js';
import { getQuesrionDetails } from '../../Promises/Question.js';

export class DeleteChoice extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })



    handleSubmit = (e) => {

        const { questionId } = this.props;
        // const {activityId} = this.props;
        const { choiceId } = this.props;

        console.log("id delete question = " + questionId)
        console.log("id delete choice = " + choiceId)

        deleteChoice(choiceId,questionId).then((res) => {
            alert("Choice Deleted Successfully")
            getQuesrionDetails(questionId).then((res) => {
                // alert("Question Data Successfully")
                // console.log(res.data);
                
                this.props.questionDataCallBack(res.data);
    
            }).catch((Error) => {
                // it enters the catch when the correctAnsId = null
                alert("error messing data or network " + Error)
                // this.props.questionIsDeleted(false);
            })
    
        }).catch((Error) => {
            alert("Can't delete Correct Answer, please Change it and update then try again " + Error)
            // this.props.questionIsDeleted(false);
        })

        this.close();
    }

    render() {
        return (

            <span >
                <Popup trigger = {
                <FontAwesomeIcon className="far fa-trash-alt"
                icon='trash-alt' onClick={this.open}>
                </FontAwesomeIcon>
                }
                content='Delete'
                position='top center'

                />
                <Confirm id="modal"
                    content='Are you sure you want 
                    to delete this Question ??'
                    open={this.state.open} 
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </span>
        )
    }
}

DeleteChoice.propTypes = {
    // getActivityData: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
});


export default withFirebase
    (connect
        (mapStateToProps,
            {  })
        (DeleteChoice)
    );
