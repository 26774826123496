import {
    LOAD_All_TERMSANDCONDITIONS, FETCH_All_TERMSANDCONDITIONS, ERROR_ALL_TERMSANDCONDITIONS, EMPTY,
    ERROR_UPDATE_TERMSANDCONDITIONS, UPDATE_TERMSANDCONDITIONS, LOAD_TERMSANDCONDITIONS,
    ADD_TERMSANDCONDITIONS, ERROR_ADDING_TERMSANDCONDITIONS,
    DELETING_TERMSANDCONDITIONS, ERROR_DELETING_TERMSANDCONDITIONS
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    showModal: '',
    loading: '',

    getting_all_terms_Conditions_error: '',
    getting_all_terms_Conditions_sucessfully: '',

    updating_all_terms_Conditions_error: '',
    updating_all_terms_Conditions__sucessfully: '',

    adding_all_terms_Conditions_error: '',
    adding_all_terms_Conditions__sucessfully: '',

    deleting_all_terms_Conditions_sucessfully: '',
    deleting_all_terms_Conditions_error: '',
}

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_All_TERMSANDCONDITIONS:

            return {
                ...state,
                items: action.payload,
                getting_all_terms_Conditions_error: '',
                getting_all_terms_Conditions_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_TERMSANDCONDITIONS:
            return {

                ...state,
                getting_all_terms_Conditions_error: action.payload,
                loading: false
            }

        case LOAD_All_TERMSANDCONDITIONS, LOAD_TERMSANDCONDITIONS:
            return {
                ...state,
                loading: true
            }

        case UPDATE_TERMSANDCONDITIONS:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                updating_all_terms_Conditions_error: '',
                updating_all_terms_Conditions__sucessfully: true,
                loading: false
            }

        case ERROR_UPDATE_TERMSANDCONDITIONS:
            return {

                ...state,
                updating_all_terms_Conditions_error: action.payload,
                adding_all_terms_Conditions__sucessfully: false,
                loading: false
            }

        case ADD_TERMSANDCONDITIONS:
            return {
                ...state,
                items: action.payload,
                showModal: false,
                items: state.items.concat(action.payload),
                adding_all_terms_Conditions_error: '',
                adding_all_terms_Conditions__sucessfully: true,
                loading: false
            }

        case ERROR_ADDING_TERMSANDCONDITIONS:
            return {

                ...state,
                adding_all_terms_Conditions_error: action.payload,
                adding_all_terms_Conditions__sucessfully: false,
                loading: false
            }

        case DELETING_TERMSANDCONDITIONS:
            return {

                ...state,
                item: action.payload,
                deleting_all_terms_Conditions_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_TERMSANDCONDITIONS:
            return {

                ...state,
                deleting_all_terms_Conditions_error: action.payload,
                loading: false
            }


        case EMPTY:
            return {
                ...state,

                updating_all_terms_Conditions_error: action.payload,
                updating_all_terms_Conditions__sucessfully: action.payload,

                adding_all_terms_Conditions_error: action.payload,
                adding_all_terms_Conditions__sucessfully: action.payload,

                deleting_all_terms_Conditions_sucessfully: action.payload,
                deleting_all_terms_Conditions_error: action.payload,
                item: {},
            }

        default:
            return state

    }

}