import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ZenDeskChat from '@goranefbl/react-zendesk-chat';

/*stripe */
import { CardElement, injectStripe } from 'react-stripe-elements';
/*Redux */

import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withFirebase } from '../../config';
import firebase from 'firebase/app'
/*Actions */
import { addStripePayment, emptyPayment, clearMessage } from '../../Actions/StripePaymentAction';
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'
import { isItAutoRenewel } from '../../Actions/MobilePaymentActions.js'
import {
    getUserData, emptyMobileUserProfile, emptyMobileUserProfileWithItem
} from '../../Actions/MobilePaymentProfileAction';
/*images */
import logo from "../../images/logo.png";
/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';
import Loader from '../Layout/Loader';
import { getUserInfo } from '../../Promises/UserData.js'



const INITIAL_STATE = {
    redirection: false,
    redirectionBack: false,
    token: '',
    isChecked: false,
    checkedMessage: ''

}

class MobilePaymentStripeCheckout extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

        this.props.emptyPayment();
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {

        const { token } = this.props;
        //console.log(token)
        getUserInfo(token).then((res) => {
            this.props.getUserData(token);
            this.props.addTokenToredux(token);
        })

        const { userPaymentProfile } = this.props;
        if (userPaymentProfile.paymentAutoRenewal === 0) {
            this.setState({ isChecked: false, checkedMessage: '' });
        } else {
            this.setState({ isChecked: true, checkedMessage: "أنت مشترك فى خدمة التجديد التلقائى " })
        }
    }


    handleCheckbuttonChange = (e) => {

        const isChecked = e.target.checked;
        //console.log(isChecked)

        // to add the data in change
        this.props.isItAutoRenewel(isChecked);
        this.setState({ isChecked: isChecked })
        if (isChecked === true) {
            this.setState({ checkedMessage: "أنت مشترك فى خدمة التجديد التلقائى " })
        }
        else {
            this.setState({ checkedMessage: '' });
        }
    }

    submit = (isChecked, ev) => {
        this.btn.setAttribute("disabled", "disabled");


        const { token } = this.props;
        this.props.stripe.createToken({ type: 'card', name: 'test' })
            .then((data) => {
       
                // getting the paying data from redux
                const { payingData } = this.props;
                //console.log("cash = " + payingData.cash)

                this.props.addStripePayment(token, data.token.id,
                    payingData.cash,
                    payingData.expirationDate,
                    payingData.paymentType,
                    this.state.isChecked,
                    payingData.isWithOffer
                );
            })
            .catch((error) => {
                alert("بيانات الكارت غير صحيحة, يرجى اعاده ادخالها")
                console.log("error")
                console.log(error)
                this.btn.removeAttribute("disabled");

            })

        //const{token} = this.props;
        //this.setState({ token: token })
        //this.props.emptyMobileUserProfile();
        //this.props.getUserData(token);
        ev.preventDefault();
    }

    handleLink = (e) => {
        this.props.addSeriesIDtoRedirectionPage("MobilePaymentStripeCheckoutParent", 0);

        //  this.setState({ redirection: true })
    }

    handleBackLink = (e) => {
        this.setState({ redirectionBack: true })
    }

    render() {


        // back to previous page if browser back button clicked
        window.onhashchange = function () {
            this.props.history.goBack();
        }

        // redirect to login if not user
        const { token } = this.props;

        const { feedback_messege, success } = this.props;

        //console.log("JJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJJ")
        console.log(success);
        console.log(feedback_messege);
        // const { feedback_messege, success } = this.props;
        /*if(success == true ){
          const{token} = this.props;
          this.props.emptyMobileUserProfile();

          this.props.getUserData(token);
          const { userPaymentProfile } = this.props;
          console.log(userPaymentProfile)
          if(userPaymentProfile.currentPackagePaymentId != null){
            let redirect = "/MobilePaymentProfile" + "/" + token;
            console.log(redirect)
            return (<Redirect to={redirect} ></Redirect>)
          }else{
            return (<Loader />)
          }
        }*/
        const { userPaymentProfile } = this.props;
        //console.log(userPaymentProfile)

        if (userPaymentProfile.user_role !== undefined) {
            if (userPaymentProfile.user_role.role !== "USER") {

                firebase.auth().signOut()
                return (<Redirect to="/MobilePaymentLogin"></Redirect>)
            }
        }


        const { redirection, redirectionBack } = this.state;
        let { isChecked } = this.state;
        const { payingData } = this.props;


        //====================
        /*start Redirection*/
        //====================
        // Redirect  to MobileSupport
        if (redirection == true) {
            return (<Redirect to="/MobilePaymentSupport" ></Redirect>)
        }
        // backing to paymentPage
        if (redirectionBack == true) {
            var pageName = '/MobilePayment/' + token;
            return (<Redirect to={pageName} ></Redirect>)

        }
        //====================
        /*end Redirection*/
        //====================
        //  const { token } = this.state;
        let feedback_messege1 = "";
        //console.log(success);
        //console.log(feedback_messege);
        if (success === true &&
            feedback_messege !== '' &&
            feedback_messege === "true ,Success!" &&
            token !== '') {

            console.log(feedback_messege)
            let type = payingData.paymentType;
            if (type === "Monthly") {
                feedback_messege1 = "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة شهر"
            } else if (type === "Yearly") {
                feedback_messege1 = "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة عام كامل"
            } else if (type === "Weekly") {
                feedback_messege1 = "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة أسبوع"
            
            }
            this.props.clearMessage();
            this.props.emptyMobileUserProfile();
            const { token } = this.props;
            this.props.getUserData(token);
            alert(feedback_messege1);

        } else if (feedback_messege !== '' && feedback_messege !== "true ,Success! ") {



            console.log("error")
            feedback_messege1 = "مع الأسف لم يتم الدفع بنجاح  " + "(" + feedback_messege + ")";
            alert(feedback_messege1)
            this.props.emptyPayment();

            return (<Redirect to="MobilePaymentStripeCheckoutParent" ></Redirect>)

        }

        //Redirect to Profile Page after getting User Data and Success Payment
        if (success === true &&
            userPaymentProfile.currentPackagePaymentId !== null &&
            feedback_messege !== '' &&
            feedback_messege === "true ,Success!"
        ) {
            console.log("Redirect to Profile");
            this.props.emptyPayment();
            let redirect = "/MobilePaymentFeedback/" + token;
            return (<Redirect to={redirect} ></Redirect>)
        }


        // loading button
        let buttonSubmitLoader = '';

        let fieldsetContent = (
            <fieldset>
                <legend className="font">الباقة الحالية</legend>
                <div className="div-box">
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <div className="mo-package paymentltr ">

                                <CardElement />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="font p-3 small-font ltr text-right">
                            : الباقة صالحة لتاريخ
                                            </div>
                    </div>
                    <div className="col-6">
                        <div className="pl-4 pt-3">
                            <div className="font small-font eng-lang text-left">{payingData.expirationDate}</div>
                        </div>
                    </div>
                </div>

                <hr className="" />
                <label className="checkbox mt-3 small-font font">التجديد التلقائي للباقة
                                    <input type="checkbox" checked={this.state.isChecked}
                        onClick={this.handleCheckbuttonChange.bind(this)} />
                    <span className="checkmark"></span>
                </label>
            </fieldset>

        )

        const { loading } = this.props;
        //console.log(loading)


        let content = '';

        buttonSubmitLoader = (
            <button className="font btn btn-lg btn-primary btn-block btn-signin mt-2"
                data-toggle="modal"
                data-target="#exampleModalCenter"

                ref={btn => { this.btn = btn; }}
                //onClick={this.submitwithAutoRenewel.bind(this, true)}
                onClick={this.submit.bind(this, true)}
            >
                Pay ${payingData.cash}
            </button>);

        if (loading === true) {
            buttonSubmitLoader = (
                <button
                    className="btn btn-block ui loading secondary button">
                </button>
            );
            //console.log("loading");
        }
        content = (
            <span>
                <div className=" h5 text-center mb-4 mt-4 font">
                    {this.state.isChecked && this.state.checkedMessage}
                </div>
                {fieldsetContent}
                {buttonSubmitLoader}
            </span>
        )
        return (
            <div className="main-payment">

                <div className="shape-container">
                    <div className="bg-img">
                    </div>
                </div>

                <div className="container">
                    <div className="pay-box">
                        <div className="main-logo text-center h4" >
                            <img className="logo" alt="logo" src={logo} />
                            <p className="small-font main-color font">بوابة الدفع لجيل</p>
                        </div>
                        <div className="card card-container Current-Package">
                            <div className="card-header profile-name-card text-right">
                                <span className="h4 font">بيانات الدفع</span>

                                <Link>
                                    <a className="back-icon float-left"
                                        onClick={this.handleBackLink.bind(this)}>
                                        <i className="fas fa-chevron-left">
                                            <FontAwesomeIcon icon="chevron-left" />
                                        </i>
                                    </a>
                                </Link>

                            </div>
                            <div className="card-body">

                                {content}

                                <div className="col-12">
                                    <Link
                                        className="h6 mt-3 mb-0 float-right font" id="livechat-open">
                                        لديك مشكلة؟
                                </Link>
                                    <ZenDeskChat onlineMsg="Live Chat Support" offlineMsg="Leave us a message" appID="rVsdP33FK31Q8eZxdFwegHbpDOk2rzMd" buttonID="livechat-open" />
                                </div>

                            </div>
                        </div>
                        <MobilePaymentFooter />
                    </div>
                </div>

            </div>
        )
    }
}


MobilePaymentStripeCheckout.propTypes = {
    addStripePayment: propTypes.func.isRequired,
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    isItAutoRenewel: propTypes.func.isRequired,
    emptyMobileUserProfile: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    feedback_messege: state.stripePayment.payment_feedback_messege,
    loading: state.stripePayment.loading,
    success: state.stripePayment.payment_updated_successfully,

    payingData: state.mobilePaymentPackage.payingData,
    redirectionPage: state.redirectionPage.item,
    autoRenewel: state.mobilePaymentPackage.autoRenewel,
    token: state.user.token,
    userPaymentProfile: state.userPaymentProfile.item,


});

export default injectStripe
    (withFirebase
        (connect
            (mapStateToProps,
                {
                    addStripePayment,
                    emptyPayment,
                    clearMessage,
                    addSeriesIDtoRedirectionPage,
                    isItAutoRenewel,
                    getUserData,
                    emptyMobileUserProfile,
                    emptyMobileUserProfileWithItem
                }
            )
            (MobilePaymentStripeCheckout)
        )
    );
