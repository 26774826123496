export default [{
    key: 'contains',
    text: 'contains',
    value: 'contains'
},
{
    key: 'does not contain',
    text: 'does not contain',
    value: 'does not contain'
},
{
    key: 'is',
    text: 'is',
    value: 'is'
},
{
    key: 'is not',
    text: 'is not',
    value: 'is not'
}]
