import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Input, Button, Modal, TextArea, Dropdown } from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    updateSpecialCountriesPrice,
    emptySpeciialCountryOffer,
    fetchAllSpecialCountriesPrice
} from '../../Actions/MobileSpecialCountriesAction';

import payment_package_type_list from '../ConstData/PaymentAndVendorCountriesList'


const INITIAL_STATE = {

    showModal: '',
    /////////// special Country data
    id: 0,
    country: '',
    description: '',
    weeklyDiscountPrice: '',
    monthlyDiscountPrice: '',
    yearlyDiscountPrice: '',
    weeklyBasicPrice: '',
    monthlyBasicPrice: '',
    yearlyBasicPrice: '',
};

class EditSpecialCountryPrice extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }


    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    updateSpecialCountriesPrice(id, country, description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice) {
        this.props.updateSpecialCountriesPrice(id, country, description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice);
    }

    handleCountryChange = (e, { value }) => {
        this.setState({ country: value });
    };

    handleChange = e => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    };


    handleSubmit = (e) => {
        const { id, country, description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice } = this.state;
        console.log(description)

        this.updateSpecialCountriesPrice(id, country, description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice);
        e.preventDefault();
        this.setState({
            id: 0,
            country: '',
            description: '',
            weeklyDiscountPrice: '',
            monthlyDiscountPrice: '',
            yearlyDiscountPrice: '',
            weeklyBasicPrice: '',
            monthlyBasicPrice: '',
            yearlyBasicPrice: '',
        })
    }


    closeModal = (e) => {
        this.setState({
            showModal: false,
            id: 0,
            country: '',
            description: '',
            weeklyDiscountPrice: '',
            monthlyDiscountPrice: '',
            yearlyDiscountPrice: '',
            weeklyBasicPrice: '',
            monthlyBasicPrice: '',
            yearlyBasicPrice: '',
        })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
        const { specialCountriesPriceItem } = this.props;
        let description = specialCountriesPriceItem.description;
        if (specialCountriesPriceItem.description === 'null' ||
            specialCountriesPriceItem.description === "null" ||
            specialCountriesPriceItem.description === null ||
            specialCountriesPriceItem.description == undefined) {
            description = '';
        }
        this.setState({
            id: specialCountriesPriceItem.id,
            country: specialCountriesPriceItem.country,
            description: description,
            weeklyDiscountPrice: specialCountriesPriceItem.weekDiscountPrice,
            monthlyDiscountPrice: specialCountriesPriceItem.monthDiscountPrice,
            yearlyDiscountPrice: specialCountriesPriceItem.yearDiscountPrice,
            weeklyBasicPrice: specialCountriesPriceItem.weekBasicPrice,
            monthlyBasicPrice: specialCountriesPriceItem.monthBasicPrice,
            yearlyBasicPrice: specialCountriesPriceItem.yearBasicPrice,
        })
    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("Error " + error)
            this.props.emptySpeciialCountryOffer();

        }
        else if (success === true) {
            alert("Country with special offer Updated Successfully")
            this.props.emptySpeciialCountryOffer();

            this.closeModal();
            this.props.fetchAllSpecialCountriesPrice();
        }

        const isInvalidSeries =
            this.state.country === '' || this.state.weeklyDiscountPrice === 0 || this.state.weeklyDiscountPrice === '' ||
            this.state.monthlyDiscountPrice === 0 || this.state.monthlyDiscountPrice === '' ||
            this.state.yearlyDiscountPrice === 0 || this.state.yearlyDiscountPrice === '' || 
            this.state.weeklyBasicPrice === 0 || this.state.weeklyBasicPrice === '' ||
            this.state.monthlyBasicPrice === 0 || this.state.monthlyBasicPrice === '' ||
            this.state.yearlyBasicPrice === 0 || this.state.yearlyBasicPrice === '';

        return (
            <Modal id="modal"
                trigger={
                    <button className="acor-btn1" type="submit" onClick={this.openModal}>
                        Edit
                    </button>
                }

                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Edit New Country With Special Offer</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>
                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">
                                    <div className="col">
                                        <div className="ui form">
                                            <label htmlFor="episode_series_id"
                                                className="label">
                                                <span className="required_input">*</span>
                                                                Select Country </label><br />
                                            <Dropdown fluid search selection
                                                placeholder='Select Country'
                                                id="episode_series_id"
                                                onChange={this.handleCountryChange}
                                                options={payment_package_type_list}
                                                value={this.state.country}
                                            />
                                            <br />
                                            <div className="two fields">
                                                <div className="field">
                                                    <label htmlFor="weeklyDiscountPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Weekly Discount Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Weekly Discount Price"
                                                        name="weeklyDiscountPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.weeklyDiscountPrice} /><br />
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="monthlyDiscountPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Monthly Discount Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Monthly Discount Price"
                                                        name="monthlyDiscountPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.monthlyDiscountPrice} /><br />
                                                    {/* <p style={{ color: "red" }}>{this.state.formErrors.monthlyDiscountPrice} </p> */}
                                                </div>
                                                <div className="field">

                                                    <label htmlFor="yearlyDiscountPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Yearly Discount Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Yearly Discount Price"
                                                        name="yearlyDiscountPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.yearlyDiscountPrice} /><br />
                                                    {/* <p style={{ color: "red" }}>{this.state.formErrors.yearlyDiscountPrice} </p> */}

                                                </div>
                                            </div>
                                            <div className="two fields">
                                                <div className="field">
                                                    <label htmlFor="weeklyBasicPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Weekly Basic Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Weekly basic Price"
                                                        name="weeklyBasicPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.weeklyBasicPrice} /><br />
                                                </div>
                                                <div className="field">
                                                    <label htmlFor="monthlyBasicPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Monthly Basic Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Monthly Basic Price"
                                                        name="monthlyBasicPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.monthlyBasicPrice} /><br />
                                                    {/* <p style={{ color: "red" }}>{this.state.formErrors.monthlyBasicPrice} </p> */}
                                                </div>
                                                <div className="field">

                                                    <label htmlFor="yearlyBasicPrice" className="label">
                                                        <span className="required_input">*</span>
                                                            Yearly Basic Price</label>
                                                    <Input type="number"
                                                        step="0.01"
                                                        min="0"
                                                        placeholder="Yearly Basic Price"
                                                        name="yearlyBasicPrice"
                                                        onChange={this.handleChange}
                                                        value={this.state.yearlyBasicPrice} /><br />
                                                    {/* <p style={{ color: "red" }}>{this.state.formErrors.yearlyBasicPrice} </p> */}

                                                </div>
                                            </div>

                                            <label htmlFor="description" className="label">Discount Description</label>
                                            <TextArea fluid rows="3" name="description" id="episode_description"
                                                onChange={this.handleChange} value={this.state.description} />
                                            <br /><br />

                                        </div>
                                    </div>

                                </div>

                                <div className="input-field">

                                    <Button onClick={this.handleSubmit}
                                        disabled={isInvalidSeries} id="saveButton" >
                                        Save
                                    </Button>


                                </div>
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

EditSpecialCountryPrice.propTypes = {
    updateSpecialCountriesPrice: propTypes.func.isRequired,
    fetchAllSpecialCountriesPrice: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    specialCountriesPrice: state.specialCountriesPrice.item,
    showModal: state.specialCountriesPrice.showModal,
    error: state.specialCountriesPrice.update_special_countries_price_error,
    success: state.specialCountriesPrice.update_special_countries_price_sucessfully
});


export default withFirebase(
    connect(
        mapStateToProps, {
        updateSpecialCountriesPrice,
        emptySpeciialCountryOffer,
        fetchAllSpecialCountriesPrice
    })
        (EditSpecialCountryPrice)
);

