import {
    FETCH_ALL_SECTION, LOAD_SECTION,
    ERROR_LOADING_ALL_SECTION,
    ADD_SECTION_SUCCESSFULLY, ADD_SECTION_ERROR,
    UPDATE_SECTION_SUCCESSFULLY, UPDATE_SECTION_ERROR,
    GET_SECTION_DATA_SUCCESSFULLY,GET_SECTION_DATA_ERROR,
    EMPTY_SECTION,
    DELETE_SECTION_SUCCESSFULLY,
    DELETE_SECTION_ERROR

} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},
    loading: '',

    error_all_loading_sections: '',
    getting_allSections_sucessfully: '',

    section_data_successfully:'',
    section_data_error:'',
    
    add_section_successfully: '',
    add_section_error: '',

    update_section_successfully: '',
    update_section_error: '',

    delete_section_successfully: '',
    delete_section_error: ''
}

export default function (state = initialState, action) {
    switch (action.type) {


        case LOAD_SECTION:
            return {
                ...state,
                loading: true
            }
        case FETCH_ALL_SECTION:
            return {
                ...state,
                items: action.payload,
                error_all_loading_sections: '',
                getting_allSections_sucessfully: true,
                loading: false,
                item: {}
            }
        case ERROR_LOADING_ALL_SECTION:
            return {
                ...state,
                error_all_loading_sections: action.payload,
                loading: false
            }

            
        case GET_SECTION_DATA_SUCCESSFULLY:
            return {
                ...state,
                item: action.payload,
                section_data_successfully: true,
                section_data_error: '',
                loading: false
            }
            
        case GET_SECTION_DATA_ERROR:
            return {
                ...state,
                section_data_error: action.payload,
                section_data_successfully: false,
                loading: false
            }

        case ADD_SECTION_SUCCESSFULLY:
            return {
                ...state,
                add_section_successfully: true,
                loading: false
            }

        case ADD_SECTION_ERROR:
            return {
                ...state,
                add_section_successfully: false,
                add_section_error: action.payload,
                loading: false
            }

        case UPDATE_SECTION_SUCCESSFULLY:
            return {
                ...state,
                update_section_successfully: true,
                update_section_error:'',
                loading: false
            }

        case UPDATE_SECTION_ERROR:
            return {
                ...state,
                update_section_successfully: false,
                update_section_error: action.payload,
                loading: false
            }

        case DELETE_SECTION_SUCCESSFULLY:
            return {
                ...state,
                delete_section_successfully: true,
                delete_section_error: "",
                loading: false
            }
        
        case DELETE_SECTION_ERROR:
            return {
                ...state,
                delete_section_successfully: false,
                delete_section_error: action.payload,
                loading: false
            }

        case EMPTY_SECTION:
            return {
                ...state,

                error_all_loading_sections: '',
                getting_allSections_sucessfully: '',

                add_section_successfully: '',
                add_section_error: '',

                update_section_successfully: '',
                update_section_error: '',

                delete_section_successfully: '',
                delete_section_error: '',

                section_data_successfully:'',
                section_data_error:'',
                item:{}
            }
        default:
            return state

    }
}