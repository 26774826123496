import React, { Component } from 'react'
import { withFirebase } from '../../config';
import { Input, Button, Modal, TextArea } from 'semantic-ui-react';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { addFAQ } from '../../Actions/FAQActions';
import { emptyError } from '../../Actions/Empty'
import { ARABICREGEX, ENGLISHREGEX } from '../../constants';

const Language = {
    ar: 'ar',
    en: 'en',
};

const INITIAL_STATE = {

    showModal: '',
    /////////// FAQ
    question: '',
    answer: '',
    ////////// validation
    formErrors: { error: true, question: '', answer: '' },
    selectedLangaugeOption: Language.ar,
    isAnswerRightLanguage: false,
    isQuestionRightLanguag: false,
    

};

class AddFAQ extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    addFAQ(question, answer, language) {
        this.props.addFAQ(question, answer, language);
    }

    checkTextLanguage(stateName,text){
        
        let isCorrect = false;
        if (this.state.selectedLangaugeOption === Language.en) {
            // Allow only English characters and spaces
            const englishRegex = ENGLISHREGEX;
            if (englishRegex.test(text)) {
                this.setState({ [stateName]: text });
                isCorrect = true
            }
        } else {
            // Allow only Arabic characters and spaces
            const arabicRegex = ARABICREGEX;
            if (arabicRegex.test(text)) {
                this.setState({ [stateName]: text });
                isCorrect = true;
            }
        }

        return isCorrect;
      
    }

    handleChange = (e) => {
        let validation = this.state.formErrors;
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');

        this.setState({ [e.target.name]: checkSpaces });
        let stateName = e.target.name;
        const isTextValid = this.checkTextLanguage(stateName, checkSpaces);

        setTimeout(() => {
            if (this.state.question === '' || this.state.question === ' ') {
                validation.question = "Question can not be empty";
                validation.error = true;
                this.setState({ formErrors: validation,
                                isQuestionRightLanguag: false });
            }
            else{
                if(isTextValid && stateName === 'question'){
                    validation.question = '';
                    this.setState({ formErrors: validation,
                                    isQuestionRightLanguag: true });
                }
            }
            if (this.state.answer === '' || this.state.answer === ' ') {
                validation.answer = "Answer can not be empty";
                validation.error = true;
                this.setState({ formErrors: validation,
                                isAnswerRightLanguage: false });
            }
            else {
                if(isTextValid && stateName === 'answer'){
                    validation.answer = '';
                    this.setState({ formErrors: validation,
                                    isAnswerRightLanguage: true });
                }
            }
            if(!isTextValid){
                if(stateName === 'question'){
                    validation.question = this.state.selectedLangaugeOption === Language.en?"Enter text in English" : "أدخل نص باللغة العربية";
                    this.setState({ formErrors: validation,
                                    isQuestionRightLanguag: false });
                }else{
                    validation.answer = this.state.selectedLangaugeOption === Language.en?"Enter text in English" : "أدخل نص باللغة العربية";
                    this.setState({ formErrors: validation,
                                    isAnswerRightLanguage: false });
                }
            }
            if (this.state.answer !== '' 
                && this.state.answer !== ' ' 
                && this.state.question !== '' 
                && this.state.question !== ' '
                && isTextValid
                && this.state.isAnswerRightLanguage
                && this.state.isQuestionRightLanguag) {
                validation.question = '';
                validation.answer = '';
                validation.error = false;
                this.setState({ formErrors: validation});
            }
        }, 0);
    }

    handleSubmit = (e) => {

        const { question, answer, selectedLangaugeOption } = this.state;

        this.addFAQ(question, answer, selectedLangaugeOption);

        e.preventDefault();
        this.setState({
            question: '',
            answer: '',
            selectedLangaugeOption: Language.ar,
        })

    }

    closeModal = (e) => {
        this.setState({
            showModal: false,
            question: '',
            answer: '',
            selectedLangaugeOption: Language.ar,
            formErrors: {
                question: '',
                answer: '',
                error: true
            }
        })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
    }

    handleLanguageChange =(e)=>{
        this.setState({selectedLangaugeOption: e.target.value});
    }

    render() {

        const { question, answer, selectedLangaugeOption } = this.state;

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true) {
            alert("FAQ added Successfully")
            this.props.emptyError();

            this.closeModal();
        }

        const isInvalidSeries =
            question === '' ||
            answer === '';


        return (
            <Modal id="modal"
                trigger={
                    <button className="btn btn-lg btn-primary add-btn float-right mb-0"
                        type="submit" onClick={this.openModal}> + Add New </button>}
                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>
                    <div className='row'  style={{ display: 'flex', justifyContent: 'space-between'}}>
                        <div>Add New</div>
                        <div>
                            <label>Select a Language:</label>
                            <select value={selectedLangaugeOption} onChange={this.handleLanguageChange}>
                                <option value={Language.ar}> Arabic </option>
                                <option value={Language.en}> English </option>
                            </select>
                        </div>

                    </div>
                </Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">


                                    <div className="col">

                                        <label htmlFor="question" className="label">Question</label>
                                        <input
                                            dir="auto"
                                            className="form-control"
                                            placeholder={selectedLangaugeOption === Language.en?"Enter text in English" : "أدخل نص باللغة العربية"}
                                            fluid name="question" id="question" onChange={this.handleChange}
                                            value={this.state.question} />
                                        <p style={{ color: "red" }}>{this.state.formErrors.question} </p>
                                        <br />

                                        <label htmlFor="answer" className="label">Answer</label>
                                        <textarea
                                            dir="auto"
                                            className="form-control"
                                            placeholder={selectedLangaugeOption === Language.en?"Enter text in English" : "أدخل نص باللغة العربية"}
                                            fluid rows="6" name="answer"
                                            id="episode_description"
                                            onChange={this.handleChange} value={this.state.answer} />
                                        <p style={{ color: "red" }}>{this.state.formErrors.answer} </p>
                                        <br />

                                    </div>

                                </div>

                                <div className="input-field">

                                    <Button onClick={this.handleSubmit} disabled={this.state.formErrors.error} id="saveButton" >
                                        Add
                                    </Button>


                                </div>
                                {error && <p>{error.message}</p>}
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

AddFAQ.propTypes = {
    addFAQ: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    faq: state.faq.items,
    showModal: state.faq.showModal,
    error: state.faq.adding_FAQ_error,
    success: state.faq.adding_FAQ__sucessfully
});


export default withFirebase(
    connect(
        mapStateToProps,
        { addFAQ, emptyError }
    )
        (AddFAQ)
);
