import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
/**Actions */
import { fetchAllAdmins } from '../../Actions/UserManagementActions';
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/*Components */
import Loader from '../Layout/Loader';
/*CSS */
import 'react-table/react-table.css';
import DeleteAdmin from './DeleteAdmin';
import BlockingAdmin from './BlockingAdmin';
import ResetPasswordAdmin from './ResetPasswordAdmin'
import ChangingAdminRole from './ChangingAdminRole'

const FoldableTable = FoldableTableHOC(ReactTable);


class UserManagementTabel extends Component {

    state = {
        filtered: [],
        customers: [],

        checkedItems: new Map()
    };

    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    componentDidMount() {
        this.props.fetchAllAdmins();
    }


    handleCheckbuttonChange = (uid, e) => {
        let { checkedItems } = this.state;
        const isChecked = e.target.checked;
        console.log(uid);
        let checkeditem = { "uid": uid, "isChecked": isChecked }

        var temCheckded = new Map([...checkedItems]);
        if (temCheckded.has(uid)) {
            if (isChecked == false) {
                temCheckded.delete(uid);
            }
        } else {
            temCheckded.set(uid, checkeditem);
        }
        this.setState({ checkedItems: temCheckded })
    }


    getCheckedState(uid) {
        //if Msp Contains my uid
        if (this.state.checkedItems.has(uid)) {
            return true;
        }
        return false;
    }

    handleDeleteButton = (uid, e) => {

        console.log("handleDeleteButton")
        console.log(uid);
    }

    deleteAdminIcon(props) {
        let trashIcon = '';
        const { userPaymentProfile } = this.props;
        if (userPaymentProfile.user_role.role === "SuperAdmin") {
            trashIcon = (<DeleteAdmin
                onClick={this.handleDeleteButton.bind(this, props.original.uid)}
                uid={props.original.uid} />)
        }
        return trashIcon;
    }

    changeAdminRoleIcon(props) {
        let changeRoleIcon = '';
        const { userPaymentProfile } = this.props;
        if (userPaymentProfile.user_role.role === "SuperAdmin"
            || (userPaymentProfile.user_role.role === "ADMIN"
                && props.original.user_role.role !== "ADMIN")) {
            changeRoleIcon = (<ChangingAdminRole
                // onClick={this.handleDeleteButton.bind(this, props.original.uid)}
                uid={props.original.uid}
                role = {props.original.user_role.role}
                roleId = {props.original.user_role.role_id} />)
        }
        return changeRoleIcon;
    }


    resetPasswordAdminIcon(props) {
        let resetPasswordIcon = '-';
        const { userPaymentProfile } = this.props;
        if (userPaymentProfile.user_role.role === "SuperAdmin"
            || (userPaymentProfile.user_role.role === "ADMIN"
                && props.original.user_role.role !== "ADMIN")) {
            resetPasswordIcon = (<ResetPasswordAdmin
                uid={props.original.uid}
                email={props.original.email} />)
        }
        return resetPasswordIcon;
    }

    blockAdminIcon(props) {
        let blockIcon = '-';
        const { userPaymentProfile } = this.props;
        if (userPaymentProfile.user_role.role === "SuperAdmin" || (userPaymentProfile.user_role.role === "ADMIN" && props.original.user_role.role !== "ADMIN")) {
            blockIcon = (<BlockingAdmin
                onClick={this.handleDeleteButton.bind(this, props.original.uid)}
                uid={props.original.uid}
                state={props.original.status} />)
        }
        return blockIcon;
    }

    render() {
        const { userPaymentProfile } = this.props;
        console.log('Parent User Management');
        const columns = [
            {
                Header: "",
                filterable: false,
                width: 40,
                MinWidth: 40,
                Maxwidth: 40,
                resizable: false,
                headerClassName: 'table-header',
                Cell: props => {
                    //       console.log("checkbox = " + props.original.uid);
                    let checkState = this.getCheckedState(props.original.uid);
                    return (
                        <div scope="row">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                    className="custom-control-input"
                                    name="check_customer"
                                    id={props.original.uid}
                                    checked={checkState}
                                    onClick={this.handleCheckbuttonChange.bind(this, props.original.uid)}
                                />
                                <label className="custom-control-label"
                                    for={props.original.uid}></label>
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: "ID",
                accessor: "uid",
                width: 130,
                MinWidth: 120,
                Maxwidth: 130,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.uid}
                        </div>)
                }

            },
            {
                Header: "User Name",
                accessor: "name",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>

                            {props.original.name}
                        </div>
                    )
                }

            },
            {
                Header: "Email",
                accessor: "email",
                width: 180,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {

                    return (
                        <div style={{ "textAlign": "center" }}>

                            {props.original.email}
                        </div>
                    )
                }

            },
            {
                Header: "Role ",
                accessor: "user_role.role",
                filterable: true,
                width: 150,
                headerClassName: 'table-header',
                //foldable: true,
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.user_role.role}
                        </div>
                    )
                }

            },
            {
                Header: "Last login",
                accessor: "now_and_lastLogin_diffrence",
                // filterable: false,
                width: 80,
                headerClassName: 'table-header',
                resizable: false,

                //foldable: true,
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.now_and_lastLogin_diffrence} m
                        </div>)
                }
            },
            {
                Header: "Actions",
                width: 100,
                filterable: false,
                headerClassName: 'table-header',
                resizable: false,
                //foldable: true,
                Cell: props => {
                    return (
                        <div style={{ textAlign: "center" }}>

                            {this.resetPasswordAdminIcon(props)}

                            {this.blockAdminIcon(props)}
                            {/* <a class="user-lock" href="#">
                                <i>  <FontAwesomeIcon icon="user-lock" />
                                </i>
                            </a> */}

                            {this.deleteAdminIcon(props)}

                            {this.changeAdminRoleIcon(props)}

                        </div>
                    )
                }
            }
        ]

        const { admins } = this.props;

        const { error, loading, success } = this.props;
        let adminList = null

        if (loading === true) {
            adminList = (<Loader />)
        }
        if (success === true && error === '' && admins !== undefined && admins.length !== undefined) {

            return (
                <div>
                    <ReactTable
                        //<FoldableTable
                        className=" table-striped custm-table"
                        columns={columns}
                        data={admins}
                        filterable
                    //defaultPageSize={10}
                    //className="-striped -highlight"
                    //defaultPageSize={5}
                    >

                    </ReactTable>
                    {/* </FoldableTable> */}
                </div>)
        }


        return (
            <div>

                <div className="mt-4 responsive-table">
                    {adminList}
                </div>
            </div>
        )
    }
}


UserManagementTabel.propTypes = {
    fetchAllAdmins: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    admins: state.admins.items,
    userPaymentProfile: state.userPaymentProfile.item,
    loading: state.admins.loading,
    error: state.admins.getting_allAdmins_error,
    success: state.admins.getting_allAdmin_sucessfully
});

export default withRouter
    (connect
        (mapStateToProps,
            { fetchAllAdmins, getUserData })
        (UserManagementTabel)
    );
