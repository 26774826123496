import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
/*Actions*/
import {
    deleteChannel,
    emptyChannel
} from '../../Actions/RadioChannelAction'
import { getRadioData } from '../../Actions/RadioActions';

import {
    deletePdfBook,
    emptyPdfBook,
    emptyError,
    fetchPdfBooks
} from '../../Actions/PdfBookAction'

import { ALL_PDF_BOOK } from '../../routes'
import { Redirect } from 'react-router-dom';

 class DeletePdfBook extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    removePdfBook(id) {
        this.props.deletePdfBook(id)
    }

    handleSubmit = (e) => {
        const { id } = this.props;
        this.removePdfBook(id);
        this.close();
    }

    render() {
        const { error,
            success, BookId } = this.props

        if (success === false) {
            alert("error missing data or network error" + error);
            // this.props.emptyBook();
            return (<Redirect to={ALL_PDF_BOOK}></Redirect>)

        }
        else if (success === true) {
            alert("Book deleted successfully");
            this.props.emptyError();
            // this.props.emptyBook();
            // this.props.getRadioData(RadioId);
            this.props.fetchPdfBooks();
            return (<Redirect to={ALL_PDF_BOOK}></Redirect>)
        }

        return (<div >
            <button className="seriesCardButtons" 
            onClick={this.open}>
                <i className="" >
                    <FontAwesomeIcon icon="trash-alt" />
                </i>
            </button>
            <Confirm id="modal"
                content='Are You Sure To Delete This Book?? '
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </div>)
    }
}


const mapStateToProps = state =>
({
    success: state.pdfBook.book_deleted_successfully,
    error: state.pdfBook.error_deleteing_book
})
export default connect
    (mapStateToProps,
        {
            deletePdfBook,
            emptyPdfBook,
            emptyError,
            fetchPdfBooks,
        })
    (DeletePdfBook)
