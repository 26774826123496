import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { setStateCustomer } 
from '../../Actions/CustomerActions'


const blockedCustomer = "BLOCKED";
const defaultCustomer = "DEFAULT";

class BlockingCustomer extends Component {

    state = { open: false }

    open = () => {
        
        this.setState({ open: true })}
    
        close = () => this.setState({ open: false })


    setStateCustomer(uid, state) {
        this.props.setStateCustomer(uid, state);
    }


    handleSubmit = (e) => {
        console.log("handelSubmit") 
        const { uid, state } = this.props;

        console.log ( uid, state)

        if (state === blockedCustomer) {
            this.setStateCustomer(uid, defaultCustomer);

        } else {
            this.setStateCustomer(uid, blockedCustomer);
        }
        this.close();

    }

    render() {

       
        const { state  } = this.props;

       
        // console.log(error , success)

        let contentPreview = null;

       

        if (state === blockedCustomer) {

            contentPreview = (
                  <Popup trigger={
                <a className="user-lock" 
                onClick={this.open.bind(this)}>
                    <i >
                        <FontAwesomeIcon icon="lock" />
                    </i>
                </a>}
                content='Tap to Unblock'
                position='top center'
                />
            )

        } else {

            contentPreview = (
                 <Popup trigger={<a className="user-lock" onClick={this.open.bind(this)}>
                    <i>  
                        <FontAwesomeIcon icon="lock-open" />
                    </i>
                </a>}
                content='Tap to Block'
                position='top center'
                />
            )
        }

        return (

            <span >
                {contentPreview}
                <Confirm id="modal"
                    content='Are you sure you want
                        to change the customer state ??'
                    open={this.state.open}
                    onCancel={this.close.bind(this)} 
                    onConfirm={this.handleSubmit.bind(this)} />
            </span>

        )

    }
}


BlockingCustomer.propTypes = {
    setStateCustomer: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    loading: state.customers.loading,
   
});

export default withFirebase
(connect
    (mapStateToProps, 
        { setStateCustomer }
    )(BlockingCustomer)
);
