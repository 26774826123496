import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import placeHolder from '../../images/placeholder.png';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/**Actions */
import {
    fetchAllSeries,
    addSerieItemWithSentFrom,
    emptySeriesWithEpisode
} from '../../Actions/SeriesActions';
import { emptyEpisodes } from '../../Actions/EpisodeActions';
import { emptyEvaluationAll } from '../../Actions/EvaluationActions'
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'
import { clearQuestions } from '../../Actions/QuestionActions';

/*Components */
import Loader from '../Layout/Loader';
import SetStateSeries from './SetStateSeries'
import DeleteSeries from './DeleteSeries'

/**CSS */
import "bootstrap/dist/css/bootstrap.min.css";
import '../../css/AppCss/Series/getAllSeries.css';
import '../../css/AppCss/Series/PublicSeriesCss.css';

/* Routes */
import { ALL_SERIES, VIEW_SERIES, UPDATE_SERIES } from '../../routes';

const INITIAL_STATE = {
    redirection: false,
    addingDone: false
}

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

class GetAllSeriesList extends Component {



    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {
        this.props.emptyEvaluationAll();
        this.props.emptyEpisodes();
        this.props.fetchAllSeries();
        this.props.addSeriesIDtoRedirectionPage(ALL_SERIES, 0);
        this.props.clearQuestions();

    }

    redirectViewEpisode = (seriesItemid, e) => {

        this.props.addSeriesIDtoRedirectionPage(VIEW_SERIES, seriesItemid);
        this.setState({ redirection: true })
    }

    addingSeriesToRedux = (seriesItem, e) => {

        this.props.addSerieItemWithSentFrom(seriesItem, "AllSeries");
        this.setState({ addingDone: true })
    }


    render() {

        const { series } = this.props;
        const { error } = this.props;
        const { redirection, addingDone } = this.state;

        let seriesList = null


        const { filterName, nameFilterCondition,
            description, descriptionFilterCondition,
            publishDate,
            dateFilterCondition,
            secondPublishDate,
            isFree,
            status,
            featured,
        } = this.props;

        let choosenNameFilter = '',
            choosenPublishDate = '',
            choosenStatus = '',
            choosenDescription = ''

            let episodesToFilter = ''
            if(series) {
                if (featured){
                    episodesToFilter = series.filter(item => (item.featured === true))
                }
                else {
                    episodesToFilter = series
                }
            }

        const FilteredList = episodesToFilter.filter(item => {

            if (nameFilterCondition == 'is') {
                choosenNameFilter = item.name == filterName
            } else if (nameFilterCondition == 'does not contain') {
                choosenNameFilter = !item.name.includes(filterName)
            } else if (nameFilterCondition == 'is not') {
                choosenNameFilter = item.name != filterName
            } else {
                choosenNameFilter = item.name.includes(filterName)
            }

            // console.log("description loop" + item.description)
            if (item.description !== null &&
                item.description !== undefined &&
                item.description !== '') {

                // console.log("description not null" + item.description)

                if (descriptionFilterCondition == 'is') {
                    choosenDescription = item.description == description
                } else if (descriptionFilterCondition == 'does not contain') {
                    choosenDescription = !item.description.includes(description)
                } else if (descriptionFilterCondition == 'is not') {
                    choosenDescription = item.description != description
                } else {
                    if (description !== '' && description !== null && description !== undefined) {
                        choosenDescription = item.description.includes(description)
                        console.log("contains = " + choosenDescription)
                    } else {
                        console.log("description true")
                        choosenDescription = true
                    }

                }
            }

            if (status == 'DEFAULT') {
                choosenStatus = item.status == 'Default' || item.status == 'DEFAULT'
            } else if (status == 'SUSPENDED') {
                choosenStatus = item.status == 'SUSPENDED'
            }


            let arrayOfDate = item.publishDate.split(" ")
            let date = arrayOfDate[0];

            if (dateFilterCondition == 'on') {
                choosenPublishDate = publishDate == date
            }
            else if (dateFilterCondition == 'after') {

                var seriesDate = new Date(date);
                var filterDate = new Date(publishDate);

                choosenPublishDate = filterDate < seriesDate
            }
            else if (dateFilterCondition == 'before') {
                var seriesDate = new Date(date);
                var filterDate = new Date(publishDate);

                choosenPublishDate = filterDate > seriesDate;
            }
            else if (dateFilterCondition == 'between') {

                if (secondPublishDate !== '' &&
                    secondPublishDate !== null &&
                    secondPublishDate !== undefined) {
                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);
                    var filterSecondDate = new Date(secondPublishDate);

                    choosenPublishDate = filterDate < seriesDate && seriesDate < filterSecondDate
                }
            }
            else if (dateFilterCondition == 'not between') {
                if (secondPublishDate !== '' &&
                    secondPublishDate !== null &&
                    secondPublishDate !== undefined) {
                    console.log("////////////////" + item.name + "////////////////////")
                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);
                    var filterSecondDate = new Date(secondPublishDate);

                    console.log("seriesDate")
                    console.log(date)
                    console.log(seriesDate)

                    console.log("filterDate")
                    console.log(publishDate)
                    console.log(filterDate)

                    console.log("filterDate")
                    console.log(secondPublishDate)
                    console.log(filterSecondDate)

                    let notbetween = filterDate < seriesDate && seriesDate < filterSecondDate

                    choosenPublishDate = !notbetween
                }

            }


            ///* Name and Date and status and description
            if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("Name and Date and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter && choosenStatus && choosenDescription
                }
            }
            ///* Name and Date and status
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("Name and Date and status")
                return choosenPublishDate && choosenNameFilter && choosenStatus
            }

            ///* Name and Date and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("Name and Date and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter && choosenDescription
                }
            }
            ///* Name and Date
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined
            ) {
                console.log("Name and Date")
                return choosenPublishDate && choosenNameFilter
            }

            //date and status and description
            else if (publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenStatus && choosenDescription
                }
            }
            //date and status
            else if (publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("date and status")
                return choosenPublishDate && choosenStatus
            }
            //date and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null &&
                dateFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenDescription
                }
            }
            //date
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined) {
                console.log("date")
                return choosenPublishDate
            }
            //name and status and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("name and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenNameFilter && choosenStatus && choosenDescription
                }
            }
            // status and description
            else if (
                status !== "" && status !== null && status !== undefined && status !== 'none' &&
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenStatus && choosenDescription
                }
            }

            //name and status
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("name and status")
                return choosenNameFilter && choosenStatus
            }
            // status
            else if (
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("//status")
                return choosenStatus
            }

            //name and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null &&
                nameFilterCondition !== undefined &&
                descriptionFilterCondition !== "" &&
                descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("///name and description")

                // base case both are null return all values
                if ((filterName == '' || filterName == null || filterName == undefined) &&
                    (description == '' || description == null || description == undefined)) {
                    console.log("both empty")
                    return true;
                }
                else if ((filterName !== '' && filterName !== null && filterName !== undefined) &&
                    (description !== '' && description !== null && description !== undefined)) {
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        console.log("both not empty")
                        return choosenNameFilter && choosenDescription
                    }
                }
                else if (filterName !== '' && filterName !== null && filterName !== undefined) {
                    console.log("name exist")
                    return choosenNameFilter
                }
                else if (description !== '' && description !== null && description !== undefined) {
                    console.log("description exist")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {

                        return choosenDescription;
                    }
                }
            }
            //name
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined) {
                console.log("name")
                return choosenNameFilter
            }
            // description
            else if (
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("description")

                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenDescription
                }
            }
        })



        if (redirection == true) {
            return (<Redirect to={VIEW_SERIES} ></Redirect>)
        }

        if (addingDone == true) {
            return (<Redirect to={UPDATE_SERIES} ></Redirect>)
        }

        if (this.props.seriesStatue === true) {
            seriesList = (<Loader />);
        }

        else if (this.props.seriesStatue === false &&
            this.props.error_loading_serieses === '') {

            let seriesItems = series

            if (
                filterName || publishDate || description || status
            ) {
                seriesItems = FilteredList
            }

            seriesList = seriesItems.length ? (
                seriesItems.map(seriesItem => {

                    let seriesDescription = "";
                    if (!seriesItem.image) {
                        seriesItem.image = placeHolder;
                    }

                    if (seriesItem.description !== null &&
                        seriesItem.description !== undefined &&
                        seriesItem.description !== '') {
                        seriesDescription = seriesItem.description
                    }
                    else {
                        seriesDescription = "No description"
                    }

                    return (
                        <div className="card" key={seriesItem.id}>
                            <div className="row" >
                                <div className="col ">
                                    <img className="img-thumbnail" src={seriesItem.image}
                                        alt="Series" />
                                </div>
                                <div className="col-10">

                                    <div >
                                        {/* link and on click call fuction
                                        that settes the parent and it's id in redux
                                        then redirect to view series */}
                                        <Link className="link" onClick={this.redirectViewEpisode.bind(this, seriesItem.id)}
                                        /*to={{ pathname: "/ViewSeries/" + seriesItem.id }} */>

                                            <span className="card-title">
                                                {seriesItem.name}
                                            </span>
                                        </Link>

                                        <DeleteSeries id={seriesItem.id} />

                                        <button type="submit"
                                            className="seriesCardButtons"
                                            onClick={this.addingSeriesToRedux.bind(this, seriesItem)}
                                        >
                                            Edit
                                             </button>


                                        <SetStateSeries state={seriesItem.status} id={seriesItem.id} sentFrom="AllSeries" />

                                    </div>
                                    <span className="font-italic small-font ">
                                        <span className="date">{GMTtolocalTime(seriesItem.createdDate)}</span>

                                        <hr />
                                        {seriesDescription}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                })
            )
                : (
                    <div className="text-center">No Series </div>
                )

        }
        else {
            seriesList = (<div><br /><center><div>{error}</div></center><br /></div>)
        }


        return (
            <div className="container">
                {seriesList}
            </div>

        )
    }
}
GetAllSeriesList.propTypes = {
    fetchAllSeries: propTypes.func.isRequired,
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    addSerieItemWithSentFrom: propTypes.func.isRequired

}
const mapStateToProps = state => ({
    series: state.series.items,
    seriesStatue: state.series.loading,
    error_loading_serieses: state.series.error_loading_serieses
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                fetchAllSeries,
                emptyEpisodes,
                emptyEvaluationAll,
                addSeriesIDtoRedirectionPage,
                addSerieItemWithSentFrom,
                clearQuestions,
                emptySeriesWithEpisode

            }
        )
        (GetAllSeriesList)
    );
