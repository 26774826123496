import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm , Popup} from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { setStateAdmin, fetchAllAdmins , emptyAdmin}
from '../../Actions/UserManagementActions'

const blockedAdmin = "BLOCKED";
const defaultAdmin = "DEFAULT";

class BlockingAdmin extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    setStateAdmin(uid, state) {
        this.props.setStateAdmin(uid, state);
    }


    handleSubmit = (e) => {

        const { uid, state } = this.props;

        if (state === blockedAdmin) {
            this.setStateAdmin(uid, defaultAdmin);

        } else {
            this.setStateAdmin(uid, blockedAdmin);
        }
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        const { state } = this.props;

        let contentPreview = null;
        console.log(success);

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyAdmin();
        }
        else if (success === true) {
            alert("Admin State Changed Successfully")
            this.props.emptyAdmin();
            this.props.fetchAllAdmins();

        }

        if (state === blockedAdmin) {

            contentPreview = (
                 <Popup trigger={
                <a className="user-lock" onClick={this.open}>
                    <i >
                        <FontAwesomeIcon icon="lock" />
                    </i>
                </a>}
                content='Tap to Unblock'
                position='top center'
                />
            )

        } else {

            contentPreview = (
                 <Popup trigger={
                <a className="user-lock" onClick={this.open}>
                    <i>  <FontAwesomeIcon icon="lock-open" />
                    </i>
                </a>}
                content='Tap to Block'
                position='top center'
                />
            )
        }

        return (

            <span >
                {contentPreview}
                <Confirm id="modal"
                    content='Are you sure you want
                    to change the admin state ??'
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </span>

        )

    }
}


BlockingAdmin.propTypes = {
    setStateAdmin: propTypes.func.isRequired,
    fetchAllAdmins: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    loading: state.admins.loading,
    error: state.admins.blocking_admin_error,
    success: state.admins.blocking_admin_sucessfully
});

export default withFirebase
(connect
    (mapStateToProps,
        { setStateAdmin, fetchAllAdmins, emptyAdmin })
    (BlockingAdmin)
);
