import React from "react";
import { HeaderData } from "../constants";
import ReactPlayer from "react-player";
import googlePlay from "../../../images/portal/icons/header-googlePlay.svg";
import appGallery from "../../../images/portal/icons/header-appGallery.svg";
import appStore from "../../../images/portal/icons/header-appStore.svg";
import web from "../../../images/portal/icons/web.png";
import {
  googlePlayLink,
  appGalleryLink,
  appleAppLink,
  webAppLink,
} from "../constants";
export const Header = () => {
  return (
    <header className="portal-section main-header">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="video-container">
              <ReactPlayer
                style={{ maxWidth: "100%" }}
                width={"auto"}
                playsInline
                fluid="true"
                controls={true}
                url="https://www.youtube.com/embed/7eNNaHUs39U?rel=0&wmode=opaque"
              />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2">
            <div className="header-content mb-5 mb-lg-0 ">
              <div className="header-title">{HeaderData.title}</div>
              <div className="header-desc">{HeaderData.descPart_1}</div>
              <div className="header-desc">{HeaderData.descPart_2}</div>
              <div className="header-desc">{HeaderData.descPart_3}</div>
              <div className="header-desc">{HeaderData.descPart_4}</div>
              <div className="header-desc">{HeaderData.descPart_5}</div>
              <div className="header-desc">{HeaderData.descPart_6}</div>
              <div className="header-desc">{HeaderData.descPart_7}</div>
            </div>
          </div>
          <div className="col-12 order-3">
            <div className="header-download-now mx-auto">
              <div className="download-now-title text-center mb-4">
                حمل التطبيق الان
              </div>
              <div className="header-download mb-4 flex-column flex-sm-row d-flex align-items-center justify-content-center">
                <div className="header-link mb-3 mb-sm-0">
                  <a
                    className="header-icon"
                    href={googlePlayLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googlePlay} alt="/" />
                  </a>
                </div>
                <div className="header-link mb-3 mb-sm-0">
                  <a
                    className="header-icon"
                    href={appGalleryLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appGallery} alt="/" />
                  </a>
                </div>
                <div className="header-link mb-3 mb-sm-0">
                  <a
                    className="header-icon"
                    href={appleAppLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appStore} alt="/" />
                  </a>
                </div>
              </div>
              <div className="download-now-title text-center mb-4">
                أو استمتع بتطبيق جيل على الويب{" "}
              </div>
              <div className="header-download mb-4 flex-column flex-sm-row d-flex align-items-center justify-content-center">
                <div className="header-link mb-3 mb-sm-0">
                  <a
                    className="header-icon"
                    href={webAppLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={web} alt="Jeel Web App" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
