import React, { useEffect , useState} from "react";
import mainLogo from "../../images/portal/icons/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import googlePlay from "../../images/portal/icons/header-googlePlay.svg";
import appGallery from "../../images/portal/icons/header-appGallery.svg";
import appStore from "../../images/portal/icons/header-appStore.svg";
import checked from "../../images/portal/icons/checked.svg";

import { useDispatch ,useSelector } from "react-redux";
import { empty } from "../../Actions/AuthActions";
import {
  emptyMobileUserProfileWithItem,
  emptyUserProfileWithItem,
} from "../../Actions/MobilePaymentProfileAction";
import { emptyPromoCode } from "../../Actions/PromoCodesActions";
import { useHistory,  BrowserRouter as Router, Switch, Route, Link, useParams, useLocation } from "react-router-dom";
import { PORTAL_LAND_PAGE, PORTAL_SUCCESSFUL } from "../../routes";
import {
  appGalleryLink,
  appleAppLink,
  googlePlayLink,
  JeelMADA,
  JeelMasterCard,
  webAppLink,
} from "./constants";
import web from "../../images/portal/icons/web.png";
import { getPaymentStatus } from "../../Actions/HyperPayAction";
import { Redirect } from "react-router-dom";


function PaymentResult() {

  let query = new URLSearchParams(useLocation().search);
  let paymentMethodName = query.get("name");
  const payingData = useSelector(state => state.mobilePaymentPackage.payingData);
  const checkoutId = useSelector(state => state.hyperPay.id);
  const status = useSelector(state => state.hyperPay.result);
  const loading = useSelector(state => state.hyperPay.loadingPaymentStatus);
  const promocode = useSelector(state => state.promoCodes.item);
  const error = useSelector(state => state.hyperPay.error);
  const isPromoCodeWork = useSelector(state => state.promoCodes.promoCodeWork)

  const history = useHistory();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");

  useEffect(()=>{
          dispatch(getPaymentStatus(checkoutId,payingData.cash,payingData.paymentType,JeelMADA,promocode.uid,isPromoCodeWork));

  },[])

  useEffect(()=>{
    if(paymentMethodName === JeelMADA){

      if (status.includes("pending")){
        setMessage("قيد الانتظار");
      }
      else if (status.includes("Cancelled")){
        setMessage("تم إلغاء الطلب");
      }
      else if (status.includes("limit")){
        setMessage("حدث خطأ " + "error, limited exceeded");
      }
      else if (status.includes("tries")){
        setMessage("حدث خطأ " + "error, too many tries");
      }else{
        setMessage(status);
      }
      
    }
   
   
  },[status])


  const handleRedux = () => {
    dispatch(emptyMobileUserProfileWithItem());
    dispatch(empty());
    dispatch(emptyUserProfileWithItem());
    dispatch(emptyPromoCode());
    history.push(PORTAL_LAND_PAGE);
  };
  return (
    <>
      <div className="container">
        <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
          <div className="d-flex align-items-center flex-column center-logo">
            <Link className="navbar-brand d-flex align-items-center" to="/">
              <img src={mainLogo} alt="" />
            </Link>
            <div className="already-subscribed mt-2">انت من عائلة جيل الان</div>
          </div>
          <button
            className="btn back-orange-btn"
            type="button"
            onClick={handleRedux}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="faChevronLeft" />
          </button>
        </div>

        <div className="successful-container">
     

          <div className="successful-checked-icon">
          {loading ? '': <FontAwesomeIcon icon={faTimesCircle} className="faTimesCircle"  /> }
          </div>
          

          <div className="successful-title">
           {loading ?<div className="successful-title">  جاري التحميل ... </div> :
              (status.includes("successfully") ?  history.push(PORTAL_SUCCESSFUL+`?name=${JeelMADA}`) :
               <div className="error" style={{ color: 'red' }}>{message}</div>)}
          </div>
          
      
          <div className="thank-you">شكرا لك</div>
          <div className="successful-download-now">
            <div className="download-now-title text-center mb-4">
              حمل التطبيق الان
            </div>
            <div className="successful-download mb-4 flex-column flex-sm-row d-flex align-items-center justify-content-center">
              <div className="successful-link mb-3 mb-sm-0">
                <a
                  className="header-icon"
                  href={googlePlayLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={googlePlay} alt="/" />
                </a>
              </div>
              <div className="successful-link mb-3 mb-sm-0">
                <a
                  className="header-icon"
                  href={appGalleryLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appGallery} alt="/" />
                </a>
              </div>
              <div className="successful-link mb-3 mb-sm-0">
                <a
                  className="header-icon"
                  href={appleAppLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appStore} alt="/" />
                </a>
              </div>
            </div>
            <div className="download-now-title text-center mb-4">
              أو استمتع بتطبيق جيل على الويب
            </div>
            <div className="header-download mb-4 flex-column flex-sm-row d-flex align-items-center justify-content-center">
              <div className="header-link mb-3 mb-sm-0">
                <a
                  className="header-icon"
                  href={webAppLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={web} alt="Jeel Web App" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentResult;
