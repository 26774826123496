import { LOAD_GENERAL_SETTING, GET_GENERAL_SETTING_DATA, ERROR_LOADING_GENERAL_SETTING, GENERAL_SETTING_UPDATE, ERROR_UPDATING_GENERAL_SETTING, EMPTY_GENERAL_SETTING } from '../Actions/Types'

const initial_state = {
    item:'',
    loading:'',
    error_loading_general_setting:'',
    generalSetting_updated_successfully:'',
    error_updating_generalSetting:''

}

export default function (state=initial_state,action)
{
    switch(action.type)
    {
        case LOAD_GENERAL_SETTING:
            return {
                ...state,
                loading:true,

            }
        
        case GET_GENERAL_SETTING_DATA:
            return {
                ...state,
                item:action.payload,
                loading:false,
                error_loading_general_setting:''
            }


        case ERROR_LOADING_GENERAL_SETTING:
            return {
                ...state,
                error_loading_general_setting:action.payload,
                loading:false,

            }

        case GENERAL_SETTING_UPDATE:
            return {
                ...state,
                item:action.payload,
                generalSetting_updated_successfully:true
            }

        case ERROR_UPDATING_GENERAL_SETTING:
            return {
                ...state,
                error_updating_generalSetting:action.payload,
                generalSetting_updated_successfully:false
            }

        case EMPTY_GENERAL_SETTING:
            return {
                ...state,
                
                error_loading_general_setting:'',
                generalSetting_updated_successfully:'',
                error_updating_generalSetting:''
            }

        default:
            return state
    }
}

