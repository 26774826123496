import { EMPTY_COUNTRY, SET_COUNTRY } from "../Actions/Types";

const initialState = {
    country: null,
};

export default function (state = initialState, action){
    switch(action.type){
        case SET_COUNTRY:
            return{
                ...state,
                country: action.payload,
            }
        case EMPTY_COUNTRY:
            return {
                ...state,
                country: '',
            }    
        default:
            return state;    
    }
};