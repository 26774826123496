import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import propTypes from 'prop-types';
import placeHolder from '../../images/placeholder.png';

import { Accordion, Icon } from 'semantic-ui-react'

/**Actions */
import { fetchAllMainValues } from '../../Actions/ContentMainValuesAction';
/*Components */
import Loader from '../Layout/Loader';
import EditMainValue from './EditMainValue';
import DeleteMainValue from './DeleteMainValue';
/*CSS*/
import '../../css/main-style.css';
import ContentsubValues from './ContentsubValues';

export class ContentMainValueCard extends Component {

    state = {
        activeIndex: 0,
    }

    componentDidMount() {
        this.props.fetchAllMainValues();
    }


    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }



    render() {

        const { filterName,
            nameFilterCondition,
            descriptionFilterCondition,
            description,
            filterOnMainValue } = this.props;

        console.log(filterName)
        console.log(nameFilterCondition)
        console.log(descriptionFilterCondition)
        console.log(description)
        console.log(filterOnMainValue)

        const { activeIndex } = this.state
        const { mainValues } = this.props;
        const { loading, error, success } = this.props;

        let choosenNameFilter = '',
            choosenDescription = '',
            FilteredList = []

        if (filterOnMainValue === true) {
            FilteredList = mainValues.filter(item => {
                if (nameFilterCondition == 'is') {
                    choosenNameFilter = item.contentValue == filterName
                } else if (nameFilterCondition == 'does not contain') {
                    choosenNameFilter = !item.contentValue.includes(filterName)
                } else if (nameFilterCondition == 'is not') {
                    choosenNameFilter = item.contentValue != filterName
                } else {
                    choosenNameFilter = item.contentValue.includes(filterName)
                }

                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== '') {

                    if (descriptionFilterCondition == 'is') {
                        choosenDescription = item.description == description
                    } else if (descriptionFilterCondition == 'does not contain') {
                        choosenDescription = !item.description.includes(description)
                    } else if (descriptionFilterCondition == 'is not') {
                        choosenDescription = item.description != description
                    } else {
                        if (description !== '' && description !== null && description !== undefined) {
                            choosenDescription = item.description.includes(description)

                        } else {
                            choosenDescription = true
                        }

                    }
                }

                if (
                    nameFilterCondition !== "" && nameFilterCondition !== null &&
                    nameFilterCondition !== undefined &&
                    descriptionFilterCondition !== "" &&
                    descriptionFilterCondition !== null &&
                    descriptionFilterCondition !== undefined
                ) {
                    console.log("///name and description")

                    // base case both are null return all values
                    if ((filterName == '' || filterName == null || filterName == undefined) &&
                        (description == '' || description == null || description == undefined)) {
                        console.log("both empty")
                        return true;
                    }
                    else if ((filterName !== '' && filterName !== null && filterName !== undefined) &&
                        (description !== '' && description !== null && description !== undefined)) {
                        if (item.description !== null &&
                            item.description !== undefined &&
                            item.description !== ""
                        ) {
                            console.log("both not empty")
                            return choosenNameFilter && choosenDescription
                        }
                    }
                    else if (filterName !== '' && filterName !== null && filterName !== undefined) {
                        console.log("name exist")
                        return choosenNameFilter
                    }
                    else if (description !== '' && description !== null && description !== undefined) {
                        console.log("description exist")
                        if (item.description !== null &&
                            item.description !== undefined &&
                            item.description !== ""
                        ) {

                            return choosenDescription;
                        }
                    }
                }



            })
        }
        // filter on subValue
        else {
            FilteredList = mainValues.map(mainItem => ({
                ...mainItem,
                subValues: mainItem.subValues
                    .filter(item => {

                        if (filterName !== ''
                            && filterName !== " "
                            && filterName !== null
                            && filterName !== undefined) {


                            if (nameFilterCondition == 'is') {
                                choosenNameFilter = item.contentValue == filterName
                            } else if (nameFilterCondition == 'does not contain') {
                                choosenNameFilter = !item.contentValue.includes(filterName)
                            } else if (nameFilterCondition == 'is not') {
                                choosenNameFilter = item.contentValue != filterName
                            } else {
                                choosenNameFilter = item.contentValue.includes(filterName)
                            }


                            if (
                                nameFilterCondition !== "" && nameFilterCondition !== null &&
                                nameFilterCondition !== undefined
                            ) {

                                if (filterName !== '' && filterName !== null && filterName !== undefined) {

                                    return choosenNameFilter
                                }

                            }

                        }
                        else {

                            return true
                        }


                    })

            })).filter(mainItem => mainItem.subValues.length > 0)
        }

        var counter = 0;
        let mainValueList = null

        if (loading === true) {
            mainValueList = (<Loader />)
        }
        else if (success === true && error === '') {

            let mainVlaueData = mainValues

            if ((filterName !== '' || description !== '')
                && filterOnMainValue === true) {
                mainVlaueData = FilteredList
            } else if (filterOnMainValue === false) {
                mainVlaueData = FilteredList
            }

            mainValueList = mainVlaueData.length ? (
                mainVlaueData.map(mainValueItem => {

                    counter = counter + 1

                    if (!mainValueItem.imageUrl) {
                        mainValueItem.imageUrl = placeHolder;
                    }
                    return (
                        <div className="card" key={mainValueItem.id}>

                            <Accordion fluid styled>
                                <Accordion.Title
                                    active={activeIndex === counter}
                                    index={counter}
                                    onClick={this.handleClick}>

                                    <div className="row" >
                                        <Icon name='dropdown' />
                                        <div>
                                            {mainValueItem.number}
                                        </div>


                                        <div className="col ">

                                            <img className="img-thumbnail"
                                                src={mainValueItem.imageUrl}
                                                alt="Main Value image" />
                                        </div>
                                        <div className="col-10">

                                            <div >


                                                {mainValueItem.contentValue}
                                                <div className="acor-btn-group">

                                                    <EditMainValue mainValueItem={mainValueItem} />

                                                    <DeleteMainValue id={mainValueItem.id} />

                                                </div>

                                            </div>
                                            <span className="font-italic small-font ">

                                                {/* {mainValueItem.description !== " " &&
                                                    mainValueItem.description !== '' &&
                                                    mainValueItem.description !== null &&
                                                    mainValueItem.description !== undefined ?
                                                    <span className="time">{mainValueItem.description}
                                                    </span> :
                                                    <span className="time">No description
                                                    </span>} */}


                                                <hr />
                                            </span>
                                        </div>
                                    </div>
                                </Accordion.Title>

                                <Accordion.Content active={activeIndex === counter}>
                                    <div id="collapseOne" className="collapse show"
                                        aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div className="card-body">

                                            {/* Sub Values */}
                                            <ContentsubValues subvalues={mainValueItem.subValues}
                                                mainValueId={mainValueItem.id}

                                            />


                                        </div>
                                    </div>
                                </Accordion.Content>

                            </Accordion>
                        </div>
                    )
                })

            ) : (
                    <div className="text-center">No MainValues </div>
                )

        }

        return (
            <div className=" mt-3 p-0">
                <div className="card-body p-0">
                    {mainValueList}
                </div>
            </div>
        )
    }
}

ContentMainValueCard.propTypes = {
    fetchAllMainValues: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    mainValues: state.contentMainValue.items,
    subvalues: state.contentMainValue.items.subValues,
    loading: state.contentMainValue.loading,
    error: state.contentMainValue.getting_all_main_values_error,
    success: state.contentMainValue.getting_all_main_values_sucessfully
});

export default
    withRouter
        (connect
            (mapStateToProps,
                { fetchAllMainValues }
            )
            (ContentMainValueCard)
        );