import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react';
import { withFirebase } from '../../config';
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from "../Layout/Footer";
import EditActivityComponent from '../Activity/EditActivityComponent'
/*CSS*/
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/AppCss/Episode/EpisodeButtons.css';

/* Routes */
import { JUMPING_DETAIL } from '../../routes';

const INITIAL_STATE = {
    redirection: false,
}

class editJump extends Component {
  constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
  }
  handleFinish = (e) => {
      this.setState({ redirection: true })
  }

    render() {
      const { redirection } = this.state;
      if (redirection == true) {
          return (<Redirect to={JUMPING_DETAIL} ></Redirect>)
      }

      // sending activity ID to EditActivityComponent
      let activityId = this.props.match.params.activityId;

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="main-progress-bar">
                                                <ol className="progress-tracker">
                                                    <li className="step active s2-active"><span className="step-name small-font">ِEdit Activity</span></li>
                                                </ol>
                                            </div>
                                            <br />

                                            <EditActivityComponent activityId={activityId} />
                                            <br />
                                            <Button id="episode_cancel"
                                                className="float-right"  style={{marginRight: '47px'}}
                                                onClick={this.handleFinish.bind(this)}>
                                                Finish
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

export default editJump
