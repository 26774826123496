import React, { Component } from 'react'
import axios from 'axios';
import { prepareCheckout } from '../../Promises/HyperPay';

 class HyperPay extends Component {

    state = {
        checkoutId: null,
        loading: true
    }

    componentDidMount() {
        prepareCheckout(10).then(res => {
            console.log(res.data.id)
            this.setState({
                checkoutId: res.data.id,
                loading: false
            })
        })
    }

    renderPaymentform = () => {
        console.log(this.state.checkoutId)

        console.log(`http://jeel-admin-dev.us-east-1.elasticbeanstalk.com/ResultPage/${this.state.checkoutId}`)
        const script = document.createElement("script");
        script.src =
            `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${this.state.checkoutId}`;
        script.async = true;
        script.locale = "ar";
        document.body.appendChild(script);
        const form = document.createElement("form")
        // dev env link
        form.action = `http://jeel-admin-dev.us-east-1.elasticbeanstalk.com/ResultPage/${this.state.checkoutId}`;
        form.setAttribute("class", "paymentWidgets");
        form.setAttribute("data-brands", "VISA MASTER AMEX MADA STC_PAY")
        document.body.appendChild(form);
    }

    render() {
        if (!this.state.loading) {
            return (
                <div>
                    {this.renderPaymentform()}
                </div>
            );
        } else {
            return (
                <div> Loading Payment Form... </div>
            )
        }
    }
}
export default HyperPay
