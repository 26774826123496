import React, { Component } from 'react'
import { DateInput } from 'semantic-ui-calendar-react';
import {Dropdown} from 'semantic-ui-react'
import CountryFilter from './CountryFilter';
import {getkidsUsageReport,emptyReport,getKidsUsageReportFilteredByCountry
    ,getKidsUsageReportByGender,getKidsUsageByAgeRange} from '../../Actions/ReportingActions'
import {connect} from 'react-redux'
import GenderFilter from './GenderFilter';
import AgeRangeFilter from './AgeRangeFilter';
import countryList from 'react-select-country-list'




 class AllReport extends Component {

    state = {
        startDate:'',
        endDate:'',
        countryLength:'',
        countryOptions:[],
        gender:'',
        ages:[],
        countries:[]
    }



    componentDidMount()
    {
        this.props.emptyReport();
        // console.log( countryList().getLabels() );
        let countries = countryList().getLabels();
        let countryOptions = [];
        for (let i=0 ;i<countries.length;i++)
        {
            let c = {key:countries[i],value:countries[i],text:countries[i]}
            countryOptions.push(c);
        }
        this.setState({countryOptions:countryOptions})

    }

    handleDropDownChange =(e,{value})=>
    {
        this.setState({
            countries:value
        })
        this.props.getKidsUsageReportFilteredByCountry(this.state.startDate,this.state.endDate,value)
    }

    handleDateChange=(e,{name,value})=>
    {
        if (this.state.hasOwnProperty(name))
        {
            this.setState({
                [name]:value
            })

             if (name ==="endDate")
            {
                if (this.state.startDate)
               { this.props.getkidsUsageReport(this.state.startDate,value)
                this.props.getKidsUsageReportByGender(this.state.startDate,value)
                this.props.getKidsUsageByAgeRange(this.state.startDate,value)
                this.props.getKidsUsageReportFilteredByCountry(this.state.startDate,value,this.state.countries)
            }
        }            if (name==='startDate')
            {
                if (this.state.endDate)
                {
                    this.props.getkidsUsageReport(value,this.state.endDate)
                    this.props.getKidsUsageReportByGender(value,this.state.endDate)
                    this.props.getKidsUsageByAgeRange(value,this.state.endDate)
                    this.props.getKidsUsageReportFilteredByCountry(value,this.state.endDate,this.state.countries)

                }
            }

        }
    }

    handleGenderDropDown=(e,{value})=>
    {
        this.setState({gender:value})
    }

    handleAgeDropDownChange=(e,{value})=>
    {
        this.setState({
            ages:value
        })
    }
    render() {
      let  genders = [{key:'male',value:'male',text:'male'},{key:'female',value:'female',text:'female'}]
      let ageRange = [{key:'0-3',value:'0-3',text:'0-3'},{key:'3-9',value:'3-9',text:'3-9'},{key:'9+',value:'9+',text:'9+'}]

        const {kidsUsage,loading,error_loading,countryFilter,error_loading_country_filter,loading_country_filter} = this.props
        const {genderFilter,error_loading_gender_filter,loading_gender_filter} = this.props
        const {ageFilter,error_loading_age_filter,loading_age_filter} = this.props
    return (
            <div>

                <div className="tab-contents">
                    <form onSubmit={(e)=> e.preventDefault()}>
                        <div className="form-row mt-2">
                            <div class="col-md-6 col-12 mb-3">
                                <label class="small-label" for="">Start Date</label>
                                <DateInput
                                    name="startDate"
                                    closeOnMouseLeave
                                    placeholder="start date"
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                    fluid
                                    value={this.state.startDate}
                                    onChange={this.handleDateChange}
                                />
                            </div>
                            <div class="col-md-6 col-12 mb-3">
                                <label class="small-label" for="">End Date</label>
                                 <DateInput
                                    name="endDate"
                                    closeOnMouseLeave
                                    placeholder="end date"
                                    fluid
                                    value = {this.state.endDate}
                                    onChange={this.handleDateChange}
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                />
                            </div>

                            <div class="col-md-4 col-12 mb-3">
                                <label class="small-label" for="">Country</label> <br/>
                               <Dropdown
                               clearable
                               multiple
                               search
                               selection
                               options={this.state.countryOptions}
                                placeholder="select Country"
                                onChange={this.handleDropDownChange}></Dropdown>

                            </div>

                            <div class="col-md-4 col-12 mb-3">
                                <label class="small-label" for="">Gender</label> <br/>
                                <Dropdown
                               clearable
                               search
                               selection
                               options={genders}
                                placeholder="select gender"
                                onChange={this.handleGenderDropDown}></Dropdown>
                            </div>
                            <div class="col-md-4 col-12 mb-3">
                                <label class="small-label" for="">Age Range</label> <br/>
                                <Dropdown
                                clearable
                                search
                                selection
                                options={ageRange}
                                placeholder="select age range"
                                multiple
                                onChange = {this.handleAgeDropDownChange}     ></Dropdown>
                            </div>
                        </div>
                    </form>


                    <div class="custom-grid">
                        <div class="row t-head">
                            <div class="col-md-4">
                                <div class="row t-title title-main-style">Total Sessions/day</div>
                            </div>
                            <div class="col-md-4">
                                <div class="row t-title title-main-style">Total time of usage/day</div>
                            </div>
                            <div class="col-md-4">
                                <div class="row t-title title-main-style">Average Session time</div>
                            </div>
                        </div>
                            <CountryFilter kidsUsage = {kidsUsage}
                             error_loading={error_loading}
                              loading={loading}
                              countryFilter={countryFilter}
                              error_loading_country_filter={error_loading_country_filter}
                              loading_country_filter = {loading_country_filter}
                              countries={this.state.countries}
                              />

                            <GenderFilter genderFilter={genderFilter}
                            error_loading_gender_filter={error_loading_gender_filter}
                            loading_gender_filter={loading_gender_filter}
                            gender={this.state.gender}/>

                       <AgeRangeFilter kidsUsage={kidsUsage}
                       error_loading={error_loading}
                        loading={loading}
                        ageFilter = {ageFilter}
                        error_loading_age_filter = {error_loading_age_filter}
                        loading_age_filter = {loading_age_filter}
                        ages = {this.state.ages}
                        />
                    </div>





                </div>
            </div>
        )
    }
}
const mapStateToProps = state =>
({
    kidsUsage: state.kidsReport.item,
    loading: state.kidsReport.loading,
    error_loading: state.kidsReport.error_loading_kid_usage,
    countryFilter:state.kidsReport.countryFilter,
    error_loading_country_filter:state.kidsReport.error_loading_country_filter,
    loading_country_filter:state.kidsReport.loading_country_filter,
    genderFilter:state.kidsReport.genderFilter,
    loading_gender_filter:state.kidsReport.loading_gender_filter,
    error_loading_gender_filter:state.kidsReport.error_loading_gender_filter,
    ageFilter :state.kidsReport.ageFilter,
    loading_age_filter:state.kidsReport.loading_age_filter,
    error_loading_age_filter:state.kidsReport.error_loading_age_filter
})
export default connect(mapStateToProps,{getkidsUsageReport,emptyReport,getKidsUsageReportFilteredByCountry,
    getKidsUsageReportByGender,getKidsUsageByAgeRange}) (AllReport)
