import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Dropdown, Input,
    Button, Modal
} from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Popup } from 'semantic-ui-react'

/*Redux */
import { withFirebase } from '../../config'
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

/*Actions */
import { activePaymentPlan, emptyPackageActivation }
    from '../../Actions/CustomerActions';


const payment_package_type_list = [{
    key: 0,
    text: 'Monthly',
    value: 'Monthly'
},
{
    key: 1,
    text: 'Yearly',
    value: 'Yearly'
},
{
    key: 2,
    text: 'Weekly',
    value: 'Weekly'
}]


const paying_method = [{
    key: 0,
    text: 'Manual',
    value: 'Manual'
},
{
    key: 1,
    text: 'Apple',
    value: 'Apple'
},
{
    key: 2,
    text: 'Fawry',
    value: 'Fawry'
}, {
    key: 3,
    text: 'Stripe',
    value: 'Stripe'
},
{
    key: 4,
    text: 'STC_PAY',
    value: 'STC_PAY'
},
{
    key: 5,
    text: 'Mada',
    value: 'MADA'
}]

const paying_currency = [{
    key: 0,
    text: 'USD',
    value: 'USD'
},
{
    key: 1,
    text: 'SAR',
    value: 'SAR'
},
{
    key: 2,
    text: 'EGP',
    value: 'EGP'
}, {
    key: 3,
    text: 'Others',
    value: 'Others'
}]

const INITIAL_STATE = {

    showModal_1: '',
    /////////// data
    chargeId: '',
    paymentDate: '',
    expirationDate: '',
    paymentPackageType: 'Monthly',
    payingMethod: '',
    price: 0,
    payingCurrency: 'USD'
};

function addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}

function addWeek(date) {
    date.setDate(date.getDate() + 7);
    return date;
}

function expirationDateFormate(date, type) {
    var today = new Date();
    var expiDate;

    var dd;
    var mm;
    var yyyy;

    let arrayOfDate = date.split('/');

    mm = arrayOfDate[0]

    dd = arrayOfDate[1];

    yyyy = arrayOfDate[2];


    // Subtract 1 from month to convert it to system ( January is 0!)
    expiDate = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0);

    if (type == 'Monthly') {
        expiDate = addMonths(expiDate, 1);
    }
    else if (type == 'Yearly') {
        expiDate = addMonths(expiDate, 12);
    }
    else {
        expiDate = addWeek(expiDate);
    }
    // add 1 to month to convert it to general ( January is 1!)
    var tempMonth = expiDate.getMonth();
    var tempMonth = tempMonth + 1;


    date = tempMonth + '/' + expiDate.getDate() + '/' + expiDate.getFullYear();

    return date;
}



class CustomerMobilePaymentControll extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };


        const { closeModal } = this.props;
        if (closeModal === true) {

            console.log("cloooosing the modal")
            this.setState({
                chargeId: '',
                paymentDate: '',
                expirationDate: '',
                showModal_1: false,
                payingMethod: '',
                price: 0,
                payingCurrency: 'USD'
            
            })
        }
    }

    componentDidMount() {

        const { closeModal } = this.props;
        if (closeModal === true) {

            console.log("cloooosing the modal")
            this.setState({
                chargeId: '',
                paymentDate: '',
                expirationDate: '',
                showModal_1: false,
                payingMethod: '',
                price: 0,
                payingCurrency: 'USD'
            
            })
        }
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal_1) {
            return {
                showModal_1: nextprops.showModal_1
            }
        }
        return state;
    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }


    handlePaymentPackageType = (e, { value }) => {

        this.setState({
            paymentPackageType: value
        })

        // calculate the expiration date 
        const { paymentDate } = this.state;

        let calcExpirationDateMonth =
            expirationDateFormate(paymentDate, value);

        this.setState({ expirationDate: calcExpirationDateMonth })
    }


    handlePayingMethod = (e, { value }) => {

        this.setState({
            payingMethod: value
        })
    }


    handlePayingCurrency =(e, { value }) => {

        this.setState({
            payingCurrency: value
        })
    }

    handleDateChange = (e, { name, value }) => {


        if (this.state.hasOwnProperty(name)) {
            this.setState({
                [name]: value
            })

            const { paymentPackageType } = this.state;

            let calcExpirationDateMonth = expirationDateFormate(value, paymentPackageType);

            this.setState({ expirationDate: calcExpirationDateMonth })

        }
    }


    handleSubmit = (e) => {
        const { chargeId, paymentDate,
            expirationDate, paymentPackageType, 
            payingMethod,
            price, payingCurrency } = this.state;

        const { uid } = this.props;

        console.log(uid + " " +
            chargeId + " " +
            paymentDate + " " +
            expirationDate + " " +
            paymentPackageType)

        this.props.activePaymentPlan(uid, chargeId, paymentDate,
            expirationDate, paymentPackageType, payingMethod,
            price, payingCurrency);

        e.preventDefault();
    }

    closeModal = (e) => {
        console.log("closeModel")
        this.setState({ showModal_1: false })
    }

    openModal = (e) => {
        this.setState({ showModal_1: true })
    }

    render() {

        const { chargeId, paymentDate,
            expirationDate, paymentPackageType, payingMethod,
            price, payingCurrency } = this.state;


        const isInvalidData =
            chargeId === '' ||
            paymentDate === '' ||
            expirationDate === '' ||
            paymentPackageType === '' ||
            payingMethod === '' ||
            price === 0 || price === " " ||price === "" ||
            payingCurrency === '';

        return (
            <Modal id="modal"
                trigger={
                    <Popup trigger={
                        <a className="key" onClick={this.openModal}>
                            <i >
                                <FontAwesomeIcon icon="money-check" />
                            </i>
                        </a>}
                        content='Active Mobile Payment Package'
                        position='top center'
                    />
                } onClose={this.closeModal} open={this.state.showModal_1}>
                <Modal.Header>Customer Activate Payment Package </Modal.Header>
                <Modal.Content >
                    <Modal.Actions>
                        <form className="white" onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <div className="col-md-12 col-12">

                                    <label htmlFor="chargeId" className="label">
                                        <span className="required_input">*</span>
                                        Charge Id </label>
                                    <Input fluid name="chargeId" id="chargeId"
                                        onChange={this.handleChange}
                                        value={this.state.chargeId} />
                                    <br />

                                    <label htmlFor="chargeId" className="label">
                                        <span className="required_input">*</span>
                                        Price </label>
                                    <Input fluid type="number" min="0"
                                        name="price" id="price"
                                        onChange={this.handleChange}
                                        value={this.state.price} />
                                    <br />

                                    <label htmlFor="episode_series_id"
                                        className="label">
                                        <span className="required_input">*</span>
                                        Select Paying Currency</label><br />

                                    <Dropdown fluid selection
                                        placeholder='Select Paying Currency'
                                        id="paying_currency"
                                        onChange={this.handlePayingCurrency}
                                        options={paying_currency}
                                        value={this.state.payingCurrency}
                                    />
                                    <br />

                                    <label htmlFor="episode_series_id"
                                        className="label">
                                        <span className="required_input">*</span>
                                        Select Paying Method</label><br />

                                    <Dropdown fluid selection
                                        placeholder='Select Payment package type'
                                        id="paying_method"
                                        onChange={this.handlePayingMethod}
                                        options={paying_method}
                                        value={this.state.payingMethod}
                                    />
                                    <br />

                                    <label htmlFor="episode_series_id"
                                        className="label">
                                        <span className="required_input">*</span>
                                        Select Payment Package Type</label><br />

                                    <Dropdown fluid selection
                                        placeholder='Select Payment package type'
                                        id="episode_series_id"
                                        onChange={this.handlePaymentPackageType}
                                        options={payment_package_type_list}
                                        value={this.state.paymentPackageType}
                                    />
                                    <br />

                                    <label class="small-label" for="">
                                        <span className="required_input">*</span>
                                        Payment Date
                                        </label>

                                    <DateInput
                                        name="paymentDate"
                                        closeOnMouseLeave
                                        placeholder="payment date"
                                        iconPosition="right"
                                        dateFormat="MM/DD/YYYY"
                                        required
                                        fluid
                                        value={this.state.paymentDate}
                                        onChange={this.handleDateChange}
                                    />
                                    <br />

                                    <label class="small-label" for="">
                                        <span className="required_input">*</span>
                                        Expiration Date
                                        </label>

                                    <DateInput
                                        name="expirationDate"
                                        closeOnMouseLeave
                                        placeholder="expiration date"
                                        iconPosition="right"
                                        dateFormat="MM/DD/YYYY"
                                        required
                                        fluid
                                        value={this.state.expirationDate}
                                        onChange={this.handleDateChange}
                                    />
                                    <br />

                                    <div className="input-field">

                                        <Button onClick={this.handleSubmit}
                                            disabled={isInvalidData}
                                            id="saveButton" >
                                            Save
                                        </Button>

                                    </div>
                                </div>
                            </div>
                        </form>

                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}


CustomerMobilePaymentControll.propTypes = {

    activePaymentPlan: propTypes.func.isRequired,
    emptyPackageActivation: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    showModal_1: state.customers.showModal_1
});

export default withFirebase
    (connect
        (mapStateToProps,
            { activePaymentPlan, emptyPackageActivation }
        )(CustomerMobilePaymentControll)
    );


