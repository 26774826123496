import store from '../Store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { post } from 'axios'

export function saveChannel(activityId) {
    return new Promise(function (resolve, reject) {
        let radioChannel = store.getState().radioChannel.item;

        let isFree = '', subValueId = '';
        if (radioChannel.isFree === 'paid') {
            isFree = 0
        }
        else if (radioChannel.isFree === 'free') {
            isFree = 1
        }

        if (radioChannel.subValueId === '' ||
            radioChannel.subValueId === undefined ||
            radioChannel.subValueId === null) {
            subValueId = 0
        } else {
            subValueId = radioChannel.subValueId
        }


        let description = radioChannel.description;
        if (radioChannel.description == null ||
            radioChannel.description == undefined ||
            radioChannel.description == '') {
            description = ''
        }

        let trackWithMusic = radioChannel.trackWithMusic;
        if (trackWithMusic == null ||
            trackWithMusic == undefined ||
            trackWithMusic == '') {

            trackWithMusic = ''
        }

        let trackWithoutMusic = radioChannel.trackWithoutMusic;
        if (trackWithoutMusic == null ||
            trackWithoutMusic == undefined ||
            trackWithoutMusic == '') {

            trackWithoutMusic = ''
        }


        const url = "/Channel/addRadioChannel";
        const formData = new FormData();
        formData.append('Name', radioChannel.name);
        formData.append('Description', description);
        formData.append('Name_In_English', radioChannel.name_in_english);
        formData.append('Description_In_English', radioChannel.description_in_english);

        formData.append('Image', radioChannel.image);
        formData.append('Status', 'SUSPENDED');
        formData.append('Publish_Date', radioChannel.PublishDate);
        formData.append('Activity_ID', activityId);
        formData.append('Is_free', isFree);
        formData.append('Voice', radioChannel.voice);
        formData.append('Track_With_Music', trackWithMusic);
        formData.append('Track_Without_Music', trackWithoutMusic);
        formData.append('Content_Sub_Value_Id', subValueId);
        formData.append('Radio_Id', radioChannel.selected_radio_id);
        formData.append('featured', radioChannel.featured);
        formData.append('contentVendorSelectedId', radioChannel.contentVendorSelectedId);
        
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res) => {
            resolve(res);
            return (res);
        }).catch((Error) => {
            reject(Error)
            return Error.message
        })
    })
}