import { 
    GET_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    LOAD_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    ERROR_COUNT_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    EMPTY_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY
   } from "../Actions/Types";

   const initial_state = {
    counts: [],
    loading: false,
    error_count_loading_subscriptions_report_by_country: '',
  }

export default function (state = initial_state, action){
switch(action.type){
       case LOAD_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY:
       return {
           ...state,
           loading: true,
       }
   
   case GET_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY:
       return {
           ...state,
           counts: action.payload,
           loading:false,
           error_count_loading_subscriptions_report_by_country:'',
       }
   
   case ERROR_COUNT_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY:
       return{
           ...state,
           loading: false,
           error_count_loading_subscriptions_report_by_country: action.payload,
       }
       case EMPTY_COUNT_SUBSCRIPTIONS_REPORT_BY_COUNTRY:
       return{
           ...state,
           counts: '',
           loading: '',
           error_count_loading_subscriptions_report_by_country:'',
       }
       
   default:
       return state;    
}
}