import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
/*header */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import AudioPlayer from "react-h5-audio-player";
/*css */
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../node_modules/video-react/dist/video-react.css';
/*Action */
import {
    getPdfBookData,
    emptyPdfBook,
    savePdfBookPuzzle,
    savePdfBookPaintingGame,
    emptyAllPdfBooks,
    addPdfBookItemWithSentFrom
} from '../../Actions/PdfBookAction';

import { addDragDropParentIDtoRedirectionPage, addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*component */
import QuestionPreview from '../Layout/QuestionPreview';
import GamePreview from '../Layout/GamePreview';
import PaintingPreview from '../Layout/PaintingPreview';
import UploadImage from '../S3Uploading/UploadImage';
import EvaluationPreview from '../Evaluation/EvaluationPreview';
import DragDropPreview from '../Layout/DragDropPreview'
import {
    PDF_BOOK_TO_EDIT,
    PDF_BOOK_ACTIVITY_TO_EDIT,
    SIGN_IN, VIEW_PDF_BOOK
} from '../../routes';


const INITIAL_STATE = {
    bookId: '',
    // bookName: '',

    Icon_Image: "",
    Painting_Image: ""
}

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

function ConvertDate(date) {
    var date = new Date(date)
    var convertedDate = date.toLocaleString()
    return convertedDate
}

class PdfBookDetails extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.props.emptyPdfBook();
        // 

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            // 
            // getting BOOK Paren tId from redux
            const { redirectionPage } = this.props;
            let id = redirectionPage.series_Id;

            // adding BOOK As parent page
            // to redirect to it after adding BOOK
            this.props.emptyAllPdfBooks()
            this.props.addSeriesIDtoRedirectionPage(VIEW_PDF_BOOK, id);

            this.props.getPdfBookData(id);
            this.props.addDragDropParentIDtoRedirectionPage(VIEW_PDF_BOOK, id);

        }
    }

    componentDidMount() {

    }

    getIconImageUrl(imageUrl) {

        if (imageUrl) {
            this.setState({ Icon_Image: imageUrl });
            const { redirectionPage } = this.props;
            let bookId = redirectionPage.series_Id;
            savePdfBookPuzzle(bookId, imageUrl)
                .then(res => {
                    alert("Puzzle Game Item Added Successfully");
                    // let id = this.props.match.params.bookId;
                    const { redirectionPage } = this.props;
                    let id = redirectionPage.series_Id;
                    this.props.getPdfBookData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        setTimeout(() => {
            this.setState({ Icon_Image: "" });
        }, 10);
    }

    getPaintingImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ Painting_Image: imageUrl });
            const { redirectionPage } = this.props;
            let bookId = redirectionPage.series_Id;
            savePdfBookPaintingGame(bookId, imageUrl)
                .then(res => {
                    alert("Painting Game Item Added Successfully");
                    // let id = this.props.match.params.bookId;
                    const { redirectionPage } = this.props;
                    let id = redirectionPage.series_Id;
                    this.props.getPdfBookData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        setTimeout(() => {
            this.setState({ Painting_Image: "" });
        }, 10);
    }


    addingItemToRedux = (item, e) => {

        this.props.addPdfBookItemWithSentFrom(item, 'ViewPdfBook');
        this.setState({
            addingDone: true,
            itemId: item.id
        })
    }



    render() {

        const { redirection, addingDone } = this.state;
        if (redirection == true) {
            return (<Redirect to={VIEW_PDF_BOOK} ></Redirect>)
        }

        if (addingDone == true) {
            return (<Redirect to={PDF_BOOK_TO_EDIT} ></Redirect>)
        }

        // 
        const { redirectionPage } = this.props;
        let bookId = redirectionPage.series_Id;
        const { pdfBook } = this.props
        const { activity } = this.props
        const puzzles = this.props.pdfBook.puzzles
        const paintingGames = this.props.pdfBook.paintingGames
        const dragDrops = this.props.pdfBook.dragDrops

        let evaluationPreview = null;

        let questionsList = null,
            editActivityLink = null,
            gamesList = null,
            gamesPaintingList = null;
        // 

        if (activity !== undefined) {

            questionsList = (
                <QuestionPreview state='view'
                    questions={activity.questions} />)

            evaluationPreview = (
                <EvaluationPreview
                    evaluationCategory={activity.evaluationCategory} />);


            if (activity.id !== undefined) {
                editActivityLink = (
                    <Link to={{
                        pathname: PDF_BOOK_ACTIVITY_TO_EDIT
                            + "/" + activity.id
                            + "/" + bookId
                    }}>

                        <Button id="episode_view"> Edit </Button>

                    </Link>);
            }
        }

        let subvalue = "No Sub Value"
        if (pdfBook.contentSubValue !== null
            && pdfBook.contentSubValue !== undefined) {

            if (pdfBook.contentSubValue.contentValue !== null
                &&
                pdfBook.contentSubValue.contentValue !== undefined) {
                subvalue = pdfBook.contentSubValue.contentValue;
            }
        }

        if (puzzles !== undefined) {
            gamesList = (
                <GamePreview
                    state='viewPdfBook'
                    games={puzzles}
                    pdfId={this.props.pdfBook.id}
                />)
        }

        if (paintingGames !== undefined) {
            gamesPaintingList = (
                <PaintingPreview
                    state='viewPdfBook'
                    games={paintingGames}
                    pdfId={this.props.pdfBook.id}
                />)
        }

        let book_drag_Drop = null;
        if (dragDrops !== undefined) {
            book_drag_Drop = (<DragDropPreview
                games={dragDrops}
                state="viewPdfBook"
                parentId={this.props.pdfBook.id}
                pdfId={this.props.pdfBook.id} />);
        }
        let isFree = ''
        if (pdfBook.isFree == 0) {
            isFree = 'paid'
        }
        else if (pdfBook.isFree == 1) {
            isFree = 'free'
        }

        let titleAudioView = ''
        if (pdfBook.voice !== undefined &&
            pdfBook.voice !== null &&
            pdfBook.voice !== "undefined"
            && pdfBook.voice !== "null"
            && pdfBook.voice !== 'null') {
            titleAudioView = (<AudioPlayer
                src={pdfBook.voice}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            titleAudioView = (
                <td fluid className="episode_label"> No Title Audio</td>
            )
        }

        // 

        let bookAudioView = ''
        if (pdfBook.bookVoice !== undefined &&
            pdfBook.bookVoice !== null &&
            pdfBook.bookVoice !== "undefined"
            && pdfBook.bookVoice !== "null"
            && pdfBook.bookVoice !== 'null') {
            bookAudioView = (<AudioPlayer
                src={pdfBook.bookVoice}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            bookAudioView = (
                <td fluid className="episode_label"> No Book Audio</td>
            )
        }

        let audioMarker = ''


        let bookDescription = '';
        if (pdfBook.description !== null &&
            pdfBook.description !== undefined &&
            pdfBook.description !== '') {
            bookDescription = pdfBook.description
        }
        else {
            bookDescription = "No description"
        }

        let featured = false;
        if (pdfBook.featured !== null && pdfBook.featured !== undefined) {
            featured = pdfBook.featured;
        }


        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <label htmlFor="" >{pdfBook.pdfName}</label>

                                                <hr />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <img src={pdfBook.pdfImage}
                                                            alt="" className="image_Preview rounded" />
                                                    </div>

                                                    <div className="col">
                                                        <table className='w-100'>
                                                            <tr>
                                                                <th className="episode_label"> Book Name </th>
                                                                <td fluid className="episode_label"> {pdfBook.pdfName} </td>
                                                            </tr>
                                                            <tr>
                                                                <th className="episode_label"> Description </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {bookDescription}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> payment availability </th>
                                                                <td fluid className="episode_label"> {isFree} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Sub Value </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {subvalue}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Schedule </th>
                                                                <td fluid className="episode_label">{GMTtolocalTime(pdfBook.publishDate)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Title Audio </th>
                                                                {titleAudioView}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Pdf Book </th>

                                                                <td fluid className="episode_label">
                                                                    {
                                                                        pdfBook.itemUrl ? <a href={pdfBook.itemUrl} download>
                                                                            View Pdf
                                                                        </a> : <p>No Pdf File</p>
                                                                    }

                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Featured </th>
                                                                {featured ? "True" : "False"}
                                                            </tr>

                                                        </table>

                                                    </div>


                                                </div>

                                                <Link className="link" to={PDF_BOOK_TO_EDIT}>
                                                    <Button color='blue'
                                                        onClick={this.addingItemToRedux.bind(this, pdfBook)}
                                                        id="editButton">
                                                        Edit
                                                    </Button>
                                                </Link>
                                                <br /> <br /> <br />


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Question</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {questionsList}


                                                </div>
                                                <br />

                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Evaluation</label>
                                                    <hr id="shorthr" />
                                                    <br />
                                                    {evaluationPreview}

                                                    <div className="buttons_container">
                                                        {editActivityLink}

                                                    </div>

                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Puzzle Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {gamesList}

                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getIconImageUrl.bind(this)}
                                                            imageUrl={this.state.Icon_Image}
                                                            imagepreview={this.state.Icon_Image}
                                                            s3DirectryrName='pdfBook/puzzlegame' />
                                                    </div>
                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Painting Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {gamesPaintingList}

                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getPaintingImageUrl.bind(this)}
                                                            imageUrl={this.state.Painting_Image}
                                                            imagepreview={this.state.Painting_Image}
                                                            s3DirectryrName='pdfBook/paintinggame' />
                                                    </div>
                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Drag Drop Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {book_drag_Drop}

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

PdfBookDetails.propTypes = {
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    addPdfBookItemWithSentFrom: propTypes.func.isRequired
}

const mapStateToProps = state => ({
    pdfBook: state.pdfBook.item,
    activity: state.pdfBook.item.activityId,
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.item,

});

export default connect
    (mapStateToProps,
        {
            getPdfBookData,
            emptyPdfBook,
            addDragDropParentIDtoRedirectionPage,
            addSeriesIDtoRedirectionPage,
            emptyAllPdfBooks,
            addPdfBookItemWithSentFrom
        })(PdfBookDetails)
