import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Button } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import {
    updateRadio,
    fetchAllRadio,
    getRadioData,
    emptyError
} from '../../Actions/RadioActions';

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';

/*Component */
import PublishDate from '../Layout/PublishDate';
import Loader from '../Layout/Loader';
import UploadAudio from '../S3Uploading/UploadAudio';
import UploadImage from '../S3Uploading/UploadImage';
import UploadVideo from '../S3Uploading/UploadVideo';
/*CSS */
import '../../css/AppCss/Series/AddSeries.css';
import { SIGN_IN, ALL_RADIO, VIEW_RADIO } from '../../routes';


const INITIAL_STATE = {

    sentFrom: '',
    item: '',
    /////////// Radio
    id: '',
    name: '',
    desscription: '',
    name_in_english: '',
    desription_in_english: '',
    publish_date: '',
    status: '',
    error: '',

    imageUrl: "",
    audio: '',
    featured: false,
    promo_with_music: '',
    promo_without_music: '',
    intro_with_music: '',
    intro_without_music: '',

    cancel: false,
    /////////////// validation
    errorMessage: ''

};

class EditRadio extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {


        const { radio } = this.props;

        var item = radio.item;
        this.setState({
            sentFrom: radio.sentFrom,
            item: radio.item
        })


        this.setState({
            id: item.id,
            name: item.name,
            description: item.description,
            imageUrl: item.image,
            status: item.status,
            audio: item.voice,
            publish_date: item.publishDate,
            name_in_english: item.nameInEnglish,
            desription_in_english: item.descriptionInEnglish,
            featured: item.featured
        });

        if (item.introWithMusicId !== null &&
            item.introWithMusicId !== undefined) {
            this.setState({
                intro_with_music: item.introWithMusicId.url
            })
        }

        if (item.introWithoutMusicId !== null &&
            item.introWithoutMusicId !== undefined) {
            this.setState({
                intro_without_music: item.introWithoutMusicId.url
            })
        }


        if (item.promoWithMusicId !== null &&
            item.promoWithMusicId !== undefined) {
            this.setState({
                promo_with_music: item.promoWithMusicId.url
            })
        }


        if (item.promoWithoutMusicId !== null &&
            item.promoWithoutMusicId !== undefined) {
            this.setState({
                promo_without_music: item.promoWithoutMusicId.url
            })
        }

        // if (item.nameInEnglish !== null && item.nameInEnglish !== undefined) {
        //     this.setState({ errorMessage: "" });
        // }else {
        // this.setState({ errorMessage: "Empty Data is not accepted" });
        // }

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }

    }

    updateRadio(id, name, description, status, imageUrl, publishDate, audio,
        promo_with_music, promo_without_music, intro_with_music,
        intro_without_music, name_in_english, description_in_english, featured) {

        this.props.updateRadio(id, name, description, status,
            imageUrl, publishDate, audio,
            promo_with_music, promo_without_music, intro_with_music,
            intro_without_music, name_in_english, description_in_english, featured);
    }

    getPublishDate(publishDateTime) {

        if (publishDateTime) {
            this.setState({ publish_date: publishDateTime });
        }
    }


    getPromoWithMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ promo_with_music: videoUrl });
            console.log("promo_with_music in edit radio = "
                + this.state.promo_with_music);
        } else {
            this.setState({ promo_with_music: '' });
        }
    }

    getPromoWithoutMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ promo_without_music: videoUrl });
            console.log("promo_without_music in edit radio = "
                + this.state.promo_without_music);
        } else {
            this.setState({ promo_without_music: '' });
        }
    }

    getIntroWithoutMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ intro_without_music: videoUrl });
            console.log("intro_without_music in edit radio = "
                + this.state.intro_without_music);
        } else {
            this.setState({ intro_without_music: '' });
        }

    }


    getIntroWithMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ intro_with_music: videoUrl });
            console.log("intro_with_music in edit radio = "
                + this.state.intro_with_music);
        } else {
            this.setState({ intro_with_music: '' });
        }

    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;
        setTimeout(() => {
            if ((this.state.imageUrl === ''
                || this.state.imageUrl === null
                || this.state.imageUrl === undefined)
                || (this.state.name === ''
                    || this.state.name === null
                    || this.state.name === ' ')
                || this.state.desription_in_english.length > 255    
                // || (this.state.name_in_english === ''
                //     || this.state.name_in_english === null
                //     || this.state.name_in_english === ' ')    
                    ) {
                    this.setState({ errorMessage: nameOfState === "desription_in_english" ? 
                        "Description in English is too long (greater than 255 characters)." : 
                        "Empty Data is not accepted" });
            }
            else if ((this.state.imageUrl !== ''
                || this.state.imageUrl !== undefined)
                && (this.state.name !== null
                    || this.state.name !== ''
                    || this.state.name !== ' ')
                && this.state.desription_in_english.length <= 255    
                // && (this.state.name_in_english !== null
                //         || this.state.name_in_english !== ''
                //         || this.state.name_in_english !== ' ')
                        ) {
                this.setState({ errorMessage: "" });
            }
            else {
                this.setState({ errorMessage: "Empty Data is not accepted" });
            }
        }, 0);
    }

    handelCancel = (e) => {
        this.setState({ cancel: true })
    }

    handleSubmit = (e) => {

        const { id, name, description, status, imageUrl, publish_date, audio,
            promo_with_music, promo_without_music, intro_with_music,
            intro_without_music, name_in_english, desription_in_english, featured } = this.state;

        if (e.nativeEvent.submitter.id === "saveButton") {
            this.updateRadio(id, name, description, status,
                imageUrl, publish_date, audio,
                promo_with_music, promo_without_music, intro_with_music,
                intro_without_music, name_in_english, desription_in_english, featured);

        }
        e.preventDefault();

    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };

    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ audio: '' });
                console.log("audio URL in Edit Radio  if = "
                    + this.state.audio);

            }

            // else send the url to DB
            else {
                this.setState({ audio: audioUrl });
                console.log("audio URL in Edit Radio else = "
                    + this.state.audio);

            }

        }
    }


    getImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({
                imageUrl: '',
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
        else if (imageUrl !== undefined || imageUrl !== '') {
            this.setState({ imageUrl: imageUrl });
            if (this.state.name !== ''
                && this.state.name !== ' '
                && this.state.name !== null
                // &&this.state.name_in_english !== ''
                // && this.state.name_in_english !== ' '
                // && this.state.name_in_english !== null
                ) {
                this.setState({ error: false, errorMessage: "" });
            }
        }
        else {
            this.setState({
                imageUrl: '',
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
    }

    render() {
        const { error_update, success_update } = this.props;
        const { sentFrom, item } = this.state;


        if (success_update === false) {
            alert("error missing data or network " + error_update)
            this.props.emptyError();
        }
        else if (success_update === true
            || this.state.cancel === true) {
            if (success_update === true) {
                alert("Radio updated Successfully")
                this.props.emptyError();
            }
            // if the requesst come from All Radio then
            //  fetch all Radio to update the item data

            if (sentFrom === "GetAllRadio") {
                this.props.fetchAllRadio();
                return (<Redirect to={ALL_RADIO}></Redirect>)

            }

            // if the requesst come from ViewRadio then
            //  get Radio data to update the item data
            if (sentFrom === "ViewRadio") {
                this.props.getRadioData(item.id);
                return (<Redirect to={VIEW_RADIO}></Redirect>)

            }

        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <h3>Updating Radio</h3>
                                                <hr />


                                                <div className="">
                                                    <form onSubmit={this.handleSubmit} >
                                                        <div className="row">

                                                            {/* image Uploader */}
                                                            <div className="col-md-4 col-12">
                                                                <span className="required_input">*</span>
                                                                <UploadImage
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.imageUrl}
                                                                    imagepreview={this.state.imageUrl}
                                                                    s3DirectryrName='radio' />


                                                            </div>

                                                            <div className="col-md-8 col-12">
                                                                <p style={{ color: "red" }}>
                                                                    {this.state.errorMessage}
                                                                </p>
                                                                <label htmlFor="name" className="label">
                                                                    <span className="required_input">*</span>
                                                                    Name
                                                                </label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    fluid name="name" id="name"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.name} />
                                                                <br />

                                                                <label htmlFor="name_in_english" className="label">
                                                                    {/* <span className="required_input">*</span> */}
                                                                    Name In English
                                                                </label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    fluid name="name_in_english" id="name_in_english"
                                                                    onChange={this.handleChange}
                                                                    value={this.state.name_in_english} />
                                                                <br />

                                                                <div className="form-group">
                                                                    <label htmlFor="description"
                                                                        className="label">
                                                                        Description
                                                                    </label>

                                                                    <textarea dir="auto"
                                                                        fluid rows="3"
                                                                        className="form-control"
                                                                        name="description" id="description"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.description}
                                                                    />
                                                                </div>
                                                                <br />

                                                                <div className="form-group">
                                                                    <label htmlFor="desription_in_english"
                                                                        className="label">
                                                                        Description In English
                                                                    </label>

                                                                    <textarea dir="auto"
                                                                        fluid rows="3"
                                                                        className="form-control"
                                                                        name="desription_in_english" id="desription_in_english"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.desription_in_english}
                                                                    />
                                                                </div>
                                                                <br />

                                                                <label htmlFor="name" className="label">
                                                                    Audio
                                                                </label>

                                                                <UploadAudio
                                                                    getAudioUrlCallBack={this.getAudio.bind(this)}

                                                                    audioPreview={this.state.audio}
                                                                    audioUrl={this.state.audio}
                                                                    s3DirectryrName="radio/audio" />


                                                                {/* DateComponent */}
                                                                <PublishDate
                                                                    getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                    date={this.state.publish_date} />

                                                                <br />
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={this.state.featured}
                                                                        onChange={this.handleCheckboxChange}
                                                                    />
                                                                    {' Featured'}
                                                                    </label>
                                                                <br />
                                                                <br />
                                                            </div>
                                                        </div>
                                                        <label className="label">
                                                            Intro with music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getIntroWithMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.intro_with_music}
                                                                videoUrl={this.state.intro_with_music}
                                                                s3DirectryrName='radio'
                                                            />
                                                        </div>
                                                        <br />

                                                        <label className="label">
                                                            Intro without music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getIntroWithoutMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.intro_without_music}
                                                                videoUrl={this.state.intro_without_music}
                                                                s3DirectryrName='radio'
                                                            />
                                                        </div>
                                                        <br />

                                                        <label className="label">
                                                            Promo with music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getPromoWithMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.promo_with_music}
                                                                videoUrl={this.state.promo_with_music}
                                                                s3DirectryrName='radio'
                                                            />
                                                        </div>
                                                        <br />

                                                        <label className="label">
                                                            Promo without music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getPromoWithoutMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.promo_without_music}
                                                                videoUrl={this.state.promo_without_music}
                                                                s3DirectryrName='radio'
                                                            />
                                                        </div>
                                                        <br />



                                                        <div className="buttons_container">

                                                            <Button
                                                                disabled={this.state.errorMessage}
                                                                id="saveButton" >
                                                                Save
                                                            </Button>

                                                            <Button id="episode_cancel"
                                                                onClick={this.handelCancel}>
                                                                Cancel
                                                            </Button>

                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

EditRadio.propTypes = {
    getRadioData: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    radio: state.radio.item,
    auth: state.firebase.auth,
    loading: state.radio.loading,
    error_update: state.radio.updating_radio_parent_error,
    success_update: state.radio.updating_radio_parent_sucessfully
});

export default withFirebase(
    connect
        (mapStateToProps,
            {
                updateRadio,
                fetchAllRadio,
                getRadioData,
                emptyError
            })
        (EditRadio)
);
