import {
    LOAD_ISSUES, FETCH_ALL_ISSUES, ERROR_LOADING_ISSUES, EMPTY, GET_ISSUE_DATA,
    ERROR_LOADING_ISSUE, LOAD_ISSUE, UPDATE_ISSUE, ERROR_UPDATING_ISSUE,
    ERROR_DELETING_ISSUE, DELETING_ISSUE, EMPTY_ERROR,
    EMPTY_ALL_ISSUES
} from "../Actions/Types";


const initial_state = {
    item: {},
    items: [],
    loading: '',
    error_loading_issues: '',
    error_loading_issue: "",
    issue_updated_successfully: "",
    error_updating_issue: "",

    delete_issue_successfully: '',
    delete_issue_error: '',

}

export default function (state = initial_state, action) {
    switch (action.type) {
        case LOAD_ISSUES:
            return {
                ...state,
                loading: true
            }
        case LOAD_ISSUE:
            return {
                ...state,
                loading: true
            }
        case UPDATE_ISSUE:
            return {
                ...state,
                item: action.payload,
                issue_updated_successfully: true,
                error_updating_issue: ""

            }
        case ERROR_UPDATING_ISSUE:
            return {
                ...state,
                error_updating_issue: action.payload,
                issue_updated_successfully: false
            }

        case GET_ISSUE_DATA:
            return {
                ...state,
                item: action.payload,
                error_loading_issue: '',
                loading: false,

            }

        case ERROR_LOADING_ISSUE:
            return {
                ...state,
                error_loading_issue: action.payload,
                loading: false
            }

        case FETCH_ALL_ISSUES:
            return {
                ...state,
                loading: false,
                error_loading_issues: '',
                item: {},
                items: action.payload
            }

        case ERROR_LOADING_ISSUES:
            return {
                ...state,
                error_loading_issues: action.payload,
                loading: false
            }

        case DELETING_ISSUE:
            return {

                ...state,
                item: action.payload,
                delete_issue_successfully: true,
                delete_issue_error: false,
                loading: false
            }
        case ERROR_DELETING_ISSUE:
            return {

                ...state,
                delete_issue_error: action.payload,
                delete_issue_successfully: false,
                loading: false
            }

        case EMPTY:
            return {
                ...state,
                error_loading_issues: action.payload,
                error_loading_issue: action.payload,

                delete_issue_successfully: '',
                delete_issue_error: '',

            }

        case EMPTY_ERROR:
            return {
                ...state,

                delete_issue_successfully: '',
                delete_issue_error: '',

            }
        case EMPTY_ALL_ISSUES:
            return {
                ...state,
                items: [],

                error_loading_issues: '',
                error_loading_issue: "",
                issue_updated_successfully: "",
                error_updating_issue: "",

                delete_issue_successfully: '',
                delete_issue_error: '',

            }

        default:
            return state
    }
}