import React, { Component } from 'react';
import { Dropdown, Input, Button } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

/*Firebase*/
import 'firebase/firestore';
import 'firebase/auth';
/**Other Component */
import PublishDate from '../Layout/PublishDate';
import Loader from '../Layout/Loader';
import UploadVideoWithoutDropZone from '../S3Uploading/UploadVideoWithoutDropZone';
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone'
import UploadAudio from '../S3Uploading/UploadAudio';

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withFirebase } from '../../config';
/* Actions*/
import { fetchAllSeries } from '../../Actions/SeriesActions';
import { getEpisodeData } from '../../Actions/EpisodeActions';
import { getSeriesData } from '../../Actions/SeriesActions';
import { updateEpisode, emptyError } from '../../Actions/EpisodeActions';
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';

/*CSS*/
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/AppCss/Episode/EpisodeButtons.css';
import '../../../node_modules/video-react/dist/video-react.css';
import { redirectionPage } from '../../Actions/RedirectionPagesActions';

/* Routes */
import { SIGN_IN } from '../../routes';
import RenderVideoWithMusic from '../VideoPreview/RenderVideoWithMusic';
import RenderVideoWithoutMusic from '../VideoPreview/RenderVideoWithoutMusic';
import { EPISODE_FOLDER_NAME } from '../../portal/src/constants';
import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'


const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]

const INITIAL_STATE = {
    selected_series_id: "",
    selected_series_name: "",
    video_with_music_folder_id: "",
    video_with_out_music_folder_id: "",
    episode_Id: "",
    episode_name: "",
    episode_description: "",
    episode_number: 0,
    episode_name_in_english: "",
    episode_description_in_english: "",

    episode_image_object: "",
    episode_imagePreviewUrl: "",
    episode_imageUrl: "",
    episode_imageTempUrl: "",

    episode_video_with_music_object: "",
    episode_video_with_music_PreviewUrl: "",
    episode_video_with_music_Url: "",
    episode_video_with_music_TempUrl: "",
    episode_video_withMusic_duration: 0,


    episode_video_without_music_object: "",
    episode_video_without_music_PreviewUrl: "",
    episode_video_without_music_Url: "",
    episode_video_without_music_TempUrl: "",
    episode_video_withoutMusic_duration: 0,

    video_key_episode_video_with_music: "",
    video_key_episode_video_without_music: "",


    isFree: '',
    voice: '',
    subValueId: '',

    episode_publishDate: '',

    episode_activityId: '',

    seriesId: '',

    returned_series_id_viewSeriesPage: "",
    returned_series_name_viewSeriesPage: "",

    error: "",
    auth_user: {},

    featured: false,
    contentVendorSelectedId: '',

    /////////// form validation
    validationError: '',
}



export class EditEpisode extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }


    componentDidMount() {
        const { auth } = this.props
        


        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {

            this.props.fetchAllSeries();
            this.props.fetchAllSubValues();
            this.props.fetchAllContentVendors();

            let episodeId = this.props.match.params.episodeId;
            let seriesId = this.props.match.params.seriesId;



            this.setState({
                episode_Id: episodeId,
                selected_series_id: seriesId
            });
            this.props.getEpisodeData(episodeId);
            this.props.getSeriesData(seriesId);

            const { episodes } = this.props;
            const { series } = this.props
            const { selectedDiscoverUsSeries } = this.props


if (series && series !== null && series !== undefined &&  Object.keys(series).length > 0){
    if (!series.withMusicFolderId || series.withMusicFolderId === null || series.withMusicFolderId === "" ||
    !series.withoutMusicFolderId || series.withoutMusicFolderId === null || series.withoutMusicFolderId === "") {
    this.setState({
        video_with_music_folder_id: "",
        video_with_out_music_folder_id: "",
    })
}
else {
    this.setState({
        video_with_music_folder_id: series.withMusicFolderId,
        video_with_out_music_folder_id: series.withoutMusicFolderId,
    })
}
}
          
if (selectedDiscoverUsSeries && selectedDiscoverUsSeries !== null && selectedDiscoverUsSeries !== undefined &&  Object.keys(selectedDiscoverUsSeries).length > 0){
    if (!selectedDiscoverUsSeries.withMusicFolderId || selectedDiscoverUsSeries.withMusicFolderId === null || selectedDiscoverUsSeries.withMusicFolderId === "" ||
    !selectedDiscoverUsSeries.withoutMusicFolderId || selectedDiscoverUsSeries.withoutMusicFolderId === null || selectedDiscoverUsSeries.withoutMusicFolderId === "") {
    this.setState({
        video_with_music_folder_id: "",
        video_with_out_music_folder_id: "",
    })
}
else {
    this.setState({
        video_with_music_folder_id: selectedDiscoverUsSeries.withMusicFolderId,
        video_with_out_music_folder_id: selectedDiscoverUsSeries.withoutMusicFolderId,
    })
}
}
           

            if (episodes.videoWithMusicId !== undefined &&
                episodes.videoWithoutMusicId !== undefined) {



                let paymentAvailability = ''
                if (episodes.isFree == 0) {
                    paymentAvailability = 'paid'
                }
                else if (episodes.isFree == 1) {
                    paymentAvailability = 'free'
                }




                let audio = episodes.voice;

                if (audio === 'undefined') {
                    audio = null
                }

                let subvalue = ''
                if (episodes.contentSubValue === null ||
                    episodes.contentSubValue === undefined) {
                    subvalue = ''
                } else {
                    subvalue = episodes.contentSubValue.id
                }

                let videoMusicUrl = null,
                    videoWithoutMusicUrl = null,
                    videoMusicDuration = 0,
                    videoWithoutMusicDuration = 0,
                    videoKeyWithMusic = null,
                    videoKeyWithoutMusic = null;

                if (episodes.videoWithMusicId !== undefined
                    && episodes.videoWithMusicId !== null) {

                    if (episodes.videoWithMusicId.url !== undefined
                        && episodes.videoWithMusicId.url !== null) {
                        videoMusicUrl = episodes.videoWithMusicId.url;
                    }

                    if (episodes.videoWithMusicId.duration !== null
                        && episodes.videoWithMusicId.duration !== undefined) {
                        videoMusicDuration = episodes.videoWithMusicId.duration
                    }


                    if (episodes.videoWithMusicId.vdocipherId !== null
                        && episodes.videoWithMusicId.vdocipherId !== undefined) {
                        videoKeyWithMusic = episodes.videoWithMusicId.vdocipherId
                    }

                }

                if (episodes.videoWithoutMusicId !== undefined
                    && episodes.videoWithoutMusicId !== null) {
                    if (episodes.videoWithoutMusicId.url !== undefined
                        && episodes.videoWithoutMusicId.url !== null) {
                        videoWithoutMusicUrl = episodes.videoWithoutMusicId.url
                    }

                    if (episodes.videoWithoutMusicId.duration !== undefined
                        && episodes.videoWithoutMusicId.duration !== null) {
                        videoWithoutMusicDuration = episodes.videoWithoutMusicId.duration
                    }
                    if (episodes.videoWithoutMusicId.vdocipherId !== null
                        && episodes.videoWithoutMusicId.vdocipherId !== undefined) {
                        videoKeyWithoutMusic = episodes.videoWithoutMusicId.vdocipherId
                    }
                }

                this.setState({
                    episode_name: episodes.name,
                    episode_imageUrl: episodes.image,
                    episode_description: episodes.description,
                    episode_number: episodes.episodeNumber,
                    episode_name_in_english: episodes.nameInEnglish,
                    episode_description_in_english: episodes.descriptionInEnglish,

                    episode_video_with_music_Url: videoMusicUrl,
                    episode_video_withMusic_duration: videoMusicDuration,

                    episode_video_without_music_Url: videoWithoutMusicUrl,
                    episode_video_withoutMusic_duration: videoWithoutMusicDuration,


                    video_key_episode_video_with_music: videoKeyWithMusic,
                    video_key_episode_video_without_music: videoKeyWithoutMusic,

                    episode_publishDate: episodes.publishDate,
                    episode_activityId: episodes.activityId.id,
                    isFree: paymentAvailability,
                    voice: audio,
                    subValueId: subvalue,
                    featured: episodes.featured,
                    contentVendorSelectedId: episodes.contentVendor?episodes.contentVendor.id:''
                    
                });

            }

            // if (episodes.nameInEnglish !== null && episodes.nameInEnglish !== undefined ) {
            //     this.setState({ validationError: "" });
            // }else {
            // this.setState({ validationError: "Empty Data is not accepted" });
            // }

        }



    }

    getImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ episode_imageUrl: imageUrl })

        }
    }

    getVideoWithMusicUrl(videoUrl, duration) {
        if (videoUrl) {
            this.setState({
                episode_video_with_music_Url: videoUrl,
                episode_video_withMusic_duration: duration
            })
        }
    }
    getVideoWithoutMusicUrl(videoUrl, duration) {
        if (videoUrl) {
            this.setState({
                episode_video_without_music_Url: videoUrl,
                episode_video_withoutMusic_duration: duration
            })
        }
    }
    getPublishDate(publishDateTime) {

        if (publishDateTime) {
            this.setState({ episode_publishDate: publishDateTime });

        }
    }


    updateEpisode(episode_Id, episode_name,
        episode_description, episode_number,
        episode_publishDate, episode_imageUrl,
        episode_activityId, selected_series_id,
        episode_video_with_music_Url,
        episode_video_withMusic_duration,
        episode_video_without_music_Url,
        episode_video_withoutMusic_duration, isFree,
        voice, subValueId,
        episode_name_in_english, episode_description_in_english,
        video_key_episode_video_with_music,
        video_key_episode_video_without_music, featured,contentVendorSelectedId
    ) {

        this.props.updateEpisode(episode_Id,
            episode_name, episode_description,
            episode_number,
            episode_publishDate, episode_imageUrl,
            episode_activityId, selected_series_id,
            episode_video_with_music_Url,
            episode_video_withMusic_duration,
            episode_video_without_music_Url,
            episode_video_withoutMusic_duration,
            isFree, voice, subValueId, episode_name_in_english, episode_description_in_english,
            video_key_episode_video_with_music,
            video_key_episode_video_without_music, featured,contentVendorSelectedId)

    }

    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ voice: '' });



            }

            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });



            }

        }
    }

    handleDesChange = (e) => {
        this.setState({ validationError: '' });
        this.setState({ [e.target.name]: e.target.value });
        setTimeout(() => {
            if (this.state.episode_description_in_english.length > 255) {
                this.setState({ validationError: "Description in English is too long (greater than 255 characters)." });
            }
        }, 0);

    }
    handleChangeVideoWithMusic = (uploadInfo) => {

        if (
            uploadInfo &&
            uploadInfo !== undefined &&
            uploadInfo !== null &&
            Object.keys(uploadInfo).length > 0
        ) {
            this.setState({ video_key_episode_video_with_music: uploadInfo.videoId });
        }
        else {
            this.setState({ video_key_episode_video_with_music:this.state.video_key_episode_video_with_music });
        }
    };
    handleChangeVideoWithoutMusic = (uploadInfo) => {

        if (
            uploadInfo &&
            uploadInfo !== undefined &&
            uploadInfo !== null &&
            Object.keys(uploadInfo).length > 0
        ) {
            this.setState({ video_key_episode_video_without_music: uploadInfo.videoId });
        }

        else {
            this.setState({ video_key_episode_video_without_music:this.state.video_key_episode_video_without_music });
        }
    };
    handleChange = (e) => {

        this.setState({ validationError: '' });
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: checkSpaces });

                setTimeout(() => {
                    if (specialCharacters.test(this.state.episode_name) || specialCharacters.test(this.state.episode_name_in_english)){
                        this.setState({ validationError: "Episode name can't contain spaecial characters" });
                      }
                    else if (checkSpaces === '' || checkSpaces === null || checkSpaces === ' ') {
                        this.setState({ validationError: "Empty Data is not accepted" });
                    }

                }, 0);
        }
    }

    handlePaymentAva = (e, { value }) => {



        this.setState({
            isFree: value
        })

    }

    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
    };

    handleSubmit = (e) => {

        const { episode_Id, episode_name,
            episode_description, episode_number,
            episode_publishDate,
            episode_imageUrl,
            episode_activityId, selected_series_id,
            episode_video_with_music_Url,
            episode_video_withMusic_duration,
            episode_video_without_music_Url,
            episode_video_withoutMusic_duration, isFree,
            voice, subValueId, episode_name_in_english, episode_description_in_english,
            video_key_episode_video_with_music,
            video_key_episode_video_without_music, featured,contentVendorSelectedId } = this.state;

        if (e.nativeEvent.submitter.id === "nextButton") {
            this.updateEpisode(episode_Id, episode_name,
                episode_description, episode_number,
                episode_publishDate,
                episode_imageUrl,
                episode_activityId, selected_series_id,
                episode_video_with_music_Url,
                episode_video_withMusic_duration,
                episode_video_without_music_Url,
                episode_video_withoutMusic_duration, isFree,
                voice, subValueId, episode_name_in_english, episode_description_in_english,
                video_key_episode_video_with_music,
                video_key_episode_video_without_music, featured,contentVendorSelectedId);
        }

        e.preventDefault();
    }


    handleContentVendorSelection = (e, { value }) => {
        //console.log("contentVendor selected: " + value);
        this.setState({ contentVendorSelectedId: value })
    }
    
    render() {
        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }


// --------------------------------
    const { ContentVendorsList } = this.props;
    const ContentVendorsArr = [];
    
    //console.log('ContentVendorsList : '+JSON.stringify(ContentVendorsList,null,2));
    
    for (let i = 0; i < ContentVendorsList.length; i++) 
    {
      ContentVendorsArr.push({
        key: ContentVendorsList[i].id,
        text: ContentVendorsList[i].user.name,
        value: ContentVendorsList[i].id,
      });
    }
// --------------------------------

        const { episoderror, episodsucess } = this.props;
        const { loading } = this.props;

        let $buttonSubmitLoader = (
            <Button disabled={this.state.validationError} type="submit" id="nextButton" >
                Save
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">Loading</button>);

        }

        if (episodsucess === false) {
            alert(episoderror)
            this.props.emptyError();

        }

        else if (episodsucess === true) {
            alert("Episode updated Successfully")
            this.props.emptyError();

            const { redirectionPage } = this.props;



            var pageName = redirectionPage.pageName
            return (<Redirect to={pageName} ></Redirect>)
        }


        const { episode_name,
            episode_number,
            episode_publishDate,
            episode_imageUrl,
            episode_video_with_music_Url,
            episode_video_withMusic_duration,
            episode_video_without_music_Url,
            episode_video_withoutMusic_duration,
            episode_name_in_english, featured,contentVendorSelectedId } = this.state;

        const isInvalid =
            episode_name === '' || episode_name == undefined ||
            episode_publishDate === '' || episode_publishDate == undefined ||
            episode_imageUrl === '' || episode_imageUrl == undefined ||
            episode_video_with_music_Url === '' || episode_video_with_music_Url == undefined ||
            episode_video_without_music_Url === '' || episode_video_without_music_Url == undefined ||
            episode_video_withMusic_duration === 0 || episode_video_withMusic_duration == undefined ||
            episode_video_withMusic_duration === '' ||
            episode_video_withoutMusic_duration === 0 || episode_video_withoutMusic_duration == undefined ||
            episode_video_withoutMusic_duration === '' ||
            episode_number === '' || episode_number == undefined;


        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="container">
                                                    <h2 className="label">Edit Episode</h2>
                                                    <br />

                                                    <form onSubmit={this.handleSubmit}>
                                                        <p style={{ color: "red" }}>{this.state.validationError} </p>
                                                        <label htmlFor="episode_name" className="label">
                                                            <span className="required_input">*</span>
                                                            Episode Name</label><br />
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="episode_name"
                                                            id="episode_name"
                                                            onChange={this.handleChange}
                                                            value={this.state.episode_name} />
                                                        <br />

                                                        <label htmlFor="episode_name_in_english" className="label">
                                                            {/* <span className="required_input">*</span> */}
                                                            Episode Name In English</label><br />
                                                        <input
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid name="episode_name_in_english"
                                                            id="episode_name_in_english"
                                                            onChange={this.handleDesChange}
                                                            value={this.state.episode_name_in_english} />
                                                        <br />

                                                        <label htmlFor="episode_number" className="label">
                                                            <span className="required_input">*</span>
                                                            Episode Number</label><br />
                                                        <Input fluid type="number" min="0"
                                                            name="episode_number" id="episode_number"
                                                            onChange={this.handleChange}
                                                            value={this.state.episode_number} />
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select payment availability</label><br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select Payment Availability'
                                                            id="episode_series_id"
                                                            onChange={this.handlePaymentAva}
                                                            options={is_free_type}
                                                            value={this.state.isFree}
                                                        />
                                                        <br />



                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            Sub Value</label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Sub Value'
                                                            id="episode_series_id"
                                                            onChange={this.handleSubValueChange}
                                                            options={subValuesList}
                                                            value={this.state.subValueId}
                                                        />
                                                        <br />

                                                        {/* DataComponent */}
                                                        <PublishDate
                                                            getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                            date={this.state.episode_publishDate}
                                                            showNow={!isInvalid} />

                                                        <br />

                                                        <label htmlFor="episode_description" className="label">Description</label><br />
                                                        <textarea
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid rows="6" name="episode_description"
                                                            id="episode_description"
                                                            onChange={this.handleDesChange}
                                                            value={this.state.episode_description} />
                                                        <br />


                                                        <label htmlFor="episode_description" className="label">Description In English</label><br />
                                                        <textarea
                                                            dir="auto"
                                                            className="form-control"
                                                            fluid rows="6" name="episode_description_in_english"
                                                            id="episode_description_in_english"
                                                            onChange={this.handleDesChange}
                                                            value={this.state.episode_description_in_english} />
                                                        <br />

                                                        <label htmlFor="name" className="label">
                                                            Audio
                                                        </label>

                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getAudio.bind(this)}

                                                            audioPreview={this.state.voice}
                                                            audioUrl={this.state.voice}
                                                            s3DirectryrName="episode/audio" />
                                                        <br />
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={this.state.featured}
                                                                onChange={this.handleCheckboxChange}
                                                            />
                                                            {' Featured'}
                                                        </label>
                                                        <br />
                                                        <label htmlFor="episode_image_object" className="label">Episode Image</label><br />
                                                        <div className="row">

                                                            <div className=" col-4">
                                                                <UploadImageWithoutDropZone
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.episode_imageUrl}
                                                                    imagepreview={this.state.episode_imageUrl}
                                                                    s3DirectryrName='episode' />

                                                            </div>
                                                        </div>
                                                        <br />

                                                        <label className="label">Video with music</label>
                                                        <div>

                                                            <RenderVideoWithMusic
                                                                videoKey={this.state.video_key_episode_video_with_music}
                                                                id="VideoWithMusic"
                                                                foldername={this.state.video_with_music_folder_id}
                                                                changedid={this.handleChangeVideoWithMusic}
                                                                enableEdit={true}
                                                            ></RenderVideoWithMusic>
                                                        </div>
                                                        <br />
                                                        <label className="label">Video without music</label>
                                                        <div>

                                                            <RenderVideoWithoutMusic
                                                                videoKey={this.state.video_key_episode_video_without_music}
                                                                id="VideoWithoutMusic"
                                                                foldername={this.state.video_with_out_music_folder_id}
                                                                changedid={this.handleChangeVideoWithoutMusic}
                                                                enableEdit={true}
                                                            ></RenderVideoWithoutMusic>
                                                        </div>
                                                        <br />


<label
                                htmlFor="episode_ContentVendor_id"
                                className="label"
                              >
                                
                                Select Content Vendor
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Select Content Vendor"
                                id="episode_ContentVendor_id"
                                onChange={this.handleContentVendorSelection}
                                options={ContentVendorsArr}
                                value={this.state.contentVendorSelectedId}
                              />
                              <br />
                              
                                                        {/* 
                                                        <label htmlFor="episode_video_with_music_object" className="label">Episode Video With Music</label><br />
                                                        <div className="imgPreview">
                                                            <UploadVideoWithoutDropZone
                                                                getVideoURLCallBack={this.getVideoWithMusicUrl.bind(this)}
                                                                videoUrl={this.state.episode_video_with_music_Url}
                                                                videoPreview={this.state.episode_video_with_music_Url}
                                                                s3DirectryrName='episode' />

                                                        </div>
                                                        <br />
                                                        <label htmlFor="episode_video_withMusic_duration"
                                                            className="label">
                                                            Video with music duration in seconds
                                                        </label><br />
                                                        <Input disabled fluid
                                                            type="number" min="0" step="0.01"
                                                            name="episode_video_withMusic_duration"
                                                            id="episode_video_withMusic_duration"
                                                            onChange={this.handleChange}
                                                            value={this.state.episode_video_withMusic_duration} />
                                                        <br /> <br /><br />

                                                        <label htmlFor="episode_video_without_music_object" className="label">Episode Video Without Music</label><br />
                                                        <div className="imgPreview">
                                                            <UploadVideoWithoutDropZone
                                                                getVideoURLCallBack={this.getVideoWithoutMusicUrl.bind(this)}
                                                                videoPreview={this.state.episode_video_without_music_Url}
                                                                videoUrl={this.state.episode_video_without_music_Url}
                                                                s3DirectryrName='episode' />
                                                        </div>
                                                        <br />
                                                        <label htmlFor="episode_video_withoutMusic_duration" className="label">Video With Out Music Duration in seconds</label><br />
                                                        <Input disabled type="number" min="0" step="0.01"
                                                            fluid name="episode_video_withoutMusic_duration"
                                                            id="episode_video_withoutMusic_duration"
                                                            onChange={this.handleChange}
                                                            value={this.state.episode_video_withoutMusic_duration} />

                                                        <br /> <br /><br /> */}

                                                        {$buttonSubmitLoader}

                                                        <br /> <br /> <br />
                                                    </form>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

EditEpisode.propTypes = {
    fetchAllSeries: propTypes.func.isRequired,
    series: propTypes.func.isRequired,
    getEpisodeData: propTypes.func.isRequired,
    getSeriesData: propTypes.func.isRequired,
    updateEpisode: propTypes.func.isRequired,
    fetchAllSubValues: propTypes.func.isRequired


}
const mapStateToProps = state => ({
    loading: state.episodes.loading,

    episodes: state.episodes.item,
    series: state.series.item,
    selectedDiscoverUsSeries: state.discoverUsSeries.item,
    episoderror: state.episodes.error_updating_episode,
    episodsucess: state.episodes.episode_updated_sucessfully,
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.item,
    allSubValues: state.ContentSubValue.items,
    ContentVendorsList : state.contentVendor.State_items,



});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                fetchAllSeries,
                getEpisodeData,
                getSeriesData,
                updateEpisode,
                emptyError,
                fetchAllSubValues,
                fetchAllContentVendors,
            })(EditEpisode));
