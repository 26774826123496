import React, { Component } from 'react'
import { withFirebase } from '../../config';
import {  Button, Modal, Icon, Popup } from 'semantic-ui-react';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import {
    SendNotificationToSingle,
    SendNotificationToAllCustomers, emptyCustomer
} from '../../Actions/CustomerActions';



const INITIAL_STATE = {

    showModal: '',
    /////////// email
    Notification_Text: '',
    //// validation
    validationError: true,
    validationMessage: ''

};

class SendCustomerNotification extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    SendNotificationToSingle(selectedCustomerUIds, Notification_Text) {
        this.props.SendNotificationToSingle(selectedCustomerUIds, Notification_Text);
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        switch (e.target.name) {
            default:
                setTimeout(() => {
                    if (this.state.Notification_Text != null && this.state.Notification_Text != ' ' && this.state.Notification_Text != '') {
                        this.setState({ validationError: false, validationMessage: '' });
                    }
                    else {
                        this.setState({ validationError: true, validationMessage: "Empty Data are not accepted" });
                    }
                }, 0);
        }
    }


    handleSubmit = (e) => {

        /////////mapping the value of customers uid to array
        const { checkedItems } = this.props;

        // send to all customers if no one selected
        if (checkedItems.size == 0) {
            console.log("Please Select customer to Send Email");

            const { Notification_Text } = this.state;

            this.props.SendNotificationToAllCustomers(Notification_Text);

            e.preventDefault();
            this.setState({
                Notification_Text: '',
                validationError: true
            })

        }
        else {

            var selectedCustomerUIds = [];
            var get_keys = checkedItems.keys();

            for (var ele of get_keys) {
                selectedCustomerUIds.push(ele);
            }

            for (let i = 0; i < selectedCustomerUIds.length; i++) {
                console.log(selectedCustomerUIds[i]);
            }

            const { Notification_Text } = this.state;

            this.SendNotificationToSingle(selectedCustomerUIds, Notification_Text);

            e.preventDefault();
            this.setState({
                Notification_Text: ''
            })
        }
    }

    closeModal = (e) => {
        this.setState({ showModal: false, validationError: true, validationMessage: '' })
    }

    openModal = (e) => {
        this.setState({ showModal: true, Notification_Text: '' })
    }


    render() {
        const { Notification_Text } = this.state;

        const { error } = this.props;
        const { success, successAllCustomers } = this.props;

        if (success === false || successAllCustomers === false) {
            this.props.emptyCustomer();

        }
        else if (success === true || successAllCustomers === true) {
            alert("Notification Sent Successfully")
            this.props.emptyCustomer();

            this.closeModal();
        }

        return (
            <Modal id="modal"
                trigger={
                    <Popup trigger={
                        <button type="submit"
                            className="customer_icons" onClick={this.openModal} >
                            <Icon name="bell" />
                        </button>}
                        content='Send Notification'
                        position='top center'
                    />
                }
                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Send Notification </Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">


                                    <div className="col">

                                        <label htmlFor="Notification_Text"
                                            className="label">
                                            Notification Text
                                        </label>

                                        <textarea fluid rows="6"
                                            dir="auto"
                                            className="form-control"
                                            name="Notification_Text"
                                            id="episode_description"
                                            onChange={this.handleChange}
                                            value={this.state.Notification_Text} />
                                        <br />

                                    </div>

                                </div>

                                <div className="input-field">

                                    <Button disabled={this.state.validationError} onClick={this.handleSubmit} id="saveButton" >
                                        Send
                                    </Button>


                                </div>
                                {this.state.validationError && <p style={{ color: "red" }}>{this.state.validationMessage}</p>}
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}


SendCustomerNotification.propTypes = {
    SendNotificationToSingle: propTypes.func.isRequired,
    SendNotificationToAllCustomers: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    showModal: state.customers.notificationShowModal,
    error: state.customers.send_notification_error,
    success: state.customers.send_notification_sucessfully,

    successAllCustomers: state.customers.send_notification_to_AllCustomers_sucessfully,

});
export default withFirebase
    (connect
        (mapStateToProps,
            {
                SendNotificationToSingle,
                emptyCustomer, SendNotificationToAllCustomers
            }
        )
        (SendCustomerNotification)
    );
