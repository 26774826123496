import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Button } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import {
    updateSongParent,
    fetchAllSongParent,
    getSongParentData,
    emptyError
} from '../../Actions/SongParentAction';

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';

/*Component */
import PublishDate from '../Layout/PublishDate';
import Loader from '../Layout/Loader';
import UploadAudio from '../S3Uploading/UploadAudio';
import UploadImage from '../S3Uploading/UploadImage';
import UploadVideo from '../S3Uploading/UploadVideo';

/*CSS */
import '../../css/AppCss/Series/AddSeries.css';
import { ALL_SONG_PARENTS, SIGN_IN, VIEW_SONG_PARENT } from '../../routes';
import RenderPromoWithMusic from '../VideoPreview/RenderPromoWithMusic';
import RenderPromoWithoutMusic from '../VideoPreview/RenderPromoWithoutMusic';
import { SONG_FOLDER_NAME_WITH_MUSIC, SONG_FOLDER_NAME_WITH_OUT_MUSIC } from '../../portal/src/constants';


const INITIAL_STATE = {

    sentFrom: '',
    item: '',
    /////////// Song Parent

    id: '',
    name: '',
    desscription: '',
    publish_date: '',
    status: '',
    error: '',

    imageUrl: "",
    audio: '',

    promo_with_music: '',
    promo_without_music: '',

    video_key_promo_with_music: "",
    video_key_promo_without_music: "",

    nameInEnglish: '',
    descriptionInEnglish: '',
    featured: false,
    cancel: false,
    /////////////// validation
    errorMessage: '',

};

class EditSongParent extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            const { songParent } = this.props;
            var item = songParent.item;

            this.setState({
                sentFrom: songParent.sentFrom,
                item: songParent.item
            })
            let audio = null;
            if (item.voice === 'undefined') {
                audio = null
            }

            this.setState({
                id: item.id,
                name: item.name,
                description: item.description,
                imageUrl: item.image,
                status: item.status,
                audio: audio,
                publish_date: item.publishDate,
                nameInEnglish: item.nameInEnglish,
                descriptionInEnglish: item.descriptionInEnglish,
                featured: item.featured
            });

            if (item.promoWithMusicId !== null &&
                item.promoWithMusicId !== undefined) {
                this.setState({
                    promo_with_music: item.promoWithMusicId.url,
                    video_key_promo_with_music: item.promoWithMusicId.vdocipherId,
                })
            }


            if (item.promoWithoutMusicId !== null &&
                item.promoWithoutMusicId !== undefined) {
                this.setState({
                    promo_without_music: item.promoWithoutMusicId.url,
                    video_key_promo_without_music: item.promoWithoutMusicId.vdocipherId,
                })
            }

            // if (item.nameInEnglish !== null && item.nameInEnglish !== undefined ) {
            //     this.setState({ errorMessage: "" });
            // }else {
            // this.setState({ errorMessage: "Empty Data is not accepted" });
            // }
        }
    }


    updateSongParent(id, name, description, status, imageUrl, publishDate, audio,
        promo_with_music, promo_without_music, nameInEnglish, descriptionInEnglish,
        video_key_promo_with_music,
        video_key_promo_without_music, featured) {
        this.props.updateSongParent(id, name, description,
            status, imageUrl, publishDate, audio,
            promo_with_music, promo_without_music, nameInEnglish, descriptionInEnglish,
            video_key_promo_with_music,
            video_key_promo_without_music, featured);
    }

    getPublishDate(publishDateTime) {

        if (publishDateTime) {
            this.setState({ publish_date: publishDateTime });
        }
    }

    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ audio: '' });



            }

            // else send the url to DB
            else {
                this.setState({ audio: audioUrl });



            }

        }
    }


    getImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({ imageUrl: '', error: true, errorMessage: "Empty Data is not accepted" });
        }
        else if (imageUrl !== undefined || imageUrl !== '') {
            this.setState({ imageUrl: imageUrl });
            if (this.state.name !== ''
                && this.state.name !== ' '
                && this.state.name !== null) {
                this.setState({ error: false, errorMessage: "" });
            }

        }
        else {
            this.setState({ imageUrl: '', error: true, errorMessage: "Empty Data is not accepted" });
        }
    }

    getPromoWithMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ promo_with_music: videoUrl });

        } else {
            this.setState({ promo_with_music: '' });
        }
    }

    getPromoWithoutMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ promo_without_music: videoUrl });

        } else {
            this.setState({ promo_without_music: '' });
        }
    }

    handleChangePromoWithMusic = (uploadInfo) => {

        if (
            uploadInfo &&
            uploadInfo !== undefined &&
            uploadInfo !== null &&
            Object.keys(uploadInfo).length > 0
        ) {
            this.setState({ video_key_promo_with_music: uploadInfo.videoId });
        }

        else {
            this.setState({ video_key_promo_with_music: this.state.video_key_promo_with_music });
        }
    };
    handleChangePromoWithoutMusic = (uploadInfo) => {

        if (
            uploadInfo &&
            uploadInfo !== undefined &&
            uploadInfo !== null &&
            Object.keys(uploadInfo).length > 0
        ) {
            this.setState({ video_key_promo_without_music: uploadInfo.videoId });
        }

        else {
            this.setState({ video_key_promo_without_music: this.state.video_key_promo_without_music });
        }
    };

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;
        setTimeout(() => {
            if (specialCharacters.test(this.state.name) || specialCharacters.test(this.state.nameInEnglish)){
                this.setState({
                  error: true,
                  errorMessage: "Song name can't contain spaecial characters",
                });
              }
            else if ((this.state.imageUrl === ''
                || this.state.imageUrl === null
                || this.state.imageUrl === undefined)
                || (this.state.name === ''
                    || this.state.name === null
                    || this.state.name === ' ')
                || this.state.descriptionInEnglish.length > 255) {
                this.setState({
                    error: true, errorMessage: nameOfState === "descriptionInEnglish" ?
                        "Description in English is too long (greater than 255 characters)." :
                        "Empty Data is not accepted"
                });
            }
            else if ((this.state.imageUrl !== ''
                || this.state.imageUrl !== undefined)
                && (this.state.name !== null
                    || this.state.name !== ''
                    || this.state.name !== ' ')
                && this.state.descriptionInEnglish.length <= 255) {
                this.setState({ errorMessage: "" });
            }
            else {
                this.setState({ errorMessage: "Empty Data is not accepted" });
            }
        }, 0);
    }

    handelCancel = (e) => {
        this.setState({ cancel: true })
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
    };

    handleSubmit = (e) => {

        const { id,
            name,
            description,
            status,
            imageUrl,
            publish_date,
            audio,
            promo_with_music, promo_without_music,
            nameInEnglish,
            descriptionInEnglish, video_key_promo_with_music,
            video_key_promo_without_music, featured

        } = this.state;

        if (e.nativeEvent.submitter.id === "saveButton") {

            if (imageUrl == null || imageUrl == undefined) {
                this.setState({ errorMessage: "Empty Image is not accepted" });

            }
            else {
                this.updateSongParent(id, name,
                    description, status, imageUrl,
                    publish_date, audio,
                    promo_with_music, promo_without_music, nameInEnglish, descriptionInEnglish,
                    video_key_promo_with_music,
                    video_key_promo_without_music, featured);
            }
        }
        e.preventDefault();

    }

    render() {
        const { error_update,
            success_update } = this.props;

        const { sentFrom, item } = this.state;

        if (success_update === false) {
            alert(error_update)
            this.props.emptyError();
        }
        else if (success_update === true
            || this.state.cancel === true) {

            if (success_update === true) {
                alert("Song Parent updated Successfully")
                this.props.emptyError();
            }

            // if the requesst come from All songParent then
            //  fetch all SongsParent to update the new data

            if (sentFrom === "AllSongParents") {
                this.props.fetchAllSongParent();
                return (<Redirect to={ALL_SONG_PARENTS}></Redirect>)

            }

            // if the requesst come from ViewSongParent then
            //  get Song Parent data to update the suspended Song
            if (sentFrom === "ViewSongParent") {
                this.props.getSongParentData(item.id);
                return (<Redirect to={VIEW_SONG_PARENT}></Redirect>)

            }

        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <h3>updating Song Parent</h3>
                                                <hr />


                                                <div className="">
                                                    <form onSubmit={this.handleSubmit} >
                                                        <div className="row">

                                                            {/* image Uploader */}
                                                            <div className="col-md-4 col-12">
                                                                <span className="required_input">*</span>
                                                                <UploadImage
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.imageUrl}
                                                                    imagepreview={this.state.imageUrl}
                                                                    s3DirectryrName='song' />


                                                            </div>

                                                            <div className="col-md-8 col-12">
                                                                <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                                                                <label htmlFor="name" className="label">
                                                                    <span className="required_input">*</span>
                                                                    Name</label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    fluid name="name" id="name" onChange={this.handleChange}
                                                                    value={this.state.name} />
                                                                <br />

                                                                <label htmlFor="nameInEnglish" className="label">
                                                                    {/* <span className="required_input">*</span> */}
                                                                    Name In English</label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    fluid name="nameInEnglish" id="nameInEnglish" onChange={this.handleChange}
                                                                    value={this.state.nameInEnglish} />
                                                                <br />

                                                                <div className="form-group">
                                                                    <label htmlFor="description" className="label">
                                                                        Description
                                                                    </label>

                                                                    <textarea dir="auto"
                                                                        fluid rows="3"
                                                                        className="form-control"
                                                                        name="description" id="description"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.description}
                                                                    />
                                                                </div>
                                                                <br />

                                                                <div className="form-group">
                                                                    <label htmlFor="descriptionInEnglish" className="label">
                                                                        Description In English
                                                                    </label>

                                                                    <textarea dir="auto"
                                                                        fluid rows="3"
                                                                        className="form-control"
                                                                        name="descriptionInEnglish" id="descriptionInEnglish"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.descriptionInEnglish}
                                                                    />
                                                                </div>
                                                                <br />

                                                                <UploadAudio
                                                                    getAudioUrlCallBack={this.getAudio.bind(this)}

                                                                    audioPreview={this.state.audio}
                                                                    audioUrl={this.state.audio}
                                                                    s3DirectryrName="song/audio" />


                                                                {/* DateComponent */}
                                                                {this.state.publish_date !== " " &&
                                                                    this.state.publish_date !== '' &&
                                                                    this.state.publish_date !== null &&
                                                                    this.state.publish_date !== undefined ?
                                                                    <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                        date={this.state.publish_date} />

                                                                    :
                                                                    <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                        value={this.state.publish_date}
                                                                    />}

                                                                <br />
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={this.state.featured}
                                                                        onChange={this.handleCheckboxChange}
                                                                    />
                                                                    {' Featured'}
                                                                </label>

                                                                <br />
                                                            </div>

                                                        </div>

                    
                                                            <div>
                                                                <label className="label">Promo with music</label>
                                                                <div>
                                                                    <RenderPromoWithMusic
                                                                        videoKey={this.state.video_key_promo_with_music}
                                                                        id="PromoWithMusic"
                                                                        foldername={SONG_FOLDER_NAME_WITH_MUSIC}
                                                                        changedid={this.handleChangePromoWithMusic}
                                                                    ></RenderPromoWithMusic>
                                                                </div>
                                                                <br />
                                                            </div>
                                                      

                                                            <div>
                                                                <label className="label">Promo without music</label>
                                                                <div>
                                                                    <RenderPromoWithoutMusic
                                                                        videoKey={this.state.video_key_promo_without_music}
                                                                        id="PromoWithoutMusic"
                                                                        foldername={SONG_FOLDER_NAME_WITH_OUT_MUSIC}
                                                                        changedid={this.handleChangePromoWithoutMusic}
                                                                    ></RenderPromoWithoutMusic>
                                                                </div>
                                                                <br />
                                                            </div>
                                                       





                                                        {/* <label className="label">
                                                            Promo with music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getPromoWithMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.promo_with_music}
                                                                videoUrl={this.state.promo_with_music}
                                                                s3DirectryrName='song'
                                                            />
                                                        </div>
                                                        <br />

                                                        <label className="label">
                                                            Promo without music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getPromoWithoutMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.promo_without_music}
                                                                videoUrl={this.state.promo_without_music}
                                                                s3DirectryrName='song'
                                                            />
                                                        </div>

                                                        <br /> */}
                                                        <div className="buttons_container">

                                                            <Button disabled={this.state.errorMessage} id="saveButton" >
                                                                Save
                                                            </Button>

                                                            <Button id="episode_cancel"
                                                                onClick={this.handelCancel}>
                                                                Cancel
                                                            </Button>

                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}

EditSongParent.propTypes = {
    getSongParentData: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    songParent: state.songParent.item,
    auth: state.firebase.auth,
    loading: state.songParent.loading,
    error_update: state.songParent.updating_song_parent_error,
    success_update: state.songParent.updating_song_parent_sucessfully
});

export default withFirebase(
    connect
        (mapStateToProps,
            {
                updateSongParent,
                fetchAllSongParent,
                getSongParentData,
                emptyError
            })
        (EditSongParent)
);

