import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Redirect, Route } from 'react-router-dom'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
/* Components */
import GetAllParentLibrary from './GetAllParentLibrary'
import AddParentLibrary from './AddParentLibrary'
import EditParentLibrary from './EditParentLibrary'
import ViewParentLibrary from './ViewParentLibrary'

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';

/* Routes */
import {SIGN_IN, ALL_EDUCATIONAL_VIDEOS, ADD_EDUCATIONAL_VIDEO, EDIT_EDUCATIONAL_VIDEO , VIEW_EDUCATIONAL_VIDEO} from '../../routes';

class ParentLibraryAuth extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    
    return (
      <span>
          {/* Reporting */}
          <Route path={ALL_EDUCATIONAL_VIDEOS} component={GetAllParentLibrary} />
          <Route path={ADD_EDUCATIONAL_VIDEO} component={AddParentLibrary} />
          <Route path={EDIT_EDUCATIONAL_VIDEO} component={EditParentLibrary} />
          <Route path={VIEW_EDUCATIONAL_VIDEO} component={ViewParentLibrary} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(ParentLibraryAuth)
);
