import React, { Component } from 'react'
import { Accordion, Icon } from 'semantic-ui-react'
/*Components */
import EditEvaluationForm from './EditEvaluationForm';
/*Redux  */
import 'firebase/firestore';
import 'firebase/auth';
import { withFirebase } from '../../config';
import { connect } from 'react-redux';
import porpTypes from 'prop-types';
/* Actions */
import { getActivityData } from '../../Actions/ActivityActions';
/*Actions */
import { addEvaluationToRedux }
    from '../../Actions/EvaluationActions';
import { emptyEvaluationSucessAndfailure }
    from '../../Actions/EvaluationActions'
import {
    saveOneEvaluationsWithCategoryIDs,
    saveOneEvaluationsWithoutCategoryIDs,
    updateEvaluation
} from '../../Promises/EvaluationPromise.js'
import { addEvaluationCategory } from '../../Actions/EvaluationCategoryAction';


class EditEvaluation extends Component {

    state = {
        activeIndex: 0,

        excellentfileURL: "",
        excellentArticlURL: "",
        excellentStart: 4,
        excellentEnd: 5,
        excellentRcommendation: 'عظيم، جاء تقييم طفلك في النشاط ممتازاً، ما يدل على حسن تقبله للمحتوى وارتفاع مستوى تركيزه. احرص على استثمار ذلك بالنقاش والتفاعل حول قيمة الحلقة.  ولإثراء النقاش حول القيم التي لاقت اهتمام الطفل، برجاء تفقد الأوراق التربوية المرفقة، إذ تحوي مزيدًا من المعلومات والأفكار. تنبيه: اختر أوقاتًا مناسبة لتطبيق بعض الأفكار العملية التي يضمها البرنامج التطويري، فستسهم تلك الأفكار في تثبيت القيمة بشكل لا يصدق',

        goodfileURL: "",
        goodArticlURL: "",
        goodStart: 2,
        goodEnd: 3,
        goodRcommendation: 'جيد، كان تفاعل طفلك متوسطًا، وهو ما يدعو إلى التفاؤل بفضل إرادته ودعمك والمواد المتاحة. شارك طفلك النقاش حول قيمة الحلقة لتساعده على تبنيها، وأكثر ما سيساعدك في ذلك الأوراق التربوية.    وهنا يفضل أن تشارك البرنامج التطويري مع طفلك لتحسين مستواه، كل ذلك بشكل عملي يساعده على تثبيت القيمة. واطلب منه بعدها إعادة مشاهدة الحلقة وتأدية النشاط مرة أخرى للوصول لنتائج أفضل',

        badfileURL: "",
        badArticlURL: "",
        badStart: 0,
        badEnd: 1,
        badRcommendation: 'حسنًا، لم يحالف طفلك التوفيق هذه المرة، لكن محاولته تدل على الاهتمام بقيمة الحلقة. ندعوك إلى إعادة مشاهدة الحلقة مع طفلك بعد الاطلاع على الأوراق التربوية بما سيساعد على إثراء نقاشكما وتيسير تبنيه للقيمة واستفادته من الحلقة.  كما نرجو تنفيذ البرنامج التطويري معه، وتذكر أن البذرة تنمو بالسقاية، وأن تشجيعك له سيساعده دائماً على تخطي أية مصاعب، في طفولته وسائر حياته ',
    }


    constructor(props) {
        super(props);
        // console.log("condtructor")
        const { activityId } = this.props;
        // console.log("constructor = " + activityId)

        // get activitydata
        this.props.getActivityData(activityId);
    }

    componentDidMount() {

        // console.log("componentWillMount")
        // getting the activity id from EditActivityEpisode
        const { activityId } = this.props;
        // console.log("activityId in EditEvaluaiton = " + activityId);

        // get activitydata
        this.props.getActivityData(activityId);

    }

    componentWillReceiveProps() {
        // console.log("will recive props");
        // console.log(this.props.activityId, this.props)

        // console.log(this.props.activitydata.id)
        // console.log(this.props.activityId)
        // console.log(this.props.activitydata.id)
        // console.log(this.props.activityId == this.props.activitydata.id)

        // if the props has activity and it equals to activityID get from EpisodeID && questions !== undefined
        if (this.props.activitydata.id && this.props.activityId == this.props.activitydata.id) {
            let activitydata = this.props.activitydata;

            // console.log("will recive props if 1 ")
            // console.log(activitydata.evaluationCategory);

            if (this.props.activitydata.evaluationCategory !== undefined) {
                // console.log("will recive props if 2 ");
                // console.log(activitydata.evaluationCategory);


                if (activitydata.evaluationCategory !== null) {
                    // console.log("will recive props if 3 ");
                    // console.log(activitydata.evaluationCategory[0])
                    // console.log(activitydata.id)

                    // this.props.addEvaluationCategory(activitydata.evaluationCategory[0]);
                    this.setState({
                        evaluationCategory: activitydata.evaluationCategory[0],
                    })
                    if (activitydata.evaluationCategory[0].excellent !== null &&
                        activitydata.evaluationCategory[0].excellent !== undefined) {
                        this.setState({
                            excellentfileURL: activitydata.evaluationCategory[0].excellent.uploadedFile,
                            excellentArticlURL: activitydata.evaluationCategory[0].excellent.articleUrl,
                            excellentStart: activitydata.evaluationCategory[0].excellent.startNumber,
                            excellentEnd: activitydata.evaluationCategory[0].excellent.endNumber,
                            excellentRcommendation: activitydata.evaluationCategory[0].excellent.recommendation
                        })
                    }
                    if (activitydata.evaluationCategory[0].good !== null &&
                        activitydata.evaluationCategory[0].good !== undefined) {
                        this.setState({
                            goodfileURL: activitydata.evaluationCategory[0].good.uploadedFile,
                            goodArticlURL: activitydata.evaluationCategory[0].good.articleUrl,
                            goodStart: activitydata.evaluationCategory[0].good.startNumber,
                            goodEnd: activitydata.evaluationCategory[0].good.endNumber,
                            goodRcommendation: activitydata.evaluationCategory[0].good.recommendation
                        })

                    }
                    if (activitydata.evaluationCategory[0].bad !== null &&
                        activitydata.evaluationCategory[0].bad !== undefined) {
                        this.setState({
                            badfileURL: activitydata.evaluationCategory[0].bad.uploadedFile,
                            badArticlURL: activitydata.evaluationCategory[0].bad.articleUrl,
                            badStart: activitydata.evaluationCategory[0].bad.startNumber,
                            badEnd: activitydata.evaluationCategory[0].bad.endNumber,
                            badRcommendation: activitydata.evaluationCategory[0].bad.recommendation
                        })

                    }

                } else if (activitydata.evaluationCategory == null) {
                    // console.log("will recive props else if 4  ");

                    // console.log(activitydata.evaluationCategory)
                    // console.log(activitydata.id)

                    // this.props.addEvaluationCategory(null);
                    this.setState({
                        evaluationCategory: null,
                    })
                }

                this.setState({
                    activityID: activitydata.id
                })

            }
            // console.log(activitydata, this.props.activitydata)
        }
        // if the props has the old activity go to componenentDidMout
        // to get the get the new activity
        else if (this.props.activitydata.id && this.props.activityId != this.props.activitydata.id)
            this.componentDidMount();

        // console.log(" End will recive props");

    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    // Start excellent
    getExcellentFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ excellentfileURL: fileURL });
            if (fileURL !== ""
                && fileURL !== null
                && fileURL !== undefined) {
                this.setState({
                    badfileURL: fileURL,
                    goodfileURL: fileURL
                })
            }
        }
    }
    getEmptyExcellentFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ excellentfileURL: fileURL });
            if (fileURL !== ""
                && fileURL !== null
                && fileURL !== undefined) {
                this.setState({
                    badfileURL: fileURL,
                    goodfileURL: fileURL
                })
            }
        }
    }
    getExcellentArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ excellentArticlURL: articlURL });

            if (articlURL !== ""
                && articlURL !== null
                && articlURL !== undefined) {

                this.setState({
                    goodArticlURL: articlURL,
                    badArticlURL: articlURL
                })
            }
        }
    }
    getEmptyExcellentArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ excellentArticlURL: articlURL });

            if (articlURL !== ""
                && articlURL !== null
                && articlURL !== undefined) {

                this.setState({
                    goodArticlURL: articlURL,
                    badArticlURL: articlURL
                })
            }
        }
    }

    getExcellentStart(start) {
        if (start) {
            console.log("ex start = " + start)
            this.setState({ excellentStart: start });
        }
    }
    getExcellentEnd(end) {
        if (end) {
            this.setState({ excellentEnd: end });
        }
    }
    getExcellentRecommendation(recommendation) {
        if (recommendation) {
            this.setState({ excellentRcommendation: recommendation });
        }
    }
    // End excellent

    // Start Good
    getGoodFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ goodfileURL: fileURL });
        }
    }
    getEmptyGoodFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ goodfileURL: fileURL });
        }
    }
    getGoodArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ goodArticlURL: articlURL });
        }
    }
    getEmptyGoodArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ goodArticlURL: articlURL });
        }
    }

    getGoodStart(start) {
        if (start) {
            this.setState({ goodStart: start });
        }
    }
    getGoodEnd(end) {
        if (end) {
            this.setState({ goodEnd: end });
        }
    }
    getGoodRecommendation(recommendation) {
        if (recommendation) {
            this.setState({ goodRcommendation: recommendation });
        }
    }
    // End Good

    // Start Bad
    getBadFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ badfileURL: fileURL });
        }
    }
    getEmptyBadFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ badfileURL: fileURL });
        }
    }
    getBadArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ badArticlURL: articlURL });
        }
    }
    getEmptyBadArticleUrl(articlURL) {
        if (articlURL) {
            this.setState({ badArticlURL: articlURL });
        }
    }

    getBadStart(start) {
        if (start) {
            this.setState({ badStart: start });
        }
    }
    getBadEnd(end) {
        if (end) {
            this.setState({ badEnd: end });
        }
    }
    getBadRecommendation(recommendation) {
        if (recommendation) {
            this.setState({ badRcommendation: recommendation });
        }
    }
    // End Good

    handleSubmit = (e) => {
        const { evaluationCategoryitem } = this.props;
        const { activityID } = this.state

        var selectedBlogsIDs = [];
        const {
            excellentStart, excellentEnd, excellentRcommendation, excellentfileURL, excellentArticlURL,
            goodStart, goodEnd, goodRcommendation, goodfileURL, goodArticlURL,
            badStart, badEnd, badRcommendation, badfileURL, badArticlURL } = this.state;

        if (excellentStart === '' || excellentStart == undefined || excellentStart < 0) {
            alert("Empty Excellent Start Number")
        }
        else if (excellentEnd === '' || excellentEnd == undefined || excellentEnd == 0) {
            alert("Empty Excellent End Number")
        }
        else if (excellentRcommendation === '' || excellentRcommendation == undefined) {
            alert("Empty Excellent Recommendation")
        }
        else if (excellentfileURL === '' || excellentfileURL == undefined) {
            alert("Empty Excellent Uploaded File")
        }
        else if (excellentArticlURL === '' || excellentArticlURL == undefined) {
            alert("Empty Excellent article File")
        }
        /************ good ************ */
        else if (goodStart === '' || goodStart == undefined || goodStart < 0) {
            alert("Empty Good Start Number")
        }
        else if (goodEnd === '' || goodEnd == undefined || goodEnd == 0) {
            alert("Empty Good End Number")
        }
        else if (goodRcommendation === '' || goodRcommendation == undefined) {
            alert("Empty Good Recommendation")
        }
        else if (goodfileURL === '' || goodfileURL == undefined) {
            alert("Empty Good Uploaded File")
        }
        else if (goodArticlURL === '' || goodArticlURL == undefined) {
            alert("Empty Good article File")
        }
        /************ Bad*************/
        else if (badStart === '' || badStart == undefined || badStart < 0) {
            alert("Empty Bad Start Number")
        }
        else if (badEnd === '' || badEnd == undefined || badEnd == 0) {
            alert("Empty Bad End Number")
        }
        else if (badRcommendation === '' || badRcommendation == undefined) {
            alert("Empty Bad Recommendation")
        }
        else if (badfileURL === '' || badfileURL == undefined) {
            alert("Empty Bad Uploaded File")
        }
        else if (badArticlURL === '' || badArticlURL == undefined) {
            alert("Empty Bad article File")
        }

        else {


            // if evaluation categoty null then save excellent evaluaiton
            // without id then save good and bad with same categoty id
            if (this.state.evaluationCategory == null) {

                var newCategoryId = 0;

                console.log("evaluationCategoryitem === null");
                console.log(evaluationCategoryitem)
                console.log(activityID)

                saveOneEvaluationsWithoutCategoryIDs(this.state.excellentStart, this.state.excellentEnd,
                    this.state.excellentRcommendation, this.state.excellentfileURL, selectedBlogsIDs,
                    "excellent", activityID, this.state.excellentArticlURL)
                    .then((res) => {
                        alert("Excellent Evaluation added successfully");

                        console.log(" evaluationCategoryitem = " + res.data.id)
                        console.log(res.data)
                        var newCategoryId = res.data.id

                        saveOneEvaluationsWithCategoryIDs(this.state.badStart, this.state.badEnd,
                            this.state.badRcommendation, this.state.badfileURL, selectedBlogsIDs,
                            "bad", newCategoryId, this.state.badArticlURL)
                            .then((res) => {
                                alert("Bad Evaluation added successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })

                            

                        saveOneEvaluationsWithCategoryIDs(this.state.goodStart, this.state.goodEnd,
                            this.state.goodRcommendation, this.state.goodfileURL, selectedBlogsIDs,
                            "good", newCategoryId, this.state.goodArticlURL)
                            .then((res) => {
                                alert(" Good Evaluation added successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })

                    })
                    .catch((Error) => {
                        alert("Error messing data or network " + Error)
                    })



            } else {


                // start excellent
                // data exist update
                if (this.state.evaluationCategory !== null
                    && this.state.evaluationCategory.id !== undefined
                    && this.state.evaluationCategory.excellent !== undefined
                    && this.state.evaluationCategory.excellent !== null) {

                    if (this.state.evaluationCategory.excellent.id !== undefined) {
                        updateEvaluation(this.state.evaluationCategory.excellent.id,
                            this.state.excellentStart,
                            this.state.excellentEnd,
                            this.state.excellentRcommendation,
                            this.state.excellentfileURL,
                            selectedBlogsIDs, this.state.excellentArticlURL)
                            .then((res) => {
                                alert("Excellent Evaluation updated successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })
                    }
                    else {
                        alert("Error with Excellent evaluation ID");
                    }
                }

                // data dosen't exist add
                else {

                    console.log(this.state.evaluationCategory)
                    console.log(evaluationCategoryitem)

                    // if the evaluation category has a value then update evaluationCategory table
                    // and the data in redux = '' , it filled only of an evaluation category saved for the first time
                    // to map data between children
                    if (this.state.evaluationCategory.id !== undefined &&
                        this.state.evaluationCategory.id !== null &&
                        evaluationCategoryitem == '') {

                        console.log("evaluationCategoryid !== undefined or null or evaluationCategoryitem == ''");
                        console.log(this.state.evaluationCategory.id)
                        console.log(evaluationCategoryitem)

                        saveOneEvaluationsWithCategoryIDs(this.state.excellentStart, this.state.excellentEnd,
                            this.state.excellentRcommendation, this.state.excellentfileURL, selectedBlogsIDs,
                            "excellent", this.state.evaluationCategory.id, this.state.excellentArticlURL)
                            .then((res) => {
                                alert("Excellent Evaluation added successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })

                    }

                    // if the evaluation category has a value then update evaluationCategory table
                    else if (evaluationCategoryitem !== undefined &&
                        evaluationCategoryitem !== null && evaluationCategoryitem !== '') {

                        console.log("evaluationCategoryitem !== undefined or null");
                        console.log(evaluationCategoryitem)

                        saveOneEvaluationsWithCategoryIDs(this.state.excellentStart, this.state.excellentEnd,
                            this.state.excellentRcommendation, this.state.excellentfileURL, selectedBlogsIDs,
                            "excellent", this.state.evaluationCategory.id, this.state.excellentArticlURL)
                            .then((res) => {
                                alert("Excellent Evaluation added successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })
                    }

                }
                // End excellent

                // start good
                // data exist update
                if (this.state.evaluationCategory !== null
                    && this.state.evaluationCategory.id !== undefined
                    && this.state.evaluationCategory.good !== undefined
                    && this.state.evaluationCategory.good !== null) {

                    if (this.state.evaluationCategory.good.id !== undefined) {
                        updateEvaluation(this.state.evaluationCategory.good.id,
                            this.state.goodStart,
                            this.state.goodEnd,
                            this.state.goodRcommendation,
                            this.state.goodfileURL,
                            selectedBlogsIDs, this.state.goodArticlURL)
                            .then((res) => {
                                alert("Good Evaluation updated successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })
                    }
                    else {
                        alert("Error with Good evaluation ID");
                    }
                }
                // data dosen't exist add
                else {

                    console.log(this.state.evaluationCategory)
                    console.log(evaluationCategoryitem)
                    // if the evaluation category has a value then update evaluationCategory table
                    // and the data in redux = '' , it filled only of an evaluation category saved for the first time
                    // to map data between children
                     if (this.state.evaluationCategory.id !== undefined &&
                        this.state.evaluationCategory.id !== null &&
                        evaluationCategoryitem == '') {

                        console.log("evaluationCategoryid !== undefined or null or evaluationCategoryitem == ''");
                        console.log(this.state.evaluationCategory.id)
                        console.log(evaluationCategoryitem)

                        saveOneEvaluationsWithCategoryIDs(this.state.goodStart, this.state.goodEnd,
                            this.state.goodRcommendation, this.state.goodfileURL, selectedBlogsIDs,
                            "good", this.state.evaluationCategory.id, this.state.goodArticlURL)
                            .then((res) => {
                                alert("Good Evaluation added successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })

                    }

                    // if the evaluation category has a value then update evaluationCategory table
                    else if (evaluationCategoryitem !== undefined &&
                        evaluationCategoryitem !== null && evaluationCategoryitem !== '') {

                        console.log("evaluationCategoryitem !== undefined or null");
                        console.log(evaluationCategoryitem)

                        saveOneEvaluationsWithCategoryIDs(this.state.goodStart, this.state.goodEnd,
                            this.state.goodRcommendation, this.state.goodfileURL, selectedBlogsIDs,
                            "good", this.state.evaluationCategory.id, this.state.goodArticlURL)
                            .then((res) => {
                                alert(" Good Evaluation added successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })
                    }


                }
                // end good

                // start bad
                // data exist update
                if (this.state.evaluationCategory !== null
                    && this.state.evaluationCategory.id !== undefined
                    && this.state.evaluationCategory.bad !== undefined
                    && this.state.evaluationCategory.bad !== null) {

                    if (this.state.evaluationCategory.bad.id !== undefined) {

                        updateEvaluation(this.state.evaluationCategory.bad.id,
                            this.state.badStart,
                            this.state.badEnd,
                            this.state.badRcommendation,
                            this.state.badfileURL,
                            selectedBlogsIDs, this.state.badArticlURL)
                            .then((res) => {
                                alert("Bad Evaluation updated successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })
                    }
                    else {
                        alert("Error with Bad evaluation ID");
                    }
                }
                // data dosen't exist add
                else {

                    // if the evaluation category has a value then update evaluationCategory table
                    // and the data in redux = '' , it filled only of an evaluation category saved for the first time
                    // to map data between children
                    if (this.state.evaluationCategory.id !== undefined &&
                        this.state.evaluationCategory.id !== null &&
                        evaluationCategoryitem == '') {

                        console.log("evaluationCategoryid !== undefined or null or evaluationCategoryitem == ''");
                        console.log(this.state.evaluationCategory.id)
                        console.log(evaluationCategoryitem)

                        saveOneEvaluationsWithCategoryIDs(this.state.badStart, this.state.badEnd,
                            this.state.badRcommendation, this.state.badfileURL, selectedBlogsIDs,
                            "bad", this.state.evaluationCategory.id, this.state.badArticlURL)
                            .then((res) => {
                                alert("Bad Evaluation added successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })

                    }

                    // if the evaluation category has a value then update evaluationCategory table
                    else if (evaluationCategoryitem !== undefined &&
                        evaluationCategoryitem !== null && evaluationCategoryitem !== '') {

                        console.log("evaluationCategoryitem !== undefined or null");
                        console.log(evaluationCategoryitem)

                        saveOneEvaluationsWithCategoryIDs(this.state.badStart, this.state.badEnd,
                            this.state.badRcommendation, this.state.badfileURL, selectedBlogsIDs,
                            "bad", this.state.evaluationCategory.id, this.state.badArticlURL)
                            .then((res) => {
                                alert("Bad Evaluation added successfully");
                            })
                            .catch((Error) => {
                                alert("Error messing data or network " + Error)
                            })
                    }


                }
                // End bad

            }
        }


        e.preventDefault();
    }

    render() {



        ///////////////////////
        const { activeIndex, activityID } = this.state
        let evaluationCategory = this.state.evaluationCategory
        let excellent = null, veryGood = null, good = null, fairlyGood = null, bad = null;

        console.log(evaluationCategory)
        console.log("activity id = " + activityID)

        if (evaluationCategory !== undefined) {

            if (evaluationCategory !== null
                && this.state.evaluationCategory.id !== undefined
                && this.state.evaluationCategory.excellent !== undefined) {
                excellent =
                    <EditEvaluationForm
                        category_type="excellent"
                        evaluationCategoryId={this.state.evaluationCategory.id}
                        formData={this.state.evaluationCategory.excellent}
                        returnCategoryFileURLCallBack={this.getExcellentFileUrl.bind(this)}
                        returnCategoryArticleURLCallBack={this.getExcellentArticleUrl.bind(this)}

                        returnedStart={this.getExcellentStart.bind(this)}
                        returnedEnd={this.getExcellentEnd.bind(this)}
                        returnedRecommendation={this.getExcellentRecommendation.bind(this)}

                        start={this.state.excellentStart}
                        end={this.state.excellentEnd}
                        recommendation={this.state.excellentRcommendation}


                    />
            }
            else if (evaluationCategory == null) {
                excellent =
                    <EditEvaluationForm category_type="excellent"
                        evaluationCategoryId={null}
                        formData={null}
                        activityID={activityID}
                        returnCategoryFileURLCallBack={this.getEmptyExcellentFileUrl.bind(this)}
                        returnCategoryArticleURLCallBack={this.getEmptyExcellentArticleUrl.bind(this)}

                        returnedStart={this.getExcellentStart.bind(this)}
                        returnedEnd={this.getExcellentEnd.bind(this)}
                        returnedRecommendation={this.getExcellentRecommendation.bind(this)}

                        start={this.state.excellentStart}
                        end={this.state.excellentEnd}
                        recommendation={this.state.excellentRcommendation}
                    />
            }

            // if (evaluationCategory !== null && this.state.evaluationCategory.id !== undefined
            //     && this.state.evaluationCategory.veryGood !== undefined) {
            //     veryGood =
            //         <EditEvaluationForm category_type="veryGood"
            //             evaluationCategoryId={this.state.evaluationCategory.id}
            //             formData={this.state.evaluationCategory.veryGood}
            //         //returnCategoryFileURLCallBack={this.getFileUrl.bind(this)}
            //         />
            // }
            // else if (evaluationCategory == null) {
            //     veryGood = <EditEvaluationForm category_type="veryGood"
            //         evaluationCategoryId={null}
            //         formData={null}
            //         activityID={activityID}
            //     //returnCategoryFileURLCallBack={this.getFileUrl.bind(this)}
            //     />
            // }

            if (evaluationCategory !== null && this.state.evaluationCategory.id !== undefined
                && this.state.evaluationCategory.good !== undefined) {

                good =
                    <EditEvaluationForm category_type="good"
                        evaluationCategoryId={this.state.evaluationCategory.id}
                        formData={this.state.evaluationCategory.good}
                        returnCategoryFileURLCallBack={this.getGoodFileUrl.bind(this)}
                        returnCategoryArticleURLCallBack={this.getGoodArticleUrl.bind(this)}
                        copyFileURL={this.state.goodfileURL}
                        copyArticleURL={this.state.goodArticlURL}

                        returnedStart={this.getGoodStart.bind(this)}
                        returnedEnd={this.getGoodEnd.bind(this)}
                        returnedRecommendation={this.getGoodRecommendation.bind(this)}

                        start={this.state.goodStart}
                        end={this.state.goodEnd}
                        recommendation={this.state.goodRcommendation}

                    />
            }
            else if (evaluationCategory == null) {
                console.log("good null")

                good =
                    <EditEvaluationForm category_type="good"
                        evaluationCategoryId={null}
                        formData={null}
                        activityID={activityID}
                        returnCategoryFileURLCallBack={this.getEmptyGoodFileUrl.bind(this)}
                        returnCategoryArticleURLCallBack={this.getEmptyGoodArticleUrl.bind(this)}
                        copyFileURL={this.state.goodfileURL}
                        copyArticleURL={this.state.goodArticlURL}

                        returnedStart={this.getGoodStart.bind(this)}
                        returnedEnd={this.getGoodEnd.bind(this)}
                        returnedRecommendation={this.getGoodRecommendation.bind(this)}

                        start={this.state.goodStart}
                        end={this.state.goodEnd}
                        recommendation={this.state.goodRcommendation}

                    />
            }

            // if (evaluationCategory !== null && this.state.evaluationCategory.id !== undefined
            //     && this.state.evaluationCategory.fairlyGood !== undefined) {
            //     fairlyGood = <EditEvaluationForm category_type="fairlyGood"
            //         evaluationCategoryId={this.state.evaluationCategory.id}
            //         formData={this.state.evaluationCategory.fairlyGood}
            //     //returnCategoryFileURLCallBack={this.getFileUrl.bind(this)}
            //     />
            // }
            // else if (evaluationCategory == null) {
            //     fairlyGood = <EditEvaluationForm category_type="fairlyGood"
            //         evaluationCategoryId={null}
            //         formData={null}
            //         activityID={activityID}
            //     //returnCategoryFileURLCallBack={this.getFileUrl.bind(this)}
            //     />
            // }


            if (evaluationCategory !== null && this.state.evaluationCategory.id !== undefined
                && this.state.evaluationCategory.bad !== undefined) {


                bad =
                    <EditEvaluationForm category_type="bad"
                        evaluationCategoryId={this.state.evaluationCategory.id}
                        formData={this.state.evaluationCategory.bad}
                        returnCategoryFileURLCallBack={this.getBadFileUrl.bind(this)}
                        returnCategoryArticleURLCallBack={this.getBadArticleUrl.bind(this)}
                        copyFileURL={this.state.badfileURL}
                        copyArticleURL={this.state.badArticlURL}

                        returnedStart={this.getBadStart.bind(this)}
                        returnedEnd={this.getBadEnd.bind(this)}
                        returnedRecommendation={this.getBadRecommendation.bind(this)}

                        start={this.state.badStart}
                        end={this.state.badEnd}
                        recommendation={this.state.badRcommendation}

                    />
            }
            else if (evaluationCategory == null) {


                bad =
                    <EditEvaluationForm category_type="bad"
                        evaluationCategoryId={null}
                        formData={null}
                        activityID={activityID}
                        returnCategoryFileURLCallBack={this.getEmptyBadFileUrl.bind(this)}
                        returnCategoryArticleURLCallBack={this.getEmptyBadArticleUrl.bind(this)}
                        copyFileURL={this.state.badfileURL}
                        copyArticleURL={this.state.badArticlURL}

                        returnedStart={this.getBadStart.bind(this)}
                        returnedEnd={this.getBadEnd.bind(this)}
                        returnedRecommendation={this.getBadRecommendation.bind(this)}

                        start={this.state.badStart}
                        end={this.state.badEnd}
                        recommendation={this.state.badRcommendation}
                    />
            }

        }



        return (
            <div>

                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 0}
                        index={1} onClick={this.handleClick}>
                        <div className="card-header" id="card-header" >
                            <h4 className="mb-0">
                                <Icon name='dropdown' />
                                Excellent
                            </h4>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                        <div id="collapseOne" className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample">

                            {excellent}

                        </div>
                    </Accordion.Content>
                </Accordion>

                {/*
                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 2}
                        index={2} onClick={this.handleClick}>
                        <div className="card-header" id="card-header" >
                            <h4 className="mb-0">
                                <Icon name='dropdown' />
                                Very Good
                            </h4>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                        <div id="collapseOne" className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample">

                            {veryGood}

                        </div>
                    </Accordion.Content>
                </Accordion>
                */}

                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 3}
                        index={3} onClick={this.handleClick}>
                        <div className="card-header" id="card-header" >
                            <h4 className="mb-0">
                                <Icon name='dropdown' />
                                Good
                            </h4>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                        <div id="collapseOne" className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample">

                            {good}

                        </div>
                    </Accordion.Content>
                </Accordion>

                {/*
                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 4}
                        index={4} onClick={this.handleClick}>
                        <div className="card-header" id="card-header" >
                            <h4 className="mb-0">
                                <Icon name='dropdown' />
                                Fairly Good
                            </h4>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                        <div id="collapseOne" className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample">

                            {fairlyGood}

                        </div>
                    </Accordion.Content>
                </Accordion>
                */}

                <Accordion fluid styled>
                    <Accordion.Title active={activeIndex === 5}
                        index={5} onClick={this.handleClick}>
                        <div className="card-header" id="card-header" >
                            <h4 className="mb-0">
                                <Icon name='dropdown' />
                                Bad
                            </h4>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 5}>
                        <div id="collapseOne" className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample">

                            {bad}

                        </div>
                    </Accordion.Content>

                </Accordion>


                <div className="buttons_container float-right">


                    <button
                        className=" float-right btn btn-lg btn-success btn-suc btn-block-sm"
                        onClick={this.handleSubmit}>
                        Save All
                    </button>

                </div>
            </div>
        )
    }
}

EditEvaluation.porpTypes = {
    getActivityData: porpTypes.func.isRequired,
    addEvaluationToRedux: porpTypes.func.isRequired,
    addEvaluationCategory: porpTypes.func.isRequired,
}
const mapStateToProps = state => ({
    activity: state.activities.item,
    activitydata: state.activities.itemforUpdate,

    evaluationCategoryitem: state.evaluationCategory.item


});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                getActivityData,
                addEvaluationToRedux,
                emptyEvaluationSucessAndfailure,
                addEvaluationCategory

            })
        (EditEvaluation)
    );
