import React, { useRef, Component } from "react";
import OuterHeader from "../Layout/OuterHeader";
import InnerHeader from "../Layout/InnerHeader";
import Dashboard from "../Dashboard";
import Footer from "../Layout/Footer";
import UploadImage from "../S3Uploading/UploadImage";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../css/AppCss/addBlog.css";
import PublishDate from "../Layout/PublishDate";
import { Button, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { saveEducationalVideo , emptyEducationalVideo } from "../../Actions/ParentLibraryAction";
import { Redirect, Link } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';

import porpTypes from "prop-types";
import JoditEditor from "jodit-react";
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_EDUCATIONAL_VIDEOS } from '../../routes';

const INITIAL_STATE = {
  image: "",
  title: "",
  titleInEnglish :"",
  description: "",
  descriptionInEnglish: "",
  link: "",

  cancel: false,
  editorImage: '',
  error: true,
  errorMessage: "",
};

class AddParentLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {

  }

  getImage(imageUrl) {
    if (imageUrl) {

      if (imageUrl === "delete") {
        this.setState({ image: '' });

      } else {
        this.setState({ image: imageUrl });
      }
    }
  }


  getEditorImage(imageUrl) {
    if (imageUrl) {

      if (imageUrl === "delete") {
        this.setState({ editorImage: '' });

      } else {
        this.setState({ editorImage: imageUrl });
      }
    }
  }


  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces });


    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}/;


    if (e.target.name === "link" && !youtubeRegex.test(checkSpaces)) {
        this.setState({ error: true, errorMessage: "Please enter a valid YouTube URL" });
    } else {
  
        if (
            this.state.title === '' || this.state.title === null || this.state.title === undefined || this.state.title.trim() === '' ||
            this.state.titleInEnglish === '' || this.state.titleInEnglish === null || this.state.titleInEnglish === undefined || this.state.titleInEnglish.trim() === '' ||
            this.state.link === '' || this.state.link === null || this.state.link === undefined || this.state.link === 'null'
        ) {
            this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
        } else {
            this.setState({ error: false, errorMessage: "" });
        }
    }
}

handleSubmit=e=>{
    const {
      title,
      titleInEnglish,
      description,
      descriptionInEnglish,
      image,
      link
    } = this.state;



    if (image === '' || image === undefined || image === null) {
      alert("please insert image");
      this.setState({
        validateInput: false
      })
    }
    else if (title === '' || title === undefined || title === null || title.trim() === '') {
      alert("please insert title in arabic");
      this.setState({
        validateInput: false
      })
    }
    else if (titleInEnglish === '' || titleInEnglish === undefined || titleInEnglish === null  || titleInEnglish.trim() === '') {
      alert("please insert title in english");
      this.setState({
        validateInput: false
      })
    }

    else if (link === '' || link === undefined || link === null  || link.trim() === '') {
      alert("please insert video link");
      this.setState({
        validateInput: false
      })
    }




    else {
      this.setState({ validateInput: true })

      const objectData = {
        itemName:title,
        itemNameInEnglish: titleInEnglish,
        itemDescription: description !== "" ? description:null,
        itemDescriptionInEnglish: descriptionInEnglish !== "" ? descriptionInEnglish :null,
        itemImage: image,
        itemUrl: link,
    }


      this.props. saveEducationalVideo(
        objectData
      );
    }
    e.preventDefault();
  };

  handelCancel = e => {
    this.setState({ cancel: true });
  }


  render() {
    const
      config = {
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        enableDragAndDropFileToEditor: true,
        useImageResizer: false,
        direction: 'rtl',
      }


    const {parentLibraryError,  parentLibrarySuccess} = this.props;


    if ( parentLibrarySuccess === false) {
      alert( parentLibraryError);
      this.props. emptyEducationalVideo();
    }
    else if ( parentLibrarySuccess === true) {
      alert("Parent Educational Video added successfully");
      this.props. emptyEducationalVideo();
      return <Redirect to={ALL_EDUCATIONAL_VIDEOS} />;
    }

    if (this.state.cancel === true) {
      return (<Redirect to={ALL_EDUCATIONAL_VIDEOS}></Redirect>)

    }
    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col-9">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">

                    <div className="col-12 left-main-body" id="left-main-body">
                      <div className="statusbar small-font">
                        <span className="status-link">
                          <Link to={ADMIN_LAND_PAGE}>Home</Link><span>  </span>
                        </span>
                        <span className="status-link">
                          <Link to={ALL_EDUCATIONAL_VIDEOS}>Parent Educational Videos</Link>
                        </span>
                      </div>
                      <br />
                      <h5 className="main-title mt-2 font-weight-bold">
                        <span>Add Educational Video</span>
                      </h5>
                      <br />
                      <div className="container">
                        <form onSubmit={this.handleSubmit}>
                          <span className="required_input">*</span>
                          <label className="small-label">Image</label>
                          <UploadImage
                            getImageURLCallBack={this.getImage.bind(this)}
                            imageUrl={this.state.image}
                            imagepreview={this.state.image}
                            s3DirectryrName='parentLibrary'
                          />
                          <br />
                          <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Title In Arabic</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title"
                            name="title"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.title}
                          />
                          <br />
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Title In English</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title_in_english"
                            name="titleInEnglish"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.titleInEnglish}
                          />
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              Description
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="description"
                              onChange={this.handleChange}
                              onKeyUp={this.handleChange}
                              value={this.state.description}
                            />
                          </div>
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              Description In English
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="descriptionInEnglish"
                              onChange={this.handleChange}
                              onKeyUp={this.handleChange}
                              value={this.state.descriptionInEnglish}
                            />
                          </div>
                          <br />

                         
                          <label className="small-label">
                          <span className="required_input">*</span>
                            Youtube Url</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="link"
                            name="link"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.link}
                          />
                          <br />

                          <Button
                            icon
                            labelPosition="right"
                            id="nextButton"
                            onClick={this.handleSubmit}
                            disabled={this.state.error}
                          >
                            Publish
                          </Button>

                          <Button id="episode_cancel"
                            onClick={this.handelCancel}>
                            Cancel
                          </Button>

                          <br />
                          <br /> <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

AddParentLibrary.porpTypes = {

};

const mapStateToProps = state => ({
    parentLibrary: state.parentLibrary.item,
    parentLibraryError: state.parentLibrary.error_adding_educationalVideo,
    parentLibrarySuccess: state.parentLibrary.educationalVideo_added_successfully
});

export default connect(
  mapStateToProps,
  {  saveEducationalVideo,  emptyEducationalVideo}
)(AddParentLibrary);
