import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
/**Actions */
import { getPromoCodes, deletePromoCode } from '../../Actions/PromoCodesActions';
import { getAdminInfo } from '../../Actions/UserManagementActions';
/*Components */
import Loader from '../Layout/Loader';
import DeletePromoCode from './DeletePromoCode';
/*CSS */
import 'react-table/react-table.css';
/*Routes*/
import { GET_CODE_TO_VIEW } from '../../routes';

const FoldableTable = FoldableTableHOC(ReactTable);


class PromoCodesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCodes: [],
      open: false,
      check: true,
    };
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  componentDidMount() {
    this.props.getPromoCodes();
    this.props.getAdminInfo();
  }

  deleteCodeIcon(props) {
    let trashIcon = (<DeletePromoCode
      onClick={this.handleDeleteButton.bind(this, props.original.uid)}
      uid={props.original.uid} />)
    return trashIcon;
  }

  handleDeleteButton = (uid, e) => {
    //
  }

  handleSetDataInState = (data) => {
    this.setState({ promoCodes: data, check: false })
  }
  render() {
    const actionColumn =
    {
      Header: "Actions",
      accessor: "uid",
      width: 77,
      MinWidth: 77,
      Maxwidth: 77,
      //foldable: true,
      filterable: false,
      resizable: true,
      headerClassName: 'table-header',
      Cell: (props) => {
        return (
          <div style={{ textAlign: "center" }}>
            {this.deleteCodeIcon(props)}
          </div>
        )
      }
    }

    const columns = [
      {
        Header: "",
        filterable: false,
        width: 40,
        MinWidth: 40,
        Maxwidth: 40,
        resizable: false,
        headerClassName: 'table-header',
        Cell: props => {
          return (
            <div scope="row">
              <div className="custom-control custom-checkbox">
                <input type="checkbox"
                  className="custom-control-input"
                  name="check_customer"
                  id={props.original.uid}
                />
                <label className="custom-control-label"
                  for={props.original.uid}></label>
              </div>
            </div>
          )
        }
      },
      {
        Header: "Code Name",
        accessor: "name",
        width: 150,
        MinWidth: 150,
        Maxwidth: 150,
        //foldable: true,
        resizable: true,
        headerClassName: 'table-header',
        Cell: props => {

          return (
            <div style={{ "textAlign": "center" }}>
              <Link className="link"
                to={{ pathname: GET_CODE_TO_VIEW + "/" + props.original.uid }}>
                {props.original.name}
              </Link>
            </div>
          )
        }

      },
      {
        Header: "Code Description",
        accessor: "text",
        width: 150,
        filterable: true,
        resizable: true,
        headerClassName: 'table-header',
        Cell: props => {

          return (
            <div style={{ "textAlign": "center" }}>

              {props.original.text}
            </div>
          )
        }

      },
      {
        Header: "Monthly Usage",
        accessor: "numberOfMonthlyUsers",
        width: 100,
        filterable: false,
        headerClassName: 'table-header',
        resizable: true,
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              {props.original.numberOfMonthlyUsers}
            </div>
          )
        }
      },
      {
        Header: "Yearly Usage",
        accessor: "numberOfYearlyUsers",
        width: 100,
        filterable: false,
        headerClassName: 'table-header',
        resizable: true,
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              {props.original.numberOfYearlyUsers}
            </div>
          )
        }
      },{
        Header: "Discount Amount",
        accessor: "discountAmount",
        width: 140,
        filterable: false,
        headerClassName: 'table-header',
        resizable: true,
        Cell: props => {
          return (
            <div style={{ textAlign: "center" }}>
              {props.original.discountAmount}
            </div>
          )
        }
      },
      {
        Header: "Discount Type",
        accessor: "discountType",
        width: 130,
        filterable: false,
        headerClassName: 'table-header',
        resizable: true,
        Cell: props => {
          if (props.original.discountType !== null && props.original.discountType !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {props.original.discountType.type}
              </div>
            )
          } else {
            return (<div style={{ "textAlign": "center" }}> -- </div>)
          }
        }
      },
      {
        Header: "Coupon Type",
        accessor: "couponType",
        width: 130,
        filterable: false,
        headerClassName: 'table-header',
        resizable: true,
        Cell: props => {
          if (props.original.couponType !== null && props.original.couponType !== undefined) {
            return (
              <div style={{ textAlign: "center" }}>
                {props.original.couponType.type}
              </div>
            )
          } else {
            return (<div style={{ "textAlign": "center" }}> -- </div>)
          }
        }
      },
    ]

    const { promoCodes, userPaymentProfile } = this.props;
    if (userPaymentProfile !== '' && userPaymentProfile !== undefined &&
      userPaymentProfile !== null && userPaymentProfile.user_role !== undefined &&
      userPaymentProfile.user_role.role === "SuperAdmin") {
      columns.push(actionColumn);
    }
    let codes = null;
    const { error, loading, success } = this.props;
    if (loading === true) {
      codes = (<Loader />)
    }

    if (success === true && error === '' && this.state.check === true) {
      // set state
      this.handleSetDataInState(promoCodes);
    }
    if (success === true && error === '') {
      //console.log(this.state.promoCodes)
      return (
        <div>
          <div className="mt-4 responsive-table">
            <ReactTable
              className=" table-striped custm-table"
              columns={columns}
              data={promoCodes}
              filterable
            >
            </ReactTable>
          </div>
        </div>
      )
    }
    return (
      <div>

        <div className="mt-4 responsive-table">
          {codes}
        </div>
      </div>
    )
  }
}

PromoCodesTable.propTypes = {
  getPromoCodes: propTypes.func.isRequired,
  getAdminInfo: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  userPaymentProfile: state.admins.item,
  promoCodes: state.promoCodes.items,
  loading: state.promoCodes.loading,
  error: state.promoCodes.getting_allCodes_error,
  success: state.promoCodes.getting_allCodes_sucessfully
});

export default withRouter
  (connect
    (mapStateToProps,
      {
        getPromoCodes,
        getAdminInfo
      })
    (PromoCodesTable)
  );
