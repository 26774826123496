import { FETCH_EDUCATIONAL_VIDEOS, LOAD_EDUCATIONAL_VIDEOS, ERROR_LOADING_EDUCATIONAL_VIDEOS, PUBLISH_EDUCATIONAL_VIDEO, ERROR_ADDING_EDUCATIONAL_VIDEO, GET_EDUCATIONAL_VIDEO_DATA, ERROR_LOADING_EDUCATIONAL_VIDEO, LOAD_EDUCATIONAL_VIDEO, UPDATE_EDUCATIONAL_VIDEO, ERROR_UPDATING_EDUCATIONAL_VIDEO, EMPTY_EDUCATIONAL_VIDEO, DELETE_EDUCATIONAL_VIDEO, ERROR_DELETING_EDUCATIONAL_VIDEO, SETTING_EDUCATIONAL_VIDEO_STATUS, ERROR_SETTING_EDUCATIONAL_VIDEO_STATUS, EMPTY_EDUCATIONAL_VIDEO_ITEMS } from "../Actions/Types";


const initialState = {
    items:[],
    item:{},
    error_loading_educationalVideos:'',
    loading:'',
    error_adding_educationalVideo:'',
    educationalVideo_added_successfully:'',
    educationalVideo_updated_successfully:'',
    error_updating_educationalVideo:'',
    educationalVideo_deleted_successfully:'',
    error_deleting_educationalVideo:'',
    status_updated_successfully:'',
    error_updating_status:''
}

export default function (state=initialState,action)
{
    switch(action.type)
    {
        case FETCH_EDUCATIONAL_VIDEOS:
            return{
                ...state,
                items:action.payload,
                error_loading_educationalVideos:'',
                loading:false
            }
        case LOAD_EDUCATIONAL_VIDEOS:
            return {
                ...state,
                loading:true
            }
        case ERROR_LOADING_EDUCATIONAL_VIDEOS:
            return{
                ...state,
                error_loading_educationalVideos:action.payload,
                loading:false
            }
        case EMPTY_EDUCATIONAL_VIDEO:
            return{
                ...state,
                error_adding_educationalVideo:action.payload,
                educationalVideo_added_successfully:action.payload,
                error_loading_educationalVideo:action.payload,
                educationalVideo_updated_successfully:action.payload,
                educationalVideo_deleted_successfully:'',
                error_deleting_educationalVideo:'',
                status_updated_successfully:'',
                error_updating_status:'',
                error_updating_educationalVideo:''
            }

        case EMPTY_EDUCATIONAL_VIDEO_ITEMS:
            return{
                ...state,
                item : {},
                items:[],
            }

        case PUBLISH_EDUCATIONAL_VIDEO:
            return {
                ...state,
                item:action.payload,
                educationalVideo_added_successfully:true

            }
            case ERROR_ADDING_EDUCATIONAL_VIDEO:
            return{
                ...state,
                error_adding_educationalVideo:action.payload,
                educationalVideo_added_successfully:false
            }

            case GET_EDUCATIONAL_VIDEO_DATA:
                return{
                    ...state,
                    item:action.payload,
                    error_loading_educationalVideo:'',
                    loading:false
                }
            case ERROR_LOADING_EDUCATIONAL_VIDEO:
                return{
                    ...state,
                    error_loading_educationalVideo:action.payload,
                    loading:false
                }
            case LOAD_EDUCATIONAL_VIDEO:
                return{
                    ...state,
                    loading:true
                }
            case UPDATE_EDUCATIONAL_VIDEO:
                return{
                    ...state,
                    item:action.payload,
                    educationalVideo_updated_successfully:true
                }
            case ERROR_UPDATING_EDUCATIONAL_VIDEO:
                return{
                    ...state,
                    error_updating_educationalVideo: action.payload,
                    loading: false
                }
            case DELETE_EDUCATIONAL_VIDEO:
                return{
                    ...state,
                    educationalVideo_deleted_successfully:true,
                    item:action.payload
                }

            case ERROR_DELETING_EDUCATIONAL_VIDEO:
                return {
                    ...state,
                    educationalVideo_deleted_successfully:false,
                    error_deleting_educationalVideo:action.payload
                }
            case SETTING_EDUCATIONAL_VIDEO_STATUS:
                return {
                    ...state,
                    status_updated_successfully:true,
                    item:action.payload,
                }
            case ERROR_SETTING_EDUCATIONAL_VIDEO_STATUS:
                return {
                    ...state,
                    status_updated_successfully:false,
                    error_updating_status:action.payload
                }
         default:
             return state

    }
}