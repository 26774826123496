import {
    ERROR_GET_PAYMENT_PACKAGE,
    GET_PAYMENT_PACKAGE,
    LOAD_GET_PAYMENT_PACKAGE,
    EMPTY_PAYMENT_PACKAGE,
    CLEAR_GET_PAYMENT_PACKAGE,
    ERROR_UPDATE_PAYMENT_PACKAGE,
    UPDATE_PAYMENT_PACKAGE
} from './Types'
import axios, { post } from 'axios'
export const fetchAllPaymentCountries = () => (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().currentUser.getIdToken().then((token) => {
        dispatch({
            type: LOAD_GET_PAYMENT_PACKAGE,
            payload: 'loading'
        })
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': token
            }
        }
        axios.get('/PaymentCountriesPayingMethod/getAllPaymentCountriesPayingMethod', config)
            .then((res) => {
                console.log(res);

                dispatch({
                    type: GET_PAYMENT_PACKAGE,
                    payload: res.data
                })
            }
            ).catch((Error) => {
                dispatch({
                    type: ERROR_GET_PAYMENT_PACKAGE,
                    payload: Error.message
                })
            })
    })
}
export const clearUpdateEvent = () => dispatch => {
    dispatch({
        type: CLEAR_GET_PAYMENT_PACKAGE,

    })
}
export const updatePaymentCountries = (id, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice) => (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().currentUser.getIdToken().then((token) => {
        // dispatch({
        //     type: LOAD_GET_PAYMENT_PACKAGE,
        //     payload: 'loading'
        // })
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': token
            }
        }
        const formData = new FormData();
        formData.append('id', id);
        formData.append('weeklyDiscountPrice', weeklyDiscountPrice);
        formData.append('monthlyDiscountPrice', monthlyDiscountPrice);
        formData.append('yearlyDiscountPrice', yearlyDiscountPrice);
        axios.post('/PaymentCountriesPayingMethod/updatePaymentCountriesPayingMethod', formData, config)
            .then((res) => {
                console.log(res);

                dispatch({
                    type: UPDATE_PAYMENT_PACKAGE,
                    payload: res.data
                })
            }
            ).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_PAYMENT_PACKAGE,
                    payload: Error.message
                })
            })
    })
}