import {
    LOAD_RANDOM_NUMBER,
    GET_RANDOM_NUMBER,
    ERROR_GET_RANDOM_NUMBER,
    EMPTY_RANDOM_NUMBER,
    EMPTY_RANDOM_NUMBER_ITEMS, GET_RANDOM_NUMBER_LINK_ID,GET_RANDOM_NUMBER_NEW,FETCH_ALL_QrTemplates,ERROR_LOADING_ALL_QrTemplates,CARD_FILE_UPLOADED

} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},
    download_link: '',
    qr_generation_id: '',
    loading: '',
    get_secure_random_number_successfully: '',
    error_get_secure_random_number: '',
    uploadedCardImageMsg:'',
}

export default function (state = initialState, action) {
    switch (action.type) {

        case LOAD_RANDOM_NUMBER:
            return {
                ...state,
                qr_generation_id: '',
                loading: true
            }

        case GET_RANDOM_NUMBER:
            return {
                ...state,
                download_link: action.payload,
                qr_generation_id: '',
                get_secure_random_number_successfully: true,
                error_get_secure_random_number: false,
                loading: false
            }

        case ERROR_GET_RANDOM_NUMBER:
            return {
                ...state,
                error_get_secure_random_number: action.payload,
                get_secure_random_number_successfully: false,
                loading: false
            }


        case EMPTY_RANDOM_NUMBER:
            return {
                ...state,
                get_secure_random_number_successfully: '',
                error_get_secure_random_number: '',
                loading: false,
                stopShowLoad:''
            }
        case EMPTY_RANDOM_NUMBER_ITEMS:
            return {
                ...state,
                items: {},
                download_link: '',
                get_secure_random_number_successfully: '',
                error_get_secure_random_number: '',
                loading: false
            }

        case GET_RANDOM_NUMBER_LINK_ID:
            return {
                ...state,
                items: {},
                download_link: '',
                qr_generation_id: action.payload,
                get_secure_random_number_successfully: '',
                error_get_secure_random_number: '',
                loading: true,
                fileLocally:'',
                stopShowLoad:'true'
            }
        case GET_RANDOM_NUMBER_NEW:
            return {
                ...state,
                items: {},
                download_link: '',
                qr_generation_id: action.payload,
                get_secure_random_number_successfully: true,
                error_get_secure_random_number: '',
                loading: true,
                fileLocally:true
            } 
        case FETCH_ALL_QrTemplates:
            return {
                ...state,
                items: action.payload,
                error_all_loading_QrTemplates: '',
                getting_allQrTemplates_sucessfully: true,
                loading: false,
                item: {}
            }
        case ERROR_LOADING_ALL_QrTemplates:
            return {
                ...state,
                error_all_loading_QrTemplates: action.payload,
                loading: false
            }      
               
        case CARD_FILE_UPLOADED:
        return {
            ...state,
            uploadedCardImageMsg: action.payload,
            loading: false
        }
            
        default:
            return state

    }

}
