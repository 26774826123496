import { LOAD_KIDS_REPORT, GET_KIDS_REPORT, ERROR_LOADING_KIDS_REPORT,EMPTY_REPORT,
    LOAD_KID_USAGE_REPORT,GET_KID_USAGE_REPORT,ERROR_LOADING_KID_USAGE_REPORT, LOAD_USAGE_COUNTRY_FILTER, GET_USAGE_COUNTRY_FILTER, ERROR_LOADING_COUNTRY_FILTER, LOAD_USAGE_GENDER_FILTER, GET_USAGE_GENDER_FILTER, ERROR_LOADING_GENDER_FILTER, LOAD_USAGE_AGE_FILTER, GET_USAGE_AGE_FILTER, ERROR_LOADING_AGE_FILTER} from "../Actions/Types";


const initial_state = 
{
    item:{},
    items:[],
    loading :'',
    error_loading_kids_report:'',
    error_loading_kid_usage:"",
    loading_country_filter:'',
    countryFilter:{},
    genderFilter: [],
    loading_gender_filter:'',
    error_loading_gender_filter:'',
    loading_age_filter:'',
    error_loading_age_filter:'',
    ageFilter:{}
}

export default function (state=initial_state,action)
{
    switch(action.type)
    {
        case LOAD_KIDS_REPORT:
            return {
                ...state,
                loading:true,
            }
        case LOAD_KID_USAGE_REPORT:
            return {
                ...state,
                loading:true
            }
        case GET_KID_USAGE_REPORT:
            return {
                ...state,
                error_loading_kid_usage:'',
                loading:false,
                item:action.payload
            }
        case ERROR_LOADING_KID_USAGE_REPORT:
            return {
                ...state,
                error_loading_kid_usage:action.payload,
                loading:false
            }
        case GET_KIDS_REPORT:
            return {
                ...state,
                items:action.payload,
                loading:false,
                error_loading_kids_report:''
            }
        case ERROR_LOADING_KIDS_REPORT:
            return {
                ...state,
                loading:false,
                error_loading_kids_report:action.payload
            }

        case EMPTY_REPORT:
            return {
                ...state,
                items:[],
                item:{},
                countryFilter:{},
                genderFilter:[],
                ageFilter:{}
                
            }

        case LOAD_USAGE_COUNTRY_FILTER:
            return {
                ...state,
                loading_country_filter:true
            }
        case GET_USAGE_COUNTRY_FILTER:
            return {
                ...state,
                loading_country_filter:false,
                countryFilter:action.payload,
                error_loading_country_filter:''

            }
        case ERROR_LOADING_COUNTRY_FILTER:
            return {
                ...state,
                loading_country_filter:false,
                error_loading_country_filter:action.payload,

            }


        case LOAD_USAGE_GENDER_FILTER:
            return{
                ...state,
                loading_gender_filter:true,

            }
        case GET_USAGE_GENDER_FILTER:
            return {
                ...state,
                loading_gender_filter:false,
                genderFilter:action.payload,
                error_loading_gender_filter:''
            }

        case ERROR_LOADING_GENDER_FILTER:
            return {
                ...state,
                loading_gender_filter:false,
                error_loading_gender_filter:action.payload
            }






            case LOAD_USAGE_AGE_FILTER:
                return{
                    ...state,
                    loading_age_filter:true,
    
                }
            case GET_USAGE_AGE_FILTER:
                return {
                    ...state,
                    loading_age_filter:false,
                    ageFilter:action.payload,
                    error_loading_age_filter:''
                }
    
            case ERROR_LOADING_AGE_FILTER:
                return {
                    ...state,
                    loading_age_filter:false,
                    error_loading_age_filter:action.payload
                }
        default:
            return state;
    }
}