import React, { Component } from 'react';
import { Snackbar } from '@material-ui/core';

import { Redirect } from 'react-router-dom';
import { Input, Button, Icon, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import branch from 'branch-sdk'

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';
import firebase from 'firebase/app'

/* Components */
import Footer from '../Layout/Footer';
import NavBar from '../Layout/NavBar';
import Loader from '../Layout/Loader';

/** Assets */
import mainLogoM from '../../../images/portal/logo-big.png';
import '../../../css/fontawesome.min.css';
import masterCard from '../../../images/portal/visa-mastercard-icon.svg';
import fawryCardIcon from '../../../images/portal/fawry-logo.png';
import visa from '../../../images/portal/current-visa.png';
import visaBackground from '../../../images/portal/visa-bg.png';
import fawryBackground from '../../../images/portal/fawry-bg.png';
import fawry from '../../../images/portal/fawry-logo.png';
import prepaidIcon from '../../../images/portal/prepaid.png';
import mada from '../../../images/portal/mada.png';
import stc from '../../../images/portal/stc.png';
import Stripe from '../../../images/portal/current-visa.png';
import Fawry from '../../../images/portal/fawry-logo.png';
import Pre_Paid_Card from '../../../images/portal/prepaid.png';
import MADA from '../../../images/portal/mada.png';
import STC_PAY from '../../../images/portal/stc.png';
import FIT_STC from '../../../images/portal/stc.png';
import JEEL_VODAFONE from '../../../images/tpay/vodafone.png'
import JEEL_WE from '../../../images/tpay/we.jpg'
import JEEL_ORANGE from '../../../images/tpay/orange.png'
import JEEL_ETISALAT from '../../../images/tpay/etisalat.png'
import FIT_MOBILY from '../../../images/fit/mobily.jpg'
import FIT_ZAIN from '../../../images/fit/zain.jpg'

/** Actions */
import { getUserPaymentInfo, emptyMobileUserProfileWithItem } from '../../../Actions/MobilePaymentProfileAction';
import { clearPaymentData, fetchAllPaymentPackage } from '../../../Actions/MobilePaymentActions';
import {
  addCodeToRedux, vertifyCode, saveCode, clearPromoCodeAll,
  emptyPromoCode, emptyCodeFromRedux
} from '../../../Actions/PromoCodesActions';
import { addPaymentDataWithCode } from '../../../Actions/MobilePaymentActions'
import { getFawryPrices } from '../../../Actions/FawryPaymentAction'
import { addTokenToredux, emptyToken } from '../../../Actions/loginActions.js'
import { emptyPaymentPrice, getPackagePriceBycountry } from '../../../Actions/MobilePaymentPriceAction';
import { signOut, empty } from '../../../Actions/AuthActions';
import { removeSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { getCurrencyRate } from '../../../Actions/CurrencyActions';
import { addSuccessMessage } from '../../../Actions/SuccessMessagesActions';

/* Routes */
import {
  PORTAL_LAND_PAGE, PORTAL_SUBSCRIPTION, PORTAL_CREDIT_CARD, PORTAL_FAWRY,
  PORTAL_PRE_PAID, PORTAL_PAYMENT_METHODS
} from '../../../routes';

import * as CONSTANTS from '../../../constants';

const INITIAL_STATE = {
  discountAmount: '',
  endDate: '',
  discountType: '',
  type: 'بخصم',
  codeList: [],
  codeNote: '',
  creditForm: false,
  fawryForm: false,
  date: '',
  codeNameFromRedux: '',
  checkVerified: false,
  inactive: true,
  apisCalls: 0,
  fromVerify: false,
  verified: false,
  color: '#6dd400',

  openSnakBar: false,
  vertical: 'bottom',
  horizontal: 'right',

  loggedOut: false,
  paymentOptions: 'none',
  option1: false,
  option2: true,
  currencyRateCheck: 0,
  displayPaymentCards: 'inline-block',
  prePaid: false,
  token: '',
  disabled: true,
  renewal: 0,
  codeName: '',
  country: '',
}

const TELECOM_COMPANIES = [
  CONSTANTS.DB_FIT_STC, CONSTANTS.DB_FIT_MOBILY, CONSTANTS.DB_FIT_ZAIN,
  CONSTANTS.DB_TPAY_VODAFONE, CONSTANTS.DB_TPAY_ETISALAT, CONSTANTS.DB_TPAY_ORANGE,
  CONSTANTS.DB_TPAY_WE
];

function FormatDate(date) {
  if (date == null) {
    return '';
  }
  date = new Date(date);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  //January is 0!
  var yyyy = date.getFullYear();
  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  date = yyyy + '/' + mm + '/' + dd;
  return date;
}

function typePackageOf(key) {
  var listOfTypes = [];
  listOfTypes['Yearly'] = 'سنوياً*';
  listOfTypes['Monthly'] = 'شهرياً';

  return listOfTypes[key];
}

function getOfferPrice(packageType, fawryItem, paymentPackage) {
  if (packageType === 'Yearly') {
    return fawryItem.yearDiscountPrice;
  } else {
    return fawryItem.monthDiscountPrice;
  }
}

function getOfferPriceStripe(packageType, mobilePaymentPackage) {
  if (packageType === 'Yearly' && mobilePaymentPackage.yearDiscountPriceConverted !== undefined) {
    return mobilePaymentPackage.yearDiscountPriceConverted.convertedPrice;
  } else if (packageType === 'Monthly' && mobilePaymentPackage.monthDiscountPriceConverted !== undefined) {
    return mobilePaymentPackage.monthDiscountPriceConverted.convertedPrice;
  }
}

function getOriginalPriceStripe(packageType, mobilePaymentPackage) {
  if (packageType === 'Yearly' && mobilePaymentPackage.yearOriginalPriceConverted !== undefined) {
    return mobilePaymentPackage.yearOriginalPriceConverted.convertedPrice;
  } else if (packageType === 'Monthly' && mobilePaymentPackage.monthOriginalPriceConverted !== undefined) {
    return mobilePaymentPackage.monthOriginalPriceConverted.convertedPrice;
  }
}

function getCountryCurrency(mobilePaymentPackage) {
  if (mobilePaymentPackage.monthOriginalPriceConverted !== undefined) {
    return mobilePaymentPackage.monthOriginalPriceConverted.currency;
  }
}

function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}

function calcExpirationDate(today, type, expirationDate) {
  let calcExpirationDate = expirationDateFormat(today, type, expirationDate);
  return calcExpirationDate;
}

function expirationDateFormat(today, type, expirationDate) {

  //console.log("expirationDate = " + expirationDate)
  var expiDate;
  var dd;
  var mm;
  var yyyy;

  if (expirationDate == null || expirationDate == undefined ||
    expirationDate == 'null') {

    expiDate = new Date();
    //console.log("today = " + expiDate)

  }
  else {
    let arrayOfDate = expirationDate.split('-');

    dd = arrayOfDate[0]

    mm = arrayOfDate[1];

    yyyy = arrayOfDate[2];


    var formatedEpxDate = mm + "/" + dd + "/" + yyyy;
    var oldDate = new Date(formatedEpxDate);

    if (today > oldDate) {
      expiDate = new Date();
    }
    else {
      // Subtract 1 from month to convert it to system ( January is 0!)
      // console.log("expirationDate = " + expirationDate)
      expiDate = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0);
      // console.log("new expiDate = " + expiDate)
    }
  }

  if (type == 'Monthly') {

    expiDate = addMonths(expiDate, 1);
    //console.log("monthExpiDate = " + expiDate)
  }
  else if (type == 'Yearly') {

    expiDate = addMonths(expiDate, 12);
    //console.log("yearExpiDate = " + expiDate)

  }
  // add 1 to month to convert it to general ( January is 1!)
  var tempMonth = expiDate.getMonth();
  var tempMonth = tempMonth + 1;


  var date = expiDate.getDate() + '-' + tempMonth + '-' + expiDate.getFullYear();
  return date;
}

function getColorOf(paymentPackage) {
  if (paymentPackage === 'Yearly') {
    return '#FFF';
  } else {
    return '#ffad4d';
  }
}

function getBackgroudColorOf(paymentPackage) {
  if (paymentPackage === 'Yearly') {
    return '#ffad4d';
  } else {
    return '#FFF';
  }
}

class CustomerPaymentMethods extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.props.emptyToken();
    this.props.clearPaymentData();
    this.props.clearPromoCodeAll();
    this.props.emptyPaymentPrice();
    this.props.emptyMobileUserProfileWithItem();
    if (firebase.auth().currentUser) {
     
      firebase.auth().currentUser.getIdToken().then((token) => {
        
        setTimeout(() => {
          this.setState({ token: token });
          this.props.addTokenToredux(token);
        }, 10)
      })

    } else {
      console.log("no");

      this.setState({ loggedOut: true })
    }

    this.props.history.push(PORTAL_PAYMENT_METHODS);

  }

  INITIATE_PURCHASE = () => {
    var event_and_custom_data = {};

    var content_items = [];

    var customer_event_alias = "";

    branch.logEvent(
      "INITIATE_PURCHASE",//"PURCHASE",
      event_and_custom_data,
      content_items,
      customer_event_alias,
      function (err) { console.log(err); }
    );
  }

  componentDidMount() {
    import('../../../css/portal-style.css').then(() => {
    });
    window.scrollTo(0, 0);
    const { auth } = this.props;
    if (!auth.uid) {
      this.setState({ loggedOut: true });
    } else {
      this.props.getUserPaymentInfo();
      this.props.fetchAllPaymentPackage();
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    if (prevState.renewal !== props.userPaymentProfile.paymentAutoRenewal) {
      let renewal = props.userPaymentProfile.paymentAutoRenewal;
      if (renewal === 1 &&
        (props.userPaymentProfile.currentPackagePaymentId !== null || props.userPaymentProfile.paymentHistory.length > 0)) {
        return {
          paymentOptions: 'inline-block',
          renewal: props.userPaymentProfile.paymentAutoRenewal,
        };
      }
      return {
        renewal: props.userPaymentProfile.paymentAutoRenewal,
      };
    }
    if (props.codeName !== '' && props.codeName !== null && props.codeName !== prevState.codeNameFromRedux) {
      props.vertifyCode(props.codeName);
      return {
        codeNameFromRedux: props.codeName,
        codeName: props.codeName,
        codeNote: props.codeNote,
        inactive: false,
        verified: true
      };
    }
  }

  callAPIs() {
    const { userPaymentProfile, token } = this.props;
    if (userPaymentProfile !== undefined && userPaymentProfile.country !== undefined &&
      token !== undefined && token !== '' && this.state.apisCalls === 0) {
      this.props.getFawryPrices(this.props.userPaymentProfile.country, "FAWRY", token);
      this.props.getPackagePriceBycountry(this.props.userPaymentProfile.country, token);
      this.setState({ apisCalls: 1 });
    }
  }

  callGetCountryCurrency(currency) {
    this.props.getCurrencyRate(currency);
    this.setState({ currencyRateCheck: 1 })
  }

  handleCodeChange = (e) => {
    const value = e.target.value;
    this.setState({ codeName: value });
    setTimeout(() => {
      if (this.state.codeName !== '' && this.state.codeName !== undefined &&
        this.state.codeName !== null) {
        this.setState({ inactive: false });
      } else {
        this.setState({ inactive: true });
      }
    });
  }

  handleCreditCardSubmit = (index, e) => {
    this.setState({ creditForm: true });
  }

  handleFawrySubmit = (index, e) => {
    this.setState({ fawryForm: true });
  }

  handleVerify = (e) => {
    this.setState({ verified: true });
    this.props.vertifyCode(this.state.codeName);
    e.preventDefault();
  }

  typeOf(key) {
    var listOfTypes = [];
    listOfTypes[CONSTANTS.DB_APPLE] = CONSTANTS.APPLE;
    listOfTypes[CONSTANTS.DB_STRIPE] = CONSTANTS.STRIPE;
    listOfTypes[CONSTANTS.DB_FAWRY] = CONSTANTS.FAWRY;
    listOfTypes[CONSTANTS.DB_PRE_PAID_CARD] = CONSTANTS.PRE_PAID_CARD;
    listOfTypes[CONSTANTS.DB_MADA] = CONSTANTS.MADA;
    listOfTypes[CONSTANTS.DB_STC_PAY] = CONSTANTS.PORTAL_STC_PAY;
    listOfTypes[CONSTANTS.DB_FIT_MOBILY] = CONSTANTS.PORTAL_FIT_MOBILY;
    listOfTypes[CONSTANTS.DB_FIT_ZAIN] = CONSTANTS.PORTAL_FIT_ZAIN
    listOfTypes[CONSTANTS.DB_FIT_STC] = CONSTANTS.PORTAL_FIT_STC;
    listOfTypes[CONSTANTS.DB_TPAY_WE] = CONSTANTS.PORTAL_TPAY_WE
    listOfTypes[CONSTANTS.DB_TPAY_ORANGE] = CONSTANTS.PORTAL_TPAY_ORANGE;
    listOfTypes[CONSTANTS.DB_TPAY_VODAFONE] = CONSTANTS.PORTAL_TPAY_VODAFONE;
    listOfTypes[CONSTANTS.DB_TPAY_ETISALAT] = CONSTANTS.PORTAL_TPAY_ETISALAT;

    if (key === null) {
      return listOfTypes[CONSTANTS.DB_STRIPE];
    }
    return listOfTypes[key];
  }

  handlePay = (cash, paymentType, expirationDate, mobilePaymentPackage, e) => {
    this.setState({ creditForm: true });
    this.props.addPaymentDataWithCode(cash, paymentType, expirationDate, false, this.state.codeNameFromRedux, mobilePaymentPackage);
  }

  handleFawryPay = (cash, paymentType, expirationDate, mobilePaymentPackage, e) => {
    this.setState({ fawryForm: true });
    this.props.addPaymentDataWithCode(cash, paymentType, expirationDate, false, this.state.codeNameFromRedux, mobilePaymentPackage);
  }

  handleClose = (e) => {
    this.setState({ openSnakBar: false })
  }

  handleOption = (e) => {
    const { name, val } = e.target;
    if (name === 'option1') {
      this.setState({ option2: false });
    } else {
      this.setState({ option1: false });
    }
    this.setState({ [e.target.name]: true });
  }

  redirectPrePaid = (e) => {
    this.setState({ prePaid: true });
  }

  submitCodeSaving = (e) => {
    let code = this.state.codeName;
    this.props.saveCode(code);
    this.setState({ codeNameFromRedux: code });
    e.preventDefault();
  }

  handleSuccessMessages = () => {
    this.props.removeSuccessMessage();
  }

  render() {
    let name = '';
    let codeList = [];
    let title = '';
    let paymentIcon = '';
    let packageType = '';
    let price = '';
    let type = '';
    let currentPackage = '';
    let subTitle = '';
    let creditCard = '';
    let fawryCard = '';
    let paymentCards = (<Loader />);
    let fawryPaymentPackage = '';
    let packagePriceInfo = '';
    const { sucess, error, loading } = this.props;
    const { paymentPackages, promocodeItem } = this.props;
    const { errorVerify, successVerify, loadingVerify } = this.props;
    const { vertical, horizontal } = this.state;
    const { customerFlag } = this.props;
    const { auth } = this.props;
    const { fawryPaymentPackageItem, fawryPaymentPackageLoading, fawryPaymentPackageSuccess,
      mobilePaymentPackage, mobilePaymentPackageLoading, mobilePaymentPackageSuccess,
      currencyRate } = this.props;
    const { newCodeSavedSuccessfully, loadingForSaving, errorSavingNewCode } = this.props;
    const [{ userPaymentProfile }, { renewal }] = [this.props, this.state];

    if (this.state.apisCalls === 0) {
      this.callAPIs();
    }

    if (!auth.uid || auth.uid === undefined || this.state.loggedOut === true) {
      this.props.empty();
      this.props.signOut();
      return (<Redirect to={PORTAL_LAND_PAGE}></Redirect>)
    }

    if (this.state.creditForm === true) {
      return (<Redirect to={PORTAL_CREDIT_CARD}> </Redirect>)
    }

    if (this.state.fawryForm === true) {
      return (<Redirect to={PORTAL_FAWRY}> </Redirect>)
    }

    if (this.state.prePaid === true) {
      return (<Redirect to={PORTAL_PRE_PAID}> </Redirect>)
    }

    let verifyButton = (
      <button name="vertifyCode" disabled={this.state.inactive} type="submit" onClick={this.handleVerify}>
        <Icon name='left arrow' />
      </button>
    )

    if (loading === true) {
      paymentCards = (<Loader />)
    }

    else if (loading === false && (error === '' || error === false)) {
      name = userPaymentProfile.name;
      price = (userPaymentProfile.currentPackagePaymentId !== undefined && userPaymentProfile.currentPackagePaymentId !== null) ? userPaymentProfile.currentPackagePaymentId.paymentPackageId.regularPrice : 0;
      type = (userPaymentProfile.currentPackagePaymentId !== undefined && userPaymentProfile.currentPackagePaymentId !== null) ? this.typeOf(userPaymentProfile.currentPackagePaymentId.type) : '';
      let currency = (userPaymentProfile.currentPackagePaymentId !== undefined && userPaymentProfile.currentPackagePaymentId !== null && userPaymentProfile.currentPackagePaymentId.type === 'Fawry') ? 'EGP' : '$';

      title = (
        <div className="payment-title h3 bold-tile">
          تُسعِدنا عودتُك!
        </div>
      )

      subTitle = 'اشترك الآن وتمتع بالتجربة الكاملة لتطبيق جيل.';
      let today = new Date();
      if (customerFlag.firstSignUp === true) {
        title = (
          <div>
            <div className="payment-title h3">
              هيَّا! فهناك الكثير لتكتشفه مع طفلك!
            </div>
          </div>
        )
        subTitle = 'اختَر وسيلة الدفع المناسبة لتبدأ رحلتكُما في عالم جيل!';
      }

      if (userPaymentProfile !== undefined && userPaymentProfile.currentPackagePaymentId !== null && userPaymentProfile.currentPackagePaymentId !== undefined) {

        if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType === CONSTANTS.SUBSCRIPTION_MONTHLY) {
          packageType = (
            <span className="type">
              شهرياً &nbsp;
            </span>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.paymentPackageId.packageType === CONSTANTS.SUBSCRIPTION_YEARLY) {
          packageType = (
            <span className="type">
              سنوياً &nbsp;
            </span>
          )
        }

        if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_FAWRY) {
          paymentIcon = (
            <div className="history-logo">
              <img src={fawry} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_PRE_PAID_CARD) {
          paymentIcon = (
            <div className="history-logo">
              <img src={prepaidIcon} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_STRIPE) {
          paymentIcon = (
            <div className="history-logo">
              <img src={visa} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_MADA) {
          paymentIcon = (
            <div className="history-logo">
              <img src={MADA} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_STC_PAY) {
          paymentIcon = (
            <div className="history-logo">
              <img src={STC_PAY} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_FIT_MOBILY) {
          paymentIcon = (
            <div className="history-logo">
              <img src={FIT_MOBILY} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_FIT_STC) {
          paymentIcon = (
            <div className="history-logo">
              <img src={FIT_STC} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_FIT_ZAIN) {
          paymentIcon = (
            <div className="history-logo">
              <img src={FIT_ZAIN} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_TPAY_VODAFONE) {
          paymentIcon = (
            <div className="history-logo">
              <img src={JEEL_VODAFONE} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_TPAY_WE) {
          paymentIcon = (
            <div className="history-logo">
              <img src={JEEL_WE} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_TPAY_ETISALAT) {
          paymentIcon = (
            <div className="history-logo">
              <img src={JEEL_ETISALAT} alt="" />
            </div>
          )
        } else if (userPaymentProfile.currentPackagePaymentId.type === CONSTANTS.DB_TPAY_ORANGE) {
          paymentIcon = (
            <div className="history-logo">
              <img src={JEEL_ORANGE} alt="" />
            </div>
          )
        } else {
          paymentIcon = '';
        }


        if (!TELECOM_COMPANIES.includes(userPaymentProfile.currentPackagePaymentId.type)) {
          packagePriceInfo = (
            <div className="current-content">
              <div className="cost d-flex">
                {/** sit dynamic currency not constant ($), and switch to EGP in case of FAWRY **/}
                <div className="currency" style={{ fontSize: '12px' }}>{currency}</div>
                <div className="price" style={{ fontSize: '17px' }}>{price}</div>
                <div className="per" style={{ fontSize: '17px' }}>{packageType}</div>
              </div>

              <div className="current-footer" style={{ float: 'right', textAligin: 'right' }}>
                تاريخ التجديد {userPaymentProfile.currentPackagePaymentId.expirationDatetime}
              </div>
              <br />
            </div>
          )
        }

        currentPackage = (
          <span>
            <div className="current-card">
              <div className="card-title" style={{ textAlign: 'right', fontSize: '15px' }}>
                نُذكِّرك بتفاصيل اشتراكك الحالي في تطبيق جيل:
              </div>
              <br />
              <div className="current-body">
                <div className="current-title" style={{ paddingBottom: '10px' }}>
                  <div className="title" style={{ textAligin: 'right' }}>عن طريق {type}</div>
                  <div className="current-logo">
                    {paymentIcon}
                  </div>
                </div>
                {packagePriceInfo}
              </div>
            </div>
            <br />
          </span>
        )
        title = (
          <div>
            <div className="row">
              <div className="col-12">
                <div className="payment-title h3 bold-tile">
                  أنت عضو في عائلة جيل!
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {currentPackage}
              </div>
            </div>
          </div>
        )
        subTitle = 'اختر وسيلة الدفع المناسبة مع رمز الخصم لتجديد باقتك أو تمديد اشتراكك.';
      }
    }


    if (errorVerify !== '' && errorVerify !== false) {
      this.setState({ codeNote: errorVerify, color: 'red' });
      this.props.emptyPromoCode();
      this.props.emptyCodeFromRedux();
    }

    else if (loadingVerify === true) {
      verifyButton = (<button
        className="btn btn-block ui loading secondary button">
      </button>)
    }

    if (successVerify === true && this.state.verified === true &&
      (promocodeItem !== '' && promocodeItem !== null && promocodeItem !== undefined) &&
      mobilePaymentPackage !== undefined && mobilePaymentPackage.yearOriginalPriceConverted !== undefined) {
      var discount = promocodeItem.discountAmount;
      var extra_text = '';
      this.setState({ disabled: false });
      this.props.emptyPromoCode();
      var symbol = '%'; var dis_type = 'خصم ';
      if (promocodeItem.discountType.type === 'FIXED') {
        symbol = mobilePaymentPackage.yearOriginalPriceConverted.currency;
        discount = discount * currencyRate;
        discount = discount.toFixed(2);
        extra_text = ' (ما يعادل ' + promocodeItem.discountAmount + '$)';
        console.log(" dd " + currencyRate)
      } else if (promocodeItem.discountType.type === 'EXTRA_TIME') {
        symbol = 'أسابيع'; dis_type = 'زيادة ';
      }
      var text = 'رمز الخصم صحيح، ويعطيك ' + dis_type + discount + symbol + extra_text;

      if (promocodeItem.endDate !== null) {
        text += ' حتى ' + FormatDate(promocodeItem.endDate);
      }

      this.setState({ codeNameFromRedux: this.state.codeName, codeNote: text, color: '#6dd400' });
      this.props.addCodeToRedux(this.state.codeName, text);
    }

    let prePaid = (
      <div className="row">
        <div className="col-12">
          <div className="prepaid-card">
            <a className="prepaid-btn"
              style={{ fontSize: '16px', paddingBottom: '100px' }}
              onClick={this.redirectPrePaid} >استخدم بطاقة جيل مُسبَقة الدفع.</a>
          </div>
        </div>
      </div>
    )

    var today = new Date();
    let expirationDate = null;
    if (userPaymentProfile.currentPackagePaymentId !== null && userPaymentProfile.currentPackagePaymentId !== undefined) {
      expirationDate = userPaymentProfile.currentPackagePaymentId.expirationDatetime;
    }
    let visaPaymentPackage = (
      <div style={{ top: '50%', left: '50%', position: 'absolute' }}>
        <Loader />
      </div>
    );
    if (mobilePaymentPackageLoading === true) {
      visaPaymentPackage = (
        <div style={{ top: '50%', left: '50%', position: 'absolute' }}>
          <Loader />
        </div>
      )
    }
    if (mobilePaymentPackageSuccess === true) {
      visaPaymentPackage = paymentPackages.slice(0).reverse().map((paymentPackage, i) => {
        if (mobilePaymentPackage !== undefined && paymentPackage.packageType === CONSTANTS.SUBSCRIPTION_YEARLY || paymentPackage.packageType === CONSTANTS.SUBSCRIPTION_MONTHLY) {
          if (this.state.currencyRateCheck === 0 && mobilePaymentPackage.yearOriginalPriceConverted !== undefined) {
            this.callGetCountryCurrency(mobilePaymentPackage.yearOriginalPriceConverted.currency);
          }
          return (
            <div className="col-6 col-md-6">
              <form className="per-year">
                <div className="form-group">
                  <button className="btn payment-submit form-control" type="submit" index={i}
                    style={{ color: `${getColorOf(paymentPackage.packageType)}`, backgroundColor: `${getBackgroudColorOf(paymentPackage.packageType)}` }}
                    onClick={this.handlePay.bind(this, getOfferPriceStripe(paymentPackage.packageType, mobilePaymentPackage),
                      paymentPackage.packageType,
                      calcExpirationDate(today, paymentPackage.packageType, expirationDate), mobilePaymentPackage)}
                  >
                    <div className="cost d-flex justify-content-center">
                      <div className="currency">{getCountryCurrency(mobilePaymentPackage)}</div>
                      <div className="price">{getOfferPriceStripe(paymentPackage.packageType, mobilePaymentPackage)}</div>
                      <div className={`per ${paymentPackage.packageType}`} >
                        {typePackageOf(paymentPackage.packageType)}
                      </div>
                    </div>
                    <div className="instead-of d-flex justify-content-center">
                      <div className="instead">
                        بدلا من
                      </div>
                      <div className="instead-price d-flex justify-content-center align-items-center">
                        <div className="currency">{getCountryCurrency(mobilePaymentPackage)}</div>
                        <div className="price">{getOriginalPriceStripe(paymentPackage.packageType, mobilePaymentPackage)}</div>
                      </div>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          )
        }

      });
    }

    creditCard = (
      <div className="col-12 col-md-6" style={{ marginTop: '10px' }}>
        <div className="payment-card visa" style={{ backgroundImage: `url(${visaBackground})` }}>
          <div className="title-content d-flex justify-content-between" style={{ padding: '10px' }}>
            <div className="title h5" style={{ color: 'white' }}>بطاقة الدفع</div>
            <div className="payment-logo">
              <img src={masterCard} alt="Visa" />
            </div>
          </div>
          <div className="payment-description">اكتب بيانات البطاقة وادفع في الحال لتستمتع بعالم جيل.</div>
          <br /><br /><br /><br />
          <div className="payment-forms">
            <div className="row">
              {visaPaymentPackage}
              <p style={{ paddingRight: '20px', fontSize: '12px', marginRight: '15px' }}> * الإشتراك السنوي يوفر قيمة اشتراك 3 شهور</p>
            </div>
          </div>
        </div>
      </div>
    )

    if (userPaymentProfile.country === 'Egypt') {
      if (fawryPaymentPackageLoading === true) {
        fawryPaymentPackage = (
          <div style={{ top: '50%', left: '50%', position: 'absolute' }}>
            <Loader />
          </div>
        );
      }
      if (fawryPaymentPackageSuccess === true) {
        fawryPaymentPackage = paymentPackages.slice(0).reverse().map((paymentPackage, i) => {
          if (paymentPackage.packageType === 'Yearly' || paymentPackage.packageType === 'Monthly') {
            return (
              <div className="col-6 col-md-6">
                <form className="per-year" action="">
                  <div className="form-group">
                    <button className="btn payment-submit form-control" type="submit" index={i}
                      style={{ color: `${getColorOf(paymentPackage.packageType)}`, backgroundColor: `${getBackgroudColorOf(paymentPackage.packageType)}` }}
                      onClick={this.handleFawryPay.bind(this, getOfferPrice(paymentPackage.packageType, fawryPaymentPackageItem, paymentPackage),
                        paymentPackage.packageType,
                        calcExpirationDate(today, paymentPackage.packageType, expirationDate), mobilePaymentPackage)}>
                      <div className="cost d-flex justify-content-center">
                        <div className="currency">EGP</div>
                        <div className="price">{getOfferPrice(paymentPackage.packageType, fawryPaymentPackageItem, paymentPackage)}</div>
                        <div className={`per ${paymentPackage.packageType}`}>
                          {typePackageOf(paymentPackage.packageType)}
                        </div>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
            )
          }
        });
      }
      fawryCard = (
        <div className="col-12 col-md-6" style={{ marginTop: '10px' }}>
          <div className="payment-card visa" style={{ backgroundImage: `url(${fawryBackground})` }}>

            <div className="title-content d-flex justify-content-between" style={{ padding: '10px' }}>
              <div className="title h5" style={{ color: 'white' }}>فوري</div>
              <div className="payment-logo">
                <img style={{ marginTop: '-8px' }} src={fawryCardIcon} alt="Fawry" />
              </div>
            </div>
            <div className="payment-description">سنُرسِل لك رمزًا خاصًا على بريدك الإلكتروني أو هاتفك، وكل ما كل ما عليك فعله هو التوجه إلى أقرب منفذٍ يقدم خدمة فوري وإخباره بذلك الرَّمز ثم دفع قيمة الاشتراك.</div>
            <br /><br />
            <br /><br />
            <div className="payment-forms">
              <div className="form-row">
                {fawryPaymentPackage}
                <p style={{ paddingRight: '20px', fontSize: '12px', marginRight: '15px' }}>* الإشتراك السنوي يوفر قيمة اشتراك 3 شهور </p>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (sucess === true && userPaymentProfile !== undefined && userPaymentProfile !== '') {
      paymentCards = (
        <div>
          {/*  Title */}
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="choose-payment">
                <p style={{ float: 'right', fontWeight: 'bold', textAlign: 'right' }}> {subTitle} </p>
                <br /> <br />
              </div>
            </div>
          </div>
          {/*  End Title */}
          {/*  Payment Cards Start */}
          <div className="row">
            {/* Credit Card */}
            {creditCard}
            {/* Credit Card End */}
            {/* Card End */}
            {/* Fawry Card */}
            {fawryCard}
          </div>
          {/* Fawry Card End */}
          {/** Payment Cards End **/}
        </div>
      )
    }

    if (this.state.option1 === true) {
      paymentCards = (
        <>
          <br />
          <button className="col-12 col-lg-4 col-md-4 pay-input btn form-control" type="submit"
            style={{ float: 'right' }}
            disabled={this.state.disabled}
            onClick={this.submitCodeSaving} >
            تأكيد حفظ الكود
          </button>
          <br /><br />
        </>
      );
    }

    if (loadingForSaving === true) {
      paymentCards = (
        <>
          <br />
          <button style={{ float: 'right', height: '50px' }}
            className="col-12 col-lg-4 col-md-4 btn btn-block ui loading secondary button">
          </button>
          <br /><br />
        </>
      );
    }
    if (newCodeSavedSuccessfully === true) {
      this.setState({ color: '#6dd400' });
      this.props.addSuccessMessage("مُبارك! أضفتَ رمز خصمٍ بنجاح، وفعَّلناه على التجديد القادم. رائع");
      return (<Redirect to={PORTAL_SUBSCRIPTION}> </Redirect>)
    }
    if (newCodeSavedSuccessfully === false && errorSavingNewCode !== '' && errorSavingNewCode !== undefined) {
      this.setState({ codeNote: errorSavingNewCode, color: 'red' });
      this.props.emptyPromoCode();
      this.props.emptyCodeFromRedux();
    }

    const { successMessage } = this.props;

    return (
      <body>

        <Snackbar
          autoHideDuration={6000} onClose={this.handleSuccessMessages}
          anchorOrigin={{ vertical, horizontal }}
          open={successMessage.show}
          onClose={this.handleSuccessMessages}
          message={successMessage.message}
          key={vertical + horizontal}
        />

        {/** Logo **/}
        <div className="logo-container">
          <div className="container">
            <div className="logo">
              <img src={mainLogoM} alt="logo" />
            </div>
          </div>
        </div>
        {/** End Logo */}
        {/* Redirect */}
        <section className="redirect-content">
          <div className="container">
            <div className="redirect-btn">
              <Link to={PORTAL_SUBSCRIPTION}>
                <Icon name='right arrow' />
                &nbsp;
                <span className="text"> الذهاب للملف الشخصي </span>
              </Link>
            </div>
          </div>
        </section>
        {/* end Redirect*/}

        {/* promo code section */}
        <section className="payment-container">
          <div className="container">
            <div className="payment-content">
              <div className="row">
                <div className="col-12 col-md-6">
                  {title}
                  <form className="check-code" action="">
                    <div className="form-group custom-group">
                      <div className="input-container">
                        <input type="text" style={{ direction: 'rtl', paddingLeft: '15px', paddingRight: '15px' }} placeholder='رمز الخصم' name="codeNameFromRedux" id="codeNameFromRedux"
                          value={this.state.codeName}
                          onChange={this.handleCodeChange}
                        />
                        <br />

                      </div>
                      {verifyButton}
                    </div>
                    <div className="succ-code" style={{ float: 'right', textAlign: 'right', color: `${this.state.color}` }}>
                      <span>{this.state.codeNote}</span>
                    </div>
                  </form>
                </div>
              </div>
              <br />
              {/** End promo code section */}
              {/** Start **/}
              <div className="row" style={{ display: `${this.state.paymentOptions}`, float: 'right' }}>
                <div id="remember" className="col-12 checkbox">
                  <label className="payment-options">
                    <input type="radio" value={this.state.option1} name="option1"
                      checked={this.state.option1}
                      onChange={this.handleOption} /> قم بحفظ رمز الخصم الآن وسيتم تفعيله عند التجديد القادم
                  </label>
                </div>
                <br />
                <div id="remember" className="col-12 checkbox">
                  <label className="payment-options">
                    <input type="radio" value={this.state.option2}
                      checked={this.state.option2}
                      name="option2" onChange={this.handleOption} /> أو الاشتراك الفوري <span style={{ fontWeight: 'normal' }}>(سيتم ترحيل الفترة المتبقية من اشتراكك الحالي)</span>
                  </label>
                </div>
              </div>
              <br /><br /><br />
              {/**  Start **/}
              {paymentCards}
              {/** End **/}
              <br />
              {prePaid}
            </div>
          </div>
        </section>
        {/* End of promo code section*/}

        {/** Start Footer */}
        <Footer />
        {/** End Footer */}
      </body>
    )
  }
}

CustomerPaymentMethods.propTypes = {
  addCodeToRedux: propTypes.func.isRequired,
  fetchAllPaymentPackage: propTypes.func.isRequired,
  getFawryPrices: propTypes.func.isRequired,
  getUserPaymentInfo: propTypes.func.isRequired,
  getPackagePriceBycountry: propTypes.func.isRequired,
}

const mapStateToProps = state => ({
  userPaymentProfile: state.userPaymentProfile.item,
  loading: state.userPaymentProfile.loading,
  error: state.userPaymentProfile.getting_user_payment_data_error,
  sucess: state.userPaymentProfile.getting_user_payment_data_sucessfully,

  paymentPackages: state.mobilePaymentPackage.items,
  paymentPackagesLoading: state.mobilePaymentPackage.loading,
  paymentPackagesError: state.mobilePaymentPackage.getting_all_payment_package_data_error,
  paymentPackagesSuccess: state.mobilePaymentPackage.getting_all_payment_package_data_sucessfully,

  codeName: state.promoCodes.code_name,
  codeNote: state.promoCodes.code_note,

  successVerify: state.promoCodes.get_code_sucessfully,
  loadingVerify: state.promoCodes.loading,
  errorVerify: state.promoCodes.error_loading_code,
  promocodeItem: state.promoCodes.item,

  newCodeSavedSuccessfully: state.promoCodes.new_code_saved_successfully,
  loadingForSaving: state.promoCodes.loading_for_saving,
  errorSavingNewCode: state.promoCodes.error_saving_new_code,
  newCodesaved: state.promoCodes.new_code_saved,

  fawryPaymentPackageItem: state.fawryPaymentPrices.item,
  fawryPaymentPackageLoading: state.fawryPaymentPrices.loading,
  fawryPaymentPackageSuccess: state.fawryPaymentPrices.getting_fawry_prices_sucessfully,

  token: state.user.token,

  mobilePaymentPackage: state.mobilePaymentPrice.item,
  mobilePaymentPackageSuccess: state.mobilePaymentPrice.getting_package_prices_sucessfully,
  mobilePaymentPackageError: state.mobilePaymentPrice.getting_package_prices_error,
  mobilePaymentPackageLoading: state.mobilePaymentPrice.loading,

  customerFlag: state.customers.firstSignUp,

  auth: state.firebase.auth,

  currencyRate: state.currencyRate.item,
  currencyRateSuccess: state.currencyRate.getting_rate_successfully,
  currencyRateError: state.currencyRate.error_getting_rate,

  successMessage: state.successMessages.item,

});

export default withRouter
  (connect(mapStateToProps,
    {
      addTokenToredux, emptyToken,
      addPaymentDataWithCode, clearPaymentData,
      getUserPaymentInfo, emptyPaymentPrice, emptyMobileUserProfileWithItem,
      fetchAllPaymentPackage, getFawryPrices, getPackagePriceBycountry,
      addCodeToRedux, emptyCodeFromRedux,
      vertifyCode, saveCode, emptyPromoCode,
      clearPromoCodeAll,
      addSuccessMessage, removeSuccessMessage,
      signOut, empty,
      getCurrencyRate
    }
  )
    (CustomerPaymentMethods));
