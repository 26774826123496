import React, { Component } from "react";
import { Link } from "react-router-dom";
import mainLogo from "../../../images/portal/icons/logo.svg";
import UpgradeCard from "./UpgradeCard";
import { withFirebase } from "../../../config";

class UpgradeSubscription extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
            <div className="d-flex align-items-center">
              <Link className="navbar-brand d-flex align-items-center" to="/">
                <img src={mainLogo} alt="" />
              </Link>
            </div>
            <Link className="btn back-btn" type="button" to="/">
              العوده
            </Link>
          </div>

          <UpgradeCard />
        </div>
      </>
    );
  }
}

export default withFirebase(UpgradeSubscription);
