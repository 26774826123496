import { EMPTY_FAWRY_PAY, ERROR_FAWRY_PAY, FETCH_FAWRY_PAY, LOAD_FAWRY_PAY } from "../Actions/Types";

const initialState = {
    data: {},
    error:'',
    loading:'',
}

export default function (state = initialState, action){
    switch(action.type){
        case FETCH_FAWRY_PAY:
            return{
                ...state,
                data: action.payload,
                error:'',
                loading: false,
            }
        case LOAD_FAWRY_PAY:
            return{
                ...state,
                error:'',
                loading: true,
            }
        case ERROR_FAWRY_PAY:
            return{
                ...state,
                error: action.payload,
                loading: false,
            }
        case EMPTY_FAWRY_PAY:
            return {
             ...state,
             data: '',
             loading: false,
             error: '',
            }    
        default:
            return state;            
    }
}