import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import React, { Component } from 'react'
import { Snackbar } from '@material-ui/core';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import mainLogoM from '../../../images/portal/logo-big@2x.png';

import succ from '../../../images/fit/succ-sign.png';

import { removeSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { getFitData } from '../../../Promises/Fit'

/* Components */
import Footer from '../Layout/Footer';
import FitDownloadApps from '../Layout/FitDownloadApps'

import "../../../css/Fit.css";

var __html = require('./p.html');


class Privacy extends Component {

    state = {
        vertical: 'bottom',
        horizontal: 'right',
    }

    componentDidMount() {        
    }

    handleSuccessMessages = () => {
        //this.props.removeSuccessMessage();
    }

    render() {	
	
	let template = { __html: __html };
	
		return (							
				<span dangerouslySetInnerHTML={template} />				
		)
    }
}

const mapStateToProps = state => ({
	});


export default withRouter
    (connect
        (mapStateToProps,
            {
                //removeSuccessMessage,
            })
        (Privacy)
    );

