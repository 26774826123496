import React, { Component } from 'react'
import { withFirebase } from '../../config';
import { Button, Modal, Icon, Popup } from 'semantic-ui-react';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { SendEmailsToSingle, emptyCustomer }
    from '../../Actions/CustomerActions';

const INITIAL_STATE = {

    showModal: '',
    /////////// email
    email_subject: '',
    email_text: '',

    ///////// validation
    validationError: true,
    validationMessage: ''

};

class SendCustomerEmail extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    SendEmailsToSingle(selectedCustomerUIds, email_subject, email_text) {
        this.props.SendEmailsToSingle(selectedCustomerUIds, email_subject, email_text);
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        switch (e.target.name) {
            default:
                setTimeout(() => {
                    if (this.state.email_subject != null && this.state.email_subject != ' ' && this.state.email_subject != '' && this.state.email_text != null && this.state.email_text != ' ' && this.state.email_text != '') {
                        this.setState({ validationError: false, validationMessage: '' });
                    }
                    else {
                        this.setState({ validationError: true, validationMessage: "Empty Data are not accepted" });
                    }
                }, 0);
        } // email_text
    }


    handleSubmit = (e) => {
        this.btn.setAttribute("disabled", "disabled");

        /////////mapping the value of customers uid to array
        const { checkedItems } = this.props;

        if (checkedItems.size == 0) {
            alert("Please Select customer to Send Email");
            e.preventDefault();
            this.closeModal();
        }
        else {

            var selectedCustomerUIds = [];
            var get_keys = checkedItems.keys();

            for (var ele of get_keys) {
                selectedCustomerUIds.push(ele);
            }

            for (let i = 0; i < selectedCustomerUIds.length; i++) {
                console.log(selectedCustomerUIds[i]);
            }


            const { email_subject, email_text } = this.state;

            this.SendEmailsToSingle(selectedCustomerUIds, email_subject, email_text);

            e.preventDefault();
            // this.setState({
            //     email_subject: '',
            //     email_text: ''
            //     email_subject: '',
            //     email_text: '',
            
            // })
        }
    }

    closeModal = (e) => {
        this.setState({ showModal: false, validationError: true, validationMessage: '' })
    }

    openModal = (e) => {
        this.setState({ showModal: true, email_subject: '', email_text: '' })
    }


    render() {

        const { email_subject, email_text } = this.state;

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyCustomer();

        }
        else if (success === true) {
            alert("Emails Sent Successfully")
            this.props.emptyCustomer();

            this.closeModal();

        }

        const isInvalidSeries =
            email_subject === '' ||
            email_text === '';


        return (
            <Modal id="modal"
                trigger={
                    <Popup trigger={
                        <button type="submit"
                            className="customer_icons" onClick={this.openModal} >
                            <Icon name="paper plane" />
                        </button>}
                        content='Send Email'
                        position='top center'
                    />
                }
                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Send Email </Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">


                                    <div className="col">

                                        <label htmlFor="email_subject"
                                            className="label">
                                            Email Subject
                                            </label>
                                        <input
                                            dir="auto"
                                            className="form-control"
                                            name="email_subject"
                                            id="email_subject"
                                            onChange={this.handleChange}
                                            value={this.state.email_subject} />
                                        <br />

                                        <label htmlFor="email_text"
                                            className="label">
                                            Email Text
                                        </label>
                                        <textarea fluid rows="6"
                                            dir="auto"
                                            className="form-control"
                                            name="email_text"
                                            id="episode_description"
                                            onChange={this.handleChange}
                                            value={this.state.email_text} />
                                        <br />

                                    </div>

                                </div>

                                <div className="input-field">

                                    <button 
                                        id="saveButton"
                                        ref={btn => { this.btn = btn; }}
                                        className="font btn btn-lg btn-primary btn-block btn-signin mt-2"
                                        data-toggle="modal"
                                        data-target="#exampleModalCenter"
                                        onClick={this.handleSubmit}
                                        disabled={this.state.validationError}
                                    >
                                        Send
                                    </button>


                                </div>
                                {this.state.validationError && <p style={{ color: "red" }}>{this.state.validationMessage}</p>}
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}


SendCustomerEmail.propTypes = {
    SendEmailsToSingle: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    showModal: state.customers.showModal,
    error: state.customers.send_email_error,
    success: state.customers.send_email_sucessfully
});
export default withFirebase
    (connect
        (mapStateToProps,
            { SendEmailsToSingle, emptyCustomer }
        )(SendCustomerEmail)
    );
