import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Redirect, Route } from 'react-router-dom'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'

/*Customer*/
import CustomerAccount from './Customer/CustomerAccount';
import CustomerAccountProfile from './Customer/CustomerAccountProfile';
import CustomerPaymentHistory from './Customer/CustomerPaymentHistory';
import CustomerSubscriptionProfile from './Customer/CustomerSubscriptionProfile';
import CustomerPaymentMethods from './Customer/CustomerPaymentMethods';
import CreditCardCheckout from './Payment/CreditCardCheckout';
import FawryPayment from './Payment/FawryPayment';
import PrePaidCardPayment from './Payment/PrePaidCardPayment';
import ChangeRegisteration from './Authentication/ChangeRegisteration';
import FitSuccess from './FIt/FitSuccess'

import TpaySuccess from './Tpay/TpaySuccess'
import TpayPhoneCode from './Tpay/TpayPhoneCode'

import TelecomSuccess from './telecommunication/TelecomSuccess'


/* Routes */
import { PORTAL_ACCOUNT, PORTAL_ACCOUNT_PROFILE, PORTAL_SUBSCRIPTION_HISTORY,
        PORTAL_SUBSCRIPTION, PORTAL_PAYMENT_METHODS, PORTAL_CREDIT_CARD,
        PORTAL_FAWRY, PORTAL_PRE_PAID, PORTAL_CHANGE_LOGIN_METHODS, FIT_SUCCESS,
        TPAY_SUCCESS ,TPAY_PHONE_CODE, 
        FIT_V_CODE, Mw_ZainIraq_V_CODE,Mw_Bahrain_V_CODE,Mw_Qatar_V_CODE,Mw_ZainIraq_SUCCESS,Mw_Bahrain_SUCCESS,Mw_Qatar_SUCCESS,TELECOMUNICATION_SUCCESS,MONDIA_SUCCESS, PORTAL_MADA } from '../../routes';
import FitVCode from './FIt/FitVCode'
import MadaPayment from './Payment/MadaPayment'
import MwZainIraqVCode from './MW/MwZainIraqVCode'
import MwBahrainVCode from '../mwBahrain/MwBahrainVCode'
import MwQatarVCode from '../mwQatar/MwQatarVCode'

import MwZainIraqVComponent from './MW/MwZainIraqSuccess'
import MwBahrainSuccessComponent from '../mwBahrain/MwBahrainSuccess'
import MwQatarSuccessComponent from '../mwQatar/MwQatarSuccess'
import MondiaSuccessComponent from '../components/mondia/MondiaSuccess'






class CustomerAuth extends Component {
  state ={
    auth: true,
  }
  constructor(props) {
      super(props);
  }

  componentDidMount() {
    // import("../../css/portal-style.css");
      if (firebase.auth().currentUser === null) {
        this.setState({auth: false});
      }
  }


  render() {
    if(this.state.auth === false){
        return (<Redirect to="/"></Redirect>);
    }
    return (
      <span>
      {/* Customer */}
        <Route path={PORTAL_ACCOUNT} component={CustomerAccount} />
        <Route path={PORTAL_ACCOUNT_PROFILE} component={CustomerAccountProfile} />
        <Route path={PORTAL_SUBSCRIPTION_HISTORY} component={CustomerPaymentHistory} />
        <Route path={PORTAL_SUBSCRIPTION} component={CustomerSubscriptionProfile} />
        <Route path={PORTAL_PAYMENT_METHODS} component={CustomerPaymentMethods} />
        <Route path={PORTAL_CREDIT_CARD} component={CreditCardCheckout} />
        <Route path={PORTAL_FAWRY} component={FawryPayment} />
        <Route path={PORTAL_MADA} component={MadaPayment} />
        <Route path={PORTAL_PRE_PAID} component={PrePaidCardPayment} />
        <Route path={PORTAL_CHANGE_LOGIN_METHODS} component={ChangeRegisteration}/>
        <Route path={FIT_SUCCESS} component ={FitSuccess}/>

        <Route path={TPAY_PHONE_CODE} component ={TpayPhoneCode}/>
        <Route path={TPAY_SUCCESS} component ={TpaySuccess}/>

        <Route path={FIT_V_CODE} component={FitVCode} />
        <Route path={TELECOMUNICATION_SUCCESS} component={TelecomSuccess} />
        
        
        
		<Route path={Mw_ZainIraq_V_CODE} component={MwZainIraqVCode} />
		<Route path={Mw_Bahrain_V_CODE} component={MwBahrainVCode} />
		<Route path={Mw_Qatar_V_CODE} component={MwQatarVCode} />
		
		<Route path={Mw_ZainIraq_SUCCESS} component={MwZainIraqVComponent} />
		<Route path={Mw_Bahrain_SUCCESS} component={MwBahrainSuccessComponent} />
		<Route path={Mw_Qatar_SUCCESS} component={MwQatarSuccessComponent} />
		<Route path={MONDIA_SUCCESS} component={MondiaSuccessComponent} />
		

      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
(connect
    (mapStateToProps,
        {}
    )(CustomerAuth)
);
