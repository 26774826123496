import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../components/Layout/Footer';

/* Assets */
import mainLogoM from '../../images/portal/logo-big.png';
import succIcon from '../../images/portal/succ-sign.svg';
import Loader from '../../components/Layout/Loader'

/* Actions */
import {
  verifyingCode,
  resendCode,
  emptyMw_Bahrain
} from './MwBahrainActions';

/** ROutes */
import * as ROUTES from '../../routes';

import * as CONSTANTS from '../../constants';



const INITIAL_STATE = {
    code: '',
    code1: '',
    code2: '',
    code3: '',
    redirect: false,
    confirmResult: '',
    errorMsg: '',
    phone: '',
    telecomComany: '',
    codeLength: 4
}

class MwBahrainVCode extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

    componentDidMount() 
    {
        let phone = this.props.match.params.phone
        let telecomCompany = this.props.match.params.telecomCompany;
        
//console.log('MWwwwwwwww Bahrain VCODE: '+telecomCompany+" , and phone: "+phone);
        
        this.setState({
            phone: phone,
            telecomCompany: telecomCompany,
        })
    }

    handleNext = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        let name = e.target.name;
        this.setState({ [e.target.name]: checkSpaces });
        
//console.log('checkSpacesss: '+checkSpaces+' , name: '+name+" , state: "+JSON.stringify(this.state,null,3));
        
        setTimeout(() => {
        this.setState({
            code: this.state.code1
            + this.state.code2
            + this.state.code3
            + this.state.code4           
        })
        }, 1000)
        if (name === 'code1')
        this.secondTextInput.focus();
        if (name === 'code2')
        this.thirdTextInput.focus();
        if (name === 'code3')
        this.forthTextInput.focus();
        //if (name === 'code4')
        //this.fifthTextInput.focus();
        
    }

    submit = (e) => {
        const { code, phone, telecomCompany } = this.state;

		//console.log('-(1): MW Bahrain VCODE in submit : '+telecomCompany+" ,phone: "+phone+", code: "+code);

        if (phone !== '' && phone !== null && phone !== undefined && phone !== ' ' &&
        	telecomCompany !== '' && telecomCompany !== null && telecomCompany !== undefined && telecomCompany !== ' ' &&
        	code !== '' && code !== null && code !== undefined && code !== ' ') 
        {
			//console.log('-(2): hereeeeeeeeeeeee: '+telecomCompany+" ,ph: "+phone);
        	this.props.verifyingCode(code, telecomCompany, phone);
        }
        else 
        {
        	this.setState({ errorMsg: "لقد حدث خطأ من فضلك حاول مره اخرى" })
        }

        e.preventDefault();
    }

    resend = (e) => {
        //console.log("resend")
        const { phone, telecomCompany } = this.state;

		//console.log('MW Bahrain VCODE in resend : '+telecomCompany);

        if (phone !== '' && phone !== null
        && phone !== undefined && phone !== ' ' && telecomCompany !== '' && telecomCompany !== null
        && telecomCompany !== undefined && telecomCompany !== ' '
        ) {
        	this.props.resendCode(phone, telecomCompany);
        }
        else {
        	this.setState({ errorMsg: "لم نتمكن من إرسال كود التفعيل، يرجى المحاولة مرة اخرى" })
        }

    }

    render() {
	
//console.log("this.props in MW Bahrain VCODE : "+JSON.stringify(this.props,null,3))
	
        const { mw, loading,verifyingError, verifyingSuccess,resendCodeError, resendCodeSuccess, resendLoading } = this.props;

        let loader = null;

        if (loading === true) 
        {
        	loader = (<Loader />)
        }

//console.log(' after sucess::: :'+JSON.stringify(mw,null,3) +" ,, verifyingSuccess:  "+verifyingSuccess);

        // verifying send code
        if (verifyingSuccess === true )                 //&& mw !== undefined && mw !== null 
        {
			//console.log('-(6): Enter bahrainBatelco succcccccesssss');
            
            //let redirect = ROUTES.Mw_Bahrain_SUCCESS +"/"+ this.state.telecomCompany + "/" + this.state.phone;
            let redirect = "/MwBahrainSuccess" +"/"+ this.state.telecomCompany + "/" + this.state.phone;
            
			//console.log(' newRedirect after success: '+redirect);            
            this.props.emptyMw_Bahrain(); 
            return (<Redirect to={redirect}> </Redirect>);
        }
        // error in calling API
        else if (verifyingSuccess === false && verifyingError !== '' && verifyingError !== undefined ) 
        {
            this.setState({ errorMsg: verifyingError })
            this.props.emptyMw_Bahrain();
        }

        // resend code
        if (resendCodeSuccess === true && mw !== undefined && mw !== null) 
        {
            //this.setState({code: '', code1:'',code2:'', code3:'', code4:'', code5:'', errorMsg: "تم ارسال الرمز بنجاح" })
            this.setState({code: '', code1:'',code2:'', code3:'', errorMsg: "تم ارسال الرمز بنجاح" })
            this.props.emptyMw_Bahrain();
        }
        // error in calling API
        else if (resendCodeSuccess === false && resendCodeError !== '' && resendCodeError !== undefined) 
        {
            this.setState({ errorMsg: resendCodeError })
            this.props.emptyMw_Bahrain();
        }

        let phoneSectionMW_Bahrain = (
        <div className="card-content text-center">
            <p style={{ color: 'red', textAlign: 'center' }}> {this.state.errorMsg}</p>
            <div className="card-title h4" id="recaptcha-container">تم ارسال الرمز</div>
            <div className="code-icon">
            <img src={succIcon} alt="" />
            </div>
            <div className="description-text">
            <span>فضلًا، أدخل الرمز المكون من {this.state.codeLength} أرقام، والمرسل في رسالة نصية إلى رقم </span>
            <span className="phone-number">{this.state.phone}</span>
            </div>
            <form className="code-form">
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1" autoFocus
                name="code1" value={this.state.code1}
                onChange={this.handleNext}
                blurOnSubmit={false}
                />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                name="code2" value={this.state.code2}
                ref={(input) => { this.secondTextInput = input; }}
                onChange={this.handleNext} />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                name="code3" value={this.state.code3}
                ref={(input) => { this.thirdTextInput = input; }}
                onChange={this.handleNext} />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                name="code4" value={this.state.code4}
                ref={(input) => { this.forthTextInput = input; }}
                onChange={this.handleNext} />
            </div>
            
            </form>
            <div className="form-group custom-btn">
            <button type="submit" className="btn phone-btn" onClick={this.submit}>تأكيد الاشتراك</button>
            </div>
        </div>
        )

        

        let phoneSection = phoneSectionMW_Bahrain ;
        

        if (resendLoading === true) 
        {
        	phoneSection = (<Loader />)
        }

        return (
        <body>
            <div className="other-methods-container">

            <section className="jeel-fit">
                <div className="logo-container">
                <div className="container">
                    <div className="logo">
                    <img src={mainLogoM} alt="logo" />
                    </div>
                </div>
                </div>

                <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-12 col-md-6">
                    <div className="center fit-about-text">
                        <div className="title h2 white ">
                        <center>
                            بعد الاشتراك ، كل ما عليك الآن هو
                        </center>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row justify-content-md-center">

                    <div className="col-12 col-md-6">
                    <div className="  main-progress-bar">
                        <ol className="progress-tracker">
                        <li className="step fitbg">
                            <span className="step-name small-font">
                            <span className="bold">
                                الاشتراك
                            </span>
                            </span>
                        </li>
                        <li className="step fit">
                            <span className="step-name small-font">
                            <span className="bold">
                                رمز التحقق
                            </span>
                            </span>
                        </li>
                        <li className="step fit">
                            <span className="step-name small-font">
                            <span className="notbold">
                                تحميل التطبيق
                            </span>
                            </span>
                        </li>
                        </ol>
                    </div>
                    </div>
                </div>
                </div>
                <br />
                <br />
            </section>

            {loader}
            <section className="dont-worry">
                <div className="container">
                <div className="dont-wory-card">
                    {phoneSection}
                </div>
                <div className="resend-code">
                    <span>لم تصلك رسالة التأكيد؟ </span>
                    <span>
                    <button className="resend"
                        type="submit"
                        onClick={this.resend}>أعد إرسال الرمز</button>
                    </span>
                </div>
                </div>
            </section>
            </div>
            <Footer />
        </body>
        )
    }
}

MwBahrainVCode.propTypes = {
  verifyingCode: propTypes.func.isRequired,
  resendCode: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
  mw: state.mwBahrainReducer.item,
  loading: state.mwBahrainReducer.loading,
  verifyingSuccess: state.mwBahrainReducer.verifying_successfully,
  verifyingError: state.mwBahrainReducer.verifying_error,

  resendCodeSuccess: state.mwBahrainReducer.resend_code_successfully,
  resendCodeError: state.mwBahrainReducer.resend_code_error,
  resendLoading: state.mwBahrainReducer.resendLoading

});

export default withRouter
  (connect

    (mapStateToProps,
      {
        verifyingCode,
        resendCode,
        emptyMw_Bahrain
      })
    (MwBahrainVCode)
  );
