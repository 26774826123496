import {
    EMPTY,
    ERROR_ADDING_EVALUATION, ADD_EVALUATION, LOAD_EVALUATION,
    EMPTY_EVALUATION, EMPTY_EVALUATIONAll
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},

    loading: '',

    adding_Evaluation_error: '',
    adding_Evaluation_sucessfully: "",
}

export default function (state = initialState, action) {
    switch (action.type) {

        // case LOAD_EVALUATION:
        //     return {
        //         ...state,
        //         loading: true
        //     }

        case ADD_EVALUATION:
            return {
                ...state,
                items: action.payload,
                items: state.items.concat(action.payload),
                adding_Evaluation_error: '',
                adding_Evaluation_sucessfully: true,
                loading: false
            }

        // case ERROR_ADDING_EVALUATION:
        //     return {

        //         ...state,
        //         adding_Evaluation_error: action.payload,
        //         adding_Evaluation_sucessfully: false,
        //         loading: false
        //     }

        case EMPTY_EVALUATION:
            return {
                ...state,
                adding_Evaluation_error: '',
                adding_Evaluation_sucessfully: '',

            }


        case EMPTY_EVALUATIONAll:
            return {
                ...state,
                loading: '',
                adding_Evaluation_error: '',
                adding_Evaluation_sucessfully: '',
                items: [],
                item: {},
                
            }


        default:
            return state

    }

}