import React, { Component } from "react";
import { Button, Icon } from "semantic-ui-react";
import { Redirect, Link } from "react-router-dom";
/*Headers */
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
/*Redux */
import { connect } from "react-redux";
import propTypes from "prop-types";
/*Actions */
import { emptyEvaluationAll } from "../../Actions/EvaluationActions";
import { clearQuestions } from "../../Actions/QuestionActions";
import { publishSong, emptySong } from "../../Actions/SongActions";
import {
  publishQuestion,
  assignCorrrectAnswerToQuestion,
} from "../../Actions/QuestionActions";
import { emptyError } from "../../Actions/Empty";
import { addSeriesIDtoRedirectionPage } from "../../Actions/RedirectionPagesActions.js";

/*css*/
import "../../css/AppCss/Episode/PublishEpisode.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/video-react/dist/video-react.css";
/*Other components */
import QuestionPreview from "../Layout/QuestionPreview";
import AudioPlayer from "react-h5-audio-player";
import Player from "video-react/lib/components/Player";
import { SONG_EVALUATION } from "../../routes";
import RenderVideoWithoutMusic from "../VideoPreview/RenderVideoWithoutMusic.js";
import RenderVideoWithMusic from "../VideoPreview/RenderVideoWithMusic.js";
import { SONG_FOLDER_NAME } from "../../portal/src/constants.js";
import PreviewVideoWithMusic from "../VideoPreview/PreviewVideoWithMusic.js";
import PreviewVideoWithoutMusic from "../VideoPreview/PreviewVideoWithoutMusic.js";

class PublishSong extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoWithMusicReady: false,
      videoWithoutMusicReady: false,
      isButtonDisabled: false,
    };
  }

  state = {
    sucess: "",
  };

  publishSong = (e) => {
    const { song } = this.props;
    const { redirectionPage } = this.props;
    console.log(redirectionPage.pageName);
    this.props.addSeriesIDtoRedirectionPage(
      redirectionPage.pageName,
      song.selected_songParent_id
    );

    const { activity } = this.props;
    this.props.publishSong(activity.name);
  };
  handleChangeVideoWithMusic = (uploadInfo,videoKey) => {
    // console.log(episodes);
    // console.log("Received uploadInfo in parent:", uploadInfo);
    // if (
    //   uploadInfo &&
    //   uploadInfo !== undefined &&
    //   uploadInfo !== null &&
    //   Object.keys(uploadInfo).length > 0
    // ) {
      
    // } 
  };
  handleChangeVideoWithoutMusic = (uploadInfo,videoKey) => {
    // console.log(episodes);
    // console.log("Received uploadInfo in parent:", uploadInfo);
    // if (
    //   uploadInfo &&
    //   uploadInfo !== undefined &&
    //   uploadInfo !== null &&
    //   Object.keys(uploadInfo).length > 0
    // ) {
      
    // } 
  };

  isButtonDisabled = () => {
    const { song } = this.props;
    const { videoWithMusicReady, videoWithoutMusicReady } = this.state;

    if (song.videoKeyVideoWithMusic && song.videoKeyVideoWithoutMusic){
      if (!videoWithMusicReady || !videoWithoutMusicReady){
        return true;
      }
    }

    else if (song.videoKeyVideoWithMusic && !song.videoKeyVideoWithoutMusic){
      if (!videoWithMusicReady){
        return true;
      }
    }

    else if (!song.videoKeyVideoWithMusic && song.videoKeyVideoWithoutMusic){
      if (!videoWithoutMusicReady){
        return true;
      }
    }

    else {
      return false;
    }
  };

  render() {
    const { song } = this.props;
    const { questions } = this.props;

    const { songerror } = this.props;
    const { songsucess } = this.props;
    const { activityerror } = this.props;
    const { activitysucess } = this.props;
    //const { loading } = this.props;
    const { loading } = false;


    let buttonName = song.radiobutton;
    console.log(buttonName);
    if (song.radiobutton === "now") {
      buttonName = "publish";
    } else if (song.radiobutton === "schedule") {
      buttonName = "save";
    }

    if (activitysucess === false) {
      alert(activityerror);
      this.props.emptySong();
      this.props.clearQuestions();
      this.props.emptyEvaluationAll();
    } else if (activitysucess === true) {
      if (songsucess === false) {
        alert("error missing data or network " + songerror);
        this.props.emptySong();
        this.props.clearQuestions();
        this.props.emptyEvaluationAll();
      } else if (songsucess === true) {
        alert("song added Successfully");
        this.props.emptySong();
        this.props.clearQuestions();
        this.props.emptyEvaluationAll();

        const { redirectionPage } = this.props;
        console.log(redirectionPage.pageName);

        var pageName = redirectionPage.pageName;
        return <Redirect to={pageName}></Redirect>;
      }
    }

    let questionsList = null;
    if (questions !== undefined) {
      questionsList = <QuestionPreview state="publish" questions={questions} />;
    }

    // loading button
    let $buttonSubmitLoader = (
      <Button id="episode_publish" onClick={this.publishSong}
      disabled={this.isButtonDisabled()}
      >
        {buttonName}
      </Button>
    );

    if (loading === true) {
      $buttonSubmitLoader = (
        <button id="episode_publish" class="ui loading button">
          Loading
        </button>
      );
      console.log("loading");
    }

    let audio = "";
    if (song.voice) {
      audio = (
        <AudioPlayer src={song.voice} onPlay={(e) => console.log("onPlay")} />
      );
    } else {
      audio = (
        <td fluid className="episode_label">
          {" "}
          No Audio
        </td>
      );
    }

    let song_VideoPreviewWithMusic = null;
    if (song.videoWithMusic) {
      song_VideoPreviewWithMusic = (
        <Player playsInline fluid src={song.videoWithMusic} />
      );
    } else {
      song_VideoPreviewWithMusic = (
        <div className="previewText">No Video for Preview</div>
      );
    }

    let song_VideoPreviewWithoutMusic = null;
    if (song.Video_Without_Music) {
      song_VideoPreviewWithoutMusic = (
        <Player playsInline fluid src={song.Video_Without_Music} />
      );
    } else {
      song_VideoPreviewWithoutMusic = (
        <div className="previewText">No Video for Preview</div>
      );
    }

    const handleVideoWithMusicReady = () => {
      this.setState({ videoWithMusicReady: true });
    };
    const handleVideoWithoutMusicReady = () => {
      this.setState({ videoWithoutMusicReady: true });
    };

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <NavBar />
                      <br />
                      <div className="main-progress-bar">
                        <ol className="progress-tracker">
                          <li className="step active s2-active">
                            <span className="step-name small-font">
                              ِAdd Song
                            </span>
                          </li>
                          <li className="step active s2-active">
                            <span className="step-name small-font">
                              Add activity
                            </span>
                          </li>
                          <li className="step active s2-active">
                            <span className="step-name small-font">
                              Add Evaluation
                            </span>
                          </li>
                          <li className="step active">
                            <span className="step-name small-font">
                              Puplish Song
                            </span>
                          </li>
                        </ol>
                      </div>
                      <br />
                      <div className="container">

                     

                        <label htmlFor="">{song.name}</label>
                        <hr />

                        <div>
                              <label className="label">
                                Video with music
                              </label>
                              <section className="video_section">
                                  <PreviewVideoWithMusic
                                  videoKey={song.videoKeyVideoWithMusic}
                                  id="VideoWithMusic" 
                                  onVideoReady={handleVideoWithMusicReady}>
                                  </PreviewVideoWithMusic>
                              </section>
                              <br />
                            </div>
                          <div>
                              <label className="label">
                                Video without music
                              </label>
                              <section className="video_section">
                                  <PreviewVideoWithoutMusic
                                  videoKey={song.videoKeyVideoWithoutMusic}
                                  id="VideoWithoutMusic" 
                                  onVideoReady={handleVideoWithoutMusicReady}>
                                  </PreviewVideoWithoutMusic>
                              </section>

                              <br />
                            </div>
                        {/* {song.videoKeyVideoWithoutMusic && (
                          <div>
                            {console.log(song.videoKeyVideoWithMusic)}
                            <label className="label">Video with music</label>
                            <section className="video_section">
                              <RenderVideoWithMusic
                                videoKey={song.videoKeyVideoWithMusic}
                                id="VideoWithMusic"
                                foldername={SONG_FOLDER_NAME}
                                enableEdit={false}
                                changedid={this.handleChangeVideoWithMusic}
                                ></RenderVideoWithMusic>
                            </section>

                            <br />
                          </div>
                        )}
                        {song.videoKeyVideoWithoutMusic && (
                          <div>
                            {console.log(song.videoKeyVideoWithoutMusic)}
                            <label className="label">Video with music</label>
                            <section className="video_section">
                              <RenderVideoWithoutMusic
                                videoKey={song.videoKeyVideoWithoutMusic}
                                id="VideoWithoutMusic"
                                foldername={SONG_FOLDER_NAME}
                                changedid={this.handleChangeVideoWithoutMusic}
                                enableEdit={false}
                                ></RenderVideoWithoutMusic>
                            </section>

                            <br />
                          </div>
                        )} */}
                        {/* {song.videoWithMusic && (
                          <div>
                            {" "}
                            <label className="label">Video with music</label>
                            <section className="video_section">
                              <div>{song_VideoPreviewWithMusic}</div>
                            </section>
                            <br />
                          </div>
                        )}
                        {song.Video_Without_Music && (
                          <div>
                            {" "}
                            <label className="label">Video without music</label>
                            <section className="video_section">
                              <div>{song_VideoPreviewWithoutMusic}</div>
                            </section>
                            <br />{" "}
                          </div>
                        )} */}
                        <hr />
                        <div className="row">
                          <div className="col-4">
                            <img
                              className="image_Preview rounded"
                              src={song.image}
                              alt=""
                            />
                          </div>

                          <div className="col">
                            <table>
                              <tr>
                                <th className="episode_label"> Song Name </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {song.name}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label">
                                  {" "}
                                  Song Name In English{" "}
                                </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {song.song_name_in_english}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label">
                                  {" "}
                                  Selected Song Parent{" "}
                                </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {song.selected_songParent_name}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label">
                                  {" "}
                                  Number Of Questions{" "}
                                </th>
                                <td fluid className="episode_label">
                                  {questions.length}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label"> Schedule </th>
                                <td fluid className="episode_label">
                                  11/2/2019, 02:00 am{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label">
                                  {" "}
                                  payment availability{" "}
                                </th>
                                <td fluid className="episode_label">
                                  {" "}
                                  {song.isFree}{" "}
                                </td>
                              </tr>

                              <tr>
                                <th className="episode_label"> Audio </th>
                                {audio}
                              </tr>
                            </table>
                          </div>
                        </div>
                        <br />
                        <div className="row" id="episode_Question_Row">
                          <label className="question_label">Questions</label>
                          <hr id="questionHR" />
                          <br />
                          {questionsList}
                        </div>
                        {$buttonSubmitLoader}
                        <Link to={SONG_EVALUATION}>
                          <Button
                            icon
                            labelPosition="left"
                            id="episode_backButton"
                          >
                            back
                            <Icon name="left arrow" />
                          </Button>
                        </Link>
                        <br /> <br /> <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
PublishSong.propTypes = {
  publishSong: propTypes.func.isRequired,
  publishActivity: propTypes.func.isRequired,
  publishQuestion: propTypes.func.isRequired,
  assignCorrrectAnswerToQuestion: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  loading: state.songs.loading,
  song: state.songs.item,
  activity: state.activities.item,
  questions: state.questions.items,
  question: state.questions.item,
  songerror: state.songs.error_adding_song,
  activityerror: state.activities.error_adding_activity,
  songsucess: state.songs.song_added_successfully,
  activitysucess: state.activities.activity_added_successfully,
  redirectionPage: state.redirectionPage.item,
});

export default connect(mapStateToProps, {
  publishSong,
  publishQuestion,
  assignCorrrectAnswerToQuestion,
  emptySong,
  clearQuestions,
  emptyEvaluationAll,
  addSeriesIDtoRedirectionPage,
})(PublishSong);
