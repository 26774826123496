import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/*Components */
import TermsAndConditionsCard from './TermsAndConditionsCard'
import AddTermsAndConditions from './AddTermsAndConditions'

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';

/* Routes */
import { ADMIN_LAND_PAGE } from '../../routes';

export class TermsAndConditions extends Component {
    render() {
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">


                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                    </span>
                                                    <span>Terms And Conditions</span>
                                                </div>
                                                {/* <!--  end status bar --> */}

                                                <div className="main-title-container">
                                                    <h5 className="main-title mt-2 font-weight-bold">Terms And Conditions</h5>

                                                    {/* Add TermsAndConditions  Component */}
                                                    <AddTermsAndConditions />

                                                </div>

                                                {/* Get All Terms and conditions Cards */}
                                                <TermsAndConditionsCard />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default TermsAndConditions
