import React, { Component } from 'react'
import { withFirebase } from '../../config';
import { Input, Button, Modal, TextArea } from 'semantic-ui-react';
import addNew from '../../images/add-nw.png'

/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/**Other Component */
import UploadImage from '../S3Uploading/UploadImage';
import Loader from '../Layout/Loader';

/*Actions */
import {
    addSubValue,
    emptySubValues
} from '../../Actions/ContentSubValueAction'

import {
    fetchAllMainValues,
} from '../../Actions/ContentMainValuesAction';


class AddSubValue extends Component {

    state = {

        showModal: '',
        /////////// FAQ

        text: '',
        text_in_english: '',
        number: 0,
        imageUrl: ''

    };

    constructor(props) {
        super(props);
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }


    addSubValue(mainContentId, text, imageUrl, number, text_in_english) {
        this.props.addSubValue(mainContentId,
            text,
            imageUrl,
            number,
            text_in_english)
    }

    getImageUrl(imageUrl) {

        if (imageUrl) {
            this.setState({ imageUrl: imageUrl });
        }
    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleSubmit = (e) => {
        const { mainContentId } = this.props;

        const { text, imageUrl, number, text_in_english } = this.state;

        this.addSubValue(mainContentId, text, imageUrl, number, text_in_english);

        e.preventDefault();
    }

    closeModal = (e) => {
        this.setState({ showModal: false })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
    }


    render() {

        const { text, number, imageUrl, text_in_english } = this.state;

        const { error } = this.props;
        const { success } = this.props;
        const { loading } = this.props;

        const isInvalidText =
            text === '' ||
            number === 0 ||
            imageUrl === '' ||
            text_in_english === '';

        let $buttonSubmitLoader = (
            <Button onClick={this.handleSubmit}
                disabled={isInvalidText}
                id="saveButton" >
                Add
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="saveButton"
                    class="ui loading button">
                    Loading</button>);
        }

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptySubValues();
        }
        else if (success === true) {
            alert("Sub Value added Successfully")

            this.closeModal();
            this.props.emptySubValues();
            this.props.fetchAllMainValues();

            this.setState({
                text: '',
                number: 0,
                imageUrl: '',
                text_in_english: '',
            })

        }



        return (
                <Modal id="modal"
                    trigger={
                        <a onClick={this.openModal} 
                        className="card a-unitbox" data-toggle="modal" 
                        data-target="#exampleModal">
                            <img className="card-img-top" alt="img-add" src={addNew} />
                        </a>
                    }
                    onClose={this.closeModal} open={this.state.showModal}>


                <Modal.Header>Add New Sub Value</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">

                                    {/* image Uploader */}
                                    <div className="col-md-4 col-12">
                                        <div className="">
                                            <span className="required_input">*</span>
                                            <UploadImage getImageURLCallBack={this.getImageUrl.bind(this)}
                                                imageUrl={this.state.imageUrl}
                                                imagepreview={this.state.imageUrl}
                                                s3DirectryrName='contentvalue' />
                                        </div>

                                    </div>

                                    <div className="col-md-8 col-12">

                                        <label htmlFor="text" className="label">
                                            <span className="required_input">*</span>
                                            Text</label>
                                        <input fluid name="text" id="text"
                                            dir="auto"
                                            className="form-control"
                                            onChange={this.handleChange}
                                            value={this.state.text} />
                                        <br />

                                        <label htmlFor="text_in_english" className="label">
                                            <span className="required_input">*</span>
                                            Text In English</label>
                                        <input fluid name="text_in_english" id="text_in_english"
                                            dir="auto"
                                            className="form-control"
                                            onChange={this.handleChange}
                                            value={this.state.text_in_english} />
                                        <br />

                                        <label htmlFor="number"
                                            className="label">
                                            <span className="required_input">*</span>
                                            Number</label>
                                        <br />
                                        <Input fluid type="number" min="0"
                                            name="number" id="number"
                                            onChange={this.handleChange}
                                            value={this.state.number} />
                                        <br />

                                    </div>

                                </div>

                                <div className="input-field">

                                    {$buttonSubmitLoader}

                                </div>
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

AddSubValue.propTypes = {
    addSubValue: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    showModal: state.ContentSubValue.showModal,
    error: state.ContentSubValue.error_adding_sub_value,
    success: state.ContentSubValue.sub_value_saved_successfully,
    loading: state.ContentSubValue.loading
});


export default withFirebase(
    connect(
        mapStateToProps,
        {
            addSubValue,
            emptySubValues,
            fetchAllMainValues
        }
    )
        (AddSubValue)
);
