import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
/*Actions*/
import {
    deleteChannel,
    emptyChannel
} from '../../Actions/RadioChannelAction'
import { getRadioData } from '../../Actions/RadioActions';

import {
    deleteBook,
    emptyBook,
    emptyError,
    fetchBooks
} from '../../Actions/ReadWithJeelAction'

import { ALL_BOOK } from '../../routes'
import { Redirect } from 'react-router-dom';

 class DeleteBook extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    removeBook(id) {
        this.props.deleteBook(id)
    }

    handleSubmit = (e) => {
        const { id } = this.props;
        this.removeBook(id);
        this.close();
    }

    render() {
        const { error,
            success, BookId } = this.props

        if (success === false) {
            alert("error missing data or network error" + error);
            // this.props.emptyBook();
            this.props.getBookParentData(this.props.BookParentId)
            // return (<Redirect to={ALL_BOOK}></Redirect>)

        }
        else if (success === true) {
            alert("Book deleted successfully");
            this.props.emptyError();
            // this.props.emptyBook();
            // this.props.getRadioData(RadioId);
            this.props.fetchBooks();
            this.props.getBookParentData(this.props.BookParentId)
            // return (<Redirect to={ALL_BOOK}></Redirect>)
        }

        return (<div >
            <button className="seriesCardButtons" 
            onClick={this.open}>
                <i className="" >
                    <FontAwesomeIcon icon="trash-alt" />
                </i>
            </button>
            <Confirm id="modal"
                content='Are You Sure To Delete This Book?? '
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </div>)
    }
}


const mapStateToProps = state =>
({
    success: state.readWithJeel.book_deleted_successfully,
    error: state.readWithJeel.error_deleteing_book
})
export default connect
    (mapStateToProps,
        {
            deleteBook,
            emptyBook,
            emptyError,
            fetchBooks,
        })
    (DeleteBook)
