import  { post } from 'axios';

export const prepareCheckout = (Amount) => {
  return new Promise (function(resolve,reject) {
      const url = '/HyperPay/prepareCheckoutFrontTest';
      const formData = new FormData();
      formData.append("Amount", Amount);
      formData.append("HyperPay_payment_type","STC_PAY")
      const config = {
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
        },
      }
      post(url, formData, config).then((res) => {
          resolve(res);
          return res;
      }).catch((Error)=>{
          reject(Error)
          return Error.response.data.message
      })
  })
}


export const getPaymentstatus = (CheckOutId) => {
    return new Promise (function(resolve,reject) {
        const url = '/HyperPay/getPaymentStatusFrontTest';
        const formData = new FormData();
        formData.append("CheckOutId", CheckOutId);
        formData.append("HyperPay_payment_type","STC_PAY")

        const config = {
          headers: {
              'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
          },
        }
        post(url, formData, config).then((res) => {
            resolve(res);
            return res;
        }).catch((Error)=>{
            reject(Error)
            return Error.response.data.message
        })
    })
  }