import {
    ADD_ACTIVITY, ERROR_ADDING_ACTIVITY, PUBLISH_ACTIVITY, EMPTY_ACTIVITY,
    LOAD_ACTIVITY, GET_ACTIVITY_DATA, ERROR_LOADING_ACTIVITY
} from '../Actions/Types'

const initial_state = {
    items: [],
    item: {},

    itemforUpdate: [],

    activity_added_successfully: '',
    error_adding_activity: '',
    error_loading_activity: '',

}
export default function (state = initial_state, action) {
    switch (action.type) {

        case ADD_ACTIVITY:

            return {
                ...state,
                item: action.payload
            }

        case EMPTY_ACTIVITY:
            return {
                ...state,
                error_adding_activity: action.payload,
                activity_added_successfully: action.payload,
                error_loading_activity: action.payload,
                loading: '',
                itemforUpdate: [],

            }


        case PUBLISH_ACTIVITY:
            return {
                ...state,
                item: action.payload,
                activity_added_successfully: true
            }

        case ERROR_ADDING_ACTIVITY:
            return {
                ...state,
                activity_added_successfully: false,
                error_adding_activity: action.payload

            }
        case GET_ACTIVITY_DATA:

            return {
                ...state,
                itemforUpdate: action.payload,
                loading: false,
                error_loading_activity: ''
            }
       

        case ERROR_LOADING_ACTIVITY:
            return {
                ...state,
                error_loading_activity: action.payload,
                loading: false
            }


        case LOAD_ACTIVITY:
            return {
                ...state,
                loading: true
            }

        default:
            return state



    }

}
