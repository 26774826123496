import React, { useEffect, useState, useCallback } from "react";
import { clearPreSignData, generateOTP , resetVdocipherData } from "../../Actions/VideoCipherActions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../css/main-style.css";
import IntroWithoutMusicUploader from "../FilePondUploading/IntroWithoutMusicUploader";

function RenderIntroWithoutMusic({ videoKey, id , foldername, changedid}) {
  const entity = useSelector((state) => state.vdoCipher.introWithoutMusic);
  const [generatedOTP, setGeneratedOTP] = useState({});
  const [isReady, setIsReady] = useState(false);
  const [actualOTP, setActualOTP] = useState("");
  const [actualPlaybackInfo, setActualPlaybackInfo] = useState("");
  const [iframeSrc, setIframeSrc] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    handleGenerateOTP();
  }, [videoKey , id]);
  const handleGenerateOTP = useCallback(async () => {
    dispatch(resetVdocipherData(id));
    if (videoKey && id) {
      try {
        await dispatch(generateOTP(videoKey, id));
      } catch (error) {
        console.error("Error generating OTP:", error);
      }
    }
  }, [videoKey,id]);

  useEffect(() => {
    if (generatedOTP && generatedOTP !== null && generatedOTP !== undefined) {
      setActualOTP(generatedOTP.otp);
      setActualPlaybackInfo(generatedOTP.playbackInfo);
      if (generatedOTP.videoUrl && generatedOTP.otp !== null && generatedOTP.otp !== "" && generatedOTP.playbackInfo !== null && generatedOTP.playbackInfo !== "") {
        setIframeSrc(generatedOTP.videoUrl);
        setIsReady(true);
      }

      else {
        setIframeSrc("");
        setIsReady(false);
      }
    }
  }, [generatedOTP]);

  useEffect(() => {
    if (entity) {
      
      setGeneratedOTP(entity.otp);
    }
  }, [entity]);

  const handleDelete = () => {
    const isConfirmed = window.confirm('Are you sure you want to delete this video?');
    if (isConfirmed) {
      const blankInfo = {
        clientPayload:{},
        videoId:""
      }
      changedid(blankInfo);
          // dispatch(clearPreSignData(videoKey));
          setIsReady(false);
          setGeneratedOTP({})
          setActualOTP("")
          setActualPlaybackInfo("")
          setIframeSrc("")
    } else {
      
      return;

  };
    };
  const handleChangeIntroWithoutMusic = (info) => {
    // dispatch(clearPreSignData(videoKey,id));
    changedid(info);
  }
  return (
    <div>
      {isReady ? (
  <div className="videoContainer">
  <button className="deleteButton" onClick={handleDelete}>
  <FontAwesomeIcon icon="times" color="#dc6d6d" className="true" />
  </button>
  <iframe
    style={{ height: '360px' }}
    src={iframeSrc}
    allowFullScreen="true"
    allow="encrypted-media"
  />
</div>
      ): 
      <IntroWithoutMusicUploader
      id="IntroWithoutMusic"
      onUploadInfo={handleChangeIntroWithoutMusic}
      folderName={foldername}
      />
      }
    </div>
  );
}

export default RenderIntroWithoutMusic;
