//EvaluationPromise
///////////////////////////////////////////
import { Promise } from 'q';
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import axios, { put, post } from 'axios'
import store from '../Store'


export function saveEvaluation(evaluation) {

    return new Promise(function (resolve, reject) {

        const url = "/Evaluation/addEvaluation";

        const formData = new FormData();
        formData.append('Start_Number', evaluation.startNumber);
        formData.append('End_Number', evaluation.endNumber);
        formData.append('Recommendation', evaluation.recommendation);
        formData.append('Uploaded_File', evaluation.uploadedFile);
        formData.append('Blogs', evaluation.blogs);
        formData.append('Article_URL', evaluation.articleUrl);

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res) => {
            console.log("Working Save Evaluation")
            resolve(res)
            return res.data
        }).catch((Error) => {
            console.log(Error)
            reject(Error)
            return Error.message
        })
    })

}

export function saveEvaluationCategory(excellent_Id, veryGood_Id, good_Id, fairlyGood_Id, bad_Id, activity_Id) {
    console.log("saveEvaluationCategory")
    console.log(excellent_Id, veryGood_Id, good_Id, fairlyGood_Id, bad_Id, activity_Id)
    return new Promise(function (resolve, reject) {

        const url = "/EvaluationCategory/addEvaluationCategory";

        const formData = new FormData();
        formData.append('Excellent_Evaluation_ID', excellent_Id);
        formData.append('Very_Good_Evaluation_ID', veryGood_Id);
        formData.append('Good_Evaluation_ID', good_Id);
        formData.append('Fairly_Evaluation_ID', fairlyGood_Id);
        formData.append('Bad_Evaluation_ID', bad_Id);
        formData.append('Activity_ID', activity_Id);

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res) => {
            resolve(res)
            return res.data
        }).catch((Error) => {
            console.log(Error)
            reject(Error)
            return Error.message

        })
    })
}

export function saveListOfEvaluation(evaluationsList) {
    return new Promise(async function (resolve, reject) {

        // let evaluationsList = store.getState().evaluation.items;
        let excellent_id = 0, veryGood_id = 0, good_id = 0, fairlyGood_id = 0, bad_id = 0;

        console.log("saveListOfEvaluation")
        console.log(evaluationsList)

        for (let i = 0; i < evaluationsList.length; i++) {

            console.log(evaluationsList[i])


            let result = await saveEvaluation(evaluationsList[i])

            console.log(evaluationsList[i].evaluation_category)

            if (evaluationsList[i].evaluation_category === "excellent") {
                console.log("excellent")
                console.log(result.data.id)
                excellent_id = result.data.id
            }
            if (evaluationsList[i].evaluation_category === "veryGood") {
                console.log("veryGood")
                console.log(result.data.id)
                veryGood_id = result.data.id
            }
            if (evaluationsList[i].evaluation_category === "good") {
                console.log("good")
                console.log(result.data.id)
                good_id = result.data.id
            }
            if (evaluationsList[i].evaluation_category === "fairlyGood") {
                console.log("fairlyGood")
                console.log(result.data.id)
                fairlyGood_id = result.data.id
            }
            if (evaluationsList[i].evaluation_category === "bad") {
                console.log("bad")
                console.log(result.data.id)
                bad_id = result.data.id
            }
        }

        let evaluationsID = {
            excellent_id: excellent_id,
            veryGood_id: veryGood_id,
            good_id: good_id,
            fairlyGood_id: fairlyGood_id,
            bad_id: bad_id
        }
        console.log("evaluationsID")
        console.log(evaluationsID)

        resolve(evaluationsID)
        return evaluationsID;
    })

}


export function saveAllEvaluationsWithCategoryIDs(activityID) {
    console.log("ActivityID in saveAllWithCategory " + activityID)
    return new Promise(function (resolve, reject) {

        let evaluationsList = store.getState().evaluation.items;
        saveListOfEvaluation(evaluationsList).then((res) => {

            console.log(res)
            console.log(res.excellent_id,
                res.veryGood_id, res.good_id, res.fairlyGood_id,
                res.bad_id, activityID)

            if (res.excellent_id !== 0 ||
                res.veryGood_id !== 0 ||
                res.good_id !== 0 ||
                res.fairlyGood_id !== 0 ||
                res.bad_id !== 0) {


                console.log(" Enter  saveEvaluationCategory")

                saveEvaluationCategory(res.excellent_id,
                    res.veryGood_id, res.good_id, res.fairlyGood_id,
                    res.bad_id, activityID).then((res) => {

                        resolve(res);
                        return "sucess";

                    }).catch((Error) => {
                        reject(Error);
                        return "error"
                    })
            }
        }).catch((Error) => {

            reject(Error);
            return "error"
        })

    })
}


export function updateEvaluationCategory(excellent_Id, veryGood_Id, good_Id,
    fairlyGood_Id, bad_Id, evaluationCategoryId) {

    console.log("updateEvaluationCategory")
    console.log(excellent_Id, veryGood_Id, good_Id,
        fairlyGood_Id, bad_Id, evaluationCategoryId)
    return new Promise(function (resolve, reject) {

        const url = "/EvaluationCategory/updateEvaluationCategory";

        const formData = new FormData();
        formData.append('Evaluation_Category_ID', evaluationCategoryId);
        formData.append('Excellent_Evaluation_ID', excellent_Id);
        formData.append('Very_Good_Evaluation_ID', veryGood_Id);
        formData.append('Good_Evaluation_ID', good_Id);
        formData.append('Fairly_Evaluation_ID', fairlyGood_Id);
        formData.append('Bad_Evaluation_ID', bad_Id);
        formData.append('Activity_ID', 0);

        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        put(url, formData, config).then((res) => {
            resolve(res)
            return res.data
        }).catch((Error) => {
            console.log(Error)
            reject(Error)
            return Error.message

        })
    })
}


export function saveOneEvaluationsWithCategoryIDs(start_number, end_number,
    recommendation, fileURL, selectedBlogsIDs, category_type, evaluationCategoryId, articleUrl) {

    return new Promise(function (resolve, reject) {

        let evaluationsList = [];
        let evaluation = {
            startNumber: start_number,
            endNumber: end_number,
            recommendation: recommendation,
            uploadedFile: fileURL,
            blogs: selectedBlogsIDs,
            evaluation_category: category_type,
            articleUrl:articleUrl
        }

        evaluationsList.push(evaluation);

        saveListOfEvaluation(evaluationsList).then((res) => {

            console.log(res)
            console.log(res.excellent_id,
                res.veryGood_id, res.good_id, res.fairlyGood_id,
                res.bad_id, evaluationCategoryId)

            updateEvaluationCategory(res.excellent_id,
                res.veryGood_id, res.good_id, res.fairlyGood_id,
                res.bad_id, evaluationCategoryId).then((res) => {

                    resolve(res);
                    return "sucess";

                }).catch((Error) => {
                    reject(Error);
                    return Error.message
                })

        }).catch((Error) => {

            reject(Error);
            return Error.message
        })

    })
}


export function saveOneEvaluationsWithoutCategoryIDs(start_number, end_number,
    recommendation, fileURL, selectedBlogsIDs, category_type, activityID, articleUrl) {

    return new Promise(function (resolve, reject) {

        let evaluationsList = [];
        let evaluation = {
            startNumber: start_number,
            endNumber: end_number,
            recommendation: recommendation,
            uploadedFile: fileURL,
            blogs: selectedBlogsIDs,
            evaluation_category: category_type,
            articleUrl:articleUrl
        }

        evaluationsList.push(evaluation);

        saveListOfEvaluation(evaluationsList).then((res) => {

            console.log(res)
            console.log(res.excellent_id,
                res.veryGood_id, res.good_id, res.fairlyGood_id,
                res.bad_id, activityID)

            saveEvaluationCategory(res.excellent_id,
                res.veryGood_id, res.good_id, res.fairlyGood_id,
                res.bad_id, activityID).then((res) => {

                    console.log(res.data)
                    resolve(res);
                    return res.data;

                }).catch((Error) => {
                    reject(Error);
                    return "error"
                })

        }).catch((Error) => {

            reject(Error);
            return Error.message
        })

    })
}


export function updateEvaluation(id, start_number, end_number,
    recommendation, fileURL, selectedBlogsIDs, articleUrl) {
    return new Promise(function (resolve, reject) {

        console.log("id = " +id)
        console.log("start_number = " + start_number)
        console.log("end_number = " +end_number)
        console.log("recommendation = " +recommendation)
        console.log("fileURL = " +fileURL)
        console.log("articleUrl = " +articleUrl)
    
    
        const url = "/Evaluation/updateEvaluation";

        const formData = new FormData();
        formData.append('Evaluation_Id', id);
        formData.append('Start_Number', start_number);
        formData.append('End_Number', end_number);
        formData.append('Recommendation', recommendation);
        formData.append('Uploaded_File', fileURL);
        formData.append('Article_URL',articleUrl)
        // formData.append('Blogs', evaluation.blogs);
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        put(url, formData, config).then((res) => {
            console.log("Working update Evaluation")
            resolve(res)
            return res.data
        }).catch((Error) => {
            console.log(Error)
            reject(Error)
            return Error.message
        })
    })

}


