import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {
    Dropdown, Input,
    Button
} from 'semantic-ui-react';
import { withFirebase } from '../../config'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Components */
import QRCCodeList from './QRCodeList'
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import VendorManagement from '../Layout/VendorManagement';

/* Actions */
import {
    getDownloadLink,
    uploadQrCardTemplate
} from '../../Actions/SecureRandomNumberActions'



class QRCardUpload extends Component 
{
    state = {
        cardsNumber: 0,
        paymentPackageType: 'Monthly',
        vendorId: 0,
        cardFile:'',
        //uploadedCardImageMsg:'',
    }

    constructor(props) {
        super(props);
        //this.props.emptySecureRandomNumberItems();
    }
    
    //const [file, setFile] = useState();

	handleFileChange = (e) => {
		this.setState({ cardFile: e.target.files[0] });
	}

    handleSubmit = (e) => {
	//alert('Handle submit');

        const { cardFile } = this.state;

        this.props.uploadQrCardTemplate(cardFile);

        e.preventDefault();
    }


        
    render() {

        const { loading, Success, Error, downloadLink,stopShowLoad,cardFile,uploadedCardImageMsg } = this.props;

        console.log('Props: '+JSON.stringify(this.props,null,3));
        

        const validate = this.state.cardsNumber == 0 ||
            this.state.vendorId == 0;


        let button = (
            <Button
                id="nextButton" onClick={this.handleSubmit} >
                Upload
            </Button>)

        if (loading === true  ) 
        {	
         	console.log('stopShowLoad :'+stopShowLoad);   
        }
        else if (loading === true && !stopShowLoad) 
        {
			/*
			console.log(' stopShowLoad :'+stopShowLoad);
			alert('not loading , stopShowLoad: '+stopShowLoad);
		            button = (
		                <button
		                    id="qrCodesBtnId"
		                    className="ui loading button">
		                    Loading
		                </button>
		            );
		            */
        }


		if(uploadedCardImageMsg === 'Succ')
		{
			
		}
		if (Success === true) 
		{
            window.open(downloadLink);
            // console.log(downloadLink);
            // alert("Data Generated Successfully ")
            this.props.emptySecureRandomNumber();
        }
        else if (Success === false) 
        {
            alert(Error)
            this.props.emptySecureRandomNumber();
        }

        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body"
                                            id="left-main-body">
                                            <VendorManagement />
                                            <div className="box-container">


                                                {/* <!--  start status bar --> */}
                                                {/* <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to="/">Home</Link><span> > </span>
                                                    </span>
                                                    <span>QR Codes Generator</span>

                                                </div> */}
                                                {/* <!--  end status bar --> */}

                                                <div className="col-12 left-main-body" id="left-main-body">

                                                </div>

                                                {/* <h5 className="main-title mt-2 font-weight-bold">QR Codes Generator</h5> */}


                                                <br />
                                                <div className="col-12 left-main-body" id="left-main-body">

                                                </div>
                                                <div className="card mt-3 p-0">
                                                    <div className="card-body p-4">

                                                        <br />
                                                        <label htmlFor="episode_number"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            File Upload</label>
                                                        <br />
                                                        
                                                        <input id="file" type="file" onChange={this.handleFileChange} />
                                                        
                                                        <br />
                                                        <br />

                                                        <div className="buttons_container">

                                                            {button}

                                                        </div>
<hr/>

		{/*uploadedFile && <img src={uploadedFile} alt="Uploaded content"/>*/}
        {/*error && <p>Error uploading file: {error.message}</p>*/}
        
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


QRCardUpload.propTypes = { 
       
    getDownloadLink: propTypes.func.isRequired,
    uploadQrCardTemplate: propTypes.func.isRequired,
    
}

const mapStateToProps = state => ({
    loading: state.randomNumbers.loading,
    Error: state.randomNumbers.error_get_secure_random_number,
    Success: state.randomNumbers.get_secure_random_number_successfully,
    downloadLink: state.randomNumbers.download_link,
    cardFile:'',
    uploadedCardImageMsg:state.randomNumbers.uploadedCardImageMsg,
    
})

export default withFirebase
    (connect
        (mapStateToProps,
            {
                getDownloadLink,
                uploadQrCardTemplate,                
            }
        )
        (QRCardUpload)
    );
