import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
/*navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { addPdfBook } from '../../Actions/PdfBookAction'
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';
// import { fetchAllRadio } from '../../Actions/RadioActions';
import { addActivity } from '../../Actions/ActivityActions';
/*CSS */
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/Songs.css';
/**Other Component */
import PublishDate from '../Layout/PublishDate';
import UploadImage from '../S3Uploading/UploadImage';
import UploadAudio from '../S3Uploading/UploadAudio';
import { ADD_PDF_BOOK_ACTIVITY, ALL_PDF_BOOK } from '../../routes';
// import DeletePage from './DeletePage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadPageImage from '../S3Uploading/UploadPageImage';
import UploadPdfFile from '../S3Uploading/UploadPdfFile';
import { Popup } from 'semantic-ui-react'
import CSVReader from 'react-csv-reader'
const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]


const INITIAL_STATE = {
    redirection: false,
    name: "",
    description: "",
    imageUrl: "",
    pdfFile: "",
    titleAudio: "",

    showfileUploader: true,

    isFree: '',

    auth_user: {},
    publishDateTime: "",
    bookbutton: '',
    voice: '',
    subValueId: '',
    featured: false,
    ////////////////// validation form
    error: true,
    errorMessage: "",

    // Book Pages
    // pages: []

}

class AddPdfBook extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE

    }
    componentDidMount() {
        // this.props.fetchAllRadio();
        this.props.fetchAllSubValues();


        const { pdfBook } = this.props;
        let showfileUploaderStatus = pdfBook.pdfFile && pdfBook.pdfFile != '' && pdfBook.pdfFile != null ? false : true
        this.setState({
            error: false,
            name: pdfBook.name,
            imageUrl: pdfBook.image,
            publishDateTime: pdfBook.PublishDate,
            titleAudio: pdfBook.titleAudio,
            pdfFile: pdfBook.pdfFile,

            showfileUploader: showfileUploaderStatus, // True /  False

            isFree: pdfBook.isFree,
            voice: pdfBook.voice,
            subValueId: pdfBook.subValueId,
            description: pdfBook.description,


            bookbutton: pdfBook.bookbutton,
        })
    }

    getFileUrl(fileURL) {
        if (fileURL) {
            this.setState({
                pdfFile: fileURL,
                showfileUploader: false
            });
        }
    }

    handleFileUploaderPreview = (e) => {
        const { showfileUploader } = this.state;

        if (showfileUploader === true) {
            this.setState({
                showfileUploader: false
            })
        }
        else {
            this.setState({
                showfileUploader: true
            })
        }
    }


    handleDateChange = (e, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            if (
                (this.state.name === ''
                    || this.state.name === null
                    || this.state.name === ' ')
            ) {
                this.setState({
                    error: true,
                    errorMessage: "Empty Data is not accepted"
                });
            }
            else if (
                (this.state.name !== ''
                    && this.state.name !== null
                    && this.state.name !== ' ')
            ) {
                this.setState({ error: false, errorMessage: "" });
            }
        }, 0);
    }

    handlePaymentAva = (e, { value }) => {

        console.log(value)

        this.setState({
            isFree: value
        })
    }


    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const { name, description,
            imageUrl,
            publishDateTime,
            pdfFile,
            titleAudio,
            bookbutton, isFree,
            voice, 
            subValueId,
            featured
            } = this.state;

        if (e.nativeEvent.submitter.id === "nextButton") {

            if (isFree === ''
                || isFree == undefined
                || isFree == null
                || isFree == "") {
                alert("You must choose payment availability")
                this.setState({ validateInput: false })
                e.preventDefault();
            } else if (publishDateTime === ''
                || publishDateTime == undefined) {
                this.setState({ validateInput: false })
            } else {
                if (name === ''
                    || name == undefined) {
                    alert("Empty Name ")
                    this.setState({ validateInput: false })
                } else {
                    this.setState({ validateInput: true });
                    this.props.addPdfBook(name,
                        description, imageUrl,
                        publishDateTime,
                        pdfFile,
                        titleAudio,
                        bookbutton,
                        isFree,
                        subValueId,
                        featured
                    )
                }
            }
        }
        /// calling save
    }

    handleCancel = (e) => {
        this.setState({ redirection: true })
    }

    getPublishDate(publishDateTime, bookButtonState) {
        if (bookButtonState) {
            this.setState({ bookbutton: bookButtonState })
        }

        if (publishDateTime) {
            this.setState({ publishDateTime: publishDateTime });
        }
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };

    getImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({
                imageUrl: '',
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
        else if (imageUrl !== undefined && imageUrl !== '') {
            this.setState({ imageUrl: imageUrl });
            if (this.state.name !== ''
                && this.state.name !== ' '
                && this.state.name !== null
            ) {
                this.setState({
                    error: false,
                    errorMessage: ""
                });
            }
        }
    }

    getAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ voice: '' });
                console.log("audio URL in add episode  if = "
                    + this.state.voice);
            }
            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
                console.log("audio URL in add episode else = "
                    + this.state.voice);

            }

        }
    }

    getTitleAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ titleAudio: '' });
                console.log("audio URL in add episode  if = "
                    + this.state.titleAudio);
            }
            // else send the url to DB
            else {
                this.setState({ titleAudio: audioUrl });
                console.log("audio URL in add episode else = "
                    + this.state.titleAudio);

            }

        }
    }
    getBookAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ bookAudio: '' });
                console.log("audio URL in add episode  if = "
                    + this.state.bookAudio);
            }
            // else send the url to DB
            else {
                this.setState({ bookAudio: audioUrl });
                console.log("audio URL in add episode else = "
                    + this.state.bookAudio);

            }

        }
    }



    onCSVFileLoaded = (data, fileInfo, originalFile) => {
        if (data) {
            this.setState({
                audioMarker: JSON.stringify(data),
                showfileUploader: false
            });
            // alert("The file has been uploaded successfully")
        }
    }
    onCSVFileError = () => {
        alert("Marker Upload Failed")
    }

    render() {
        const papaparseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
        };

        const { name, description,
            imageUrl,
            publishDateTime,
            titleAudio,
            // bookAudio,
            pdfFile,
            bookbutton, isFree,
            subValueId,
            // selected_radio_id,
            // selected_radio_name,
            showfileUploader } = this.state;

        const isInvalid =
            name === '' || name == undefined ||
            imageUrl === '' || imageUrl == undefined ||
            // pages === '' || pages == undefined ||
            isFree === '' || isFree == undefined ||
            // selected_radio_id === '' || selected_radio_id == undefined ||
            // selected_radio_name === '' || selected_radio_name == undefined ||
            titleAudio === '' || titleAudio == undefined ||
            pdfFile === '' || pdfFile == undefined;

        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }

        if (this.state.redirection === true) {
            const { redirectionPage } = this.props;
            var pageName = redirectionPage.pageName
            return (<Redirect to={ALL_PDF_BOOK} ></Redirect>)
        }


        if (this.state.validateInput === true) {
            return (<Redirect to={ADD_PDF_BOOK_ACTIVITY}></Redirect>)
        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body"
                                            id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="main-progress-bar">
                                                    <ol className="progress-tracker">
                                                        <li className="step active"><span className="step-name small-font">Add Pdf Book</span></li>
                                                        <li className="step"><span className="step-name small-font">Add activity</span></li>
                                                        <li className="step "><span className="step-name small-font">Add Evaluation</span></li>
                                                        <li className="step"><span className="step-name small-font">Puplish Pdf Book</span></li>
                                                    </ol>
                                                </div>
                                                <br />

                                                <form onSubmit={this.handleSubmit.bind(this)} >
                                                    <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                                                    <br />

                                                    <div className="row">
                                                        <div className="col-4">
                                                            {/* <span className="required_input">*</span> */}
                                                            <UploadImage
                                                                getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                imageUrl={this.state.imageUrl}
                                                                imagepreview={this.state.imageUrl}
                                                                s3DirectryrName='pdfBook'
                                                            />

                                                        </div>
                                                        <div className="col">
                                                            <label htmlFor="song_name" className="label">
                                                                <span className="required_input">*</span>
                                                                Book Name</label><br />

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                fluid name="name" id="name"
                                                                onChange={this.handleChange}
                                                                value={this.state.name} />
                                                            <br />

                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Book Description</label><br />

                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1" rows="3"
                                                                name="description" onChange={this.handleChange}
                                                                value={this.state.description} />
                                                            <br />

                                                            <label htmlFor="name" className="label">
                                                                Title Audio
                                                            </label>
                                                            <UploadAudio
                                                                getAudioUrlCallBack={this.getTitleAudio.bind(this)}

                                                                audioPreview={this.state.titleAudio}
                                                                audioUrl={this.state.titleAudio}
                                                                s3DirectryrName="pdfBook/audio"
                                                            />
                                                            <br />

                                                            <label htmlFor="name" className="label">
                                                            {/* <span className="required_input">*</span> */}
                                                                Pdf Book File
                                                            </label>
                                                            {
                                                                this.state.pdfFile && <a href={this.state.pdfFile} download className='d-block mb-2'>{this.state.pdfFile}</a>
                                                            }
                                                            
                                                            <UploadPdfFile
                                                                getFileURLCallBack={this.getFileUrl.bind(this)}
                                                                fileURL={this.state.pdfFile}
                                                                s3DirectryrName='pdfBook/pdf' />
                                                            <br />
                                                            {/* <label htmlFor="name" className="label">
                                                                Audio Marker
                                                            </label>
                                                            <br /> */}
                                                            {/* {fileLink}
                                                            {uploadFile} */}



                                                            <br />

                                                            <br />

                                                            <label htmlFor="episode_series_id"
                                                                className="label">
                                                                <span className="required_input">*</span>
                                                                Select payment availability</label><br />

                                                            <Dropdown required fluid selection
                                                                placeholder='Select Payment Availability'
                                                                id="episode_series_id"
                                                                onChange={this.handlePaymentAva}
                                                                options={is_free_type}
                                                                value={this.state.isFree}
                                                            />
                                                            <br />




                                                            <label htmlFor="episode_series_id"
                                                                className="label">
                                                                Sub Value</label><br />

                                                            <Dropdown required fluid search selection
                                                                placeholder='Select Sub Value'
                                                                id="episode_series_id"
                                                                onChange={this.handleSubValueChange}
                                                                options={subValuesList}
                                                                value={this.state.subValueId}
                                                            />
                                                            <br />


                                                            {/* DataComponent */}
                                                            {this.state.publishDateTime !== " " &&
                                                                this.state.publishDateTime !== '' &&
                                                                this.state.publishDateTime !== null &&
                                                                this.state.publishDateTime !== undefined ?
                                                                <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                    date={this.state.publishDateTime}
                                                                    showNow={!isInvalid} />

                                                                :
                                                                <PublishDate
                                                                    getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                    value={this.state.publishDateTime}
                                                                    showNow={!isInvalid} />}
                                                                <br />
                                                                <label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={this.state.featured}
                                                                    onChange={this.handleCheckboxChange}
                                                                />
                                                                {' Featured'}
                                                                </label>
                                                            <br />

                                                        </div>


                                                        <br />
                                                        <div className="buttons_container">

                                                            <Button icon labelPosition='right'
                                                                id="nextButton"
                                                                disabled={this.state.error}>
                                                                Next
                                                                <Icon name='right arrow' />
                                                            </Button>

                                                            <Button
                                                                onClick={this.handleCancel}
                                                                id="episode_cancel">Cancel</Button>

                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>

                        </div>



                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

AddPdfBook.propTypes = {
    addPdfBook: propTypes.func.isRequired,
    addActivity: propTypes.func.isRequired,
    fetchAllSubValues: propTypes.func.isRequired

}
const mapStateToProps = state => ({
    pdfBook: state.pdfBook.item,
    activities: state.activities.item,
    allSubValues: state.ContentSubValue.items,
    // radio: state.radio.items,
    redirectionPage: state.redirectionPage.item
});

export default withFirebase(
    connect(
        mapStateToProps,
        {
            addPdfBook,
            addActivity,
            fetchAllSubValues,
            // fetchAllRadio
        })(AddPdfBook));
