import {
    LOAD_VENDOR_REPORT,
    FETCH_ALL_VENDOR_REPORT, FETCH_ALL_VENDOR_REPORT_ERROR,
    EMPTY_VENDOR_REPORT

} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    loading: '',

    get_all_vendors_report_successfully: '',
    get_all_vendors_report_error: ''
}

export default function (state = initialState, action) {
    switch (action.type) {


        case LOAD_VENDOR_REPORT:
            return {
                ...state,
                loading: true
            }
        case FETCH_ALL_VENDOR_REPORT:
            return {
                ...state,
                items: action.payload,
                get_all_vendors_report_error: '',
                get_all_vendors_report_successfully: true,
                loading: false
            }
        case FETCH_ALL_VENDOR_REPORT_ERROR:
            return {
                ...state,
                get_all_vendors_report_error: action.payload,
                loading: false
            }
        case EMPTY_VENDOR_REPORT:
            return {
                ...state,
                get_all_vendors_report_error: '',
                get_all_vendors_report_successfully: ''

            }
        default:
            return state

    }
}