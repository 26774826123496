import {post} from 'axios'
import { LOAD_SUBSCRIPTIONS_REPORT,
         GET_SUBSCRIPTIONS_REPORT,
         ERROR_LOADING_SUBSCRIPTIONS_REPORT,
         EMPTY_SUBSCRIPTIONS_REPORT} from './Types';

export const getSubscriptionsReport =(startDate,endDate)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_SUBSCRIPTIONS_REPORT,
            })
            const url = "/subsReport/getSubscriptionsReport"
            const formData = new FormData();
            formData.append("Start_Date",startDate)
            formData.append("End_Date",endDate)
            const config = {
                headers:{
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            
            }
            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:GET_SUBSCRIPTIONS_REPORT,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_SUBSCRIPTIONS_REPORT,
                    payload:Error.message
                })
            })
        })
    }
}




export const getMondiaSubscriptionsReport =(startDate,endDate)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_SUBSCRIPTIONS_REPORT,
            })
            const url = "/subsReport/getMondiaSubscriptionsReport"
            const formData = new FormData();
            formData.append("Start_Date",startDate)
            formData.append("End_Date",endDate)
            const config = {
                headers:{
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            
            }
            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:GET_SUBSCRIPTIONS_REPORT,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_SUBSCRIPTIONS_REPORT,
                    payload:Error.message
                })
            })
        })
    }
}

export const emptyReport =()=> dispatch=>
{
    dispatch({
        type:EMPTY_SUBSCRIPTIONS_REPORT,
        payload:[]
    })
}