import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'



/* images */
import logo from "../../images/logo.png";

/*Redux */
import { FAWRY_FAILURE, PORTAL_SUCCESSFUL } from '../../routes';
const INITIAL_STATE = {
    merchantRefNumber: '',
    fawryRefNumber:null,
    signature: '',

}

class FawrySuccess extends Component {

    constructor(props) {
        super(props);
        var url_string = window.location.href;
        var url = new URL(url_string);
        var RefNumber = url.searchParams.get("referenceNumber");
        this.state = { 
            fawryRefNumber:RefNumber,
         };
      }


   

    render() {
       
        if (this.state.fawryRefNumber === null) {
         
            return  <Redirect to={FAWRY_FAILURE}> </Redirect>;
        }

 

        return (
            <section className="main-payment custom-main-payment">
                <div className="container">
                    <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
                        <div className="d-flex align-items-center">
                            <Link className="navbar-brand d-flex align-items-center" to="/">
                                <img src={logo} alt="" />
                            </Link>
                            <div className="complete-process d-none d-sm-block">
                                بوابة الدفع لجيل
                            </div>
                        </div>
                        <Link className="btn back-btn" type="button" to="/">
                            العوده
                        </Link>
                    </div>
                </div>

                <div className="container leftToRight">
                    <div className="pay-box">
                        <div>
                            <div className=" card card-container Current-Package mb-4 fawry-card">
                                <div className="card-header profile-name-card text-right">
                                    <span className="h4 font">مرحبا</span>
                                </div>
                                <div className="tab-button-outer">
                                    <div id="tab02" className="tab-contents">
                                        <div className="  card-body">
                                            <div><div className="  h6 text-center mb-4  font">
                                                تمت العمليه بنجاح , رقم فورى للدفع
                                            </div>
                                            <div className="  h5 text-center mb-4  font">
                                                {this.state.fawryRefNumber }
                                            </div></div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}





export default FawrySuccess
    
