import {
    FETCH_All_PACKAGE_PAYMENT,
    ERROR_FETCH_All_PACKAGE_PAYMENT,
    LOAD_PACKAGE_PAYMENT,
    ADD_PAYMENT_DATA, DELETE_PAYMENT_DATA,
    ADD_AUTO_RENEWEL,
    UPDATE_All_PACKAGE_PAYMENT,
    ERROR_UPDATE_All_PACKAGE_PAYMENT,
    EMPTY_PACKAGE_PAYMENT
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    payingData: {},
    autoRenewel: '',

    loading: '',
    getting_all_payment_package_data_error: '',
    getting_all_payment_package_data_sucessfully: '',


    update_all_payment_package_data_error: '',
    update_all_payment_package_data_sucessfully: '',

}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_AUTO_RENEWEL:
            return {
                ...state,
                autoRenewel: action.payload
            }

        case ADD_PAYMENT_DATA:
            return {
                ...state,
                payingData: action.payload

            }
        case DELETE_PAYMENT_DATA:
            return {
                ...state,
                payingData: ''
            }

        case FETCH_All_PACKAGE_PAYMENT:
            return {
                ...state,
                items: action.payload,
                getting_all_payment_package_data_error: '',
                getting_all_payment_package_data_sucessfully: true,
                loading: false
            }

        case ERROR_FETCH_All_PACKAGE_PAYMENT:
            return {
                ...state,
                getting_all_payment_package_data_error: action.payload,
                loading: false
            }

        case UPDATE_All_PACKAGE_PAYMENT:
            return {
                ...state,
                items: action.payload,
                update_all_payment_package_data_error: '',
                update_all_payment_package_data_sucessfully: true,
                loading: false
            }

        case ERROR_UPDATE_All_PACKAGE_PAYMENT:
            return {
                ...state,
                update_all_payment_package_data_error: action.payload,
                loading: false
            }

        case LOAD_PACKAGE_PAYMENT:
            return {
                ...state,
                loading: true
            }

            case EMPTY_PACKAGE_PAYMENT:
                return {
                    ...state,
                    update_all_payment_package_data_error:'',
                    update_all_payment_package_data_sucessfully:''
                }
        default:
            return state

    }

}
