import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

/*Actions*/
import {
    emptyMobileUserProfile,
    emptyMobileUserProfileWithItem
} from '../../Actions/MobilePaymentProfileAction';
import { emptyToken } from '../../Actions/loginActions.js'
import { removeFawryRedirectPage } from '../../Actions/FawryRedirectionActions';


/* images */
import logo from "../../images/logo.png";

/*Redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { FAWRY_FAILURE, FAWRY_SUCCESS, PORTAL_SUCCESSFUL } from '../../routes';
import { getFawryPaymentStatus, emptyFawryPay } from '../../Actions/FawryPayAction';
import { sha256 } from "js-sha256";
import { E_Wallet_Payment_Method_Type, Fawry_Payment_Method_Type, MERCHANT_CODE, SECURE_KEY } from '../../portal/src/constants';
import { emptyCodeFromRedux, emptyPromoCode } from '../../Actions/PromoCodesActions';
const INITIAL_STATE = {
    merchantRefNumber: '',
    fawryRefNumber:null,
    signature: '',
    paymentMethodtype:'',

}
class FawryResult extends Component {

    constructor(props) {
        super(props);
        // this.props.emptyFawryPay();
        var url_string = window.location.href;
        var url = new URL(url_string);
        var merchantNumber= url.searchParams.get("merchantRefNumber");
        var RefNumber = url.searchParams.get("referenceNumber");
        var sign = url.searchParams.get("signature");
        var paymentMethod = url.searchParams.get("paymentMethod");
        this.state = { 
            merchantRefNumber:merchantNumber,
            fawryRefNumber:RefNumber,
            signature:sign,
            paymentMethodtype: paymentMethod,
         };
        this.props.emptyCodeFromRedux();
        this.props.emptyPromoCode(); 
      }

      getSignature = () => {
        var hashData = MERCHANT_CODE + this.state.merchantRefNumber + SECURE_KEY;  
        var signature = sha256(hashData);
        return signature;
    }

    componentDidMount() {
        if(this.state.paymentMethodtype === E_Wallet_Payment_Method_Type){
            this.props.getFawryPaymentStatus(MERCHANT_CODE,this.state.merchantRefNumber, this.getSignature());
        }
    }

    render() {
        const { isFawryloading, fawryStatus, error } = this.props;
        
        //Redirections
        if (this.state.fawryRefNumber === null) {
            return  <Redirect to={FAWRY_FAILURE}> </Redirect>;
        }

        if(!isFawryloading && fawryStatus!='' && fawryStatus.orderStatus ==="PAID"){
            return <Redirect to={PORTAL_SUCCESSFUL}> </Redirect>;
        }

        if(!isFawryloading && error!='' ){
            return <Redirect to={FAWRY_FAILURE}></Redirect>;
        }

        if(this.state.paymentMethodtype === Fawry_Payment_Method_Type ){
            return <Redirect to={FAWRY_SUCCESS + `?referenceNumber=${this.state.fawryRefNumber}`}> </Redirect>;
        }


        return (
            <section className="main-payment custom-main-payment">
                <div className="container">
                    <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
                        <div className="d-flex align-items-center">
                            <Link className="navbar-brand d-flex align-items-center" to="/">
                                <img src={logo} alt="" />
                            </Link>
                            <div className="complete-process d-none d-sm-block">
                                بوابة الدفع لجيل
                            </div>
                        </div>
                        <Link className="btn back-btn" type="button" to="/">
                            العوده
                        </Link>
                    </div>
                </div>

                <div className="container leftToRight">
                    <div className="pay-box">
                        <div>
                            <div className=" card card-container Current-Package mb-4 fawry-card">
                                <div className="card-header profile-name-card text-right">
                                    <span className="h4 font">مرحبا</span>
                                </div>
                                <div className="tab-button-outer">
                                    <div id="tab02" className="tab-contents">
                                        <div className="  card-body">
                                           
                                         <div className="h6 text-center mb-4  font">  جاري التحميل ... </div> 
                                              
        
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}




FawryResult.propTypes = {
}
const mapStateToProps = state => ({
    userPaymentProfile: state.userPaymentProfile.item,
    redirectPage: state.fawryPaymentRedirect.item,
    token: state.user.token,
    fawryStatus: state.fawryPay.data,
    isFawryloading: state.fawryPay.loading,
    paymentMethodName: state.paymentData.paymentMethod,
    error: state.fawryPay.error,
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyToken, emptyMobileUserProfile,
                emptyMobileUserProfileWithItem,
                removeFawryRedirectPage,
                getFawryPaymentStatus,
                emptyFawryPay,
                emptyPromoCode,
                emptyCodeFromRedux
            }
        )
        (FawryResult)
    );
