import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Components */
import GetAllStories from './GetAllStories'
import AddStory from './AddStory';
import AddStoryActivity from './AddStoryActivity'
import PublishStory from './PublishStory';
import StoryDetails from './StoryDetails';
import StoryEvaluation from './StoryEvaluation';
import EditStory from './EditStory'
import EditActivityStory from './EditActivityStory';
import EditEvaluationStory from './EditEvaluationStory';

import GetAllStoryParents from '../StoryParent/GetAllStoryParents'
import AddStoryParent from '../StoryParent/AddStoryParent'
import EditStoryParent from '../StoryParent/EditStoryParent'
import ViewStoryParent from '../StoryParent/ViewStoryParent'

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import {
    SIGN_IN, ADMIN_LAND_PAGE
    // ,ADD_STORY,
    // ALL_STORIES, ADD_STORY_ACTIVITY, PUBLISH_STORY,
    // STORY_DETAIL, STORY_EVALUATION,
    // EDIT_STORY, EDIT_STORY_ACTIVITY,
    // EDIT_STORY_EVALUATION,
    // ALL_STORIES_PARENTS,
    // ADD_STORY_PARENT,
    // VIEW_STORY_PARENT,
    // EDIT_STORY_PARENT

} from '../../routes';

class StoryAuth extends Component {

    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    componentDidMount() {
        const { userPaymentProfile } = this.props;
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to={SIGN_IN}></Redirect>
        }
    }

    render() {
        const { userPaymentProfile } = this.props;

        if (userPaymentProfile.user_role.role === "Analyst") {
            return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
        }
        return (
            <span>
                {/* <Route path={ALL_STORIES_PARENTS} component={GetAllStoryParents} />
                <Route path={ADD_STORY_PARENT} component={AddStoryParent} />
                <Route path={VIEW_STORY_PARENT} component={ViewStoryParent} />
                <Route path={EDIT_STORY_PARENT} component={EditStoryParent} /> */}


                {/* adding story Routers */}
                {/* <Route path={ADD_STORY} component={AddStory} />
                <Route path={ALL_STORIES} component={GetAllStories} />
                <Route path={ADD_STORY_ACTIVITY} component={AddStoryActivity} />
                <Route path={PUBLISH_STORY} component={PublishStory} />
                <Route path={STORY_DETAIL} component={StoryDetails} />
                <Route path={STORY_EVALUATION} component={StoryEvaluation} />
                <Route path={EDIT_STORY} component={EditStory} />
                <Route path={EDIT_STORY_ACTIVITY} component={EditActivityStory} />
                <Route path={EDIT_STORY_EVALUATION} component={EditEvaluationStory} /> */}
            </span>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
    (connect
        (mapStateToProps,
            { getUserData }
        )(StoryAuth)
    );
