import React, { Component } from 'react'

import QRCode from 'qrcode'

import QrCode from 'react.qrcode.generator'
import { Button } from 'semantic-ui-react';

import { CSVLink } from "react-csv";

import JSZip from "jszip"
import { saveAs } from 'file-saver';

import { toFile } from '../../Promises/FileGenerator'

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


class QRCodeList extends Component {


    onDownload = () => {

        var opts = {
            errorCorrectionLevel: 'H',
            type: 'image/jpeg',
            quality: 0.3,
            margin: 1
        }

        const { items } = this.props;
        console.log(items)
        if ((!isEmpty(items)) && items !== undefined && items !== null &&
            items.length !== undefined && items.length > 0) {
            var zip = new JSZip();

            items.map((item) => {
                QRCode.toDataURL(item.code, opts, function (err, url) {
                    if (err) console.log(err)

                    console.log(url)
                    var x = url;
                    console.log(x)
                    //download(x, item.id + ".svg", "image/svg");
                    //var f = new File([x], item.id + ".svg", {type: "image/svg", lastModified: new Date()})
                    var f = toFile(x, item.id, "image/svg");
                    //alert(f);
                    zip.file(item.id + ".svg", f, { base64: true });
                })
            })

            //alert("not yet");
            zip.generateAsync({ type: "blob" }).then(function (content) {
                //  alert("hohohoho");
                saveAs(content, "qr.zip");
                console.log("Just below Comment");
            }).catch((error) => {
                //alert("weeeeeeeeeeeee");
                console.log(error);
            });
            // zip.generateAsync({type:"base64"}).then(function (base64) {
            //   saveAs(arr, "qr.zip");
            // });
        }
    }

    render() {

        // if ((!isEmpty(randomNumbersList)) && randomNumbersList !== undefined && randomNumbersList !== null &&
        //     randomNumbersList.length !== undefined && randomNumbersList.length > 0) {

        // }

        var opts = {
            errorCorrectionLevel: 'H',
            type: 'image/jpeg',
            quality: 0.3,
            margin: 1
        }
        const { items } = this.props; 
        console.log(items)
        if ((!isEmpty(items)) && items !== undefined && items !== null &&
            items.length !== undefined && items.length > 0) {



            let codes = [];
            var CSVbutton = ''
            var title = ["id", "codes"];
            codes.push(title);

            for (let i = 0; i < items.length; i++) {

                var obj = [];

                obj.push(items[i].id)
                obj.push("code: " + items[i].code)

                codes.push(obj)
            }

            CSVbutton = (
                <CSVLink
                    data={codes} filename={"Jeel Codes List.csv"}   >
                    <Button type="submit"
                        id="nextButton">
                        Download CSV
                    </Button>
                </CSVLink>)
            // console.log(items.length > 0)
            // console.log("return")
            // console.log(items)
            // console.log("length")
            // console.log(items.length)
            return (
                <div /*className="row align-items-start"*/>

                    <div className="buttons_container">
                        <Button id="nextButton" onClick={this.onDownload}> DownLoad QR</Button>

                        {CSVbutton}
                    </div>

                    {/* {items.map((item) => { */}

                    {/* QRCode.toDataURL(item, opts, function (err, url) {
                            if (err) console.log(err)

                            console.log(url)
                            var x= url;
                            console.log(x)
                            download(x, item+".jpeg","image/png");
                        }) */}

                    {/* return (
                            <span className="col">

                                <QrCode name="QRCode" className="test"
                                    id={item} value={item} style={{ width: 100 }} />

                                <br />

                                <span>{item}</span>
                                <br />
                                <br />
                            </span>)

                    }) */}
                    {/* } */}


                </div>
            )
        }
        else {
            return (
                <div>

                </div>
            )
        }
    }
}

export default QRCodeList
