import {
  LOAD_PRE_PAID_PAYMENT, ADD_PRE_PAID_PAYMENT,
  EMPTY_PRE_PAID_PAYMENT, ERROR_ADDING_PRE_PAID_PAYMENT
} from './Types'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import axios, { post } from 'axios';


export const paymentWithPrePaidCard = (prepaidCardNumber) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_PRE_PAID_PAYMENT,
                payload: 'loading'
            })


            const url = "/PaymentCard/activePrePaidCodePayment";
            const formData = new FormData();
            formData.append('QR_Code', prepaidCardNumber);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_PRE_PAID_PAYMENT,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_PRE_PAID_PAYMENT,
                    payload: Error.response.data.message,
                })
            })
        })

    }
}

export const emptyPrePaidPayment = () => dispatch => {
  dispatch({
      type: EMPTY_PRE_PAID_PAYMENT,
      payload: ''
  })
}
