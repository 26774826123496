import { ADD_ACTIVITY, LOAD_ACTIVITY, GET_ACTIVITY_DATA, ERROR_LOADING_ACTIVITY, EMPTY_ACTIVITY } from './Types';
import { post } from 'axios'
import { conditionalExpression } from '@babel/types';


export const addActivity = (name) => dispatch => {
    const activity = { name: name + " activity" };
    dispatch(
        {
            type: ADD_ACTIVITY,
            payload: activity
        }
    )
}

export const getActivityData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
          
            // console.log(id)

            dispatch({
                type: LOAD_ACTIVITY,
                payload: 'loading'
            })
            const url = "/Activity/getActivityDetails"
            const formData = new FormData();
            formData.append('Activity_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {

                // console.log(res.data)

                dispatch(
                    {
                        type: GET_ACTIVITY_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_ACTIVITY,
                    payload: Error.message
                })
            })

        })

    }
}

export const emptyActivity =()=> dispatch=>
{
    dispatch({
        type:EMPTY_ACTIVITY,
        payload:''
    })
}


