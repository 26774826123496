import {
      FETCH_ALL_COUPONS, LOAD_ALL_COUPONS, ERROR_ALL_COUPONS,
} from '../Actions/Types'

const initialState = {
    items: [],
    loading: '',
    getting_allCoupons_sucessfully: '',
    getting_allCoupons_error: '',
}

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_ALL_COUPONS:
            return {
                ...state,
                items: action.payload,
                getting_allCoupons_error: '',
                getting_allCoupons_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_COUPONS:
            return {
                ...state,
                getting_allCoupons_error: action.payload,
                loading: false
            }


            case LOAD_ALL_COUPONS:
                return {
                    ...state,
                    loading: true
                }
        default:
            return state
    }
}
