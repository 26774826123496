import React, { Component } from 'react'
import { connect } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Actions*/
import {
    setChannelStatus,
    emptyChannel
} from '../../Actions/RadioChannelAction'
import { getRadioData } from '../../Actions/RadioActions';

class SetRadioChannelState extends Component {

    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setChannelStatus(id, staus) {
        this.props.setChannelStatus(id, staus)
    }

    handleSubmit = (e) => {

        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setChannelStatus(id, "DEFAULT");

        } else {

            this.setChannelStatus(id, "SUSPENDED");
        }
        this.close();
    }
    render() {
        const { success,
            error } = this.props
        const { status, RadioId } = this.props

        let contentPreview = null;

        if (success === false) {
            alert("Error: " + error);
            this.props.emptyChannel();
            this.props.getRadioData(RadioId);
        }
        else if (success === true) {
            alert("Radio Channel status updated successfully");
            this.props.emptyChannel();
            this.props.getRadioData(RadioId);

        }

        if (status === "SUSPENDED") {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>
                        <i className="" >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )
        }
        else {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>||</button>}
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }

        return (<span >
            {contentPreview}
            <Confirm id="modal"
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </span>)
    }
}


const mapStateToProps = state =>
({
    success: state.radioChannel.channel_status_updated_successfully,
    error: state.radioChannel.error_setting_status
})
export default connect
    (mapStateToProps,
        {
            setChannelStatus,
            emptyChannel,
            getRadioData
        })
    (SetRadioChannelState)
