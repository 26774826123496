import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Redirect } from 'react-router-dom'
/*image */
import logo from "../../images/logo.png";
/*Actions*/
import { getUserHistory } from '../../Actions/MobilePaymentHistoryAction';
/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';
import Loader from '../Layout/Loader';
/*Redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import firebase from 'firebase/app'

const INITIAL_STATE = {
    token: ''
}

class MobilePaymentHistory extends Component {

    constructor(props) {

        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {
        this.props.getUserHistory();
    }

    redirect = () => {
        console.log("redirect")
        // firebase.auth().currentUser.getIdToken().then((token) => {
            const{token} = this.props;
            console.log(token)
            this.setState({ token: token })

        // })

    }

    render() {

        // back to previous page if browser back button clicked
        window.onhashchange = function() {
            this.props.history.goBack();
        }

          // redirect to login if not user
          const { userPaymentProfile } = this.props;

          //console.log(userPaymentProfile)
  
          if (userPaymentProfile.user_role !== undefined) {
              if (userPaymentProfile.user_role.role !== "USER") {
                  
                  firebase.auth().signOut()
                  return (<Redirect to="/MobilePaymentLogin"></Redirect>)
              }
          }

        const { userPaymentHistory } = this.props;
        const { error, loading } = this.props;

        let UserHistoryList = null
        let packageType = ''


        const { token } = this.state;
        if (token !== '') {
            var redirectLink = "/MobilePaymentProfile/" + token;
            //console.log(redirectLink)
            return (<Redirect to={redirectLink} ></Redirect>)

        }

        if (loading === true) {
            UserHistoryList = (<Loader />)
        }

        else if (loading === false &&
            error === '') {
            UserHistoryList = userPaymentHistory.length ? (
                userPaymentHistory.map(paymentHistoryItem => {
                    if (paymentHistoryItem.userPaymentPackageId.paymentPackageId.packageType
                        == 'Monthly') {
                        packageType = (
                            <span className="small-font mb-0 custom-margin">
                                &nbsp; الشهرية &nbsp;
                                </span>
                        )
                    }

                    else if (paymentHistoryItem.userPaymentPackageId.paymentPackageId.packageType
                        == 'Yearly') {
                        packageType = (
                            <span className="small-font mb-0 custom-margin">
                                &nbsp; السنويه&nbsp;
                                 </span>
                        )
                    }

                    else if (paymentHistoryItem.userPaymentPackageId.paymentPackageId.packageType
                        == 'Weekly') {
                        packageType = (
                            <span className="small-font mb-0 custom-margin">
                                &nbsp; الأسبوعية&nbsp;
                                 </span>
                        )
                    }

                    return (
                        <div className="row">
                            <div className="col-md-8 col-12">
                                <p className="small-font mb-0 custom-margin">
                                    تم الاشتراك فى الباقة
                                     {packageType}
                                    بتاريخ
                                </p>
                            </div>
                            <div className="col-md-4 col-12">
                                <p className="small-font eng-lang mb-0">
                                    {paymentHistoryItem.userPaymentPackageId.paymentDatetime}
                                </p>
                            </div>
                        </div>
                    )
                })
            )
                : (
                    <div className="center">No Payment History </div>
                )

        }
        else {
            UserHistoryList = (<div><br /><center><div>{error}</div></center><br /></div>)
        }

        return (
            <div className="main-payment">
                <div className="shape-container">
                    <div className="bg-img">
                    </div>
                </div>

                <div className="container">
                    <div className="pay-box">
                        <div className="main-logo text-center h4">
                            <img className="  logo" alt="logo" src={logo} />
                            <p className="font small-font main-color">بوابة الدفع لجيل</p>
                        </div>
                        <div className="card card-container Current-Package">
                            <div className="card-header profile-name-card text-right">
                                <span className="h4 font">الباقات السابقة</span>
                                <Link>
                                    <a className="back-icon float-left" onClick={this.redirect}>
                                        <i className="fas fa-chevron-left">
                                            <FontAwesomeIcon icon="chevron-left" />
                                        </i>
                                    </a>
                                </Link>
                            </div>
                            <div className="history-card card-body">
                                <div className="card-body">
                                    <div className="p-2">

                                        {UserHistoryList}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <MobilePaymentFooter />
                    </div>
                </div>
            </div>
        )
    }
}

MobilePaymentHistory.propTypes = {
    getUserHistory: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    userPaymentHistory: state.userPaymentHistory.items,
    loading: state.userPaymentHistory.loading,
    error: state.userPaymentHistory.getting_user_payment_history_error,
    sucess: state.userPaymentHistory.getting_user_payment_history_sucessfully,
    token : state.user.token,
    userPaymentProfile: state.userPaymentProfile.item,


});

export default withRouter
    (connect
        (mapStateToProps,
            {
                getUserHistory
            }
        )
        (MobilePaymentHistory)
    );

