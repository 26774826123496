import axios, { post, put } from 'axios'
import firebase from 'firebase/app';

import {
    LOAD_CODE, ADD_CODE, ERROR_ADDING_CODE,
    FETCH_ALL_CODES, LOAD_ALL_CODES, ERROR_ALL_CODES,
    EMPTY_CODE, ERROR_DELETING_CODE, DELETING_CODE,
    FETCH_CODE_DATA, ERROR_LOADING_CODE, EDIT_CODE, FETCH_SAVED_CODE,
    ERROR_EDITING_CODE, ADD_CODE_TO_REDUX, EMPTY_CODE_FROM_REDUX,
    LOAD_SAVED_CODE, ERROR_FETCH_SAVED_CODE, SAVE_CODE, ERROR_SAVING_CODE,
    LOAD_SAVING_CODE, CLEAR_PROMO_CODE_ALL
} from './Types';


export const getPromoCodes = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ALL_CODES,
                payload: 'loading'
            })

            const url = "/promocode/getAllPromoCodes"
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            axios.get(url, config).then((res) => {
                dispatch(
                    {
                        type: FETCH_ALL_CODES,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ALL_CODES,
                    payload: Error.message
                })
            })
        })
    }
}

export const deletePromoCode = (uid) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CODE,
                payload: 'loading'
            })

            const url = "/promocode/delete";
            const formData = new FormData();
            formData.append('Code_ID', uid);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_CODE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_CODE,
                    payload: Error.message
                })
            })
        })

    }
}

export const getPromoCodeItem =(uid) =>{
    return (dispatch,getState,{getFirebase}) =>{
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_CODE,
                payload:'loading'
            })

            const url = "/promocode/getPromoCodeItem?Code_ID="+uid
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase':  token
                }
            }
            axios.get(url,config).then((res)=>
            {
                dispatch({
                    type:FETCH_CODE_DATA,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_CODE,
                    payload:Error.message
                })

            })
        })
    }

}

export const emptyPromoCode = () => dispatch => {
    dispatch({
        type: EMPTY_CODE,
        payload: ''
    })
}

export const addPromoCode = (obj) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CODE,
                payload: 'Loading'
            })

            const url = "/promocode/add";
            const formData = new FormData();
            formData.append('Code_Name', obj.name);
            formData.append('Code_Text', obj.text);
            formData.append('Status', obj.status);
            formData.append('Start_Date', obj.start_date);
            formData.append('End_Date', obj.end_date);
            formData.append('Country', obj.country);
            //
            formData.append('Max_Users', obj.max_users);
            formData.append('Max_Uses', obj.max_uses);
            //
            formData.append('Discount_Amount', obj.discount_amount);
            formData.append('Discount_Type', obj.discount_type_text);
            //
            formData.append('Coupon_Type', obj.coupon_type_text);
            //
            formData.append('Payment_Packages', obj.payment_packages_ids);
            formData.append('Payment_Methods', obj.payment_methods_ids);
            formData.append('County_Names', obj.country_names);
            //
            formData.append('Is_Grouped', 1);
            formData.append('Email', null);
            formData.append('Registered_Date', obj.registered_on);
            formData.append('Registered_Months', obj.registered_months);
            formData.append('Subscribed_Months', obj.subscribed_months);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: ADD_CODE,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_CODE,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const editPromoCode = (obj) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CODE,
                payload: 'Loading'
            })

            const url = "/promocode/update";
            const formData = new FormData();
            formData.append('Code_ID' , obj.uid);
            formData.append('Code_Name', obj.name);
            formData.append('Code_Text', obj.text);
            formData.append('Status', obj.status);
            formData.append('Start_Date', obj.start_date);
            formData.append('End_Date', obj.end_date);
            formData.append('Country', obj.country);
            //
            formData.append('Max_Users', obj.max_users);
            formData.append('Max_Uses', obj.max_uses);
            //
            formData.append('Discount_Amount', obj.discount_amount);
            formData.append('Discount_Type', obj.discount_type_text);
            //
            formData.append('Coupon_Type', obj.coupon_type_text);
            //
            formData.append('Payment_Packages', obj.payment_packages_ids);
            formData.append('Payment_Methods', obj.payment_methods_ids);
            formData.append('County_Names', obj.country_names);
            //
            formData.append('Is_Grouped', 1);
            formData.append('Email', null);
            formData.append('Registered_Date', obj.registered_on);
            formData.append('Registered_Months', obj.registered_months);
            formData.append('Subscribed_Months', obj.subscribed_months);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: EDIT_CODE,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_EDITING_CODE,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const getUserActivePromoCodes = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ALL_CODES,
                payload: 'loading'
            })

            const url = "/promocode/getUserActivePromoCodes"
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            axios.get(url, config).then((res) => {
                dispatch(
                    {
                        type: FETCH_ALL_CODES,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ALL_CODES,
                    payload: Error.message
                })
            })
        })
    }
}

export const addCodeToRedux = (codeName, codeNote) => dispatch => {
        let obj = {
          code_name: codeName,
          code_note: codeNote,
        }
        dispatch({
            type: ADD_CODE_TO_REDUX,
            payload: obj
        })
    }

export const emptyCodeFromRedux = () => dispatch => {

    dispatch({
        type: EMPTY_CODE_FROM_REDUX,
        payload: ''
    })
}

export const vertifyCode = (codeName) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CODE,
                payload: 'Loading'
            })

            const url = "/promocode/verifyCode";
            const formData = new FormData();
            formData.append('Code_Name', codeName);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: FETCH_CODE_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
              if (Error.response && Error.response.data) {
                dispatch({
                    type: ERROR_LOADING_CODE,
                    payload: Error.response.data.message
                })
              }else{
                dispatch({
                    type: ERROR_LOADING_CODE,
                    payload: 'حدث خطأ ما'
                })
              }

            })
        })
    }
}

export const newVertifyCode = (codeName,paymentMethod,packageType) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CODE,
                payload: 'Loading'
            })

            const url = "/promocode/newVerifyCode";
            const formData = new FormData();
            formData.append('Code_Name', codeName);
            formData.append('Payment_Method', paymentMethod);
            formData.append('Package_Type', packageType);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: FETCH_CODE_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
              if (Error.response && Error.response.data) {
                dispatch({
                    type: ERROR_LOADING_CODE,
                    payload: Error.response.data.message
                })
              }else{
                dispatch({
                    type: ERROR_LOADING_CODE,
                    payload: 'حدث خطأ ما'
                })
              }

            })
        })
    }
}

export const getPromoCodeItemByName =(codeName) =>{
    return (dispatch,getState,{getFirebase}) =>{
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_CODE,
                payload:'loading'
            })

            const url = "/promocode/getPromoCodeItemByName?Code_Name="+codeName
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase':  token
                }
            }
            axios.get(url,config).then((res)=>
            {
                dispatch({
                    type:FETCH_CODE_DATA,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_CODE,
                    payload:Error.response.data.message
                })

            })
        })
    }
}

export const getPromoCodeItemByNamePublic =(codeName) =>{
    return async (dispatch) =>{
       
            dispatch({
                type:LOAD_CODE,
                payload:'loading'
            })

            const url = "/promocode/public/getPromoCodeItemByName?Code_Name="+codeName

            axios.get(url).then((res)=>
            {
                dispatch({
                    type:FETCH_CODE_DATA,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_CODE,
                    payload:Error.response.data.message
                })

            })
        
    }
}

export const getSavedPromoCode =() =>{
    return (dispatch,getState,{getFirebase}) =>{
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_CODE,
                payload:'loading'
            })

            const url = "/promocode/getSavedPromoCode"
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase':  token
                }
            }
            axios.get(url,config).then((res)=>
            {
                dispatch({
                    type:FETCH_CODE_DATA,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_CODE,
                    payload:Error.response.data.message
                })

            })
        })
    }
}

export const checkIfSavedPromoCode =() =>{
    return (dispatch,getState,{getFirebase}) =>{
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_SAVED_CODE,
                payload:'loading'
            })

            const url = "/promocode/getSavedPromoCode"
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase':  token
                }
            }
            axios.get(url,config).then((res)=>
            {
                dispatch({
                    type:FETCH_SAVED_CODE,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_FETCH_SAVED_CODE,
                    payload:Error.response.data.message
                })

            })
        })
    }
}

export const saveCode = (codeName) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SAVING_CODE,
                payload: 'Loading'
            })

            const url = "/promocode/saveCode";
            const formData = new FormData();
            formData.append('Code_Name', codeName);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SAVE_CODE,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SAVING_CODE,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const clearPromoCodeAll = () => dispatch => {
    dispatch({
        type: CLEAR_PROMO_CODE_ALL,
        payload: ''
    })
}

export const deleteSavedPromoCode = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_CODE,
                payload: 'loading'
            })

            const url = "/promocode/deleteSavedPromoCode";
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_CODE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
              if(Error.response && Error.response.data){
                dispatch({
                    type: ERROR_DELETING_CODE,
                    payload: Error.response.data.message
                })
              }else{
                dispatch({
                    type: ERROR_DELETING_CODE,
                    payload: Error.message
                })
              }
            })
        })

    }
}
