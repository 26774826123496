import React, { Component } from "react";
import OuterHeader from "../Layout/OuterHeader";
import InnerHeader from "../Layout/InnerHeader";
import Dashboard from "../Dashboard";
import Footer from "../Layout/Footer";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { saveSetting , emptySetting } from "../../Actions/SettingAction";
import { emptyError } from '../../Actions/Empty';
import { Redirect, Link } from "react-router-dom";
import "../../css/AppCss/addBlog.css";
import { ADMIN_LAND_PAGE, SETTINGS } from "../../routes";

const INITIAL_STATE = {
  name: "",
  value: "",
  cancel: false,
  error: true,
  errorMessage: "",
  submitError: "",
  redirectToSettings: false // New state to handle redirection
};

class AddSetting extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, " ");
    this.setState({ [e.target.name]: checkSpaces });

    if (
      this.state.name === "" ||
      this.state.name === null ||
      this.state.name === undefined ||
      this.state.name.trim() === "" ||
      this.state.value === "" ||
      this.state.value === null ||
      this.state.value === undefined ||
      this.state.value.trim() === ""
    ) {
      this.setState({
        error: true,
        errorMessage: "Empty Data is not accepted"
      });
    } else {
      this.setState({ error: false, errorMessage: "" });
    }
  };

  handleSubmit = async (e) => {
    
    const { name, value } = this.state;
    
    if (!name.trim() || !value.trim()) {
      this.setState({
        errorMessage: "Both name and value fields are required."
      });
      return;
    }
    else {
      this.props.saveSetting(name, value);
    }
    e.preventDefault();
  };

  handelCancel = (e) => {
    this.setState({ cancel: true });
  };

  render() {

    if (this.state.cancel === true) {
      this.props.emptySetting();
      return <Redirect to={SETTINGS} />;
    }

    const {settingError,  settingSuccess} = this.props;


    if ( settingSuccess === false) {
      alert( settingError);
      this.props.emptySetting();
    }
    else if ( settingSuccess === true) {
      alert("setting added successfully");
      this.props.emptySetting();
      return <Redirect to={SETTINGS} />;
    }

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col-9">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div
                      className="col-12 left-main-body"
                      id="left-main-body"
                    >
                      <div className="statusbar small-font">
                        <span className="status-link">
                          <Link to={ADMIN_LAND_PAGE}>Home</Link>
                          <span> </span>
                        </span>
                        <span className="status-link">
                          <Link to={SETTINGS}>Settings</Link>
                        </span>
                      </div>
                      <br />
                      <h5 className="main-title mt-2 font-weight-bold">
                        <span>Add Setting</span>
                      </h5>
                      <br />
                      <div className="container">
                        <form onSubmit={this.handleSubmit}>
                          <span className="required_input">*</span>
                          <label className="small-label">Name</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="name"
                            name="name"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.name}
                          />
                          <br />
                          <span className="required_input">*</span>
                          <label className="small-label">Value</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="value"
                            name="value"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.value}
                          />
                          <br />
                          <p style={{ color: "red" }}>
                            {this.state.errorMessage}
                          </p>
                          <p style={{ color: "red" }}>
                            {this.state.submitError}
                          </p>

                          <Button
                            icon
                            labelPosition="right"
                            id="nextButton"
                            type="submit"
                            disabled={this.state.error}
                          >
                            Save
                          </Button>

                          <Button
                            id="episode_cancel"
                            onClick={this.handelCancel}
                          >
                            Cancel
                          </Button>

                          <br />
                          <br /> <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

AddSetting.propTypes = {};

const mapStateToProps = (state) => ({
  settingError: state.settings.error_adding_setting,
  settingSuccess: state.settings.setting_added_successfully
});

export default connect(mapStateToProps, { saveSetting, emptyError ,emptySetting})(
  AddSetting
);
