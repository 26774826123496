import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { TextArea, Input, Button, Icon, Label, Modal } from 'semantic-ui-react';
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*component */
import Loader from '../Layout/Loader';
/*Actions */
import { getPromoCodeItem, emptyPromoCode } from '../../Actions/PromoCodesActions';
/* Routes */
import { ADMIN_LAND_PAGE, PROMO_CODES, GET_CODE_TO_EDIT,
         ADD_PROMO_CODE, EDIT_PROMO_CODE} from '../../routes';

function FormatDate(date) {
  if(date == null){
    return '';
  }
  date = new Date(date);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  //January is 0!
  var yyyy = date.getFullYear();
  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  date = yyyy + '/' + mm + '/' + dd;
  return date;
}

class PromoCodeDetails extends Component {
  constructor(props) {
      super(props);
      this.state = {
        back: false
      }
  }

  handleBack = (e) => {
      this.setState({ back: true });
  }

  componentDidMount() {
    let codeUid = this.props.match.params.codeUid;
    this.props.emptyPromoCode();
    this.props.getPromoCodeItem(codeUid);
  }

  render() {
    let data = (<Loader/>);
    const {promocodeload, promocodesuccess , promocode, promocoderror } = this.props;
    let codeUid = this.props.match.params.codeUid;

    let editPromoCodeButton = (
      <div className="col-12 left-main-body" id="left-main-body">
        <Link  to={{ pathname: GET_CODE_TO_EDIT + "/" + codeUid }}><button type="submit"
            className="seriesButtons">
             Edit
            </button>
        </Link>
      </div>
    )
    if (this.state.back === true) {
      return (<Redirect to={PROMO_CODES} ></Redirect>)
    }

   if(promocoderror !== '' && promocoderror !== false){
     //alert("Invalid Coupon Number");
     return (<Redirect to={PROMO_CODES} ></Redirect>)
   }
   if(promocode !== null && promocode !== undefined && promocodesuccess === true && promocodeload !== true
     && promocode.couponType !== undefined && promocode.discountType !== undefined){
    let paymentPackages = '';
    let paymentMethods = '';
    let countryNames = '';

    let usersCount = (promocode.users !== null && promocode.users !== undefined) ? promocode.users : "-";

    if(promocode.paymentPackages !== undefined && promocode.paymentPackages.length >= 1){
      paymentPackages = promocode.paymentPackages.map( packageItem => {
            return (
                <div class="children-box mb-3">
                    <div className="row">
                        <div className="col-12">
                            <label htmlFor="payment_package" className="label input-group">Payment Package</label>
                            <Input readOnly fluid type="text" name="payment_package" id="payment_package" value={packageItem.packageType} />
                            <br />
                        </div>
                    </div>
                </div>
            )
        })
    }else{
      paymentPackages =
      <div className="children_container">
          <div className="customer-card-container">
              <div id="child-data">

                  <div className="child-toogle">

                      <label style={{ paddingLeft: "20px", fontSize: '13px' }}>No Payment Packages Applied</label>

                  </div>

              </div>
          </div>
      </div>
    }

    if(promocode.paymentMethods !== undefined && promocode.paymentMethods.length >= 1){
      paymentMethods = (
        promocode.paymentMethods.map( methodItem => {
              return (
                  <div class="children-box mb-3">
                      <div className="row">
                          <div className="col-12">
                              <label htmlFor="payment_methods" className="label input-group">Payment Methods</label>
                              <Input readOnly fluid type="text" name="payment_methods" id="payment_methods" value={methodItem.name} />
                              <br />
                          </div>
                      </div>
                  </div>
              )
          })
      )
    }else{
      paymentMethods = (
          <div className="children_container">
              <div className="customer-card-container">
                  <div id="child-data">

                      <div className="child-toogle">

                          <label style={{ paddingLeft: "20px", fontSize: '13px' }}>No Payment Methods Applied</label>

                      </div>

                  </div>
              </div>
          </div>
        )
    }

    if(promocode.countryCurrencies !== undefined && promocode.countryCurrencies.length >= 1){
        countryNames = promocode.countryCurrencies.map( countryItem => {
              return (
                  <div class="children-box mb-3">
                      <div className="row">
                          <div className="col-12">
                              <label htmlFor="code_country" className="label input-group">Country Name</label>
                              <Input readOnly fluid type="text" name="code_country" id="code_country" value={countryItem.country} />
                              <br />
                          </div>
                      </div>
                  </div>
              )
          })
      }

    return (
        <div className="container">
            <OuterHeader />
            <div className="container">
                <div className="container-body">
                    <div className="row">
                        <Dashboard />
                        <div className="col">
                            <InnerHeader />
                            <div className="main-content">
                                <div className="row">

                                    <div className="col-12 left-main-body" id="left-main-body">
                                        <div className="box-container">

                                            {/* <!--  start status bar --> */}
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                </span>
                                                <span className="status-link">
                                                    <Link to={PROMO_CODES}>Promo Codes</Link><span> > </span>
                                                </span>

                                                <span>View Promo Code</span>
                                            </div>

                                              {editPromoCodeButton}

                                            <h5 className="main-title mt-2 font-weight-bold">Promo Code Data</h5>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="codeboxs col">
                                                        <div className="row">
                                                          <div className="col-6">
                                                            <div className="circle-tile ">
                                                            </div>
                                                            <p className="col-12 vendorEditayhaga">
                                                                    Total Number Of Coupon Usage
                                                            </p>
                                                          </div>
                                                          <div className="col-6 vendorEditayhaga">
                                                            <p style = {{float: 'right', marginTop: '-10px', paddingRight: '20px'}} className="vendorEditnumbers">{promocode.couponUsage}</p>
                                                          </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="codeboxs col">
                                                        <div className="row">
                                                          <div className="col-6">
                                                            <div className="circle-tile ">
                                                            </div>
                                                            <p className="col-12 vendorEditayhaga">
                                                                    Total Number Of Potential Users
                                                            </p>
                                                          </div>
                                                          <div className="col-6 vendorEditayhaga">
                                                            <p style = {{float: 'right', marginTop: '-10px', paddingRight: '20px'}} className="vendorEditnumbers">{usersCount}</p>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                            </div>
                                            <div className="card mt-3 p-0">
                                                <div className="card-body p-4">

                                                    <div className="row">
                                                        <div className="col-6">
                                                            <form >
                                                                <label htmlFor="code_name" className="label">Code Name</label><br />
                                                                <Input readOnly fluid name="code_name" id="code_name" value={promocode.name} />
                                                                <br />

                                                                <label htmlFor="code_number" className="label">Code Description</label><br />
                                                                <Input readOnly fluid type="text" name="code_text" id="code_text" value={promocode.text} />
                                                                <br />

                                                                <label htmlFor="code_status" className="label">Code Status</label><br />
                                                                <Input readOnly fluid type="text" name="code_status" id="code_status" value={promocode.status} />
                                                                <br />

                                                                <label htmlFor="code_coupon_type" className="label">Code Coupon Type</label><br />
                                                                <Input readOnly fluid type="text" name="code_coupon_type" id="code_coupon_type" value={promocode.couponType.type} />
                                                                <br />

                                                                <label class="label">Country Code</label>
                                                                {countryNames}
                                                                <br />

                                                                <label htmlFor="code_max_usage" className="label">Code Max Users</label><br />
                                                                <Input readOnly fluid type="text" name="code_max_usage" id="code_max_usage" value={promocode.maxUsage} />
                                                                <br />

                                                                <label htmlFor="code_max_usage_per_user" className="label">Code Max Usage Per User</label><br />
                                                                <Input readOnly fluid type="text" name="code_max_usage_per_user" id="code_max_usage_per_user" value={promocode.maxUsagePerUser} />
                                                                <br />

                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label htmlFor="code_start_date" className="label input-group">Code Start Date</label>
                                                                        <Input readOnly fluid type="text" name="code_start_date" id="code_start_date" value={FormatDate(promocode.startDate)} />
                                                                        <br />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <label htmlFor="code_end_date" className="label input-group"> Code End Date</label>
                                                                        <Input readOnly fluid type="text" name="code_end_date" id="code_end_date" value={FormatDate(promocode.endDate)} />
                                                                        <br />
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-6">
                                                                        <label htmlFor="code_discount_amount" className="label input-group">Code Discount Amount</label>
                                                                        <Input readOnly fluid type="text" name="code_discount_amount" id="code_discount_amount" value={promocode.discountAmount} />
                                                                        <br />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <label htmlFor="code_discount_type" className="label input-group"> Code Discount Type</label>
                                                                        <Input readOnly fluid type="text" name="code_discount_type" id="code_discount_type" value={promocode.discountType.type} />
                                                                        <br />
                                                                    </div>
                                                                </div>

                                                            </form>
                                                        </div>

                                                        <div className="col-6">
                                                            <label class="label">Applied Payment Packages</label>
                                                            {paymentPackages}
                                                            <br />
                                                            <label class="label">Applied Payment Methods</label>
                                                            {paymentMethods}
                                                            <br />
                                                            <label className="label input-group">Options</label>
                                                             <div class="children-box mb-3">
                                                                <div className="row">
                                                                  <div className="col-12">
                                                                    <label className="label">Registered Months</label><br />
                                                                    <Input readOnly fluid type="text" name="registered_months" id="registered_months"
                                                                            value = {promocode.registeredMonths} />
                                                                    <br /><br />
                                                                    </div>

                                                                    <div className= "col-12">
                                                                    <label className="label">Subscribed Months</label><br />
                                                                    <Input redOnly fluid type="text" name="subscribed_months" id="subscribed_months"
                                                                            value = {promocode.subscribedMonths}/>
                                                                    <br /><br />
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <label className="label input-group"> Registered On</label>
                                                                        <Input readOnly className="col-12" style={{marginLeft: '-15px'}}
                                                                          name="registered_on"
                                                                          value={FormatDate(promocode.registeredOn)}
                                                                        />
                                                                        <br />
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                        </div>

                                                    </div>
                                                    <br /><br />

                                                    <div className="buttons_container">
                                                        <Button icon labelPosition='right' id="episode_cancel" style={{ width: "120px", float: "right" }} onClick={this.handleBack} >
                                                            Back
                                                      <Icon name='right arrow' />
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
   }

   else{
     return (
         <div className="container">
             <OuterHeader />
             <div className="container">
                 <div className="container-body">
                     <div className="row">
                         <Dashboard />
                         <div className="col">
                             <InnerHeader />
                             <div className="main-content">
                                 <div className="row">

                                     <div className="col-12 left-main-body" id="left-main-body">
                                         <div className="box-container">

                                             {/* <!--  start status bar --> */}
                                             <div className="statusbar small-font">
                                                 <span className="status-link">
                                                     <Link to="/">Home</Link><span> > </span>
                                                 </span>
                                                 <span className="status-link">
                                                     <Link to="/PromoCodes">Promo Codes</Link><span> > </span>
                                                 </span>

                                                 <span>View Promo Code</span>
                                             </div>

                                             <h5 className="main-title mt-2 font-weight-bold">Promo Code Data</h5>

                                             <div className="card mt-3 p-0">
                                                 <div className="card-body p-4">
                                                     {data}
                                                     </div>
                                                 </div>

                                             </div>
                                         </div>

                                     </div>
                                 </div>

                             </div>
                         </div>
                     </div>
                 </div>

             <Footer />
         </div>
     )
   }
  }
}

const mapStateToProps = state => ({
  promocode: state.promoCodes.item,
  promocoderror: state.promoCodes.error_loading_code,
  promocodesuccess: state.promoCodes.get_code_sucessfully,
  promocodeload: state.promoCodes.loading
});

export default withRouter
    (connect
        (mapStateToProps,{ getPromoCodeItem, emptyPromoCode})
        (PromoCodeDetails)
    );
