import React,{Component} from 'react'
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Redirect} from 'react-router-dom'
import { Confirm } from 'semantic-ui-react'
import {deleteQuestionCategory,fetchQuestionsCategories,emptyQuestionCategory} from '../../Actions/QuestionCategoryActions'
/* Routes */
import { CONTENT_SETTING } from '../../routes';
class DeleteQuestionCategory extends Component
{
    state={open:false}
    open=()=>this.setState({open:true})
    close =()=>this.setState({open:false})

    removeQuestionCategory (id)
    {
        this.props.deleteQuestionCategory(id);
    }
    handleSubmit = (e)=>
    {
        const {id} = this.props;
        this.removeQuestionCategory(id);
        this.close();
    }
    render()
    {
        const {deleted,error_deleting} = this.props;
        if (deleted===false)
        {
            alert ("Error in deleting question category: "+ error_deleting);
            this.props.emptyQuestionCategory();
        }
        else if (deleted===true)
        {
            alert("question category deleted successfully");
            this.props.emptyQuestionCategory();
            this.props.fetchQuestionsCategories();
            return (<Redirect to={CONTENT_SETTING}></Redirect>)
        }
        return (<div >
            <button  onClick={this.open}>
                    <i className="far fa-trash-alt" >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>)

    }
}
const mapStateToProps = state =>
({
    deleted:state.questionCategories.question_category_deleted_successfully,
    error_deleting:state.questionCategories.error_deleteing_question_category
})
export default connect (mapStateToProps,{deleteQuestionCategory,fetchQuestionsCategories,emptyQuestionCategory}) (DeleteQuestionCategory)
