import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
/*Actions*/
import {
    deleteChannel,
    emptyChannel
} from '../../Actions/RadioChannelAction'
import { getRadioData } from '../../Actions/RadioActions';

 class DeleteRadioChannel extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    removeChannel(id) {
        this.props.deleteChannel(id)
    }

    handleSubmit = (e) => {
        const { id } = this.props;
        this.removeChannel(id);
        this.close();
    }

    render() {
        const { error,
            success, RadioId } = this.props

        if (success === false) {
            alert("error missing data or network error" + error);
            this.props.emptyChannel();

        }
        else if (success === true) {
            alert("Radio Channel deleted successfully");
            this.props.emptyChannel();
            this.props.getRadioData(RadioId);
        }

        return (<div >
            <button className="seriesCardButtons" 
            onClick={this.open}>
                <i className="" >
                    <FontAwesomeIcon icon="trash-alt" />
                </i>
            </button>
            <Confirm id="modal"
                content='Are You Sure To Delete This Radio Channel?? '
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </div>)
    }
}


const mapStateToProps = state =>
({
    success: state.radioChannel.channel_deleted_successfully,
    error: state.radioChannel.error_deleteing_channel
})
export default connect
    (mapStateToProps,
        {
            deleteChannel,
            emptyChannel,
            getRadioData
        })
    (DeleteRadioChannel)
