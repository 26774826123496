import {
    FETCH_All_BOOK_PARENT, GET_BOOK_PARENT_DATA, LOAD_BOOK_PARENTES, ERROR_LOADING_BOOK_PARENTES,
    ERROR_LOADING_BOOK_PARENT, LOAD_BOOK_PARENT, SAVE_BOOK_PARENT, ERROR_ADDING_BOOK_PARENT,
    ERROR_SETTING_STATE_BOOK_PARENT, SETTING_STATE_BOOK_PARENT,
    ERROR_DELETING_BOOK_PARENT, DELETING_BOOK_PARENT,
    ERROR_UPDATE_BOOK_PARENT, UPDATE_BOOK_PARENT, EMPTY_BOOK_PARENT,
    EMPTY_BOOK_PARENT_WITH_DATA,
    ADD_BOOK_PARENT_WITH_SENTFROM
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},

    loading: '',
    error_loading_book_parents: '',
    error_loading_book_parent: '',

    error_adding_book_parent: '',
    book_parent_added_sucessfully: '',

    setting_state_book_parent_error: "",
    setting_state_book_parent_sucessfully: "",

    deleting_book_parent_error: "",
    deleting_book_parent_sucessfully: "",

    updating_book_parent_sucessfully: "",
    updating_book_parent_error: "",
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_BOOK_PARENT_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }

        case FETCH_All_BOOK_PARENT:

            return {
                ...state,
                items: action.payload,
                error_loading_book_parents: '',
                loading: false
            }
        case GET_BOOK_PARENT_DATA:

            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_book_parent: ''
            }
        case SAVE_BOOK_PARENT:
            return {
                ...state,
                item: action.payload,
                items: state.items.concat(action.payload),
                book_parent_added_sucessfully: true
            }
        case EMPTY_BOOK_PARENT:
            return {
                ...state,
                error_adding_book_parent: action.payload,
                book_parent_added_sucessfully: action.payload,

                setting_state_book_parent_error: action.payload,
                setting_state_book_parent_sucessfully: action.payload,

                deleting_book_parent_sucessfully: action.payload,
                deleting_book_parent_error: action.payload,

                updating_book_parent_sucessfully: action.payload,
                updating_book_parent_error: action.payload,

                loading: action.payload,

            }
        case EMPTY_BOOK_PARENT_WITH_DATA:
            return {
                ...state,
                item: {}
            }

        case LOAD_BOOK_PARENTES:
            return {
                ...state,
                loading: true
            }
        case LOAD_BOOK_PARENT:
            return {
                ...state,
                loading: true
            }

        case ERROR_LOADING_BOOK_PARENTES:
            return {

                ...state,
                error_loading_book_parents: action.payload,
                loading: false
            }

        case ERROR_LOADING_BOOK_PARENT:
            return {
                ...state,
                error_loading_book_parent: action.payload,
                loading: false
            }

        case ERROR_ADDING_BOOK_PARENT:
            return {
                ...state,
                error_adding_book_parent: action.payload,
                book_parent_added_sucessfully: false
            }

        case SETTING_STATE_BOOK_PARENT:
            return {
                ...state,
                item: action.payload,
                setting_state_book_parent_sucessfully: true,
                loading: false
            }

        case ERROR_SETTING_STATE_BOOK_PARENT:
            return {
                ...state,
                setting_state_book_parent_error: action.payload,
                loading: false
            }

        case DELETING_BOOK_PARENT:
            return {

                ...state,
                item: action.payload,
                deleting_book_parent_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_BOOK_PARENT:
            return {

                ...state,
                deleting_book_parent_error: action.payload,
                loading: false
            }

        case UPDATE_BOOK_PARENT:
            return {
                ...state,
                item: action.payload,
                updating_book_parent_sucessfully: true,
                updating_book_parent_error: '',
                loading: false
            }

        case ERROR_UPDATE_BOOK_PARENT:
            return {
                ...state,
                updating_book_parent_error: action.payload,
                loading: false,
                updating_book_parent_sucessfully: false
            }


        default:
            return state

    }

}
