
export default [
    {
        key: 2,
        text: 'none',
        value: 'none'
    },
    {
        key: 0,
        text: 'paid',
        value: 'paid'
    },
    {
        key: 1,
        text: 'free',
        value: 'free'
    }

]
