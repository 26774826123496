import {
    FETCH_ALL_IN_APP_NOTIFICATION, LOAD_ALL_IN_APP_NOTIFICATION, ERROR_LOADING_ALL_IN_APP_NOTIFICATION,
    ADD_IN_APP_NOTIFICATION_SUCCESSFULLY, ADD_IN_APP_NOTIFICATION_ERROR,
    UPDATE_IN_APP_NOTIFICATION_SUCCESSFULLY, UPDATE_IN_APP_NOTIFICATION_ERROR,
    GET_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY, GET_IN_APP_NOTIFICATION_DATA_ERROR,
    DELETE_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY, DELETE_IN_APP_NOTIFICATION_DATA_ERROR,
    LOAD_IN_APP_NOTIFICATION, EMPTY_IN_APP_NOTIFICATION, EMPTY_IN_APP_NOTIFICATION_RESPONSE
} from './Types'

import { post } from 'axios';
import { put } from 'axios';

export const fetchAllInAppNotification = () => {

    return (dispatch, getState, { getFirebase }) => {
        dispatch({
            type: LOAD_ALL_IN_APP_NOTIFICATION,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/InAppNotification/getAllInAppNotification"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: FETCH_ALL_IN_APP_NOTIFICATION,
                    payload: res.data
                })

            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_ALL_IN_APP_NOTIFICATION,
                    payload: Error.message
                })
            })

        })

    }
}

export const getInAppNotificationData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_IN_APP_NOTIFICATION,
                payload: 'loading'
            })

            const url = "/InAppNotification/getInAppNotificationDetails";
            const formData = new FormData();
            formData.append('Id', id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                console.log(Error.message)
                console.log(Error.response.data.message)

                dispatch({
                    type: GET_IN_APP_NOTIFICATION_DATA_ERROR,
                    payload: Error.response.data.message
                })
            })
        })

    }
}

// export const addInAppNotification = (Content_Type, Text, Audio, Action_Link, Is_NumberOfViews_EveryTime, Start_Date, End_Date, isVideo) => {
//     return (dispatch, getState, { getFirebase }) => {
//         const firebase = getFirebase();
//         firebase.auth().currentUser.getIdToken().then((token) => {
//             dispatch({
//                 type: LOAD_IN_APP_NOTIFICATION,
//                 payload: 'loading'
//             })

//             const url = "/InAppNotification/saveInAppNotification";
//             const formData = new FormData();
//             formData.append('Content_Type', Content_Type);
//             formData.append('Text', Text);
//             formData.append('Audio', Audio);
//             formData.append('Action_Link', Action_Link);
//             formData.append('Is_NumberOfViews_EveryTime', Is_NumberOfViews_EveryTime);
//             formData.append('isVideo', isVideo);
//             formData.append('Start_Date', Start_Date);
//             formData.append('End_Date', End_Date);

//             const config = {
//                 headers: {
//                     'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
//                     'X-Authorization-Firebase': token
//                 }
//             }
//             post(url, formData, config).then((res) => {
//                 dispatch(
//                     {
//                         type: ADD_IN_APP_NOTIFICATION_SUCCESSFULLY,
//                         payload: res.data
//                     }
//                 )
//             }).catch((Error) => {
//                 dispatch({
//                     type: ADD_IN_APP_NOTIFICATION_ERROR,
//                     payload: Error.response.data.message

//                 })

//             })
//         })

//     }
// }


export const addInAppNotification = (notificationData) => (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().currentUser.getIdToken().then((token) => {
      dispatch({
        type: LOAD_IN_APP_NOTIFICATION,
        payload: 'loading',
      });
  
      const url = "/InAppNotification/saveInAppNotification";
  
      const config = {
        headers: {
          'content-type': 'application/json',
          'X-Authorization-Firebase': token
        }
      };
      const jsonData = JSON.stringify(notificationData);
  
      post(url, notificationData, config).then((res) => {
        dispatch({
          type: ADD_IN_APP_NOTIFICATION_SUCCESSFULLY,
          payload: res.data
        });
      }).catch((Error) => {
        dispatch({
          type: ADD_IN_APP_NOTIFICATION_ERROR,
          payload: Error.response.data.message
        });
      });
    });
  };
  

// export const editInAppNotification = (id, Content_Type, Text, Audio, Action_Link, Is_NumberOfViews_EveryTime, Start_Date, End_Date, isVideo) => {
//     return (dispatch, getState, { getFirebase }) => {
//         const firebase = getFirebase();
//         firebase.auth().currentUser.getIdToken().then((token) => {
//             dispatch({
//                 type: LOAD_IN_APP_NOTIFICATION,
//                 payload: 'loading'
//             })

//             const url = "/InAppNotification/updateInAppNotification";
//             const formData = new FormData();
//             formData.append('Id', id);
//             formData.append('Content_Type', Content_Type);
//             formData.append('Text', Text);
//             formData.append('Audio', Audio);
//             formData.append('Action_Link', Action_Link);
//             formData.append('Is_NumberOfViews_EveryTime', Is_NumberOfViews_EveryTime);
//             formData.append('isVideo', isVideo);
//             formData.append('Start_Date', Start_Date);
//             formData.append('End_Date', End_Date);


//             const config = {
//                 headers: {
//                     'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
//                     'X-Authorization-Firebase': token
//                 }
//             }
//             post(url, formData, config).then((res) => {
//                 dispatch(
//                     {
//                         type: UPDATE_IN_APP_NOTIFICATION_SUCCESSFULLY,
//                         payload: res.data
//                     }
//                 )
//             }).catch((Error) => {

//                 dispatch({
//                     type: UPDATE_IN_APP_NOTIFICATION_ERROR,
//                     payload: Error.response.data.message

//                 })

//             })
//         })

//     }
// }
export const editInAppNotification = ( data) => (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().currentUser.getIdToken().then((token) => {
      dispatch({
        type: LOAD_IN_APP_NOTIFICATION,
        payload: "loading",
      });
  
      const url = "/InAppNotification/updateInAppNotification";
      const jsonData = JSON.stringify( data ); // Include id in the request body
  
      const config = {
        headers: {
          "content-type": "application/json",
          "X-Authorization-Firebase": token,
        },
      };
  
      put(url, jsonData, config)
        .then((res) => {
          dispatch({
            type: UPDATE_IN_APP_NOTIFICATION_SUCCESSFULLY,
            payload: res.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: UPDATE_IN_APP_NOTIFICATION_ERROR,
            payload: error.response.data.message,
          });
        });
    });
  };

export const deleteInAppNotification = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_IN_APP_NOTIFICATION,
                payload: 'loading'
            })

            const url = "/InAppNotification/deleteInAppNotification";
            const formData = new FormData();
            formData.append('Id', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETE_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: DELETE_IN_APP_NOTIFICATION_DATA_ERROR,
                    payload: Error.message
                })
            })
        })

    }
}

export const emptyError = () => dispatch => {
    console.log("enter EmptyError")
    dispatch({
        type: EMPTY_IN_APP_NOTIFICATION_RESPONSE,
        payload: ""
    })
}

export const emptyInAppNotification = () => dispatch => {
    dispatch({
        type: EMPTY_IN_APP_NOTIFICATION,
        payload: ""
    })
}
