export default
[
    {
        key: 'New',
        text: 'New',
        value: 'New'
    },
    {
        key: 'Featured',
        text: 'Featured',
        value: 'Featured'
    },
    {
        key: 'MostWatched',
        text: 'MostWatched',
        value: 'MostWatched'
    },
    {
        key: 'All',
        text: 'All',
        value: 'All'
    }
]