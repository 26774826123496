import {
    ADD_EVALUATION_CATEGORY_ID, 
   EMPTY_EVALUATION_CATEGORY_ID
} from './Types'

export const addEvaluationCategory = (item) => dispatch => {
 
   dispatch({
       type: ADD_EVALUATION_CATEGORY_ID,
       payload: item
   })
}

export const emptyEvaluationCategory =()=> dispatch=>
{
    dispatch({
        type:EMPTY_EVALUATION_CATEGORY_ID,
        payload:''
    })
}