import React, { Component } from 'react'
import googlePlay from "../../../images/fit/google-play.png";
import appStore from "../../../images/fit/app-store.png";

export class FitDownloadApps extends Component {
    render() {
        return (
            <div className="fit-parent-app-section">

                <div className="row justify-content-md-center">
                    <div className=" col-12 col-md-8">
                        <div className="center img">
                            <strong >
                                حمل تطبيق جيل
                      </strong>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-md-center" //"col-12 col-md-8"
                >
                    <div className="center form-row fit-Appdownload-btns">
                        <div className="col-6 ">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.jeelapp.android">

                                <button className="Appdownload-btn">
                                    <img src={googlePlay} alt="" />
                                </button>
                            </a>
                        </div>
                        <div className="col-6">
                            <a target="_blank" href="https://apps.apple.com/us/app/jeel-%D8%AC%D9%8A%D9%84/id1489596085">

                                <button className="Appdownload-btn">
                                    <img src={appStore} alt="" />
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default FitDownloadApps
