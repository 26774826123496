import {
    LOAD_All_FAQ, FETCH_All_FAQ, ERROR_ALL_FAQ, EMPTY,
    LOAD_FAQ, ERROR_UPDATE_FAQ, UPDATE_FAQ,
    ERROR_ADDING_FAQ, ADD_FAQ,
    DELETING_FAQ, ERROR_DELETING_FAQ
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    showModal: '',
    loading: '',

    getting_all_FAQ_error: '',
    getting_all_FAQ_sucessfully: '',

    updating_FAQ_error: '',
    updating_FAQ__sucessfully: '',

    adding_FAQ_error: '',
    adding_FAQ__sucessfully: "",

    deleting_FAQ_error: '',
    deleting_FAQ__sucessfully: "",
}

export default function (state = initialState, action) {
    switch (action.type) {


        case FETCH_All_FAQ:
            return {
                ...state,
                items: action.payload,
                getting_all_FAQ_error: '',
                getting_all_FAQ_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_FAQ:
            return {

                ...state,
                getting_all_FAQ_error: action.payload,
                loading: false
            }

        case LOAD_All_FAQ, LOAD_FAQ:
            return {
                ...state,
                loading: true
            }

        case UPDATE_FAQ:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                updating_FAQ_error: '',
                updating_FAQ__sucessfully: true,
                loading: false
            }

        case ERROR_UPDATE_FAQ:
            return {

                ...state,
                updating_FAQ_error: action.payload,
                updating_FAQ__sucessfully: false,
                loading: false
            }

        case ADD_FAQ:
            return {
                ...state,
                items: action.payload,
                showModal: false,
                items: state.items.concat(action.payload),
                adding_FAQ_error: '',
                adding_FAQ__sucessfully: true,
                loading: false
            }

        case ERROR_ADDING_FAQ:
            return {

                ...state,
                adding_FAQ_error: action.payload,
                adding_FAQ__sucessfully: false,
                loading: false
            }

        case DELETING_FAQ:
            return {

                ...state,
                item: action.payload,
                deleting_FAQ__sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_FAQ:
            return {

                ...state,
                deleting_FAQ_error: action.payload,
                loading: false
            }

        case EMPTY:
            return {
                ...state,
                updating_FAQ_error: action.payload,
                updating_FAQ__sucessfully: action.payload,
                adding_FAQ_error: action.payload,
                adding_FAQ__sucessfully: action.payload,
                deleting_FAQ_error: action.payload,
                deleting_FAQ__sucessfully: action.payload,
                item: {},
            }

        default:
            return state

    }

}