import React, { Component } from 'react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Actions */
import {
    setStateStoryParent,
    emptyError,
    fetchAllStoryParent,
    getStoryParentData
} from '../../Actions/StoryParentAction'

class SetStateStoryParent extends Component {

    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setStateStoryParent(id, status) {
        this.props.setStateStoryParent(id, status)
    }

    handleSubmit = (e) => {

        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setStateStoryParent(id, "DEFAULT");

        } else {
            this.setStateStoryParent(id, "SUSPENDED");
        }
        this.close();
    }


    render() {
        const { success, error, status, id, sentFrom } = this.props;

        let contentPreview = null;

        if (success === false) {
            alert("Error: " + error);
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Story Parent status updated successfully");
            this.props.emptyError();

            // if the requesst come from All storyParent then
            // fetch all StorysParent to update 
            // the new data
            if (sentFrom === "AllStoryParents") {
                this.props.fetchAllStoryParent();
            }

            // if the requesst come from ViewStoryParent then
            // get Story parent data to update 
            // the suspended Story
            if (sentFrom === "ViewStoryParent") {
                this.props.getStoryParentData(id);
            }
        }
        if (status === "SUSPENDED") {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons" 
                    onClick={this.open}>
                        <i >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )
        }
        else {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>||</button>}
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }


        return (
            <span >
                {contentPreview}
                <Confirm id="modal"
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </span>)
    }
}

SetStateStoryParent.propTypes = {
    setStateStoryParent: propTypes.func.isRequired,
    fetchAllStoryParent: propTypes.func.isRequired,
    getStoryParentData: propTypes.func.isRequired,
}
const mapStateToProps = state =>
({
    success: state.storyParent.setting_state_story_parent_sucessfully,
    error: state.storyParent.setting_state_story_parent_error
})

export default connect
    (mapStateToProps,
        {
            setStateStoryParent,
            emptyError,
            fetchAllStoryParent,
            getStoryParentData
        })
    (SetStateStoryParent)

