import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Dropdown, Input, Button, Icon, Label } from 'semantic-ui-react';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Redux */
import { connect } from 'react-redux';
import firebase from "firebase/app";
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';
import NavBar from '../Layout/NavBar';
import SideMenu from '../Layout/SideMenu';
import Loader from '../Layout/Loader';

/* Assets */
import avatar1 from "../../../images/portal/avatars/avatar-1.png";
import avatar2 from "../../../images/portal/avatars/avatar-2.png";
import avatar3 from "../../../images/portal/avatars/avatar-3.png";
import avatar4 from "../../../images/portal/avatars/avatar-4.png";
import avatar5 from "../../../images/portal/avatars/avatar-5.png";
import avatar6 from "../../../images/portal/avatars/avatar-6.png";

import addImage from "../../../images/portal/add-photo.png";
import addIcon from "../../../images/portal/add.svg";

import {
  addParentData, emptyParentData,
  emptychildData, editCustomer, emptyCustomer,
} from '../../../Actions/CustomerActions';
import { uploadImageProfileToW3s } from '../../../Promises/Child';
import { getUserData, getUserPaymentInfo, emptyMobileUserProfileWithItem } from "../../../Actions/MobilePaymentProfileAction";
import { addSuccessMessage } from '../../../Actions/SuccessMessagesActions';
/* Routes */
import { PORTAL_ACCOUNT_PROFILE , PORTAL_ACCOUNT} from '../../../routes';

const INITIAL_STATE = {
    kids: '',
    parentSection: '#ffad4d',
    childSection: '#E8E8E8',
    gender: '',
    parentName: '',
    year: '',
    disabled: true,
    errorMsg: '',
    isParent: true,
    isChild: false,
    parentProfile: 50,
    childProfile: 0,
    advancedOptions: false,
    display: 'none',
    dayUse: false,
    hourUse: 24,
    minuteUse: 0,
    childPassword: false,
    kidPass: '',
    kidName: '',
    kidAge: 1,
    kidGender: '',
    kidDayUse: 0,
    backToParent: false,
    childDisabled: false,
    childErrorMsg: '',
    viewKids: false,
    children: {
      saved: [],
    },
    token: '',
    backToChild: false,
    errorMsg: '',
    redirect: false,
    isMaleKid: false,
    isFemaleKid: false,
    isFemale: false,
    isMale: false,
    kidImage: '',
    saveButton: false,
    isRevealPassword: false, // NGMA-2328
    nameRegex: '[a-zA-Zء-ي]+', //NGMA-2327 & NGMA-2325
    isLoading: '',
    childPassErrorMsg: '', // errorMsg relalted to password only (NGMA-2425)
    responsive: {
      0: {
        items: 6,
      },
      450: {
        items: 6,
      },
      600: {
        items: 6,
      },
      1000: {
        items: 10,
      },
  },
}

function getDifferenceInYears(date1, date2) {
  var dt1 = new Date(date1);
  var dt2 = new Date(date2);
  var days = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24 * 365));
  return days;
}

function getYears() {
  let years = [];
  let year = new Date().getFullYear();
  let youngest_year = year - 19;
  let diff = youngest_year - 1960;
  years.push('');
  for (var i = 0; i <= diff; i++) {
    years.push({ text: youngest_year - i, value: youngest_year - i });
  }
  return years;
}

class CustomerAccount extends Component {

  constructor(props) {
    super(props);
    // import("../../../css/portal-style.css");
    this.state = { ...INITIAL_STATE };
    const { ifChild } = this.props;
    if (firebase.auth().currentUser) {
      this.props.getUserPaymentInfo();
    }

    this.props.history.push(PORTAL_ACCOUNT);

  }

  componentDidMount(props) {
    // import("../../../css/portal-style.css");
    const { userPaymentProfile, customer, ifChild } = this.props;
    if (ifChild !== undefined) {
      if (ifChild.isChild === true) {
        this.setState({ isParent: false, isChild: true, viewKids: false })
      }
    }
    setTimeout(() => {
      if (userPaymentProfile !== null && userPaymentProfile !== undefined) {
        this.setState({ parentName: userPaymentProfile.name, gender: userPaymentProfile.gender });
        if (userPaymentProfile.gender === 'female') {
          this.setState({ isFemale: true, isMale: false, gender: 'female' })
        } else if (userPaymentProfile.gender === 'male') {
          this.setState({ isMale: true, isFemale: false, gender: 'male' })
        }
        this.setState({ disabled: false, children: userPaymentProfile.children })
        if (userPaymentProfile !== undefined && userPaymentProfile.children !== undefined && userPaymentProfile.children.length > 0) {
          this.setState({ childProfile: 100 })
        }
        if (userPaymentProfile.name !== null && userPaymentProfile.name !== undefined && userPaymentProfile.name !== ''
          && userPaymentProfile.gender !== null && userPaymentProfile.gender !== undefined && userPaymentProfile.gender !== '') {
          this.setState({ parentProfile: 100 })
        }
      } if (customer.gender !== '' && customer.gender !== null && customer.gender !== undefined) {
        if (customer.gender === 'female') {
          this.setState({ isFemale: true, isMale: false })
        } else if (customer.gender === 'male') {
          this.setState({ isMale: true, isFemale: false })
        }
        this.setState({
          parentName: customer.name,
          gender: customer.gender,
          year: customer.year,
          disabled: false,
        });
        if (customer.kidName !== '' && customer.kidName !== undefined && customer.kidName !== null) {
          this.setState({
            kidName: customer.kidName,
            kidAge: customer.kidAge,
            kidDayUse: customer.kidDayUse,
            kidPass: customer.kidPass,
          })
          if (customer.kidGender === 'female') {
            this.setState({ isMaleKid: false, isFemale: true, kidGender: 'female' });
          } else if (customer.kidGender === 'male') {
            this.setState({ isMaleKid: true, isFemale: false, kidGender: 'male' });
          }
        }
      }
      if (userPaymentProfile.name !== null && userPaymentProfile.name !== undefined) {
        this.setState({ redirect: true })
      }
    }, 100);


  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces, errorMsg: '' });
    setTimeout(() => {
      if (this.state.parentName !== '' && this.state.parentName !== null && this.state.parentName !== undefined && this.state.parentName !== ' '
        //&&
        //    this.state.gender !== '' && this.state.gender !== null && this.state.gender !== undefined && this.state.gender !== ' '
      ) {
        this.setState({ disabled: false, errorMsg: '' })
      } else {
        this.setState({ disabled: true, errorMsg: '* لم يتم استكمال البيانات' })
      }
    }, 0);
  }

  handleYearChange = (e, { value }) => {
    //let value = e.target.value;
    this.setState({ year: value });
  }

  handleParentSubmit = () => {
    if (this.state.parentName !== '' && this.state.parentName !== null && this.state.parentName !== undefined && this.state.parentName !== ' ' &&
      this.state.gender !== '' && this.state.gender !== null && this.state.gender !== undefined && this.state.gender !== ' ') {
      //NGMA-2325
      if(this.state.parentName === ' ' || !this.state.parentName.match(this.state.nameRegex)){
        this.setState({disabled: true, errorMsg:  '* اسم الأم/الأب يجب ان يحتوي على حرف واحد على الأقل'})
      }
      else{
        this.setState({ disabled: false, errorMsg: '' })

        let kid = { kidName: this.state.kidName, kidAge: this.state.kidAge, kidGender: this.state.kidGender, kidPass: this.state.kidPass, kidDayUse: this.state.kidDayUse, kidImage: this.state.kidImage };
        //  kids.push(kid);
        if (this.state.kidName !== null && this.state.kidName !== undefined && this.state.kidName !== '')
          this.props.addParentData(this.state.parentName, this.state.gender, this.state.year, kid);
        else
          this.props.addParentData(this.state.parentName, this.state.gender, this.state.year, '');
        this.setState({ isParent: false, parentProfile: 100, childSection: this.state.parentSection });
        const { userPaymentProfile } = this.props;
        setTimeout(() => {
          if (userPaymentProfile.children.length === 0) {
            this.setState({ isChild: true });
          } else {
            this.props.emptychildData(this.state.parentName, this.state.gender, this.state.year);
            this.setState({ isChild: false, viewKids: true, backToParent: true, childProfile: 100 });
          }
        }, 100)
      }
    } else {
      this.setState({ disabled: true, errorMsg: '* لم يتم استكمال البيانات' });
    }
  }

  handleGenderChange = (e) => {
    let gender = e.target.value;
    if (gender === 'female') {
      this.setState({ isFemale: true, isMale: false, gender: gender })
    } else {
      this.setState({ isFemale: false, isMale: true, gender: gender })
    }

    setTimeout(() => {
      if (//this.state.parentName !== '' && this.state.parentName !== null && this.state.parentName !== undefined && this.state.parentName !== ' ' &&
        this.state.gender !== '' && this.state.gender !== null && this.state.gender !== undefined && this.state.gender !== ' ') {
        this.setState({ disabled: false, errorMsg: '' })
      } else {
        this.setState({ disabled: true, errorMsg: '* لم يتم استكمال البيانات' })
      }
    }, 0);
  }

  handleAdvancedOptions = () => {
    this.setState({ advancedOptions: !this.state.advancedOptions })
    setTimeout(() => {
      if (this.state.advancedOptions === true) {
        this.setState({ display: 'block' });
      } else {
        this.setState({ display: 'none' })
      }
    }, 100)
  }

  handleRedirect = () => {
    this.setState({
      isParent: true, isChild: false, advancedOptions: false,
      parentSection: '#ffad4d',
      childSection: '#E8E8E8',
      childErrorMsg:'',
      childPassErrorMsg: '',
      childDisabled: false,    
    })
  }

  handleBack = () => {
    if (this.state.backToParent === false) {
      this.setState({
        isParent: false, isChild: true, advancedOptions: false,
        parentSection: '#ffad4d',
        childSection: '#ffad4d'
      })
    } else {
      this.setState({
        isParent: true, isChild: false, advancedOptions: false,
        parentSection: '#ffad4d',
        childSection: '#E8E8E8', childProfile: 100
      })
    }
  }

  handleTogglePassword = () => {
    if(!this.state.childPassword === false){
        this.setState({kidPass: ''});
    }
    this.setState({ childPassword: !this.state.childPassword, errorMsg: '', childPassErrorMsg: '', childDisabled: false })
  }

  handleToggleDayUse = () => {
    this.setState({dayUse: !this.state.dayUse, errorMsg: ''});
    setTimeout(() => {
      if(this.state.dayUse === true){
        this.setState({minuteUse: 0, hourUse: 3});
      }else{
        this.setState({minuteUse: 0, hourUse: 24});
      }
    },0)
  }

  handleChildSubmit = () => {

    if (this.state.kidName !== null && this.state.kidName !== undefined && this.state.kidName !== '' &&
      this.state.kidGender !== null && this.state.kidGender !== undefined && this.state.kidGender !== '') {
      // NGMA-2329
      var regex = '((([0-9])+){4})$';
     // NGMA-2425    
      if(this.state.childPassword === true && this.state.kidPass === ''){
           this.setState({ childDisabled: true,
          childPassErrorMsg: '* كلمة مرور الطفل مكونة من ٤ أرقام ' })
      }
      // NGMA-2329
      else if(this.state.childPassword === true && this.state.kidPass !== '' && !this.state.kidPass.match(regex)){
        this.setState({ childDisabled: true,
          childPassErrorMsg: '* كلمة مرور الطفل مكونة من ٤ أرقام وأرقام فقط' })
      }
      // NGMA-2327
      else if(this.state.kidName === ' ' || !this.state.kidName.match(this.state.nameRegex)){
        this.setState({ childDisabled: true,
          childErrorMsg: '* اسم الطفل يجب ان يحتوي على حرف واحد على الأقل'})
      }
      else {
        let m = this.state.minuteUse.toString();
        let h = this.state.hourUse.toString();
        if(this.state.minuteUse.toString().length === 1 ){
          m = '0'+this.state.minuteUse;
        }
        if(this.state.hourUse.toString().length === 1 ){
          h = '0'+this.state.hourUse;
        }
        let use = h + ':' + m;

        let kid = {
          kidName: this.state.kidName,
          kidAge: this.state.kidAge,
          kidGender: this.state.kidGender,
          kidPass: this.state.kidPass,
          kidDayUse: use,
          kidImage: this.state.kidImage
        };
        this.setState({ kids: kid });
        this.props.addParentData(this.state.parentName, this.state.gender, this.state.year, kid);
        this.setState({ backToChild: true, backToParent: false, isParent: false, isChild: false, viewKids: true, childProfile: 100 });
        // edit user
        // add child
        // in case of success redirect to the last page
      }
    } else {
      this.setState({ childDisabled: true, childErrorMsg: '* لم يتم استكمال البيانات' })
    }

  }

  addAge = () => {
    if (this.state.kidAge < 15)
      this.setState({ kidAge: this.state.kidAge + 1, errorMsg: '' });
    else {
      //
    }
  }

  minusAge = () => {
    if (this.state.kidAge > 1)
      this.setState({ kidAge: this.state.kidAge - 1, errorMsg: '' });
    else {
      //
    }
  }

  handleAgeChange = (e) => {
    this.setState({ kidAge: e.target.value });
  }

  handleKidInfo = (e) => {
    let value = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: value, errorMsg: '' })
    setTimeout(() => {
      if (this.state.kidName !== null && this.state.kidName !== undefined && this.state.kidName !== ''
      // &&
    //     this.state.kidGender !== null && this.state.kidGender !== undefined && this.state.kidGender !== ''
    ) {
        this.setState({ childDisabled: false, childErrorMsg: '' });
      } else {
        this.setState({ childDisabled: true, childErrorMsg: '* لم يتم استكمال البيانات' })
      }

    //   if (this.state.childPassword === true && this.state.kidPass.length !== 4) {
    //     this.setState({ childDisabled: true, childErrorMsg: '* لم يتم استكمال البيانات' })
    //   }
    }, 100)
  }

  handleDayUse = (e) => {
    let value = e.target.value;
    if (value <= 24) {
      this.setState({ kidDayUse: value })
    }
  }

  handleHourUseChange = (e) => {
    let hour = e.target.value;
    this.setState({hourUse: hour});
  }

  addHour = (e) => {
    if(this.state.hourUse <= 23)
      this.setState({hourUse: this.state.hourUse + 1, errorMsg: ''});
  }

  minusHour = (e) => {
    if(this.state.hourUse > 0)
      this.setState({hourUse: this.state.hourUse - 1, errorMsg: ''});
  }

  handleMinutesUseChange = (e) => {
    let minutes = e.target.value;
    this.setState({minuteUse: minutes});
  }

  addMinute = (e) => {
    if(this.state.minuteUse < 59)
      this.setState({minuteUse: this.state.minuteUse + 1, errorMsg: ''});
  }

  minusMinute = (e) => {
    if(this.state.minuteUse > 0)
      this.setState({minuteUse: this.state.minuteUse - 1, errorMsg: ''});
  }

  handleGenderKidChange = (e) => {
    let gender = e.target.value;
    if (gender === 'female') {
      this.setState({
        isFemaleKid: true, isMaleKid: false,
        kidGender: gender
      })
    } else {
      this.setState({
        isFemaleKid: false, isMaleKid: true,
        kidGender: gender
      })
    }
    this.setState({errorMsg: ''});
    setTimeout(() => {
      if (
        // this.state.kidName !== null && this.state.kidName !== undefined && this.state.kidName !== '' &&
        this.state.kidGender !== null && this.state.kidGender !== undefined && this.state.kidGender !== '') {
        this.setState({ childDisabled: false, childErrorMsg: '' });
       } else {
        this.setState({ childDisabled: true, childErrorMsg: '* لم يتم استكمال البيانات' })
      }
    }, 100)
  }

  handleNewChild = (e) => {
    const { customer } = this.props;
    let obj = {};
    if (customer.kidName !== '' && customer.kidName !== null && customer.kidName !== undefined) {
      obj.kidName = customer.kidName;
      obj.kidAge = customer.kidAge;
      obj.kidDayUse = customer.kidDayUse;
      obj.kidPass = customer.kidPass;
      obj.kidGender = customer.kidGender;
      obj.kidImage = customer.kidImage;
      let children = this.state.children;
      children['saved'] = children['saved'] ? children['saved'] : [];
      children['saved'].push(obj);
      this.setState({ children: children });
    }
    this.setState({
      isParent: false, isChild: true, viewKids: false, childDisabled: false,
      kidName: '', kidAge: 1, kidPass: '', kidDayUse: 0, kidGender: '', isMaleKid: false,
      isFemaleKid: false
    })
    this.props.emptychildData(customer.parentName, customer.gender, customer.year);

    //alert(children['saved'].length);
  }

  handleSubmit = (e) => {
    setTimeout( () => {
      this.setState({saveButton: true});
    },0)
    // send parent api
    let obj = this.state;
    let customerObj = {};
    let parent = {};
    parent.parentName = obj.parentName;
    parent.gender = obj.gender;
    parent.year = obj.year;
    const { customer } = this.props;
    let lastChild = {};
    lastChild.kidName = customer.kidName;
    lastChild.kidDayUse = customer.kidDayUse;
    lastChild.kidPass = customer.kidPass;
    lastChild.kidAge = customer.kidAge;
    lastChild.kidGender = customer.kidGender;
    lastChild.kidImage = customer.kidImage;
    let totalChildren = [];
    if (this.state.children.saved !== null && this.state.children.saved !== undefined &&
      this.state.children.saved.length > 0) {
      for (var i = 0; i < this.state.children.saved.length; i++) {
        totalChildren.push(this.state.children.saved[i]);
      }
    }
    if (lastChild !== null)
      totalChildren.push(lastChild);
    customerObj.parent = parent;
    // upload images first
    totalChildren.forEach((child) => {
      //alert(child.kidImage);
      this.setState({isLoading: true});
      uploadImageProfileToW3s(child.kidImage).then(res => {
              console.log(res.data);
              child.kidImage = res.data ;
      })
    });
    setTimeout(() => {
      customerObj.children = totalChildren;
      this.props.editCustomer(customerObj);
      let children = this.state.children;
      children['saved'] = [];
      this.setState({ kidName: '', kidPass: '', kidGender: '', kidAge: 1, kidDayUse: '', kidImage: '', saveButton: false });
    }, 5000)
    // then

  }

  handleClose = (e) => {
    this.setState({ openSnakBar: false })
  }

  fileChange = (e) => {
    let imageFile = e.target.files[0];
    this.setState({ kidImage: imageFile, errorMsg: ''});
    var all = document.getElementsByClassName("avatar");
    for (var i = 0; i < all.length; i++) {
      all[i].style.border = 'none';
      all[i].style.borderRadius=  'none';
    }
    var component = document.getElementById("av");
    component.style.border = '5px solid #ffad4d';
    component.style.borderRadius=  '50%';
  };

  async handleUrlToFiles(image, id){
    const data = await fetch(image);
    const blob = await data.blob();
    var original = Promise.resolve(blob);
    var cast = Promise.resolve(original);
    cast.then((value) => {
        let f = new File([value], id + ".png", {type:"image/png", lastModified:new Date()});
        console.log('value: ' + f);
         this.setState({kidImage: f, errorMsg: ''});
    });
  }
  
  imageChange = (e) => {
    let image = e.target.src;
    let id = e.target.id;
    var all = document.getElementsByClassName("avatar");
    for (var i = 0; i < all.length; i++) {
      all[i].style.border = 'none';
      all[i].style.borderRadius=  'none';
    }
    var component = document.getElementById(id);
    component.style.border = '5px solid #ffad4d';
    component.style.borderRadius=  '50%';
    this.handleUrlToFiles(image, id);
  }
  // NGMA-2328
  togglePassword = (e) => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword })
  }

  render() {
    const { customerEditSuccess, customerEditError, loading } = this.props;
    const { isRevealPassword } = this.state;

    let buttonSubmitloader = (
      <button className="next-section1 full-w-btn  mt-40px"
        onClick={this.handleSubmit}  disabled={this.state.saveButton}> حفظ البيانات
      </button>
    )
    // NGMA-2418
    if(loading === true || this.state.isLoading === true){
      buttonSubmitloader = (
        <button
          className="full-w-btn ui loading mt-40px button" style={{height: '50%', backgroundColor: '#ffad4d', borderRadius: '50px'}}>
        </button>
      )
    }
    if (customerEditSuccess === true) {
      //alert('تم تسجيل البيانات بنجاح');
      let message = 'تم تسجيل البيانات بنجاح'
      this.props.addSuccessMessage(message);
      this.props.emptyParentData();
      this.props.emptyMobileUserProfileWithItem();
      //this.props.getUserData(this.state.token);
      this.setState({ isParent: true, isChild: false, viewKids: false, parentProfile: 100, childProfile: 100 });
      return (<Redirect to={PORTAL_ACCOUNT_PROFILE}> </Redirect>)
    }

    if (customerEditSuccess === false) {
      //alert(customerEditError);
      this.setState({ errorMsg: "حدث خطأ اثناء تحديث البيانات، يرجي المحاولة مرة أخرى", isLoading: '' });
      this.props.emptyParentData();
      this.props.emptyCustomer();
    }
    let text = (
      <div className="account-info-content">
        <div className="card-info">
          بيانات حسابك غير مكتملة ... قم باستكمال بيانات حسابك
        </div>
      </div>
    )

    if (this.state.parentProfile === 100 && this.state.childProfile === 100) {
      text = '';
    }

    const { userloading, customer, userPaymentProfile } = this.props;

    let childPassword = '';
    let dayUse = '';

    const childProgressInstance = <ProgressBar className="progress-bar" now={this.state.childProfile} variant="warning" />;
    const parentProgressInstance = <ProgressBar className="progress-bar" now={this.state.parentProfile} variant="warning" />;

    let advancedOptions = '';

    if (this.state.childPassword === true) {
      childPassword = (
        <div className="row">
         <div className="col-12">
          <div className="form-group">
           <div className="advanced-options-style input-container">
            <input className="form-control main-input childPasswordInput"
              type={isRevealPassword ? "text" : "password"} //NGMA-2328
              minLength="4" maxLength="4"
              id="kidPass" name="kidPass"
              value={this.state.kidPass}
              onChange={this.handleKidInfo}
              placeholder="كلمة مرور الطفل" />
            <span onClick={this.togglePassword}>
              <span>
                {isRevealPassword ?
                  <FontAwesomeIcon icon="eye" className="eyeIcon"/> :
                  <FontAwesomeIcon icon="eye-slash" className="eyeIcon" />
                }
              </span>
            </span>
            <label className="small-label"
              style={{ float: 'right', marginRight: '20px' }}>
              مكونة من 4 أرقام فقط </label>
          </div>
          </div>
         </div>
        </div>
      )
    } else {
      childPassword = '';
    }

    if (this.state.dayUse === true) {
      dayUse = (
       <div className="container form-group">
        <div className="number-container">
            <input type="text" className="form-control main-input custom-number" readOnly
              min="0" max="23"
              value={this.state.hourUse} onChange={this.handleHourUseChange}
            />
            <div className="right-icon number-icon" onClick={this.addHour}>
                  <Icon name="plus" />
            </div>
            <div className="left-icon number-icon" onClick={this.minusHour}>
                <Icon name="minus" />
            </div>
            <label className="small-label" style={{float:'right', marginRight: '18px'}}>ساعة </label>
        </div>
        <div className="number-container">
            <input type="text" className="form-control main-input custom-number" readOnly
              min="1" max="15"
              value={this.state.minuteUse} onChange={this.handleMinutesUseChange}
            />
            <div className="right-icon number-icon" onClick={this.addMinute}>
                  <Icon name="plus" />
            </div>
            <div className="left-icon number-icon" onClick={this.minusMinute}>
                <Icon name="minus" />
            </div>
            <label className="small-label" style={{float:'right', marginRight: '18px'}}>دقيقة </label>
        </div>
       </div>
      )
    } else {
      dayUse = ''
    }

    if (this.state.advancedOptions === true) {
      advancedOptions = (
        <span>
          <div className="container" id="advanced" style={{ direction: 'rtl', float: 'right', textAlign: 'right', display: `${this.state.display}`, marginRight: '-8px' }}>
            <br />
            <div className="row">
              <div className="col-9">
                <span className="h3 bold-title" style={{ fontSize: '16px' }}>
                  اضافة كلمة مرور الطفل
           </span>
              </div>
              <div className="col-2">
                <label className="switch">
                  <input type="checkbox" checked={this.state.childPassword} onChange={this.handleTogglePassword} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            {childPassword}
            <br />
            <div className="row">
              <div className="col-9">
                <span className="h3 bold-title" style={{ fontSize: '16px' }}>
                  مدة الاستخدام اليومي
         </span>
              </div>
              <div className="col-2">
                <label className="switch">
                  <input type="checkbox" checked={this.state.dayUse} onChange={this.handleToggleDayUse} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            {dayUse}
          </div>
        </span>
      );
    }

    let parentSection = (
      <span>
        <div className="form-sections active">
          <div className="form-group">
            <label className="h6 bold-title" style={{ fontSize: '16px', textAlign: 'right', float: 'right' }}>هل انت</label>
          </div>
        </div>
        <br /><br />
        <div className="row form-group parents-group parent-icons">
          <div className=" parent-radio">
            <input id="mother-radio" type="radio" name="gender" value='female'
              checked={this.state.isFemale} onChange={this.handleGenderChange} />&nbsp;
              <label for="mother-radio">الأم</label>
          </div>
          <div className="parent-radio">
            <input id="father-radio" type="radio" name="gender" value='male'
              checked={this.state.isMale} onChange={this.handleGenderChange} />&nbsp;
              <label for="father-radio">الأب</label>
          </div>
        </div>

        <div className="form-group">
          <label for="parent-name" style={{ float: 'right', fontSize: '16px' }}>اسم الأم/الأب</label>
          <div className="col-12 col-md-10" style={{ padding: '0' }}>
            <input type="text" className="form-control main-input"
              id="parent-name" name="parentName"
              value={this.state.parentName}
              onChange={this.handleChange}
              placeholder="اسم الأم/الأب" />
          </div>
        </div>
        <br />

        <div className="row">
          <div className="col-12 col-md-10">
            <div className="group-container" style={{
              fontSize: '16px',
              textAlign: 'right'
            }}>
              <label>سنة الميلاد (اختياري)</label>
              <br />
              <Dropdown id="dropdown-menu-align-right"
                fluid selection placeholder='سنة الميلاد'
                name="year" id="year"
                value={this.state.year} onChange={this.handleYearChange}
                options={getYears()} />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="form-group form-btns">
            <button className="next-section full-w-btn auto-btn mt-40px" disabled={this.state.disabled}
              onClick={this.handleParentSubmit}>الخطوة التالية</button>
          </div>
        </div>
      </span>
    )

    let childSection = (
      <span>
        {/* Start Upload Image  */}

        <div className="form-group" style={{ textAlign: 'right', fontSize: '16px' }}>
          <label className="bold-title d-block">صورة الطفل</label>
          <label className="d-block">اختر شخصية كرتونية لطفلك او اضف صورة شخصية</label>
        </div>

        <div>
          <div className="owl-carousel child-carousel owl-reponsive owl-theme owl-loaded owl-drag">

            <div className="row upload-child-image" style={{marginRight: '0px'}}>
              <div className="upload-container child-items"
              onClick={()=>{this.upload.click()}}>
                <input type="file" accept="image/x-png,image/gif,image/jpeg"
                ref={(ref) => this.upload = ref}
                onChange={this.fileChange.bind(this)}
                 />
                <img id="av" className="avatar" src={addImage} alt=""/>
                <input type="hidden" id="upload-img"/>
              </div>
              <div className={"col-2 child-items item child-img"}>
                <input id="av1" className="avatar" type="image" src={avatar1} width="50" height="50" onClick={this.imageChange}/>
              </div>
              <div className={"col-2 child-items item child-img"}>
                <input id="av2" className="avatar" type="image" src={avatar2} width="50" height="50" onClick={this.imageChange}/>
              </div>
              <div className={"col-2 child-items item child-img"}>
                <input id="av3" className="avatar" type="image" src={avatar3} width="50" height="50" onClick={this.imageChange}/>
              </div>
              <div className={"col-2 child-items item child-img"}>
                <input id="av4" className="avatar" type="image" src={avatar4} width="50" height="50" onClick={this.imageChange}/>
              </div>
              <div className={"col-2 child-items item child-img"}>
                <input id="av5" className="avatar" type="image" src={avatar5} width="50" height="50" onClick={this.imageChange}/>
              </div>
              <div className={"col-2 child-items item child-img"}>
                <input id="av6" className="avatar" type="image" src={avatar6} width="50" height="50" onClick={this.imageChange}/>
              </div>
            </div>
          </div>
        </div>
        {/* End Upload Image  */}


        <div className="form-sections active">
          <div className="form-group">
            <label className="h6 bold-title" style={{ fontSize: '16px', float: 'right' }}>نوع الطفل</label>
          </div>
        </div>
        <br /><br />
        <div className="row form-group children-group">
          <div className="children-radio">
            <input id="son-radio" type="radio" name="gender" value='male'
              checked={this.state.isMaleKid} onChange={this.handleGenderKidChange} />&nbsp;
              <label for="son-radio">ولد</label>
          </div>
          <div className="children-radio">
            <input id="doughter-radio" type="radio" name="gender" value='female'
              checked={this.state.isFemaleKid} onChange={this.handleGenderKidChange} />&nbsp;
               <label for="doughter-radio">بنت</label>
          </div>
        </div>
        <br />
        <div className="container form-group">
          <div className="row">
            <label for="parent-name"
              className="h6 bold-title"
              style={{ float: 'right', fontSize: '16px', marginTop: '-20px' }}>اسم الطفل</label>
          </div>
          <div className="row">
            <input type="text" className="form-control main-input" id="kid-name" name="kidName"
              value={this.state.kidName} onChange={this.handleKidInfo} placeholder="اسم الطفل" />
          </div>
        </div>

        <div className="container form-group">
          <div className="row">
            <label for="parent-name"
              className="h6 bold-title"
              style={{ float: 'right', fontSize: '16px' }}>سن الطفل</label>
          </div>
          <div className="number-container">
            <input type="text" className="form-control main-input custom-number" readOnly
              min="1" max="15"
              value={this.state.kidAge} onChange={this.handleAgeChange}
            />
            <div className="right-icon number-icon" onClick={this.addAge}>
              <Icon name="plus" />
            </div>
            <div className="left-icon number-icon" onClick={this.minusAge}>
              <Icon name="minus" />
            </div>
          </div>
        </div>

        <div className="row">
          <button className="add-btn advanced-btn advanced-options" type="button" onClick={this.handleAdvancedOptions}>
            <span>
              <label className="h6 bold-title"
                style={{ fontSize: '16px' }}
              >خيارات متقدمة <Icon name="down chevron" /></label>
            </span>
          </button>
        </div>
        <div className="row">
          <div className="col-12 section-advanced-options">
            <div>
              {advancedOptions}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-4 form-group form-btns">
            <button disabled={this.state.childDisabled}
              className="next-section1 full-w-btn  mt-40px"
              onClick={this.handleChildSubmit}>التالي
            </button>
          </div>

          <div className="col-6 col-md-4 form-group form-btns">
            <button type="button"
              className="prev-section full-w-btn  mt-40px none-bg-btn"
              onClick={this.handleRedirect}>رجوع
            </button>
          </div>
        </div>

      </span>
    );

    let section = parentSection;

    let childrenData = '';
    let savedChildren = '';
    let newChildren = '';
    let countSaved = 0;
    let addNewKid = '';

    if (this.state.children.saved !== undefined && this.state.children.saved.length > 0) {
      countSaved = this.state.children.saved.length;
    }

    if (this.state.kidName !== '' && this.state.kidName !== null) {
      countSaved += 1;
    }
    if (userPaymentProfile !== undefined && userPaymentProfile.children !== undefined && userPaymentProfile.children.length + countSaved < 3) {
      addNewKid = (
        <button type="button" className="add-btn" onClick={this.handleNewChild}>
          <div className="add-icon">
            <img src={addIcon} alt="" />
          </div>

          <span>
            إضافه طفل اخر
            </span>
        </button>
      )
    } else {
      addNewKid = '';
    }

    if (this.state.isParent === false && this.state.isChild === true) {
      section = childSection;
    } else if (this.state.isParent === false && this.state.isChild === false && this.state.viewKids === true) {
      let today = new Date();

      childrenData = userPaymentProfile.children.map(child => {
        return (
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-3 form-group child-profile">
                  <div className="child-image">
                    <img src={(child.profilePicture !== null && child.profilePicture !== '') ? child.profilePicture : (child.gender === 'male') ? avatar1 : avatar2} alt="" />
                  </div>
                </div>

              <div className="col-9">
                <span className="kid-info">
                  <strong> {child.name}</strong>
                      <br />
                      {getDifferenceInYears(child.dateOfBirth, today)} سنوات
                 </span>
            </div>
            </div>
            </div>
        )
      });

      if (this.state.children.saved !== undefined && this.state.children.saved.length > 0) {
        countSaved = this.state.children.saved.length;
        savedChildren = this.state.children.saved.map(child => {
          return (
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-3 form-group child-profile">
                  <div className="child-image">
                    <img src={(child.kidImage !== null && child.kidImage !== '') ? ( (typeof child.kidImage !== 'string') ? URL.createObjectURL(child.kidImage) : child.kidImage )  : (child.kidGender === 'male') ? avatar1 : avatar2}
                       alt="" />
                  </div>
                </div>

                <div className="col-9">
                  <span className="kid-info">
                    <strong>{child.kidName}</strong>
                    <br />
                    <span>{child.kidAge} سنوات</span>
                  </span>
                </div>
              </div>
            </div>
          )
        });
      }

      if (customer.kidName !== null && customer.kidName !== undefined && customer.kidName !== '') {

        newChildren = (

            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-3 form-group child-profile">
                  <div className="child-image">
                    <img src={(customer.kidImage !== null && customer.kidImage !== '') ? ( (typeof customer.kidImage !== 'string') ? URL.createObjectURL(customer.kidImage) : customer.kidImage )  : (customer.kidGender === 'male') ? avatar1 : avatar2}
                         alt="" />
                  </div>
                </div>

                <div className="col-9">
                    <span className="kid-info">
                      <strong>{customer.kidName}</strong>
                      <br />
                      <span>{customer.kidAge} سنوات</span>
                    </span>
                </div>
              </div>
            </div>
        )
      }

      section = (
        <div>
          <div className="row">
            {childrenData}
            {savedChildren}
            {newChildren}
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                {addNewKid}
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-6 col-md-4 form-group form-btns">
              {buttonSubmitloader}
            </div>

            <div className="col-6 col-md-4 form-group form-btns">
              <button type="button"
                className="prev-section full-w-btn  mt-40px none-bg-btn"
                onClick={this.handleBack}>رجوع
            </button>
            </div>
          </div>

        </div>
      );
    }

    if (this.state.isParent === true && this.state.isChild === false && this.state.viewKids === false) {
      section = parentSection;
    }
    if(userloading === true){
      section = (<Loader />);
    }
    return (
      <body>
        {/** Start NavBar **/}
        <NavBar />
        {/** End NavBar */}
        <div className="main-container" style={{ paddingBottom: '200px' }}>
          <div className="container">
            <div className="form-row">
              {/** Side Menu **/}
              <SideMenu />
              {/** End Side Menu */}
              <div className="col-12 col-md-8">
                <div className="content-container">
                  {/** Start of subsciption section */}
                  <div className="plan-title h2 bold-title" style={{ float: 'right' }}>
                    بيانات الحساب
                  </div>
                  <br /> <br /> <br />
                  <div className="row">
                    <p style={{ color: 'red', textAlign: 'right', float: 'right' }}> {this.state.errorMsg || this.state.childErrorMsg || this.state.childPassErrorMsg}</p>
                  </div>
                  <br />
                  {/** text to note that data isn't completed **/}
                  {text}
                  <br />
                  {/** end note text to inform user that data isn't completed **/}
                  {/** status bar of completing data */}
                  <div className="row no-gutters">

                    <div className="col-6 col-md-5 padding-0">
                      {parentProgressInstance}
                      <ol className="account-segment" id="parent-progressBar" style={{ color: `${this.state.parentSection}` }}>
                        بيانات الوالدين
                           </ol>
                    </div>

                    <div className="col-6 col-md-5 padding-0" id="child-bar">
                      {childProgressInstance}
                      <ol className="account-segment" style={{ color: `${this.state.parentSection}` }}>
                        بيانات الأطفال
                           </ol>
                    </div>
                  </div>
                  <br />
                  {/** End of status bar */}
                  {/** parent info */}
                  <div className="container">
                  {section}
                  </div>
                  {/** End of parent section **/}

                </div>
              </div>
            </div>
          </div>
        </div>
        {/** Start Footer */}
        <Footer />
        {/** End Footer */}

      </body>
    )
  }
}

const mapStateToProps = state => ({
  customer: state.customers.item,

  ifChild: state.customers.isChild,

  loading: state.customers.loading,

  customerEditSuccess: state.customers.editing_customer_sucessfully,
  customerEditError: state.customers.editing_customer_error,

  userPaymentProfile: state.userPaymentProfile.item,
  userloading: state.userPaymentProfile.loading,
  usererror: state.userPaymentProfile.getting_user_payment_data_error,
  usersucess: state.userPaymentProfile.getting_user_payment_data_sucessfully,
});


export default withRouter
  (connect
    (mapStateToProps,
      {
        addParentData, editCustomer, emptyParentData, emptyCustomer,
        getUserPaymentInfo, emptyMobileUserProfileWithItem,
        emptychildData, getUserData, addSuccessMessage
      })
    (CustomerAccount)
  );
