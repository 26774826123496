import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import { Confirm, Popup } from 'semantic-ui-react'

/*Actions*/
import {
    deleteSubValue,
    emptySubValues
} from '../../Actions/ContentSubValueAction'

import {
    fetchAllMainValues
} from '../../Actions/ContentMainValuesAction'

/* Routes */
import { CONTENT_VALUES } from '../../routes';

class DeleteSubValue extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    removeSubValue(id) {
        console.log("deleted sub value = " + id)

        this.props.deleteSubValue(id);
    }

    handleSubmit = (e) => {
        const { id } = this.props;
        this.removeSubValue(id);
        this.close();
    }
    render() {
        const { deleted, error_deleting } = this.props;
        if (deleted === false) {
            alert("error deleting Sub Value" + error_deleting);
            this.props.emptySubValues();
        }
        else if (deleted === true) {
            alert("Sub Value deleted successfully");
            this.props.emptySubValues();
            this.props.fetchAllMainValues();
            return (<Redirect to={CONTENT_VALUES}></Redirect>)
        }
        return (<div >
            <Popup trigger=
                {<FontAwesomeIcon className="far fa-trash-alt"
                    icon='trash-alt' onClick={this.open}>
                </FontAwesomeIcon>
                }
                content="Delete"
                position="top center" />

            <Confirm id="modal"
                content='Are you sure you want to delete this Sub Value and remove its connection with content ?? '
                open={this.state.open}
                onCancel={this.close}
                onConfirm={this.handleSubmit} />
        </div>)

    }
}
const mapStateToProps = state =>
    ({
        deleted: state.ContentSubValue.sub_value_deleted_successfully,
        error_deleting: state.ContentSubValue.error_deleteing_sub_value

    })
export default connect(
    mapStateToProps,
    {
        deleteSubValue,
        emptySubValues,
        fetchAllMainValues
    }
)
    (DeleteSubValue)
