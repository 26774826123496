import React, { Component } from 'react';
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from "../Layout/Footer";
import '../../css/Activity.css';
import { Button, Icon } from 'semantic-ui-react';
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from 'react-redux';
import { addActivity } from '../../Actions/ActivityActions'
import { Link } from 'react-router-dom';
import AddActivityComponent from '../Activity/AddActivityComponent'
/* Routes */
import { ADD_STORY, STORY_EVALUATION } from '../../routes';
const INITIAL_STATE = {
    questions: []
}
class AddStoryActivity extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        this.props.addActivity(this.props.story.Name)
    }


    getValidatinData(questions) {
        console.log(questions)
        this.setState({ questions: questions })

    }
    handelSubmit = (e) => {

        // console.log(this.state.questions === true)

        // if (this.state.questions.length == 0) {
        //     alert("Empty Questions, Please add at least one Question")

        // }

    }
    render() {

        const { questions } = this.state;

        console.log(questions)
        // const isInvalid =
        //     questions.length === 0 || questions == undefined;

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="main-progress-bar">
                                                    <ol className="progress-tracker">
                                                        <li className="step active s2-active"><span className="step-name small-font">ِAdd story</span></li>
                                                        <li className="step active"><span className="step-name small-font">Add activity</span></li>
                                                        <li className="step "><span className="step-name small-font">Add Evaluation</span></li>
                                                        <li className="step"><span className="step-name small-font">Puplish Story</span></li>
                                                    </ol>
                                                </div>
                                                <br />

                                                <AddActivityComponent
                                                validateDataCallBack={this.getValidatinData.bind(this)} />

                                                <br />
                                                <Link to={STORY_EVALUATION}>
                                                    <Button icon labelPosition='right'
                                                        //disabled={isInvalid}
                                                        id="nextButton" >
                                                        Next
                                                            <Icon name='right arrow' />
                                                    </Button>
                                                </Link>

                                                <Link to={ADD_STORY}>
                                                    <Button icon labelPosition='left'

                                                        id="episode_backButton" >
                                                        back
                                                            <Icon name='left arrow' />
                                                    </Button>
                                                </Link>

                                                {/* <Button id="episode_cancel">Cancel</Button> */}
                                                <br /> <br /> <br />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}
const mapStateToProps = state => ({
    activity: state.activities.item,
    story: state.stories.item,

});
export default connect(mapStateToProps, { addActivity })(AddStoryActivity)
