import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import mainLogo from "../../../images/portal/icons/logo.svg";
import { Modal, Transition } from "semantic-ui-react";
import JeelAuthForm from "./JeelAuthForm";
import { connect } from "react-redux";
import {
  customerRegister, 
  empty,
  customerSignIn,
} from "../../../Actions/AuthActions";
import {
  signedUpCustomer,
  emptyCustomer,
  signedInCustomer,
} from "../../../Actions/CustomerActions";
import { removeSuccessMessage } from "../../../Actions/SuccessMessagesActions";
import { PORTAL_PRE_PAID, PORTAL_UPGRADE_SUBSCRIPTION } from "../../../routes";
import { Redirect } from "react-router-dom";
import { addCodeToRedux } from "../../../Actions/PromoCodesActions";
import { PRE_PAID_CARD } from "../constants";

const INITIAL_STATE = {
  email: "",
  password: "",
  phoneNumber: "",
  error: "",
  errorMsg: "",
  code: "",
  country: "",
  confirmForm: true,
  confirmPassword: false,
  confirmPhoneNumber: false,
};

class MainAuthModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleData = (email, password, phoneNumber, confirmPassword, confirmPhoneNumber) => {
    this.setState({ error: false, errorMsg: "" });
    this.setState({
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      confirmPassword: confirmPassword,
      confirmPhoneNumber:confirmPhoneNumber
    });
    if(
        email.length > 0 
        && password.length > 0 
        && confirmPassword === true
        && confirmPhoneNumber === true){
    
      this.setState({confirmForm:false});
    }else{
      this.setState({confirmForm:true});
    }
  };

  componentDidMount() {
    const { userCountry } = this.props;
    this.setState({ country: userCountry });
  }

  handleRegisterSubmit = () => {
    let obj = this.state;
    // call Register API
    this.props.signedUpCustomer();
    this.props.customerRegister(obj);
  };
  handleLoginSubmit = () => {
    let obj = this.state;
    // call Login API
    this.props.emptyCustomer();
    this.props.customerSignIn(obj);
  };

  render() {
    const { success, error, loginsucess, loginloading, paymentMethodName} = this.props;

    if (loginsucess === true) {
      let redirect = paymentMethodName === PRE_PAID_CARD ? PORTAL_PRE_PAID : PORTAL_UPGRADE_SUBSCRIPTION ;
      return <Redirect to={redirect}> </Redirect>;
    }

    if (success === true && error === "") {
      // redirect to the next page
      this.setState({ error: false, errorMsg: "" });
      this.handleLoginSubmit();
    } else if (success === false && error !== "" && error !== false) {
      //console.log(error);
      if (error.code === "auth/email-already-in-use") {
        this.setState({ errorMsg: "البريد الإلكتروني المُدخل مسجل مسبقاً" });
      } else {
        this.setState({ errorMsg: "حدث خطأ ما، يرجي المحاولة مرة أخرى" });
      }
      this.setState({ error: true });
      this.props.empty();
      this.props.emptyCustomer();
    }

 

    return (
      <div className="auth-modal-container">
        <p style={{ color: "red", textAlign: "right" }}>
          {" "}
          {this.state.error && this.state.errorMsg}
        </p>
        <Transition duration={500} visible={this.props.showMainAuth}>
          <Modal
            className="auth-modal login-modal show"
            closeOnDimmerClick={false}
            onClose={() => this.props.closeMainAuthModal()}
            open={this.props.showMainAuth}
          >
            <Modal.Header>
              <div className="d-flex align-items-center justify-content-between">
                <div className="modal-logo">
                  <img src={mainLogo} alt="" />
                </div>
                <button
                  type="button"
                  className="close m-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.props.closeMainAuthModal()}
                >
                  <FontAwesomeIcon icon={faTimes} className="faTimes" />
                </button>
              </div>
            </Modal.Header>
            <Modal.Content>
              <Modal.Actions>
                <form className="jeel-form">
                  <p style={{ color: "red", textAlign: "right" }}>
                    {" "}
                    {this.state.error && this.state.errorMsg}
                  </p>
                  <div className="orange-title mb-3">
                    انشاء حساب جديد في جيل
                  </div>

                  <JeelAuthForm onData={this.handleData} />

                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="btn portal-main-btn jeel-form-submit mx-auto my-2"
                      onClick={() => {
                        // this.props.closeMainAuthModal();
                        // this.props.openOTPModal();
                        this.handleRegisterSubmit();
                      }}
                      disabled={loginloading || this.state.confirmForm}
                    >
                      اشترك الان
                      {loginloading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="faTimes fa-spin mr-2"
                        />
                      )}
                    </button>
                  </div>
                </form>
                <div className="jeel-form-link d-flex align-items-center justify-content-center">
                  لديك حساب؟
                  <button
                    type="button"
                    onClick={() => {
                      this.props.closeMainAuthModal();
                      this.props.openLoginModal();
                    }}
                    
                  >
                    سجل الدخول الان
                  </button>
                </div>
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        </Transition>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.customers.item,
  success: state.customers.adding_customer_sucessfully,
  loading: state.customers.loading,
  error: state.customers.adding_customer_error,
  loginsucess: state.authUser.success,
  loginerror: state.authUser.errorMsg,
  loginloading: state.authUser.loading,
  codeName: state.promoCodes.code_name,
  userCountry: state.countryName.country,
  successMessage: state.successMessages.item,
  paymentMethodName: state.paymentData.paymentMethod,
});
export default connect(mapStateToProps, {
  signedUpCustomer,
  customerRegister,
  removeSuccessMessage,
  empty,
  emptyCustomer,
  signedInCustomer,
  addCodeToRedux,
  customerSignIn,
})(MainAuthModal);
