import {
    LOAD_DISCOVER_US_SERIES,
    FETCH_ALL_DISCOVER_US_SERIES,
    ERROR_LOADING_DISCOVER_US_SERIES,
    EMPTY_DISCOVER_US_SERIES,
    SAVE_DISCOVER_US_SERIES,
    ERROR_SAVING_DISCOVER_US_SERIES,
    DELETE_DISCOVER_US_SERIES,
    ERROR_DELETING_DISCOVER_US_SERIES,
    UPDATE_DISCOVE_US_SERIES,
    ERROR_UPDATING_DISCOVER_US_SERIES,
    GET_DISCOVER_US_SERIES_DATA,
    SETTING_DISCOVER_US_SERIES_STATUS,
    ERROR_SETTING_DISCOVER_US_SERIES_STATUS,
    EMPTY_DISCOVER_US_SERIES_ITEM,
    ADD_DISCOVER_WITH_SENTFROM
} from "../Actions/Types";

const initialState = {
    items: [],
    item: {},
    showModel: '',
    updateShowModal: '',

    loading: '',
    error_loading_discover_us_series: '',

    error_adding_discover_us_series: '',
    discover_us_series_added_successfully: '',

    discover_us_series_deleted_successfully: '',
    error_deleting_discover_us_series: '',

    discover_us_series_updated_successfully: '',
    error_updating_discover_us_series: '',

    setting_state_series_sucessfully: '',
    error_setting_series_state: ''

}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_DISCOVER_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }

        case FETCH_ALL_DISCOVER_US_SERIES:
            return {
                ...state,
                items: action.payload,
                loading: false,
                error_loading_discover_us_series: ''
            }

        case GET_DISCOVER_US_SERIES_DATA:
            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_discover_us_series: ''
            }



        case ERROR_LOADING_DISCOVER_US_SERIES:
            return {
                ...state,
                error_loading_discover_us_series: action.payload,
                loading: false
            }
        case SAVE_DISCOVER_US_SERIES:
            return {
                ...state,
                item: action.payload,
                showModel: false,
                items: state.items.concat(action.payload),
                discover_us_series_added_successfully: true
            }

        case ERROR_SAVING_DISCOVER_US_SERIES:
            return {
                ...state,
                error_adding_discover_us_series: action.payload,
                discover_us_series_added_successfully: false
            }

        case DELETE_DISCOVER_US_SERIES:
            return {
                ...state,
                item: action.payload,
                discover_us_series_deleted_successfully: true,
                loading: false
            }

        case ERROR_DELETING_DISCOVER_US_SERIES:
            return {
                ...state,
                error_deleting_discover_us_series: action.payload,
                // discover_us_series_deleted_successfully:false,
                loading: false
            }

        case SETTING_DISCOVER_US_SERIES_STATUS:
            return {
                ...state,
                item: action.payload,
                setting_state_series_sucessfully: true,
                loading: false
            }

        case ERROR_SETTING_DISCOVER_US_SERIES_STATUS:
            return {
                ...state,
                // setting_state_series_sucessfully:false,
                error_setting_series_state: action.payload,
                loading: false
            }

        case UPDATE_DISCOVE_US_SERIES:
            return {
                ...state,
                item: action.payload,
                discover_us_series_updated_successfully: true,
                error_updating_discover_us_series: '',
                updateShowModal: false,

                loading: false

            }

        case ERROR_UPDATING_DISCOVER_US_SERIES:
            return {
                ...state,
                discover_us_series_updated_successfully: false,
                error_updating_discover_us_series: action.payload,
                loading: false,

            }



        case EMPTY_DISCOVER_US_SERIES:
            return {
                ...state,
                error_adding_discover_us_series: '',
                discover_us_series_added_successfully: '',

                discover_us_series_deleted_successfully: '',
                error_deleting_discover_us_series: '',

                discover_us_series_updated_successfully: '',
                error_updating_discover_us_series: '',

                setting_state_series_sucessfully: '',
                error_setting_series_state: '',

                loading: '',
            }
        case EMPTY_DISCOVER_US_SERIES_ITEM:
            return {
                ...state,
                item: {}
            }

        case LOAD_DISCOVER_US_SERIES:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}