import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import firebase from 'firebase/app'

/* Components */
import Footer from '../Layout/Footer';
import NavBar from '../Layout/NavBar';
import SideMenu from '../Layout/SideMenu';
import Loader from '../Layout/Loader';

/* Assets */
import visa from '../../../images/portal/current-visa.png';
import fawry from '../../../images/portal/fawry-logo.png';
import prepaidIcon from '../../../images/portal/prepaid.png';
import mada from '../../../images/portal/mada.png';
import stc from '../../../images/portal/stc.png';

/** Actions */
import { getUserHistory } from '../../../Actions/MobilePaymentHistoryAction';

/* Routes */
import {
  PORTAL_SUBSCRIPTION_HISTORY
} from '../../../routes';

const INITIAL_STATE = {
  token: ''
}

class CustomerPaymentHistory extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    this.props.history.push(PORTAL_SUBSCRIPTION_HISTORY);

  }

  componentDidMount() {
    // import("../../../css/portal-style.css");
    if (firebase.auth().currentUser) {
      this.props.getUserHistory();
    }
  }

  render() {
    const { userPaymentHistory } = this.props;
    const { error, loading } = this.props;
    let userHistoryList = null
    let packageType = '';
    let paymentIcon = '';
    if (loading === true) {
      userHistoryList = (<Loader />)
    }
    else if (loading === false && error === '') {
      userHistoryList = userPaymentHistory.length ? (
        userPaymentHistory.map(paymentHistoryItem => {

          if (paymentHistoryItem.userPaymentPackageId.paymentPackageId.packageType
            == 'Monthly') {
            packageType = (
              <span className="type">
                شهري &nbsp;
              </span>
            )
          }

          else if (paymentHistoryItem.userPaymentPackageId.paymentPackageId.packageType
            == 'Yearly') {
            packageType = (
              <span className="type">
                سنوي &nbsp;
              </span>
            )
          }

          else if (paymentHistoryItem.userPaymentPackageId.paymentPackageId.packageType
            == 'Weekly') {
            packageType = (
              <span className="type">
                أسبوعي &nbsp;
              </span>
            )
          }

          if
          (paymentHistoryItem.userPaymentPackageId.type === 'FIT_STC' ||
            paymentHistoryItem.userPaymentPackageId.type === 'FIT_MOBILY' ||
            paymentHistoryItem.userPaymentPackageId.type === 'FIT_ZAIN' ||
            paymentHistoryItem.userPaymentPackageId.type === 'q_Virgin'||
            
            paymentHistoryItem.userPaymentPackageId.type === 'JEEL_Orange' ||
            paymentHistoryItem.userPaymentPackageId.type === 'JEEL_Vodafone' ||
            paymentHistoryItem.userPaymentPackageId.type === 'JEEL_We'||
            paymentHistoryItem.userPaymentPackageId.type === 'JEEL_Etisalat' ) {
            paymentIcon = (
              <span>   
                <p style={{ fontWeight: 'bold', fontSize: '18px' }}>
                تم الأشتراك عن طريق شركات الاتصالات </p>

              </span>
            )
          }

          if (paymentHistoryItem.userPaymentPackageId.type === 'MADA') {
            paymentIcon = (
              <div className="history-logo">
                <img src={mada} alt="" />
              </div>
            )
          }
          if (paymentHistoryItem.userPaymentPackageId.type === 'STC_PAY') {
            paymentIcon = (
              <div className="history-logo">
                <img src={stc} alt="" />
              </div>
            )
          }
          if (paymentHistoryItem.userPaymentPackageId.type === 'Fawry') {
            paymentIcon = (
              <div className="history-logo">
                <img src={fawry} alt="" />
              </div>
            )
          } if (paymentHistoryItem.userPaymentPackageId.type === 'Stripe') {
            paymentIcon = (
              <div className="history-logo">
                <img src={visa} alt="" />
              </div>
            )
          } else if (paymentHistoryItem.userPaymentPackageId.type === 'Pre_Paid_Card') {
            paymentIcon = (
              <span>   <p style={{ fontWeight: 'bold', fontSize: '18px' }}> بطاقة مسبقة الدفع </p>

                <div className="history-logo">
                  <img src={prepaidIcon} alt="" />

                </div>
              </span>
            )
          }
          return (
            <div className="history-card">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  {paymentIcon}
                  <div className="history-date">
                    {paymentHistoryItem.userPaymentPackageId.paymentDatetime}
                  </div>
                </div>
                <div className="type">
                  اشتراك {packageType}
                </div>
              </div>
            </div>
          )
        })
      )
        : (
          <div className="history-card">
            <div className="d-flex justify-content-between align-items-center">
              <div className="align-items-center" style={{ paddingBottom: '250px' }}>
                لا يوجد سجل باقات حالياً
                 </div>
            </div>
          </div>
        )

    }
    else {
      userHistoryList = (<div><br /><center><div>{error}</div></center><br /></div>)
    }
    return (
      <body>
        {/** Start NavBar **/}
        <NavBar />
        {/** End NavBar */}
        <div className="main-container">
          <div className="container">
            <div className="form-row">
              {/** Side Menu **/}
              <SideMenu />
              {/** End Side Menu */}
              <div className="col-12 col-md-8">
                <div className="content-container">
                  <div className="history-content">
                    <div className="history-title h2 bold-title" style={{ float: 'right' }}>
                      سجل الباقات السابقة
                    </div>
                    {/** Start History Cards */}
                    <div className="history-cards">
                      <br /><br /> <br />
                      {userHistoryList}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/** Start Footer */}
        <Footer />
        {/** End Footer */}

      </body>
    )
  }
}

const mapStateToProps = state => ({
  token: state.user.token,
  userPaymentHistory: state.userPaymentHistory.items,
  loading: state.userPaymentHistory.loading,
  error: state.userPaymentHistory.getting_user_payment_history_error,
  sucess: state.userPaymentHistory.getting_user_payment_history_sucessfully,
});

export default withRouter
  (connect(mapStateToProps,
    {
      getUserHistory
    })
    (CustomerPaymentHistory));
