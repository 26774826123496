import { 
    GET_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    LOAD_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    ERROR_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    EMPTY_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
   } from "../Actions/Types";

   const initial_state = {
    reports: [],
    loading: false,
    error_loading_subscriptions_report_by_country: '',
  }

export default function (state = initial_state, action){
switch(action.type){
       case LOAD_SUBSCRIPTIONS_REPORT_BY_COUNTRY:
       return {
           ...state,
           loading: true,
       }
   
   case GET_SUBSCRIPTIONS_REPORT_BY_COUNTRY:
       return {
           ...state,
           reports: action.payload,
           loading:false,
           error_loading_subscriptions_report_by_country:'',
       }
   
   case ERROR_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY:
       return{
           ...state,
           loading: false,
           error_loading_subscriptions_report_by_country: action.payload,
       }
       case EMPTY_SUBSCRIPTIONS_REPORT_BY_COUNTRY:
       return{
           ...state,
           reports: '',
           loading: '',
           error_loading_subscriptions_report_by_country:'',
       }
       
   default:
       return state;    
}
}