import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import { withFirebase } from '../../config';

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';

/* Components */
import GetAllSetting from './GetAllSetting';
import AddSetting from './AddSetting';
import EditSetting from './EditSetting';

/* Routes */
import { SIGN_IN, SETTINGS, ADD_SETTING, EDIT_SETTING, GET_SETTING_TO_EDIT } from '../../routes';

class SettingAuth extends Component {
    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            });
        }
    }

    componentDidMount() {
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to={SIGN_IN}></Redirect>;
        }
    }

    render() {
        return (
            <span>
                <Route path={SETTINGS} component={GetAllSetting} />
                <Route path={ADD_SETTING} component={AddSetting} />
                <Route path={GET_SETTING_TO_EDIT} component={EditSetting} />
            </span>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
});

export default withFirebase(
    connect(mapStateToProps, { getUserData })(SettingAuth)
);
