import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import ReactTable from 'react-table';
import React,{Component} from 'react'
import Loader from '../Layout/Loader'
const FoldableTable = FoldableTableHOC(ReactTable);

class ReportList extends Component
{


    componentWillReceiveProps(next)
    {
        // console.log(next.kidsReport)
    }

    render()
    {
        const columns =[
            {
                Header:"ID",
                accessor:"kidId",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.kidId}
                        </div>
                    )
                }
            },
            {
                Header:"Name",
                accessor:"kidName",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.kidName}
                        </div>
                    )
                }
            },
            {
                Header:"Gender",
                accessor:"kidGender",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.kidGender}
                        </div>
                    )
                }
            },
            {
                Header:"Age",
                accessor:"kidAge",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.kidAge}
                        </div>
                    )
                }
            },
            {
                Header:"Country",
                accessor:"country",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.country}
                        </div>
                    )
                }
            },
           
            {
                Header:"content name",
                accessor:"contentName",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.contentName}
                        </div>
                    )
                }
            },
           
            {
                Header:"Type",
                accessor:"contentType",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                filterable:false,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.contentType}
                        </div>
                    )
                }
            }
            ,
            {
                Header:"number of watching time",
                accessor:"numberOfWatchingTime",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                filterable:false,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.numberOfWatchingTime}
                        </div>
                    )
                }
            },
            {
                Header:"first time watching date",
                accessor:"firstTimeWatchingDate",
                width:120,
                MinWidth:120,
                MaxWidth:130,
                resizable:true,
                filterable:false,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.firstTimeWatchingDate}
                        </div>
                    )
                }
            },
            {
                Header:"first time watching duration",
                accessor:"firstTimeWatchingDuration",
                width:180,
                MinWidth:180,
                MaxWidth:180,
                resizable:true,
                filterable:false,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.firstTimeWatchingDuration}
                        </div>
                    )
                }
            },
            {
                Header:"watching percentage",
                accessor:"watchingPercentage",
                width:150,
                MinWidth:150,
                MaxWidth:170,
                resizable:true,
                filterable:false,
                headerClassName:'table-header',
                Cell:props=>
                {
                    return(
                        <div style={{ "textAlign": "center" }}>
                            {props.original.watchingPercentage}
                        </div>
                    )
                }
            }
        ]
        const {kidsReport,error,loading} = this.props
        let table = null;
        console.log(kidsReport)
        if (loading===true)
        {
            table = <center><div><Loader/></div></center>

        }

        else if (error=='' && loading===false)
        {
            table = 
             <div>
                    <ReactTable
                        className=" table-striped custm-table"
                        columns={columns}
                        data={kidsReport}
                        filterable
                    
                    >

                    </ReactTable>
                </div>
        }
        return (
            <div>
            {table}
            </div>
        )
    }

}


export default ReportList;