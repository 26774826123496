import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Input, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

/* Redux */
import firebase from 'firebase/app'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';

/* Assets */
import mainLogoM from '../../../images/portal/logo-big.png';
import succIcon from '../../../images/portal/succ-sign.svg';

/* Actions */
import { signInWithPhone, empty } from '../../../Actions/AuthActions';
/* Routes */
import { PORTAL_LOGGED_BY_PHONE, PORTAL_CHANGE_LOGIN_METHODS } from '../../../routes';

const INITIAL_STATE = {
  code: '',
  code1: '',
  code2: '',
  code3: '',
  code4: '',
  code5: '',
  redirect: false,
  confirmResult: '',
  prev: false,
  errorMsg: '',
  phone: '',
}

class PhoneCode extends Component {

  constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
  }

  componentDidMount(){
    // import("../../../css/portal-style.css").then(()=>{});
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
    });
    const { customer } = this.props;
    const phoneNumber = customer.phone;
    const appVerifier = window.recaptchaVerifier;
    this.setState({phone: customer.phone})
    if(customer.phone !== undefined && customer.phone !== null && customer.phone !== ''){
      firebase
      .auth()
      .signInWithPhoneNumber(customer.phone, appVerifier)
      .then(confirmResult => {
        // success
        this.setState({confirmResult: confirmResult});
      })
      .catch(error => {
        // error
        this.props.empty();
        if(error.message === 'INVALID_PHONE_NUMBER'){
          this.setState({errorMsg: 'صيغة الهاتف غير صحيحة، برجاء إضافة كود الدولة قبل الرقم'})
        }else{
          this.setState({errorMsg: "لم نتمكن من إرسال رمز التفعيل، فضلًا تأكد من رقم الهاتف وجودة اتصالك بالإنترنت"})
        }
        //this.setState({prev: true});
      });
    }else{
      this.setState({prev: true})
    }
  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces });
    setTimeout(() => {
      this.setState({code: this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4 + this.state.code5 + this.state.code6 })
    }, 1000)

  }

  handleNext = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    let name = e.target.name;
    this.setState({[e.target.name]: checkSpaces });
    setTimeout(() => {
      this.setState({code: this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4 + this.state.code5 + this.state.code6 })
    }, 1000)
    if(name === 'code1')
     this.secondTextInput.focus();
     if(name === 'code2')
      this.thirdTextInput.focus();
    if(name === 'code3')
      this.forthTextInput.focus();
    if(name === 'code4')
     this.fifthTextInput.focus();
    if(name === 'code5')
      this.sixthTextInput.focus();
  }

  submit = (e) => {
      const {customer} = this.props;
      this.state.confirmResult.confirm(this.state.code).then((result) => {
      // User signed in successfully.
      // call user login
      //alert("success")
      this.props.signInWithPhone(this.state.phone);
      e.preventDefault();

    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      this.props.empty()
      this.setState({errorMsg: "رمز التفعيل المُدخل غير صحيح"})

    })
  }

  resend = (e) => {
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //   'size': 'invisible',
    // });
    const appVerifier = window.recaptchaVerifier;
    firebase.auth().signInWithPhoneNumber(this.state.phone, appVerifier).then(confirmResult => {
      // success
      this.setState({confirmResult: confirmResult});
    })
    .catch(error => {
      // error
      this.props.empty();
      if(error.message === 'INVALID_PHONE_NUMBER'){
        this.setState({errorMsg: 'صيغة الهاتف غير صحيحة، برجاء إضافة كود الدولة قبل الرقم'})
      }else{
        this.setState({errorMsg: "لم نتمكن من إرسال رمز التفعيل، يرجى المحاولة مرة اخرى"})
      }
    })
  }

  render(){
    const { customer } = this.props;
    const {loginsuccess, loginerror, loginloading } = this.props;
    if(this.state.prev === true){
        this.props.empty();
        return (<Redirect to={PORTAL_LOGGED_BY_PHONE}> </Redirect>);
    }
    if(loginsuccess === true){
      let redirect = {PORTAL_CHANGE_LOGIN_METHODS};
      this.props.empty();
      return (<Redirect to={redirect}> </Redirect>);

    }
    if(loginsuccess === false && (loginerror !== '' && loginerror !== false && loginerror !== undefined)){
        this.setState({errorMsg: "الرقم المُدخل غير مسجل لدينا"})
      //this.setState({error: true, errorMsg: loginerror});
      this.props.empty();
    }
    return(
      <body>
       <div className="other-methods-container">
        <div className="logo-container">
          <div className="container">
             <div className="logo">
               <img src={mainLogoM} alt="logo" />
             </div>
          </div>
        </div>

         <section className="redirect-content">
           <div className="container">
               <div className="redirect-btn">
                   <Link to={PORTAL_LOGGED_BY_PHONE}>
                     <Icon name='right arrow' />
                     <span className="text"> رجوع</span>
                   </Link>
               </div>
           </div>
         </section>

         <section className="dont-worry">
          <div className="container">
              <div className="dont-wory-card">
                  <div className="card-content text-center">
                  <p style={{color: 'red', textAlign: 'center'}}> {this.state.errorMsg}</p>
                      <div className="card-title h4"  id="recaptcha-container">تم ارسال الكود</div>
                      <div className="code-icon">
                          <img src={succIcon} alt=""/>
                      </div>
                      <div className="description-text">
                          <span>من فضلك قم بإدخال الكود المكون من 6 أرقام والمرسل في رسالة نصية إلى الهاتف رقم </span>
                          <span className="phone-number">{this.state.phone}</span>
                      </div>
                      <form className="code-form">
                          <div className="form-group">
                              <input type="text" className="form-control" maxlength="1" autoFocus
                                     name = "code1" value={this.state.code1}
                                     onChange={this.handleNext}
                                     blurOnSubmit={false}
                                     />
                          </div>
                          <div className="form-group">
                              <input type="text" className="form-control" maxlength="1"
                                      name = "code2" value={this.state.code2}
                                      ref={(input) => { this.secondTextInput = input; }}
                                      onChange={this.handleNext}/>
                          </div>
                          <div className="form-group">
                              <input type="text" className="form-control" maxlength="1"
                                      name = "code3" value={this.state.code3}
                                      ref={(input) => { this.thirdTextInput = input; }}
                                      onChange={this.handleNext}/>
                          </div>
                          <div className="form-group">
                              <input type="text" className="form-control" maxlength="1"
                                     name = "code4" value={this.state.code4}
                                     ref={(input) => { this.forthTextInput = input; }}
                                     onChange={this.handleNext}/>
                          </div>
                          <div className="form-group">
                              <input type="text" className="form-control" maxlength="1"
                                     name = "code5" value={this.state.code5}
                                     ref={(input) => { this.fifthTextInput = input; }}
                                     onChange={this.handleNext}/>
                          </div>
                          <div className="form-group">
                              <input type="text" className="form-control" maxlength="1"
                                     name = "code6" value={this.state.code6}
                                     ref={(input) => { this.sixthTextInput = input; }}
                                     onChange={this.handleNext}/>
                          </div>
                      </form>
                      <div className="form-group custom-btn">
                          <button  type="submit" className="btn phone-btn" onClick={this.submit}>تأكيد</button>
                      </div>

                  </div>

              </div>
              <div className="resend-code">
                  <span>لم يتم الأرسال؟</span>
                  <span>
                      <button className="resend" type="submit" onClick={this.resend}>اعد ارسال الكود</button>
                  </span>
              </div>
          </div>
         </section>

       </div>
        <Footer />
      </body>
    )
  }
}

PhoneCode.propTypes = {

}
const mapStateToProps = state => ({
  customer: state.customers.item,
  authUser: state.authUser.item,
  loginsuccess: state.authUser.success,
  loginerror: state.authUser.errorMsg,
  loginloading: state.authUser.loading,
});

export default withRouter
    (connect
        (mapStateToProps,{ signInWithPhone, empty })
        (PhoneCode)
    );
