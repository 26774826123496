import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withFirebase } from '../../config';
import propTypes from 'prop-types';
import { Confirm } from 'semantic-ui-react'

/*Redux*/
import { connect } from 'react-redux';
import {
    fetchAllGameData,
    deleteGameData,
    emptyGameData
} from '../../Actions/GameDataAction'

/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import Loader from '../Layout/Loader';

import '../../css/AppCss/gamePreview.css'

import dragAndDropIcon from '../../images/Drag-Shape.png';
import '../../css/AppCss/gamePreview.css';

/* Routes */
import {
    SIGN_IN, ADMIN_LAND_PAGE, ALL_GAME_PARENTS, GAME_DATA_TO_EDIT,
    ADD_GAME_DATA
} from '../../routes';

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate)
    var d = new Date(date.toString())
    var d = DateFormate(d)
    return d;
}

const INITIAL_STATE = {
    game_Image: "",
    open: false

}

class GetAllGameData extends Component {


    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.props.fetchAllGameData();
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }

    }

    componentDidMount() {
        this.props.fetchAllGameData();
    }

    open = (e, id) => {
        this.setState({ open: true, id: id })
    }

    close = () => this.setState({ open: false })


    handleDeleteGameData(e) {
        const { id } = this.state;
        e.preventDefault();

        this.props.deleteGameData(id)

        this.close();

    }


    render() {

        const { gameData, loading, success, error, successAllData } = this.props;

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyGameData();
        }
        else if (success === true) {
            alert("Game Data Item Deleted Successfully");
            this.props.emptyGameData();
            this.props.fetchAllGameData();
        }

        if (successAllData === true) {
            this.props.emptyGameData();

        }

        let gameDataList = ""
        if (loading === true) {
            gameDataList = (<Loader />)
        }
        else if (gameData !== null) {

            gameDataList = gameData.length ? (
                gameData.map(game => {
                    var deletGame = ''

                    // disable deleting Main Game Data
                    if (game.gameKey !== "Puzzle"
                        && game.gameKey !== "Paint"
                        && game.gameKey !== "Drag-Drop") {
                        deletGame = (
                            <span>
                                <button className="seriesCardButtons"
                                    onClick={e => this.open(e, game.id)}
                                >
                                    <i className="" >
                                        <FontAwesomeIcon icon="trash-alt" />
                                    </i>
                                </button>
                                <Confirm id="modal"
                                    content='Are you sure you want to delete this game data??'
                                    open={this.state.open}
                                    onCancel={this.close}
                                    onConfirm={this.handleDeleteGameData.bind(this)} />

                            </span>)
                    }
                    return (
                        // start card
                        <div className="card">
                            <div className="row" >
                                <div className="col ">
                                    <img className="rounded"
                                        src={game.image}
                                        alt={game.image}
                                        height="90" width="90" />
                                </div>
                                <div className="col-9">
                                    <div>
                                        <span className="card-title" color="gray">
                                            <Link className="link"
                                                to={GAME_DATA_TO_EDIT + "/" + game.id}>
                                                {game.name}
                                            </Link>
                                        </span>

                                        {/* Start delete */}
                                        {deletGame}
                                        {/* end delete */}

                                        <Link className="link" to={GAME_DATA_TO_EDIT + "/" + game.id}>
                                            <button className="songCardButtons"  >
                                                Edit
                                            </button>
                                        </Link>
                                    </div>
                                    <span className="font-italic small-font">
                                        <span className="date">{GMTtolocalTime(game.createdDate)}</span>
                                        <hr />
                                        <span><p>{game.gameKey}</p></span>
                                    </span>


                                </div>
                            </div>
                        </div>

                    )
                }
                )) : (
                <div className="text-center">No Game Data </div>
            )
        }

        return (
            <div className="container">

                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />

                                            <div className="col-12 left-main-body" id="left-main-body">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link>
                                                        <span> {'>'} </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link to={ALL_GAME_PARENTS}>Get All Game Parents</Link>
                                                        <span> {'>'} </span>
                                                    </span>
                                                    <span>Game Data</span>
                                                </div>

                                                <Link to={ADD_GAME_DATA}>
                                                    <button type="submit" className="SongsButtons">
                                                        + Add New Item
                                                    </button>
                                                </Link>

                                            </div>


                                            <div className="container">

                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col ">
                                                                    <img className="rounded"
                                                                        src={dragAndDropIcon}
                                                                        alt=""
                                                                        height="65"
                                                                        width="65" />
                                                                </div>
                                                                <div className="col-10">

                                                                    <div>
                                                                        <span className="card-title"
                                                                            color="gray">
                                                                            Game Data
                                                                        </span>
                                                                    </div>
                                                                    <span className="font-italic small-font">
                                                                        <hr />
                                                                        <div className="">
                                                                            <div className="container">

                                                                                {gameDataList}
                                                                                {/* {this.state.gameData !== null
                                                                                    && this.state.gameData.length < 1
                                                                                    && <div className="col-12">No games</div>} */}
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
GetAllGameData.propTypes = {
    fetchAllGameData: propTypes.func.isRequired,
    deleteGameData: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.item,

    gameData: state.gameData.items,
    loading: state.gameData.loading_data,
    error: state.gameData.deleting_game_data_error,
    success: state.gameData.deleting_game_data_sucessfully,

    successAllData: state.gameData.getting_all_game_data_sucessfully
});
export default withFirebase(
    connect(
        mapStateToProps,
        {
            fetchAllGameData,
            deleteGameData,
            emptyGameData
        }
    )
        (GetAllGameData));
