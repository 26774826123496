import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Components */
import AddRadio from './AddRadio'
import EditRadio from './EditRadio'
import GetAllRadio from './GetAllRadio'
import ViewRadio from './ViewRadio'

import AddRadioChannel from '../RadioChannel/AddRadioChannel'
import EditRadioChannel from '../RadioChannel/EditRadioChannel'
import RadioChannelDetails from '../RadioChannel/RadioChannelDetails'
import PublishRadioChannel from '../RadioChannel/PublishRadioChannel'
import RadioChannelEvaluation from '../RadioChannel/RadioChannelEvaluation'
import EditActivitRadioChannel from '../RadioChannel/EditActivitRadioChannel'
import EditEvaluationRadioChannel from '../RadioChannel/EditEvaluationRadioChannel'
import AddRadioChannelActivity from '../RadioChannel/AddRadioChannelActivity'

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
import { ADD_RADIO, ADD_RADIO_CHANNEL, ADD_RADIO_CHAN_ACTIVITY, ADMIN_LAND_PAGE, ALL_RADIO, EDIT_ACTIVITY_RADIO_CHAN, EDIT_RADIO, EDIT_RADIO_CHANNEL, EDIT_RADIO_CHAN_EVAL, GET_RADIO_CHAN_EVAL, PUBLISH_RADIO_CHANNEL, SIGN_IN, VIEW_RADIO, VIEW_RADIO_CHANNEL } from '../../routes'

export class RadioAuth extends Component {
    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    componentDidMount() {
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to={SIGN_IN}></Redirect>
        }
    }

    render() {
        const { userPaymentProfile } = this.props;

        if (userPaymentProfile.user_role.role === "Analyst") {
            return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
        }

        return (
            <span>
                <Route path={ALL_RADIO} component={GetAllRadio} />
                <Route path={ADD_RADIO} component={AddRadio} />
                <Route path={VIEW_RADIO} component={ViewRadio} />
                <Route path={EDIT_RADIO} component={EditRadio} />

                {/* adding Radio Channel Routers */}
                <Route path={ADD_RADIO_CHANNEL} component={AddRadioChannel} />
                <Route path={EDIT_RADIO_CHANNEL} component={EditRadioChannel} />
                <Route path={VIEW_RADIO_CHANNEL} component={RadioChannelDetails} />
                <Route path={PUBLISH_RADIO_CHANNEL} component={PublishRadioChannel} />
                <Route path={GET_RADIO_CHAN_EVAL} component={RadioChannelEvaluation} />
                <Route path={EDIT_ACTIVITY_RADIO_CHAN} component={EditActivitRadioChannel} />
                <Route path={EDIT_RADIO_CHAN_EVAL} component={EditEvaluationRadioChannel} />
                <Route path={ADD_RADIO_CHAN_ACTIVITY} component={AddRadioChannelActivity} />

            </span>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
    (connect
        (mapStateToProps,
            { getUserData }
        )(RadioAuth)
    );
