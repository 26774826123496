import {createStore,applyMiddleware,compose} from 'redux';
import rootReducer from './Reducers';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {getFirebase,reactReduxFirebase} from 'react-redux-firebase';
import {getFirestore,reduxFirestore} from 'redux-firestore';
import firebase from './config/fbConfig'

const initialState = {};
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig={
    key:'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const Store = createStore(persistedReducer,
    initialState,
    composeEnhancer(applyMiddleware(thunk.withExtraArgument({getFirebase,getFirestore}))
    ,reactReduxFirebase(firebase, { attachAuthIsReady: true }),
    reduxFirestore(firebase)
    ),

    );


export const presistor = persistStore(Store)
export default Store;
