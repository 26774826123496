import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { DateInput } from 'semantic-ui-calendar-react';
import { Dropdown, Accordion, Icon } from 'semantic-ui-react';

/*Redux*/
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import Loader from '../Layout/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*Actions */
import { emptyEvaluationAll } from '../../Actions/EvaluationActions'
import { fetchStories } from '../../Actions/StoryActions'
import { emptyEvaluationCategory } from '../../Actions/EvaluationCategoryAction';
import { clearQuestions } from '../../Actions/QuestionActions';
/*Component*/
import DeleteStory from './DeleteStory'
import SetStoryStatus from './SetStoryStatus';

import NAME_CONDITIONS from '../ConstData/NameFilterConditions';
import DESCRIPTION_CONDITIONS from '../ConstData/NameFilterConditions';
import DATE_CONDITIONS from '../ConstData/DateFilterConditions'
import FREE_PAID from '../ConstData/FreeOrPaid'
import STATUS from '../ConstData/Status'

/*Css*/
import '../../../node_modules/video-react/dist/video-react.css';
import "bootstrap/dist/css/bootstrap.min.css";
import '../../css/Songs.css';

/* Routes */
import { ADD_STORY, STORY_TO_GET } from '../../routes';

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

class GetAllStories extends Component {

    state = {
        activeIndex: 0,

        filterName: '',
        nameFilterCondition: 'contains',

        dateFilterCondition: 'on',
        publishDate: '',
        secondPublishDate: '',

        isFree: 'none',
        status: 'none',

        descriptionFilterCondition: 'contains',
        description: ''
    }


    componentDidMount() {
        this.props.emptyEvaluationCategory();
        this.props.emptyEvaluationAll();
        this.props.fetchStories();
        this.props.clearQuestions();

    }

    handleStatus = (e, { value }) => {
        this.setState({
            status: value
        })
    }

    handlePaymentAva = (e, { value }) => {
        this.setState({
            isFree: value
        })
    }

    handleChange = (e) => {

        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
    }

    handleDateChange = (e, { name, value }) => {

        if (this.state.hasOwnProperty(name)) {
            this.setState({
                [name]: value
            })
        }
    }

    handleNameFilterConditions = (e, { value }) => {
        this.setState({
            nameFilterCondition: value,
        })
    }

    handleDescriptionFilterConditions = (e, { value }) => {
        this.setState({
            descriptionFilterCondition: value,
        })
    }

    handleDateFilterConditions = (e, { value }) => {
        if (value == 'on' ||
            value == 'before' ||
            value == 'after') {
            this.setState({ secondPublishDate: '' })
        }

        this.setState({
            dateFilterCondition: value,
        })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }
    render() {

        let secondDate = '', and = '', firstDate = ''

        firstDate = (<div className="col-md-4 col-4">

            <label className="label">Publish Date</label>
            <DateInput
                name="publishDate"
                closeOnMouseLeave
                placeholder="Publish date"
                iconPosition="right"
                dateFormat="MM/DD/YYYY"
                fluid
                value={this.state.publishDate}
                onChange={this.handleDateChange}
            />
        </div>
        )

        if (this.state.dateFilterCondition == 'not between' ||
            this.state.dateFilterCondition == 'between') {


            firstDate = (<div className="col-md-3 col-3">

                <label className="label">Publish Date</label>
                <DateInput
                    name="publishDate"
                    closeOnMouseLeave
                    placeholder="Publish date"
                    iconPosition="right"
                    dateFormat="MM/DD/YYYY"
                    fluid
                    value={this.state.publishDate}
                    onChange={this.handleDateChange}
                />
            </div>)
            and = (<div className="col-md-1 col-1">
                <label className="label mt-4">And</label>
            </div>
            )
            secondDate = (
                <div className="col-md-3 col-3">
                    <label className="label">Publish Date</label>
                    <DateInput
                        name="secondPublishDate"
                        closeOnMouseLeave
                        placeholder="Publish date"
                        iconPosition="right"
                        dateFormat="MM/DD/YYYY"
                        fluid
                        value={this.state.secondPublishDate}
                        onChange={this.handleDateChange}
                    />
                </div>
            )
        }

        const {
            filterName, nameFilterCondition,
            description, descriptionFilterCondition,
            publishDate,
            dateFilterCondition,
            secondPublishDate,
            isFree,
            status,
        } = this.state;

        const { stories } = this.props;
        const { error } = this.props;
        const { storiesStatue } = this.props;
        let storiesList = null


        let choosenNameFilter = '',
            choosenPublishDate = '',
            choosenIsFreeOrNot = '',
            choosenStatus = '',
            choosenDescription = ''

        const FilteredList = stories.filter(item => {
            if (nameFilterCondition == 'is') {
                choosenNameFilter = item.name == filterName
            } else if (nameFilterCondition == 'does not contain') {
                choosenNameFilter = !item.name.includes(filterName)
            } else if (nameFilterCondition == 'is not') {
                choosenNameFilter = item.name != filterName
            } else {
                choosenNameFilter = item.name.includes(filterName)
            }
            if (item.description !== null &&
                item.description !== undefined &&
                item.description !== '') {

                if (descriptionFilterCondition == 'is') {
                    choosenDescription = item.description == description
                } else if (descriptionFilterCondition == 'does not contain') {
                    choosenDescription = !item.description.includes(description)
                } else if (descriptionFilterCondition == 'is not') {
                    choosenDescription = item.description != description
                } else {
                    if (description !== '' && description !== null && description !== undefined) {
                        choosenDescription = item.description.includes(description)

                    } else {
                        choosenDescription = true
                    }

                }
            }
            if (isFree == 'paid') {
                choosenIsFreeOrNot = item.isFree == 0
            } else if (isFree == 'free') {
                choosenIsFreeOrNot = item.isFree == 1
            }

            if (status == 'DEFAULT') {
                choosenStatus = item.status == 'Default' || item.status == 'DEFAULT'
            } else if (status == 'SUSPENDED') {
                choosenStatus = item.status == 'SUSPENDED'
            }


            let arrayOfDate = item.publishDate.split(" ")
            let date = arrayOfDate[0];

            if (dateFilterCondition == 'on') {
                choosenPublishDate = publishDate == date
            }
            else if (dateFilterCondition == 'after') {

                var seriesDate = new Date(date);
                var filterDate = new Date(publishDate);

                choosenPublishDate = filterDate < seriesDate
            }
            else if (dateFilterCondition == 'before') {
                var seriesDate = new Date(date);
                var filterDate = new Date(publishDate);

                choosenPublishDate = filterDate > seriesDate;
            }
            else if (dateFilterCondition == 'between') {

                if (secondPublishDate !== '' &&
                    secondPublishDate !== null &&
                    secondPublishDate !== undefined) {
                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);
                    var filterSecondDate = new Date(secondPublishDate);

                    choosenPublishDate = filterDate < seriesDate && seriesDate < filterSecondDate
                }
            }
            else if (dateFilterCondition == 'not between') {
                if (secondPublishDate !== '' &&
                    secondPublishDate !== null &&
                    secondPublishDate !== undefined) {
                    console.log("////////////////" + item.name + "////////////////////")
                    var seriesDate = new Date(date);
                    var filterDate = new Date(publishDate);
                    var filterSecondDate = new Date(secondPublishDate);

                    console.log("seriesDate")
                    console.log(date)
                    console.log(seriesDate)

                    console.log("filterDate")
                    console.log(publishDate)
                    console.log(filterDate)

                    console.log("filterDate")
                    console.log(secondPublishDate)
                    console.log(filterSecondDate)

                    let notbetween = filterDate < seriesDate && seriesDate < filterSecondDate

                    choosenPublishDate = !notbetween
                }

            }


            /* Name and Date and Payment and status and description*/
            if (publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("Name and Date and Payment and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter &&
                        choosenIsFreeOrNot && choosenStatus && choosenDescription
                }
            }

            /* Name and Date and Payment and status*/
            if (publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("Name and Date and Payment and status")
                return choosenPublishDate && choosenNameFilter && choosenIsFreeOrNot && choosenStatus
            }

            /* Name and Date and Payment and description*/
            if (publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("Name and Date and Payment and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter &&
                        choosenIsFreeOrNot && choosenDescription && choosenDescription
                }
            }

            /* Name and Date and Payment*/
            if (publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none'
            ) {
                console.log("Name and Date and Payment")
                return choosenPublishDate && choosenNameFilter && choosenIsFreeOrNot
            }
            ///* Name and Date and status and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("Name and Date and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter && choosenStatus && choosenDescription
                }
            }

            ///* Name and Date and status
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("Name and Date and status")
                return choosenPublishDate && choosenNameFilter && choosenStatus
            }

            ///* Name and Date and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("Name and Date and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenNameFilter && choosenDescription
                }
            }
            ///* Name and Date
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                filterName !== "" && filterName !== null && filterName !== undefined &&
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined
            ) {
                console.log("Name and Date")
                return choosenPublishDate && choosenNameFilter
            }

            //date and payment and status and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and payment and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenIsFreeOrNot && choosenStatus && choosenDescription
                }
            }

            //date and payment and status
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("date and payment and status")
                return choosenPublishDate && choosenIsFreeOrNot && choosenStatus
            }
            //date and payment and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and payment and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenIsFreeOrNot && choosenDescription
                }
            }

            //date and payment
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none') {
                console.log("date and payment")
                return choosenPublishDate && choosenIsFreeOrNot
            }

            //date and status and description
            else if (publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenStatus && choosenDescription
                }
            }
            //date and status
            else if (publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("date and status")
                return choosenPublishDate && choosenStatus
            }
            //date and description
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null &&
                dateFilterCondition !== undefined &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("date and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenPublishDate && choosenDescription
                }
            }
            //date
            else if (
                publishDate && publishDate !== null && publishDate !== undefined &&
                dateFilterCondition !== "" && dateFilterCondition !== null && dateFilterCondition !== undefined) {
                console.log("date")
                return choosenPublishDate
            }
            // name and payment and status and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("name and payment and status and description ")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenNameFilter && choosenIsFreeOrNot && choosenStatus && choosenDescription
                }
            }

            // name and payment and status
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("name and payment and status")
                return choosenNameFilter && choosenIsFreeOrNot && choosenStatus
            }

            // name and payment and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("name and payment and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenNameFilter && choosenIsFreeOrNot && choosenDescription
                }
            }
            // name and payment
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none') {
                console.log("name and payment")

                return choosenNameFilter && choosenIsFreeOrNot
            }

            //name and status and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined

            ) {
                console.log("name and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenNameFilter && choosenStatus && choosenDescription
                }
            }
            //payment and status and description
            else if (
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                status !== "" && status !== null && status !== undefined && status !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("payment and status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenIsFreeOrNot && choosenStatus && description
                }
            }
            //payment and status
            else if (
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("payment and status")
                return choosenIsFreeOrNot && choosenStatus
            }

            //payment and description
            else if (
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none' &&

                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null && descriptionFilterCondition !== undefined
            ) {
                console.log("payment and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenIsFreeOrNot && choosenDescription
                }
            }
            //payment
            else if (
                isFree !== "" && isFree !== null && isFree !== undefined && isFree !== 'none') {
                console.log("payment")
                return choosenIsFreeOrNot
            }

            // status and description
            else if (
                status !== "" && status !== null && status !== undefined && status !== 'none' &&
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("status and description")
                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenStatus && choosenDescription
                }
            }
            // status
            else if (
                status !== "" && status !== null && status !== undefined && status !== 'none') {
                console.log("status")
                return choosenStatus
            }

            //name and status
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined &&
                status !== "" && status !== null && status !== undefined && status !== 'none'
            ) {
                console.log("name and status")
                return choosenNameFilter && choosenStatus
            }
            //name and description
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null &&
                nameFilterCondition !== undefined &&
                descriptionFilterCondition !== "" &&
                descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("///name and description")

                // base case both are null return all values
                if ((filterName == '' || filterName == null || filterName == undefined) &&
                    (description == '' || description == null || description == undefined)) {
                    console.log("both empty")
                    return true;
                }
                else if ((filterName !== '' && filterName !== null && filterName !== undefined) &&
                    (description !== '' && description !== null && description !== undefined)) {
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {
                        console.log("both not empty")
                        return choosenNameFilter && choosenDescription
                    }
                }
                else if (filterName !== '' && filterName !== null && filterName !== undefined) {
                    console.log("name exist")
                    return choosenNameFilter
                }
                else if (description !== '' && description !== null && description !== undefined) {
                    console.log("description exist")
                    if (item.description !== null &&
                        item.description !== undefined &&
                        item.description !== ""
                    ) {

                        return choosenDescription;
                    }
                }
            }
            //name
            else if (
                nameFilterCondition !== "" && nameFilterCondition !== null && nameFilterCondition !== undefined) {
                console.log("name")
                return choosenNameFilter
            }
            // description
            else if (
                description !== "" && description !== null && description !== undefined &&
                descriptionFilterCondition !== "" && descriptionFilterCondition !== null &&
                descriptionFilterCondition !== undefined
            ) {
                console.log("description")

                if (item.description !== null &&
                    item.description !== undefined &&
                    item.description !== ""
                ) {
                    return choosenDescription
                }
            }
        })




        if (storiesStatue === true) {
            console.log('loading')
            storiesList = (<Loader />)
        }
        else if (storiesStatue === false && error === '') {

            let storyItems = stories

            if (filterName !== '' || publishDate || isFree || status || description !== '') {
                storyItems = FilteredList
            }



            storiesList = storyItems.length ? (
                storyItems.map(story => {

                    let storyDescription = '';
                    if (story.description !== null &&
                        story.description !== undefined &&
                        story.description !== '') {
                        storyDescription = story.description
                    }
                    else {
                        storyDescription = "No description"
                    }


                    return (
                        <div className="card" key={story.id}>
                            <div className="row" >
                                <div className="col ">
                                    <img className="rounded" src={story.iconImage} alt="" height="90" width="90" />
                                </div>
                                <div className="col-10">

                                    <div>
                                        <span className="card-title" color="gray">
                                            <Link className="link" to={STORY_TO_GET + '/' + story.id}>{story.name}</Link>
                                        </span>
                                        {/* <button className="songCardButtons"><FontAwesomeIcon icon="trash-alt"></FontAwesomeIcon></button> */}
                                        <DeleteStory id={story.id} />
                                        <Link to={ STORY_TO_GET + '/' + story.id}> <button className="songCardButtons"  >Edit</button> </Link>
                                        {/* <button className="songCardButtons">||</button> */}
                                        <SetStoryStatus id={story.id} status={story.status} />
                                    </div>
                                    <span className="font-italic small-font">
                                        <span className="date">{GMTtolocalTime(story.createdDate)}</span>
                                        <hr />
                                        <span><p>{storyDescription}</p></span>
                                    </span>


                                </div>
                            </div>
                        </div>
                    )
                })
            )
                : (
                    <div className="text-center">No Stories </div>
                )
        }
        else {
            storiesList = (<div><br /><center><div>{error}</div></center><br /></div>)
        }
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <Link to={ADD_STORY}>
                                                <button type="submit" className="SongsButtons">
                                                    + Add New Story
                                                </button>
                                            </Link>
                                            <br />
                                            <Accordion fluid styled>
                                                <Accordion.Title
                                                    active={this.state.activeIndex === 0}
                                                    index={0}
                                                    onClick={this.handleClick}
                                                >
                                                    <Icon name='dropdown' />
                                             Filter
                                                </Accordion.Title>
                                                <Accordion.Content active={this.state.activeIndex === 0}>


                                                    <div className="row" >

                                                        <div className="col-md-5 col-5">
                                                            <label className="label">Story Name Conditions</label>

                                                            <Dropdown
                                                                className="form-control"
                                                                placeholder='Select Condition'
                                                                selection
                                                                options={NAME_CONDITIONS}
                                                                onChange={this.handleNameFilterConditions}
                                                                value={this.state.nameFilterCondition}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-6">

                                                            <label className="label">Story Name</label>

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                name="filterName"
                                                                placeholder='..'
                                                                id="filterName"
                                                                onChange={this.handleChange}
                                                                value={this.state.filterName} />

                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row" >

                                                        <div className="col-md-5 col-5">
                                                            <label className="label">Story Description Conditions</label>

                                                            <Dropdown
                                                                className="form-control"
                                                                placeholder='Select Condition'
                                                                selection
                                                                options={DESCRIPTION_CONDITIONS}
                                                                onChange={this.handleDescriptionFilterConditions}
                                                                value={this.state.descriptionFilterCondition}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 col-6">

                                                            <label className="label">Story Description</label>

                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                name="description"
                                                                placeholder='..'
                                                                id="description"
                                                                onChange={this.handleChange}
                                                                value={this.state.description} />

                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-md-5 col-5">
                                                            <label className="label">Story Date Conditions</label>

                                                            <Dropdown
                                                                className="form-control"
                                                                placeholder='Select Condition'
                                                                selection
                                                                options={DATE_CONDITIONS}
                                                                onChange={this.handleDateFilterConditions}
                                                                value={this.state.dateFilterCondition}
                                                            />
                                                        </div>
                                                        {firstDate}
                                                        {and}
                                                        {secondDate}


                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-md-5 col-5">
                                                            <label className="label">
                                                                Select payment availability
                                                                </label>

                                                            <Dropdown required fluid selection
                                                                placeholder='Select Payment Availability'
                                                                id="episode_series_id"
                                                                onChange={this.handlePaymentAva}
                                                                options={FREE_PAID}
                                                                value={this.state.isFree}
                                                            />
                                                        </div>
                                                        <div className="col-md-4 col-4">

                                                            <label className="label">Select Status </label>
                                                            <Dropdown required fluid selection
                                                                placeholder='Select Status'
                                                                id="episode_series_id"
                                                                onChange={this.handleStatus}
                                                                options={STATUS}
                                                                value={this.state.status}
                                                            />
                                                        </div>
                                                    </div>

                                                </Accordion.Content>

                                            </Accordion>



                                            <div className="container">
                                                {storiesList}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )

    }
}
const mapStateToProps = state => ({
    stories: state.stories.items,
    storiesStatue: state.stories.loading,
    error: state.stories.error_loading_stories
});

export default
    withRouter
        (connect
            (mapStateToProps,
                {
                    fetchStories,
                    emptyEvaluationAll,
                    emptyEvaluationCategory,
                    clearQuestions
                }
            )
            (GetAllStories)
        );
