import {
    MW_Bahrain_LOAD_VERIFYING_CODE,
    MW_Bahrain_VERIFYING_CODE_SUCCESS,
    MW_Bahrain_VERIFYING_CODE_ERROR,

    MW_Bahrain_LOAD_RESEND_CODE,
    MW_Bahrain_RESEND_CODE_SUCCESS,
    MW_Bahrain_RESEND_CODE_ERROR,

    MW_Bahrain_EMPTY

} from "../../Actions/Types";

import { post } from 'axios'


export const verifyingCode = (pinCode, telecomCompany, phone) => {
//console.log('-(3): In verifyCode method: '+telecomCompany+" ,p: "+phone+" ,c: "+pinCode);
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: MW_Bahrain_LOAD_VERIFYING_CODE,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            //console.log(" token is: "+token);
            
            const url = "/bahrainBatelco/verifyPin"
            
            const formData = new FormData();
            formData.append('telecomCompany', telecomCompany)
            formData.append('pincode', pinCode)
            formData.append('msisdn', phone)
            
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',                    
                    'X-Authorization-Firebase': token
                }
            }
           
            //fetch(url, options).then((res) => {
            post(url, formData, config).then((res) => {
	
//console.log("-(4): return after BackEnd verifyPin: "+JSON.stringify(res.data,null,3));
	
                dispatch({
                    type: MW_Bahrain_VERIFYING_CODE_SUCCESS,
                    payload: res.data
                })
            }).catch((Error) => {
	
	
if (Error.response){
//console.log("Error.response: "+JSON.stringify(Error.response,null,3));
//do something

}else if(Error.request){
//console.log("Error.request: "+Error.request);
//do something else

}else if(Error.message){
//console.log("Error.message: "+Error.message);
//do something other than the other two

}
	
	
	
                dispatch({
                    type: MW_Bahrain_VERIFYING_CODE_ERROR,
                    payload: Error.response.data.message
                })

            })
        })
    }
}


export const resendCode = (phone, telecomCompany) => {
    return (dispatch, getState, { getFirebase }) => {

        dispatch({
            type: MW_Bahrain_LOAD_RESEND_CODE,
            payload: 'loading'
        })

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
          
            const url = "/bahrainBatelco/resendPinCode"
            
            const formData = new FormData();
            formData.append('msisdn', phone)
            formData.append('telecomCompany', telecomCompany)
            
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: MW_Bahrain_RESEND_CODE_SUCCESS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: MW_Bahrain_RESEND_CODE_ERROR,
                    payload: Error.response.data.message
                })

            })
        })
    }

}


export const emptyMw_Bahrain = () => dispatch => {
    dispatch({
        type: MW_Bahrain_EMPTY,
        payload: ''
    })
}