import React, { Component } from "react";
import { Redirect, NavLink } from "react-router-dom";
import branch from "branch-sdk";
import { JeelMADA, MadaWidgetURL } from '../../src/constants';

/*Redux */
import "firebase/firestore";
import "firebase/auth";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withFirebase } from "../../../config";
import firebase from "firebase/app";
/*Actions */
import { addSeriesIDtoRedirectionPage } from "../../../Actions/RedirectionPagesActions.js";
import {
  emptyMobileUserProfile,
  emptyMobileUserProfileWithItem,
} from "../../../Actions/MobilePaymentProfileAction";
import { getUserPaymentInfo } from "../../../Actions/MobilePaymentProfileAction";
import { clearPaymentData } from "../../../Actions/MobilePaymentActions";
import {
  getPromoCodeItemByName,
  emptyPromoCode,
  checkIfSavedPromoCode,
  getSavedPromoCode,
  emptyCodeFromRedux,
  deleteSavedPromoCode,
} from "../../../Actions/PromoCodesActions";
import { addSuccessMessage } from "../../../Actions/SuccessMessagesActions";
import {
  getCurrencyRate,
  emptyCurrencyRate,
} from "../../../Actions/CurrencyActions";

/*Components */

import Loader from "../Layout/Loader";
/** Routes */
import {
  PORTAL_PAYMENT_RESULT,
  PORTAL_UPGRADE_SUBSCRIPTION,
} from "../../../routes";
import Mada from "../../../images/portal/icons/mada.svg";
import { Link } from "react-router-dom";

const INITIAL_STATE = {
  redirectionBack: false,
  errorMsg: "",
  message: "",
  codeNameFromRedux: "",
  checkIfCurrencyRateExist: 0,
  currency: 1,
  openPopup: false,
  checkSaved: false,
  errorDeletingMsg: "",
  checked: false,
};

class MadaPayment extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.emptyMobileUserProfileWithItem();
    if (firebase.auth().currentUser) {
      this.props.getUserPaymentInfo();
      this.props.checkIfSavedPromoCode();
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.codeName !== prevState.codeNameFromRedux) {
      if (
        props.codeName !== undefined &&
        props.codeName !== "" &&
        props.codeName !== null
      ) {
        props.getPromoCodeItemByName(props.codeName);
      } else {
        //  props.getSavedPromoCode();
      }
      return {
        codeNameFromRedux: props.codeName,
      };
    }
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src =  MadaWidgetURL+`?checkoutId=${this.props.checkoutId}`;
    script.async = true;
    document.body.appendChild(script);
    this.script = script;
  }

  componentWillUnmount() {
    document.body.removeChild(this.script);
  }

  BRANCH_PURCHASE = () => {
    var event_and_custom_data = {};

    var content_items = [];

    var customer_event_alias = "";

    branch.logEvent(
      "PURCHASE",
      event_and_custom_data,
      content_items,
      customer_event_alias,
      function (err) {
        console.log(err);
      }
    );
  };

 
  handleClose = (e) => {
    this.setState({ openSnakBar: false });
  };

  callCurrecnyAPI(currency) {
    this.props.getCurrencyRate(currency);
    this.setState({ checkIfCurrencyRateExist: 1 });
  }

  handlePopupClose = (e) => {
    this.setState({ redirectionBack: true });
    e.preventDefault();
  };

  handlePopupConfirm = (e) => {
    this.props.deleteSavedPromoCode();
    e.preventDefault();
  };

  render() {

    const { userloading } = this.props;
    const { payingData } = this.props;
    const { promocodesuccess, promocode, promocodeload, isPromoCodeWork } = this.props;
    const { errorDeletingCode, savedcodeDeletedSuccessfully } = this.props;
    const {
      successSavedCodeChecked,
      loadingSavedCodeChecked,
      savedcode,
    } = this.props;
    const {checkoutId, idSuccess} = this.props;

    let codeDescription = "";
    let nPrice = payingData.cash;

    if (payingData === "") {
      return <Redirect to={PORTAL_UPGRADE_SUBSCRIPTION}> </Redirect>;
    }

    //// end payment
    //// default package
    var packageType = payingData.paymentType;
    var ePrice = 0;
    var eSPrice = 0;
    if (packageType === "Monthly") {
      ePrice = payingData.paymentCurrencyPackage.monthDiscountPrice;
      eSPrice = ePrice;
    } else if (packageType === "Yearly") {
      ePrice = payingData.paymentCurrencyPackage.yearDiscountPrice;
      eSPrice = ePrice;
    } else if (packageType === "Weekly") {
      ePrice = payingData.paymentCurrencyPackage.weekDiscountPrice;
      eSPrice = ePrice;
    }

    //// promo code
    if (
      promocodesuccess === true && isPromoCodeWork &&
      ((promocode !== null && promocode !== undefined && promocode !== "") ||
        promocode.uid !== undefined)
    ) {
      let text = "";
      var symbol = "USD";
      var type = "خصم";
      var amount = "";
      if (payingData.paymentCurrencyPackage !== undefined) {
        symbol =
          payingData.paymentCurrencyPackage.monthDiscountPriceConverted
            .currency;
      }
      const { currencyRate } = this.props;

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "EXTRA_TIME"
      ) {
        symbol = "أسابيع";
        type = "زيادة";
      }
      amount = promocode.discountAmount;

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "PERCENTAGE"
      ) {
        console.log("price");
        nPrice = payingData.cash - (amount * payingData.cash) / 100;
        nPrice = nPrice.toFixed(2);
        ePrice = ePrice - (amount * ePrice) / 100;
        ePrice = ePrice.toFixed(2);
      } else if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "FIXED"
      ) {
        // amount must be changed to the user country curreny
        if (
          this.state.checkIfCurrencyRateExist === 0 &&
          payingData.paymentCurrencyPackage !== undefined
        ) {
          this.callCurrecnyAPI(
            payingData.paymentCurrencyPackage.monthOriginalPriceConverted
              .currency
          );
        }
        console.log(
          payingData.cash + "   " + amount + "   " + currencyRate + "   "
        );
        nPrice = payingData.cash - amount * currencyRate;
        nPrice = nPrice.toFixed(2);
        ePrice = ePrice - amount;
        ePrice = ePrice.toFixed(2);
      }

      text = (
        <span>
          سعر الباقة بعد الخصم{" "}
          <span className="price-color">{nPrice + " " + symbol}</span> 
         . سعر الباقة قبل
          الخصم:{" "}
          <span className="price-color">
            {payingData.cash.toFixed(2) + " " + symbol}
          </span>
        </span>
      );

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "EXTRA_TIME"
      ) {
        text = "سيتم تمديد الباقة " + amount + " أسابيع";
      }
   
      if (amount !== "" && amount !== undefined) {
        codeDescription = (
          <div class="form-card-content">
            <div class="card-discount checkout-message">{text}</div>
          </div>
        );
      }
    } else {
      codeDescription = "";
    }
    //// end promo code

    const { loading } = this.props;

    let price, monthlyPriceinDollar, yearlyPriceinDollar;
    if (payingData.paymentType === "Monthly") {
      
      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.monthDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.monthDiscountPriceConverted
                  .currency
              }
            </span>
          </span>
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    } else if (payingData.paymentType === "Yearly") {
      if (
        payingData.paymentCurrencyPackage.yearDiscountPriceConverted !==
          undefined &&
        payingData.paymentCurrencyPackage.yearDiscountPriceConverted !== null
      ) {
        
      }
      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.yearDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.yearDiscountPriceConverted
                  .currency
              }
            </span>
          </span>{" "}
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    } else if (payingData.paymentType === "Weekly") {
      if (
        payingData.paymentCurrencyPackage.weekDiscountPriceConverted !==
          undefined &&
        payingData.paymentCurrencyPackage.weekDiscountPriceConverted !== null
      ) {
        
      }
      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.weekDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.weekDiscountPriceConverted
                  .currency
              }
            </span>
          </span>{" "}
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    }

    if (this.state.redirectionBack === true) {
      //Back to checkout Page
      return <Redirect to={PORTAL_UPGRADE_SUBSCRIPTION}> </Redirect>;
    }

    // if saved code and not viewed
    // show Modal
    if (
      successSavedCodeChecked === true &&
      savedcode !== undefined &&
      savedcode !== "" &&
      this.state.checkSaved === false
    ) {
      this.setState({ openPopup: true, checkSaved: true });
    }

    if (savedcodeDeletedSuccessfully === true) {
      this.setState({ openPopup: false });
      this.props.emptyPromoCode();
      if (
        this.state.codeNameFromRedux !== undefined &&
        this.state.codeNameFromRedux !== null &&
        this.state.codeNameFromRedux !== ""
      ) {
        this.props.getPromoCodeItemByName(this.state.codeNameFromRedux);
      }
    }

    if (
      errorDeletingCode !== undefined &&
      errorDeletingCode !== "" &&
      errorDeletingCode !== false
    ) {
      this.setState({ errorDeletingMsg: errorDeletingCode });
      this.props.emptyPromoCode();
    }

    return (
      <div>
        {loading === true && idSuccess !== true ? <Loader/> : 
        <div>
          <div className="container">
              <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
                <div className="d-flex align-items-center">
                  <Link className="navbar-brand d-flex align-items-center">
                    <img src={Mada} alt="" />
                  </Link>
                  <div className="complete-process d-none d-sm-block">
                    اكمال عملية الدفع
                  </div>
                </div>
                <Link className="btn back-btn" onClick={() => {
                  // this.backButtonHnadler();
                  this.forceUpdate();
                }} type="button" to="/">
                  العوده
                </Link>
              </div>
            </div>

            <div className="container checkout-card-container">

              {codeDescription ? codeDescription : price}

            </div>
            <div >
              {/* { this.renderPaymentform()} */}
              <form action= {PORTAL_PAYMENT_RESULT + `?name=${JeelMADA}`} class="paymentWidgets" data-brands="MADA"></form>

            </div>
        </div>}
      </div>
    );
  }
}

MadaPayment.propTypes = {
  addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
  emptyMobileUserProfile: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  payingData: state.mobilePaymentPackage.payingData,
  redirectionPage: state.redirectionPage.item,
  token: state.user.token,

  userPaymentProfile: state.userPaymentProfile.item,
  userloading: state.userPaymentProfile.loading,
  usererror: state.userPaymentProfile.getting_user_payment_data_error,
  usersucess: state.userPaymentProfile.getting_user_payment_data_sucessfully,

  token: state.user.token,

  promocode: state.promoCodes.item,
  promocoderror: state.promoCodes.error_loading_code,
  promocodesuccess: state.promoCodes.get_code_sucessfully,
  promocodeload: state.promoCodes.loading,
  isPromoCodeWork: state.promoCodes.promoCodeWork,

  cancelSubscribtionSuccess:
    state.subscriptionProfile.user_payment_cancel_subscription_sucessfully,

  codeName: state.promoCodes.code_name,

  currencyRate: state.currencyRate.item,
  currencyRateSuccess: state.currencyRate.getting_rate_successfully,
  currencyRateError: state.currencyRate.error_getting_rate,

  savedcodeDeletedSuccessfully: state.promoCodes.code_deleted_successfully,
  errorDeletingCode: state.promoCodes.error_deleting_code,

  successSavedCodeChecked: state.promoCodes.get_saved_code_sucessfully,
  loadingSavedCodeChecked: state.promoCodes.loading_saved,
  errorSavedCodeChecked: state.promoCodes.error_loading_saved_code,
  savedcode: state.promoCodes.saved,
  currentUserPaymentMethod: state.paymentData.paymentMethod,
  checkoutId:  state.hyperPay.id,
  loading: state.hyperPay.loading,
  idSuccess:  state.hyperPay.idSuccess,


});

export default 
  withFirebase(
    connect(mapStateToProps, {
      addSeriesIDtoRedirectionPage,
      emptyMobileUserProfile,
      emptyMobileUserProfileWithItem,
      getUserPaymentInfo,
      emptyPromoCode,
      getPromoCodeItemByName,
      clearPaymentData,
      checkIfSavedPromoCode,
      getSavedPromoCode,
      addSuccessMessage,
      emptyCodeFromRedux,
      getCurrencyRate,
      emptyCurrencyRate,
      deleteSavedPromoCode,
    })(MadaPayment)
  );
