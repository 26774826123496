import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withFirebase } from '../../config';
/*Redux*/
import { connect } from 'react-redux';
import { getAllDragAndDrops, deleteDragAndDrop } from '../../Actions/GameActions';
import { addDragDropParentIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*Headers*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import '../../css/AppCss/gamePreview.css'

import dragAndDropIcon from '../../images/Drag-Shape.png';
import '../../css/AppCss/gamePreview.css';

/* Routes */
import {
    SIGN_IN, ADMIN_LAND_PAGE, ALL_GAMES, DRAG_DROP_TO_EDIT,
    DRAG_DROP_DETAIL, ADD_DRAG_DROP, EDIT_DRAG_DROP
} from '../../routes';
const INITIAL_STATE = {
    game_Image: ""
}

class dragDropDetails extends Component {


    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        this.state = {
            gamePuzzle: null
        }

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            this.props.addDragDropParentIDtoRedirectionPage(DRAG_DROP_DETAIL, 0);

        }

    }

    componentDidMount() {
        this.getDragAndDropData();
    }

    getDragAndDropData() {
        getAllDragAndDrops()
            .then(res => {
                console.log(res.data);
                this.setState({ gamePuzzle: res.data });
            })
            .catch(err => {
                alert(err.response.data.message);
            });
    }

    handleDeleteImage(e, id) {
        deleteDragAndDrop(id)
            .then(res => {
                alert("Drag & Drop Item Deleted Successfully");
                this.getDragAndDropData();
            })
            .catch(err => {
                alert(err.response.data.message);
            });
    }


    render() {

        return (
            <div className="container">

                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />

                                            <div className="col-12 left-main-body" id="left-main-body">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link>
                                                        <span> {'>'} </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link to={ALL_GAMES}>Get All Games</Link>
                                                        <span> {'>'} </span>
                                                    </span>
                                                    <span>Drag & Drop Details</span>
                                                </div>

                                                <Link to={ADD_DRAG_DROP}>
                                                    <button type="submit" className="SongsButtons">
                                                        + Add New Item
                                                    </button>
                                                </Link>

                                            </div>


                                            <div className="container">

                                                <div className="card">
                                                    <div className="row" >
                                                        <div className="col-12">
                                                            <div className="row">
                                                                <div className="col ">
                                                                    <img className="rounded"
                                                                        src={dragAndDropIcon}
                                                                        alt=""
                                                                        height="65"
                                                                        width="65" />
                                                                </div>
                                                                <div className="col-10">

                                                                    <div>
                                                                        <span className="card-title"
                                                                            color="gray">
                                                                            Drag & Drop
                                                                        </span>
                                                                    </div>
                                                                    <span className="font-italic small-font">
                                                                        <hr />
                                                                        <div className="row">
                                                                            {this.state.gamePuzzle !== null
                                                                                && this.state.gamePuzzle.map((game, index) => {
                                                                                    return (
                                                                                        <div className="col-4 gamePreview dragDrop">
                                                                                            <span
                                                                                                onClick={e => this.handleDeleteImage(e, game.dragDropId)}>
                                                                                                <FontAwesomeIcon icon="times"
                                                                                                    color="#dc6d6d" className="true" />
                                                                                            </span>

                                                                                            <Link to={DRAG_DROP_TO_EDIT + "/" + game.dragDropId}>
                                                                                                <span>
                                                                                                    <FontAwesomeIcon icon="edit" color="#888" className="true" />
                                                                                                </span>
                                                                                            </Link>

                                                                                            <img className="image_Preview"
                                                                                                alt={game.url}
                                                                                                src={game.url} />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            {this.state.gamePuzzle !== null
                                                                                && this.state.gamePuzzle.length < 1
                                                                                && <div className="col-12">No games</div>}
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.item
});
export default withFirebase(
    connect(
        mapStateToProps,
        {
            addDragDropParentIDtoRedirectionPage
        }
    )
        (dragDropDetails));
