import { LOAD_BANNERS, FETCH_BANNERS, ERROR_LOADING_BANNERS, PUBLISH_BANNER, ERROR_ADDING_BANNER, EMPTY_BANNER, SETTING_BANNER_STATUS, ERROR_SETTING_BANNER_STATUS, DELETE_BANNER, ERROR_DELETING_BANNER, UPDATE_BANNER, ERROR_UPDATING_BANNER, GET_BANNER_DATA, ERROR_LOADING_BANNER, LOAD_BANNER, EMPTY_BANNER_ITEMS} from "./Types";
import axios, { post, put  } from 'axios'

export const getAllBanners = () => dispatch => {
    dispatch({
        type: LOAD_BANNERS,
        payload: 'Loading'
    })

    axios.get('/Banner/getAllBannersList')
        .then((res) => {
            dispatch({
                type: FETCH_BANNERS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_BANNERS,
                payload: Error.message
            })
        })
}

export const saveBanner = (title, titleInEnglish,description, image, link, category) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Banner/addBanner"
            const formData = new FormData();
            formData.append('title', title)
            formData.append('title_in_english', titleInEnglish)
            formData.append('description', description)
            formData.append('image', image)
            formData.append('link', link)
            formData.append('category', category)
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: PUBLISH_BANNER,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_BANNER,
                    payload: Error.message
                })

            })
        })
    }

}


export const emptyBanner = () => dispatch => {
    dispatch({
        type: EMPTY_BANNER,
        payload: ''
    })
}

export const emptyBannerItems = () => dispatch => {
    dispatch({
        type: EMPTY_BANNER_ITEMS,
        payload: ''
    })
}

export const setBannerStatus = (id, status) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Banner/setBannerStatus"
            const formData = new FormData();
            formData.append("id", id);
            formData.append("Status", status);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SETTING_BANNER_STATUS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_BANNER_STATUS,
                    payload: Error.message
                })
            })

        })

    }

    
}

export const deleteBanner = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Banner/deleteBanner"
            const formData = new FormData();
            formData.append("banner_id", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }

            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_BANNER,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_BANNER,
                    payload: Error.message
                })
            })
        })
    }
}

export const updateBanner = (id, title, titleInEnglish,description, image, link,category) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BANNER,
                payload: 'loading'
            })
            const url = '/Banner/updateBanner'
            const formData = new FormData();
            formData.append('id', id)
            formData.append('title', title)
            formData.append('title_in_english', titleInEnglish)
            formData.append('description', description)
            formData.append('image', image)
            formData.append('link', link)
            formData.append('category', category)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            put(url, formData, config).then((res) => {
                dispatch({
                    type: UPDATE_BANNER,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATING_BANNER,
                    payload: Error.message
                })
            })

        })
    }

}

export const getBannerData = (id) => {
    return (dispatch, getState, { getFirebase }) => {


        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BANNER,
                payload: 'loading'
            })

            const url = "/Banner/getBannerDetails"
            const formData = new FormData();
            formData.append('banner_id', id)


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: GET_BANNER_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_BANNER,
                    payload: Error.message
                })

            })
        })
    }

}
