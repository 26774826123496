import {
    FETCH_SONGS, ADD_SONG, GET_SONG_DATA,
    LOAD_SONGS, ERROR_LOADING_SONGS,
    ERROR_ADDING_SONG, PUBLISH_SONG,
    LOAD_SONG, ERROR_LOADING_SONG, EDIT_SONG_DATA,
    ERROR_EDITING_SONG, EMPTY_SONG,
    DELETE_SONG, ERROR_DELETING_SONG,
    SETTING_SONG_STATUS, ERROR_SETTING_SONG_STATUS,
    EMPTY_ALL_SONG
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},

    loading: '',

    error_adding_song: '',
    song_added_successfully: '',
    error_loading_songs: '',
    error_loading_song: '',
    song_updated_successfully: '',
    error_editing_song: '',
    song_deleted_successfully: '',
    error_deleteing_song: '',
    song_status_updated_successfully: '',
    error_setting_status: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_SONGS:
            return {
                ...state,
                items: action.payload,
                error_loading_songs: '',
                loading: false,
                item: {}
            }

        case ADD_SONG:
            return {
                ...state,
                item: action.payload
            }

        case PUBLISH_SONG:
            return {
                ...state,
                item: action.payload,
                song_added_successfully: true
            }
        case GET_SONG_DATA:
            return {
                ...state,
                item: action.payload,
                error_loading_song: '',
                loading: false
            }


        case ERROR_LOADING_SONGS:
            return {
                ...state,
                error_loading_songs: action.payload,
                loading: false
            }

        case ERROR_LOADING_SONG:
            return {
                ...state,
                error_loading_song: action.payload,
                loading: false
            }

        case EMPTY_SONG:
            return {
                ...state,
                error_adding_song: '',
                song_added_successfully: '',
                error_editing_song: '',
                song_updated_successfully: '',
                error_deleteing_song: '',
                song_deleted_successfully: '',
                song_status_updated_successfully: '',
                error_loading_song: '',
                error_setting_status: '',
                loading: false

            }

        case EMPTY_ALL_SONG:
            return {
                ...state,
                items: [],
                item: {},
            }

        case LOAD_SONG:
            return {
                ...state,
                loading: true
            }


        case LOAD_SONGS:
            return {
                ...state,
                loading: true
            }


        case ERROR_ADDING_SONG:
            return {
                ...state,
                error_adding_song: action.payload,
                song_added_successfully: false

            }

        case EDIT_SONG_DATA:
            return {
                ...state,
                item: action.payload,
                song_updated_successfully: true
            }

        case ERROR_EDITING_SONG:
            return {
                ...state,
                error_editing_song: action.payload,
                song_updated_successfully: false,
                loading: false
            }

        case DELETE_SONG:
            return {
                ...state,
                item: action.payload,
                song_deleted_successfully: true
            }
        case ERROR_DELETING_SONG:
            return {
                ...state,
                error_deleteing_song: action.payload,
                song_deleted_successfully: false
            }

        case SETTING_SONG_STATUS:
            return {
                ...state,
                song_status_updated_successfully: true,
                item: action.payload,
                loading: false
            }
        case ERROR_SETTING_SONG_STATUS:
            return {
                ...state,
                song_status_updated_successfully: false,
                error_setting_status: action.payload,
                loading: false
            }
        default:
            return state

    }

}
