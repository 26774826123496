import {FETCH_QUESTIONS_CATEGORIES, QUESTION_CATEGORY_DELETED, ERROR_DELETING_QUESTION_CATEGORY, UPDATE_QUESTION_CATEGORY, ERROR_UPDATE_QUESTION_CATEGORY, SAVE_Question_CATEGORY, ERROR_ADDING_Question_CATEGORY, EMPTY_QUESTION_CATEGORY} from './Types'
import axios,{post,put} from 'axios';

export const fetchQuestionsCategories = () => dispatch => {

    axios.get('/QuestionCategory/getAllQuestionsCategory')
        .then((res) => {
            dispatch({
                type: FETCH_QUESTIONS_CATEGORIES,
                payload: res.data
            })
        }
        )

}
export const deleteQuestionCategory = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = '/QuestionCategory/deleteQuestionCategory';
            const formData = new FormData();
            formData.append('Question_Category_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: QUESTION_CATEGORY_DELETED,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_QUESTION_CATEGORY,
                    payload: Error.response.data.message
                })
            })
        })
    }
}

export const updateQuestionCategory = (id, categoryText) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/QuestionCategory/updateQuestionCategory"
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Category_Text', categoryText);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            put(url, formData, config).then((res) => {
                dispatch({
                    type: UPDATE_QUESTION_CATEGORY,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_QUESTION_CATEGORY,
                    payload: Error.message
                })
            })
        })
    }
}

export const addQuestionCategory = (categoryText) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            const url = "/QuestionCategory/addQuestionCategory"
            const formData = new FormData();
            formData.append('QuestionCategory', categoryText);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SAVE_Question_CATEGORY,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_Question_CATEGORY,
                    payload: Error.message
                })
            })
        })
    }
}
export const emptyQuestionCategory=()=>dispatch=>
{
    dispatch({
        type:EMPTY_QUESTION_CATEGORY
    })
}