import { LOAD_CUSTOME_COUNTRY, GET_CUSTOME_COUNTRY, ERROR_LOADING_CUSTOME_COUNTRY,
     EMPTY_CUSTOME_REPORT, LOAD_CUSTOME_GENDER, GET_CUSTOME_GENDER, 
     ERROR_LOADING_CUSTOME_GENDER, 
     LOAD_CUSTOME_AGE_RANGE,
     GET_CUSTOME_AGE_RANGE,
     ERROR_LOADING_CUSTOME_AGE_RANGE} from '../Actions/Types'


const initial_state = 
{
    loading_custome_country:'',
    error_loading_custome_country:'',
    custome_country:[],
    loading_custome_gender:'',
    error_loading_custome_gender:'',
    custome_gender:[],
    loading_custome_age_range:'',
    error_loading_custome_age_range:'',
    custome_age_range:[]
}

export default function (state = initial_state,action)
{
    switch(action.type)
    {
        case LOAD_CUSTOME_AGE_RANGE:
            return {
                ...state,
                loading_custome_age_range:true
            }
        case GET_CUSTOME_AGE_RANGE:
            return {
                ...state,
                loading_custome_age_range:false,
                custome_age_range:action.payload
            }
        case ERROR_LOADING_CUSTOME_AGE_RANGE:
            return {
                ...state,
                loading_custome_age_range:false,
                error_loading_custome_age_range:action.payload
            }


        case LOAD_CUSTOME_GENDER:
            return{
                ...state,
                loading_custome_gender:true
            }

        case GET_CUSTOME_GENDER:
            return{
                ...state,
                loading_custome_gender:false,
                custome_gender:action.payload
            }

        case ERROR_LOADING_CUSTOME_GENDER:
            return{
                ...state,
                loading_custome_gender:false,
                error_loading_custome_gender:action.payload
            }

        case LOAD_CUSTOME_COUNTRY:
            return {
                ...state,
                loading_custome_country:true
            }

            case GET_CUSTOME_COUNTRY:
               return {
                   ...state,
                   loading_custome_country:false,
                   custome_country:action.payload
               }

            case ERROR_LOADING_CUSTOME_COUNTRY:
               return {
                        ...state,
                        loading_custome_country:false,
                        error_loading_custome_country:action.payload
                    }

            case EMPTY_CUSTOME_REPORT:
                return {
                    ...state,
                    custome_country:[],
                    loading_custome_country:'',
                    error_loading_custome_country:'',
                    custome_gender:[],
                    loading_custome_gender:'',
                    error_loading_custome_gender:'',
                    loading_custome_age_range:'',
                     error_loading_custome_age_range:'',
                     custome_age_range:[]

                }
        default:
            return state;
    }
}