import React, { Component } from 'react'
/*Redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import propTypes from 'prop-types';
/*Component */
import UploadFile from '../S3Uploading/UploadFile';
import DeleteDurationOfUse from './DeleteDurationOfUse'
/*Actions */
import {
    emptyDurationResponse,
    addDurationOfUse,
    fetchAllDuration
} from '../../Actions/DurationOfUseActions';


const INITIAL_STATE = {

    id: 0,
    text: '',
    articleUrl: '',
    programUrl: '',
    duration: 0

};

class DurationOfUse extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

        this.props.fetchAllDuration();

        if (this.props.durationOfUse) {
            let durationOfUse = this.props.durationOfUse[0];
            if (durationOfUse !== null && durationOfUse !== undefined) {
                this.setState({
                    id: durationOfUse.id,
                    text: durationOfUse.text,
                    articleUrl: durationOfUse.articleUrl,
                    programUrl: durationOfUse.programUrl,
                    duration: durationOfUse.duration,
                })
            }
        }
    }

    componentDidMount() {
        this.props.fetchAllDuration();

        if (this.props.durationOfUse) {
            let durationOfUse = this.props.durationOfUse[0];
            if (durationOfUse !== null && durationOfUse !== undefined) {
                this.setState({
                    id: durationOfUse.id,
                    text: durationOfUse.text,
                    articleUrl: durationOfUse.articleUrl,
                    programUrl: durationOfUse.programUrl,
                    duration: durationOfUse.duration,
                })
            }
        }
    }

    getFileUrl(fileURL) {
        if (fileURL) {
            this.setState({ programUrl: fileURL });
            console.log("Duration programUrl")
            console.log(fileURL);
        }

    }

    getArticleUrl(articleUrl) {
        if (articleUrl) {
            this.setState({ articleUrl: articleUrl });
            console.log("Duration articleUrl")
            console.log(articleUrl);
        }
    }

    deleteDuration(deleted) {
        if (deleted) {
            this.setState({
                id: 0,
                text: '',
                articleUrl: '',
                programUrl: '',
                duration: 0
            });
            console.log("Duration deleted")
        }
    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleSubmit = (e) => {

        const { text, articleUrl, programUrl, duration } = this.state;

        console.log(text + " " + articleUrl
            + " " + programUrl + " " + duration)
        this.props.addDurationOfUse(text, articleUrl,
            programUrl, duration);

        e.preventDefault();
    }

    render() {

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert(error)
            this.props.emptyDurationResponse();
        }
        else if (success === true) {
            alert("Duration saved Successfully")
            this.props.emptyDurationResponse();
            this.props.fetchAllDuration();

        }

        const { text, programUrl, articleUrl, duration } = this.state;

        const isInvalidDuration =
            text === '' ||
            programUrl === '' ||
            articleUrl === '' ||
            duration === 0;

        let fileLink;
        let articleLink;

        if (programUrl !== undefined &&
            programUrl !== null &&
            programUrl !== '') {
            fileLink = <span > Program Link : {programUrl}</span>;
        }


        if (articleUrl !== undefined &&
            articleUrl !== null &&
            articleUrl !== '') {
            articleLink = <span >Article Link : {articleUrl}</span>;
        }


        let durationOfUse = this.props.durationOfUse[0];
        let button;
        if (durationOfUse) {

            button =
                <button
                    className="btn btn-lg btn-success btn-suc btn-block-sm"
                    onClick={this.handleSubmit.bind(this)}
                    disabled={isInvalidDuration}>
                    update
                </button>
        }
        else {

            button = <button
                className="btn btn-lg btn-success btn-suc btn-block-sm"
                onClick={this.handleSubmit.bind(this)}
                disabled={isInvalidDuration}>

                Save
            </button>
        }


        return (
            <div className="card-body">

                <div className="row">
                    <div className="form-group col-md-12">
                        <label className="small-label"
                            for="exampleFormControlTextarea1">
                            <span className="required_input">*</span>
                            Recommendation:
                            </label>
                        <textarea dir="auto" className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            name="text" id="text"
                            onChange={this.handleChange}
                            value={this.state.text} >
                        </textarea>
                        <br />

                        <label className="small-label">
                            <span className="required_input">*</span>
                            Duration in hours
                            </label>

                        <input className="form-control" type="number"
                            name="duration" id="duration"
                            onChange={this.handleChange}
                            value={this.state.duration} />

                        <br />


                        <label className="small-label">
                            <span className="required_input">*</span>
                            Aticle File </label>
                        <br />
                        {articleLink}
                        <UploadFile
                            getFileURLCallBack={this.getArticleUrl.bind(this)}
                            fileURL={this.state.articleUrl}
                            s3DirectryrName='durationofuse' />
                        <br />


                        <label className="small-label">
                            <span className="required_input">*</span>
                            Upload File </label>
                        <br />
                        {fileLink}
                        <UploadFile
                            getFileURLCallBack={this.getFileUrl.bind(this)}
                            fileURL={this.state.programUrl}
                            s3DirectryrName='durationofuse' />

                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="float-right">

                            {button}
                        </div>

                        <div className="float-left">

                            <DeleteDurationOfUse id={this.state.id}
                                deleteDurationOfUse={this.deleteDuration.bind(this)} />

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

DurationOfUse.propTypes = {
    fetchAllDuration: propTypes.func.isRequired,
    addDurationOfUse: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    durationOfUse: state.durationOfUse.items,
    loading: state.durationOfUse.loading,
    error: state.durationOfUse.adding_Duration_error,
    success: state.durationOfUse.adding_Duration_sucessfully
});

export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyDurationResponse,
                addDurationOfUse,
                fetchAllDuration
            })
        (DurationOfUse)
    );
