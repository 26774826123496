import React, { Component } from "react";
import { connect } from "react-redux";
import OuterHeader from "../Layout/OuterHeader";
import InnerHeader from "../Layout/InnerHeader";
import Dashboard from "../Dashboard";
import Footer from "../Layout/Footer";
import Loader from '../Layout/Loader';
import { Redirect } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { updateSetting, getSettingData,emptySetting } from "../../Actions/SettingAction";
import { SETTINGS } from "../../routes";

class EditSetting extends Component {
  state = {
    name: "",
    value: "",
    error: true,
    errorMessage: "",
    setting_id: 0,
    redirectToSettings: false,
  };


  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getSettingData(id);
    this.setState({ setting_id: id });
}

  componentDidUpdate(prevProps) {
    if (prevProps.setting !== this.props.setting) {
      const { name, value } = this.props.setting;
      this.setState({ name, value });
    }
  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces }, () => {
      if (
        this.state.name === '' || this.state.name === null || this.state.name === undefined || this.state.name.trim() === '' || 
        this.state.value === '' || this.state.value === null || this.state.value === undefined || this.state.value.trim() === ''
      ) {
        this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
      } else {
        this.setState({ error: false, errorMessage: "" });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { setting_id, name, value } = this.state; 
    this.props.updateSetting(setting_id, name, value); 
    this.setState({ redirectToSettings: true }); // Set redirectToSettings to true
  };
  

  
  render() {

    const { error, errorMessage } = this.state;
    const { settingError, settingSuccess } = this.props;
    if (settingSuccess === false) {
      alert("error messing data or network " + settingError);
      this.props.emptySetting();
    } else if (settingSuccess === true) {
      alert("setting updated Successfully");
      this.props.emptySetting();
      return <Redirect to={SETTINGS} />;
    }
  
  
    // Check if there was an error while fetching the data
    if (settingError) {
      return <div>Error: {settingError}</div>;
    }
  
    // Once the data is available, render the component
    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col-9">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <h5 className="main-title mt-2 font-weight-bold">
                        <span>Edit Setting</span>
                      </h5>
                      <br />
                      <div className="container">
                        <form onSubmit={this.handleSubmit}>
                          <label className="small-label">
                            <span className="required_input">*</span>Name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                          />
                          <br />
                          <label className="small-label">
                            <span className="required_input">*</span>Value
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="value"
                            value={this.state.value}
                            onChange={this.handleChange}
                          />
                          <br />
                          <p style={{ color: "red", fontSize: "12px", marginBottom: "0.5em" }}>
                            {errorMessage}
                          </p>
                          <Button
                            icon
                            labelPosition="right"
                            id="nextButton"
                            type="submit"
                        
                            disabled={error}
                            
                          >
                            Update
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
  
}

const mapStateToProps = (state) => ({
  setting: state.settings.item,
  settingSuccess: state.settings.setting_updated_successfully,
  settingError: state.settings.error_updating_setting,
  auth: state.firebase.auth
});

export default connect(mapStateToProps, { updateSetting, getSettingData,emptySetting })(EditSetting);
