import { LOAD_EDUCATIONAL_VIDEOS, FETCH_EDUCATIONAL_VIDEOS, ERROR_LOADING_EDUCATIONAL_VIDEOS, PUBLISH_EDUCATIONAL_VIDEO, ERROR_ADDING_EDUCATIONAL_VIDEO, EMPTY_EDUCATIONAL_VIDEO, SETTING_EDUCATIONAL_VIDEO_STATUS, ERROR_SETTING_EDUCATIONAL_VIDEO_STATUS, DELETE_EDUCATIONAL_VIDEO, ERROR_DELETING_EDUCATIONAL_VIDEO, UPDATE_EDUCATIONAL_VIDEO, ERROR_UPDATING_EDUCATIONAL_VIDEO, GET_EDUCATIONAL_VIDEO_DATA, ERROR_LOADING_EDUCATIONAL_VIDEO, LOAD_EDUCATIONAL_VIDEO, EMPTY_EDUCATIONAL_VIDEO_ITEMS} from "./Types";
import axios, { post, put  } from 'axios'

export const getAllEducationalVideos = () => dispatch => {
    dispatch({
        type: LOAD_EDUCATIONAL_VIDEOS,
        payload: 'Loading'
    })

    axios.get('/parentLibrary/getAllParentLibrary')
        .then((res) => {
            dispatch({
                type: FETCH_EDUCATIONAL_VIDEOS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_EDUCATIONAL_VIDEOS,
                payload: Error.message
            })
        })
}

export const saveEducationalVideo = (objectData) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/parentLibrary/addParentLibrary"


            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                  }
            }
            post(url, objectData, config).then((res) => {
                dispatch({
                    type: PUBLISH_EDUCATIONAL_VIDEO,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_EDUCATIONAL_VIDEO,
                    payload: Error.message
                })

            })
        })
    }

}


export const emptyEducationalVideo = () => dispatch => {
    dispatch({
        type: EMPTY_EDUCATIONAL_VIDEO,
        payload: ''
    })
}

export const emptyEducationalVideoItems = () => dispatch => {
    dispatch({
        type: EMPTY_EDUCATIONAL_VIDEO_ITEMS,
        payload: ''
    })
}

export const setEducationalVideoStatus = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/parentLibrary/setParentLibraryStatus"
            const formData = new FormData();
            formData.append("id", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SETTING_EDUCATIONAL_VIDEO_STATUS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_EDUCATIONAL_VIDEO_STATUS,
                    payload: Error.message
                })
            })

        })

    }

    
}

export const deleteEducationalVideo = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/parentLibrary/deleteParentLibrary"
            const formData = new FormData();
            formData.append("id", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }

            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_EDUCATIONAL_VIDEO,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_EDUCATIONAL_VIDEO,
                    payload: Error.message
                })
            })
        })
    }
}

export const updateEducationalVideo = (objectData) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_EDUCATIONAL_VIDEO,
                payload: 'loading'
            })
            const url = '/parentLibrary/updateParentLibrary'

            const config = {
                headers: {
                    'content-type': 'application/json',
                    'X-Authorization-Firebase': token
                  }
            }

            put(url, objectData, config).then((res) => {
                dispatch({
                    type: UPDATE_EDUCATIONAL_VIDEO,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATING_EDUCATIONAL_VIDEO,
                    payload: Error.message
                })
            })

        })
    }

}

export const getEducationalVideoData = (id) => {
    return (dispatch, getState, { getFirebase }) => {


        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_EDUCATIONAL_VIDEO,
                payload: 'loading'
            })

            const url = "/parentLibrary/getParentLibrary"
            const formData = new FormData();
            formData.append('id', id)


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: GET_EDUCATIONAL_VIDEO_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_EDUCATIONAL_VIDEO,
                    payload: Error.message
                })

            })
        })
    }

}
