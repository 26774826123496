import React, { Component } from "react";
import { getEducationalVideoData,updateEducationalVideo,emptyEducationalVideo ,emptyEducationalVideoItems } from "../../Actions/ParentLibraryAction";
import { connect } from "react-redux";
import OuterHeader from "../Layout/OuterHeader";
import InnerHeader from "../Layout/InnerHeader";
import Dashboard from "../Dashboard";
import Footer from "../Layout/Footer";
import { Redirect } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import UploadImageWithoutDropZone from "../S3Uploading/UploadImageWithoutDropZone";


/* Routes */
import { SIGN_IN, ALL_EDUCATIONAL_VIDEOS } from '../../routes';
import UploadImage from "../S3Uploading/UploadImage";

class EditParentLibrary extends Component {
  state = {
    image: "",
    title: "",
    titleInEnglish :"",
    description: "",
    descriptionInEnglish:"",
    link: "",
    cancel: false,
    parentLibrary_id: 0,
    error: true,
    errorMessage: "",
  };


  componentDidMount() {
    this.props.emptyEducationalVideoItems();
    if (!this.props.auth.uid) {
      return <Redirect to={SIGN_IN} />;
    } else {
      let id = this.props.match.params.id;
      this.props.getEducationalVideoData(id);
      this.setState({ parentLibrary_id: id });
    }
  }

  componentWillReceiveProps(nextprop) {
    let parentLibrary = nextprop.parentLibrary;
    this.setState({
      title: parentLibrary.itemName,
      titleInEnglish : parentLibrary.itemNameInEnglish,
      image: parentLibrary.itemImage,
      description: parentLibrary.itemDescription,
      descriptionInEnglish: parentLibrary.itemDescriptionInEnglish,
      link: parentLibrary.itemUrl,
    });
  }


  getImageUrl(image) {
    if (image) {
      this.setState({ image: image });
      if (
        this.state.title === '' || this.state.title === null || this.state.title === undefined || this.state.title.trim() === '' ||
        this.state.titleInEnglish === '' || this.state.titleInEnglish === null || this.state.titleInEnglish === undefined || this.state.titleInEnglish.trim() === '' ||
        this.state.image === '' || this.state.image === null || this.state.image === undefined || this.state.image === 'null'||
        this.state.link === '' || this.state.link === null || this.state.link === undefined || this.state.link === 'null'
      ) {
        this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
      } else {
        this.setState({ error: false, errorMessage: "" });
      }
    }
  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces });

        if ((this.state.title === '' || this.state.title === null || this.state.title === undefined || this.state.title.trim() === ''
        || this.state.titleInEnglish === '' || this.state.titleInEnglish === null || this.state.titleInEnglish === undefined || this.state.titleInEnglish.trim() === '' ||
        this.state.link === '' || this.state.link === null || this.state.link === undefined || this.state.link === 'null')) {
            this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
        }
        else {
            this.setState({ error: false, errorMessage: "" });
        }

}

handelCancel = e => {
    this.setState({ cancel: true });
  }

  handleSubmit = e => {

    const {
      parentLibrary_id,
      title,
      titleInEnglish,
      image,
      description,
      descriptionInEnglish,
      link
    } = this.state;



    if (image === '' || image === undefined || image === null || image === 'null') {
      alert("please insert image");
      this.setState({
        validateInput: false
      })
    }


    else if (title === '' || title === null || title === undefined || title.trim() === '') {
      alert("please insert title in arabic");
      this.setState({
        validateInput: false
      })
    }
    else if (titleInEnglish === '' || titleInEnglish === null || titleInEnglish === undefined || titleInEnglish.trim() === '') {
      alert("please insert title in english");
      this.setState({
        validateInput: false
      })
    }
    else if (link === '' || link === undefined || link === null || link.trim() === '') {
      alert("please insert video link");
      this.setState({
        validateInput: false
      })
    }
  
    else {

        const objectData = {
            id:parentLibrary_id,
            itemName:title,
            itemNameInEnglish: titleInEnglish,
            itemDescription: description !== "" ? description:null,
            itemDescriptionInEnglish: descriptionInEnglish !== "" ? descriptionInEnglish :null,
            itemImage: image,
            itemUrl: link,
        }

      this.props.updateEducationalVideo(
    objectData
      );
    }
    e.preventDefault();
  };

  render() {


    const
      config = {
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        enableDragAndDropFileToEditor: true,
        useImageResizer: false,
        direction: 'rtl',
      }



    const { parentLibraryerror, parentLibrarysucess } = this.props;
    if (parentLibrarysucess === false) {
      alert("error messing data or network " + parentLibraryerror);
      this.props.emptyEducationalVideo();
    } else if (parentLibrarysucess === true) {
      alert("Parent Educational Video updated Successfully");
      this.props.emptyEducationalVideo();
      return <Redirect to={ALL_EDUCATIONAL_VIDEOS} />;
    }
    if (this.state.cancel === true) {
        return (<Redirect to={ALL_EDUCATIONAL_VIDEOS}></Redirect>)
  
      }
    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col-9">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <h5 className="main-title mt-2 font-weight-bold">
                        <span> Edit Parent Educational Videos</span>
                      </h5>
                      <br />
                      <div className="container">
                        <form onSubmit={this.handleSubmit}>
                        <span className="required_input">*</span>
                          <label className="small-label">Image</label>
                          <UploadImageWithoutDropZone
                            getImageURLCallBack={this.getImageUrl.bind(this)}
                            imagepreview={this.state.image}
                            imageUrl={this.state.image}
                            s3DirectryrName='parentLibrary'
                          />
                          <br />
                          <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Title In Arabic</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title"
                            name="title"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.title}
                          />
                          <br />
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Title In English</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title_in_english"
                            name="titleInEnglish"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.titleInEnglish}
                          />
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              Description
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="description"
                              onChange={this.handleChange}
                              onKeyUp={this.handleChange}
                              value={this.state.description}
                            />
                          </div>
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              Description In English
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="descriptionInEnglish"
                              onChange={this.handleChange}
                              onKeyUp={this.handleChange}
                              value={this.state.descriptionInEnglish}
                            />
                          </div>
                          <br />

                         
                          <label className="small-label">
                          <span className="required_input">*</span>
                            Youtube Url</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="link"
                            name="link"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.link}
                          />
                          <br />

                          <Button
                            icon
                            labelPosition="right"
                            id="nextButton"
                            onClick={this.handleSubmit}
                            disabled={this.state.error}
                          >
                            Publish
                          </Button>
                          <Button id="episode_cancel"
                            onClick={this.handelCancel}>
                            Cancel
                          </Button>
                          <br />
                          <br /> <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  parentLibrary: state.parentLibrary.item,
  parentLibraryerror: state.parentLibrary.error_updating_educationalVideo,
  parentLibrarysucess: state.parentLibrary.educationalVideo_updated_successfully,
  auth: state.firebase.auth
});
export default connect(
  mapStateToProps,
  { getEducationalVideoData, updateEducationalVideo, emptyEducationalVideo,emptyEducationalVideoItems}
)(EditParentLibrary);
