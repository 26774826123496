import React, { Component } from "react";
import Player from "video-react/lib/components/Player";
import { Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
/*Component */
import QuestionPreview from "../Layout/QuestionPreview";
import AudioPlayer from "react-h5-audio-player";

/*Redux*/
import { connect, useSelector } from "react-redux";
import propTypes from "prop-types";
/*Actions */
import { emptyEvaluationAll } from "../../Actions/EvaluationActions";
import { clearQuestions } from "../../Actions/QuestionActions";
import { publishEpisode, emptyError } from "../../Actions/EpisodeActions";
import {
  publishQuestion,
  assignCorrrectAnswerToQuestion,
} from "../../Actions/QuestionActions";
import { addSeriesIDtoRedirectionPage } from "../../Actions/RedirectionPagesActions.js";

/*Headers*/
import OuterHeader from "../Layout/OuterHeader";
import Dashboard from "../Dashboard";
import InnerHeader from "../Layout/InnerHeader";
import NavBar from "../Layout/NavBar";
import Footer from "../Layout/Footer";
/* CSS */
import "../../css/AppCss/Episode/PublishEpisode.css";
import "../../css/AppCss/Episode/PublicViewPublishEpisode.css";
import "../../css/AppCss/Episode/EpisodeButtons.css";
import "../../../node_modules/video-react/dist/video-react.css";
import "bootstrap/dist/css/bootstrap.min.css";

/* Routes */
import { EPISODE_EVALUATION } from "../../routes";
import RenderVideoWithMusic from "../VideoPreview/RenderVideoWithMusic.js";
import RenderVideoWithoutMusic from "../VideoPreview/RenderVideoWithoutMusic.js";
import { EPISODE_FOLDER_NAME } from "../../portal/src/constants.js";
import PreviewVideoWithMusic from "../VideoPreview/PreviewVideoWithMusic.js";
import PreviewVideoWithoutMusic from "../VideoPreview/PreviewVideoWithoutMusic.js";

class PublishEpisode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoWithMusicReady: false,
      videoWithoutMusicReady: false,
      isButtonDisabled: false,
    };
  }

  publishEpisode = (e) => {
    const { episode } = this.props;
    const { redirectionPage } = this.props;
    console.log(redirectionPage.pageName);
    this.props.addSeriesIDtoRedirectionPage(
      redirectionPage.pageName,
      episode.selected_series_id
    );

    const { activity } = this.props;
    this.props.publishEpisode(activity.name);
  };
  handleChangeVideoWithMusic = (uploadInfo,videoKey) => {
    // console.log(episodes);
    // console.log("Received uploadInfo in parent:", uploadInfo);
    // if (
    //   uploadInfo &&
    //   uploadInfo !== undefined &&
    //   uploadInfo !== null &&
    //   Object.keys(uploadInfo).length > 0
    // ) {
      
    // } 
  };
  handleChangeVideoWithoutMusic = (uploadInfo,videoKey) => {
    // console.log(episodes);
    // console.log("Received uploadInfo in parent:", uploadInfo);
    // if (
    //   uploadInfo &&
    //   uploadInfo !== undefined &&
    //   uploadInfo !== null &&
    //   Object.keys(uploadInfo).length > 0
    // ) {
      
    // } 
  };

  isButtonDisabled = () => {
    const { episode } = this.props;
    const { videoWithMusicReady, videoWithoutMusicReady } = this.state;
    if (episode.videoKeyVideoWithMusic && episode.videoKeyVideoWithoutMusic){
      if (!videoWithMusicReady || !videoWithoutMusicReady){
        return true;
      }
    }

    else if (episode.videoKeyVideoWithMusic && !episode.videoKeyVideoWithoutMusic){
      if (!videoWithMusicReady){
        return true;
      }
    }

    else if (!episode.videoKeyVideoWithMusic && episode.videoKeyVideoWithoutMusic){
      if (!videoWithoutMusicReady){
        return true;
      }
    }

    else {
      return false;
    }
  };

  render() {
    const { episode } = this.props;
    const { questions } = this.props;

    const { activityerror } = this.props;
    const { activitysucess } = this.props;
    const { episodsucess } = this.props;
    const { episoderror } = this.props;
    const { loading } = this.props;

    let buttonName = episode.radiobutton;
    console.log(buttonName);

    if (episode.radiobutton === "now") {
      buttonName = "publish";
    } else if (episode.radiobutton === "schedule") {
      buttonName = "save";
    }

    if (activitysucess === false) {
      console.log("activitysucess === false");
      alert(activityerror);
      this.props.emptyError();
      this.props.clearQuestions();
    } else if (activitysucess === true) {
      console.log("activitysucess === true");

      if (episodsucess === false) {
        console.log("episodsucess === false");

        alert("error messing data or network " + episoderror);
        this.props.emptyError();
        this.props.clearQuestions();
        this.props.emptyEvaluationAll();
      } else if (episodsucess === true) {
        console.log("episodsucess === true");

        alert("Episode added Successfully");
        this.props.emptyError();
        this.props.clearQuestions();
        this.props.emptyEvaluationAll();

        const { redirectionPage } = this.props;
        var pageName = redirectionPage.pageName;
        return <Redirect to={pageName}></Redirect>;
      }
    }

    let $episode_Question = null;
    if (questions !== undefined) {
      $episode_Question = (
        <QuestionPreview questions={questions} state="publish" />
      );
    }

    let $episode_VideoPreviewWithMusic = null;

    if (episode.episode_videoUrlwithMusic) {
      $episode_VideoPreviewWithMusic = (
        <Player playsInline fluid src={episode.episode_videoUrlwithMusic} />
      );
    } else {
      $episode_VideoPreviewWithMusic = (
        <div className="previewText">No Video for Preview</div>
      );
    }

    let $episode_VideoPreviewWithoutMusic = null;
    if (episode.episode_videoUrlwithoutMusic) {
      $episode_VideoPreviewWithoutMusic = (
        <Player playsInline fluid src={episode.episode_videoUrlwithoutMusic} />
      );
    } else {
      $episode_VideoPreviewWithoutMusic = (
        <div className="previewText">No Video for Preview</div>
      );
    }

    let $imagePreview = null;
    if (episode.episode_imageUrl) {
      $imagePreview = (
        <img
          className="image_Preview"
          src={episode.episode_imageUrl}
          alt="Episode "
        />
      );
    } else {
      $imagePreview = <div className="image_Preview">No Image for Preview</div>;
    }

    // loading button
    let $buttonSubmitLoader = (
      <Button id="episode_publish" onClick={this.publishEpisode}
      disabled={this.isButtonDisabled()}
      >
        {" "}
        {buttonName}{" "}
      </Button>
    );
    if (loading === true) {
      $buttonSubmitLoader = (
        <button id="episode_publish" class="ui loading button">
          Loading
        </button>
      );
      console.log("loading");
    }

    let audio = "";
    if (episode.voice) {
      audio = (
        <AudioPlayer
          src={episode.voice}
          onPlay={(e) => console.log("onPlay")}
        />
      );
    } else {
      audio = (
        <td fluid className="episode_label">
          {" "}
          No Audio
        </td>
      );
    }

    const handleVideoWithMusicReady = () => {
      this.setState({ videoWithMusicReady: true });
    };
    const handleVideoWithoutMusicReady = () => {
      this.setState({ videoWithoutMusicReady: true });
    };

    

    return (
      <div>
        <div className="container">
          <OuterHeader />
          <div className="container">
            <div className="container-body">
              <div className="row">
                <Dashboard />
                <div className="col">
                  <InnerHeader />
                  <div className="main-content">
                    <div className="row">
                      <div
                        className="col-12 left-main-body"
                        id="left-main-body"
                      >
                        <NavBar />
                        <br />
                        <div className="main-progress-bar">
                          <ol className="progress-tracker">
                            <li className="step active s2-active">
                              <span className="step-name small-font">
                                ِAdd Episode
                              </span>
                            </li>
                            <li className="step active s2-active">
                              <span className="step-name small-font">
                                Add activity
                              </span>
                            </li>
                            <li className="step active s2-active">
                              <span className="step-name small-font">
                                Add Evaluation
                              </span>
                            </li>
                            <li className="step active">
                              <span className="step-name small-font">
                                Puplish Episode
                              </span>
                            </li>
                          </ol>
                          <br />
                        </div>

                        <div className="container">
                          {/* {episode.episode_videoUrlwithMusic && (
                            <div>
                              {" "}
                              <label className="label">Video with music</label>
                              <section className="video_section">
                                <div>{$episode_VideoPreviewWithMusic}</div>
                              </section>
                              <br />
                            </div>
                          )} */}


                          <div>
                              <label className="label">
                                Video with music
                              </label>
                              <section className="video_section">
                                  <PreviewVideoWithMusic
                                  videoKey={episode.videoKeyVideoWithMusic}
                                  id="VideoWithMusic" 
                                  onVideoReady={handleVideoWithMusicReady}>
                                  </PreviewVideoWithMusic>
                              </section>
                              <br />
                            </div>
                          <div>
                              <label className="label">
                                Video without music
                              </label>
                              <section className="video_section">
                                  <PreviewVideoWithoutMusic
                                  videoKey={episode.videoKeyVideoWithoutMusic}
                                  id="VideoWithoutMusic" 
                                  onVideoReady={handleVideoWithoutMusicReady}>
                                  </PreviewVideoWithoutMusic>
                              </section>

                              <br />
                            </div>
                          {/* {episode.videoKeyVideoWithMusic && (
                            <div>

                              <label className="label">Video with music</label>
                              <section className="video_section">
                                <RenderVideoWithMusic
                                  videoKey={episode.videoKeyVideoWithMusic}
                                  id="VideoWithMusic"
                                  foldername={ EPISODE_FOLDER_NAME}
                                  changedid={this.handleChangeVideoWithMusic}
                                  enableEdit={false}
                                  ></RenderVideoWithMusic>
                              </section>

                              <br />
                            </div>
                          )} */}
{/* 
                          {episode.episode_videoUrlwithoutMusic && (
                            <div>
                              <label className="label">
                                Video without music
                              </label>
                              <section className="video_section">
                                <div>{$episode_VideoPreviewWithoutMusic}</div>
                              </section>
                              <br />
                            </div>
                          )} */}

                       

{/* 
                          {episode.videoKeyVideoWithoutMusic && (
                            <div>
                              <label className="label">
                                Video without music
                              </label>
                              <section className="video_section">
                                <RenderVideoWithoutMusic
                                  videoKey={episode.videoKeyVideoWithoutMusic}
                                  id="VideoWithoutMusic"
                                  foldername={ EPISODE_FOLDER_NAME}
                                  changedid={this.handleChangeVideoWithoutMusic}
                                  enableEdit={false}
                                  ></RenderVideoWithoutMusic>
                              </section>

                              <br />
                            </div>
                          )} */}
                          <br />

                          <div className="row">
                            <div className="col-4">
                              <section>{$imagePreview}</section>
                            </div>

                            <div className="col">
                              <table>
                                <tr>
                                  <th className="episode_label">
                                    {" "}
                                    Episode Name{" "}
                                  </th>
                                  <td fluid className="episode_label">
                                    {" "}
                                    {episode.episode_name}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="episode_label">
                                    {" "}
                                    Episode Name In English{" "}
                                  </th>
                                  <td fluid className="episode_label">
                                    {" "}
                                    {episode.episode_name_in_english}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="episode_label">
                                    {" "}
                                    Episode Number{" "}
                                  </th>
                                  <td fluid className="episode_label">
                                    {" "}
                                    {episode.episode_number}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="episode_label">
                                    {" "}
                                    Selected Series{" "}
                                  </th>
                                  <td fluid className="episode_label">
                                    {" "}
                                    {episode.selected_series_name}{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <th className="episode_label"> Schedule </th>
                                  <td fluid className="episode_label">
                                    {" "}
                                    {episode.episode_publishDate}{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <th className="episode_label">
                                    {" "}
                                    payment availability{" "}
                                  </th>
                                  <td fluid className="episode_label">
                                    {" "}
                                    {episode.isFree}{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <th className="episode_label">
                                    {" "}
                                    Description{" "}
                                  </th>
                                  <td fluid className="episode_label">
                                    <p>{episode.episode_description}</p>
                                  </td>
                                </tr>

                                <tr>
                                  <th className="episode_label">
                                    {" "}
                                    Description In English{" "}
                                  </th>
                                  <td fluid className="episode_label">
                                    <p>
                                      {episode.episode_description_in_english}
                                    </p>
                                  </td>
                                </tr>

                                <tr>
                                  <th className="episode_label"> Audio </th>
                                  {audio}
                                </tr>
                              </table>
                            </div>
                          </div>

                          <div className="row" id="episode_Question_Row">
                            <label className="question_label">Question</label>
                            <hr id="questionHR" />
                            <br />
                            {$episode_Question}

                            <div className="buttons_container">
                              {/* <Button id="episode_cancel" >Cancel</Button> */}

                              {$buttonSubmitLoader}

                              <Link to={EPISODE_EVALUATION}>
                                <Button
                                  icon
                                  labelPosition="left"
                                  id="episode_backButton"
                                >
                                  back
                                  <Icon name="left arrow" />
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

PublishEpisode.propTypes = {
  publishEpisode: propTypes.func.isRequired,
  publishEpisodeActivity: propTypes.func.isRequired,
  publishQuestion: propTypes.func.isRequired,
  assignCorrrectAnswerToQuestion: propTypes.func.isRequired,
  addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  loading: state.episodes.loading,
  episode: state.episodes.item,
  activity: state.activities.item,
  questions: state.questions.items,
  question: state.questions.item,

  episoderror: state.episodes.error_adding_episode,
  episodsucess: state.episodes.episode_added_sucessfully,

  activityerror: state.activities.error_adding_activity,
  activitysucess: state.activities.activity_added_successfully,
  redirectionPage: state.redirectionPage.item,
});

export default connect(mapStateToProps, {
  publishEpisode,
  publishQuestion,
  assignCorrrectAnswerToQuestion,
  emptyError,
  clearQuestions,
  emptyEvaluationAll,
  addSeriesIDtoRedirectionPage,
})(PublishEpisode);
