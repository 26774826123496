import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react';
import { Snackbar } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import ReactPlayer from "react-player"
import branch from 'branch-sdk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';
import { countries } from '../../../components/ConstData/CountryListTranslated';
import Testimonials from '../Layout/Testimonials';

/* Assets */
import mainLogoM from '../../../images/portal/logo-big.png';
import reportIcon from '../../../images/portal/icon-1.png';
import developIcon from '../../../images/portal/icon-2.png';
import trackingIcon from '../../../images/portal/icon-3.png';
import circleIcon from '../../../images/portal/circle-xxl.png';
import subscribeNow from '../../../images/portal/subscribe-now.png';
import signInNow from '../../../images/portal/sign-in-text.png';

/** Actions */
import { customerRegister, empty, customerSignIn } from '../../../Actions/AuthActions';
import { emptyCustomer, signedUpCustomer, signedInCustomer } from '../../../Actions/CustomerActions';
import { addCodeToRedux } from '../../../Actions/PromoCodesActions';
import { removeSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { redirectionPage } from '../../../Actions/RedirectionPagesActions';

/** Routes */
import { PORTAL_LOGIN_METHODS, PORTAL_PAYMENT_METHODS, PORTAL_FORGET_PASSWORD } from '../../../routes';

const INITIAL_STATE = {
  register: true,
  activeRegisterBtn: 'active',
  activeSignInBtn: '',
  testimonials: [],
  activeItemIndex: 0,
  email: '',

  isRevealPassword: false,
  password: '',

  country: '',
  countryText: '',
  code: '',
  disabled: true,
  loginDisabled: true,
  errorMsg: '',
  error: false,
  openSnakBar: false,
  vertical: 'bottom',
  horizontal: 'right',

}

class Auth extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.props.redirectionPage();

    // ref for password icon
    this.passwordOneRef = React.createRef();
    this.iconRevealPassword = React.createRef();
  }

  componentDidMount() {
    // import("../../../css/portal-style.css");
  }

  togglePassword = event => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword })
  }
  handleRegisterForm = () => {
    this.setState({ register: true, activeRegisterBtn: 'active', activeSignInBtn: '' });
    this.setState({ error: false, errorMsg: '' })
  }

  handleSignInForm = () => {
    this.setState({ register: false, activeRegisterBtn: '', activeSignInBtn: 'active' });
    this.setState({ error: false, errorMsg: '' })
  }

  changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

  handleRegisterChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    let name = e.target.name;
    this.setState({ error: false, errorMsg: '' })
    this.setState({ [name]: checkSpaces });
    setTimeout(() => {
      if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
        this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ' &&
        this.state.country !== '' && this.state.country !== null && this.state.country !== undefined && this.state.country !== ' ') {
        this.setState({ disabled: false })
      } else {
        this.setState({ disabled: true })
      }
    }, 0);
  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces });
    setTimeout(() => {
      this.setState({ error: false, errorMsg: '' })
      if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
        this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ') {
        this.setState({ loginDisabled: false })
      } else {
        this.setState({ loginDisabled: true })
      }
    }, 0);
  }

  handleRegisterSubmit = (e) => {
    if(this.state.password.length < 8){
      this.setState({error: true, errorMsg: 'يجب ألا تقل كلمة المرور عن 8 أحرف'})
    }else if(this.state.password.indexOf(' ') !== -1){
      this.setState({error: true, errorMsg: 'يجب ألا تحتوي كلمة المرور علي أي مسافات'})
    }else{
      let obj = this.state;
      // call Register API
      this.props.signedUpCustomer();
      this.props.customerRegister(obj);
    }
    e.preventDefault();
  }

  handleLoginSubmit = (e) => {
    let obj = this.state;
    // call Login API
    this.props.signedInCustomer();
    this.props.customerSignIn(obj);
    e.preventDefault();
  }

  handleClose = (e) => {
    this.setState({ openSnakBar: false })
  }

  handleCountryChange = (e, data) => {
    setTimeout(() => {
      this.setState({ countryText: data.value });
      for (var i = 0; i < countries.length; i++) {
        if (countries[i].value === data.value) {
          this.setState({ country: countries[i].name });
          break;
        }
      }
      if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
        this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ' &&
        this.state.country !== '' && this.state.country !== null && this.state.country !== undefined && this.state.country !== ' ') {
        this.setState({ disabled: false })
      } else {
        this.setState({ disabled: true })
      }
    }, 0)
  }

  BRANCH_SET_IDENTITY = () => {
    let obj = this.state;

    branch.init('key_test_jmMVB6W5LMRcYl69K7eE3kalwEbn6HAh', /*options,*/ function (err, data) {
      console.log(err);
      console.log(data);
    });

    // start setIdentity
    branch.setIdentity(obj.uid, function (err, data) {
      console.log(err);
      console.log(data)

    });
  }

  BRANCH_COMPLETE_REGISTRATION = () => {
    ////////////////// start lifecycle
    // COMPLETE_REGISTRATION
    console.log("start COMPLETE_REGISTRATION")

    var event_and_custom_data = {};

    var customer_event_alias = "";

    var content_items = [];

    branch.logEvent(
      "COMPLETE_REGISTRATION", //COMPLETE_TUTORIAL
      event_and_custom_data,
      content_items,
      customer_event_alias,
      function (err) { console.log(err); }
    );
    console.log("end COMPLETE_REGISTRATION")
    ////////////////// end lifecycle

  }

  BRANCH_COMPLETE_TUTORIAL = () => {
    ////////////////// start lifecycle
    // COMPLETE_TUTORIAL
    console.log("start COMPLETE_TUTORIAL")

    var event_and_custom_data = {
    };

    var customer_event_alias = "";

    var content_items = [];

    branch.logEvent(
      "COMPLETE_TUTORIAL",
      event_and_custom_data,
      content_items,
      customer_event_alias,
      function (err) { console.log(err); }
    );
    console.log("end COMPLETE_TUTORIAL")
    ////////////////// end lifecycle

  }

  handleSuccessMessages = () => {
    this.props.removeSuccessMessage();
  }

  render() {
    const { activeItemIndex, isRevealPassword } = this.state;
    const { success, error, loading, loginsucess, loginerror, loginloading } = this.props;
    let registerForm = '';
    let signinForm = '';

    let registerDisableButton = this.state.disabled

    if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
        this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ' &&
        this.state.country !== '' && this.state.country !== null && this.state.country !== undefined && this.state.country !== ' ') {
          registerDisableButton= false
      }

    let buttonSubmitLoader = (
      <div className="custom-form-group">
        <button disabled={registerDisableButton} className="btn form-control" type="submit" onClick={this.handleRegisterSubmit} >
          اشترك الآن
        </button>
      </div>
    );

    let loginDisableButton = this.state.loginDisabled;
    if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined && this.state.email !== ' ' &&
        this.state.password !== '' && this.state.password !== null && this.state.password !== undefined && this.state.password !== ' ') {
          loginDisableButton =  false
      }

    let buttonLoginLoader = (
      <div className="custom-form-group w-100 mb-3">
        <button disabled={loginDisableButton} className="btn form-control" type="submit" onClick={this.handleLoginSubmit} >
          تسجيل الدخول
        </button>
      </div>
    );

    if (loading === true) {
      buttonSubmitLoader = (
        <button className="ui loading button">
          Loading
        </button>
      );
    }

    if (success === true && error === '') {
      // redirect to the next page
      //alert("تم تسجيل الحساب بنجاح");
      this.setState({ openSnakBar: true });
      this.BRANCH_COMPLETE_REGISTRATION();
      this.props.customerSignIn(this.state);
      this.props.emptyCustomer();
      this.props.empty();
      this.setState({ error: false, errorMsg: '' });
    } else if (success === false && (error !== '' && error !== false)) {
      if (error.code === 'auth/email-already-in-use') {
        this.setState({ errorMsg: 'البريد الإلكتروني المُدخل مسجل مسبقاً' })
      } else {
        this.setState({ errorMsg: 'حدث خطأ ما، يرجي المحاولة مرة أخرى' })
      }
      this.setState({ error: true });
      this.props.empty();
      this.props.emptyCustomer();
    }


    if (loginloading === true) {
      buttonLoginLoader = (
        <button className="ui loading button">
          Loading
        </button>
      );
    }
    if (loginsucess === true) {
      var param = 0;
      if (this.state.code !== null && this.state.code !== '') {
        param = this.state.code;
        this.props.addCodeToRedux(this.state.code);
      }
      this.BRANCH_SET_IDENTITY();
      let redirect = PORTAL_PAYMENT_METHODS;
      return (<Redirect to={redirect}> </Redirect>);
      this.props.empty();
      this.setState({ error: false, errorMsg: '' });
    }
    else if(loginsucess === false && (loginerror !== '' && loginerror !== false && loginerror !== undefined)){
      if(loginerror.code === 'auth/network-request-failed'){
        this.setState({errorMsg: 'فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً'})
      }else if(loginerror.code === 'auth/user-not-found'){
          this.setState({errorMsg: 'البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال'})
      }else if(loginerror.code === 'auth/wrong-password'){
          this.setState({errorMsg: 'كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور'})
      }else if(loginerror.code === undefined ){
        this.setState({errorMsg: loginerror})
      }else{
        this.setState({errorMsg: 'حدث خطأ ما، يرجى المحاولة لاحقاً'})
      }
      this.setState({ error: true });
      this.props.empty();
    }

    if (this.state.register === true) {
      registerForm = (
        <form className="signup-form">
          <div className="form-group">
            <input type="email" className="form-control" name="email" placeholder="بريدك الإلكتروني"
              value={this.state.email}
              onChange={this.handleRegisterChange}
              onFocus={this.BRANCH_COMPLETE_TUTORIAL} />
          </div>
          <div className="form-group">

            <div className="input-container" style={{ 'position': 'relative' }}>
              <input id="font"
                type={isRevealPassword ? "text" : "password"}
                dir="auto"
                className="filed form-control"
                name="password"
                placeholder="كلمة المرور"
                value={this.state.password}
                onChange={this.handleRegisterChange}
                ref={this.passwordOneRef} />

              <span onClick={this.togglePassword} ref={this.iconRevealPassword}>
                <span>
                  {isRevealPassword ?
                    <FontAwesomeIcon icon="eye" className="password-eye" /> :
                    <FontAwesomeIcon icon="eye-slash" className="password-eye" />
                  }
                </span>

              </span>
            </div>
          </div>

          <div className="form-group" id="user-country">
            <Dropdown fluid search selection className="dropdown form-control"
              style={{ textAlign: 'right', border: 'none' }}
              id={this.state.country}
              placeholder={(this.state.countryText) ? this.state.countryText : 'بلدك'}
              value={this.state.countryText}
              name={this.state.country}
              onChange={this.handleCountryChange}
              options={countries}
            />

          </div>
          <div className="form-group">
            <input type="text" className="form-control" name="code" placeholder="رمز الخصم"
              value={this.state.code}
              onChange={this.handleRegisterChange} />
          </div>
          {buttonSubmitLoader}

        </form>
      )
    }
    else {
      signinForm = (
        <form className="signin-form">
          <div className="form-group">
            <input type="email" className="form-control" name="email" placeholder="بريدك الإلكتروني"
              value={this.state.email}
              onChange={this.handleChange} />
          </div>

          <div className="form-group">
            <div className="input-container" style={{ 'position': 'relative' }}>
              <input id="font"
                type={isRevealPassword ? "text" : "password"}
                dir="auto"
                className="filed form-control"
                name="password"
                placeholder="كلمة المرور"
                value={this.state.password}
                onChange={this.handleChange}
                ref={this.passwordOneRef} />

              <span onClick={this.togglePassword} ref={this.iconRevealPassword}>
                <span>
                  {isRevealPassword ?
                    <FontAwesomeIcon icon="eye" className="password-eye" /> :
                    <FontAwesomeIcon icon="eye-slash" className="password-eye" />
                  }
                </span>

              </span>
            </div>
          </div>
          <div className="custom-form-group w-100 mb-3">
            <div className="forgot-pw">
              <NavLink style={{ float: 'right', paddingRight: '5px' }} to={PORTAL_FORGET_PASSWORD}>نَسِيتُ كلمة المرور!</NavLink>
            </div>
          </div>
          <br /><br />
          <div className="form-group">
            <input type="text" className="form-control" name="code" placeholder="رمز الخصم"
              value={this.state.code} onChange={this.handleChange} />
          </div>
          {buttonLoginLoader}
          <div className="another-ways">
            <div className="text">
              <p>هل سجَّلت عبر وسيلة أخرى؟</p>
              <div className="sign-way">
                <NavLink to={PORTAL_LOGIN_METHODS}>تسجيل الدخول عبر وسيلة أخرى.</NavLink>
              </div>
            </div>
          </div>
        </form>
      )
    }

    let introImage = (
      <div className="image">
        <img src={(this.state.register === true) ? subscribeNow : signInNow} alt="" srcSet="" />
      </div>
    )
    const { vertical, horizontal } = this.state;
    const { successMessage } = this.props;

    return (
      <body>

        <Snackbar
          autoHideDuration={6000} onClose={this.handleSuccessMessages}
          anchorOrigin={{ vertical, horizontal }}
          open={successMessage.show}
          onClose={this.handleSuccessMessages}
          message={successMessage.message}
          key={vertical + horizontal}
        />

        <Snackbar
          autoHideDuration={6000} onClose={this.handleClose}
          anchorOrigin={{ vertical, horizontal }}
          open={this.state.openSnakBar}
          onClose={this.handleClose}
          message="تم تسجيل الحساب بنجاح"
          key={vertical + horizontal}
        />
        <div className="logo-container">
          <div className="container">
            <div className="logo">
              <img src={mainLogoM} alt="logo" />
            </div>
          </div>
        </div>
        <section className="jeel-video">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="about-text">
                  <div className="title h2">
                    ‫‫عالمٌ مُدهشٌ بانتظارك!
                              <br />
                    <p className="h4">استمتع مع طفلك بمزايا جيل الرائعة! </p>
                  </div>
                  <ul className="list-unstyled no-gutters">
                    <li className="d-flex align-items-center">
                      <span className="icon video-section">
                        <img src={reportIcon} alt="icon" />
                      </span>
                      <span className="list-text">
                        محتوًى تربويٌ هادفٌ، أعدَّته مجموعة من التربويِّين المُختصين.
                                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="icon video-section">
                        <img src={developIcon} alt="icon" />
                      </span>
                      <span className="list-text">
                        تطبيق آمن تمامًا لطفلك، دون أي محتوًى غير ملائم.
                                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="icon video-section">
                        <img src={trackingIcon} alt="icon" />
                      </span>
                      <span className="list-text">
                        الاطلاع على مقالات وبرامج قسم جُسُور التربوي، ومتابعة المحتوى المعروض لطفلك.
                                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="icon video-section">
                        <img src={developIcon} alt="icon" />
                      </span>
                      <span className="list-text">
                        أنشطة وألعاب لإثراء قيم طفلك ومهاراته، وتقارير لتُتابِع استخدامه للتطبيق.
                                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="icon video-section">
                        <img src={reportIcon} alt="icon" />
                      </span>
                      <span className="list-text">
                        حساب واحد لثلاثة أطفال.
                                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-12 col-md-6" style={{ alignContent: 'center' }}>
                <section className="video_view" >
                  <div >
                    <ReactPlayer style={{ maxWidth: '100%' }} playsInline
                      fluid="true" controls={true}
                      url="https://www.youtube.com/watch?v=D2PJr4ups7M&list=PLr-Tz3QO4fte0tx9Z6o_u9BTUg9KMkJeJ&index=2" />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        {/** Promo Code */}
        <section className="promo-code">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="image-container">
                  {introImage}
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-container">
                  <div className="btn-container">
                    <button data-toggle-form=".signup-form" className={"btn toggle-btn signin-btn " + this.state.activeRegisterBtn} onClick={this.handleRegisterForm}>حساب جديد</button>
                    <button data-toggle-form=".signin-form" className={"btn toggle-btn signup-btn " + this.state.activeSignInBtn} onClick={this.handleSignInForm}>تسجيل دخول</button>
                  </div>
                  <p style={{ color: 'red', textAlign: 'right' }}> {this.state.error && this.state.errorMsg}</p>
                  {registerForm}
                  {signinForm}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/** End Promo Code */}

        <Testimonials />
        {/** Start Footer **/}
        <Footer />
        {/** End Footer */}

      </body>
    )
  }
}

const mapStateToProps = state => ({
  customer: state.customers.item,
  success: state.customers.adding_customer_sucessfully,
  loading: state.customers.loading,
  error: state.customers.adding_customer_error,
  loginsucess: state.authUser.success,
  loginerror: state.authUser.errorMsg,
  loginloading: state.authUser.loading,
  codeName: state.promoCodes.code_name,

  successMessage: state.successMessages.item,
});

export default withRouter
  (connect
    (mapStateToProps,
      {
        customerSignIn,
        empty,
        emptyCustomer,
        signedUpCustomer,
        signedInCustomer,
        customerRegister,
        addCodeToRedux,
        removeSuccessMessage,
        redirectionPage
      })
    (Auth)
  );
