import React, { Component } from 'react';
import '../../../node_modules/video-react/dist/video-react.css';
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from '../Layout/Loader'
import '../../css/Songs.css';
import { withRouter,Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { emptyError } from '../../Actions/Empty'
import { getAllBanners } from '../../Actions/BannerAction'
// import DeleteBanner from './DeleteBanner';
/* Routes */
import { ADMIN_LAND_PAGE, ADD_BANNER, BANNER_TO_EDIT } from '../../routes';
import SetBannerStatus from './SetBannerStatus';
import DeleteBanner from './DeleteBanner';


class GetAllBanners extends Component {

    componentDidMount() {
        this.props.getAllBanners();
        this.props.emptyError();
    }

    render() {
        const { banners } = this.props;
        const {error} = this.props;
        let bannersList = null
        if (this.props.bannersStatus === true) {
            bannersList = (<Loader/>)
        }
        else if(this.props.bannersStatus===false && this.props.error==='') {
            bannersList =
                banners.map(banner => {


                    return (
                        <div className="card" key={banner.id}>
                            <div className="row" >
                                <div className="col ">
                                    <img className="rounded" src={banner.image} alt="" height="90" width="90" />
                                </div>
                                <div className="col-10">

                                    <div>

                                        <span className="card-title" color="gray">
                                            {banner.titleInEnglish} - {banner.title}
                                        </span>
                                        <DeleteBanner id={banner.id}/>
                                        <Link to={`${BANNER_TO_EDIT}/${banner.id}`}><button className="songCardButtons"  >Edit</button>
                                        </Link>
                                        <SetBannerStatus id={banner.id} status={banner.status}/>
                                    </div>
                                    <span className="font-italic small-font">
                                        <span className="card-link">{banner.link}</span>
                                        <hr />
                                        <span><p>{banner.description}</p></span>

                                    </span>


                                </div>
                            </div>
                        </div>
                    )
                })
        }
        else
        {
            bannersList=( <div><br/><center><div>{error}</div></center><br/></div>)
        }
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body" id="left-main-body">
                                        <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span>  </span>
                                                    </span>
                                                    <span>Banners</span>

                                                </div>
                                            <br />
                                            <h5 className="main-title mt-2 font-weight-bold"><span>  Banners</span></h5>

                                           <Link to={ADD_BANNER}><button type="submit" className="SongsButtons">+ Add New Banner</button></Link>
                                            <div className="container">
                                                {bannersList}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )

    }
}
const mapStateToProps = state => ({
    banners: state.banners.items,
    bannersStatus: state.banners.loading,
    error:state.banners.error_loading_banners
});

export default withRouter(connect(mapStateToProps, { getAllBanners ,emptyError })(GetAllBanners));
