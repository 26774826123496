import { LOAD_GENERAL_SETTING, GET_GENERAL_SETTING_DATA, ERROR_LOADING_GENERAL_SETTING, GENERAL_SETTING_UPDATE, ERROR_UPDATING_GENERAL_SETTING, EMPTY_GENERAL_SETTING } from './Types'
import { post, put } from 'axios'
export const getGeneralSetting = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_GENERAL_SETTING,
            })
            const url = '/GeneralSetting/getGeneralSettingDetails'
            const formData = new FormData();
            formData.append("ID", id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: GET_GENERAL_SETTING_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_GENERAL_SETTING,
                    payload: Error.message
                })
            })

        })
    }
}

export const updateGeneralSetting = (id, aboutUs, contactUs, facebookPage, instagramPage, twitterAccount, introVideoWithMusic, introVideoWithoutMusic, aboutUsEng, mainBannerImage, mainBannerAction) => {
    console.log(aboutUsEng)

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/GeneralSetting/editGeneralSetting"

            const formData = new FormData();
            formData.append("ID", id);
            formData.append("About_Us", aboutUs);
            formData.append("About_Us_Eng", aboutUsEng);
            formData.append("Contact_Us", contactUs);
            formData.append("Facebook_Page", facebookPage);
            formData.append("Instagram_Page", instagramPage);
            formData.append("Twitter_Account", twitterAccount);
            formData.append('Intro_Video_With_Music', introVideoWithMusic);
            formData.append('Intro_Video_Without_Music', introVideoWithoutMusic);
            formData.append("Main_Banner_Image", mainBannerImage);
            formData.append("Main_Banner_Action", mainBannerAction);


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            put(url, formData, config).then((res) => {
                dispatch({
                    type: GENERAL_SETTING_UPDATE,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATING_GENERAL_SETTING,
                    payload: Error.message
                })
            })

        })
    }
}

export const emptyGeneralSetting = () => dispatch => {
    dispatch({
        type: EMPTY_GENERAL_SETTING,
        payload: ""
    })
}