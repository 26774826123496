import React, { Component } from 'react'
import {Elements} from 'react-stripe-elements';

import CreditCardPayment from './CreditCardPayment';

class CreditCardCheckout extends Component {
    render() {
        return (
            <Elements>
                <CreditCardPayment />
            </Elements>
        )
    }
}

export default CreditCardCheckout
