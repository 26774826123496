import React, { Component } from 'react'
import Loader from '../Layout/Loader';
import PieChart from 'react-minimal-pie-chart';

export default class GenderFilter extends Component {

    state = {
        totalSessionsPerDayValues: [],
        totalTimeUsagePerDayValues: [],
        averageSessionTimeValues: [],
      
    }

    componentDidMount() {

    }
    componentWillReceiveProps(nextProp) {
        let totalSessionsPerDayValues = [];
        let totalTimeUsagePerDayValues = [];
        let averageSessionTimeValues = []


        if (nextProp.genderFilter.length > 0) {
            if (nextProp.genderFilter[0].kidUsageReport !== null) {
                let pie = { title: nextProp.genderFilter[0].gender, value: nextProp.genderFilter[0].kidUsageReport.totalSessionsPerDay, color: '#800080' }
                let pieUsage = { title: nextProp.genderFilter[0].gender, value: nextProp.genderFilter[0].kidUsageReport.totalTimeUsagePerDay, color: '#800080' }
                let average = { title: nextProp.genderFilter[0].gender, value: nextProp.genderFilter[0].kidUsageReport.averageSessionTime, color: '#800080' }
                totalSessionsPerDayValues.push(pie)
                totalTimeUsagePerDayValues.push(pieUsage)
                averageSessionTimeValues.push(average)
                pie = { title: nextProp.genderFilter[1].gender, value: nextProp.genderFilter[1].kidUsageReport.totalSessionsPerDay, color: '#e67e00' }
                pieUsage = { title: nextProp.genderFilter[1].gender, value: nextProp.genderFilter[1].kidUsageReport.totalTimeUsagePerDay, color: '#e67e00' }
                average = { title: nextProp.genderFilter[1].gender, value: nextProp.genderFilter[1].kidUsageReport.averageSessionTime, color: '#e67e00' }
                totalSessionsPerDayValues.push(pie)
                totalTimeUsagePerDayValues.push(pieUsage)
                averageSessionTimeValues.push(average)

            }
        }

        this.setState({ totalSessionsPerDayValues: totalSessionsPerDayValues, totalTimeUsagePerDayValues: totalTimeUsagePerDayValues, averageSessionTimeValues: averageSessionTimeValues })

    }


    render() {
        const { genderFilter, error_loading_gender_filter, loading_gender_filter, gender } = this.props
        // console.log(this.props)
        let data = null;
        if (loading_gender_filter === true) {
            data = <Loader />
        }
        else if (loading_gender_filter === false && error_loading_gender_filter === '') {
            if (gender === '') {
                data = <div class="row t-row">
                    <div class="col-12 head-row small-font">
                        Gender
                <hr />
                    </div>
                    <div class="col-md-4">
                        <div class="h2 font-weight-bold text-center p-3">
                            <PieChart
                                data={this.state.totalSessionsPerDayValues}
                                label={({ data, dataIndex }) =>
                                    Math.round(data[dataIndex].percentage) + '%'
                                } labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                           

                            /></div>
                    </div>
                    <div class="col-md-4">
                        <div class="h2 font-weight-bold text-center p-3"><PieChart
                            data={this.state.totalTimeUsagePerDayValues}
                            label={({ data, dataIndex }) =>
                                Math.round(data[dataIndex].percentage) + '%'
                            }labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                           

                        /></div>
                    </div>
                    <div class="col-md-4">
                        <div class="h2 font-weight-bold text-center p-3"><PieChart
                            data={this.state.averageSessionTimeValues}
                            label={({ data, dataIndex }) =>
                                Math.round(data[dataIndex].percentage) + '%'
                            } labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                           

                        /></div>
                    </div>
                </div>
            }
            else if (gender === 'male') {
                if (genderFilter[0].kidUsageReport !== undefined) {
                    data = <div class="row t-row">
                        <div class="col-12 head-row small-font">
                            Gender
         <hr />
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3">{genderFilter[0].kidUsageReport.totalSessionsPerDay}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3">{genderFilter[0].kidUsageReport.totalTimeUsagePerDay}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3">{genderFilter[0].kidUsageReport.averageSessionTime}</div>
                        </div>
                    </div>
                }
            }
            else if (gender === 'female') {
                if (genderFilter[1].kidUsageReport !== undefined) {
                    data = <div class="row t-row">
                        <div class="col-12 head-row small-font">
                            Gender
      <hr />
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3">{genderFilter[1].kidUsageReport.totalSessionsPerDay}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3">{genderFilter[1].kidUsageReport.totalTimeUsagePerDay}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3">{genderFilter[1].kidUsageReport.averageSessionTime}</div>
                        </div>
                    </div>
                }
            }
        }
        return (

            <div>
                {data}
            </div>
        )
    }
}
