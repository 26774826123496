import {post} from 'axios'
import {
    GET_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    LOAD_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    ERROR_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
    EMPTY_SUBSCRIPTIONS_REPORT_BY_COUNTRY,} from './Types';


export const getTotalPrice =(startDate,endDate)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>
        {
            dispatch({
                type:LOAD_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
            })
            const url = "/country-subs-report/get-total-price"
            const formData = new FormData();
            formData.append("Start_Date",startDate)
            formData.append("End_Date",endDate)
            const config = {
                headers:{
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            
            }
            post(url,formData,config).then((res)=>
            {
                const countrySubscriptions = res.data;
                console.log(countrySubscriptions);
                dispatch({
                    type:GET_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
                    payload:countrySubscriptions
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
                    payload:Error.message
                })
            })
        })
    }
}

export const emptySubscriptionReportByCountry =()=> dispatch=>
{
    dispatch({
        type:EMPTY_SUBSCRIPTIONS_REPORT_BY_COUNTRY,
        payload:[]
    })    
}
