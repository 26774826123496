import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom';

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {
    deleteRadio,
    fetchAllRadio,
    emptyError
} from '../../Actions/RadioActions'
import { ALL_RADIO } from '../../routes';


class DeleteRadio extends Component {
  
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteRadio(id) {
        this.props.deleteRadio(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        this.deleteRadio(id);
        this.close();

    }


    render() {
        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();

        }
        else if (success === true) {
            alert("Radio Deleted Successfully")
            this.props.emptyError();
            this.props.fetchAllRadio();
            return (<Redirect to={ALL_RADIO}></Redirect>)
        }

        return (
            <div >
                <button className="seriesCardButtons" onClick={this.open}>
                    <i>
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal"
                    content='Are you sure 
                    you want to delete this Radio ??'
                    open={this.state.open} 
                    onCancel={this.close} 
                    onConfirm={this.handleSubmit} />
            </div>

        )
    }
}


DeleteRadio.propTypes = {
    deleteRadio: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    error: state.radio.deleting_radio_parent_error,
    success: state.radio.deleting_radio_parent_sucessfully
});

export default withFirebase(
    connect
        (mapStateToProps,
            {
                deleteRadio,
                fetchAllRadio,
                emptyError

            })
        (DeleteRadio));
