import React,{Component} from 'react'
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import {setBlogStatus,getAllBlogs,emptyBlog} from '../../Actions/BlogActions'


class SetBlogStatus extends Component{

    state={open:false}
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setBlogStatus (id,status)
    {
        this.props.setBlogStatus(id,status);
    }
    
    handleSubmit =(e)=>
    {
        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setBlogStatus(id, "DEFAULT");

        } else {

            this.setBlogStatus(id, "SUSPENDED");
        }
        this.close();
    }
    render ()
    {
        const {status_updated_successfully,error_updating_status} = this.props
        const {id,status} = this.props
        let contentPreview =null;
        if(status_updated_successfully===false)
        {
            alert("error setting status"+error_updating_status);
            this.props.emptyBlog();
        }
        else if (status_updated_successfully===true)
        {
            alert("blog updated successfully");
            this.props.emptyBlog();
            this.props.getAllBlogs();
        }

        if (status==="SUSPENDED")
        {
            contentPreview=(
                <button className="seriesCardButtons" onClick={this.open}>
                <i className="far fa-trash-alt" >
                        <FontAwesomeIcon icon="play" />
                    </i>
                </button>
            )
        }
        else {
            contentPreview = (
                <button className="seriesCardButtons" onClick={this.open}>||</button>
            )
        }

        return ( <span >
            {contentPreview}
            <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
        </span>)

    }

}


const mapStateToProps =state =>
({
    status_updated_successfully:state.blogs.status_updated_successfully,
    error_updating_status:state.blogs.error_updating_status
})
export default connect (mapStateToProps,{setBlogStatus,getAllBlogs,emptyBlog}) (SetBlogStatus)
