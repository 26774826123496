import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
/*header */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import AudioPlayer from "react-h5-audio-player";
/*css */
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../node_modules/video-react/dist/video-react.css';
/*Action */
import {
    getBookData,
    emptyBook,
    saveBookPuzzle,
    saveBookPaintingGame,
    emptyAllBooks,
    addBookItemWithSentFrom
} from '../../Actions/ReadWithJeelAction';

import { addDragDropParentIDtoRedirectionPage, addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*component */
import QuestionPreview from '../Layout/QuestionPreview';
import GamePreview from '../Layout/GamePreview';
import PaintingPreview from '../Layout/PaintingPreview';
import UploadImage from '../S3Uploading/UploadImage';
import EvaluationPreview from '../Evaluation/EvaluationPreview';
import DragDropPreview from '../Layout/DragDropPreview'
import {
    BOOK_TO_EDIT,
    BOOK_ACTIVITY_TO_EDIT,
    SIGN_IN, VIEW_BOOK
} from '../../routes';


const INITIAL_STATE = {
    bookId: '',
    bookParentId: '',
    bookParentName: '',
    // bookName: '',

    Icon_Image: "",
    Painting_Image: ""
}

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

function ConvertDate(date) {
    var date = new Date(date)
    var convertedDate = date.toLocaleString()
    return convertedDate
}

class BookDetails extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.props.emptyBook();
        
    }

    componentDidMount() {
        const { auth } = this.props
        const { redirectionPage, readWithJeel } = this.props;
        
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            // 
            // getting BOOK Paren tId from redux
            // let bookParentName = this.props.match.params.bookParentName;
            // let bookParentId = this.props.match.params.bookParentId;
            // let bookId = this.props.match.params.bookId;

            let bookParentName = this.props.match.params.bookParentName;
            let bookParentId = this.props.match.params.bookParentId;
            let bookId = this.props.match.params.bookId;

            this.setState({
                bookParentName: bookParentName,
                bookParentId: bookParentId,
                bookId:bookId

            });
            // const { redirectionPage } = this.props;
            let id = bookId;

            // adding BOOK As parent page
            // to redirect to it after adding BOOK
            this.props.emptyAllBooks()
            this.props.addSeriesIDtoRedirectionPage(VIEW_BOOK, id);

            this.props.getBookData(id);
            this.props.addDragDropParentIDtoRedirectionPage(VIEW_BOOK, id);
        }

    }
    

    getIconImageUrl(imageUrl) {

        if (imageUrl) {
            this.setState({ Icon_Image: imageUrl });
            const { redirectionPage } = this.props;
            let bookId = redirectionPage.series_Id;
            saveBookPuzzle(bookId, imageUrl)
                .then(res => {
                    alert("Puzzle Game Item Added Successfully");
                    // let id = this.props.match.params.bookId;
                    const { redirectionPage } = this.props;
                    let id = redirectionPage.series_Id;
                    this.props.getBookData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        setTimeout(() => {
            this.setState({ Icon_Image: "" });
        }, 10);
    }

    getPaintingImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ Painting_Image: imageUrl });
            const { redirectionPage } = this.props;
            let bookId = redirectionPage.series_Id;
            saveBookPaintingGame(bookId, imageUrl)
                .then(res => {
                    alert("Painting Game Item Added Successfully");
                    // let id = this.props.match.params.bookId;
                    const { redirectionPage } = this.props;
                    let id = redirectionPage.series_Id;
                    this.props.getBookData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        setTimeout(() => {
            this.setState({ Painting_Image: "" });
        }, 10);
    }


    addingItemToRedux = (item, e) => {
        let itemContent = item
        item.parentID = this.state.bookParentId
        item.parentName = this.state.bookParentName
        this.props.addBookItemWithSentFrom(itemContent, 'ViewBook');
        this.setState({
            addingDone: true,
            itemId: item.id
        })
    }



    render() {

        const { redirection, addingDone } = this.state;
        if (redirection == true) {
            return (<Redirect to={VIEW_BOOK} ></Redirect>)
        }

        if (addingDone == true) {
            return (<Redirect to={BOOK_TO_EDIT + "/" + this.state.bookId} ></Redirect>)
        }

        // 
        const { redirectionPage } = this.props;
        let bookId = redirectionPage.series_Id;

        const { readWithJeel } = this.props
        const { activity } = this.props
        const { puzzles } = this.props.readWithJeel
        const { paintingGames } = this.props.readWithJeel
        const { dragDrops } = this.props.readWithJeel

        let evaluationPreview = null;

        let questionsList = null,
            editActivityLink = null,
            gamesList = null,
            gamesPaintingList = null;

        // 
        let pagesList = readWithJeel.bookPages && readWithJeel.bookPages.length ? readWithJeel.bookPages.map((page, index) => {
            return (
                <div key={index} className='viewPagesList w-100 mt-3'>
                    <div className='row pb-3' style={{ borderBottom: "1px solid #eee" }}>
                        <div className='col col-sm-3'>
                            <div className='pageImage'>
                                {
                                    page.image ? <img style={{ maxWidth: "100%" }} src={page.image} alt="Page Image" /> : 'No Image'
                                }

                            </div>
                        </div>
                        <div className='col col-sm-9'>
                            <div className='pageDescription'>
                                {page.text}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }) : <div className="text-center">No Pages</div>


        // 

        if (activity !== undefined) {

            questionsList = (
                <QuestionPreview state='view'
                    questions={activity.questions} />)

            evaluationPreview = (
                <EvaluationPreview
                    evaluationCategory={activity.evaluationCategory} />);


            if (activity.id !== undefined) {
                editActivityLink = (
                    <Link to={{
                        pathname: BOOK_ACTIVITY_TO_EDIT
                            + "/" + activity.id
                            + "/" + bookId
                    }}>

                        <Button id="episode_view"> Edit </Button>

                    </Link>);
            }
        }

        let subvalue = "No Sub Value"
        if (readWithJeel.contentSubValue !== null
            && readWithJeel.contentSubValue !== undefined) {

            if (readWithJeel.contentSubValue.contentValue !== null
                &&
                readWithJeel.contentSubValue.contentValue !== undefined) {
                subvalue = readWithJeel.contentSubValue.contentValue;
            }
        }

        if (puzzles !== undefined) {
            gamesList = (
                <GamePreview
                    state='viewBook'
                    games={puzzles}
                    bookId={bookId}
                />)
        }

        if (paintingGames !== undefined) {
            gamesPaintingList = (
                <PaintingPreview
                    state='viewBook'
                    games={paintingGames}
                    bookId={bookId} />)
        }

        let book_drag_Drop = null;
        if (dragDrops !== undefined) {
            book_drag_Drop = (<DragDropPreview
                games={dragDrops}
                state="viewBook"
                parentId={bookId}
                bookId={bookId} />);
        }
        let isFree = ''
        if (readWithJeel.isFree == 0) {
            isFree = 'paid'
        }
        else if (readWithJeel.isFree == 1) {
            isFree = 'free'
        }

        let titleAudioView = ''
        if (readWithJeel.voice !== undefined &&
            readWithJeel.voice !== null &&
            readWithJeel.voice !== "undefined"
            && readWithJeel.voice !== "null"
            && readWithJeel.voice !== 'null') {
            titleAudioView = (<AudioPlayer
                src={readWithJeel.voice}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            titleAudioView = (
                <td fluid className="episode_label"> No Title Audio</td>
            )
        }

        // 

        let bookAudioView = ''
        if (readWithJeel.bookVoice !== undefined &&
            readWithJeel.bookVoice !== null &&
            readWithJeel.bookVoice !== "undefined"
            && readWithJeel.bookVoice !== "null"
            && readWithJeel.bookVoice !== 'null') {
            bookAudioView = (<AudioPlayer
                src={readWithJeel.bookVoice}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            bookAudioView = (
                <td fluid className="episode_label"> No Book Audio</td>
            )
        }

        let audioMarker = ''
        if (readWithJeel.bookMarker) {
            audioMarker = <>
                <td fluid className="episode_label">
                    <button type="button" className="ui blue button" data-toggle="modal" data-target="#merkerDataModal">
                        View Marker Data
                    </button>
                </td>
                <div className="modal fade bd-example-modal-lg" id="merkerDataModal" tabindex="-1" role="dialog" aria-labelledby="merkerDataModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="merkerDataModalLabel">Marker Data</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* ...Table... */}
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ borderTop: "none" }} scope="col">Name</th>
                                            <th style={{ borderTop: "none" }} scope="col">Description</th>
                                            <th style={{ borderTop: "none" }} scope="col">Start</th>
                                            <th style={{ borderTop: "none" }} scope="col">Duration</th>
                                            <th style={{ borderTop: "none" }} scope="col">Type</th>
                                            <th style={{ borderTop: "none" }} scope="col">Time Format</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            JSON.parse(readWithJeel.bookMarker).length ? JSON.parse(readWithJeel.bookMarker).map((markerItem, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{markerItem.name ? markerItem.name : ""}</td>
                                                        <td>{markerItem.description ? markerItem.description : ""}</td>
                                                        <td>{markerItem.start ? markerItem.start : ""}</td>
                                                        <td>{markerItem.duration ? markerItem.duration : ""}</td>
                                                        <td>{markerItem.type ? markerItem.type : ""}</td>
                                                        <td>{markerItem.time_format ? markerItem.time_format : ""}</td>
                                                    </tr>
                                                )
                                            }) : ''
                                        }
                                    </tbody>
                                </table>
                                {/* END */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        else {
            audioMarker = (
                <td fluid className="episode_label"> No Audio Marker</td>
            )
        }


        let bookDescription = '';
        if (readWithJeel.description !== null &&
            readWithJeel.description !== undefined &&
            readWithJeel.description !== '') {
            bookDescription = readWithJeel.description
        }
        else {
            bookDescription = "No description"
        }


        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <label htmlFor="" >{readWithJeel.name}</label>

                                                <hr />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <img src={readWithJeel.image}
                                                            alt="" className="image_Preview rounded" />
                                                    </div>

                                                    <div className="col">
                                                        <table className='w-100'>
                                                            <tr>
                                                                <th className="episode_label"> Book Name </th>
                                                                <td fluid className="episode_label"> {readWithJeel.name} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Book Name In English </th>
                                                                <td fluid className="episode_label"> {readWithJeel.nameInEnglish} </td>
                                                            </tr>

                                                            {/* <tr>
                                                                <th className="episode_label"> Selected Radio </th>
                                                                <td fluid className="episode_label"> {this.state.radioName} </td>
                                                            </tr> */}
                                                            <tr>
                                                                <th className="episode_label"> Selected Book Parent </th>
                                                                <td fluid className="episode_label"> {this.state.bookParentName} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> payment availability </th>
                                                                <td fluid className="episode_label"> {isFree} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Sub Value </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {subvalue}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Schedule </th>
                                                                <td fluid className="episode_label">{GMTtolocalTime(readWithJeel.publishDate)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Title Audio </th>
                                                                {titleAudioView}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Book Audio </th>
                                                                {bookAudioView}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Audio Marker</th>
                                                                {audioMarker}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Description </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {bookDescription}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Description In English </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {readWithJeel.descriptionInEnglish}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                        </table>

                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='episode_label' style={{ fontWeight: "bold" }}>
                                                            Pages
                                                        </div>
                                                        <div fluid className="episode_label">
                                                            {pagesList}
                                                        </div>
                                                    </div>


                                                </div>
                                                <Link className="link" to={BOOK_TO_EDIT + "/" + this.state.bookId}>
                                                    <Button color='blue'
                                                        onClick={this.addingItemToRedux.bind(this, readWithJeel)}
                                                        id="editButton">
                                                        Edit
                                                    </Button>
                                                </Link>
                                                <br /> <br /> <br />


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Question</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {questionsList}


                                                </div>
                                                <br />

                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Evaluation</label>
                                                    <hr id="shorthr" />
                                                    <br />
                                                    {evaluationPreview}

                                                    <div className="buttons_container">
                                                        {editActivityLink}

                                                    </div>

                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Puzzle Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {gamesList}

                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getIconImageUrl.bind(this)}
                                                            imageUrl={this.state.Icon_Image}
                                                            imagepreview={this.state.Icon_Image}
                                                            s3DirectryrName='readWithJeel/puzzlegame'
                                                            bookId={bookId} />
                                                    </div>
                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Painting Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {gamesPaintingList}

                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getPaintingImageUrl.bind(this)}
                                                            imageUrl={this.state.Painting_Image}
                                                            imagepreview={this.state.Painting_Image}
                                                            s3DirectryrName='readWithJeel/paintinggame' 
                                                            bookId={bookId} />
                                                    </div>
                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Drag Drop Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {book_drag_Drop}

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}

BookDetails.propTypes = {
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    addBookItemWithSentFrom: propTypes.func.isRequired
}

const mapStateToProps = state => ({
    readWithJeel: state.readWithJeel.item,
    activity: state.readWithJeel.item.activityId,
    auth: state.firebase.auth,
    redirectionPage: state.redirectionPage.item,

});

export default connect
    (mapStateToProps,
        {
            getBookData,
            emptyBook,
            addDragDropParentIDtoRedirectionPage,
            addSeriesIDtoRedirectionPage,
            emptyAllBooks,
            addBookItemWithSentFrom
        })(BookDetails)
