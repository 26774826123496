import React, { Component } from "react";
import { Link } from "react-router-dom";

/*Actions*/
import {
  getUserData,
  emptyMobileUserProfile,
  emptyMobileUserProfileWithItem,
} from "../../Actions/MobilePaymentProfileAction";
import { emptyToken } from "../../Actions/loginActions.js";
import { removeFawryRedirectPage } from "../../Actions/FawryRedirectionActions";
import { emptyFawryPay } from "../../Actions/FawryPayAction";


/* images */
import logo from "../../images/logo.png";

/*Redux */
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class FawryFailure extends Component {
  constructor(props) {
    super(props);
    this.props.emptyFawryPay();
  }

  render() {
    
    return (
      <section className="main-payment custom-main-payment">
        <div className="container">
          <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
            <div className="d-flex align-items-center">
              <Link className="navbar-brand d-flex align-items-center" to="/">
                <img src={logo} alt="" />
              </Link>
              <div className="complete-process d-none d-sm-block">
                بوابة الدفع لجيل
              </div>
            </div>
            <Link className="btn back-btn" type="button" to="/upgrade-subscription">
              العوده
            </Link>
          </div>
        </div>

        <div className="container leftToRight">
          <div className="pay-box">
            <div>
              <div className=" card card-container Current-Package mb-4 fawry-card">
                <div className="card-header profile-name-card text-right">
                  <span className="h4 font">مرحبا</span>
                </div>
                <div className="tab-button-outer">
                  <div id="tab02" className="tab-contents">
                    <div className="  card-body">
                      <div className="  h6 text-center font">
                        لقد حدث خطأ ما من فضلك حاول مره اخرى
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FawryFailure.propTypes = {};
const mapStateToProps = (state) => ({
  userPaymentProfile: state.userPaymentProfile.item,
  redirectPage: state.fawryPaymentRedirect.item,
  token: state.user.token,
});

export default withRouter(
  connect(mapStateToProps, {
    emptyToken,
    emptyMobileUserProfile,
    emptyMobileUserProfileWithItem,
    removeFawryRedirectPage,
    emptyFawryPay,
    
  })(FawryFailure)
);
