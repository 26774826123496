import {
    ERROR_GET_PAYMENT_PRICE_BY_COUNTRY,
    GET_PAYMENT_PRICE_BY_COUNTRY,
    LOAD_PAYMENT_PRICE_BY_COUNTRY,
    EMPTY_PAYMENT_PRICE_BY_COUNTRY
} from "../Actions/Types";

const initialState = {
    items: [],
    item: {},
    loading: '',

    getting_package_prices_error: '',
    getting_package_prices_sucessfully: '',
}

export default function (state = initialState, action) {
    switch (action.type) {

        case LOAD_PAYMENT_PRICE_BY_COUNTRY:
            return {
                ...state,
                loading: true
            }

        case GET_PAYMENT_PRICE_BY_COUNTRY:
            return {
                ...state,
                item: action.payload,
                getting_package_prices_error: '',
                getting_package_prices_sucessfully: true,
                loading: false
            }
        case ERROR_GET_PAYMENT_PRICE_BY_COUNTRY:
            return {
                ...state,
                getting_package_prices_error: action.payload,
                loading: false
            }
        case EMPTY_PAYMENT_PRICE_BY_COUNTRY:
            return {
                ...state,
                getting_package_prices_error: '',
                getting_package_prices_sucessfully: '',
                item: {},
            }

        default:
            return state

    }

}