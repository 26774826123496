import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Input, Button, Modal, TextArea } from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { updateFAQ } from '../../Actions/FAQActions'
import { emptyError } from '../../Actions/Empty'
import { fetchAllFAQ } from '../../Actions/FAQActions'
import { ARABICREGEX, ENGLISHREGEX } from '../../constants'

const INITIAL_STATE = {

    showModal: '',
    /////////// FAQ
    id: 0,
    question: '',
    answer: '',
    language: '',
    formErrors: { question: '', answer: '' }
};

const Language = {
    ar: 'ar',
    en: 'en',
};

class EditFAQ extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }


    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    updateFAQ(FAQ_ID, question, answer) {
        this.props.updateFAQ(FAQ_ID, question, answer);
    }

    checkTextLanguage(stateName,text){
        
        let isCorrect = false;
        if (this.state.language === Language.en) {
            // Allow only English characters and spaces
            const englishRegex = ENGLISHREGEX;
            if (englishRegex.test(text)) {
                this.setState({ [stateName]: text });
                isCorrect = true
            }
        } else {
            // Allow only Arabic characters and spaces
            const arabicRegex = ARABICREGEX;
            if (arabicRegex.test(text)) {
                this.setState({ [stateName]: text });
                isCorrect = true;
            }
        }

        return isCorrect;
      
    }

    handleChange = (e) => {
        let validation = this.state.formErrors;
        validation.question = ""; validation.answer = "";
        this.setState({ formErrors: validation });
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        let stateName = e.target.name;
        const isTextValid = this.checkTextLanguage(stateName, checkSpaces);
        setTimeout(() => {
            if (this.state.question === "" || this.state.question === null || this.state.question === ' ') {
                validation.question = "Question can not be empty";
                this.setState({ formErrors: validation });
            }
            else{
                if(isTextValid && stateName === 'question'){
                    validation.question = '';
                    this.setState({ formErrors: validation});
                }
            }
            if (this.state.answer === "" || this.state.answer === null || this.state.answer === ' ') {
                validation.answer = "Answer can not be empty";
                this.setState({ formErrors: validation });
            }
            else {
                if(isTextValid && stateName === 'answer'){
                    validation.answer = '';
                    this.setState({ formErrors: validation});
                }
            }
            if(!isTextValid){
                if(stateName === 'question'){
                    validation.question = this.state.language === Language.en?"Enter text in English" : "أدخل نص باللغة العربية";
                    this.setState({ formErrors: validation});
                }else{
                    validation.answer = this.state.language === Language.en?"Enter text in English" : "أدخل نص باللغة العربية";
                    this.setState({ formErrors: validation});
                }
            }
        }, 0);
    }

    handleSubmit = (e) => {
        const { id, question, answer } = this.state;
        this.updateFAQ(id, question, answer);
        e.preventDefault();
        this.setState({
            question: '',
            answer: '',
            id: 0,
            language: '',
        })
    }


    closeModal = (e) => {
        this.setState({
            showModal: false,
            formErrors: {
                question: '',
                answer: '',
                language: '',
            }
        })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
        const { faqItem } = this.props;
        this.setState({
            id: faqItem.id,
            question: faqItem.question,
            answer: faqItem.answer,
            language: faqItem.localization,
        })
    }

    render() {
        const { question, answer } = this.state;

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyError();

        }
        else if (success === true) {
            alert("FAQ Updated Successfully")
            this.props.emptyError();

            this.closeModal();
            this.props.fetchAllFAQ();
        }

        return (
            <Modal id="modal"
                trigger={
                    <button className="acor-btn1" type="submit" onClick={this.openModal}>
                        Edit
                    </button>
                }

                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Edit FAQ</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">

                                    <div className="col">
                                        <label htmlFor="question" className="label">Question</label>
                                        <input
                                            dir="auto"
                                            className="form-control"
                                            fluid name="question"
                                            isRequired="true" id="question"
                                            onChange={this.handleChange}
                                            value={this.state.question} />
                                        <p style={{ color: "red" }}>{this.state.formErrors.question} </p>
                                        <br />


                                        <label htmlFor="answer" className="label">Answer</label>
                                        <textarea
                                            dir="auto"
                                            className="form-control"
                                            fluid rows="6" name="answer"
                                            isRequired="true" id="episode_description"
                                            onChange={this.handleChange} value={this.state.answer} />
                                        <p style={{ color: "red" }}>{this.state.formErrors.answer} </p>
                                        <br />

                                    </div>

                                </div>

                                <div className="input-field">

                                    <Button disabled={this.state.formErrors.question || this.state.formErrors.answer} onClick={this.handleSubmit} id="saveButton" >
                                        Save
                                    </Button>


                                </div>
                                {error && <p>{error.message}</p>}
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

EditFAQ.propTypes = {
    updateFAQ: propTypes.func.isRequired,
    fetchAllFAQ: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    faq: state.faq.item,
    showModal: state.faq.showModal,
    error: state.faq.updating_FAQ_error,
    success: state.faq.updating_FAQ__sucessfully
});


export default withFirebase(connect(mapStateToProps, { updateFAQ, emptyError, fetchAllFAQ })(EditFAQ));
