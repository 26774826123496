import React, { Component } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { Confirm } from "semantic-ui-react";
import branch from "branch-sdk";

/*stripe */
import { CardElement, injectStripe } from "react-stripe-elements";

/*Redux */
import "firebase/firestore";
import "firebase/auth";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { withFirebase } from "../../../config";
import firebase from "firebase/app";
/*Actions */
import {
  addStripePaymentWithCode,
  emptyPayment,
  clearMessage,
} from "../../../Actions/StripePaymentAction";
import { addSeriesIDtoRedirectionPage } from "../../../Actions/RedirectionPagesActions.js";
import { isItAutoRenewel } from "../../../Actions/MobilePaymentActions.js";
import {
  emptyMobileUserProfile,
  emptyMobileUserProfileWithItem,
} from "../../../Actions/MobilePaymentProfileAction";
import { getUserPaymentInfo } from "../../../Actions/MobilePaymentProfileAction";
import { clearPaymentData } from "../../../Actions/MobilePaymentActions";
import {
  getPromoCodeItemByName,
  emptyPromoCode,
  checkIfSavedPromoCode,
  getSavedPromoCode,
  emptyCodeFromRedux,
  deleteSavedPromoCode,
} from "../../../Actions/PromoCodesActions";
import { addSuccessMessage } from "../../../Actions/SuccessMessagesActions";
import {
  getCurrencyRate,
  emptyCurrencyRate,
} from "../../../Actions/CurrencyActions";

/*images */
import mainLogoM from "../../../images/portal/logo-big.png";
import visa from "../../../images/portal/visa-logo@2x.png";
import mastercard from "../../../images/portal/mastercard-logo@2x.png";

/*Components */
import Footer from "../Layout/Footer";
import Loader from "../Layout/Loader";
/** Routes */
import {
  PORTAL_SUCCESSFUL,
  PORTAL_UPGRADE_SUBSCRIPTION,
} from "../../../routes";

import { Link } from "react-router-dom";
import MasterCardLogo from "../../../images/portal/icons/mastercard-logo.svg";
import { JeelMasterCard } from "../../src/constants";

const INITIAL_STATE = {
  redirectionBack: false,
  checkedMessage: "",
  renewal: 1,
  errorMsg: "",
  message: "",
  codeNameFromRedux: "",
  checkIfCurrencyRateExist: 0,
  currency: 1,
  openPopup: false,
  checkSaved: false,
  errorDeletingMsg: "",
  checked: false,
};

class CreditCardPayment extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.emptyMobileUserProfileWithItem();
    if (firebase.auth().currentUser) {
      this.props.getUserPaymentInfo();
      this.props.checkIfSavedPromoCode();
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.codeName !== prevState.codeNameFromRedux) {
      if (
        props.codeName !== undefined &&
        props.codeName !== "" &&
        props.codeName !== null
      ) {
        props.getPromoCodeItemByName(props.codeName);
      } else {
        //  props.getSavedPromoCode();
      }
      return {
        codeNameFromRedux: props.codeName,
      };
    }
  }

  BRANCH_PURCHASE = () => {
    var event_and_custom_data = {};

    var content_items = [];

    var customer_event_alias = "";

    branch.logEvent(
      "PURCHASE",
      event_and_custom_data,
      content_items,
      customer_event_alias,
      function (err) {
      }
    );
  };

  handleRenew = (e) => {
    let value = e.target.checked;
    this.setState({ renewal: value });
  };

  submit = (ePrice, isChecked, ev) => {
    this.setState({ errorMsg: "" });
    const { promocode } = this.props;

    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          this.props.stripe
            .createToken({ type: "card", name: "test" })
            .then((data) => {
              // getting the paying data from redux
              const { payingData } = this.props;
              this.props.addStripePaymentWithCode(
                token,
                data.token.id,
                ePrice,
                payingData.expirationDate,
                payingData.paymentType,
                this.state.renewal,
                payingData.isWithOffer,
                promocode.uid,
                "Stripe"
              );
              //this.props.clearPaymentData();
            })
            .catch((error) => {
              alert("البيانات المُدخلة غير صحيحة، فضلًا أدخل بيانات البطاقة مرة أخرى")
              this.setState({
                errorMsg:
                  "البيانات المُدخلة غير صحيحة، فضلًا أدخل بيانات البطاقة مرة أخرى",
              });
            });
        });
    }
    ev.preventDefault();
  };

  handleClose = (e) => {
    this.setState({ openSnakBar: false });
  };

  callCurrecnyAPI(currency) {
    this.props.getCurrencyRate(currency);
    this.setState({ checkIfCurrencyRateExist: 1 });
  }

  handlePopupClose = (e) => {
    this.setState({ redirectionBack: true });
    e.preventDefault();
  };

  handlePopupConfirm = (e) => {
    this.props.deleteSavedPromoCode();
    e.preventDefault();
  };

  render() {
    let confirmText = (
      <div className="container">
        <span style={{ color: "red", paddingTop: "15px" }}>
          {this.state.errorDeletingMsg}
        </span>
        <br />
        <p>
          لديك رمز خصم محفوظ في حسابك كي يُفعَّل مع تجديدك القادم، وإذا قررت
          تجديد اشتراكك الآن ستفقد رمز الخصم.
        </p>
        <strong> هل أنت متأكد من تجديد اشتراكك الآن؟ </strong>
        <br />
      </div>
    );

    let confirmDeleteButton = <button class="ui primary button">تأكيد</button>;

    const { usersucess, userloading, usererror } = this.props;
    const { payingData, currentUserPaymentMethod } = this.props;
    const { feedback_messege, stripesuccess, stripeloading } = this.props;
    const { promocodesuccess, promocode, promocodeload , isPromoCodeWork} = this.props;
    const { errorDeletingCode, savedcodeDeletedSuccessfully } = this.props;
    const {
      successSavedCodeChecked,
      loadingSavedCodeChecked,
      errorSavedCodeChecked,
      savedcode,
    } = this.props;
    const [{ userPaymentProfile }, { renewal }] = [this.props, this.state];

    if (
      userPaymentProfile.paymentHistory !== undefined &&
      this.state.checked === false
    ) {
      if (
        (userPaymentProfile.paymentHistory === null ||
          userPaymentProfile.paymentHistory.length === 0) &&
        userPaymentProfile.currentPackagePaymentId !== undefined &&
        userPaymentProfile.currentPackagePaymentId === null
      ) {
        this.setState({ renewal: 1 });
      } else {
        this.setState({ renewal: userPaymentProfile.paymentAutoRenewal });
      }
      this.setState({ checked: true });
    }

    let feedback_messege1 = "";
    let codeDescription = "";
    let nPrice = payingData.cash;

    if (payingData === "") {
      return <Redirect to={PORTAL_UPGRADE_SUBSCRIPTION}> </Redirect>;
    }

    //// Check Stripe Payment Result
    if (
      stripesuccess === true &&
      feedback_messege !== "" &&
      feedback_messege === "true ,Success!"
    ) {
      //handle success of Payment Stripe
      let type = payingData.paymentType;
      if (type === "Monthly") {
        feedback_messege1 =
          "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة شهر";
      } else if(type == "Yearly") {
        feedback_messege1 =
          "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة عام كامل";
      } else {
        feedback_messege1 =
        "تهانينا! تم الدفع بنجاح، يمكنكم الآن الاستمتاع بتطبيق جيل لمدة أسبوع";
      }
      this.BRANCH_PURCHASE();
      this.props.clearMessage();
      // this.props.clearPaymentData();
      this.props.emptyMobileUserProfile();
      this.props.emptyCodeFromRedux();
      this.props.emptyCurrencyRate();
      this.props.emptyPromoCode();
      this.props.addSuccessMessage(feedback_messege1);
      return <Redirect to={PORTAL_SUCCESSFUL + `?name=${JeelMasterCard}`}> </Redirect>;
    } else if (
      feedback_messege !== "" &&
      feedback_messege !== "true ,Success! "
    ) {
      //Hanlde error in Stripe payment
      feedback_messege1 =
        "مع الأسف، لم تتم عملية الدفع بنجاح، " + "(" + feedback_messege + ")";
      this.setState({ errorMsg: feedback_messege1 });
      this.props.emptyPayment();
    }

    //// end payment
    //// default package
    var packageType = payingData.paymentType;
    var ePrice = 0;
    var eSPrice = 0;
    if (packageType === "Monthly") {
      ePrice = payingData.paymentCurrencyPackage.monthDiscountPrice;
      eSPrice = ePrice;
    } else if (packageType === "Yearly") {
      ePrice = payingData.paymentCurrencyPackage.yearDiscountPrice;
      eSPrice = ePrice;
    } else if(packageType === "Weekly") {
      ePrice = payingData.paymentCurrencyPackage.weekDiscountPrice;
      eSPrice = ePrice;
    }

    //// promo code
    if (
      promocodesuccess === true && isPromoCodeWork &&
      ((promocode !== null && promocode !== undefined && promocode !== "") ||
        promocode.uid !== undefined)
    ) {
      let text = "";
      var symbol = "USD";
      var type = "خصم";
      var amount = "";
      if (payingData.paymentCurrencyPackage !== undefined) {
        symbol =
          payingData.paymentCurrencyPackage.monthDiscountPriceConverted
            .currency;
      }
      const { currencyRate } = this.props;

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "EXTRA_TIME"
      ) {
        symbol = "أسابيع";
        type = "زيادة";
      }
      amount = promocode.discountAmount;

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "PERCENTAGE"
      ) {
        nPrice = payingData.cash - (amount * payingData.cash) / 100;
        nPrice = nPrice.toFixed(2);
        ePrice = ePrice - (amount * ePrice) / 100;
        ePrice = ePrice.toFixed(2);
      } else if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "FIXED"
      ) {
        // amount must be changed to the user country curreny
        if (
          this.state.checkIfCurrencyRateExist === 0 &&
          payingData.paymentCurrencyPackage !== undefined
        ) {
          this.callCurrecnyAPI(
            payingData.paymentCurrencyPackage.monthOriginalPriceConverted
              .currency
          );
        }
        nPrice = payingData.cash - amount * currencyRate;
        nPrice = nPrice.toFixed(2);
        ePrice = ePrice - amount;
        ePrice = ePrice.toFixed(2);
      }

      text = (
        <span>
          سعر الباقة بعد الخصم{" "}
          <span className="price-color">{nPrice + " " + symbol}</span> (ما يعادل{" "}
          <span className="price-color">{ePrice + "$"}</span>). سعر الباقة قبل
          الخصم:{" "}
          <span className="price-color">
            {payingData.cash.toFixed(2) + " " + symbol}
          </span>
        </span>
      );

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "EXTRA_TIME"
      ) {
        text = "سيتم تمديد الباقة " + amount + " أسابيع";
      }
    
      if (amount !== "" && amount !== undefined) {
        codeDescription = (
          <div class="form-card-content">
            <div class="card-discount checkout-message">{text}</div>
          </div>
        );
      }
    } else {
      codeDescription = "";
    }
    //// end promo code
    // loading button
    let buttonSubmitLoader = "";
    const CARD_ELEMENT_OPTIONS = {
      base: {
        fontSize: "16px",
        ":focus": {
          fontSize: "16px",
        },
      },
      invalid: {
        fontSize: "16px",
        ":focus": {
          fontSize: "16px",
        },
      },
    };
    let fieldsetContent = (
      <div>
        <label className="">رقم البطاقة</label>

        <div className="div-box custom-card-element">
          <div className="row">
            <div className="col-12">
              <div className="mo-package paymentltr ">
                <CardElement style={CARD_ELEMENT_OPTIONS} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const { loading } = this.props;

    let content = "";

    buttonSubmitLoader = (
      <button
        className="btn portal-main-btn checkout-pay-btn w-100"
        type="submit"
        onClick={this.submit.bind(this, ePrice, true)}
      >
        الدفع
      </button>
    );

    if (stripeloading === true) {
      buttonSubmitLoader = (
        <button className="btn btn-block ui loading secondary button"></button>
      );
    }
    content = (
      <span>
        <span>
          <div className="row">
            {/* <div className="col-12">
              <p style={{ color: "red", float: "right" }}>
                {" "}
                {this.state.errorMsg}{" "}
              </p>
            </div> */}
          </div>
          {fieldsetContent}
          <label className="checkbox mt-4 mb-4 small-font font">
            تجديد الباقة تلقائيا
            <input
              type="checkbox"
              id="automatic"
              className="main-checkbox"
              checked={this.state.renewal}
              onChange={this.handleRenew}
            />
            <span className="checkmark"></span>
          </label>

          {buttonSubmitLoader}
        </span>
      </span>
    );

    let price, weeklyPriceinDollar, monthlyPriceinDollar, yearlyPriceinDollar;
    if (payingData.paymentType === "Monthly") {
      if (
        payingData.paymentCurrencyPackage.monthDiscountPriceConverted
          .convertedPrice !==
        payingData.paymentCurrencyPackage.monthDiscountPrice
      ) {
        monthlyPriceinDollar = (
          <span>
            &nbsp; ما يعادل قيمته&nbsp;
            <span class="price">
              <span className="price-color">
                {payingData.paymentCurrencyPackage.monthDiscountPrice}$
              </span>
            </span>
            &nbsp; تقريباًً &nbsp;
          </span>
        );
      }
      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.monthDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.monthDiscountPriceConverted
                  .currency
              }
            </span>
          </span>
          {monthlyPriceinDollar}
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    } else if (payingData.paymentType === "Yearly") {
      if (
        payingData.paymentCurrencyPackage.yearDiscountPriceConverted !==
          undefined &&
        payingData.paymentCurrencyPackage.yearDiscountPriceConverted !== null
      ) {
        if (
          payingData.paymentCurrencyPackage.yearDiscountPriceConverted
            .convertedPrice !==
          payingData.paymentCurrencyPackage.yearDiscountPrice
        ) {
          yearlyPriceinDollar = (
            <span>
              &nbsp; ما يعادل قيمته&nbsp;
              <span class="price">
                <span className="price-color">
                  {payingData.paymentCurrencyPackage.yearDiscountPrice}$
                </span>
              </span>
              &nbsp; تقريباًً &nbsp;
            </span>
          );
        }
      }
      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.yearDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.yearDiscountPriceConverted
                  .currency
              }
            </span>
          </span>{" "}
          {yearlyPriceinDollar}
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    }
    else if (payingData.paymentType === "Weekly") {
      if (
        payingData.paymentCurrencyPackage.weekDiscountPriceConverted !==
          undefined &&
        payingData.paymentCurrencyPackage.weekDiscountPriceConverted !== null
      ) {
        if (
          payingData.paymentCurrencyPackage.weekDiscountPriceConverted
            .convertedPrice !==
          payingData.paymentCurrencyPackage.weekDiscountPrice
        ) {
          weeklyPriceinDollar = (
            <span>
              &nbsp; ما يعادل قيمته&nbsp;
              <span class="price">
                <span className="price-color">
                  {payingData.paymentCurrencyPackage.weekDiscountPrice}$
                </span>
              </span>
              &nbsp; تقريباًً &nbsp;
            </span>
          );
        }
      }
      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.weekDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.weekDiscountPriceConverted
                  .currency
              }
            </span>
          </span>{" "}
          {weeklyPriceinDollar}
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    }

    if (this.state.redirectionBack === true) {
      //Back to checkout Page
      return <Redirect to={PORTAL_UPGRADE_SUBSCRIPTION}> </Redirect>;
    }

    // if saved code and not viewed
    // show Modal
    if (
      successSavedCodeChecked === true &&
      savedcode !== undefined &&
      savedcode !== "" &&
      this.state.checkSaved === false
    ) {
      this.setState({ openPopup: true, checkSaved: true });
    }

    if (savedcodeDeletedSuccessfully === true) {
      this.setState({ openPopup: false });
      this.props.emptyPromoCode();
      if (
        this.state.codeNameFromRedux !== undefined &&
        this.state.codeNameFromRedux !== null &&
        this.state.codeNameFromRedux !== ""
      ) {
        this.props.getPromoCodeItemByName(this.state.codeNameFromRedux);
      }
    }

    if (
      errorDeletingCode !== undefined &&
      errorDeletingCode !== "" &&
      errorDeletingCode !== false
    ) {
      this.setState({ errorDeletingMsg: errorDeletingCode });
      this.props.emptyPromoCode();
    }

    if (
      userloading === true ||
      loadingSavedCodeChecked === true ||
      promocodeload === true
    ) {
      content = <Loader />;
    }
    return (
      <div>
        <Confirm
          id="modal"
          style={{ textAlign: "right" }}
          //content='لديك رمز خصم محفوظ في حسابك كي يُفعَّل مع تجديدك القادم، وإذا قررت تجديد اشتراكك الآن ستفقد رمز الخصم.'
          content={confirmText}
          confirmButton={confirmDeleteButton}
          cancelButton="تراجع"
          open={this.state.openPopup}
          onCancel={this.handlePopupClose}
          onConfirm={this.handlePopupConfirm}
        />

        <section className="main-payment custom-main-payment">
          <div className="container">
            <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
              <div className="d-flex align-items-center">
                <Link className="navbar-brand d-flex align-items-center" to="/">
                  <img src={MasterCardLogo} alt="" />
                </Link>
                <div className="complete-process d-none d-sm-block">
                  اكمال عملية الدفع
                </div>
              </div>
              <Link className="btn back-btn" type="button" to="/">
                العوده
              </Link>
            </div>
          </div>

          <div className="container checkout-card-container">
            {/*
            <div class="card-discount">
              سيتم خصم <span class="price"><strong>40 EGP</strong>
              </span> ما يعادل قيمته <span class="price">
                <strong>3$</strong>
                </span> تقريباًً من بطاقة الدفع الخاصة بك .. سعر الباقة قبل الخصم
                <span class="price instead-price"><strong>50 EGP</strong>
                </span>
            </div> */}

            {codeDescription ? codeDescription : price}
            <div className="pay-box checkout-card">
              <div className="card card-container Current-Package">
                <div className="card-header profile-name-card text-center d-flex align-items-center justify-content-between">
                  <div className="title">بيانات الدفع</div>
                  <div className="icons d-flex align-items-center">
                    <div className="icon">
                      <img src={mastercard} alt="" />
                    </div>
                    <div className="icon">
                      <img src={visa} alt="" />
                    </div>
                  </div>
                </div>
                <div className="card-body">{content}</div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
      </div>
    );
  }
}

CreditCardPayment.propTypes = {
  addStripePaymentWithCode: propTypes.func.isRequired,
  addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
  isItAutoRenewel: propTypes.func.isRequired,
  emptyMobileUserProfile: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  feedback_messege: state.stripePayment.payment_feedback_messege,
  stripeloading: state.stripePayment.loading,
  stripesuccess: state.stripePayment.payment_updated_successfully,

  payingData: state.mobilePaymentPackage.payingData,
  redirectionPage: state.redirectionPage.item,
  autoRenewel: state.mobilePaymentPackage.autoRenewel,
  token: state.user.token,

  userPaymentProfile: state.userPaymentProfile.item,
  userloading: state.userPaymentProfile.loading,
  usererror: state.userPaymentProfile.getting_user_payment_data_error,
  usersucess: state.userPaymentProfile.getting_user_payment_data_sucessfully,

  token: state.user.token,

  promocode: state.promoCodes.item,
  promocoderror: state.promoCodes.error_loading_code,
  promocodesuccess: state.promoCodes.get_code_sucessfully,
  promocodeload: state.promoCodes.loading,
  isPromoCodeWork: state.promoCodes.promoCodeWork,

  cancelSubscribtionSuccess:
    state.subscriptionProfile.user_payment_cancel_subscription_sucessfully,

  codeName: state.promoCodes.code_name,

  currencyRate: state.currencyRate.item,
  currencyRateSuccess: state.currencyRate.getting_rate_successfully,
  currencyRateError: state.currencyRate.error_getting_rate,

  savedcodeDeletedSuccessfully: state.promoCodes.code_deleted_successfully,
  errorDeletingCode: state.promoCodes.error_deleting_code,

  successSavedCodeChecked: state.promoCodes.get_saved_code_sucessfully,
  loadingSavedCodeChecked: state.promoCodes.loading_saved,
  errorSavedCodeChecked: state.promoCodes.error_loading_saved_code,
  savedcode: state.promoCodes.saved,
  currentUserPaymentMethod: state.paymentData.paymentMethod,
});

export default injectStripe(
  withFirebase(
    connect(mapStateToProps, {
      addStripePaymentWithCode,
      emptyPayment,
      clearMessage,
      addSeriesIDtoRedirectionPage,
      isItAutoRenewel,
      emptyMobileUserProfile,
      emptyMobileUserProfileWithItem,
      getUserPaymentInfo,
      emptyPromoCode,
      getPromoCodeItemByName,
      clearPaymentData,
      checkIfSavedPromoCode,
      getSavedPromoCode,
      addSuccessMessage,
      emptyCodeFromRedux,
      getCurrencyRate,
      emptyCurrencyRate,
      deleteSavedPromoCode,
    })(CreditCardPayment)
  )
);
