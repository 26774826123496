import {
    ERROR_GET_PAYMENT_PRICE_BY_COUNTRY ,
    GET_PAYMENT_PRICE_BY_COUNTRY,
    LOAD_PAYMENT_PRICE_BY_COUNTRY,
    EMPTY_PAYMENT_PRICE_BY_COUNTRY
}from './Types';

import { post } from 'axios';

export const getPackagePriceBycountry = (country, token) => {

    return (dispatch, getState, { getFirebase }) => {
        
            dispatch({
                type:LOAD_PAYMENT_PRICE_BY_COUNTRY,
            })

            const url = "/PaymentHandlingPrices/getPaymentPackageByCountry"
            const formData = new FormData();
            formData.append('Country_Name', country);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_PAYMENT_PRICE_BY_COUNTRY,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_GET_PAYMENT_PRICE_BY_COUNTRY,
                    payload: Error.message
                })
            })

        

    }
}
 

export const emptyPaymentPrice = () => dispatch => {
 
    dispatch({
        type: EMPTY_PAYMENT_PRICE_BY_COUNTRY,
        payload: ''
    })
}