import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
/**Actions */
import {
    fetchAllInAppNotification,
    emptyError,
    deleteInAppNotification
} from '../../Actions/InAppNotificationAction'


class DeleteInAppNotification extends Component {
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteInAppNotification(id) {
        this.props.deleteInAppNotification(id);
    }

    handleSubmit = (e) => {

        const { id } = this.props;
        this.deleteInAppNotification(id);
        this.close();

    }
    render() {
        const { id } = this.props;

        let message = 'Are you sure you want to delete this In App Notification '
            + id + ' ??'

        return (
            <span >
                <Popup trigger=
                    {
                        <a class="trash" onClick={this.open}>
                            <i><FontAwesomeIcon icon="trash" /></i>
                        </a>
                    }
                    content='Delete'
                    position='top center'
                />

                <Confirm id="modal"
                    content={message}
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </span>

        )
    }
}



DeleteInAppNotification.propTypes = {
    fetchAllInAppNotification: propTypes.func.isRequired,
    deleteInAppNotification: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    loading: state.inAppNotification.loading,
    error: state.inAppNotification.delete_inAppNotification_error,
    success: state.inAppNotification.delete_inAppNotification_successfully,

});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                fetchAllInAppNotification,
                emptyError,
                deleteInAppNotification
            })
        (DeleteInAppNotification)
    );

