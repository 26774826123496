import firebase from 'firebase/app';
import 'firebase/auth'

const config = {
    apiKey: "AIzaSyAQdp8uKo8vQstE-9wPb6NJ-Bc2tgbYVHk",
    authDomain: "mobidevlabsgen.firebaseapp.com",
    databaseURL: "https://mobidevlabsgen.firebaseio.com",
    projectId: "mobidevlabsgen",
    storageBucket: "mobidevlabsgen.appspot.com",
    messagingSenderId: "207655697394"

}

firebase.initializeApp(config);
export default firebase;