import React, { useEffect , useState} from "react";
import mainLogo from "../../images/portal/icons/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import googlePlay from "../../images/portal/icons/header-googlePlay.svg";
import appGallery from "../../images/portal/icons/header-appGallery.svg";
import appStore from "../../images/portal/icons/header-appStore.svg";
import checked from "../../images/portal/icons/checked.svg";

import { useDispatch } from "react-redux";
import { empty } from "../../Actions/AuthActions";
import {
  emptyMobileUserProfileWithItem,
  emptyUserProfileWithItem,
} from "../../Actions/MobilePaymentProfileAction";
import { emptyPromoCode } from "../../Actions/PromoCodesActions";
import { useHistory,  BrowserRouter as Router, Switch, Route, Link, useParams, useLocation } from "react-router-dom";
import { PORTAL_LAND_PAGE } from "../../routes";
import {
  appGalleryLink,
  appleAppLink,
  googlePlayLink,
  JeelMADA,
  JeelMasterCard,
  webAppLink,
} from "./constants";
import web from "../../images/portal/icons/web.png";
import { emptyPaymentStatus } from "../../Actions/HyperPayAction";
import { emptyFawryPay } from "../../Actions/FawryPayAction";


function Successful() {

  let query = new URLSearchParams(useLocation().search);
  let name = query.get("name");

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(()=>{
      
    dispatch(emptyPaymentStatus());
    dispatch(emptyFawryPay());

  },[])

  const handleRedux = () => {
    dispatch(emptyMobileUserProfileWithItem());
    dispatch(empty());
    dispatch(emptyUserProfileWithItem());
    dispatch(emptyPromoCode());
    history.push(PORTAL_LAND_PAGE);
  };

  return (
    <>
      <div className="container">
        <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
          <div className="d-flex align-items-center flex-column center-logo">
            <Link className="navbar-brand d-flex align-items-center" to="/">
              <img src={mainLogo} alt="" />
            </Link>
            <div className="already-subscribed mt-2">انت من عائلة جيل الان</div>
          </div>
          <button
            className="btn back-orange-btn"
            type="button"
            onClick={handleRedux}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="faChevronLeft" />
          </button>
        </div>

        <div className="successful-container">
          <div className="successful-checked-icon">
            <img src={checked} alt="" />
          </div>

          <div className="successful-title">
           
           تم الاشتراك بنجاح في جيل</div>
          
      
          <div className="thank-you">شكرا لك</div>
          <div className="successful-download-now">
            <div className="download-now-title text-center mb-4">
              حمل التطبيق الان
            </div>
            <div className="successful-download mb-4 flex-column flex-sm-row d-flex align-items-center justify-content-center">
              <div className="successful-link mb-3 mb-sm-0">
                <a
                  className="header-icon"
                  href={googlePlayLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={googlePlay} alt="/" />
                </a>
              </div>
              <div className="successful-link mb-3 mb-sm-0">
                <a
                  className="header-icon"
                  href={appGalleryLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appGallery} alt="/" />
                </a>
              </div>
              <div className="successful-link mb-3 mb-sm-0">
                <a
                  className="header-icon"
                  href={appleAppLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appStore} alt="/" />
                </a>
              </div>
            </div>
            <div className="download-now-title text-center mb-4">
              أو استمتع بتطبيق جيل على ويب
            </div>
            <div className="header-download mb-4 flex-column flex-sm-row d-flex align-items-center justify-content-center">
              <div className="header-link mb-3 mb-sm-0">
                <a
                  className="header-icon"
                  href={webAppLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={web} alt="Jeel Web App" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Successful;
