import {

    FETCH_All_SPECIAL_COUNTRIES_PRICE,
    ERROR_FETCH_All_SPECIAL_COUNTRIES_PRICE,

    LOAD_SPECIAL_COUNTRIES_PRICE,

    ADD_SPECIAL_COUNTRIES_PRICE,
    ERROR_ADD_SPECIAL_COUNTRIES_PRICE,

    DELETE_SPECIAL_COUNTRIES_PRICE,
    ERROR_DELETE_SPECIAL_COUNTRIES_PRICE,

    UPDATE_SPECIAL_COUNTRIES_PRICE,
    ERROR_UPDATE_SPECIAL_COUNTRIES_PRICE,

    EMPTY_SPECIAL_COUNTRIES_PRICE
} from './Types'

import axios, { post } from 'axios'


export const fetchAllSpecialCountriesPrice = () => dispatch => {

    dispatch({
        type: LOAD_SPECIAL_COUNTRIES_PRICE,
        payload: 'loading'
    })

    axios.get('/PaymentSpecialCountriesOffer/getAllPaymentSpecialCountriesOffer')
        .then((res) => {
            dispatch({
                type: FETCH_All_SPECIAL_COUNTRIES_PRICE,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_FETCH_All_SPECIAL_COUNTRIES_PRICE,
                payload: Error.message
            })
        })
}


export const saveSpecialCountriesPrice = (country,
    description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice) => {

    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SPECIAL_COUNTRIES_PRICE,
                payload: 'loading'
            })
            const url = "/PaymentSpecialCountriesOffer/saveCountryWithSpecialPaymentOffer";
            const formData = new FormData();
            formData.append('Country', country);
            formData.append('Description', description);
            formData.append('Weekly_Discount_Price', weeklyDiscountPrice);
            formData.append('Monthly_Discount_Price', monthlyDiscountPrice);
            formData.append('Yearly_Discount_Price', yearlyDiscountPrice);
            formData.append('Weekly_Basic_Price', weeklyBasicPrice);
            formData.append('Monthly_Basic_Price', monthlyBasicPrice);
            formData.append('Yearly_Basic_Price', yearlyBasicPrice);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: ADD_SPECIAL_COUNTRIES_PRICE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADD_SPECIAL_COUNTRIES_PRICE,
                    payload: Error.response.data.message
                })
            })
        })

    }
}


export const updateSpecialCountriesPrice = (id, country,
    description, weeklyDiscountPrice, monthlyDiscountPrice, yearlyDiscountPrice, weeklyBasicPrice, monthlyBasicPrice, yearlyBasicPrice) => {

    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SPECIAL_COUNTRIES_PRICE,
                payload: 'loading'
            })
            const url = "/PaymentSpecialCountriesOffer/updateCountryWithSpecialPaymentOffer";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Country', country);
            formData.append('Description', description);
            formData.append('Weekly_Discount_Price', weeklyDiscountPrice);
            formData.append('Monthly_Discount_Price', monthlyDiscountPrice);
            formData.append('Yearly_Discount_Price', yearlyDiscountPrice);
            formData.append('Weekly_Basic_Price', weeklyBasicPrice);
            formData.append('Monthly_Basic_Price', monthlyBasicPrice);
            formData.append('Yearly_Basic_Price', yearlyBasicPrice);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_SPECIAL_COUNTRIES_PRICE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_SPECIAL_COUNTRIES_PRICE,
                    payload: Error.response.data.message
                })
            })
        })

    }
}

export const deleteSpecialCountriesPrice = (id) => {

    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_SPECIAL_COUNTRIES_PRICE,
                payload: 'loading'
            })
            const url = "/PaymentSpecialCountriesOffer/deleteCountryWithSpecialPaymentOffer";
            const formData = new FormData();
            formData.append('Country_With_Special_Offer_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETE_SPECIAL_COUNTRIES_PRICE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETE_SPECIAL_COUNTRIES_PRICE,
                    payload: Error.response.data.message
                })
            })
        })

    }
}


export const emptySpeciialCountryOffer = () => dispatch => {
    console.log("emptySpeciialCountryOffer")

    dispatch({
        type: EMPTY_SPECIAL_COUNTRIES_PRICE,
        payload: ''
    })
}