import { connect } from 'react-redux'
import React, { Component } from 'react'
import { getSubscriptionsReport, emptyReport  } from "../../Actions/SubscriptionsReportActions";
import { getTotalPrice,emptySubscriptionReportByCountry} from "../../Actions/CountrySubscriptionsReportActions"
import { getCountForSubscriptionByCountry ,emptyCountForSubscriptionByCountry } from '../../Actions/CountryCountSubscriptionsReportActions';

import { getAllCurrenyProfits  } from "../../Actions/ProfitShareActions";

import { DateInput } from 'semantic-ui-calendar-react';

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';


import ProfitShareTabel from './ProfitShareTabel';

 




class ProfitShare extends Component {
    constructor(props) {
        super(props);
        const today = new Date();
        const eMonth = today.getMonth() + 1;
        const eDay = today.getDate();
        const eYear = today.getFullYear();
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        const sMonth = oneWeekAgo.getMonth() + 1;
        const sDay = oneWeekAgo.getDate();
        const sYear = oneWeekAgo.getFullYear();
        
        this.state = {
            startDate:  `${sMonth}/${sDay}/${sYear}`,
            endDate: `${eMonth}/${eDay}/${eYear}`   
        };
      }
    
          
       

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    handleDateChange = (e, { name, value }) => {
        if (this.state.hasOwnProperty(name)) 
        {
            this.setState({ [name]: value });
            if (name === "startDate")
            {
                if (this.state.endDate)
               
               { 
					this.props.getAllCurrenyProfits(this.state.startDate, this.state.endDate);
	           }              
            }
            if (name ==="endDate")
            {
                if (this.state.startDate)
                { 
					this.props.getAllCurrenyProfits(this.state.startDate, this.state.endDate);
                }              
            }
        }
        
    }

   

    componentDidMount()
    {
        //this.props.emptyCountForSubscriptionByCountry();
        this.props.getAllCurrenyProfits(this.state.startDate, this.state.endDate);
    }
    

    render() {
        //const {loading,loadingCountries ,loadingCount ,subsReport,subsReportByCountry,CountsubsReportByCountry,errorLoadingSubscriptionsReport, errorLoadingSubscriptionsReportByCountry ,errorLoadingCountSubscriptionsReportByCountry} = this.props;
        
        const {profitShareList,fetchProfitShareListSuccessfully,fetchProfitShareListError} = this.props;
        
        //console.log('prooooofitshare: '+JSON.stringify(this.props,null,2));
        
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                           
                                            <div className="box-container">                                                
                                                

                                                <h4 className="main-title mt-2 font-weight-bold">
                                                    Profit Share </h4>
                                                <div className="card mt-3 p-0">
                                                
<div>
                <div id="tab03" className="tab-contents details-table">
                    <form onSubmit={(e)=> e.preventDefault()}>
                        <div className="form-row mt-2">
                            <div className="col-md-4 col-12 mb-3">
                                <label className="small-label" for="">Start Date</label>
                                <DateInput
                                    name="startDate"
                                    closeOnMouseLeave

                                    value={this.state.startDate}
                                    placeholder="start date"
                                    onChange={this.handleDateChange}
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                />
                            </div>
                            <div className="col-md-4 col-12 mb-3">
                                <label className="small-label" for="">End Date</label>
                                <DateInput
                                    name="endDate"
                                    closeOnMouseLeave

                                    value={this.state.endDate}
                                    placeholder="end date"
                                    onChange={this.handleDateChange}
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                />                 
                             </div>
                        </div>
                    </form>                                      
                </div>
            </div>
            
            
                                                            
                                                
                                                
                                                
                                                    <div className="card-body p-4">
                                                        {/* <!-- start form --> */}
                                                        <form className="alluserMng" onSubmit={(e)=> e.preventDefault()}>
                                                            <div className="mt-4 responsive-table">
                                                                {/* Admins Table */}
                                                                <ProfitShareTabel  profitShareList={profitShareList} fetchProfitShareListSuccessfully={fetchProfitShareListSuccessfully} fetchProfitShareListError={fetchProfitShareListError} />
                                                            </div>
                                                        </form>
                                                    </div>                                                                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
                
    }
}
const mapStateToProps = state => ({

    profitShareList: state.profitShareReducer.State_items,
    fetchProfitShareListSuccessfully : state.profitShareReducer.State_Profit_Share_Load_sucessfully,
    fetchProfitShareListError : state.profitShareReducer.State_Profit_Share_Load_Error,
    //errorLoadingSubscriptionsReport: state.subscriptionsReport.error_loading_subscriptions_report,
    //loading:state.subscriptionsReport.loading,
    //subsReportByCountry: state.countrySubscriptionReport.reports,
    //errorLoadingSubscriptionsReportByCountry: state.countrySubscriptionReport.error_loading_subscriptions_report_by_country,
    //loadingCountries :state.countrySubscriptionReport.loading,
    //CountsubsReportByCountry: state.countryCountSubscriptionReport.counts,
    //errorLoadingCountSubscriptionsReportByCountry: state.countryCountSubscriptionReport.error_loading_count_subscriptions_report_by_country,
    //loadingCount :state.countryCountSubscriptionReport.loading
      

})
export default connect(mapStateToProps, { getAllCurrenyProfits })(ProfitShare);