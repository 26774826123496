import {
    ADD_SUCCESS_MESSAGE,
    REMOVE_SUCCESS_MESSAGE,
} from '../Actions/Types'

const initialState = {
    item: {},

    loading: '',
    getting_message_error: '',
    getting_message_sucessfully: '',

}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_SUCCESS_MESSAGE:
            return {
                ...state,
                item: action.payload,
                getting_message_error: '',
                getting_message_sucessfully: true,
                loading: false
            }

        case REMOVE_SUCCESS_MESSAGE:
            return {
                ...state,
                item: action.payload,
                getting_message_error: '',
                getting_message_sucessfully: true,
                loading: false
            }

        default:
            return state
    }

}
