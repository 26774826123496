import React, { Component } from 'react'
import Loader from '../Layout/Loader';
import PieChart from 'react-minimal-pie-chart';

export default class CountryFilter extends Component {

    state = {
        totalSessionsPerDayValues: [],
        totalTimeUsagePerDayValues :[],
        averageSessionTimeValues:[]
    }

   


    componentWillReceiveProps(nextProp) {
        let totalSessionsPerDayValues = [];
        let totalTimeUsagePerDayValues =[];
        let averageSessionTimeValues = []
        for (let i = 0; i < nextProp.countryFilter.length; i++) {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var j = 0; j < 6; j++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            if (nextProp.countryFilter[i].kidUsageReport !== null) {
                let pie = { title: nextProp.countryFilter[i].country, value: nextProp.countryFilter[i].kidUsageReport.totalSessionsPerDay, color: color }
                let pieUsage = { title: nextProp.countryFilter[i].country, value: nextProp.countryFilter[i].kidUsageReport.totalTimeUsagePerDay, color: color }
                let average = {title:nextProp.countryFilter[i].country,value:nextProp.countryFilter[i].kidUsageReport.averageSessionTime,color:color}
                totalSessionsPerDayValues.push(pie)
                totalTimeUsagePerDayValues.push(pieUsage)
                averageSessionTimeValues.push(average)
            }
        }

        this.setState({totalSessionsPerDayValues:totalSessionsPerDayValues,totalTimeUsagePerDayValues:totalTimeUsagePerDayValues,averageSessionTimeValues:averageSessionTimeValues})
        // console.log(this.state)
    }

    render() {
        // console.log(this.state)
        const { kidsUsage, error_loading, loading, countryFilter, error_loading_country_filter, loading_country_filter } = this.props
        const {countries} = this.props;
        // console.log(this.props)

        let dataa = null;

        if (loading_country_filter === true || loading === true) {
            console.log("loading")
            dataa = <Loader />
        }
        else if (countries.length===0)
        {
            dataa =  <div class="row t-row">
            <div class="col-12 head-row small-font">
                Country
<hr />
            </div>
            <div class="col-md-4">
                <div class="h2 font-weight-bold text-center p-3"><PieChart
                    data={this.state.totalSessionsPerDayValues}
                  label={({ data, dataIndex }) =>
                        Math.round(data[dataIndex].percentage) + '%'
                    } labelStyle={{
                        fontSize: '5px',
                        fontFamily: 'sans-serif',
                        fill: '#121212'
                    }}
                /></div>
            </div>
            <div class="col-md-4">
                <div class="h2 font-weight-bold text-center p-3"><PieChart
                    data={this.state.totalTimeUsagePerDayValues}
                    label={({ data, dataIndex }) =>
                        Math.round(data[dataIndex].percentage) + '%'
                    } labelStyle={{
                        fontSize: '5px',
                        fontFamily: 'sans-serif',
                        fill: '#121212'
                    }}
                /></div>
            </div>
            <div class="col-md-4">
                <div class="h2 font-weight-bold text-center p-3"><PieChart
                    data={this.state.averageSessionTimeValues}
                    label={({ data, dataIndex }) =>
                        Math.round(data[dataIndex].percentage) + '%'
                    } labelStyle={{
                        fontSize: '5px',
                        fontFamily: 'sans-serif',
                        fill: '#121212'
                    }}
                /></div>
            </div>
        </div>
        }
        else if (countryFilter.length > 0 && error_loading_country_filter === '' && countries.length>0) {
            console.log(countryFilter.length)

            if (loading_country_filter === false) {
                if (countryFilter.length < 2) {
                    // console.log(true)

                    if (countryFilter[0].kidUsageReport !== undefined) {
                        console.log((countryFilter[0]))
                        dataa = <div class="row t-row">
                            <div class="col-12 head-row small-font">
                                Country
                         <hr />
                            </div>
                            <div class="col-md-4">
                                <div class="h2 font-weight-bold text-center p-3">{countryFilter[0].kidUsageReport.totalSessionsPerDay}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="h2 font-weight-bold text-center p-3">{countryFilter[0].kidUsageReport.totalTimeUsagePerDay}</div>
                            </div>
                            <div class="col-md-4">
                                <div class="h2 font-weight-bold text-center p-3">{countryFilter[0].kidUsageReport.averageSessionTime}</div>
                            </div>
                        </div>
                    }
                }
                else {
                    dataa = <div class="row t-row">
                        <div class="col-12 head-row small-font">
                            Country
<hr />
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3"><PieChart
                                data={this.state.totalSessionsPerDayValues}
                                label={({ data, dataIndex }) =>
                                    Math.round(data[dataIndex].percentage) + '%'
                                } labelStyle={{
                                    fontSize: '5px',
                                    fontFamily: 'sans-serif',
                                    fill: '#121212'
                                }}
                            /></div>
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3"><PieChart
                                data={this.state.totalTimeUsagePerDayValues}
                                label={({ data, dataIndex }) =>
                                    Math.round(data[dataIndex].percentage) + '%'
                                } labelStyle={{
                                    fontSize: '5px',
                                    fontFamily: 'sans-serif',
                                    fill: '#121212'
                                }}
                            /></div>
                        </div>
                        <div class="col-md-4">
                            <div class="h2 font-weight-bold text-center p-3"><PieChart
                                data={this.state.averageSessionTimeValues}
                                label={({ data, dataIndex }) =>
                                    Math.round(data[dataIndex].percentage) + '%'
                                } labelStyle={{
                                    fontSize: '5px',
                                    fontFamily: 'sans-serif',
                                    fill: '#121212'
                                }}
                            /></div>
                        </div>
                    </div>
                }
            }
        }
       
        console.log(this.props)
        return (
            <div>
                {dataa}
            </div>


        )
    }
}
