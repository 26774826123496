import axios, { post } from 'axios';
import firebase from 'firebase/app';

export const uploadImageProfileToW3s = (file) => {
  return new Promise (function(resolve,reject) {
      const url = 'Child/uploadChildImageToW3s';
      let token = firebase.auth().currentUser.h.b;
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
            'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
            'X-Authorization-Firebase': token
        },
      }
      post(url, formData, config).then((res) => {
          resolve(res);
          return res;
      }).catch((Error)=>{
          reject(Error)
          return Error.response.data.message
      })
  })
}
