import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Label } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom'

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';

/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
/*component */
import Loader from '../Layout/Loader';
/*Actions */
import {
    fetchAllPaymentPackage, emptyError,
    updateAllPaymentPackage
} from '../../Actions/MobilePaymentActions'
import { fetchAllSpecialCountriesPrice }
    from '../../Actions/MobileSpecialCountriesAction'
import { fetchAllPaymentCountries, updatePaymentCountries, clearUpdateEvent } from '../../Actions/PaymentCountriesActions'
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE } from '../../routes';


const INITIAL_STATE = {
    weeklyFawryDiscountPrice: '',
    monthlyFawryDiscountPrice: '',
    yearlyFawryDiscountPrice: '',

    weeklyMadaDiscountPrice: '',
    monthlyMadaDiscountPrice: '',
    yearlyMadaDiscountPrice: '',

    weeklyFawryBasicPrice: '',
    monthlyFawryBasicPrice: '',
    yearlyFawryBasicPrice: '',

    weeklyMadaBasicPrice: '',
    monthlyMadaBasicPrice: '',
    yearlyMadaBasicPrice: ''
}


class PaymentCountries extends Component {

    constructor(props) {
        super(props)
        this.state = INITIAL_STATE
    }

    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {

            this.props.fetchAllPaymentCountries();

        }
    }

    componentWillReceiveProps(nextprop) {
        let paymentCountriesPackage = nextprop.paymentCountriesList;
        let paymentCountriesPackageList = '';


        if (paymentCountriesPackage !== null && paymentCountriesPackage !== undefined) {

            paymentCountriesPackage = paymentCountriesPackage.length ? (
                paymentCountriesPackage.map(paymentCountriesPackage => {

                    if (paymentCountriesPackage.paymentPayingMethodsId.name === 'FAWRY'
                    ) {
                        this.setState({
                            weeklyFawryDiscountPrice: paymentCountriesPackage.weekDiscountPrice,
                            monthlyFawryDiscountPrice: paymentCountriesPackage.monthDiscountPrice,
                            yearlyFawryDiscountPrice: paymentCountriesPackage.yearDiscountPrice,

                            weeklyFawryBasicPrice: paymentCountriesPackage.weekBasicPrice,
                            monthlyFawryBasicPrice: paymentCountriesPackage.monthBasicPrice,
                            yearlyFawryBasicPrice: paymentCountriesPackage.yearBasicPrice,
                        })
                    }
                    else if (paymentCountriesPackage.paymentPayingMethodsId.name === 'MADA'
                    ) {
                        this.setState({
                            weeklyMadaDiscountPrice: paymentCountriesPackage.weekDiscountPrice,
                            monthlyMadaDiscountPrice: paymentCountriesPackage.monthDiscountPrice,
                            yearlyMadaDiscountPrice: paymentCountriesPackage.yearDiscountPrice,

                            weeklyMadaBasicPrice: paymentCountriesPackage.weekBasicPrice,
                            monthlyMadaBasicPrice: paymentCountriesPackage.monthBasicPrice,
                            yearlyMadaBasicPrice: paymentCountriesPackage.yearBasicPrice,
                        })
                    }

                })) : (
                <div className="center">No Payment Package </div>
            )
        }

    }

    handleChange = e => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    };

    handleSaveFawryPrices = e => {

        const { weeklyFawryDiscountPrice, monthlyFawryDiscountPrice, yearlyFawryDiscountPrice, weeklyFawryBasicPrice, monthlyFawryBasicPrice, yearlyFawryBasicPrice } = this.state;

        const { paymentCountriesList } = this.props;
        if (paymentCountriesList !== null && paymentCountriesList !== undefined) {

            let id;
            for (let i = 0; i < paymentCountriesList.length; i++) {
                if (paymentCountriesList[i].paymentPayingMethodsId.name === 'FAWRY') {
                    id = paymentCountriesList[i].id;
                    paymentCountriesList[i].weeklyFawryDiscountPrice = weeklyFawryDiscountPrice;
                    paymentCountriesList[i].monthlyFawryDiscountPrice = monthlyFawryDiscountPrice;
                    paymentCountriesList[i].yearlyFawryDiscountPrice = yearlyFawryDiscountPrice;

                    paymentCountriesList[i].weeklyFawryBasicPrice = weeklyFawryBasicPrice;
                    paymentCountriesList[i].monthlyFawryBasicPrice = monthlyFawryBasicPrice;
                    paymentCountriesList[i].yearlyFawryBasicPrice = yearlyFawryBasicPrice;
                    break;
                }
            }
            this.props.updatePaymentCountries(id, weeklyFawryDiscountPrice, monthlyFawryDiscountPrice, yearlyFawryDiscountPrice, weeklyFawryBasicPrice, monthlyFawryBasicPrice, yearlyFawryBasicPrice);

            e.preventDefault();
        }
    }
    handleSaveMadaPrices = e => {

        const { weeklyMadaDiscountPrice, monthlyMadaDiscountPrice, yearlyMadaDiscountPrice, weeklyMadaBasicPrice, monthlyMadaBasicPrice, yearlyMadaBasicPrice } = this.state;

        const { paymentCountriesList } = this.props;
        if (paymentCountriesList !== null && paymentCountriesList !== undefined) {

            let id;
            for (let i = 0; i < paymentCountriesList.length; i++) {
                if (paymentCountriesList[i].paymentPayingMethodsId.name === 'MADA') {
                    id = paymentCountriesList[i].id;
                    paymentCountriesList[i].weeklyMadaDiscountPrice = weeklyMadaDiscountPrice;
                    paymentCountriesList[i].monthlyMadaDiscountPrice = monthlyMadaDiscountPrice;
                    paymentCountriesList[i].yearlyMadaDiscountPrice = yearlyMadaDiscountPrice;

                    paymentCountriesList[i].weeklyMadaBasicPrice = weeklyMadaBasicPrice;
                    paymentCountriesList[i].monthlyMadaBasicPrice = monthlyMadaBasicPrice;
                    paymentCountriesList[i].yearlyMadaBasicPrice = yearlyMadaBasicPrice;
                    break;
                }
            }
            this.props.updatePaymentCountries(id, weeklyMadaDiscountPrice, monthlyMadaDiscountPrice, yearlyMadaDiscountPrice, weeklyMadaBasicPrice, monthlyMadaBasicPrice, yearlyMadaBasicPrice);

            e.preventDefault();
        }
    }

    render() {


        const { updateError, updateSucess, loading } = this.props;


        if (updateSucess === true && loading !== true) {
            alert("Payment Package Prices Updated Successfully")
            this.props.fetchAllPaymentCountries();
        }

        if (loading !== true && (updateError !== '' && updateError !== ""
            && updateError !== null && updateError !== undefined)) {
            alert(updateError)
            this.props.fetchAllPaymentCountries();

        }





        let loadingFawry = ''
        if (loading === true) {
            loadingFawry = (<Loader />)
        }
        else {
            loadingFawry = (
                <span>
                    <div className="ui form">
                        <h4 className="ui dividing header">Pricing</h4>
                        <div className="two fields">
                            <div className="field">
                                <Label> Weekly Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Week Fawry Price"
                                    name="weeklyFawryDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.weeklyFawryDiscountPrice} />
                            </div>
                            <div className="field">
                                <Label> Monthly Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Month Fawry Price"
                                    name="monthlyFawryDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.monthlyFawryDiscountPrice} />
                            </div>
                            <div className="field">
                                <Label> Yearly Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Yearly Fawry Price"
                                    name="yearlyFawryDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.yearlyFawryDiscountPrice} />
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={this.handleSaveFawryPrices}
                        id='contentSettingbutton'>Save</Button>

                </span>
            )
        }

        let loadingMada = ''
        if (loading === true) {
            loadingMada = (<Loader />)
        }
        else {
            loadingMada = (
                <span>
                    <div className="ui form">
                        <h4 className="ui dividing header">Pricing</h4>
                        <div className="two fields">
                            <div className="field">
                                <Label> Weekly Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Week Mada Price"
                                    name="weeklyMadaDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.weeklyMadaDiscountPrice} />
                            </div>
                            <div className="field">
                                <Label> Monthly Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Month Mada Price"
                                    name="monthlyMadaDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.monthlyMadaDiscountPrice} />
                            </div>
                            <div className="field">
                                <Label> Yearly Discount Price</Label>
                                <input type="number"
                                    step="0.01"
                                    min="0"
                                    placeholder="Yearly Mada Price"
                                    name="yearlyMadaDiscountPrice"
                                    onChange={this.handleChange}
                                    value={this.state.yearlyMadaDiscountPrice} />
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={this.handleSaveMadaPrices}
                        id='contentSettingbutton'>Save</Button>

                </span>
            )
        }


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">


                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> {'>'} </span>
                                                    </span>
                                                    <span>PaymentCountries</span>
                                                </div>
                                                {/* <!--  end status bar --> */}


                                                <div className="container">
                                                    <span className="general-setting-form"
                                                    >
                                                        <fieldset className='fieldset'>
                                                            <legend className="legend">Fawry Payment Package Price</legend>

                                                            {loadingFawry}

                                                        </fieldset>
                                                        <fieldset className='fieldset'>
                                                            <legend className="legend">Mada Payment Package Price</legend>

                                                            {loadingMada}

                                                        </fieldset>


                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}



const mapStateToProps = state => ({
    loading: state.paymentCountriesPackage.loading,
    error: state.paymentCountriesPackage.getting_prices_error,
    sucess: state.paymentCountriesPackage.getting_prices_sucessfully,
    paymentCountriesList: state.paymentCountriesPackage.items,
    updateError: state.paymentCountriesPackage.update_payment_package_data_error,
    updateSucess: state.paymentCountriesPackage.update_payment_package_data_sucessfully,
    auth: state.firebase.auth,

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                updatePaymentCountries,
                fetchAllPaymentCountries,
                clearUpdateEvent
            }
        )
        (PaymentCountries)
    );
