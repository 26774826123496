import React, { Component } from "react";
/* Redux */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  blog,
  subscribe,
  questions,
  faq,
  content,
  install,
  feature,
  about,
} from "../constants";
/** Assets*/
import mainLogo from "../../../images/portal/icons/logo.svg";

const INITIAL_STATE = {
  sideNav: false,
};

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  toggleSideNav = () => {
    let { sideNav } = this.state;
    sideNav = !sideNav;
    this.setState({ sideNav });
  };

  render() {
    return (
      <span className="parent-navebar">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={mainLogo} alt="" />
            </a>
            <button
              className={`navbar-toggler ${this.state.sideNav ? "show" : ""}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleSideNav}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              id="navbarNav"
              className={`collapse navbar-collapse ${
                this.state.sideNav ? "show" : ""
              }`}
            >
              <div className="flex-column flex-lg-row d-flex align-items-start align-items-lg-center justify-content-between w-100">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={about}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      عن جيل
                      <span className="sr-only">(current)</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={feature}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      المميزات
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={install}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      التحميل
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={content}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      المحتوي
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={faq}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      الأسئلة الشائعة
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={questions}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      أسئلة جيل
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href={blog}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      مدونة جيل
                    </a>
                  </li>
                </ul>
                <div className="nav-actions d-flex align-items-start align-items-lg-center justify-content-start justify-content-lg-end my-3 my-lg-0">
                  <a
                    className="btn portal-main-btn subsribe-now-btn"
                    // href={subscribe}
                    href="#subscribe-now"
                  >
                    أشترك الان
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({});

export default withRouter(connect(mapStateToProps, {})(NavBar));
