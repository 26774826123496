module.exports=`
<!DOCTYPE html>
<!--[if IE 7]>
<html class="ie ie7" lang="en-US" xmlns:og="http://ogp.me/ns#" xmlns:fb="http://ogp.me/ns/fb#">
<![endif]-->
<!--[if IE 8]>
<html class="ie ie8" lang="en-US" xmlns:og="http://ogp.me/ns#" xmlns:fb="http://ogp.me/ns/fb#">
<![endif]-->
<!--[if !(IE 7) | !(IE 8) ]><!-->
<html lang="en-US" xmlns:og="http://ogp.me/ns#" xmlns:fb="http://ogp.me/ns/fb#">
<!--<![endif]-->
<head>
	<meta charset="UTF-8">
	<meta name="viewport" content="width=device-width, initial-scale=1.0" />
	<link rel="profile" href="http://gmpg.org/xfn/11">
	<link rel="pingback" href="https://jeelapp.com/EN/xmlrpc.php">
	<title>PRIVACY POLICY &#8211; Jeel</title>
<link rel='dns-prefetch' href='//fonts.googleapis.com' />
<link rel='dns-prefetch' href='//s.w.org' />
<link rel="alternate" type="application/rss+xml" title="Jeel &raquo; Feed" href="https://jeelapp.com/EN/feed/" />
<link rel="alternate" type="application/rss+xml" title="Jeel &raquo; Comments Feed" href="https://jeelapp.com/EN/comments/feed/" />
		<script type="text/javascript">
			window._wpemojiSettings = {"baseUrl":"https:\/\/s.w.org\/images\/core\/emoji\/12.0.0-1\/72x72\/","ext":".png","svgUrl":"https:\/\/s.w.org\/images\/core\/emoji\/12.0.0-1\/svg\/","svgExt":".svg","source":{"concatemoji":"https:\/\/jeelapp.com\/EN\/wp-includes\/js\/wp-emoji-release.min.js?ver=5.3.2"}};
			!function(e,a,t){var r,n,o,i,p=a.createElement("canvas"),s=p.getContext&&p.getContext("2d");function c(e,t){var a=String.fromCharCode;s.clearRect(0,0,p.width,p.height),s.fillText(a.apply(this,e),0,0);var r=p.toDataURL();return s.clearRect(0,0,p.width,p.height),s.fillText(a.apply(this,t),0,0),r===p.toDataURL()}function l(e){if(!s||!s.fillText)return!1;switch(s.textBaseline="top",s.font="600 32px Arial",e){case"flag":return!c([127987,65039,8205,9895,65039],[127987,65039,8203,9895,65039])&&(!c([55356,56826,55356,56819],[55356,56826,8203,55356,56819])&&!c([55356,57332,56128,56423,56128,56418,56128,56421,56128,56430,56128,56423,56128,56447],[55356,57332,8203,56128,56423,8203,56128,56418,8203,56128,56421,8203,56128,56430,8203,56128,56423,8203,56128,56447]));case"emoji":return!c([55357,56424,55356,57342,8205,55358,56605,8205,55357,56424,55356,57340],[55357,56424,55356,57342,8203,55358,56605,8203,55357,56424,55356,57340])}return!1}function d(e){var t=a.createElement("script");t.src=e,t.defer=t.type="text/javascript",a.getElementsByTagName("head")[0].appendChild(t)}for(i=Array("flag","emoji"),t.supports={everything:!0,everythingExceptFlag:!0},o=0;o<i.length;o++)t.supports[i[o]]=l(i[o]),t.supports.everything=t.supports.everything&&t.supports[i[o]],"flag"!==i[o]&&(t.supports.everythingExceptFlag=t.supports.everythingExceptFlag&&t.supports[i[o]]);t.supports.everythingExceptFlag=t.supports.everythingExceptFlag&&!t.supports.flag,t.DOMReady=!1,t.readyCallback=function(){t.DOMReady=!0},t.supports.everything||(n=function(){t.readyCallback()},a.addEventListener?(a.addEventListener("DOMContentLoaded",n,!1),e.addEventListener("load",n,!1)):(e.attachEvent("onload",n),a.attachEvent("onreadystatechange",function(){"complete"===a.readyState&&t.readyCallback()})),(r=t.source||{}).concatemoji?d(r.concatemoji):r.wpemoji&&r.twemoji&&(d(r.twemoji),d(r.wpemoji)))}(window,document,window._wpemojiSettings);
		</script>
		<style type="text/css">
img.wp-smiley,
img.emoji {
	display: inline !important;
	border: none !important;
	box-shadow: none !important;
	height: 1em !important;
	width: 1em !important;
	margin: 0 .07em !important;
	vertical-align: -0.1em !important;
	background: none !important;
	padding: 0 !important;
}
</style>
	<link rel='stylesheet' id='layerslider-css'  href='https://jeelapp.com/EN/wp-content/plugins/LayerSlider/static/layerslider/css/layerslider.css?ver=6.10.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-preloader-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-preloader.css?ver=5.3.2' type='text/css' media='all' />
<style id='thegem-preloader-inline-css' type='text/css'>

		body:not(.compose-mode) .gem-icon-style-gradient span,
		body:not(.compose-mode) .gem-icon .gem-icon-half-1,
		body:not(.compose-mode) .gem-icon .gem-icon-half-2 {
			opacity: 0 !important;
			}
</style>
<link rel='stylesheet' id='thegem-reset-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-reset.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-grid-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-grid.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-style-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/style.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-header-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-header.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-widgets-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-widgets.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-new-css-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-new-css.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='perevazka-css-css-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-perevazka-css.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-google-fonts-css'  href='//fonts.googleapis.com/css?family=Montserrat%3A700%2C800%7CTajawal%3Aregular%2C300%2C700%7CSource+Sans+Pro%3Aregular%2C300&#038;subset=latin%2Ccyrillic-ext%2Clatin-ext%2Ccyrillic%2Cvietnamese%2Carabic%2Cgreek%2Cgreek-ext&#038;ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-custom-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/custom-LTFdSdc3.css?ver=5.3.2' type='text/css' media='all' />
<style id='thegem-custom-inline-css' type='text/css'>
.vc_custom_1580137287531{margin-top: 0px !important;margin-bottom: 0px !important;background-color: #515151 !important;}.vc_custom_1582044110572{margin-left: 0px !important;border-right-width: 0px !important;padding-right: 0px !important;padding-left: 0px !important;}.vc_custom_1580610844306{margin-bottom: 0px !important;}
</style>
<link rel='stylesheet' id='js_composer_front-css'  href='https://jeelapp.com/EN/wp-content/plugins/js_composer/assets/css/js_composer.min.css?ver=6.1' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-additional-blog-1-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-additional-blog-1.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='jquery-fancybox-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/js/fancyBox/jquery.fancybox.min.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='thegem-vc_elements-css'  href='https://jeelapp.com/EN/wp-content/themes/thegem/css/thegem-vc_elements.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='wp-block-library-css'  href='https://jeelapp.com/EN/wp-includes/css/dist/block-library/style.min.css?ver=5.3.2' type='text/css' media='all' />
<link rel='stylesheet' id='contact-form-7-css'  href='https://jeelapp.com/EN/wp-content/plugins/contact-form-7/includes/css/styles.css?ver=5.1.7' type='text/css' media='all' />
<link rel='stylesheet' id='geotarget-css'  href='https://jeelapp.com/EN/wp-content/plugins/geotargeting/public/css/geotarget-public.css?ver=1.3.3' type='text/css' media='all' />
<link rel='stylesheet' id='rs-plugin-settings-css'  href='https://jeelapp.com/EN/wp-content/plugins/revslider/public/assets/css/rs6.css?ver=6.2.1' type='text/css' media='all' />
<style id='rs-plugin-settings-inline-css' type='text/css'>
#rs-demo-id {}
</style>
<link rel='stylesheet' id='popup-maker-site-css'  href='//jeelapp.com/EN/wp-content/uploads/pum/pum-site-styles.css?generated=1583170267&#038;ver=1.9.1' type='text/css' media='all' />
<script type='text/javascript'>
/* <![CDATA[ */
var gemSettings = {"isTouch":"","forcedLasyDisabled":"","tabletPortrait":"1","tabletLandscape":"","topAreaMobileDisable":"","parallaxDisabled":"","fillTopArea":"","themePath":"https:\/\/jeelapp.com\/EN\/wp-content\/themes\/thegem","rootUrl":"https:\/\/jeelapp.com\/EN","mobileEffectsEnabled":"","isRTL":""};
/* ]]> */
</script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/thegem-settings-init.js?ver=5.3.2'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var LS_Meta = {"v":"6.10.2"};
/* ]]> */
</script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/LayerSlider/static/layerslider/js/greensock.js?ver=1.19.0'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-includes/js/jquery/jquery.js?ver=1.12.4-wp'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-includes/js/jquery/jquery-migrate.min.js?ver=1.4.1'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/LayerSlider/static/layerslider/js/layerslider.kreaturamedia.jquery.js?ver=6.10.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/LayerSlider/static/layerslider/js/layerslider.transitions.js?ver=6.10.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/thegem-fullwidth-loader.js?ver=5.3.2'></script>
<!--[if lt IE 9]>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/html5.js?ver=3.7.3'></script>
<![endif]-->
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/geotargeting/public/js/geotarget-public.js?ver=1.3.3'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/geotargeting/public/js/ddslick.js?ver=1.3.3'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/revslider/public/assets/js/rbtools.min.js?ver=6.0'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/revslider/public/assets/js/rs6.min.js?ver=6.2.1'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var zilla_likes = {"ajaxurl":"https:\/\/jeelapp.com\/EN\/wp-admin\/admin-ajax.php"};
/* ]]> */
</script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/zilla-likes/scripts/zilla-likes.js?ver=5.3.2'></script>
<meta name="generator" content="Powered by LayerSlider 6.10.2 - Multi-Purpose, Responsive, Parallax, Mobile-Friendly Slider Plugin for WordPress." />
<!-- LayerSlider updates and docs at: https://layerslider.kreaturamedia.com -->
<link rel='https://api.w.org/' href='https://jeelapp.com/EN/wp-json/' />
<link rel="EditURI" type="application/rsd+xml" title="RSD" href="https://jeelapp.com/EN/xmlrpc.php?rsd" />
<link rel="wlwmanifest" type="application/wlwmanifest+xml" href="https://jeelapp.com/EN/wp-includes/wlwmanifest.xml" /> 
<meta name="generator" content="WordPress 5.3.2" />
<link rel="canonical" href="https://jeelapp.com/EN/privacypolicy/" />
<link rel='shortlink' href='https://jeelapp.com/EN/?p=26302' />
<link rel="alternate" type="application/json+oembed" href="https://jeelapp.com/EN/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fjeelapp.com%2FEN%2Fprivacypolicy%2F" />
<link rel="alternate" type="text/xml+oembed" href="https://jeelapp.com/EN/wp-json/oembed/1.0/embed?url=https%3A%2F%2Fjeelapp.com%2FEN%2Fprivacypolicy%2F&#038;format=xml" />
<style type="text/css">.recentcomments a{display:inline !important;padding:0 !important;margin:0 !important;}</style><meta name="generator" content="Powered by WPBakery Page Builder - drag and drop page builder for WordPress."/>
<meta name="generator" content="Powered by Slider Revolution 6.2.1 - responsive, Mobile-Friendly Slider Plugin for WordPress with comfortable drag and drop interface." />
<link rel="icon" href="https://jeelapp.com/wp2/wp-content/uploads/2020/01/logo1-small.png" sizes="32x32" />
<link rel="icon" href="https://jeelapp.com/wp2/wp-content/uploads/2020/01/logo1-small.png" sizes="192x192" />
<link rel="apple-touch-icon-precomposed" href="https://jeelapp.com/wp2/wp-content/uploads/2020/01/logo1-small.png" />
<meta name="msapplication-TileImage" content="https://jeelapp.com/wp2/wp-content/uploads/2020/01/logo1-small.png" />
<script>if(document.querySelector('[data-type="vc_custom-css"]')) {document.head.appendChild(document.querySelector('[data-type="vc_custom-css"]'));}</script><script type="text/javascript">function setREVStartSize(e){			
			try {								
				var pw = document.getElementById(e.c).parentNode.offsetWidth,
					newh;
				pw = pw===0 || isNaN(pw) ? window.innerWidth : pw;
				e.tabw = e.tabw===undefined ? 0 : parseInt(e.tabw);
				e.thumbw = e.thumbw===undefined ? 0 : parseInt(e.thumbw);
				e.tabh = e.tabh===undefined ? 0 : parseInt(e.tabh);
				e.thumbh = e.thumbh===undefined ? 0 : parseInt(e.thumbh);
				e.tabhide = e.tabhide===undefined ? 0 : parseInt(e.tabhide);
				e.thumbhide = e.thumbhide===undefined ? 0 : parseInt(e.thumbhide);
				e.mh = e.mh===undefined || e.mh=="" || e.mh==="auto" ? 0 : parseInt(e.mh,0);		
				if(e.layout==="fullscreen" || e.l==="fullscreen") 						
					newh = Math.max(e.mh,window.innerHeight);				
				else{					
					e.gw = Array.isArray(e.gw) ? e.gw : [e.gw];
					for (var i in e.rl) if (e.gw[i]===undefined || e.gw[i]===0) e.gw[i] = e.gw[i-1];					
					e.gh = e.el===undefined || e.el==="" || (Array.isArray(e.el) && e.el.length==0)? e.gh : e.el;
					e.gh = Array.isArray(e.gh) ? e.gh : [e.gh];
					for (var i in e.rl) if (e.gh[i]===undefined || e.gh[i]===0) e.gh[i] = e.gh[i-1];
										
					var nl = new Array(e.rl.length),
						ix = 0,						
						sl;					
					e.tabw = e.tabhide>=pw ? 0 : e.tabw;
					e.thumbw = e.thumbhide>=pw ? 0 : e.thumbw;
					e.tabh = e.tabhide>=pw ? 0 : e.tabh;
					e.thumbh = e.thumbhide>=pw ? 0 : e.thumbh;					
					for (var i in e.rl) nl[i] = e.rl[i]<window.innerWidth ? 0 : e.rl[i];
					sl = nl[0];									
					for (var i in nl) if (sl>nl[i] && nl[i]>0) { sl = nl[i]; ix=i;}															
					var m = pw>(e.gw[ix]+e.tabw+e.thumbw) ? 1 : (pw-(e.tabw+e.thumbw)) / (e.gw[ix]);					

					newh =  (e.type==="carousel" && e.justify==="true" ? e.gh[ix] : (e.gh[ix] * m)) + (e.tabh + e.thumbh);
				}			
				
				if(window.rs_init_css===undefined) window.rs_init_css = document.head.appendChild(document.createElement("style"));					
				document.getElementById(e.c).height = newh;
				window.rs_init_css.innerHTML += "#"+e.c+"_wrapper { height: "+newh+"px }";				
			} catch(e){
				console.log("Failure at Presize of Slider:" + e)
			}					   
		  };</script>
<style id="tt-easy-google-font-styles" type="text/css">p { }
h1 { }
h2 { }
h3 { }
h4 { }
h5 { }
h6 { }
</style><noscript><style> .wpb_animate_when_almost_visible { opacity: 1; }</style></noscript>
</head>


<body class="page-template-default page page-id-26302 wpb-js-composer js-comp-ver-6.1 vc_responsive">

<div id="page" class="layout-fullwidth header-style-3">

			<a href="#page" class="scroll-top-button"></a>
	
	
	<div id="main" class="site-main">

<div id="main-content" class="main-content">


<div class="block-content no-top-margin">
	<div class="container">
		<div class="panel row">

			<div class="panel-center col-xs-12">
				<article id="post-26302" class="post-26302 page type-page status-publish">

					<!-- 
					<div class="entry-content post-content">
						
						
							
						
						<h4 style="text-align: center;"><span style="color: #000000;"><strong>PRIVACY POLICY &#8211; JEEL APP</strong></span></h4>
<p><span style="color: #000000;">Last updated: December 7, 2020</span></p>
<p><span style="color: #000000;">By accessing or using <strong>“</strong><strong>JEEL” </strong>Mobile Application (the “<strong>App</strong>”) and/or using Services as set out hereinafter, you consent to the information collection, disclosure and use practices described in this Privacy Policy. The App is owned and maintained by <strong>Roots Integrated LLC </strong>having principal office located at <u>423 James Street Office #2, Syracuse, NY, 13203, USA</u> (hereinafter referred to as “<strong>Company</strong>”, “<strong>we</strong>,” “<strong>us</strong>” or “<strong>our</strong>”). This Privacy Policy also applies to all Services provided by us and sets out how we may collect, use and disclose information in relation to our clients as well as users of the App.</span></p>
<p><span style="color: #000000;">Use of our App and Services, including all materials presented herein, may be subject to separate Terms of Use and the following Privacy Policy.</span></p>
<ol>
<li><span style="color: #000000;"><strong>Information We Collect</strong></span>
<ul>
<li><span style="color: #000000;">Your privacy is important to us and we have taken steps to ensure that we do not collect more information from you than is necessary for us to provide you with our Services and to protect your account.</span></li>
<li><span style="color: #000000;">We may process the following categories of personal data about you:</span>
<ul>
<li><span style="color: #000000;"><strong>Personal Data</strong> means any information relating to an identified or identifiable natural person. This information may include, in particular by reference to an identifier, such as name, e-mail address, country, Date of Birth (optional) and all information, data and materials as we may deem necessary for provision of Services.</span></li>
<li><span style="color: #000000;"><strong>Communication Data</strong> may include any communication that you send to us whether that be through the Contact Form on our website or App, through email, text, social media messaging, social media posting or any other communication that you send us. We process this data for the purposes of communicating with you, for record keeping and for the establishment, pursuance or defence of legal claims. Our lawful ground for this processing is our legitimate interests which in this case are to reply to communications sent to us, to keep records and to establish, pursue or defend legal claims.</span></li>
<li><span style="color: #000000;"><strong>Payment Data</strong>. We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe and Apple in App Purchase. You may find their privacy policies at <a style="color: #000000;" href="https://stripe.com/privacy">https://stripe.com/privacy</a> and <a style="color: #000000;" href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a></span></li>
<li><span style="color: #000000;"><strong>Technical Data: </strong>We automatically collect certain information when you visit, use or navigate the App. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as device characteristics, operating system, language preferences, referring URLs, device name, country, information about how and when you use our App and other technical information. This information is primarily needed to maintain the security and operation of our&nbsp;App, and for our internal analytics and reporting purposes.</span></li>
</ul>
</li>
</ul>
</li>
</ol>
<p><span style="color: #000000;">The information we collect includes the following:</span></p>
<p><span style="color: #000000;"><em>Log and Usage Data.</em>&nbsp;Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our&nbsp;App&nbsp;and which we record in log files. Depending on how you interact with us, this log data may include your device information, settings and information about your activity in the&nbsp;App&nbsp;(such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called &#8216;crash dumps&#8217;) and hardware settings).</span></p>
<p><span style="color: #000000;"><em>Device Data.</em>&nbsp;We collect device data such as information about your computer, phone, tablet or other device you use to access the&nbsp;App. Depending on the device used, this device data may include information such as your device and application identification numbers, browser type, hardware model, operating system and system configuration information.</span></p>
<p><span style="color: #000000;">Push Notifications.&nbsp;We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device&#8217;s settings.</span></p>
<p><span style="color: #000000;">The Technical Information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes</span></p>
<ul>
<li><span style="color: #000000;"><strong>Marketing Data</strong> may include data about your preferences in receiving marketing from us and your communication preferences. Our lawful ground for this processing is our legitimate interests which in this case are to study how customers use our products/services, to develop them, to grow our business and to decide our marketing strategy.</span></li>
</ul>
<ul>
<li><span style="color: #000000;">We may use Personal Data, Payment Data, Communication Data, Technical Data and Marketing Data (the “<strong>Data</strong>”) to provide you with Services. We may also use such data to send other marketing communications to you.</span></li>
</ul>
<ol start="2">
<li><span style="color: #000000;"><strong>Name and Address of the Data Controller</strong></span>
<ul>
<li><span style="color: #000000;">Data Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection laws applicable in Member states of the European Union and other provisions related to data protection is:</span></li>
</ul>
</li>
</ol>
<p><span style="color: #000000;"><strong>Name: Amr El-Basiony</strong></span></p>
<p><span style="color: #000000;"><strong>Email: <a style="color: #000000;" href="mailto:Hello@jeelapp.com">Hello@jeelapp.com</a></strong></span></p>
<p><span style="color: #000000;"><strong>Phone number: </strong>+13022000244</span></p>
<p><span style="color: #000000;"><strong>Address: </strong><u>423 James Street Office #2, Syracuse, NY, 13203, USA</u></span></p>
<p><span style="color: #000000;"><strong>App: </strong>Jeel</span></p>
<p><span style="color: #000000;"><strong>Website: </strong>Jeelapp.com</span></p>
<ol start="3">
<li><span style="color: #000000;"><strong>Consent and its Withdrawal</strong></span>
<ul>
<li><span style="color: #000000;">When you visit our App, you provide us consent to use your Data as per this Privacy Policy. In order to provide you with the Services, it is necessary for us to collect all relevant and necessary Data about you from you. In addition, by signing up and filling the Forms available on the App, you give us your express consent and permission to use your Data.</span></li>
<li><span style="color: #000000;">We will process the Data only after taking written instructions/consent from you mostly in electronic form in the form of Clickable button, a checkbox or through an email.</span></li>
<li><span style="color: #000000;">If you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting us at <a style="color: #000000;" href="mailto:Hello@jeelapp.com">Hello@jeelapp.com</a>.</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>Promotional Emails</strong></span>
<ul>
<li><span style="color: #000000;">You agree that the App and/or third parties may from time to time send e-mail messages to you which offer products and services, promotions, subscriptions or registration-based services or other material. If you wish to discontinue receiving such email, you may opt-out by writing us through email at <a style="color: #000000;" href="mailto:Hello@jeelapp.com">Hello@jeelapp.com</a>. Your preferences will then be updated.</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>How We Use and Process the Data</strong></span>
<ul>
<li><span style="color: #000000;">The Data collected by us from you may be used to provide you with Services and better understand your needs related services and programs, to correspond with you and reply to your questions with about our services.</span></li>
<li><span style="color: #000000;">We will not rent or sell your Data to others. We may store the Data in locations outside our direct control (for instance, on servers or databases co-located with hosting providers).</span></li>
<li><span style="color: #000000;">We process your Data for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</span></li>
<li><span style="color: #000000;">We use your Data collected via our App for a variety of business purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.</span></li>
<li><span style="color: #000000;">If you provide any Data to us, you are deemed to have authorized us to collect, retain and use that data for the following purposes:</span></li>
</ul>
</li>
<li><span style="color: #000000;">verifying your identity;</span></li>
<li><span style="color: #000000;">providing you with customer service and responding to your queries, feedback, or disputes;</span></li>
</ol>
<ul>
<li><span style="color: #000000;">making such disclosures as may be required for any of the above purposes or as required by law, regulations and guidelines or in respect of any investigations, claims or potential claims brought on or against us;</span></li>
</ul>
<ol>
<li><span style="color: #000000;">providing and maintain the Services;</span></li>
<li><span style="color: #000000;">notifying you about changes to our Services;</span></li>
<li><span style="color: #000000;">managing our account and keeping it in working order;</span></li>
</ol>
<ul>
<li><span style="color: #000000;"><strong>Sending you marketing and promotional communications: </strong>We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences;</span></li>
<li><span style="color: #000000;"><strong>Delivering targeted advertising to you: </strong>We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</span></li>
</ul>
<ol>
<li><span style="color: #000000;">achieving other business purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our&nbsp;App, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information.</span>
<ul>
<li><span style="color: #000000;">We shall ensure that:</span></li>
</ul>
</li>
<li><span style="color: #000000;">The Data collected and processed for and on our behalf by any party is collected and processed fairly and lawfully;</span></li>
<li><span style="color: #000000;">You are always made fully aware of the reasons for the collection of Data and are given details of the purpose(s) for which the data will be used;</span></li>
</ol>
<ul>
<li><span style="color: #000000;">The Data is only collected to the extent that is necessary to fulfil the purpose(s) for which it is required;</span></li>
</ul>
<ol>
<li><span style="color: #000000;">No Data is held for any longer than necessary in light of the purpose(s) for which it is required.</span></li>
<li><span style="color: #000000;">Whenever cookies or similar technologies are used online by us, they shall be used strictly in accordance with the law;</span></li>
<li><span style="color: #000000;">You are informed if any data submitted by you online cannot be fully deleted at your request under normal circumstances and how to request that the we delete any other copies of that data, where it is within your right to do so;</span></li>
</ol>
<ul>
<li><span style="color: #000000;">Appropriate technical and organizational measures are taken to protect the Data;</span></li>
<li><span style="color: #000000;">Data is transferred securely, whether it is transmitted electronically or in hard copy.</span></li>
</ul>
<ol>
<li><span style="color: #000000;">You can fully exercise your rights with ease and without hindrance.</span></li>
<li><span style="color: #000000;"><strong>Disclosure of Data</strong></span>
<ul>
<li><span style="color: #000000;">We shall not be able to keep your Data private in response to legal process i.e., a court order or a subpoena, a law enforcement agency’s request. If, in our view, it is deemed appropriate to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of use, or as otherwise required by law, we may be compelled to disclose the Data and Personal Data. Moreover, in case of takeover, merger or acquisition, we reserve a right to transfer your data to new platform.</span></li>
<li><span style="color: #000000;">We may disclose the Data in the good faith belief that such action is necessary to:</span></li>
</ul>
</li>
<li><span style="color: #000000;">comply with a legal obligation</span></li>
<li><span style="color: #000000;">protect and defend our rights or property</span></li>
</ol>
<ul>
<li><span style="color: #000000;">prevent or investigate possible wrongdoing</span></li>
<li><span style="color: #000000;">protect the personal safety of users of the Service or the public</span></li>
<li><span style="color: #000000;">protect against legal liability</span>
<ul>
<li><span style="color: #000000;">When necessary, we may also disclose and transfer your Data to our professional advisers, law enforcement agencies, insurers, government and regulatory and other organizations.</span></li>
</ul>
</li>
</ul>
<ol start="7">
<li><span style="color: #000000;"><strong>Data Storage</strong></span>
<ul>
<li><span style="color: #000000;">Your Data may be stored and processed at the servers in the United States, Europe, or any other country in which the App or its subsidiaries, affiliates or service providers maintain facilities.</span></li>
<li><span style="color: #000000;">The App may transfer Data to affiliated entities, or to other third parties across borders and from your country or jurisdiction to other countries or jurisdictions around the world. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</span></li>
<li><span style="color: #000000;">We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</span></li>
<li><span style="color: #000000;">We will only retain your Data preferably for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, see section regarding insurance requirement and reporting requirements. When deciding what the correct time is to keep the Data for we look at its amount, nature and sensitivity, potential risk of harm from unauthorised use or disclosure, the processing purposes, if these can be achieved by other means and legal requirements.</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>How We Protect Your Information</strong></span>
<ul>
<li><span style="color: #000000;">We store all the Data submitted by you through App at a secure database namely Firebase (Google) for Authentication and AWS (Amazon) for App Servers.</span></li>
<li><span style="color: #000000;">We are concerned with protecting your privacy and data, but we cannot ensure or warrant the security of any data you transmit to or guarantee that your Data may not be accessed, disclosed, altered or destroyed by breach of any of our industry standard physical, technical or managerial safeguards.</span></li>
<li><span style="color: #000000;">No method of transmission over the Internet or method of electronic Therefore, we cannot guarantee its absolute security. If you have any questions about security of our App, you can contact us at <a style="color: #000000;" href="mailto:Hello@jeelapp.com">Hello@jeelapp.com</a>.</span></li>
<li><span style="color: #000000;">Any Data supplied by you will be retained by us and will be accessible by our employees, any service providers engaged by us and third parties.</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>Compliance with the GDPR</strong></span>
<ul>
<li><span style="color: #000000;">For users based in the European Union (EU), the App shall make all reasonable efforts to ensure that it complies with The&nbsp;<strong>General Data Protection Regulation</strong>(<strong>GDPR</strong>) (EU) 2016/679 as set forth by the European Union regarding the collection, use, and retention of Data from European Union member countries. App shall make all reasonable efforts to adhere to the requirements of notice, choice, onward transfer, security, data integrity, access and enforcement.</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>The Rights of Users </strong></span></li>
</ol>
<p><span style="color: #000000;">You may exercise certain rights regarding your Data processed by us. In particular, users based in the EU may do the following:</span></p>
<ul>
<li><span style="color: #000000;"><strong>Right of confirmation</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator to obtain from us the confirmation as to whether or not personal data concerning you are being processed.</span></p>
<ul>
<li><span style="color: #000000;"><strong>Right of Access</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator to obtain from us free information about your personal data stored at any time and a copy of this information. Furthermore, the European directives and regulations grant you access to the following information:</span></p>
<ul>
<li><span style="color: #000000;">the purposes of the processing;</span></li>
<li><span style="color: #000000;">the categories of personal data concerned;</span></li>
<li><span style="color: #000000;">the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organizations;</span></li>
<li><span style="color: #000000;">where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;</span></li>
<li><span style="color: #000000;">the existence of the right to request from us rectification or erasure of personal data, or restriction of processing of personal data concerning the data subject, or to object to such processing;</span></li>
<li><span style="color: #000000;">the existence of the right to lodge a complaint with a supervisory authority;</span></li>
<li><span style="color: #000000;">where the personal data are not collected from you, any available information as to its source;</span></li>
<li><span style="color: #000000;">the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) of the GDPR and, at least in those cases, meaningful information about the logic involved, as well as the significance and envisaged consequences of such processing for you.</span></li>
</ul>
<p><span style="color: #000000;">Furthermore, you shall have a right to obtain information as to whether personal data are transferred to a third country or to an international organisation. Where this is the case, you shall have the right to be informed of the appropriate safeguards relating to the transfer.</span></p>
<ul>
<li><span style="color: #000000;"><strong>Right to rectification</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator to obtain from us without undue delay the rectification of inaccurate personal data concerning you. Taking into account the purposes of the processing, you shall have the right to have incomplete personal data completed, including by means of providing a supplementary statement.</span></p>
<ul>
<li><span style="color: #000000;"><strong>Right to erasure (Right to be forgotten)</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator to obtain from us the erasure of personal data concerning you without undue delay, and we shall have the obligation to erase personal data without undue delay where one of the following grounds applies, as long as the processing is not necessary:</span></p>
<ul>
<li><span style="color: #000000;">The personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed.</span></li>
<li><span style="color: #000000;">You withdraw consent to which the processing is based according to point (a) of Article 6(1) of the GDPR, or point (a) of Article 9(2) of the GDPR, and where there is no other legal ground for the processing.</span></li>
<li><span style="color: #000000;">You object to the processing pursuant to Article 21(1) of the GDPR and there are no overriding legitimate grounds for the processing, or you object to the processing pursuant to Article 21(2) of the GDPR.</span></li>
<li><span style="color: #000000;">The personal data have been unlawfully processed.</span></li>
<li><span style="color: #000000;">The personal data must be erased for compliance with a legal obligation in Union or Member State law to which we are subject.</span></li>
<li><span style="color: #000000;">The personal data have been collected in relation to the offer of information society services referred to in Article 8(1) of the GDPR.</span></li>
</ul>
<p><span style="color: #000000;">Where we have made personal data public and are obliged pursuant to Article 17(1) to erase the personal data, we, while taking account of available technology and the cost of implementation, shall take reasonable steps, including technical measures, to inform other controllers processing the personal data that you have requested erasure by such controllers of any links to, or copy or replication of, those personal data, as far as processing is not required. We will arrange the necessary measures in individual cases.</span></p>
<ul>
<li><span style="color: #000000;"><strong>Right of restriction of processing</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator to obtain from us restriction of processing where one of the following applies:</span></p>
<ul>
<li><span style="color: #000000;">The accuracy of the personal data is contested by the data subject, for a period enabling us to verify the accuracy of the personal data.</span></li>
<li><span style="color: #000000;">The processing is unlawful and the data subject opposes the erasure of the personal data and requests instead the restriction of their use instead.</span></li>
<li><span style="color: #000000;">We no longer need the personal data for the purposes of the processing, but we are required by the data subject for the establishment, exercise or defence of legal claims.</span></li>
<li><span style="color: #000000;">You have objected to processing pursuant to Article 21(1) of the GDPR pending the verification whether our legitimate grounds override those of yours.</span></li>
</ul>
<p><span style="color: #000000;">If one of the aforementioned conditions is met, and you wish to request the restriction of the processing of personal data stored by us, you may at any time contact us.</span></p>
<ul>
<li><span style="color: #000000;"><strong>Right to data portability</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator, to receive the personal data concerning you, which was provided to us, in a structured, commonly used and machine-readable format. You shall have the right to transmit those data to another data controller without hindrance from us to which the personal data have been provided, as long as the processing is based on consent pursuant to point (a) of Article 6(1) of the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract pursuant to point (b) of Article 6(1) of the GDPR, and the processing is carried out by automated means, as long as the processing is not necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in us.</span></p>
<ul>
<li><span style="color: #000000;"><strong>Right to object</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator to object, on grounds relating to your particular situation, at any time, to processing of personal data concerning you, which is based on point (e) or (f) of Article 6(1) of the GDPR. This also applies to profiling based on these provisions.</span></p>
<p><span style="color: #000000;">We shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the establishment, exercise or defence of legal claims.</span></p>
<p><span style="color: #000000;">If we processes personal data for direct marketing purposes, you shall have the right to object at any time to processing of personal data concerning you for such marketing. This applies to profiling to the extent that it is related to such direct marketing. If you object to us to the processing for direct marketing purposes, we will no longer process the personal data for these purposes.</span></p>
<p><span style="color: #000000;">In addition, you have the right, on grounds relating to your particular situation, to object to processing of personal data concerning you by us for scientific or historical research purposes, or for statistical purposes pursuant to Article 89(1) of the GDPR, unless the processing is necessary for the performance of a task carried out for reasons of public interest.</span></p>
<ul>
<li><span style="color: #000000;"><strong>Automated individual decision-making, including profiling</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning you, or similarly significantly affects you, as long as the decision (1) is not necessary for entering into, or the performance of, a contract between you and us, or (2) is not authorised by Union or Member State law to which we are subject and which also lays down suitable measures to safeguard your rights and freedoms and legitimate interests, or (3) is not based on your explicit consent.</span></p>
<p><span style="color: #000000;">If the decision (1) is necessary for entering into, or the performance of, a contract between you and us, or (2) it is based on your explicit consent, we shall implement suitable measures to safeguard your rights and freedoms and legitimate interests, at least the right to obtain human intervention on our part, to express your point of view and contest the decision.</span></p>
<ul>
<li><span style="color: #000000;"><strong>Right to withdraw data protection consent</strong></span></li>
</ul>
<p><span style="color: #000000;">You shall have the right granted by the European legislator to withdraw your consent to processing of your personal data at any time. You may initiate request with us at <a style="color: #000000;" href="mailto:Hello@jeelapp.com">Hello@jeelapp.com</a> to exercise any of the above-mentioned rights. We shall review your request and, in our own discretion, honor your request, if deemed necessary by us, within reasonable time.</span></p>
<ol start="11">
<li><span style="color: #000000;"><strong>Use by Children and COPPA Compliance</strong></span>
<ul>
<li><span style="color: #000000;">Our App strives to comply with COPPA regulations. COPPA stands for the “Children’s Online Privacy Protection Act”. It is a U.S. federal law enforced by the Federal Trade Commission. Its purpose is to give parents control over their children’s privacy online. COPPA imposes special legal obligations on websites, mobile apps, tablets, and other similar technologies designed for children under the age of 13.</span></li>
<li><span style="color: #000000;">With some exceptions, COPPA requires companies to get a parent’s verified permission (such as through a credit card transaction, customer support phone call, or other legal method) before collecting or using a child’s personal information online “Personal information” includes a child’s name, home address, email address, phone number, picture, voice recording, physical location, and certain unique identifiers (like device ID). Without a parent’s prior permission, COPPA prohibits certain types of online advertising, community forums, and social networking features. COPPA also requires companies to post a privacy policy and to keep children’s data secure.</span></li>
<li><span style="color: #000000;">Under the COPPA law, you can ask a company to delete or stop using your child’s personal information at any time Carefully review each company’s privacy policy before allowing your child to share any personal information with that company.</span></li>
<li><span style="color: #000000;">We strive to educate parents and kids about how to appropriately safeguard their privacy when using our App. We are committed to complying with the Children’s Online Privacy Protection Act of 1998 (“<strong>COPPA</strong>”) which requires us to inform parents and legal guardians about our information collection and use practices. COPPA also requires that we obtain parental consent before we allow children under the age of 13 to access and/or use our App. We recommend that parents discuss with their kids restrictions regarding the online release of Personal Data to anyone they do not know. We strive to help parents ensure that their kids have a safe experience using our App and other services. Parents or legal guardians can review any Personal Data collected about their child under 13 years of age, have this information deleted, request that there be no further collection or use of their child’s Personal Data (name and age), and/or allow for our collection and use of their child’s Personal Data while withholding consent for us to disclose it to third parties. We may take steps to verify the identity of anyone requesting information about a child and to ensure that the person is in fact the child’s parent or legal guardian.</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>Compliance with CalOPPA</strong></span>
<ul>
<li><span style="color: #000000;">We are also committed to complying with the California Online Privacy Protection Act (“<strong>CalOPPA</strong>”) which requires commercial websites and online services to post a privacy policy. The law requires any person or company in the United States (and conceivably the world) that operates websites/Apps collecting ‘personally identifiable information’ from California consumers to post a conspicuous privacy policy stating exactly the information being collected and those individuals or companies with whom it is being shared. As required under CalOPPA, we agree to the following:</span></li>
</ul>
</li>
</ol>
<ul>
<li><span style="color: #000000;">Users can access our App anonymously.</span></li>
<li><span style="color: #000000;">Our Privacy Policy link includes the word ‘Privacy’ and can easily be found in our App.</span>
<ul>
<li><span style="color: #000000;">We endeavour to fulfil requirements of the CalOPPA under this Privacy Policy in line with our policy for user data collection, storage, and disclosure as outlined for GDPR compliance.</span></li>
</ul>
</li>
</ul>
<ol start="13">
<li><span style="color: #000000;"><strong>Compliance of California Privacy Act 2018</strong></span>
<ul>
<li><span style="color: #000000;">This Privacy Policy is also applicable on California residents and explains how we collect, use, and share your Personal Information and how you may exercise your rights under the California Consumer Privacy Act of 2018 (“CCPA”).</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>Your rights under the CCPA:</strong></span>
<ul>
<li><span style="color: #000000;">Under the CCPA, you have the right to access the Personal Information we’ve collected about you during the past 12 months and information about our data practices. You also have the right to request that we delete the Personal Information that we have collected from you.</span></li>
<li><span style="color: #000000;">To request manual access or deletion of your Personal Information, please contact us at&nbsp;<a style="color: #000000;" href="mailto:Hello@jeelapp.com">Hello@jeelapp.com</a>.</span></li>
<li><span style="color: #000000;">Please note, for all manual requests, you will need to verify your identity by providing us with the all the information as we may require from you for this purpose.</span></li>
<li><span style="color: #000000;">You have the right to be free from any discrimination for exercising your rights to access or delete your Personal Information. We will not discriminate against you for exercising any of these rights.</span></li>
<li><span style="color: #000000;">If you have additional questions about this or how to exercise your rights under the CCPA, please&nbsp;<a style="color: #000000;" href="mailto:CCPA.inquiries@support.whatsapp.com">contact us</a> at <a style="color: #000000;" href="mailto:Hello@jeelapp.com">Hello@jeelapp.com</a>.</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>Changes to this Privacy Statement</strong></span>
<ul>
<li><span style="color: #000000;">We may modify these this Privacy Policy from time to time, and any such change shall be reflected on the App with the updated version of the Privacy Policy and you agree to be bound to any changes to the updated version of Privacy Policy when you use the App or its services.</span></li>
<li><span style="color: #000000;">You acknowledge and agree that it is your responsibility to review this App and this Policy periodically and to be aware of any modifications. Updates to this Policy will be posted on this page.</span></li>
<li><span style="color: #000000;">Also, occasionally there may be information on the App that contains typographical errors, inaccuracies or omissions that may relate to service descriptions, pricing, availability, and various other information, and the App reserves the right to correct any errors, inaccuracies or omissions and to change or update the information at any time, without prior notice.</span></li>
</ul>
</li>
<li><span style="color: #000000;"><strong>Contact US</strong></span>
<ul>
<li><span style="color: #000000;">If you have questions about our Privacy Policy, please contact us via email: <a style="color: #000000;" href="mailto:Hello@jeelapp.com">Hello@jeelapp.com</a>.</span></li>
</ul>
</li>
</ol>
					</div>
					-->
					
					<!-- .entry-content -->

					<div class="entry-content post-content">
					
<div class=WordSection1>

<p class=MsoNormal align=center dir=RTL style='text-align:center;direction:
rtl;unicode-bidi:embed'><span lang=AR-SA style='font-size:14.0pt;line-height:
107%;font-family:"Arial","sans-serif"'>&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; -
&#1578;&#1591;&#1576;&#1610;&#1602; &#1580;&#1610;&#1604;</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1570;&#1582;&#1585;
&#1578;&#1581;&#1583;&#1610;&#1579;: 7
&#1583;&#1610;&#1587;&#1605;&#1576;&#1585; 2020</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
&#1573;&#1604;&#1609; &#1578;&#1591;&#1576;&#1610;&#1602;
&quot;&#1580;&#1610;&#1604;&quot; &#1604;&#1604;&#1607;&#1575;&#1578;&#1601;
&#1575;&#1604;&#1605;&#1581;&#1605;&#1608;&#1604; (&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;)
&#1608;/&#1571;&#1608; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1603;&#1605;&#1575;
&#1607;&#1608; &#1605;&#1608;&#1590;&#1581; &#1607;&#1606;&#1575;&#1548;
&#1601;&#1573;&#1606;&#1603; &#1578;&#1608;&#1575;&#1601;&#1602;
&#1593;&#1604;&#1609; &#1605;&#1605;&#1575;&#1585;&#1587;&#1575;&#1578;
&#1580;&#1605;&#1593;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1548;
&#1575;&#1604;&#1603;&#1588;&#1601; &#1593;&#1606;&#1607;&#1575;&#1548;
&#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;
&#1603;&#1605;&#1575; &#1607;&#1608; &#1605;&#1608;&#1589;&#1608;&#1601;
&#1601;&#1610; &#1587;&#1610;&#1575;&#1587;&#1577; &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1607;&#1584;&#1607;. &#1610;&#1578;&#1605;
&#1575;&#1605;&#1578;&#1604;&#1575;&#1603;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602; &#1608;&#1589;&#1610;&#1575;&#1606;&#1578;&#1607;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1588;&#1585;&#1603;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span> Roots Integrated
LLC</span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'><span
dir=RTL></span>&#1548; &#1608;&#1605;&#1603;&#1578;&#1576;&#1607;&#1575;
&#1575;&#1604;&#1585;&#1574;&#1610;&#1587;&#1610;
&#1605;&#1608;&#1580;&#1608;&#1583; &#1601;&#1610; 423
&#1588;&#1575;&#1585;&#1593; &#1580;&#1610;&#1605;&#1587;
&#1605;&#1603;&#1578;&#1576; #2&#1548;
&#1587;&#1610;&#1585;&#1575;&#1603;&#1610;&#1608;&#1586;&#1548;
&#1606;&#1610;&#1608;&#1610;&#1608;&#1585;&#1603;&#1548; 13203&#1548;
&#1575;&#1604;&#1608;&#1604;&#1575;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1578;&#1581;&#1583;&#1577; &#1575;&#1604;&#1571;&#1605;&#1585;&#1610;&#1603;&#1610;&#1577;
(&#1610;&#1588;&#1575;&#1585; &#1573;&#1604;&#1610;&#1607;&#1575;
&#1601;&#1610;&#1605;&#1575; &#1610;&#1604;&#1610; &#1576;&#1600;
&quot;&#1575;&#1604;&#1588;&#1585;&#1603;&#1577;&quot;&#1548;
&quot;&#1606;&#1581;&#1606;&quot;&#1548; &quot;&#1604;&#1606;&#1575;&quot;
&#1571;&#1608; &quot;&#1604;&#1583;&#1610;&#1606;&#1575;&quot;).
&#1578;&#1606;&#1591;&#1576;&#1602; &#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1607;&#1584;&#1607;
&#1571;&#1610;&#1590;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1580;&#1605;&#1610;&#1593; &#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1606;&#1602;&#1583;&#1605;&#1607;&#1575;
&#1608;&#1578;&#1581;&#1583;&#1583; &#1603;&#1610;&#1601; &#1602;&#1583;
&#1606;&#1580;&#1605;&#1593;&#1548;
&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;&#1548;
&#1608;&#1606;&#1603;&#1588;&#1601; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1601;&#1610;&#1605;&#1575; &#1610;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1593;&#1605;&#1604;&#1575;&#1574;&#1606;&#1575;
&#1608;&#1603;&#1584;&#1604;&#1603; &#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
&#1604;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;
&#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;&#1548;
&#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
&#1580;&#1605;&#1610;&#1593; &#1575;&#1604;&#1605;&#1608;&#1575;&#1583;
&#1575;&#1604;&#1605;&#1593;&#1585;&#1608;&#1590;&#1577;
&#1607;&#1606;&#1575;&#1548; &#1602;&#1583; &#1610;&#1582;&#1590;&#1593;
&#1604;&#1588;&#1585;&#1608;&#1591; &#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1606;&#1601;&#1589;&#1604;&#1577;
&#1608;&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577;.</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1580;&#1605;&#1593;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
67.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1606;&#1581;&#1606;
&#1606;&#1602;&#1583;&#1585; &#1582;&#1589;&#1608;&#1589;&#1610;&#1578;&#1603;
&#1608;&#1575;&#1578;&#1582;&#1584;&#1606;&#1575;
&#1582;&#1591;&#1608;&#1575;&#1578; &#1604;&#1590;&#1605;&#1575;&#1606;
&#1593;&#1583;&#1605; &#1580;&#1605;&#1593;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1571;&#1603;&#1579;&#1585;
&#1605;&#1606; &#1575;&#1604;&#1604;&#1575;&#1586;&#1605; &#1605;&#1606;&#1603;
&#1604;&#1578;&#1602;&#1583;&#1610;&#1605;
&#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575; &#1604;&#1603;
&#1608;&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1581;&#1587;&#1575;&#1576;&#1603;. &#1602;&#1583; &#1606;&#1593;&#1575;&#1604;&#1580;
&#1575;&#1604;&#1601;&#1574;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577; &#1605;&#1606;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1593;&#1606;&#1603;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>:</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;: &#1578;&#1593;&#1606;&#1610;
&#1571;&#1610; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1578;&#1578;&#1593;&#1604;&#1602; &#1576;&#1588;&#1582;&#1589;
&#1591;&#1576;&#1610;&#1593;&#1610; &#1605;&#1593;&#1585;&#1608;&#1601;
&#1571;&#1608; &#1602;&#1575;&#1576;&#1604;
&#1604;&#1604;&#1578;&#1581;&#1583;&#1610;&#1583;. &#1602;&#1583;
&#1578;&#1588;&#1605;&#1604; &#1607;&#1584;&#1607; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1548;
&#1576;&#1588;&#1603;&#1604; &#1582;&#1575;&#1589; &#1605;&#1606;
&#1582;&#1604;&#1575;&#1604; &#1575;&#1604;&#1573;&#1588;&#1575;&#1585;&#1577;
&#1573;&#1604;&#1609; &#1605;&#1615;&#1593;&#1585;&#1601;&#1548;
&#1605;&#1579;&#1604; &#1575;&#1604;&#1575;&#1587;&#1605;&#1548;
&#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1548;
&#1575;&#1604;&#1576;&#1604;&#1583;&#1548; &#1578;&#1575;&#1585;&#1610;&#1582;
&#1575;&#1604;&#1605;&#1610;&#1604;&#1575;&#1583;
(&#1575;&#1582;&#1578;&#1610;&#1575;&#1585;&#1610;)
&#1608;&#1580;&#1605;&#1610;&#1593;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1605;&#1608;&#1575;&#1583; &#1575;&#1604;&#1578;&#1610;
&#1602;&#1583; &#1606;&#1593;&#1578;&#1576;&#1585;&#1607;&#1575;
&#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
&#1604;&#1578;&#1602;&#1583;&#1610;&#1605;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;: &#1602;&#1583;
&#1578;&#1588;&#1605;&#1604; &#1571;&#1610; &#1575;&#1578;&#1589;&#1575;&#1604;
&#1578;&#1585;&#1587;&#1604;&#1607; &#1604;&#1606;&#1575; &#1587;&#1608;&#1575;&#1569;
&#1603;&#1575;&#1606; &#1584;&#1604;&#1603; &#1605;&#1606;
&#1582;&#1604;&#1575;&#1604; &#1606;&#1605;&#1608;&#1584;&#1580;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1593;&#1604;&#1609;
&#1605;&#1608;&#1602;&#1593;&#1606;&#1575;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1571;&#1608; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;&#1548;
&#1593;&#1576;&#1585; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1548;
&#1575;&#1604;&#1585;&#1587;&#1575;&#1574;&#1604;
&#1575;&#1604;&#1606;&#1589;&#1610;&#1577;&#1548; &#1575;&#1604;&#1605;&#1585;&#1575;&#1587;&#1604;&#1577;
&#1593;&#1576;&#1585; &#1608;&#1587;&#1575;&#1574;&#1604;
&#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604;
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;&#1548;
&#1575;&#1604;&#1605;&#1588;&#1575;&#1585;&#1603;&#1575;&#1578;
&#1593;&#1604;&#1609; &#1608;&#1587;&#1575;&#1574;&#1604;
&#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604;
&#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610; &#1571;&#1608;
&#1571;&#1610; &#1575;&#1578;&#1589;&#1575;&#1604; &#1570;&#1582;&#1585;
&#1578;&#1585;&#1587;&#1604;&#1607; &#1604;&#1606;&#1575;.
&#1606;&#1593;&#1575;&#1604;&#1580; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604;
&#1605;&#1593;&#1603;&#1548; &#1604;&#1604;&#1581;&#1601;&#1592;
&#1575;&#1604;&#1587;&#1580;&#1604;&#1575;&#1578;
&#1608;&#1604;&#1573;&#1606;&#1588;&#1575;&#1569;&#1548;
&#1605;&#1578;&#1575;&#1576;&#1593;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1583;&#1601;&#1575;&#1593; &#1593;&#1606;
&#1575;&#1604;&#1605;&#1591;&#1575;&#1604;&#1576;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;.
&#1575;&#1604;&#1571;&#1587;&#1575;&#1587; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;
&#1604;&#1607;&#1584;&#1575;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1607;&#1608;
&#1605;&#1589;&#1575;&#1604;&#1581;&#1606;&#1575;
&#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;
&#1608;&#1575;&#1604;&#1578;&#1610; &#1601;&#1610; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1581;&#1575;&#1604;&#1577; &#1607;&#1610;
&#1575;&#1604;&#1585;&#1583; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1585;&#1587;&#1604;&#1577;
&#1573;&#1604;&#1610;&#1606;&#1575;&#1548; &#1604;&#1604;&#1581;&#1601;&#1592;
&#1575;&#1604;&#1587;&#1580;&#1604;&#1575;&#1578;
&#1608;&#1604;&#1573;&#1606;&#1588;&#1575;&#1569;&#1548;
&#1605;&#1578;&#1575;&#1576;&#1593;&#1577; &#1571;&#1608; &#1575;&#1604;&#1583;&#1601;&#1575;&#1593;
&#1593;&#1606; &#1575;&#1604;&#1605;&#1591;&#1575;&#1604;&#1576;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1583;&#1601;&#1593;: &#1602;&#1583;
&#1606;&#1580;&#1605;&#1593; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1583;&#1601;&#1593;&#1578;&#1603; &#1573;&#1584;&#1575; &#1602;&#1605;&#1578;
&#1576;&#1593;&#1605;&#1604;&#1610;&#1575;&#1578;
&#1588;&#1585;&#1575;&#1569;&#1548; &#1605;&#1579;&#1604; &#1585;&#1602;&#1605;
&#1571;&#1583;&#1575;&#1577; &#1575;&#1604;&#1583;&#1601;&#1593;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;
(&#1605;&#1579;&#1604; &#1585;&#1602;&#1605;
&#1576;&#1591;&#1575;&#1602;&#1577;
&#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;)&#1548;
&#1608;&#1585;&#1605;&#1586; &#1575;&#1604;&#1571;&#1605;&#1575;&#1606;
&#1575;&#1604;&#1605;&#1585;&#1578;&#1576;&#1591;
&#1576;&#1571;&#1583;&#1575;&#1577; &#1575;&#1604;&#1583;&#1601;&#1593;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1603;.
&#1610;&#1578;&#1605; &#1578;&#1582;&#1586;&#1610;&#1606;
&#1580;&#1605;&#1610;&#1593; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1583;&#1601;&#1593; &#1576;&#1608;&#1575;&#1587;&#1591;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span> Stripe </span><span
lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1608;</span><span
lang=EN-GB dir=LTR>Apple in App Purchase. </span><span lang=AR-SA
style='font-family:"Arial","sans-serif"'>&#1610;&#1605;&#1603;&#1606;&#1603;
&#1575;&#1604;&#1593;&#1579;&#1608;&#1585; &#1593;&#1604;&#1609;
&#1587;&#1610;&#1575;&#1587;&#1575;&#1578;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1607;&#1605;
&#1593;&#1604;&#1609;</span><span dir=LTR></span><span lang=EN-GB dir=LTR><span
dir=LTR></span> https://stripe.com/privacy </span><span lang=AR-SA
style='font-family:"Arial","sans-serif"'>&#1608;</span><span lang=EN-GB
dir=LTR>https://www.apple.com/legal/privacy/en-ww/</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577;: &#1606;&#1580;&#1605;&#1593;
&#1578;&#1604;&#1602;&#1575;&#1574;&#1610;&#1611;&#1575;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1605;&#1593;&#1610;&#1606;&#1577;
&#1593;&#1606;&#1583;&#1605;&#1575; &#1578;&#1586;&#1608;&#1585;&#1548;
&#1578;&#1587;&#1578;&#1582;&#1583;&#1605; &#1571;&#1608;
&#1578;&#1578;&#1589;&#1601;&#1581;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;. &#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1604;&#1575;
&#1578;&#1603;&#1588;&#1601; &#1593;&#1606; &#1607;&#1608;&#1610;&#1578;&#1603;
&#1575;&#1604;&#1605;&#1581;&#1583;&#1583;&#1577; (&#1605;&#1579;&#1604;
&#1575;&#1587;&#1605;&#1603; &#1571;&#1608;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1576;&#1603;)
&#1608;&#1604;&#1603;&#1606; &#1602;&#1583; &#1578;&#1588;&#1605;&#1604;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
&#1580;&#1607;&#1575;&#1586;&#1603;
&#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;&#1548;
&#1605;&#1579;&#1604; &#1582;&#1589;&#1575;&#1574;&#1589;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586;&#1548; &#1606;&#1592;&#1575;&#1605;
&#1575;&#1604;&#1578;&#1588;&#1594;&#1610;&#1604;&#1548;
&#1578;&#1601;&#1590;&#1610;&#1604;&#1575;&#1578;
&#1575;&#1604;&#1604;&#1594;&#1577;&#1548;
&#1593;&#1606;&#1575;&#1608;&#1610;&#1606; </span><span lang=EN-GB dir=LTR>URL</span><span
dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'><span
dir=RTL></span> &#1575;&#1604;&#1605;&#1588;&#1610;&#1585;&#1577;&#1548;
&#1575;&#1587;&#1605; &#1575;&#1604;&#1580;&#1607;&#1575;&#1586;&#1548;
&#1575;&#1604;&#1576;&#1604;&#1583;&#1548;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
&#1603;&#1610;&#1601; &#1608;&#1605;&#1578;&#1609;
&#1578;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;
&#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1578;&#1602;&#1606;&#1610;&#1577; &#1571;&#1582;&#1585;&#1609;. &#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1590;&#1585;&#1608;&#1585;&#1610;&#1577; &#1576;&#1588;&#1603;&#1604;
&#1571;&#1587;&#1575;&#1587;&#1610; &#1604;&#1604;&#1581;&#1601;&#1575;&#1592;
&#1593;&#1604;&#1609; &#1571;&#1605;&#1575;&#1606;
&#1608;&#1578;&#1588;&#1594;&#1610;&#1604; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1606;&#1580;&#1605;&#1593;&#1607;&#1575;
&#1578;&#1588;&#1605;&#1604; &#1605;&#1575; &#1610;&#1604;&#1610;:</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1587;&#1580;&#1604;
&#1608;&#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;:
&#1607;&#1610; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1575;&#1604;&#1582;&#1583;&#1605;&#1577;&#1548;
&#1578;&#1588;&#1582;&#1610;&#1589;&#1610;&#1577;&#1548;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1610;&#1577;
&#1608;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1571;&#1583;&#1575;&#1569;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1602;&#1608;&#1605;
&#1582;&#1608;&#1575;&#1583;&#1605;&#1606;&#1575;
&#1576;&#1580;&#1605;&#1593;&#1607;&#1575;
&#1578;&#1604;&#1602;&#1575;&#1574;&#1610;&#1611;&#1575; &#1593;&#1606;&#1583;&#1605;&#1575;
&#1578;&#1589;&#1604; &#1571;&#1608; &#1578;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1608;&#1575;&#1604;&#1578;&#1610; &#1606;&#1602;&#1608;&#1605;
&#1576;&#1578;&#1587;&#1580;&#1610;&#1604;&#1607;&#1575; &#1601;&#1610;
&#1605;&#1604;&#1601;&#1575;&#1578; &#1575;&#1604;&#1587;&#1580;&#1604;.
&#1575;&#1593;&#1578;&#1605;&#1575;&#1583;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1603;&#1610;&#1601;&#1610;&#1577; &#1578;&#1601;&#1575;&#1593;&#1604;&#1603;
&#1605;&#1593;&#1606;&#1575;&#1548; &#1602;&#1583; &#1578;&#1588;&#1605;&#1604;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1607;&#1584;&#1607; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1593;&#1606; &#1580;&#1607;&#1575;&#1586;&#1603;&#1548;
&#1575;&#1604;&#1573;&#1593;&#1583;&#1575;&#1583;&#1575;&#1578;
&#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
&#1606;&#1588;&#1575;&#1591;&#1603; &#1601;&#1610; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
(&#1605;&#1579;&#1604; &#1575;&#1604;&#1591;&#1608;&#1575;&#1576;&#1593;
&#1575;&#1604;&#1586;&#1605;&#1606;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1585;&#1578;&#1576;&#1591;&#1577;
&#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;&#1548;
&#1575;&#1604;&#1589;&#1601;&#1581;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1605;&#1604;&#1601;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1605;
&#1593;&#1585;&#1590;&#1607;&#1575;&#1548; &#1575;&#1604;&#1576;&#1581;&#1579;
&#1608;&#1594;&#1610;&#1585;&#1607;&#1575; &#1605;&#1606;
&#1575;&#1604;&#1573;&#1580;&#1585;&#1575;&#1569;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1602;&#1608;&#1605; &#1576;&#1607;&#1575;
&#1605;&#1579;&#1604; &#1575;&#1604;&#1605;&#1610;&#1586;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610;
&#1578;&#1587;&#1578;&#1582;&#1583;&#1605;&#1607;&#1575;)&#1548;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1581;&#1583;&#1579; &#1601;&#1610;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586; (&#1605;&#1579;&#1604;
&#1606;&#1588;&#1575;&#1591; &#1575;&#1604;&#1606;&#1592;&#1575;&#1605;&#1548;
&#1578;&#1602;&#1575;&#1585;&#1610;&#1585; &#1575;&#1604;&#1582;&#1591;&#1571;
(&#1575;&#1604;&#1578;&#1610; &#1578;&#1587;&#1605;&#1609;
&#1571;&#1581;&#1610;&#1575;&#1606;&#1611;&#1575;
&quot;&#1578;&#1602;&#1575;&#1585;&#1610;&#1585;
&#1575;&#1604;&#1575;&#1606;&#1607;&#1610;&#1575;&#1585;&quot;)
&#1608;&#1573;&#1593;&#1583;&#1575;&#1583;&#1575;&#1578;
&#1575;&#1604;&#1571;&#1580;&#1607;&#1586;&#1577;).</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586;: &#1606;&#1580;&#1605;&#1593;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586; &#1605;&#1579;&#1604;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
&#1575;&#1604;&#1603;&#1605;&#1576;&#1610;&#1608;&#1578;&#1585;&#1548;
&#1575;&#1604;&#1607;&#1575;&#1578;&#1601;&#1548;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586; &#1575;&#1604;&#1604;&#1608;&#1581;&#1610;
&#1571;&#1608; &#1571;&#1610; &#1580;&#1607;&#1575;&#1586;
&#1570;&#1582;&#1585; &#1578;&#1587;&#1578;&#1582;&#1583;&#1605;&#1607;
&#1604;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;.
&#1575;&#1593;&#1578;&#1605;&#1575;&#1583;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1548; &#1602;&#1583;
&#1578;&#1588;&#1605;&#1604; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586; &#1607;&#1584;&#1607;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1605;&#1579;&#1604;
&#1571;&#1585;&#1602;&#1575;&#1605; &#1578;&#1593;&#1585;&#1610;&#1601;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586;
&#1608;&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;&#1548;
&#1606;&#1608;&#1593; &#1575;&#1604;&#1605;&#1578;&#1589;&#1601;&#1581;&#1548;
&#1606;&#1605;&#1608;&#1584;&#1580;
&#1575;&#1604;&#1571;&#1580;&#1607;&#1586;&#1577;&#1548;
&#1606;&#1592;&#1575;&#1605; &#1575;&#1604;&#1578;&#1588;&#1594;&#1610;&#1604;
&#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1578;&#1603;&#1608;&#1610;&#1606; &#1575;&#1604;&#1606;&#1592;&#1575;&#1605;.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1573;&#1588;&#1593;&#1575;&#1585;&#1575;&#1578;
&#1575;&#1604;&#1583;&#1601;&#1593;: &#1602;&#1583;
&#1606;&#1591;&#1604;&#1576; &#1573;&#1585;&#1587;&#1575;&#1604;
&#1573;&#1588;&#1593;&#1575;&#1585;&#1575;&#1578; &#1583;&#1601;&#1593;
&#1573;&#1604;&#1610;&#1603; &#1578;&#1578;&#1593;&#1604;&#1602; &#1576;&#1581;&#1587;&#1575;&#1576;&#1603;
&#1571;&#1608; &#1605;&#1610;&#1586;&#1575;&#1578;
&#1605;&#1593;&#1610;&#1606;&#1577; &#1601;&#1610;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;. &#1573;&#1584;&#1575;
&#1603;&#1606;&#1578; &#1578;&#1585;&#1594;&#1576; &#1601;&#1610;
&#1575;&#1604;&#1575;&#1588;&#1578;&#1585;&#1575;&#1603; &#1601;&#1610;
&#1593;&#1583;&#1605; &#1578;&#1604;&#1602;&#1610; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1606;&#1608;&#1593; &#1605;&#1606;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;&#1575;&#1578;&#1548;
&#1610;&#1605;&#1603;&#1606;&#1603; &#1573;&#1610;&#1602;&#1575;&#1601;
&#1578;&#1588;&#1594;&#1610;&#1604;&#1607;&#1575; &#1601;&#1610;
&#1573;&#1593;&#1583;&#1575;&#1583;&#1575;&#1578; &#1580;&#1607;&#1575;&#1586;&#1603;.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1578;&#1615;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577; &#1576;&#1588;&#1603;&#1604;
&#1571;&#1587;&#1575;&#1587;&#1610; &#1604;&#1604;&#1581;&#1601;&#1575;&#1592;
&#1593;&#1604;&#1609; &#1571;&#1605;&#1575;&#1606;
&#1608;&#1578;&#1588;&#1594;&#1610;&#1604;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1604;&#1583;&#1610;&#1606;&#1575;&#1548; &#1604;&#1575;&#1587;&#1578;&#1603;&#1588;&#1575;&#1601;
&#1575;&#1604;&#1571;&#1582;&#1591;&#1575;&#1569;
&#1608;&#1573;&#1589;&#1604;&#1575;&#1581;&#1607;&#1575;
&#1608;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1578;&#1581;&#1604;&#1610;&#1604;
&#1608;&#1575;&#1604;&#1578;&#1602;&#1575;&#1585;&#1610;&#1585;
&#1575;&#1604;&#1583;&#1575;&#1582;&#1604;&#1610;&#1577;
&#1604;&#1583;&#1610;&#1606;&#1575;.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;: &#1602;&#1583;
&#1578;&#1588;&#1605;&#1604; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1581;&#1608;&#1604; &#1578;&#1601;&#1590;&#1610;&#1604;&#1575;&#1578;&#1603;
&#1601;&#1610; &#1578;&#1604;&#1602;&#1610;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602; &#1605;&#1606;&#1575;
&#1608;&#1578;&#1601;&#1590;&#1610;&#1604;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
&#1576;&#1603;. &#1575;&#1604;&#1571;&#1587;&#1575;&#1587;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;
&#1604;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1607;&#1608;
&#1605;&#1589;&#1575;&#1604;&#1581;&#1606;&#1575;
&#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;
&#1608;&#1575;&#1604;&#1578;&#1610; &#1601;&#1610; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1581;&#1575;&#1604;&#1577; &#1607;&#1610;
&#1583;&#1585;&#1575;&#1587;&#1577; &#1603;&#1610;&#1601;&#1610;&#1577;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605; &#1575;&#1604;&#1593;&#1605;&#1604;&#1575;&#1569;
&#1604;&#1605;&#1606;&#1578;&#1580;&#1575;&#1578;&#1606;&#1575;/&#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;&#1548;
&#1604;&#1578;&#1591;&#1608;&#1610;&#1585;&#1607;&#1575;&#1548;
&#1604;&#1606;&#1605;&#1608; &#1571;&#1593;&#1605;&#1575;&#1604;&#1606;&#1575;
&#1608;&#1604;&#1578;&#1581;&#1583;&#1610;&#1583;
&#1575;&#1587;&#1578;&#1585;&#1575;&#1578;&#1610;&#1580;&#1610;&#1577;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
&#1576;&#1606;&#1575;.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1602;&#1583;
&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1548;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1583;&#1601;&#1593;&#1548;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;&#1548;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577;
&#1608;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;
(&#1575;&#1604;&#1605;&#1588;&#1575;&#1585; &#1573;&#1604;&#1610;&#1607;&#1575;
&#1580;&#1605;&#1610;&#1593;&#1611;&#1575; &#1576;&#1600;
&quot;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&quot;)
&#1604;&#1578;&#1602;&#1583;&#1610;&#1605;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1604;&#1603;.
&#1602;&#1583; &#1606;&#1587;&#1578;&#1582;&#1583;&#1605; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1571;&#1610;&#1590;&#1611;&#1575;
&#1604;&#1573;&#1585;&#1587;&#1575;&#1604;
&#1575;&#1578;&#1589;&#1575;&#1604;&#1575;&#1578;
&#1578;&#1587;&#1608;&#1610;&#1602;&#1610;&#1577; &#1571;&#1582;&#1585;&#1609;
&#1604;&#1603;.&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;&#1548;
&#1608;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1578;&#1581;&#1604;&#1610;&#1604;&#1575;&#1578;
&#1575;&#1604;&#1583;&#1575;&#1582;&#1604;&#1610;&#1577; &#1608;&#1575;&#1604;&#1578;&#1602;&#1575;&#1585;&#1610;&#1585;
&#1604;&#1583;&#1610;&#1606;&#1575;.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1606;&#1580;&#1605;&#1593;&#1607;&#1575;
&#1578;&#1588;&#1605;&#1604; &#1605;&#1575; &#1610;&#1604;&#1610;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>:</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
67.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1587;&#1580;&#1604;
&#1608;&#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;:
&#1607;&#1610; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1575;&#1604;&#1582;&#1583;&#1605;&#1577;&#1548;
&#1578;&#1588;&#1582;&#1610;&#1589;&#1610;&#1577;&#1548;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1610;&#1577;
&#1608;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1571;&#1583;&#1575;&#1569;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1602;&#1608;&#1605;
&#1582;&#1608;&#1575;&#1583;&#1605;&#1606;&#1575;
&#1576;&#1580;&#1605;&#1593;&#1607;&#1575;
&#1578;&#1604;&#1602;&#1575;&#1574;&#1610;&#1611;&#1575; &#1593;&#1606;&#1583;&#1605;&#1575;
&#1578;&#1589;&#1604; &#1571;&#1608; &#1578;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1608;&#1575;&#1604;&#1578;&#1610; &#1606;&#1602;&#1608;&#1605;
&#1576;&#1578;&#1587;&#1580;&#1610;&#1604;&#1607;&#1575; &#1601;&#1610;
&#1605;&#1604;&#1601;&#1575;&#1578; &#1575;&#1604;&#1587;&#1580;&#1604;.
&#1575;&#1593;&#1578;&#1605;&#1575;&#1583;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1603;&#1610;&#1601;&#1610;&#1577; &#1578;&#1601;&#1575;&#1593;&#1604;&#1603;
&#1605;&#1593;&#1606;&#1575;&#1548; &#1602;&#1583; &#1578;&#1588;&#1605;&#1604;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1587;&#1580;&#1604;
&#1607;&#1584;&#1607; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1593;&#1606; &#1580;&#1607;&#1575;&#1586;&#1603;&#1548; &#1575;&#1604;&#1573;&#1593;&#1583;&#1575;&#1583;&#1575;&#1578;
&#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
&#1606;&#1588;&#1575;&#1591;&#1603; &#1601;&#1610;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602; (&#1605;&#1579;&#1604;
&#1575;&#1604;&#1591;&#1608;&#1575;&#1576;&#1593;
&#1575;&#1604;&#1586;&#1605;&#1606;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1585;&#1578;&#1576;&#1591;&#1577;
&#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;&#1548; &#1575;&#1604;&#1589;&#1601;&#1581;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1605;&#1604;&#1601;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1605;
&#1593;&#1585;&#1590;&#1607;&#1575;&#1548; &#1575;&#1604;&#1576;&#1581;&#1579;
&#1608;&#1594;&#1610;&#1585;&#1607;&#1575; &#1605;&#1606;
&#1575;&#1604;&#1573;&#1580;&#1585;&#1575;&#1569;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1602;&#1608;&#1605; &#1576;&#1607;&#1575;
&#1605;&#1579;&#1604; &#1575;&#1604;&#1605;&#1610;&#1586;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1587;&#1578;&#1582;&#1583;&#1605;&#1607;&#1575;)&#1548;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1581;&#1583;&#1579; &#1601;&#1610;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586; (&#1605;&#1579;&#1604;
&#1606;&#1588;&#1575;&#1591; &#1575;&#1604;&#1606;&#1592;&#1575;&#1605;&#1548;
&#1578;&#1602;&#1575;&#1585;&#1610;&#1585; &#1575;&#1604;&#1582;&#1591;&#1571;
(&#1575;&#1604;&#1578;&#1610; &#1578;&#1587;&#1605;&#1609;
&#1571;&#1581;&#1610;&#1575;&#1606;&#1611;&#1575; &quot;&#1578;&#1602;&#1575;&#1585;&#1610;&#1585;
&#1575;&#1604;&#1575;&#1606;&#1607;&#1610;&#1575;&#1585;&quot;)
&#1608;&#1573;&#1593;&#1583;&#1575;&#1583;&#1575;&#1578;
&#1575;&#1604;&#1571;&#1580;&#1607;&#1586;&#1577;)</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
67.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586;: &#1606;&#1580;&#1605;&#1593;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586; &#1605;&#1579;&#1604;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
&#1575;&#1604;&#1603;&#1605;&#1576;&#1610;&#1608;&#1578;&#1585;&#1548;
&#1575;&#1604;&#1607;&#1575;&#1578;&#1601;&#1548;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586;
&#1575;&#1604;&#1604;&#1608;&#1581;&#1610; &#1571;&#1608; &#1571;&#1610;
&#1580;&#1607;&#1575;&#1586; &#1570;&#1582;&#1585;
&#1578;&#1587;&#1578;&#1582;&#1583;&#1605;&#1607;
&#1604;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;.
&#1575;&#1593;&#1578;&#1605;&#1575;&#1583;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1548; &#1602;&#1583;
&#1578;&#1588;&#1605;&#1604; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586; &#1607;&#1584;&#1607;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1605;&#1579;&#1604;
&#1571;&#1585;&#1602;&#1575;&#1605; &#1578;&#1593;&#1585;&#1610;&#1601;
&#1575;&#1604;&#1580;&#1607;&#1575;&#1586; &#1608;&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;&#1548;
&#1606;&#1608;&#1593; &#1575;&#1604;&#1605;&#1578;&#1589;&#1601;&#1581;&#1548;
&#1606;&#1605;&#1608;&#1584;&#1580;
&#1575;&#1604;&#1571;&#1580;&#1607;&#1586;&#1577;&#1548;
&#1606;&#1592;&#1575;&#1605; &#1575;&#1604;&#1578;&#1588;&#1594;&#1610;&#1604;
&#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1578;&#1603;&#1608;&#1610;&#1606; &#1575;&#1604;&#1606;&#1592;&#1575;&#1605;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
67.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1573;&#1588;&#1593;&#1575;&#1585;&#1575;&#1578;
&#1575;&#1604;&#1583;&#1601;&#1593;: &#1602;&#1583;
&#1606;&#1591;&#1604;&#1576; &#1573;&#1585;&#1587;&#1575;&#1604;
&#1573;&#1588;&#1593;&#1575;&#1585;&#1575;&#1578; &#1583;&#1601;&#1593;
&#1573;&#1604;&#1610;&#1603; &#1578;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1581;&#1587;&#1575;&#1576;&#1603; &#1571;&#1608;
&#1605;&#1610;&#1586;&#1575;&#1578; &#1605;&#1593;&#1610;&#1606;&#1577;
&#1601;&#1610; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;.
&#1573;&#1584;&#1575; &#1603;&#1606;&#1578; &#1578;&#1585;&#1594;&#1576;
&#1601;&#1610; &#1575;&#1604;&#1575;&#1588;&#1578;&#1585;&#1575;&#1603;
&#1601;&#1610; &#1593;&#1583;&#1605; &#1578;&#1604;&#1602;&#1610;
&#1607;&#1584;&#1575; &#1575;&#1604;&#1606;&#1608;&#1593; &#1605;&#1606;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;&#1575;&#1578;&#1548;
&#1610;&#1605;&#1603;&#1606;&#1603; &#1573;&#1610;&#1602;&#1575;&#1601;
&#1578;&#1588;&#1594;&#1610;&#1604;&#1607;&#1575; &#1601;&#1610;
&#1573;&#1593;&#1583;&#1575;&#1583;&#1575;&#1578;
&#1580;&#1607;&#1575;&#1586;&#1603;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
67.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1578;&#1615;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577; &#1576;&#1588;&#1603;&#1604;
&#1571;&#1587;&#1575;&#1587;&#1610; &#1604;&#1604;&#1581;&#1601;&#1575;&#1592;
&#1593;&#1604;&#1609; &#1571;&#1605;&#1575;&#1606;
&#1608;&#1578;&#1588;&#1594;&#1610;&#1604;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1604;&#1583;&#1610;&#1606;&#1575;&#1548;
&#1604;&#1575;&#1587;&#1578;&#1603;&#1588;&#1575;&#1601;
&#1575;&#1604;&#1571;&#1582;&#1591;&#1575;&#1569;
&#1608;&#1573;&#1589;&#1604;&#1575;&#1581;&#1607;&#1575;
&#1608;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1578;&#1581;&#1604;&#1610;&#1604; &#1608;&#1575;&#1604;&#1578;&#1602;&#1575;&#1585;&#1610;&#1585;
&#1575;&#1604;&#1583;&#1575;&#1582;&#1604;&#1610;&#1577;
&#1604;&#1583;&#1610;&#1606;&#1575;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
67.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;: &#1602;&#1583;
&#1578;&#1588;&#1605;&#1604; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1581;&#1608;&#1604; &#1578;&#1601;&#1590;&#1610;&#1604;&#1575;&#1578;&#1603;
&#1601;&#1610; &#1578;&#1604;&#1602;&#1610;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602; &#1605;&#1606;&#1575;
&#1608;&#1578;&#1601;&#1590;&#1610;&#1604;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604; &#1575;&#1604;&#1582;&#1575;&#1589;&#1577;
&#1576;&#1603;. &#1575;&#1604;&#1571;&#1587;&#1575;&#1587;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;
&#1604;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1607;&#1608;
&#1605;&#1589;&#1575;&#1604;&#1581;&#1606;&#1575;
&#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;
&#1608;&#1575;&#1604;&#1578;&#1610; &#1601;&#1610; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1581;&#1575;&#1604;&#1577; &#1607;&#1610;
&#1583;&#1585;&#1575;&#1587;&#1577; &#1603;&#1610;&#1601;&#1610;&#1577;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1593;&#1605;&#1604;&#1575;&#1569;
&#1604;&#1605;&#1606;&#1578;&#1580;&#1575;&#1578;&#1606;&#1575;/&#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;&#1548;
&#1604;&#1578;&#1591;&#1608;&#1610;&#1585;&#1607;&#1575;&#1548;
&#1604;&#1606;&#1605;&#1608; &#1571;&#1593;&#1605;&#1575;&#1604;&#1606;&#1575;
&#1608;&#1604;&#1578;&#1581;&#1583;&#1610;&#1583; &#1575;&#1587;&#1578;&#1585;&#1575;&#1578;&#1610;&#1580;&#1610;&#1577;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1606;&#1575;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1602;&#1583;
&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1548;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1583;&#1601;&#1593;&#1548;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;&#1548;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577;
&#1608;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;
(&#1575;&#1604;&#1605;&#1588;&#1575;&#1585; &#1573;&#1604;&#1610;&#1607;&#1575;
&#1580;&#1605;&#1610;&#1593;&#1611;&#1575; &#1576;&#1600;
&quot;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&quot;)
&#1604;&#1578;&#1602;&#1583;&#1610;&#1605;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1604;&#1603;.
&#1602;&#1583; &#1606;&#1587;&#1578;&#1582;&#1583;&#1605; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1571;&#1610;&#1590;&#1611;&#1575; &#1604;&#1573;&#1585;&#1587;&#1575;&#1604;
&#1575;&#1578;&#1589;&#1575;&#1604;&#1575;&#1578;
&#1578;&#1587;&#1608;&#1610;&#1602;&#1610;&#1577; &#1571;&#1582;&#1585;&#1609;
&#1604;&#1603;.</span></p>

<p class=MsoListParagraph dir=RTL style='margin-top:0in;margin-right:.5in;
margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1587;&#1605;
&#1608;&#1593;&#1606;&#1608;&#1575;&#1606; &#1605;&#1578;&#1581;&#1603;&#1605;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1605;&#1578;&#1581;&#1603;&#1605;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> (GDPR)</span><span dir=RTL></span><span
lang=AR-EG style='font-family:"Arial","sans-serif"'><span dir=RTL></span>&#1548;
&#1608;&#1602;&#1608;&#1575;&#1606;&#1610;&#1606;
&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1571;&#1582;&#1585;&#1609;
&#1575;&#1604;&#1605;&#1593;&#1605;&#1608;&#1604; &#1576;&#1607;&#1575;
&#1601;&#1610; &#1575;&#1604;&#1583;&#1608;&#1604;
&#1575;&#1604;&#1571;&#1593;&#1590;&#1575;&#1569;
&#1576;&#1575;&#1604;&#1575;&#1578;&#1581;&#1575;&#1583;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1608;&#1571;&#1581;&#1603;&#1575;&#1605;
&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1571;&#1582;&#1585;&#1609; &#1584;&#1575;&#1578;
&#1575;&#1604;&#1589;&#1604;&#1577; &#1607;&#1608;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>:</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1575;&#1587;&#1605;:
&#1593;&#1605;&#1585;&#1608;
&#1575;&#1604;&#1576;&#1575;&#1587;&#1610;&#1608;&#1606;&#1610;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>:
Hello@jeelapp.com</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1585;&#1602;&#1605;
&#1575;&#1604;&#1607;&#1575;&#1578;&#1601;: +13022000244</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1593;&#1606;&#1608;&#1575;&#1606;:
423 &#1588;&#1575;&#1585;&#1593; &#1580;&#1610;&#1605;&#1587;
&#1605;&#1603;&#1578;&#1576; #2&#1548;
&#1587;&#1610;&#1585;&#1575;&#1603;&#1610;&#1608;&#1586;&#1548;
&#1606;&#1610;&#1608;&#1610;&#1608;&#1585;&#1603;&#1548; 13203&#1548;
&#1575;&#1604;&#1608;&#1604;&#1575;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1578;&#1581;&#1583;&#1577;
&#1575;&#1604;&#1571;&#1605;&#1585;&#1610;&#1603;&#1610;&#1577;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;:
&#1580;&#1610;&#1604;</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;: </span><span
lang=EN-GB dir=LTR>Jeelapp.com</span></p>

<p class=MsoNormal dir=RTL style='margin-right:.25in;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
&#1608;&#1587;&#1581;&#1576;&#1607;&#1575;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1593;&#1606;&#1583;
&#1586;&#1610;&#1575;&#1585;&#1578;&#1603;
&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;&#1548;
&#1601;&#1573;&#1606;&#1603; &#1578;&#1605;&#1606;&#1581;&#1606;&#1575;
&#1575;&#1604;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577; &#1593;&#1604;&#1609;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1608;&#1601;&#1602;&#1611;&#1575; &#1604;&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1607;&#1584;&#1607;.
&#1605;&#1606; &#1571;&#1580;&#1604; &#1578;&#1602;&#1583;&#1610;&#1605;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1604;&#1603;&#1548;
&#1605;&#1606; &#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1610; &#1571;&#1606;
&#1606;&#1580;&#1605;&#1593; &#1603;&#1604; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1584;&#1575;&#1578; &#1575;&#1604;&#1589;&#1604;&#1577;
&#1608;&#1575;&#1604;&#1604;&#1575;&#1586;&#1605;&#1577; &#1593;&#1606;&#1603;
&#1605;&#1606;&#1603;. &#1576;&#1575;&#1604;&#1573;&#1590;&#1575;&#1601;&#1577;
&#1573;&#1604;&#1609; &#1584;&#1604;&#1603;&#1548; &#1593;&#1606;&#1583;
&#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604; &#1608;&#1605;&#1604;&#1569;
&#1575;&#1604;&#1606;&#1605;&#1575;&#1584;&#1580; &#1575;&#1604;&#1605;&#1578;&#1575;&#1581;&#1577;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;&#1548;
&#1601;&#1573;&#1606;&#1603; &#1578;&#1605;&#1606;&#1581;&#1606;&#1575;
&#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603; &#1575;&#1604;&#1589;&#1585;&#1610;&#1581;&#1577;
&#1608;&#1573;&#1584;&#1606;&#1603;
&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1587;&#1606;&#1602;&#1608;&#1605;
&#1576;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1601;&#1602;&#1591;
&#1576;&#1593;&#1583; &#1575;&#1604;&#1581;&#1589;&#1608;&#1604;
&#1593;&#1604;&#1609; &#1578;&#1593;&#1604;&#1610;&#1605;&#1575;&#1578;/&#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
&#1582;&#1591;&#1610;&#1577; &#1605;&#1606;&#1603;&#1548; &#1601;&#1610;
&#1575;&#1604;&#1594;&#1575;&#1604;&#1576; &#1576;&#1588;&#1603;&#1604;
&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1593;&#1604;&#1609;
&#1588;&#1603;&#1604; &#1586;&#1585; &#1602;&#1575;&#1576;&#1604;
&#1604;&#1604;&#1606;&#1602;&#1585;&#1548; &#1582;&#1575;&#1606;&#1577;
&#1575;&#1582;&#1578;&#1610;&#1575;&#1585;&#1548; &#1571;&#1608; &#1605;&#1606;
&#1582;&#1604;&#1575;&#1604; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1573;&#1584;&#1575;
&#1594;&#1610;&#1585;&#1578; &#1585;&#1571;&#1610;&#1603;&#1548;
&#1610;&#1605;&#1603;&#1606;&#1603; &#1587;&#1581;&#1576;
&#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603;
&#1604;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604; &#1605;&#1593;&#1603;&#1548;
&#1604;&#1575;&#1587;&#1578;&#1605;&#1585;&#1575;&#1585;
&#1580;&#1605;&#1593;&#1548; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1571;&#1608; &#1575;&#1604;&#1603;&#1588;&#1601; &#1593;&#1606;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;&#1548; &#1601;&#1610;
&#1571;&#1610; &#1608;&#1602;&#1578;&#1548; &#1593;&#1606;
&#1591;&#1585;&#1610;&#1602; &#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604;
&#1605;&#1593;&#1606;&#1575; &#1593;&#1604;&#1609;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> Hello@jeelapp.com.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1585;&#1587;&#1575;&#1574;&#1604;
&#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1575;&#1604;&#1578;&#1585;&#1608;&#1610;&#1580;&#1610;&#1577;</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1571;&#1606;&#1578;
&#1578;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609; &#1571;&#1606;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602; &#1608;/&#1571;&#1608;
&#1575;&#1604;&#1571;&#1591;&#1585;&#1575;&#1601;
&#1575;&#1604;&#1579;&#1575;&#1604;&#1579;&#1577; &#1602;&#1583;
&#1578;&#1585;&#1587;&#1604; &#1605;&#1606; &#1608;&#1602;&#1578;
&#1604;&#1570;&#1582;&#1585; &#1585;&#1587;&#1575;&#1574;&#1604;
&#1576;&#1585;&#1610;&#1583;
&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610; &#1573;&#1604;&#1610;&#1603;
&#1578;&#1593;&#1585;&#1590; &#1605;&#1606;&#1578;&#1580;&#1575;&#1578;
&#1608;&#1582;&#1583;&#1605;&#1575;&#1578;&#1548;
&#1578;&#1585;&#1608;&#1610;&#1580;&#1575;&#1578;&#1548;
&#1575;&#1588;&#1578;&#1585;&#1575;&#1603;&#1575;&#1578; &#1571;&#1608;
&#1582;&#1583;&#1605;&#1575;&#1578; &#1602;&#1575;&#1574;&#1605;&#1577;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1578;&#1587;&#1580;&#1610;&#1604;
&#1571;&#1608; &#1605;&#1608;&#1575;&#1583; &#1571;&#1582;&#1585;&#1609;.
&#1573;&#1584;&#1575; &#1603;&#1606;&#1578; &#1578;&#1585;&#1594;&#1576;
&#1601;&#1610; &#1575;&#1604;&#1578;&#1608;&#1602;&#1601; &#1593;&#1606;
&#1578;&#1604;&#1602;&#1610; &#1605;&#1579;&#1604; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1548;
&#1610;&#1605;&#1603;&#1606;&#1603; &#1575;&#1582;&#1578;&#1610;&#1575;&#1585;
&#1593;&#1583;&#1605; &#1575;&#1604;&#1575;&#1588;&#1578;&#1585;&#1575;&#1603;
&#1593;&#1606; &#1591;&#1585;&#1610;&#1602; &#1603;&#1578;&#1575;&#1576;&#1577;
&#1585;&#1587;&#1575;&#1604;&#1577; &#1573;&#1604;&#1610;&#1606;&#1575;
&#1593;&#1576;&#1585; &#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;
&#1593;&#1604;&#1609; </span><span lang=EN-GB dir=LTR>Hello@jeelapp.com</span><span
dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'><span
dir=RTL></span>. &#1587;&#1610;&#1578;&#1605; &#1576;&#1593;&#1583;
&#1584;&#1604;&#1603; &#1578;&#1581;&#1583;&#1610;&#1579;
&#1578;&#1601;&#1590;&#1610;&#1604;&#1575;&#1578;&#1603;.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1603;&#1610;&#1601;
&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1608;&#1606;&#1593;&#1575;&#1604;&#1580;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1602;&#1583;
&#1610;&#1578;&#1605; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1606;&#1580;&#1605;&#1593;&#1607;&#1575;
&#1605;&#1606;&#1603; &#1604;&#1578;&#1602;&#1583;&#1610;&#1605;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1604;&#1603;
&#1608;&#1604;&#1601;&#1607;&#1605;
&#1575;&#1581;&#1578;&#1610;&#1575;&#1580;&#1575;&#1578;&#1603;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1576;&#1585;&#1575;&#1605;&#1580;
&#1576;&#1588;&#1603;&#1604; &#1571;&#1601;&#1590;&#1604;&#1548;
&#1604;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604; &#1605;&#1593;&#1603;
&#1608;&#1575;&#1604;&#1585;&#1583; &#1593;&#1604;&#1609;
&#1571;&#1587;&#1574;&#1604;&#1578;&#1603; &#1581;&#1608;&#1604;
&#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1604;&#1606;
&#1606;&#1602;&#1608;&#1605; &#1576;&#1578;&#1571;&#1580;&#1610;&#1585;
&#1571;&#1608; &#1576;&#1610;&#1593;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1604;&#1604;&#1570;&#1582;&#1585;&#1610;&#1606;. &#1602;&#1583;
&#1606;&#1582;&#1586;&#1606;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1601;&#1610;
&#1605;&#1608;&#1575;&#1602;&#1593; &#1582;&#1575;&#1585;&#1580;
&#1587;&#1610;&#1591;&#1585;&#1578;&#1606;&#1575;
&#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;&#1577; (&#1593;&#1604;&#1609;
&#1587;&#1576;&#1610;&#1604; &#1575;&#1604;&#1605;&#1579;&#1575;&#1604;&#1548;
&#1593;&#1604;&#1609; &#1582;&#1608;&#1575;&#1583;&#1605; &#1571;&#1608;
&#1602;&#1608;&#1575;&#1593;&#1583; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1605;&#1588;&#1578;&#1585;&#1603;&#1577; &#1605;&#1593;
&#1605;&#1586;&#1608;&#1583;&#1610;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1590;&#1575;&#1601;&#1577;)</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1606;&#1602;&#1608;&#1605;
&#1576;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1578;&#1587;&#1578;&#1606;&#1583;
&#1573;&#1604;&#1609; &#1605;&#1589;&#1575;&#1604;&#1581;
&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;&#1548;
&#1608;&#1573;&#1578;&#1605;&#1575;&#1605; &#1593;&#1602;&#1583;&#1606;&#1575;
&#1605;&#1593;&#1603;&#1548;
&#1608;&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;&#1606;&#1575;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;&#1548;
&#1608;/&#1571;&#1608; &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603; &#1575;&#1604;&#1578;&#1610;
&#1606;&#1580;&#1605;&#1593;&#1607;&#1575; &#1593;&#1576;&#1585;
&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575; &#1604;&#1605;&#1580;&#1605;&#1608;&#1593;&#1577;
&#1605;&#1578;&#1606;&#1608;&#1593;&#1577; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
&#1576;&#1575;&#1604;&#1575;&#1593;&#1578;&#1605;&#1575;&#1583;
&#1593;&#1604;&#1609; &#1605;&#1589;&#1575;&#1604;&#1581;&#1606;&#1575;
&#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;&#1548; &#1605;&#1606;
&#1571;&#1580;&#1604; &#1575;&#1604;&#1583;&#1582;&#1608;&#1604; &#1601;&#1610;
&#1593;&#1602;&#1583; &#1571;&#1608; &#1571;&#1583;&#1575;&#1574;&#1607;
&#1605;&#1593;&#1603;&#1548;
&#1576;&#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603;&#1548;
&#1608;/&#1571;&#1608; &#1604;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;&#1606;&#1575;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1573;&#1584;&#1575;
&#1602;&#1583;&#1605;&#1578; &#1571;&#1610;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1604;&#1606;&#1575;&#1548;
&#1601;&#1573;&#1606;&#1603; &#1578;&#1593;&#1578;&#1576;&#1585; &#1602;&#1583;
&#1571;&#1593;&#1591;&#1610;&#1578;&#1606;&#1575;
&#1575;&#1604;&#1578;&#1601;&#1608;&#1610;&#1590;
&#1604;&#1580;&#1605;&#1593;&#1607;&#1575;&#1548;
&#1575;&#1604;&#1575;&#1581;&#1578;&#1601;&#1575;&#1592;
&#1576;&#1607;&#1575;&#1548; &#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1578;&#1604;&#1603; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1604;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>:</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1578;&#1581;&#1602;&#1602;
&#1605;&#1606; &#1607;&#1608;&#1610;&#1578;&#1603;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1578;&#1608;&#1601;&#1610;&#1585;
&#1582;&#1583;&#1605;&#1577; &#1575;&#1604;&#1593;&#1605;&#1604;&#1575;&#1569;
&#1608;&#1575;&#1604;&#1585;&#1583; &#1593;&#1604;&#1609;
&#1575;&#1587;&#1578;&#1601;&#1587;&#1575;&#1585;&#1575;&#1578;&#1603;&#1548; &#1605;&#1604;&#1575;&#1581;&#1592;&#1575;&#1578;&#1603;&#1548;
&#1571;&#1608; &#1575;&#1604;&#1606;&#1586;&#1575;&#1593;&#1575;&#1578;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1573;&#1580;&#1585;&#1575;&#1569;
&#1575;&#1604;&#1573;&#1601;&#1589;&#1575;&#1581;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1602;&#1583; &#1578;&#1603;&#1608;&#1606;
&#1605;&#1591;&#1604;&#1608;&#1576;&#1577; &#1604;&#1571;&#1610; &#1605;&#1606;
&#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1605;&#1584;&#1603;&#1608;&#1585;&#1577;
&#1571;&#1593;&#1604;&#1575;&#1607; &#1571;&#1608; &#1603;&#1605;&#1575;
&#1607;&#1608; &#1605;&#1591;&#1604;&#1608;&#1576; &#1576;&#1605;&#1608;&#1580;&#1576;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1548;
&#1575;&#1604;&#1604;&#1608;&#1575;&#1574;&#1581;
&#1608;&#1575;&#1604;&#1605;&#1576;&#1575;&#1583;&#1574;
&#1575;&#1604;&#1578;&#1608;&#1580;&#1610;&#1607;&#1610;&#1577; &#1571;&#1608;
&#1601;&#1610;&#1605;&#1575; &#1610;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1571;&#1610; &#1578;&#1581;&#1602;&#1610;&#1602;&#1575;&#1578;&#1548;
&#1605;&#1591;&#1575;&#1604;&#1576;&#1575;&#1578; &#1571;&#1608;
&#1605;&#1591;&#1575;&#1604;&#1576;&#1575;&#1578;
&#1605;&#1581;&#1578;&#1605;&#1604;&#1577; &#1605;&#1608;&#1580;&#1607;&#1577;
&#1590;&#1583;&#1606;&#1575; &#1571;&#1608; &#1604;&#1606;&#1575;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1578;&#1608;&#1601;&#1610;&#1585;
&#1608;&#1589;&#1610;&#1575;&#1606;&#1577;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1573;&#1582;&#1591;&#1575;&#1585;&#1603;
&#1576;&#1575;&#1604;&#1578;&#1594;&#1610;&#1610;&#1585;&#1575;&#1578;
&#1593;&#1604;&#1609; &#1582;&#1583;&#1605;&#1575;&#1578;&#1606;&#1575;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1573;&#1583;&#1575;&#1585;&#1577;
&#1581;&#1587;&#1575;&#1576;&#1603;
&#1608;&#1575;&#1604;&#1581;&#1601;&#1575;&#1592; &#1593;&#1604;&#1610;&#1607;
&#1601;&#1610; &#1581;&#1575;&#1604;&#1577;
&#1593;&#1605;&#1604;&#1610;&#1577;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1573;&#1585;&#1587;&#1575;&#1604;
&#1575;&#1578;&#1589;&#1575;&#1604;&#1575;&#1578;
&#1578;&#1587;&#1608;&#1610;&#1602;&#1610;&#1577;
&#1608;&#1578;&#1585;&#1608;&#1610;&#1580;&#1610;&#1577;: &#1606;&#1581;&#1606;
&#1608;/&#1571;&#1608; &#1588;&#1585;&#1603;&#1575;&#1572;&#1606;&#1575; &#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;&#1610;&#1608;&#1606;
&#1575;&#1604;&#1582;&#1575;&#1585;&#1580;&#1610;&#1608;&#1606; &#1602;&#1583;
&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610;
&#1578;&#1585;&#1587;&#1604;&#1607;&#1575; &#1604;&#1606;&#1575;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602; &#1604;&#1583;&#1610;&#1606;&#1575;&#1548;
&#1573;&#1584;&#1575; &#1603;&#1575;&#1606; &#1584;&#1604;&#1603;
&#1608;&#1601;&#1602;&#1611;&#1575;
&#1604;&#1578;&#1601;&#1590;&#1610;&#1604;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602; &#1604;&#1583;&#1610;&#1603;&#1563;</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1578;&#1602;&#1583;&#1610;&#1605;
&#1573;&#1593;&#1604;&#1575;&#1606;&#1575;&#1578;
&#1605;&#1587;&#1578;&#1607;&#1583;&#1601;&#1577; &#1604;&#1603;:
&#1602;&#1583; &#1606;&#1587;&#1578;&#1582;&#1583;&#1605;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
&#1604;&#1578;&#1591;&#1608;&#1610;&#1585; &#1608;&#1593;&#1585;&#1590;
&#1605;&#1581;&#1578;&#1608;&#1609;
&#1608;&#1573;&#1593;&#1604;&#1575;&#1606;&#1575;&#1578;
&#1605;&#1582;&#1589;&#1589;&#1577; (&#1608;&#1575;&#1604;&#1593;&#1605;&#1604;
&#1605;&#1593; &#1571;&#1591;&#1585;&#1575;&#1601;
&#1579;&#1575;&#1604;&#1579;&#1577; &#1575;&#1604;&#1584;&#1610;&#1606;
&#1610;&#1601;&#1593;&#1604;&#1608;&#1606; &#1584;&#1604;&#1603;)
&#1605;&#1589;&#1605;&#1605;&#1577; &#1604;&#1575;&#1607;&#1578;&#1605;&#1575;&#1605;&#1575;&#1578;&#1603;
&#1608;/&#1571;&#1608; &#1605;&#1608;&#1602;&#1593;&#1603;
&#1608;&#1602;&#1610;&#1575;&#1587;
&#1601;&#1593;&#1575;&#1604;&#1610;&#1578;&#1607;&#1575;.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1578;&#1581;&#1602;&#1610;&#1602;
&#1571;&#1594;&#1585;&#1575;&#1590; &#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
&#1571;&#1582;&#1585;&#1609;&#1548; &#1605;&#1579;&#1604;
&#1578;&#1581;&#1604;&#1610;&#1604; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548;
&#1578;&#1581;&#1583;&#1610;&#1583;
&#1575;&#1578;&#1580;&#1575;&#1607;&#1575;&#1578;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1548;
&#1578;&#1581;&#1583;&#1610;&#1583; &#1601;&#1593;&#1575;&#1604;&#1610;&#1577;
&#1581;&#1605;&#1604;&#1575;&#1578;&#1606;&#1575;
&#1575;&#1604;&#1578;&#1585;&#1608;&#1610;&#1580;&#1610;&#1577;&#1548;
&#1608;&#1578;&#1602;&#1610;&#1610;&#1605;
&#1608;&#1578;&#1581;&#1587;&#1610;&#1606; &#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;&#1548;
&#1605;&#1606;&#1578;&#1580;&#1575;&#1578;&#1606;&#1575;&#1548;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;&#1548;
&#1608;&#1578;&#1580;&#1585;&#1576;&#1578;&#1603;. &#1602;&#1583;
&#1606;&#1587;&#1578;&#1582;&#1583;&#1605; &#1608;&#1606;&#1582;&#1586;&#1606;
&#1607;&#1584;&#1607; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1576;&#1588;&#1603;&#1604; &#1605;&#1580;&#1605;&#1593;
&#1608;&#1605;&#1580;&#1607;&#1608;&#1604; &#1576;&#1581;&#1610;&#1579;
&#1604;&#1575; &#1610;&#1578;&#1605; &#1585;&#1576;&#1591;&#1607;&#1575;
&#1576;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
&#1606;&#1607;&#1575;&#1574;&#1610;&#1610;&#1606;
&#1601;&#1585;&#1583;&#1610;&#1610;&#1606; &#1608;&#1604;&#1575;
&#1578;&#1578;&#1590;&#1605;&#1606;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1588;&#1582;&#1589;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1587;&#1606;&#1590;&#1605;&#1606;
&#1571;&#1606;</span><span dir=LTR></span><span lang=EN-GB dir=LTR><span
dir=LTR></span>:</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1610;&#1578;&#1605;
&#1580;&#1605;&#1593;&#1607;&#1575;
&#1608;&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;
&#1604;&#1606;&#1575; &#1608;&#1576;&#1575;&#1604;&#1606;&#1610;&#1575;&#1576;&#1577;
&#1593;&#1606;&#1575; &#1605;&#1606; &#1602;&#1576;&#1604; &#1571;&#1610;
&#1591;&#1585;&#1601; &#1610;&#1578;&#1605; &#1580;&#1605;&#1593;&#1607;&#1575;
&#1608;&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;
&#1576;&#1588;&#1603;&#1604; &#1593;&#1575;&#1583;&#1604;
&#1608;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1610;&#1578;&#1605;
&#1583;&#1575;&#1574;&#1605;&#1611;&#1575;
&#1573;&#1593;&#1604;&#1575;&#1605;&#1603; &#1576;&#1588;&#1603;&#1604;
&#1603;&#1575;&#1605;&#1604; &#1576;&#1571;&#1587;&#1576;&#1575;&#1576;
&#1580;&#1605;&#1593; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1608;&#1610;&#1578;&#1605; &#1578;&#1586;&#1608;&#1610;&#1583;&#1603;
&#1576;&#1578;&#1601;&#1575;&#1589;&#1610;&#1604;
&#1575;&#1604;&#1594;&#1585;&#1590; (&#1571;&#1594;&#1585;&#1575;&#1590;)
&#1575;&#1604;&#1584;&#1610; &#1587;&#1610;&#1578;&#1605; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1604;&#1571;&#1580;&#1604;&#1607;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1610;&#1578;&#1605;
&#1580;&#1605;&#1593; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1601;&#1602;&#1591; &#1573;&#1604;&#1609; &#1575;&#1604;&#1581;&#1583;
&#1575;&#1604;&#1584;&#1610; &#1610;&#1603;&#1608;&#1606; &#1590;&#1585;&#1608;&#1585;&#1610;&#1611;&#1575;
&#1604;&#1578;&#1581;&#1602;&#1610;&#1602; &#1575;&#1604;&#1594;&#1585;&#1590;
(&#1571;&#1594;&#1585;&#1575;&#1590;)
&#1575;&#1604;&#1605;&#1591;&#1604;&#1608;&#1576; &#1604;&#1607;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1604;&#1575;
&#1610;&#1578;&#1605; &#1575;&#1604;&#1575;&#1581;&#1578;&#1601;&#1575;&#1592;
&#1576;&#1571;&#1610; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1604;&#1601;&#1578;&#1585;&#1577; &#1571;&#1591;&#1608;&#1604; &#1605;&#1606;
&#1575;&#1604;&#1604;&#1575;&#1586;&#1605; &#1601;&#1610; &#1590;&#1608;&#1569;
&#1575;&#1604;&#1594;&#1585;&#1590; (&#1571;&#1594;&#1585;&#1575;&#1590;)
&#1575;&#1604;&#1605;&#1591;&#1604;&#1608;&#1576; &#1604;&#1607;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1601;&#1610;
&#1603;&#1604; &#1605;&#1585;&#1577; &#1610;&#1578;&#1605;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1605;&#1604;&#1601;&#1575;&#1578; &#1578;&#1593;&#1585;&#1610;&#1601;
&#1575;&#1604;&#1575;&#1585;&#1578;&#1576;&#1575;&#1591;
(&#1575;&#1604;&#1603;&#1608;&#1603;&#1610;&#1586;) &#1571;&#1608;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1605;&#1575;&#1579;&#1604;&#1577; &#1593;&#1576;&#1585;
&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578; &#1605;&#1606;
&#1602;&#1576;&#1604;&#1606;&#1575;&#1548; &#1587;&#1610;&#1578;&#1605;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575; &#1576;&#1583;&#1602;&#1577;
&#1608;&#1601;&#1602;&#1611;&#1575;
&#1604;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1571;&#1606;&#1578;
&#1605;&#1615;&#1591;&#1604;&#1593; &#1593;&#1604;&#1609; &#1571;&#1606;&#1607;
&#1573;&#1584;&#1575; &#1604;&#1605; &#1610;&#1578;&#1605;&#1603;&#1606;
&#1605;&#1606; &#1581;&#1584;&#1601; &#1571;&#1610; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1602;&#1583;&#1605;&#1578;&#1607;&#1575; &#1593;&#1576;&#1585;
&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;
&#1576;&#1606;&#1575;&#1569;&#1611; &#1593;&#1604;&#1609;
&#1591;&#1604;&#1576;&#1603; &#1578;&#1581;&#1578; &#1575;&#1604;&#1592;&#1585;&#1608;&#1601;
&#1575;&#1604;&#1593;&#1575;&#1583;&#1610;&#1577;&#1548;
&#1608;&#1603;&#1610;&#1601;&#1610;&#1577; &#1591;&#1604;&#1576;
&#1581;&#1584;&#1601; &#1571;&#1610; &#1606;&#1587;&#1582;
&#1571;&#1582;&#1585;&#1609; &#1605;&#1606; &#1578;&#1604;&#1603;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548;
&#1581;&#1610;&#1579; &#1610;&#1603;&#1608;&#1606; &#1605;&#1606;
&#1581;&#1602;&#1603; &#1575;&#1604;&#1602;&#1610;&#1575;&#1605;
&#1576;&#1584;&#1604;&#1603;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1578;&#1582;&#1575;&#1584;
&#1578;&#1583;&#1575;&#1576;&#1610;&#1585; &#1578;&#1602;&#1606;&#1610;&#1577;
&#1608;&#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577;
&#1605;&#1606;&#1575;&#1587;&#1576;&#1577; &#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1606;&#1602;&#1604;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1576;&#1588;&#1603;&#1604; &#1570;&#1605;&#1606;&#1548;
&#1587;&#1608;&#1575;&#1569; &#1603;&#1575;&#1606; &#1584;&#1604;&#1603;
&#1610;&#1578;&#1605; &#1606;&#1602;&#1604;&#1607;&#1575;
&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1611;&#1575;
&#1571;&#1608; &#1576;&#1606;&#1587;&#1582;&#1577;
&#1608;&#1585;&#1602;&#1610;&#1577;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1610;&#1605;&#1603;&#1606;&#1603;
&#1605;&#1605;&#1575;&#1585;&#1587;&#1577; &#1581;&#1602;&#1608;&#1602;&#1603;
&#1576;&#1588;&#1603;&#1604; &#1603;&#1575;&#1605;&#1604;
&#1608;&#1576;&#1587;&#1607;&#1608;&#1604;&#1577; &#1608;&#1576;&#1583;&#1608;&#1606;
&#1593;&#1608;&#1575;&#1574;&#1602;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1603;&#1588;&#1601;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1602;&#1583;
&#1604;&#1575; &#1606;&#1578;&#1605;&#1603;&#1606; &#1605;&#1606;
&#1575;&#1604;&#1581;&#1601;&#1575;&#1592; &#1593;&#1604;&#1609;
&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603; &#1601;&#1610; &#1575;&#1587;&#1578;&#1580;&#1575;&#1576;&#1577;
&#1604;&#1604;&#1593;&#1605;&#1604;&#1610;&#1577;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;&#1548;
&#1571;&#1610; &#1571;&#1605;&#1585; &#1575;&#1604;&#1605;&#1581;&#1603;&#1605;&#1577;
&#1571;&#1608; &#1605;&#1584;&#1603;&#1585;&#1577;
&#1575;&#1587;&#1578;&#1583;&#1593;&#1575;&#1569;&#1548; &#1571;&#1608;
&#1591;&#1604;&#1576; &#1608;&#1603;&#1575;&#1604;&#1577;
&#1573;&#1606;&#1601;&#1575;&#1584; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;.
&#1573;&#1584;&#1575; &#1603;&#1575;&#1606; &#1605;&#1606;
&#1608;&#1580;&#1607;&#1577; &#1606;&#1592;&#1585;&#1606;&#1575;
&#1605;&#1606;&#1575;&#1587;&#1576;&#1611;&#1575;
&#1604;&#1604;&#1578;&#1581;&#1602;&#1610;&#1602;&#1548; &#1605;&#1606;&#1593;&#1548;
&#1571;&#1608; &#1575;&#1578;&#1582;&#1575;&#1584;
&#1573;&#1580;&#1585;&#1575;&#1569;&#1575;&#1578; &#1576;&#1588;&#1571;&#1606;
&#1575;&#1604;&#1571;&#1606;&#1588;&#1591;&#1577; &#1594;&#1610;&#1585;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;&#1548;
&#1575;&#1604;&#1575;&#1581;&#1578;&#1610;&#1575;&#1604;
&#1575;&#1604;&#1605;&#1588;&#1578;&#1576;&#1607; &#1576;&#1607;&#1548;
&#1575;&#1604;&#1581;&#1575;&#1604;&#1575;&#1578; &#1575;&#1604;&#1578;&#1610;
&#1578;&#1606;&#1591;&#1608;&#1610; &#1593;&#1604;&#1609;
&#1578;&#1607;&#1583;&#1610;&#1583;&#1575;&#1578;
&#1605;&#1581;&#1578;&#1605;&#1604;&#1577;
&#1604;&#1604;&#1587;&#1604;&#1575;&#1605;&#1577;
&#1575;&#1604;&#1580;&#1587;&#1583;&#1610;&#1577; &#1604;&#1571;&#1610; &#1588;&#1582;&#1589;&#1548;
&#1575;&#1606;&#1578;&#1607;&#1575;&#1603;&#1575;&#1578;
&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1606;&#1575;&#1548; &#1571;&#1608;
&#1603;&#1605;&#1575; &#1610;&#1602;&#1578;&#1590;&#1610;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1548; &#1602;&#1583;
&#1606;&#1590;&#1591;&#1585; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1603;&#1588;&#1601; &#1593;&#1606; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;.
&#1593;&#1604;&#1575;&#1608;&#1577;&#1611; &#1593;&#1604;&#1609;
&#1584;&#1604;&#1603;&#1548; &#1601;&#1610; &#1581;&#1575;&#1604;&#1577;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1581;&#1608;&#1575;&#1584;&#1548;
&#1575;&#1604;&#1583;&#1605;&#1580; &#1571;&#1608;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1581;&#1608;&#1575;&#1584;&#1548; &#1606;&#1581;&#1578;&#1601;&#1592;
&#1576;&#1575;&#1604;&#1581;&#1602; &#1601;&#1610; &#1606;&#1602;&#1604;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603; &#1573;&#1604;&#1609;
&#1605;&#1606;&#1589;&#1577; &#1580;&#1583;&#1610;&#1583;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1602;&#1583;
&#1606;&#1603;&#1588;&#1601; &#1593;&#1606;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1576;&#1606;&#1575;&#1569;&#1611; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1575;&#1593;&#1578;&#1602;&#1575;&#1583; &#1575;&#1604;&#1581;&#1587;&#1606;
&#1576;&#1571;&#1606; &#1605;&#1579;&#1604; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1573;&#1580;&#1585;&#1575;&#1569;
&#1590;&#1585;&#1608;&#1585;&#1610; &#1604;&#1600;:</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1581;&#1605;&#1575;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1583;&#1601;&#1575;&#1593; &#1593;&#1606;
&#1581;&#1602;&#1608;&#1602;&#1606;&#1575; &#1571;&#1608;
&#1605;&#1605;&#1578;&#1604;&#1603;&#1575;&#1578;&#1606;&#1575;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1605;&#1606;&#1593;
&#1571;&#1608; &#1575;&#1604;&#1578;&#1581;&#1602;&#1610;&#1602; &#1601;&#1610;
&#1575;&#1581;&#1578;&#1605;&#1575;&#1604; &#1608;&#1580;&#1608;&#1583;
&#1587;&#1604;&#1608;&#1603; &#1582;&#1575;&#1591;&#1574;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1581;&#1605;&#1575;&#1610;&#1577;
&#1587;&#1604;&#1575;&#1605;&#1577;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
&#1604;&#1604;&#1582;&#1583;&#1605;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1580;&#1605;&#1607;&#1608;&#1585;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1590;&#1583; &#1575;&#1604;&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1577;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1593;&#1606;&#1583;
&#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1577;&#1548; &#1602;&#1583;
&#1606;&#1603;&#1588;&#1601; &#1608;&#1606;&#1606;&#1602;&#1604;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1571;&#1610;&#1590;&#1611;&#1575; &#1573;&#1604;&#1609; &#1605;&#1587;&#1578;&#1588;&#1575;&#1585;&#1610;&#1606;&#1575;
&#1575;&#1604;&#1605;&#1607;&#1606;&#1610;&#1610;&#1606;&#1548;
&#1608;&#1603;&#1575;&#1604;&#1575;&#1578; &#1573;&#1606;&#1601;&#1575;&#1584;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1548;
&#1588;&#1585;&#1603;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1571;&#1605;&#1610;&#1606;&#1548;
&#1575;&#1604;&#1581;&#1603;&#1608;&#1605;&#1577;
&#1608;&#1575;&#1604;&#1580;&#1607;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1606;&#1592;&#1610;&#1605;&#1610;&#1577;
&#1608;&#1594;&#1610;&#1585;&#1607;&#1575; &#1605;&#1606;
&#1575;&#1604;&#1605;&#1606;&#1592;&#1605;&#1575;&#1578;.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1578;&#1582;&#1586;&#1610;&#1606;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1602;&#1583;
&#1610;&#1578;&#1605; &#1578;&#1582;&#1586;&#1610;&#1606;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1608;&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1582;&#1608;&#1575;&#1583;&#1605;
&#1601;&#1610; &#1575;&#1604;&#1608;&#1604;&#1575;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1578;&#1581;&#1583;&#1577;&#1548;
&#1571;&#1608;&#1585;&#1608;&#1576;&#1575;&#1548; &#1571;&#1608; &#1571;&#1610;
&#1576;&#1604;&#1583; &#1570;&#1582;&#1585; &#1610;&#1602;&#1608;&#1605;
&#1601;&#1610;&#1607; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1571;&#1608; &#1588;&#1585;&#1603;&#1575;&#1578;&#1607;
&#1575;&#1604;&#1601;&#1585;&#1593;&#1610;&#1577;&#1548;
&#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1575;&#1576;&#1593;&#1577; &#1604;&#1607; &#1571;&#1608;
&#1605;&#1586;&#1608;&#1583;&#1610; &#1575;&#1604;&#1582;&#1583;&#1605;&#1577;
&#1576;&#1589;&#1610;&#1575;&#1606;&#1577;
&#1575;&#1604;&#1605;&#1585;&#1575;&#1601;&#1602;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1602;&#1583;
&#1610;&#1602;&#1608;&#1605; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1576;&#1606;&#1602;&#1604;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1603;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1575;&#1576;&#1593;&#1577; &#1604;&#1607;&#1548;
&#1571;&#1608; &#1573;&#1604;&#1609; &#1571;&#1591;&#1585;&#1575;&#1601;
&#1579;&#1575;&#1604;&#1579;&#1577; &#1571;&#1582;&#1585;&#1609;
&#1593;&#1576;&#1585; &#1575;&#1604;&#1581;&#1583;&#1608;&#1583;
&#1608;&#1605;&#1606; &#1576;&#1604;&#1583;&#1603; &#1571;&#1608;
&#1608;&#1604;&#1575;&#1610;&#1578;&#1603;
&#1575;&#1604;&#1602;&#1590;&#1575;&#1574;&#1610;&#1577; &#1573;&#1604;&#1609;
&#1576;&#1604;&#1583;&#1575;&#1606; &#1571;&#1608;
&#1608;&#1604;&#1575;&#1610;&#1575;&#1578;
&#1602;&#1590;&#1575;&#1574;&#1610;&#1577; &#1571;&#1582;&#1585;&#1609;
&#1581;&#1608;&#1604; &#1575;&#1604;&#1593;&#1575;&#1604;&#1605;.
&#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603; &#1593;&#1604;&#1609;
&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1607;&#1584;&#1607;
&#1578;&#1604;&#1610;&#1607;&#1575; &#1578;&#1602;&#1583;&#1610;&#1605;&#1603;
&#1604;&#1607;&#1584;&#1607; &#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1578;&#1605;&#1579;&#1604; &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603;
&#1593;&#1604;&#1609; &#1607;&#1584;&#1575; &#1575;&#1604;&#1606;&#1602;&#1604;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1587;&#1606;&#1578;&#1582;&#1584;
&#1580;&#1605;&#1610;&#1593; &#1575;&#1604;&#1582;&#1591;&#1608;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1593;&#1602;&#1608;&#1604;&#1577;
&#1575;&#1604;&#1604;&#1575;&#1586;&#1605;&#1577; &#1604;&#1590;&#1605;&#1575;&#1606;
&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603; &#1576;&#1588;&#1603;&#1604;
&#1570;&#1605;&#1606; &#1608;&#1608;&#1601;&#1602;&#1611;&#1575;
&#1604;&#1587;&#1610;&#1575;&#1587;&#1577; &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1607;&#1584;&#1607;&#1548; &#1608;&#1604;&#1606; &#1610;&#1578;&#1605;
&#1606;&#1602;&#1604; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1573;&#1604;&#1609; &#1605;&#1606;&#1592;&#1605;&#1577; &#1571;&#1608;
&#1583;&#1608;&#1604;&#1577; &#1605;&#1575; &#1604;&#1605;
&#1610;&#1603;&#1606; &#1607;&#1606;&#1575;&#1603;
&#1590;&#1608;&#1575;&#1576;&#1591; &#1603;&#1575;&#1601;&#1610;&#1577;
&#1605;&#1608;&#1580;&#1608;&#1583;&#1577; &#1576;&#1605;&#1575; &#1601;&#1610;
&#1584;&#1604;&#1603; &#1571;&#1605;&#1575;&#1606;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1571;&#1582;&#1585;&#1609;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1587;&#1606;&#1581;&#1578;&#1601;&#1592;
&#1576;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1604;&#1604;&#1601;&#1578;&#1585;&#1577;
&#1575;&#1604;&#1586;&#1605;&#1606;&#1610;&#1577;
&#1575;&#1604;&#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
&#1604;&#1578;&#1581;&#1602;&#1610;&#1602; &#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1578;&#1610; &#1580;&#1605;&#1593;&#1606;&#1575;&#1607;&#1575;
&#1605;&#1606; &#1571;&#1580;&#1604;&#1607;&#1575;&#1548; &#1576;&#1605;&#1575;
&#1601;&#1610; &#1584;&#1604;&#1603; &#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1578;&#1604;&#1576;&#1610;&#1577; &#1571;&#1610;
&#1605;&#1578;&#1591;&#1604;&#1576;&#1575;&#1578;
&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;&#1548;
&#1575;&#1606;&#1592;&#1585; &#1575;&#1604;&#1602;&#1587;&#1605;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1605;&#1578;&#1591;&#1604;&#1576;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1571;&#1605;&#1610;&#1606;
&#1608;&#1575;&#1604;&#1573;&#1576;&#1604;&#1575;&#1594;. &#1593;&#1606;&#1583;
&#1578;&#1581;&#1583;&#1610;&#1583; &#1575;&#1604;&#1608;&#1602;&#1578;
&#1575;&#1604;&#1589;&#1581;&#1610;&#1581;
&#1604;&#1604;&#1575;&#1581;&#1578;&#1601;&#1575;&#1592;
&#1576;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548;
&#1606;&#1606;&#1592;&#1585; &#1601;&#1610; &#1581;&#1580;&#1605;&#1607;&#1575;&#1548;
&#1591;&#1576;&#1610;&#1593;&#1578;&#1607;&#1575;
&#1608;&#1581;&#1587;&#1575;&#1587;&#1610;&#1578;&#1607;&#1575;&#1548;
&#1575;&#1604;&#1582;&#1591;&#1585;
&#1575;&#1604;&#1605;&#1581;&#1578;&#1605;&#1604; &#1604;&#1604;&#1571;&#1584;&#1609;
&#1605;&#1606; &#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1594;&#1610;&#1585; &#1575;&#1604;&#1605;&#1589;&#1585;&#1581; &#1576;&#1607;
&#1571;&#1608; &#1575;&#1604;&#1603;&#1588;&#1601;
&#1593;&#1606;&#1607;&#1575;&#1548; &#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;&#1548;
&#1573;&#1584;&#1575; &#1603;&#1575;&#1606; &#1610;&#1605;&#1603;&#1606;
&#1578;&#1581;&#1602;&#1610;&#1602;&#1607;&#1575;
&#1576;&#1608;&#1587;&#1575;&#1574;&#1604; &#1571;&#1582;&#1585;&#1609;
&#1608;&#1605;&#1578;&#1591;&#1604;&#1576;&#1575;&#1578;
&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1603;&#1610;&#1601;
&#1606;&#1581;&#1605;&#1610;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1606;&#1582;&#1586;&#1606;
&#1580;&#1605;&#1610;&#1593;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1602;&#1583;&#1605;&#1578;&#1607;&#1575;
&#1593;&#1576;&#1585; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1601;&#1610; &#1602;&#1575;&#1593;&#1583;&#1577;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1570;&#1605;&#1606;&#1577;
&#1608;&#1607;&#1610;</span><span dir=LTR></span><span lang=EN-GB dir=LTR><span
dir=LTR></span> Firebase (Google) </span><span lang=AR-EG style='font-family:
"Arial","sans-serif"'>&#1604;&#1604;&#1605;&#1589;&#1575;&#1583;&#1602;&#1577;
&#1608;</span><span lang=EN-GB dir=LTR>AWS (Amazon) </span><span lang=AR-EG
style='font-family:"Arial","sans-serif"'>&#1604;&#1582;&#1608;&#1575;&#1583;&#1605;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1606;&#1581;&#1606;
&#1602;&#1604;&#1602;&#1608;&#1606; &#1576;&#1588;&#1571;&#1606;
&#1581;&#1605;&#1575;&#1610;&#1577;
&#1582;&#1589;&#1608;&#1589;&#1610;&#1578;&#1603;
&#1608;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;&#1548;
&#1604;&#1603;&#1606;&#1606;&#1575; &#1604;&#1575;
&#1610;&#1605;&#1603;&#1606;&#1606;&#1575; &#1590;&#1605;&#1575;&#1606;
&#1571;&#1605;&#1575;&#1606; &#1571;&#1610;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1578;&#1606;&#1602;&#1604;&#1607;&#1575; &#1571;&#1608; &#1606;&#1590;&#1605;&#1606;
&#1571;&#1606; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603; &#1604;&#1575;
&#1610;&#1605;&#1603;&#1606; &#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
&#1573;&#1604;&#1610;&#1607;&#1575;&#1548; &#1575;&#1604;&#1603;&#1588;&#1601;
&#1593;&#1606;&#1607;&#1575;&#1548;
&#1578;&#1594;&#1610;&#1610;&#1585;&#1607;&#1575; &#1571;&#1608;
&#1578;&#1583;&#1605;&#1610;&#1585;&#1607;&#1575; &#1593;&#1606;
&#1591;&#1585;&#1610;&#1602; &#1582;&#1585;&#1602; &#1571;&#1610;
&#1605;&#1606; &#1578;&#1583;&#1575;&#1576;&#1610;&#1585;
&#1575;&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1575;&#1583;&#1610;&#1577;&#1548;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1573;&#1583;&#1575;&#1585;&#1610;&#1577; &#1575;&#1604;&#1602;&#1610;&#1575;&#1587;&#1610;&#1577;
&#1601;&#1610; &#1602;&#1591;&#1575;&#1593;&#1606;&#1575;. &#1604;&#1575;
&#1578;&#1608;&#1580;&#1583; &#1591;&#1585;&#1610;&#1602;&#1577;
&#1604;&#1604;&#1606;&#1602;&#1604; &#1593;&#1576;&#1585; &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;
&#1571;&#1608; &#1591;&#1585;&#1610;&#1602;&#1577;
&#1578;&#1582;&#1586;&#1610;&#1606;
&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577;
&#1570;&#1605;&#1606;&#1577; &#1576;&#1588;&#1603;&#1604;
&#1605;&#1591;&#1604;&#1602;. &#1573;&#1584;&#1575;
&#1603;&#1575;&#1606;&#1578; &#1604;&#1583;&#1610;&#1603; &#1571;&#1610;
&#1571;&#1587;&#1574;&#1604;&#1577; &#1581;&#1608;&#1604;
&#1571;&#1605;&#1575;&#1606; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1575;&#1604;&#1582;&#1575;&#1589; &#1576;&#1606;&#1575;&#1548;
&#1610;&#1605;&#1603;&#1606;&#1603;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1576;&#1606;&#1575;
&#1593;&#1604;&#1609;</span><span dir=LTR></span><span lang=EN-GB dir=LTR><span
dir=LTR></span> Hello@jeelapp.com.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1571;&#1610;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1578;&#1586;&#1608;&#1583;&#1606;&#1575; &#1576;&#1607;&#1575;
&#1587;&#1610;&#1578;&#1605;
&#1575;&#1604;&#1575;&#1581;&#1578;&#1601;&#1575;&#1592; &#1576;&#1607;&#1575;
&#1605;&#1606; &#1602;&#1576;&#1604;&#1606;&#1575;
&#1608;&#1587;&#1578;&#1603;&#1608;&#1606; &#1605;&#1578;&#1575;&#1581;&#1577;
&#1604;&#1605;&#1608;&#1592;&#1601;&#1610;&#1606;&#1575;&#1548; &#1571;&#1610;
&#1605;&#1586;&#1608;&#1583;&#1610; &#1575;&#1604;&#1582;&#1583;&#1605;&#1577;
&#1575;&#1604;&#1584;&#1610;&#1606; &#1610;&#1593;&#1605;&#1604;&#1608;&#1606;
&#1605;&#1593;&#1606;&#1575; &#1608;&#1571;&#1591;&#1585;&#1575;&#1601;
&#1579;&#1575;&#1604;&#1579;&#1577;.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1604;&#1608;&#1575;&#1574;&#1581;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> GDPR</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1576;&#1575;&#1604;&#1606;&#1587;&#1576;&#1577;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
&#1601;&#1610; &#1575;&#1604;&#1575;&#1578;&#1581;&#1575;&#1583;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> (EU)</span><span dir=RTL></span><span
lang=AR-EG style='font-family:"Arial","sans-serif"'><span dir=RTL></span>&#1548;
&#1610;&#1580;&#1576; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602; &#1576;&#1584;&#1604;
&#1603;&#1604; &#1575;&#1604;&#1580;&#1607;&#1608;&#1583;
&#1575;&#1604;&#1605;&#1593;&#1602;&#1608;&#1604;&#1577; &#1604;&#1590;&#1605;&#1575;&#1606;
&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> (GDPR) (EU) 2016/679 </span><span
lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1603;&#1605;&#1575;
&#1608;&#1590;&#1593;&#1607;&#1575;
&#1575;&#1604;&#1575;&#1578;&#1581;&#1575;&#1583;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610;
&#1576;&#1582;&#1589;&#1608;&#1589; &#1580;&#1605;&#1593;&#1548;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1608;&#1575;&#1604;&#1575;&#1581;&#1578;&#1601;&#1575;&#1592;
&#1576;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1605;&#1606;
&#1583;&#1608;&#1604; &#1575;&#1604;&#1575;&#1578;&#1581;&#1575;&#1583;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610;. &#1610;&#1580;&#1576;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1576;&#1584;&#1604; &#1603;&#1604; &#1575;&#1604;&#1580;&#1607;&#1608;&#1583;
&#1575;&#1604;&#1605;&#1593;&#1602;&#1608;&#1604;&#1577;
&#1604;&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
&#1576;&#1605;&#1578;&#1591;&#1604;&#1576;&#1575;&#1578;
&#1575;&#1604;&#1573;&#1588;&#1593;&#1575;&#1585;&#1548;
&#1575;&#1604;&#1575;&#1582;&#1578;&#1610;&#1575;&#1585;&#1548;
&#1575;&#1604;&#1606;&#1602;&#1604;
&#1575;&#1604;&#1604;&#1575;&#1581;&#1602;&#1548;
&#1575;&#1604;&#1571;&#1605;&#1575;&#1606;&#1548;
&#1587;&#1604;&#1575;&#1605;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548;
&#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
&#1608;&#1575;&#1604;&#1573;&#1606;&#1601;&#1575;&#1584;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraph dir=RTL style='margin-top:0in;margin-right:.5in;
margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1581;&#1602;&#1608;&#1602;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1610;&#1605;&#1603;&#1606;&#1603;
&#1605;&#1605;&#1575;&#1585;&#1587;&#1577; &#1576;&#1593;&#1590;
&#1575;&#1604;&#1581;&#1602;&#1608;&#1602;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
&#1576;&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1606;&#1593;&#1575;&#1604;&#1580;&#1607;&#1575;.
&#1593;&#1604;&#1609; &#1608;&#1580;&#1607;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1548; &#1610;&#1605;&#1603;&#1606;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606; &#1575;&#1604;&#1605;&#1608;&#1580;&#1608;&#1583;&#1610;&#1606;
&#1601;&#1610; &#1575;&#1604;&#1575;&#1578;&#1581;&#1575;&#1583;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610;
&#1575;&#1604;&#1602;&#1610;&#1575;&#1605; &#1576;&#1605;&#1575; &#1610;&#1604;&#1610;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>:</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1581;&#1602;
&#1575;&#1604;&#1578;&#1571;&#1603;&#1610;&#1583;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1604;&#1604;&#1581;&#1589;&#1608;&#1604;
&#1605;&#1606;&#1575; &#1593;&#1604;&#1609; &#1578;&#1571;&#1603;&#1610;&#1583;
&#1581;&#1608;&#1604; &#1605;&#1575; &#1573;&#1584;&#1575;
&#1603;&#1575;&#1606;&#1578; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1603;
&#1602;&#1610;&#1583; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1571;&#1605; &#1604;&#1575;</span><span dir=LTR></span><span lang=EN-GB
dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1581;&#1602;
&#1575;&#1604;&#1608;&#1589;&#1608;&#1604;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1604;&#1604;&#1581;&#1589;&#1608;&#1604;
&#1605;&#1606;&#1575; &#1593;&#1604;&#1609; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1605;&#1580;&#1575;&#1606;&#1610;&#1577; &#1581;&#1608;&#1604;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1582;&#1586;&#1606;&#1577; &#1601;&#1610; &#1571;&#1610;
&#1608;&#1602;&#1578; &#1608;&#1606;&#1587;&#1582;&#1577; &#1605;&#1606;
&#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;.
&#1593;&#1604;&#1575;&#1608;&#1577; &#1593;&#1604;&#1609;
&#1584;&#1604;&#1603;&#1548; &#1578;&#1605;&#1606;&#1581;&#1603;
&#1575;&#1604;&#1578;&#1608;&#1580;&#1610;&#1607;&#1575;&#1578;
&#1608;&#1575;&#1604;&#1604;&#1608;&#1575;&#1574;&#1581;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610;&#1577;
&#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577;:</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
2.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
2.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1601;&#1574;&#1575;&#1578;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1593;&#1606;&#1610;&#1577;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
2.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1605;&#1578;&#1604;&#1602;&#1608;&#1606;
&#1571;&#1608; &#1601;&#1574;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1578;&#1604;&#1602;&#1610;&#1606;
&#1575;&#1604;&#1584;&#1610;&#1606; &#1578;&#1605; &#1571;&#1608;
&#1587;&#1610;&#1578;&#1605; &#1575;&#1604;&#1603;&#1588;&#1601; &#1593;&#1606;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1604;&#1607;&#1605;&#1548;
&#1608;&#1582;&#1575;&#1589;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1604;&#1602;&#1610;&#1606; &#1601;&#1610; &#1583;&#1608;&#1604;
&#1579;&#1575;&#1604;&#1579;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1605;&#1606;&#1592;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1583;&#1608;&#1604;&#1610;&#1577;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
2.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1581;&#1610;&#1579;&#1605;&#1575;
&#1571;&#1605;&#1603;&#1606;&#1548; &#1575;&#1604;&#1601;&#1578;&#1585;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1608;&#1602;&#1593;&#1577;
&#1575;&#1604;&#1578;&#1610; &#1587;&#1610;&#1578;&#1605; &#1601;&#1610;&#1607;&#1575;
&#1578;&#1582;&#1586;&#1610;&#1606;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1548; &#1571;&#1608;
&#1573;&#1584;&#1575; &#1604;&#1605; &#1610;&#1603;&#1606;
&#1584;&#1604;&#1603; &#1605;&#1605;&#1603;&#1606;&#1611;&#1575;&#1548;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1610;&#1610;&#1585;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1577;
&#1604;&#1578;&#1581;&#1583;&#1610;&#1583; &#1578;&#1604;&#1603; &#1575;&#1604;&#1601;&#1578;&#1585;&#1577;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
2.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1608;&#1580;&#1608;&#1583;
&#1575;&#1604;&#1581;&#1602; &#1601;&#1610; &#1591;&#1604;&#1576;
&#1605;&#1606;&#1575; &#1578;&#1589;&#1581;&#1610;&#1581; &#1571;&#1608;
&#1581;&#1584;&#1601; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1548; &#1571;&#1608;
&#1578;&#1602;&#1610;&#1610;&#1583; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1575;&#1604;&#1605;&#1608;&#1590;&#1608;&#1593;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548; &#1571;&#1608;
&#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590; &#1593;&#1604;&#1609;
&#1607;&#1584;&#1607; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
2.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1608;&#1580;&#1608;&#1583;
&#1575;&#1604;&#1581;&#1602; &#1601;&#1610; &#1578;&#1602;&#1583;&#1610;&#1605;
&#1588;&#1603;&#1608;&#1609; &#1573;&#1604;&#1609; &#1587;&#1604;&#1591;&#1577;
&#1573;&#1588;&#1585;&#1575;&#1601;&#1610;&#1577;&#1563;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
2.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1601;&#1610;
&#1581;&#1575;&#1604; &#1593;&#1583;&#1605; &#1580;&#1605;&#1593;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1605;&#1606;&#1603;&#1548;
&#1571;&#1610; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1605;&#1578;&#1575;&#1581;&#1577; &#1576;&#1588;&#1571;&#1606;
&#1605;&#1589;&#1583;&#1585;&#1607;&#1575;&#1563;</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
2.0in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1608;&#1580;&#1608;&#1583;
&#1589;&#1606;&#1593; &#1575;&#1604;&#1602;&#1585;&#1575;&#1585;
&#1575;&#1604;&#1570;&#1604;&#1610;&#1548; &#1576;&#1605;&#1575; &#1601;&#1610;
&#1584;&#1604;&#1603; &#1575;&#1604;&#1578;&#1606;&#1605;&#1610;&#1591;&#1548; &#1575;&#1604;&#1605;&#1588;&#1575;&#1585;
&#1573;&#1604;&#1610;&#1607; &#1601;&#1610; &#1575;&#1604;&#1605;&#1575;&#1583;&#1577;
22(1) &#1608; (4) &#1605;&#1606;
&#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> (GDPR) </span><span lang=AR-EG
style='font-family:"Arial","sans-serif"'>&#1608;&#1548; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1571;&#1602;&#1604; &#1601;&#1610; &#1578;&#1604;&#1603;
&#1575;&#1604;&#1581;&#1575;&#1604;&#1575;&#1578;&#1548;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1584;&#1575;&#1578;
&#1605;&#1593;&#1606;&#1609; &#1581;&#1608;&#1604;
&#1575;&#1604;&#1605;&#1606;&#1591;&#1602;
&#1575;&#1604;&#1605;&#1578;&#1590;&#1605;&#1606;&#1548;
&#1608;&#1603;&#1584;&#1604;&#1603;
&#1575;&#1604;&#1571;&#1607;&#1605;&#1610;&#1577; &#1608;&#1575;&#1604;&#1593;&#1608;&#1575;&#1602;&#1576;
&#1575;&#1604;&#1605;&#1578;&#1608;&#1602;&#1593;&#1577;
&#1604;&#1605;&#1579;&#1604; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1576;&#1575;&#1604;&#1606;&#1587;&#1576;&#1577; &#1604;&#1603;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1593;&#1604;&#1575;&#1608;&#1577;
&#1593;&#1604;&#1609; &#1584;&#1604;&#1603;&#1548; &#1610;&#1581;&#1602;
&#1604;&#1603; &#1575;&#1604;&#1581;&#1589;&#1608;&#1604; &#1593;&#1604;&#1609;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1576;&#1588;&#1571;&#1606;
&#1605;&#1575; &#1573;&#1584;&#1575; &#1603;&#1575;&#1606;&#1578;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1578;&#1615;&#1606;&#1602;&#1604; &#1573;&#1604;&#1609;
&#1583;&#1608;&#1604;&#1577; &#1579;&#1575;&#1604;&#1579;&#1577; &#1571;&#1608;
&#1573;&#1604;&#1609; &#1605;&#1606;&#1592;&#1605;&#1577; &#1583;&#1608;&#1604;&#1610;&#1577;.
&#1601;&#1610; &#1581;&#1575;&#1604; &#1603;&#1575;&#1606;
&#1575;&#1604;&#1571;&#1605;&#1585; &#1603;&#1584;&#1604;&#1603;&#1548;
&#1610;&#1581;&#1602; &#1604;&#1603; &#1571;&#1606; &#1610;&#1578;&#1605;
&#1573;&#1593;&#1604;&#1575;&#1605;&#1603;
&#1576;&#1575;&#1604;&#1590;&#1605;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1606;&#1575;&#1587;&#1576;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
&#1576;&#1575;&#1604;&#1606;&#1602;&#1604;.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1581;&#1602;
&#1575;&#1604;&#1578;&#1589;&#1581;&#1610;&#1581;</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1601;&#1610;
&#1575;&#1604;&#1581;&#1589;&#1608;&#1604; &#1605;&#1606;&#1575;
&#1593;&#1604;&#1609; &#1578;&#1589;&#1581;&#1610;&#1581;
&#1601;&#1608;&#1585;&#1610;
&#1604;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1594;&#1610;&#1585;
&#1575;&#1604;&#1583;&#1602;&#1610;&#1602;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1603;.
&#1605;&#1593; &#1605;&#1585;&#1575;&#1593;&#1575;&#1577;
&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;&#1548;
&#1604;&#1583;&#1610;&#1603; &#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
&#1575;&#1587;&#1578;&#1603;&#1605;&#1575;&#1604;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1594;&#1610;&#1585;
&#1575;&#1604;&#1603;&#1575;&#1605;&#1604;&#1577;&#1548; &#1576;&#1605;&#1575;
&#1601;&#1610; &#1584;&#1604;&#1603; &#1593;&#1606;
&#1591;&#1585;&#1610;&#1602; &#1578;&#1602;&#1583;&#1610;&#1605;
&#1576;&#1610;&#1575;&#1606; &#1578;&#1603;&#1605;&#1610;&#1604;&#1610;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1581;&#1602;
&#1575;&#1604;&#1581;&#1584;&#1601; (&#1581;&#1602;
&#1575;&#1604;&#1606;&#1587;&#1610;&#1575;&#1606;)</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1601;&#1610;
&#1575;&#1604;&#1581;&#1589;&#1608;&#1604; &#1605;&#1606;&#1575;
&#1593;&#1604;&#1609; &#1581;&#1584;&#1601;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
&#1576;&#1603; &#1583;&#1608;&#1606; &#1578;&#1571;&#1582;&#1610;&#1585;
&#1594;&#1610;&#1585; &#1605;&#1576;&#1585;&#1585;&#1548;
&#1608;&#1606;&#1581;&#1606; &#1605;&#1604;&#1586;&#1605;&#1608;&#1606;
&#1576;&#1581;&#1584;&#1601;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1583;&#1608;&#1606;
&#1578;&#1571;&#1582;&#1610;&#1585; &#1594;&#1610;&#1585;
&#1605;&#1576;&#1585;&#1585; &#1593;&#1606;&#1583;&#1605;&#1575;
&#1610;&#1606;&#1591;&#1576;&#1602; &#1571;&#1581;&#1583;
&#1575;&#1604;&#1571;&#1587;&#1576;&#1575;&#1576;
&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577;&#1548;
&#1591;&#1575;&#1604;&#1605;&#1575; &#1571;&#1606; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1594;&#1610;&#1585; &#1590;&#1585;&#1608;&#1585;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>:</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
157.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1604;&#1605;
&#1578;&#1593;&#1583; &#1590;&#1585;&#1608;&#1585;&#1610;&#1577;
&#1601;&#1610;&#1605;&#1575; &#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1605; &#1580;&#1605;&#1593;&#1607;&#1575;
&#1605;&#1606; &#1571;&#1580;&#1604;&#1607;&#1575; &#1571;&#1608;
&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;
&#1576;&#1591;&#1585;&#1610;&#1602;&#1577; &#1571;&#1582;&#1585;&#1609;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
157.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1571;&#1606;&#1578;
&#1578;&#1587;&#1581;&#1576;
&#1575;&#1604;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
&#1575;&#1604;&#1578;&#1610; &#1578;&#1593;&#1578;&#1605;&#1583;
&#1593;&#1604;&#1610;&#1607;&#1575; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1608;&#1601;&#1602;&#1611;&#1575; &#1604;&#1604;&#1576;&#1606;&#1583;
(&#1571;) &#1605;&#1606; &#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 6(1)
&#1605;&#1606; &#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> (GDPR)</span><span dir=RTL></span><span
lang=AR-EG style='font-family:"Arial","sans-serif"'><span dir=RTL></span>&#1548;
&#1571;&#1608; &#1575;&#1604;&#1576;&#1606;&#1583; (&#1571;) &#1605;&#1606;
&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 9(2) &#1605;&#1606;
&#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;&#1548;
&#1608;&#1593;&#1606;&#1583;&#1605;&#1575; &#1604;&#1575;
&#1610;&#1608;&#1580;&#1583; &#1571;&#1587;&#1575;&#1587;
&#1602;&#1575;&#1606;&#1608;&#1606;&#1610; &#1570;&#1582;&#1585;
&#1604;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
157.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1571;&#1606;&#1578;
&#1578;&#1593;&#1578;&#1585;&#1590; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1608;&#1601;&#1602;&#1611;&#1575; &#1604;&#1604;&#1605;&#1575;&#1583;&#1577;
21(1) &#1605;&#1606; &#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1608;&#1604;&#1575; &#1578;&#1608;&#1580;&#1583;
&#1571;&#1587;&#1576;&#1575;&#1576; &#1605;&#1588;&#1585;&#1608;&#1593;&#1577;
&#1605;&#1604;&#1581;&#1577;
&#1604;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;&#1548; &#1571;&#1608;
&#1578;&#1593;&#1578;&#1585;&#1590; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1608;&#1601;&#1602;&#1611;&#1575; &#1604;&#1604;&#1605;&#1575;&#1583;&#1577;
21(2) &#1605;&#1606; &#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
157.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1578;&#1605;
&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1607;&#1575;
&#1576;&#1591;&#1585;&#1610;&#1602;&#1577; &#1594;&#1610;&#1585; &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
157.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1610;&#1580;&#1576;
&#1581;&#1584;&#1601; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1604;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;
&#1602;&#1575;&#1606;&#1608;&#1606;&#1610; &#1601;&#1610;
&#1575;&#1604;&#1575;&#1578;&#1581;&#1575;&#1583; &#1571;&#1608;
&#1602;&#1575;&#1606;&#1608;&#1606; &#1575;&#1604;&#1583;&#1608;&#1604;&#1577;
&#1575;&#1604;&#1593;&#1590;&#1608; &#1575;&#1604;&#1578;&#1610;
&#1606;&#1582;&#1590;&#1593; &#1604;&#1607;&#1575;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
157.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-EG style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1578;&#1605;
&#1580;&#1605;&#1593;&#1607;&#1575; &#1601;&#1610;&#1605;&#1575;
&#1610;&#1578;&#1593;&#1604;&#1602; &#1576;&#1593;&#1585;&#1590; &#1582;&#1583;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1580;&#1578;&#1605;&#1593;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1610;
&#1575;&#1604;&#1605;&#1588;&#1575;&#1585; &#1573;&#1604;&#1610;&#1607;&#1575;
&#1601;&#1610; &#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 8(1) &#1605;&#1606;
&#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1593;&#1606;&#1583;&#1605;&#1575;
&#1606;&#1602;&#1608;&#1605; &#1576;&#1580;&#1593;&#1604;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1593;&#1575;&#1605;&#1577;
&#1608;&#1606;&#1603;&#1608;&#1606; &#1605;&#1604;&#1586;&#1605;&#1610;&#1606;
&#1608;&#1601;&#1602;&#1611;&#1575; &#1604;&#1604;&#1605;&#1575;&#1583;&#1577;
17(1) &#1605;&#1606; &#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> (GDPR) </span><span lang=AR-SA
style='font-family:"Arial","sans-serif"'>&#1576;&#1581;&#1584;&#1601;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1548;
&#1606;&#1581;&#1606;&#1548; &#1605;&#1593;
&#1605;&#1585;&#1575;&#1593;&#1575;&#1577; &#1575;&#1604;&#1578;&#1603;&#1606;&#1608;&#1604;&#1608;&#1580;&#1610;&#1575;
&#1575;&#1604;&#1605;&#1578;&#1575;&#1581;&#1577;
&#1608;&#1578;&#1603;&#1604;&#1601;&#1577;
&#1575;&#1604;&#1578;&#1606;&#1601;&#1610;&#1584;&#1548;
&#1587;&#1606;&#1578;&#1582;&#1584; &#1582;&#1591;&#1608;&#1575;&#1578;
&#1605;&#1593;&#1602;&#1608;&#1604;&#1577;&#1548; &#1576;&#1605;&#1575;
&#1601;&#1610; &#1584;&#1604;&#1603; &#1575;&#1604;&#1573;&#1580;&#1585;&#1575;&#1569;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1602;&#1606;&#1610;&#1577;&#1548;
&#1604;&#1573;&#1576;&#1604;&#1575;&#1594;
&#1575;&#1604;&#1605;&#1578;&#1581;&#1603;&#1605;&#1610;&#1606;
&#1575;&#1604;&#1570;&#1582;&#1585;&#1610;&#1606;
&#1575;&#1604;&#1584;&#1610;&#1606;
&#1610;&#1593;&#1575;&#1604;&#1580;&#1608;&#1606;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1576;&#1571;&#1606;&#1603; &#1591;&#1604;&#1576;&#1578;
&#1581;&#1584;&#1601; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1605;&#1606;
&#1602;&#1576;&#1604; &#1607;&#1572;&#1604;&#1575;&#1569; &#1575;&#1604;&#1605;&#1578;&#1581;&#1603;&#1605;&#1610;&#1606;
&#1604;&#1571;&#1610; &#1585;&#1608;&#1575;&#1576;&#1591; &#1573;&#1604;&#1609;
&#1607;&#1584;&#1607; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1548; &#1571;&#1608;
&#1606;&#1587;&#1582; &#1571;&#1608; &#1578;&#1603;&#1585;&#1575;&#1585;
&#1578;&#1604;&#1603;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548;
&#1591;&#1575;&#1604;&#1605;&#1575; &#1571;&#1606; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1594;&#1610;&#1585; &#1605;&#1591;&#1604;&#1608;&#1576;&#1577;.
&#1587;&#1606;&#1606;&#1592;&#1605;
&#1575;&#1604;&#1573;&#1580;&#1585;&#1575;&#1569;&#1575;&#1578;
&#1575;&#1604;&#1604;&#1575;&#1586;&#1605;&#1577; &#1601;&#1610;
&#1581;&#1575;&#1604;&#1575;&#1578; &#1601;&#1585;&#1583;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraph dir=RTL style='margin-top:0in;margin-right:.5in;
margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1581;&#1602;
&#1578;&#1602;&#1610;&#1610;&#1583;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1604;&#1604;&#1581;&#1589;&#1608;&#1604;
&#1605;&#1606;&#1575; &#1593;&#1604;&#1609; &#1578;&#1602;&#1610;&#1610;&#1583;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1581;&#1610;&#1579;
&#1610;&#1606;&#1591;&#1576;&#1602; &#1571;&#1581;&#1583; &#1575;&#1604;&#1581;&#1575;&#1604;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1575;&#1604;&#1610;&#1577;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>:</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
1.25in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1583;&#1602;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1605;&#1578;&#1606;&#1575;&#1586;&#1593; &#1593;&#1604;&#1610;&#1607;&#1575;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1589;&#1575;&#1581;&#1576;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548;
&#1604;&#1601;&#1578;&#1585;&#1577; &#1578;&#1605;&#1603;&#1606;&#1606;&#1575;
&#1605;&#1606; &#1575;&#1604;&#1578;&#1581;&#1602;&#1602; &#1605;&#1606;
&#1583;&#1602;&#1577; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.25in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1594;&#1610;&#1585; &#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577; &#1608;&#1589;&#1575;&#1581;&#1576;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1610;&#1593;&#1575;&#1585;&#1590; &#1581;&#1584;&#1601;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1608;&#1610;&#1591;&#1604;&#1576;
&#1576;&#1583;&#1604;&#1575;&#1611; &#1605;&#1606; &#1584;&#1604;&#1603;
&#1578;&#1602;&#1610;&#1610;&#1583;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
1.25in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1605;
&#1606;&#1593;&#1583; &#1576;&#1581;&#1575;&#1580;&#1577; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;&#1548;
&#1604;&#1603;&#1606;&#1606;&#1575;
&#1605;&#1591;&#1575;&#1604;&#1576;&#1608;&#1606; &#1605;&#1606;
&#1602;&#1576;&#1604; &#1589;&#1575;&#1581;&#1576;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1604;&#1573;&#1606;&#1588;&#1575;&#1569;&#1548;
&#1605;&#1605;&#1575;&#1585;&#1587;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1583;&#1601;&#1575;&#1593; &#1593;&#1606;
&#1575;&#1604;&#1605;&#1591;&#1575;&#1604;&#1576;&#1575;&#1578; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
1.25in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1571;&#1606;&#1578;
&#1602;&#1583; &#1575;&#1593;&#1578;&#1585;&#1590;&#1578; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1608;&#1601;&#1602;&#1611;&#1575; &#1604;&#1604;&#1605;&#1575;&#1583;&#1577;
21(1) &#1605;&#1606; &#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1601;&#1610;
&#1575;&#1606;&#1578;&#1592;&#1575;&#1585;
&#1575;&#1604;&#1578;&#1581;&#1602;&#1602; &#1605;&#1605;&#1575;
&#1573;&#1584;&#1575; &#1603;&#1575;&#1606;&#1578;
&#1571;&#1587;&#1576;&#1575;&#1576;&#1606;&#1575;
&#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577; &#1578;&#1591;&#1594;&#1609;
&#1593;&#1604;&#1609; &#1571;&#1587;&#1576;&#1575;&#1576;&#1603;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1573;&#1584;&#1575;
&#1578;&#1605; &#1575;&#1587;&#1578;&#1610;&#1601;&#1575;&#1569;
&#1571;&#1581;&#1583; &#1575;&#1604;&#1588;&#1585;&#1608;&#1591;
&#1575;&#1604;&#1605;&#1584;&#1603;&#1608;&#1585;&#1577;
&#1571;&#1593;&#1604;&#1575;&#1607;&#1548; &#1608;&#1603;&#1606;&#1578;
&#1578;&#1585;&#1594;&#1576; &#1601;&#1610; &#1591;&#1604;&#1576;
&#1578;&#1602;&#1610;&#1610;&#1583; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1578;&#1610; &#1606;&#1582;&#1586;&#1606;&#1607;&#1575;&#1548;
&#1610;&#1605;&#1603;&#1606;&#1603;
&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604; &#1576;&#1606;&#1575;
&#1601;&#1610; &#1571;&#1610; &#1608;&#1602;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraph dir=RTL style='margin-top:0in;margin-right:.5in;
margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1581;&#1602;
&#1575;&#1604;&#1606;&#1602;&#1604;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1601;&#1610;
&#1578;&#1604;&#1602;&#1610; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1603;&#1548;
&#1575;&#1604;&#1578;&#1610; &#1602;&#1583;&#1605;&#1578; &#1604;&#1606;&#1575;&#1548;
&#1576;&#1578;&#1606;&#1587;&#1610;&#1602; &#1605;&#1606;&#1592;&#1605;&#1548;
&#1588;&#1575;&#1574;&#1593;
&#1575;&#1604;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1608;&#1602;&#1575;&#1576;&#1604; &#1604;&#1604;&#1602;&#1585;&#1575;&#1569;&#1577;
&#1570;&#1604;&#1610;&#1611;&#1575;. &#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1601;&#1610; &#1606;&#1602;&#1604;
&#1578;&#1604;&#1603; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1573;&#1604;&#1609; &#1605;&#1578;&#1581;&#1603;&#1605;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1570;&#1582;&#1585;
&#1583;&#1608;&#1606; &#1593;&#1608;&#1575;&#1574;&#1602; &#1605;&#1606;&#1575;
&#1608;&#1575;&#1604;&#1578;&#1610; &#1578;&#1605; &#1578;&#1608;&#1601;&#1610;&#1585;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1604;&#1607;&#1575;&#1548;
&#1591;&#1575;&#1604;&#1605;&#1575; &#1571;&#1606;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1578;&#1593;&#1578;&#1605;&#1583;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
&#1608;&#1601;&#1602;&#1611;&#1575; &#1604;&#1604;&#1606;&#1602;&#1591;&#1577;
(&#1571;) &#1605;&#1606; &#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 6(1)
&#1605;&#1606; &#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578; &#1571;&#1608;
&#1575;&#1604;&#1606;&#1602;&#1591;&#1577; (&#1571;) &#1605;&#1606;
&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 9(2) &#1605;&#1606;
&#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;&#1548; &#1571;&#1608;
&#1593;&#1604;&#1609; &#1593;&#1602;&#1583; &#1608;&#1601;&#1602;&#1611;&#1575;
&#1604;&#1604;&#1606;&#1602;&#1591;&#1577; (&#1576;) &#1605;&#1606;
&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 6(1) &#1605;&#1606;
&#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;&#1548;
&#1608;&#1578;&#1578;&#1605; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1576;&#1608;&#1587;&#1575;&#1574;&#1604; &#1570;&#1604;&#1610;&#1577;&#1548;
&#1591;&#1575;&#1604;&#1605;&#1575; &#1571;&#1606;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1594;&#1610;&#1585;
&#1590;&#1585;&#1608;&#1585;&#1610;&#1577; &#1604;&#1571;&#1583;&#1575;&#1569;
&#1605;&#1607;&#1605;&#1577; &#1578;&#1578;&#1605; &#1601;&#1610; &#1575;&#1604;&#1605;&#1589;&#1604;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577; &#1571;&#1608; &#1601;&#1610;
&#1605;&#1605;&#1575;&#1585;&#1587;&#1577;
&#1575;&#1604;&#1587;&#1604;&#1591;&#1577;
&#1575;&#1604;&#1585;&#1587;&#1605;&#1610;&#1577; &#1575;&#1604;&#1605;&#1582;&#1608;&#1604;&#1577;
&#1604;&#1606;&#1575;.</span></p>

<p class=MsoListParagraph dir=RTL style='margin-top:0in;margin-right:.5in;
margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1581;&#1602;
&#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590;</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1604;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590;&#1548;
&#1593;&#1604;&#1609; &#1571;&#1587;&#1587; &#1578;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1608;&#1590;&#1593;&#1603; &#1575;&#1604;&#1582;&#1575;&#1589;&#1548;
&#1601;&#1610; &#1571;&#1610; &#1608;&#1602;&#1578;&#1548;
&#1593;&#1604;&#1609; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1603;&#1548;
&#1608;&#1575;&#1604;&#1578;&#1610; &#1578;&#1593;&#1578;&#1605;&#1583;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1606;&#1602;&#1591;&#1577;
(&#1607;&#1600;) &#1571;&#1608; (&#1608;) &#1605;&#1606;
&#1575;&#1604;&#1605;&#1575;&#1583;&#1577; 6(1) &#1605;&#1606;
&#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;.
&#1610;&#1606;&#1591;&#1576;&#1602; &#1607;&#1584;&#1575;
&#1571;&#1610;&#1590;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1578;&#1606;&#1605;&#1610;&#1591;
&#1575;&#1604;&#1602;&#1575;&#1574;&#1605; &#1593;&#1604;&#1609;
&#1607;&#1584;&#1607; &#1575;&#1604;&#1571;&#1581;&#1603;&#1575;&#1605;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1606;
&#1606;&#1602;&#1608;&#1605; &#1576;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1601;&#1610;
&#1581;&#1575;&#1604;&#1577; &#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590;&#1548;
&#1605;&#1575; &#1604;&#1605; &#1606;&#1578;&#1605;&#1603;&#1606;
&#1605;&#1606; &#1573;&#1579;&#1576;&#1575;&#1578;
&#1571;&#1587;&#1576;&#1575;&#1576; &#1605;&#1588;&#1585;&#1608;&#1593;&#1577; &#1605;&#1604;&#1581;&#1577;
&#1604;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1578;&#1578;&#1580;&#1575;&#1608;&#1586;
&#1605;&#1589;&#1575;&#1604;&#1581;&#1548; &#1581;&#1602;&#1608;&#1602;
&#1608;&#1581;&#1585;&#1610;&#1575;&#1578; &#1589;&#1575;&#1581;&#1576;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548; &#1571;&#1608;
&#1604;&#1573;&#1606;&#1588;&#1575;&#1569;&#1548;
&#1605;&#1605;&#1575;&#1585;&#1587;&#1577; &#1571;&#1608;
&#1575;&#1604;&#1583;&#1601;&#1575;&#1593; &#1593;&#1606;
&#1575;&#1604;&#1605;&#1591;&#1575;&#1604;&#1576;&#1575;&#1578;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1573;&#1584;&#1575;
&#1602;&#1605;&#1606;&#1575; &#1576;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;
&#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;&#1548;
&#1601;&#1604;&#1583;&#1610;&#1603; &#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
&#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590; &#1601;&#1610;
&#1571;&#1610; &#1608;&#1602;&#1578; &#1593;&#1604;&#1609;
&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1603;
&#1604;&#1607;&#1584;&#1575; &#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;.
&#1610;&#1606;&#1591;&#1576;&#1602; &#1607;&#1584;&#1575; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1578;&#1606;&#1605;&#1610;&#1591; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1581;&#1583; &#1575;&#1604;&#1584;&#1610;
&#1610;&#1585;&#1578;&#1576;&#1591; &#1576;&#1607;&#1584;&#1575;
&#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;
&#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;. &#1573;&#1584;&#1575;
&#1575;&#1593;&#1578;&#1585;&#1590;&#1578; &#1593;&#1604;&#1609;
&#1605;&#1593;&#1575;&#1604;&#1580;&#1578;&#1606;&#1575;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1575;&#1604;&#1578;&#1587;&#1608;&#1610;&#1602;
&#1575;&#1604;&#1605;&#1576;&#1575;&#1588;&#1585;&#1548; &#1601;&#1604;&#1606;
&#1606;&#1602;&#1608;&#1605; &#1576;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1604;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1575;&#1604;&#1573;&#1590;&#1575;&#1601;&#1577;
&#1573;&#1604;&#1609; &#1584;&#1604;&#1603;&#1548; &#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602;&#1548; &#1593;&#1604;&#1609; &#1571;&#1587;&#1587;
&#1578;&#1578;&#1593;&#1604;&#1602; &#1576;&#1608;&#1590;&#1593;&#1603;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1548; &#1601;&#1610;
&#1575;&#1604;&#1575;&#1593;&#1578;&#1585;&#1575;&#1590; &#1593;&#1604;&#1609;
&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577; &#1576;&#1603;
&#1605;&#1606; &#1602;&#1576;&#1604;&#1606;&#1575;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590; &#1575;&#1604;&#1576;&#1581;&#1579;
&#1575;&#1604;&#1593;&#1604;&#1605;&#1610; &#1571;&#1608;
&#1575;&#1604;&#1578;&#1575;&#1585;&#1610;&#1582;&#1610;&#1548; &#1571;&#1608;
&#1604;&#1571;&#1594;&#1585;&#1575;&#1590;
&#1573;&#1581;&#1589;&#1575;&#1574;&#1610;&#1577; &#1608;&#1601;&#1602;&#1611;&#1575;
&#1604;&#1604;&#1605;&#1575;&#1583;&#1577; 89(1) &#1605;&#1606; &#1575;&#1604;&#1604;&#1575;&#1574;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;
&#1604;&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1548; &#1605;&#1575;
&#1604;&#1605; &#1578;&#1603;&#1606; &#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1590;&#1585;&#1608;&#1585;&#1610;&#1577; &#1604;&#1571;&#1583;&#1575;&#1569;
&#1605;&#1607;&#1605;&#1577; &#1578;&#1578;&#1605;
&#1604;&#1571;&#1587;&#1576;&#1575;&#1576; &#1578;&#1578;&#1593;&#1604;&#1602;
&#1576;&#1575;&#1604;&#1605;&#1589;&#1604;&#1581;&#1577;
&#1575;&#1604;&#1593;&#1575;&#1605;&#1577;.</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraph dir=RTL style='margin-top:0in;margin-right:.5in;
margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1578;&#1582;&#1575;&#1584;
&#1602;&#1585;&#1575;&#1585;&#1575;&#1578; &#1601;&#1585;&#1583;&#1610;&#1577;
&#1570;&#1604;&#1610;&#1577;&#1548; &#1576;&#1605;&#1575; &#1601;&#1610;
&#1584;&#1604;&#1603; &#1575;&#1604;&#1578;&#1606;&#1605;&#1610;&#1591;</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1601;&#1610;
&#1593;&#1583;&#1605; &#1575;&#1604;&#1578;&#1593;&#1585;&#1590;
&#1604;&#1602;&#1585;&#1575;&#1585; &#1610;&#1593;&#1578;&#1605;&#1583;
&#1601;&#1602;&#1591; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1593;&#1575;&#1604;&#1580;&#1577; &#1575;&#1604;&#1570;&#1604;&#1610;&#1577;&#1548;
&#1576;&#1605;&#1575; &#1601;&#1610; &#1584;&#1604;&#1603;
&#1575;&#1604;&#1578;&#1606;&#1605;&#1610;&#1591;&#1548;
&#1608;&#1575;&#1604;&#1584;&#1610; &#1610;&#1606;&#1578;&#1580;
&#1593;&#1606;&#1607; &#1570;&#1579;&#1575;&#1585;
&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577; &#1578;&#1582;&#1589;&#1603;
&#1571;&#1608; &#1610;&#1572;&#1579;&#1585; &#1593;&#1604;&#1610;&#1603;
&#1576;&#1588;&#1603;&#1604; &#1603;&#1576;&#1610;&#1585;&#1548;
&#1591;&#1575;&#1604;&#1605;&#1575; &#1571;&#1606;
&#1575;&#1604;&#1602;&#1585;&#1575;&#1585; (1) &#1604;&#1610;&#1587;
&#1590;&#1585;&#1608;&#1585;&#1610;&#1611;&#1575;
&#1604;&#1573;&#1576;&#1585;&#1575;&#1605; &#1593;&#1602;&#1583; &#1571;&#1608;
&#1578;&#1606;&#1601;&#1610;&#1584;&#1607; &#1576;&#1610;&#1606;&#1603;
&#1608;&#1576;&#1610;&#1606;&#1606;&#1575;&#1548; &#1571;&#1608; (2)
&#1604;&#1610;&#1587; &#1605;&#1589;&#1585;&#1581;&#1611;&#1575; &#1576;&#1607;
&#1576;&#1605;&#1608;&#1580;&#1576; &#1602;&#1575;&#1606;&#1608;&#1606;
&#1575;&#1604;&#1575;&#1578;&#1581;&#1575;&#1583; &#1571;&#1608;
&#1575;&#1604;&#1583;&#1608;&#1604;&#1577; &#1575;&#1604;&#1593;&#1590;&#1608;
&#1575;&#1604;&#1578;&#1610; &#1606;&#1582;&#1590;&#1593; &#1604;&#1607;&#1575;
&#1608;&#1575;&#1604;&#1584;&#1610; &#1610;&#1606;&#1589;
&#1571;&#1610;&#1590;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1578;&#1583;&#1575;&#1576;&#1610;&#1585;
&#1605;&#1606;&#1575;&#1587;&#1576;&#1577;
&#1604;&#1581;&#1605;&#1575;&#1610;&#1577; &#1581;&#1602;&#1608;&#1602;&#1603;
&#1608;&#1581;&#1585;&#1610;&#1575;&#1578;&#1603; &#1608;&#1605;&#1589;&#1575;&#1604;&#1581;&#1603;
&#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;&#1548; &#1571;&#1608;
(3) &#1604;&#1610;&#1587; &#1605;&#1576;&#1606;&#1610;&#1611;&#1575;
&#1593;&#1604;&#1609; &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603; &#1575;&#1604;&#1589;&#1585;&#1610;&#1581;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1573;&#1584;&#1575;
&#1603;&#1575;&#1606; &#1575;&#1604;&#1602;&#1585;&#1575;&#1585; (1)
&#1590;&#1585;&#1608;&#1585;&#1610;&#1611;&#1575;
&#1604;&#1573;&#1576;&#1585;&#1575;&#1605; &#1593;&#1602;&#1583; &#1571;&#1608;
&#1578;&#1606;&#1601;&#1610;&#1584;&#1607; &#1576;&#1610;&#1606;&#1603;
&#1608;&#1576;&#1610;&#1606;&#1606;&#1575;&#1548; &#1571;&#1608; (2)
&#1605;&#1576;&#1606;&#1610;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603; &#1575;&#1604;&#1589;&#1585;&#1610;&#1581;&#1577;&#1548;
&#1587;&#1606;&#1606;&#1601;&#1584; &#1578;&#1583;&#1575;&#1576;&#1610;&#1585;
&#1605;&#1606;&#1575;&#1587;&#1576;&#1577;
&#1604;&#1581;&#1605;&#1575;&#1610;&#1577; &#1581;&#1602;&#1608;&#1602;&#1603;
&#1608;&#1581;&#1585;&#1610;&#1575;&#1578;&#1603;
&#1608;&#1605;&#1589;&#1575;&#1604;&#1581;&#1603;
&#1575;&#1604;&#1605;&#1588;&#1585;&#1608;&#1593;&#1577;&#1548;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1571;&#1602;&#1604;
&#1575;&#1604;&#1581;&#1602; &#1601;&#1610; &#1575;&#1604;&#1581;&#1589;&#1608;&#1604;
&#1593;&#1604;&#1609; &#1578;&#1583;&#1582;&#1604; &#1576;&#1588;&#1585;&#1610;
&#1605;&#1606; &#1580;&#1575;&#1606;&#1576;&#1606;&#1575;&#1548;
&#1604;&#1604;&#1578;&#1593;&#1576;&#1610;&#1585; &#1593;&#1606;
&#1608;&#1580;&#1607;&#1577; &#1606;&#1592;&#1585;&#1603;
&#1608;&#1575;&#1604;&#1591;&#1593;&#1606; &#1601;&#1610;
&#1575;&#1604;&#1602;&#1585;&#1575;&#1585;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraph dir=RTL style='margin-top:0in;margin-right:.5in;
margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1581;&#1602;
&#1587;&#1581;&#1576; &#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
&#1581;&#1605;&#1575;&#1610;&#1577;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;</span></p>

<p class=MsoNormal dir=RTL style='margin-right:37.3pt;text-align:right;
direction:rtl;unicode-bidi:embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1575;&#1604;&#1605;&#1605;&#1606;&#1608;&#1581;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1575;&#1604;&#1605;&#1588;&#1585;&#1593;
&#1575;&#1604;&#1571;&#1608;&#1585;&#1608;&#1576;&#1610; &#1601;&#1610;
&#1587;&#1581;&#1576; &#1605;&#1608;&#1575;&#1601;&#1602;&#1578;&#1603;
&#1593;&#1604;&#1609; &#1605;&#1593;&#1575;&#1604;&#1580;&#1577;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;&#1603;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1601;&#1610; &#1571;&#1610;
&#1608;&#1602;&#1578;. &#1610;&#1605;&#1603;&#1606;&#1603;
&#1576;&#1583;&#1569; &#1591;&#1604;&#1576; &#1605;&#1593;&#1606;&#1575;
&#1593;&#1604;&#1609; </span><span lang=EN-GB dir=LTR>Hello@jeelapp.com</span><span
dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'><span
dir=RTL></span> &#1604;&#1605;&#1605;&#1575;&#1585;&#1587;&#1577;
&#1571;&#1610; &#1605;&#1606; &#1575;&#1604;&#1581;&#1602;&#1608;&#1602;
&#1575;&#1604;&#1605;&#1584;&#1603;&#1608;&#1585;&#1577;
&#1571;&#1593;&#1604;&#1575;&#1607;. &#1587;&#1606;&#1585;&#1575;&#1580;&#1593;
&#1591;&#1604;&#1576;&#1603; &#1608;&#1548; &#1581;&#1587;&#1576;
&#1578;&#1602;&#1583;&#1610;&#1585;&#1606;&#1575;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1548; &#1606;&#1581;&#1578;&#1585;&#1605;
&#1591;&#1604;&#1576;&#1603;&#1548; &#1573;&#1584;&#1575;
&#1575;&#1593;&#1578;&#1576;&#1585;&#1606;&#1575; &#1584;&#1604;&#1603;
&#1590;&#1585;&#1608;&#1585;&#1610;&#1611;&#1575;&#1548;
&#1582;&#1604;&#1575;&#1604; &#1608;&#1602;&#1578;
&#1605;&#1593;&#1602;&#1608;&#1604;.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602; &#1605;&#1606;
&#1602;&#1576;&#1604; &#1575;&#1604;&#1571;&#1591;&#1601;&#1575;&#1604;
&#1608;&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604; &#1604;&#1602;&#1575;&#1606;&#1608;&#1606;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span> COPPA</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1610;&#1587;&#1593;&#1609;
&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;
&#1604;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1604;&#1608;&#1575;&#1574;&#1581;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> COPPA. COPPA </span><span lang=AR-SA
style='font-family:"Arial","sans-serif"'>&#1607;&#1610;
&#1575;&#1582;&#1578;&#1589;&#1575;&#1585; &#1604;&#1600;
&quot;&#1602;&#1575;&#1606;&#1608;&#1606; &#1581;&#1605;&#1575;&#1610;&#1577;
&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1571;&#1591;&#1601;&#1575;&#1604; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;&quot;&#1548;
&#1608;&#1607;&#1608; &#1602;&#1575;&#1606;&#1608;&#1606;
&#1601;&#1610;&#1583;&#1585;&#1575;&#1604;&#1610;
&#1571;&#1605;&#1585;&#1610;&#1603;&#1610; &#1610;&#1615;&#1601;&#1585;&#1590;
&#1605;&#1606; &#1602;&#1576;&#1604; &#1604;&#1580;&#1606;&#1577;
&#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1577;
&#1575;&#1604;&#1601;&#1610;&#1583;&#1585;&#1575;&#1604;&#1610;&#1577;.
&#1575;&#1604;&#1594;&#1585;&#1590; &#1605;&#1606;&#1607; &#1607;&#1608;
&#1605;&#1606;&#1581; &#1575;&#1604;&#1570;&#1576;&#1575;&#1569;
&#1575;&#1604;&#1587;&#1610;&#1591;&#1585;&#1577; &#1593;&#1604;&#1609;
&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1571;&#1591;&#1601;&#1575;&#1604;&#1607;&#1605;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;.
&#1610;&#1601;&#1585;&#1590;</span><span dir=LTR></span><span lang=EN-GB
dir=LTR><span dir=LTR></span> COPPA </span><span lang=AR-SA style='font-family:
"Arial","sans-serif"'>&#1575;&#1604;&#1578;&#1586;&#1575;&#1605;&#1575;&#1578;
&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577; &#1582;&#1575;&#1589;&#1577;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1605;&#1608;&#1575;&#1602;&#1593;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577;&#1548;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1581;&#1605;&#1608;&#1604;&#1577;&#1548;
&#1575;&#1604;&#1571;&#1580;&#1607;&#1586;&#1577; &#1575;&#1604;&#1604;&#1608;&#1581;&#1610;&#1577;&#1548;
&#1608;&#1594;&#1610;&#1585;&#1607;&#1575; &#1605;&#1606;
&#1575;&#1604;&#1578;&#1603;&#1606;&#1608;&#1604;&#1608;&#1580;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1605;&#1575;&#1579;&#1604;&#1577; &#1575;&#1604;&#1605;&#1589;&#1605;&#1605;&#1577;
&#1604;&#1604;&#1571;&#1591;&#1601;&#1575;&#1604; &#1583;&#1608;&#1606;
&#1587;&#1606; 13 &#1593;&#1575;&#1605;&#1611;&#1575;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1575;&#1587;&#1578;&#1579;&#1606;&#1575;&#1569;&#1575;&#1578;
&#1605;&#1593;&#1610;&#1606;&#1577;&#1548; &#1610;&#1578;&#1591;&#1604;&#1576;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span> COPPA </span><span
lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1605;&#1606;
&#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578;
&#1575;&#1604;&#1581;&#1589;&#1608;&#1604; &#1593;&#1604;&#1609;
&#1573;&#1584;&#1606; &#1605;&#1608;&#1579;&#1602; &#1605;&#1606;
&#1575;&#1604;&#1608;&#1575;&#1604;&#1583;&#1610;&#1606; (&#1605;&#1579;&#1604;
&#1593;&#1606; &#1591;&#1585;&#1610;&#1602;
&#1605;&#1593;&#1575;&#1605;&#1604;&#1577; &#1576;&#1591;&#1575;&#1602;&#1577;
&#1575;&#1604;&#1575;&#1574;&#1578;&#1605;&#1575;&#1606;&#1548;
&#1605;&#1603;&#1575;&#1604;&#1605;&#1577; &#1607;&#1575;&#1578;&#1601;&#1610;&#1577;
&#1604;&#1583;&#1593;&#1605;
&#1575;&#1604;&#1593;&#1605;&#1604;&#1575;&#1569;&#1548; &#1571;&#1608;
&#1591;&#1585;&#1610;&#1602;&#1577;
&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1577; &#1571;&#1582;&#1585;&#1609;)
&#1602;&#1576;&#1604; &#1580;&#1605;&#1593; &#1571;&#1608;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1588;&#1582;&#1589;&#1610;&#1577; &#1604;&#1604;&#1591;&#1601;&#1604;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;.
&quot;&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&quot;
&#1578;&#1588;&#1605;&#1604; &#1575;&#1587;&#1605; &#1575;&#1604;&#1591;&#1601;&#1604;&#1548;
&#1593;&#1606;&#1608;&#1575;&#1606;
&#1575;&#1604;&#1605;&#1606;&#1586;&#1604;&#1548;
&#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1548;
&#1585;&#1602;&#1605; &#1575;&#1604;&#1607;&#1575;&#1578;&#1601;&#1548;
&#1575;&#1604;&#1589;&#1608;&#1585;&#1577;&#1548;
&#1578;&#1587;&#1580;&#1610;&#1604; &#1575;&#1604;&#1589;&#1608;&#1578;&#1548;
&#1575;&#1604;&#1605;&#1608;&#1602;&#1593;
&#1575;&#1604;&#1580;&#1594;&#1585;&#1575;&#1601;&#1610;&#1548;
&#1608;&#1576;&#1593;&#1590;
&#1575;&#1604;&#1605;&#1593;&#1585;&#1601;&#1575;&#1578;
&#1575;&#1604;&#1601;&#1585;&#1610;&#1583;&#1577; (&#1605;&#1579;&#1604;
&#1605;&#1593;&#1585;&#1601; &#1575;&#1604;&#1580;&#1607;&#1575;&#1586;). &#1583;&#1608;&#1606;
&#1573;&#1584;&#1606; &#1575;&#1604;&#1608;&#1575;&#1604;&#1583;&#1610;&#1606;
&#1575;&#1604;&#1605;&#1587;&#1576;&#1602;&#1548; &#1610;&#1581;&#1592;&#1585;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span> COPPA </span><span
lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1571;&#1606;&#1608;&#1575;&#1593;&#1611;&#1575;
&#1605;&#1593;&#1610;&#1606;&#1577; &#1605;&#1606;
&#1575;&#1604;&#1573;&#1593;&#1604;&#1575;&#1606;&#1575;&#1578;
&#1593;&#1576;&#1585;
&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;&#1548; &#1575;&#1604;&#1605;&#1606;&#1578;&#1583;&#1610;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1580;&#1578;&#1605;&#1593;&#1610;&#1577;&#1548;
&#1608;&#1605;&#1610;&#1586;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1576;&#1603;&#1575;&#1578; &#1575;&#1604;&#1575;&#1580;&#1578;&#1605;&#1575;&#1593;&#1610;&#1577;.
&#1603;&#1605;&#1575; &#1610;&#1578;&#1591;&#1604;&#1576;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> COPPA </span><span lang=AR-SA
style='font-family:"Arial","sans-serif"'>&#1605;&#1606;
&#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578; &#1606;&#1588;&#1585;
&#1587;&#1610;&#1575;&#1587;&#1577; &#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1581;&#1601;&#1575;&#1592; &#1593;&#1604;&#1609;
&#1571;&#1605;&#1575;&#1606; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1571;&#1591;&#1601;&#1575;&#1604;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1605;&#1608;&#1580;&#1576;
&#1602;&#1575;&#1606;&#1608;&#1606;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> COPPA</span><span dir=RTL></span><span
lang=AR-SA style='font-family:"Arial","sans-serif"'><span dir=RTL></span>&#1548;
&#1610;&#1605;&#1603;&#1606;&#1603; &#1591;&#1604;&#1576; &#1605;&#1606;
&#1575;&#1604;&#1588;&#1585;&#1603;&#1577; &#1581;&#1584;&#1601; &#1571;&#1608;
&#1575;&#1604;&#1578;&#1608;&#1602;&#1601; &#1593;&#1606;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1604;&#1591;&#1601;&#1604;&#1603; &#1601;&#1610; &#1571;&#1610;
&#1608;&#1602;&#1578;. &#1610;&#1615;&#1606;&#1589;&#1581; &#1576;&#1605;&#1585;&#1575;&#1580;&#1593;&#1577;
&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1604;&#1603;&#1604;
&#1588;&#1585;&#1603;&#1577; &#1576;&#1593;&#1606;&#1575;&#1610;&#1577;
&#1602;&#1576;&#1604; &#1575;&#1604;&#1587;&#1605;&#1575;&#1581;
&#1604;&#1591;&#1601;&#1604;&#1603;
&#1576;&#1605;&#1588;&#1575;&#1585;&#1603;&#1577; &#1571;&#1610;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1588;&#1582;&#1589;&#1610;&#1577; &#1605;&#1593; &#1578;&#1604;&#1603;
&#1575;&#1604;&#1588;&#1585;&#1603;&#1577;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1606;&#1581;&#1606;
&#1606;&#1587;&#1593;&#1609; &#1604;&#1578;&#1579;&#1602;&#1610;&#1601;
&#1575;&#1604;&#1570;&#1576;&#1575;&#1569;
&#1608;&#1575;&#1604;&#1571;&#1591;&#1601;&#1575;&#1604; &#1581;&#1608;&#1604;
&#1603;&#1610;&#1601;&#1610;&#1577; &#1581;&#1605;&#1575;&#1610;&#1577;
&#1582;&#1589;&#1608;&#1589;&#1610;&#1578;&#1607;&#1605;
&#1576;&#1588;&#1603;&#1604; &#1605;&#1606;&#1575;&#1587;&#1576;
&#1593;&#1606;&#1583; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;. &#1606;&#1581;&#1606;
&#1605;&#1604;&#1578;&#1586;&#1605;&#1608;&#1606;
&#1576;&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1581;&#1605;&#1575;&#1610;&#1577;
&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1571;&#1591;&#1601;&#1575;&#1604; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578;
&#1604;&#1593;&#1575;&#1605; 1998 (&quot;</span><span lang=EN-GB dir=LTR>COPPA</span><span
dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'><span
dir=RTL></span>&quot;) &#1575;&#1604;&#1584;&#1610;
&#1610;&#1578;&#1591;&#1604;&#1576; &#1605;&#1606;&#1575;
&#1573;&#1576;&#1604;&#1575;&#1594; &#1575;&#1604;&#1570;&#1576;&#1575;&#1569;
&#1608;&#1575;&#1604;&#1571;&#1608;&#1589;&#1610;&#1575;&#1569; &#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1610;&#1606;
&#1576;&#1605;&#1605;&#1575;&#1585;&#1587;&#1575;&#1578; &#1580;&#1605;&#1593;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;&#1575;.
&#1603;&#1605;&#1575; &#1610;&#1578;&#1591;&#1604;&#1576; </span><span
lang=EN-GB dir=LTR>COPPA</span><span dir=RTL></span><span lang=AR-SA
style='font-family:"Arial","sans-serif"'><span dir=RTL></span>
&#1605;&#1606;&#1575; &#1575;&#1604;&#1581;&#1589;&#1608;&#1604;
&#1593;&#1604;&#1609; &#1605;&#1608;&#1575;&#1601;&#1602;&#1577;
&#1575;&#1604;&#1608;&#1575;&#1604;&#1583;&#1610;&#1606; &#1602;&#1576;&#1604;
&#1575;&#1604;&#1587;&#1605;&#1575;&#1581; &#1604;&#1604;&#1571;&#1591;&#1601;&#1575;&#1604;
&#1583;&#1608;&#1606; &#1587;&#1606; 13 &#1593;&#1575;&#1605;&#1611;&#1575;
&#1576;&#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575; &#1608;/&#1571;&#1608;
&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1607;.
&#1606;&#1608;&#1589;&#1610; &#1575;&#1604;&#1570;&#1576;&#1575;&#1569;
&#1576;&#1605;&#1606;&#1575;&#1602;&#1588;&#1577;
&#1575;&#1604;&#1602;&#1610;&#1608;&#1583; &#1575;&#1604;&#1605;&#1578;&#1593;&#1604;&#1602;&#1577;
&#1576;&#1573;&#1591;&#1604;&#1575;&#1602;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1593;&#1576;&#1585;
&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578; &#1605;&#1593;
&#1571;&#1591;&#1601;&#1575;&#1604;&#1607;&#1605; &#1604;&#1571;&#1610;
&#1588;&#1582;&#1589; &#1604;&#1575;
&#1610;&#1593;&#1585;&#1601;&#1608;&#1606;&#1607;. &#1606;&#1581;&#1606;
&#1606;&#1587;&#1593;&#1609; &#1604;&#1605;&#1587;&#1575;&#1593;&#1583;&#1577;
&#1575;&#1604;&#1570;&#1576;&#1575;&#1569; &#1593;&#1604;&#1609;
&#1590;&#1605;&#1575;&#1606; &#1581;&#1589;&#1608;&#1604;
&#1571;&#1591;&#1601;&#1575;&#1604;&#1607;&#1605; &#1593;&#1604;&#1609;
&#1578;&#1580;&#1585;&#1576;&#1577; &#1570;&#1605;&#1606;&#1577; &#1576;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;
&#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1571;&#1582;&#1585;&#1609;. &#1610;&#1605;&#1603;&#1606;
&#1604;&#1604;&#1608;&#1575;&#1604;&#1583;&#1610;&#1606; &#1571;&#1608;
&#1575;&#1604;&#1571;&#1608;&#1589;&#1610;&#1575;&#1569;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;&#1610;&#1606;
&#1605;&#1585;&#1575;&#1580;&#1593;&#1577; &#1571;&#1610; &#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1588;&#1582;&#1589;&#1610;&#1577; &#1578;&#1605;
&#1580;&#1605;&#1593;&#1607;&#1575; &#1593;&#1606;
&#1591;&#1601;&#1604;&#1607;&#1605; &#1583;&#1608;&#1606; &#1587;&#1606; 13
&#1593;&#1575;&#1605;&#1611;&#1575;&#1548; &#1608;&#1591;&#1604;&#1576;
&#1581;&#1584;&#1601; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1548;
&#1571;&#1608; &#1591;&#1604;&#1576; &#1593;&#1583;&#1605;
&#1580;&#1605;&#1593; &#1571;&#1608; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1586;&#1610;&#1583; &#1605;&#1606;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1604;&#1591;&#1601;&#1604;&#1607;&#1605; (&#1575;&#1587;&#1605;
&#1608;&#1593;&#1605;&#1585;)&#1548; &#1608;/&#1571;&#1608;
&#1575;&#1604;&#1587;&#1605;&#1575;&#1581; &#1576;&#1580;&#1605;&#1593;
&#1608;&#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;
&#1604;&#1591;&#1601;&#1604;&#1607;&#1605; &#1605;&#1593;
&#1575;&#1604;&#1575;&#1605;&#1578;&#1606;&#1575;&#1593; &#1593;&#1606;
&#1575;&#1604;&#1573;&#1584;&#1606; &#1604;&#1606;&#1575;
&#1576;&#1575;&#1604;&#1603;&#1588;&#1601; &#1593;&#1606;&#1607;&#1575;
&#1604;&#1571;&#1591;&#1585;&#1575;&#1601; &#1579;&#1575;&#1604;&#1579;&#1577;.
&#1602;&#1583; &#1606;&#1578;&#1582;&#1584; &#1582;&#1591;&#1608;&#1575;&#1578;
&#1604;&#1604;&#1578;&#1581;&#1602;&#1602; &#1605;&#1606;
&#1607;&#1608;&#1610;&#1577; &#1571;&#1610; &#1588;&#1582;&#1589;
&#1610;&#1591;&#1604;&#1576; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1593;&#1606; &#1591;&#1601;&#1604; &#1608;&#1604;&#1604;&#1578;&#1571;&#1603;&#1583;
&#1605;&#1606; &#1571;&#1606; &#1575;&#1604;&#1588;&#1582;&#1589;
&#1607;&#1608; &#1576;&#1575;&#1604;&#1601;&#1593;&#1604;
&#1608;&#1575;&#1604;&#1583; &#1575;&#1604;&#1591;&#1601;&#1604; &#1571;&#1608;
&#1575;&#1604;&#1608;&#1589;&#1610;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;&#1610;.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> CalOPPA</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1606;&#1581;&#1606;
&#1605;&#1604;&#1578;&#1586;&#1605;&#1608;&#1606;
&#1571;&#1610;&#1590;&#1611;&#1575;
&#1576;&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1581;&#1605;&#1575;&#1610;&#1577; &#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578; &#1601;&#1610;
&#1603;&#1575;&#1604;&#1610;&#1601;&#1608;&#1585;&#1606;&#1610;&#1575;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>
(&quot;CalOPPA&quot;) </span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1584;&#1610;
&#1610;&#1578;&#1591;&#1604;&#1576; &#1605;&#1606;
&#1575;&#1604;&#1605;&#1608;&#1575;&#1602;&#1593;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;&#1577;
&#1575;&#1604;&#1578;&#1580;&#1575;&#1585;&#1610;&#1577;
&#1608;&#1575;&#1604;&#1582;&#1583;&#1605;&#1575;&#1578; &#1593;&#1576;&#1585;
&#1575;&#1604;&#1573;&#1606;&#1578;&#1585;&#1606;&#1578; &#1606;&#1588;&#1585;
&#1587;&#1610;&#1575;&#1587;&#1577; &#1582;&#1589;&#1608;&#1589;&#1610;&#1577;.
&#1610;&#1578;&#1591;&#1604;&#1576;
&#1575;&#1604;&#1602;&#1575;&#1606;&#1608;&#1606; &#1605;&#1606; &#1571;&#1610;
&#1588;&#1582;&#1589; &#1571;&#1608; &#1588;&#1585;&#1603;&#1577;
&#1601;&#1610; &#1575;&#1604;&#1608;&#1604;&#1575;&#1610;&#1575;&#1578; &#1575;&#1604;&#1605;&#1578;&#1581;&#1583;&#1577;
(&#1608;&#1610;&#1605;&#1603;&#1606; &#1578;&#1589;&#1608;&#1585;&#1607;
&#1601;&#1610; &#1575;&#1604;&#1593;&#1575;&#1604;&#1605;)
&#1610;&#1583;&#1610;&#1585; &#1605;&#1608;&#1575;&#1602;&#1593; &#1608;&#1610;&#1576;/&#1578;&#1591;&#1576;&#1610;&#1602;&#1575;&#1578;
&#1578;&#1580;&#1605;&#1593;
&quot;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1588;&#1582;&#1589;&#1610;&#1577; &#1602;&#1575;&#1576;&#1604;&#1577;
&#1604;&#1604;&#1578;&#1593;&#1585;&#1610;&#1601;&quot; &#1605;&#1606;
&#1605;&#1587;&#1578;&#1607;&#1604;&#1603;&#1610;
&#1603;&#1575;&#1604;&#1610;&#1601;&#1608;&#1585;&#1606;&#1610;&#1575;
&#1606;&#1588;&#1585; &#1587;&#1610;&#1575;&#1587;&#1577;
&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1608;&#1575;&#1590;&#1581;&#1577;
&#1578;&#1581;&#1583;&#1583; &#1576;&#1575;&#1604;&#1590;&#1576;&#1591;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1610;&#1578;&#1605; &#1580;&#1605;&#1593;&#1607;&#1575;
&#1608;&#1575;&#1604;&#1571;&#1601;&#1585;&#1575;&#1583; &#1571;&#1608;
&#1575;&#1604;&#1588;&#1585;&#1603;&#1575;&#1578; &#1575;&#1604;&#1578;&#1610;
&#1610;&#1578;&#1605; &#1605;&#1588;&#1575;&#1585;&#1603;&#1578;&#1607;&#1575;
&#1605;&#1593;&#1607;&#1575;. &#1603;&#1605;&#1575; &#1607;&#1608;
&#1605;&#1591;&#1604;&#1608;&#1576; &#1576;&#1605;&#1608;&#1580;&#1576;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span> CalOPPA</span><span
dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'><span
dir=RTL></span>&#1548; &#1606;&#1581;&#1606;
&#1606;&#1608;&#1575;&#1601;&#1602; &#1593;&#1604;&#1609; &#1605;&#1575;
&#1610;&#1604;&#1610;</span><span dir=LTR></span><span lang=EN-GB dir=LTR><span
dir=LTR></span>:</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
121.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1610;&#1605;&#1603;&#1606;
&#1604;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1610;&#1606;
&#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
&#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575; &#1576;&#1588;&#1603;&#1604;
&#1605;&#1580;&#1607;&#1608;&#1604;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
121.5pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1585;&#1575;&#1576;&#1591;
&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1606;&#1575;
&#1610;&#1578;&#1590;&#1605;&#1606; &#1603;&#1604;&#1605;&#1577; &quot;&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;&quot;
&#1608;&#1610;&#1605;&#1603;&#1606; &#1575;&#1604;&#1593;&#1579;&#1608;&#1585;
&#1593;&#1604;&#1610;&#1607; &#1576;&#1587;&#1607;&#1608;&#1604;&#1577;
&#1601;&#1610; &#1578;&#1591;&#1576;&#1610;&#1602;&#1606;&#1575;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1606;&#1587;&#1593;&#1609;
&#1604;&#1578;&#1604;&#1576;&#1610;&#1577;
&#1605;&#1578;&#1591;&#1604;&#1576;&#1575;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> CalOPPA </span><span lang=AR-SA
style='font-family:"Arial","sans-serif"'>&#1601;&#1610;
&#1573;&#1591;&#1575;&#1585; &#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1607;&#1584;&#1607;
&#1576;&#1605;&#1575; &#1610;&#1578;&#1605;&#1575;&#1588;&#1609; &#1605;&#1593;
&#1587;&#1610;&#1575;&#1587;&#1578;&#1606;&#1575; &#1604;&#1580;&#1605;&#1593;
&#1576;&#1610;&#1575;&#1606;&#1575;&#1578;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1582;&#1583;&#1605;&#1548;
&#1575;&#1604;&#1578;&#1582;&#1586;&#1610;&#1606;&#1548; &#1608;&#1575;&#1604;&#1603;&#1588;&#1601;
&#1603;&#1605;&#1575; &#1607;&#1608; &#1605;&#1608;&#1590;&#1581;
&#1604;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1605;&#1578;&#1591;&#1604;&#1576;&#1575;&#1578;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> GDPR.</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>13.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1575;&#1605;&#1578;&#1579;&#1575;&#1604;
&#1604;&#1602;&#1575;&#1606;&#1608;&#1606;
&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1603;&#1575;&#1604;&#1610;&#1601;&#1608;&#1585;&#1606;&#1610;&#1575; 2018</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1578;&#1606;&#1591;&#1576;&#1602;
&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1607;&#1584;&#1607;
&#1571;&#1610;&#1590;&#1611;&#1575; &#1593;&#1604;&#1609;
&#1587;&#1603;&#1575;&#1606; &#1603;&#1575;&#1604;&#1610;&#1601;&#1608;&#1585;&#1606;&#1610;&#1575;
&#1608;&#1578;&#1588;&#1585;&#1581; &#1603;&#1610;&#1601;
&#1606;&#1580;&#1605;&#1593;&#1548;
&#1606;&#1587;&#1578;&#1582;&#1583;&#1605;&#1548;
&#1608;&#1606;&#1588;&#1575;&#1585;&#1603; &#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1608;&#1603;&#1610;&#1601;
&#1610;&#1605;&#1603;&#1606;&#1603; &#1605;&#1605;&#1575;&#1585;&#1587;&#1577;
&#1581;&#1602;&#1608;&#1602;&#1603; &#1576;&#1605;&#1608;&#1580;&#1576;
&#1602;&#1575;&#1606;&#1608;&#1606; &#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1605;&#1587;&#1578;&#1607;&#1604;&#1603; &#1601;&#1610;
&#1603;&#1575;&#1604;&#1610;&#1601;&#1608;&#1585;&#1606;&#1610;&#1575; &#1604;&#1593;&#1575;&#1605;
2018</span><span dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>
(&quot;CCPA&quot;).</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>14.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1581;&#1602;&#1608;&#1602;&#1603;
&#1576;&#1605;&#1608;&#1580;&#1576;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> CCPA:</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1576;&#1605;&#1608;&#1580;&#1576;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span> CCPA</span><span
dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'><span
dir=RTL></span>&#1548; &#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
&#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1573;&#1604;&#1609;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610; &#1580;&#1605;&#1593;&#1606;&#1575;&#1607;&#1575;
&#1593;&#1606;&#1603; &#1582;&#1604;&#1575;&#1604;
&#1575;&#1604;&#1571;&#1588;&#1607;&#1585; &#1575;&#1604;&#1600; 12
&#1575;&#1604;&#1605;&#1575;&#1590;&#1610;&#1577;
&#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1581;&#1608;&#1604;
&#1605;&#1605;&#1575;&#1585;&#1587;&#1575;&#1578;&#1606;&#1575;
&#1575;&#1604;&#1576;&#1610;&#1575;&#1606;&#1610;&#1577;. &#1603;&#1605;&#1575;
&#1604;&#1583;&#1610;&#1603; &#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
&#1591;&#1604;&#1576; &#1581;&#1584;&#1601;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577; &#1575;&#1604;&#1578;&#1610;
&#1580;&#1605;&#1593;&#1606;&#1575;&#1607;&#1575; &#1605;&#1606;&#1603;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1591;&#1604;&#1576;
&#1575;&#1604;&#1608;&#1589;&#1608;&#1604;
&#1575;&#1604;&#1610;&#1583;&#1608;&#1610; &#1571;&#1608; &#1581;&#1584;&#1601;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;&#1548;
&#1610;&#1585;&#1580;&#1609; &#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604;
&#1605;&#1593;&#1606;&#1575; &#1593;&#1604;&#1609;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span> Hello@jeelapp.com.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1610;&#1585;&#1580;&#1609;
&#1605;&#1604;&#1575;&#1581;&#1592;&#1577; &#1571;&#1606;&#1607;
&#1604;&#1580;&#1605;&#1610;&#1593;
&#1575;&#1604;&#1591;&#1604;&#1576;&#1575;&#1578;
&#1575;&#1604;&#1610;&#1583;&#1608;&#1610;&#1577;&#1548; &#1587;&#1578;&#1581;&#1578;&#1575;&#1580;
&#1573;&#1604;&#1609; &#1575;&#1604;&#1578;&#1581;&#1602;&#1602; &#1605;&#1606;
&#1607;&#1608;&#1610;&#1578;&#1603; &#1593;&#1606; &#1591;&#1585;&#1610;&#1602;
&#1578;&#1586;&#1608;&#1610;&#1583;&#1606;&#1575; &#1576;&#1580;&#1605;&#1610;&#1593;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1575;&#1604;&#1578;&#1610; &#1602;&#1583;
&#1606;&#1591;&#1604;&#1576;&#1607;&#1575; &#1605;&#1606;&#1603;
&#1604;&#1607;&#1584;&#1575; &#1575;&#1604;&#1594;&#1585;&#1590;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1604;&#1583;&#1610;&#1603;
&#1575;&#1604;&#1581;&#1602; &#1601;&#1610; &#1571;&#1606;
&#1578;&#1603;&#1608;&#1606; &#1581;&#1585;&#1611;&#1575; &#1605;&#1606;
&#1571;&#1610; &#1578;&#1605;&#1610;&#1610;&#1586;
&#1604;&#1605;&#1605;&#1575;&#1585;&#1587;&#1577;
&#1581;&#1602;&#1608;&#1602;&#1603; &#1601;&#1610;
&#1575;&#1604;&#1608;&#1589;&#1608;&#1604; &#1571;&#1608; &#1581;&#1584;&#1601;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;&#1603;
&#1575;&#1604;&#1588;&#1582;&#1589;&#1610;&#1577;. &#1604;&#1606;
&#1606;&#1605;&#1610;&#1586; &#1590;&#1583;&#1603;
&#1604;&#1605;&#1605;&#1575;&#1585;&#1587;&#1577; &#1571;&#1610; &#1605;&#1606;
&#1607;&#1584;&#1607; &#1575;&#1604;&#1581;&#1602;&#1608;&#1602;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1573;&#1584;&#1575;
&#1603;&#1575;&#1606; &#1604;&#1583;&#1610;&#1603;
&#1571;&#1587;&#1574;&#1604;&#1577; &#1573;&#1590;&#1575;&#1601;&#1610;&#1577;
&#1581;&#1608;&#1604; &#1607;&#1584;&#1575; &#1571;&#1608;
&#1603;&#1610;&#1601;&#1610;&#1577; &#1605;&#1605;&#1575;&#1585;&#1587;&#1577;
&#1581;&#1602;&#1608;&#1602;&#1603; &#1576;&#1605;&#1608;&#1580;&#1576; </span><span
lang=EN-GB dir=LTR>CCPA</span><span dir=RTL></span><span lang=AR-SA
style='font-family:"Arial","sans-serif"'><span dir=RTL></span>&#1548;
&#1610;&#1585;&#1580;&#1609; &#1575;&#1604;&#1578;&#1608;&#1575;&#1589;&#1604;
&#1605;&#1593;&#1606;&#1575; &#1593;&#1604;&#1609; </span><span lang=EN-GB><a
href="mailto:Hello@jeelapp.com"><span dir=LTR>Hello@jeelapp.com</span></a></span><span
dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'><span
dir=RTL></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=EN-GB dir=LTR>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>15.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1578;&#1594;&#1610;&#1610;&#1585;&#1575;&#1578;
&#1601;&#1610; &#1576;&#1610;&#1575;&#1606;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1607;&#1584;&#1575;</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1602;&#1583;
&#1606;&#1602;&#1608;&#1605; &#1576;&#1578;&#1593;&#1583;&#1610;&#1604;
&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577; &#1607;&#1584;&#1607;
&#1605;&#1606; &#1608;&#1602;&#1578; &#1604;&#1570;&#1582;&#1585;&#1548;
&#1608;&#1571;&#1610; &#1578;&#1594;&#1610;&#1610;&#1585; &#1605;&#1606;
&#1607;&#1584;&#1575; &#1575;&#1604;&#1602;&#1576;&#1610;&#1604; &#1587;&#1610;&#1606;&#1593;&#1603;&#1587;
&#1593;&#1604;&#1609; &#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1605;&#1593; &#1575;&#1604;&#1606;&#1587;&#1582;&#1577;
&#1575;&#1604;&#1605;&#1581;&#1583;&#1579;&#1577; &#1605;&#1606; &#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;&#1548;
&#1608;&#1571;&#1606;&#1578; &#1578;&#1608;&#1575;&#1601;&#1602;
&#1593;&#1604;&#1609; &#1571;&#1606; &#1578;&#1603;&#1608;&#1606;
&#1605;&#1604;&#1586;&#1605;&#1611;&#1575; &#1576;&#1571;&#1610;
&#1578;&#1594;&#1610;&#1610;&#1585;&#1575;&#1578; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1606;&#1587;&#1582;&#1577;
&#1575;&#1604;&#1605;&#1581;&#1583;&#1579;&#1577; &#1605;&#1606;
&#1587;&#1610;&#1575;&#1587;&#1577; &#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1593;&#1606;&#1583; &#1575;&#1587;&#1578;&#1582;&#1583;&#1575;&#1605;&#1603;
&#1604;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602; &#1571;&#1608;
&#1582;&#1583;&#1605;&#1575;&#1578;&#1607;</span><span dir=LTR></span><span
lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpMiddle dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1571;&#1606;&#1578;
&#1578;&#1602;&#1585; &#1608;&#1578;&#1608;&#1575;&#1601;&#1602;
&#1593;&#1604;&#1609; &#1571;&#1606; &#1605;&#1606;
&#1605;&#1587;&#1572;&#1608;&#1604;&#1610;&#1578;&#1603;
&#1605;&#1585;&#1575;&#1580;&#1593;&#1577; &#1607;&#1584;&#1575;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602; &#1608;&#1607;&#1584;&#1607;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577; &#1576;&#1588;&#1603;&#1604;
&#1583;&#1608;&#1585;&#1610; &#1608;&#1571;&#1606; &#1578;&#1603;&#1608;&#1606;
&#1593;&#1604;&#1609; &#1583;&#1585;&#1575;&#1610;&#1577; &#1576;&#1571;&#1610;
&#1578;&#1593;&#1583;&#1610;&#1604;&#1575;&#1578;. &#1587;&#1610;&#1578;&#1605;
&#1606;&#1588;&#1585;
&#1575;&#1604;&#1578;&#1581;&#1583;&#1610;&#1579;&#1575;&#1578;
&#1593;&#1604;&#1609; &#1607;&#1584;&#1607;
&#1575;&#1604;&#1587;&#1610;&#1575;&#1587;&#1577; &#1593;&#1604;&#1609;
&#1607;&#1584;&#1607; &#1575;&#1604;&#1589;&#1601;&#1581;&#1577;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
63.0pt;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1603;&#1584;&#1604;&#1603;&#1548;
&#1602;&#1583; &#1610;&#1603;&#1608;&#1606; &#1607;&#1606;&#1575;&#1603;
&#1571;&#1581;&#1610;&#1575;&#1606;&#1611;&#1575;
&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1593;&#1604;&#1609;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1578;&#1581;&#1578;&#1608;&#1610; &#1593;&#1604;&#1609;
&#1571;&#1582;&#1591;&#1575;&#1569;
&#1603;&#1578;&#1575;&#1576;&#1610;&#1577;&#1548; &#1593;&#1583;&#1605;
&#1583;&#1602;&#1577; &#1571;&#1608; &#1587;&#1607;&#1608; &#1602;&#1583;
&#1578;&#1578;&#1593;&#1604;&#1602; &#1576;&#1608;&#1589;&#1601;
&#1575;&#1604;&#1582;&#1583;&#1605;&#1577;&#1548;
&#1575;&#1604;&#1578;&#1587;&#1593;&#1610;&#1585;&#1548;
&#1575;&#1604;&#1578;&#1608;&#1601;&#1585;&#1548; &#1608;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578;
&#1605;&#1582;&#1578;&#1604;&#1601;&#1577; &#1571;&#1582;&#1585;&#1609;&#1548;
&#1608;&#1610;&#1581;&#1578;&#1601;&#1592;
&#1575;&#1604;&#1578;&#1591;&#1576;&#1610;&#1602;
&#1576;&#1575;&#1604;&#1581;&#1602; &#1601;&#1610;
&#1578;&#1589;&#1581;&#1610;&#1581; &#1571;&#1610;
&#1571;&#1582;&#1591;&#1575;&#1569;&#1548; &#1593;&#1583;&#1605;
&#1583;&#1602;&#1577; &#1571;&#1608; &#1587;&#1607;&#1608;
&#1608;&#1578;&#1594;&#1610;&#1610;&#1585; &#1571;&#1608;
&#1578;&#1581;&#1583;&#1610;&#1579;
&#1575;&#1604;&#1605;&#1593;&#1604;&#1608;&#1605;&#1575;&#1578; &#1601;&#1610;
&#1571;&#1610; &#1608;&#1602;&#1578;&#1548; &#1583;&#1608;&#1606;
&#1573;&#1588;&#1593;&#1575;&#1585; &#1605;&#1587;&#1576;&#1602;</span><span
dir=LTR></span><span lang=EN-GB dir=LTR><span dir=LTR></span>.</span></p>

<p class=MsoNormal dir=RTL style='text-align:right;direction:rtl;unicode-bidi:
embed'><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&nbsp;</span></p>

<p class=MsoListParagraphCxSpFirst dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB>16.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;
&#1576;&#1606;&#1575;</span></p>

<p class=MsoListParagraphCxSpLast dir=RTL style='margin-top:0in;margin-right:
.5in;margin-bottom:8.0pt;margin-left:0in;text-align:right;text-indent:-.25in;
direction:rtl;unicode-bidi:embed'><span lang=EN-GB style='font-family:Symbol'>·<span
style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span dir=RTL></span><span lang=AR-SA style='font-family:"Arial","sans-serif"'>&#1573;&#1584;&#1575;
&#1603;&#1575;&#1606; &#1604;&#1583;&#1610;&#1603;
&#1571;&#1587;&#1574;&#1604;&#1577; &#1581;&#1608;&#1604;
&#1587;&#1610;&#1575;&#1587;&#1577;
&#1575;&#1604;&#1582;&#1589;&#1608;&#1589;&#1610;&#1577;
&#1575;&#1604;&#1582;&#1575;&#1589;&#1577; &#1576;&#1606;&#1575;&#1548;
&#1610;&#1585;&#1580;&#1609; &#1575;&#1604;&#1575;&#1578;&#1589;&#1575;&#1604;
&#1576;&#1606;&#1575; &#1593;&#1576;&#1585;
&#1575;&#1604;&#1576;&#1585;&#1610;&#1583;
&#1575;&#1604;&#1573;&#1604;&#1603;&#1578;&#1585;&#1608;&#1606;&#1610;: </span><span
lang=EN-GB dir=LTR>Hello@jeelapp.com</span><span dir=RTL></span><span
lang=AR-SA style='font-family:"Arial","sans-serif"'><span dir=RTL></span>.</span></p>

</div>
					
					
					</div>
					
					
					
					
				</article><!-- #post-## -->

			</div>

			
		</div>

	</div>
</div><!-- .block-content -->

</div><!-- #main-content -->


		</div><!-- #main -->
		<div id="lazy-loading-point"></div>

			</div><!-- #page -->

	
	<div id="pum-26143" class="pum pum-overlay pum-theme-25358 pum-theme-enterprise-blue popmake-overlay click_open" data-popmake="{&quot;id&quot;:26143,&quot;slug&quot;:&quot;test&quot;,&quot;theme_id&quot;:25358,&quot;cookies&quot;:[],&quot;triggers&quot;:[{&quot;type&quot;:&quot;click_open&quot;,&quot;settings&quot;:{&quot;extra_selectors&quot;:&quot;&quot;,&quot;cookie_name&quot;:null}}],&quot;mobile_disabled&quot;:null,&quot;tablet_disabled&quot;:null,&quot;meta&quot;:{&quot;display&quot;:{&quot;stackable&quot;:false,&quot;overlay_disabled&quot;:false,&quot;scrollable_content&quot;:false,&quot;disable_reposition&quot;:false,&quot;size&quot;:&quot;medium&quot;,&quot;responsive_min_width&quot;:&quot;0%&quot;,&quot;responsive_min_width_unit&quot;:false,&quot;responsive_max_width&quot;:&quot;100%&quot;,&quot;responsive_max_width_unit&quot;:false,&quot;custom_width&quot;:&quot;640px&quot;,&quot;custom_width_unit&quot;:false,&quot;custom_height&quot;:&quot;380px&quot;,&quot;custom_height_unit&quot;:false,&quot;custom_height_auto&quot;:false,&quot;location&quot;:&quot;center top&quot;,&quot;position_from_trigger&quot;:false,&quot;position_top&quot;:&quot;100&quot;,&quot;position_left&quot;:&quot;0&quot;,&quot;position_bottom&quot;:&quot;0&quot;,&quot;position_right&quot;:&quot;0&quot;,&quot;position_fixed&quot;:false,&quot;animation_type&quot;:&quot;fade&quot;,&quot;animation_speed&quot;:&quot;350&quot;,&quot;animation_origin&quot;:&quot;center top&quot;,&quot;overlay_zindex&quot;:false,&quot;zindex&quot;:&quot;1999999999&quot;},&quot;close&quot;:{&quot;text&quot;:&quot;&quot;,&quot;button_delay&quot;:&quot;0&quot;,&quot;overlay_click&quot;:false,&quot;esc_press&quot;:false,&quot;f4_press&quot;:false},&quot;click_open&quot;:[]}}" role="dialog" aria-hidden="true" >

	<div id="popmake-26143" class="pum-container popmake theme-25358 pum-responsive pum-responsive-medium responsive size-medium">

				

				

		

				<div class="pum-content popmake-content">
			<p><div id="vc_row-654f2836cacce" class="vc_row wpb_row vc_row-fluid vc_hidden-sm vc_hidden-xs vc_custom_1582842978693 vc_row-has-fill"><div class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill"><div class="vc_column-inner vc_custom_1582826554364"><div class="wpb_wrapper"><div class="vc_empty_space"   style="height: 400px"><span class="vc_empty_space_inner"></span></div></div></div></div><div class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill"><div class="vc_column-inner vc_custom_1582839400979"><div class="wpb_wrapper"><div class="vc_empty_space"   style="height: 60px"><span class="vc_empty_space_inner"></span></div>
	<div class="wpb_text_column wpb_content_element  vc_custom_1582839672139" >
		<div class="wpb_wrapper">
			<h6 style="text-align: center;"><span style="color: #ffffff;">سجل بريدك الإلكتروني واشترك الآن</span></h6>

		</div>
	</div>

	<div class="wpb_text_column wpb_content_element  vc_custom_1583170015888" >
		<div class="wpb_wrapper">
			<h5 style="text-align: center;"><span style="color: #ffffff;"><strong>في قائمة تطبيق جيل البريدية</strong></span></h5>
<p style="text-align: center; font-size: 80%;"><span style="color: #ffffff;">لتكون على اطلاع دائم بأحدث التوصيات والمقالات التربوية</span></p>


		<form class="pum_sub_form none inline default pum-sub-form pum-form pum-sub-form--provider-none pum-form--layout-inline pum-form--style-default pum-form--alignment-center" data-settings="{&quot;closepopup&quot;:true,&quot;closedelay&quot;:&quot;0&quot;,&quot;openpopup&quot;:null,&quot;openpopup_id&quot;:&quot;0&quot;,&quot;redirect_enabled&quot;:null,&quot;redirect&quot;:&quot;&quot;}">

			
			
			
			<div class="pum-form__field  pum-form__field--email  pum-sub-form-field  pum-sub-form-field--email">
								<input type="email" name="email" required placeholder="البريد الإلكتروني" />
			</div>

			
			
			<input type="hidden" name="provider" value="none" />

			
			<div class="pum-form__field  pum-form__field--submit  pum-sub-form-field  pum-sub-form-field--submit">
				<button class="pum-form__submit  pum-sub-form-submit">اشترك</button>
			</div>

					</form>

		

		</div>
	</div>
<div class="vc_empty_space"   style="height: 45px"><span class="vc_empty_space_inner"></span></div></div></div></div></div><div id="vc_row-654f2836cc5f4" class="vc_row wpb_row vc_row-fluid vc_hidden-lg vc_hidden-md vc_custom_1582843356285 vc_row-has-fill"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper">
	<div class="wpb_text_column wpb_content_element " >
		<div class="wpb_wrapper">
			<h6 style="text-align: center;"><span style="color: #ffffff;">سجل بريدك الإلكتروني واشترك الآن <strong>في قائمة تطبيق جيل البريدية</strong></span></h6>
<p style="text-align: center; font-size: 80%;"><span style="color: #ffffff;">لتكون على اطلاع دائم بأحدث التوصيات والمقالات التربوية</span></p>


		<form class="pum_sub_form none block default pum-sub-form pum-form pum-sub-form--provider-none pum-form--layout-block pum-form--style-default pum-form--alignment-left" data-settings="{&quot;closepopup&quot;:true,&quot;closedelay&quot;:&quot;0&quot;,&quot;openpopup&quot;:null,&quot;openpopup_id&quot;:&quot;0&quot;,&quot;redirect_enabled&quot;:null,&quot;redirect&quot;:&quot;&quot;}">

			
			
			
			<div class="pum-form__field  pum-form__field--email  pum-sub-form-field  pum-sub-form-field--email">
								<input type="email" name="email" required placeholder="البريد الإلكتروني" />
			</div>

			
			
			<input type="hidden" name="provider" value="none" />

			
			<div class="pum-form__field  pum-form__field--submit  pum-sub-form-field  pum-sub-form-field--submit">
				<button class="pum-form__submit  pum-sub-form-submit">اشترك</button>
			</div>

					</form>

		

		</div>
	</div>
</div></div></div></div></p>
		</div>


				

				            <button type="button" class="pum-close popmake-close" aria-label="Close">
			×            </button>
		
	</div>

</div>
<script type="text/html" id="wpb-modifications"></script><script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/jquery.dlmenu.js?ver=5.3.2'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var thegem_dlmenu_settings = {"backLabel":"Back","showCurrentLabel":"Show this page"};
/* ]]> */
</script>
<script type='text/javascript'>
window.customMegaMenuSettings = [
		{
			menuItem: 21,
			urls: [/^\/thegem\/(shop|product\-category|cart|checkout|my\-account)\/.*$/],
			data: {
				backgroundImage: 'url(https://jeelapp.com/wp2/wp-content/uploads/2016/03/2-27.jpg)',
				backgroundPosition: 'right top',
				style: 'grid',
				masonry: true,
				padding: '0px 581px 0px 0px',
                                borderRight: '0'
			}
		}
	];
</script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/thegem-menu_init.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/svg4everybody.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/thegem-form-elements.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/jquery.easing.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/thegem-header.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/SmoothScroll.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/functions.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/fancyBox/jquery.mousewheel.pack.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/fancyBox/jquery.fancybox.min.js?ver=5.3.2'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/themes/thegem/js/fancyBox/jquery.fancybox-init.js?ver=5.3.2'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var wpcf7 = {"apiSettings":{"root":"https:\/\/jeelapp.com\/EN\/wp-json\/contact-form-7\/v1","namespace":"contact-form-7\/v1"}};
/* ]]> */
</script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/contact-form-7/includes/js/scripts.js?ver=5.1.7'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-content/plugins/js_composer/assets/js/dist/js_composer_front.min.js?ver=6.1'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-includes/js/jquery/ui/core.min.js?ver=1.11.4'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-includes/js/jquery/ui/position.min.js?ver=1.11.4'></script>
<script type='text/javascript'>
/* <![CDATA[ */
var pum_vars = {"version":"1.9.1","ajaxurl":"https:\/\/jeelapp.com\/EN\/wp-admin\/admin-ajax.php","restapi":"https:\/\/jeelapp.com\/EN\/wp-json\/pum\/v1","rest_nonce":null,"default_theme":"25359","debug_mode":"","disable_tracking":"","home_url":"\/EN\/","message_position":"top","core_sub_forms_enabled":"1","popups":[]};
var ajaxurl = "https:\/\/jeelapp.com\/EN\/wp-admin\/admin-ajax.php";
var pum_sub_vars = {"ajaxurl":"https:\/\/jeelapp.com\/EN\/wp-admin\/admin-ajax.php","message_position":"top"};
var pum_popups = {"pum-26143":{"disable_on_mobile":false,"disable_on_tablet":false,"custom_height_auto":false,"scrollable_content":false,"position_from_trigger":false,"position_fixed":false,"overlay_disabled":false,"stackable":false,"disable_reposition":false,"close_on_form_submission":false,"close_on_overlay_click":false,"close_on_esc_press":false,"close_on_f4_press":false,"disable_form_reopen":false,"disable_accessibility":false,"theme_id":"25358","size":"medium","responsive_min_width":"0%","responsive_max_width":"100%","custom_width":"640px","custom_height":"380px","animation_type":"fade","animation_speed":"350","animation_origin":"center top","location":"center top","position_top":"100","position_bottom":"0","position_left":"0","position_right":"0","zindex":"1999999999","close_button_delay":"0","close_on_form_submission_delay":"0","triggers":[],"cookies":[],"theme_slug":"enterprise-blue","id":26143,"slug":"test"}};
/* ]]> */
</script>
<script type='text/javascript' src='//jeelapp.com/EN/wp-content/uploads/pum/pum-site-scripts.js?defer&#038;generated=1583170267&#038;ver=1.9.1'></script>
<script type='text/javascript' src='https://jeelapp.com/EN/wp-includes/js/wp-embed.min.js?ver=5.3.2'></script>
</body>
</html>
`;