import React, { useRef, Component } from "react";
import OuterHeader from "../Layout/OuterHeader";
import InnerHeader from "../Layout/InnerHeader";
import Dashboard from "../Dashboard";
import Footer from "../Layout/Footer";
import UploadImage from "../S3Uploading/UploadImage";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../css/AppCss/addBlog.css";
import PublishDate from "../Layout/PublishDate";
import { Button, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { saveBlog, emptyBlog } from "../../Actions/BlogActions";
import { getBlogCategories } from "../../Actions/BlogCategoryAction";
import { Redirect, Link } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';

import porpTypes from "prop-types";
import JoditEditor from "jodit-react";
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_BLOGS } from '../../routes';

const INITIAL_STATE = {
  image: "",
  title: "",
  description: "",
  publishDate: "",
  blogHTML: "",
  blogCategoryId: 0,
  blogCategory: "",
  cancel: false,
  editorImage: ''
};

class AddBlog extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.props.getBlogCategories();
  }

  getImage(imageUrl) {
    if (imageUrl) {

      if (imageUrl === "delete") {
        this.setState({ image: '' });

      } else {
        this.setState({ image: imageUrl });
      }
    }
  }


  getEditorImage(imageUrl) {
    if (imageUrl) {

      if (imageUrl === "delete") {
        this.setState({ editorImage: '' });

      } else {
        this.setState({ editorImage: imageUrl });
      }
    }
  }


  getDate(publishDate) {
    if (publishDate) {
      this.setState({ publishDate });
    }
  }

  handleChange = e => {
    switch (e.target.name) {
      default:
        this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSubmit = e => {
    const {
      title,
      image,
      description,
      blogHTML,
      publishDate,
      blogCategoryId
    } = this.state;

    var res = blogHTML;

    if (blogHTML.includes('<img') === true) {
      res = res.split('<img').join('<img style="display: block; margin-left: auto; margin-right: auto;       text-align: center;  line-height: 96px; height: 22%; color: gray; border: 1px solid rgba(34, 36, 38, .15);border-radius: .28571429rem; transition: box-shadow .1s ease, border-color .1s ease, -webkit-box-shadow .1s ease; object-fit: scale-down"');
    }

    // if (blogHTML.includes('<figcaption') === true) {
    //   res = res.split('<figcaption').join('<figcaption style="text-align: center"');
    // }

    // let html = "<div dir='rtl' lang='ar'>" + res + "</div>"
    let html = res;

    if (image === '' || image === undefined || image === null) {
      alert("please insert image");
      this.setState({
        validateInput: false
      })
    }

    else if (blogCategoryId === '' || blogCategoryId === null || blogCategoryId === undefined) {
      alert("please choose blog category");
      this.setState({
        validateInput: false
      })
    }
    else if (title === '' || title === null || title === undefined) {
      alert("please insert title");
      this.setState({
        validateInput: false
      })
    }
    else if (blogHTML === '' || blogHTML === undefined || blogHTML === null) {

      alert("post can't be empty");
      this.setState({
        validateInput: false
      })
    }
    else if (publishDate === '' || publishDate === undefined || publishDate === null) {
      alert("publish date can't be empty");
      this.setState({
        validateInput: false
      })
    }
    else if (description === '' || description === undefined || description === null) {
      alert("description can't be empty");
      this.setState({
        validateInput: false
      })
    }
    else {
      this.setState({ validateInput: true })
      this.props.saveBlog(
        title,
        description,
        image,
        html,
        publishDate,
        blogCategoryId
      );
    }
    e.preventDefault();
  };

  handelCancel = e => {
    this.setState({ cancel: true });
  }

  handleBlogCategoryChange = (e, { value }) => {
    this.setState({ blogCategoryId: value });
  };

  render() {
    const
      config = {
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        enableDragAndDropFileToEditor: true,
        useImageResizer: false,
        direction: 'rtl',
      }


    const { blog, blogerror, blogSuccess, blogCategories } = this.props;
    console.log("bloc" + blogCategories);
    const blogCategoriesList = [];
    for (let i = 0; i < blogCategories.length; i++) {
      blogCategoriesList.push({
        key: blogCategories[i].id,
        text: blogCategories[i].categoryText,
        value: blogCategories[i].id
      });
    }

    if (blogSuccess === false) {
      alert(blogerror);
      this.props.emptyBlog();
    }
    else if (blogSuccess === true) {
      alert("blog added successfully");
      this.props.emptyBlog();
      return <Redirect to={ALL_BLOGS} />;
    }

    if (this.state.cancel === true) {
      return (<Redirect to={ALL_BLOGS}></Redirect>)

    }
    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col-9">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">

                    <div className="col-12 left-main-body" id="left-main-body">
                      <div className="statusbar small-font">
                        <span className="status-link">
                          <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                        </span>
                        <span className="status-link">
                          <Link to={ALL_BLOGS}>Blogs</Link>
                        </span>
                      </div>
                      <br />
                      <h5 className="main-title mt-2 font-weight-bold">
                        <span>Add Blog</span>
                      </h5>
                      <br />
                      <div className="container">
                        <form onSubmit={this.handleSubmit}>
                          <span className="required_input">*</span>
                          <label className="small-label">post image</label>
                          <UploadImage
                            getImageURLCallBack={this.getImage.bind(this)}
                            imageUrl={this.state.image}
                            imagepreview={this.state.image}
                            s3DirectryrName='blog'
                          />
                          <br />
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Blog Category
                          </label>{" "}
                          <br />
                          <Dropdown
                            placeholder="select blog category"
                            selection
                            onChange={this.handleBlogCategoryChange}
                            options={blogCategoriesList}
                            value={this.state.blogCategoryId}
                          />
                          <br />
                          <br />
                          <label className="small-label">
                            <span className="required_input">*</span>
                            post title</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title"
                            name="title"
                            onChange={this.handleChange}
                            value={this.state.title}
                          />
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              <span className="required_input">*</span>
                              Description
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="description"
                              onChange={this.handleChange}
                              value={this.state.description}
                            />
                          </div>
                          <br />

                          <label className="small-label">
                            Editor Upload Image </label>
                          <UploadImage
                            getImageURLCallBack={this.getEditorImage.bind(this)}
                            imageUrl={this.state.editorImage}
                            imagepreview={this.state.editorImage}
                            s3DirectryrName='blog'
                          />
                          <br />
                          <label className="small-label">
                            Editor Image Url</label>

                          <input
                            className="form-control"
                            type="text"
                            value={this.state.editorImage}
                          />
                          <br />


                          <label className="small-label">
                            <span className="required_input">*</span>
                            post</label>
                          {/* 
                          <div>
                            <JoditEditor
                              config={config}
                              value={this.state.blogHTML}
                              /** NGMA-2403 
                              onBlur={newContent =>
                                this.setState({ blogHTML: newContent.target.innerHTML })
                              } // preferred to use only this option to update the content for performance reasons
                            />
                          </div>
                           */}
                          <div>
                            <Editor
                              onInit={(evt, editor) => {

                                const body = editor.dom.select('body')[0];
                                editor.dom.add(
                                  body,
                                  'script',
                                  {
                                    src: "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js",
                                    type: 'text/javascript'
                                  }
                                );

                                editor.dom.add(
                                  body,
                                  'script',
                                  {
                                    src: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js",
                                    type: 'text/javascript'
                                  }
                                );
                              }}
                              initialValue={this.state.blogHTML}
                              onBlur={newValue => {
                                this.setState({ blogHTML: newValue.target.getContent() })
                              }}
                              // onEditorChange={newValue => {
                              //   this.setState({ blogHTML: newValue.target.innerHTML })
                              // }}
                              init={{

                                height: 500,
                                plugins: [
                                  "advlist autolink lists link image charmap print preview anchor",
                                  "searchreplace visualblocks code fullscreen",
                                  "insertdatetime media table paste"
                                ],
                                toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
                                content_css: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css, https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css",
                                valid_children: '+body[style]',
                                valid_elements: '*[*]',
                                inline_styles: true,
                                extended_valid_elements: 'i[class]',
                                width: '100%'

                              }}
                            />
                          </div>

                          <br /> <br />
                          <PublishDate
                            getPublishDateCallBack={this.getDate.bind(this)}

                          />
                          <br /> <br />
                          <Button
                            icon
                            labelPosition="right"
                            id="nextButton"
                            onClick={this.handleSubmit}
                          >
                            Publish
                          </Button>

                          <Button id="episode_cancel"
                            onClick={this.handelCancel}>
                            Cancel
                          </Button>

                          <br />
                          <br /> <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

AddBlog.porpTypes = {
  getBlogCategories: porpTypes.func.isRequired
};

const mapStateToProps = state => ({
  blog: state.blogs.item,
  blogerror: state.blogs.error_adding_blog,
  blogSuccess: state.blogs.blog_added_successfully,
  blogCategories: state.blogCategories.items
});

export default connect(
  mapStateToProps,
  { saveBlog, emptyBlog, getBlogCategories }
)(AddBlog);
