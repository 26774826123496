import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup, Confirm } from 'semantic-ui-react';

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { adminResetPassword, emptyAdmin, fetchAllAdmins }
    from '../../Actions/UserManagementActions'

const INITIAL_STATE = {

    showModal: false,
    /////////// Series
    password: '',
};


class ResetPasswordAdmin extends Component {


    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    adminResetPassword(uid, email) {
        this.props.adminResetPassword(uid, email);
    }

    handleSubmit = (e) => {
        const { uid, email } = this.props;
        this.adminResetPassword(uid, email);
        this.props.emptyAdmin();
        this.props.fetchAllAdmins();
        this.closeModal();
        this.setState({
            password: ''
        })
        e.preventDefault();
    }

    closeModal = (e) => {
        this.setState({ showModal: false, password: '' })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
    }

    render() {

        const { error } = this.props;
        const { success } = this.props;


        let contentPreview = null;

        if (error !== '') {
            alert("error missing data or network " + error)
            this.props.emptyAdmin();
        }

        return (

            <span>
                <Popup trigger=
                    {
                        <a className="key" onClick={this.openModal}>
                            <i >
                                <FontAwesomeIcon icon="key" />
                            </i>
                        </a>}
                    content='Reset Password'
                    position='top center'
                />

                <Confirm id="modal"
                    content='Are you sure you want
                    to reset password for this admin ??'
                    open={this.state.showModal}
                    onCancel={this.closeModal}
                    onConfirm={this.handleSubmit} />

            </span>
        )

    }
}


ResetPasswordAdmin.propTypes = {
    adminResetPassword: propTypes.func.isRequired,
    fetchAllAdmins: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    loading: state.admins.loading,
    error: state.admins.admin_reset_password_error,
    success: state.admins.admin_reset_password_sucessfully,
    showModal: state.admins.showModal
});

export default withFirebase
    (connect
        (mapStateToProps,
            { adminResetPassword, fetchAllAdmins, emptyAdmin })
        (ResetPasswordAdmin)
    );
