import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Input, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';
import Loader from '../Layout/Loader';

/* Assets */
import mainLogoM from '../../../images/portal/logo-big.png';

/* Actions */
import { customerForgetPassword,emptyCustomer } from '../../../Actions/CustomerActions';

/* Routes */
import { PORTAL_LAND_PAGE } from '../../../routes';

const INITIAL_STATE = {
  disabled: true,
  email: '',
  show: false,
  message: '',
  color: '',
}

class ForgetPassword extends Component {

  constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
  }

  componentDidMount(){
    // import("../../../css/portal-style.css");
  }

  handleChange = (e) => {
    let value = e.target.value;
    this.setState({message: ''});
    setTimeout( () => {
      this.setState({email: value});
      if(this.state.email !== null && this.state.email !== undefined && this.state.email !== '' &&
         this.state.email !== ' '){
         this.setState({disabled: false})
      }else{
        this.setState({disabled: true})
      }
    });
  }

  handleSubmit = (e) => {
    this.props.customerForgetPassword(this.state.email);
    e.preventDefault();
  }

  handleClose = () => {
    this.setState({show: false, message: ''});
    this.props.emptyCustomer();
  }

  render(){
    const {success, error, loading, resetItem} = this.props;
    let message = '';
    let resetButton = (
      <button type="submit" className="change-btn btn form-control"
              disabled={this.state.disabled}
              onClick={this.handleSubmit}>تاكيد
     </button>
    )
    if(loading === true){
      resetButton = (
        <button
          className="btn btn-block ui loading secondary button">
        </button>
      )
    }

    if(success === true){
      this.setState({message: resetItem, color: '#6dd400'});
      this.props.emptyCustomer();
    }
    if(success === false && error !== false && error !== ''){
      this.setState({message: "البريد الإلكتروني المُدخل غير مسجل لدينا", color: 'red'});
      this.props.emptyCustomer();
    }
    return(
     <body>
      <div className="forget-password-container">
       <div className="logo-container">
         <div className="container">
            <div className="logo">
              <img src={mainLogoM} alt="logo" />
            </div>
         </div>
      </div>

      <section className="redirect-content">
        <div className="container">
            <div className="redirect-btn">
                <Link to={PORTAL_LAND_PAGE}>
                  <Icon name='right arrow' />
                  <span className="text"> العودة للصفحة الرئيسية</span>
                </Link>
            </div>
        </div>
      </section>

      <section className="dont-worry" style={{paddingBottom: '80px'}}>
       <div className="container">
         <div className="dont-wory-card">
             <div className="card-content">
              <p style={{textAlign: 'center' , color: `${this.state.color}`}}> {this.state.message} </p>
                 <div className="card-title h4" style={{fontWeight: 'bold'}}>نَسِيتُ كلمة المرور!</div>
                 <div className="description-text">من فضلك ادخل البريد الالكتروني الذي قمت بتسجيل الدخول به لاستعادة كلمة المرور</div>
                 <form className="reset-form">
                     <div className="form-group">
                         <input type="text" className="form-control" placeholder="البريد الإلكتروني"
                                value={this.state.email}
                                onChange={this.handleChange}/>
                     </div>

                     <div className="form-group custom-form-group w-100 mb-3">
                         {resetButton}
                     </div>
                 </form>
             </div>
         </div>
      </div>
      </section>

      <Footer />
      </div>

     </body>
    )
 }
}

ForgetPassword.propTypes = {
  customerForgetPassword: propTypes.func.isRequired,
  emptyCustomer: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
  success: state.customers.customer_reset_password_sucessfully,
  error: state.customers.customer_reset_password_error,
  loading: state.customers.loading,
  resetItem: state.customers.item,
});

export default withRouter
    (connect
        (mapStateToProps,{customerForgetPassword, emptyCustomer})
        (ForgetPassword)
    );
