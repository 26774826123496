import React,{Component} from 'react'
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { setEducationalVideoStatus,getAllEducationalVideos,emptyEducationalVideo } from '../../Actions/ParentLibraryAction'


class SetParentLibraryStatus extends Component{

    state={open:false}
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setEducationalVideoStatus (id)
    {
        this.props.setEducationalVideoStatus(id);
    }
    
    handleSubmit =(e)=>
    {
        const { id } = this.props;
            this.setEducationalVideoStatus(id);
        this.close();
    }
    render ()
    {
        const {status_updated_successfully,error_updating_status} = this.props
        const {id , status} = this.props
        let contentPreview =null;
        if(status_updated_successfully===false)
        {
            alert("error setting status"+error_updating_status);
            this.props.emptyEducationalVideo();
        }
        else if (status_updated_successfully===true)
        {
            alert("Educational Video updated successfully");
            this.props.emptyEducationalVideo();
            this.props.getAllEducationalVideos();
        }

        if (status==="SUSPENDING")
        {
            contentPreview=(
                <button className="seriesCardButtons" onClick={this.open}>
                        <FontAwesomeIcon icon="play" />
                </button>
            )
        }
        else {
            contentPreview = (
                <button className="seriesCardButtons" onClick={this.open}>||</button>
            )
        }

        return ( <span >
            {contentPreview}
            <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
        </span>)

    }

}


const mapStateToProps =state =>
({
    status_updated_successfully:state.parentLibrary.status_updated_successfully,
    error_updating_status:state.parentLibrary.error_updating_status
})
export default connect (mapStateToProps,{setEducationalVideoStatus,getAllEducationalVideos,emptyEducationalVideo}) (SetParentLibraryStatus)
