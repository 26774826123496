import {
    ERROR_GET_PAYMENT_PACKAGE,
    GET_PAYMENT_PACKAGE,
    LOAD_GET_PAYMENT_PACKAGE,
    EMPTY_PAYMENT_PACKAGE,
    UPDATE_PAYMENT_PACKAGE,
    CLEAR_GET_PAYMENT_PACKAGE,
    ERROR_UPDATE_PAYMENT_PACKAGE
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    loading: '',
    update_payment_package_data_sucessfully: '',
    update_payment_package_data_error: '',
    getting_prices_error: '',
    getting_prices_sucessfully: '',
}

export default function (state = initialState, action) {
    switch (action.type) {

        case LOAD_GET_PAYMENT_PACKAGE:
            return {
                ...state,
                loading: true,
                update_payment_package_data_sucessfully: '',
                update_payment_package_data_error: ''
            }

        case GET_PAYMENT_PACKAGE:
            return {
                ...state,
                items: action.payload,
                getting_prices_error: '',
                getting_prices_sucessfully: true,
                loading: false
            }
        case ERROR_GET_PAYMENT_PACKAGE:
            return {

                ...state,
                getting_prices_error: action.payload,
                loading: false
            }
        case EMPTY_PAYMENT_PACKAGE:
            return {
                ...state,
                getting_prices_error: '',
                getting_prices_sucessfully: '',
                items: {},
            }
        case UPDATE_PAYMENT_PACKAGE:
            return {
                ...state,
                loading: false,
                getting_prices_error: '',
                getting_prices_sucessfully: '',
                update_payment_package_data_sucessfully: true,
                update_payment_package_data_error: '',

            }
        case ERROR_UPDATE_PAYMENT_PACKAGE:
            return {
                ...state,
                loading: false,
                getting_prices_error: '',
                getting_prices_sucessfully: '',
                update_payment_package_data_sucessfully: '',
                update_payment_package_data_error: action.payload,

            }
        case CLEAR_GET_PAYMENT_PACKAGE:
            return {
                ...state,
                loading: false,
                getting_prices_error: '',
                getting_prices_sucessfully: '',
                update_payment_package_data_sucessfully: '',
            }

        default:
            return state

    }

}