import {
    FETCH_All_GAME_PARENT, GET_GAME_PARENT_DATA, LOAD_GAME_PARENTES, ERROR_LOADING_GAME_PARENTES,
    ERROR_LOADING_GAME_PARENT, LOAD_GAME_PARENT, SAVE_GAME_PARENT, ERROR_ADDING_GAME_PARENT,
    ERROR_SETTING_STATE_GAME_PARENT, SETTING_STATE_GAME_PARENT,
    ERROR_DELETING_GAME_PARENT, DELETING_GAME_PARENT,
    ERROR_UPDATE_GAME_PARENT, UPDATE_GAME_PARENT, EMPTY_GAME_PARENT,
    EMPTY_GAME_PARENT_WITH_DATA,
    ADD_GAME_PARENT_WITH_SENTFROM
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},

    loading: '',
    error_loading_game_parents: '',
    error_loading_game_parent: '',

    error_adding_game_parent: '',
    game_parent_added_sucessfully: '',

    setting_state_game_parent_error: "",
    setting_state_game_parent_sucessfully: "",

    deleting_game_parent_error: "",
    deleting_game_parent_sucessfully: "",

    updating_game_parent_sucessfully: "",
    updating_game_parent_error: "",
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_GAME_PARENT_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }

        case FETCH_All_GAME_PARENT:

            return {
                ...state,
                items: action.payload,
                error_loading_game_parents: '',
                loading: false
            }
        case GET_GAME_PARENT_DATA:

            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_game_parent: ''
            }
        case SAVE_GAME_PARENT:
            return {
                ...state,
                item: action.payload,
                items: state.items.concat(action.payload),
                game_parent_added_sucessfully: true
            }
        case EMPTY_GAME_PARENT:
            return {
                ...state,
                error_adding_game_parent: action.payload,
                game_parent_added_sucessfully: action.payload,

                setting_state_game_parent_error: action.payload,
                setting_state_game_parent_sucessfully: action.payload,

                deleting_game_parent_sucessfully: action.payload,
                deleting_game_parent_error: action.payload,

                updating_game_parent_sucessfully: action.payload,
                updating_game_parent_error: action.payload,

                loading: action.payload,

            }
        case EMPTY_GAME_PARENT_WITH_DATA:
            return {
                ...state,
                item: {}
            }

        case LOAD_GAME_PARENTES:
            return {
                ...state,
                loading: true
            }
        case LOAD_GAME_PARENT:
            return {
                ...state,
                loading: true
            }

        case ERROR_LOADING_GAME_PARENTES:
            return {

                ...state,
                error_loading_game_parents: action.payload,
                loading: false
            }

        case ERROR_LOADING_GAME_PARENT:
            return {
                ...state,
                error_loading_game_parent: action.payload,
                loading: false
            }

        case ERROR_ADDING_GAME_PARENT:
            return {
                ...state,
                error_adding_game_parent: action.payload,
                game_parent_added_sucessfully: false
            }

        case SETTING_STATE_GAME_PARENT:
            return {
                ...state,
                item: action.payload,
                setting_state_game_parent_sucessfully: true,
                loading: false
            }

        case ERROR_SETTING_STATE_GAME_PARENT:
            return {
                ...state,
                setting_state_game_parent_error: action.payload,
                loading: false
            }

        case DELETING_GAME_PARENT:
            return {

                ...state,
                item: action.payload,
                deleting_game_parent_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_GAME_PARENT:
            return {

                ...state,
                deleting_game_parent_error: action.payload,
                loading: false
            }

        case UPDATE_GAME_PARENT:
            return {
                ...state,
                item: action.payload,
                updating_game_parent_sucessfully: true,
                updating_game_parent_error: '',
                loading: false
            }

        case ERROR_UPDATE_GAME_PARENT:
            return {
                ...state,
                updating_game_parent_error: action.payload,
                loading: false,
                updating_game_parent_sucessfully: false
            }


        default:
            return state

    }

}
