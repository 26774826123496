import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
//import ar from "react-phone-number-input/locale/ar";


const INITIAL_STATE = {
  phoneNumber: "",
  showPassword: false,
  email: '',
  password: '',
};

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  handleChangeNumber = (val) => {
    this.setState({ phoneNumber: val });
  };
  hideShowPassword = (inputName) => {
    if (inputName == "showPassword") {
      this.setState({ showPassword: !this.state.showPassword });
    }
  };
  handleChange = (e) => {
    this.setState({ error: false, errorMsg: '' });
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces },() => {
      // Pass email and password values to parent component
      this.props.onData(this.state.email, this.state.password);
    });
  };



  render() {
   
    return (
      <>
        
        <div className="form-group">
          <label>البريد الالكتروني</label>
          <input className="form-control" type="email" name="email"
           value={this.state.email}
           onChange={this.handleChange} />
        </div>
        {/* <div className="form-group">
          <label>رقم الهاتف</label>
          <PhoneInput
            international
            value={this.state.phoneNumber}
            onChange={(val) => this.handleChangeNumber(val)}
            className="phoneNumberInput"
          />
        </div> */}
        <div className="form-group">
          <label>كلمة المرور</label>
          <div
            className={`input-with-icon ${
              this.state.showPassword ? "text" : "password"
            }`}
          >
            <input
              className="form-control"
              type={`${this.state.showPassword ? "text" : "password"}`}
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.hideShowPassword("showPassword");
              }}
              className="toggle-password input-icon bg-none"
            >
              <FontAwesomeIcon icon={faEye} className="faEye" />
              <FontAwesomeIcon icon={faEyeSlash} className="faEyeSlash" />
            </button>
          </div>
          
        </div>
      </>
    );
  }
}

 
export default LoginForm;
