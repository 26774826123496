export default
    [
        {
            key: 'Series',
            text: 'Series',
            value: 'Series'
        },
        {
            key: 'Discover',
            text: 'Learning&Skills',
            value: 'Discover'
        },
        {
            key: 'Episode',
            text: 'Episode',
            value: 'Episode'
        },
        {
            key: 'Song',
            text: 'Song',
            value: 'Song'
        },
        {
            key: 'SongParent',
            text: 'SongParent',
            value: 'SongParent'
        },
        // {
        //     key: 'Story',
        //     text: 'Story',
        //     value: 'Story'
        // },
        // {
        //     key: 'StoryParent',
        //     text: 'StoryParent',
        //     value: 'StoryParent'
        // },
        {
            key: 'Game',
            text: 'Game',
            value: 'Game'
        },
        {
            key: 'GameParent',
            text: 'GameParent',
            value: 'GameParent'
        },
        {
            key: 'Book',
            text: 'Book',
            value: 'Book'
        },
        {
            key: 'BookParent',
            text: 'BookParent',
            value: 'BookParent'
        },
        {
            key: 'Radio',
            text: 'Radio',
            value: 'Radio'
        },
        // {
        //     key: 'RadioChannel',
        //     text: 'RadioChannel',
        //     value: 'RadioChannel'
        // },
        {
            key: 'Videos',
            text: 'Videos',
            value: 'Videos'
        }
    ]