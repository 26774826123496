import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { deleteMainValue,
    emptyMainValue,
    fetchAllMainValues } from '../../Actions/ContentMainValuesAction'

export class DeleteMainValue extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteMainValue(id) {
        this.props.deleteMainValue(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;
        
        console.log("id = " + id)
        
        this.deleteMainValue(id);
        this.close();

    }
    render() {
        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyMainValue();
        }
        else if (success === true) {
            alert("Main Value Deleted Successfully")
            this.props.emptyMainValue();
            this.props.fetchAllMainValues();
        }

        return (
            <div className="acor-btn-group">

                 <button className="acor-btn2"  onClick={this.open}> 
                    <i >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal" 
                content='Are you sure you want to delete this Main Value ?? '
                open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>
            
        )
    }
}

DeleteMainValue.propTypes = {
    deleteMainValue: propTypes.func.isRequired,
    fetchAllMainValues: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    mainValues: state.contentMainValue.item,
    error: state.contentMainValue.deleting_main_values_error,
    success: state.contentMainValue.deleting_main_values_sucessfully
});


export default
    withFirebase
        (connect
            (mapStateToProps,
                {
                    deleteMainValue,
                    emptyMainValue,
                    fetchAllMainValues
                }
            )
            (DeleteMainValue)
        );


