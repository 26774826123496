import React from 'react';
import ReactTable from 'react-table';
import Loader from '../Layout/Loader';

class CountrySubscriptionReportDashboard extends React.Component {
  renderTable() {
    const { SubsReport, error, loading } = this.props;
    console.log('SubsReport:', SubsReport);
    if (loading) {
      console.log('Loading...');
      return <Loader />;
    }

    if (error) {
      console.error('Error loading data:', error);
      return <div>Error loading data</div>;
    }

    const countries = Object.keys(SubsReport);
    const paymentMethods = Array.from(
      new Set(
        countries.reduce(
          (methods, country) => methods.concat(SubsReport[country].map((item) => item.nameOfpaymentMethod)),
          []
        )
      )
    );

    const columns = [
      {
        Header: 'Country',
        accessor: 'country',
        headerClassName: 'table-header',
        Cell: (props) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
      },
      ...paymentMethods.map((method) => ({
        Header: method,
        accessor: method,
        headerClassName: 'table-header',
        Cell: (props) => (
          <div style={{ textAlign: 'center' }}>
            {props.original[method] ? (
              props.original[method].totalPrice % 1 === 0 ?
                props.original[method].totalPrice :
                props.original[method].totalPrice.toFixed(2)
            ) + " " + props.original[method].currency
            : 'N/A'}
          </div>
        ),
      })),
    ];
    
    
    

    const data = countries.map((country) => {
      const row = { country };
      paymentMethods.forEach((method) => {
        const subscription = SubsReport[country].find((item) => item.nameOfpaymentMethod === method);
        row[method] = subscription ? { totalPrice: subscription.totalPrice, currency: subscription.currency } : null;
      });
      return row;
    });

    return (
      <div>
        <ReactTable
          className=" table-striped custm-table mt-4"
          columns={columns}
          data={data}
          showPagination={false}
          pageSize={data.length === 0 ? 3 : data.length}
        />
      </div>
    );
  }

  render() {
    return <div style={{ overflow: 'hidden' }}>{this.renderTable()}</div>;
  }
}

export default CountrySubscriptionReportDashboard;
