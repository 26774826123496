import axios from "axios";
import { EMPTY_FAWRY_PAY, ERROR_FAWRY_PAY, FETCH_FAWRY_PAY, LOAD_FAWRY_PAY } from "./Types";
import firebase from 'firebase/app'


export const getFawryPaymentStatus =(merchantCode,merchantRefNumber,signature)=>{
    return(dispatch, getState ,{getFirebase})=>{
        dispatch({
            type:LOAD_FAWRY_PAY,
            payload: true,
        })
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/fawry/getPaymentStatus";
            const formData = new FormData();
            formData.append("merchantCode", merchantCode);
            formData.append("merchantRefNumber", merchantRefNumber);
            formData.append("signature", signature);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            axios.post(url, formData, config).then((res) => {
                dispatch({
                    type: FETCH_FAWRY_PAY,
                    payload: res.data,
                })
            }).catch((error)=> {
                dispatch({
                    type: ERROR_FAWRY_PAY,
                    payload: error.message,
                })
            })

        })
    }
}

export const emptyFawryPay =()=> dispatch =>{
    dispatch({
        type: EMPTY_FAWRY_PAY,
        payload: ''
    })
}