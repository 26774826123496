import React, { Component } from 'react'

class MobilePaymentFooter extends Component {
    render() {
        return (
            <div>
                <footer  >
                    <p class="copyrights text-center small-font font">
                        جميع الحقوق محفوظة
                    {/* <span class="mr-2"> - </span><a href="login-ar.html" class="en-font font">english</a> */}
                    </p>
                </footer>
            </div>
        )
    }
}

export default MobilePaymentFooter
