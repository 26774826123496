import {
    FETCH_ALL_IN_APP_NOTIFICATION, LOAD_ALL_IN_APP_NOTIFICATION, ERROR_LOADING_ALL_IN_APP_NOTIFICATION,
    ADD_IN_APP_NOTIFICATION_SUCCESSFULLY, ADD_IN_APP_NOTIFICATION_ERROR,
    UPDATE_IN_APP_NOTIFICATION_SUCCESSFULLY, UPDATE_IN_APP_NOTIFICATION_ERROR,
    GET_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY, GET_IN_APP_NOTIFICATION_DATA_ERROR,
    DELETE_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY, DELETE_IN_APP_NOTIFICATION_DATA_ERROR,
    LOAD_IN_APP_NOTIFICATION, EMPTY_IN_APP_NOTIFICATION, EMPTY_IN_APP_NOTIFICATION_RESPONSE
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},
    loading: '',
    loadingAllInAppNotification:'',

    error_all_loading_inAppNotification: '',
    getting_all_inAppNotification_sucessfully: '',

    inAppNotification_data_successfully: '',
    inAppNotification_data_error: '',

    add_inAppNotification_successfully: '',
    add_inAppNotification_error: '',

    update_inAppNotification_successfully: '',
    update_inAppNotification_error: '',

    delete_inAppNotification_successfully: '',
    delete_inAppNotification_error: ''

}


export default function (state = initialState, action) {
    switch (action.type) {


        case LOAD_ALL_IN_APP_NOTIFICATION:
            return {
                ...state,
                loadingAllInAppNotification: true
            }
        case LOAD_IN_APP_NOTIFICATION:
            return {
                ...state,
                loading: true
            }
        case FETCH_ALL_IN_APP_NOTIFICATION:
            return {
                ...state,
                items: action.payload,
                error_all_loading_inAppNotification: '',
                getting_all_inAppNotification_sucessfully: true,
                loadingAllInAppNotification: false,
                item: {}
            }
        case ERROR_LOADING_ALL_IN_APP_NOTIFICATION:
            return {
                ...state,
                error_all_loading_inAppNotification: action.payload,
                loadingAllInAppNotification: false
            }


        case GET_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY:
            return {
                ...state,
                item: action.payload,
                inAppNotification_data_successfully: true,
                inAppNotification_data_error: '',
                loading: false
            }

        case GET_IN_APP_NOTIFICATION_DATA_ERROR:
            return {
                ...state,
                inAppNotification_data_error: action.payload,
                inAppNotification_data_successfully: false,
                loading: false
            }

        case ADD_IN_APP_NOTIFICATION_SUCCESSFULLY:
            return {
                ...state,
                add_inAppNotification_successfully: true,
                loading: false
            }

        case ADD_IN_APP_NOTIFICATION_ERROR:
            return {
                ...state,
                add_inAppNotification_successfully: false,
                add_inAppNotification_error: action.payload,
                loading: false
            }

        case UPDATE_IN_APP_NOTIFICATION_SUCCESSFULLY:
            return {
                ...state,
                update_inAppNotification_successfully: true,
                update_inAppNotification_error: '',
                loading: false
            }

        case UPDATE_IN_APP_NOTIFICATION_ERROR:
            return {
                ...state,
                update_inAppNotification_successfully: false,
                update_inAppNotification_error: action.payload,
                loading: false
            }

            case DELETE_IN_APP_NOTIFICATION_DATA_SUCCESSFULLY:
            return {
                ...state,
                delete_inAppNotification_successfully: true,
                loading: false
            }

        case DELETE_IN_APP_NOTIFICATION_DATA_ERROR:
            return {
                ...state,
                delete_inAppNotification_successfully: false,
                delete_inAppNotification_error: action.payload,
                loading: false
            }

        case EMPTY_IN_APP_NOTIFICATION_RESPONSE:
            return {
                ...state,

                error_all_loading_inAppNotification: '',
                getting_all_inAppNotification_sucessfully: '',

                inAppNotification_data_successfully: '',
                inAppNotification_data_error: '',

                add_inAppNotification_successfully: '',
                add_inAppNotification_error: '',

                update_inAppNotification_successfully: '',
                update_inAppNotification_error: '',

                delete_inAppNotification_successfully: '',
                delete_inAppNotification_error: '',

                loading: '',
                loadingAllInAppNotification:'',
                item:{}
            }
        case EMPTY_IN_APP_NOTIFICATION:
            return {
                ...state,
                items: [],
                item: {},

            }
        default:
            return state

    }
}