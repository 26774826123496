import {FETCH_All_SONG_PARENT, GET_SONG_PARENT_DATA, LOAD_SONG_PARENTES, ERROR_LOADING_SONG_PARENTES,
    ERROR_LOADING_SONG_PARENT, LOAD_SONG_PARENT, SAVE_SONG_PARENT, ERROR_ADDING_SONG_PARENT,
    ERROR_SETTING_STATE_SONG_PARENT ,SETTING_STATE_SONG_PARENT,
    ERROR_DELETING_SONG_PARENT, DELETING_SONG_PARENT,
    ERROR_UPDATE_SONG_PARENT, UPDATE_SONG_PARENT, EMPTY_SONG_PARENT,
    EMPTY_SONG_PARENT_WITH_DATA,
    ADD_SONG_PARENT_WITH_SENTFROM
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},

    loading: '',
    error_loading_song_parents: '',
    error_loading_song_parent: '',

    error_adding_song_parent: '',
    song_parent_added_sucessfully: '',

    setting_state_song_parent_error: "",
    setting_state_song_parent_sucessfully: "",

    deleting_song_parent_error: "",
    deleting_song_parent_sucessfully: "",

    updating_song_parent_sucessfully: "",
    updating_song_parent_error: "",
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_SONG_PARENT_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }

        case FETCH_All_SONG_PARENT:

            return {
                ...state,
                items: action.payload,
                error_loading_song_parents: '',
                loading: false
            }
        case GET_SONG_PARENT_DATA:

            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_song_parent: ''
            }
        case SAVE_SONG_PARENT:
            return {
                ...state,
                item: action.payload,
                items: state.items.concat(action.payload),
                song_parent_added_sucessfully: true
            }
        case EMPTY_SONG_PARENT:
            return {
                ...state,
                error_adding_song_parent: action.payload,
                song_parent_added_sucessfully: action.payload,

                setting_state_song_parent_error: action.payload,
                setting_state_song_parent_sucessfully: action.payload,

                deleting_song_parent_sucessfully: action.payload,
                deleting_song_parent_error: action.payload,

                updating_song_parent_sucessfully: action.payload,
                updating_song_parent_error: action.payload,

                loading: action.payload,

            }
        case EMPTY_SONG_PARENT_WITH_DATA:
            return {
                ...state,
                item: {}
            }

        case LOAD_SONG_PARENTES:
            return {
                ...state,
                loading: true
            }
        case LOAD_SONG_PARENT:
            return {
                ...state,
                loading: true
            }

        case ERROR_LOADING_SONG_PARENTES:
            return {

                ...state,
                error_loading_song_parents: action.payload,
                loading: false
            }

        case ERROR_LOADING_SONG_PARENT:
            return {
                ...state,
                error_loading_song_parent: action.payload,
                loading: false
            }

        case ERROR_ADDING_SONG_PARENT:
            return {
                ...state,
                error_adding_song_parent: action.payload,
                song_parent_added_sucessfully: false
            }

        case SETTING_STATE_SONG_PARENT:
            return {
                ...state,
                item: action.payload,
                setting_state_song_parent_sucessfully: true,
                loading: false
            }

        case ERROR_SETTING_STATE_SONG_PARENT:
            return {
                ...state,
                setting_state_song_parent_error: action.payload,
                loading: false
            }

        case DELETING_SONG_PARENT:
            return {

                ...state,
                item: action.payload,
                deleting_song_parent_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_SONG_PARENT:
            return {

                ...state,
                deleting_song_parent_error: action.payload,
                loading: false
            }

        case UPDATE_SONG_PARENT:
            return {
                ...state,
                item: action.payload,
                updating_song_parent_sucessfully: true,
                updating_song_parent_error: '',
                loading: false
            }

        case ERROR_UPDATE_SONG_PARENT:
            return {
                ...state,
                updating_song_parent_error: action.payload,
                loading: false,
                updating_song_parent_sucessfully: false
            }


        default:
            return state

    }

}
