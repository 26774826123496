import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import {getPromoCodes, deletePromoCode, emptyPromoCode} from '../../Actions/PromoCodesActions';


class DeletePromoCode extends Component {

    state = {
        open: false
    }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    handleSubmit = (e) => {
        const {uid} = this.props;
        this.deletePromoCode(uid);
        this.close();

    }
    deletePromoCode(uid){
      this.props.deletePromoCode(uid);
    }

    render() {
      const { error, success } = this.props;
      if (success === false) {
          alert("error missing data or network " + error);
          this.props.emptyPromoCode();
      }
      else if (success === true) {
          alert("Promo Code has been Deleted")
          this.props.emptyPromoCode();
          this.props.getPromoCodes();
      }
      return (
          <span >
          <Popup trigger=
          {
              <a class="trash" onClick={this.open}>
                  <i><FontAwesomeIcon icon="trash" /></i>
              </a>
          }
          content='Delete'
          position='top center'
          />

              <Confirm id="modal"
                  content='Are you sure you want
                  to delete this PromoCode ??'
                  open={this.state.open}
                  onCancel={this.close}
                  onConfirm={this.handleSubmit} />
          </span>

      )
    }
}

DeletePromoCode.propTypes = {
    deletePromoCode: propTypes.func.isRequired,
    getPromoCodes: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
  error: state.promoCodes.error_deleting_code,
  success: state.promoCodes.code_deleted_successfully
});

export default withFirebase
    (connect
        (mapStateToProps,
            {getPromoCodes, deletePromoCode, emptyPromoCode})
        (DeletePromoCode)
    );
