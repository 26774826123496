import {
    ADD_EPISODE_REDIRECTION_PAGE,
    EMPTY_EPISODE_REDIRECTION_PAGE,
    EXCHANGE_DATA, CLEAR_EXCHANGE_DATA,
    EMPTY_DRAG_DROP_PARENT_REDIRECTION_PAGE,
    ADD_DRAG_DROP_PARENT_REDIRECTION_PAGE

} from './Types';

export const addSeriesIDtoRedirectionPage = (pageName, series_Id) => dispatch => {

    const redirectionPage = {
        pageName: pageName,
        series_Id: series_Id
    }

    dispatch({
        type: ADD_EPISODE_REDIRECTION_PAGE,
        payload: redirectionPage
    })
}

export const redirectionPage = () => dispatch => {
    dispatch({
        type: EMPTY_EPISODE_REDIRECTION_PAGE,
        payload: ''
    })
}

export const addDragDropParentIDtoRedirectionPage = (pageName, id) => dispatch => {

    const redirectionPage = {
        dragDropPageName: pageName,
        dragDropId: id
    }

    dispatch({
        type: ADD_DRAG_DROP_PARENT_REDIRECTION_PAGE,
        payload: redirectionPage
    })
}


export const emptyDragDropParentRedirectionPage = () => dispatch => {
    dispatch({
        type: EMPTY_DRAG_DROP_PARENT_REDIRECTION_PAGE,
        payload: ''
    })
}

export const exchangeData = (param1, param2, param3) => dispatch => {

    const exchangeData = {
        parameter1: param1,
        parameter2: param2,
        parameter3: param3


    }

    dispatch({
        type: EXCHANGE_DATA,
        payload: exchangeData
    })
}
export const emptyExchangeData = () => dispatch => {
    dispatch({
        type: CLEAR_EXCHANGE_DATA,
        payload: ''
    })
}
