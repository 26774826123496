import React from 'react'
import '../../css/AppCss/loader.css'

const Loader = () => {
    return (<div>
        <br />
        <center> <div className="loader"> </div></center>
        <br />
    </div>)
}

export default Loader;