import React, { Component } from 'react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Actions */
import {
    setStateBookParent,
    emptyError,
    fetchAllBookParent,
    getBookParentData
} from '../../Actions/BookParentAction'

class SetStateBookParent extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setStateBookParent(id, status) {
        this.props.setStateBookParent(id, status)
    }

    handleSubmit = (e) => {

        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setStateBookParent(id, "DEFAULT");

        } else {
            this.setStateBookParent(id, "SUSPENDED");
        }
        this.close();
    }


    render() {
        const { success, error, status, id, sentFrom } = this.props;

        let contentPreview = null;

        if (success === false) {
            alert("Error: " + error);
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Book Parent status updated successfully");
            this.props.emptyError();

            // if the requesst come from All bookParent then
            // fetch all BooksParent to update 
            // the new data
            if (sentFrom === "AllBookParents") {
                this.props.fetchAllBookParent();
            }

            // if the requesst come from ViewBookParent then
            // get Book parent data to update 
            // the suspended Book
            if (sentFrom === "ViewBookParent") {
                this.props.getBookParentData(id);
            }
        }
        if (status === "SUSPENDED") {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons" onClick={this.open}>
                        <i >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )
        }
        else {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>||</button>}
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }


        return (
            <span >
                {contentPreview}
                <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </span>)
    }
}

SetStateBookParent.propTypes = {
    setStateBookParent: propTypes.func.isRequired,
    fetchAllBookParent: propTypes.func.isRequired,
    getBookParentData: propTypes.func.isRequired,
}
const mapStateToProps = state =>
({
    success: state.bookParent.setting_state_book_parent_sucessfully,
    error: state.bookParent.setting_state_book_parent_error
})

export default connect
    (mapStateToProps,
        {
            setStateBookParent,
            emptyError,
            fetchAllBookParent,
            getBookParentData
        })
    (SetStateBookParent)
