import {
    LOAD_PAYMENT, ADD_PAYMENT,
     EMPTY_PAYMENT,CLEAR_MESSAGE, RENEW_PAYMENT, CANCEL_RENEW_PAYMENT
} from '../Actions/Types'

const initialState = {

    payment_feedback_messege: '',
    loading: '',
    payment_updated_successfully: '',
    renew: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RENEW_PAYMENT:
            return {
                ...state,
                renew: true
            } 
        case CANCEL_RENEW_PAYMENT:
            return {
                ...state,
                renew: false
            }     
        case ADD_PAYMENT:
            return {
                ...state,
                payment_feedback_messege: action.payload,
                payment_updated_successfully: true,
                loading: false
            }   
        case LOAD_PAYMENT:
            return {
                ...state,
                loading: true
            }
        case EMPTY_PAYMENT:
            return {
                ...state,
                payment_feedback_messege: '',
                payment_updated_successfully: '',
                loading: '',
                renew:false
            }
        case CLEAR_MESSAGE:
            return{
                ...state,
                payment_feedback_messege: '',
            }     
        default:
            return state
    }
}