import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import OuterHeader from '../Layout/OuterHeader'
import Dashboard from '../Dashboard'
import InnerHeader from '../Layout/InnerHeader'
import Footer from '../Layout/Footer'
import { Button } from 'semantic-ui-react';
/*Redux */
import firebase from 'firebase/app'
import UploadImage from "../S3Uploading/UploadImage";

import Loader from '../Layout/Loader'
import '../../css/AppCss/generalSetting.css';
import { getGeneralSetting, updateGeneralSetting, emptyGeneralSetting } from '../../Actions/GeneralSettingActions'
import { Link } from 'react-router-dom'
import UploadVideo from '../S3Uploading/UploadVideo';
import UploadVideoWithoutDropZone from '../S3Uploading/UploadVideoWithoutDropZone'
/*Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, GENERAL_SETTING } from '../../routes';
import IntroWithMusicUploader from '../FilePondUploading/IntroWithMusicUploader'
import { GENERAL_SETTING_FOLDER_NAME_WITH_MUSIC, GENERAL_SETTING_FOLDER_NAME_WITH_OUT_MUSIC } from '../../portal/src/constants'
import IntroWithoutMusicUploader from '../FilePondUploading/IntroWithoutMusicUploader'
import RenderIntroWithMusic from '../VideoPreview/RenderIntroWithMusic'
import RenderIntroWithoutMusic from '../VideoPreview/RenderIntroWithoutMusic'

const INITIAL_STATE = {
    isSuperAdmin: '',
    aboutUs: '',
    aboutUsEng: '',
    contactUs: '',
    facebookPage: '',
    mainBannerImage: '',
    mainBannerAction: '',
    instagramPage: '',
    twitterAccount: '',
    introVideoWithMusic: '',
    introVideoWithoutMusic: ''
}
class GeneralSetting extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;

        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {

                this.props.getUserData(token);
            })
        }
    }

    componentDidMount() {
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to={SIGN_IN}></Redirect>
        }
        else {
            this.props.getGeneralSetting(1);

            // const { userPaymentProfile } = this.props;
            //
            // if (userPaymentProfile.user_role !== undefined) {
            //     if (userPaymentProfile.user_role.role === "SuperAdmin") {
            //         this.setState({ isSuperAdmin: true })
            //     }
            //     else {
            //         this.setState({ isSuperAdmin: false })
            //     }
            // }
            // else {
            //     this.setState({ isSuperAdmin: false })
            // }
        }
    }
    getImage(imageUrl) {
        if (imageUrl) {

            if (imageUrl === "delete") {
                this.setState({ mainBannerImage: '' });

            } else {
                this.setState({ mainBannerImage: imageUrl });
            }
        }
    }
    componentWillReceiveProps(next) {
        let generalSetting = next.generalSetting;
        this.setState({
            contactUs: generalSetting.contactUs,
            aboutUs: generalSetting.aboutUs,
            aboutUsEng: generalSetting.aboutUsEng,
            facebookPage: generalSetting.facebookPage,
            instagramPage: generalSetting.instagramPage,
            twitterAccount: generalSetting.twitterAccount,
            introVideoWithMusic: generalSetting.introVideoWithMusic,
            introVideoWithoutMusic: generalSetting.introVideoWithoutMusic,
            mainBannerImage: generalSetting.mainBannerImage,
            mainBannerAction: generalSetting.mainBannerAction,

        })
        if (generalSetting.introVideoWithMusic) {
            this.setState({ introVideoWithMusic: generalSetting.introVideoWithMusic })
        }
        if (generalSetting.introVideoWithoutMusic) {
            this.setState({ introVideoWithoutMusic: generalSetting.introVideoWithoutMusic })
        }
    }

    getVideoWithMusicUrl(video) {
        if (video) {
            this.setState({
                introVideoWithMusic: video
            })
        }
    }
    getVideoWithoutMusicUrl(video) {
        if (video) {
            this.setState({
                introVideoWithoutMusic: video
            })
        }
    }


    handleChangeIntroWithMusic = (uploadInfo,videoKey) => {

        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ introVideoWithMusic: uploadInfo.videoId });
        } 

        else {
            this.setState({ introVideoWithMusic: this.state.introVideoWithMusic});
          }
      };

      handleChangeIntroWithoutMusic = (uploadInfo) => {

        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ introVideoWithoutMusic: uploadInfo.videoId });
        } 
        else {
            this.setState({ introVideoWithoutMusic: this.state.introVideoWithoutMusic});
          }
      };

    handleSubmit = (e) => {
        const { aboutUs, contactUs, facebookPage, instagramPage, twitterAccount, introVideoWithMusic, introVideoWithoutMusic, aboutUsEng, mainBannerImage, mainBannerAction } = this.state
        console.log(aboutUsEng)
        this.props.updateGeneralSetting(1, aboutUs, contactUs, facebookPage, instagramPage, twitterAccount, introVideoWithMusic, introVideoWithoutMusic, aboutUsEng, mainBannerImage, mainBannerAction);
        e.preventDefault();

    }

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }

    render() {
        // console.log(this.state.introVideoWithMusic)
        const { generalSetting_updated_successfully, error_updating_generalSetting, loading } = this.props;
        // const { introVideoWithMusic, introVideoWithoutMusic } = this.state
        let data = null



        if (generalSetting_updated_successfully === false) {
            alert("error" + error_updating_generalSetting)
            this.props.emptyGeneralSetting();

        }
        else if (generalSetting_updated_successfully === true) {
            alert("general setting updated successfully");
            this.props.emptyGeneralSetting();
            return (<Redirect to={GENERAL_SETTING}></Redirect>)
        }

        const { isSuperAdmin } = this.state

        // data = <form className="general-setting-form" onSubmit={this.handleSubmit}>
        //     <div className="general-setting-header"><strong>Add intro App video </strong> </div>
        //     {/* adding video with and video without */}
        //     <br />
        //     <div className="row">
        //         <div className="col-4">
        //             <label className="small-label">intro video with music</label> <br />
        //             <div className = "general-setting-video">
        //                 <UploadVideoWithoutDropZone
        //                     getVideoURLCallBack={this.getVideoWithMusicUrl.bind(this)}
        //                     videoUrl={this.state.introVideoWithMusic}
        //                     videoPreview={this.state.introVideoWithMusic}
        //                     s3DirectryrName='generalsetting' />
        //             </div>
        //         </div>
        //         <div className="col-4">
        //             <label className="small-label">intro video without music</label> <br />
        //             <div className = "general-setting-video">
        //                 <UploadVideoWithoutDropZone
        //                     getVideoURLCallBack={this.getVideoWithoutMusicUrl.bind(this)}
        //                     videoUrl={this.state.introVideoWithoutMusic}
        //                     videoPreview={this.state.introVideoWithoutMusic}
        //                     s3DirectryrName='generalsetting' />
        //             </div>
        //         </div>
        //               </div>
        //         </form>
        // if (loading===true)
        // {
        //     data=<Loader/>
        // }
        // else if (loading===false)
        // {
        data = <form className="general-setting-form" onSubmit={this.handleSubmit}>
            <div className="general-setting-header"><strong>Add intro App videos</strong></div>
            <br /> <br />
            {/* <label className="small-label">About App</label> <br/>
            <textarea className="form-control"  rows="3"   name="aboutUs" onChange={this.handleChange} value={this.state.aboutUs} disabled={!isSuperAdmin}></textarea>
            <br/>
            <label className="small-label">Contact Us</label> <br/> */}
            <div className="row">
                <div className="col-12">
                    <label className="small-label"> intro video with music</label>
                    <div className="general-setting-video">
                        {/* <UploadVideoWithoutDropZone
                            getVideoURLCallBack={this.getVideoWithMusicUrl.bind(this)}
                            videoUrl={this.state.introVideoWithMusic}
                            videoPreview={this.state.introVideoWithMusic}
                            s3DirectryrName='generalsetting' /> */}
                            <RenderIntroWithMusic
                                  videoKey={this.state.introVideoWithMusic}
                                  id="IntroWithMusic"
                                  foldername={ GENERAL_SETTING_FOLDER_NAME_WITH_MUSIC}
                                  changedid={this.handleChangeIntroWithMusic}
                                  ></RenderIntroWithMusic>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <label className="small-label"> intro video without music</label>
                    <div className="general-setting-video">
                        {/* <UploadVideoWithoutDropZone
                            getVideoURLCallBack={this.getVideoWithoutMusicUrl.bind(this)}
                            videoUrl={this.state.introVideoWithoutMusic}
                            videoPreview={this.state.introVideoWithoutMusic}
                            s3DirectryrName='generalsetting' /> */}
                            <RenderIntroWithoutMusic
                                  videoKey={this.state.introVideoWithoutMusic}
                                  id="IntroWithoutMusic"
                                  foldername={ GENERAL_SETTING_FOLDER_NAME_WITH_OUT_MUSIC}
                                  changedid={this.handleChangeIntroWithoutMusic}
                                  ></RenderIntroWithoutMusic>
                    </div>
                </div>
</div>

            <br />
            <div className="general-setting-header"><strong>About app</strong></div>
            <br /> <br />
            <label className="small-label">About App English</label> <br />
            <textarea className="form-control" rows="3" name="aboutUsEng" onChange={this.handleChange} value={this.state.aboutUsEng}></textarea>
            <br />
            <label className="small-label">About App Arabic</label> <br />
            <textarea className="form-control" rows="3" name="aboutUs" onChange={this.handleChange} value={this.state.aboutUs}></textarea>
            <br />

            <label className="small-label">Contact Us</label> <br />

            <textarea className="form-control" rows="3" name="contactUs" value={this.state.contactUs} onChange={this.handleChange} ></textarea>
            <br />
            <br />
            <div className="general-setting-header"><strong>Social Media Links</strong></div>
            <br /> <br />
            <label className="small-label">facebook link</label> <br />
            <input dir="auto" className="form-control" name="facebookPage" value={this.state.facebookPage} onChange={this.handleChange}></input> <br />
            <label className="small-label">instagram link</label>
            <input dir="auto" className="form-control" name="instagramPage" value={this.state.instagramPage} onChange={this.handleChange}></input> <br />

            <label className="small-label">twitter link</label>
            <input dir="auto" className="form-control" name="twitterAccount" value={this.state.twitterAccount} onChange={this.handleChange} ></input> <br />
            <div className="general-setting-header"><strong>App Main Banner</strong></div>
            <label className="small-label">main banner action</label> <br />
            <label className="small-label">banner image</label>
            <UploadImage
                getImageURLCallBack={this.getImage.bind(this)}
                imageUrl={this.state.mainBannerImage}
                imagepreview={this.state.mainBannerImage}
                s3DirectryrName='banner'
            />
            <input dir="auto" className="form-control" name="mainBannerAction" value={this.state.mainBannerAction} onChange={this.handleChange}></input> <br />

            <br /> <br />
            <Button onClick={this.handleSubmit} color="green" className="nextButton" >Save</Button>

        </form>

        // }

        return (<div>
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="statusbar small-font">
                                                <span className="status-link">
                                                    <Link to={ADMIN_LAND_PAGE}>Home</Link><span>&gt;</span>
                                                </span>
                                                <span>General Setting</span>

                                            </div>
                                            <br />
                                            <div className="container handleWidth">
                                                <h3>General Setting</h3>
                                                <hr />
                                                {data}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </div>)
    }
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    generalSetting: state.generalSetting.item,
    generalSetting_updated_successfully: state.generalSetting.generalSetting_updated_successfully,
    error_updating_generalSetting: state.generalSetting.error_updating_generalSetting,
    loading: state.generalSetting.loading,
    userPaymentProfile: state.userPaymentProfile.item,

})
export default connect
    (mapStateToProps,
        {
            getGeneralSetting,
            updateGeneralSetting,
            emptyGeneralSetting,
            getUserData
        })
    (GeneralSetting);
