import React, { useState, useEffect } from "react";
/* Components */
// import Features from "./Features";
import Testimonials from "./Testimonials";
import SubscribeSection from "./SubscribeSection";
import Header from "./Header";
import Content from "./Content";
import Footer from "../layout/Footer";
import NavBar from "../layout/NavBar";
import useGeoLocation from 'react-ipgeolocation';
import { useDispatch, useSelector } from "react-redux";
import { changePaymentMethodType, fetchPackagesAndMethods } from "../../../Actions/PaymentDataAction";
import { empty } from "../../../Actions/AuthActions";
import { emptyPromoCode, emptyCodeFromRedux, clearPromoCodeAll } from "../../../Actions/PromoCodesActions";
import { emptyToken } from "../../../Actions/loginActions";
import { clearPaymentData } from "../../../Actions/MobilePaymentActions";
import { emptyPaymentPrice } from "../../../Actions/MobilePaymentPriceAction";
import { emptyMobileUserProfileWithItem, emptyUserProfileWithItem } from "../../../Actions/MobilePaymentProfileAction";
import { emptyCountry, setCountry } from "../../../Actions/CountryActions";
import { getName  } from "country-list";
import { useLocation } from "react-router-dom";
import { emptyStatusMadaId } from "../../../Actions/HyperPayAction";
import { emptyFawryPay } from "../../../Actions/FawryPayAction";

function LoadingIndicator() {
  return <div className="loading-indicator">Loading...</div>;
}

function PortalHome() {
  let query = new URLSearchParams(useLocation().search);
  let codetest = query.get("x-test-country-code-x");

  const location = useGeoLocation();
  const code = location && location.country;

  const data =  useSelector(state => state.paymentData.package_and_methods);
  const country = useSelector(state => state.countryName.country);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    dispatch(empty());
    dispatch(emptyCountry());
    dispatch(emptyUserProfileWithItem());
    dispatch(emptyPromoCode());
    dispatch(emptyMobileUserProfileWithItem());
    dispatch(clearPaymentData());
    dispatch(emptyCodeFromRedux());
    dispatch(emptyStatusMadaId());
    dispatch(emptyFawryPay());
    dispatch(changePaymentMethodType("other")); 

    if(codetest !== null){
      setIsLoading(false);
      const countryName = getName(codetest);
      dispatch(setCountry(countryName))
      dispatch(fetchPackagesAndMethods(codetest));
    }else{
      if(location.isLoading){
        setIsLoading(true);
      }
      if(location.error){
        dispatch(fetchPackagesAndMethods(''));
      }
      if(code && country === ''){
        setIsLoading(false);
        const countryName = getName(code);
        dispatch(setCountry(countryName))
        dispatch(fetchPackagesAndMethods(code));
      }
    }
    

  }, [code,dispatch]);
    return (
      
      <>
        <NavBar />
        <Header />
        <Content />
        <div className="container">   
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <SubscribeSection />
          )}
       </div>
        <Testimonials />

        {/** Start Footer **/}
        <Footer />
        {/** End Footer */}
      </>
    );

}

export default PortalHome;
