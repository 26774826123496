/* eslint-disable */

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { sha256 } from "js-sha256";
import { Link } from "react-router-dom";
/*CSS */
// import '../../../css/AppCss/MobilePayment/payment.css';
// import '../../../css/AppCss/MobilePayment/main-mQuery.css';

/* images */
import fawry from "../../../images/portal/fawry-logo1.png";

/*Actions */
import { addPaymentData } from "../../../Actions/MobilePaymentActions";
import { addSeriesIDtoRedirectionPage } from "../../../Actions/RedirectionPagesActions.js";
import {
  getUserData,
  emptyMobileUserProfile,
  emptyMobileUserProfileWithItem,
} from "../../../Actions/MobilePaymentProfileAction";
import { getFawryPrices } from "../../../Actions/FawryPaymentAction";
import {
  getPromoCodeItemByName,
  emptyPromoCode,
  getSavedPromoCode,
  emptyCodeFromRedux,
  deleteSavedPromoCode,
  checkIfSavedPromoCode,
} from "../../../Actions/PromoCodesActions";
import { getUserPaymentInfo } from "../../../Actions/MobilePaymentProfileAction";
import { addTokenToredux } from "../../../Actions/loginActions.js";
import { getCurrencyRate } from "../../../Actions/CurrencyActions";
import { addFawryRedirectPage } from "../../../Actions/FawryRedirectionActions";
import { saveFawryOrderWithCode } from "../../../Promises/FawryOrders";



/*Redux */
import firebase from "firebase/app";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import propTypes from "prop-types";

/* Routes */
import {
  PORTAL_LAND_PAGE,
  PORTAL_LAND_PAGE_SUBSCRIBE_NOW,
} from "../../../routes";
import { E_Wallet_Payment_Method_Type, Fawry_Payment_Method, Fawry_Payment_Method_Type, Fawry_Return_Url, MERCHANT_CODE, Order_WebHook_Url, SECURE_KEY } from "../../src/constants";

/*consts*/
var itemId = "12222";
var quantity = "1";


const INITIAL_STATE = {
  errorPage: false,
  token: "",
  fawryRedirection: false,
  fawryOrderId: 0,
  cash: 0,
  email: "",
  phone: "",
  loading: "",
  errorMsg: "",
  codeNameFromRedux: "",
  checkIfCurrencyRateExist: 0,
  loggedOut: false,
  openPopup: false,
  checkSaved: false,
  errorDeletingMsg: "",
  redirectionBack: false,
  error: false,


};

class FawryPayment extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          this.setState({ token: token });
          this.props.addTokenToredux(token);
        });
    } else {
      this.setState({ loggedOut: true });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.emptyMobileUserProfileWithItem();
    if (firebase.auth().currentUser) {
      this.props.getUserPaymentInfo();
      this.props.checkIfSavedPromoCode();
      const { token } = this.props;
      // if (token === "") {
      //   this.setState({ loggedOut: true });
      // }
    } else {
      this.setState({ loggedOut: true });
    }
    const { userPaymentProfile } = this.props;


    if (
      userPaymentProfile !== undefined &&
      userPaymentProfile.phone !== undefined &&
      userPaymentProfile.email !== undefined
    ) {
      if (
        userPaymentProfile.phone != null &&
        userPaymentProfile.phone.startsWith("+2")
      ) {
        this.setState({ phone: userPaymentProfile.phone.substring(2) });
      } else if (
        userPaymentProfile.phone != null &&
        !userPaymentProfile.phone.startsWith("+2")
      ) {
        this.setState({ phone: userPaymentProfile.phone });
      }
      this.setState({ email: userPaymentProfile.email });
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.codeName !== prevState.codeNameFromRedux) {
      if (
        props.codeName !== undefined &&
        props.codeName !== "" &&
        props.codeName !== null
      ) {
        props.getPromoCodeItemByName(props.codeName);
      } else {
        //props.getSavedPromoCode();
      }
      return {
        codeNameFromRedux: props.codeName,
      };
    }
  }

  getSignature = () => {
    var cashRounded = Math.round(this.state.cash);
    var cashAsFloat = cashRounded + ".00";
    var hashData = MERCHANT_CODE + this.state.fawryOrderId + this.props.userPaymentProfile.uid +
      Fawry_Return_Url + itemId + quantity + cashAsFloat + SECURE_KEY;  
    var signature = sha256(hashData);
    
    return signature;
  }



  buildChargeRequest = (price) => {
    var priceRound = Math.round(price);
    const paymentMethodType = this.props.paymentMethodName === Fawry_Payment_Method ? Fawry_Payment_Method_Type : E_Wallet_Payment_Method_Type;
    const chargeRequest = {
      merchantCode: MERCHANT_CODE,
      merchantRefNum: this.state.fawryOrderId,
      customerMobile: this.state.phone, //	optional
      customerEmail: this.state.email, //	optional
      customerName: this.props.userPaymentProfile.name, // optional
      customerProfileId: this.props.userPaymentProfile.uid, //	optional
      chargeItems: [
        {
          itemId: itemId,
          price: priceRound,
          quantity: quantity,
        },
      ],
      paymentMethod: paymentMethodType,
      returnUrl: Fawry_Return_Url,
      orderWebHookUrl: Order_WebHook_Url,
      signature: this.getSignature(),
    };
    return chargeRequest;
  }

  checkout = (price) => {
    const configuration = {
      locale: "ar",  //default en
      mode: DISPLAY_MODE.POPUP,  //required, allowed values [POPUP, INSIDE_PAGE, SIDE_PAGE , SEPARATED]
    };
    FawryPay.checkout(this.buildChargeRequest(price), configuration);
  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, " ");
    this.setState({ [e.target.name]: checkSpaces });
    this.setState({ errorMsg: "" });
  };

  handlePay = (cash, paymentType, codeUid, token) => {
    var regex = "^(01)([0-9]){9,9}$";
    if (this.state.email === "" || this.state.email == null) {
      this.setState({ errorMsg: "* لم يتم استكمال البيانات", error: true });
    } else if (!this.state.phone.match(regex) || this.state.email === "" || this.state.email == null) {
      this.setState({ errorMsg: "* يرجى إدخال رقم هاتف صحيح", error: true });
    } else {
      saveFawryOrderWithCode(paymentType, codeUid, token, cash)
        .then((res) => {
          this.setState({
            fawryOrderId: res.data.id,
            cash: cash,
          });
          this.checkout(cash);
          // this.props.emptyCodeFromRedux();
          // this.props.emptyPromoCode();
        })
        .catch((Error) => {
          this.setState({ errorMsg: Error.response.data.message });
          this.setState({ errorPage: true });
        });
    }
  };

  callCurrecnyAPI(currency) {
    this.props.getCurrencyRate(currency);
    this.setState({ checkIfCurrencyRateExist: 1 });
  }



  render() {


    let codeDescription = "";
    const { error } = this.props;
    const { payingData, token } = this.props;


    if (this.state.loggedOut === true) {
      return <Redirect to={PORTAL_LAND_PAGE}> </Redirect>;
    }

    if (payingData === "" || this.state.redirectionBack === true) {
      return <Redirect to={PORTAL_LAND_PAGE_SUBSCRIBE_NOW}> </Redirect>;
    }

    const { promocodesuccess, promocode, promocodeload, isPromoCodeWork } = this.props;
    let codeUid = null;
    if (promocode !== null && promocode !== undefined) {
      codeUid = promocode.uid;
    }
    if (this.state.errorPage === true || error !== "") {
      //this.props.addFawryRedirectPage(PORTAL_UPGRADE_SUBSCRIPTION);
      return <Redirect to={"/FawryFailure"}> </Redirect>;
    }


    var packageType = payingData.paymentType;
    var ePrice = 0;
    var eSPrice = 0;
    if (packageType === "Monthly") {
      ePrice = payingData.paymentCurrencyPackage.monthDiscountPrice;
      eSPrice = ePrice;
    } else if (packageType === "Yearly") {
      ePrice = payingData.paymentCurrencyPackage.yearDiscountPrice;
      eSPrice = ePrice;
    } else if (packageType === "Weekly") {
      ePrice = payingData.paymentCurrencyPackage.weekDiscountPrice;
      eSPrice = ePrice;
    }
    
    let nPrice = payingData.cash;
    //alert(promocodesuccess)
    
    if (
      promocodesuccess === true && isPromoCodeWork &&
      ((promocode !== null && promocode !== undefined && promocode !== "") ||
        promocode.uid !== undefined)
    ) {
      let text = "";
      var symbol = "USD";
      var type = "خصم";
      var amount = "";
      if (payingData.paymentCurrencyPackage !== undefined) {
        symbol =
          payingData.paymentCurrencyPackage.monthDiscountPriceConverted
            .currency;
      }
      const { currencyRate } = this.props;

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "EXTRA_TIME"
      ) {
        symbol = "أسابيع";
        type = "زيادة";
      }
      amount = promocode.discountAmount;

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "PERCENTAGE"
      ) {
        nPrice = payingData.cash - (amount * payingData.cash) / 100;
        nPrice = nPrice.toFixed(2);
        ePrice = ePrice - (amount * ePrice) / 100;
        ePrice = ePrice.toFixed(2);
      } else if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "FIXED"
      ) {
        // amount must be changed to the user country curreny
        if (
          this.state.checkIfCurrencyRateExist === 0 &&
          payingData.paymentCurrencyPackage !== undefined
        ) {
          this.callCurrecnyAPI(
            payingData.paymentCurrencyPackage.monthOriginalPriceConverted
              .currency
          );
        }
        console.log(
          payingData.cash + "   " + amount + "   " + currencyRate + "   "
        );
        nPrice = payingData.cash - amount * currencyRate;
        nPrice = nPrice.toFixed(2);
        ePrice = ePrice - amount;
        ePrice = ePrice.toFixed(2);
      }

      text = (
        <span>
          سعر الباقة بعد الخصم{" "}
          <span className="price-color">{nPrice + " " + symbol}</span>
          . سعر الباقة قبل
          الخصم:{" "}
          <span className="price-color">
            {payingData.cash.toFixed(2) + " " + symbol}
          </span>
        </span>
      );

      if (
        promocode.discountType !== undefined &&
        promocode.discountType.type === "EXTRA_TIME"
      ) {
        text = "سيتم تمديد الباقة " + amount + " أسابيع";
      }

      if (amount !== "" && amount !== undefined) {
        codeDescription = (
          <div class="form-card-content">
            <div class="card-discount checkout-message">{text}</div>
          </div>
        );
      }
    } else {
      codeDescription = "";
    }

    let price;
    if (payingData.paymentType === "Monthly") {

      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.monthDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.monthDiscountPriceConverted
                  .currency
              }
            </span>
          </span>
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    } else if (payingData.paymentType === "Yearly") {
      if (
        payingData.paymentCurrencyPackage.yearDiscountPriceConverted !==
        undefined &&
        payingData.paymentCurrencyPackage.yearDiscountPriceConverted !== null
      ) {

      }
      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.yearDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.yearDiscountPriceConverted
                  .currency
              }
            </span>
          </span>{" "}
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    } else if(payingData.paymentType === "Weekly"){
      if (
        payingData.paymentCurrencyPackage.weekDiscountPriceConverted !==
        undefined &&
        payingData.paymentCurrencyPackage.weekDiscountPriceConverted !== null
      ) {

      }
      price = (
        <div class="card-discount  checkout-message">
          سيتم خصم{" "}
          <span class="price">
            <span className="price-color">
              {
                payingData.paymentCurrencyPackage.weekDiscountPriceConverted
                  .convertedPrice
              }
              &nbsp;
              {
                payingData.paymentCurrencyPackage.weekDiscountPriceConverted
                  .currency
              }
            </span>
          </span>{" "}
          من بطاقة الدفع الخاصة بك ..
        </div>
      );
    }


    let fieldsetContent = (
      <div className="card-content fawry-payment-card mb-4">
        <form className="main-form" action="">
          <div className="form-group">
            <input
             className="form-control payment-input"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="بريدك الإلكتروني"
            />
          </div>
          <div className="form-group">
             
            <input
              type="text"
              className="form-control payment-input"
              name="phone"
              style={{ direction: "ltr", textAlign: "right" }}
              value={this.state.phone}
              onChange={this.handleChange}
              placeholder="رقم هاتفك"
            />
          </div>
        </form>
      </div>
    );


    let buttonSubmitLoader = (
      <input type="image" onClick={() => {
        this.handlePay(
          nPrice,
          payingData.paymentType,
          codeUid,
          token
        );
      }} src="https://www.atfawry.com/assets/img/FawryPayLogo.jpg"
        alt="pay-using-fawry" id="fawry-payment-btn" />
    );



    let content = (
      <span>
        <span>
          <div className="row">
            <div className="col-12">
              <p style={{ color: "red", float: "right", paddingBottom: "5px" }}>
                {" "}
                {this.state.errorMsg}{" "}
              </p>
            </div>
          </div>

          {fieldsetContent}
          {buttonSubmitLoader}
        </span>
      </span>
    );


    return (
      <body>
        <div>


          <section className="main-payment custom-main-payment">
            <div className="container">
              <div className="page-custom-nav d-flex align-items-center justify-content-between my-3">
                <div className="d-flex align-items-center">
                  <div className="navbar-brand d-flex align-items-center">
                    <img src={fawry} alt="" />
                  </div>
                  <div className="complete-process d-none d-sm-block">
                    اكمال عملية الدفع
                  </div>
                </div>
                <Link className="btn back-btn" type="button" to="/">
                  العوده
                </Link>
              </div>
            </div>

            <div className="container checkout-card-container">
              {codeDescription ? codeDescription : price}
              <div className="pay-box checkout-card">
                <div className="card card-container Current-Package">
                  <div className="card-header profile-name-card text-center d-flex align-items-center justify-content-between">
                    <div className="title">بيانات الدفع</div>
                    <div className="icons d-flex align-items-center">
                      <div className="icon">
                        <img src={fawry} alt="" />
                      </div>
                    </div>
                  </div>

                  <center><div className="card-body">{content}</div></center>
                </div>
              </div>
            </div>
          </section>
          {/* <Footer /> */}
        </div>
      </body>
    );
  }
}

FawryPayment.propTypes = {
  addPaymentData: propTypes.func.isRequired,
  addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
  getUserData: propTypes.func.isRequired,
  emptyMobileUserProfile: propTypes.func.isRequired,
  getFawryPrices: propTypes.func.isRequired,
  addTokenToredux: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  loading: state.fawryPaymentPrices.loading,
  error: state.fawryPaymentPrices.getting_fawry_prices_error,
  sucess: state.fawryPaymentPrices.getting_fawry_prices_sucessfully,
  userPaymentProfile: state.userPaymentProfile.item,

  token: state.user.token,

  promocode: state.promoCodes.item,
  promocoderror: state.promoCodes.error_loading_code,
  promocodesuccess: state.promoCodes.get_code_sucessfully,
  promocodeload: state.promoCodes.loading,
  isPromoCodeWork: state.promoCodes.promoCodeWork,

  payingData: state.mobilePaymentPackage.payingData,

  codeName: state.promoCodes.code_name,

  currencyRate: state.currencyRate.item,
  currencyRateSuccess: state.currencyRate.getting_rate_successfully,
  currencyRateError: state.currencyRate.error_getting_rate,

  savedcodeDeletedSuccessfully: state.promoCodes.code_deleted_successfully,
  errorDeletingCode: state.promoCodes.error_deleting_code,

  successSavedCodeChecked: state.promoCodes.get_saved_code_sucessfully,
  loadingSavedCodeChecked: state.promoCodes.loading_saved,
  errorSavedCodeChecked: state.promoCodes.error_loading_saved_code,
  savedcode: state.promoCodes.saved,

  paymentMethodName: state.paymentData.paymentMethod,
});

export default withRouter(
  connect(mapStateToProps, {
    addPaymentData,
    addSeriesIDtoRedirectionPage,
    getUserData,
    emptyPromoCode,
    emptyMobileUserProfile,
    emptyMobileUserProfileWithItem,
    getFawryPrices,
    getUserPaymentInfo,
    addTokenToredux,
    getPromoCodeItemByName,
    getSavedPromoCode,
    checkIfSavedPromoCode,
    emptyCodeFromRedux,
    getCurrencyRate,
    addFawryRedirectPage,
    deleteSavedPromoCode,
  })(FawryPayment)
);
