import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'semantic-ui-react';
import { withFirebase } from '../../config';
import placeHolder from '../../images/placeholder.png';

/*Redux*/
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import {getGeneralActivity, getAllPuzzles, savePuzzle, deletePuzzle } from '../../Actions/GameActions';

/*Headers*/
import Loader from '../Layout/Loader'
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import QuestionPreview from '../Layout/QuestionPreview';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
import UploadImage from '../S3Uploading/UploadImage';
import '../../css/AppCss/gamePreview.css'

import puzzleIcon from '../../images/Puzzle-Shape.png';
import '../../css/AppCss/gamePreview.css';
/* Routes */
import { SIGN_IN, ADMIN_LAND_PAGE, ALL_GAMES, JUMPING_TO_EDIT } from '../../routes';

const INITIAL_STATE = {
}

class jumpDetails extends Component {
  constructor(props) {
      super(props);

      this.state = {
          questionGame: []
      }

      const { auth } = this.props;
      if (!auth.uid) {
          return (<Redirect to={SIGN_IN}></Redirect>)
      }
      else {


      }
  }

  componentDidMount() {
      this.getGeneralActivityDetails();
  }

  getGeneralActivityDetails() {
      getGeneralActivity()
          .then(res => {
              console.log(res.data);
              this.setState({ questionGame: res.data });
          })
          .catch(err => {
              alert(err.response.data.message);
          });
  }


  render() {
    let activity_question = null;
    let editQuestionsLink = null;

    activity_question = (<QuestionPreview questions={this.state.questionGame.questions} state="view" />);

    editQuestionsLink = (<Link to={{ pathname: JUMPING_TO_EDIT + "/" + this.state.questionGame.id }}>

        <Button id="episode_view"> Edit </Button>

    </Link>);

    let activityData = (
        <div className="container">

          <span className="font-italic small-font">
            <hr />
          </span>

          <div className="row" id="episode_Question_Row">
           <label className="question_label">Questions</label>
           <br />
           {activity_question}
          </div>
       </div>
      );

    let jumpQuestions = (

            <div className="container">
                <div className="card">
                    <div className="row" >
                        <div className="col-12">
                            <div className="row">
                                <div className="col">
                                    <img className="rounded" src={puzzleIcon} alt="" height="65" width="65" />
                                </div>
                                <div className="col-10">
                                    <div>
                                        <span className="card-title" color="gray">Jump Questions</span>
                                    </div>
                                    <br />
                                </div>
                                  {activityData}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

      return (
        <div>
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />

                                            <div className="col-12 left-main-body" id="left-main-body">
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                    </span>
                                                    <span className="status-link">
                                                        <Link to={ALL_GAMES}>Get All Games</Link><span> > </span>
                                                    </span>
                                                    <span>Jumping questions Details</span>
                                                </div>
                                            </div>


                                            <br />
                                            {jumpQuestions}
                                        </div>

                                        <div className="buttons_container" style={{paddingRight: '25px'}}>
                                            {editQuestionsLink}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
      )
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  redirectionPage: state.redirectionPage.item
});

export default withFirebase(connect(mapStateToProps, {})(jumpDetails));
