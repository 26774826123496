import axios from 'axios'
import firebase from 'firebase/app';

import {
  LOAD_CURRENCY_RATE, GET_CURRENCY_RATE, ERROR_GETTING_CURRENCY_RATE, EMPTY_CURRENCY_RATE
} from './Types'

export const getCurrencyRate = (currency) => {
    return (dispatch) => {
            dispatch({
                type: LOAD_CURRENCY_RATE,
                payload: 'loading'
            })
            const url = "/PaymentHandlingPrices/getCurrencyRate"
            const formData = new FormData();
            formData.append('Currency', currency);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8'
                }
            }
            axios.post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_CURRENCY_RATE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_GETTING_CURRENCY_RATE,
                    payload: Error.message
                })
            })
        }
    }

export const emptyCurrencyRate = (currency) => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_CURRENCY_RATE,
      payload: ''
    })
  }
}
