import { connect } from 'react-redux'
import React, { Component } from 'react'
import { getSubscriptionsReport, getMondiaSubscriptionsReport,emptyReport  } from "../../Actions/SubscriptionsReportActions";
//import { getTotalPrice,emptySubscriptionReportByCountry} from "../../Actions/CountrySubscriptionsReportActions"
//import { getCountForSubscriptionByCountry ,emptyCountForSubscriptionByCountry } from '../../Actions/CountryCountSubscriptionsReportActions';
import { DateInput } from 'semantic-ui-calendar-react';
 
import MondiaSubscriptionReportDashboard from './MondiaSubscriptionReportDashboard';




class MondiaSubscriptionsReport extends Component 
{
    constructor(props) 
    {
        super(props);
        const today = new Date();
        const eMonth = today.getMonth() + 1;
        const eDay = today.getDate();
        const eYear = today.getFullYear();
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        const sMonth = oneWeekAgo.getMonth() + 1;
        const sDay = oneWeekAgo.getDate();
        const sYear = oneWeekAgo.getFullYear();
        
        this.state = {
            startDate:  `${sMonth}/${sDay}/${sYear}`,
            endDate: `${eMonth}/${eDay}/${eYear}`   
        };
      }
    
      
       

    handleChange = (e) => {
        switch (e.target.name) {
            default:
                this.setState({ [e.target.name]: e.target.value });
        }
    }


    handleDateChange = (e, { name, value }) => {
        
        if (this.state.hasOwnProperty(name)) 
        {
            this.setState({ [name]: value });
          
            if (name === "startDate")
            {
                if (this.state.endDate)
               
               { 
				//this.props.getSubscriptionsReport(value,this.state.endDate);
				this.props.getMondiaSubscriptionsReport(value,this.state.endDate);
            }
              
            }
             if (name ==="endDate")
            {
                if (this.state.startDate)
                { 
	                //this.props.getSubscriptionsReport(this.state.startDate,value);
	                this.props.getMondiaSubscriptionsReport(this.state.startDate,value);
                }
              

            }
        }
        
    }

   

    componentDidMount()
    {
        //this.props.getSubscriptionsReport(this.state.startDate, this.state.endDate);                                  //get arr of packageType --> Monthly/Yearly/Customer/Total subscriptions
        this.props.getMondiaSubscriptionsReport(this.state.startDate, this.state.endDate);
        this.props.emptyReport();        
    }
    

    render() {
        //const {loading,loadingCountries ,loadingCount ,subsReport,subsReportByCountry,CountsubsReportByCountry,errorLoadingSubscriptionsReport, errorLoadingSubscriptionsReportByCountry ,errorLoadingCountSubscriptionsReportByCountry} = this.props;
        const {loading ,loadingCount ,subsReport,errorLoadingSubscriptionsReport} = this.props;
        return (
            <div>
                <div id="tab03" className="tab-contents details-table">
                    
                    <form onSubmit={(e)=> e.preventDefault()}>
                        <div className="form-row mt-2">
                            <div className="col-md-4 col-12 mb-3">
                                <label className="small-label" for="">Start Date</label>
                                <DateInput
                                    name="startDate"
                                    closeOnMouseLeave

                                    value={this.state.startDate}
                                    placeholder="start date"
                                    onChange={this.handleDateChange}
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                />
                            </div>
                            <div className="col-md-4 col-12 mb-3">
                                <label className="small-label" for="">End Date</label>
                                <DateInput
                                    name="endDate"
                                    closeOnMouseLeave

                                    value={this.state.endDate}
                                    placeholder="end date"
                                    onChange={this.handleDateChange}
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                />                 
                             </div>
                        </div>
                    </form>
                    
                    <div className="responsive-table ">
                        <MondiaSubscriptionReportDashboard SubsReport={subsReport} error={errorLoadingSubscriptionsReport} loading={loading}/>                        
                    </div>
         


                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({

    subsReport: state.subscriptionsReport.items,
    errorLoadingSubscriptionsReport: state.subscriptionsReport.error_loading_subscriptions_report,
    loading:state.subscriptionsReport.loading,
    
})
export default connect(mapStateToProps, { 
 //getSubscriptionsReport,
 getMondiaSubscriptionsReport
,emptyReport })(MondiaSubscriptionsReport);
