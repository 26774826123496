import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import ZenDeskChat from '@goranefbl/react-zendesk-chat';

/*CSS */
import '../../css/AppCss/MobilePayment/payment.css';
import '../../css/AppCss/MobilePayment/main-mQuery.css';

/* images */
import logout from '../../images/logout.png';

import logo from "../../images/logo.png";

/*Actions */
import { emptyPaymentPrice, getPackagePriceBycountry } from '../../Actions/MobilePaymentPriceAction';
import { addPaymentData } from '../../Actions/MobilePaymentActions'
import { cancelRenew } from '../../Actions/StripePaymentAction'
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'
import {
    getUserData, emptyMobileUserProfile,
    cancelSubscription, emptyMobileUserProfileWithItem
} from '../../Actions/MobilePaymentProfileAction';
import { addTokenToredux } from '../../Actions/loginActions.js'
import { getUserInfo } from '../../Promises/UserData.js'
import { emptyToken } from '../../Actions/loginActions.js'

/*Components */
import MobilePaymentFooter from './MobilePaymentFooter';
import Loader from '../Layout/Loader';

/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { ThemeProvider } from 'react-bootstrap';

var today = new Date();

function addMonths(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}

function expirationDateFormate(date, type, expirationDate) {

    //console.log("expirationDate = " + expirationDate)
    var expiDate;
    var dd;
    var mm;
    var yyyy;

    if (expirationDate == null || expirationDate == undefined ||
        expirationDate == 'null') {

        expiDate = new Date();
        //console.log("today = " + expiDate)

    }
    else {
        let arrayOfDate = expirationDate.split('-');

        dd = arrayOfDate[0]

        mm = arrayOfDate[1];

        yyyy = arrayOfDate[2];


        var formatedEpxDate = mm + "/" + dd + "/" + yyyy;
        var oldDate = new Date(formatedEpxDate);

        if (today > oldDate) {
            expiDate = new Date();
        }
        else {
            // Subtract 1 from month to convert it to system ( January is 0!)
            // console.log("expirationDate = " + expirationDate)
            expiDate = new Date(yyyy, mm - 1, dd, 0, 0, 0, 0);
            // console.log("new expiDate = " + expiDate)
        }
    }

    if (type == 'Month') {

        expiDate = addMonths(expiDate, 1);
        //console.log("monthExpiDate = " + expiDate)
    }
    else {

        expiDate = addMonths(expiDate, 12);
        //console.log("yearExpiDate = " + expiDate)

    }
    // add 1 to month to convert it to general ( January is 1!)
    var tempMonth = expiDate.getMonth();
    var tempMonth = tempMonth + 1;


    date = expiDate.getDate() + '-' + tempMonth + '-' + expiDate.getFullYear();
    return date;
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const INITIAL_STATE = {
    errorPage: false,
    redirection: false,
    isYearActive: false,
    isSignOut: false,
    token: '',
    redirectPaymentPage: ''
}

const countriesWithOffer = ["Egypt"];

class MobilePayment extends Component {

    constructor(props) {
        console.log("Mobile Payment Constructor");
        super(props);
        this.state = { ...INITIAL_STATE };

        //this.props.emptyToken();
        // this.props.emptyMobileUserProfile();
        // this.props.emptyMobileUserProfileWithItem();
        let token = this.props.match.params.token;
        console.log("token profile = " + token)

        console.log("TOKEN")
        console.log(token)

        getUserInfo(token).then((res) => {
            this.props.getUserData(token);
            this.props.addTokenToredux(token);

            if (res.data.country !== null) {

                this.props.getPackagePriceBycountry(res.data.country, token);
                // this.viewOfferPages(userPaymentProfile.country);
            } else {
                this.setState({ errorPage: true })

            }
        })


        // adding page to browser history
        let red = "/MobilePayment/" + token;
        this.props.history.push(red);
    }


    componentDidMount() {
        let token = this.props.match.params.token;

        // const { token } = this.props;
        console.log("TOKEN")
        console.log(token)
        if (token === undefined || token === null) {
            console.log(" didmount token undefiend")
            this.setState({ redirectPaymentPage: "/MobilePaymentLogin" })
        }
        // if(!firebase.auth().currentUser){
        //   this.setState({redirectPaymentPage: "/MobilePaymentLogin"})
        // }else{
        //   firebase.auth().currentUser.getIdToken().then((token) => {
        //     this.props.getUserData(token);
        //   });
        getUserInfo(token).then((res) => {
            this.props.getUserData(token);
            this.props.addTokenToredux(token);
        })

        const { userPaymentProfile } = this.props;
        //console.log("RRRRRRRRRRRRRRRRRRRRR")
        console.log(userPaymentProfile)
        if (userPaymentProfile.country !== null) {

            this.props.getPackagePriceBycountry(userPaymentProfile.country, token);
        } else {
            this.setState({ errorPage: true })

        }
    }

    componentWillUnmount() {
        console.log("unmount");
        this.props.cancelRenew();
    }

    componentWillReceiveProps(nextProps) {
        /*if(nextProps.currentPackagePaymentId !== null){
          //Perform some operation
          this.viewOfferPages(nextProps.country);
        }*/
        if (nextProps.userPaymentProfile.country !== null) {
            // this.viewOfferPages(nextProps.userPaymentProfile.country);
        }
    }


    handlePay = (cash, paymentType, expirationDate, e) => {
        // const { token } = this.props;
        let token = this.props.match.params.token;

        getUserInfo(token).then((res) => {
            this.props.getUserData(token);
            this.props.addTokenToredux(token);
        })

        //console.log(cash, paymentType, expirationDate)
        this.props.addPaymentData(cash, paymentType, expirationDate, false);

        let oldexpirationDate = this.props.match.params.expirationDate;
        this.props.addSeriesIDtoRedirectionPage("MobilePayment", oldexpirationDate);

        this.setState({ redirection: true })
    }

    activetheYear = () => this.setState({ isYearActive: true })


    deactivetheYear = () => this.setState({ isYearActive: false })

    signOut = (e) => {
        firebase.auth().signOut()
        this.props.emptyMobileUserProfileWithItem();
        console.log("PROPS")
        console.log(this.props)
        console.log("SignOut")
        this.setState({ isSignOut: true })
        e.preventDefault();
    }

    render() {

        // back to previous page if browser back button clicked
        window.onhashchange = function () {
            this.props.history.goBack();
        }

        if (this.state.errorPage === true) {
            return (<Redirect to={"/FawryFailure"}> </Redirect>)
        }

        // const { token } = this.props;
        let token = this.props.match.params.token;

        //console.log(token)
        if (token === undefined || token === null || token === '') {
            //this.setState({redirectPaymentPage: "/MobilePaymentLogin"})
            console.log(" render token undefiend")

            return (<Redirect to={"/MobilePaymentLogin"}> </Redirect>)
        }
        const { userPaymentProfile } = this.props;

        if (this.state.redirectPaymentPage !== '') {
            return (<Redirect to={this.state.redirectPaymentPage}> </Redirect>)
        }


        //console.log(userPaymentProfile)

        // redirect to login if not user
        if (userPaymentProfile.user_role !== undefined) {
            if (userPaymentProfile.user_role.role !== "USER") {
                console.log("  role not user")

                firebase.auth().signOut()
                return (<Redirect to="/MobilePaymentLogin"></Redirect>)
            }
        }

        if (this.state.isSignOut == true) {

            return (<Redirect to="/MobilePaymentLogin" ></Redirect>)

        }

        const { isYearActive } = this.state;
        const { redirection } = this.state;

        if (redirection == true) {
            return (<Redirect to="/MobilePaymentStripeCheckoutParent" ></Redirect>)
        }


        let expirationDate = null;

        if (userPaymentProfile.currentPackagePaymentId !== null &&
            token !== undefined && token !== null && token !== '') {

            if ((!isEmpty(userPaymentProfile)) &&
                userPaymentProfile.currentPackagePaymentId.expirationDatetime !== null
                && userPaymentProfile.currentPackagePaymentId.expirationDatetime !== undefined) {

                //console.log(userPaymentProfile.currentPackagePaymentId.expirationDatetime);

                expirationDate = userPaymentProfile.currentPackagePaymentId.expirationDatetime;
            }
        }

        let calcExpirationDateMonth = expirationDateFormate(today, 'Month', expirationDate);
        let calcExpirationDateYear = expirationDateFormate(today, 'Year', expirationDate);

        const { mobilePaymentPackage } = this.props;


        let description = null;
        if (mobilePaymentPackage.description !== null
            && mobilePaymentPackage.description !== 'null'
            && mobilePaymentPackage.description !== undefined) {
            description = (<div className=" font special-offer p-2 mb-4">
                {mobilePaymentPackage.description}
            </div>)
        }

        const { error, loading } = this.props;

        if (error !== "" && error !== ''
            && error !== null && error !== undefined) {
            this.props.emptyPaymentPrice();
            return (<Redirect to="/MobilePaymentError" ></Redirect>)

        }

        let yearContent = ''
        let monthContent = ''
        let monthActive = ''
        let yearActive = ''

        let packageType = ''

        if (loading === false &&
            error === '') {

            let monthDiscount = '';
            let yearDiscount = '';
            let monthDisConverted = '';
            let yearDisConverted = '';
            let monthOriginalConverted = '';
            let yearOriginalConverted = '';

            if (mobilePaymentPackage.monthOriginalPrice !==
                mobilePaymentPackage.monthOriginalPriceConverted.convertedPrice) {
                monthDisConverted = (
                    <span className="font  small-font eng-lang h5">
                        (${mobilePaymentPackage.monthOriginalPrice})
                        &nbsp;
                    </span>)

                monthOriginalConverted = (
                    <div className=" font price-value h2 m-0 eng-lang">
                        ( ${mobilePaymentPackage.monthDiscountPrice})

                    </div>)
            }

            if (mobilePaymentPackage.monthOriginalPrice !== mobilePaymentPackage.monthDiscountPrice)
                monthDiscount = (<p className=" font small-font text-center">
                    <span className="font">بدلا من</span>
                    <span
                        className="font  small-font eng-lang h5">
                        {monthDisConverted}

                        {mobilePaymentPackage.monthOriginalPriceConverted.currency}
                        &nbsp;
                        {mobilePaymentPackage.monthOriginalPriceConverted.convertedPrice}
                    </span>
                </p>)


            if (mobilePaymentPackage.yearOriginalPrice !==
                mobilePaymentPackage.yearOriginalPriceConverted.convertedPrice) {
                yearDisConverted = (
                    <span className="font  small-font eng-lang h5">
                        (${mobilePaymentPackage.yearOriginalPrice})
                         &nbsp;
                    </span>)
                yearOriginalConverted =
                    (<div className=" font price-value h2 m-0 eng-lang">
                        (  ${mobilePaymentPackage.yearDiscountPrice})
                    </div>)
            }


            if (mobilePaymentPackage.yearOriginalPrice !== mobilePaymentPackage.yearDiscountPrice)
                yearDiscount = (
                    <p className="font  small-font text-center">
                        <span className="font">بدلا من</span>
                        <span
                            className=" font small-font  eng-lang h5">

                            {yearDisConverted}
                            {mobilePaymentPackage.yearOriginalPriceConverted.convertedPrice}
                            &nbsp;
                            {mobilePaymentPackage.yearOriginalPriceConverted.currency}


                        </span>
                    </p>)

            monthContent = (
                <div id="tab02" className="tab-contents">

                    <div className="  card-body">
                        <p className="font">
                            استمتع بمحتوى جيل لمدة شهر كامل لثلاثة أطفال
                                    </p>

                        <div className="  div-box mb-3">
                            <div className="  row">
                                <div className="  col-12 text-center">
                                    {description}
                                    {/* <div className=" font special-offer p-2 mb-4">
                                        خصم خاص
                                                    </div> */}
                                    <div className="  Pkg-price">
                                        <div className=" font small-font text-right">
                                            سعر الباقة
                                                        </div>
                                        <div className=" font price-value h1 m-0 eng-lang">
                                            {mobilePaymentPackage.monthDiscountPriceConverted.convertedPrice}
                                            &nbsp;
                                            {mobilePaymentPackage.monthDiscountPriceConverted.currency}
                                            {/* ${mobilePaymentPackage.monthDiscountPrice} */}

                                            {monthOriginalConverted}

                                        </div>
                                        {monthDiscount}
                                    </div>
                                    <p className=" font Pkg-hint small-font p-2">
                                        إضافة حتى 3 أطفال<br />
                                                    محتوى تربوي ممتع<br />
                                                    تقارير تفصيلية مستقلة لكل طفل<br />
                                                    أنشطة تربوية بعد كل حلقة<br />
                                                    الاستفادة بالقسم التربوي للآباء (جسور)
                                                    </p>
                                    <p className="  danger-color small-font m-0">
                                        <span className="font">تنتهي في</span>
                                        <span className=" font danger-color eng-lang">
                                            {calcExpirationDateMonth}
                                        </span>
                                    </p>

                                </div>
                            </div>
                        </div>


                        <button
                            className=" font btn btn-lg btn-primary btn-block btn-signin mt-2"
                            type="submit" onClick={this.handlePay.bind(this,
                                mobilePaymentPackage.monthDiscountPrice,
                                "Monthly",
                                calcExpirationDateMonth)}>
                            اشترك الآن
                                    </button>
                        <br />
                        <Link id="livechat-open"
                            className="h6 mt-3 mb-0 font">
                            لديك مشكلة؟
                                    </Link>
                    </div>
                    <ZenDeskChat onlineMsg="Live Chat Support" offlineMsg="Leave us a message" appID="rVsdP33FK31Q8eZxdFwegHbpDOk2rzMd" buttonID="livechat-open" />
                </div>

            )

            yearContent = (
                <div id="tab01" className="tab-contents">

                    <div className="  card-body">
                        <p className="font">
                            استمتع بكل محتوى جيل لمدة عام كامل لثلاثة أطفال
                                            </p>
                        <div className="  div-box mb-3">
                            <div className="  row">
                                <div className="  col-12 text-center">
                                    {description}
                                    {/* <div className=" font special-offer p-2 mb-4">
                                        خصم خاص
                                                    </div> */}
                                    <div className="  Pkg-price">
                                        <div className=" font small-font text-right">
                                            سعر الباقة
                                                        </div>
                                        <div className=" font price-value h1 m-0 eng-lang">

                                            {mobilePaymentPackage.yearDiscountPriceConverted.convertedPrice}
                                            &nbsp;
                                            {mobilePaymentPackage.yearDiscountPriceConverted.currency}
                                            {yearOriginalConverted}

                                            {/* ${mobilePaymentPackage.yearDiscountPrice} */}
                                        </div>
                                        {yearDiscount}

                                    </div>
                                    <p className=" font Pkg-hint small-font p-2">
                                        إضافة حتى 3 أطفال<br />
                                                    محتوى تربوي ممتع<br />
                                                    تقارير تفصيلية مستقلة لكل طفل<br />
                                                    أنشطة تربوية بعد كل حلقة<br />
                                                    الاستفادة بالقسم التربوي للآباء (جسور)
                                                </p>
                                    <p className=" font danger-color small-font m-0">
                                        <span className="font">تنتهي في</span>
                                        <span className=" font danger-color eng-lang">
                                            {calcExpirationDateYear}
                                        </span>
                                    </p>

                                </div>
                            </div>
                        </div>

                        <button
                            className=" font btn btn-lg btn-primary btn-block btn-signin mt-2"
                            type="submit" onClick={this.handlePay.bind(this,
                                mobilePaymentPackage.yearDiscountPrice,
                                "Yearly",
                                calcExpirationDateYear)}>
                            اشترك الآن
                                            </button>
                        <br />
                        <Link id="livechat-open"
                            className="h6 mt-3 mb-0 font">
                            لديك مشكلة؟
                                              </Link>
                        <ZenDeskChat onlineMsg="Live Chat Support" offlineMsg="Leave us a message" appID="rVsdP33FK31Q8eZxdFwegHbpDOk2rzMd" buttonID="livechat-open" />
                    </div>
                </div>

            )
        }


        yearActive = (
            <div className="tabs card card-container Current-Package mb-4 ">
                <br />
                <br />
                <span className="h4 font" style={{ paddingRight: "30px" }}> مرحبا &nbsp; {userPaymentProfile.name}

                    <a className=" float-left language-btn logout-btn" style={{ marginLeft: "30px" }}
                        onClick={this.signOut}>
                        <i className="fas fa-chevron-left">
                            <img className="logout" src={logout} alt="logout" />
                        </i>
                    </a>
                </span>
                <div className="tab-button-outer">
                    <ul id="tab-button">
                        <li >
                            <a>
                                <div className="  card-header profile-name-card"
                                    onClick={this.deactivetheYear}>
                                    <div className=" font h4">
                                        الباقة السنوية
                            </div>
                                </div>
                            </a>
                        </li>

                        <li className="is-active">
                            <a>
                                <div className="card-header profile-name-card"
                                    onClick={this.activetheYear}>
                                    <div className=" font h4">
                                        الباقة الشهرية
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                {monthContent}
            </div>)

        monthActive = (
            <div className="tabs card card-container Current-Package mb-4 ">
                <br />
                <br />
                <span className="h4 font" style={{ paddingRight: "30px" }}> مرحبا &nbsp; {userPaymentProfile.name}

                    <a className=" float-left language-btn logout-btn" style={{ marginLeft: "30px" }}
                        onClick={this.signOut}>
                        <i className="fas fa-chevron-left">
                            <img className="logout" src={logout} alt="logout" />
                        </i>
                    </a>
                </span>
                <div className="tab-button-outer">
                    <ul id="tab-button">
                        <li className="is-active">
                            <a>
                                <div className="  card-header profile-name-card"
                                    onClick={this.deactivetheYear}>
                                    <div className=" font h4">
                                        الباقة السنوية
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li >
                            <a>
                                <div className="  card-header profile-name-card"
                                    onClick={this.activetheYear}>
                                    <div className=" font h4">
                                        الباقة الشهرية
                            </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
                {yearContent}
            </div>)

        let content = '';
        if (loading === true) {
            content = (<Loader />)
        }
        else {
            if (isYearActive == true) {
                content =
                    (<span>{yearActive}</span>)
            }
            else {
                content = (
                    <span>{monthActive}</span>
                )
            }
        }

        return (
            <div className="main-payment" >

                <div className="shape-container">
                    <div className=" bg-img">
                    </div>
                </div>

                <div className="container leftToRight">
                    <div className="pay-box">

                        <div className="  main-logo text-center h4">
                            <img className="  logo" alt="logo" src={logo} />
                            <p className=" font  small-font main-color ">
                                بوابة الدفع لجيل
                        </p>
                        </div>
                        <div className="  h5 text-center mb-4 mt-4 font">
                            من فضلك قم بالاشتراك فى احدى الخطط التالية
                        </div>

                        <div className="  row justify-content-md-center Plans-box">

                            {content}
                            {/* {monthActive} */}
                            {/* {yearActive} */}

                        </div>

                    </div>

                    <MobilePaymentFooter />
                </div>


            </div>
        )
    }
}


MobilePayment.propTypes = {
    getPackagePriceBycountry: propTypes.func.isRequired,
    addPaymentData: propTypes.func.isRequired,
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,
    getUserData: propTypes.func.isRequired,
    emptyMobileUserProfile: propTypes.func.isRequired,
    addTokenToredux: propTypes.func.isRequired,
    emptyToken: propTypes.func.isRequired,
    emptyPaymentPrice: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    mobilePaymentPackage: state.mobilePaymentPrice.item,
    loading: state.mobilePaymentPrice.loading,
    error: state.mobilePaymentPrice.getting_package_prices_error,
    sucess: state.mobilePaymentPrice.getting_package_prices_sucessfully,
    autoRenewel: state.mobilePaymentPackage.autoRenewel,
    userPaymentProfile: state.userPaymentProfile.item,
    token: state.user.token,
    renew: state.stripePayment.renew

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                emptyPaymentPrice,
                emptyToken,
                getPackagePriceBycountry,
                addPaymentData,
                cancelRenew,
                addSeriesIDtoRedirectionPage,
                getUserData,
                addTokenToredux,
                emptyMobileUserProfile,
                emptyMobileUserProfileWithItem
            }
        )
        (MobilePayment)
    );
