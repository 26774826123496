import {
    LOAD_ISSUES,
    FETCH_ALL_ISSUES, ERROR_LOADING_ISSUES,
    LOAD_ISSUE,
    ERROR_LOADING_ISSUE
    , GET_ISSUE_DATA,
    UPDATE_ISSUE,
    ERROR_UPDATING_ISSUE,
    ERROR_DELETING_ISSUE, DELETING_ISSUE,
     EMPTY_ERROR, EMPTY_ALL_ISSUES
} from "./Types";
import axios, { post, put } from 'axios'

export const getIssuesByType = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ISSUES,
                payload: 'Loading'
            })

            const url = "/Issue/getIssuesByType"
            const formData = new FormData();
            formData.append('Type_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {

                        type: FETCH_ALL_ISSUES,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_ISSUES,
                    payload: Error.message
                })
            })
        })
    }
}
export const getIssueDetails = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_ISSUE,
                payload: 'Loading'
            })

            const url = '/Issue/getIssueData';
            const formData = new FormData();
            formData.append('Issue_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: GET_ISSUE_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_ISSUE,
                    payload: Error.message
                })
            })
        })

    }
}

export const updateIssueStatus = (status, issueId) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Issue/updateIssueStatus";
            const formData = new FormData();
            formData.append('Issue_ID', issueId)
            formData.append('Status', status);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            put(url, formData, config).then((res) => {
                dispatch({
                    type: UPDATE_ISSUE,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATING_ISSUE,
                    payload: Error.message
                })
            })
        })

    }
}

export const deleteIssue = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_ISSUE,
                payload: 'loading'
            })

            const url = "/Issue/deleteIssue";
            const formData = new FormData();
            formData.append('Issue_ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_ISSUE,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_ISSUE,
                    payload: Error.message
                })
            })
        })

    }
}

export const emptyError = () => dispatch => {
    dispatch({
        type: EMPTY_ERROR,
        payload: ''
    })
}

export const emptyAllIssues = () => dispatch => {
    dispatch({
        type: EMPTY_ALL_ISSUES,
        payload: ''
    })
}