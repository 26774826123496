import {
    ADD_FAWRY_REDIRECTION_PAGE, REMOVE_FAWRY_REDIRECTION_PAGE
} from './Types';

export const addFawryRedirectPage = (route) => dispatch => {
  dispatch({
      type: ADD_FAWRY_REDIRECTION_PAGE,
      payload: route
  })
}

export const removeFawryRedirectPage = () => dispatch => {
  dispatch({
      type: REMOVE_FAWRY_REDIRECTION_PAGE,
      payload: ''
  })
}
