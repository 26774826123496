import React, { Component } from 'react'
import Loader from '../Layout/Loader';
import { ProgressBar } from 'react-bootstrap';

var ReactS3Uploader = require('react-s3-uploader');

const INITIAL_STATE = {
    file: "",
    fileURL: "",
    fileError: "",
    completed: 0,
    no_visible: false,
    s3DirectryrName: ''

}


class UploadExcelFile extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE
    }

    componentWillMount() {

        if (this.props.fileURL !== undefined &&
            this.props.s3DirectryrName !== undefined) {
            this.setState({
                fileURL: this.props.fileURL,
                s3DirectryrName: this.props.s3DirectryrName
            })
        }
    }

    componentWillReceiveProps(nextProp) {
        if (nextProp.fileURL !== undefined &&
            this.props.s3DirectryrName !== undefined) {
            this.setState({
                fileURL: nextProp.fileURL,
                s3DirectryrName: nextProp.s3DirectryrName

            })
        }
    }
    onUploadProgress = (percent) => {
        this.setState({ no_visible: false, completed: percent });
    }

    onError = (errror) => {
        alert(errror)
    }
    onUploadFinish = (e) => {

        this.setState({
            fileURL: e.publicUrl,
            no_visible: true
        })
        console.log("fileURL", e.publicUrl)
        this.props.getFileURLCallBack(e.publicUrl);

    }



    render() {
        let { fileURL, file, fileError, s3DirectryrName } = this.state;
        let loadFile, filePreview = null;

        let uploadinglink = 'newgenepublic';
        if (s3DirectryrName !== '') {
            uploadinglink = 'newgenepublic/' + s3DirectryrName;
        }
        let noDropzone = (
            <div class="custom-file">
                <div className=''>
                    <ReactS3Uploader accept=".csv"
                        signingUrl="/W3S/getPresignedURL"
                        signingUrlMethod="POST"
                        onProgress={this.onUploadProgress}
                        onError={this.handleError}
                        onFinish={this.onUploadFinish}
                        signingUrlHeaders={{
                            'content-type': 'application/json'
                        }}
                        signingUrlQueryParams={{ 'bucketName': uploadinglink }}
                        uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
                        contentDisposition="auto"
                        scrubFilename={(filename) => new Date().getTime() + ".csv"}
                    />
                    <br />
                    <div>
                        <ProgressBar striped bsstyle="success" hidden={this.state.no_visible} now={this.state.completed} label={`${this.state.completed}%`} />
                    </div>
                </div>

            </div>);


        if (file !== "") {

            if (fileURL === "") {
                if (fileError === '') {
                    loadFile = (<Loader />)
                }
                else if (fileError) {

                    this.setState({ fileError: '', file: '' })
                }
            }
            else {
                // console.log("else")
                filePreview = (<label className="label"> Uploaded File : {file.name} </label>)
            }
        }

        return (
            <div>
                {loadFile}
                {filePreview}
                {noDropzone}
                {/* <div className="custom-file">
                    <input type="file" className="custom-file-input" id="inputGroupFile03"
                        onChange={this.handleDropFile} />

                    <label className="custom-file-label" for="inputGroupFile03">Choose file</label>
                </div> */}
            </div>
        )
    }
}

export default UploadExcelFile
