import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Components */
// import AddBook from './AddBook'
// import EditBook from './EditBook'
import GetAllPdfBook from './GetAllPdfBook'
// import ViewBook from './ViewBook'

import AddPdfBook from '../PdfBook/AddPdfBook'
import EditPdfBook from '../PdfBook/EditPdfBook'
import PdfBookDetails from '../PdfBook/PdfBookDetails'
import PublishPdfBook from '../PdfBook/PublishPdfBook'
import PdfBookEvaluation from '../PdfBook/PdfBookEvaluation'
import EditActivitPdfBook from '../PdfBook/EditActivitPdfBook'
import EditEvaluationPdfBook from '../PdfBook/EditEvaluationPdfBook'
import AddPdfBookActivity from '../PdfBook/AddPdfBookActivity'

/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
// import { ADD_BOOK, ADD_RADIO_CHANNEL, ADD_RADIO_CHAN_ACTIVITY, ADMIN_LAND_PAGE, ALL_BOOK, EDIT_ACTIVITY_RADIO_CHAN, EDIT_BOOK, EDIT_RADIO_CHANNEL, EDIT_RADIO_CHAN_EVAL, GET_RADIO_CHAN_EVAL, PUBLISH_RADIO_CHANNEL, SIGN_IN, VIEW_BOOK, VIEW_RADIO_CHANNEL } from '../../routes';
import { ADD_PDF_BOOK, 
    ADD_PDF_BOOK_ACTIVITY, 
    ADMIN_LAND_PAGE, 
    ALL_PDF_BOOK, 
    PDF_BOOK_TO_EDIT, 
    EDIT_ACTIVITY_PDF_BOOK, 
    EDIT_PDF_BOOK, 
    EDIT_PDF_BOOK_EVAL, 
    GET_PDF_BOOK_EVAL, 
    PUBLISH_PDF_BOOK, 
    SIGN_IN, VIEW_PDF_BOOK
 } from '../../routes';

export class PdfBookAuth extends Component {
    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    componentDidMount() {
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to={SIGN_IN}></Redirect>
        }
    }

    render() {
        const { userPaymentProfile } = this.props;

        if (userPaymentProfile.user_role.role === "Analyst") {
            return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
        }

        return (
            <span>
                {/* adding Book Routers */}
                <Route path={ALL_PDF_BOOK} component={GetAllPdfBook} />
                <Route path={ADD_PDF_BOOK} component={AddPdfBook} />
                <Route path={PDF_BOOK_TO_EDIT} component={EditPdfBook} />
                <Route path={VIEW_PDF_BOOK} component={PdfBookDetails} />
                <Route path={PUBLISH_PDF_BOOK} component={PublishPdfBook} />
                <Route path={GET_PDF_BOOK_EVAL} component={PdfBookEvaluation} />
                <Route path={EDIT_ACTIVITY_PDF_BOOK} component={EditActivitPdfBook} />
                <Route path={EDIT_PDF_BOOK_EVAL} component={EditEvaluationPdfBook} />
                <Route path={ADD_PDF_BOOK_ACTIVITY} component={AddPdfBookActivity} />

            </span>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
    (connect
        (mapStateToProps,
            { getUserData }
        )(PdfBookAuth)
    );
