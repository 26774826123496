import {
    FETCH_All_RADIO, GET_RADIO_DATA,
    LOAD_ALL_RADIO, ERROR_LOADING_RADIOES,
    ERROR_LOADING_RADIO, LOAD_RADIO,
    SAVE_RADIO, ERROR_ADDING_RADIO,
    ERROR_SETTING_STATE_RADIO,
    SETTING_STATE_RADIO,
    ERROR_DELETING_RADIO, DELETING_RADIO,
    ERROR_UPDATE_RADIO, UPDATE_RADIO,
    EMPTY_RADIO, EMPTY_RADIO_WITH_DATA,
    ADD_RADIO_WITH_SENTFROM
}from './Types'

import axios, { post } from 'axios'

export const addRadioItemWithSentFrom = (item, sentFrom) => dispatch => {

    const radio = {
        item: item,
        sentFrom: sentFrom
    }
    dispatch({
        type: ADD_RADIO_WITH_SENTFROM,
        payload: radio
    })
}


export const fetchAllRadio = () => dispatch => {

    dispatch({
        type: LOAD_ALL_RADIO,
        payload: 'loading'
    })

    axios.get('/Radio/getAllRadioList')
        .then((res) => {
            dispatch({
                type: FETCH_All_RADIO,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_RADIOES,
                payload: Error.message
            })
        })
}

export const getRadioData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_RADIO,
                payload: 'loading'
            })
            const url = "/Radio/getRadioDetails"
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_RADIO_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_RADIO,
                    payload: Error.message
                })
            })


        })

    }
}

export const saveRadio = (name, description, imageUrl,
    publishDate, audio,
    promoWithMusic, promoWithoutMusic,
    introWithMusic, introWithoutMusic,
    name_in_english, description_in_english, featured) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_RADIO,
                payload: 'loading'
            })

            const url = '/Radio/addRadio';
            const formData = new FormData();
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', imageUrl);
            formData.append('Status', "SUSPENDED");
            formData.append('Publish_Date', publishDate);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promoWithMusic);
            formData.append('Promo_Video_Without_Music', promoWithoutMusic);
            formData.append('Intro_Video_With_Music', introWithMusic);
            formData.append('Intro_Video_Without_Music', introWithoutMusic);
            formData.append('Name_In_English', name_in_english);
            formData.append('Description_In_English', description_in_english);
            formData.append('featured', featured);
            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SAVE_RADIO,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_RADIO,
                    payload: Error.message
                })
            })
        })


    }
}

export const setStateRadio = (id, state) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_RADIO,
                payload: 'loading'
            })

            const url = "/Radio/setRadioStatus";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Status', state);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SETTING_STATE_RADIO,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_STATE_RADIO,
                    payload: Error.response.data.message//Error.message
                })
            })
        })

    }
}

export const deleteRadio = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_RADIO,
                payload: 'loading'
            })

            const url = "/Radio/deleteRadio";
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_RADIO,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_RADIO,
                    payload: Error.message
                })
            })
        })

    }
}

export const updateRadio = (id, name, description,
    status, imageUrl, publishDate, audio,
    promoWithMusic, promoWithoutMusic,
    introWithMusic, introWithoutMusic,
    name_in_english, description_in_english, featured) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_RADIO,
                payload: 'loading'
            })

            const url = "/Radio/updateRadio";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', imageUrl);
            formData.append('Status', "");
            formData.append('Publish_Date', publishDate);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promoWithMusic);
            formData.append('Promo_Video_Without_Music', promoWithoutMusic);
            formData.append('Intro_Video_With_Music', introWithMusic);
            formData.append('Intro_Video_Without_Music', introWithoutMusic);
            formData.append('Name_In_English', name_in_english);
            formData.append('Description_In_English', description_in_english);
            formData.append('featured', featured);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_RADIO,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_RADIO,
                    payload: Error.message
                })
            })
        })

    }
}

export const emptyRadioWithChannels = () => dispatch => {
    dispatch({
        type: EMPTY_RADIO_WITH_DATA,
        payload: ""
    })
}

export const emptyError = () => dispatch => {
    dispatch({
        type: EMPTY_RADIO,
        payload: ""
    })
}
