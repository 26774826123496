import store from '../Store'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import { post } from 'axios'
import { feature } from '../portal/src/constants'

export function saveStory(activityId) {
    return new Promise(function (resolve, reject) {
        let story = store.getState().stories.item;

        let isFree = '', subValueId = '',
            durationWithMusic = '', durationWithoutMusic = '',
            videoWithMusicUrl = '', videoWithoutMusicUrl = '',
            videoKeyVideoWithMusic='' , videoKeyVideoWithoutMusic='';

            console.log(story);

        if (story.isFree === 'paid') {
            isFree = 0
        }
        else if (story.isFree === 'free') {
            isFree = 1
        }

        if (story.subValueId === '' ||
            story.subValueId === undefined ||
            story.subValueId === null) {
            subValueId = 0
        } else {
            subValueId = story.subValueId
        }


        if (story.videoWithMusic_Duration === '' ||
            story.videoWithMusic_Duration === undefined ||
            story.videoWithMusic_Duration === null) {
            durationWithMusic = 0
        } else {
            durationWithMusic = story.videoWithMusic_Duration
        }

        if (story.videoWithoutMusic_Duration === '' ||
            story.videoWithoutMusic_Duration === undefined ||
            story.videoWithoutMusic_Duration === null) {
            durationWithoutMusic = 0
        } else {
            durationWithoutMusic = story.videoWithoutMusic_Duration
        }


        if (story.Video_With_Music === '' ||
            story.Video_With_Music === undefined ||
            story.Video_With_Music === null) {
            videoWithMusicUrl = ''
        } else {
            videoWithMusicUrl = story.Video_With_Music
        }

         if (story.videoKeyVideoWithMusic === '' ||
            story.videoKeyVideoWithMusic === undefined ||
            story.videoKeyVideoWithMusic === null) {
            videoKeyVideoWithMusic = ''
        } else {
            videoKeyVideoWithMusic = story.videoKeyVideoWithMusic
        }
         if (story.videoKeyVideoWithoutMusic === '' ||
            story.videoKeyVideoWithoutMusic === undefined ||
            story.videoKeyVideoWithoutMusic === null) {
            videoKeyVideoWithoutMusic = ''
        } else {
            videoKeyVideoWithoutMusic = story.videoKeyVideoWithoutMusic
        }
        


        if (story.Video_Without_Music === '' ||
            story.Video_Without_Music === undefined ||
            story.Video_Without_Music === null) {
            videoWithoutMusicUrl = ''
        } else {
            videoWithoutMusicUrl = story.Video_Without_Music
        }


        let description = story.Description;
        if (story.Description == null ||
            story.Description == undefined ||
            story.Description == '') {
            description = ''
        }

        const url = "/Story/addStory";
        const formData = new FormData();
        formData.append('Name', story.Name);
        formData.append('Status', 'SUSPENDED');
        formData.append('Description', description);
        formData.append('Icon_Image', story.Icon_Image);
        formData.append('Publish_Date', story.Publish_Date);
        formData.append('Name_In_English', story.name_in_english);
        formData.append('Description_In_English', story.description_in_english)
        formData.append('Activity_ID', activityId);

        formData.append('Video_With_Music_URL', videoWithMusicUrl);
        formData.append('Video_With_Music_Duration', durationWithMusic);

        formData.append('Video_Without_Music_URL', videoWithoutMusicUrl);
        formData.append('Video_Without_Music_Duration', durationWithoutMusic);
        formData.append('Is_free', isFree);
        formData.append('Voice', story.voice);
        formData.append('Content_Sub_Value_Id', subValueId);
        formData.append('Story_Parent_Id', story.selected_storyParent_id);
        formData.append("videoKeyVideoWithMusic", videoKeyVideoWithMusic);
        formData.append("videoKeyVideoWithoutMusic", videoKeyVideoWithoutMusic);
        formData.append('featured', story.featured);
        
        const config = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                'X-Authorization-Firebase': firebase.auth().currentUser.h.b
            }
        }
        post(url, formData, config).then((res) => {
            resolve(res);
            return (res);
        }).catch((Error) => {
            reject(Error)
            return Error.message
        })
    })
}