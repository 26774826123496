import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Components */
import EditSong from './EditSong';
import AddSong from './AddSong';
import SongDetails from './SongDetails';
import GetAllSongs from './getAllSongs';
import PublishSong from './PublishSong';
import SongEvaluation from './SongEvaluation';
import AddSongActivity from './AddSongActivity';
import EditActivitySong from './EditActivitySong';
import EditEvaluationSong from './EditEvaluationSong';
/*Song Parent */
import GetAllSongParents from '../SongParent/GetAllSongParents'
import AddSongParent from '../SongParent/AddSongParent'
import ViewSongParent from '../SongParent/ViewSongParent';
import EditSongParent from '../SongParent/EditSongParent'
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';

/* Routes */
import {
  SIGN_IN, ADMIN_LAND_PAGE, ALL_SONGS,
  ADD_SONG, EDIT_SONG, SONG_DETAIL,
  PUBLISH_SONG, SONG_EVALUATION,
  EDIT_SONG_ACTIVITY, EDIT_SONG_EVALUATION,
  ADD_SONG_ACTIVITY, ADD_SONG_PARENT, VIEW_STORY_PARENT, EDIT_SONG_PARENT, ALL_SONG_PARENTS, VIEW_SONG_PARENT
} from '../../routes';

class SongAuth extends Component {

  constructor(props) {
    super(props);
    if (firebase.auth().currentUser) {
      firebase.auth().currentUser.getIdToken().then((token) => {
        this.props.getUserData(token);
      })
    }
  }

  componentDidMount() {
    const { auth } = this.props;
    if (!auth.uid) {
      return <Redirect to={SIGN_IN}></Redirect>
    }
  }

  render() {
    const { userPaymentProfile } = this.props;

    if (userPaymentProfile.user_role.role === "Analyst") {
      return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
    }
    return (
      <span>
        <Route path={ALL_SONG_PARENTS} component={GetAllSongParents} />
        <Route path={ADD_SONG_PARENT} component={AddSongParent} />
        <Route path={VIEW_SONG_PARENT} component={ViewSongParent} />
        <Route path={EDIT_SONG_PARENT} component={EditSongParent} />

        {/* adding Song Routers */}
        <Route path={ALL_SONGS} component={GetAllSongs} />
        <Route path={ADD_SONG} component={AddSong} />
        <Route path={EDIT_SONG} component={EditSong} />
        <Route path={SONG_DETAIL} component={SongDetails} />
        <Route path={PUBLISH_SONG} component={PublishSong} />
        <Route path={SONG_EVALUATION} component={SongEvaluation} />
        <Route path={EDIT_SONG_ACTIVITY} component={EditActivitySong} />
        <Route path={EDIT_SONG_EVALUATION} component={EditEvaluationSong} />
        <Route path={ADD_SONG_ACTIVITY} component={AddSongActivity} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
  (connect
    (mapStateToProps,
      { getUserData }
    )(SongAuth)
  );
