import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
/*navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';
/*Redux */
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
// import { updateChannel, emptyChannel, getChannelData } from '../../Actions/RadioChannelAction'
import {
    updatePdfBook, emptyPdfBook, getPdfBookData, fetchPdfBooks, emptyError, updatePdfBookPage, addPdfBookPage,
    deletePdfBookPage, addPdfBookItemWithSentFrom
} from '../../Actions/PdfBookAction'
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';
// import { fetchAllRadio, getRadioData } from '../../Actions/RadioActions';
/*CSS */
import '../../css/AppCss/Episode/addEpisode.css';
import '../../css/Songs.css';
/**Other Component */
import PublishDate from '../Layout/PublishDate';
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone'
import UploadAudio from '../S3Uploading/UploadAudio';
import { ALL_PDF_BOOK, PDF_BOOK_TO_EDIT, EDIT_PDF_BOOK, SIGN_IN, VIEW_PDF_BOOK } from '../../routes';
import UploadPageImage from '../S3Uploading/UploadPageImage';
import UploadPdfFile from '../S3Uploading/UploadPdfFile';
import { Popup } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import DeletePage from './DeletePage';
import CSVReader from 'react-csv-reader'
const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]


const INITIAL_STATE = {
    selected_radio_id: "",
    selected_radio_name: "",

    id: null,
    name: "",
    description: "",
    publishDate: "",
    activityId: "",

    imageUrl: "",

    titleAudioUrl: "",
    pdfFileUrl: "",
    showfileUploader: false,
    bookbutton: '',
    voice: '',
    subValueId: '',
    isFree: '',
    featured: false,
    auth_user: {},

    ////////////////// validation form
    error: true,
    errorMessage: "",

    formErrors: { general: '' },
    sentFrom: "",
    item: "",

}

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + " " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    // var date = new Date(GMTDate + ' UTC')
    var date = new Date(GMTDate)
    var d = new Date(date.toString())
    var d = DateFormate(d)
    return d;
}

class EditPdfBook extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        const { pdfBook } = this.props;
        let { updatePageDesc, updatePageImage } = this.state
        var item = pdfBook.item;
        this.setState({
            sentFrom: pdfBook.sentFrom ? pdfBook.sentFrom : "ViewPdfBook",
            item: pdfBook.item ? pdfBook.item : pdfBook
        })

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            this.props.fetchAllSubValues();
        }
        this.setBookDataInState(pdfBook)

    }

    setBookDataInState(data) {

        let pdfBook = data.item ? data.item : data;

        let paymentAvailability = ''
        if (pdfBook.isFree == 0) {
            paymentAvailability = 'paid'
        }
        else if (pdfBook.isFree == 1) {
            paymentAvailability = 'free'
        }

        let titleAudio = pdfBook.voice;
        if (pdfBook.voice == undefined &&
            pdfBook.voice == null &&
            pdfBook.voice == "undefined"
            && pdfBook.voice == "null"
            && pdfBook.voice == 'null') {
            titleAudio = null
        }

        let pdfFile = pdfBook.pdfFile;
        if (pdfBook.pdfFile == undefined &&
            pdfBook.pdfFile == null &&
            pdfBook.pdfFile == "undefined"
            && pdfBook.pdfFile == "null"
            && pdfBook.pdfFile == 'null') {
            pdfFile = null
        }

        let subvalue = ''
        // if (pdfBook.contentSubValue.id === undefined ||
        //     pdfBook.contentSubValue.id === null) {
        //     subvalue = ''
        // } else 
        if(pdfBook.contentSubValue && pdfBook.contentSubValue.id){
            subvalue = pdfBook.contentSubValue.id
        } else {
            subvalue = ''
        }

        this.setState(
            {
                id: pdfBook.id,
                name: pdfBook.pdfName,
                description: pdfBook.description,
                imageUrl: pdfBook.pdfImage,
                status: pdfBook.status,
                pdfFileUrl: pdfBook.itemUrl,
                titleAudioUrl: pdfBook.voice,
                publishDate: pdfBook.publishDate,
                // Uncomment this line ///////////////////////
                activityId: pdfBook.activityId,
                isFree: paymentAvailability,
                subValueId: subvalue,
                featured: pdfBook.featured
            })
    }

    getImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ imageUrl: imageUrl })
        }
    }

    getPublishDate(publishDate) {
        if (publishDate) {
            this.setState({ publishDate: publishDate });
        }
    }

    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ voice: '' });
            }
            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
            }

        }
    }


    getTitleAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {
                this.setState({ titleAudioUrl: '' });
            }
            // else send the url to DB
            else {
                this.setState({ titleAudioUrl: audioUrl });
            }

        }
    }

    updatePdfBook(id, name, description, Image,
        PublishDate, titleAudio, pdfFile,
        activityId,
        isFree,
        subValueId, featured) {

        this.props.updatePdfBook(id, name, description, Image,
            PublishDate, titleAudio, pdfFile,
            activityId,
            isFree,
            subValueId, featured);
    }

    handleChange = (e) => {
        let generalValidation = this.state.formErrors;

        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });

        switch (e.target.name) {
            case 'description':
                this.setState({ [e.target.name]: checkSpaces });
                break;
            default:
                setTimeout(() => {
                    if (checkSpaces === '' || checkSpaces === null || checkSpaces === ' ') {
                        generalValidation.general = "Empty Data is not accepted";
                        this.setState({ formErrors: generalValidation });
                    } else {
                        generalValidation.general = '';
                        this.setState({ formErrors: generalValidation });
                    }

                    if ((this.state.imageUrl === '' ||
                        this.state.imageUrl === null ||
                        this.state.imageUrl === undefined)
                        || (this.state.name === '' ||
                            this.state.name === null ||
                            this.state.name === ' ')
                    ) {
                        {
                            generalValidation.general = "Empty Data is not accepted";
                            this.setState({ formErrors: generalValidation });
                        }
                    }

                }, 0);
        }
    }

    handlePaymentAva = (e, { value }) => {

        console.log("value in handle change = " + value)

        this.setState({
            isFree: value
        })

    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
    };
    
    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };

    handleSubmit = (e) => {

        if (e.nativeEvent.submitter.id === "nextButton") {
            const { id, name, description, imageUrl,
                publishDate,
                activityId,
                isFree,
                subValueId,
                pdfFileUrl, titleAudioUrl, featured} = this.state;

            this.updatePdfBook(id, name, description, imageUrl,
                publishDate, titleAudioUrl, pdfFileUrl,
                activityId,
                isFree,
                subValueId, featured);
        }

        /// calling save
        e.preventDefault();

    }

    getFileUrl(fileURL) {
        if (fileURL) {
            this.setState({
                pdfFileUrl: fileURL,
                showfileUploader: false
            });
        }
    }

    handleFileUploaderPreview = (e) => {
        const { showfileUploader } = this.state;

        if (showfileUploader === true) {
            this.setState({
                showfileUploader: false
            })
        }
        else {
            this.setState({
                showfileUploader: true
            })
        }
    }

    render() {
        const papaparseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
        };
        const { sentFrom, item } = this.state;
        const { error_update, success_update } = this.props;
        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }

        const { success,
            error, loading } = this.props

        // loading button
        let $buttonSubmitLoader = (
            <Button disabled={this.state.formErrors.general}
                type="submit" id="nextButton" >
                Save
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="nextButton"
                    class="ui loading button">Loading</button>);
        }

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true
            || this.state.cancel === true) {
            if (success === true) {
                alert("Book updated Successfully")
                this.props.emptyError();
            }
            // if the requesst come from All Book then
            //  fetch all Book to update the item data

            if (sentFrom === "GetAllPdfBook") {
                this.props.fetchPdfBooks();
                return (<Redirect to={ALL_PDF_BOOK}></Redirect>)

            }

            // if the requesst come from ViewRadio then
            //  get Book data to update the item data
            if (sentFrom === "ViewPdfBook") {
                this.props.getPdfBookData(item.id);
                return (<Redirect to={VIEW_PDF_BOOK}></Redirect>)

            }

            if (!sentFrom) {
                this.props.getPdfBookData(item.id);
                return (<Redirect to={VIEW_PDF_BOOK}></Redirect>)
            }

        }

        const { name, imageUrl,
            publishDate,
            isFree, pdfFileUrl, showfileUploader } = this.state;

        const isInvalid =
            name === '' || name == undefined ||
            imageUrl === '' || imageUrl == undefined ||
            // publishDate === '' || publishDate == undefined ||
            isFree === '' || isFree == undefined;

        // 
        let uploadFile = (<UploadPdfFile
            getFileURLCallBack={this.getFileUrl.bind(this)}
            fileURL={this.state.pdfFile}
            s3DirectryrName='pdfBook/pdf' />
        )

        if (showfileUploader == false) {
            uploadFile = (
                <div style={{ maxWidth: '100%' }}>
                    <div className="d-none">
                    <UploadPdfFile
                        getFileURLCallBack={this.getFileUrl.bind(this)}
                        fileURL={this.state.pdfFile}
                        s3DirectryrName='pdfBook/pdf' />
                    </div>
                    <br />
                    <br />
                </div>
            )
        }

        if (pdfFileUrl == undefined ||
            pdfFileUrl == null ||
            pdfFileUrl == '') {
            uploadFile = (<div style={{ maxWidth: '100%' }}>
                <span style={{ wordBreak: "break-all" }}> File Link : </span>
                <div className="">
                <UploadPdfFile
                        getFileURLCallBack={this.getFileUrl.bind(this)}
                        fileURL={this.state.pdfFile}
                        s3DirectryrName='pdfBook/pdf' />
                </div>
                <br />
                <br />
            </div>)

        }
        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="container">

                                                    <form onSubmit={this.handleSubmit}>
                                                        <div className='d-flex justify-content-between align-items-center mb-3'>
                                                            <h2 className="label mb-0">Edit Pdf Book</h2>
                                                            {$buttonSubmitLoader}

                                                        </div>
                                                        <br />


                                                        <p style={{ color: "red" }}>
                                                            {this.state.formErrors.general}
                                                        </p>


                                                        <label htmlFor="Image" className="label">
                                                            Book Image
                                                        </label>
                                                        <br />

                                                        <div className="row">

                                                            <div className=" col-4">
                                                                <UploadImageWithoutDropZone
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.imageUrl}
                                                                    imagepreview={this.state.imageUrl}
                                                                    s3DirectryrName='readWithJeel' />

                                                            </div>
                                                        </div>

                                                        <br />

                                                        <div className="input-field">
                                                            <label htmlFor="name"
                                                                className="label">Book Name</label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="name"
                                                                fluid id="name"
                                                                onChange={this.handleChange}
                                                                value={this.state.name}
                                                                required />
                                                        </div>
                                                        <br />
                                                        <div className="form-group">
                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Book Description
                                                            </label><br />
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="description"
                                                                fluid onChange={this.handleChange}
                                                                value={this.state.description} />
                                                        </div>
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select payment availability</label><br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select Payment Availability'
                                                            id="episode_series_id"
                                                            onChange={this.handlePaymentAva}
                                                            options={is_free_type}
                                                            value={this.state.isFree}
                                                        />
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            Sub Value</label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Sub Value'
                                                            id="episode_series_id"
                                                            onChange={this.handleSubValueChange}
                                                            options={subValuesList}
                                                            value={this.state.subValueId}
                                                        />
                                                        <br />

                                                        {/* DataComponent */}
                                                        {/* {customPublishDate} */}
                                                        <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                            date={this.state.publishDate}
                                                            showNow={!isInvalid} />
                                                        <br />
                                                        <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={this.state.featured}
                                                            onChange={this.handleCheckboxChange}
                                                        />
                                                        {' Featured'}
                                                        </label>
                                                        <br />
                                                        <label htmlFor="name" className="label">
                                                            Title Audio
                                                        </label>
                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getTitleAudio.bind(this)}
                                                            audioPreview={this.state.titleAudioUrl}
                                                            audioUrl={this.state.titleAudioUrl}
                                                            s3DirectryrName="pdfBook/audio"
                                                        />
                                                        <br/>



                                                            <label htmlFor="name" className="label">
                                                                Pdf Book File
                                                            </label>
                                                            <br/>
                                                            {
                                                                pdfFileUrl && <span className='mb-2 d-block' style={{ wordBreak: "break-all" }}> Pdf Book : <a href={pdfFileUrl} download>View Pdf</a></span>
                                                            }
                                                            
                                                            <UploadPdfFile
                                                                getFileURLCallBack={this.getFileUrl.bind(this)}
                                                                fileURL={this.state.pdfFile}
                                                                s3DirectryrName='pdfBook/pdf' />
                                                            <br />

                                                        <br /> <br /> <br />
                                                    </form>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


const mapStateToProps = state => ({

    pdfBook: state.pdfBook.item,
    activity: state.pdfBook.item.activityId,
    auth: state.firebase.auth,

    addPageItem: state.pdfBook.addPageItem,

    loading: state.pdfBook.loading,
    success: state.pdfBook.book_updated_successfully,
    error: state.pdfBook.error_updated_book,

    allSubValues: state.ContentSubValue.items,
    redirectionPage: state.redirectionPage.item,
});

EditPdfBook.propTypes = {
    addPdfBookItemWithSentFrom: propTypes.func.isRequired
}
export default connect(
    mapStateToProps,
    {
        fetchPdfBooks,
        getPdfBookData,
        emptyPdfBook,
        updatePdfBook,
        fetchAllSubValues,
        emptyError,

        addPdfBookItemWithSentFrom
    })(EditPdfBook);
