import {SAVE_COMMENT,ERROR_SAVING_COMMENT, GET_ISSUE_COMMENTS, ERROR_LOADING_ISSUE_COMMENTS,LOAD_ISSUE_COMMENTS} from "../Actions/Types"

const initial_state = 
{
    item:{},
    comment_added_successfully:"",
    items:[],
    error_adding_comment:""
}

export default function (state=initial_state,action)
{
    switch(action.type)
    {
        case SAVE_COMMENT:
            return {
                ...state,
                item:action.payload,
                comment_added_successfully:true,
                items:state.items.concat(action.payload)

            }
        case LOAD_ISSUE_COMMENTS:
            return{
                ...state,
                loading:true
            }
        case GET_ISSUE_COMMENTS:
            return {
                ...state,
                items:action.payload,
                loading:false
            }
        case ERROR_LOADING_ISSUE_COMMENTS:
            return{
                ...state,
                erorr_loading_issue_comments:action.payload,
                loading:false
            }
        case ERROR_SAVING_COMMENT:
            return {
                ...state,
                error_adding_comment:action.payload,
                comment_added_successfully:false

            }
        default:
            return state
    }
}
