import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { setStateEpisode, emptyError } from '../../Actions/EpisodeActions'
import { getSeriesData } from '../../Actions/SeriesActions';
import { getDiscoverUsSeriesData } from '../../Actions/DiscoverUsSeriesActions'

class SetStateEpisode extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setStateEpisode(id, state) {
        this.props.setStateEpisode(id, state);
    }

    getSeriesData(seriesID) {
        this.props.getSeriesData(seriesID)
    }

    handleSubmit = (e) => {

        const { id, state } = this.props;

        if (state === "SUSPENDED") {
            this.setStateEpisode(id, "DEFAULT");

        } else {

            this.setStateEpisode(id, "SUSPENDED");
        }
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;
        const { seriesType } = this.props;
        const { state, seriesID } = this.props;

        let contentPreview = null;

        if (success === false) {
            alert("Error: " + error)
            this.props.emptyError();
            if (seriesType === "Normal") { this.getSeriesData(seriesID) }
            else if (seriesType === "Discover") {
                this.props.getDiscoverUsSeriesData(seriesID);
            }
        }
        else if (success === true) {
            alert("Episode State Changed Successfully")
            this.props.emptyError();
            if (seriesType === "Normal") { this.getSeriesData(seriesID) }
            else if (seriesType === "Discover") {
                this.props.getDiscoverUsSeriesData(seriesID);
            }
        }

        if (state === "SUSPENDED") {

            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons" onClick={this.open}>
                        <i className="" >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>}
                    content='Tap to Active'
                    position='top center'
                />
            )

        } else {

            contentPreview = (

                <Popup trigger={
                    <button className="seriesCardButtons" onClick={this.open}>||</button>
                }
                    content='Tap to Suspend'
                    position='top center'
                />
            )

        }

        return (

            <div >
                {contentPreview}
                <Confirm id="modal"
                    open={this.state.open}
                    onCancel={this.close}
                    onConfirm={this.handleSubmit} />
            </div>

        )

    }
}


SetStateEpisode.propTypes = {
    setStateEpisode: propTypes.func.isRequired,
    getSeriesData: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    episodes: state.episodes.item,
    error: state.episodes.setting_state_episode_error,
    success: state.episodes.setting_state_episode_sucessfully
});

export default withFirebase(connect(mapStateToProps, { setStateEpisode, emptyError, getSeriesData, getDiscoverUsSeriesData })(SetStateEpisode));
