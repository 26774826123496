import React, { Component } from 'react'
import { Link } from 'react-router-dom'
/*Redux */
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import propTypes from 'prop-types';
import { Accordion, Icon } from 'semantic-ui-react'

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
/*Components */
import DurationOfUse from './DurationOfUse';
import TimeOfUse from './TimeOfUse';
/* Routes */
import { ADMIN_LAND_PAGE } from '../../routes';

class Recommendation extends Component {

    state = { activeIndex: 0 }

    componentDidMount() {

    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }


    render() {
        const { activeIndex } = this.state

        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <div className="box-container">


                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                    </span>
                                                    <span>Recommendation</span>
                                                </div>
                                                {/* <!--  end status bar --> */}


                                                <div className="main-title-container">
                                                    <h5 className="main-title mt-2 font-weight-bold">Recommendation</h5>
                                                </div>

                                                <br />

                                                {/* /*************** duration of Use********************* *  */}

                                                <Accordion fluid styled>
                                                    <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
                                                        <div className="card-header" id="card-header" >
                                                            <h4 className="mb-0">

                                                                <Icon name='dropdown' />
                                                                Duration OF Use
                                                                    <div className="acor-btn-group">

                                                                    {/* <EditFAQ faqItem={faqItem} />

                                                <DeleteFAQ id ={faqItem.id}/> */}

                                                                </div>
                                                            </h4>
                                                        </div>
                                                    </Accordion.Title>

                                                    <Accordion.Content active={activeIndex === 0}>
                                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                            <div className="card-body">
                                                                <DurationOfUse />
                                                            </div>
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>



                                                {/* /************** time of use ********************** *  */}


                                                <Accordion fluid styled>
                                                    <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                                                        <div className="card-header" id="card-header" >
                                                            <h4 className="mb-0">

                                                                <Icon name='dropdown' />
                                                                Time OF Use
                                                                    <div className="acor-btn-group">
                                                                </div>
                                                            </h4>
                                                        </div>
                                                    </Accordion.Title>

                                                    <Accordion.Content active={activeIndex === 1}>
                                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                            <div className="card-body">
                                                                    <TimeOfUse/>
                                                            </div>
                                                        </div>
                                                    </Accordion.Content>
                                                </Accordion>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

Recommendation.propTypes = {
}
const mapStateToProps = state => ({
});

export default withRouter(connect(mapStateToProps, {})(Recommendation));
