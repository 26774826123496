import React, { Component } from 'react';
import { put, post } from 'axios';
import Player from 'video-react/lib/components/Player';
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import 'firebase/firestore'
import 'firebase/auth'
import Footer from '../Layout/Footer'
import '../../../node_modules/video-react/dist/video-react.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Redirect } from 'react-router-dom'
import { Dropdown, Input, Button, Icon } from 'semantic-ui-react';
/*Actions*/
import { fetchAllSubValues } from '../../Actions/ContentSubValueAction';
import { updateSong, emptySong, getSongData } from '../../Actions/SongActions'
import { fetchAllSongParent, getSongParentData } from '../../Actions/SongParentAction';

/**Other Component */
import PublishDate from '../Layout/PublishDate';
import { connect } from 'react-redux';
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone'
import UploadVideoWithoutDropZone from '../S3Uploading/UploadVideoWithoutDropZone';
import UploadAudio from '../S3Uploading/UploadAudio';

/* Routes */
import { SIGN_IN, ALL_SONGS } from '../../routes';
import { SONG_FOLDER_NAME } from '../../portal/src/constants';
import RenderVideoWithMusic from '../VideoPreview/RenderVideoWithMusic';
import RenderVideoWithoutMusic from '../VideoPreview/RenderVideoWithoutMusic';
import VideoWithMusicUploader from '../FilePondUploading/VideoWithMusicUploader';
import VideoWithoutMusicUploader from '../FilePondUploading/VideoWithoutMusicUploader';
import { fetchAllContentVendors } from '../../Actions/ContentVendorActions.js'



const is_free_type = [{
    key: 0,
    text: 'paid',
    value: 'paid'
},
{
    key: 1,
    text: 'free',
    value: 'free'
}]


const buttonStyle = {
    float: "right"
}
const INITIAL_STATE = {

    selected_songParent_id: "",
    selected_songParent_name: "",
    video_with_music_folder_id : "",
    video_with_out_music_folder_id : "",

    id: null,
    song_name: "",
    description: "",
    song_name_in_english: "",
    description_in_english: "",
    publishDate: "",
    error: "",
    videoWithMusic: "",
    videoWithoutMusic: "",
    activityId: "",
    imageUrl: "",
    videoWithMusic_duration: 0,
    videoWithoutMusicPreviewUrl: "",
    videoWithoutMusicUrl: "",
    videoWithoutMusic_duration: 0,
    isFree: '',
    voice: '',
    subValueId: '',

    auth_user: {},

    formErrors: { general: '' },
    video_key_song_video_with_music: "",
    video_key_song_video_without_music: "",
    featured: false,
    contentVendorSelectedId: '',
}


class EditSong extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            this.props.fetchAllSubValues();
            this.props.fetchAllSongParent();
            this.props.fetchAllContentVendors();

            let songParentName = this.props.match.params.songParentName;
            let songParentId = this.props.match.params.songParentId;
            let songId = this.props.match.params.songId;


            this.setState({
                id: songId,
                selected_songParent_id: songParentId,
                selected_songParent_name: songParentName,
                
            })
            this.props.getSongData(songId);
            this.props.getSongParentData(songParentId)

            const { songParent } = this.props;
            const {song} = this.props;


            if (!songParent.withMusicFolderId || songParent.withMusicFolderId=== null ||songParent.withMusicFolderId ==="" ||
      !songParent.withoutMusicFolderId || songParent.withoutMusicFolderId=== null ||songParent.withoutMusicFolderId ===""){
        this.setState({
            video_with_music_folder_id : "",
            video_with_out_music_folder_id :""    ,
        })
      }
      else {
        this.setState({
            video_with_music_folder_id : songParent.withMusicFolderId,
            video_with_out_music_folder_id :songParent.withoutMusicFolderId    ,
        })
      }


      
        }

    }

    componentWillReceiveProps(nextprop) {
        let song = nextprop.song;        
        if (song.videoWithMusicId !== undefined && song.videoWithoutMusicId !== undefined) {


            let videoMusicUrl = null,
                videoWithoutMusicUrl = null,
                videoMusicDuration = 0,
                videoWithoutMusicDuration = 0,
                    videoKeyWithMusic = null,
                    videoKeyWithoutMusic = null;

            if (song.videoWithMusicId !== null) {

                if (song.videoWithMusicId.url !== undefined
                    && song.videoWithMusicId.url !== null) {
                    videoMusicUrl = song.videoWithMusicId.url;
                }

                if (song.videoWithMusicId.duration !== null
                    && song.videoWithMusicId.duration !== undefined) {
                    videoMusicDuration = song.videoWithMusicId.duration
                }
                if (song.videoWithMusicId.vdocipherId !== null
                    && song.videoWithMusicId.vdocipherId !== undefined) {
                        videoKeyWithMusic = song.videoWithMusicId.vdocipherId
                }

            }

            if (song.videoWithoutMusicId !== null) {
                if (song.videoWithoutMusicId.url !== undefined
                    && song.videoWithoutMusicId.url !== null) {
                    videoWithoutMusicUrl = song.videoWithoutMusicId.url
                }

                if (song.videoWithoutMusicId.duration !== undefined
                    && song.videoWithoutMusicId.duration !== null) {
                    videoWithoutMusicDuration = song.videoWithoutMusicId.duration
                }
                if (song.videoWithoutMusicId.vdocipherId !== null
                    && song.videoWithoutMusicId.vdocipherId !== undefined) {
                        videoKeyWithoutMusic = song.videoWithoutMusicId.vdocipherId
                }
            }




            let paymentAvailability = ''
            if (song.isFree == 0) {
                paymentAvailability = 'paid'
            }
            else if (song.isFree == 1) {
                paymentAvailability = 'free'
            }

            let audio = song.voice;
            
            if (audio === 'undefined') {
                audio = null
            }

            let subvalue = ''

            if (song.contentSubValue === undefined ||
                song.contentSubValue === null) {
                subvalue = ''
            } else {
                subvalue = song.contentSubValue.id
            }
            this.setState(
                {
                    song_name: song.name,
                    description: song.description,
                    imageUrl: song.image,
                    status: song.status,
                    videoWithMusic: videoMusicUrl,
                    videoWithoutMusic: videoWithoutMusicUrl,
                    publishDate: song.publishDate,
                    activityId: song.activityId.id,
                    videoWithMusic_duration: videoMusicDuration,
                    videoWithoutMusic_duration: videoWithoutMusicDuration,
                    isFree: paymentAvailability,
                    voice: audio,
                    subValueId: subvalue,
                    song_name_in_english: song.nameInEnglish,
                    description_in_english: song.descriptionInEnglish,
                    video_key_song_video_with_music: videoKeyWithMusic,
                    video_key_song_video_without_music: videoKeyWithoutMusic,
                    featured: song.featured,
                    contentVendorSelectedId: song.contentVendor?song.contentVendor.id:''
                })
                
                
            let generalValidation = this.state.formErrors
            // if (song.nameInEnglish !== null && song.nameInEnglish !== undefined ) {
            //      generalValidation.general = '';
            //      this.setState({ formErrors: generalValidation });
            // }else {
            //     generalValidation.general = "Empty Data is not accepted";
            //     this.setState({ formErrors: generalValidation });
            // }
        }

    }

    getImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ imageUrl: imageUrl })
        }
    }

    getPublishDate(publishDate) {

        if (publishDate) {
            this.setState({ publishDate: publishDate });
            
        }
    }

    getVideoWithMusicUrl(videoUrl, duration) {
        if (videoUrl) {
            
            this.setState({
                videoWithMusic: videoUrl,
                videoWithMusic_duration: duration
            })
        }
    }

    getVideoWithoutMusicUrl(videoUrl, duration) {
        if (videoUrl) {
            this.setState({
                videoWithoutMusic: videoUrl,
                videoWithoutMusic_duration: duration
            })
        }
    }

    updateSong(id, name, description, Image, PublishDate,
        videoWithMusic, videoWithoutMusic,
        activityId, videoWithMusic_duration,
        videoWithoutMusic_duration, isFree, voice,
        subValueId, selected_songParent_id,
        song_name_in_english,description_in_english,
        video_key_song_video_with_music,
        video_key_song_video_without_music, featured,contentVendorSelectedId) {
        
        this.props.updateSong(id, name, description, Image,
            PublishDate, videoWithMusic,
            videoWithoutMusic, activityId,
            videoWithMusic_duration,
            videoWithoutMusic_duration, isFree, voice,
            subValueId,
            selected_songParent_id,
            song_name_in_english,
            description_in_english,
            video_key_song_video_with_music,
            video_key_song_video_without_music, featured,contentVendorSelectedId);
    }


    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ voice: '' });
                
         

            }

            // else send the url to DB
            else {
                this.setState({ voice: audioUrl });
                
       

            }

        }
    }

    handleUploadVideoWithMusic = (uploadInfo) => {
        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ video_key_song_video_with_music: uploadInfo.videoId });
        } else {
            this.setState({ video_key_song_video_with_music: this.state.video_key_song_video_with_music });
        }
      };
      handleUploadVideoWithoutMusic = (uploadInfo) => {
        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ video_key_song_video_without_music: uploadInfo.videoId });
        } 
        
        else {
          this.setState({ video_key_song_video_without_music: this.state.video_key_song_video_without_music });
        }
      };
    handleChangeVideoWithMusic = (uploadInfo) => {
        
        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ video_key_song_video_with_music: uploadInfo.videoId });
        } 
        else {
            this.setState({ video_key_song_video_with_music: this.state.video_key_song_video_with_music});
        }
      };
      handleChangeVideoWithoutMusic = (uploadInfo) => {
        
        if (
          uploadInfo &&
          uploadInfo !== undefined &&
          uploadInfo !== null &&
          Object.keys(uploadInfo).length > 0
        ) {
          this.setState({ video_key_song_video_without_music: uploadInfo.videoId });
        } 

        else {
            this.setState({ video_key_song_video_without_music: this.state.video_key_song_video_without_music});
        }
      };
      setVideoWithMusicReady = (isReady) => {
        
        if (
         isReady
        )
          this.setState({ VideoWithMusicReady: true });
      };
      setVideoWithoutMusicReady = (isReady) => {
        
        if (
         isReady
        )
          this.setState({ VideoWithoutMusicReady: true });
      };
    handleChange = (e) => {
        let generalValidation = this.state.formErrors;

        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
        this.setState({ [e.target.name]: checkSpaces });

        switch (e.target.name) {
            case 'song_name' , 'song_name_in_english' :
                this.setState({ [e.target.name]: checkSpaces });
                generalValidation.general = '';
                this.setState({ formErrors: generalValidation });
                setTimeout(()=>{
                    if(specialCharacters.test(this.state.song_name) || specialCharacters.test(this.state.song_name_in_english)){
                        generalValidation.general = "Song name can't contain spaecial characters";
                        this.setState({ formErrors: generalValidation });
                    }
                })
            break;
            case 'description':
                this.setState({ [e.target.name]: checkSpaces });
                break;
            case 'description_in_english':
                this.setState({ [e.target.name]: checkSpaces });
                generalValidation.general = '';
                this.setState({ formErrors: generalValidation });
                setTimeout(()=>{
                    if(this.state.description_in_english.length > 255){
                        generalValidation.general = "Description in English is too long (greater than 255 characters).";
                        this.setState({ formErrors: generalValidation });
                    }
                })
                break;    
            default:
                setTimeout(() => {
                    if (checkSpaces === '' || checkSpaces === null || checkSpaces === ' ') {
                        generalValidation.general = "Empty Data is not accepted";
                        this.setState({ formErrors: generalValidation });
                    } else {
                        generalValidation.general = '';
                        this.setState({ formErrors: generalValidation });
                    }

                    if ((this.state.imageUrl === '' || this.state.imageUrl === null || this.state.imageUrl === undefined)
                        || (this.state.song_name === '' || this.state.song_name === null || this.state.song_name === ' ')
                        || this.state.description_in_english > 255
                        // || (this.state.song_name_in_english === '' || this.state.song_name_in_english === null || this.state.song_name_in_english === ' ')
                        // || (this.state.videoWithMusic == undefined || this.state.videoWithMusic == '')
                        // || (this.state.videoWithoutMusic == undefined || this.state.videoWithoutMusic == '')
                        // || (this.state.videoWithMusic_duration == 0 || this.state.videoWithMusic_duration == undefined)
                        // || (this.state.videoWithoutMusic_duration == 0 || this.state.videoWithoutMusic_duration == undefined)
                    ) {
                        {
                            generalValidation.general ="Empty Data is not accepted";
                            this.setState({ formErrors: generalValidation });
                        }
                    }

                }, 0);
        }
    }

    handlePaymentAva = (e, { value }) => {

        

        this.setState({
            isFree: value
        })

    }

    handleSubValueChange = (e, { value }) => {
        this.setState({ subValueId: value });
    };

    handleSubmit = (e) => {
        
        

        if (e.nativeEvent.submitter.id === "nextButton") {

            
            const { id, song_name, description, imageUrl,
                publishDate, videoWithMusic,
                videoWithoutMusic, activityId,
                videoWithMusic_duration,
                videoWithoutMusic_duration,
                isFree, voice,
                subValueId,
                selected_songParent_id,
                song_name_in_english,
                description_in_english,
                video_key_song_video_with_music,
            video_key_song_video_without_music, featured,contentVendorSelectedId } = this.state;

            

            this.updateSong(id, song_name, description, imageUrl,
                publishDate, videoWithMusic,
                videoWithoutMusic, activityId,
                videoWithMusic_duration,
                videoWithoutMusic_duration, isFree, voice,
                subValueId, selected_songParent_id,
                song_name_in_english,description_in_english,
                video_key_song_video_with_music,
            video_key_song_video_without_music, featured,contentVendorSelectedId);
        }

        /// calling save
        e.preventDefault();
    }
    
   handleContentVendorSelection = (e, { value }) => {
        //console.log("contentVendor selected: " + value);
        this.setState({ contentVendorSelectedId: value })
    }

    handleSongParentChange = (e, { value }) => {
        
        

        this.setState({ selected_songParent_id: value[0].id })
        this.setState({ selected_songParent_name: value[1].name })
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };
    
    render() {
        const { allSubValues } = this.props;
        const subValuesList = [];
        for (let i = 0; i < allSubValues.length; i++) {
            subValuesList.push({
                key: allSubValues[i].id,
                text: allSubValues[i].contentValue,
                value: allSubValues[i].id
            });
        }

// --------------------------------
    const { ContentVendorsList } = this.props;
    const ContentVendorsArr = [];
    
    //console.log('ContentVendorsList : '+JSON.stringify(ContentVendorsList,null,2));
    
    for (let i = 0; i < ContentVendorsList.length; i++) 
    {
      ContentVendorsArr.push({
        key: ContentVendorsList[i].id,
        text: ContentVendorsList[i].user.name,
        value: ContentVendorsList[i].id,
      });
    }
// --------------------------------

        // for SongParent dropdown 
        const { songParent } = this.props;

        const songParentList = [];
        for (let i = 0; i < songParent.length; i++) {
            songParentList.push({
                key: songParent[i].id,
                text: songParent[i].name,
                value: [{ id: songParent[i].id }, { name: songParent[i].name }]
            })
        }


        const { song_updated_successfully,
            error_editing_song } = this.props
        const { loading } = this.props;



        // loading button
        let $buttonSubmitLoader = (
            <Button disabled={this.state.formErrors.general}
                type="submit" id="nextButton" >
                Save
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="nextButton" class="ui loading button">Loading</button>);
        }

        if (song_updated_successfully === true) {
            alert("song updated successfully");
            this.props.emptySong();

            const { redirectionPage } = this.props;

            this.props.getSongParentData(redirectionPage.series_Id);

            var pageName = redirectionPage.pageName
            return (<Redirect to={pageName} ></Redirect>)
        }

        else if (song_updated_successfully === false) {
            alert(error_editing_song);
            this.props.emptySong();
        }

        const { song_name, imageUrl,
            publishDate, videoWithMusic,
            videoWithoutMusic,
            videoWithMusic_duration,
            videoWithoutMusic_duration,
            isFree, song_name_in_english } = this.state;

        const isInvalid =
            song_name === '' || song_name == undefined ||
            imageUrl === '' || imageUrl == undefined ||
            publishDate === '' || publishDate == undefined ||
            isFree === '' || isFree == undefined ||
            videoWithMusic === '' || videoWithMusic == undefined ||
            videoWithoutMusic === '' || videoWithoutMusic == undefined ||
            videoWithMusic_duration === '' || videoWithMusic_duration == undefined ||
            videoWithMusic_duration === 0 ||
            videoWithoutMusic_duration === '' || videoWithoutMusic_duration == undefined ||
            videoWithoutMusic_duration === 0 ;




        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <div className="container">
                                                    <h2 className="label">Edit Song</h2>
                                                    <br />
                                                    <form onSubmit={this.handleSubmit}>
                                                        <p style={{ color: "red" }}>{this.state.formErrors.general} </p>
                                                        <div className="input-field">
                                                            <label htmlFor="song_name" className="label">
                                                            <span className="required_input">*</span>
                                                                Song Name
                                                            </label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="song_name"
                                                                fluid id="song_name"
                                                                onChange={this.handleChange}
                                                                value={this.state.song_name}
                                                                required />
                                                        </div>
                                                        <br />

                                                        <div className="input-field">
                                                            <label htmlFor="song_name_in_english" className="label">Song Name In English</label><br />
                                                            <input
                                                                dir="auto"
                                                                className="form-control"
                                                                type="text" name="song_name_in_english"
                                                                fluid id="song_name_in_english"
                                                                onChange={this.handleChange}
                                                                value={this.state.song_name_in_english}
                                                                 />
                                                        </div>
                                                        <br />

                                                        <div className="form-group">
                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Song Description
                                                            </label><br />
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="description"
                                                                fluid onChange={this.handleChange}
                                                                value={this.state.description} />
                                                        </div>
                                                        <br />

                                                        <div className="form-group">
                                                            <label className="label"
                                                                for="exampleFormControlTextarea1">
                                                                Song Description In English
                                                            </label><br />
                                                            <textarea dir="auto"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                rows="3" name="description_in_english"
                                                                fluid onChange={this.handleChange}
                                                                value={this.state.description_in_english} />
                                                        </div>
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">Select the Song Parent
                                                          <br />
                                                            <strong>Parent is : {this.state.selected_songParent_name}</strong>
                                                        </label>
                                                        <br />
                                                        {/* <Dropdown fluid search
                                                            selection
                                                            placeholder='Select Song Parent'
                                                            name="episode_series_id"
                                                            onChange={this.handleSongParentChange}
                                                            options={songParentList} /> */}
                                                        <br />


                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            <span className="required_input">*</span>
                                                            Select payment availability</label><br />

                                                        <Dropdown fluid selection
                                                            placeholder='Select Payment Availability'
                                                            id="episode_series_id"
                                                            onChange={this.handlePaymentAva}
                                                            options={is_free_type}
                                                            value={this.state.isFree}
                                                        />
                                                        <br />

                                                        <label htmlFor="episode_series_id"
                                                            className="label">
                                                            Sub Value</label><br />

                                                        <Dropdown fluid search selection
                                                            placeholder='Select Sub Value'
                                                            id="episode_series_id"
                                                            onChange={this.handleSubValueChange}
                                                            options={subValuesList}
                                                            value={this.state.subValueId}
                                                        />
                                                        <br />

                                                        {/* DataComponent */}
                                                        <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                            date={this.state.publishDate}
                                                            showNow={!isInvalid} />

                                                        <br />
                                                            <label>
                                                                <input
                                                                type="checkbox"
                                                                checked={this.state.featured}
                                                                onChange={this.handleCheckboxChange}
                                                                />
                                                                {' Featured'}
                                                            </label>
                                                        <br />

<label
                                htmlFor="episode_ContentVendor_id"
                                className="label"
                              >
                                
                                Select Content Vendor
                              </label>
                              <br />

                              <Dropdown                                
                                fluid
                                selection
                                placeholder="Select Content Vendor"
                                id="episode_ContentVendor_id"
                                onChange={this.handleContentVendorSelection}
                                options={ContentVendorsArr}
                                value={this.state.contentVendorSelectedId}
                              />
                              <br />

                                                        <label htmlFor="name" className="label">
                                                            Audio
                                                        </label>

                                                        <UploadAudio
                                                            getAudioUrlCallBack={this.getAudio.bind(this)}

                                                            audioPreview={this.state.voice}
                                                            audioUrl={this.state.voice}
                                                            s3DirectryrName="song/audio" />


                                                        <label htmlFor="song_Image" className="label">Song Image</label><br />

                                                        <div className="row">

                                                            <div className=" col-4">
                                                                <UploadImageWithoutDropZone
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.imageUrl}
                                                                    imagepreview={this.state.imageUrl}
                                                                    s3DirectryrName='song' />

                                                            </div>
                                                        </div>

                                                        <br />

{}
                                                        <label className="label">Video with music</label>
                                                        
 <div>
                            <RenderVideoWithMusic
                                  videoKey={this.state.video_key_song_video_with_music}
                                  id="VideoWithMusic"
                                  foldername={this.state.video_with_music_folder_id}
                                  changedid={this.handleChangeVideoWithMusic}
                                  enableEdit={true}
                                  ></RenderVideoWithMusic>
                            </div>
                       
                           
                            <br />
                            <label className="label">Video without music</label>
                             <div>
                             <RenderVideoWithoutMusic
                                   videoKey={this.state.video_key_song_video_without_music}
                                   id="VideoWithoutMusic"
                                   foldername={this.state.video_with_out_music_folder_id}
                                   changedid={this.handleChangeVideoWithoutMusic}
                                   enableEdit={true}
                                   ></RenderVideoWithoutMusic>
                             </div>
                        
                           
                            <br />

                                                        {/* <label htmlFor="videoWithMusic" className="label">Video with music</label><br />

                                                        <div className="imgPreview">
                                                            <UploadVideoWithoutDropZone
                                                                getVideoURLCallBack={this.getVideoWithMusicUrl.bind(this)}
                                                                videoUrl={this.state.videoWithMusic}
                                                                videoPreview={this.state.videoWithMusic}
                                                                s3DirectryrName='song' />
                                                        </div>
                                                        <br />

                                                        <br />
                                                        <label htmlFor="videoWithMusic_duration"
                                                            className="label">Video with music duration in seconds
                                                        </label>
                                                        <br />
                                                        <Input fluid disabled
                                                            type="number" min="0" step="0.01"
                                                            name="videoWithMusic_duration"
                                                            id="videoWithMusic_duration"
                                                            onChange={this.handleChange}
                                                            value={this.state.videoWithMusic_duration}
                                                            required />

                                                        <br /><br /> <br />

                                                        <div className="input-field">
                                                            <label htmlFor="videoWithoutMusic"
                                                                className="label">video without music</label><br />
                                                            <div className="imgPreview">
                                                                <UploadVideoWithoutDropZone
                                                                    getVideoURLCallBack={this.getVideoWithoutMusicUrl.bind(this)}
                                                                    videoPreview={this.state.videoWithoutMusic}
                                                                    videoUrl={this.state.videoWithoutMusic}
                                                                    s3DirectryrName='song' />
                                                            </div>
                                                            <br />
                                                        </div>
                                                        <br />
                                                        <label htmlFor="videoWithoutMusic_duration"
                                                            className="label">
                                                            Video without music duration in seconds
                                                        </label><br />
                                                        <Input fluid disabled
                                                            type="number" min="0" step="0.01"
                                                            name="videoWithoutMusic_duration"
                                                            id="videoWithoutMusic_duration"
                                                            onChange={this.handleChange}
                                                            value={this.state.videoWithoutMusic_duration}
                                                            required />


                                                        <br /> <br /> <br /> */}

                                                        {$buttonSubmitLoader}

                                                        <br /> <br /> <br />
                                                    </form>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({
    loading: state.songs.loading,

    song: state.songs.item,
    songParent : state.songParent.item,
    activity: state.songs.item.activityId,
    auth: state.firebase.auth,
    song_updated_successfully: state.songs.song_updated_successfully,
    error_editing_song: state.songs.error_editing_song,
    allSubValues: state.ContentSubValue.items,
    redirectionPage: state.redirectionPage.item,
    ContentVendorsList : state.contentVendor.State_items,


});

export default connect(
    mapStateToProps,
    {
        getSongData,
        getSongParentData,
        emptySong,
        updateSong,
        fetchAllSubValues,
        fetchAllSongParent,
        getSongParentData,fetchAllContentVendors
    })(EditSong);
