import {
    LOAD_TPAY_VERIFYING_CODE,
    TPAY_VERIFYING_CODE_SUCCESS,
    TPAY_VERIFYING_CODE_ERROR,

    LOAD_TPAY_RESEND_CODE,
    TPAY_RESEND_CODE_SUCCESS,
    TPAY_RESEND_CODE_ERROR,

    LOAD_TPAY_DIGEST_CODE,
    TPAY_DIGEST_CODE_SUCCESS,
    TPAY_DIGEST_CODE_ERROR,

    EMPTY_TPAY

} from '../Actions/Types'

const initialState = {
    item: {},
    loading: '',

    verifying_successfully: '',
    verifying_error: '',

    resend_code_error: '',
    resend_code_successfully: '',
    resendLoading:'',

    digest: '',
    get_digest_error: '',
    get_digest_successfully: '',
    get_digest_loading: '',

}

export default function (state = initialState, action) {
    switch (action.type) {


        case LOAD_TPAY_VERIFYING_CODE:
            return {
                ...state,
                loading: true
            }

        case TPAY_VERIFYING_CODE_SUCCESS:
            return {
                ...state,
                item: action.payload,
                verifying_successfully: true,
                verifying_error: '',
                loading: false

            }
        case TPAY_VERIFYING_CODE_ERROR:
            return {
                ...state,
                verifying_successfully: false,
                verifying_error: action.payload,
                loading: false
            }

        case LOAD_TPAY_RESEND_CODE:
            return {
                ...state,
                resendLoading: true
            }

        case TPAY_RESEND_CODE_SUCCESS:
            return {
                ...state,
                item: action.payload,
                resend_code_successfully: true,
                resend_code_error: '',
                resendLoading: false

            }
        case TPAY_RESEND_CODE_ERROR:
            return {
                ...state,
                resend_code_successfully: false,
                resend_code_error: action.payload,
                resendLoading: false
            }

        case LOAD_TPAY_DIGEST_CODE:
            return {
                ...state,
                get_digest_loading: true,
            }
        
        case TPAY_DIGEST_CODE_SUCCESS:
            return {
                ...state,
                digest: action.payload,
                get_digest_successfully: true,
                get_digest_loading: false,
                get_digest_error: '',
            }
        
        case TPAY_DIGEST_CODE_ERROR:
            return {
                ...state,
                get_digest_error: action.payload,
                get_digest_successfully: false,
                get_digest_loading: false,
            }

        case EMPTY_TPAY:
            return {
                ...state,
                verifying_error: '',
                verifying_successfully: '',
                resend_code_error: '',
                resend_code_successfully: '',
                item: '',
                loading: false,
                resendLoading:false,
                digest: ''

            }

        default:
            return state
    }
}