import {
    LOAD_VENDOR_REPORT,
    FETCH_ALL_VENDOR_REPORT, FETCH_ALL_VENDOR_REPORT_ERROR,
    EMPTY_VENDOR_REPORT
    
} from './Types'
import { post } from 'axios';

export const fetchVendorsReport = () => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_VENDOR_REPORT,
                payload: 'loading'
            })
            const url = "/VendorReport/getAllVendorsReport"
            const formData = new FormData();
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: FETCH_ALL_VENDOR_REPORT,
                    payload: res.data
                })

            }).catch((Error) => {
                 dispatch({
                type: FETCH_ALL_VENDOR_REPORT_ERROR,
                payload: Error.message
            })
            })

        })

    }
}


export const emptyVendorReport = () => dispatch => {
    console.log("emptyVendorReport")
    dispatch({
        type: EMPTY_VENDOR_REPORT,
        payload: ""
    })
}

