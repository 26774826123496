import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { withFirebase } from '../../config'

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/*Components */
import CustomerTable from './CustomerTable'
import SendCustomerEmail from './SendCustomerEmail'
import SendCustomerNotification from './SendCustomerNotification'
/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import { ADMIN_LAND_PAGE } from '../../routes';
class Customer extends Component {

    state = {
        checkedItems: new Map()
    };

    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    getCheckedCustomers(checkedItems) {

        if (checkedItems) {
            this.setState({ checkedItems: checkedItems });
        }
    }

    render() {
      let trashIcon = '', addCustomerIcon = '', uploadIcon = '', blockIcon = '', sendMailIcon= '', sendNotificationIcon = '';
      const {userPaymentProfile } = this.props;
      if(userPaymentProfile.user_role.role !== "Analyst"){
        // trashIcon = (
        //     <button type="submit"
        //         className="customer_icons" >
        //         <Icon name='trash' />
        //     </button>
        // );
        // addCustomerIcon = (
        //   <Link to="/"><button type="submit" id="nextButton"
        //       className="customer_buttons">
        //       + Add Customer
        //       </button></Link>
        // );
        // uploadIcon = (
        //   <button type="submit"
        //       className="customer_buttons" id="nextButton">
        //       <Icon name='up arrow' />
        //   </button>
        // );
        // blockIcon = (
        //   <button type="submit"
        //       className="customer_icons" >
        //       <Icon name="ban" />
        //   </button>
        // );
        sendMailIcon = (
          <SendCustomerEmail
              checkedItems={this.state.checkedItems} />
        );

        sendNotificationIcon = (
          <SendCustomerNotification
              checkedItems={this.state.checkedItems} />
        );
      }
        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">
                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">

                                        <div className="col-12 left-main-body"
                                            id="left-main-body">
                                            <div className="box-container">


                                                {/* <!--  start status bar --> */}
                                                <div className="statusbar small-font">
                                                    <span className="status-link">
                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span> &gt; </span>
                                                    </span>
                                                    <span>Customers</span>

                                                </div>
                                                {/* <!--  end status bar --> */}

                                                <div className="col-12 left-main-body" id="left-main-body">
                                                    {/* {addCustomerIcon} */}
                                                    {/* {uploadIcon} */}

                                                   {/* <button type="submit"
                                                        className="customer_buttons"  >
                                                        <Icon name='down arrow' />Download
                                                    </button> */}

                                                </div>

                                                <h5 className="main-title mt-2 font-weight-bold">Customers</h5>

                                                <div className="mt-4 responsive-table">

                                                    <br />
                                                    <div className="col-12 left-main-body" id="left-main-body">
                                                        {/* {trashIcon} */}
                                                        {/* {blockIcon} */}
                                                        {/* <button type="submit"
                                                            className="customer_icons" >
                                                            <Icon name='down arrow' />
                                                        </button> */}

                                                      {sendMailIcon}

                                                      {sendNotificationIcon}

                                                    </div>
                                                    <div className="card mt-3 p-0">
                                                        <div className="card-body p-4">

                                                            {/* Customer Table */}
                                                            {/* <CustomerTable/> */}
                                                            <CustomerTable
                                                                getListOfCheckedCustomersCallBack={this.getCheckedCustomers.bind(this)} />

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(Customer)
);
