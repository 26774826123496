import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Label } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom'

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';

/*Redux */
import firebase from 'firebase/app'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*component */
import Loader from '../Layout/Loader';
import PromoCodesTable from './PromoCodesTable';
/* Routes */
import { ADMIN_LAND_PAGE, ADD_PROMO_CODE } from '../../routes';

class PromoCodes extends Component {
  constructor(props) {
      super(props);

  }

  render() {
    let addNewPromoCodeButton = (
      <div className="col-12 left-main-body" id="left-main-body">
          <Link to={ADD_PROMO_CODE}><button type="submit"
              className="seriesButtons">
              + Add New Promo Code
              </button></Link>
      </div>
    )

      return (
          <div className="container">
              <OuterHeader />
              <div className="container">
                  <div className="container-body">
                      <div className="row">
                          <Dashboard />
                          <div className="col-9">
                              <InnerHeader />
                              <div className="main-content">
                                  <div className="row">
                                      <div className="col-12 left-main-body" id="left-main-body">
                                          <div className="box-container">

                                              {/* <!--  start status bar --> */}
                                              <div className="statusbar small-font">
                                                  <span className="status-link">
                                                      <Link to={ADMIN_LAND_PAGE}>Home</Link><span> > </span>
                                                  </span>
                                                  <span>Promo Codes</span>

                                              </div>
                                              {/* <!--  end status bar --> */}
                                                {addNewPromoCodeButton}
                                              <h5 className="main-title mt-2 font-weight-bold">
                                                  Promo Codes</h5>
                                              <div className="card mt-3 p-0">
                                                  <div className="card-body p-4">
                                                      {/* <!-- start form --> */}
                                                      <form className="alluserMng" onSubmit={(e)=> e.preventDefault()}>
                                                          <div className="mt-4 responsive-table">
                                                            <PromoCodesTable/>
                                                          </div>
                                                      </form>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <Footer />
          </div>
      )
  }
}

const mapStateToProps = state => ({
});
export default withRouter
    (connect
        (mapStateToProps,{})
        (PromoCodes)
    );
