import {
  SAVE_PAYMENT_METHODS,
  SAVE_PRICE_SUBSCRIBE_CARD,
  SAVE_DISCOUNT_SUBSCRIBE_CARD,
  SAVE_DISCRIPTION_SUBSCRIBE_CARD,
  SAVE_EXPIRATIONDATE_SUBSCRIBE_CARD,
  SAVE_SLUG_SUBSCRIBE_CARD,
  CLEAR_EXCHANGE_DATA,
  CLEAR_PAYMENT_DATA,
  LOAD_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
  ERORR_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
  GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE,
  CHANGE_PAYMENT_METHOD_TYPE,
} from "../Actions/Types"; 

const initialState = {
  paymentMethod: "",
  price: "",
  discount: "",
  expirationDate: "",
  discription: "",
  slug: "",
  package_and_methods:{},
  error: null,
  loading: false,
  paymentMethodType: "other",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE:
        return{
            ...state,
            loading: true,
        }
    case GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE:
        let data = action.payload;
        return{
            ...state,
            package_and_methods: action.payload,
            price: data.paymentSpecialCountriesOffer ? data.paymentSpecialCountriesOffer.yearOriginalPriceConverted.convertedPrice : '',
            discount: data.paymentSpecialCountriesOffer? data.paymentSpecialCountriesOffer.yearDiscountPriceConverted.convertedPrice : '',
            expirationDate: data.paymentSpecialCountriesOffer? data.paymentSpecialCountriesOffer.yearOriginalPriceConverted.expirationDate : '',
            discription: data.paymentSpecialCountriesOffer? data.paymentSpecialCountriesOffer.description : '',
            slug:"Yearly",
            loading: false,
            
        }
    case ERORR_GET_ALL_PAYMENT_METHODS_AND_PACKAGE_BY_COUNTRYCODE:
        return{
            ...state,
            error: action.payload,
        }
    case SAVE_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case SAVE_PRICE_SUBSCRIBE_CARD:
      return {
        ...state,
        price: action.payload,
      };
    case SAVE_DISCOUNT_SUBSCRIBE_CARD:
      return {
        ...state,
        discount: action.payload,
      };
    case SAVE_DISCRIPTION_SUBSCRIBE_CARD:
      return {
        ...state,
        discription: action.payload,
      };
    case SAVE_EXPIRATIONDATE_SUBSCRIBE_CARD:
      return {
        ...state,
        expirationDate: action.payload,
      };
    case SAVE_SLUG_SUBSCRIBE_CARD:
      return {
        ...state,
        slug: action.payload,
      };
    case CLEAR_PAYMENT_DATA:
      return {
        ...state,
        paymentMethod: "",
        price: "",
        discount: "",
        expirationDate: "",
        discription: "",
        slug: "",
      };
      case CHANGE_PAYMENT_METHOD_TYPE:
        return{
          ...state,
          paymentMethodType: action.payload,
        }
    default:
      return state;
      
  }
}
