import {
    ADD_QUESTIONS, GET_ACTIVITY_QUESTIONS, ASSIGN_CORRECT_ANSWER_TO_QUESTION,
    PUBLISH_QUESTION, CLEAR_QUESTIONS,

    ERROR_DELETING_QUESTION, DELETING_QUESTION,
    LOAD_QUESTION, EMPTY_QUESTION, DELETE_QUESTION_FROM_REDUX
} from './Types'

import store from '../Store';
import axios, { post } from 'axios'

export const publishQuestion = (questionType, questionCategoryID, questionText, choices, correctAnswerId, activityId) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken((token) => {
            axios({
                method: 'post',
                url: '/Questions/addQuestion',
                headers: {
                    'X-Authorization-Firebase': token,
                    'Content-Type': 'application/json'
                },
                data: {

                    "text": questionText,
                    "questionType": questionType,
                    "activityId": {
                        "id": activityId

                    },
                    "choices": choices,
                    "questionCategoryId": questionCategoryID
                }
            }).then((res) => {
                assignCorrrectAnswerToQuestion(res.data.choices[correctAnswerId].id, res.data.id)


                dispatch({
                    type: PUBLISH_QUESTION,
                    payload: res.data
                })
            })

        })

    }
}

export const addQuestions = (questions) => dispatch => {
    dispatch({
        type: ADD_QUESTIONS,
        payload: questions
    })
}


export const getActitvityQuestions = () => dispatch => {
    axios.get('/Activity/getActivityDetails?Activity_ID=' + store.getState().activities.item.id + '').then(res => {
        console.log(res.data.questions)
        dispatch({
            type: GET_ACTIVITY_QUESTIONS,
            payload: res.data.questions
        })

    })

}


export const assignCorrrectAnswerToQuestion = (correctAnswerId, questionId) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase()
        firebase.auth().currentUser.getIdToken((token) => {
            const url = "/Questions/assignCorrectChoiceToQuestion"
            const formdata = new FormData();
            formdata.append('Question_ID', questionId);
            formdata.append('CorrectChoice_ID', correctAnswerId);
            const config = {
                headers: {

                    'X-Authorization-Firebase': token
                }

            }
            post(url, formdata, config).then((res) => {
                dispatch({
                    type: ASSIGN_CORRECT_ANSWER_TO_QUESTION,
                    payload: res.data
                })
            })
        })

    }
}

export const clearQuestions = () => dispatch => {
    dispatch({
        type: CLEAR_QUESTIONS,
        payload: ''
    })
}

export const emptyQuestions = () => dispatch => {
    dispatch({
        type: EMPTY_QUESTION,
        payload: ''
    })
}

export const deleteQuestionfromRedux = (questions) => dispatch => {
    dispatch({
        type: DELETE_QUESTION_FROM_REDUX,
        payload: questions
    })
}
