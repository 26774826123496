import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Dropdown } from 'semantic-ui-react';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

/*Action */
import { addSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { tpayRegister, tpayLogin, empty, signOut } from '../../../Actions/AuthActions'
import {
    addSeriesIDtoRedirectionPage,
    emptyExchangeData, redirectionPage
} from '../../../Actions/RedirectionPagesActions'
import { getTelecomPrices } from '../../../Actions/FawryPaymentAction'

/*component */
import mainLogoM from '../../../images/portal/logo-big@2x.png';
import vodafone from '../../../images/tpay/vodafone.png'
import we from '../../../images/tpay/we.jpg'
import orange from '../../../images/tpay/orange.png'
import etisalat from '../../../images/tpay/etisalat.png'

import Footer from '../Layout/Footer';
import Testimonials from '../Layout/Testimonials';
import Loader from '../../../components/Layout/Loader'
import "../../../css/Fit.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* Routes */
import { PORTAL_LOGIN_METHODS } from '../../../routes';


const telecom_company = [{
    key: 0,
    text: 'اورانج - Oragne',
    value: 'JEEL_Orange',
    image: { avatar: true, src: orange },
},
{
    key: 1,
    text: 'فودافون - Vodafone',
    value: 'JEEL_Vodafone',
    image: { avatar: true, src: vodafone },
},
{
    key: 2,
    text: 'وى -We',
    value: 'JEEL_We',
    image: { avatar: true, src: we },
},
{
    key: 3,
    text: 'اتصالات - Etisalat',
    value: 'JEEL_Etisalat',
    image: { avatar: true, src: etisalat },
}]


class TpayLogin extends Component {

    state =
        {
            register: true,
            email: "",

            phone: "",
            telecomCompany: "",
            activeRegisterBtn: 'active',
            activeSignInBtn: '',
            activeItemIndex: 0,
            disabled: true,
            loginDisabled: true,
            errorMsg: '',
            error: false,

            openSnakBar: false,
            vertical: 'bottom',
            horizontal: 'right',

            isRevealPassword: false,
            password: "",

            redirect: false,
            loginSuccess: false
        }

    constructor(props) {
        super(props);

        setTimeout(() => {
            // sign out from Firebase
            console.log("Signout Tpay")

            this.props.signOut();
            this.props.empty();


            // empty redirection page
            this.props.redirectionPage();
            this.props.getTelecomPrices("Egypt", "JEEL_Orange");

            this.passwordOneRef = React.createRef();
            this.iconRevealPassword = React.createRef();
        }, 100);
    }


    handleRegisterForm = () => {
        this.setState({ register: true, activeRegisterBtn: 'active', activeSignInBtn: '' });
    }

    handleSignInForm = () => {
        this.setState({ register: false, activeRegisterBtn: '', activeSignInBtn: 'active' });
    }

    handlePhoneLoginChange = (e) => {
        this.setState({ phone: e });

        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '

            ) {

                this.setState({ loginDisabled: false })
            } else {
                this.setState({ loginDisabled: true })
            }
        }, 0);
    }

    handlePhoneRegisterChange = (e, country) => {
      
        this.setState({
            phone: e,
            county: country.name
        });

        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '


            ) {
                this.setState({ disabled: false })
            } else {
                this.setState({ disabled: true })
            }
        }, 0);

    }

    handleTelecomCompany = (e, { value }) => {
        this.setState({
            telecomCompany: value
        })
    }



    handleRegisterChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '


            ) {
                this.setState({ disabled: false })
            } else {
                this.setState({ disabled: true })
            }
        }, 0);
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            this.setState({ error: false, errorMsg: '' })
            if (this.state.email !== '' && this.state.email !== null
                && this.state.email !== undefined && this.state.email !== ' ' &&

                this.state.password !== '' && this.state.password !== null
                && this.state.password !== undefined && this.state.password !== ' ' &&

                this.state.phone !== '20' &&
                this.state.phone !== '' && this.state.phone !== null
                && this.state.phone !== undefined && this.state.phone !== ' ' &&

                this.state.telecomCompany !== '' && this.state.telecomCompany !== null
                && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '

            ) {


                this.setState({ loginDisabled: false })
            } else {
                this.setState({ loginDisabled: true })
            }
        }, 0);
    }

    handleRegisterSubmit = (e) => {
        const { email, password,
            phone,
            telecomCompany, county } = this.state;

        // call Register API
        this.props.tpayRegister(email, password, phone, telecomCompany, county);

        this.setState({ loginSuccess: true })
        e.preventDefault();
    }

    handleLoginSubmit = (e) => {
        const { email, password, phone, telecomCompany } = this.state;

        // call Login API
        this.props.tpayLogin(email, password, phone, telecomCompany);

        this.setState({ loginSuccess: true })

        e.preventDefault();
    }

    togglePassword = event => {
        this.setState({ isRevealPassword: !this.state.isRevealPassword })
    }

    redirect = event => {

        if (
            this.state.phone !== '20' &&
            this.state.phone !== '' && this.state.phone !== null
            && this.state.phone !== undefined && this.state.phone !== ' ') {

            var path = "TpayLogin";
            this.props.addSeriesIDtoRedirectionPage(path, this.state.phone);
            this.setState({ redirect: true });

        } else {
            this.setState({ errorMsg: "يرجى ادخال رقم الهاتف اولا" });

        }
    }


    render() {

        const { isRevealPassword,
            loginSuccess, redirect, phone } = this.state;

        let loader = null;
        let tpaySubContractCode, tpayTransactionCode;

        const { tpay, loading,
            success, errorResponse,
            packagePrice } = this.props;

        if (redirect === true) {
            return (<Redirect to={PORTAL_LOGIN_METHODS}></Redirect>)
        }

        if (loading === true) {
            loader = (<Loader />)
        }

        if (success === true &&
            loginSuccess === true &&
            tpay !== undefined &&
            tpay !== null) {
            tpaySubContractCode = tpay.subscriptionContractId;
            tpayTransactionCode = tpay.transactionId;

            // Add subscription Api Returns error in json when operationStatusCode !=0 
            if (tpay.operationStatusCode !== 0) {

                let errorMsg = " لقد حدث خطأ  " +tpay.errorMessage

                if (tpay.errorMessage.includes('This user already subscribed to the given product')) {
                    errorMsg = 'هذا الرقم مشترك بالفعل في خدمتنا.'
                } else if (tpay.errorMessage.includes('Please enter valid phone number for the selected mobile operator.')) {
                    errorMsg = 'الرجاء إدخال رقم هاتف صالح لمشغل الشبكة المحددة.'
                } else if (tpay.errorMessage.includes("MSISDN Can't be null")) {
                    errorMsg = 'رقم الموبايل مطلوب ولا يمكن تركه فارغًا. '
                } else if (tpay.errorMessage.includes('mobile number is blacklisted')) {
                    errorMsg = 'عذرًا ، لا يمكن تنشيط رقم الموبايل هذا.'
                } else if (tpay.errorMessage.includes('is duplicate, wait for 2 minutes before issuing same request')) {
                    errorMsg = 'عذرًا ، لم نتمكن من إكمال تسجيلك ، انتظر بضع دقائق وحاول مرة أخرى.'
                } else if (tpay.errorMessage.includes('for same customer was cancelled or suspended previously and cool off period did not pass')) {
                    errorMsg = 'عذرًا ، لا يمكنك الاشتراك ، يرجى إعادة المحاولة بعد يوم واحد.'
                }

                this.setState({ errorMsg:  errorMsg });

                this.props.empty();
                this.props.emptyExchangeData();
            }
            else {
                let message = 'تم تسجيل البيانات بنجاح'
                this.props.addSuccessMessage(message);
                this.props.empty();
                this.props.emptyExchangeData();

                let redirect = "/TpayPhoneCode/" + phone + "/" + tpaySubContractCode + "/" + tpayTransactionCode;
                return (<Redirect path to={redirect}> </Redirect>);

            }
        } 
        // erorr in calling api
        else if (success === false &&
            (errorResponse !== '' && errorResponse !== undefined
                && loginSuccess === true)
        ) {

            this.setState({ errorMsg: " لقد حدث خطأ " + errorResponse });

            console.log(errorResponse)
            this.props.empty();
            this.props.emptyExchangeData();

        }

        let registerForm = '';
        let signinForm = '';
        let phoneSection = "";
        let loginactive = this.state.loginDisabled

        if (this.state.email !== '' && this.state.email !== null
            && this.state.email !== undefined && this.state.email !== ' ' &&

            this.state.password !== '' && this.state.password !== null
            && this.state.password !== undefined && this.state.password !== ' ' &&

            this.state.phone !== '20' &&
            this.state.phone !== '' && this.state.phone !== null
            && this.state.phone !== undefined && this.state.phone !== ' ' &&

            this.state.telecomCompany !== '' && this.state.telecomCompany !== null
            && this.state.telecomCompany !== undefined && this.state.telecomCompany !== ' '
        ) {
            loginactive = false
        }

        let buttonLoginLoader = (
            <div className="custom-form-group w-100 mb-3">
                <button disabled={loginactive}
                    className="btn form-control submit-btn"
                    type="submit" onClick={this.handleLoginSubmit} >
                    تفعيل الأشتراك
                </button>
            </div>
        );

        let buttonSubmitLoader = (
            <div className="custom-form-group">
                <button disabled={this.state.disabled}
                    className="btn form-control submit-btn"
                    type="submit" onClick={this.handleRegisterSubmit} >
                    تفعيل الأشتراك
                </button>
            </div>
        );

        if (this.state.register === true) {

            phoneSection = (
                <div className=" phone-card " >

                    <div className="" >
                        <strong>   رقم الهاتف الذي تود الاشتراك به
                        </strong>
                        <br /><br />
                        <div className="phoneNum" id="right-lift">
                            <PhoneInput
                                country={'eg'}
                                placeholder="رقم الهاتف"
                                name="phone"
                                value={this.state.phone}
                                onChange={this.handlePhoneRegisterChange.bind(this)}
                            />

                        </div>
                        <br />

                        <Dropdown required fluid selection
                            placeholder='شركه الاتصالات التابعه لرقمك'
                            className="phoneNum" id="right-lift"
                            onChange={this.handleTelecomCompany}
                            options={telecom_company}
                            value={this.state.telecomCompany}
                        />

                    </div>
                </div>
            )

            registerForm = (
                <form className="signup-form">

                    <div className="form-group">
                        <input id="font" dir="auto"
                            type="email"
                            className="filed form-control"
                            name="email"
                            placeholder="بريدك الإلكتروني"
                            value={this.state.email}
                            onChange={this.handleRegisterChange} />
                    </div>

                    <div className="form-group">

                        <div className="input-container" style={{ 'position': 'relative' }}>
                            <input id="font"
                                type={isRevealPassword ? "text" : "password"}
                                dir="auto"
                                className="filed form-control"
                                name="password"
                                placeholder="كلمة المرور"
                                value={this.state.password}
                                onChange={this.handleRegisterChange}
                                ref={this.passwordOneRef} />

                            <span onClick={this.togglePassword} ref={this.iconRevealPassword}>
                                <span>
                                    {isRevealPassword ?
                                        <FontAwesomeIcon icon="eye" className="password-eye" /> :
                                        <FontAwesomeIcon icon="eye-slash" className="password-eye" />
                                    }
                                </span>

                            </span>
                        </div>

                    </div>



                    <div className="policies">
                        <div className="text">
                            <p>بتسجيلك فانت توافق على <span className="color-font">
                                شروط الاستخدام و سياسه الخصوصيه

                            </span> </p>

                        </div>
                    </div>
                    {buttonSubmitLoader}

                </form>
            )
        } else {
            phoneSection = (<div className=" phone-card">
                <div className="">
                    <strong>   رقم الهاتف الذي تود الاشتراك به
                    </strong>
                    <br /><br />
                    <div className="phoneNum" id="right-lift">
                        <PhoneInput
                            country={'eg'}

                            id="font"
                            placeholder="رقم الهاتف"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handlePhoneLoginChange} />
                    </div>

                    <br />
                    <Dropdown required fluid selection
                        placeholder='شركه الاتصالات التابعه لرقمك'
                        className="phoneNum" id="right-lift"
                        onChange={this.handleTelecomCompany}
                        options={telecom_company}
                        value={this.state.telecomCompany}
                    />
                </div>
            </div>)

            signinForm = (
                <form className="signin-form">


                    <div className="form-group">
                        <input id="font"
                            type="email"
                            className=" filed form-control"
                            name="email"
                            placeholder="بريدك الإلكتروني"
                            dir="auto"
                            value={this.state.email}
                            onChange={this.handleChange} />
                    </div>

                    <div className="form-group">

                        <div className="input-container" style={{ 'position': 'relative' }}>
                            <input id="font"
                                type={isRevealPassword ? "text" : "password"}
                                dir="auto"
                                className="filed form-control"
                                name="password"
                                placeholder="كلمة المرور"
                                value={this.state.password}
                                onChange={this.handleChange}
                                ref={this.passwordOneRef} />

                            <span onClick={this.togglePassword} ref={this.iconRevealPassword}>
                                <span>
                                    {isRevealPassword ?
                                        <FontAwesomeIcon icon="eye" className="password-eye" /> :
                                        <FontAwesomeIcon icon="eye-slash" className="password-eye" />
                                    }
                                </span>

                            </span>
                        </div>

                    </div>

                    {buttonLoginLoader}

                    <div className="another-ways">
                        <div className="text">
                            <p>هل سجَّلت عبر وسيلة أخرى؟</p>
                            <div className="sign-way">
                                <a onClick={this.redirect}>تسجيل الدخول عبر وسيلة أخرى.</a>
                            </div>
                        </div>
                    </div>
                </form>
            )
        }
        return (

            <body>


                <section className="jeel-fit">
                    <div className="logo-container">
                        <div className="container">
                            <div className="logo">
                                <img src={mainLogoM} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-6">
                                <div className="center fit-about-text">
                                    <div className="title h2 white ">
                                        <center>
                                            أدخل رقم هاتفك واشترك فورًا في تطبيق جيل!

                                            <br />
                                            <span className=" h5">
                                                قيمة الاشتراك الشهري هي ({packagePrice.monthDiscountPrice}) جنيهًا مصريًا، تجدد تلقائيًا كل شهر.
                                            </span>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-6">
                                <div className="  main-progress-bar">
                                    <ol className="progress-tracker">
                                        <li className="step fit">
                                            <span className=" step-name small-font">
                                                <span className="bold">
                                                    الاشتراك
                                                </span>
                                            </span>
                                        </li>
                                        <li className="step fit">
                                            <span className="step-name small-font">
                                                <span className="notbold">
                                                    رمز التحقق
                                                </span>
                                            </span>
                                        </li>
                                        <li className="step fit">
                                            <span className="step-name small-font">
                                                <span className="notbold">
                                                    تحميل التطبيق
                                                </span>
                                            </span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </section>

                <section className="phone-section" >
                    <div className="row justify-content-md-center ">
                        <div className=" col-12 col-md-6 ">
                            <div className="form-container">

                                {phoneSection}
                            </div>
                        </div>
                    </div>
                </section>

                <br /><br />
                {loader}
                <section className="buttons">
                    <div className="container">
                        <div className="row justify-content-md-center">

                            <div className=" col-12 col-md-6 " id="fit_space" >
                                <p className="h5 text-rtl">

                                    لتفعيل اشتراكك في باقة شركات الاتصالات ،

                                    <strong>
                                        سجل الدخول بحسابك على تطبيق جيل
                                    </strong>

                                </p>
                                <br />
                                <div className="mx-auto text-rtl row">
                                    <p style={{ color: 'red', textAlign: 'right' }}> {this.state.errorMsg}</p>
                                </div>
                            </div>

                            <br /><br />
                        </div>

                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-6">
                                <div className="form-container">
                                    <div className="form-header btn-container">

                                        <button data-toggle-form=".signup-form"
                                            className={"btn toggle-btn signin-btn " +
                                                this.state.activeRegisterBtn}
                                            onClick={this.handleRegisterForm}>
                                            انشاء حساب جديد
                                        </button>

                                        <button data-toggle-form=".signin-form"
                                            className={"btn toggle-btn signup-btn "
                                                + this.state.activeSignInBtn}
                                            onClick={this.handleSignInForm}>
                                            تسجيل دخول</button>


                                    </div>
                                    {registerForm}
                                    {signinForm}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Testimonials />
                {/** Start Footer **/}
                <Footer />
                {/** End Footer */}
            </body>
        )
    }
}

const mapStateToProps = state => ({
    success: state.authUser.success,
    errorResponse: state.authUser.errorMsg,
    loading: state.authUser.loading,
    tpay: state.authUser.tpayData,
    successMessage: state.successMessages.item,
    exchangeData: state.redirectionPage.item,

    token: state.user.token,
    packagePrice: state.fawryPaymentPrices.item,

});

export default withRouter
    (connect
        (mapStateToProps,
            {
                tpayRegister,
                empty,
                tpayLogin,
                addSuccessMessage,
                addSeriesIDtoRedirectionPage,
                emptyExchangeData,
                redirectionPage,
                signOut,
                getTelecomPrices
            })
        (TpayLogin)
    );
