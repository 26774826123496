import {
    FETCH_All_BOOK_PARENT, GET_BOOK_PARENT_DATA, LOAD_BOOK_PARENTES, ERROR_LOADING_BOOK_PARENTES,
    ERROR_LOADING_BOOK_PARENT, LOAD_BOOK_PARENT, SAVE_BOOK_PARENT, ERROR_ADDING_BOOK_PARENT,
    ERROR_SETTING_STATE_BOOK_PARENT, SETTING_STATE_BOOK_PARENT,
    ERROR_DELETING_BOOK_PARENT, DELETING_BOOK_PARENT,
    ERROR_UPDATE_BOOK_PARENT, UPDATE_BOOK_PARENT, EMPTY_BOOK_PARENT, EMPTY_BOOK_PARENT_WITH_DATA,
    ADD_BOOK_PARENT_WITH_SENTFROM
}
    from './Types'

import axios, { post } from 'axios'

export const addBookParentItemWithSentFrom = (item, sentFrom) => dispatch => {

    const bookParent = {
        item: item,
        sentFrom: sentFrom
    }
    dispatch({
        type: ADD_BOOK_PARENT_WITH_SENTFROM,
        payload: bookParent
    })
}


export const fetchAllBookParent = () => dispatch => {

    dispatch({
        type: LOAD_BOOK_PARENTES,
        payload: 'loading'
    })

    axios.get('/BookParent/getAllBookParentList')
        .then((res) => {
            dispatch({
                type: FETCH_All_BOOK_PARENT,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_BOOK_PARENTES,
                payload: Error.message
            })
        })
}

export const getBookParentData = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BOOK_PARENT,
                payload: 'loading'
            })
            const url = "/BookParent/getBookParentDetails"
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: GET_BOOK_PARENT_DATA,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_BOOK_PARENT,
                    payload: Error.message
                })
            })


        })

    }
}

export const saveBookParent = (name, description, imgURL, publish_date, audio, promo_with_music, promo_without_music, name_in_english, description_in_english, featured) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {

            dispatch({
                type: LOAD_BOOK_PARENT,
                payload: 'loading'
            })

            const url = '/BookParent/addBookParent';
            const formData = new FormData();
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Status', "SUSPENDED");
            formData.append('Image', imgURL);
            formData.append('Publish_Date', publish_date);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promo_with_music);
            formData.append('Promo_Video_Without_Music', promo_without_music);
            formData.append('Name_In_English', name_in_english);
            formData.append('Description_In_English', description_in_english);
            formData.append('featured', featured);

            const config = {
                headers: {
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SAVE_BOOK_PARENT,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_BOOK_PARENT,
                    payload: Error.message
                })
            })
        })


    }
}

export const setStateBookParent = (id, state) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BOOK_PARENT,
                payload: 'loading'
            })

            const url = "/BookParent/setBookParentStatus";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Status', state);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: SETTING_STATE_BOOK_PARENT,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_STATE_BOOK_PARENT,
                    payload: Error.response.data.message//Error.message
                })
            })
        })

    }
}

export const deleteBookParent = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BOOK_PARENT,
                payload: 'loading'
            })

            const url = "/BookParent/deleteBookParent";
            const formData = new FormData();
            formData.append('ID', id);
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: DELETING_BOOK_PARENT,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_BOOK_PARENT,
                    payload: Error.message
                })
            })
        })

    }
}

export const updateBookParent = (id, name, description, status, imageUrl, publishDate, audio, promo_with_music, promo_without_music, name_in_english, description_in_english, featured) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BOOK_PARENT,
                payload: 'loading'
            })

            const url = "/BookParent/updateBookParent";
            const formData = new FormData();
            formData.append('ID', id);
            formData.append('Name', name);
            formData.append('Description', description);
            formData.append('Image', imageUrl);
            formData.append('Status', "");
            formData.append('Publish_Date', publishDate);
            formData.append('Voice', audio);
            formData.append('Promo_Video_With_Music', promo_with_music);
            formData.append('Promo_Video_Without_Music', promo_without_music);
            formData.append('Description_In_English', description_in_english);
            formData.append('Name_In_English', name_in_english);
            formData.append('featured', featured);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch(
                    {
                        type: UPDATE_BOOK_PARENT,
                        payload: res.data
                    }
                )
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATE_BOOK_PARENT,
                    payload: Error.message
                })
            })
        })

    }
}


export const emptyBookParentWithBooks = () => dispatch => {
    dispatch({
        type: EMPTY_BOOK_PARENT_WITH_DATA,
        payload: ""
    })
}

export const emptyError = () => dispatch => {
    dispatch({
        type: EMPTY_BOOK_PARENT,
        payload: ""
    })
}
