import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Button } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import {
    updateStoryParent,
    fetchAllStoryParent,
    getStoryParentData,
    emptyError
} from '../../Actions/StoryParentAction';

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';

/*Component */
import PublishDate from '../Layout/PublishDate';
import Loader from '../Layout/Loader';
import UploadAudio from '../S3Uploading/UploadAudio';
import UploadImage from '../S3Uploading/UploadImage';
import UploadVideo from '../S3Uploading/UploadVideo';

/*CSS */
import '../../css/AppCss/Series/AddSeries.css';
import { ALL_STORIES_PARENTS, SIGN_IN, VIEW_STORY_PARENT } from '../../routes';
import RenderPromoWithMusic from '../VideoPreview/RenderPromoWithMusic';
import RenderPromoWithoutMusic from '../VideoPreview/RenderPromoWithoutMusic';
import { STORY_FOLDER_NAME_WITH_MUSIC, STORY_FOLDER_NAME_WITH_OUT_MUSIC } from '../../portal/src/constants';


const INITIAL_STATE = {

    sentFrom: '',
    item: '',
    /////////// Story Parent

    id: '',
    name: '',
    desscription: '',
    name_in_english: '',
    description_in_english: '',
    publish_date: '',
    status: '',
    error: '',

    imageUrl: "",
    audio: '',
    promo_with_music: '',
    promo_without_music: '',
    video_key_promo_with_music: "",
    video_key_promo_without_music: "",
    featured: false,
    cancel: false,
    /////////////// validation
    errorMessage: ''

};

class EditStoryParent extends Component {

    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

    }

    componentDidMount() {

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            const { storyParent } = this.props;
            var item = storyParent.item;

            this.setState({
                sentFrom: storyParent.sentFrom,
                item: storyParent.item
            })
            let audio = null;
            if (item.voice === 'undefined') {
                audio = null
            }
            console.log(item);
            this.setState({
                id: item.id,
                name: item.name,
                description: item.description,
                imageUrl: item.image,
                status: item.status,
                audio: audio,
                publish_date: item.publishDate,
                name_in_english: item.nameInEnglish,
                description_in_english: item.descriptionInEnglish,
                featured: item.featured
            });


            if (item.promoWithMusicId !== null &&
                item.promoWithMusicId !== undefined) {
                this.setState({
                    promo_with_music: item.promoWithMusicId.url,
                    video_key_promo_with_music: item.promoWithMusicId.vdocipherId,
                })
            }


            if (item.promoWithoutMusicId !== null &&
                item.promoWithoutMusicId !== undefined) {
                this.setState({
                    promo_without_music: item.promoWithoutMusicId.url,
                    video_key_promo_without_music: item.promoWithoutMusicId.vdocipherId,
                })
            }

            // if (item.nameInEnglish !== null && item.nameInEnglish !== undefined ) {
            //     this.setState({ errorMessage: "" });
            // }else {
            // this.setState({ errorMessage: "Empty Data is not accepted" });
            // }

        }
    }

    updateStoryParent(id, name, description, status, imageUrl, publishDate, audio,
        promo_with_music, promo_without_music, name_in_english, description_in_english,
        video_key_promo_with_music,
        video_key_promo_without_music, featured) {
        this.props.updateStoryParent(id, name, description, status, imageUrl, publishDate, audio,
            promo_with_music, promo_without_music, name_in_english, description_in_english,
            video_key_promo_with_music,
            video_key_promo_without_music, featured);
    }

    getPublishDate(publishDateTime) {

        if (publishDateTime) {
            this.setState({ publish_date: publishDateTime });
        }
    }

    getAudio(audioUrl) {
        if (audioUrl) {

            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ audio: '' });
                console.log("audio URL in EditStory Parent  if = "
                    + this.state.audio);

            }

            // else send the url to DB
            else {
                this.setState({ audio: audioUrl });
                console.log("audio URL in EditStory Parent else = "
                    + this.state.audio);

            }

        }
    }

    getPromoWithMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ promo_with_music: videoUrl });
            console.log("promo_with_music in series = "
                + this.state.promo_with_music);
        } else {
            this.setState({ promo_with_music: '' });
        }
    }

    getPromoWithoutMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ promo_without_music: videoUrl });
            console.log("promo_without_music in series = "
                + this.state.promo_without_music);
        } else {
            this.setState({ promo_without_music: '' });
        }
    }


    getImageUrl(imageUrl) {
        if (imageUrl === 'delete') {
            this.setState({
                imageUrl: '',
                error: true,
                errorMessage: "Empty Data is not accepted"
            });
        }
        else if (imageUrl !== undefined || imageUrl !== '') {
            this.setState({ imageUrl: imageUrl });
            if (this.state.name !== ''
                && this.state.name !== ' '
                && this.state.name !== null
                // && this.state.name_in_english !== ''
                // && this.state.name_in_english !== ' '
                // && this.state.name_in_english !== null
            ) {
                this.setState({ error: false, errorMessage: "" });
            }
            console.log("img in StoryParent = " + this.state.image);
        }
        else {
            this.setState({ imageUrl: '', error: true, errorMessage: "Empty Data is not accepted" });
        }
    }

    handleChangePromoWithMusic = (uploadInfo) => {
        console.log("Received uploadInfo in parent:", uploadInfo);
        if (
            uploadInfo &&
            uploadInfo !== undefined &&
            uploadInfo !== null &&
            Object.keys(uploadInfo).length > 0
        ) {
            this.setState({ video_key_promo_with_music: uploadInfo.videoId });
        }
        else {
            this.setState({ video_key_promo_with_music: this.state.video_key_promo_with_music });
        }
    };
    handleChangePromoWithoutMusic = (uploadInfo) => {
        console.log("Received uploadInfo in parent:", uploadInfo);
        if (
            uploadInfo &&
            uploadInfo !== undefined &&
            uploadInfo !== null &&
            Object.keys(uploadInfo).length > 0
        ) {
            this.setState({ video_key_promo_without_music: uploadInfo.videoId });
        }

        else {
            this.setState({ video_key_promo_without_music: this.state.video_key_promo_without_music });
        }
    };

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        const specialCharacters = /[@#$%^&*()\+={}[\]:;<>,\/|\\]/; 
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;
        setTimeout(() => {
            if (specialCharacters.test(this.state.name) || specialCharacters.test(this.state.name_in_english)){
                this.setState({
                  error: true,
                  errorMessage: "Story name can't contain spaecial characters",
                });
              }
            else if ((this.state.imageUrl === ''
                || this.state.imageUrl === null
                || this.state.imageUrl === undefined)
                || (this.state.name === ''
                    || this.state.name === null
                    || this.state.name === ' ')
                || this.state.description_in_english.length > 255
                // || (this.state.name_in_english === ''
                //     || this.state.name_in_english === null
                //     || this.state.name_in_english === ' ')
            ) {

                this.setState({
                    error: true, errorMessage: nameOfState === "description_in_english" ?
                        "Description in English is too long (greater than 255 characters)." :
                        "Empty Data is not accepted"
                });
            }
          
            else if ((this.state.imageUrl !== ''
                || this.state.imageUrl !== undefined)
                && (this.state.name !== null
                    || this.state.name !== ''
                    || this.state.name !== ' ')
                && this.state.description_in_english.length <= 255
                // && (this.state.name_in_english !== null
                //         || this.state.name_in_english !== ''
                //         || this.state.name_in_english !== ' ')
            ) {
                this.setState({ errorMessage: "" });
            }
            else {
                this.setState({ errorMessage: "Empty Data is not accepted" });
            }
        }, 0);
    }

    handelCancel = (e) => {
        this.setState({ cancel: true })
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
    };

    handleSubmit = (e) => {

        const { id,
            name,
            description,
            status,
            imageUrl,
            publish_date,
            audio,
            promo_with_music, promo_without_music,
            name_in_english,
            description_in_english,
            video_key_promo_with_music,
            video_key_promo_without_music,
            featured
        } = this.state;

        if (e.nativeEvent.submitter.id === "saveButton") {

            if (imageUrl == null || imageUrl == undefined) {
                this.setState({ errorMessage: "Empty Image is not accepted" });

            }
            else {
                this.updateStoryParent(id, name,
                    description, status, imageUrl,
                    publish_date, audio,
                    promo_with_music, promo_without_music, name_in_english, description_in_english,
                    video_key_promo_with_music,
                    video_key_promo_without_music, featured);
            }
        }
        e.preventDefault();

    }


    render() {
        const { error_update,
            success_update } = this.props;

        const { sentFrom, item } = this.state;

        if (success_update === false) {
            alert("error missing data or network "
                + error_update)
            this.props.emptyError();
        }
        else if (success_update === true
            || this.state.cancel === true) {

            if (success_update === true) {
                alert("Story Parent updated Successfully")
                this.props.emptyError();
            }

            // if the requesst come from All storyParent then
            //  fetch all storyParent to update the new data

            if (sentFrom === "AllStoryParents") {
                this.props.fetchAllStoryParent();
                return (
                    <Redirect to={ALL_STORIES_PARENTS}>
                    </Redirect>
                )

            }

            // if the requesst come from ViewStoryParent then
            //  get Story Parent data to update the suspended Story
            if (sentFrom === "ViewStoryParent") {
                this.props.getStoryParentData(item.id);
                return (<Redirect to={VIEW_STORY_PARENT}></Redirect>)

            }

        }

        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <h3>updating Story Parent</h3>
                                                <hr />


                                                <div className="">
                                                    <form onSubmit={this.handleSubmit} >
                                                        <div className="row">

                                                            {/* image Uploader */}
                                                            <div className="col-md-4 col-12">
                                                                <span className="required_input">*</span>
                                                                <UploadImage
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.imageUrl}
                                                                    imagepreview={this.state.imageUrl}
                                                                    s3DirectryrName='story' />


                                                            </div>

                                                            <div className="col-md-8 col-12">
                                                                <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                                                                <label htmlFor="name" className="label">
                                                                    <span className="required_input">*</span>
                                                                    Name</label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    fluid name="name" id="name" onChange={this.handleChange}
                                                                    value={this.state.name} />
                                                                <br />

                                                                <label htmlFor="name_in_english" className="label">
                                                                    {/* <span className="required_input">*</span> */}
                                                                    Name In English</label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    fluid name="name_in_english" id="name_in_english" onChange={this.handleChange}
                                                                    value={this.state.name_in_english} />
                                                                <br />

                                                                <div className="form-group">
                                                                    <label htmlFor="description" className="label">
                                                                        Description
                                                                    </label>

                                                                    <textarea dir="auto"
                                                                        fluid rows="3"
                                                                        className="form-control"
                                                                        name="description" id="description"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.description}
                                                                    />
                                                                </div>
                                                                <br />
                                                                <div className="form-group">
                                                                    <label htmlFor="description_in_english" className="label">
                                                                        Description In English
                                                                    </label>

                                                                    <textarea dir="auto"
                                                                        fluid rows="3"
                                                                        className="form-control"
                                                                        name="description_in_english" id="description_in_english"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.description_in_english}
                                                                    />
                                                                </div>
                                                                <br />

                                                                <UploadAudio
                                                                    getAudioUrlCallBack={this.getAudio.bind(this)}

                                                                    audioPreview={this.state.audio}
                                                                    audioUrl={this.state.audio}
                                                                    s3DirectryrName="story/audio" />


                                                                {/* DateComponent */}

                                                                {this.state.publish_date !== " " &&
                                                                    this.state.publish_date !== '' &&
                                                                    this.state.publish_date !== null &&
                                                                    this.state.publish_date !== undefined ?
                                                                    <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                        date={this.state.publish_date} />

                                                                    :
                                                                    <PublishDate getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                        value={this.state.publish_date}
                                                                    />}

                                                                <br />
                                                                <label>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={this.state.featured}
                                                                        onChange={this.handleCheckboxChange}
                                                                    />
                                                                    {' Featured'}
                                                                </label>
                                                                <br />

                                                                <br />
                                                            </div>

                                                        </div>
                                                      
                                                            <div>
                                                                <label className="label">Promo with music</label>
                                                                <div>
                                                                    <RenderPromoWithMusic
                                                                        videoKey={this.state.video_key_promo_with_music}
                                                                        id="PromoWithMusic"
                                                                        foldername={STORY_FOLDER_NAME_WITH_MUSIC}
                                                                        changedid={this.handleChangePromoWithMusic}
                                                                    ></RenderPromoWithMusic>
                                                                </div>
                                                                <br />
                                                            </div>
                                                        
                                                     
                                                          
                                                            <div>
                                                                <label className="label">Promo without music</label>
                                                                <div>
                                                                    <RenderPromoWithoutMusic
                                                                        videoKey={this.state.video_key_promo_without_music}
                                                                        id="PromoWithoutMusic"
                                                                        foldername={STORY_FOLDER_NAME_WITH_OUT_MUSIC}
                                                                        changedid={this.handleChangePromoWithoutMusic}
                                                                    ></RenderPromoWithoutMusic>
                                                                </div>
                                                                <br />
                                                            </div>
                                                       






                                                        {/* <label className="label">
                                                            Promo with music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getPromoWithMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.promo_with_music}
                                                                videoUrl={this.state.promo_with_music}
                                                                s3DirectryrName='story'
                                                            />
                                                        </div>
                                                        <br />

                                                        <label className="label">
                                                            Promo without music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getPromoWithoutMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.promo_without_music}
                                                                videoUrl={this.state.promo_without_music}
                                                                s3DirectryrName='story'
                                                            />
                                                        </div>
                                                        <br />
 */}

                                                        <div className="buttons_container">

                                                            <Button disabled={this.state.errorMessage} id="saveButton" >
                                                                Save
                                                            </Button>

                                                            <Button id="episode_cancel"
                                                                onClick={this.handelCancel}>
                                                                Cancel
                                                            </Button>

                                                        </div>
                                                    </form>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}

EditStoryParent.propTypes = {
    getStoryParentData: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    storyParent: state.storyParent.item,
    auth: state.firebase.auth,
    loading: state.storyParent.loading,
    error_update: state.storyParent.updating_story_parent_error,
    success_update: state.storyParent.updating_story_parent_sucessfully
});

export default withFirebase(
    connect
        (mapStateToProps,
            {
                updateStoryParent,
                fetchAllStoryParent,
                getStoryParentData,
                emptyError
            })
        (EditStoryParent)
);
