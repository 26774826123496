import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import ReactTable from 'react-table';
import { Popup } from 'semantic-ui-react'

/**Actions */
import { fetchAllDynamicSections, emptyDynamicSection } from '../../Actions/DynamicSectionActions'
/*Components */
import Loader from '../Layout/Loader';
/*CSS */
import 'react-table/react-table.css';
/*Routes*/
import { GET_SECTION_TO_EDIT } from '../../routes';
import DeleteDynamicSection from './DeleteDynamicSection';

class DynamicSectionTable extends Component {
    constructor(props) {
        super(props);
        this.props.fetchAllDynamicSections();
    }

    render() {
        const { dynamicSection, deleteSuccess, deleteError, } = this.props;

        if (deleteSuccess === false) {
            alert("error missing data or network " + deleteError)
            this.props.emptyDynamicSection();
        }
        else if (deleteSuccess === true) {
            this.props.emptyDynamicSection();
            alert("Dynamic Section Deleted Successfully")
            this.props.fetchAllDynamicSections();
        }

        const columns = [
            {
                Header: "ID",
                accessor: "id",
                width: 50,
                MinWidth: 50,
                Maxwidth: 130,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_SECTION_TO_EDIT + "/" + props.original.id }}>
                                {props.original.id}
                            </Link>
                        </div>
                    )
                }
            },
            {
                Header: "Title",
                accessor: "title",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                                {props.original.title}
                        </div>
                    )
                }
            },
            {
                Header: "Title In English",
                accessor: "titleInEnglish",
                width: 140,
                MinWidth: 140,
                Maxwidth: 140,
                //foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                                {props.original.titleInEnglish}
                        </div>
                    )
                }
            },
            {
                Header: "Type",
                accessor: "type",
                width: 180,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.type}
                        </div>
                    )
                }
            },
            {
                Header: "Category",
                accessor: "category",
                width: 130,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.category === "Discover" ? "Learning&Skills": props.original.category}
                        </div>
                    )
                }
            },
            {
                Header: "Active",
                accessor: "active",
                width: 180,
                filterable: true,
                // foldable: true,
                resizable: true,
                headerClassName: 'table-header',
                Cell: props => {
                    return (
                        <div style={{ "textAlign": "center" }}>
                            {props.original.active === true ? "ACTIVE" : "SUSPENDED"}
                        </div>
                    )
                }
            },
            {
                Header: "Actions",
                width: 180,
                filterable: false,
                headerClassName: 'table-header',
                resizable: false,
                Cell: props => {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Link className="link"
                                to={{ pathname: GET_SECTION_TO_EDIT + "/" + props.original.id }}>
                                <Popup trigger={
                                    <a className="user-lock" onClick={this.open}>
                                        <i>  <FontAwesomeIcon icon="edit" />
                                        </i>
                                    </a>}
                                    content='Edit Dynamic Section'
                                    position='top center'
                                />
                            </Link>

                            <DeleteDynamicSection
                                id={props.original.id} />


                        </div>
                    )
                }
            }
        ]


        const { error, loading, success } = this.props;

        let dynamicSectionList = null

        if (loading === true) {
            dynamicSectionList = (<Loader />)
        }
        else if (success === true && error === '') {
            return (
                <div>
                    <ReactTable
                       className=" table-striped custm-table"
                        columns={columns}
                        data={dynamicSection}
                        filterable
                        defaultPageSize={10}
                    >
                    </ReactTable>
                </div>)
        }

        return (
            <div>

                <div className="mt-4 responsive-table">
                    {dynamicSectionList}
                </div>
            </div>
        )
    }
}


DynamicSectionTable.propTypes = {
    fetchAllDynamicSections: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    dynamicSection: state.dynamicSection.items,
    loading: state.dynamicSection.loading,
    error: state.dynamicSection.error_all_loading_sections,
    success: state.dynamicSection.getting_allSections_sucessfully,
    deleteError: state.dynamicSection.delete_section_error,
    deleteSuccess: state.dynamicSection.delete_section_successfully,
});

export default withRouter
    (connect
        (mapStateToProps,
            { fetchAllDynamicSections, emptyDynamicSection })
        (DynamicSectionTable)
    );
