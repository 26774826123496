import React, { Component } from "react";
import { getBannerData, updateBanner, emptyBanner ,emptyBannerItems} from "../../Actions/BannerAction";
import { connect } from "react-redux";
import OuterHeader from "../Layout/OuterHeader";
import InnerHeader from "../Layout/InnerHeader";
import Dashboard from "../Dashboard";
import Footer from "../Layout/Footer";
import { Redirect } from "react-router-dom";
import { Button, Dropdown } from "semantic-ui-react";
import UploadImageWithoutDropZone from "../S3Uploading/UploadImageWithoutDropZone";


/* Routes */
import { SIGN_IN, ALL_BANNERS } from '../../routes';
import BannerCategories from "../ConstData/BannerCategories";

class EditBanner extends Component {
  state = {
    image: "",
    title: "",
    titleInEnglish :"",
    description: "",
    link: "",

    banner_id: 0,
    error: true,
    errorMessage: "",
    selectedCategory: "",
  };


  componentDidMount() {
    this.props.emptyBannerItems();
    if (!this.props.auth.uid) {
      return <Redirect to={SIGN_IN} />;
    } else {
      let id = this.props.match.params.id;
      this.props.getBannerData(id);
      this.setState({ banner_id: id });
    }
  }

  componentWillReceiveProps(nextprop) {
    let banner = nextprop.banner;
    this.setState({
      title: banner.title,
      titleInEnglish : banner.titleInEnglish,
      image: banner.image,
      description: banner.description,
      link: banner.link,
      selectedCategory : banner.category,
    });
  }


  getImageUrl(image) {
    if (image) {
      this.setState({ image: image });
      if (
        this.state.title === '' || this.state.title === null || this.state.title === undefined || this.state.title.trim() === '' ||
        this.state.titleInEnglish === '' || this.state.titleInEnglish === null || this.state.titleInEnglish === undefined || this.state.titleInEnglish.trim() === '' ||
        this.state.image === '' || this.state.image === null || this.state.image === undefined || this.state.image === 'null'
      ) {
        this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
      } else {
        this.setState({ error: false, errorMessage: "" });
      }
    }
  }

  handleChange = (e) => {
    let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
    this.setState({ [e.target.name]: checkSpaces });

        if ((this.state.title === '' || this.state.title === null || this.state.title === undefined || this.state.title.trim() === ''
        || this.state.titleInEnglish === '' || this.state.titleInEnglish === null || this.state.titleInEnglish === undefined || this.state.titleInEnglish.trim() === '')) {
            this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
        }
        else {
            this.setState({ error: false, errorMessage: "" });
        }

}

handleCategoryDropDown = (e, { value }) => {
  this.setState(prevState => ({
      selectedCategory: value 
  }));
  if (
    this.state.title === '' || this.state.title === null || this.state.title === undefined || this.state.title.trim() === '' ||
    this.state.titleInEnglish === '' || this.state.titleInEnglish === null || this.state.titleInEnglish === undefined || this.state.titleInEnglish.trim() === '' ||
    this.state.image === '' || this.state.image === null || this.state.image === undefined || this.state.image === 'null'
  ) {
    this.setState({ error: true, errorMessage: "Empty Data is not accepted" });
  } else {
    this.setState({ error: false, errorMessage: "" });
  }
};
  handleSubmit = e => {

    const {
      banner_id,
      title,
      titleInEnglish,
      image,
      description,
      link,
      selectedCategory
    } = this.state;



    if (image === '' || image === undefined || image === null || image === 'null') {
      alert("please insert image");
      this.setState({
        validateInput: false
      })
    }


    else if (title === '' || title === null || title === undefined || title.trim() === '') {
      alert("please insert title in arabic");
      this.setState({
        validateInput: false
      })
    }
    else if (titleInEnglish === '' || titleInEnglish === null || titleInEnglish === undefined || titleInEnglish.trim() === '') {
      alert("please insert title in english");
      this.setState({
        validateInput: false
      })
    }
    else if (selectedCategory === '' || selectedCategory === undefined || selectedCategory === null || selectedCategory.trim() === '') {
      alert("please select category");
      this.setState({
        validateInput: false
      })
    }
  
    else {

      this.props.updateBanner(
        banner_id,
        title,
        titleInEnglish,
        description,
        image,
        link,
        selectedCategory
      );
    }
    e.preventDefault();
  };

  render() {


    const
      config = {
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_clear_html",
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        enableDragAndDropFileToEditor: true,
        useImageResizer: false,
        direction: 'rtl',
      }



    const { bannererror, bannersucess } = this.props;
    if (bannersucess === false) {
      alert("error messing data or network " + bannererror);
      this.props.emptyBanner();
    } else if (bannersucess === true) {
      alert("banner updated Successfully");
      this.props.emptyBanner();
      return <Redirect to={ALL_BANNERS} />;
    }

    return (
      <div className="container">
        <OuterHeader />
        <div className="container">
          <div className="container-body">
            <div className="row">
              <Dashboard />
              <div className="col-9">
                <InnerHeader />
                <div className="main-content">
                  <div className="row">
                    <div className="col-12 left-main-body" id="left-main-body">
                      <h5 className="main-title mt-2 font-weight-bold">
                        <span> Edit Banner</span>
                      </h5>
                      <br />
                      <div className="container">
                        <form onSubmit={this.handleSubmit}>
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Image</label>

                          <br />
                          <UploadImageWithoutDropZone
                            getImageURLCallBack={this.getImageUrl.bind(this)}
                            imagepreview={this.state.image}
                            imageUrl={this.state.image}
                            s3DirectryrName='banner'
                          />
                        <br />
                            <p style={{ color: "red" ,fontSize: "12px" ,marginBottom: "0.5em"}} >{this.state.errorMessage} </p>
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Title In Arabic</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="title"
                            name="title"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.title}
                          />
                          <br />
                          <label className="small-label">
                            <span className="required_input">*</span>
                            Title In English</label>
                          <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="titleInEnglish"
                            name="titleInEnglish"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.titleInEnglish}
                          />
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              Description
                            </label>
                            <textarea
                              dir="auto"
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              name="description"
                              onChange={this.handleChange}
                              onKeyUp={this.handleChange}
                              value={this.state.description}
                            />
                          </div>
                          <br />
                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                              
                              Link
                            </label>
                             <input
                            dir="auto"
                            className="form-control"
                            type="text"
                            id="link"
                            name="link"
                            onChange={this.handleChange}
                            onKeyUp={this.handleChange}
                            value={this.state.link}
                          />
                          </div>
                          <br />

                          <div className="form-group">
                            <label
                              className="small-label"
                              for="exampleFormControlTextarea1"
                            >
                                 <span className="required_input">*</span>
                                 Category
                            </label>
                            <Dropdown
                            className="form-control mb-5"
                            id="category"
                            name="category"
                            clearable
                            selection
                            options={BannerCategories}
                            placeholder="Select Category"
                            onChange={this.handleCategoryDropDown}
                            value={this.state.selectedCategory}
                            />
                          </div>
                          <br />

                         
                          <Button
                            icon
                            labelPosition="right"
                            id="nextButton"
                            onClick={this.handleSubmit}
                            disabled={this.state.error}
                          >
                            Publish
                          </Button>
                          <br />
                          <br /> <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  banner: state.banners.item,
  bannererror: state.banners.error_updating_banner,
  bannersucess: state.banners.banner_updated_successfully,
  auth: state.firebase.auth
});
export default connect(
  mapStateToProps,
  { getBannerData, updateBanner, emptyBanner,emptyBannerItems}
)(EditBanner);
