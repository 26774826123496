import React, { Component } from 'react'
import OuterHeader from '../Layout/OuterHeader'
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import Footer from '../Layout/Footer'
import "../../css/AppCss/reporting.css"
import "../../css/AppCss/tabs.css"
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AllReport from './AllReport';
import CustomeReport from './CustomeReport'
import DetailsReport from './DetailsReport'
/* Routes */
import { ADMIN_LAND_PAGE } from '../../routes';
import SubscriptionsReport from './SubscriptionsReport';
import MondiaSubscriptionsReport from './MondiaSubscriptionsReport';

class KidReport extends Component {

    render() {
        return (
            <div>
                <div className="container">
                    <OuterHeader />
                    <div className="container">
                        <div className="container-body">
                            <div className="row">

                                <Dashboard />
                                <div className="col-9">
                                    <InnerHeader />
                                    <div className="main-content">
                                        <div className="row">
                                            <div className="col-12 left-main-body" id="left-main-body">
                                                <div class="main-content">
                                                    <div class="row">
                                                        {/* <!-- start left main body --> */}
                                                        <div class="col left-main-body" id="left-main-body">
                                                            <div class="box-container">


                                                                {/* <!--  start status bar --> */}
                                                                <div class="statusbar small-font">
                                                                    <span class="status-link">
                                                                        <Link to={ADMIN_LAND_PAGE}>Home</Link><span>  </span>
                                                                    </span>
                                                                    <span>Customer Reporting</span>
                                                                </div>
                                                                {/* <!--  end status bar --> */}


                                                                <h5 class="main-title mt-2 font-weight-bold">Customer Reporting</h5>
                                                                <div class="handleCardWidth card cstm-card mt-3 p-0">
                                                                    <div class="card-body p-0">
                                                                        {/* <!-- start  tabs  --> */}

                                                                        <div className="tabs">
                                                                            <div className="tab-button-outer">
                                                                                {/* <ul id="tab-button">
                                                                                    <li><a href="#tab01">All</a></li>
                                                                                    <li><a href="#tab02">Custome</a></li>
                                                                                    <li><a href="#tab03">Details</a></li>
                                                                                </ul> */}
                                                                                <Tabs >
                                                                                    <TabList >
                                                                                        <Tab>All</Tab>
                                                                                        <Tab >Custom</Tab>
                                                                                        <Tab>Details</Tab>
                                                                                        <Tab>Subscriptions</Tab>
                                                                                        <Tab>Mondia Subscriptions</Tab>
                                                                                    </TabList>
                                                                                    <TabPanel>
                                                                                        <AllReport />
                                                                                    </TabPanel>
                                                                                    <TabPanel>
                                                                                        <CustomeReport />
                                                                                    </TabPanel>
                                                                                    <TabPanel>
                                                                                        <DetailsReport />
                                                                                    </TabPanel>
                                                                                    <TabPanel>
                                                                                        <SubscriptionsReport/>
                                                                                    </TabPanel>
                                                                                    <TabPanel>
                                                                                        <MondiaSubscriptionsReport/>  
                                                                                    </TabPanel>                                                                                    
                                                                                </Tabs>
                                                                            </div>



                                                                        </div>


                                                                        {/* <!-- end  tabs  --> */}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }
}
export default KidReport;
