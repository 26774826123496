import React, { Component } from 'react'
import googlePlay from "../../../images/portal/google-play.png";
import appStore from "../../../images/portal/app-store.png";
import download from "../../../images/portal/download-bg.png";

export class DownloadApps extends Component {
    render() {
        return (
            <div className="parent-app-section">
                <div className="Appdownload-bg ">
                    <img className="appimage" src={download} alt="" />
                </div>
                <div className="form-row Appdownload-btns">
                    <div className="col-6">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.jeelapp.android">

                            <button className="Appdownload-btn">
                                <img src={googlePlay} alt="" />
                            </button>
                        </a>
                    </div>
                    <div className="col-6">
                        <a target="_blank" href="https://apps.apple.com/us/app/jeel-%D8%AC%D9%8A%D9%84/id1489596085">

                            <button className="Appdownload-btn">
                                <img src={appStore} alt="" />
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default DownloadApps
