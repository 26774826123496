import React, { Component } from 'react'
import { Input, Button, Modal } from 'semantic-ui-react'

export class viewchildren extends Component {
    componentDidMount() {

    }

    render() {
        const { children } = this.props;
        return (
            <Modal id="modal"
                trigger={
                    <Button id="customer-button-view">View active</Button>
                }
            >
                <Modal.Header>Children Data</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">

                                    <div className="col-4">
                                        <img className="image_Preview" src={children.profilePicture} />
                                    </div>

                                    <div className="col">

                                        <label htmlFor="name" className="label">Name</label>
                                        <input
                                            dir="auto"
                                            className="form-control"
                                            name="name" id="name" value={children.name} />
                                        <br />

                                        <label htmlFor="password" className="label">Password</label>
                                        <Input fluid name="password" id="password" value={children.password} />
                                        <br />

                                        <label htmlFor="dailyUsage" className="label">Daily Usage</label>
                                        <Input fluid name="dailyUsage" id="dailyUsage" value={children.dailyUsage} />
                                        <br />

                                        <label htmlFor="dateOfBirth" className="label">Date Of Birth</label>
                                        <Input fluid name="dateOfBirth" id="dateOfBirth" value={children.dateOfBirth} />
                                        <br />

                                        <label htmlFor="gender" className="label">Gender</label>
                                        <Input fluid name="gender" id="gender" value={children.gender} />
                                        <br />
                                    </div>

                                </div>

                                <div className="input-field">

                                </div>
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>
        )
    }
}

export default viewchildren
