import { FETCH_BANNERS, LOAD_BANNERS, ERROR_LOADING_BANNERS, PUBLISH_BANNER, ERROR_ADDING_BANNER, GET_BANNER_DATA, ERROR_LOADING_BANNER, LOAD_BANNER, UPDATE_BANNER, ERROR_UPDATING_BANNER, EMPTY_BANNER, DELETE_BANNER, ERROR_DELETING_BANNER, SETTING_BANNER_STATUS, ERROR_SETTING_BANNER_STATUS, EMPTY_BANNER_ITEMS } from "../Actions/Types";


const initialState = {
    items:[],
    item:{},
    error_loading_banners:'',
    loading:'',
    error_adding_banner:'',
    banner_added_successfully:'',
    banner_updated_successfully:'',
    error_updating_banner:'',
    banner_deleted_successfully:'',
    error_deleting_banner:'',
    status_updated_successfully:'',
    error_updating_status:''
}

export default function (state=initialState,action)
{
    switch(action.type)
    {
        case FETCH_BANNERS:
            return{
                ...state,
                items:action.payload,
                error_loading_banners:'',
                loading:false
            }
        case LOAD_BANNERS:
            return {
                ...state,
                loading:true
            }
        case ERROR_LOADING_BANNERS:
            return{
                ...state,
                error_loading_banners:action.payload,
                loading:false
            }
        case EMPTY_BANNER:
            return{
                ...state,
                error_adding_banner:action.payload,
                banner_added_successfully:action.payload,
                error_loading_banner:action.payload,
                banner_updated_successfully:action.payload,
                banner_deleted_successfully:'',
                error_deleting_banner:'',
                status_updated_successfully:'',
                error_updating_status:'',
                error_updating_banner:''
            }
        case EMPTY_BANNER_ITEMS:
            return{
                ...state,
                item : {},
                items:[],
            }
        case PUBLISH_BANNER:
            return {
                ...state,
                item:action.payload,
                banner_added_successfully:true

            }
            case ERROR_ADDING_BANNER:
            return{
                ...state,
                error_adding_banner:action.payload,
                banner_added_successfully:false
            }

            case GET_BANNER_DATA:
                return{
                    ...state,
                    item:action.payload,
                    error_loading_banner:'',
                    loading:false
                }
            case ERROR_LOADING_BANNER:
                return{
                    ...state,
                    error_loading_banner:action.payload,
                    loading:false
                }
            case LOAD_BANNER:
                return{
                    ...state,
                    loading:true
                }
            case UPDATE_BANNER:
                return{
                    ...state,
                    item:action.payload,
                    banner_updated_successfully:true
                }
            case ERROR_UPDATING_BANNER:
                return{
                    ...state,
                    error_updating_banner: action.payload,
                    loading: false
                }
            case DELETE_BANNER:
                return{
                    ...state,
                    banner_deleted_successfully:true,
                    item:action.payload
                }

            case ERROR_DELETING_BANNER:
                return {
                    ...state,
                    banner_deleted_successfully:false,
                    error_deleting_banner:action.payload
                }
            case SETTING_BANNER_STATUS:
                return {
                    ...state,
                    status_updated_successfully:true,
                    item:action.payload,
                }
            case ERROR_SETTING_BANNER_STATUS:
                return {
                    ...state,
                    status_updated_successfully:false,
                    error_updating_status:action.payload
                }
         default:
             return state

    }
}