import {
    PROFIT_SHARE_CURRENCY_PROFITS_LOAD, 
    PROFIT_SHARE_CURRENCY_PROFITS_FETCH_SUCCESSFULLY, 
    PROFIT_SHARE_CURRENCY_PROFITS_FETCH_ERROR,
    
    PROFIT_SHARE_ContentVendor_PROFITS_LOAD,
	PROFIT_SHARE_ContentVendor_PROFITS_FETCH_SUCCESSFULLY,
	PROFIT_SHARE_ContentVendor_FETCH_ERROR,
   

} from '../Actions/Types'


const initialState = {
    State_items: [],
    State_item: {},
    State_loading: '',

    State_Profit_Share_Fetch_sucessfully: '',
    State_Profit_Share_Fetch_Error: '',
    
    
    State_Items_ByVendors:[],
    State_Profit_Share_ByVendors_Fetch_sucessfully: '',
    State_Profit_Share_ByVendors_Fetch_Error: '',    
}

export default function (state = initialState, action) {
    switch (action.type) {

        case PROFIT_SHARE_CURRENCY_PROFITS_LOAD:
            return {
                ...state,
                State_loading: true
            } 
        case PROFIT_SHARE_CURRENCY_PROFITS_FETCH_SUCCESSFULLY:
            return {
                ...state,
                State_items: action.payload,
                State_Profit_Share_Load_Error: '',
                State_Profit_Share_Load_sucessfully: true,
                State_loading: false,
                State_item: {}
            }           
        case PROFIT_SHARE_CURRENCY_PROFITS_FETCH_ERROR:
            return {
                ...state,
                State_Profit_Share_Load_sucessfully: false,
                State_Profit_Share_Load_Error: action.payload,
                State_loading: false
            }







        case PROFIT_SHARE_ContentVendor_PROFITS_LOAD:
            return {
                ...state,
                State_loading: true
            } 
        case PROFIT_SHARE_ContentVendor_PROFITS_FETCH_SUCCESSFULLY:
            return {
                ...state,
                //State_items: [],                
                //State_Profit_Share_Load_Error: '',
                //State_Profit_Share_Load_sucessfully: true,
                
                State_Items_ByVendors: action.payload,
                State_Profit_Share_ByVendors_Fetch_sucessfully:true,
                State_Profit_Share_ByVendors_Fetch_Error:false,
                
                //State_loading: false,
                //State_item: {}
            }           
        case PROFIT_SHARE_ContentVendor_FETCH_ERROR:
            return {
                ...state,
                State_Profit_Share_Load_sucessfully: false,
                State_Profit_Share_Load_Error: action.payload,
                State_loading: false,
                
                State_Items_ByVendors:[],
                State_Profit_Share_ByVendors_Fetch_sucessfully:false,
                State_Profit_Share_ByVendors_Fetch_Error:true,
            }
        case 'emptyProfits':
            return {
                ...state,
			
			    State_Items_ByVendors: [],
                State_Profit_Share_ByVendors_Fetch_sucessfully:false,
                State_Profit_Share_ByVendors_Fetch_Error:false,
            }

        default:
            return state

    }
}