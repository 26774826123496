import React, { Component } from 'react'
import { DateInput } from 'semantic-ui-calendar-react';
import countryList from 'react-select-country-list'
import { Dropdown } from 'semantic-ui-react'
import { getCustomeCountryReport, emptyCustomeReport, getCustomeGenderReport, getCustomeAgeRangeReport } from '../../Actions/CustomeReportActions'
import { connect } from 'react-redux'
import Loader from '../Layout/Loader';
import PieChart from 'react-minimal-pie-chart';

class CustomeReport extends Component {
    state = {
        startDate: '',
        endDate: '',
        countryOptions: [],
        radioValue: '',
        gender: '',
        countries: [],
        ageRanges: [],
        buttonType: 'Total Sessions PerDay',
        totalSessionsPerDayValues: [],
        totalTimeUsagePerDayValues: [],
        averageSessionTimeValues: [],


    }
    componentDidMount() {
        this.props.emptyCustomeReport();
        let countries = countryList().getLabels();
        let countryOptions = [];
        for (let i = 0; i < countries.length; i++) {
            let c = { key: countries[i], value: countries[i], text: countries[i] }
            countryOptions.push(c);
        }
        this.setState({ countryOptions: countryOptions })

    }


    componentWillReceiveProps(nextProp) {
        let totalSessionsPerDayValues = [];
        let totalTimeUsagePerDayValues = [];
        let averageSessionTimeValues = []
        if (nextProp.customeCountry.length > 0) {
            for (let i = 0; i < nextProp.customeCountry.length; i++) {
                var letters = '0123456789ABCDEF';
                var color = '#';
                for (var j = 0; j < 6; j++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                if (nextProp.customeCountry[i].kidUsageReport !== null) {
                    let pie = { title: nextProp.customeCountry[i].country, value: nextProp.customeCountry[i].kidUsageReport.totalSessionsPerDay, color: color }
                    let pieUsage = { title: nextProp.customeCountry[i].country, value: nextProp.customeCountry[i].kidUsageReport.totalTimeUsagePerDay, color: color }
                    let average = { title: nextProp.customeCountry[i].country, value: nextProp.customeCountry[i].kidUsageReport.averageSessionTime, color: color }
                    totalSessionsPerDayValues.push(pie)
                    totalTimeUsagePerDayValues.push(pieUsage)
                    averageSessionTimeValues.push(average)
                }
            }
        }

        else if (nextProp.custome_gender.length > 0) {
            console.log('here')
            if (nextProp.custome_gender[0].kidUsageReport !== null) {
                let pie = { title: nextProp.custome_gender[0].gender, value: nextProp.custome_gender[0].kidUsageReport.totalSessionsPerDay, color: '#800080' }
                let pieUsage = { title: nextProp.custome_gender[0].gender, value: nextProp.custome_gender[0].kidUsageReport.totalTimeUsagePerDay, color: '#800080' }
                let average = { title: nextProp.custome_gender[0].gender, value: nextProp.custome_gender[0].kidUsageReport.averageSessionTime, color: '#800080' }
                totalSessionsPerDayValues.push(pie)
                totalTimeUsagePerDayValues.push(pieUsage)
                averageSessionTimeValues.push(average)
                pie = { title: nextProp.custome_gender[1].gender, value: nextProp.custome_gender[1].kidUsageReport.totalSessionsPerDay, color: '#e67e00' }
                pieUsage = { title: nextProp.custome_gender[1].gender, value: nextProp.custome_gender[1].kidUsageReport.totalTimeUsagePerDay, color: '#e67e00' }
                average = { title: nextProp.custome_gender[1].gender, value: nextProp.custome_gender[1].kidUsageReport.averageSessionTime, color: '#e67e00' }
                totalSessionsPerDayValues.push(pie)
                totalTimeUsagePerDayValues.push(pieUsage)
                averageSessionTimeValues.push(average)

            }
        }
        else if (nextProp.custome_age_range.length > 0) {
            for (let i = 0; i < nextProp.custome_age_range.length; i++) {
                var letters = '0123456789ABCDEF';
                var color = '#';
                for (var j = 0; j < 6; j++) {
                    color += letters[Math.floor(Math.random() * 16)];
                }
                if (nextProp.custome_age_range[i].kidUsageReport !== null) {
                    let pie = { title: nextProp.custome_age_range[i].ageRange, value: nextProp.custome_age_range[i].kidUsageReport.totalSessionsPerDay, color: color }
                    let pieUsage = { title: nextProp.custome_age_range[i].ageRange, value: nextProp.custome_age_range[i].kidUsageReport.totalTimeUsagePerDay, color: color }
                    let average = { title: nextProp.custome_age_range[i].ageRange, value: nextProp.custome_age_range[i].kidUsageReport.averageSessionTime, color: color }
                    totalSessionsPerDayValues.push(pie)
                    totalTimeUsagePerDayValues.push(pieUsage)
                    averageSessionTimeValues.push(average)
                }
            }
        }
        this.setState({ totalSessionsPerDayValues: totalSessionsPerDayValues, totalTimeUsagePerDayValues: totalTimeUsagePerDayValues, averageSessionTimeValues: averageSessionTimeValues })
    }


    handleDateChange = (e, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({
                [name]: value
            })
        }
    }

    handleRadioChange = (e) => {

        this.props.emptyCustomeReport();
        this.setState({
            radioValue: e.target.value
        })
        const { startDate, endDate, countries, ageRanges, gender } = this.state

        if (e.target.value === 'gender') {
            if (startDate && endDate) {
                this.props.getCustomeGenderReport(startDate, endDate, countries, ageRanges)
            }
        }

        else if (e.target.value === 'country') {
            if (startDate && endDate && countries.length > 0) {
                this.props.getCustomeCountryReport(startDate, endDate, countries, gender, ageRanges)
            }
        }
        else if (e.target.value === 'ageRange') {
            if (startDate && endDate) {
                this.props.getCustomeAgeRangeReport(startDate, endDate, countries, gender);
            }
        }
    }

    handleCountryDropDown = (e, { value }) => {
        this.setState({
            countries: value
        })

        const { startDate, endDate, ageRanges, radioValue, gender } = this.state



        if (startDate && endDate) {
            if (radioValue === 'gender') {
                this.props.getCustomeGenderReport(startDate, endDate, value, ageRanges)
            }
            if (radioValue === 'country') {
                this.props.getCustomeCountryReport(startDate, endDate, value, gender, ageRanges)
            }
            if (radioValue === 'ageRange') {
                this.props.getCustomeAgeRangeReport(startDate, endDate, value, gender);
            }
        }

    }

    handleGenderDropDown = (e, { value }) => {
        this.setState({ gender: value })
        const { startDate, endDate, radioValue, countries, ageRanges } = this.state
        if (startDate && endDate) {
            if (radioValue === 'country') {
                this.props.getCustomeCountryReport(startDate, endDate, countries, value, ageRanges);
            }
            if (radioValue === 'gender') {

                this.props.getCustomeGenderReport(startDate, endDate, countries, ageRanges);
            }
            if (radioValue === 'ageRange') {
                this.props.getCustomeAgeRangeReport(startDate, endDate, countries, value)
            }
        }
    }

    handleAgeRangeDropDown = (e, { value }) => {
        this.setState({ ageRanges: value });
        const { startDate, endDate, radioValue, countries, gender } = this.state
        if (startDate && endDate) {
            if (radioValue === 'country') {
                this.props.getCustomeCountryReport(startDate, endDate, countries, gender, value);
            }
            if (radioValue === 'gender') {
                this.props.getCustomeGenderReport(startDate, endDate, countries, value);
            }
            if (radioValue === 'ageRange') {
                this.props.getCustomeAgeRangeReport(startDate, endDate, countries, gender)
            }
        }
    }



    handleTotalSessionsPerDayClick = (e) => {

        if (this.state.startDate && this.state.endDate) {
            const { startDate, endDate, countries, gender, ageRanges } = this.state
            if (this.state.radioValue === 'country') {
                this.props.getCustomeCountryReport(startDate, endDate, countries, gender, ageRanges)
            }
            if (this.state.radioValue === 'gender') {
                this.props.getCustomeGenderReport(startDate, endDate, countries, ageRanges);
            }
            if (this.state.radioValue === 'ageRange') {
                this.props.getCustomeAgeRangeReport(startDate, endDate, countries, gender)
            }
        }
        this.setState({
            buttonType: 'Total Sessions PerDay'
        })
        e.preventDefault();
    }


    handleTotalTimeUsageClick = (e) => {
        if (this.state.startDate && this.state.endDate) {
            const { startDate, endDate, countries, gender, ageRanges } = this.state
            if (this.state.radioValue === 'country') {
                this.props.getCustomeCountryReport(startDate, endDate, countries, gender, ageRanges)
            }
            if (this.state.radioValue === 'gender') {
                this.props.getCustomeGenderReport(startDate, endDate, countries, ageRanges);
            }
            if (this.state.radioValue === 'ageRange') {
                this.props.getCustomeAgeRangeReport(startDate, endDate, countries, gender)
            }
        }
        this.setState({
            buttonType: 'Total Time Usage perDay',

        })
        e.preventDefault();
    }


    handleAverageSessionTimeClick = (e) => {
        if (this.state.startDate && this.state.endDate) {
            const { startDate, endDate, countries, gender, ageRanges } = this.state
            if (this.state.radioValue === 'country') {
                this.props.getCustomeCountryReport(startDate, endDate, countries, gender, ageRanges)
            }
            if (this.state.radioValue === 'gender') {
                this.props.getCustomeGenderReport(startDate, endDate, countries, ageRanges);
            }
            if (this.state.radioValue === 'ageRange') {
                this.props.getCustomeAgeRangeReport(startDate, endDate, countries, gender)
            }
        }
        this.setState({
            buttonType: 'Average Session Time'
        })
        e.preventDefault();
    }


    render() {
        console.log(this.state)
        let genders = [{ key: 'male', value: 'male', text: 'male' }, { key: 'female', value: 'female', text: 'female' }]
        let ageRange = [{ key: '0-3', value: '0-3', text: '0-3' }, { key: '3-9', value: '3-9', text: '3-9' }, { key: '9+', value: '9+', text: '9+' }]

        const { buttonType, ageRanges, totalSessionsPerDayValues, totalTimeUsagePerDayValues, averageSessionTimeValues } = this.state
        const { customeCountry, error_loading_custome_country, loading_custome_country } = this.props
        const { custome_gender, error_loading_custome_gender, loading_custome_gender } = this.props
        const { custome_age_range, error_loading_custome_age_range, loading_custome_age_range } = this.props


        let data = null;

        if (loading_custome_country === true || loading_custome_gender === true || loading_custome_age_range === true) {
            data = <Loader />
        }
        else if (loading_custome_country === false && error_loading_custome_country === '') {
            if (customeCountry.length == 1) {
                if (buttonType === 'Total Sessions PerDay') {

                    if (customeCountry[0].kidUsageReport !== undefined) {

                        data = <div>{customeCountry[0].kidUsageReport.totalSessionsPerDay}</div>
                    }

                }
                else if (buttonType === 'Total Time Usage perDay') {
                    if (customeCountry[0].kidUsageReport !== undefined) {

                        data = <div>{customeCountry[0].kidUsageReport.totalTimeUsagePerDay}</div>
                    }
                }
                else if (buttonType === 'Average Session Time') {
                    if (customeCountry[0].kidUsageReport !== undefined) {

                        data = <div>{customeCountry[0].kidUsageReport.averageSessionTime}</div>
                    }
                }
            }
            else if (customeCountry.length > 1) {
                if (buttonType === 'Total Sessions PerDay') {
                    data = <PieChart
                        data={this.state.totalSessionsPerDayValues}
                        label={({ data, dataIndex }) =>
                            Math.round(data[dataIndex].percentage) + '%'
                        } labelStyle={{
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                            fill: '#121212'
                        }}
                        style={{ height: '200px' }}

                    />
                }
                else if (buttonType === 'Total Time Usage perDay') {
                    data = <PieChart
                        data={this.state.totalTimeUsagePerDayValues}
                        label={({ data, dataIndex }) =>
                            Math.round(data[dataIndex].percentage) + '%'
                        } labelStyle={{
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                            fill: '#121212'
                        }}
                        style={{ height: '200px' }}

                    />
                }
                else if (buttonType === 'Average Session Time') {
                    data = <PieChart
                        data={this.state.averageSessionTimeValues}
                        label={({ data, dataIndex }) =>
                            Math.round(data[dataIndex].percentage) + '%'
                        } labelStyle={{
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                            fill: '#121212'
                        }}
                        style={{ height: '200px' }}

                    />
                }
            }
        }
        else if (loading_custome_gender === false && error_loading_custome_gender === '') {
            if (buttonType === 'Total Sessions PerDay') {
                if (this.state.gender === 'male') {
                    if (custome_gender[0].kidUsageReport !== undefined) {

                        data = <div>{custome_gender[0].kidUsageReport.totalSessionsPerDay}</div>
                    }

                }
                else if (this.state.gender === 'female') {
                    if (custome_gender[1].kidUsageReport !== undefined) {

                        data = <div>{custome_gender[1].kidUsageReport.totalSessionsPerDay}</div>
                    }
                }
                else {
                    data = <PieChart
                        data={this.state.totalSessionsPerDayValues}
                        label={({ data, dataIndex }) =>
                            Math.round(data[dataIndex].percentage) + '%'
                        } labelStyle={{
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                            fill: '#121212'
                        }}
                        style={{ height: '200px' }}

                    />
                }
            }
            else if (buttonType === 'Total Time Usage perDay') {
                if (this.state.gender === 'male') {
                    if (custome_gender[0].kidUsageReport !== undefined) {

                        data = <div>{custome_gender[0].kidUsageReport.totalTimeUsagePerDay}</div>
                    }

                }
                else if (this.state.gender === 'female') {
                    if (custome_gender[1].kidUsageReport !== undefined) {

                        data = <div>{custome_gender[1].kidUsageReport.totalTimeUsagePerDay}</div>
                    }
                }
                else {
                    data = <PieChart
                        data={this.state.totalTimeUsagePerDayValues}
                        label={({ data, dataIndex }) =>
                            Math.round(data[dataIndex].percentage) + '%'
                        } labelStyle={{
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                            fill: '#121212'
                        }}
                        style={{ height: '200px' }}

                    />
                }
            }
            else if (buttonType === 'Average Session Time') {
                if (this.state.gender === 'male') {
                    if (custome_gender[0].kidUsageReport !== undefined) {

                        data = <div>{custome_gender[0].kidUsageReport.averageSessionTime}</div>
                    }

                }
                else if (this.state.gender === 'female') {
                    if (custome_gender[1].kidUsageReport !== undefined) {

                        data = <div>{custome_gender[1].kidUsageReport.averageSessionTime}</div>
                    }
                }
                else {
                    data = <PieChart
                        data={this.state.averageSessionTimeValues}
                        label={({ data, dataIndex }) =>
                            Math.round(data[dataIndex].percentage) + '%'
                        } labelStyle={{
                            fontSize: '5px',
                            fontFamily: 'sans-serif',
                            fill: '#121212'
                        }}
                        style={{ height: '200px' }}

                    />
                }
            }
        }

        else if (custome_age_range.length > 0 && error_loading_custome_age_range === '' && loading_custome_age_range === false) {
            if (buttonType === 'Total Sessions PerDay') {
                if (ageRanges.length === 1) {
                    for (let i = 0; i < totalSessionsPerDayValues.length; i++) {
                        if (ageRanges[0] === totalSessionsPerDayValues[i].title) {
                            data = <div>{totalSessionsPerDayValues[i].value}</div>
                        }
                    }
                }
                else if (ageRanges.length>1) {
                    let dummy = [];
                    for (let i = 0; i < ageRanges.length; i++) {
                        for (let j = 0; j < totalSessionsPerDayValues.length; j++) {
                            if (ageRanges[i]=== totalSessionsPerDayValues[j].title) {
                                dummy.push(this.state.totalSessionsPerDayValues[j])
                            }
                        }
                    }
                    console.log(dummy)
                    data =
                        <PieChart
                            data={dummy}
                            label={({ data, dataIndex }) =>
                                Math.round(data[dataIndex].percentage) + '%'
                            } labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                            style={{ height: '200px' }}

                        />

                }
                else if (ageRanges.length===0)
                {
                    data =
                        <PieChart
                            data={totalSessionsPerDayValues}
                            label={({ data, dataIndex }) =>
                                Math.round(data[dataIndex].percentage) + '%'
                            } labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                            style={{ height: '200px' }}

                        />
                }
            }
            else if (buttonType==='Total Time Usage perDay')
            {
                if (ageRanges.length === 1) {
                    for (let i = 0; i < totalTimeUsagePerDayValues.length; i++) {
                        if (ageRanges[0] === totalTimeUsagePerDayValues[i].title) {
                            data = <div>{totalTimeUsagePerDayValues[i].value}</div>
                        }
                    }
                }
                else if (ageRanges.length>1) {
                    let dummy = [];
                    for (let i = 0; i < ageRanges.length; i++) {
                        for (let j = 0; j < totalTimeUsagePerDayValues.length; j++) {
                            if (ageRanges[i]=== totalTimeUsagePerDayValues[j].title) {
                                dummy.push(totalTimeUsagePerDayValues[j])
                            }
                        }
                    }
                    console.log(dummy)
                    data =
                        <PieChart
                            data={dummy}
                            label={({ data, dataIndex }) =>
                                Math.round(data[dataIndex].percentage) + '%'
                            } labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                            style={{ height: '200px' }}

                        />

                }
                else if (ageRanges.length===0)
                {
                    data =
                        <PieChart
                            data={totalTimeUsagePerDayValues}
                            label={({ data, dataIndex }) =>
                                Math.round(data[dataIndex].percentage) + '%'
                            } labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                            style={{ height: '200px' }}

                        />
                }
            }
            else if (buttonType=='Average Session Time')
            {
                if (ageRanges.length === 1) {
                    for (let i = 0; i < averageSessionTimeValues.length; i++) {
                        if (ageRanges[0] === averageSessionTimeValues[i].title) {
                            data = <div>{averageSessionTimeValues[i].value}</div>
                        }
                    }
                }
                else if (ageRanges.length>1) {
                    let dummy = [];
                    for (let i = 0; i < ageRanges.length; i++) {
                        for (let j = 0; j < averageSessionTimeValues.length; j++) {
                            if (ageRanges[i]=== averageSessionTimeValues[j].title) {
                                dummy.push(averageSessionTimeValues[j])
                            }
                        }
                    }
                    console.log(dummy)
                    data =
                        <PieChart
                            data={dummy}
                            label={({ data, dataIndex }) =>
                                Math.round(data[dataIndex].percentage) + '%'
                            } labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                            style={{ height: '200px' }}

                        />

                }
                else if (ageRanges.length===0)
                {
                    data =
                        <PieChart
                            data={averageSessionTimeValues}
                            label={({ data, dataIndex }) =>
                                Math.round(data[dataIndex].percentage) + '%'
                            } labelStyle={{
                                fontSize: '5px',
                                fontFamily: 'sans-serif',
                                fill: '#121212'
                            }}
                            style={{ height: '200px' }}

                        />
                }
            }
        }

        // console.log(this.props)
        return (
            <div>
                <div id="tab02" class="tab-contents custome-chart">


                    <form onSubmit={(e)=> e.preventDefault()}>
                        <div class="form-row mt-2">
                            <div class="col-md-3 col-12 mb-3">
                                <label class="small-label" for="">Period of date</label>
                                <DateInput
                                    name="startDate"
                                    closeOnMouseLeave
                                    placeholder="start date"
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                    fluid
                                    value={this.state.startDate}
                                    onChange={this.handleDateChange}
                                />
                            </div>
                            <div class="col-md-3 col-12 mb-3">
                                <label class="small-label visibility" for="">End Date</label>
                                <DateInput
                                    name="endDate"
                                    closeOnMouseLeave
                                    placeholder="end date"
                                    iconPosition="right"
                                    dateFormat="MM/DD/YYYY"
                                    required
                                    fluid
                                    value={this.state.endDate}
                                    onChange={this.handleDateChange}
                                />
                            </div>

                        </div>
                    </form>


                    <div class="custom-grid">
                        <form action="/action_page.php" >
                            <div class="row t-head">
                                <div class="col-md-4 col-12">
                                    <div class="row title-main-style rest-t-head-style">
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" value="country" onChange={this.handleRadioChange} />
                                            <label class="custom-control-label" for="customRadio1">Country</label>
                                        </div>
                                        <Dropdown
                                            clearable
                                            multiple
                                            search
                                            selection
                                            options={this.state.countryOptions}
                                            placeholder="select Country"
                                            onChange={this.handleCountryDropDown}></Dropdown>
                                    </div>
                                </div>

                                <div class="col-md-4 col-12">
                                    <div class="row title-main-style rest-t-head-style">
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" value="gender" onChange={this.handleRadioChange} />
                                            <label class="custom-control-label" for="customRadio2">Gender</label>
                                        </div>
                                        <Dropdown
                                            clearable
                                            search
                                            selection
                                            options={genders}
                                            placeholder="select gender"
                                            onChange={this.handleGenderDropDown}></Dropdown>
                                    </div>
                                </div>

                                <div class="col-md-4 col-12">
                                    <div class="row title-main-style rest-t-head-style">
                                        <div class="custom-control custom-radio">
                                            <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input" value="ageRange" onChange={this.handleRadioChange} />
                                            <label class="custom-control-label" for="customRadio3">Age Range</label>
                                        </div>
                                        <Dropdown
                                            clearable
                                            selection
                                            multiple
                                            search
                                            options={ageRange}
                                            placeholder="select age range"
                                            onChange={this.handleAgeRangeDropDown}
                                        ></Dropdown>
                                    </div>
                                </div>

                            </div>
                        </form>
                        <div class="row t-row">
                            <div class="col-md-12">
                                {/* <h3>{this.state.buttonType}</h3> */}
                                <div class="h2 font-weight-bold text-center p-3">{data}</div>
                            </div>
                            <div class="col-12  t-row">
                                <div class="form-row pb-4">
                                    <div class="col-sm-4">
                                        <button type="button"
                                            className={this.state.buttonType === 'Total Sessions PerDay' ? "w-100 btn btn-outline-secondary small-font active" : "w-100 btn btn-outline-secondary small-font "}
                                            onClick={this.handleTotalSessionsPerDayClick}>Total Sessions/day</button>
                                    </div>
                                    <div class="col-sm-4">
                                        <button type="button"
                                            className={this.state.buttonType === 'Total Time Usage perDay' ? "w-100 btn btn-outline-secondary small-font active" : "w-100 btn btn-outline-secondary small-font "}
                                            onClick={this.handleTotalTimeUsageClick}>Total time of usage/day</button>
                                    </div>
                                    <div class="col-sm-4">
                                        <button type="button"
                                            className={this.state.buttonType === 'Average Session Time' ? "w-100 btn btn-outline-secondary small-font active" : "w-100 btn btn-outline-secondary small-font "}
                                            onClick={this.handleAverageSessionTimeClick}>Average Session time</button>
                                    </div>
                                </div></div>
                        </div>
                    </div>






                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>
    ({
        customeCountry: state.customeReport.custome_country,
        error_loading_custome_country: state.customeReport.error_loading_custome_country,
        loading_custome_country: state.customeReport.loading_custome_country,
        loading_custome_gender: state.customeReport.loading_custome_gender,
        error_loading_custome_gender: state.customeReport.error_loading_custome_gender,
        custome_gender: state.customeReport.custome_gender,
        loading_custome_age_range: state.customeReport.loading_custome_age_range,
        error_loading_custome_age_range: state.customeReport.error_loading_custome_age_range,
        custome_age_range: state.customeReport.custome_age_range

        // defaultValues :state.kidsReport.item
    })
export default connect(mapStateToProps, { getCustomeCountryReport, emptyCustomeReport, getCustomeGenderReport, getCustomeAgeRangeReport })(CustomeReport);
