import { ADD_TOKEN, EMPTY_TOKEN } from '../Actions/Types'

const intial_state =
{
    item: {},
    token: ''
}
export default function (state = intial_state, action) {
    switch (action.type) {
        case ADD_TOKEN:
            return {
                ...state,
                token: action.payload
            }

        case EMPTY_TOKEN:
            return {
                ...state,
                token: ''
            }

        default:
            return state;

    }

}