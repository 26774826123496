import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { setDiscoverUsSeriesState, fetchDiscoverUsSeries, getDiscoverUsSeriesData, emptyDiscoverUsSeries } from '../../Actions/DiscoverUsSeriesActions'

class SetDiscoverUsSeriesState extends Component {

    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setStateDiscoverUs(id, status) {
        this.props.setDiscoverUsSeriesState(id, status)
    }

    handleSubmit = (e) => {
        const { id, state } = this.props;
        if (state == "SUSPENDED") {
            this.setStateDiscoverUs(id, "DEFAULT")
        }
        else {
            this.setStateDiscoverUs(id, "SUSPENDED")
        }
        this.close();
    }

    render() {
        const { error } = this.props;
        const { success } = this.props;

        const { state, id, sentFrom } = this.props;

        let contentPreview = null;

        if (success === false) {
            alert("error messing data or network " + error)
            this.props.emptyDiscoverUsSeries();
        }
        else if (success === true) {
            alert("Series State Changed Successfully")
            this.props.emptyDiscoverUsSeries();

            // if the requesst come from All Series then 
            //  fetch all Series to update the suspended Series
            if (sentFrom === "AllSeries") {
                this.props.fetchDiscoverUsSeries()
            }

            // if the requesst come from ViewSeries then 
            //  get series data to update the suspended Series\
            if (sentFrom === "ViewSeries") {
                this.props.getDiscoverUsSeriesData(id);
            }
        }

        if (state === "SUSPENDED") {

            contentPreview = (

                <Popup trigger={
                    <button className="seriesCardButtons" onClick={this.open}>
                        <i className="" >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )

        } else {

            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons" onClick={this.open}>||</button>
                }
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }

        return (

            <span >
                {contentPreview}
                <Confirm id="modal" open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </span>

        )



    }

}

const mapStateToProps = state => ({
    error: state.discoverUsSeries.error_setting_series_state,
    success: state.discoverUsSeries.setting_state_series_sucessfully
})

export default connect(mapStateToProps, { setDiscoverUsSeriesState, fetchDiscoverUsSeries, getDiscoverUsSeriesData, emptyDiscoverUsSeries })(SetDiscoverUsSeriesState)