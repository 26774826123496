import React, { Component } from 'react'
import { withFirebase } from '../../config';
import { Button, Modal } from 'semantic-ui-react';
/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import { addTermsAndConditions } from '../../Actions/TermsAndConditionsActions';
import { emptyError } from '../../Actions/Empty'

const INITIAL_STATE = {

    showModal: '',
    /////////// TermsAndConditions
    title: '',
    text: '',
    /////////// validation
    formErrors: { error: true, title: '', text: '' }
};

class AddTermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    static getDerivedStateFromProps(nextprops, state) {
        if (nextprops.showModal) {
            return {
                showModal: nextprops.showModal
            }
        }
        return state;
    }

    addTermsAndConditions(title, text) {
        this.props.addTermsAndConditions(title, text);
    }

    handleChange = (e) => {
        let validation = this.state.formErrors;
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            if (this.state.title === '' || this.state.title === ' ') {
                validation.title = "Title can not be empty";
                validation.error = true;
                this.setState({ formErrors: validation });
            }
            else {
                validation.title = '';
                this.setState({ formErrors: validation });
            }
            if (this.state.text === '' || this.state.text === ' ') {
                validation.text = "Text can not be empty";
                validation.error = true;
                this.setState({ formErrors: validation });
            }
            else {
                validation.text = '';
                this.setState({ formErrors: validation });
            }
            if (this.state.title !== '' && this.state.title !== ' ' && this.state.text !== '' && this.state.text !== ' ') {
                validation.title = '';
                validation.text = '';
                validation.error = false;
                this.setState({ formErrors: validation });
            }
        }, 0);
    }

    handleSubmit = (e) => {

        const { title, text } = this.state;
        this.addTermsAndConditions(title, text);
        e.preventDefault();
        this.setState({
            title: '',
            text: ''
        })

    }

    closeModal = (e) => {
        this.setState({
            showModal: false,
            title: '',
            text: '',
            formErrors: { error: true, title: '', text: '' }
        })
    }

    openModal = (e) => {
        this.setState({ showModal: true })
    }

    render() {
        const { title, text } = this.state;

        const { error } = this.props;
        const { success } = this.props;

        if (success === false) {
            alert("error missing data or network " + error)
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Terms And Conditions added Successfully")
            this.props.emptyError();

            this.closeModal();
        }

        return (
            <Modal id="modal"
                trigger={
                    <button className="btn btn-lg btn-primary add-btn float-right mb-0"
                        type="submit" onClick={this.openModal}> + Add New </button>}
                onClose={this.closeModal} open={this.state.showModal}>
                <Modal.Header>Add New</Modal.Header>
                <Modal.Content >
                    <Modal.Actions>

                        <div className="container">
                            <form className="white" onSubmit={(e)=> e.preventDefault()}>
                                <div className="row">


                                    <div className="col">

                                        <label htmlFor="title" className="label">Title</label>
                                        <input
                                            dir="auto"
                                            className="form-control"
                                            name="title"
                                            id="title"
                                            onChange={this.handleChange}
                                            value={this.state.title} />
                                        <p style={{ color: "red" }}>{this.state.formErrors.title} </p>
                                        <br />

                                        <label htmlFor="text" className="label">Text</label>
                                        <textarea dir="auto"
                                            className="form-control"
                                            rows="6" name="text"
                                            id="episode_description"
                                            onChange={this.handleChange}
                                            value={this.state.text} />
                                        <p style={{ color: "red" }}>{this.state.formErrors.text} </p>
                                        <br />

                                    </div>

                                </div>

                                <div className="input-field">

                                    <Button onClick={this.handleSubmit} disabled={this.state.formErrors.error} id="saveButton" >
                                        Add
                                    </Button>


                                </div>
                                {error && <p>{error.message}</p>}
                            </form>


                        </div>
                    </Modal.Actions>
                </Modal.Content>
            </Modal>

        )
    }
}

AddTermsAndConditions.propTypes = {
    addTermsAndConditions: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    termsandconditions: state.termsandconditions.items,
    showModal: state.termsandconditions.showModal,
    error: state.termsandconditions.adding_all_terms_Conditions_error,
    success: state.termsandconditions.adding_all_terms_Conditions__sucessfully
});

export default withFirebase(connect(mapStateToProps, { addTermsAndConditions, emptyError })(AddTermsAndConditions));
