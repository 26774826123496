import React, { Component } from "react";
import { Link } from "react-router-dom";
import MasterCardLogo from "../../../images/portal/icons/mastercard-logo.svg";
import Subscribe from "./Subscribe";

class PaymentInputs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="PaymentInputs text-start">
        <div className="title blue">بيانات الدفع</div>
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label>الاسم بالكامل</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>عنوان الدفع</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>البلد</label>
              <input type="text" className="form-control" />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label>رمز البريد</label>
              <input type="number" min={0} className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label>الدولة</label>
              <input type="text" className="form-control" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentInputs;
