import {
    LOAD_BLOGS, FETCH_BLOGS, ERROR_LOADING_BLOGS, PUBLISH_BLOG, ERROR_ADDING_BLOG, LOAD_BLOG, GET_BLOG_DATA,
    ERROR_LOADING_BLOG, UPDATE_BLOG, ERROR_UPDATING_BLOG, EMPTY_BLOG, DELETE_BLOG, ERROR_DELETING_BLOG, SETTING_BLOG_STATUS, ERROR_SETTING_BLOG_STATUS
} from "./Types";
import axios, { post, put } from 'axios'

export const getAllBlogs = () => dispatch => {
    dispatch({
        type: LOAD_BLOGS,
        payload: 'Loading'
    })

    axios.get('/Blog/getAllBlogsList')
        .then((res) => {
            dispatch({
                type: FETCH_BLOGS,
                payload: res.data
            })
        }
        ).catch((Error) => {
            dispatch({
                type: ERROR_LOADING_BLOGS,
                payload: Error.message
            })
        })
}

export const saveBlog = (title, description, image, blog_html, publish_date, Blog_Category_ID) => {
    return (dispatch, getState, { getFirebase }) => {

        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Blog/addBlog"
            const formData = new FormData();
            formData.append('Title', title)
            formData.append('Description', description)
            formData.append('Image', image)
            formData.append('Blog_HTML', blog_html)
            formData.append('Publish_Date', publish_date)
            formData.append('Status', 'DEFAULT')
            formData.append('Blog_Category_ID', Blog_Category_ID)
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: PUBLISH_BLOG,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_ADDING_BLOG,
                    payload: Error.message
                })

            })
        })
    }

}

export const getBlogData = (id) => {
    return (dispatch, getState, { getFirebase }) => {


        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BLOG,
                payload: 'loading'
            })

            const url = "/Blog/getBlogDetails"
            const formData = new FormData();
            formData.append('Blog_ID', id)
            formData.append('Edit_Font', true)


            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: GET_BLOG_DATA,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_LOADING_BLOG,
                    payload: Error.message
                })

            })
        })
    }

}

export const updateBlog = (id, title, description, image, blog_html, publish_date, blogCategoryId) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: LOAD_BLOG,
                payload: 'loading'
            })
            const url = '/Blog/updateBlog'
            const formData = new FormData();
            formData.append('ID', id)
            formData.append('Title', title)
            formData.append('Description', description)
            formData.append('Image', image)
            formData.append('Blog_HTML', blog_html)
            formData.append('Publish_Date', publish_date)
            formData.append('Status', 'DEFAULT')
            formData.append('Blog_Category_ID', blogCategoryId)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }

            put(url, formData, config).then((res) => {
                dispatch({
                    type: UPDATE_BLOG,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_UPDATING_BLOG,
                    payload: Error.message
                })
            })

        })
    }

}

export const emptyBlog = () => dispatch => {
    dispatch({
        type: EMPTY_BLOG,
        payload: ''
    })
}

export const deleteBlog = (id) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Blog/deleteBlog"
            const formData = new FormData();
            formData.append("Blog_ID", id);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }

            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: DELETE_BLOG,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_DELETING_BLOG,
                    payload: Error.message
                })
            })
        })
    }
}

export const setBlogStatus = (id, status) => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            const url = "/Blog/setBlogStatus"
            const formData = new FormData();
            formData.append("ID", id);
            formData.append("Status", status);

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
                dispatch({
                    type: SETTING_BLOG_STATUS,
                    payload: res.data
                })
            }).catch((Error) => {
                dispatch({
                    type: ERROR_SETTING_BLOG_STATUS,
                    payload: Error.message
                })
            })

        })

    }
}