import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import mainLogo from "../../../images/portal/icons/logo.svg";
import { Modal, Transition } from "semantic-ui-react";
import LoginForm from "./LoginForm";
import { connect } from "react-redux";
import { customerSignIn, empty } from "../../../Actions/AuthActions";
import { signedInCustomer } from "../../../Actions/CustomerActions";
import { PORTAL_PRE_PAID, PORTAL_UPGRADE_SUBSCRIPTION } from "../../../routes";
import { Redirect } from "react-router-dom";
import { PRE_PAID_CARD } from "../constants";

const INITIAL_STATE = {
  errorMsg: "",
  error: false,
  email: "",
  password: "",
};

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleData = (email, password) => {
    this.setState({ error: false, errorMsg: "" });
    this.setState({
      email: email,
      password: password,
    });
  };
  handleLoginSubmit = () => {
    let obj = this.state;
    // call Login API
    this.props.signedInCustomer();
    this.props.customerSignIn(obj);
  };
  render() {
    const { loginsucess, loginerror, loginloading, paymentMethodName} = this.props;
    if (loginsucess) {
      let redirect = paymentMethodName === PRE_PAID_CARD ? PORTAL_PRE_PAID : PORTAL_UPGRADE_SUBSCRIPTION;
      return <Redirect to={redirect}> </Redirect>;
    }
    if (
      loginsucess === false &&
      loginerror !== "" &&
      loginerror !== false &&
      loginerror !== undefined
    ) {
      if (loginerror.code === "auth/network-request-failed") {
        this.setState({
          errorMsg: "فشل الاتصال بالأنترنت، يرجى المحاولة لاحقاً",
        });
      } else if (loginerror.code === "auth/user-not-found") {
        this.setState({
          errorMsg:
            "البريد الإلكتروني المُدخل غير موجود، فضلًا أدخل عنوان بريد فعَّال",
        });
      } else if (loginerror.code === "auth/wrong-password") {
        this.setState({
          errorMsg:
            "كلمة المرور المُدخلة غير صحيحة، برجاء التحقق من كلمة المرور",
        });
      } else if (loginerror.code === undefined) {
        this.setState({ errorMsg: loginerror });
      } else {
        this.setState({ errorMsg: "حدث خطأ ما، يرجى المحاولة لاحقاً" });
      }
      this.setState({ error: true });
      this.props.empty();
    }
    return (
      <div className="auth-modal-container">
        <Transition duration={500} visible={this.props.showLogin}>
          <Modal
            className="auth-modal login-modal show"
            closeOnDimmerClick={false}
            onClose={() => this.props.closeLoginModal()}
            open={this.props.showLogin}
          >
            <Modal.Header>
              <div className="d-flex align-items-center justify-content-between">
                <div className="modal-logo">
                  <img src={mainLogo} alt="" />
                </div>
                <button
                  type="button"
                  className="close m-0"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.props.closeLoginModal()}
                >
                  <FontAwesomeIcon icon={faTimes} className="faTimes" />
                </button>
              </div>
            </Modal.Header>
            <Modal.Content>
              <Modal.Actions>
                <p style={{ color: "red", textAlign: "right" }}>
                  {" "}
                  {this.state.error && this.state.errorMsg}
                </p>
                <form className="jeel-form">
                  <div className="mb-2">تسجيل الدخول</div>
                  <div className="orange-title mb-3">
                    لاكمال الطلب الخاص بك يجب ان تسجل دخولك للمنصة
                  </div>

                  <LoginForm onData={this.handleData} />
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="btn portal-main-btn jeel-form-submit mx-auto my-2"
                      onClick={this.handleLoginSubmit}
                      disabled={loginloading}
                    >
                      الدخول
                      {loginloading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="faTimes fa-spin mr-2"
                        />
                      )}
                    </button>
                  </div>
                </form>
                <div className="jeel-form-link d-flex align-items-center justify-content-center">
                  ليس لديك حساب؟
                  <button
                    type="button"
                    onClick={() => {
                      this.props.closeLoginModal();
                      this.props.openMainAuthModal();
                      // this.props.openSubAuthModal();
                    }}
                  >
                    انشاء حساب جديد
                  </button>
                </div>
              </Modal.Actions>
            </Modal.Content>
          </Modal>
        </Transition>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loginsucess: state.authUser.success,
  loginerror: state.authUser.errorMsg,
  loginloading: state.authUser.loading,
  paymentMethodName: state.paymentData.paymentMethod,
});

export default connect(mapStateToProps, {
  customerSignIn,
  signedInCustomer,
  empty,
})(LoginModal);
