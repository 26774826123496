import {
    FETCH_All_SERIES, GET_SERIES_DATA, SAVE_SERIES, LOAD_SERIESES, ERROR_LOADING_SERIESES,
    LOAD_SERIES, EMPTY, ERROR_LOADING_SERIES, ERROR_ADDING_SERIES,
    ERROR_SETTING_STATE_SERIES, SETTING_STATE_SERIES,
    ERROR_DELETING_SERIES, DELETING_SERIES,
    ERROR_UPDATE_SERIES, UPDATE_SERIES,
     EMPTY_SERIRS, ADD_SERIES_WITH_SENTFROM, GET_SERIES_TYPE, CLEAR_SERIES_TYPE
} from '../Actions/Types'


const initialState = {
    items: [],
    item: {},

    loading: '',
    error_loading_serieses: '',
    error_loading_series: '',

    error_adding_series: '',
    series_added_sucessfully: '',

    setting_state_series_error: "",
    setting_state_series_sucessfully: "",

    deleting_series_error: "",
    deleting_series_sucessfully: "",

    updating_series_sucessfully: "",
    updating_series_error: "",

    series_type :"",
}

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_SERIES_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }

        case FETCH_All_SERIES:

            return {
                ...state,
                items: action.payload,
                error_loading_serieses: '',
                loading: false
            }
        case GET_SERIES_DATA:

            return {
                ...state,
                item: action.payload,
                loading: false,
                error_loading_series: ''
            }
        case SAVE_SERIES:
            return {
                ...state,
                item: action.payload,
                items: state.items.concat(action.payload),
                series_added_sucessfully: true
            }
        case EMPTY:
            return {
                ...state,
                error_adding_series: action.payload,
                series_added_sucessfully: action.payload,

                setting_state_series_error: action.payload,
                setting_state_series_sucessfully: action.payload,

                deleting_series_sucessfully: action.payload,
                deleting_series_error: action.payload,

                updating_series_sucessfully: action.payload,
                updating_series_error: action.payload,

                loading: action.payload,

            }
        case EMPTY_SERIRS:
            return {
                ...state,
                item: {}
            }

        case LOAD_SERIESES:
            return {
                ...state,
                loading: true
            }
        case LOAD_SERIES:
            console.log("LOAD_SERIES")
            return {
                ...state,
                loading: true
            }

        case ERROR_LOADING_SERIESES:
            return {

                ...state,
                error_loading_serieses: action.payload,
                loading: false
            }

        case ERROR_LOADING_SERIES:
            return {
                ...state,
                error_loading_series: action.payload,
                loading: false
            }

        case ERROR_ADDING_SERIES:
            return {
                ...state,
                error_adding_series: action.payload,
                series_added_sucessfully: false
            }

        case GET_SERIES_TYPE:
            return{
                ...state,
                series_type:action.payload,
            }    
        case CLEAR_SERIES_TYPE:
            return{
                ...state,
                series_type:action.payload,
            }    

        case SETTING_STATE_SERIES:
            return {
                ...state,
                item: action.payload,
                setting_state_series_sucessfully: true,
                loading: false
            }

        case ERROR_SETTING_STATE_SERIES:
            return {
                ...state,
                setting_state_series_error: action.payload,
                loading: false
            }

        case DELETING_SERIES:
            return {

                ...state,
                item: action.payload,
                deleting_series_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_SERIES:
            return {

                ...state,
                deleting_series_error: action.payload,
                loading: false
            }

        case UPDATE_SERIES:
            return {
                ...state,
                item: action.payload,
                updating_series_sucessfully: true,
                updating_series_error: '',
                loading: false
            }

        case ERROR_UPDATE_SERIES:
            return {
                ...state,
                updating_series_error: action.payload,
                loading: false,
                updating_series_sucessfully: false
            }


        default:
            return state

    }

}
