import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateOTP, getvdoCipherStatus } from "../../Actions/VideoCipherActions";

function PreviewVideoWithMusic({ videoKey, id ,onVideoReady }) {
  const dispatch = useDispatch();
  const videoWithMusicEntity = useSelector(
    (state) => state.vdoCipher.videoWithMusic
  );
  const [videoStatus, setVideoStatus] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPreviewWithMusic, setVideoPreviewWithMusic] = useState(null);

  useEffect(() => {
    
    if (
      videoKey &&
      videoKey !== null &&
      videoKey !== undefined &&
      videoKey !== ""
    ) {
      dispatch(getvdoCipherStatus(videoKey, id));
    }
  },[]);

  useEffect(() => {
    if (
      videoKey &&
      videoKey !== null &&
      videoKey !== undefined &&
      videoKey !== ""
    ) {
      if (videoWithMusicEntity) {
        if (videoWithMusicEntity.status && videoWithMusicEntity.status !== videoStatus) {
          setVideoStatus(videoWithMusicEntity.status);
        }

        if (
          videoWithMusicEntity.otp &&
          videoWithMusicEntity.otp.otp !== null &&
          videoWithMusicEntity.otp.playbackInfo !== null
        ) {
          setVideoUrl(videoWithMusicEntity.otp.videoUrl);
        }
      }
    }
  }, [videoWithMusicEntity]);

  useEffect(() => {
    if (
      videoKey &&
      videoKey !== null &&
      videoKey !== undefined &&
      videoKey !== ""
    ) {
      if (videoStatus === "deleted") {
        setVideoPreviewWithMusic(
          <div className="previewText">
            Sorry, The video you're trying to view has been deleted.
          </div>
        );
      } else if (
        videoStatus === "Pre-Upload" ||
        videoStatus === "Queued" ||
        videoStatus === "Processing"
      ) {
        setVideoPreviewWithMusic(
          <div className="previewText">
            <p>Video has been uploaded and is being encoded and encrypted</p>
            <div className="loader-line"></div>
          </div>
        );
        dispatch(getvdoCipherStatus(videoKey, id));
      } else if (videoStatus === "ready") {
        if (videoUrl && videoUrl !== "" && videoUrl !== null && videoUrl !== undefined ) {
            setVideoPreviewWithMusic(
            <div className="previewText">
              <iframe
              title="Video is ready for playback"
                style={{ height: "360px" }}
                src={videoUrl}
                allowFullScreen="true"
                allow="encrypted-media"
              />
            </div>
          );
          if (onVideoReady) {
            onVideoReady();
          }
        }
        else {
            dispatch(generateOTP(videoKey,id));
        }
      }
    }
  }, [videoStatus,videoWithMusicEntity]);

  return <div>
    {videoKey &&
      videoKey !== null &&
      videoKey !== undefined &&
      videoKey !== "" ?  <div>{videoPreviewWithMusic}</div>:<div className="previewText">No Video for Preview</div>  }
  </div>;
}

export default PreviewVideoWithMusic;
