import {
    FETCH_All_SPECIAL_COUNTRIES_PRICE,
    ERROR_FETCH_All_SPECIAL_COUNTRIES_PRICE,

    LOAD_SPECIAL_COUNTRIES_PRICE,

    ADD_SPECIAL_COUNTRIES_PRICE,
    ERROR_ADD_SPECIAL_COUNTRIES_PRICE,

    DELETE_SPECIAL_COUNTRIES_PRICE,
    ERROR_DELETE_SPECIAL_COUNTRIES_PRICE,

    UPDATE_SPECIAL_COUNTRIES_PRICE,
    ERROR_UPDATE_SPECIAL_COUNTRIES_PRICE,

    EMPTY_SPECIAL_COUNTRIES_PRICE
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    showModal:'',
    loading: '',

    getting_all_special_countries_price_error: '',
    getting_all_special_countries_price_sucessfully: '',

    update_special_countries_price_error: '',
    update_special_countries_price_sucessfully: '',

    add_special_countries_price_error: '',
    add_special_countries_price_sucessfully: '',

    delete_special_countries_price_error: '',
    delete_special_countries_price_sucessfully: '',

}

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_All_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                items: action.payload,
                getting_all_special_countries_price_error: '',
                getting_all_special_countries_price_sucessfully: true,
                loading: false
            }

        case ERROR_FETCH_All_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                getting_all_special_countries_price_error: action.payload,
                loading: false
            }


        case ADD_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                items: action.payload,
                items: state.items.concat(action.payload),

                showModal: false,
                add_special_countries_price_error: '',
                add_special_countries_price_sucessfully: true,
                loading: false
            }

        case ERROR_ADD_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                add_special_countries_price_error: action.payload,
                add_special_countries_price_sucessfully: false,
                loading: false
            }

        case UPDATE_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                item: action.payload,
                showModal: false,
                update_special_countries_price_error: '',
                update_special_countries_price_sucessfully: true,
                loading: false
            }

        case ERROR_UPDATE_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                update_special_countries_price_error: action.payload,
                update_special_countries_price_sucessfully: false,
                loading: false
            }

        case DELETE_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                item: action.payload,
                delete_special_countries_price_error: '',
                delete_special_countries_price_sucessfully: true,
                loading: false
            }

        case ERROR_DELETE_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                delete_special_countries_price_error: action.payload,
                loading: false
            }
        case LOAD_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,
                loading: true
            }

        case EMPTY_SPECIAL_COUNTRIES_PRICE:
            return {
                ...state,                
                add_special_countries_price_error: '',
                add_special_countries_price_sucessfully: '',

                getting_all_special_countries_price_error: '',
                getting_all_special_countries_price_sucessfully: '',

                update_special_countries_price_error: '',
                update_special_countries_price_sucessfully: '',

                delete_special_countries_price_error: '',
                delete_special_countries_price_sucessfully: '',
                item: {},

            }
        default:
            return state

    }

}