import React, { Component } from 'react'

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import { post } from 'axios'


class MwTestComponent extends Component 
{

    state = {
        url:"",
        inputMsg:'',
        resMsg:'Initial Msg..........'
    }


// =========================================================================================================================================
// =========================================================================================================================================
// =========================================================================================================================================
    componentDidMount() {
        //this.setState({ url: this.props.t1 })
    }

   
    getQueryStringParam=(variable) =>
	{
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
	}
   
   
// =========================================================================================================================================
// =========================================================================================================================================
// =========================================================================================================================================

postToDB = () =>   {
	
	const be_URL = "/test/cleanDB"
			            let msisdn = this.getQueryStringParam('msisdn');
			            let addHeaders = this.getQueryStringParam('addHeaders');
			            let url = this.state.url;
			            let addHeader = addHeaders!=null && addHeaders!=undefined&&addHeaders.length >0?'add':'not';
			            
			            console.log('msisdn: '+msisdn+" , addHeader: "+addHeaders);
			            
			            const formData = new FormData();
			            formData.append('url', url)
			            formData.append('addHeaders',addHeader );
			            formData.append('msisdn', msisdn)
			            
			            const config = {
			                headers: {
			                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',                    
			                    //'X-Authorization-Firebase': token
			                }
			            }
			           
			            post(be_URL, formData, config).then((res) => {
				
																	console.log("-(4): return after BackEnd : "+JSON.stringify(res.data,null,3));
															
															this.setState({resMsg:res.data.msg});
															
														                //dispatch({
														                //    type: MW_Qatar_VERIFYING_CODE_SUCCESS,
														                //    payload: res.data
														                //})
														            })
			           								 .catch((Error) => {
				
																				if (Error.response)
																				{
																					console.log("Error.response: "+JSON.stringify(Error.response,null,3));
																					//do something														
																				}else if(Error.request)
																				{
																					console.log("Error.request: "+Error.request);
																					//do something else														
																				}else if(Error.message)
																				{
																					console.log("Error.message: "+Error.message);
																					//do something other than the other two														
																				}
										
																		});
}
   
   
handleUrlChange = (e) => {
        this.setState({url:e.target.value,inputMsg:e.target.value });
}   
   
// =========================================================================================================================================
// =========================================================================================================================================
// =========================================================================================================================================

postToUrl = () => {
						const be_URL = "/test/callUrl"
			            let msisdn = this.getQueryStringParam('msisdn');
			            let addHeaders = this.getQueryStringParam('addHeaders');
			            let url = this.state.url;
			            let addHeader = addHeaders!=null && addHeaders!=undefined&&addHeaders.length >0?'add':'not';
			            
			            console.log('msisdn: '+msisdn+" , addHeader: "+addHeaders);
			            
			            const formData = new FormData();
			            formData.append('url', url)
			            formData.append('addHeaders',addHeader );
			            formData.append('msisdn', msisdn)
			            
			            const config = {
			                headers: {
			                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',                    
			                    //'X-Authorization-Firebase': token
			                }
			            }
			           
			            post(be_URL, formData, config).then((res) => {
				
																	console.log("-(4): return after BackEnd : "+JSON.stringify(res.data,null,3));
															
															this.setState({resMsg:res.data.msg});
															
														                //dispatch({
														                //    type: MW_Qatar_VERIFYING_CODE_SUCCESS,
														                //    payload: res.data
														                //})
														            })
			           								 .catch((Error) => {
				
																				if (Error.response)
																				{
																					console.log("Error.response: "+JSON.stringify(Error.response,null,3));
																					//do something														
																				}else if(Error.request)
																				{
																					console.log("Error.request: "+Error.request);
																					//do something else														
																				}else if(Error.message)
																				{
																					console.log("Error.message: "+Error.message);
																					//do something other than the other two														
																				}
										
																		});


}
// =========================================================================================================================================
// =========================================================================================================================================
// =========================================================================================================================================
   
postToCurl = () => {
						const be_URL = "/test/curl"
			            let addHeaders = this.getQueryStringParam('addHeaders');
			            let curlUrl = this.state.url;
			            let addHeader = addHeaders!=null && addHeaders!=undefined&&addHeaders.length >0?'add':'not';
			            
			            console.log('url: '+curlUrl+" , addHeader: "+addHeaders);
			            
			            const formData = new FormData();
			            formData.append('curlUrl', curlUrl)
			            formData.append('addHeaders',addHeader );
			            
			            const config = {
			                headers: {
			                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',                    
			                    //'X-Authorization-Firebase': token
			                }
			            }
			           
			            post(be_URL, formData, config).then((res) => {
				
																	console.log("-(4): return after BackEnd : "+JSON.stringify(res.data,null,3));
															
															this.setState({resMsg:res.data.msg});
															
														                //dispatch({
														                //    type: MW_Qatar_VERIFYING_CODE_SUCCESS,
														                //    payload: res.data
														                //})
														            })
			           								 .catch((Error) => {
				
																				if (Error.response)
																				{
																					console.log("Error.response: "+JSON.stringify(Error.response,null,3));
																					//do something														
																				}else if(Error.request)
																				{
																					console.log("Error.request: "+Error.request);
																					//do something else														
																				}else if(Error.message)
																				{
																					console.log("Error.message: "+Error.message);
																					//do something other than the other two														
																				}
										
																		});


}
// =========================================================================================================================================
// =========================================================================================================================================
// =========================================================================================================================================

    render() 
    {
	
		console.log('QatarSuccess page: props is :'+JSON.stringify(this.props,null,3));
		console.log('QatarSuccess page: state is :'+JSON.stringify(this.state,null,3));
		
		
		
		return (
            <div>
					<h1> Welcom in testing Page </h1>
					<br/>
					Input Url is :  {this.state.inputMsg}
					<br/>
					<hr/>
					Response Message is :  {this.state.resMsg}
					<br/>
					<hr/>
					<input type="text" id="urlId" name="urlId" value={this.state.url} onChange={this.handleUrlChange} />
					
					
				                            
					<br/><br/><br/>
					
					<button  onClick={this.postToUrl} > Click url </button>

					<br/><br/><br/>

					<button  onClick={this.postToDB} > Change DB </button>

					<br/><br/>
					<button  onClick={this.postToCurl} > Simulate Curl </button>

					<br/><br/>					
					Known Requests Bah:  
					
					<br/><br/>
					
					<h4> 	Sub    : https://mediaworldsdp.com/api/get/users.send_pincode?api_key=5e80ca9fcb8a98921e61afdd95e78f76&msisdn=962777111111&channel_id=15 </h4>
					<h4> 	varivy : https://mediaworldsdp.com/api/get/users.subscribe_pincode?api_key=5e80ca9fcb8a98921e61afdd95e78f76&msisdn=962777111111&channel_id=15 </h4>
					<h4> 	Cancel : https://mediaworldsdp.com/api/get/users.unsub_user/?api_key=5e80ca9fcb8a98921e61afdd95e78f76&msisdn=962777111111&channel_id=15 </h4>					                 
					<h4> 	SMS    : https://mediaworldsdp.com/api/get/users.send_mt?api_key=5e80ca9fcb8a98921e61afdd95e78f76&msisdn=962777111111&channel_id=15			 </h4>			


					<h4>             http://localhost:9091/zain-rest/success2?api_key=5e80ca9fcb8a98921e61afdd95e78f76&msisdn=962777111111&channel_id=15 </h4>
					
					<br/>
					<hr/>
					<br/>					
					Known Requests Qat: 					
					<br/>
					<h4> 	Sub    : https://mediaworldsdp.com/api/get/users.send_pincode?api_key=4348d0be60857c28ee6b0e98cdacf802&msisdn=962777111111&channel_id=20 </h4>
					<h4> 	varivy : https://mediaworldsdp.com/api/get/users.subscribe_pincode?api_key=4348d0be60857c28ee6b0e98cdacf802&msisdn=962777111111&channel_id=20 </h4>
					<h4> 	Cancel : https://mediaworldsdp.com/api/get/users.unsub_user/?api_key=4348d0be60857c28ee6b0e98cdacf802&msisdn=962777111111&channel_id=20 </h4>					                 
					<h4> 	SMS    : https://mediaworldsdp.com/api/get/users.send_mt?api_key=4348d0be60857c28ee6b0e98cdacf802&msisdn=962777111111&channel_id=20			 </h4>			
					
            </div>
        )
    }
}


// =========================================================================================================================================
// =========================================================================================================================================
// =========================================================================================================================================

const mapStateToProps = state => ({

    //successMessage: state.successMessages.item,
});


export default withRouter
    (connect
        (mapStateToProps,
            {
                //removeSuccessMessage,
            })
        (MwTestComponent)
    );

