import React, { Component } from 'react';
import Loader from '../Layout/Loader';

import ReactPlayer from 'react-player'
import '../../../node_modules/video-react/dist/video-react.css';

import { ProgressBar } from 'react-bootstrap';

var ReactS3Uploader = require('react-s3-uploader');

const INITIAL_STATE = {
    videoWithMusic: "",
    videoWithMusicPreviewUrl: "",
    videoWithMusicUrl: "",
    videoError: "",
    completed: 0,
    no_visible: false,
    s3DirectryrName: '',
    duration: '',
    uploadingDone: false
}

const _validFileExtensions = [".mp4", ".3gp", ".wmv", ".flv"];



function dateNaming() {
    var date = new Date();
    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    //January is 0! 
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }

    date = dd + "_" + mm + "_" + yyyy + "_" + date.getHours() + "_" + date.getMinutes();
    // console.log(date)
    return date;
}

function namingFile(sFileName) {
    // console.log(sFileName)
    let fileExtention = sFileName.split('.').pop();
    // console.log(fileExtention)

    let sFileNameWithoutExtention = sFileName.split('.').slice(0, -1).join('.')
    // console.log(sFileNameWithoutExtention)

    let sFileNameWithoutExtentionEncoded =
        unescape(encodeURIComponent(sFileNameWithoutExtention));
    // console.log(sFileNameWithoutExtentionEncoded);

    // for decodeing
    // let sFileNameWithoutExtentionEncoded = 
    // decodeURIComponent( escape(  sFileNameWithoutExtention ) );


    let date = dateNaming();
    sFileName = sFileNameWithoutExtentionEncoded + date + "." + fileExtention
    // console.log("NewName = " + sFileName)

    return sFileName
}



class UploadVideoWithoutDropZone extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE
    }

    componentWillReceiveProps(nextProp) {
        if (nextProp.videoPreview !== undefined ||
            nextProp.videoUrl !== undefined ||
            nextProp.s3DirectryrName !== undefined) {
            this.setState({
                videoWithMusicPreviewUrl: nextProp.videoPreview,
                videoWithMusicUrl: nextProp.videoUrl,
                s3DirectryrName: nextProp.s3DirectryrName

            })
        }
    }

    onUploadProgress = (percent) => {

        this.setState({ no_visible: false, completed: percent , duration:'' });
    }

    onError = (errror) => {
        alert(errror)
    }
    onUploadFinish = (e) => {

        this.setState({
            videoWithMusicPreviewUrl: e.publicUrl,
            videoWithMusicUrl: e.publicUrl,
            no_visible: true,
            uploadingDone: true

        })
        console.log("video url", e.publicUrl)
        // this.props.getVideoURLCallBack(e.publicUrl);

    }

    handleDuration = (duration) => {
        console.log('handleDuration = ', duration)
        this.setState({ duration: duration })
    }

    callbackdata = () => {

        console.log("callbackdata")
        console.log(this.state.videoWithMusicPreviewUrl)
        console.log(Math.ceil(this.state.duration))

        this.props.getVideoURLCallBack(
            this.state.videoWithMusicPreviewUrl,
            Math.ceil(this.state.duration));

        this.setState({
            uploadingDone: false,
            duration: ''
        })
    }




    render() {

        const { s3DirectryrName } = this.state;

        let uploadinglink = 'newgeneprivate';
        if (s3DirectryrName !== '') {
            uploadinglink = 'newgeneprivate/' + s3DirectryrName;
        }
        // uploading generalSetting videos to public bucket
        if (s3DirectryrName === 'generalsetting') {
            uploadinglink = 'newgenepublic/generalsetting'
        }
        // console.log(uploadinglink)


        let { videoWithMusicUrl, videoError } = this.state
        let loadVideoWithMusic = null;
        let { videoWithMusicPreviewUrl } = this.state;
        let $videoWithMusicPreview = null;

        let $noDropzone = (
            <div class="custom-file">
                <div className=''>
                    <ReactS3Uploader accept="video/*"
                        signingUrl="/W3S/getPresignedURL"
                        signingUrlMethod="POST"
                        onProgress={this.onUploadProgress}
                        onError={this.handleError}
                        onFinish={this.onUploadFinish}
                        signingUrlHeaders={{
                            'content-type': 'application/json'
                        }}
                        signingUrlQueryParams={{ 'bucketName': uploadinglink }}
                        uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
                        contentDisposition="auto"
                        scrubFilename={(filename) => new Date().getTime()}
                    />
                    <br />
                    <div>
                        <ProgressBar striped bsstyle="success"
                            hidden={this.state.no_visible}
                            now={this.state.completed}
                            label={`${this.state.completed}%`} />
                    </div>
                </div>

            </div>
        );
        let $dropzoneWithPlayer = (
            <div>
                <section className="video_section" >
                    <ReactPlayer
                        playsinline
                        fluid
                        url={videoWithMusicPreviewUrl}
                        onDuration={this.handleDuration}
                        playing={true}
                        controls={true}
                        width="100%"
                        className='react-player'

                    />

                    {/* <Player playsInline fluid src={videoWithMusicPreviewUrl} /> */}
                </section>
                <br />
                <div className="custom-file">
                    <ReactS3Uploader accept="video/*"
                        signingUrl="/W3S/getPresignedURL"
                        signingUrlMethod="POST"
                        onProgress={this.onUploadProgress}
                        onError={this.handleError}
                        onFinish={this.onUploadFinish}
                        signingUrlHeaders={{
                            'content-type': 'application/json'
                        }}
                        signingUrlQueryParams={{ 'bucketName': uploadinglink }}
                        uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
                        contentDisposition="auto"
                        scrubFilename={(filename) => new Date().getTime()}
                    />
                    <br />
                    <div>
                        <ProgressBar striped bsstyle="success"
                            hidden={this.state.no_visible}
                            now={this.state.completed}
                            label={`${this.state.completed}%`} />
                    </div>

                </div>
            </div>
        );

        if (videoWithMusicPreviewUrl) {

            if (videoWithMusicUrl === '') {
                if (videoError === '') { loadVideoWithMusic = (<Loader />) }

                else if (videoError) {
                    $videoWithMusicPreview = $dropzoneWithPlayer;
                    this.setState({ videoError: '', videoWithMusicPreviewUrl: '' })
                }
            }
            else {
                if (this.state.uploadingDone === true &&
                    this.state.duration !== '') {

                    this.callbackdata();
                }
                $videoWithMusicPreview = $dropzoneWithPlayer
            }
        }
        else {
            $videoWithMusicPreview = $noDropzone;
        }

        return (
            <div>
                {$videoWithMusicPreview}
                {loadVideoWithMusic}
            </div>
        )
    }
}

export default UploadVideoWithoutDropZone
