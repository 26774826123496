
export default [
    {
        key: 'none',
        text: 'none',
        value: 'none'
    },
    {
        key: 'SUSPENDED',
        text: 'suspended',
        value: 'SUSPENDED'
    },
    {
        key: 'DEFAULT',
        text: 'default',
        value: 'DEFAULT'
    }

]
