import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types';

/* Components */
import Footer from '../Layout/Footer';

/* Assets */
import mainLogoM from '../../../images/portal/logo-big.png';
import succIcon from '../../../images/portal/succ-sign.svg';
import Loader from '../../../components/Layout/Loader'

/* Actions */
import {
  verifyingCode,
  resendCode,
  emptyFit
} from '../../../Actions/FitActions';

/** ROutes */
import * as ROUTES from '../../../routes';

import * as CONSTANTS from '../../../constants';

const INITIAL_STATE = {
    code: '',
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    redirect: false,
    confirmResult: '',
    errorMsg: '',
    phone: '',
    telecomComany: '',
    codeLength: 6
}

class FitVCode extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

    componentDidMount() {
        let phone = this.props.match.params.phone
        let telecomCompany = this.props.match.params.telecomCompany;
        if(telecomCompany === CONSTANTS.FIT_STC || telecomCompany === CONSTANTS.FIT_ZAIN){
            this.setState({
                codeLength: 4,
                phone: phone,
                telecomCompany: telecomCompany,
            })
        }else{
            this.setState({
                phone: phone,
                telecomCompany: telecomCompany,
            })
        }        
    }

    handleNext = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        let name = e.target.name;
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
        this.setState({
            code: this.state.code1
            + this.state.code2
            + this.state.code3
            + this.state.code4
            + this.state.code5
            + this.state.code6
        })
        }, 1000)
        if (name === 'code1')
        this.secondTextInput.focus();
        if (name === 'code2')
        this.thirdTextInput.focus();
        if (name === 'code3')
        this.forthTextInput.focus();
        if (name === 'code4')
        this.fifthTextInput.focus();
        if (name === 'code5')
        this.sixthTextInput.focus();
    }

    handleNextSTCAndZAIN = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        let name = e.target.name;
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
        this.setState({
            code: this.state.code1
            + this.state.code2
            + this.state.code3
            + this.state.code4
        })
        }, 1000)
        if (name === 'code1')
        this.secondTextInput.focus();
        if (name === 'code2')
        this.thirdTextInput.focus();
        if (name === 'code3')
        this.forthTextInput.focus();
    }

    submit = (e) => {
        const { code, phone, telecomCompany } = this.state;

        if (phone !== '' && phone !== null
        && phone !== undefined && phone !== ' ' &&

        telecomCompany !== '' && telecomCompany !== null
        && telecomCompany !== undefined && telecomCompany !== ' ' &&

        code !== '' && code !== null
        && code !== undefined && code !== ' ') {

        this.props.verifyingCode(code, telecomCompany, phone);
        }
        else {
        this.setState({ errorMsg: "لقد حدث خطأ من فضلك حاول مره اخرى" })

        }

        e.preventDefault();

    }

    resend = (e) => {
        console.log("resend")
        const { phone, telecomCompany } = this.state;

        if (phone !== '' && phone !== null
        && phone !== undefined && phone !== ' ' && telecomCompany !== '' && telecomCompany !== null
        && telecomCompany !== undefined && telecomCompany !== ' '
        ) {
        this.props.resendCode(phone, telecomCompany);
        }
        else {
        this.setState({ errorMsg: "لم نتمكن من إرسال كود التفعيل، يرجى المحاولة مرة اخرى" })
        }

    }

    render() {
        const { fit, loading,
        verifyingError, verifyingSuccess,
        resendCodeError, resendCodeSuccess, resendLoading } = this.props;

        let loader = null;

        if (loading === true) {
        loader = (<Loader />)
        }

        // verifying send code
        if (verifyingSuccess === true
        && fit !== undefined
        && fit !== null) {
            let redirect = ROUTES.TELECOM_SUCCESS + "/Fit/" + this.state.telecomCompany + "/" + this.state.phone;
            this.props.emptyFit(); 
            return (<Redirect to={redirect}> </Redirect>);
        }
        // error in calling API
        else if (verifyingSuccess === false &&
        verifyingError !== '' &&
        verifyingError !== undefined
        ) {
            this.setState({ errorMsg: verifyingError })
            this.props.emptyFit();
        }

        // resend code
        if (resendCodeSuccess === true
        && fit !== undefined
        && fit !== null) {
            this.setState({code: '', code1:'',code2:'', code3:'', code4:'', code5:'', errorMsg: "تم ارسال الرمز بنجاح" })
            this.props.emptyFit();
        }
        // error in calling API
        else if (resendCodeSuccess === false &&
        resendCodeError !== '' &&
        resendCodeError !== undefined
        ) {
            this.setState({ errorMsg: resendCodeError })
            this.props.emptyFit();
        }

        let phoneSectionFit = (
        <div className="card-content text-center">
            <p style={{ color: 'red', textAlign: 'center' }}> {this.state.errorMsg}</p>
            <div className="card-title h4" id="recaptcha-container">تم ارسال الرمز</div>
            <div className="code-icon">
            <img src={succIcon} alt="" />
            </div>
            <div className="description-text">
            <span>فضلًا، أدخل الرمز المكون من {this.state.codeLength} أرقام، والمرسل في رسالة نصية إلى رقم </span>
            <span className="phone-number">{this.state.phone}</span>
            </div>
            <form className="code-form">
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1" autoFocus
                name="code1" value={this.state.code1}
                onChange={this.handleNext}
                blurOnSubmit={false}
                />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                name="code2" value={this.state.code2}
                ref={(input) => { this.secondTextInput = input; }}
                onChange={this.handleNext} />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                name="code3" value={this.state.code3}
                ref={(input) => { this.thirdTextInput = input; }}
                onChange={this.handleNext} />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                name="code4" value={this.state.code4}
                ref={(input) => { this.forthTextInput = input; }}
                onChange={this.handleNext} />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                name="code5" value={this.state.code5}
                ref={(input) => { this.fifthTextInput = input; }}
                onChange={this.handleNext} />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                name="code6" value={this.state.code6}
                ref={(input) => { this.sixthTextInput = input; }}
                onChange={this.handleNext} />
            </div>
            </form>
            <div className="form-group custom-btn">
            <button type="submit" className="btn phone-btn" onClick={this.submit}>تأكيد الاشتراك</button>
            </div>

        </div>
        )

        let phoneSectionSTCAndZAIN = (
            <div className="card-content text-center">
            <p style={{ color: 'red', textAlign: 'center' }}> {this.state.errorMsg}</p>
            <div className="card-title h4" id="recaptcha-container">تم ارسال الرمز</div>
            <div className="code-icon">
                <img src={succIcon} alt="" />
            </div>
            <div className="description-text">
                <span>فضلًا، أدخل الرمز المكون من {this.state.codeLength} أرقام، والمرسل في رسالة نصية إلى رقم </span>
                <span className="phone-number">{this.state.phone}</span>
            </div>
            <form className="code-form">
                <div className="form-group">
                <input type="text" className="form-control" maxlength="1" autoFocus
                    name="code1" value={this.state.code1}
                    onChange={this.handleNextSTCAndZAIN}
                    blurOnSubmit={false}
                />
                </div>
                <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                    name="code2" value={this.state.code2}
                    ref={(input) => { this.secondTextInput = input; }}
                    onChange={this.handleNextSTCAndZAIN} />
                </div>
                <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                    name="code3" value={this.state.code3}
                    ref={(input) => { this.thirdTextInput = input; }}
                    onChange={this.handleNextSTCAndZAIN} />
                </div>
                <div className="form-group">
                <input type="text" className="form-control" maxlength="1"
                    name="code4" value={this.state.code4}
                    ref={(input) => { this.forthTextInput = input; }}
                    onChange={this.handleNextSTCAndZAIN} />
                </div>
            </form>
            <div className="form-group custom-btn">
                <button type="submit" className="btn phone-btn" onClick={this.submit}>تأكيد الاشتراك</button>
            </div>
    
            </div>
        )

        let phoneSection = '';
        if(this.state.telecomCompany === CONSTANTS.FIT_MOBILY){
            phoneSection = phoneSectionFit;
        }else{
            phoneSection = phoneSectionSTCAndZAIN;
        }

        if (resendLoading === true) {
        phoneSection = (<Loader />)
        }

        return (
        <body>
            <div className="other-methods-container">

            <section className="jeel-fit">
                <div className="logo-container">
                <div className="container">
                    <div className="logo">
                    <img src={mainLogoM} alt="logo" />
                    </div>
                </div>
                </div>

                <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col-12 col-md-6">
                    <div className="center fit-about-text">
                        <div className="title h2 white ">
                        <center>
                            بعد الاشتراك ، كل ما عليك الآن هو
                        </center>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row justify-content-md-center">

                    <div className="col-12 col-md-6">
                    <div className="  main-progress-bar">
                        <ol className="progress-tracker">
                        <li className="step fitbg">
                            <span className="step-name small-font">
                            <span className="bold">
                                الاشتراك
                            </span>
                            </span>
                        </li>
                        <li className="step fit">
                            <span className="step-name small-font">
                            <span className="bold">
                                رمز التحقق
                            </span>
                            </span>
                        </li>
                        <li className="step fit">
                            <span className="step-name small-font">
                            <span className="notbold">
                                تحميل التطبيق
                            </span>
                            </span>
                        </li>
                        </ol>
                    </div>
                    </div>
                </div>
                </div>
                <br />
                <br />
            </section>

            {loader}
            <section className="dont-worry">
                <div className="container">
                <div className="dont-wory-card">
                    {phoneSection}
                </div>
                <div className="resend-code">
                    <span>لم تصلك رسالة التأكيد؟ </span>
                    <span>
                    <button className="resend"
                        type="submit"
                        onClick={this.resend}>أعد إرسال الرمز</button>
                    </span>
                </div>
                </div>
            </section>
            </div>
            <Footer />
        </body>
        )
    }
}

FitVCode.propTypes = {
  verifyingCode: propTypes.func.isRequired,
  resendCode: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
  fit: state.fit.item,
  loading: state.fit.loading,

  verifyingSuccess: state.fit.verifying_successfully,
  verifyingError: state.fit.verifying_error,

  resendCodeSuccess: state.fit.resend_code_successfully,
  resendCodeError: state.fit.resend_code_error,
  resendLoading: state.fit.resendLoading

});

export default withRouter
  (connect

    (mapStateToProps,
      {
        verifyingCode,
        resendCode,
        emptyFit
      })
    (FitVCode)
  );
