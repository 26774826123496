import React, { Component } from 'react'
import { withFirebase } from '../../config';
import { Button } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

/*Navbar*/
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';

/*Redux */
import 'firebase/firestore';
import 'firebase/auth';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
/*Actions */
import {
    saveGameParent,
    fetchAllGameParent,
    emptyError
} from '../../Actions/GameParentAction';
/*Component */
import PublishDate from '../Layout/PublishDate';
import UploadImage from '../S3Uploading/UploadImage';
import UploadAudio from '../S3Uploading/UploadAudio';
import UploadVideo from '../S3Uploading/UploadVideo';

import { ALL_GAME_PARENTS } from '../../routes';

const INITIAL_STATE = {

    name: '',
    description: '',
    name_in_english: '',
    description_in_english: '',
    publish_date: '',
    imgURL: '',
    audio: '',
    promo_with_music: '',
    promo_without_music: '',
    featured: false,
    error: true,
    errorMessage: ''
};

class AddGameParent extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    saveGameParent(name, description, imgURL, publish_date, audio, promo_with_music, promo_without_music, name_in_english, description_in_english, featured) {

        this.props.saveGameParent(name, description, imgURL, publish_date, audio, promo_with_music, promo_without_music, name_in_english, description_in_english, featured)
    }

    getPublishDate(publishDateTime) {

        if (publishDateTime) {
            this.setState({ publish_date: publishDateTime });

        }
    }

    getImageUrl(imageUrl) {
        console.log(imageUrl)

        if (imageUrl === 'delete') {
            this.setState({ imgURL: '', error: true, errorMessage: "Empty Data is not accepted" });
            console.log("delete")

        }
        else if (imageUrl !== undefined || imageUrl !== '') {
            this.setState({ imgURL: imageUrl });
            console.log(imageUrl)
            if (this.state.name !== '' && this.state.name !== ' ' && this.state.name !== null
                // && this.state.name_in_english !== '' && this.state.name_in_english !== ' ' && this.state.name_in_english !== null
                ) {
                this.setState({ error: false, errorMessage: "" });
            }
        }
        else {
            this.setState({ imgURL: '', error: true, errorMessage: "Empty Data is not accepted" });
            console.log("error")

        }
    }

    getPromoWithMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ promo_with_music: videoUrl });
            console.log("promo_with_music in series = "
                + this.state.promo_with_music);
        }
        else {
            this.setState({ promo_with_music: '' });
        }
    }

    getPromoWithoutMusicVideoUrl(videoUrl) {

        if (videoUrl !== "delete") {
            this.setState({ promo_without_music: videoUrl });
            console.log("promo_without_music in series = "
                + this.state.promo_without_music);
        } else {
            this.setState({ promo_without_music: '' });
        }
    }

    handleChange = (e) => {
        //
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        let nameOfState = e.target.name;
        setTimeout(() => {
            if ((this.state.imgURL === ''
                || this.state.imgURL === null
                || this.state.imgURL === undefined)
                || (this.state.name === ''
                    || this.state.name === null
                    || this.state.name === ' ')
                || this.state.description_in_english.length > 255    
                // || (this.state.name_in_english === ''
                //     || this.state.name_in_english === null
                //     || this.state.name_in_english === ' ')
                    ) {
                    this.setState({ error: true, errorMessage: nameOfState === "description_in_english" ? 
                        "Description in English is too long (greater than 255 characters)." : 
                        "Empty Data is not accepted" });
            }
            else if ((this.state.imgURL !== ''
                || this.state.imgURL !== undefined)
                && (this.state.name !== null
                    || this.state.name !== ''
                    || this.state.name !== ' ')
                    && this.state.description_in_english.length <= 255    
                // && (this.state.name_in_english !== null
                //         || this.state.name_in_english !== ''
                //         || this.state.name_in_english !== ' ')
                        ) {
                this.setState({ error: false, errorMessage: "" });
            }
            else {
                this.setState({
                    error: true,
                    errorMessage: "Empty Data is not accepted"
                });
            }
        }, 0);
    }

    handleSubmit = (e) => {

        const { name, description,
            publish_date, imgURL, audio,
            promo_with_music, promo_without_music, name_in_english, description_in_english, featured } = this.state;


        if (e.nativeEvent.submitter.id === "saveButton") {
            this.saveGameParent(name, description, imgURL, publish_date, audio, promo_with_music, promo_without_music, name_in_english, description_in_english, featured);
        }
        // this.closeModal();
        e.preventDefault();
    }

    handleCheckboxChange = () => {
        this.setState({ featured: !this.state.featured })
      };
    getAudio(audioUrl) { // get  Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ audio: '' });
            }
            // else send the url to DB
            else {
                this.setState({ audio: audioUrl });

            }

        }
    }


    render() {
        const { error } = this.state;

        const { addingError, success, loading } = this.props;

        let $buttonSubmitLoader = (
            <Button
                disabled={this.state.error} id="saveButton" >
                Add
            </Button>);

        if (loading === true) {
            $buttonSubmitLoader = (
                <button id="saveButton"
                    class="ui loading button">
                    Loading</button>);
        }

        if (success === false) {
            alert("error missing data or network " + addingError)
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Game Parent added Successfully")
            this.props.emptyError();
            this.props.fetchAllGameParent();

            this.setState({
                name: '',
                description: '',
                publish_date: '',
                imgURL: '',
                audio: '',
                promo_with_music: '',
                promo_without_music: '',
                name_in_english: '',
                description_in_english: '',
                featured: false,
            })

            return (<Redirect to={ALL_GAME_PARENTS} ></Redirect>)

        }


        return (
            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <h3>Add New Game Parent</h3>
                                                <hr />


                                                <div className="">

                                                    <form onSubmit={this.handleSubmit.bind(this)}>
                                                        <div className="row">

                                                            {/* image Uploader */}
                                                            <div className="col-4">
                                                                <label className="label">
                                                                    <span className="required_input">*</span>
                                                                    Image
                                                                </label>
                                                                <UploadImage
                                                                    getImageURLCallBack={this.getImageUrl.bind(this)}
                                                                    imageUrl={this.state.imgURL}
                                                                    imagepreview={this.state.imgURL}
                                                                    s3DirectryrName='game' />


                                                            </div>

                                                            <div className="col">
                                                                <p style={{ color: "red" }}>{this.state.errorMessage} </p>
                                                                <label htmlFor="name" className="label">
                                                                    <span className="required_input">*</span>
                                                                    Name</label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    fluid name="name" id="name" onChange={this.handleChange}
                                                                    value={this.state.name} />
                                                                <br />

                                                                <label htmlFor="name_in_english" className="label">
                                                                    {/* <span className="required_input">*</span> */}
                                                                    Name In English</label>
                                                                <input
                                                                    dir="auto"
                                                                    className="form-control"
                                                                    fluid name="name_in_english" id="name_in_english" onChange={this.handleChange}
                                                                    value={this.state.name_in_english} />
                                                                <br />

                                                                <div className="form-group">
                                                                    <label htmlFor="description" className="label">
                                                                        Description
                                                                    </label>

                                                                    <textarea dir="auto"
                                                                        fluid rows="3"
                                                                        className="form-control"
                                                                        name="description"
                                                                        id="description"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.description} />
                                                                </div>
                                                                <br />

                                                                <div className="form-group">
                                                                    <label htmlFor="description_in_english" className="label">
                                                                        Description In English
                                                                    </label>

                                                                    <textarea dir="auto"
                                                                        fluid rows="3"
                                                                        className="form-control"
                                                                        name="description_in_english"
                                                                        id="description_in_english"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.description_in_english} />
                                                                </div>
                                                                <br />



                                                                <label htmlFor="name" className="label">
                                                                    Audio
                                                                </label>

                                                                <UploadAudio
                                                                    getAudioUrlCallBack={this.getAudio.bind(this)}

                                                                    audioPreview={this.state.audio}
                                                                    audioUrl={this.state.audio}
                                                                    s3DirectryrName="game/audio"
                                                                />
                                                                <br />

                                                                {/* DateComponent */}
                                                                <PublishDate
                                                                    getPublishDateCallBack={this.getPublishDate.bind(this)}
                                                                    value={this.state.publish_date} />

                                                                <br />
                                                                    <label>
                                                                        <input  
                                                                            type="checkbox"
                                                                            checked={this.state.featured}
                                                                            onChange={this.handleCheckboxChange}
                                                                        />
                                                                        {' Featured'}
                                                                        </label>
                                                                <br />

                                                            </div>

                                                        </div>

                                                        {error && <p>{error.message}</p>}

                                                        <label className="label">
                                                            Promo with music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getPromoWithMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.promo_with_music}
                                                                videoUrl={this.state.promo_with_music}
                                                                s3DirectryrName='game'
                                                            />
                                                        </div>
                                                        <br />

                                                        <label className="label">
                                                            Promo without music</label>
                                                        <div >
                                                            <UploadVideo
                                                                getVideoURLCallBack={this.getPromoWithoutMusicVideoUrl.bind(this)}
                                                                videoPreview={this.state.promo_without_music}
                                                                videoUrl={this.state.promo_without_music}
                                                                s3DirectryrName='game'
                                                            />
                                                        </div>
                                                        <br />


                                                        <div className="input-field">

                                                            {$buttonSubmitLoader}
                                                        </div>
                                                    </form>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>


        )
    }
}

AddGameParent.propTypes = {
    saveGameParent: propTypes.func.isRequired,
    fetchAllGameParent: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    loading: state.gameParent.loading,

    gameParent: state.gameParent.items,
    addingError: state.gameParent.error_adding_game_parent,
    success: state.gameParent.game_parent_added_sucessfully
});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                saveGameParent,
                emptyError,
                fetchAllGameParent
            }
        )
        (AddGameParent)
    );

