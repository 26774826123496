import React, { Component } from 'react';
import Loader from '../Layout/Loader';
import { ProgressBar } from 'react-bootstrap';

var ReactS3Uploader = require('react-s3-uploader');



const INITIAL_STATE = {
    Image: "",
    imagePreviewUrl: "",
    imageUrl: "",
    imageError: '',
    completed: 0,
    no_visible: false,
    s3DirectryrName: ''

}


class UploadImageWithoutDropZone extends Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE
    }

    /// for reading data from other components
    componentWillMount() {
        if (this.props.imageUrl !== undefined ||
            this.props.imagepreview !== undefined ||
            this.props.s3DirectryrName !== undefined) {

            this.setState({
                imagePreviewUrl: this.props.imagepreview,
                imageUrl: this.props.imageUrl,
                s3DirectryrName: this.props.s3DirectryrName
            })
            // console.log(this.props.s3DirectryrName)
        }
    }

    componentWillReceiveProps(nextProp) {

        // console.log(nextProp)

        if (nextProp.imageUrl !== undefined ||
            nextProp.imagepreview !== undefined ||
            nextProp.s3DirectryrName !== undefined
        ) {
            this.setState({
                imagePreviewUrl: nextProp.imagepreview,
                imageUrl: nextProp.imageUrl,
                s3DirectryrName: nextProp.s3DirectryrName

            })
            // console.log(nextProp.s3DirectryrName)
        }
    }
    componentDidMount() {
    }


    onUploadProgress = (percent) => {
        this.setState({ no_visible: false, completed: percent });
    }

    onError = (errror) => {
        alert(errror)
    } 
    onUploadFinish = (e) => {

        this.setState({
            imagePreviewUrl: e.publicUrl,
            imageUrl: e.publicUrl,
            no_visible: true
        })
        console.log("image url", e.publicUrl)
        
        let bookPageID = this.props.bookPageID ? this.props.bookPageID : ''
        if(bookPageID != null || bookPageID != '') {
            this.props.getImageURLCallBack(e.publicUrl, bookPageID);
        } else {
            this.props.getImageURLCallBack(e.publicUrl);
        }
        
        console.log("onUploadFinish = " + e.publicUrl);
    }


    render() {

        let { imageError,
            imagePreviewUrl,
            imageUrl,
            s3DirectryrName } = this.state;
        let $imagePreview = null;
        let loadImage = null;

        let uploadinglink = 'newgenepublic';
        if (s3DirectryrName !== '') {
            uploadinglink = 'newgenepublic/' + s3DirectryrName;
        }
        // console.log(uploadinglink)

        let $noDropzone = (
            <div class="custom-file">
                <div className=''>
                    <ReactS3Uploader accept="image/*"
                        signingUrl="/W3S/getPresignedURL"
                        signingUrlMethod="POST"
                        onProgress={this.onUploadProgress}
                        onError={this.handleError}
                        onFinish={this.onUploadFinish}
                        signingUrlHeaders={{
                            'content-type': 'application/json'
                        }}
                        signingUrlQueryParams={{ 'bucketName': uploadinglink }}
                        uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
                        contentDisposition="auto"
                        scrubFilename={(filename) => new Date().getTime()}
                    />
                    <br />
                    <div>
                        <ProgressBar striped bsstyle="success" hidden={this.state.no_visible} now={this.state.completed} label={`${this.state.completed}%`} />
                    </div>
                </div>

            </div>);

        let $noDropzonewithPreview = (
            <div>
                <div className="img-edit">
                    <img className="image_Preview" src={imagePreviewUrl} alt="img" />
                </div>
                <div class="custom-file">
                    <div className=''>
                        <ReactS3Uploader accept="image/*"
                            signingUrl="/W3S/getPresignedURL"
                            signingUrlMethod="POST"
                            onProgress={this.onUploadProgress}
                            onError={this.handleError}
                            onFinish={this.onUploadFinish}
                            signingUrlHeaders={{
                                'content-type': 'application/json'
                            }}
                            signingUrlQueryParams={{ 'bucketName': uploadinglink }}
                            uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}
                            contentDisposition="auto"
                            scrubFilename={(filename) => new Date().getTime()}
                        />
                        <br />
                        <div>
                            <ProgressBar striped bsstyle="success"
                                hidden={this.state.no_visible}
                                now={this.state.completed}
                                label={`${this.state.completed}%`} />
                        </div>
                    </div>
                </div>
                <br /> <br />

            </div>);

        if (imagePreviewUrl) {

            if (imageUrl === '') {
                if (imageError === '') {
                    loadImage = (<Loader />)
                }
                else if (imageError) {
                    $imagePreview = $noDropzonewithPreview;
                    this.setState({ imageError: '', imagePreviewUrl: '', imageUrl: '' })
                }
            }

            else {
                $imagePreview = $noDropzonewithPreview
            };
        }
        else {
            $imagePreview = $noDropzone;
        }

        return (
            <div>
                {$imagePreview}
                {loadImage}
            </div>
        )
    }
}

export default UploadImageWithoutDropZone
