import {
    LOGIN_SUCCESS, LOGIN_ERROR,
    LOGIN_LOADING, EMPTY,
    REGISTER_SUCCESS, REGISTER_ERROR,
    REGISTER_LOADING, SIGNOUT_SUCCESS,
    FIREBASE_ERROR,


    // Fit Data
    FIT_USER_LOGIN_SUCCESSFULLY, FIT_USER_LOGIN_ERROR,
    LOADING_FIT_USER_LOGIN,

    FIT_USER_REGISTER_SUCCESSFULLY, FIT_USER_REGISTER_ERROR,
    LOADING_FIT_USER_REGISTER,

    
    // Tpay Data
    TPAY_USER_LOGIN_SUCCESSFULLY, TPAY_USER_LOGIN_ERROR,
    LOADING_TPAY_USER_LOGIN,

    TPAY_USER_REGISTER_SUCCESSFULLY, TPAY_USER_REGISTER_ERROR,
    LOADING_TPAY_USER_REGISTER

} from '../Actions/Types'

const initialState = {

    tpayData:{},
    item: {},
    loading: '',
    success: '',
    errorMsg: '',
    register_loading: ''
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGIN_ERROR:
            return {
                ...state,
                success: false,
                errorMsg: action.payload,
                loading: false
            }

        case FIREBASE_ERROR:
            return {
                ...state,
                success: false,
                errorMsg: action.payload,
                loading: false
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                item: action.payload,
                success: true,
                errorMsg: '',
                loading: false

            }
        case LOGIN_LOADING:
            return {
                ...state,
                loading: true

            }

        case REGISTER_ERROR:
            return {
                ...state,
                success: false,
                errorMsg: action.payload,
                loading: false
            }

        case REGISTER_SUCCESS:
            return {
                ...state,
                success: true,
                errorMsg: '',
                loading: false

            }
        case REGISTER_LOADING:
            return {
                ...state,
                loading: true
            }

        case EMPTY:
            return {
                ...state,
                success: '',
                errorMsg: '',
                loading: ''
            }

        case SIGNOUT_SUCCESS:
            console.log('sign out success')
            return {
              ...state,
              item:{},
              loading: '',
              success: '',
              errorMsg: '',
            }

        ////////////////////// FIT
        case FIT_USER_REGISTER_ERROR:
            return {
                ...state,
                success: false,
                errorMsg: action.payload,
                loading: false
            }

        case FIT_USER_REGISTER_SUCCESSFULLY:
            return {
                ...state,
                success: true,
                errorMsg: '',
                loading: false

            }
        case LOADING_FIT_USER_REGISTER:
            return {
                ...state,
                loading: true
            }

        case FIT_USER_LOGIN_ERROR:
            return {
                ...state,
                success: false,
                errorMsg: action.payload,
                loading: false
            }

        case FIT_USER_LOGIN_SUCCESSFULLY:
            return {
                ...state,
                item: action.payload,
                success: true,
                errorMsg: '',
                loading: false

            }
        case LOADING_FIT_USER_LOGIN:
            return {
                ...state,
                loading: true

            }

             ////////////////////// TPAY
        case TPAY_USER_REGISTER_ERROR:
            return {
                ...state,
                success: false,
                errorMsg: action.payload,
                loading: false
            }

        case TPAY_USER_REGISTER_SUCCESSFULLY: 
            return {
                ...state,
                tpayData: action.payload,
                success: true,
                errorMsg: '',
                loading: false

            }
        case LOADING_TPAY_USER_REGISTER:
            return {
                ...state,
                loading: true
            }

        case TPAY_USER_LOGIN_ERROR:
            return {
                ...state,
                success: false,
                errorMsg: action.payload,
                loading: false
            }

        case TPAY_USER_LOGIN_SUCCESSFULLY:
            return {
                ...state,
                tpayData: action.payload,
                success: true,
                errorMsg: '',
                loading: false

            }
        case LOADING_TPAY_USER_LOGIN:
            return {
                ...state,
                loading: true

            }



        default:
            return state
    }
}
