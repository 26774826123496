import React, { Component } from 'react'
import { Snackbar } from '@material-ui/core';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

/* Redux */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import mainLogoM from '../../../images/portal/logo-big@2x.png';

import succ from '../../../images/fit/succ-sign.png';

import { removeSuccessMessage } from '../../../Actions/SuccessMessagesActions';
import { getFitData } from '../../../Promises/Fit'

/* Components */
import Footer from '../Layout/Footer';
import FitDownloadApps from '../Layout/FitDownloadApps'

import "../../../css/Fit.css";


class FitSuccess extends Component {

    state = {
        fitCompanyContent: "",
        phoneNumber: "",
        openSnakBar: false,

        vertical: 'bottom',
        horizontal: 'right',
    }

    componentDidMount() {
        let phone = this.props.match.params.phone;
        let fitId = this.props.match.params.fitId;
        let type = this.props.match.params.type;
        let fitCompanyContent;

        if (type == "Fit") {
            getFitData(fitId).then(res => {
                if (res.data.serviceId !== null && res.data.serviceId !== undefined) {

                    if (res.data.serviceId == "3358") { // Zain
                        console.log("Zain")
                        fitCompanyContent = "للاشتراك في باقة زين اليومية، أرسل رقم (11) في رسالة نصية إلى رقم (708080) بريال واحد يوميًا (تشمل ضريبة القيمة المضافة 15%)، وللإلغاء أرسل (غ ١) أو (U 1)."
                    }
                    else if (res.data.serviceId == "3361") { // Mobily
                        console.log("Mobily")

                        fitCompanyContent = " للاشتراك في باقة موبايلي اليومية، أرسل رقم (1) في رسالة نصية إلى رقم (607078) بريال واحد يوميًا (تشمل ضريبة القيمة المضافة 15%)، وللإلغاء أرسل (غ ١) أو (U 1)."
                    } else { // STC
                        console.log("STC")

                        fitCompanyContent = "للاشتراك في باقة STC الأسبوعية، أرسل رقم (1) في رسالة نصية إلى رقم (801666) بـ 7.5 ريال أسبوعيًا (تشمل ضريبة القيمة المضافة 15%)، وللإلغاء أرسل (غ ١) أو (U 1)."
                    }
                    this.setState({ phoneNumber: phone, fitCompanyContent: fitCompanyContent })

                }

            })
        }

        this.setState({ phoneNumber: phone })

    }

    handleSuccessMessages = () => {
        this.props.removeSuccessMessage();
    }

    render() {
        const { vertical, horizontal } = this.state;
        const { successMessage } = this.props;

        return (
            <div>

                <Snackbar
                    autoHideDuration={6000} onClose={this.handleSuccessMessages}
                    anchorOrigin={{ vertical, horizontal }}
                    open={successMessage.show}
                    onClose={this.handleSuccessMessages}
                    message={successMessage.message}
                    key={vertical + horizontal}
                />

                <section className="jeel-fit">
                    <div className="logo-container">
                        <div className="container">
                            <div className="logo">
                                <img src={mainLogoM} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12 col-md-6">
                                <div className="center fit-about-text">
                                    <div className="title h2 white">

                                        بعد اشتراكك عبر الرسائل النصية،
                                        كل ما عليك الآن هو
                                            </div>
                                </div>
                            </div>

                        </div>
                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-6">
                                <div className="  main-progress-bar">
                                    <ol className="progress-tracker">
                                        <li className="step fitbg">
                                            <span className="step-name small-font">
                                                <span className="bold">
                                                    تفعيل الحساب
                                                    </span>
                                            </span>
                                        </li>
                                        <li className="step fit">
                                            <span className="step-name small-font">
                                                <span className="bold">
                                                    تحميل التطبيق
                                                </span>
                                            </span>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


                <div className="container" >

                    <div className=" col-12 col-md-8" id="border">

                        <div className=" center img">
                            <img src={succ} alt="logo" />
                            <br /><br />

                            <strong>
                                مُبارك!
                                </strong>

                        </div>

                        <div className="row justify-content-md-center">
                            <div className=" col-12 col-md-8">

                                <p className="h5 text-rtl">
                                    اشتركت بنجاح في باقة شركات الاتصالات،
                                    وستُجدَّدُ الباقة تلقائيًا بعد انتهائها.
                                    </p>
                            </div>
                        </div>

                        <br />

                    </div>
                </div>
                <div className="container" >
                    <div className=" col-12 col-md-8" id="border">

                        <div className="row justify-content-md-center">

                            <div className="col-12 col-md-8 ">

                                <p className="h5 text-rtl margin">
                                    رقم الهاتف الذى تم الاشتراك من خلاله
                                        </p>
                                <br />
                                <div className="phoneNum" id="phone">
                                    <PhoneInput
                                        name="phone"
                                        value={this.state.phoneNumber}
                                        disabled />
                                </div>
                                <br />

                                <p className="cancel small-font font-weight-bold text-rtl">
                                    {this.state.fitCompanyContent}
                                </p>
                                <br />
                                <br />
                            </div>

                        </div>
                    </div>


                </div>


                <div className="container" >
                    <div className=" col-12 col-md-8" id="border"
                    >

                        {/** download section */}
                        <FitDownloadApps />
                        {/** End of download section*/}
                    </div>
                </div>





                {/** Start Footer */}
                <Footer />
                {/** End Footer */}

            </div>
        )
    }
}

const mapStateToProps = state => ({

    successMessage: state.successMessages.item,
});


export default withRouter
    (connect
        (mapStateToProps,
            {
                removeSuccessMessage,
            })
        (FitSuccess)
    );

