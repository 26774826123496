import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  addCodeToRedux,
  emptyPromoCode,
  newVertifyCode,
} from "../../../Actions/PromoCodesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
export const Promocode = (props) => {

  const loading = useSelector((state) => state.promoCodes.loading);
  const paymentMethod = useSelector((state) => state.paymentData.paymentMethod);
  const paymentPackage = useSelector((state) => state.paymentData.slug);
  const [code, setCode] = useState("");
  const [inactive, setInactive] = useState(false);
  const dispatch = useDispatch();
  const isButtonDisabled = code.trim() === "";
  const handleCodeChange = (e) => {
    const value = e.target.value;
    setCode(value);
    setTimeout(() => {
      if (code !== "" && code !== undefined && code !== null) {
        setInactive(false);
      } else {
        setInactive(true);
      }
    });
    console.log(code);
  };

  const handleVerify = (e) => {
    console.log("code = " + code);
    e.preventDefault();
    dispatch(newVertifyCode(code,paymentMethod,paymentPackage));
    dispatch(emptyPromoCode());
    dispatch(addCodeToRedux(code, ""));
    props.callMethod();
  };

  return (
    <div className="promocode">
      <form>
        <div className="promocode-group form-group d-flex align-items-center">
          <label>هل لديك بروموكود؟</label>
          <input
            type="text"
            className="form-control"
            value={code}
            onChange={handleCodeChange}
          />
          <button
            className="btn send-promocode"
            onClick={handleVerify}
            disabled={isButtonDisabled || loading}
          >
            تأكيد الكود
            {loading && (
              <FontAwesomeIcon
                icon={faSpinner}
                className="faTimes fa-spin mr-2"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
export default Promocode;
