import {
    PROFIT_SHARE_CURRENCY_PROFITS_LOAD, 
    PROFIT_SHARE_CURRENCY_PROFITS_FETCH_SUCCESSFULLY, 
    PROFIT_SHARE_CURRENCY_PROFITS_FETCH_ERROR,
    PROFIT_SHARE_ContentVendor_PROFITS_LOAD,
    PROFIT_SHARE_ContentVendor_PROFITS_FETCH_SUCCESSFULLY,
    PROFIT_SHARE_ContentVendor_FETCH_ERROR
    
} from './Types'

import { post } from 'axios';

    
export const getAllCurrenyProfits = (startDate,endDate) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: PROFIT_SHARE_CURRENCY_PROFITS_LOAD,
                payload: 'loading'
            })
            const url = "/country-subs-report/getProfitByCurrency"
            const formData = new FormData();
            formData.append("Start_Date",startDate)
            formData.append("End_Date",endDate)
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
	
				//console.log('All Currency profits in Actions : '+JSON.stringify(res.data,null,2));
	
                dispatch({
                    type: PROFIT_SHARE_CURRENCY_PROFITS_FETCH_SUCCESSFULLY,
                    payload: res.data
                })

            }).catch((Error) => {
                 dispatch({
                type: PROFIT_SHARE_CURRENCY_PROFITS_FETCH_ERROR,
                payload: Error.message
            })
            })

        })

    }
}


export const getAllContentVendorsProfits = (suggestedProfit) => {

    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token) => {
            dispatch({
                type: PROFIT_SHARE_ContentVendor_PROFITS_LOAD,
                payload: 'loading'
            })
            const url = "/country-subs-report/getProfitsByContentVendor"
            const formData = new FormData();
            formData.append("suggestedProfit",suggestedProfit)
            
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase': token
                }
            }
            post(url, formData, config).then((res) => {
	
				//console.log('All profits shares divided in Actions : '+JSON.stringify(res.data,null,2));
	
                dispatch({
                    type: PROFIT_SHARE_ContentVendor_PROFITS_FETCH_SUCCESSFULLY,
                    payload: res.data
                })

            }).catch((Error) => {
                 dispatch({
                type: PROFIT_SHARE_ContentVendor_FETCH_ERROR,
                payload: Error.message
            })
            })

        })

    }
}


export const emptyContentVendorProfits = () => dispatch => {    
    dispatch({
        type: 'emptyProfits',
        payload: ""
    })
}
