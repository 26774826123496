import {
    FETCH_ALL_BOOKS,
    ADD_BOOK,
    PUBLISH_BOOK,
    GET_BOOK_DATA,
    ERROR_LOADING_ALL_BOOKS,
    ERROR_LOADING_BOOK,
    EMPTY_BOOK,
    EMPTY_ALL_BOOKS,
    LOAD_BOOK,
    LOAD_ALL_BOOKS,
    ERROR_ADDING_BOOK,
    EDIT_BOOK_DATA,
    ERROR_EDITING_BOOK,
    DELETE_BOOK,
    ERROR_DELETING_BOOK,
    SETTING_BOOK_STATUS,
    ERROR_SETTING_BOOK_STATUS,
    ADD_BOOK_WITH_SENTFROM,
    ADD_BOOK_PAGE,
    ERROR_ADDING_BOOK_PAGE,
    UPDATE_BOOK_PAGE,
    ERROR_UPDATE_BOOK_PAGE,
    LOAD_BOOK_PAGE,
    ERROR_DELETING_BOOK_PAGE,
    DELETE_BOOK_PAGE
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},

    loading: '',

    error_adding_book: '',
    book_added_successfully: '',

    error_loading_all_books: '',
    error_loading_book: '',

    book_updated_successfully: '',
    error_updated_book: '',

    book_deleted_successfully: '',
    error_deleteing_book: '',

    book_status_updated_successfully: '',
    error_setting_status: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_BOOK_WITH_SENTFROM:
            return {
                ...state,
                item: action.payload
            }
        case FETCH_ALL_BOOKS:
            return {
                ...state,
                items: action.payload,
                error_loading_all_books: '',
                loading: false,
                item: {}
            }

        case ADD_BOOK:
            return {
                ...state,
                item: action.payload,
                loading: false,
            }

        case PUBLISH_BOOK:
            return {
                ...state,
                item: action.payload,
                book_added_successfully: true,
                loading: false,
            }

        case GET_BOOK_DATA:
            return {
                ...state,
                item: action.payload,
                error_loading_book: '',
                loading: false
            }


        case ERROR_LOADING_ALL_BOOKS:
            return {
                ...state,
                error_loading_all_books: action.payload,
                loading: false
            }
        case ERROR_LOADING_BOOK:
            return {
                ...state,
                error_loading_book: action.payload,
                loading: false
            }

        case EMPTY_BOOK:
            return {
                ...state,
                error_adding_book: '',
                book_added_successfully: '',

                error_updated_book: '',
                book_updated_successfully: '',

                error_deleteing_book: '',
                book_deleted_successfully: '',

                book_status_updated_successfully: '',
                error_setting_status: '',

                error_loading_book: '',
                error_loading_all_books: '',

                // 
                error_adding_book_page: '',
                book_page_added_sucessfully: '',
                updating_book_page_error: '',
                updating_book_page_sucessfully: '',
                deleting_book_page_sucessfully: '',
                deleting_book_page_error: '',
                loading: false

            }
        case EMPTY_ALL_BOOKS:
            return {
                ...state,
                items: [],
                item: {},
            }

        case LOAD_BOOK:
            return {
                ...state,
                loading: true
            }


        case LOAD_ALL_BOOKS:
            return {
                ...state,
                loading: true
            }

        case ERROR_ADDING_BOOK:
            return {
                ...state,
                error_adding_book: action.payload,
                book_added_successfully: false,
                loading: false,
            }

        case EDIT_BOOK_DATA:
            return {
                ...state,
                item: action.payload,
                book_updated_successfully: true,
                loading: false,
            }

        case ERROR_EDITING_BOOK:
            return {
                ...state,
                error_updated_book: action.payload,
                book_updated_successfully: false,
                loading: false
            }

        case DELETE_BOOK:
            return {
                ...state,
                item: action.payload,
                book_deleted_successfully: true,
                loading: false,
            }
        case ERROR_DELETING_BOOK:
            return {
                ...state,
                error_deleteing_book: action.payload,
                book_deleted_successfully: false,
                loading: false,
            }

            case SETTING_BOOK_STATUS:
                return {
                    ...state,
                    book_status_updated_successfully: true,
                    item: action.payload,
                    loading: false
                }
            case ERROR_SETTING_BOOK_STATUS:
                return {
                    ...state,
                    book_status_updated_successfully: false,
                    error_setting_status: action.payload,
                    loading: false
                }
            case ADD_BOOK_PAGE: // START PAGES
                return {
                    ...state,
                    addPageItem: action.payload,
                    book_page_added_sucessfully: true,
                    loadingPages: false,
                    disableUpdateStorage: true
                }
            case ERROR_ADDING_BOOK_PAGE:
                return {
                    ...state,
                    error_adding_book_page: action.payload,
                    book_page_added_sucessfully: false,
                    loadingPages: false,
                    disableUpdateStorage: true
                }
            case UPDATE_BOOK_PAGE:
                return {
                    ...state,
                    pageItemUpdated: action.payload,
                    updating_book_page_sucessfully: true,
                    updating_book_page_error: '',
                    loadingPages: false
                }

            case ERROR_UPDATE_BOOK_PAGE:
                return {
                    ...state,
                    updating_book_page_error: action.payload,
                    loadingPages: false,
                    updating_book_page_sucessfully: false
                }
            case DELETE_BOOK_PAGE:
                return {
                    ...state,
                    pageItemDeleted: action.payload,
                    deleting_book_page_sucessfully: true,
                    loadingPages: false
                }
            case ERROR_DELETING_BOOK_PAGE:
                return {
                    ...state,
                    deleting_book_page_error: action.payload,
                    loadingPages: false
                }
            case LOAD_BOOK_PAGE:
                return {
                    ...state,
                    loadingPages: true
                }

            default:
                return state    
        }
    
    }
    

