import axios from "axios";

import {
  FETCH_PRE_SIGN_URL,
  LOAD_PRE_SIGN_URL,
  ERROR_PRE_SIGN_URL,
  CLEAR_PRE_SIGN_URL_DATA,
  GET_VIDEO_STATUS,
  GENERATE_OTP,
  CLEAR_ALL,
  RESET_VDOCIPHER_DATA,
} from "./Types";

export const getPreSingUrlvdoCipher = (filename, componentId , folderName) => (dispatch) => {
  dispatch({
    type: LOAD_PRE_SIGN_URL,
    payload: "loading",
    id: componentId,
  });

  axios
    .post(`/vdocipher/upload?title=${filename}&folderName=${folderName}`)
    .then((res) => {
      dispatch({
        type: FETCH_PRE_SIGN_URL,
        payload: res.data,
        id: componentId,
      });
    })
    .catch((Error) => {
      dispatch({
        type: ERROR_PRE_SIGN_URL,
        payload: Error.message,
        id: componentId,
      });
    });
};
export const uploadSubVideo = (filename, componentId , folderName) => (dispatch) => {
  dispatch({
    type: LOAD_PRE_SIGN_URL,
    payload: "loading",
    id: componentId,
  });

  axios
    .post(`/vdocipher/upload-sub-video?title=${filename}&folderName=${folderName}`)
    .then((res) => {
      dispatch({
        type: FETCH_PRE_SIGN_URL,
        payload: res.data,
        id: componentId,
      });
    })
    .catch((Error) => {
      dispatch({
        type: ERROR_PRE_SIGN_URL,
        payload: Error.message,
        id: componentId,
      });
    });
};

export const generateOTP = (filename, componentId) => (dispatch) => {
  axios.post(`/vdocipher/otp?video_key=${filename}`).then((res) => {
    dispatch({
      type: GENERATE_OTP,
      payload: res.data,
      id: componentId,
    });
  });
};
export const clearPreSignData = (filename,componentId) => (dispatch) => {
  axios.delete(`/vdocipher/deleteVideo?video_key=${filename}`).then((res)=> {
    dispatch({
      type: CLEAR_PRE_SIGN_URL_DATA,
      payload: "",
      id: componentId,
    });
  });
};

export const clearAll = () => (dispatch) => {
  dispatch({
    type: CLEAR_ALL,
    payload: "",
  });
};
export const resetVdocipherData = (componentId) => (dispatch) => {
  dispatch({
    type: RESET_VDOCIPHER_DATA,
    payload: "",
    id : componentId,
  });
};

export const getvdoCipherStatus = (videoKey, componentId) => (dispatch) => {
  axios.get(`/vdocipher/vdoStatus?video_key=${videoKey}`).then((res) => {
    dispatch({
      type: GET_VIDEO_STATUS,
      payload: res.data,
      id: componentId,
    });
  });
};
