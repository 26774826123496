import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import {deleteDiscoverUsSeries,fetchDiscoverUsSeries,emptyDiscoverUsSeries} from '../../Actions/DiscoverUsSeriesActions'
/* Routes */
import { ALL_DISCOVER_SERIES } from '../../routes';

class DeleteDiscoverUsSeries extends Component{
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    deleteDiscoverUsSeries (id)
    {
        this.props.deleteDiscoverUsSeries(id);
    }

    handleSubmit=(e)=>
    {
        const {id} = this.props;
        this.deleteDiscoverUsSeries(id);
        this.close();
    }


    render()
    {
        const {error_deleting_discover_us_series,discover_us_series_deleted_successfully} = this.props
        if (discover_us_series_deleted_successfully===false)
        {
            alert("error"+error_deleting_discover_us_series);
            this.props.emptyDiscoverUsSeries();
        }
        else if (discover_us_series_deleted_successfully===true)
        {
            alert("series deleted successfully");
            this.props.emptyDiscoverUsSeries();
            this.props.fetchDiscoverUsSeries();
            return(<Redirect to={ALL_DISCOVER_SERIES}></Redirect>)
        }


        return (
            <div >
            <button className="seriesCardButtons"  onClick={this.open}>
                    <i className="" >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal"
                content='Are you sure you want to delete this series ??'
                open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>

        )
    }
}
DeleteDiscoverUsSeries.propTypes={
    deleteDiscoverUsSeries:propTypes.func.isRequired
}
const mapStateToProps =state =>
({
    discoverUsSeries : state.discoverUsSeries.items,
    error_deleting_discover_us_series:state.discoverUsSeries.error_deleting_discover_us_series,
    discover_us_series_deleted_successfully:state.discoverUsSeries.discover_us_series_deleted_successfully
})

export default connect(mapStateToProps,{deleteDiscoverUsSeries,fetchDiscoverUsSeries,emptyDiscoverUsSeries}) (DeleteDiscoverUsSeries)
