import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'
import { Redirect, Route } from 'react-router-dom'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Components */
import AddEpisode from './AddEpisode';
import PublishEpisode from './PublishEpisode';
import ViewEpisode from './ViewEpisode';
import AddActivityEpisode from './AddActivityEpisode';
import EditActivityEpisode from './EditActivityEpisode';
import EditEpisode from "./EditEpisode";
import EpisodeEvaluation from './EpisodeEvaluation';
import EditEvaluationEpisode from './EditEvaluationEpisode'
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import {SIGN_IN, ADMIN_LAND_PAGE, ADD_EPISODE, PUBLISH_EPISODE, VIEW_EPISODE,
        ADD_EPISODE_ACTIVITY, EDIT_EPISODE, EPISODE_EVALUATION,
        EDIT_EPISODE_ACTIVITY, EDIT_EPISODE_EVALUATION} from '../../routes';

class EpisodeAuth extends Component {

  constructor(props) {
      super(props);
      if (firebase.auth().currentUser) {
          firebase.auth().currentUser.getIdToken().then((token) => {
              this.props.getUserData(token);
          })
      }
  }

  componentDidMount() {
      const { userPaymentProfile } = this.props;
      const { auth } = this.props;
      if (!auth.uid) {
          return <Redirect to={SIGN_IN}></Redirect>
      }
  }

  render() {
    const { userPaymentProfile } = this.props;

    if(userPaymentProfile.user_role.role === "Analyst"){
      return <Redirect to={ADMIN_LAND_PAGE}></Redirect>
    }
    return (
      <span>
          {/* adding Episode Routers */}
          <Route path={ADD_EPISODE} component={AddEpisode} />
          <Route path={PUBLISH_EPISODE} component={PublishEpisode} />
          <Route path={VIEW_EPISODE} component={ViewEpisode} />
          <Route path={ADD_EPISODE_ACTIVITY} component={AddActivityEpisode} />
          <Route path={EDIT_EPISODE} component={EditEpisode} />
          <Route path={EPISODE_EVALUATION} component={EpisodeEvaluation} />
          <Route path={EDIT_EPISODE_ACTIVITY} component={EditActivityEpisode} />
          <Route path={EDIT_EPISODE_EVALUATION} component={EditEvaluationEpisode} />
      </span>
    )
  }
}

const mapStateToProps = state => ({
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,
})

export default withFirebase
(connect
    (mapStateToProps,
        {getUserData}
    )(EpisodeAuth)
);
