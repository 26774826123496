import React, { Component } from 'react'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm, Popup } from 'semantic-ui-react'

/*Actions */
import {
    setStateRadio,
    emptyError,
    fetchAllRadio,
    getRadioData
} from '../../Actions/RadioActions'

class SetStateRadio extends Component {
    state = { open: false }
    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })

    setStateRadio(id, status) {
        this.props.setStateRadio(id, status)
    }

    handleSubmit = (e) => {

        const { id, status } = this.props;

        if (status === "SUSPENDED") {
            this.setStateRadio(id, "DEFAULT");

        } else {
            this.setStateRadio(id, "SUSPENDED");
        }
        this.close();
    }


    render() {
        const { success, error, status, id, sentFrom } = this.props;

        let contentPreview = null;

        if (success === false) {
            alert("Error: " + error);
            this.props.emptyError();
        }
        else if (success === true) {
            alert("Radio status updated successfully");
            this.props.emptyError();

            // if the requesst come from All Radio then
            // fetch all Radio to update 
            // the new data
            if (sentFrom === "GetAllRadio") {
                this.props.fetchAllRadio();
            }

            // if the requesst come from ViewRadio then
            // get Radio data to update 
            // the suspended Radio
            if (sentFrom === "ViewRadio") {
                this.props.getRadioData(id);
            }
        }
        if (status === "SUSPENDED") {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                     onClick={this.open}>
                        <i >
                            <FontAwesomeIcon icon="play" />
                        </i>
                    </button>
                }
                    content='Tap to Active'
                    position='top center'
                />

            )
        }
        else {
            contentPreview = (
                <Popup trigger={
                    <button className="seriesCardButtons"
                        onClick={this.open}>||</button>}
                    content='Tap to Suspend'
                    position='top center'
                />

            )
        }


        return (
            <span >
                {contentPreview}
                <Confirm id="modal" 
                open={this.state.open} 
                onCancel={this.close} 
                onConfirm={this.handleSubmit} />
            </span>)
    }
}

SetStateRadio.propTypes = {
    setStateRadio: propTypes.func.isRequired,
    fetchAllRadio: propTypes.func.isRequired,
    getRadioData: propTypes.func.isRequired,
}
const mapStateToProps = state =>
({
    success: state.radio.setting_state_radio_parent_sucessfully,
    error: state.radio.setting_state_radio_parent_error
})

export default connect
    (mapStateToProps,
        {
            setStateRadio,
            emptyError,
            fetchAllRadio,
            getRadioData
        })
    (SetStateRadio)

