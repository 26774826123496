import React from 'react';
import mainLogo from "../../../images/portal/logo-big@2x.png";

// NGMA-2417
const Loader =() =>
{
    return(
      <div class="loading">
        <div class="loading-logo">
          <img src={mainLogo} alt="Jeel"/>
        </div>
      </div>
    )
}
export default Loader;
