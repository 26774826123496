import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import { withFirebase } from '../../config'
/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import firebase from 'firebase/app'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/* Actions */
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
/* Routes */
import {SIGN_IN, VENDORS, VENDOR_REPORT, QR_CODE_GENERATOR,QR_CODE_CARD_UPLOAD} from '../../routes';

class VendorManagement extends Component {

  constructor(props) {
    super(props);
    if (firebase.auth().currentUser) {
      firebase.auth().currentUser.getIdToken().then((token) => {
        this.props.getUserData(token);
      })
    }
  }

  componentDidMount() {
    const { userPaymentProfile } = this.props;
    const { auth } = this.props;
    if (!auth.uid) {
      return <Redirect to={SIGN_IN}></Redirect>
    }
  }

  render() {
    const { userPaymentProfile } = this.props;

    if (userPaymentProfile.user_role !== undefined) {
      if (userPaymentProfile.user_role.role !== "Analyst") {
        return (
          <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <ul className="navbar-nav mr-auto">
                <li>
                  <NavLink to={VENDORS} className="nav-link" > Partner
                  </NavLink>
                </li>
                <li>
                  <NavLink to={VENDOR_REPORT} className="nav-link">Partner Report
                  </NavLink>
                </li>
                <li
                ><NavLink to={QR_CODE_GENERATOR} className="nav-link">QR Codes Generator
                </NavLink>
                </li>
                <li
                ><NavLink to={QR_CODE_CARD_UPLOAD} className="nav-link">QR Code Card Upload
                </NavLink>
                </li>                              
              </ul>

            </nav>
            <br />
          </div>
        )
      }
    } else {
      return (<div></div>);
    }

  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  userPaymentProfile: state.userPaymentProfile.item,

})

export default withFirebase
  (connect
    (mapStateToProps,
      { getUserData }
    )(VendorManagement)
  );
