import React, { Component } from 'react';
import { withFirebase } from '../../config';
import { Dropdown, Popup } from 'semantic-ui-react';
/**Redux */
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import porpTypes from 'prop-types';
/**Actions */
import { fetchQuestionsCategories } from '../../Actions/QuestionCategoryActions';
import { addQuestions, getActitvityQuestions, deleteQuestionfromRedux } from '../../Actions/QuestionActions';
/**Components */
import UploadImage from '../S3Uploading/UploadImage';
import UploadImageWithoutDropZone from '../S3Uploading/UploadImageWithoutDropZone'
import UploadAudio from '../S3Uploading/UploadAudio';
/*Css */
import "bootstrap/dist/css/bootstrap.min.css";
import '../../css/Activity.css';

const questions_type = [{
    key: 'text',
    text: 'text',
    value: 'text'
},
{
    key: 'image',
    text: 'image',
    value: 'image'
}]

let returnedCorrectAnswerId = true;

let memoryCategoryId;

class AddActivity extends Component {
    constructor(props) {
        super(props);

    }
    state = {
        questionCategoryBoolean: "",

        answers: [{ text: null, voice: null }],

        questionType: "",
        questionCategoryID: "",
        questionText: "",
        questions: [],
        correctAnswerId: -1,

        open: false,
        updaingQuestion: false,
        questionAudio: "",

        // validation
        error: true,
        errorMessage: ''
    }

    componentDidMount() {

        this.props.fetchQuestionsCategories();
        const { activityQuestions } = this.props;
        this.setState({ questions: activityQuestions })



        const { questionCategories } = this.props;

        for (let i = 0; i < questionCategories.length; i++) {

            if (questionCategories[i].categoryText == "null") {
                memoryCategoryId = questionCategories[i].categoryID;
                this.setState({ questionCategoryID: questionCategories[i].categoryID })
            }

        }
    }

    handleQuestionType = (e, { value }) => {
        let bool = false;  //image
        if (value === 'text') {
            bool = true;
        }
        this.setState({
            questionCategoryBoolean: bool,
            questionType: value,
            answers: []
        })

    }

    deleteAnswer(i, event) {
        console.log("deleteAnswer")
        console.log(i)

        let answers = [...this.state.answers];
        const filteredItems = answers.filter(function (item) {
            return item.text !== answers[i].text
        })

        console.log(filteredItems)
        this.setState({ answers: filteredItems })
    }

    createInputField() {
        let showRadioButton = null;
        let $textcontent = null;

        if (this.state.previewQuestionImages === false
            && this.state.updaingQuestion == true) {
            $textcontent = this.state.answers.map((el, i) => {

                if (i == this.state.correctAnswerId) {

                    showRadioButton = <input type='radio' id={i}
                        name="radio" value={i}
                        checked={returnedCorrectAnswerId}
                        onChange={this.handleCorrectAnswersChange.bind(this, i)}></input>

                } else {
                    showRadioButton =
                        <input type='radio' id={i}
                            name="radio" value={i}
                            onChange={this.handleCorrectAnswersChange.bind(this, i)}></input>

                }

                return <div className='row'>

                    <div className="col-1">
                        <UploadAudio
                            getAudioUrlCallBack={this.getChoiceAudio.bind(this, i)}

                            audioPreview={el.voice}
                            audioUrl={el.voice}
                            s3DirectryrName="activity/audio" />
                    </div>

                    <div className="col-9">
                        <input
                            type="text"
                            dir="auto"
                            className="form-control"
                            onChange={this.handleAnswersChange.bind(this, i)}
                            value={el.text} />
                    </div>
                    <div className="answers-box">

                        <span className="ansr-control">
                            {showRadioButton}
                            <span> | </span>
                            <Popup trigger={
                                <FontAwesomeIcon className="far fa-trash-alt"
                                    icon='trash-alt' onClick={this.deleteAnswer.bind(this, i)}>
                                </FontAwesomeIcon>}

                                content='Delete'
                                position='top center'
                            />
                        </span>
                    </div>
                </div>

            }
            )
        }
        else {
            $textcontent = this.state.answers.map((el, i) =>

                <div className="row" key={i}>


                    <div className="col-1">
                        <UploadAudio
                            getAudioUrlCallBack={this.getChoiceAudio.bind(this, i)}

                            audioPreview={el.voice}
                            audioUrl={el.voice}
                            s3DirectryrName="activity/audio" />
                    </div>


                    <div className="col-9">
                        <input type="text"
                            dir="auto"
                            className="form-control"
                            onChange={this.handleAnswersChange.bind(this, i)}
                            value={el.text} />
                    </div>

                    <div className="answers-box">

                        <span className="ansr-control">
                            <input type='radio' id={i} name="radio" value={i}
                                onChange={this.handleCorrectAnswersChange.bind(this, i)} >
                            </input>
                            <span> | </span>
                            <Popup trigger={
                                <FontAwesomeIcon className="far fa-trash-alt"
                                    icon='trash-alt' onClick={this.deleteAnswer.bind(this, i)}>
                                </FontAwesomeIcon>}
                                content='Delete'
                                position='top center'
                            />
                        </span>

                    </div>
                    <br />

                </div>

            )
        }
        return $textcontent;
    }

    handleCorrectAnswersChange(i, event) {

        let correctAnswerId = event.target.value;
        this.setState({ correctAnswerId });
    }

    createDropZoneFields() {

        let $imagesContent = null;
        let showRadioButton = null;
        if (this.state.previewQuestionImages === true &&
            this.state.updaingQuestion == true) {

            $imagesContent = this.state.answers.map((el, i) => {


                if (i == this.state.correctAnswerId) {

                    showRadioButton = <input type='radio' id={i}
                        name="radio" value={i}
                        checked={returnedCorrectAnswerId}
                        onChange={this.handleCorrectAnswersChange.bind(this, i)}></input>

                } else {
                    showRadioButton =
                        <input type='radio' id={i}
                            name="radio" value={i}
                            onChange={this.handleCorrectAnswersChange.bind(this, i)}></input>

                }

                return <div className='row'>

                    <div className="col-1">
                        <UploadAudio
                            getAudioUrlCallBack={this.getChoiceAudio.bind(this, i)}

                            audioPreview={el.voice}
                            audioUrl={el.voice}
                            s3DirectryrName="activity/audio" />
                    </div>

                    <div className="col-9">

                        <UploadImageWithoutDropZone
                            getImageURLCallBack={this.handleImagesChange.bind(this, i)}
                            imageUrl={el.text} imagepreview={el.text}
                            s3DirectryrName='activity' />

                    </div>

                    <div className="answers-box">

                        <span className="ansr-control">
                            {showRadioButton}
                            <span> | </span>
                            <Popup trigger={

                                <FontAwesomeIcon className="far fa-trash-alt"
                                    icon='trash-alt' onClick={this.deleteAnswer.bind(this, i)}>
                                </FontAwesomeIcon>}
                                content='Delete'
                                position='top center'
                            />
                        </span>
                    </div>
                </div>
            }
            )
        }
        else {
            $imagesContent = this.state.answers.map((el, i) => {

                return <div className='row'>
                    <div className="col-1">
                        <UploadAudio
                            getAudioUrlCallBack={this.getChoiceAudio.bind(this, i)}

                            audioPreview={el.voice}
                            audioUrl={el.voice}
                            s3DirectryrName="activity/audio" />
                    </div>

                    <div className="col-9">
                        <UploadImage
                            getImageURLCallBack={this.handleImagesChange.bind(this, i)}
                            imageUrl={el.text}
                            imagepreview={el.text}
                            s3DirectryrName='activity' />
                    </div>
                    <div className="answers-box">
                        <span className="ansr-control">

                            <input type='radio' id={i} name="radio" value={i}
                                onChange={this.handleCorrectAnswersChange.bind(this, i)}></input>

                        </span>
                    </div>
                    <br />
                </div>

            })
        }
        return $imagesContent;
    }

    handleImagesChange(i, imageUrl) {
        let answers = [...this.state.answers];
        if (imageUrl === 'delete') {

            answers[i] = {
                "text": '',
                "voice": null
            };
            this.setState({ answers });
        }
        else if (imageUrl !== null && imageUrl !== undefined) {
            if (answers[i].voice == '' ||
                answers[i].voice == undefined
            ) {
                answers[i] = {
                    "text": imageUrl,
                    "voice": null
                };
            } else {
                answers[i] = {
                    "text": imageUrl,
                    "voice": answers[i].voice
                };
            }
            this.setState({ answers });
        }
    }


    getAudio(audioUrl) { // get Question Audio
        if (audioUrl) {
            // check if url =  delete send empty to DB
            if (audioUrl === 'delete') {

                this.setState({ questionAudio: '' });
                console.log("audio URL in addActivity  if = "
                    + this.state.questionAudio);

            }
            // else send the url to DB
            else {
                this.setState({ questionAudio: audioUrl });
                console.log("audio URL in addActivity else = "
                    + this.state.questionAudio);

            }

        }
    }

    handleAnswersChange(i, event) {
        let answers = [...this.state.answers];

        if (answers[i].voice == '' ||
            answers[i].voice == undefined
        ) {
            answers[i] = {
                "text": event.target.value,
                "voice": null
            };
        } else {
            answers[i] = {
                "text": event.target.value,
                "voice": answers[i].voice
            };
        }


        this.setState({ answers });
    }

    getChoiceAudio(i, audioUrl, event) {

        console.log("i in getChoiceAudio = " + i);
        let choiceAudio = null
        if (audioUrl) {

            console.log("Audio Url in getChoiceAudio")
            // check if url !==  delete send url to DB
            if (audioUrl !== 'delete') {

                choiceAudio = audioUrl;
                console.log("choice audio URL in addActivity else = "
                    + choiceAudio);
            }

        }


        let answers = [...this.state.answers];

        answers[i] = {
            "text": answers[i].text,
            "voice": choiceAudio
        };
        this.setState({ answers });
    }

    addClick = (e) => {
        this.setState(prevState => ({ answers: [...prevState.answers, ''] }))
        e.preventDefault();
    }

    handleQuestionCategory = (e, { value }) => {
        this.setState({
            questionCategoryID: value
        })
    }

    handleChange = (e) => {
        let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
        this.setState({ [e.target.name]: checkSpaces });
        setTimeout(() => {
            if ((this.state.questionText != null && this.state.questionText != ' ' && this.state.questionText != "")) {
                this.setState({ error: false, errorMessage: '' });
            }
            else if ((this.state.questionText == null || this.state.questionText == ' ' || this.state.questionText == "")) {
                this.setState({ error: true, errorMessage: 'Empty data is not accepted' });
            }
        }, 0);
    }

    handleSubmit = (event) => {
        let questionType = this.state.questionType;
        let questionCategoryID = this.state.questionCategoryID;
        let questionText = this.state.questionText;
        let questionAudio = this.state.questionAudio;

        let ans = [];
        for (let i = 0; i < this.state.answers.length; i++) {

            if (this.state.answers[i].text === '' ||
                this.state.answers[i].text == undefined) {
                continue;
            }
            else {
                ans.push({
                    "text": this.state.answers[i].text,
                    "id": i,
                    "voice": this.state.answers[i].voice
                })
            }
        }
        let correctAnswerId = this.state.correctAnswerId;

        if (correctAnswerId == -1) {
            alert("Empty Correct Answer, Please Add one")
        }
        else {
            if (ans[correctAnswerId] == undefined ||
                ans[correctAnswerId].text == '' ||
                ans[correctAnswerId].text == undefined) {
                alert("Empty Answer, Please Add one")
            }
            else {
                let question = {
                    questionType: questionType,
                    questionCategoryId: questionCategoryID,
                    text: questionText,
                    choices: ans,
                    correctAnswerId: correctAnswerId,
                    questionAudio: questionAudio
                }
                let questions = [...this.state.questions, question];
                this.setState({ questions: questions })
                this.props.validateDataCallBack(questions)
                this.props.addQuestions(questions)
                alert("Question added successfully");

                // adding null defalut qategory





                this.setState({ questionCategoryID: memoryCategoryId })


                this.setState({
                    answers: [], questionText: '',
                    questionType: '',
                    correctAnswerId: -1, questionAudio: ''
                })
            }
        }
        event.preventDefault();
    }

    handleDelete(question, event) {

        // console.log("handle Delete")
        // console.log(question)

        let questions = [...this.state.questions];

        const filteredItems = questions.filter(function (item) {
            return item !== question
        })

        console.log(questions)
        console.log(filteredItems)

        this.setState({ questions: filteredItems })
        this.props.deleteQuestionfromRedux(filteredItems)

        this.setState({ questionCategoryID: memoryCategoryId })

        this.setState({
            answers: [], questionText: '',
            //questionCategoryID: '',
             questionType: '',
            questionAudio: ''
        })

        event.preventDefault();
    }

    cancelSubmit = (e) => {

        this.setState({ questionCategoryID: memoryCategoryId })

        this.setState({
            answers: [], questionText: '',// questionCategoryID: '',
            questionType: '', updaingQuestion: false,
            questionAudio: ''
        })

    }

    handleGetQuestionDataForEdit(question, event) {
        this.setState({ updaingQuestion: true })


        let bool = false;  //image
        if (question.questionType === 'text') {
            bool = true;
            this.setState({ previewQuestionImages: false })
        }
        else { // for images to preview dropzone or preview images
            this.setState({ previewQuestionImages: true })
        }

        let ans = [];

        // ans in state
        if (question.choices !== undefined) {
            for (let i = 0; i < question.choices.length; i++) {
                ans.push({
                    "text": question.choices[i].text,
                    "voice": question.choices[i].voice
                })
            }
        }

        this.setState({
            questionCategoryBoolean: bool,
            answers: ans,
            questionType: question.questionType,
            questionCategoryID: question.questionCategoryId,
            questionText: question.text,
            correctAnswerId: question.correctAnswerId,
            updaingQuestion: true,
            questionAudio: question.questionAudio,


            questionToUpdate: question,
        })
    }

    // get the old question that has been updated and removeit from questions state
    // getting the new updated data and insert it in questions state
    updateQuestion = (event) => {

        // start remove the old question from questions state
        // didin't call deleting func as the question state doesn't change at the same time i use
        let questionToUpdate = this.state.questionToUpdate;

        let questions = [...this.state.questions];

        const filteredItems = questions.filter(function (item) {
            return item !== questionToUpdate
        })
        this.props.deleteQuestionfromRedux(filteredItems)
        // end removing the old question

        // getting the update question
        let questionType = this.state.questionType;
        let questionCategoryID = this.state.questionCategoryID;
        let questionText = this.state.questionText;
        let questionAudio = this.state.questionAudio;

        let ans = [];
        for (let i = 0; i < this.state.answers.length; i++) {
            if (this.state.answers[i].text === '')
                continue;
            else {
                ans.push({
                    "text": this.state.answers[i].text,
                    "id": i,
                    "voice": this.state.answers[i].voice
                })
            }
        }
        let correctAnswerId = this.state.correctAnswerId;

        let questiontheUpdatedQuestion = {
            questionType: questionType,
            questionCategoryId: questionCategoryID,
            text: questionText,
            choices: ans,
            correctAnswerId: correctAnswerId,
            questionAudio: questionAudio
        }

        let QuestionsWitNew = [...filteredItems, questiontheUpdatedQuestion];
        this.setState({ questions: QuestionsWitNew })
        this.props.addQuestions(QuestionsWitNew)

        this.setState({ questionCategoryID: memoryCategoryId })


        this.setState({
            answers: [], questionText: '',
           // questionCategoryID: '',
             questionType: '',
            updaingQuestion: false,
            questionAudio: ''
        })

        event.preventDefault();

    }

    render() {

        let buttonsState = null;
        if (this.state.updaingQuestion == true) {

            buttonsState = <span>
                <button
                    className="btn btn-lg btn-dark float-left small-font pl-4 pr-4"
                    type="submit" onClick={this.cancelSubmit} >
                    cancel
                </button>

                <button
                    className="btn btn-lg btn-primary add-ansrs-btn small-font pl-4 pr-4"
                    onClick={this.updateQuestion} >
                    update
                </button>
            </span>


        }
        else if (this.state.updaingQuestion == false) {
            buttonsState = <button
                className="btn btn-lg btn-primary add-ansrs-btn small-font pl-4 pr-4"
                type="submit" disabled={this.state.error} onClick={this.handleSubmit}>add</button>

        }

        const questions = this.state.questions;
        const { questionCategories } = this.props;
        const questionCategoriesList = [];
        for (let i = 0; i < questionCategories.length; i++) {
            questionCategoriesList.push({
                key: questionCategories[i].categoryID,
                text: questionCategories[i].categoryText,
                value: questionCategories[i].categoryID
            })
        }
        const questionsAdded = questions.length ? (

            questions.map((question, index) => {
                return (
                    <div className="answers-box" key={index}>
                        <span className="ansr-control">

                            <Popup trigger={
                                <FontAwesomeIcon className="far fa-trash-alt"
                                    icon='trash-alt'
                                    onClick={(e) => this.handleDelete(question, e)} />
                            }
                                content='Delete'
                                position='top center'
                            />


                            <span> | </span>
                            <Popup trigger={
                                <FontAwesomeIcon icon='edit'
                                    className="far fa-edit" onClick={(e) => this.handleGetQuestionDataForEdit(question, e)}>
                                </FontAwesomeIcon>}
                                content='Edit'
                                position='top center'
                            />
                        </span>
                        <span className="text-ansr">{question.text}</span>
                    </div>
                )
            })
        ) : (
                <p className="small-font">No questions added</p>
            )

        return (
            <div className="container">

                <div className="container">

                    <div className="row">
                        <h3 className="col-3">Add Question</h3> <hr className="col-8" />
                    </div>
                    <div className="row questionOuterDiv">
                        <div className="col-6 question">
                            <br />
                            <form onSubmit={(e)=> e.preventDefault()}>
                                <label>Question Type</label>
                                <Dropdown
                                    placeholder='Select Type'
                                    fluid
                                    selection
                                    options={questions_type}
                                    onChange={this.handleQuestionType}
                                    value={this.state.questionType}
                                />
                                <br />
                                <label>Question Category</label>
                                <Dropdown
                                    placeholder='Select Category'
                                    fluid
                                    selection
                                    options={questionCategoriesList}
                                    onChange={this.handleQuestionCategory}
                                    value={this.state.questionCategoryID}
                                />
                                <hr />
                                <label htmlFor="Question-text">Question</label>

                                <div className="row">
                                    <div className="col-1">
                                        <UploadAudio
                                            getAudioUrlCallBack={this.getAudio.bind(this)}

                                            audioPreview={this.state.questionAudio}
                                            audioUrl={this.state.questionAudio}
                                            s3DirectryrName="activity/audio" />
                                    </div>
                                    <div className="col">

                                        <input
                                            type="text"
                                            dir="auto"
                                            className="form-control"
                                            onChange={this.handleChange}
                                            name="questionText"
                                            value={this.state.questionText}>
                                        </input>

                                    </div>
                                </div>

                                <br />
                                <label>Add Answer</label>

                                {this.state.questionCategoryBoolean ? (this.createInputField()) : (this.createDropZoneFields())}
                                <br />
                                <button className="btn btn-lg btn-primary btn-add btn-block mb-4" onClick={this.addClick}>add New Answer</button>

                                <br />

                                <div className="text-right">
                                    {buttonsState}
                                </div>
                                <p style={{ color: 'red' }}> {this.state.errorMessage} </p>
                                <br />
                                <br /> <br />
                            </form>
                        </div>
                        <div className="col-5 addedQuestions">
                            <label className="mb-2 font-weight-bold small-font">Added questions</label>

                            {questionsAdded}
                        </div>
                    </div>

                </div>

            </div>

        )
    }
}
AddActivity.porpTypes = {
    fetchQuestionsCategories: porpTypes.func.isRequired,
    questionCategories: porpTypes.func.isRequired,
    addQuestion: porpTypes.func.isRequired,
    getActitvityQuestions: porpTypes.func.isRequired,
    Activityquestions: porpTypes.func.isRequired,
    deleteQuestionfromRedux: porpTypes.func.isRequired,
}
const mapStateToProps = state => ({
    questionCategories: state.questionCategories.items,
    activity: state.activities.item,
    question: state.questions.item,
    activityQuestions: state.questions.items

});

export default withFirebase(
    connect(
        mapStateToProps,
        {
            fetchQuestionsCategories,
            addQuestions,
            getActitvityQuestions,
            deleteQuestionfromRedux
        })
        (AddActivity));
