import React, { Component } from 'react'
import { Confirm, Popup } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
/*Redux */
import { withFirebase } from '../../config'
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { customerForgetPassword, fetchAllCustomers, emptyCustomer }
    from '../../Actions/CustomerActions'

const INITIAL_STATE = {
    open: false
};


class ResetPasswordCustomer extends Component {


    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    open = () => { this.setState({ open: true }) }

    close = () => this.setState({ open: false })


    customerResetPassword(email) {
        this.props.customerForgetPassword(email);
    }


    handleSubmit = (e) => {
        console.log("handelSubmit")
        const { email } = this.props;

        this.customerResetPassword(email);

        e.preventDefault();
    }


    render() {

        const { email } = this.props;
        let text = 'Are you sure you want to reset this email ' + email + ' password ??'

        return (

            <span >
                <Popup trigger=
                    {<a className="key" onClick={this.open.bind(this)}>
                        <i >
                            <FontAwesomeIcon icon="key" />
                        </i>
                    </a>}
                    content='Reset Password'
                    position='top center'
                />
                <Confirm id="modal"
                    content={text}
                    open={this.state.open}
                    onCancel={this.close.bind(this)}
                    onConfirm={this.handleSubmit.bind(this)} />
            </span>


        )

    }
}


ResetPasswordCustomer.propTypes = {
    customerForgetPassword: propTypes.func.isRequired,
    fetchAllCustomers: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
   });

export default withFirebase
    (connect
        (mapStateToProps,
            {
                customerForgetPassword,
                fetchAllCustomers,
                emptyCustomer
            }
        )(ResetPasswordCustomer)
    );
