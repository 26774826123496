import React, { Component } from 'react'
import Zoom from 'react-medium-image-zoom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect } from 'react-router-dom';
import firebase from 'firebase/app'
import { connect } from 'react-redux';
import propTypes from 'prop-types';

/*components*/
import OuterHeader from '../Layout/OuterHeader';
import InnerHeader from '../Layout/InnerHeader';
import Dashboard from '../Dashboard';
import Footer from '../Layout/Footer';
import Loader from '../Layout/Loader';
import GetCommentIssueList from './GetCommentIssueList';
/*Actions*/
import { addSeriesIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'
import { getIssueDetails, updateIssueStatus } from '../../Actions/IssueAction'
import { getUserData } from '../../Actions/MobilePaymentProfileAction';
import { saveComment, getIssueComments } from '../../Actions/CommentActions'
/*css*/
import '../../css/IssueDetails.css';
import 'react-medium-image-zoom/dist/styles.css'
/* Routes */
import { SIGN_IN, ALL_ISSUES, VIEW_CUSTOMER, ISSUE_TO_GET } from '../../routes';

class IssueDetails extends Component {
    constructor(props) {
        super(props);
        if (firebase.auth().currentUser) {
            firebase.auth().currentUser.getIdToken().then((token) => {
                this.props.getUserData(token);
            })
        }
    }

    state = {
        text: "",
        issue_id: 0,
        status: '',
        // validation
        error: true,
        // analyst can't change status
        allowedRole: false
    }

    componentDidMount() {

        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {
            let id = this.props.match.params.id;
            const { userPaymentProfile } = this.props;
            if (userPaymentProfile !== undefined && userPaymentProfile !== null && userPaymentProfile.user_role.role !== 'Analyst') {
                this.setState({ allowedRole: true });
            }
            this.setState({
                issue_id: id
            })
            this.props.getIssueDetails(id);
            let page = ISSUE_TO_GET + '/' + id
            this.props.addSeriesIDtoRedirectionPage(page, 0);

        }
    }


    handleTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }


    handleChange = (e) => {
        switch (e.target.name) {
            // validation
            case 'status':
                this.props.updateIssueStatus(e.target.value, this.state.issue_id)
            default:
                let checkSpaces = e.target.value.replace(/\s\s+/g, ' ');
                console.log("checkSpaces")
                console.log(checkSpaces)
                this.setState({ [e.target.name]: checkSpaces });
                if (this.state.text !== "" && this.state.text !== ' ' && this.state.text !== null) {
                    this.setState({ error: false });
                }
        }
    }

    handleSubmit = (e) => {
        const { text, issue_id } = this.state;

        let checkSpaces = text.replace(/\s\s+/g, ' ');
        if (checkSpaces !== " "
            && checkSpaces !== ""
            && checkSpaces !== ''
            && checkSpaces !== ' ') {
            this.props.saveComment(text, issue_id)
            this.setState({ text: "", error: true })

        } else {
            alert("Can't add empty text")
            this.setState({ text: "", error: true })
        }
    }

    render() {
        const { issue } = this.props;

        let issueStatue = "";
        let dropDown = null
        if (issue.status !== undefined) {
            issueStatue = issue.status
            dropDown = (<select className="form-control"
                defaultValue={issueStatue}
                disabled={!this.state.allowedRole}
                onChange={this.handleChange}
                name="status"
                id="exampleFormControlSelect1">
                <option value="open">open</option>
                <option value="close">close</option>
                <option value="waiting for replay">waiting for replay</option>

            </select>)
        }
        const { comments } = this.props;
        console.log(comments); // returns undefined/ wait till it's value
       
        let commentsNumber = 0;
        if (comments === undefined) {
            return <Loader />
        }
        if (comments !== undefined) {
            commentsNumber = comments.length;
        }

        let userName = "", userUid = "";

        if (issue.createdByUid !== undefined) {
            userUid = issue.createdByUid.uid

            if (issue.createdByUid.name !== undefined &&
                issue.createdByUid.name !== null &&
                issue.createdByUid.name !== '') {
                userName = issue.createdByUid.name
            }

        }

        let image = "";
        let imageItems;

        if (issue.image === null) {
            image = <span></span>
        }
        else if (image !== null) {

            image = (

                <Zoom>
                    <img src={issue.image} alt="" height="90" width="90" style={{ margin: '0px 5px 0px 5px' }} />
                </Zoom>
            );

        }

        if (issue.issueImages === null || issue.issueImages.length == 0) {
            imageItems = <div></div>
        }
        else if (issue.issueImages !== null && issue.issueImages.length !== 0) {

            imageItems = issue.issueImages.map(img => {
                return (<Zoom>
                    <img src={img.imageUrl} alt="" height="90" width="90" style={{ margin: '0px 5px 0px 5px' }} />
                </Zoom>)
            })
        }


        // console.log("text")
        // console.log(this.state.text)
        // console.log("this.state.text !== null")
        // console.log(this.state.text !== null)

        // console.log("this.state.text !== ''")
        // console.log(this.state.text !== "")

        // console.log("this.state.text !== ' '")
        // console.log(this.state.text !== ' ')


        let validateText = this.state.text !== "" &&
            this.state.text !== ' ' &&
            this.state.text !== null

        // console.log("condition validateText")
        // console.log(validateText)

        // console.log("!condition validateText")
        // console.log(!validateText)

        let sendMessage = '';
        const { userPaymentProfile } = this.props;
        if (userPaymentProfile.user_role.role !== "Analyst") {
            sendMessage = (
                <div className="type_msg">
                    <div className="input_msg_write">
                        <textarea type="text"
                            dir="auto"
                            className="form-control write_msg"
                            id="exampleFormControlTextarea1"
                            placeholder="Type a message"
                            name="text"
                            onChange={this.handleTextChange}
                            value={this.state.text}
                            rows="4" />

                        <button disabled={!validateText}
                            className="msg_send_btn"
                            type="submit" onClick={this.handleSubmit} >
                            <FontAwesomeIcon icon='paper-plane'
                                className="fa fa-paper-plane" aria-hidden="true">
                            </FontAwesomeIcon>
                        </button>
                    </div>
                </div>
            )
        }

        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <br />
                                            <h5 className="main-title mt-2 font-weight-bold">Complaints and suggestions</h5>
                                            <div class="card chat-box">
                                                <div class="card-body">

                                                    <div class="row">
                                                        <div class="col-6 col-xs-12">
                                                            <div class="float-left">
                                                                <Link to={ALL_ISSUES} class="C-user-details"><FontAwesomeIcon icon='chevron-left' className="fas fa-chevron-left"></FontAwesomeIcon></Link>
                                                                <span class="h6 font-weight-bold">{issue.title}</span>

                                                                <Link className="link"
                                                                    to={{ pathname: VIEW_CUSTOMER + "/" + userUid }}>

                                                                    <span class="e-user-details mt-1">{userName}</span>
                                                                </Link>

                                                            </div>
                                                        </div>
                                                        <div class="col-6 col-xs-12">
                                                            <div class="float-right">
                                                                <a href="" class="mr-1">{commentsNumber}<FontAwesomeIcon icon="comments" className="fas fa-comments ml-1"></FontAwesomeIcon></a>
                                                                <span class="message-status small-font form-group">
                                                                    {dropDown}
                                                                </span>
                                                                <div class="small-label">
                                                                    <span>{issue.createdDate}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <center>
                                                            {image}
                                                            {imageItems}
                                                        </center>

                                                    </div>
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col">
                                                            <div class="cop-content mb-3">
                                                                <a class="small-font">
                                                                    {issue.descreption}
                                                                </a>
                                                            </div>

                                                            <div class="mesgs">
                                                                <div class="msg_history">
                                                                    <GetCommentIssueList issueId={issue.id} />
                                                                </div>
                                                                {sendMessage}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}
IssueDetails.propTypes = {
    addSeriesIDtoRedirectionPage: propTypes.func.isRequired,

}
const mapStateToProps = state => ({
    issue: state.issues.item,
    comments: state.issues.item.comments,
    auth: state.firebase.auth,
    userPaymentProfile: state.userPaymentProfile.item,

})

export default
    connect(
        mapStateToProps,
        {
            getIssueDetails,
            saveComment,
            updateIssueStatus,
            getUserData,
            getIssueComments,
            addSeriesIDtoRedirectionPage
        })(IssueDetails)
