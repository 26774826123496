import {
    LOAD_All_TIME_OF_USE, FETCH_All_TIME_OF_USE,
    ERROR_ALL_TIME_OF_USE,
    LOAD_TIME_OF_USE,
    ERROR_ADDING_TIME_OF_USE, ADD_TIME_OF_USE,
    DELETING_TIME_OF_USE,
    ERROR_DELETING_TIME_OF_USE,
    EMPTY_TIME
} from '../Actions/Types'

const initialState = {
    items: [],
    item: {},
    loading: '',

    getting_all_Time_error: '',
    getting_all_Time_sucessfully: '',

    adding_Time_error: '',
    adding_Time_sucessfully: "",

    deleting_Time_error: '',
    deleting_Time_sucessfully: "",
}

export default function (state = initialState, action) {
    switch (action.type) {


        case FETCH_All_TIME_OF_USE:
            return {
                ...state,
                items: action.payload,
                getting_all_Time_error: '',
                getting_all_Time_sucessfully: true,
                loading: false
            }

        case ERROR_ALL_TIME_OF_USE:
            return {

                ...state,
                getting_all_Time_error: action.payload,
                loading: false
            }

        case LOAD_All_TIME_OF_USE:
            return {
                ...state,
                loading: true
            }

        case LOAD_TIME_OF_USE:
            return {
                ...state,
                loading: true
            }

        case ADD_TIME_OF_USE:
            return {
                ...state,
                items: action.payload,
                adding_Time_error: '',
                adding_Time_sucessfully: true,
                loading: false
            }

        case ERROR_ADDING_TIME_OF_USE:
            return {

                ...state,
                adding_Time_error: action.payload,
                adding_Time_sucessfully: false,
                loading: false
            }

        case DELETING_TIME_OF_USE:
            return {

                ...state,
                item: action.payload,
                deleting_Time_sucessfully: true,
                loading: false
            }
        case ERROR_DELETING_TIME_OF_USE:
            return {

                ...state,
                deleting_Time_error: action.payload,
                deleting_Time_sucessfully: false,
                loading: false
            }

        case EMPTY_TIME:
            return {
                ...state,
                adding_Time_error: action.payload,
                adding_Time_sucessfully: action.payload,
                deleting_Time_error: action.payload,
                deleting_Time_sucessfully: action.payload
            }

        default:
            return state

    }

}