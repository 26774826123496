import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
/*header */
import OuterHeader from '../Layout/OuterHeader';
import Dashboard from '../Dashboard';
import InnerHeader from '../Layout/InnerHeader';
import NavBar from '../Layout/NavBar';
import Footer from '../Layout/Footer';

import { connect } from 'react-redux';
import Player from 'video-react/lib/components/Player';
import AudioPlayer from "react-h5-audio-player";
/*css */
import "bootstrap/dist/css/bootstrap.min.css";
import '../../../node_modules/video-react/dist/video-react.css';
/*Action */
import {
    getSongData,
    emptySong,
    saveSongPuzzle,
    saveSongPaintingGame
} from '../../Actions/SongActions';
import { addDragDropParentIDtoRedirectionPage } from '../../Actions/RedirectionPagesActions.js'

/*component */
import QuestionPreview from '../Layout/QuestionPreview';
import GamePreview from '../Layout/GamePreview';
import PaintingPreview from '../Layout/PaintingPreview';
import UploadImage from '../S3Uploading/UploadImage';
import EvaluationPreview from '../Evaluation/EvaluationPreview';
import DragDropPreview from '../Layout/DragDropPreview'

/* Routes */
import { SIGN_IN, SONG_ACTIVITY_TO_EDIT, SONG_TO_EDIT, SONG_TO_GET } from '../../routes';
import RenderVideoWithoutMusic from '../VideoPreview/RenderVideoWithoutMusic.js';
import { SONG_FOLDER_NAME } from '../../portal/src/constants.js';
import RenderVideoWithMusic from '../VideoPreview/RenderVideoWithMusic.js';

const INITIAL_STATE = {
    songParentId: '',
    songParentName: '',

    Icon_Image: "",
    Painting_Image: ""
}

function DateFormate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    //January is 0!
    var yyyy = date.getFullYear();
    if (dd < 10) { dd = '0' + dd }
    if (mm < 10) { mm = '0' + mm }
    date = mm + '/' + dd + '/' + yyyy + ", " + date.getHours() + ":" + date.getMinutes();
    return date;
}

function GMTtolocalTime(GMTDate) {
    var date = new Date(GMTDate + " UTC")
    var d = new Date(date.toString())
    var d = DateFormate(d)

    return d;
}

class SongDetails extends Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.props.emptySong();
    }


    componentDidMount() {
        const { auth } = this.props
        if (!auth.uid) {
            return (<Redirect to={SIGN_IN}></Redirect>)
        }
        else {

            let songParentName = this.props.match.params.songParentName;
            let songParentId = this.props.match.params.songParentId;
            let songId = this.props.match.params.songId;

            this.setState({
                songParentName: songParentName,
                songParentId: songParentId
            });

            this.props.getSongData(songId);

            var url = SONG_TO_GET + "/"
                + songParentName + "/"
                + songParentId + "/"
                + songId;

            this.props.addDragDropParentIDtoRedirectionPage(url, songId);

        }

    }

    handleChangeVideoWithMusic = (uploadInfo, videoKey) => {

        // if (
        //   uploadInfo &&
        //   uploadInfo !== undefined &&
        //   uploadInfo !== null &&
        //   Object.keys(uploadInfo).length > 0
        // ) {

        // } 
    };
    handleChangeVideoWithoutMusic = (uploadInfo, videoKey) => {

        // if (
        //   uploadInfo &&
        //   uploadInfo !== undefined &&
        //   uploadInfo !== null &&
        //   Object.keys(uploadInfo).length > 0
        // ) {

        // } 
    };

    getIconImageUrl(imageUrl) {

        if (imageUrl) {
            this.setState({ Icon_Image: imageUrl });

            saveSongPuzzle(this.props.match.params.songId, imageUrl)
                .then(res => {
                    alert("Puzzle Game Item Added Successfully");
                    let id = this.props.match.params.songId;
                    this.props.getSongData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        setTimeout(() => {
            this.setState({ Icon_Image: "" });
        }, 10);
    }



    getPaintingImageUrl(imageUrl) {
        if (imageUrl) {
            this.setState({ Painting_Image: imageUrl });

            saveSongPaintingGame(this.props.match.params.songId, imageUrl)
                .then(res => {
                    alert("Painting Game Item Added Successfully");
                    let id = this.props.match.params.songId;
                    this.props.getSongData(id);
                })
                .catch(err => {
                    alert(err.response.data.message);
                });
        }

        setTimeout(() => {
            this.setState({ Painting_Image: "" });
        }, 10);
    }


    render() {

        let songParentName = this.props.match.params.songParentName;
        let songParentId = this.props.match.params.songParentId;
        let songId = this.props.match.params.songId;





        const { song } = this.props
        const { activity } = this.props
        const { puzzles } = this.props.song
        const { paintingGames } = this.props.song
        const { dragDrops } = this.props.song

        let evaluationPreview = null;

        let questionsList = null, editActivityLink = null, gamesList = null, gamesPaintingList = null;

        if (activity !== undefined) {
            questionsList = (<QuestionPreview state='view' questions={activity.questions} />)
            evaluationPreview = (<EvaluationPreview evaluationCategory={activity.evaluationCategory} />);


            if (activity.id !== undefined) {
                editActivityLink = (
                    <Link to={{
                        pathname: SONG_ACTIVITY_TO_EDIT
                            + "/" + activity.id
                            + "/" + songParentName + "/" + songParentId
                            + "/" + songId
                    }}>

                        <Button id="episode_view"> Edit </Button>

                    </Link>);
            }
        }


        let subvalue = "No Sub Value"
        if (song.contentSubValue !== null
            && song.contentSubValue !== undefined) {

            if (song.contentSubValue.contentValue !== null
                &&
                song.contentSubValue.contentValue !== undefined) {
                subvalue = song.contentSubValue.contentValue;
            }
        }

        if (puzzles !== undefined) {
            gamesList = (<GamePreview state='viewSong' games={puzzles} />)
        }

        if (paintingGames !== undefined) {
            gamesPaintingList = (<PaintingPreview state='viewSong' games={paintingGames} />)
        }

        let song_drag_Drop = null;
        if (dragDrops !== undefined) {
            song_drag_Drop = (<DragDropPreview
                games={dragDrops}
                state="viewSong"
                parentId={songId} />);
        }


        let VideoPreviewWithMusic = null;
        if (song.videoWithMusicId !== undefined
            && song.videoWithMusicId !== null) {
            if (song.videoWithMusicId.url !== undefined
                && song.videoWithMusicId.url !== null) {
                VideoPreviewWithMusic = (<Player playsInline fluid src={song.videoWithMusicId.url} />);
            } else {
                VideoPreviewWithMusic = (<div className="previewText">No Video for Preview</div>);
            }
        }

        let VideoPreviewWithoutMusic = null;
        if (song.videoWithoutMusicId !== undefined
            && song.videoWithoutMusicId !== null) {
            if (song.videoWithoutMusicId.url !== undefined
                && song.videoWithoutMusicId.url !== null) {
                VideoPreviewWithoutMusic = (<Player playsInline fluid src={song.videoWithoutMusicId.url} />);
            } else {
                VideoPreviewWithoutMusic = (<div className="previewText">No Video for Preview</div>);
            }

        }

        let isFree = ''
        if (song.isFree == 0) {
            isFree = 'paid'
        }
        else if (song.isFree == 1) {
            isFree = 'free'
        }

        let audio = ''

        if (song.voice !== undefined &&
            song.voice !== null &&
            song.voice !== "undefined"
            && song.voice !== "null"
            && song.voice !== 'null') {
            audio = (<AudioPlayer
                src={song.voice}
                onPlay={e => console.log("onPlay")}
            />)
        }
        else {
            audio = (
                <td fluid className="episode_label"> No Audio</td>
            )
        }


        let songDescription = '';
        if (song.description !== null &&
            song.description !== undefined &&
            song.description !== '') {
            songDescription = song.description
        }
        else {
            songDescription = "No description"
        }

        let featured = false;
        if (song.featured !== null && song.featured !== undefined) {
            featured = song.featured;
        }

        return (

            <div className="container">
                <OuterHeader />
                <div className="container">
                    <div className="container-body">
                        <div className="row">

                            <Dashboard />
                            <div className="col-9">
                                <InnerHeader />
                                <div className="main-content">
                                    <div className="row">
                                        <div className="col-12 left-main-body" id="left-main-body">
                                            <NavBar />
                                            <br />
                                            <div className="container">
                                                <label htmlFor="" >{song.name}</label>
                                                <hr />

                                                {song.videoWithMusicId && (
                                                    <div>

                                                        <label className="label">Video with music</label>
                                                        <section className="video_section">
                                                            <RenderVideoWithMusic
                                                                videoKey={song.videoWithMusicId.vdocipherId}
                                                                id="VideoWithMusic"
                                                                foldername={SONG_FOLDER_NAME}
                                                                changedid={this.handleChangeVideoWithMusic}
                                                                enableEdit={false}
                                                            ></RenderVideoWithMusic>
                                                        </section>

                                                        <br />
                                                    </div>
                                                )}
                                                {song.videoWithoutMusicId && (
                                                    <div>

                                                        <label className="label">Video without music</label>
                                                        <section className="video_section">
                                                            <RenderVideoWithoutMusic
                                                                videoKey={song.videoWithoutMusicId.vdocipherId}
                                                                id="VideoWithoutMusic"
                                                                foldername={SONG_FOLDER_NAME}
                                                                changedid={this.handleChangeVideoWithoutMusic}
                                                                enableEdit={false}
                                                            ></RenderVideoWithoutMusic>
                                                        </section>

                                                        <br />
                                                    </div>
                                                )}
                                                {/* {song.videoWithMusicId &&
            song.videoWithMusicId.url !== null && song.videoWithMusicId.url !== ""  && <div>
 <label className="label">Video with music</label>

<section className="video_section" >
    <div>
        {VideoPreviewWithMusic}
    </div>
</section>
<br />
            </div>}

            {song.videoWithoutMusicId &&
            song.videoWithoutMusicId.url !== null && song.videoWithoutMusicId.url !== ""  && <div>
 <label className="label">Video without music</label>

<section className="video_section" >
    <div>
        {VideoPreviewWithoutMusic}
    </div>
</section>

<br />
            </div>} */}



                                                <hr />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <img className="rounded image_Preview" src={song.image} alt="" />
                                                    </div>

                                                    <div className="col">
                                                        <table >
                                                            <tr>
                                                                <th className="episode_label"> Song Name </th>
                                                                <td fluid className="episode_label"> {song.name} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Song Name In English </th>
                                                                <td fluid className="episode_label"> {song.nameInEnglish} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Selected Song Parent </th>
                                                                <td fluid className="episode_label"> {this.state.songParentName} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> payment availability </th>
                                                                <td fluid className="episode_label"> {isFree} </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Sub Value </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {subvalue}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Schedule </th>
                                                                <td fluid className="episode_label">{GMTtolocalTime(song.publishDate)}</td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Audio </th>
                                                                {audio}
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Description </th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {songDescription}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Description In English</th>
                                                                <td fluid className="episode_label">
                                                                    <p>
                                                                        {song.descriptionInEnglish}
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th className="episode_label"> Featured </th>
                                                                {featured ? "True" : "False"}
                                                            </tr>

                                                        </table>
                                                    </div>


                                                </div>

                                                <Link className="link" to={SONG_TO_EDIT + "/" + this.state.songParentName
                                                    + "/" + this.state.songParentId
                                                    + "/" + song.id}>
                                                    <Button color='blue'
                                                        id="editButton">
                                                        Edit
                                                    </Button>
                                                </Link>
                                                <br /> <br /> <br />


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Question</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {questionsList}


                                                </div>
                                                <br />

                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Evaluation</label>
                                                    <hr id="shorthr" />
                                                    <br />
                                                    {evaluationPreview}

                                                    <div className="buttons_container">
                                                        {editActivityLink}

                                                    </div>

                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Puzzle Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {gamesList}

                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getIconImageUrl.bind(this)}
                                                            imageUrl={this.state.Icon_Image}
                                                            imagepreview={this.state.Icon_Image}
                                                            s3DirectryrName='song/puzzlegame' />
                                                    </div>
                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Painting Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {gamesPaintingList}

                                                    <div className={"col-3"}>
                                                        <UploadImage
                                                            getImageURLCallBack={this.getPaintingImageUrl.bind(this)}
                                                            imageUrl={this.state.Painting_Image}
                                                            imagepreview={this.state.Painting_Image}
                                                            s3DirectryrName='song/paintinggame' />
                                                    </div>
                                                </div>


                                                <div className="row" id="episode_Question_Row">
                                                    <label className="question_label">Drag Drop Game</label>
                                                    <hr id="questionHR" />
                                                    <br />
                                                    {song_drag_Drop}

                                                </div>




                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }
}
const mapStateToProps = state => ({
    song: state.songs.item,
    activity: state.songs.item.activityId,
    auth: state.firebase.auth

});

export default connect
    (mapStateToProps,
        {
            getSongData,
            emptySong,
            addDragDropParentIDtoRedirectionPage
        })(SongDetails)
