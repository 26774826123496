import {
    GET_BLOG_CATEGPORIES,
    ERROR_LOADING_BLOGS,
    LOAD_BLOG_CATEGORIES,
    UPDATE_BLOG_CATEGORY,
    EMPTY_BLOG_CATEGORY,
    ERROR_UPDATE_BLOG_CATEGORY,
    SAVE_BLOG_CATEGORY,
    ERROR_ADDING_BLOG_CATEGORY,
    BLOG_CATEGORY_DELETED,
    ERROR_DELETING_BLOG_CATEGORY
} from "../Actions/Types";

const Initial_State = {
    item: {},
    items: [],
    error_loading_blog_category: '',
    loading_blog_category: '',
    blog_category_updated_successfully: '',
    blog_category_saved_successfully: '',
    error_updating_blog_category: '',
    blog_category_deleted_successfully: '',
    error_deleteing_blog_category: '',
    error_adding_blog_category: ''
}

export default function (state = Initial_State, action) {
    switch (action.type) {
        case BLOG_CATEGORY_DELETED:
            return {
                ...state,
                blog_category_deleted_successfully: true,
                error_deleteing_blog_category: '',
                item: action.payload
            }
        case ERROR_DELETING_BLOG_CATEGORY:
            return {
                ...state,
                blog_category_deleted_successfully: false,
                error_deleteing_blog_category: action.payload
            }
        case UPDATE_BLOG_CATEGORY:
            return {
                ...state,
                item: action.payload,
                blog_category_updated_successfully: true
            }
        case ERROR_UPDATE_BLOG_CATEGORY:
            return {
                ...state,
                error_updating_blog_category: action.payload,
                blog_category_updated_successfully: false
            }
        case SAVE_BLOG_CATEGORY:
            return {
                ...state,
                item: action.payload,
                blog_category_saved_successfully: true
            }
        case ERROR_ADDING_BLOG_CATEGORY:
            return {
                ...state,
                error_adding_blog_category: action.payload,
                blog_category_saved_successfully: false

            }
        case GET_BLOG_CATEGPORIES:
            return {
                ...state,
                items: action.payload,
                loading_blog_category: false,
                error_loading_blog_category: ''

            }
        case EMPTY_BLOG_CATEGORY:
            return {
                ...state,
                blog_category_updated_successfully: '',
                error_loading_blog_category: '',
                error_adding_blog_category: '',
                blog_category_saved_successfully: '',
                error_updating_blog_category: '',
                error_deleteing_blog_category: '',
                blog_category_deleted_successfully: ''

            }
        case ERROR_LOADING_BLOGS:
            return {
                ...state,
                error_loading_blog_category: action.payload,
                loading_blog_category: false
            }
        case LOAD_BLOG_CATEGORIES:
            return {
                ...state,
                loading_blog_category: true
            }
        default:
            return state
    }
}