import {post} from 'axios'
import {SAVE_COMMENT,ERROR_SAVING_COMMENT,ERROR_LOADING_ISSUE_COMMENTS,GET_ISSUE_COMMENTS,LOAD_ISSUE_COMMENTS} from './Types'

export const saveComment =(comment,issueId)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        console.log("saveComment action")
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>{

            const url = "/Comment/saveComment";
            const formData = new FormData();
            formData.append('Text',comment);
            formData.append('Issue_ID',issueId)
            
            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase':  token
                }
            }
            post(url,formData,config).then((res)=>
            {
                dispatch({
                    type:SAVE_COMMENT,
                    payload:res.data
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_SAVING_COMMENT,
                    payload:Error.message
                })
            })
        })

    }
} 

export const getIssueComments =(issue_id)=>
{
    return (dispatch,getState,{getFirebase})=>
    {
        console.log("get comment action")

        dispatch({
            type:LOAD_ISSUE_COMMENTS,
            payload:'loading'
        })
    
        const firebase = getFirebase();
        firebase.auth().currentUser.getIdToken().then((token)=>{

            
            const url = "/Comment/getCommentsByIssue"
            const formData  = new FormData();
            formData.append('Issue_ID',issue_id)

            const config = {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                    'X-Authorization-Firebase':  token
                }
            }

            post (url,formData,config).then((res)=>
            {

                console.log(res.data.results)
                dispatch({
                    type:GET_ISSUE_COMMENTS,
                    payload:res.data.results
                })
            }).catch((Error)=>
            {
                dispatch({
                    type:ERROR_LOADING_ISSUE_COMMENTS,
                    payload:Error.message
                })
            })

        })
    }
}
