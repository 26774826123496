import React, { Component } from 'react'
import { withFirebase } from '../../config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Confirm } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom';

/*Redux */
import 'firebase/firestore'
import 'firebase/auth'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
/*Actions */
import { deleteEpisode, emptyError } from '../../Actions/EpisodeActions'
import { getSeriesData } from '../../Actions/SeriesActions';
import { getDiscoverUsSeriesData } from '../../Actions/DiscoverUsSeriesActions'


class DeleteEpisode extends Component {
    state = { open: false }

    open = () => this.setState({ open: true })
    close = () => this.setState({ open: false })


    deleteEpisode(id) {
        this.props.deleteEpisode(id);
    }


    handleSubmit = (e) => {

        const { id } = this.props;

        console.log("id = " + id)

        this.deleteEpisode(id);
        this.close();

    }

    render() {

        const { error } = this.props;
        const { success } = this.props;
        const { seriesID } = this.props;
        const { seriesType } = this.props

        if (success === false && error !== '') {
            alert("error messing data or network " + error)
            this.props.emptyError();

        }
        else if (success === true) {
            alert("Episode Deleted Successfully")
            this.props.emptyError();
            if (seriesType === "Normal") { this.props.getSeriesData(seriesID) }
            else if (seriesType === "Discover") {
                this.props.getDiscoverUsSeriesData(seriesID);
            }
        }

        return (
            <div >
                <button className="seriesCardButtons" onClick={this.open}>
                    <i className="" >
                        <FontAwesomeIcon icon="trash-alt" />
                    </i>
                </button>
                <Confirm id="modal"
                    content='Are you sure you want to delete this episode ??'
                    open={this.state.open} onCancel={this.close} onConfirm={this.handleSubmit} />
            </div>

        )
    }
}


DeleteEpisode.propTypes = {
    deleteEpisode: propTypes.func.isRequired,
    getSeriesData: propTypes.func.isRequired,
}
const mapStateToProps = state => ({
    episodes: state.episodes.item,
    error: state.episodes.deleting_episode_error,
    success: state.episodes.deleting_episode_sucessfully
});

export default withFirebase
    (connect
        (mapStateToProps,
            {
                deleteEpisode,
                emptyError,
                getSeriesData,
                getDiscoverUsSeriesData
            }
        )
        (DeleteEpisode)
    );
