import React, { Component } from 'react'
import {Elements} from 'react-stripe-elements';

import InjectedCheckoutForm from './MobilePaymentStripeCheckout';

class MobilePaymentStripeCheckoutParent extends Component {
    render() {
        return (
            <Elements>
                <InjectedCheckoutForm />
            </Elements>
        )
    }
}

export default MobilePaymentStripeCheckoutParent
